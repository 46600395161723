import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styled';
import React, { PropsWithChildren, useCallback, useState } from 'react';

export interface GalleryProps {
  showNavigation?: boolean;
}

export const Gallery = ({
  children,
  showNavigation = true,
}: PropsWithChildren<GalleryProps>) => {
  const [navIndex, setNavIndex] = useState<number>(0);

  const handlePrevious = useCallback(() => {
    if (navIndex > 0) setNavIndex(navIndex - 1);
  }, [navIndex]);

  const handleNext = useCallback(() => {
    if (navIndex < React.Children.count(children) - 1)
      setNavIndex(navIndex + 1);
  }, [navIndex, children]);

  return (
    <SC.Container>
      <SC.Content>
        <SC.NavButton>
          {showNavigation && (
            <IconButton
              size="small"
              variant="filled"
              icon="IconChevronLeft"
              onClick={handlePrevious}
            />
          )}
        </SC.NavButton>

        {children && (
          <SC.Content>
            {React.Children.map(
              children,
              (child, i) => i === navIndex && child,
            )}
          </SC.Content>
        )}

        <SC.NavButton>
          {showNavigation && (
            <IconButton
              size="small"
              variant="filled"
              icon="IconChevronRight"
              onClick={handleNext}
            />
          )}
        </SC.NavButton>
      </SC.Content>

      {showNavigation && (
        <SC.NavList>
          {children &&
            [...Array(React.Children.count(children))].map((e, i) => (
              <SC.NavListItem
                key={i}
                isActive={i === navIndex}
                onClick={() => setNavIndex(i)}
              />
            ))}
        </SC.NavList>
      )}
    </SC.Container>
  );
};
