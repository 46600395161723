//CSS no padrão BEM (Bloco Elemento Modificador)

var LocationsTemplate = {
  //Template principal "pai" dos demais componentes
  main: ({
    placeholder,
    readOnly,
    selectOptions,
    showBackgroundMask,
    hideLoader,
    inputText,
    validationResult,
    onChange,
    onClickOutsideOptions,
    onClick,
    onKeyDown,
  }) => (
    <div
      className={
        'exon-locations' +
        (!validationResult.isValid
          ? ' exon-alert-border'
          : '' + `${readOnly ? ' readonly' : ''}`)
      }
    >
      <div
        className={
          'exon-outside-options-background-mask ' +
          (!showBackgroundMask ? 'hide' : '')
        }
        onClick={onClickOutsideOptions}
      ></div>
      <input
        className={
          'exon-textbox exon-locations__field' +
          (showBackgroundMask ? '-active' : '')
        }
        readOnly={readOnly}
        autoComplete="off"
        name="text"
        type="text"
        pattern="[a-z\s]+$"
        required="required"
        placeholder={placeholder}
        value={inputText}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
      <span
        className={
          'exon-icon-loader exon-locations__loader ' +
          (hideLoader ? 'hide' : '')
        }
      ></span>
      {!validationResult.isValid && (
        <span className={'exon-msg-alert-field'}>
          {validationResult.message}
        </span>
      )}
      {selectOptions}
    </div>
  ),

  //Template das opções a serem renderizadas a partir do resultado da busca
  options: ({ options, onClick, hideOptions }) => (
    <ul
      className={
        'exon-select-itens list exon-locations__list ' +
        (hideOptions ? 'hide' : '')
      }
    >
      {options.map(({ _identifier, id, name, type }, index) => {
        let disabled = id === 0;
        return (
          <LocationsTemplate.option
            value={_identifier}
            disabled={disabled}
            key={index}
            type={type}
            onClick={onClick}
          >
            {name}
          </LocationsTemplate.option>
        );
      })}
    </ul>
  ),

  //Template de cada item do resultado da busca
  option: ({ disabled, onClick, value, type, children }) => (
    <li
      disabled={disabled}
      onClick={onClick}
      value={value}
      className="exon-locations__list-item"
    >
      {/*airport, city, state, country, busstation*/}
      <span
        className={'exon-icon exon-icon-' + type + '-unfilled_disabled'}
      ></span>
      {children}
    </li>
  ),
};

export default LocationsTemplate;
