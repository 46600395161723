import { trpc } from '@api/bff/client';
import { useWizard } from '@components/Wizard/hooks/useWizard';
import { Wizard } from '@components/Wizard/Wizard';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { FormProvider } from 'react-hook-form';
import { PolicyBasicInformation } from './components/PolicyBasicInformation';
import { PolicyProperties } from './components/PolicyProperties';
import { usePolicyForm } from './hooks/usePolicyForm';

type Props = {
  policyId?: string | null;
  onSave: () => void;
  onCancel: () => void;
};

export const PolicyRegister = ({ onCancel, onSave, policyId }: Props) => {
  const policy = trpc.getPolicyById.useQuery(
    { id: policyId },
    {
      enabled: !!policyId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const policyRegisterWizard = useWizard();

  const { policyForm, onSubmit, isSaving } = usePolicyForm({
    onSave,
    policy: policy.data,
    step: policyRegisterWizard.activeStep,
  });

  async function handleChangeStep(step: number) {
    try {
      if (step > policyRegisterWizard.activeStep) {
        const isValid = await policyForm.trigger();

        if (!isValid) return;
      }

      return policyRegisterWizard.onStepChange(step);
    } catch (err) {
      return policyRegisterWizard.onStepChange(step);
    }
  }

  const steps = [
    <PolicyBasicInformation isLoading={!!policyId && policy.isLoading} />,
    <PolicyProperties />,
  ];

  return (
    <Wizard
      onCancel={onCancel}
      steps={['Informações básicas', 'Propriedades da política' /* 'Resumo' */]}
      onComplete={policyForm.handleSubmit(onSubmit)}
      isSubmitting={isSaving}
      activeStep={policyRegisterWizard.activeStep}
      onStepChange={handleChangeStep}
    >
      <FormProvider {...policyForm}>
        <Typography variant="headline6">
          Adicionar política de viagem
        </Typography>

        {steps[policyRegisterWizard.activeStep]}
      </FormProvider>
    </Wizard>
  );
};
