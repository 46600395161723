'use strict';

import React from 'react';
import TravelRequestNavigationTemplate from './travel-request-navigation.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil.js';

export default class TravelRequestNavigationComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      identifierActiveItem: this.props.items[0].identifier,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <TravelRequestNavigationTemplate
          items={this.props.items ? this.props.items : null}
          identifierActiveItem={this.state.identifierActiveItem}
          isOffline={this.props.isOffline}
          events={this.events}
        />
      </ErrorBoundary>
    );
  }

  static getDerivedStateFromProps(props, state) {
    return {
      identifierActiveItem: props.identifierActiveItem
        ? props.identifierActiveItem
        : state.identifierActiveItem,
    };
  }

  events = {
    onItemClicked: (item, index) => {
      this.setState({
        identifierActiveItem: item.identifier,
      });

      if (this.props.onNavigationItemClick)
        this.props.onNavigationItemClick(item, index);
    },

    onAddItemClicked: () => {
      this.props.events &&
        this.props.events.onOpenSelectItemTypeClick &&
        this.props.events.onOpenSelectItemTypeClick();
    },
  };
}
