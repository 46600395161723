import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';
import { useCallback, useMemo, useState } from 'react';
import { TravelDataTableProps } from '../types';
import { useTableColumns } from './useTableColumns';

const INITIAL_PAGINATION: PaginationState = {
  pageNumber: 1,
  pageSize: 10,
};

export function useTravelDataTable<T>(props: TravelDataTableProps<T>) {
  const [pagination, setPagination] =
    useState<PaginationState>(INITIAL_PAGINATION);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Record<string, string[]>>({});

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e?.target?.value || '');
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  const handleFilters = useCallback((newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  const filteredItems = useMemo(() => {
    return props.rows.filter((item) => {
      // Aplicar filtros de pesquisa
      const matchesSearch = Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase()),
      );

      // Aplicar filtros específicos
      const matchesFilters = Object.entries(filters).every(([key, values]) => {
        // Acessa a propriedade correta do item e compara com os valores do filtro
        const itemValue = item[key]?.toString() ?? '';

        // Verifica se o itemValue está incluído nos valores do filtro
        return values.length === 0 || values.includes(itemValue);
      });

      return matchesSearch && matchesFilters;
    });
  }, [props.rows, search, filters]);

  const paginatedItems = useMemo(
    () =>
      filteredItems.slice(
        (pagination.pageNumber - 1) * pagination.pageSize,
        pagination.pageNumber * pagination.pageSize,
      ),
    [filteredItems, pagination],
  );

  const table = useTableColumns({
    data: paginatedItems,
    columns: props.columns,
    renderActions: props.renderActions,
    onPaginationChange: setPagination,
    pagination,
    // refetch: props.onRefetch,
  });

  return {
    filteredItems,
    pagination,
    search,
    isLoading: props.isLoading,
    table,
    handleSearch,
    handleFilters,
    setPagination,
    refetch: props.onRefetch,
  };
}
