import { TextAlign } from '../../../themes/types/Variants';
import * as SC from '../styled';

interface ModalHeaderTitleProps {
  /**
   * Title header
   *
   */
  children: React.ReactNode;
  /**
   * Header text alignment
   */
  textAlign?: TextAlign;
}
export function ModalHeaderTitle({
  children,
  textAlign,
}: ModalHeaderTitleProps) {
  return <SC.Title align={textAlign}>{children}</SC.Title>;
}
