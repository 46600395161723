import React from 'react';
import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';

const PAGE_SIZE_OPTIONS = [
  { label: '5 fluxos', value: 5 },
  { label: '10 fluxos', value: 10 },
  { label: '25 fluxos', value: 25 },
];

interface TablePaginationProps {
  filteredItemsCount: number;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
}

export function TablePagination({
  filteredItemsCount,
  pagination,
  setPagination,
}: TablePaginationProps) {
  if (filteredItemsCount <= pagination.pageSize) {
    return null;
  }

  return (
    <Table.Pagination
      count={filteredItemsCount}
      onPaginationChange={setPagination}
      pagination={pagination}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      showItemRange
    />
  );
}
