import {
  getLocationFormatted,
  getVehicleAdditionalsText,
} from '@legacy-utils/resolvers/vehicleResolver.ts';

const Template = {
  main: ({ booking }) => {
    const empty = '--';
    const originalRequest = booking?.vehicle?.originalRequest;

    return (
      <div className="exon-booking-vehicle-segments">
        <table className="exon-table-line" cellSpacing="0">
          <thead>
            <tr>
              <th>Locadora Preferencial</th>
              <th>Categoria</th>
              <th>Tipo</th>
              <th>Câmbio</th>
              <th>Adicionais</th>
              <th>Retirada</th>
              <th>Devolução</th>
              <th>Comentários</th>
            </tr>
          </thead>
          <tbody>
            {originalRequest && (
              <tr>
                <td>{originalRequest.rentalCompany || empty}</td>
                <td>{originalRequest.category || empty}</td>
                <td>{originalRequest.type || empty}</td>
                <td>{originalRequest.transmission || empty}</td>
                <td>{getVehicleAdditionalsText(originalRequest) || empty}</td>
                <td className="break-line">
                  {getLocationFormatted(
                    originalRequest.checkIn,
                    originalRequest.pickUpLocation,
                  ) || empty}
                </td>
                <td className="break-line">
                  {getLocationFormatted(
                    originalRequest.checkOut,
                    originalRequest.returnLocation,
                  ) || empty}
                </td>
                <td className="break-line">
                  {originalRequest.comments || empty}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  },
};

export default Template;
