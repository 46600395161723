import Popup from '../popup/popup.component.js';
import Select from '../select/select.component.js';
import Notifier from '../common/notifier/notifier.component.js';
import ProductSummary from '../product-summary/product-summary.component.js';
import { utils } from '../../utils/utils.js';
import { enums } from '../../utils/enums.js';
import useShoppingCartStore from '../../../store/ShoppingCart.store';

var ShoppingCartTemplate = {
  //Template principal "pai" dos demais componentes
  main: ({
    shoppingCart,
    counterAir,
    counterHotel,
    groupIndexSelected,
    selectGroupsListValue,
    warningSelectGroup,
    showMoveAllProducts,
    shoppingCartAllProducts,
    linkItemsFromGroups,
    enableProductCheckbox,
    displayMoveProducts,
    selectProps,
    onHideShoppingCartClick,
    onIncludeGroupClick,
    onDeleteProductClick,
    onDeleteGroupClick,
    onUpdateProductsClick,
    onSendBudgetClick,
    onSelectedGroupClick,
    onSelectedProductClick,
    onMoveProductClick,
    onMoveAllProductsClick,
    onSelectedMoveTripProductChange,
    onMoveProductConfimrClick,
    onHideMoveProductsClick,
    onRenameGroupChange,
    notifierProps,
    notifierEvents,
  }) => {
    const displayShoppingCart = useShoppingCartStore(
      (state) => state.displayShoppingCart,
    );

    return (
      <div className="exon-shopping-cart">
        {displayShoppingCart && (
          <Popup
            okButtonLabel={null}
            cancelButtonLabel={null}
            title={null}
            cancelButtonClick={onHideShoppingCartClick}
            exonStyle={true}
            okButtonClick={null}
          >
            <ShoppingCartTemplate.itensCart
              shoppingCart={shoppingCart}
              counterAir={counterAir}
              counterHotel={counterHotel}
              enableProductCheckbox={enableProductCheckbox}
              onIncludeGroupClick={onIncludeGroupClick}
              onDeleteProductClick={onDeleteProductClick}
              onDeleteGroupClick={onDeleteGroupClick}
              onUpdateProductsClick={onUpdateProductsClick}
              onSendBudgetClick={onSendBudgetClick}
              onSelectedGroupClick={onSelectedGroupClick}
              onSelectedProductClick={onSelectedProductClick}
              onMoveProductClick={onMoveProductClick}
              onMoveAllProductsClick={onMoveAllProductsClick}
              onSelectedMoveTripProductChange={onSelectedMoveTripProductChange}
              onRenameGroupChange={onRenameGroupChange}
            />
          </Popup>
        )}

        {displayMoveProducts && (
          <div className="exon-popup-secondary">
            <Popup
              okButtonLabel={'Vincular'}
              cancelButtonLabel={null}
              title={'Vincular itens'}
              exonStyle={true}
              cancelButtonClick={onHideMoveProductsClick}
              okButtonClick={onMoveProductConfimrClick}
            >
              <ShoppingCartTemplate.moveProduct
                selectProps={selectProps}
                linkItemsFromGroups={linkItemsFromGroups}
                counterAir={counterAir}
                counterHotel={counterHotel}
                shoppingCart={shoppingCart}
                groupIndexSelected={groupIndexSelected}
                selectGroupsListValue={selectGroupsListValue}
                warningSelectGroup={warningSelectGroup}
                showMoveAllProducts={showMoveAllProducts}
                shoppingCartAllProducts={shoppingCartAllProducts}
                onSelectedMoveTripProductChange={
                  onSelectedMoveTripProductChange
                }
              />
            </Popup>
          </div>
        )}

        {notifierProps.show && (
          <div className="budget-preview-container">
            <Popup
              okButtonLabel={'Confirmar'}
              cancelButtonLabel={'Cancelar'}
              title={'Orçamento'}
              exonStyle={true}
              cancelButtonClick={notifierEvents.onHidePopupClicked}
              okButtonClick={
                notifierEvents.onConfirmNotifyServiceRequestClicked
              }
            >
              <Notifier
                notificationType={notifierProps.notificationType}
                dataSource={notifierProps.dataSources}
                fieldValues={notifierProps.fieldsData}
                events={notifierEvents}
                showAddresseeName={true}
                placeholder={'email@empresa.com'}
              />
            </Popup>
          </div>
        )}
      </div>
    );
  },

  itensCart: ({
    shoppingCart,
    counterAir,
    counterHotel,
    enableProductCheckbox,

    onIncludeGroupClick,
    onDeleteProductClick,
    onDeleteGroupClick,
    onUpdateProductsClick,
    onSendBudgetClick,
    onSelectedGroupClick,
    onSelectedProductClick,
    onMoveProductClick,
    onMoveAllProductsClick,
    onSelectedMoveTripProductChange,
    onRenameGroupChange,
  }) => {
    const index = 0;
    return (
      <div className="exon-shopping-cart-products">
        <div className="exon-shopping-cart-header">
          <h3 className="exon-header-title">
            Carrinho
            <span className="exon-icon exon-icon-marketing-unfilled_enabled"></span>
          </h3>
          <div
            className={
              'exon-box-button-double-small' +
              (counterAir == 0 && counterHotel == 0 ? ' hide' : '')
            }
          >
            <button
              className="exon-button exon-button-primary"
              onClick={() => onUpdateProductsClick()}
            >
              <span className="exon-icon exon-icon-update-unfilled_enabled-white"></span>
              Atualizar valores
            </button>
          </div>
        </div>
        <div className="exon-box-groups-products">
          {shoppingCart &&
            shoppingCart.map((group, index) => {
              return (
                <ShoppingCartTemplate.groupProducts
                  key={index}
                  shoppingCart={shoppingCart}
                  counterAir={counterAir}
                  counterHotel={counterHotel}
                  products={group.products}
                  groupName={group.groupName}
                  travelers={group.travelers}
                  indexGroup={index}
                  enableProductCheckbox={enableProductCheckbox}
                  onDeleteProductClick={onDeleteProductClick}
                  onDeleteGroupClick={onDeleteGroupClick}
                  onSendBudgetClick={onSendBudgetClick}
                  onSelectedGroupClick={onSelectedGroupClick}
                  onSelectedProductClick={onSelectedProductClick}
                  onMoveProductClick={onMoveProductClick}
                  onMoveAllProductsClick={onMoveAllProductsClick}
                  onSelectedMoveTripProductChange={
                    onSelectedMoveTripProductChange
                  }
                  onRenameGroupChange={onRenameGroupChange}
                />
              );
            })}
        </div>
        <div className="exon-box-button-new-group">
          <button
            className="exon-button exon-button-secondary exon-button-secondary_enabled"
            onClick={() => onIncludeGroupClick()}
          >
            <span>+</span> Novo Grupo
          </button>
        </div>
      </div>
    );
  },

  groupProducts: ({
    counterAir,
    counterHotel,
    shoppingCart,
    products,
    groupName,
    indexGroup,
    enableProductCheckbox,
    travelers,

    onDeleteProductClick,
    onDeleteGroupClick,
    onSendBudgetClick,
    onSelectedGroupClick,
    onSelectedProductClick,
    onMoveProductClick,
    onMoveAllProductsClick,
    onSelectedMoveTripProductChange,
    onRenameGroupChange,
  }) => {
    return (
      <div className="exon-box-group">
        <div className="exon-box-group-header">
          <div className="exon-box-title-group">
            <input
              type="text"
              className="exon-input-edit"
              value={groupName}
              onChange={(ev) => onRenameGroupChange(ev, indexGroup)}
            />
            <span className="exon-icon exon-icon-edit-unfilled_enabled"></span>
          </div>
          <div>
            <span className="exon-travalers">
              {utils.traveler.travelersFormatted(travelers)}
            </span>
          </div>
          <div className="exon-group-actions">
            <div className="exon-box-button-double-small">
              <button
                className="exon-button exon-button-secondary exon-button-secondary_enabled"
                onClick={() => onSendBudgetClick(indexGroup)}
              >
                <span className="exon-icon exon-icon-envelope-unfilled_disabled"></span>
                Enviar Orçamento
              </button>
            </div>
            <div className="exon-box-button-double exon-box-button-double-small">
              <button
                className={
                  'exon-button exon-button-secondary' +
                  (shoppingCart.length == 1 ? ' hide' : '')
                }
                onClick={() => onDeleteGroupClick(indexGroup)}
              >
                <span className="exon-icon exon-icon-dump-unfilled_disabled"></span>
              </button>
              <button
                className="exon-button exon-button-primary"
                onClick={() => onSelectedGroupClick(indexGroup)}
              >
                Selecionar
              </button>
            </div>
          </div>
        </div>

        <ShoppingCartTemplate.productsList
          shoppingCart={shoppingCart}
          indexGroup={indexGroup}
          counterAir={counterAir}
          counterHotel={counterHotel}
          products={products}
          travelers={travelers}
          enableProductCheckbox={enableProductCheckbox}
          onDeleteProductClick={onDeleteProductClick}
          onSelectedProductClick={onSelectedProductClick}
          onMoveProductClick={onMoveProductClick}
          onMoveAllProductsClick={onMoveAllProductsClick}
          onSelectedMoveTripProductChange={onSelectedMoveTripProductChange}
        />
      </div>
    );
  },

  productsList: ({
    indexGroup,
    shoppingCart,
    products,
    travelers,
    counterAir,
    enableProductCheckbox,
    counterHotel,

    onDeleteProductClick,
    onSelectedProductClick,
    onMoveProductClick,
    onMoveAllProductsClick,
    onSelectedMoveTripProductChange,
  }) => {
    return (
      <div className="exon-box-products">
        <div className={counterAir == 0 && counterHotel == 0 ? 'hide' : ''}>
          <button
            className={
              'exon-button-add' +
              (products.travels.length > 0 || products.hotels.length > 0
                ? ' hide'
                : '')
            }
            onClick={() => onMoveAllProductsClick(indexGroup)}
          >
            <span>+</span>
            Vincular Item
          </button>
        </div>

        {products.travels.length > 0 &&
          products.travels.map((product, index) => {
            let enableButtonMoveProduct = shoppingCart.length <= 1;
            let actions = (
              <div>
                <div className="exon-box-button-double exon-box-button-double-small">
                  <button
                    className="exon-button exon-button-secondary exon-button-secondary_enabled"
                    onClick={() =>
                      onDeleteProductClick(
                        product.groupIndex,
                        product.productIndex,
                        enums.productType.air,
                      )
                    }
                  >
                    <span className="exon-icon exon-icon-dump-unfilled_disabled"></span>
                  </button>
                  <button
                    className="exon-button exon-button-primary"
                    onClick={() =>
                      onSelectedProductClick(
                        product.groupIndex,
                        product.productIndex,
                        enums.productType.air,
                      )
                    }
                  >
                    Selecionar
                  </button>
                </div>
                <span
                  className={
                    'exon-icon exon-icon-multipleArrow-unfilled_enabled' +
                    (enableButtonMoveProduct ? ' hide' : '')
                  }
                  onClick={() =>
                    onMoveProductClick(
                      product.groupIndex,
                      product.productIndex,
                      enums.productType.air,
                    )
                  }
                ></span>
              </div>
            );

            return (
              <ProductSummary
                key={index}
                productType={enums.productType.air}
                product={product}
                hasPreBooking={
                  shoppingCart[index]
                    ? shoppingCart[index].hasPreBooking
                    : false
                }
                travelers={travelers}
                enableProductCheckbox={enableProductCheckbox}
                productActions={actions}
                onSelectedMoveTripProductChange={
                  onSelectedMoveTripProductChange
                }
              />
            );
          })}

        {products.hotels.length > 0 &&
          products.hotels.map((product, index) => {
            let enableButtonMoveProduct = shoppingCart.length <= 1;
            let actions = (
              <div>
                <div className="exon-box-button-double exon-box-button-double-small">
                  <button
                    className="exon-button exon-button-secondary exon-button-secondary_enabled"
                    onClick={() =>
                      onDeleteProductClick(
                        product.groupIndex,
                        product.productIndex,
                        enums.productType.hotel,
                      )
                    }
                  >
                    <span className="exon-icon exon-icon-dump-unfilled_disabled"></span>
                  </button>
                  <button
                    className="exon-button exon-button-primary"
                    onClick={() =>
                      onSelectedProductClick(
                        product.groupIndex,
                        product.productIndex,
                        enums.productType.hotel,
                      )
                    }
                  >
                    Selecionar
                  </button>
                </div>
                <span
                  className={
                    'exon-icon exon-icon-multipleArrow-unfilled_enabled' +
                    (enableButtonMoveProduct ? ' hide' : '')
                  }
                  onClick={() =>
                    onMoveProductClick(
                      product.groupIndex,
                      product.productIndex,
                      enums.productType.hotel,
                    )
                  }
                ></span>
              </div>
            );

            return (
              <ProductSummary
                key={index}
                productType={enums.productType.hotel}
                product={product}
                enableProductCheckbox={enableProductCheckbox}
                productActions={actions}
                onSelectedMoveTripProductChange={
                  onSelectedMoveTripProductChange
                }
              />
            );
          })}
      </div>
    );
  },

  moveProduct: ({
    selectProps,
    linkItemsFromGroups,
    shoppingCart,
    groupIndexSelected,
    selectGroupsListValue,
    warningSelectGroup,
    counterAir,
    counterHotel,
    showMoveAllProducts,
    shoppingCartAllProducts,
    onSelectedMoveTripProductChange,
  }) => {
    let currentProducts = showMoveAllProducts
      ? shoppingCartAllProducts
      : shoppingCart[groupIndexSelected].products;

    return (
      <div>
        <div
          className={
            'exon-select-simple' + (linkItemsFromGroups ? ' hide' : '')
          }
        >
          <label
            className={
              'exon-label-field' +
              (selectGroupsListValue == 0 && warningSelectGroup
                ? ' exon-label-field-alert'
                : '')
            }
          >
            {' '}
            Selecione o grupo que deseja vincular{' '}
          </label>
          <Select
            data={selectProps.data}
            placeholder={selectProps.placeholder}
            externalOptions={selectProps.options}
          />
        </div>
        <label className="exon-label-field">
          {' '}
          Selecione os itens que deseja vincular ao grupo selecionado{' '}
        </label>
        <hr />

        <ShoppingCartTemplate.productsList
          indexGroup={groupIndexSelected}
          shoppingCart={shoppingCart}
          products={currentProducts}
          counterAir={counterAir}
          counterHotel={counterHotel}
          enableProductCheckbox={true}
          onDeleteProductClick={null}
          onSelectedProductClick={null}
          onSelectedMoveTripProductChange={onSelectedMoveTripProductChange}
          onMoveProductClick={null}
        />
      </div>
    );
  },
};

export default ShoppingCartTemplate;
