'use strict';

import React from 'react';
import _ from 'lodash';
import { componentUtil } from '../../utils/componentUtil.js';
import { compressorResolver } from '../../utils/resolvers/compressorResolver.js';
import { utils, gritter } from '../../utils/utils.js';
import { airTrip } from '../../utils/resolvers/airTripResolver.js';
import { fareMapping } from '../../utils/fareMapping.js';
import { apiResponse } from '../../utils/apiResponse.js';
import { enums } from '../../utils/enums.js';
import { formatterUtil } from '../../utils/formatter.js';
import { Loading } from '@components/Loading/Loading';

import ErrorBoundary from '../common/error-boundary/error-boundary.component.js';
import QuotesTemplate from './quotes.template.js';
import useSelectedCompanyStore from '../../../store/SelectedCompany.store';

import FeedbackComponent from '../common/feedback/feedback.component.js';

export default class QuotesComponent extends React.Component {
  widthToApplyTreatment = 1600;

  externalOptions = {
    dataSources: {
      locations: null,
      airClasses: null,
      airLines: null,
      airSearch: null,
      budget: null,

      hotels: null,
      destination: null,
      hotelsRoomDetails: null,
      hotelDetails: null,
      preBooking: null,
      travelers: null,

      companies: null,
      requester: null,
    },
    agency: null,
  };

  itensPerPage = 5;

  shoppingCart = [
    {
      groupName: 'Grupo 1',
      products: {
        travels: [],
        hotels: [],
      },
      travelers: null,
    },
  ];

  initialStateValues = {
    searches: {},
    shoppingCart: this.shoppingCart,
    productsToSendCheckout: [],
    displayConfirmSendproductsCheckout: false,
    displayCheckout: false,
    engineSelected: 'air',
    activeTabIdentifier: '', // Identificador da aba ativa
    tripPartSelectedIndex: 0,
    applyOrder: false,
    allTariffedPriceProducts: null,

    checkoutData: {},
    searchIdIncludeCheckout: [],

    stopCountingTariffedPriceExpiration: false,
    attemptsOfTariffedPrice: 0,
    popupWarning: {},
    displayPopupWarning: false,
    timeToExpirePreCheckout: '00:59',
    searchFilters: {},
  };

  airlinesLoadPromise = null;

  constructor(props) {
    super(props);

    //this.externalOptions = {...this.externalOptions,...props.externalOptions}; //$.extend(true, this.externalOptions, props.externalOptions);
    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    };

    for (let component in this.components) {
      let componentFunctions = this.components[component];
      componentUtil.react.bindMethods(componentFunctions, this);
    }

    componentUtil.react.bindMethods(this.searchManager, this);
    componentUtil.react.bindMethods(this.hotelDetails, this);
    componentUtil.react.bindMethods(this.shoppingCartManager, this);
    componentUtil.react.bindMethods(this.itensOrder, this);
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.dataSources.airSearch, this);
    componentUtil.react.bindMethods(this.dataSources.checkout, this);
    componentUtil.react.bindMethods(this.dataSources.airLines, this);
    componentUtil.react.bindMethods(this.dataSources.hotel, this);
    componentUtil.react.bindMethods(this.checkoutManager, this);
    componentUtil.react.bindMethods(this.airFunctions, this);

    let initialStateValues = this.getInitialStateValues();

    this.state = {
      ...initialStateValues,
      loading: false,
      airlines: [],
      showFilter: document.body.clientWidth >= this.widthToApplyTreatment,
      displaySelectedCompany: false,
      popupAgency: {
        title: 'Cotação',
        cancelButtonLabel: null,
        cancelButtonClick: this.events.onPopupCloseAgencyClicked,
        okButtonLabel: 'Aplicar',
        okButtonClick: this.events.onPopupSelectCompanyClicked,
      },
      companySelected: { value: 0 },
      activeCompany: { value: 0, label: '' },
      optionsCompanies: [],
      searchedHotelName: null,
      popupFeedbackData: {
        show: false,
      },
      currentUserData: this.externalOptions.user,
    };
  }

  initializeComponent = () => {
    const { agency } = this.props.externalOptions;
    const selectedCompany = useSelectedCompanyStore.getState().companySelected;
    if (agency.isAgency && agency.travelProfile === 4) {
      this.dataSources.companies.load();
      this.setState({ displaySelectedCompany: true });

      // Verifique se uma empresa já está selecionada
      if (!selectedCompany) {
        this.openCompanyPopup();
      }
    } else {
      this.closeCompanyPopup();
      this.setState({ displaySelectedCompany: false });
    }

    this.dataSources.airLines.load();
  };

  componentDidMount() {
    this.initializeComponent();
    // Para atualizar o estado do componente
    this.unsubscribe = useSelectedCompanyStore.subscribe(
      (displayPopupAgency) => this.setState({ displayPopupAgency }),
      (state) => state.displayPopupAgency,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.externalOptions.user.company.contextId !==
      this.props.externalOptions.user.company.contextId
    ) {
      this.setInitialStateValues();
      this.initializeComponent();
      this.setState({
        currentUserData: this.props.externalOptions.user,
        activeCompany: { value: 0, label: '' },
      });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  toggleModalLoading = (loading) => {
    this.setState({ loading });
  };

  openCompanyPopup = () => {
    useSelectedCompanyStore.getState().setDisplayPopupAgency(true);
  };

  closeCompanyPopup = () => {
    useSelectedCompanyStore.getState().setDisplayPopupAgency(false);
  };

  setSelectedCompany = (company) => {
    useSelectedCompanyStore.getState().setCompany(company);
    this.closeCompanyPopup();
  };

  handleAlterCompanyClick = () => {
    this.setState({
      popupAgency: {
        title: 'Cotação',
        cancelButtonLabel: null,
        cancelButtonClick: this.events.onPopupCloseAgencyClicked,
        okButtonLabel: 'Aplicar',
        okButtonClick: this.events.onPopupSelectCompanyClicked,
      },
    });
    this.openCompanyPopup();
  };

  render() {
    const displayPopupAgency =
      useSelectedCompanyStore.getState().displayPopupAgency;
    const searches = this.state.searches;
    const identifier = this.state.activeTabIdentifier;
    const search = searches[identifier];
    const tripPartSelectedIndex = search ? search.tripPartSelectedIndex : '';
    const fullFlightOptions =
      search &&
      search.productType == 'air' &&
      search.results[tripPartSelectedIndex]
        ? search.results[tripPartSelectedIndex].fullFlightOptions
        : undefined;
    const numberOfItems = fullFlightOptions
      ? fullFlightOptions.length
      : search && search.results
        ? search.results.length
        : 0;
    const selectedTabSearch = searches[identifier];
    const selectedTabSearchResults =
      (selectedTabSearch &&
        selectedTabSearch.productType == 'air' &&
        selectedTabSearch.results[selectedTabSearch.tripPartSelectedIndex]) ||
      null;

    const _this = this;
    document.body.onresize = function () {
      if (
        document.body.clientWidth <= _this.widthToApplyTreatment &&
        _this.state.showEngine == true
      ) {
        _this.setState({
          showFilter: false,
          showEngine: false,
        });
      } else if (
        document.body.clientWidth > _this.widthToApplyTreatment &&
        _this.state.showFilter == false
      ) {
        _this.setState({
          showFilter: true,
          showEngine: false,
        });
      }
    };

    return (
      <ErrorBoundary>
        {this.state.loading && <Loading />}
        <QuotesTemplate.main
          searches={searches}
          identifierNameFilter={
            (selectedTabSearchResults &&
              selectedTabSearchResults.placeholderIdentifier) ||
            ''
          }
          airSearchEngineProps={this.components.airSearchEngine.getProps()}
          hotelSearchEngineProps={this.components.hotelSearchEngine.getProps()}
          hotelsSearchResultsProps={this.components.hotelsSearchResults.getProps()}
          tabsProps={this.components.tabs.getProps()}
          activeTabIdentifier={identifier}
          engineSelected={this.state.engineSelected}
          showEngine={this.state.showEngine}
          numberOfItems={numberOfItems}
          displayCheckout={this.state.displayCheckout}
          displayConfirmSendproductsCheckout={
            this.state.displayConfirmSendproductsCheckout
          }
          displayLoaderResults={this.state.displayLoaderResults}
          allTariffedPriceProducts={this.state.allTariffedPriceProducts}
          displayPopupWarning={this.state.displayPopupWarning}
          popupWarning={this.state.popupWarning}
          searchExpire={this.events.searchExpire}
          onTripClicked={this.events.onTripClicked}
          onShowEngineClicked={this.events.onShowEngineClicked}
          onAirSearchResultFilterApplyFiltersClicked={
            this.events.onApplyFiltersClicked
          }
          onHotelSearchResultFilterApplyFiltersClicked={
            this.events.onHotelApplyFiltersClick
          }
          onSelectEngineClick={this.events.onSelectEngineClicked}
          onPageClick={this.events.onPageClicked}
          onOrderAirSearchResultClick={
            this.events.onOrderAirSearchResultClicked
          }
          onShowFaresIndexesClick={this.events.onShowFaresIndexesClicked}
          onSearchEngineRadioChanged={this.events.onSearchEngineRadioChanged}
          onSelectedProductAirSearchResultClick={
            this.shoppingCartManager.includeAirProducts
          }
          onSelectedProductHotelSearchResultClick={
            this.shoppingCartManager.includeHotelProducts
          }
          onClearTripMaganerItemClick={
            this.events.onClearTripMaganerItemClicked
          }
          onFeedbackPopupCloseClick={this.events.onFeedbackPopupCloseClicked}
          onOpenHotelDetailsClick={this.events.onOpenHotelDetailsClicked}
          onShowFilterClicked={this.events.onShowFilterClicked}
          showFilter={this.state.showFilter}
          onHotelSearchByIdClick={this.events.onHotelSearchByIdClicked}
          onSearchHotelDetailsClick={this.events.onSearchHotelDetailsClicked}
          onHotelOptionClick={this.events.onHotelOptionClicked}
          //Envio para checkout
          checkoutData={this.state.checkoutData}
          onTryGetTariffedPriceClick={this.events.onTryGetTariffedPriceClicked}
          timeToExpirePreCheckout={this.state.timeToExpirePreCheckout}
          checkoutProps={{
            dataSources: {
              licenceFeatures: this.externalOptions.dataSources.licenceFeatures,
              companySettings: this.externalOptions.dataSources.companySettings,
              nationality: this.externalOptions.dataSources.nationality,
              processBooking: this.dataSources.processBooking,
              justificationsList:
                this.externalOptions.dataSources.justificationsList,

              travelers: this.dataSources.checkout.travelers,
              costCenter: this.dataSources.checkout.costCenter,
              paymentForms: this.dataSources.checkout.paymentForms,
              customField: this.dataSources.checkout.customField,
              travelReason: this.dataSources.checkout.travelReason,
              requester: this.dataSources.checkout.requester,
              serviceRequest: this.externalOptions.dataSources.serviceRequest,
              preBooking: this.externalOptions.dataSources.preBooking,
              hotelsRoomDetails:
                this.components.hotelsSearchResults.getProps().options
                  .dataSources.hotelsRoomDetails,
            },
            data: this.state.checkoutData,
            user: this.externalOptions.user,
          }}
          productsToSendCheckout={this.state.productsToSendCheckout}
          onCancelSendCheckoutProductsClick={
            this.events.onCancelSendCheckoutProductsClicked
          }
          onConfirmSendCheckoutProductsClick={
            this.events.onConfirmSendCheckoutProductsClicked
          }
          onSendSelectedProductCheckoutAirResultClick={
            this.checkoutManager.includeProductToSendCheckout
          }
          onSendSelectedProductCheckoutHotelResultClick={
            this.checkoutManager.includeHotelProductToSendCheckout
          }
          onSelectedGroupShoppingCartClick={
            this.checkoutManager.includeGroupProductToSendCheckout
          }
          porductListSendToCheckout={this.state.porductListSendToCheckout}
          //ShoppingCart
          shoppingCart={this.state.shoppingCart}
          budgetDataSource={this.dataSources.budget}
          onIncludeGroupShoppingCartClick={
            this.shoppingCartManager.includeGroup
          }
          onIncludeProductShoppingCartClick={
            this.shoppingCartManager.includeAirProducts
          }
          onDeleteProductShoppingCartClick={
            this.shoppingCartManager.deleteProduct
          }
          onDeleteGroupShoppingCartClick={this.shoppingCartManager.deleteGroup}
          onUpdateProductsShoppingCartClick={
            this.shoppingCartManager.updateProducts
          }
          onSendBudgetShoppingCartClick={this.shoppingCartManager.sendBudget}
          onSelectedProductShoppingCartClick={
            this.events.onIncludeProductCheckoutShoppingCartClicked
          }
          onMoveProductShoppingCartClick={this.shoppingCartManager.moveProduct}
          onSelectedMoveTripProductShoppingCartChange={
            this.shoppingCartManager.onSelectedMoveTripProductChanged
          }
          onRenameGroupShoppingCartChange={this.shoppingCartManager.renameGroup}
          onHideMoveProductsShoppingCartClick={
            this.shoppingCartManager.onHideMoveProductsClicked
          }
          onMoveProductConfimrShoppingCartClick={
            this.shoppingCartManager.onMoveProductConfimrShoppingCartClicked
          }
          onMoveAllProductsShoppingCartClick={
            this.shoppingCartManager.onMoveAllProduct
          }
          onCloseCheckoutClick={this.events.onCloseCheckoutClicked}
          onAllTripsSelected={this.events.onAllTripsSelected}
          onRedoSearchClick={this.events.onRedoSearchClicked}
          onDeleteTabAndSearchClick={this.events.onDeleteTabAndSearchClicked}
          onTryAgainTariffedPriceMultipleTripClick={
            this.events.onTryAgainTariffedPriceMultipleTripClicked
          }
          // PopupAgency
          agencyAndCompany={{
            agency: this.props.externalOptions.agency,
            company: this.state.activeCompany,
          }}
          popupAgency={this.state.popupAgency}
          displayPopupAgency={displayPopupAgency}
          displaySelectedCompany={this.state.displaySelectedCompany}
          companiesProps={this.components.companiesProps.getProps()}
          onAlterCompanyClicked={this.handleAlterCompanyClick}
          searchedHotelName={this.state.searchedHotelName}
          popupFeedbackData={this.state.popupFeedbackData}
          navigate={this.props.navigate}
        />
      </ErrorBoundary>
    );
  }

  components = {
    airSearchEngine: {
      getProps: function () {
        let _this = this;
        return {
          options: {
            events: {
              onSearchClicked: function (data) {
                let identifier = _this.generateIdentifier();
                let apiRequest = _this.convertAirSearchDataToApiRequest(
                  data,
                  identifier,
                );
                let dataApiRequest = apiRequest.dataApiRequest;
                let tripType = apiRequest.tripType;
                let productType = 'air';

                _this.searchManager.include(identifier, data, productType);
                _this.dataSources.airSearch.callLoad({
                  request: dataApiRequest,
                  identifier: identifier,
                });
              },
            },
            dataSources: {
              locations: _this.externalOptions.dataSources.locations,
            },
            airlines: _this.state.airlines,
          },
        };
      },
    },

    hotelSearchEngine: {
      getProps: function () {
        let _this = this;
        return {
          options: {
            events: {
              onSearchClicked: function (data) {
                let identifier = _this.generateIdentifier();
                let productType = 'hotel';
                const dataLocal = { ...data };

                _this.searchManager.include(identifier, dataLocal, productType);

                _this.dataSources.hotel.search(identifier, dataLocal);

                _this.setState({
                  searchedHotelName: data.searchViewParameters.filter.hotelName,
                });
              },
            },
            dataSources: {
              destination: _this.externalOptions.dataSources.destination,
              locations: _this.externalOptions.dataSources.pointOfInterest,
            },
          },
        };
      },
    },
    hotelsSearchResults: {
      getProps: function () {
        return {
          options: {
            events: {
              onOrderHotelSearchResultClicked: (sortingData) => {
                const identifier = this.state.activeTabIdentifier;
                const searches = this.state.searches;
                const search = searches[identifier];

                if (
                  JSON.stringify(
                    search.searchParameters.searchViewParameters.sortingData,
                  ) === JSON.stringify(sortingData)
                ) {
                  return;
                }

                search.loading = true;

                search.searchParameters.searchViewParameters.searchId =
                  search.originalResults.resultResume.searchId;

                search.searchParameters.searchViewParameters.sortingData =
                  sortingData;

                this.setState({ searches }, () => {
                  this.dataSources.hotel.search(
                    identifier,
                    search.searchParameters,
                  );
                });
              },
            },
            dataSources: {
              //hotelsRoomDetails: _this.externalOptions.dataSources.hotelsRoomDetails
              hotelsRoomDetails: this.dataSources.hotel.getRoomDetails,
            },
          },
        };
      }.bind(this),
    },
    tabs: {
      getProps: function () {
        let _this = this;
        return {
          tabs: _this.state.tabs,
          options: {
            events: {
              onTabClicked: function (identifier) {
                _this.activeTab(identifier);
              },
              onTabDeleted: function (identifier, index) {
                _this.searchManager.delete(identifier, index);
              },
            },
          },
        };
      },
    },

    companiesProps: {
      getProps: function () {
        let _this = this;
        let companies = _this.state.optionsCompanies.sort((a, b) => {
          if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          }
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          }
          return 0;
        });

        return {
          data: _this.state.companySelected,
          placeholder: 'Selecione o cliente',
          options: {
            items: companies,
            dataSource: {
              load: function () {
                return Promise.resolve(companies);
                // var deferred = new $.Deferred();
                // deferred.resolve(companies);
                // return deferred.promise();
              },
            },
            events: {
              onSelected: function (selectedItems) {
                _this.setState({
                  companySelected: selectedItems,
                });
              },
            },
          },
        };
      },
    },
  };

  dataSources = {
    locations: {
      load: function (loadOptions) {
        return new Promise((resolve) => {});
      },
      events: {
        beforeLoad: function (loadOptions) {},
        afterLoad: function (items) {},
      },
    },
    airClasses: {
      load: function (loadOptions) {
        return Promise.resolve([]);
      },
      events: {
        beforeLoad: function (loadOptions) {},
        afterLoad: function (items) {},
      },
    },
    airLines: {
      load: function (loadOptions) {
        const _this = this;
        _this.airlinesLoadPromise =
          this.externalOptions.dataSources.airLines.load();

        _this.airlinesLoadPromise.then((airlines) => {
          _this.setState({
            airlines,
          });
        });
      },
      events: {
        beforeLoad: function (loadOptions) {},
        afterLoad: function (items) {},
      },
    },
    airSearch: {
      callLoad: function (loadOptions) {
        let _this = this;
        let dataSource = this.externalOptions.dataSources.airSearch;

        if (!loadOptions) loadOptions = {};

        let company = this.state.companySelected;
        if (
          this.props.externalOptions.agency.isAgency &&
          this.props.externalOptions.agency.travelProfile === 4 &&
          company.value !== 0
        )
          loadOptions.contextId = company.value;

        if (dataSource) {
          if (dataSource.events && dataSource.events.beforeLoad)
            dataSource.events.beforeLoad(loadOptions);

          dataSource
            .load(loadOptions)
            .then((response) => {
              let identifier = loadOptions.identifier;
              let results = _this.convertApiResponseToAirResultData(
                response,
                identifier,
              ); //TODO: Converter o retorno da api pro objeto do componente de resultados do aéreo
              let optimizedResponse = { mock: response.mock }; //this.getOptimizedAPIResponse(response);

              _this.searchManager.update(
                identifier,
                results,
                response.summary && response.summary.searchId,
              );

              if (dataSource.events && dataSource.events.afterLoad)
                dataSource.events.afterLoad(loadOptions);
            })
            .catch((error) => {
              console.error(error);
              this.searchManager.delete(loadOptions.identifier);
            });
        }
      },
    },
    hotel: {
      search: (identifier, paramSearchData) => {
        if (paramSearchData) {
          if (!this.state.searches[identifier].searchHotelName) {
            delete paramSearchData.searchViewParameters.filter.hotelName;
            delete paramSearchData.searchViewParameters.filter.externalCode;
          }

          if (!this.state.searches[identifier].searchConnectionSource)
            delete paramSearchData.searchViewParameters.filter.connectionSource;

          if (!this.state.searches[identifier].searchGeoLocation)
            delete paramSearchData.searchViewParameters.filter.geoLocation;
        } else {
          paramSearchData = {};
        }

        let company = this.state.companySelected;
        if (
          this.props.externalOptions.agency.isAgency &&
          this.props.externalOptions.agency.travelProfile === 4 &&
          company.value !== 0
        )
          paramSearchData.contextId = company.value;

        this.externalOptions.dataSources.hotels
          .load(paramSearchData)
          .then((response) => {
            if (
              response.successful ||
              (response.hotels && response.hotels.length > 0)
            ) {
              var result = this.convertApiResponseToHotelResultData(
                response,
                paramSearchData,
                identifier,
              );
              let optimizedResponse = {};

              this.searchManager.update(
                identifier,
                result,
                response?.summary?.searchId,
              );
            } else {
              let originalResume =
                this.state.searches[identifier].originalResults.resultResume;
              var result = {
                resultResume: {
                  totalResults: 0,
                  prices: originalResume ? originalResume.prices : null,
                  stars: originalResume ? originalResume.stars : null,
                  meals: originalResume ? originalResume.meals : null,
                  providers: originalResume ? originalResume.providers : null,
                },
                results: [],
              };

              this.searchManager.update(identifier, result, {});
            }
          })
          .catch((error) => {
            console.error(error);
            this.searchManager.delete(identifier);
          });
      },
      searchById: (identifier, paramSearchData) => {
        let _this = this;

        if (!paramSearchData) paramSearchData = {};

        if (paramSearchData.searchViewParameters)
          paramSearchData.searchViewParameters.searchId = null;

        paramSearchData.searchId = null;

        let company = this.state.companySelected;
        if (
          this.props.externalOptions.agency.isAgency &&
          this.props.externalOptions.agency.travelProfile === 4 &&
          company.value !== 0
        )
          paramSearchData.contextId = company.value;

        this.externalOptions.dataSources.hotels
          .load(paramSearchData)
          .then((response) => {
            let successful = response.successful;
            let result = this.convertApiResponseToHotelResultData(
              response,
              paramSearchData,
              identifier,
            );

            if (!response.hotels || response.hotels.length == 0) {
              response.successful = false;
              apiResponse.successful.showErrorIfExists(
                response,
                'Não encontramos quartos disponíveis',
              );
            }

            _this.hotelDetails.update(
              identifier,
              result,
              paramSearchData,
              true,
            );

            response.successful = successful;
          });
      },
      getHotelDetails: (loadOptions) => {
        let _this = this;
        let dataSource = this.externalOptions.dataSources.hotelDetails;
        let company = this.state.companySelected;

        loadOptions ? loadOptions : {};

        if (
          this.props.externalOptions.agency.isAgency &&
          this.props.externalOptions.agency.travelProfile === 4 &&
          company.value !== 0
        ) {
          loadOptions.contextId = company.value;
          loadOptions.searchParameters.contextId = company.value;
        }

        if (dataSource) {
          if (dataSource.events && dataSource.events.beforeLoad)
            dataSource.events.beforeLoad(loadOptions);

          dataSource
            .load(loadOptions)
            .then((response) => {
              let identifier = loadOptions.identifier;
              _this.hotelDetails.update(
                identifier,
                response,
                loadOptions.searchParameters,
                false,
              );
            })
            .catch((error) => {
              console.error(error);
              //this.searchManager.delete(loadOptions.identifier);
              apiResponse.http.showErrorIfExists(
                error,
                'Ocorreu um erro ao obter os dados do hotel, tente novamente em instantes e caso o erro persista contate o suporte.',
              );
            });
        }
      },
      getRoomDetails: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.hotelsRoomDetails.load(
            loadOptions,
          );
        },
      },
    },
    preBooking: {
      callPreBooking: (data, searchIdentifier, multipleTrips) => {
        let request;
        let _this = this;
        if (!multipleTrips) {
          //Converter dado do componente no request do pre-booking
          let searches = this.state.searches;
          searches[searchIdentifier].displayLoaderTariffedPrice = true;

          request = this.convertToPreBookingRequest(data, searchIdentifier);
          this.setState({
            searches: searches,
            stopCountingTariffedPriceExpiration: false,
          });
        } else {
          request = data;
        }

        let company = this.state.companySelected;
        if (
          this.props.externalOptions.agency.isAgency &&
          this.props.externalOptions.agency.travelProfile === 4 &&
          company.value !== 0
        )
          request.contextId = company.value;

        let dataSource = this.externalOptions.dataSources.preBooking;
        dataSource
          .load(request)
          .then((response) => {
            if (!response.successful) {
              const feedbackData =
                FeedbackComponent.ConvertMessagesResponseToFeedbackObj(
                  response,
                );
              const popupFeedbackData = {
                ...this.state.popupFeedbackData,
                show: true,
                ...feedbackData,
                description:
                  feedbackData.description ||
                  'Ocorreu um erro no momento da tarifação.',
              };

              this.setState({ popupFeedbackData });
            }

            let result = this.convertPreBookingResponseToFareResult(response);
            let checkoutData = this.state.checkoutData;
            let responseData = response;

            checkoutData.compressedSearchResult =
              _this.includeCompressedSearchResultAirProcessBookingData(request);

            if (multipleTrips) {
              this.checkoutManager.startCountingTariffedPrice();
              checkoutData.productsData.allTariffedPrice = result;
            } else {
              this.state.searches[searchIdentifier].tariffedPriceFareResult =
                result;
            }

            checkoutData.preBookingData = responseData;
            checkoutData.searchData = request.searchData;

            this.setState({
              checkoutData: checkoutData,
            });
          })
          .finally(() => {
            if (!multipleTrips) {
              let searches = this.state.searches;
              searches[searchIdentifier].displayLoaderTariffedPrice = false;
              this.setState({
                searches: searches,
                timeToExpirePreCheckout: '00:59',
              });
            }
          });
      },
    },
    processBooking: {
      callProcessBooking: (loadOptions) => {
        let company = this.state.companySelected;
        if (
          this.props.externalOptions.agency.isAgency &&
          this.props.externalOptions.agency.travelProfile === 4 &&
          company.value !== 0
        )
          loadOptions.contextId = company.value;

        return this.externalOptions.dataSources.processBooking.load(
          loadOptions,
        );
      },
    },
    companies: {
      load: (companyId) => {
        const _this = this;

        const dataSource = this.externalOptions.dataSources.companies;

        if (dataSource.events && dataSource.events.beforeLoad)
          dataSource.events.beforeLoad();

        dataSource
          .load(companyId)
          .then((result) => {
            let popupAgency = this.state.popupAgency;
            popupAgency.title = 'Cotação';
            popupAgency.cancelButtonLabel = null;
            popupAgency.cancelButtonClick =
              _this.events.onPopupCloseAgencyClicked;
            popupAgency.okButtonLabel = 'Aplicar';
            popupAgency.okButtonClick =
              _this.events.onPopupSelectCompanyClicked;

            this.setState({
              optionsCompanies: result,
              popupAgency: popupAgency,
            });
            this.openCompanyPopup();
            if (dataSource.events && dataSource.events.afterLoad)
              dataSource.events.afterLoad(result);
          })
          .catch((error) => {
            console.error(error);
            if (dataSource.events && dataSource.events.afterLoad)
              dataSource.events.afterLoad(error);
          });
      },
    },
    checkout: {
      travelers: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.travelers.load(loadOptions);
        },
        insert: (traveler) => {
          if (!traveler) traveler = {};

          let company = this.state.companySelected;
          if (
            !traveler.contextId &&
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            traveler.contextId = company.value;

          return this.externalOptions.dataSources.travelers.insert(
            traveler,
            company.value,
          );
        },
        update: (traveler) => {
          if (!traveler) traveler = {};

          let company = this.state.companySelected;
          if (
            !traveler.contextId &&
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            traveler.contextId = company.value;

          return this.externalOptions.dataSources.travelers.update(
            traveler,
            company.value,
          );
        },
        events: {
          beforeLoad: () => {},
          afterLoad: () => {},
          beforeInsert: () => {
            this.toggleModalLoading(true);
          },
          afterInsert: () => {
            this.toggleModalLoading(false);
          },
          beforeUpdate: () => {
            this.toggleModalLoading(true);
          },
          afterUpdate: () => {
            this.toggleModalLoading(false);
          },
        },
      },
      costCenter: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.costCenter.load(loadOptions);
        },
      },
      paymentForms: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.paymentForms.load(
            loadOptions,
          );
        },
        loadAllowedPaymentTypes: () => {
          let contextId = null;
          let company = this.state.companySelected;
          if (
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            contextId = company.value;

          const dataSource = this.externalOptions.dataSources.paymentForms;
          dataSource
            .loadAllowedPaymentTypes(contextId)
            .then((result) => {
              let checkoutData = this.state.checkoutData;
              checkoutData.allowedPaymentTypes = result;
              this.setState({ checkoutData });
            })
            .catch((result) => {
              gritter.Error(
                'Ocorreu um erro ao obter os tipos de pagamentos habilitados na empresa.',
              );
            });
        },
        events: {
          beforeLoad: () => {
            this.externalOptions.dataSources.paymentForms.beforeLoad();
          },
          afterLoad: () => {
            this.externalOptions.dataSources.paymentForms.afterLoad();
          },
        },
      },
      customField: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.customField.load(loadOptions);
        },
        events: {
          beforeLoad: () => {
            this.externalOptions.dataSources.customField.beforeLoad();
          },
          afterLoad: () => {
            this.externalOptions.dataSources.customField.afterLoad();
          },
        },
      },
      travelReason: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.travelReason.load(
            loadOptions,
          );
        },
      },
      requester: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.props.externalOptions.agency.isAgency &&
            this.props.externalOptions.agency.travelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.requester.load(loadOptions);
        },
      },
    },
    budget: {
      load: (loadOptions) => {
        if (!loadOptions) loadOptions = {};

        let company = this.state.companySelected;
        let contextId = null;
        if (
          this.props.externalOptions.agency.isAgency &&
          this.props.externalOptions.agency.travelProfile === 4 &&
          company.value !== 0
        )
          contextId = company.value;

        return this.externalOptions.dataSources.budget.load(
          loadOptions,
          contextId,
        );
      },
      send: (loadOptions) => {
        if (!loadOptions) loadOptions = {};

        let company = this.state.companySelected;
        let contextId = null;
        if (
          this.props.externalOptions.agency.isAgency &&
          this.props.externalOptions.agency.travelProfile === 4 &&
          company.value !== 0
        )
          contextId = company.value;

        return this.externalOptions.dataSources.budget.send(
          loadOptions,
          contextId,
        );
      },
      events: {
        beforeLoad: () => {
          this.externalOptions.dataSources.budget.events.beforeLoad();
        },
        afterLoad: () => {
          this.externalOptions.dataSources.budget.events.afterLoad();
        },
      },
    },
  };

  activeTab = function (identifier) {
    this.setState({
      activeTabIdentifier: identifier,
    });
  };

  events = {
    onOpenHotelDetailsClicked: (dataHotelClicked) =>
      this.hotelDetails.include(dataHotelClicked),

    onHotelSearchByIdClicked: (identifier, searchParameters) => {
      let searches = this.state.searches;

      searches[identifier].loadingRooms = true;

      this.setState({ searches });
      this.dataSources.hotel.searchById(identifier, searchParameters);
    },

    onHotelOptionClicked: (identifier, hotelIndex) => {
      let searches = this.state.searches;
      searches[identifier].loading = true;
      searches[identifier].hotelSelectedSearch =
        searches[identifier].cheapestHotels[hotelIndex].hotelOriginalInfo;

      this.dataSources.hotel.getHotelDetails({
        identifier,
        searchParameters: this.hotelDetails.objectToRequestHotelDetails(
          searches[identifier].cheapestHotels[hotelIndex].hotelOriginalInfo,
          searches[identifier].searchParameters,
        ),
      });

      this.setState({ searches });
    },

    onShowEngineClicked: function () {
      let showEngine = !this.state.showEngine;
      this.setState({ showEngine: showEngine });
    },

    onShowFilterClicked: function () {
      let showFilter = !this.state.showFilter;
      this.setState({ showFilter: showFilter });
    },

    onApplyFiltersClicked: (result, index, identifier) => {
      if (result.fullFlightOptions) {
        let searches = this.state.searches;
        let search = searches[identifier];

        search.paginationParameters[index].itemsQuantity =
          result.fullFlightOptions.length;
        search.paginationParameters[index].currentPage = 1;
        search.showFaresIndexes[index] = [];
        search.results[index] = result;

        searches[identifier] = search;

        this.setState({
          searches: this.searchManager.applyPagination(searches),
        });
      }
    },

    onSelectEngineClicked: (engine) => {
      this.setState({ engineSelected: engine });
    },

    onTripClicked: (index) => {
      let searches = this.state.searches;
      let search = searches[this.state.activeTabIdentifier];
      search.tripPartSelectedIndex = index;

      this.setState({ searches: searches });
    },

    onClearTripMaganerItemClicked: () => {
      let searches = this.state.searches;

      delete searches[this.state.activeTabIdentifier].tariffedPriceFareResult;
      searches[
        this.state.activeTabIdentifier
      ].tariffedPriceAttemptsMultiplrTrips = 0;

      this.setState({ searches: searches });
    },

    onPageClicked: (currentPage) => {
      let index = this.state.tripPartSelectedIndex;
      let identifier = this.state.activeTabIdentifier;
      let searches = this.state.searches;
      searches[identifier].showFaresIndexes[index] = [];

      this.setState({ searches: searches });
      this.searchManager.onPageClicked(currentPage);
    },

    onOrderAirSearchResultClicked: (order) => {
      const searches = this.state.searches;
      const identifier = this.state.activeTabIdentifier;
      const search = searches[identifier];
      const tripPartSelectedIndex = search ? search.tripPartSelectedIndex : '';
      const orderItens =
        searches[identifier].orderList[tripPartSelectedIndex].order;

      searches[identifier].showFaresIndexes[tripPartSelectedIndex] = [];

      const fullFlightOptions =
        search && search.results[tripPartSelectedIndex]
          ? search.results[tripPartSelectedIndex].fullFlightOptions
          : undefined;

      switch (order) {
        case 2: {
          this.itensOrder.lessTime(fullFlightOptions, search);
          break;
        }
        case 3: {
          this.itensOrder.lessTimeLessCost(fullFlightOptions, search);
          break;
        }
        case 4: {
          this.itensOrder.descending(fullFlightOptions, search);
          break;
        }
        case 5: {
          this.itensOrder.byDepartureHour(fullFlightOptions, search);
          break;
        }
        default:
          this.itensOrder.ascending(fullFlightOptions, search);
      }

      this.setState({ searches: this.searchManager.applyPagination(searches) });
    },

    onShowFaresIndexesClicked: (index) => {
      this.searchManager.showFaresIndexes(index);
    },

    onCloseCheckoutClicked: () => {
      this.setState({ displayCheckout: false });
    },

    onCancelSendCheckoutProductsClicked: () => {
      this.setState({
        displayConfirmSendproductsCheckout: false,
        attemptsOfTariffedPrice: 0,
        stopCountingTariffedPriceExpiration: true,
        timeToExpirePreCheckout: '00:59',
        checkoutData: {},
        popupFeedbackData: {
          ...this.state.popupFeedbackData,
          show: false,
        },
      });
    },

    onPopupCloseClicked: () => {
      this.setState({
        displayPopupWarning: false,
        searches: this.searchManager.activateTabExpired(),
      });
    },

    onConfirmSendCheckoutProductsClicked: () => {
      let checkoutData = this.state.checkoutData;

      if (!this.checkAllowedPaymentTypes(checkoutData)) {
        return;
      }

      this.includeSearchIdItems(checkoutData);
      this.includeSelectedOptions(checkoutData);

      this.setState({
        displayCheckout: true,
        checkoutData,
        stopCountingTariffedPriceExpiration: true,
        displayConfirmSendproductsCheckout: false,
        attemptsOfTariffedPrice: 0,
        timeToExpirePreCheckout: '00:59',
      });
    },

    cancelButtonClick: () => {
      this.setState({
        displayPopupWarning: false,
      });
    },

    onIncludeProductCheckoutShoppingCartClicked: (
      groupIndex,
      productIndex,
      productType,
    ) => {
      let shoppingCart = this.state.shoppingCart;
      let travelersRooms =
        this.state.searches[this.state.activeTabIdentifier].searchParameters
          .rooms;
      let productSelected = {};
      if (productType === enums.productType.hotel) {
        productSelected =
          shoppingCart[groupIndex].products.hotels[productIndex];
        this.checkoutManager.includeHotelProductToSendCheckout(
          [productSelected],
          shoppingCart[groupIndex].travelers,
          travelersRooms,
        );
      } else {
        productSelected =
          shoppingCart[groupIndex].products.travels[productIndex];
        this.checkoutManager.includeProductToSendCheckout(
          [productSelected],
          shoppingCart[groupIndex].travelers,
        );
      }
    },

    onAllTripsSelected: (tripItems, searchIdentifier) => {
      this.dataSources.preBooking.callPreBooking(tripItems, searchIdentifier);
    },

    onHotelApplyFiltersClick: (parametersFiltered) => {
      const identifier = this.state.activeTabIdentifier;
      const searches = this.state.searches;
      const search = searches[identifier];

      search.loading = true;

      search.searchParameters.searchViewParameters.filter = parametersFiltered;
      search.searchParameters.searchViewParameters.searchId =
        search.originalResults.resultResume.searchId;

      if (search?.searchParameters?.searchViewParameters?.pagingData?.page != 1)
        search.searchParameters.searchViewParameters.pagingData.page = 1;

      this.setState({ searches: searches });
      this.dataSources.hotel.search(identifier, search.searchParameters);
    },

    onTryGetTariffedPriceClicked: () => {
      let checkoutData = this.state.checkoutData;

      let attemptsOfTariffedPrice = this.state.attemptsOfTariffedPrice;
      attemptsOfTariffedPrice++;

      if (attemptsOfTariffedPrice >= 3) {
        this.checkoutManager.popupWarningExpiredAttempts(
          this.events.onPopupCloseClicked,
          this.events.onPopupCloseClicked,
        );
        this.events.onCancelSendCheckoutProductsClicked();
      } else {
        checkoutData.productsData.allTariffedPrice = {};
        checkoutData.productsData.tariffedPriceExpired = false;

        this.setState({
          checkoutData,
          stopCountingTariffedPriceExpiration: false,
          attemptsOfTariffedPrice: attemptsOfTariffedPrice,
        });

        this.checkoutManager.convertToPreBookingConfirmationRequest(
          checkoutData.productsData,
          null,
        );
      }
    },

    onRedoSearchClicked: () => {
      this.searchManager.redoSearch();
    },

    onDeleteTabAndSearchClicked: () => {
      this.searchManager.delete(this.state.activeTabIdentifier);
    },

    onTryAgainTariffedPriceMultipleTripClicked: (tripItems, identifier) => {
      let searches = this.state.searches;
      let attempts = searches[identifier].tariffedPriceAttemptsMultiplrTrips;
      attempts = attempts + 1;

      if (attempts >= 3) {
        this.checkoutManager.popupWarningExpiredAttempts(
          this.searchManager.tariffedPriceExpiredMultipleTrip,
          this.searchManager.tariffedPriceExpiredMultipleTrip,
        );
      } else {
        searches[identifier].tariffedPriceAttemptsMultiplrTrips = attempts;
        delete searches[identifier].tariffedPriceFareResult;
        this.setState({ searches: searches });
        this.events.onAllTripsSelected(tripItems, identifier);
      }
    },

    onPopupCloseAgencyClicked() {
      this.closeCompanyPopup();
    },

    onFeedbackPopupCloseClicked() {
      this.events.onCancelSendCheckoutProductsClicked();
    },

    onPopupSelectCompanyClicked() {
      let company = this.state.companySelected;
      this.setSelectedCompany(company);

      if (company.value != 0) {
        if (company.value != this.state.activeCompany.value) {
          this.setInitialStateValues();

          this.closeCompanyPopup();
          this.setState({
            activeCompany: company,
          });
        } else {
          this.closeCompanyPopup();
        }
      } else {
        gritter.Info('Selecione um cliente');
      }
    },

    searchExpire: (identifier) => {
      let searches = this.state.searches;
      searches[identifier].displayActionsTabPanel = true;

      this.setState({ searches });
    },
  };

  getInitialStateValues() {
    return JSON.parse(JSON.stringify(this.initialStateValues)); //deep copy
  }

  setInitialStateValues() {
    let initialStateValues = this.getInitialStateValues();
    this.setState(initialStateValues);
  }

  includeSelectedOptions(checkoutData) {
    this.includeAirSelectedOptions(checkoutData);
    this.includeHotelSelectedOptions(checkoutData);
  }

  checkAllowedPaymentTypes(checkoutData) {
    let allowedPaymentTypes = checkoutData.allowedPaymentTypes;
    if (
      checkoutData.preBookingData.hotel &&
      checkoutData.preBookingData.hotel.hotels
    )
      return this.verifyHotelGuarantees(
        allowedPaymentTypes,
        checkoutData.preBookingData.hotel.hotels,
      );

    return true;
  }

  verifyHotelGuarantees(allowedPaymentTypes, hotels) {
    let hotelGuaranteesValidation = true;

    hotels.forEach((hotel) => {
      let guaranteesAccepted = hotel.rooms
        .map((room) => room.guaranteesAccepted)
        .flat();

      if (
        guaranteesAccepted.some(
          (guarantee) => guarantee.type == enums.hotelGuaranteeType.creditCard,
        )
      ) {
        hotelGuaranteesValidation = hotelGuaranteesValidation && true;
        return;
      }

      if (
        guaranteesAccepted.some(
          (guarantee) => guarantee.type == enums.hotelGuaranteeType.companyName,
        ) &&
        allowedPaymentTypes.some(
          (type) => type.PaymentType == enums.paymentType.invoice,
        )
      ) {
        hotelGuaranteesValidation = hotelGuaranteesValidation && true;
      } else hotelGuaranteesValidation = false;

      if (!hotelGuaranteesValidation) {
        let popupWarning = this.state.popupWarning;
        let names = this.getGuaranteesNames(guaranteesAccepted);

        popupWarning.messages = [
          `Não conseguiremos seguir com a reserva deste hotel devido ao '${
            hotel.name
          }' aceitar como garantia da reserva apenas o pagamento '${names
            .join(' ou ')
            .toUpperCase()}' que não foi contratado pela sua empresa.`,
          'Para seguir com sua viagem, por favor selecione outro hotel ou quarto.',
        ];

        popupWarning.okButtonLabel = 'Ok';
        popupWarning.title = 'Aviso';

        popupWarning.cancelButtonClick = this.events.cancelButtonClick;
        popupWarning.okButtonClick = this.events.cancelButtonClick;

        this.setState({
          popupWarning: popupWarning,
          displayPopupWarning: true,
        });

        return hotelGuaranteesValidation;
      }
    });

    return hotelGuaranteesValidation;
  }

  getGuaranteesNames(guarantees) {
    let names = [];
    guarantees.forEach((guarantee) => {
      switch (guarantee.type) {
        case enums.hotelGuaranteeType.creditCard:
          names.push('Cartão de Crédito');
          break;
        case enums.hotelGuaranteeType.companyIata:
        case enums.hotelGuaranteeType.companyName:
          names.push('Faturado');
          break;
        case enums.hotelGuaranteeType.b2bPay:
          names.push('B2B Pay');
          break;
      }
    });

    return names;
  }

  includeAirSelectedOptions(checkoutData) {
    let _this = this;

    checkoutData.compressedSearchResult.air.forEach((item) => {
      item.selectedOption = [];
    });
    if (checkoutData.productsData.trips)
      checkoutData.productsData.trips &&
        checkoutData.productsData.trips.forEach((travel) => {
          let tripOption = {
            trips: travel.trips.map((item) => {
              let trip = item.formattedObject;
              return {
                departure: {
                  iata: trip.departure.iata,
                  name: trip.departure.fullName,
                },
                arrival: {
                  iata: trip.arrival.iata,
                  name: trip.arrival.fullName,
                },
                departureDate: _this.searchManager.dataCompressor.clearDateObj(
                  trip.departure.date,
                ),
                arrivalDate: _this.searchManager.dataCompressor.clearDateObj(
                  trip.arrival.date,
                ),
                issuerAirline: trip.airLine,
                duration: trip.duration.durationInMinutes,
                stops: trip.stops,
                baggages: travel.fares.fareOptions.baggages,
                firstFlightCabinType: trip.airClass.id,
                searchId: trip.searchId,
              };
            }),
            fares: [
              {
                travellersFareValues: travel.fares.travellersFareValues,
                inPolicy: travel.fares.fareOptions.inPolicy,
                agreementApplied: travel.fares.fareOptions.agreementApplied,
              },
            ],
          };

          let compressedSearchIndex =
            checkoutData.compressedSearchResult.air.findIndex(
              (i) => i.searchId == tripOption.trips[0].searchId,
            );

          if (compressedSearchIndex != -1) {
            if (
              typeof checkoutData.compressedSearchResult.air[
                compressedSearchIndex
              ].selectedOption == 'string'
            )
              checkoutData.compressedSearchResult.air[
                compressedSearchIndex
              ].selectedOption = compressorResolver.descompress(
                checkoutData.compressedSearchResult.air[compressedSearchIndex]
                  .selectedOption,
              );

            checkoutData.compressedSearchResult.air[
              compressedSearchIndex
            ].selectedOption.push(tripOption);
          }

          checkoutData.compressedSearchResult.air[
            compressedSearchIndex
          ].selectedOption = compressorResolver.compress(
            checkoutData.compressedSearchResult.air[compressedSearchIndex]
              .selectedOption,
          );
        });

    checkoutData.compressedSearchResult.air &&
      checkoutData.compressedSearchResult.air.forEach((item) => {
        item.selectedOption = compressorResolver.compress(item.selectedOption);
      });
    checkoutData.productsData.products = [
      ...(checkoutData.productsData.products || []),
      {
        type: enums.purchaseConfiguration.product.air,
        hasItems: !!checkoutData.productsData.trips.length > 0 === true,
      },
    ];
  }

  includeHotelSelectedOptions(checkoutData) {
    let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
    let getFormatted = utils.formatters.money.getFormatted;

    if (checkoutData?.productsData?.hotels?.selectedHotels?.length) {
      checkoutData.productsData.hotels.selectedHotels.forEach((item) => {
        let hotel = item.hotel;

        let dailyFareValue = 0;
        let dailyTaxesValue = 0;
        let totalValue = 0;

        let selectedRooms = [...item.selectedRooms];

        let hotelOption = {
          hotel: {
            images: hotel.images,
            name: hotel.name,
            searchId: hotel.searchId,
            evaluation: {
              rating: hotel.stars,
              source: hotel.rooms[0].supplier,
            },
            services: hotel.rooms[0].mealOption,
            localization: `${hotel.location.name}, ${hotel.location.city} - ${hotel.location.state}`,
            description: hotel.description,
            needCompleteAddress: hotel.needCompleteAddress,
            selectedRooms: selectedRooms,
          },
          fares: selectedRooms.map((item, index) => {
            let roomValue = item.room.fares[0].roomValue;
            let days = item.room.fares[0].fareInformation.days;

            dailyFareValue = dailyFareValue + roomValue.dailyFare.value;
            dailyTaxesValue = dailyTaxesValue + roomValue.dailyTax.value;
            totalValue = totalValue + dailyFareValue + dailyTaxesValue;

            return {
              totalDaily: {
                currencySymbol: getCurrencySymbol(
                  roomValue.dailyFare.currencyCode,
                ),
                value: totalValue,
              },
              dailyFare: `${getCurrencySymbol(
                roomValue.dailyFare.currencyCode,
              )} ${getFormatted(
                dailyFareValue * days,
                roomValue.dailyFare.currencyCode,
              )}`,
              dailyTaxes: `${getCurrencySymbol(
                roomValue.dailyTax.currencyCode,
              )} ${getFormatted(
                dailyTaxesValue,
                roomValue.dailyTax.currencyCode,
              )} `,
              total: `${getCurrencySymbol(
                roomValue.dailyTax.currencyCode,
              )} ${getFormatted(
                totalValue * days,
                roomValue.dailyTax.currencyCode,
              )} `,
              days: days,
            };
          }),
        };

        let compressedSearchIndex =
          checkoutData.compressedSearchResult.hotel.findIndex(
            (i) => i.searchId == hotel.searchId,
          );

        if (compressedSearchIndex != -1) {
          let hotelOptionToCompress = { ...hotelOption };
          compressorResolver.hotel.resolveProperties(hotelOptionToCompress);
          checkoutData.compressedSearchResult.hotel[
            compressedSearchIndex
          ].selectedOption = compressorResolver.compress(hotelOptionToCompress);
        }
      });
      checkoutData.productsData.products = [
        ...(checkoutData.productsData.products || []),
        {
          type: enums.purchaseConfiguration.product.hotel,
          hasItems: true,
        },
      ];
    }
  }

  includeSearchIdItems(checkoutData) {
    let _this = this;
    let searches = this.state.searches;

    checkoutData.preBookingData.air &&
      checkoutData.preBookingData.air.airTrips.forEach((airTrip) => {
        airTrip.hashCode = _this.checkoutManager.createAirHashCode(airTrip);
        airTrip.searchId = null;

        Object.keys(searches).forEach((identifier) => {
          if (searches[identifier].results)
            searches[identifier].results.forEach((result) => {
              result.fullFlightOptions &&
                result.fullFlightOptions.forEach((option) => {
                  option.trips.forEach((trip) => {
                    if (trip.hashCode == airTrip.hashCode) {
                      airTrip.searchId = trip.searchId;
                    }
                  });
                });
            });
        });
      });

    checkoutData.preBookingData.hotel &&
      checkoutData.preBookingData.hotel.hotels.forEach((hotel) => {
        hotel.hashCode = _this.checkoutManager.createHotelHashCode(hotel);

        Object.keys(searches).forEach((identifier) => {
          if (searches[identifier].results)
            searches[identifier].results.forEach((result) => {
              if (result.name && result.hashCode == hotel.hashCode) {
                hotel.searchId = result.searchId;
              }
            });
          else
            searches[identifier].hotel.searchId =
              searches[identifier].searchParameters.searchId;
        });
      });

    return checkoutData;
  }

  includeCompressedSearchResultAirProcessBookingData(data) {
    let _this = this;
    let searches = this.state.searches;
    let compressedSearchResult = {};
    compressedSearchResult.compressType = 3;
    compressedSearchResult.air = [];
    compressedSearchResult.hotel = [];

    data.air &&
      data.air.selectedFlights.forEach((item) => {
        if (
          compressedSearchResult.air.findIndex(
            (i) => i.searchId == item.airTrip.searchId,
          ) == -1
        ) {
          Object.keys(searches).forEach((identifier) => {
            if (
              searches[identifier].searchParameters.searchId ==
              item.airTrip.searchId
            ) {
              compressedSearchResult.air.push(
                searches[identifier].compressedSearchResult,
              );
            }
          });
        }
      });

    data.hotel &&
      data.hotel.selectedHotels.forEach((item) => {
        Object.keys(searches).forEach((identifier) => {
          if (
            searches[identifier].searchParameters.searchId ==
            item.hotel.searchId
          ) {
            compressedSearchResult.hotel.push(
              searches[identifier].compressedSearchResult,
            );
          }
        });
      });

    return compressedSearchResult;
  }

  itensOrder = {
    order: {
      ascending: 'ascending',
      descending: 'descending',
      lessTime: 'lessTime',
      lessTimeLessCost: 'lessTimeLessCost',
      byDepartureHour: 'byDepartureHour',
    },

    ascending: function (fullFlightOptions, search) {
      return this.itensOrder.doOrder(
        fullFlightOptions,
        search,
        this.itensOrder.order.ascending,
        function (initial, final) {
          return initial.fareOptions[0].price.fare.value >
            final.fareOptions[0].price.fare.value
            ? 1
            : -1;
        },
      );
    },

    descending: function (fullFlightOptions, search) {
      return this.itensOrder.doOrder(
        fullFlightOptions,
        search,
        this.itensOrder.order.descending,
        function (initial, final) {
          return initial.fareOptions[0].price.fare.value <
            final.fareOptions[0].price.fare.value
            ? 1
            : -1;
        },
      );
    },
    lessTime: function (fullFlightOptions, search) {
      return this.itensOrder.doOrder(
        fullFlightOptions,
        search,
        this.itensOrder.order.lessTime,
        function (initial, final) {
          let initialDuration = initial.trips
            .map((trip) => trip.duration.durationInMinutes)
            .reduce((accumulator, a) => {
              return accumulator + a;
            }, 0);
          let finalDuration = final.trips
            .map((trip) => trip.duration.durationInMinutes)
            .reduce((accumulator, a) => {
              return accumulator + a;
            }, 0);
          return initialDuration > finalDuration ? 1 : -1;
        },
      );
    },
    lessTimeLessCost: function (fullFlightOptions, search) {
      return this.itensOrder.doOrder(
        fullFlightOptions,
        search,
        this.itensOrder.order.lessTimeLessCost,
        function (initial, final) {
          let initialDuration = initial.trips
            .map((trip) => trip.duration.durationInMinutes)
            .reduce((accumulator, a) => {
              return accumulator + a;
            }, 0);
          let initialCost = initial.fareOptions[0].price.fare.value;

          let finalDuration = final.trips
            .map((trip) => trip.duration.durationInMinutes)
            .reduce((accumulator, a) => {
              return accumulator + a;
            }, 0);
          let finalCost = final.fareOptions[0].price.fare.value;

          return initialCost > finalCost || initialDuration > finalDuration
            ? 1
            : -1;
        },
      );
    },
    byDepartureHour: function (fullFlightOptions, search) {
      return this.itensOrder.doOrder(
        fullFlightOptions,
        search,
        this.itensOrder.order.byDepartureHour,
        function (initial, final) {
          return initial.trips[0].originalAirTrip.departureDate >
            final.trips[0].originalAirTrip.departureDate
            ? 1
            : -1;
        },
      );
    },
    doOrder: (fullFlightOptions, search, order, orderMethod) => {
      let flightsOptionsOrder = fullFlightOptions.sort(orderMethod);
      let tripPartSelectedIndex = search.tripPartSelectedIndex;

      search.orderList[tripPartSelectedIndex].order = order;
      search.results.length > 0
        ? (search.results[tripPartSelectedIndex].fullFlightOptions =
            flightsOptionsOrder)
        : undefined;

      return search;
    },
  };

  getOptimizedAPIResponse(response) {
    return {};
  }

  convertToPreBookingRequest(selectedAirTrips, searchIdentifier) {
    let selectedFlights = [];
    let search = this.state.searches[searchIdentifier];
    let searchParameters = { ...search.searchParameters };

    for (let index in selectedAirTrips) {
      let airTrip = selectedAirTrips[index];
      selectedFlights.push({
        airTrip: airTrip.trip.originalAirTrip,
        fareInfo: airTrip.fare.originalFare.fareInformations[0], //TODO THIAGO: verificar com WELL
      });
    }

    return {
      action: 0,
      travellers: this.convertPassengersToTravelers(
        searchParameters.passengers,
      ),
      air: {
        selectedFlights: selectedFlights,
      },
    };
  }

  getSearchData(productsData) {
    const searchData = {
      airSearches: {},
      hotelSearches: {}
    };

    if (productsData.trips?.length) {
      productsData.trips.forEach(trip => {
        if (trip.trips && trip.trips[0]?.searchId){
          searchData.airSearches[trip.trips[0]?.searchId] = trip.searchData;
        }
      });
    }

    if (productsData.hotels?.selectedHotels?.length){
      productsData.hotels.selectedHotels.forEach(hotel => {
        if (hotel.searchData.searchId){
          searchData.hotelSearches[hotel.searchData.searchId] = hotel.searchData
        }
      });
    } else {
      searchData.hotelSearches = null;
    }

    if (searchData.airSearches == {})
      searchData.airSearches = null;

    if (searchData.hotelSearches == {})
      searchData.hotelSearches = null;

    return searchData;
  }

  convertPreBookingResponseToFareResult(response) {
    let getFormattedValue = utils.formatters.money.getFormatted;

    let total = response.summary.total;
    total.formatted = getFormattedValue(total.value, total.currencyCode);
    total.currencySymbol = enums.currencySymbol[total.currencyCode];

    let totalFare = response.summary.totalFare;
    totalFare.formatted = getFormattedValue(
      totalFare.value,
      totalFare.currencyCode,
    );
    totalFare.currencySymbol = enums.currencySymbol[totalFare.currencyCode];

    let totalTaxes = response.summary.totalTaxes;
    totalTaxes.formatted = getFormattedValue(
      totalTaxes.value,
      totalTaxes.currencyCode,
    );
    totalTaxes.currencySymbol = enums.currencySymbol[totalTaxes.currencyCode];

    let totalServices = response.summary.totalServices;
    totalServices.formatted = getFormattedValue(
      totalServices.value,
      totalServices.currencyCode,
    );
    totalServices.currencySymbol =
      enums.currencySymbol[totalServices.currencyCode];

    //return response;
    return {
      agreementApplied: this.hasAgreementCode(response),
      total: total,
      totalTaxes: totalTaxes,
      totalFare: totalFare,
      totalServices: totalServices,
    };
  }

  hasAgreementCode(preBookingResponse) {
    if (
      preBookingResponse.air &&
      preBookingResponse.air.fareDetails.some(
        (fareDetail) =>
          fareDetail.agreementCode && fareDetail.agreementCode.length,
      )
    ) {
      return true;
    }

    if (
      preBookingResponse.hotel &&
      preBookingResponse.hotel.hotels.some((hotel) =>
        hotel.rooms.some((room) => {
          let fareInformation = room.fares[0].fareInformation;
          return (
            fareInformation.commercialAgreement &&
            fareInformation.commercialAgreement.agreementCode &&
            fareInformation.commercialAgreement.agreementCode.length
          );
        }),
      )
    ) {
      return true;
    }

    return false;
  }

  convertPassengersToTravelers(passengers) {
    let passengersList = Object.entries(passengers);
    let travelers = [];

    passengersList.forEach(([key, value]) => {
      if (value && value !== 0) {
        travelers.push({
          type:
            key == 'adults' || key == 'adultCount'
              ? 0
              : key == 'childrens' || key == 'childCount'
                ? 1
                : 2,
          count: value,
        });
      }
    });

    return travelers;
  }

  convertAirSearchDataToApiRequest = function (data, identifier) {
    let airSearchEngineData = data;
    let dataApiRequest = {};
    let groupingType =
      airSearchEngineData.tripType.value == 1 &&
      !airSearchEngineData.separeResults
        ? 0
        : 1; //todo thiago: transformar para enum
    dataApiRequest.trips = [];
    dataApiRequest.travelers = [];
    dataApiRequest.directFlight = airSearchEngineData.directFlights;
    dataApiRequest.baggageIncluded = airSearchEngineData.baggageIncluded;
    dataApiRequest.groupingType = groupingType;
    dataApiRequest.sendPriceMatrix = airSearchEngineData.sendPriceMatrix;

    let filters = this.state.searchFilters;
    filters[identifier] = [];

    airSearchEngineData.trips.forEach((trip) => {
      let airlineCodes = trip.airLines
        ? trip.airLines.map((cia) => cia.value)
        : [];
      let cabinType = trip.airClass ? trip.airClass.value : null;
      let timeRange = trip.goingDate.period
        ? Object.values(trip.goingDate.period.range)
        : null;

      if (airlineCodes) filters[identifier].push({ airlineCodes, timeRange });

      var trip = {
        iataOrigin: trip.origin.iata,
        iataDestination: trip.destination.iata,
        departureDate: {
          day: trip.goingDate.date.day,
          month: trip.goingDate.date.month,
          year: trip.goingDate.date.year,
        },
      };

      //Caso não seja economica envio para o connecton para que realize o filtro na cia de modo que retorne apenas tarifas e voos de acordo
      if (cabinType && cabinType == '2')
        filters[identifier].push({ cabinType });
      else trip.cabinType = parseInt(cabinType);

      dataApiRequest.trips.push(trip);
    });

    this.setState({ searchFilters: filters });
    dataApiRequest.travellers = this.convertPassengersToTravelers(
      airSearchEngineData.passengers,
    );

    dataApiRequest.mock = data.mock;

    let apiRequest = {
      dataApiRequest: dataApiRequest,
      tripType: airSearchEngineData.tripType.value,
    };

    return apiRequest;
  };

  convertApiResponseToAirResultData = function (response, identifier) {
    const responseApi = response;
    const search = this.state.searches[identifier].searchParameters;
    const filters = this.state.searchFilters[identifier];

    let _this = this;
    let travelOptions = [];

    let optionsPerTrip = {
      placeholderIdentifier: '',
      tripPartType: 0,
      separeResults: false,
      fullFlightOptions: [],
      flightOptions: [],
    };

    let flightOption = {
      trips: [],
      fareOptions: [],
    };

    if (responseApi.roundTripOptions) {
      let optionsPerTrip = {
        placeholderIdentifier: '',
        tripPartType: 0,
        separeResults: false,
        fullFlightOptions: [],
        flightOptions: [],
      };

      responseApi.roundTripOptions.forEach((tripOption) => {
        flightOption = {};
        flightOption.trips = [];
        flightOption.fareOptions = [];

        tripOption.airTrips.forEach((indexFlight) => {
          flightOption.trips.push(
            _this.createObjTrip(responseApi, indexFlight, identifier),
          );
        });

        tripOption.fares.forEach((fare, index) => {
          flightOption.fareOptions.push(
            _this.createObjFare(fare, index, flightOption.trips[0].airLine),
          );
        });

        optionsPerTrip.fullFlightOptions.push(flightOption);
      });
      optionsPerTrip.placeholderIdentifier =
        search.trips[0].origin.iata + '/' + search.trips[0].destination.iata;
      travelOptions.push(this.applySearchEngineFilter(optionsPerTrip, filters));
    } else {
      let counter = 0;
      responseApi.tripOptions &&
        responseApi.tripOptions.forEach((tripOption, index) => {
          let newOptionsPerTrip = _.cloneDeep(optionsPerTrip); //$.extend(true, {}, optionsPerTrip);
          counter++;
          tripOption.pricedAirTrips.forEach((pricedAirTrip) => {
            let newFlightOption = _.cloneDeep(flightOption); //$.extend(true, {}, flightOption);

            pricedAirTrip.airTrips.forEach((indexFlight) => {
              newFlightOption.trips.push(
                _this.createObjTrip(responseApi, indexFlight, identifier),
              );
            });

            pricedAirTrip.fares.forEach((fare, index) => {
              newFlightOption.fareOptions.push(
                _this.createObjFare(
                  fare,
                  index,
                  newFlightOption.trips[0].airLine,
                ),
              );
            });
            newOptionsPerTrip.fullFlightOptions.push(newFlightOption);
          });

          newOptionsPerTrip.placeholderIdentifier =
            search.trips[index].origin.iata +
            '/' +
            search.trips[index].destination.iata;
          newOptionsPerTrip.tripPartType =
            search.tripType.value == 2
              ? 1
              : search.tripType.value == 3
                ? 2
                : search.separeResults &&
                    search.tripType.value == 1 &&
                    counter > 1
                  ? 3
                  : 1;

          travelOptions.push(
            this.applySearchEngineFilter(
              newOptionsPerTrip,
              filters,
              counter - 1,
            ),
          );
        });
    }

    return travelOptions;
  };

  applySearchEngineFilter = function (travelOptions, filter, index) {
    let timeRange = filter.map((f) => f.timeRange);

    if (index) filter = filter[index];
    else filter = filter[0];

    if (filter && filter.airlineCodes.length > 0) {
      let filteredByAirLines = travelOptions.fullFlightOptions.filter(
        (option) =>
          option.trips.some((trip) =>
            filter.airlineCodes.some((code) => code == trip.airLine.code),
          ),
      );
      travelOptions.fullFlightOptions = filteredByAirLines;
    }

    if (filter && filter.cabinType && filter.cabinType != 'all') {
      let filteredByCabinType = travelOptions.fullFlightOptions.filter(
        (option) =>
          option.trips.some((trip) => filter.cabinType == trip.airClass.id),
      );
      travelOptions.fullFlightOptions = filteredByCabinType;
    }

    if (timeRange && timeRange.length > 0) {
      let filteredByTimeRange = travelOptions.fullFlightOptions.filter(
        (option) => this.filterByTimeRange(option.trips, timeRange, index),
      );
      travelOptions.fullFlightOptions = filteredByTimeRange;
    }

    return travelOptions;
  };

  filterByTimeRange = function (trips, timeRange, index) {
    let result = trips.map((trip, i) => {
      if (!timeRange[index || i]) return true;

      let start = timeRange[index || i][0];
      let final = timeRange[index || i][1];

      let departure = trip.departure.date;
      let inTimeRange = false;

      if (departure.hour > start.hour) inTimeRange = true;
      else if (departure.hour == start.hour && departure.minute >= start.minute)
        inTimeRange = true;
      else inTimeRange = false;

      if (inTimeRange && departure.hour < final.hour) inTimeRange = true;
      else if (
        inTimeRange &&
        departure.hour == final.hour &&
        departure.minute <= final.minute
      )
        inTimeRange = true;
      else inTimeRange = false;

      return inTimeRange;
    });

    return result.every((i) => i);
  };

  convertApiResponseToHotelResultData = function (
    response,
    paramSearchData,
    identifier,
  ) {
    let summaryResponse = { ...response.summary };
    let _this = this;

    let summary = {
      pageNumber: paramSearchData.searchViewParameters.pagingData.page,
      itemsPerPage:
        paramSearchData.searchViewParameters.pagingData.itemsPerPage,
      totalResults: summaryResponse ? summaryResponse.count : 0,
      prices: response.summary ? response.summary.priceRange : null,
      stars: response.summary ? response.summary.rating : null,
      meals: response.summary ? response.summary.mealOptions : null,
      providers: response.summary ? response.summary.suppliers : null,
      searchId: response.summary ? response.summary.searchId : null,
      distances: response.summary ? response.summary.distanceRange : null,
    };

    if (
      this.state.searches[identifier] &&
      this.state.searches[identifier].originalResults &&
      this.state.searches[identifier].originalResults.resultResume
    ) {
      let originalResume =
        this.state.searches[identifier].originalResults.resultResume;
      summary.prices = originalResume.prices;
      summary.stars = originalResume.stars;
      summary.meals = originalResume.meals;
      summary.providers = originalResume.providers;
    }

    if (response.hotels && response.hotels.length > 0) {
      return {
        results: response.hotels.map((hotel) => {
          if (!hotel.images || hotel.images.length == 0) {
            hotel.images = [
              {
                url: require('../../assets/images/thumb-hotel-placeholder.svg'),
              },
            ];
          }

          return {
            name: hotel.name,
            hashCode: _this.checkoutManager.createHotelHashCode(hotel),
            searchId: response.summary.searchId,
            stars: hotel.rating,
            address: hotel.address,
            description: hotel.description ? hotel.description : '',
            images: hotel.images,
            roomOptions: hotel.roomOptions,
            distance: hotel.distance,
            chain: {
              code: hotel.chain && hotel.chain.code ? hotel.chain.code : '',
              name: hotel.chain && hotel.chain.name ? hotel.chain.name : '',
            },
            location: hotel.address,
            rooms: hotel.rooms.map((room, index) => {
              if (index == 0) {
                summary.currency =
                  room.fares[0].roomValue.dailyFare.currencyCode;
              }
              return {
                index: index,
                cancellationPolicies: room.cancellationPolicies
                  ? room.cancellationPolicies.map((cancel) => {
                      return {
                        description: cancel.description,
                        refundable: !cancel.nonRefundable,
                        nonRefundable: cancel.nonRefundable,
                        endDate: cancel.endDate,
                        noShow: cancel.noShow,
                        penalty: cancel.penalty
                          ? {
                              value: cancel.penalty.value,
                              currencyCode: cancel.penalty.currencyCode,
                            }
                          : null,
                      };
                    })
                  : null,
                cancelDeadline: room.cancelDeadline,
                capacity: room.capacity,
                compressedAdditionalProperties:
                  room.compressedAdditionalProperties,
                description: room.description ? room.description : '',
                fares: room.fares
                  ? [
                      {
                        fareInformation: room.fares[0].fareInformation,
                        roomValue: {
                          dailyFare: room.fares[0].roomValue.dailyFare,
                          dailyTax: room.fares[0].roomValue.dailyTax,
                          originalDailyFare:
                            room.fares[0].roomValue.originalDailyFare,
                          originalDailyTax:
                            room.fares[0].roomValue.originalDailyTax,
                          markupedDailyFare:
                            room.fares[0].roomValue.markupedDailyFare,
                          total: {
                            value:
                              room.fares[0].roomValue.dailyFare.value +
                              room.fares[0].roomValue.dailyTax.value,
                            currencyCode:
                              room.fares[0].roomValue.dailyFare.currencyCode,
                          },
                          totalDays: room.fares[0].fareInformation.days,
                        },
                      },
                    ]
                  : null,
                guaranteesAccepted: room.guaranteesAccepted,
                id: room.id,
                images: room.images,
                name: room.name,
                sourceIdentifier: Object.values(room.sourceIdentifier)[0],
                refundable:
                  room.cancellationPolicies &&
                  !room.cancellationPolicies[0] &&
                  !room.cancellationPolicies[0].nonRefundable != undefined
                    ? !room.cancellationPolicies[0].nonRefundable
                    : false,
                price: {
                  currencySymbol:
                    room.fares[0].roomValue.dailyFare.currencyCode,
                  totalDaily:
                    room.fares[0].roomValue.markupedDailyFare.value +
                    room.fares[0].roomValue.dailyTax.value,
                  dailyFare: room.fares[0].roomValue.dailyFare.value,
                  markupedDailyFare: room.fares[0].roomValue.markupedDailyFare,
                  dailyTaxes: room.fares[0].roomValue.dailyTax.value,
                  total:
                    (room.fares[0].roomValue.markupedDailyFare.value +
                      room.fares[0].roomValue.dailyTax.value) *
                    room.fares[0].fareInformation.days,
                  totalDays: room.fares[0].fareInformation.days,
                },
                amenities: room.amenities ? room.amenities : [],
                original: room,
              };
            }),
            comppressedAdditionalProperties:
              hotel.comppressedAdditionalProperties,
            externalCode: hotel.externalCode,
            needCompleteAddress: hotel.needCompleteAddress,
          };
        }),
        resultResume: summary,
      };
    } else {
      return {
        results: [],
        summary: summary,
      };
    }
  };

  createObjTrip(responseApi, index, identifier) {
    let trip = responseApi.airTrips[index];
    let departure = responseApi.locations[trip.departure];
    let arrival = responseApi.locations[trip.arrival];
    let cabinTypeId = airTrip.getCabinType(trip.flights);
    let airlineCode = trip.issuerAirline;
    let hashCode = this.checkoutManager.createAirHashCode(
      trip,
      responseApi.locations,
    );
    let flights = this.createFlightsObj(trip.flights, responseApi.locations);

    trip.searchId = responseApi.summary.searchId;
    trip.hashCode = hashCode;

    return {
      originalAirTrip: trip,
      airLine: this.getAirline(airlineCode),
      airClass: {
        id: cabinTypeId,
        name: enums.airClasses[cabinTypeId],
      },
      departure: {
        iata: departure.ia,
        fullName: departure.n,
        date: this.generateDateObject(trip.departureDate),
      },
      arrival: {
        iata: arrival.ia,
        fullName: arrival.n,
        date: this.generateDateObject(trip.arrivalDate),
      },
      stops: trip.stops,
      duration: {
        durationInMinutes: trip.duration,
        durationFormatted:
          trip.duration > 0
            ? utils.formatters.time.durationHoursMinute(trip.duration)
            : utils.calculateDuration(trip.departureDate, trip.arrivalDate),
      },
      flights: flights,
      searchId: responseApi.summary.searchId,
      hashCode: hashCode,
      fareIndexSelected: 0,
      identifier: identifier,
    };
  }

  createFlightsObj(flights, locations) {
    let flightsObj = flights.map((flight) => {
      let departure = locations[flight.departure];
      let arrival = locations[flight.arrival];
      let formatedflight = {
        departure: {
          iata: departure.ia,
          fullName: departure.n,
          date: flight.departureDate,
        },
        arrival: {
          iata: arrival.ia,
          fullName: arrival.n,
          date: flight.arrivalDate,
        },
        layover:
          flight.layover > 0
            ? utils.formatters.time.durationHoursMinute(flight.layover)
            : 0,
        flightNumber: flight.flightNumber,
        airLine: this.getAirline(flight.airline),
        operatedBy: this.getAirline(flight.operatedBy),
      };
      return formatedflight;
    });
    return flightsObj;
  }

  getAirline(code) {
    let airline = this.state.airlines.find((x) => x.code == code);
    if (!airline)
      airline = {
        code: code,
      };

    return {
      code: airline.code,
      name: airline.displayName || airline.name || airline.code,
      imageUrl: airline.imageUrl,
    };
  }

  createObjFare(fare, index, airLine) {
    let getFormattedValue = utils.formatters.money.getFormatted;

    let _this = this;
    let currentFare = fare.travellersFareValues.find((i) => i.type == 0)
      ? fare.travellersFareValues.find((i) => i.type == 0)
      : fare.travellersFareValues.find((i) => i.type == 1);
    let currencyCode = currentFare.markupedFare.currencyCode;
    let markupedFareValue = currentFare.markupedFare.value;
    let serviceTaxesValue = currentFare.du.value;
    let embarkTaxesValue = currentFare.taxes.value;
    let totalValue = markupedFareValue + serviceTaxesValue + embarkTaxesValue;
    let isInternational = fare.isInternational ? 'international' : 'national';
    let airLineCode = airLine.code == 'JJ' ? 'LA' : airLine.code;
    let additionalInfo = fareMapping[airLineCode]
      ? fareMapping[airLineCode][isInternational][
          fare.fareInformations[0].mappedFareName
        ]
      : fareMapping.default;
    let baggages =
      fareMapping[airLineCode] &&
      fareMapping[airLineCode][isInternational][
        fare.fareInformations[0].mappedFareName
      ]
        ? fareMapping[airLineCode][isInternational][
            fare.fareInformations[0].mappedFareName
          ]['baggageQuantity']
        : 1;
    let agreementCode = fare.fareInformations[0].agreementCode;

    return {
      price: {
        currencyCode: currencyCode,
        currencySymbol: enums.currencySymbol[currencyCode],

        fare: {
          // "markupedFare" se tiver desconto subtrair daqui
          value: markupedFareValue,
          formatted: getFormattedValue(markupedFareValue, currencyCode),
        },
        serviceTaxes: {
          // "du"
          value: serviceTaxesValue,
          formatted: getFormattedValue(serviceTaxesValue, currencyCode),
        },
        embarkTaxes: {
          // "taxes"
          value: embarkTaxesValue,
          formatted: getFormattedValue(embarkTaxesValue, currencyCode),
        },
        total: {
          // soma dos de cima
          value: totalValue,
          formatted: getFormattedValue(totalValue, currencyCode),
        },
      },

      inPolicy: index & 2 ? true : false,
      agreementApplied: agreementCode && agreementCode.length,
      agreementCode,
      baggages: baggages,
      additionalInfo: additionalInfo,
      airLineName: airLine.name,
      originalFare: fare,
    };
  }

  getCurrencySymbol(currencyCode) {
    //TODO: gerar enum
    return currencyCode == 'BRL' ? 'R$' : currencyCode == 'USD' ? 'US$' : '€';
  }

  generateValueFormatted(value) {
    return new Intl.NumberFormat('pt-BR').format(Math.round(value)).toString();
  }

  generateDateObject(dateItem) {
    if (
      dateItem != '--' ||
      dateItem != '-' ||
      dateItem != null ||
      dateItem != undefined
    ) {
      let date = new Date(dateItem);

      let objDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        dateFormatted: date.toLocaleDateString(),
        dayOfWeek: enums.daysOfWeek[date.getDay()],
        dateShortFormatted: `${date.getDate()} de ${
          enums.months[date.getMonth() + 1]
        }`,
        timeFormatted: date.toTimeString().substring(5, 0),
      };

      return objDate;
    } else {
      return '--';
    }
  }

  generateIdentifier = function () {
    return Math.floor(Date.now() * Math.random()).toString(36);
  };

  getCheapestHotels(searchIdentifier) {
    return this.state.searches[searchIdentifier].results
      .slice(0, 5)
      .map((hotel, index) => {
        return {
          hotelIndex: index,
          hotelOriginalInfo: hotel,
          name: hotel.name,
          externalCode: hotel.externalCode,
          rating: hotel.stars,
          connectionSource: hotel.connectionSource,
          sourceSupplier: hotel.sourceSupplier
            ? hotel.sourceSupplier
            : hotel.rooms[0].supplier,
          chain: hotel.chain,
          address: hotel.address,
          fareInformationSummary: {
            checkIn:
              this.state.searches[searchIdentifier].searchParameters.checkin,
            checkOut:
              this.state.searches[searchIdentifier].searchParameters.checkout,
            days: hotel.rooms[0].price.totalDays,
            value: {
              currencyCode: hotel.rooms[0].price.currencySymbol,
              value: hotel.rooms[0].price.total,
            },
          },
          images: [
            hotel.images.find((image) => image.thumbImage == true) ||
              hotel.images[0],
          ],
        };
      });
  }

  createNewSearchParameters = function (data) {
    return {
      location: data.location ? data.location : null,
      locationName: data.locationName ? data.locationName : null,
      rooms: data.rooms ? data.rooms : [],
      checkin: data.checkin ? data.checkin : null,
      checkout: data.checkout ? data.checkout : null,
      searchViewParameters: data.searchViewParameters
        ? { ...data.searchViewParameters }
        : null,
    };
  };

  hotelDetails = {
    include: (dataHotelClicked) => {
      let searches = { ...this.state.searches };
      let identifier = this.generateIdentifier();

      searches[identifier] = {
        identifier: identifier,
        dataHoteSearch: dataHotelClicked,
        indexHotelSelected: dataHotelClicked.indexHotelClicked,
        identifierSearch: dataHotelClicked.identifierSearch,
        hotelSelectedSearch:
          searches[dataHotelClicked.identifierSearch].results[
            dataHotelClicked.indexHotelClicked
          ],
        hotel:
          searches[dataHotelClicked.identifierSearch].results[
            dataHotelClicked.indexHotelClicked
          ],
        cheapestHotels: this.getCheapestHotels(
          dataHotelClicked.identifierSearch,
        ),
        originalSearchParameters: {
          ...this.state.searches[dataHotelClicked.identifierSearch]
            .searchParameters,
        },
        originalSearchIdentifier: dataHotelClicked.identifierSearch,
        productType: enums.quotationSteps.hotelDetails,
        label:
          searches[dataHotelClicked.identifierSearch].results[
            dataHotelClicked.indexHotelClicked
          ].name,
        loading: true,
        loadingRooms: false,
        result: {},
        apiReturn: false,
        displayActionsTabPanel: false,
        displayLoaderTariffedPrice: false,
      };

      this.setState({
        searches,
        activeTabIdentifier: identifier,
      });

      this.dataSources.hotel.getHotelDetails({
        identifier,
        searchParameters: this.hotelDetails.objectToRequestHotelDetails(
          searches[identifier].hotelSelectedSearch,
          {
            ...this.state.searches[dataHotelClicked.identifierSearch]
              .searchParameters,
          },
        ),
      });
    },

    update: (identifier, data, searchParameters, searchId) => {
      let searches = this.state.searches;
      let searchParametersCurrent = searchParameters.searchData
        ? searchParameters.searchData
        : searchParameters;

      if (data.results) {
        data.hotel = data.results[0];
        delete data.results[0];
      }

      if (data.hotel) {
        searches[identifier].loading = false;
        searches[identifier].loadingRooms = false;
        searches[identifier].result = data;
        searches[identifier].searchParameters = this.createNewSearchParameters({
          ...searchParametersCurrent,
        });
        searches[identifier].label = data.hotel.name;

        if (searchId) {
          searches[identifier].hotel.rooms = data.hotel.rooms;
          searches[identifier].hotelSelectedSearch.rooms = data.hotel.rooms;
        } else {
          searches[identifier].hotel = data.hotel;
        }
      } else {
        let searchParameters = searches[searches[identifier].identifierSearch]
          ? {
              ...searches[searches[identifier].identifierSearch]
                .searchParameters,
            }
          : searches[identifier].originalSearchParameters;
        searchParameters.checkin =
          searches[identifier].searchParameters.checkin;
        searchParameters.checkout =
          searches[identifier].searchParameters.checkout;

        searches[identifier].loadingRooms = false;
        searches[identifier].searchParameters = searchParameters;
      }

      this.setState({ searches });
    },

    objectToRequestHotelDetails: (hotel, searchParameters) => {
      let searchData = { ...searchParameters };
      searchData.searchViewParameters.filter.hotelName = hotel.name;
      searchData.searchViewParameters.filter.externalCode = hotel.externalCode;

      let selectedRoom = hotel.roomOptions[0];
      selectedRoom.room = hotel.rooms[0].original;

      return {
        comppressedAdditionalProperties: hotel.comppressedAdditionalProperties,
        selectedRoom: selectedRoom,
        searchData: searchData,
      };
    },
  };

  searchManager = {
    include: function (identifier, parameters, productType) {
      let searches = this.state.searches;
      let trips = productType == 'air' ? parameters.trips : null;
      let label =
        productType == 'hotel'
          ? formatterUtil.stringReduce(parameters.label, 30)
          : productType == 'air'
            ? trips[0].origin.iata + ' > ' + trips[0].destination.iata
            : parameters.hotelName;
      let tripType = productType == 'air' ? parameters.tripType.value : null;

      searches[identifier] = {
        searchHotelName:
          parameters &&
          parameters.searchViewParameters &&
          parameters.searchViewParameters.filter &&
          parameters.searchViewParameters.filter.hotelName
            ? true
            : false,
        searchConnectionSource:
          parameters &&
          parameters.searchViewParameters &&
          parameters.searchViewParameters.filter &&
          parameters.searchViewParameters.filter.connectionSource
            ? true
            : false,
        searchGeoLocation:
          parameters &&
          parameters.searchViewParameters &&
          parameters.searchViewParameters.filter &&
          parameters.searchViewParameters.filter.geoLocation
            ? true
            : false,
        identifier: identifier,
        searchParameters: parameters,
        productType: productType,
        label:
          label +
          (productType == 'air'
            ? tripType == 3
              ? ' +' + (trips.length - 1)
              : ''
            : ''),
        loading: true,
        tripPartSelectedIndex: 0,
        originalResults: [],
        results: [],
        paginationParameters: [],
        orderList: [],
        apiReturn: false,
        displayActionsTabPanel: false,
        displayLoaderTariffedPrice: false,
        clearTripsSelected: true,
      };

      this.setState({
        searches: searches,
        activeTabIdentifier: identifier,
      });
    },

    update: function (identifier, results, searchId) {
      let searches = this.state.searches;
      let itensPerPage = this.itensPerPage;

      let search = searches[identifier];

      search.results =
        search.productType == 'air'
          ? results
          : search.productType == 'hotel'
            ? results.results
            : results;

      search.originalResults =
        search.productType == 'air' ? [...search.results] : { ...results };
      search.loading = false;
      search.showFaresIndexes = [];
      search.clearTripsSelected = false;
      search.displayActionsTabPanel = false;
      search.tariffedPriceAttemptsMultiplrTrips = 0;

      if (searchId) search.searchParameters.searchId = searchId;

      if (search.productType == 'air') {
        search.results.map((result, index) => {
          search.paginationParameters.push({
            itemsQuantity:
              result && result.fullFlightOptions
                ? result.fullFlightOptions.length
                : 0,
            itemsPerPage: itensPerPage,
            currentPage: 1,
          });

          search.orderList.push({
            order: 'descending',
          });

          search.showFaresIndexes.push([]);
        });
        let tripPartSelectedIndex = search ? search.tripPartSelectedIndex : '';

        this.airFunctions.fillFlightsAirline(
          search.results[tripPartSelectedIndex].fullFlightOptions,
        );
        search = this.itensOrder.ascending(
          search.results[tripPartSelectedIndex].fullFlightOptions,
          search,
        );

        search.compressedSearchResult =
          this.searchManager.dataCompressor.getAirCompressedSearchResult(
            search,
          );

        //optimized
        searches[identifier] = search;
      } else if (search.productType == 'hotel') {
        search.paginationParameters = [
          {
            itemsQuantity: results?.resultResume?.totalResults || 0,
            itemsPerPage: results?.resultResume?.itemsPerPage || itensPerPage,
            currentPage: results?.resultResume?.pageNumber || 1,
          },
        ];

        search.orderList = [
          {
            order: 'descending',
          },
        ];

        search.compressedSearchResult =
          this.searchManager.dataCompressor.getHotelCompressedSearchResult(
            search,
          );
        search?.searchParameters?.searchId;

        //optimized
        searches[identifier] = search;
      }

      let newSearchesPaged = this.searchManager.applyPagination(searches, true);

      this.setState({
        searches: newSearchesPaged,
      });
    },

    delete: function (identifier) {
      let searches = this.state.searches;
      let searchFilters = this.state.searchFilters;
      let currentIndex = Object.keys(searches).indexOf(identifier);

      delete searchFilters[identifier];
      delete searches[identifier];

      let identifierArray = Object.keys(searches);
      let activeTabIdentifier =
        currentIndex >= identifierArray.length - 1
          ? identifierArray[identifierArray.length - 1]
          : identifierArray[currentIndex];

      this.setState({
        searches: searches,
        searchFilters: searchFilters,
        activeTabIdentifier: activeTabIdentifier,
      });
    },

    applyPagination: (searches, firstLoad = false) => {
      let _this = this;
      Object.keys(searches).map((identifier, index) => {
        const search = searches[identifier];
        if (search.productType == 'air') {
          search.results &&
            search.results.map((result, index) => {
              result = _this.searchManager.getPagedItemsResults(
                result,
                search.paginationParameters[index].itemsPerPage,
                search.paginationParameters[index].currentPage,
              );
            });
        }
        if (
          search.productType == 'hotel' &&
          !firstLoad &&
          identifier == this.state.activeTabIdentifier
        ) {
          search.loading = true;

          search.searchParameters.searchViewParameters.pagingData.page =
            search.paginationParameters[0].currentPage;
          search.searchParameters.searchViewParameters.searchId =
            search?.originalResults?.resultResume?.searchId;

          delete search.searchParameters.searchViewParameters.filter
            .externalCode;
          delete search.searchParameters.searchViewParameters.filter
            .connectionSource;
          delete search.searchParameters.searchViewParameters.filter.hotelName;

          _this.dataSources.hotel.search(identifier, search.searchParameters);
          searches[identifier] = search;
        }
      });

      return searches;
    },

    getPagedItemsResults: (result, itemsPerPage, currentPage) => {
      let begin = 0;
      let resultPageItens = result;
      let end = itemsPerPage;

      if (currentPage > 1) {
        begin = itemsPerPage * (currentPage - 1);
        end = begin + itemsPerPage;
      }

      resultPageItens
        ? (resultPageItens.flightOptions = [...result.fullFlightOptions])
        : null;
      resultPageItens
        ? (resultPageItens.flightOptions = resultPageItens.flightOptions.slice(
            begin,
            end,
          ))
        : null;
      return resultPageItens;
    },

    onPageClicked: (currentPage) => {
      let searches = this.state.searches;
      let identifier = this.state.activeTabIdentifier;
      let tripPartSelectedIndex = searches[identifier].tripPartSelectedIndex;

      searches[identifier].paginationParameters[
        tripPartSelectedIndex
      ].currentPage = currentPage;

      this.setState({ searches: this.searchManager.applyPagination(searches) });
    },

    showFaresIndexes: (index) => {
      let searches = this.state.searches;
      let identifier = this.state.activeTabIdentifier;
      let search = searches[identifier];
      let faresList = search.showFaresIndexes[search.tripPartSelectedIndex];

      let fareIndex = faresList.indexOf(index);

      if (fareIndex == -1) {
        faresList.push(index);
      } else {
        faresList.splice(fareIndex, 1);
      }

      searches[identifier].showFaresIndexes[search.tripPartSelectedIndex] =
        faresList;

      this.setState({ searches: searches });
    },

    changeLoaderVisibility(identifier, show) {
      let searches = this.state.searches;
      searches[identifier].loading = show;
      this.setState({
        searches: searches,
      });
    },

    redoSearch: () => {
      let searches = this.state.searches;
      let identifier = this.state.activeTabIdentifier;
      let searchParameters = searches[identifier].searchParameters;
      let search = searches[identifier];

      if (search.productType == 'air') {
        let apiRequest = this.convertAirSearchDataToApiRequest(
          searchParameters,
          identifier,
        );

        search.loading = true;
        search.displayActionsTabPanel = false;
        search.clearTripsSelected = true;
        delete search.tariffedPriceFareResult;

        this.dataSources.airSearch.callLoad({
          request: apiRequest.dataApiRequest,
          identifier: identifier,
        });
      } else if (search.productType == 'hotel') {
        search.loading = true;
        search.displayActionsTabPanel = false;

        search.searchParameters.searchViewParameters.searchId = null;

        this.dataSources.hotel.search(identifier, search.searchParameters);
      }

      this.setState({
        searches: searches,
      });
    },

    activateTabExpired: (multipleTrip) => {
      let searches = { ...this.state.searches };
      let checkoutData = this.state.checkoutData;

      if (multipleTrip) {
        searches[this.state.activeTabIdentifier].displayActionsTabPanel = true;
      } else {
        checkoutData.productsData.trips &&
          checkoutData.productsData.trips.map((flights, index) => {
            if (Array.isArray(flights)) {
              flights.map((travel) => {
                travel.trips.map((trip) => {
                  searches[
                    trip.formattedObject.identifier
                  ].displayActionsTabPanel = true;
                });
              });
            } else {
              flights.trips.map((trip) => {
                searches[
                  trip.formattedObject.identifier
                ].displayActionsTabPanel = true;
              });
            }
          });
      }

      return searches;
    },

    tariffedPriceExpiredMultipleTrip: () => {
      this.setState({
        searches: this.searchManager.activateTabExpired(true),
        displayPopupWarning: false,
      });
    },

    dataCompressor: {
      getAirCompressedSearchResult: (search) => {
        let _this = this;

        let searchResults = search.originalResults;
        let searchParameters = search.searchParameters;
        let convertedSearchResults = [];

        searchResults.forEach((result) => {
          let tripOptions = [];

          if (searchParameters.tripType.value == enums.tripTypes.multipleTrips)
            tripOptions = result.fullFlightOptions.slice(0, 10);
          else tripOptions = result.fullFlightOptions.slice(0, 50);

          let searchResult = {
            count: result.fullFlightOptions.length,
            items: tripOptions.map((item) => {
              item.fareOptions[0].originalFare.travellersFareValues.forEach(
                (tValue) => {
                  if (tValue.type == enums.passengerType.adult)
                    tValue.travellersCount = searchParameters.passengers.adults;
                  if (tValue.type == enums.passengerType.child)
                    tValue.travellersCount =
                      searchParameters.passengers.childrens;
                },
              );

              return {
                trips: item.trips.map((trip) => {
                  return {
                    departure: {
                      iata: trip.departure.iata,
                      name: trip.departure.fullName,
                    },
                    arrival: {
                      iata: trip.arrival.iata,
                      name: trip.arrival.fullName,
                    },
                    departureDate:
                      _this.searchManager.dataCompressor.clearDateObj(
                        trip.departure.date,
                      ),
                    arrivalDate:
                      _this.searchManager.dataCompressor.clearDateObj(
                        trip.arrival.date,
                      ),
                    issuerAirline: trip.airLine,
                    duration: trip.duration.durationInMinutes,
                    stops: trip.stops,
                    baggages: item.fareOptions[0].baggages,
                    firstFlightCabinType: trip.airClass.id,
                  };
                }),
                fares: [
                  {
                    travellersFareValues:
                      item.fareOptions[0].originalFare.travellersFareValues,
                    inPolicy: item.fareOptions[0].inPolicy,
                    agreementApplied: item.fareOptions[0].agreementApplied,
                  },
                ],
              };
            }),
          };

          convertedSearchResults.push(searchResult);
        });

        return {
          searchId: searchParameters.searchId,
          searchData: JSON.stringify(
            this.convertAirSearchDataToApiRequest(searchParameters)
              .dataApiRequest,
          ), //Request da Pesquisa
          additionalProperties: {
            additionalProp1: JSON.stringify({
              isMultiple:
                searchParameters.tripType.value ==
                enums.tripTypes.multipleTrips,
            }),
          },
          searchResults: compressorResolver.compress(convertedSearchResults),
          selectedOption: [],
          cheapestOption: compressorResolver.compress(
            convertedSearchResults[0].items[0],
          ),
          equivalentOption: '',
        };
      },

      getHotelCompressedSearchResult: (search) => {
        let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
        let getFormattedRounded = utils.formatters.money.getFormattedRounded;
        let getFormatted = utils.formatters.money.getFormatted;
        let searchResults = search.originalResults.results;
        let searchParameters = { ...search.searchParameters };
        let convertedSearchResults = [];

        searchResults.forEach((hotel, index) => {
          let fare = hotel.rooms[0].original.fares[0].roomValue;
          let days = hotel.rooms[0].original.fares[0].fareInformation.days;
          let totalDaily = {
            currencySymbol: getCurrencySymbol(fare.dailyFare.currencyCode),
            value: getFormatted(fare.dailyFare.value + fare.dailyTax.value),
          };

          convertedSearchResults.push({
            hotel: {
              images: hotel.images,
              name: hotel.name,
              evaluation: {
                rating: hotel.stars,
                source: hotel.rooms[0].supplier,
              },
              services: hotel.rooms[0].mealOption,
              localization: `${hotel.location.name}, ${hotel.location.city} - ${hotel.location.state}`,
              description: hotel.description,
            },
            fare: {
              totalDaily: totalDaily,
              markupedDailyFare: `${getCurrencySymbol(
                fare.markupedDailyFare.currencyCode,
              )} ${getFormatted(
                (fare.dailyTax.value + fare.markupedDailyFare.value) * days,
                fare.markupedDailyFare.currencyCode,
              )}`,
              dailyFare: `${getCurrencySymbol(
                fare.dailyFare.currencyCode,
              )} ${getFormatted(
                (fare.dailyTax.value + fare.dailyTax.value) * days,
                fare.dailyFare.currencyCode,
              )}`,
              dailyTaxes: `${getCurrencySymbol(
                fare.dailyTax.currencyCode,
              )} ${getFormatted(
                fare.dailyTax.value,
                fare.dailyTax.currencyCode,
              )} `,
              total: `${getCurrencySymbol(
                fare.dailyTax.currencyCode,
              )} ${getFormatted(
                (fare.dailyTax.value + fare.dailyTax.value) * days,
                fare.dailyTax.currencyCode,
              )} `,
              days: days,
            },
          });
        });

        let searchResult = {
          count: search?.originalResults?.resultResume?.totalResults || 0,
          items: convertedSearchResults,
        };

        delete searchParameters.searchViewParameters;

        return {
          searchId: search?.originalResults?.resultResume?.searchId,
          searchData: JSON.stringify(searchParameters), //Request da Pesquisa
          additionalProperties: null,
          searchResults: null, //compressorResolver.compress(searchResult),
          selectedOption: null,
          cheapestOption: null, //compressorResolver.compress(searchResult.items[0]),
          equivalentOption: '',
        };
      },

      clearDateObj: (dateObj) => {
        return {
          day: dateObj.day,
          month: dateObj.month,
          year: dateObj.year,
          hour: dateObj.hour,
          minute: dateObj.minute,
        };
      },
    },
  };

  shoppingCartManager = {
    selectGroupToIncludeProduct: (shoppingCart, travelersCounter) => {
      let isFound = false;
      let selectedGroupIndex = 0;

      shoppingCart.forEach((group, index) => {
        if (
          !isFound &&
          (!group.travelers ||
            (group.travelers.adultCount == travelersCounter.adultCount &&
              group.travelers.childCount == travelersCounter.childCount))
        ) {
          selectedGroupIndex = index;
          isFound = true;
          return;
        }
      });
      if (!isFound) {
        this.shoppingCartManager.includeGroup(shoppingCart);

        selectedGroupIndex = shoppingCart.length - 1;
      }

      return selectedGroupIndex;
    },

    includeAirProducts: (selectedTrips, hasPreBooking) => {
      const search = this.state.searches[this.state.activeTabIdentifier];
      const shoppingCart = [...this.state.shoppingCart];

      selectedTrips.forEach(trip => {
        if (search.compressedSearchResult?.searchData)
          trip.searchData = JSON.parse(search.compressedSearchResult.searchData);
      });

      const travelers = {
        adultCount: search.searchParameters.passengers.adults ?? 0,
        childCount: (search.searchParameters.passengers.childrens ?? 0) + (search.searchParameters.passengers.infants ?? 0)
      };

      const selectedGroupIndex =
        this.shoppingCartManager.selectGroupToIncludeProduct(
          shoppingCart,
          travelers,
        );

      shoppingCart[selectedGroupIndex].travelers = travelers;
      shoppingCart[selectedGroupIndex].hasPreBooking = hasPreBooking
        ? hasPreBooking
        : false;
      let firstGroup = shoppingCart[selectedGroupIndex];
      firstGroup.searchData = {};

      if (!hasPreBooking) {
        if (
          !this.shoppingCartManager.allowIncludeOnGroup(
            shoppingCart[selectedGroupIndex],
            selectedTrips,
          )
        )
          return;
      }
      if (selectedTrips.length > 1) {
        selectedTrips.forEach((product) => {
          product.groupIndex = 0;
        });

        firstGroup.products.travels.push({
          groupIndex: 0,
          selected: false,
          tripList: this.shoppingCartManager.multipleTripsObj(selectedTrips),
        });
      } else {
        selectedTrips[0].groupIndex = 0;
        firstGroup.products.travels.push(selectedTrips[0]);
      }

      this.setState({ shoppingCart: shoppingCart });
    },

    includeHotelProducts: (selectedHotel, guestsCounter) => {
      let shoppingCart = [...this.state.shoppingCart];
      let index = this.shoppingCartManager.selectGroupToIncludeProduct(
        shoppingCart,
        guestsCounter,
      );

      shoppingCart[index].travelers = guestsCounter;
      shoppingCart[index].products.hotels.push(selectedHotel);

      this.setState({ shoppingCart: shoppingCart });
    },

    multipleTripsObj: (selectedTrips) => {
      let trips = [];
      let embarkTaxes = 0;
      let fare = 0;
      let serviceTaxes = 0;
      let total = 0;
      let currencySymbol = '';
      let baggagesCurrentTrip = [];
      let originalFare;

      selectedTrips.forEach((trip) => {
        let currentTrip = trip.trips[0];
        currentTrip.fareOptions = trip.fareOptions;
        currentTrip.fareOptions.originalFare = trip.fareOptions.originalFare;
        baggagesCurrentTrip = trip.fareOptions.additionalInfo.baggage;
        currentTrip.baggage = trip.fareOptions.additionalInfo.baggage;
        trips.push(currentTrip);
      });

      let fareOptions = {
        price:
          this.state.searches[this.state.activeTabIdentifier]
            .tariffedPriceFareResult,
        additionalInfo: { baggage: baggagesCurrentTrip },
      };

      return {
        trips: trips,
        fareOptions: fareOptions,
      };
    },

    includeGroup: (shoppingCart) => {
      let group = {
        groupName: '',
        products: {
          selected: false,
          travels: [],
          hotels: [],
          searchData: {},
        },
        travelers: null,
      };
      let numberGroup = null;

      if (!shoppingCart) {
        let shoppingCart = this.state.shoppingCart;
        numberGroup = shoppingCart.length + 1;
        group.groupName = 'Grupo ' + numberGroup;

        shoppingCart.push(group);

        this.setState({ shoppingCart: shoppingCart });

        return;
      }

      numberGroup = shoppingCart.length + 1;
      group.groupName = 'Grupo ' + numberGroup;

      shoppingCart.push(group);
    },

    deleteProduct: (groupIndex, productIndex, productType) => {
      let shoppingCart = this.state.shoppingCart;
      if (productType === enums.productType.hotel) {
        shoppingCart[groupIndex].products.hotels.splice(productIndex, 1);
      } else {
        shoppingCart[groupIndex].products.travels.splice(productIndex, 1);
      }

      this.setState({ shoppingCart: shoppingCart });
    },

    deleteGroup: (groupIndex) => {
      let shoppingCart = this.state.shoppingCart;
      shoppingCart.splice(groupIndex, 1);
      this.setState({ shoppingCart: shoppingCart });
    },

    updateProducts: () => {},

    moveProduct: (groupIndex, productIndex, productType) => {
      let shoppingCart = this.state.shoppingCart;
      if (productType === enums.productType.hotel) {
        shoppingCart[groupIndex].products.hotels[productIndex].selected = true;
      } else {
        shoppingCart[groupIndex].products.travels[productIndex].selected = true;
      }

      this.setState({ shoppingCart });
    },

    moveAllProducts: () => {},

    onSelectedMoveTripProductChanged: (
      ev,
      groupIndex,
      productIndex,
      productType,
    ) => {
      let shoppingCart = this.state.shoppingCart;

      let isTravellersCompatible =
        !ev.target.checked ||
        !shoppingCart.some((item) => {
          return (
            item.products.hotels.some((hotel) => {
              return (
                hotel.selected &&
                item.travelers != shoppingCart[groupIndex].travelers
              );
            }) ||
            item.products.travels.some((travel) => {
              return (
                travel.selected &&
                item.travelers != shoppingCart[groupIndex].travelers
              );
            })
          );
        });

      if (isTravellersCompatible) {
        if (productType === enums.productType.hotel) {
          shoppingCart[groupIndex].products.hotels[productIndex].selected =
            ev.target.checked;
        } else {
          shoppingCart[groupIndex].products.travels[productIndex].selected =
            ev.target.checked;
        }
      } else {
        //Alert
        let popupWarning = this.state.popupWarning;

        popupWarning.messages = ['Esse produto não é compativel com o grupo'];

        popupWarning.okButtonLabel = 'Ok';
        popupWarning.title = 'Aviso';

        popupWarning.okButtonClick = this.events.cancelButtonClick;

        this.setState({
          popupWarning: popupWarning,
          displayPopupWarning: true,
        });
      }

      this.setState({ shoppingCart: shoppingCart });
    },

    renameGroup: (ev, groupIndex) => {
      let shoppingCart = this.state.shoppingCart;

      shoppingCart[groupIndex].groupName = ev.target.value;

      this.setState({ shoppingCart: shoppingCart });
    },

    sendBudget: (groupIndex) => {
      let dataSource = this.externalOptions.dataSources.budget;
    },

    onHideMoveProductsClicked: (shoppingCartUpdate) => {
      let shoppingCart = shoppingCartUpdate
        ? shoppingCartUpdate
        : this.state.shoppingCart;

      shoppingCart.forEach((group) => {
        group.products.travels.forEach((product) => {
          product.selected = false;
        });

        group.products.hotels.forEach((product) => {
          product.selected = false;
        });
      });

      this.setState({ shoppingCart: shoppingCart });
    },

    onMoveProductConfimrShoppingCartClicked: (
      selectGroupsListValue,
      indexGroupSeleted,
    ) => {
      let shoppingCart = this.state.shoppingCart;
      let shoppingCartCurrent = [...this.state.shoppingCart];
      let shoppingCartProductsSelected = [];
      let shoppingCartHotelProductsSelected = [];

      if (
        shoppingCartCurrent[selectGroupsListValue].travelers == null ||
        shoppingCartCurrent[selectGroupsListValue].travelers == undefined
      ) {
        shoppingCartCurrent[selectGroupsListValue].travelers =
          shoppingCart[indexGroupSeleted].travelers;
      }

      shoppingCart.forEach((group) => {
        group.products.travels.forEach((product) => {
          if (product.selected == true)
            shoppingCartProductsSelected.push({ ...product });
        });
        group.products.hotels.forEach((product) => {
          if (product.selected == true)
            shoppingCartHotelProductsSelected.push({ ...product });
        });
      });

      shoppingCartProductsSelected.forEach((productSelected) => {
        let index = shoppingCartCurrent[
          productSelected.groupIndex
        ].products.travels.findIndex(
          (p) => p.productIndex == productSelected.productIndex,
        );

        if (
          this.shoppingCartManager.allowIncludeOnGroup(
            shoppingCartCurrent[selectGroupsListValue],
            [productSelected],
          )
        ) {
          shoppingCartCurrent[
            productSelected.groupIndex
          ].products.travels.splice(index, 1);
          shoppingCartCurrent[selectGroupsListValue].products.travels.push(
            productSelected,
          );
        } else return;
      });

      shoppingCartHotelProductsSelected.forEach((productSelected) => {
        let index = shoppingCartCurrent[
          productSelected.groupIndex
        ].products.hotels.findIndex(
          (p) => p.productIndex == productSelected.productIndex,
        );
        shoppingCartCurrent[productSelected.groupIndex].products.hotels.splice(
          index,
          1,
        );
        shoppingCartCurrent[selectGroupsListValue].products.hotels.push(
          productSelected,
        );
      });

      this.shoppingCartManager.onHideMoveProductsClicked(shoppingCartCurrent);
    },
    allowIncludeOnGroup: (shoppingCart, selectedTrips) => {
      let travels = shoppingCart.products.travels;
      let golTravels = travels.filter((t) =>
        t.trips.some((trip) => trip.airLine.code == 'G3'),
      );

      let hasGol = false;
      selectedTrips.forEach((st) => {
        hasGol = !hasGol && st.trips.some((trip) => trip.airLine.code == 'G3');
      });

      if (golTravels.length >= 2 && hasGol) {
        let popupWarning = this.state.popupWarning;

        popupWarning.messages = [
          'Só é possivel ter 2 voos da companhia Gol no mesmo grupo',
          'Favor escolher outra companhia aérea.',
        ];

        popupWarning.okButtonLabel = 'Ok';
        popupWarning.title = 'Aviso';

        popupWarning.okButtonClick = this.events.cancelButtonClick;

        this.setState({
          popupWarning: popupWarning,
          displayPopupWarning: true,
        });
        return false;
      }

      return true;
    },
  };

  checkoutManager = {
    includeProductToSendCheckout: (selectedTrips, travelers, searchIdentifier) => {

      if (searchIdentifier) {
       selectedTrips?.forEach(selectedTrip => {
        selectedTrip.searchData = JSON.parse(this.state.searches[searchIdentifier].compressedSearchResult.searchData);
       })
      }

      let selectedTripsCheckout =
        this.checkoutManager.getAirProductsData(selectedTrips);
      let checkoutData = this.state.checkoutData;

      selectedTripsCheckout.travelers = travelers;
      checkoutData.productsData = selectedTripsCheckout;

      this.setState({
        checkoutData,
        displayConfirmSendproductsCheckout: true,
        displayCheckout: false,
        stopCountingTariffedPriceExpiration: false,
      });

      this.checkoutManager.convertToPreBookingConfirmationRequest(
        selectedTripsCheckout,
      );
    },

    includeHotelProductToSendCheckout: (
      selectedHotel,
      travelers,
      currentIdentifier,
    ) => {
      let identifier = currentIdentifier
        ? currentIdentifier
        : this.state.activeTabIdentifier;

      let productsForCheckout = {
        trips: [],
        hotels: [],
        allTariffedPrice: {},
        travelers: {},
      };

      productsForCheckout.hotels = {
        selectedHotels: [],
      };

      productsForCheckout.travelers = travelers;

      this.checkoutManager
        .includeHotelProducts(selectedHotel, travelers)
        .map((item) => {
          productsForCheckout.hotels.selectedHotels.push(item);
        });

      let checkoutData = this.state.checkoutData;
      checkoutData.productsData = productsForCheckout;

      this.setState({
        //productsForCheckout: productsForCheckout,
        checkoutData,
        displayConfirmSendproductsCheckout: true,
        displayCheckout: false,
        stopCountingTariffedPriceExpiration: false,
      });

      this.checkoutManager.convertToPreBookingConfirmationRequest(
        productsForCheckout,
        identifier,
      );
    },

    includeGroupProductToSendCheckout: (groupIndex, productIndex) => {
      let checkoutData = this.state.checkoutData;
      let search = this.state.searches[this.state.activeTabIdentifier];
      let shoppingCart = this.state.shoppingCart;
      let cartProducts = shoppingCart[groupIndex].products;

      let travelOptions = {
        trips: [],
        hotels: [],
        allTariffedPrice: {},
        travelers: {},
      };

      let selectedTrips = [];

      shoppingCart[groupIndex].products.passengers =
        search.searchParameters.passengers;

      travelOptions = {
        ...this.checkoutManager.getAirProductsData(cartProducts.travels),
      };

      if (cartProducts.hotels.length) {
        let hotelTripsCheckout = {
          selectedHotels: [],
        };
        cartProducts.hotels.map((selectedHotel) => {
          this.checkoutManager
            .includeHotelProducts(
              selectedHotel,
              shoppingCart[groupIndex].travelers,
            )
            .map((item) => {
              hotelTripsCheckout.selectedHotels.push(item);
            });
        });
        travelOptions.hotels = hotelTripsCheckout;
      }
      travelOptions.travelers = shoppingCart[groupIndex].travelers;
      checkoutData.productsData = travelOptions;

      this.setState({
        checkoutData,
        displayConfirmSendproductsCheckout: true,
        stopCountingTariffedPriceExpiration: false,
      });

      this.checkoutManager.convertToPreBookingConfirmationRequest(
        travelOptions,
        null,
      );
    },

    getAirProductsData: (selectedAirOptions) => {
      let travelOptions = {
        trips: [],
        hotels: [],
        allTariffedPrice: {},
        tariffedPriceExpired: false,
        searchParameters: {
          travelers: null,
        },
      };

      let airOptions = [];
      let airOption = {
        trips: [],
        fares: {},
        tariffedPrice: {},
        searchParameters: {},
        searchData: selectedAirOptions ? selectedAirOptions[0].searchData : {}
      };
      let indexTrip = 0;

      selectedAirOptions.forEach((selectedAirOption) => {
        let searchIdentifier = '';
        if (selectedAirOption.trips) {
          searchIdentifier = selectedAirOption.trips[0].identifier;
        } else {
          searchIdentifier = selectedAirOption.tripList.trips[0].identifier;
        }

        const search = this.state.searches[searchIdentifier];
        const tripType = search.searchParameters.tripType.value;

        let tripOption = {
          trips: [],
          fares: {},
          tariffedPrice: {},
          searchParameters: {},
          searchData: selectedAirOption.searchData
        };

        if (selectedAirOption.trips) {
          selectedAirOption.trips.forEach((trip, index) => {
            let originalAirTrip = { ...trip.originalAirTrip };

            if (
              tripType == enums.tripTypes.goingAndReturn ||
              tripType == enums.tripTypes.going
            ) {
              originalAirTrip.partTrip =
                indexTrip == 0 ? enums.tripTypes.going : enums.tripTypes.return;
            } else {
              originalAirTrip.partTrip = enums.tripTypes.multipleTrips;
            }

            indexTrip++;

            originalAirTrip.formattedObject = trip;
            originalAirTrip.additionalInfo =
              selectedAirOption.fareOptions.additionalInfo;

            if (selectedAirOptions.length > 1)
              tripOption.trips.push(originalAirTrip);
            else airOption.trips.push(originalAirTrip);
          });
        } else {
          selectedAirOption.tripList.trips.forEach((trip, index) => {
            let originalAirTrip = { ...trip.originalAirTrip };

            if (
              tripType == enums.tripTypes.goingAndReturn ||
              tripType == enums.tripTypes.going
            ) {
              originalAirTrip.partTrip =
                indexTrip == 0 ? enums.tripTypes.going : enums.tripTypes.return;
            } else {
              originalAirTrip.partTrip = enums.tripTypes.multipleTrips;
            }

            indexTrip++;

            originalAirTrip.formattedObject = trip;
            originalAirTrip.fareOptions = {
              originalFare: trip.fareOptions.originalFare,
            };
            originalAirTrip.additionalInfo = trip.fareOptions.additionalInfo;

            if (selectedAirOptions.length > 1)
              tripOption.trips.push(originalAirTrip);
            else airOption.trips.push(originalAirTrip);
          });
        }

        if (selectedAirOption.tripList) {
          let productOption =
            selectedAirOptions.length > 1 ? tripOption : airOption;

          let fareOptions = { ...selectedAirOption.tripList.fareOptions };
          if (selectedAirOption.tripList.fareOptions.originalFare) {
            productOption.fares =
              selectedAirOption.tripList.fareOptions.originalFare;
            delete fareOptions.originalFare;
          } else {
            productOption.fares = {};
          }

          productOption.fares.fareOptions = fareOptions;
          productOption.searchParameters.separeResults =
            search.searchParameters.separeResults;

          airOptions.push(productOption);
        } else if (selectedAirOptions.length > 1) {
          let fareOptions = { ...selectedAirOption.fareOptions };
          tripOption.fares = selectedAirOption.fareOptions.originalFare;

          delete fareOptions.originalFare;
          tripOption.fares.fareOptions = fareOptions;

          tripOption.searchParameters.separeResults =
            search.searchParameters.separeResults;

          airOptions.push(tripOption);
        } else {
          let fareOptions = { ...selectedAirOption.fareOptions };
          airOption.fares = selectedAirOption.fareOptions.originalFare;

          delete fareOptions.originalFare;
          airOption.fares.fareOptions = fareOptions;

          airOption.searchParameters.separeResults =
            search.searchParameters.separeResults;

          airOptions.push(airOption);
        }

        if (!travelOptions.searchParameters.travelers)
          travelOptions.searchParameters.travelers = {
            ...search.searchParameters.passengers,
          };

        travelOptions.searchParameters.mock = search.searchParameters.mock;
      });

      travelOptions.trips.push(...airOptions);

      return travelOptions;
    },

    includeHotelProducts: (selectedHotels, travelers) => {
      let selectedHotelsLocal = [];
      if (selectedHotels.length) {
        selectedHotels.map((selectedHotel) => {
          selectedHotelsLocal.push(selectedHotel);
        });
      } else {
        selectedHotelsLocal.push(selectedHotels);
      }

      return selectedHotelsLocal.map((selectedHotel) => {
        let travelersRooms = selectedHotel.searchData
          ? selectedHotel.searchData.rooms
          : this.state.searches[selectedHotel.tabIdentifier].searchParameters
              .rooms;
        return {
          name: selectedHotel.hotel.name,
          searchId: selectedHotel.hotel.searchId,
          hotel: selectedHotel.hotel,
          searchData: selectedHotel.searchData,
          selectedRooms: selectedHotel.selectedRooms.map((room, index) => {
            let rommDetails = {
              room: room.original ? room.original : room,
            };

            travelersRooms[index].adultCount
              ? (rommDetails.adultCount = travelersRooms[index].adultCount)
              : null;
            travelersRooms[index].childCount
              ? (rommDetails.childCount = travelersRooms[index].childCount)
              : null;
            travelersRooms[index].infantCount
              ? (rommDetails.infantCount = travelersRooms[index].infantCount)
              : null;

            return rommDetails;
          }),
          comppressedAdditionalProperties:
            selectedHotel.hotel.comppressedAdditionalProperties,
        };
      });
    },

    convertToPreBookingConfirmationRequest(productsData, identifier) {
      let checkoutData = this.state.checkoutData;
      let checkoutProductsData = {};
      if (productsData) {
        checkoutProductsData = productsData;

        checkoutData.productsData = checkoutProductsData;

        //Remover setstate de metodo "convert" (principio de responsabilidade única)
        this.setState({
          checkoutData,
          displayConfirmSendproductsCheckout: true,
          displayCheckout: false,
          stopCountingTariffedPriceExpiration: false,
        });
      } else {
        checkoutProductsData = this.state.checkoutData.productsData;
      }
      let selectedFlights = [];

      checkoutProductsData.trips &&
        checkoutProductsData.trips.map((flights, index) => {
          if (Array.isArray(flights)) {
            flights.map((travel) => {
              travel.trips.map((trip, index) => {
                selectedFlights.push({
                  airTrip: trip.formattedObject.originalAirTrip,
                  fareInfo: travel.fares.fareInformations[index], //TODO THIAGO: verificar com WELL
                });
              });
            });
          } else {
            flights.trips.map((trip, index) => {
              let fareInfo = undefined;

              if (
                trip.fareOptions &&
                trip.fareOptions.originalFare.fareInformations
              ) {
                if (
                  trip.fareOptions.originalFare.fareInformations.length >=
                  index + 1
                ) {
                  fareInfo =
                    trip.fareOptions.originalFare.fareInformations[index];
                } else {
                  fareInfo = trip.fareOptions.originalFare.fareInformations[0];
                }
              } else if (flights.fares) {
                if (flights.fares.fareInformations.length >= index + 1) {
                  fareInfo = flights.fares.fareInformations[index];
                } else {
                  fareInfo = flights.fares.fareInformations[0];
                }
              } else {
                fareInfo =
                  trip.formattedObject.fareOptions.originalFare
                    .fareInformations[index];
              }

              selectedFlights.push({
                airTrip: trip.formattedObject.originalAirTrip,
                fareInfo: fareInfo, //TODO THIAGO: verificar com WELL
              });
            });
          }
        });

      let selectedHotels = checkoutProductsData.hotels
        ? checkoutProductsData.hotels
        : null;

      //Remover sendPreBooking de dentro do móetodo "convert" (principio de responsabilidade única)
      this.checkoutManager.sendPreBooking(
        selectedFlights,
        selectedHotels,
        checkoutProductsData,
        identifier,
      );
    },

    sendPreBooking: (
      selectedFlights,
      selectedHotels,
      productsData,
      identifier,
    ) => {
      let travelers = {};
      if (!identifier) identifier = this.state.activeTabIdentifier;

      if (productsData) {
        if (productsData.travelers) {
          travelers = {
            adults: productsData.travelers.adultCount,
            childrens: productsData.travelers.childCount,
            infants: productsData.travelers.infantCount,
          };
        } else if (
          productsData.searchParameters &&
          productsData.searchParameters.passengers
        ) {
          travelers = productsData.searchParameters.passengers;
        } else {
          travelers =
            this.state.searches[identifier].searchParameters.passengers;
        }
      }

      const searchData = this.getSearchData(productsData);

      let preBookingObj = {
        action: 0,
        travellers: this.convertPassengersToTravelers(travelers),
        mock:
          productsData.searchParameters && productsData.searchParameters.mock,
        searchData
      };
      if (selectedFlights.length) {
        preBookingObj.air = {
          selectedFlights: selectedFlights,
        };
      }
      if (
        selectedHotels.selectedHotels &&
        selectedHotels.selectedHotels.length
      ) {
        preBookingObj.hotel = selectedHotels;
      }

      this.dataSources.preBooking.callPreBooking(preBookingObj, null, true);
      this.dataSources.checkout.paymentForms.loadAllowedPaymentTypes();
    },

    startCountingTariffedPrice: () => {
      let initialTime = '00:59';
      let seconds = 59;
      let minutes = 0;
      let _this = this;
      let timeToExpire = this.state.timeToExpirePreCheckout;

      let time = setInterval(function () {
        let stopCountingTariffedPriceExpiration =
          _this.state.stopCountingTariffedPriceExpiration;

        if (!stopCountingTariffedPriceExpiration) {
          seconds--;

          if (seconds == 0) {
            minutes == 0 ? (minutes = 0) : minutes--;
            minutes == 0 ? (seconds = 0) : seconds == 59;
          }

          timeToExpire =
            '0' +
            minutes.toString() +
            ':' +
            (seconds <= 9 ? '0' : '') +
            seconds.toString();
        } else {
          timeToExpire = initialTime;
          clearInterval(time);
        }

        let checkoutData = _this.state.checkoutData;

        if (minutes == 0 && seconds == 0) {
          checkoutData.productsData.tariffedPriceExpired = true;

          timeToExpire = initialTime;
          clearInterval(time);
        }

        _this.setState({
          checkoutData,
          timeToExpirePreCheckout: timeToExpire,
        });
      }, 1000);
    },

    popupWarningExpiredAttempts: (cancelButtonClick, okButtonClick) => {
      let popupWarning = this.state.popupWarning;

      popupWarning.messages = [
        'O número de tentativas para tarifação excedeu!',
        'Será necessário refazer a pesquisa.',
      ];

      popupWarning.okButtonLabel = 'Ok';
      popupWarning.cancelButtonLabel = null;
      popupWarning.title = 'Aviso';
      popupWarning.cancelButtonClick = cancelButtonClick;
      popupWarning.okButtonClick = okButtonClick;

      this.setState({
        popupWarning: popupWarning,
        displayPopupWarning: true,
      });
    },

    createAirHashCode: (data) => {
      let trip = { ...data };
      let departureDate = trip.departureDate;
      let arrivalDate = trip.arrivalDate;

      if (typeof trip.departureDate == 'object')
        departureDate = JSON.stringify(trip.departureDate);

      if (typeof trip.arrivalDate == 'object')
        arrivalDate = JSON.stringify(trip.arrivalDate);

      return `${trip.departure}/${departureDate}/${trip.arrival}/${arrivalDate}/${trip.supplier}/${trip.issuerAirline}/${trip.stops}`;
    },

    createHotelHashCode: (data) => {
      let hotel = { ...data };
      return `${hotel.name}/${data.address.locationId}`;
    },
  };

  airFunctions = {
    fillFlightsAirline: (flightOptions) => {
      const _this = this;

      for (let foIndex = 0; foIndex < flightOptions.length; foIndex++) {
        const flightOption = flightOptions[foIndex];

        for (let tIndex = 0; tIndex < flightOption.trips.length; tIndex++) {
          const trip = flightOption.trips[tIndex];

          for (
            let fIndex = 0;
            fIndex < trip.originalAirTrip.flights.length;
            fIndex++
          ) {
            const flight = trip.originalAirTrip.flights[fIndex];
            flight.airLineObj = _this.getAirline(flight.airline);
          }
        }
      }
    },
  };
}
