import { FlashLogo } from '@components/FlashLogo';
import { PaymentFormsProps } from '..';
import { ProductTypeDictionary } from '@shared/enums/ProductType.enums';
import { NumericFormat } from 'react-number-format';
import { ApprovalStatus, CardStatus } from '../models';

import * as SC from './styled';

interface PaymentFormsItemProps {
  paymentForm: PaymentFormsProps;
  approvalStatus: ApprovalStatus;
}

export function PaymentFormsItem({ paymentForm }: PaymentFormsItemProps) {
  const isFlashPay = paymentForm.type === 5;
  const renderMessage = (status: CardStatus) => {
    switch (status) {
      case CardStatus.generated:
        return (
          <SC.Text variant="body3">
            Para o item <b>{ProductTypeDictionary[paymentForm.productType]}</b>{' '}
            foi gerado o seguinte cartão virtual:
          </SC.Text>
        );
      case CardStatus.withoutBalance:
        return (
          <SC.Text variant="body3">
            O cartão virtual corporativo não possui saldo suficiente para o
            pagamento da(s) reserva(s).
          </SC.Text>
        );
      case CardStatus.expired:
        return (
          <SC.Text variant="body3">
            O cartão virtual corporativo escolhido como forma de pagamento
            expirou.
          </SC.Text>
        );
      default:
        return (
          <SC.Text variant="body3">
            Para o item <b>{ProductTypeDictionary[paymentForm.productType]}</b>{' '}
            a forma de pagamento foi trocada para usar o pagamento padrão da
            empresa.
          </SC.Text>
        );
    }
  };

  return (
    <SC.Container>
      {!paymentForm.success && (
        <>
          {renderMessage(paymentForm.status)}
          {isFlashPay && (
            <SC.PaymentFormsItemContainer>
              <FlashLogo height="40" width="76" />
              <SC.FlashCardInfo>
                <SC.CardInfo>
                  <div>
                    <strong>
                      {paymentForm.creditCard.cardName} · Final{' '}
                      {paymentForm.creditCard.number}
                    </strong>
                    <p>{paymentForm.creditCard.printedName}</p>
                  </div>

                  {paymentForm.flashPayment &&
                    paymentForm.status === CardStatus.withoutBalance && (
                      <SC.FlashPaymentBalance>
                        <strong>
                          <NumericFormat
                            value={paymentForm.flashPayment.balance}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={'R$ '}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType="text"
                          />
                        </strong>
                        <p>Valor disponível</p>
                      </SC.FlashPaymentBalance>
                    )}
                </SC.CardInfo>
              </SC.FlashCardInfo>
            </SC.PaymentFormsItemContainer>
          )}
        </>
      )}
    </SC.Container>
  );
}
