'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import MessageTemplate from './message.template.js';
import { enums } from '@legacy-utils/enums';

export default class MessageComponent extends React.Component {
  initialRooms = null;
  componentRef = null;

  constructor(props) {
    super(props);

    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      inputText: '',
      checkboxObject: {
        traveler: true,
        requester: true,
        consultant: true,
        approver: true,
      },
      messageHistoric: [],
    };

    // cgm.services.GetMessages().then((response) => {
    //   this.getMessages(response);
    // });

    this.componentRef = React.createRef();
  }

  render() {
    return (
      <MessageTemplate.main
        onSendMessageClick={this.events.onSendMessageClicked}
        onCheckChange={this.events.onCheckChanged}
        inputText={this.state.inputText}
        currentUser={this.props.currentUser}
        messageHistoric={this.state.messageHistoric}
        checkboxObject={this.state.checkboxObject}
        onTextChanged={this.events.onTextChanged}
        componentRef={this.componentRef}
      />
    );
  }

  getMessages = function (response) {
    this.setState({ messageHistoric: response });
  };

  events = {
    onSendMessageClicked: function (event) {
      let messageHistoricLocal = this.state.messageHistoric;
      let checkboxObjectLocal = this.state.checkboxObject;

      if (
        !checkboxObjectLocal.traveler &&
        !checkboxObjectLocal.requester &&
        !checkboxObjectLocal.consultant &&
        !checkboxObjectLocal.approver
      ) {
        alert('selecione pelo menos 1 perfil');
        return;
      }
      if (this.state.inputText == '') {
        alert('Mensagem está vazio');
        return;
      }

      let destinationProfile = [];
      if (checkboxObjectLocal.traveler) {
        destinationProfile.push(enums.destinationProfile.traveler);
      }
      if (checkboxObjectLocal.requester) {
        destinationProfile.push(enums.destinationProfile.requester);
      }
      if (checkboxObjectLocal.consultant) {
        destinationProfile.push(enums.destinationProfile.consultant);
      }
      if (checkboxObjectLocal.approver) {
        destinationProfile.push(enums.destinationProfile.approver);
      }

      messageHistoricLocal.push({
        sender: this.props.currentUser,
        destinationProfile: destinationProfile,
        text: this.state.inputText,
      });

      this.setState({ messageHistoric: messageHistoricLocal });
      this.setState({ inputText: '' });

      $(this.componentRef.current).animate({
        scrollTop: this.componentRef.current.scrollHeight,
      });
    },

    onTextChanged: function (event) {
      this.setState({ inputText: event.target.value });
    },

    onCheckChanged: function (event, destinationProfile) {
      let checkboxObject = this.state.checkboxObject;

      switch (destinationProfile) {
        case enums.destinationProfile.all:
          checkboxObject.traveler = event.target.checked;
          checkboxObject.requester = event.target.checked;
          checkboxObject.consultant = event.target.checked;
          checkboxObject.approver = event.target.checked;
          break;
        case enums.destinationProfile.traveler:
          checkboxObject.traveler = event.target.checked;
          break;
        case enums.destinationProfile.requester:
          checkboxObject.requester = event.target.checked;
          break;
        case enums.destinationProfile.consultant:
          checkboxObject.consultant = event.target.checked;
          break;
        case enums.destinationProfile.approver:
          checkboxObject.approver = event.target.checked;
          break;
      }

      this.setState({ checkboxObject: checkboxObject });
    },
  };

  componentDidMount() {
    $(this.componentRef.current).animate({
      scrollTop: this.componentRef.current.scrollHeight,
    });
  }
}
