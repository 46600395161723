import React from 'react';
import { Box } from '@mui/material';
import {
  Typography,
  TextField,
  TextArea,
  SelectField,
  LinkButton,
  Button,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ApprovalCriteriaForm from './components/ApprovalCriteriaForm';
import { trpc } from '@api/bff/client';
import { useApprovalTable } from '../useApprovalTable';
import { ModalReorderingList } from './components/ModalReorderingList';

const criteriaSchema = yup.object().shape({
  condition: yup
    .string()
    .when('$index', ([index]) =>
      index === 0
        ? yup.string().notRequired()
        : yup.string().required('Condição é obrigatória'),
    ),
  field: yup.string().required('Campo é obrigatório'),
  operator: yup.string().required('Operador é obrigatório'),
  value: yup.string().required('Valor é obrigatório'),
});

export const schema = yup.object().shape({
  flowName: yup.string().required('Nome do fluxo é obrigatório'),
  priority: yup.string().required('Prioridade é obrigatória'),
  description: yup.string().required('Descrição é obrigatória'),
  criteria: yup
    .array()
    .of(criteriaSchema)
    .required('Pelo menos um critério é obrigatório'),
});

const AddApprovalFlow: React.FC = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      flowName: '',
      priority: '',
      description: '',
      criteria: [{ condition: '', field: '', operator: '', value: '' }],
    },
    context: { index: 0 },
  });
  const { refetch } = useApprovalTable();

  const createApprovalFlowMutation = trpc.createApprovalFlow.useMutation();

  const onSubmit = async (data: any) => {
    try {
      enum ConditionType {
        MANUAL = 'MANUAL',
        HIERARCHY = 'HIERARCHY',
        COST_CENTER_OWNER = 'COST_CENTER_OWNER',
      }

      const transformedData = {
        name: data.flowName,
        description: data.description,
        approvalType: 'AUTO',
        condition: [
          {
            conditionType: ConditionType.MANUAL, // Uso do enum
            value: '1',
            level: 1,
          },
        ],
        criteria: data.criteria.map((criterion: any) => ({
          type: criterion.field,
          operator: criterion.operator,
          relationshipCondition: criterion.condition || 'AND',
          value: criterion.value,
          complementValue: '',
        })),
        flowMode: 'SEQUENTIAL' as 'SEQUENTIAL' | 'PARALLEL',
      };

      await createApprovalFlowMutation.mutateAsync(transformedData);
      console.log('Approval flow created successfully');

      // Call refetch after successful creation
      await refetch();
    } catch (error) {
      console.error('Error creating approval flow:', error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <Typography variant={'headline6'}>
            Adicionar fluxo de aprovação
          </Typography>
        </Box>
        <Box p={2} borderRadius={4} border={1} borderColor={'#EBE6E9'} mb={4}>
          <Box>
            <Typography
              variant={'headline7'}
              color={'primary'}
              variantColor={'#FE2B8F'}
            >
              Informações básicas
            </Typography>
            <Typography variant={'body4'}>
              Insira as informações básicas do fluxo de aprovação, e quais serão
              os critérios para ativação
            </Typography>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <TextField
              label="Nome do fluxo"
              {...methods.register('flowName')}
              error={!!methods.formState.errors.flowName}
              helperText={methods.formState.errors.flowName?.message}
            />
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            my={2}
            justifyContent={'space-between'}
          >
            <Box width={'50%'}>
              <Controller
                name={`priority`}
                render={({ field }) => (
                  <SelectField
                    fullWidth
                    label="Prioridade"
                    onSelectChange={(_, selectedItem) => {
                      field.onChange(selectedItem?.value);
                    }}
                    options={[
                      { label: '1', value: '1' },
                      { label: '2', value: '2' },
                    ]}
                    error={!!methods.formState.errors.priority}
                    helperText={methods.formState.errors.priority?.message}
                  />
                )}
              />
            </Box>
            <Box>
              <ModalReorderingList />
              <LinkButton onClick={() => {}} variant="default">
                Reordenar fluxo
              </LinkButton>
            </Box>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Controller
              name={`description`}
              render={({ field, fieldState: { error } }) => (
                <TextArea
                  onChange={field.onChange}
                  value={field.value}
                  {...(field as any)}
                  placeholder={'Descrição do fluxo'}
                  errorMessage={error ? 'Campo obrigatório' : ''}
                />
              )}
            />
          </Box>
        </Box>
        <Box>
          <ApprovalCriteriaForm />
        </Box>
        <Box mt={4}>
          <Typography variant="headline10">Debug Error: Form State</Typography>
          <pre>{JSON.stringify(methods.formState.errors, null, 2)}</pre>
        </Box>
        <Box mt={2}>
          <Button type="submit" variant="primary">
            Salvar
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddApprovalFlow;
