import { trpc } from '@api/bff/client';
import { Spinner, Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { dispatchToast } from '@shared/toast';
import { PolicyStatus } from 'bff/src/routers/policies/enums';
import { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

type Props = {
  policy: { id?: string; status?: PolicyStatus };
};

export const TogglePolicyStatus = ({ policy }: Props) => {
  const updatePolicyStatus = trpc.updatePolicyStatus.useMutation();

  const [isChecked, setIsChecked] = useState(
    policy.status === PolicyStatus.ACTIVE,
  );

  const handleUpdateStatus = useDebouncedCallback(
    (id: string, value: boolean) => {
      return updatePolicyStatus.mutateAsync({
        id: id,
        status: value ? PolicyStatus.ACTIVE : PolicyStatus.INACTIVE,
      });
    },
    300,
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      alignItems="center"
      width={75}
    >
      <Toggle
        checked={isChecked}
        onChange={async (e) => {
          try {
            setIsChecked(e.target.checked);

            await handleUpdateStatus(policy.id, e.target.checked);
          } catch (error) {
            dispatchToast({
              type: 'error',
              content:
                'Ocorreu um erro ao alterar o status da política. Tente novamente',
            });
          }
        }}
      />

      {updatePolicyStatus.isLoading && <Spinner size={16} variant="primary" />}
    </Box>
  );
};
