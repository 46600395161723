'use strict';

import React from 'react';
import ProductFareTemplate from './product-fare.template.js';

export default class ProductFareComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ProductFareTemplate
        productType={this.props.productType ? this.props.productType : null}
        productFare={this.props.productFare ? this.props.productFare : null}
        actions={this.props.actions ? this.props.actions : null}
      />
    );
  }
}
