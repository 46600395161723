'use strict';

import React from 'react';
import TravelRequestsNotificationsTemplate from './travel-requests-notifications.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class TravelRequestsNotificationsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      displayNotificationsList: false,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <TravelRequestsNotificationsTemplate.main
          requestIdentifier={
            this.props.requestIdentifier ? this.props.requestIdentifier : null
          }
          notifications={
            this.props.notifications ? this.props.notifications : null
          }
          displayNotificationsList={this.state.displayNotificationsList}
          events={this.events}
        />
      </ErrorBoundary>
    );
  }

  events = {
    onOpenPopupClicked: () => {
      this.setState({ displayNotificationsList: true });
    },
    onCloseClicked: () => {
      this.setState({ displayNotificationsList: false });
    },
  };
}
