export function timeToMinutes(time: string): number | null {
  const regex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
  const match = time.match(regex);

  if (!match) {
    return null;
  }

  const hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);

  return hours * 60 + minutes;
}

export function minutesToTime(minutes: number | null): string | null {
  if (!minutes || typeof minutes !== 'number') return null;

  if (!Number.isInteger(minutes) || minutes < 0) {
    return null;
  }

  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(mins).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export function safeParseNumber(value: unknown): number | null {
  const parsed = Number(value);

  if (isNaN(parsed)) {
    return null;
  }

  return parsed;
}
