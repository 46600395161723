import React from 'react';
import { componentUtil } from '../../../utils/componentUtil.js';
import CreditCardComponent from '../credit-card/credit-card.component.js';
import Template from './payment-selector.template.js';

import { enums } from '../../../utils/enums.js';
import { listItemsResolver } from '../../../utils/resolvers/listItemresolver.js';
import { creditCardUtil } from '../../../utils/creditCardUtil.js';

export default class PaymentSelectorComponent extends React.Component {
  constructor(props) {
    super(props);

    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      cardData: {},

      popup: {
        show: false,
      },

      select: {
        data: this.props.cardData,
        required: this.props.required,
        forceValidation: this.props.forceValidation,
        items: [],
      },

      forceValidation: this.props.forceValidation,

      propsMemory: {
        items: null,
        selectedItem: null,
      },
    };
  }

  render() {
    return (
      <Template.main
        select={this.select.getOptions()}
        popup={this.popup.getOptions()}
        card={this.card.getOptions()}
        disabled={this.props.disabled}
      />
    );
  }

  componentDidMount() {
    this.select.dataSource.callLoad();
  }

  static newCreditCardIdentifier = '_new_cc';

  static getPaymentItemHash(payment) {
    return payment.value;
  }

  static isPaymentsEquals(itemsA, itemsB) {
    if (!itemsA || !itemsB) return false;

    // compare lengths - can save a lot of time
    if (itemsA.length != itemsB.length) return false;

    for (let i = 0; i < itemsA.length; i++) {
      let itemA = itemsA[i];

      if (
        itemsB.some(
          (itemB) =>
            PaymentSelectorComponent.getPaymentItemHash(itemB) ==
            PaymentSelectorComponent.getPaymentItemHash(itemA),
        )
      )
        return false;
    }

    return true;
  }

  static getDerivedStateFromProps(props, state) {
    let select = state.select;

    select.forceValidation = props.forceValidation;

    if (
      !PaymentSelectorComponent.isPaymentsEquals(
        props.items,
        state.propsMemory.items,
      )
    )
      select.items = PaymentSelectorComponent.resolveResponse(
        [...props.items],
        props.addCard,
      );

    if (props.selectedItem != state.propsMemory.selectedItem)
      select.data = props.selectedItem;

    return {
      select: select,
      propsMemory: {
        ...state.propsMemory,
        items: props.items,
        selectedItem: props.selectedItem,
      },
    };
  }

  static resolveResponse(paymentForms, addCard) {
    //Adiciona o item para incluir novo cartão de credito
    if (addCard) {
      paymentForms.push(
        listItemsResolver.getItem(
          PaymentSelectorComponent.newCreditCardIdentifier,
          '+ Adicionar Cartão de Crédito',
        ),
      );
    }

    return paymentForms;
  }
  static resolveCreditCardListItemLabel(cardNumber) {
    if (!cardNumber.includes('XXX'))
      cardNumber = creditCardUtil.maskCreditCardNumber(cardNumber);

    return 'Cartão (' + cardNumber + ')';
  }

  select = {
    getOptions: () => {
      const _this = this;
      return {
        props: this.state.select,
        events: {
          onChange: (selectedItem) => {
            if (
              selectedItem.value ==
              PaymentSelectorComponent.newCreditCardIdentifier
            ) {
              _this.setState({
                popup: { ..._this.state.popup, show: true },
                select: { ..._this.state.select, data: selectedItem }, //?
              });
            } else {
              _this.setState({
                select: { ..._this.state.select, data: selectedItem },
              });

              _this.props.onPaymentSelected &&
                _this.props.onPaymentSelected(selectedItem);
            }
          },
        },
      };
    },

    dataSource: {
      callLoad: () => {
        const _this = this;

        this.props.dataSource &&
          this.props.dataSource
            .load({
              products: this.props.products,
              supplier: this.props.supplier,
            })
            .then((paymentForms) => {
              const items = PaymentSelectorComponent.resolveResponse(
                paymentForms,
                this.props.addCard,
              );
              this.setState({
                select: { ...this.state.select, items },
              });
            });
      },
    },
  };

  card = {
    getOptions: () => {
      return {
        props: {
          data: this.state.cardData,
          forceValidation: this.state.forceValidation,
        },
        events: {
          onCardHolderChanged: (holder) => {
            this.setState({
              cardData: { ...this.state.cardData, holder },
            });
          },
          onCardNumberChanged: (number) => {
            let cardData = this.state.cardData;
            cardData.number = number;
            this.setState({ cardData });
          },
          onCardBrandCodeChanged: (brandCode) => {
            this.setState({
              cardData: { ...this.state.cardData, brandCode },
            });
          },
          onCardCvvChanged: (cvv) => {
            this.setState({
              cardData: { ...this.state.cardData, cvv },
            });
          },
          onCardExpirationDateChanged: (expirationDate) => {
            this.setState({
              cardData: { ...this.state.cardData, expirationDate },
            });
          },
        },
      };
    },
  };

  popup = {
    getOptions: () => {
      const _this = this;
      return {
        props: this.state.popup,
        events: {
          onCancelButtonClicked: () => {
            this.setState({
              popup: { ..._this.state.popup, show: false },
            });
          },
          onOkButtonClicked: () => {
            let cardData = _this.state.cardData;

            const validationResult = CreditCardComponent.validate(
              cardData.number,
              cardData.brandCode,
              cardData.holder,
              cardData.cvv,
              cardData.expirationDate,
            );
            if (validationResult.isValid) {
              let paymentOption = _this.state.select.items.find(
                (i) =>
                  i.value == PaymentSelectorComponent.newCreditCardIdentifier,
              );

              paymentOption.label =
                PaymentSelectorComponent.resolveCreditCardListItemLabel(
                  _this.state.cardData.number,
                );
              paymentOption.paymentForm = {
                creditCard: _this.state.cardData,
                paymentType: enums.paymentType.creditCard,
                newPayment: true,
              };

              this.setState({
                popup: { ..._this.state.popup, show: false },
                select: { ..._this.state.select, data: paymentOption },
              });

              _this.props.onPaymentSelected &&
                _this.props.onPaymentSelected({
                  ...paymentOption,
                });
            } else {
              this.setState({
                forceValidation: true,
              });
            }
          },
        },
      };
    },
  };
}
