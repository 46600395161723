import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Loader,
  SelectField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { dataSources } from '@legacy-dataSources/profile.dataSources';
import { CreditCard } from '@components/RequestTravel/RequestTravelOffline/TravelInfoPayment/CreditCard';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import useModalStore from '../../../../store/Modal.store';

export function TravelInfoPayment({ errors, control }) {
  const [optionsPayments, setOptionsPayments] = useState([]);
  const [newPaymentOptions, setNewPaymentOptions] = useState([]);

  const contextId = useTravelStore((state) => state.contextId);
  const costCenter = useTravelStore((state) => state.costCenter);
  const requester = useTravelStore((state) => state.requester);
  const traveler = useTravelStore((state) => state.traveler);
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);

  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const loadOptions = {
        contextId,
        products: 1,
        supplier: 'all',
        costCenter: costCenter?.id,
        travelers: traveler?.userId,
        requester: requester.userId,
      };
      const responses = await dataSources.paymentForms.load(loadOptions);
      const defaultOption = {
        label: '+ Adicionar forma de pagamento',
        value: 'new_payment',
      };
      setOptionsPayments([defaultOption, ...responses, ...newPaymentOptions]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [contextId, costCenter, traveler, requester, newPaymentOptions]);

  const onSaveCard = (cardData) => {
    const number = cardData.number.replace(/\s+/g, '');
    // Separar a data de validade em mês e ano
    const [expireMonth, expireYear] = cardData.expiry.split('/').map(Number);

    const newPaymentOption = {
      label: `Cartão Novo Final - ${cardData.number.slice(-4)}`,
      value: `card_${cardData.number}`,
      paymentForm: {
        type: 1,
        creditCard: {
          number: number,
          expireYear: expireYear,
          expireMonth: expireMonth,
          printedName: cardData.name,
          cardName: cardData.name,
          securityCode: cardData.cvc,
          provider: cardData.cardType,
        },
        instalments: 0,
        productType: 1,
      },
    };
    setNewPaymentOptions((prevOptions) => [...prevOptions, newPaymentOption]);
    setOptionsPayments((prevOptions) => [
      prevOptions[0],
      ...prevOptions.slice(1),
      newPaymentOption,
    ]);
    closeModal();
  };

  const onPaymentSelect = (selectedItem) => {
    if (selectedItem.value === 'new_payment') {
      openModal({
        title: 'Cadastre um novo cartão',
        subTitle:
          'Preencha as informações abaixo e cadastre um novo cartão para pagamento',
        content: <CreditCard onSaveCard={onSaveCard} />,
        isAlert: false,
      });
    }
  };

  return (
    <div
      className={'info-payment'}
      style={{ paddingTop: 20, paddingBottom: 20 }}
    >
      <Typography variant={'headline8'}>Informações de pagamento</Typography>
      <div style={{ display: 'flex', position: 'relative' }}>
        <div style={{ width: '50%' }}>
          <Controller
            control={control}
            name="paymentForms"
            render={({ field: { value, onChange, onBlur, ref } }) => (
              <SelectField
                onSelectChange={(event, selectedItem) => {
                  onChange(selectedItem);
                  onPaymentSelect(selectedItem);
                }}
                onFocus={loadOptions}
                searchable={true}
                value={value}
                options={optionsPayments}
                label={'Selecione a forma de pagamento'}
                error={!!errors.paymentForms}
                helperText={
                  <DisplayErrorFormValidation
                    errors={errors}
                    fieldName="paymentForms"
                  />
                }
                onBlur={onBlur}
                ref={ref}
              />
            )}
          />
        </div>
        {isLoading && (
          <div style={{ position: 'absolute', top: '26px', left: '16px' }}>
            <Loader size={'extraSmall'} variant={'secondary'} />
          </div>
        )}
      </div>
    </div>
  );
}
