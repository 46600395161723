import { addLead } from '@api/expenseGateway/expenseGateway.service';
import { TRACKING_LANDING_PAGE_CLICK } from '@shared/constants/Tracking.constants';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useTracking } from '@shared/hooks/useTracking';

export function useCreateLead(user) {
  const tracking = useTracking();
  const { selectedCompany } = useSelectedCompany();

  async function sendCreateLead(): Promise<boolean> {
    tracking.trackEvent({
      name: TRACKING_LANDING_PAGE_CLICK,
    });
    // Add CTA Here!
    try {
      await addLead({
        company: {
          name: selectedCompany.name,
          cnpj: selectedCompany.registrationNumber,
        },
        contact: {
          email: user.email,
          phone: user.phone,
          name: user.name,
        },
      });
      return true;
    } catch (err) {
      return false;
    }
  }
  return {
    sendCreateLead,
  };
}
