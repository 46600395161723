import * as SC from '../styled';
import React from 'react';

interface ModalHeaderProps {
  children: React.ReactNode;

  /**
   * Cancel event
   *
   */
  onCancelClick?(): void;

  /**
   * Close event
   *
   */
  onCloseClick?(): void;
  className?: string;
  hasDismissButton?: boolean;
  showDivider?: boolean;
  collapse?: boolean;
}
export function ModalHeaderRoot({
  onCancelClick,
  onCloseClick,
  children,
  className,
  hasDismissButton = false,
  showDivider = true,
  collapse = false,
}: ModalHeaderProps) {
  return (
    <SC.ModalHeaderContainer
      className={className}
      showDivider={showDivider}
      collapse={collapse}
    >
      <>
        {hasDismissButton && (
          <SC.IconClose onClick={onCloseClick || onCancelClick} />
        )}
        {children}
      </>
    </SC.ModalHeaderContainer>
  );
}
