import styled from 'styled-components';

interface ButtonContainerProps {
  isBothButtons: boolean;
}

export const ModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.neutral[100]};
  max-width: 100%;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: #1d161b;
`;
export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
  color: #1d161b;
`;
export const Children = styled.div``;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  justify-content: ${(props) =>
    props.isBothButtons ? 'space-between' : 'center'};
  margin-top: 30px;
`;

export const IconCustomModal = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: 15px;
`;
