'use strict';

import React from 'react';
import Template from './air-option.template.js';

export default class AirOptionComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Template.main
        flights={this.props.flights}
        fareData={this.props.fareData}
        actions={this.props.actions}
      />
    );
  }
}
