'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import HotelSearchResultsFilterTemplate from './hotel-search-result-filter.template.js';

export default class HotelSearchResultsFilterComponent extends React.Component {
  constructor(props) {
    super(props);

    for (let filter in this.managerFilters) {
      let componentFunctions = this.managerFilters[filter];
      componentUtil.react.bindMethods(componentFunctions, this);
    }

    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      openFilter: '',
      originalResult: this.props.originalResult,
      identifierName: this.props.identifierName,
      identifierFiltered: [],
      identifier: this.managerFilters.identifier,
      filters: this.convertToFilterOptions(),
      statusFilters: this.managerFilters.statusFilters,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      identifierName: props.identifierName,
    };
  }

  render() {
    return (
      <HotelSearchResultsFilterTemplate
        openFilter={this.state.openFilter}
        identifier={this.state.identifier}
        filterOptions={this.state.filters}
        identifierName={this.state.identifierName}
        onOpenPopupButtonClick={this.events.filter.show}
        onPopupCloseButtonClick={this.events.filter.cancel}
        onPopupApplyButtonClick={this.events.filter.apply}
        onApplyFiltersClick={this.events.onApplyFiltersClicked}
        onClearFiltersClick={this.events.onClearFiltersClicked}
        onOptionsListPriceChange={this.managerFilters.price.onChange}
        onOptionsListDistanceChange={this.managerFilters.distances.onChange}
        onOptionsListMealChange={this.managerFilters.meal.onChange}
        onOptionsListStarChange={this.managerFilters.star.onChange}
        onOptionsListProviderChange={this.managerFilters.provider.onChange}
      />
    );
  }

  events = {
    filter: {
      show: (identifier) => {
        this.setState({
          openFilter: identifier,
          filters: this.setFiltersValuesAppliedToCurrent(identifier),
        });
      },

      hide: () => {
        this.setState({ openFilter: '' });
      },

      cancel: (identifier) => {
        this.events.filter.hide();
      },

      apply: (identifier) => {
        switch (identifier) {
          case this.managerFilters.identifier.price:
            this.managerFilters.price.apply();
            break;
          case this.managerFilters.identifier.star:
            this.managerFilters.star.apply();
            break;
          case this.managerFilters.identifier.meal:
            this.managerFilters.meal.apply();
            break;
          case this.managerFilters.identifier.provider:
            this.managerFilters.provider.apply();
            break;
             case this.managerFilters.identifier.distances:
            this.managerFilters.distances.apply();
            break;
        }
        this.events.filter.hide(identifier);
      },
    },

    onApplyFiltersClicked: () => {
      let filters = this.state.filters;

      let parameters = {};

      if (
        filters.price.limits.min != filters.price.applied.min ||
        filters.price.limits.max != filters.price.applied.max
      ) {
        filters.price.filtered = { ...filters.price.applied };
        parameters.priceRange = {
          min: this.state.filters.price.applied.min,
          max: this.state.filters.price.applied.max,
        };

        filters.statusFilters.price.appliedFilter = true;
        filters.statusFilters.price.pendentFilter = false;
      }

      if (
        filters.distances.limits.min != filters.distances.applied.min ||
        filters.distances.limits.max != filters.distances.applied.max
      ) {
        filters.distances.filtered = { ...filters.distances.applied };
        parameters.distanceRange = {
          min: this.state.filters.distances.applied.min,
          max: this.state.filters.distances.applied.max,
        };

        filters.statusFilters.distances.appliedFilter = true;
        filters.statusFilters.distances.pendentFilter = false;
      }

      if (this.state.filters.star.some((item) => item.checked)) {
        parameters.ratings = this.state.filters.star
          .filter((item) => item.checked)
          .map((item) => item.value);

        filters.statusFilters.star.appliedFilter = true;
        filters.statusFilters.star.pendentFilter = false;
      }
      if (this.state.filters.meal.some((item) => item.checked)) {
        parameters.mealOptions = this.state.filters.meal
          .filter((item) => item.checked)
          .map((item) => {
            return item.value;
          });

        filters.statusFilters.meal.appliedFilter = true;
        filters.statusFilters.meal.pendentFilter = false;
      }
      if (this.state.filters.provider.some((item) => item.checked)) {
        parameters.suppliers = this.state.filters.provider
          .filter((item) => item.checked)
          .map((item) => {
            return item.value;
          });

        filters.statusFilters.provider.appliedFilter = true;
        filters.statusFilters.provider.pendentFilter = false;
      }
      if (this.props.searchedHotelName)
        parameters.hotelName = this.props.searchedHotelName;

      if(this.props.geoLocation)
        parameters.geoLocation = this.props.geoLocation;

      this.setState({ filters: filters });

      if (this.props.applyFilters) this.props.applyFilters(parameters);
    },

    onClearFiltersClicked: () => {
      this.setState({ filters: this.convertToFilterOptions() });

      if (this.props.applyFilters) this.props.applyFilters({});
    },
  };

  setFiltersValuesAppliedToCurrent = (identifier) => {
    let filters = this.state.filters;

    switch (identifier) {
      case this.managerFilters.identifier.price:
        filters.price.set = filters.price.applied;
        break;
      case this.managerFilters.identifier.star:
        filters.star.set = filters.star.applied;
        break;
      case this.managerFilters.identifier.meal:
        filters.meal.set = filters.meal.applied;
        break;
      case this.managerFilters.identifier.provider:
        filters.provider.set = filters.provider.applied;
        break;
        case this.managerFilters.identifier.distances:
        filters.distances.set = filters.distances.applied;
        break;
    }

    return filters;
  };

  managerFilters = {
    identifier: {
      price: 1,
      star: 2,
      meal: 3,
      provider: 4,
      distances: 5,
    },

    price: {
      onChange: (range) => {
        let filters = this.state.filters;
        filters.price.set.min = range[0] ? range[0] : filters.price.limits.min;
        filters.price.set.max = range[1] ? range[1] : filters.price.limits.max;

        this.setState({ filters: filters });
      },
      apply: () => {
        let filters = this.state.filters;
        filters.price.applied = filters.price.set;

        filters.statusFilters.price.pendentFilter = true;
        filters.statusFilters.price.appliedFilter = false;

        this.setState({ filters: filters });
      },
    },
    distances: {
      onChange: (range) => {
        let filters = this.state.filters;
        filters.distances.set.min = range[0] ? range[0] : filters.distances.limits.min;
        filters.distances.set.max = range[1] ? range[1] : filters.distances.limits.max;

        this.setState({ filters: filters });
      },
      apply: () => {
        let filters = this.state.filters;
        filters.distances.applied = filters.distances.set;

        filters.statusFilters.distances.pendentFilter = true;
        filters.statusFilters.distances.appliedFilter = false;

        this.setState({ filters: filters });
      },
    },
    star: {
      onChange: (event, index) => {
        let filters = this.state.filters;
        filters.star[index].checked = event.target.checked;
        this.setState({ filters: filters });
      },
      apply: () => {
        let filters = this.state.filters;

        filters.statusFilters.star.pendentFilter = true;
        filters.statusFilters.star.appliedFilter = false;

        this.setState({ filters: filters });
      },
    },
    meal: {
      onChange: (event, index) => {
        let filters = this.state.filters;
        filters.meal[index].checked = event.target.checked;
        this.setState({ filters: filters });
      },
      apply: () => {
        let filters = this.state.filters;

        filters.statusFilters.meal.pendentFilter = true;
        filters.statusFilters.meal.appliedFilter = false;

        this.setState({ filters: filters });
      },
    },
    provider: {
      onChange: (event, index) => {
        let filters = this.state.filters;
        filters.provider[index].checked = event.target.checked;
        this.setState({ filters: filters });
      },
      apply: () => {
        let filters = this.state.filters;

        filters.statusFilters.provider.pendentFilter = true;
        filters.statusFilters.provider.appliedFilter = false;

        this.setState({ filters: filters });
      },
    },
  };

  convertToFilterOptions = () => {
    let resultResume = this.props.originalResult.resultResume;
    let filters = [];

    let filterOptions = {};

    filterOptions.price = {
      currency: resultResume.currency,
      limits: {
        min: resultResume.prices ? resultResume.prices.min : 0,
        max: resultResume.prices ? resultResume.prices.max : 0,
      },
      set: {
        min: resultResume.prices ? resultResume.prices.min : 0,
        max: resultResume.prices ? resultResume.prices.max : 0,
      },
      applied: {
        min: resultResume.prices ? resultResume.prices.min : 0,
        max: resultResume.prices ? resultResume.prices.max : 0,
      },
      filtered: {
        min: resultResume.prices ? resultResume.prices.min : 0,
        max: resultResume.prices ? resultResume.prices.max : 0,
      },
    };

    filterOptions.distances = {
      limits: {
        min: resultResume.distances ? resultResume.distances.min : 0,
        max: resultResume.distances ? resultResume.distances.max : 0,
      },
      set: {
        min: resultResume.distances ? resultResume.distances.min : 0,
        max: resultResume.distances ? resultResume.distances.max : 0,
      },
      applied: {
        min: resultResume.distances ? resultResume.distances.min : 0,
        max: resultResume.distances ? resultResume.distances.max : 0,
      },
      filtered: {
        min: resultResume.distances ? resultResume.distances.min : 0,
        max: resultResume.distances ? resultResume.distances.max : 0,
      },
    };

    if (resultResume.stars) {
      filterOptions.star = resultResume.stars
        .sort((a, b) => (a > b ? 1 : -1))
        .map((item) => {
          return {
            label: (
              <span>
                <i
                  className={
                    'exon-button-icon__icon-star ' +
                    (item >= 1
                      ? 'exon-icon-star-filled_enabled'
                      : 'exon-icon-star-no-border-unfilled_disabled')
                  }
                ></i>
                <i
                  className={
                    'exon-button-icon__icon-star ' +
                    (item >= 2
                      ? 'exon-icon-star-filled_enabled'
                      : 'exon-icon-star-no-border-unfilled_disabled')
                  }
                ></i>
                <i
                  className={
                    'exon-button-icon__icon-star ' +
                    (item >= 3
                      ? 'exon-icon-star-filled_enabled'
                      : 'exon-icon-star-no-border-unfilled_disabled')
                  }
                ></i>
                <i
                  className={
                    'exon-button-icon__icon-star ' +
                    (item >= 4
                      ? 'exon-icon-star-filled_enabled'
                      : 'exon-icon-star-no-border-unfilled_disabled')
                  }
                ></i>
                <i
                  className={
                    'exon-button-icon__icon-star ' +
                    (item >= 5
                      ? 'exon-icon-star-filled_enabled'
                      : 'exon-icon-star-no-border-unfilled_disabled')
                  }
                ></i>
              </span>
            ),
            checked: false,
            disabled: false,
            value: item,
          };
        });
    } else {
      filterOptions.star = resultResume.stars;
    }

    if (resultResume.meals) {
      filterOptions.meal = resultResume.meals.map((item) => {
        return {
          label: item,
          checked: false,
          disabled: false,
          value: item,
        };
      });
    } else {
      filterOptions.meal = resultResume.meals;
    }

    if (resultResume.providers) {
      filterOptions.provider = resultResume.providers.map((item) => {
        return {
          label: item,
          checked: false,
          disabled: false,
          value: item,
        };
      });
    } else {
      filterOptions.provider = resultResume.providers;
    }

    filterOptions.statusFilters = {
      price: {
        appliedFilter: false,
        pendentFilter: false,
      },
      distances: {
        appliedFilter: false,
        pendentFilter: false,
      },
      star: {
        appliedFilter: false,
        pendentFilter: false,
      },
      meal: {
        appliedFilter: false,
        pendentFilter: false,
      },
      provider: {
        appliedFilter: false,
        pendentFilter: false,
      },
    };
    filters = filterOptions;

    return filters;
  };
}
