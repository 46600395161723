'use strict';

import React from 'react';
import Template from './analysis-items.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class AnalysisItemsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    let analysisItems = this.props.data.searchResults;

    Object.keys(analysisItems).forEach((identifier) => {
      analysisItems[identifier].showSearchResultsAnalysis = false;
    });

    this.state = {
      identifierActiveTab: Object.keys(analysisItems)[0],
      analysisItems: analysisItems,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          productType={this.props.productType}
          analysisItems={this.state.analysisItems}
          identifierActiveTab={this.state.identifierActiveTab}
          events={this.events}
        />
      </ErrorBoundary>
    );
  }

  events = {
    tabEvents: {
      onSelectTabClicked: (identifierActiveTab) => {
        this.setState({ identifierActiveTab });
      },
    },

    onViewModeClicked: () => {
      let analysisItems = this.state.analysisItems;
      let identifier = this.state.identifierActiveTab;

      analysisItems[identifier].showSearchResultsAnalysis =
        !analysisItems[identifier].showSearchResultsAnalysis;

      this.setState({ analysisItems });
    },
  };
}
