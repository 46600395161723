'use strict';

import React from 'react';
import { componentUtil } from '../../../utils/componentUtil.js';
import Template from './image-mosaic.template.js';

export default class ImageMosaicComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      show: false,
      images: props.images,
      moveClassCarroussel: '',
      imageIndexActive: 0,
    };
  }

  componentDidMount() {
    if (this.props.randomize) this.shuffleArray(this.state.images);
  }

  render() {
    return <Template {...this.state} props={this.props} events={this.events} />;
  }

  events = {
    onClick: () => {
      let imageIndexActive = this.state.images.findIndex(
        (image) => image.thumbImage == true,
      );
      this.setState({ show: !this.state.show, imageIndexActive });
    },

    onCurrentImageClick: (imageIndexActive, moveClass) => {
      let moveClassCarroussel = this.state.moveClassCarroussel;

      if (this.state.images.length > 6) moveClassCarroussel = moveClass;

      this.setState({ imageIndexActive, moveClassCarroussel });
    },
  };

  shuffleArray(inputArray) {
    let images = [...inputArray];

    images.sort(() => Math.random() - 0.5);

    let indexFixed = images.findIndex((item) => item.thumbImage == true);
    let itemFixed = images[indexFixed];

    images.splice(indexFixed, 1);
    images.unshift(itemFixed);

    this.setState({ images });
  }
}
