import { trpc } from '@api/bff/client';
import TitleSectionItem from '@legacy-components/TitleSectionItem';
import { PaymentItem } from '@models/PaymentItem';
import { FLASH_PAY_PAYMENT_TYPE } from '@shared/constants/FlashPayment.constants';
import { useContext, useMemo, useState } from 'react';
import { SessionContext } from '../../contexts/SessionContext/sessionContext';
import { REQUESTER_TRAVEL_PROFILES } from './constants';
import { LegacyPaymentInputs } from './inputs';
import { LegacyProductPayment } from './Legacy-Product-Payment';
import { Product } from './models';
import { PaymentOption, parseBalanceData } from './parsers';
import * as SC from './styled';

interface LegacyPaymentProps {
  products: Product[];
  onSelect: (payment: any) => void;
  isFlashPayEnabled: boolean;
  required: boolean;
  confirmedTravelers: any;
  travelersCount: number;
  company: Company;
  items: any;
  disableSelector: boolean;
  showValidationsFields: boolean;
}

export interface Company {
  isAgency: boolean;
  travelerProfile: number;
}

export const LegacyPayment = ({
  products: productsGroup,
  onSelect,
  isFlashPayEnabled,
  required,
  travelersCount,
  confirmedTravelers,
  company,
  items,
  disableSelector,
  showValidationsFields,
}: LegacyPaymentProps) => {
  const { employeeId: currentUserId } = useContext(SessionContext);
  const [paymentOptions, setPaymentOptions] = useState<PaymentOption[]>([]);
  const isCurrentUserARequesterProfile = REQUESTER_TRAVEL_PROFILES.includes(
    company.travelerProfile,
  );
  const enableFetch = useMemo(() => {
    return confirmedTravelers.length === travelersCount;
  }, [JSON.stringify(confirmedTravelers)]);

  const getTravelerIdsToUseInPayment = (confirmedTravelersIds: string[]) => {
    return company.isAgency || isCurrentUserARequesterProfile
      ? [currentUserId, ...confirmedTravelersIds].reduce((acc, cur) => {
          if (!acc.includes(cur)) {
            acc.push(cur);
          }
          return acc;
        }, [])
      : [currentUserId];
  };

  const payingEmployeesIds = useMemo(() => {
    const confirmedTravelersIds = confirmedTravelers
      .filter((traveler) => traveler?.externalId)
      .map((traveler) => traveler?.externalId);

    return getTravelerIdsToUseInPayment(confirmedTravelersIds);
  }, [JSON.stringify(confirmedTravelers)]);

  const updateFlashPayBalance = (
    paymentOption: PaymentOption,
    price: number,
    action: 'sum' | 'subtract',
  ) => {
    const actions = {
      sum: (a: number, b: number) => a + b,
      subtract: (a: number, b: number) => a - b,
    };
    const paymentFormIndex = data.findIndex(
      (option) => option.employee === paymentOption.flashPayment.employeeId,
    );
    data[paymentFormIndex].balance = actions[action](
      data[paymentFormIndex].balance,
      price,
    );
  };

  const onSelectPayment = (paymentData: PaymentOption, price: number) => {
    const newPaymentOptions = [...paymentOptions];
    const index = newPaymentOptions.findIndex(
      (option) => option.productType === paymentData.productType,
    );

    if (index === -1) newPaymentOptions.push(paymentData);
    else newPaymentOptions[index] = paymentData;

    if (isFlashPay(paymentData) && false) {
      updateFlashPayBalance(paymentData, price, 'subtract');
    }

    onSelect(newPaymentOptions);
    setPaymentOptions(newPaymentOptions);
  };

  let { data, isLoading, isFetching, error } = trpc.getForEmployees.useQuery(
    {
      employeeNanoIds: payingEmployeesIds,
    },
    {
      enabled: enableFetch,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 0,
      cacheTime: 0,
      retry: 0,
      queryKey: ['getForEmployees', { employeeNanoIds: payingEmployeesIds }],
    },
  );

  const paymentList = useMemo<PaymentItem[]>((): PaymentItem[] => {
    if (error || confirmedTravelers.length !== travelersCount) {
      return [];
    }
    if (data) {
      return parseBalanceData(data);
    }
    return [];
  }, [JSON.stringify(data), confirmedTravelers]);

  const onUncheck = (product: Product) => {
    let removedOption: PaymentOption;
    const newPaymentOptions = paymentOptions.filter((option) => {
      if (option.productType !== product.type) return true;
      removedOption = option;
    });

    if (isFlashPay(removedOption) && false)
      updateFlashPayBalance(removedOption, product.price, 'sum');

    onSelect(newPaymentOptions);
    setPaymentOptions(newPaymentOptions);
  };

  return (
    <div className="exon-payment-information exon-section-item">
      <TitleSectionItem title="Informações de pagamento" />
      <div className="exon-box-fields-row">
        <SC.ProductContainer>
          {(productsGroup || []).map((productGroup, index) => (
            <LegacyProductPayment
              productType={productGroup.type}
              key={index}
              showBadges={
                productGroup.separatePaymentPerProduct &&
                productsGroup.length > 1
              }
            >
              <LegacyPaymentInputs
                items={items}
                selectorDisabled={disableSelector}
                required={required}
                onSelect={onSelectPayment}
                onUncheck={onUncheck}
                travelers={confirmedTravelers}
                showValidationsFields={showValidationsFields}
                flashPayVisible={isFlashPayEnabled}
                travelersCount={travelersCount}
                isLoading={isLoading && isFetching}
                paymentList={paymentList}
                isUserARequesterProfile={isCurrentUserARequesterProfile}
                product={productGroup}
                hotelEnabled={true}
              />
            </LegacyProductPayment>
          ))}
        </SC.ProductContainer>
      </div>
    </div>
  );
};

function isFlashPay(paymentData: PaymentOption) {
  return paymentData?.type === FLASH_PAY_PAYMENT_TYPE;
}
