import React from 'react';
import { componentUtil } from '../../../utils/componentUtil.js';
import Template from './notifier.template.js';
import { enums } from '@legacy-utils/enums';

export default class NotifierComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      documentContent: this.props.budgetDocumentContent || '',
    };
    componentUtil.react.bindMethods(this.events, this);
  }

  render() {
    let notificationType =
      this.props.notificationType != undefined
        ? this.props.notificationType
        : enums.notificationType.email;
    return (
      <Template.main
        notificationType={notificationType}
        documentContent={this.state.documentContent}
        events={this.props.events}
        fieldValues={this.props.fieldValues}
        showAddresseeName={this.props.showAddresseeName}
        placeholder={this.props.placeholder}
      />
    );
  }

  componentDidMount() {
    this.dataSource.callPreviewTemplate();
  }

  dataSource = {
    callPreviewTemplate: () => {
      const _this = this;

      let dsEvents = this.props.dataSource.events || {};
      dsEvents.beforeLoad && dsEvents.beforeLoad(loadOptions);

      this.props.dataSource
        .previewTemplate()
        .then((content) => {
          if (content.errors && content.errors.length > 0) {
            let concatErrors = content.errors.map((e) => e.detail).join(' | ');
            _this.setState({ documentContent: concatErrors });
          } else {
            _this.setState({ documentContent: content.html });
          }
          dsEvents.afterLoad && dsEvents.afterLoad(content);
        })
        .catch((result) => {
          _this.setState({ documentContent: 'Falha ao carregar o template' });

          dsEvents.afterLoad && dsEvents.afterLoad(result);
        });
    },
  };
}
