import { enums } from '../../../../utils/enums';
import { utils } from '../../../../utils/utils';

let Template = (props) => {
  return (
    <div className="exon-hotel-cancellation-policies">
      {props.cancellationPolicies &&
        props.cancellationPolicies.length > 0 &&
        props.cancellationPolicies.map((item, index) => {
          return (
            <div key={index}>
                <div>
                  <h5>Políticas</h5>
                  <p>{item.description || "Politica de cancelamento não registrada"}</p>
                </div>

              {props.cancelDeadline && (
                <div>
                  <h5>Prazo para cancelamento</h5>
                  <p className="exon-hotel-cancellation-policies-cancel-deadline">
                    {utils.formatters.date.dateObjToString(
                      props.cancelDeadline,
                      enums.dateFormat.monthYearHourMinute,
                    )}
                  </p>
                </div>
              )}

              {item.endDate && (
                <div>
                  <h5>Reembolso</h5>
                  <p>
                    Reembolsável até:{' '}
                    {utils.formatters.date.dateObjToString(
                      item.endDate,
                      enums.dateFormat.monthYearHourMinute,
                    )}
                  </p>
                </div>
              )}

              {item.penalty && item.penalty.value != 0 && (
                <div>
                  <h5>Multas</h5>
                  <p>
                    {`${utils.converters.money.getCurrencySymbol(
                      item.penalty.currencyCode,
                    )} ${utils.formatters.money.getFormatted(
                      item.penalty.value,
                      item.penalty.currencyCode,
                    )}`}
                  </p>
                </div>
              )}
            </div>
          );
        })}

      {(!props.cancellationPolicies ||
        !props.cancellationPolicies.length > 0) &&
        !props.popupLoad && <p>Nenhuma politica encontrada!</p>}

      {props.popupLoad && (
        <span className="loader exon-icon-loader exon-hotel-loader-popup"></span>
      )}
    </div>
  );
};

export default Template;
