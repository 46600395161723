'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import RangeTimeTemplate from './range-time.template.js';
import { utils } from '@legacy-utils/utils';

export default class RangeTimeComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    const min = props.min ? RangeTimeComponent.convertToMinutes(props.min) : 0;
    const max = props.max
      ? RangeTimeComponent.convertToMinutes(props.max)
      : 1440;

    this.state = {
      min,
      max,
      step: 30,
      values: [min, max],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      min: props.min ? RangeTimeComponent.convertToMinutes(props.min) : 0,
      max: props.max ? RangeTimeComponent.convertToMinutes(props.max) : 1440,
      step: props.step ? props.step : 30,
    };
  }

  static convertToMinutes = (time) => {
    let hoursInMinutes = time.hour * 60;
    return hoursInMinutes + time.minute;
  };

  formatValues = (values) => {
    let firstTime = this.getHourMinuteFromTimeStamp(values[0]);
    let lastTime = this.getHourMinuteFromTimeStamp(values[1]);

    let separator = 'h';

    let start = utils.formatters.time.durationHoursMinuteFromDateObj(
      firstTime,
      separator,
    );
    let end = utils.formatters.time.durationHoursMinuteFromDateObj(
      lastTime,
      separator,
    );

    return [start, end];
  };

  getHourMinuteFromTimeStamp(timeStamp) {
    let hour = Math.floor(timeStamp / 60);
    let minute = timeStamp - hour * 60;

    if (hour == 24) {
      hour = 23;
      minute = 59;
    }

    return { hour, minute };
  }

  setPeriodHourAndMinute = (values) => {
    let period = {
      min: this.getHourMinuteFromTimeStamp(values[0]),
      max: this.getHourMinuteFromTimeStamp(values[1]),
    };
    return period;
  };

  render() {
    const formattedValues = this.formatValues(this.state.values);
    return (
      <RangeTimeTemplate
        {...this.state}
        formattedValues={formattedValues}
        events={this.events}
      />
    );
  }

  events = {
    onChange: (event, values) => {
      this.setState({ values });

      if (this.props.onValuesChange)
        this.props.onValuesChange(this.setPeriodHourAndMinute(values));
    },
  };
}
