import { enums } from '../../../utils/enums.js';
import { utils } from '../../../utils/utils.js';
import Feedback from '../../common/feedback/feedback.component.js';
import Popup from '../../popup/popup.component.js';
import AirProducts from '../../products-travel/air-product/air-product.component.js';
import ProductList from '../../products-travel/product-list/product-list.component.js';
import Select from '../../select/select.component.js';
import TextboxComponent from '../../textbox/textbox.component.js';
import { PurchaseInfos } from './purchase-infos';
import { useUserAuth } from '../../../../shared/hooks/useUserAuth';
import { PopupProcessBooking } from './popup-process';

var CheckoutTemplate = {
  main: ({
    //product List
    currentProducts,
    serviceRequestExisting,
    tripTotalValue,
    onDeleteCurrentProductClick,
    onDeleteRvProductClick,
    checkoutPreBookingData,

    //select RV
    selectActionRv,
    loadingSearchRV,
    requestNumber,
    loaderTravelRequest,
    onSearchExistingTravelRequestClick,
    onRequestNumberTextboxOnChange,
    getCustomValidationRS,

    //Lista de quartos
    roomOptions,
    hotelsOptions,
    showValidationAdditionalFieldsTraveler,

    //Centro de custo
    costCenterSplit,
    isCostCenterSplitEnabled,
    isCostCenterSplitAvailable,
    costCenterOptions,
    isCostCenterAllowed,

    //Motivos da Viagem
    reasonTravelOptions,

    //Formas de pagamento
    paymentInformationOptions,
    disablePaymentforms,

    //RV Infos
    travelers,
    travelersDataSources,
    onTravelerConfirm,
    onTravelerAutoCompleteInputChange,
    onValidationAdditionalFieldsClick,
    onCloseTravelerClick,

    //Custom Fields
    customFieldsOptions,

    //Garantia Hotel
    hotelGuaranteeOptions,
    showHotelGuarantee,

    showInvalidSamePassengerAlert,
    onCloseSamePassengerAlertClick,

    //ProcessBooking
    popupFeedbackData,
    popupFeedbackConfirmData,
    popupJustifyPolicyData,
    popupUnconfirmedRequestData,
    selectJustificationsOptions,
    eventsProcessBooking,
    showLoadingMask,
    isImportExternal,
    popupConfirmationAlertsData,

    showValidationsFields,
    showValidationsfieldsJustification,
    descriptionJustification,
    disabledFields,

    //AgencyAndCompany
    agencyAndCompany,
    requesterOptions,

    //Erros
    errorMessages,

    //Requisição off-line
    isRvOffline,
    eventsRvOffline,

    selectedHotel,
    isFlashPayEnabled,
    separatePaymentPerProduct,
    popupProcessBookingData,
  }) => {
    const userAuth = useUserAuth();
    return (
      <div className="exon-checkout">
        {showInvalidSamePassengerAlert && (
          <CheckoutTemplate.popupInvalidSamePassenger
            onCloseClick={onCloseSamePassengerAlertClick}
          />
        )}

        {!isRvOffline && (
          <CheckoutTemplate.travelRequest
            selectActionRv={selectActionRv}
            requestNumber={requestNumber}
            loaderTravelRequest={loaderTravelRequest}
            showValidationsFields={showValidationsFields}
            getCustomValidationRS={getCustomValidationRS}
            isImportExternal={isImportExternal}
            onRequestNumberTextboxOnChange={onRequestNumberTextboxOnChange}
            onSearchExistingTravelRequestClick={
              onSearchExistingTravelRequestClick
            }
          />
        )}

        {loadingSearchRV && !isRvOffline && (
          <div className="exon-box-common-loading">
            <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
            <p>
              Por favor aguarde!
              <br />
              Estamos carregando as informações...
            </p>
          </div>
        )}

        {errorMessages.length > 0 && !isRvOffline && (
          <div className="exon-checkout-errors">
            <div>
              <span className="exon-icon exon-icon-popup-info-unfilled_enabled"></span>
              {errorMessages.map((error, index) => {
                return <p key={index}>{error.message}</p>;
              })}
            </div>
          </div>
        )}

        {!loadingSearchRV && errorMessages.length == 0 && (
          <div className="exon-checkout-content">
            {!isRvOffline && (
              <CheckoutTemplate.purchaseSummary
                currentProducts={currentProducts}
                checkoutPreBookingData={checkoutPreBookingData}
                requestNumber={requestNumber}
                serviceRequestExisting={serviceRequestExisting}
                tripTotalValue={tripTotalValue}
                onDeleteCurrentProductClick={onDeleteCurrentProductClick}
                onDeleteRvProductClick={onDeleteRvProductClick}
                hotelRoomDetails={travelersDataSources}
              />
            )}
            <PurchaseInfos
              currentProducts={currentProducts}
              checkoutPreBookingData={checkoutPreBookingData}
              serviceRequestExisting={serviceRequestExisting}
              travelers={travelers}
              travelersDataSources={travelersDataSources}
              roomOptions={roomOptions}
              hotelsOptions={hotelsOptions}
              showValidationAdditionalFieldsTraveler={
                showValidationAdditionalFieldsTraveler
              }
              reasonTravelOptions={reasonTravelOptions}
              costCenterSplit={costCenterSplit}
              isCostCenterSplitEnabled={isCostCenterSplitEnabled}
              isCostCenterSplitAvailable={isCostCenterSplitAvailable}
              costCenterOptions={costCenterOptions}
              isCostCenterAllowed={isCostCenterAllowed}
              paymentInformationOptions={paymentInformationOptions}
              customFieldsOptions={customFieldsOptions}
              hotelGuaranteeOptions={hotelGuaranteeOptions}
              showHotelGuarantee={showHotelGuarantee}
              onTravelerConfirm={onTravelerConfirm}
              onTravelerAutoCompleteInputChange={
                onTravelerAutoCompleteInputChange
              }
              onValidationAdditionalFieldsClick={
                onValidationAdditionalFieldsClick
              }
              onCloseTravelerClick={onCloseTravelerClick}
              showValidationsFields={showValidationsFields}
              agencyAndCompany={agencyAndCompany}
              requesterOptions={requesterOptions}
              disabledFields={disabledFields}
              isImportExternal={isImportExternal}
              disablePaymentforms={disablePaymentforms}
              isRvOffline={isRvOffline}
              selectedHotel={selectedHotel}
              isFlashPayEnabled={isFlashPayEnabled}
              separatePaymentPerProduct={separatePaymentPerProduct}
            />
          </div>
        )}

        {!loadingSearchRV && errorMessages.length == 0 && !isRvOffline && (
          <CheckoutTemplate.actionsCheckout
            events={eventsProcessBooking.actions}
            isImportExternal={isImportExternal}
          />
        )}

        {!loadingSearchRV && errorMessages.length == 0 && isRvOffline && (
          <CheckoutTemplate.actionsRvOffline events={eventsRvOffline} />
        )}

        {popupFeedbackData.show && !isRvOffline && (
          <div className="exon-box-popup-policy-violation">
            <Popup
              okButtonLabel={popupFeedbackData.okButtonLabel}
              exonStyle={true}
              cancelButtonClick={
                eventsProcessBooking.feedbacks.onClosePopupFeedbackClick
              }
              okButtonClick={
                eventsProcessBooking.feedbacks.onViolationsConfirmClicked
              }
            >
              <Feedback
                description={popupFeedbackData.description}
                details={popupFeedbackData.details}
                feedbackType={popupFeedbackData.type}
                policyViolation={popupFeedbackData.policyViolation}
              />
            </Popup>
          </div>
        )}

        {popupFeedbackConfirmData.show && !isRvOffline && (
          <div className="exon-box-popup-feedback">
            <Popup
              okButtonLabel={'Abrir requisição gerada'}
              cancelButtonLabel={'Continuar em cotações'}
              exonStyle={true}
              cancelButtonClick={
                eventsProcessBooking.feedbacks.onCloseFeedbackConfirmClick
              }
              okButtonClick={
                eventsProcessBooking.feedbacks.onOpenRequestFeedbackConfirmData
              }
            >
              <Feedback
                description={popupFeedbackConfirmData.description}
                feedbackType={popupFeedbackConfirmData.type}
              />
            </Popup>
          </div>
        )}

        {popupJustifyPolicyData.show && !isRvOffline && (
          <div className="exon-box-popup-policy-violation">
            <CheckoutTemplate.popupJustifyPolicy
              select={selectJustificationsOptions}
              data={popupJustifyPolicyData}
              descriptionJustification={descriptionJustification}
              events={eventsProcessBooking.feedbacks}
              showValidationsfieldsJustification={
                showValidationsfieldsJustification
              }
            />
          </div>
        )}

        {popupConfirmationAlertsData.show && (
          <div className="exon-box-popup-feedback">
            <Popup
              okButtonLabel={popupConfirmationAlertsData.okButtonLabel}
              cancelButtonLabel={'Cancelar'}
              exonStyle={true}
              cancelButtonClick={
                eventsProcessBooking.feedbacks
                  .onClosePopupConfirmationAlertsClick
              }
              okButtonClick={
                eventsProcessBooking.feedbacks.onConfirmationAlertsClick
              }
            >
              <Feedback
                description={popupConfirmationAlertsData.description}
                feedbackType={popupConfirmationAlertsData.type}
              />
            </Popup>
          </div>
        )}

        {popupUnconfirmedRequestData.show && !isRvOffline && (
          <div className="exon-box-popup-unconfirmed">
            <CheckoutTemplate.popupUnconfirmedRequest
              data={popupUnconfirmedRequestData}
              events={eventsProcessBooking.feedbacks}
            />
          </div>
        )}

        {popupProcessBookingData.show && !isRvOffline && (
          <div className="exon-model-process-booking">
            <PopupProcessBooking data={popupProcessBookingData} />
          </div>
        )}

        {showLoadingMask && <CheckoutTemplate.loadingMask />}
      </div>
    );
  },

  travelRequest: ({
    selectActionRv,
    requestNumber,
    loaderTravelRequest,
    showValidationsFields,
    getCustomValidationRS,
    isImportExternal,

    onRequestNumberTextboxOnChange,
    onSearchExistingTravelRequestClick,
  }) => {
    let existingTravelRequest = selectActionRv.data.value == 2;

    return (
      <div className="exon-box-travel-request">
        <div>
          <label className="exon-label-field">Requisição de viagem</label>
          <Select
            data={selectActionRv.data}
            placeholder={selectActionRv.placeholder}
            externalOptions={selectActionRv.options}
          />
        </div>
        <div className={'exon-field' + (!existingTravelRequest ? ' hide' : '')}>
          <TextboxComponent
            placeholder={'nº requisição'}
            type={enums.components.textBoxType.number}
            onChange={onRequestNumberTextboxOnChange}
            value={requestNumber}
            required={true}
            customValidation={getCustomValidationRS}
            forceValidation={showValidationsFields}
          />
        </div>
        <button
          className={
            'exon-button exon-button-primary' +
            (!existingTravelRequest ? ' hide' : '')
          }
          onClick={onSearchExistingTravelRequestClick}
        >
          Buscar
          <span
            className={
              'exon-icon exon-icon-loader-unfilled_disabled' +
              (!loaderTravelRequest ? ' hide' : '')
            }
          ></span>
        </button>
      </div>
    );
  },

  purchaseSummary: ({
    currentProducts,
    checkoutPreBookingData,
    serviceRequestExisting,
    tripTotalValue,
    requestNumber,
    onDeleteCurrentProductClick,
    hotelRoomDetails,
  }) => {
    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;
    let getDurationHoursMinute = utils.formatters.time.durationHoursMinute;
    let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
    let getFormattedRounded = utils.formatters.money.getFormattedRounded;
    let getFormatted = utils.formatters.money.getFormatted;
    let itemsSummary =
      serviceRequestExisting && serviceRequestExisting.itemsSummary
        ? serviceRequestExisting.itemsSummary
        : null;
    let onDeleteProductClick =
      (currentProducts.trips.length > 0 &&
        currentProducts?.hotels?.selectedHotels?.length > 0 &&
        tripTotalValue) ||
      (currentProducts.trips.length > 1 && tripTotalValue) ||
      (currentProducts?.hotels?.selectedHotels?.length > 1 && tripTotalValue)
        ? onDeleteCurrentProductClick
        : null;

    return (
      <div className="exon-purchase-summary">
        <div className="exon-purchase-new-products">
          <label>Resumo da compra</label>
          <div className="exon-box-products">
            <ProductList
              products={currentProducts}
              preBooking={checkoutPreBookingData}
              dataSources={hotelRoomDetails}
              tariffedPriceFare={null}
              onDeleteProductClick={onDeleteProductClick}
              airActions={null}
              hotelActions={null}
              displayCheckbox={false}
              displaytariffedPriceFare={null}
            />
          </div>
        </div>

        {itemsSummary && itemsSummary.length > 0 && (
          <div className="exon-purchase-existing-products">
            <label className="exon-title-rs-existing">
              Na requisição de viagem
              <strong>{serviceRequestExisting.id}</strong>
              já constam os itens:
            </label>
            <div className="exon-box-products">
              {itemsSummary.map((product, index) => {
                if (product.type == enums.products.namedType.airBooking) {
                  return (
                    <div className="exon-products-item" key={index}>
                      <AirProducts
                        travels={{
                          trips: product.airTrips.map((trip, index) => {
                            return {
                              airLine: trip.issuerAirline,
                              airClass: {
                                id: null,
                                name: trip.firstFlightCabinType,
                              },
                              departure: {
                                iata: trip.departure.iata,
                                fullName: trip.departure.name,
                                date: getFormattedDateObjFromDateObject(
                                  trip.departureDate,
                                ),
                              },
                              arrival: {
                                iata: trip.arrival.iata,
                                fullName: trip.arrival.name,
                                date: getFormattedDateObjFromDateObject(
                                  trip.arrivalDate,
                                ),
                              },
                              stops: trip.stops,
                              duration: {
                                durationInMinutes: trip.duration,
                                durationFormatted: getDurationHoursMinute(
                                  trip.duration,
                                ),
                              },
                              agreementApplied: true,
                              inPolicy: true,
                              additionalInfo: {
                                baggage: ['1 bagagem de mão'],
                                cancellation: ['Valor não reembolsável'],
                                refund: ['Não reembolsável'],
                                seat: [
                                  'Marcação de Assento com Custo Adicional',
                                ],
                              },
                            };
                          }),
                        }}
                        showDots={product.airTrips.length > 1}
                      />
                      <div className="exon-price-trips">
                        <div className="exon-value-details">
                          <span className="exon-value-currency">
                            {getCurrencySymbol(
                              product.valueData.totalValue.currencyCode,
                            )}
                          </span>
                          <span className="exon-value">
                            {getFormattedRounded(
                              product.valueData.totalValue.value,
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }

                if (product.type == enums.products.namedType.hotelBooking) {
                  return <div key={index}></div>;
                }
                return <p>Nenhuma reserva encontrada</p>;
              })}
            </div>
          </div>
        )}

        <div hidden={true} className="exon-total-purchase">
          <p>Sua viagem está saindo por:</p>
          <span>
            {tripTotalValue &&
              `${getCurrencySymbol(tripTotalValue.currencyCode)} ${getFormatted(
                tripTotalValue.value +
                  (serviceRequestExisting &&
                  serviceRequestExisting.valueData &&
                  serviceRequestExisting.valueData.totalValue
                    ? serviceRequestExisting.valueData.totalValue.value
                    : 0),
                tripTotalValue.currencyCode,
              )}`}

            {!tripTotalValue && (
              <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
            )}
          </span>
        </div>
      </div>
    );
  },

  actionsCheckout: ({ events, isImportExternal }) => {
    return (
      <div className="exon-box-actions-checkbox">
        <button
          className="exon-button exon-button-secondary exon-button-secondary_enabled"
          onClick={events.onBookingClicked}
        >
          {isImportExternal ? 'Importar' : 'Reservar'}
        </button>
        {!isImportExternal && (
          <button
            className="exon-button exon-button-primary"
            onClick={events.onBookAndSubmitClicked}
          >
            Reservar e Submeter
          </button>
        )}
        {isImportExternal && (
          <button
            className="exon-button exon-button-primary"
            onClick={events.onBookAndSubmitClicked}
          >
            Submeter
          </button>
        )}
      </div>
    );
  },

  actionsRvOffline: ({ events }) => {
    return (
      <div className="exon-box-actions-checkbox">
        <button
          className="exon-button exon-button-secondary"
          onClick={events.onCancelRvOfflineClicked}
        >
          Cancelar
        </button>
        <button
          className="exon-button exon-button-primary"
          onClick={events.onCreateRvOfflineClicked}
        >
          Criar RV Offline
        </button>
      </div>
    );
  },

  popupInvalidSamePassenger: ({ onCloseClick }) => {
    return (
      <Popup
        okButtonLabel={'Ok'}
        title={'Seleção de viajantes inválida'}
        exonStyle={false}
        cancelButtonClick={onCloseClick}
        okButtonClick={onCloseClick}
      >
        Não é possível escolher o mesmo viajante mais de uma vez para uma mesma
        viagem. Selecione um viajante diferente.
      </Popup>
    );
  },

  popupJustifyPolicy: ({
    select,
    data,
    events,
    descriptionJustification,
    showValidationsfieldsJustification,
  }) => {
    return (
      <Popup
        okButtonLabel={'Confirmar'}
        title={null}
        exonStyle={true}
        cancelButtonClick={events.onClosePopupJustifyPolicyClick}
        okButtonClick={events.onJustifyConfirmClicked}
      >
        <p>
          <strong>{data.description}</strong>
        </p>

        <CheckoutTemplate.policyViolations policies={data.items} />

        <div>
          <label className="exon-label-field">
            Para seguir com esse RV, justifique:
          </label>
          <Select
            data={select.data}
            placeholder={select.placeholder}
            externalOptions={select.options}
            required={true}
            forceValidation={showValidationsfieldsJustification}
          />
        </div>
        <div
          className={
            'exon-field' +
            ((descriptionJustification == null ||
              descriptionJustification == '') &&
            showValidationsfieldsJustification
              ? ' exon-alert-border'
              : '')
          }
        >
          <textarea
            type="readonly"
            placeholder="Digite a justificativa"
            onChange={(e) => select.onChangeDescription(e.target.value)}
          ></textarea>
          {(descriptionJustification == null ||
            descriptionJustification == '') &&
            showValidationsfieldsJustification && (
              <span className="exon-msg-alert-field">campo obrigatório</span>
            )}
        </div>
      </Popup>
    );
  },

  policyViolations: ({ policies }) => {
    return policies.map((item, index) => {
      return (
        <div key={index} className="exon-box-items-popup">
          <p>{`- Política: ${item.policyName}`}</p>
          <p>{`- Regras:`}</p>
          {item.violatedRules.map((rule, index) => {
            return (
              <p key={index} className="exon-box-items-policy-rule">
                {rule.message[0]}
              </p>
            );
          })}
        </div>
      );
    });
  },

  popupUnconfirmedRequest: ({ data, events }) => {
    return (
      <Popup
        okButtonLabel={null}
        title={null}
        exonStyle={true}
        cancelButtonClick={events.onClosePopupUnconfirmedRequestClick}
        okButtonClick={null}
      >
        <span className="exon-icon exon-icon-popup-warning-unfilled_enabled"></span>

        <p>{data.description}</p>

        {data.items.map((item, index) => {
          return (
            <div key={index} className="exon-box-items-popup">
              <p>
                Produto:
                <strong>{item.title}</strong>-
              </p>
              <p>{item.description}</p>
            </div>
          );
        })}
      </Popup>
    );
  },

  loadingMask: () => {
    return (
      <div className="exon-box-loading-mask">
        <div className="exon-loading-mask"></div>
        <div className="exon-loading-item">
          <div className="exon-box-loading-bar">
            <div className="exon-loading-bar-after"></div>
            <span>
              <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
              Finalizando requisição de viagem...
            </span>
          </div>
        </div>
      </div>
    );
  },
};

export default CheckoutTemplate;
