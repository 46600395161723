'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import { listItemsResolver } from '../../utils/resolvers/listItemresolver.js';
import PassengersTemplate from './passengers.template.js';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';

export default class PassengersComponent extends React.Component {
  selected = null;

  externalOptions = {
    events: {
      onSelected: null,
    },
  };

  labels = {
    passageiros: 'Passageiros',
    adultos: 'Adultos',
    adicionarCriancas: 'Adicionar crianças',
    criancas: 'Criancas',
    bebes: 'Bebês',
  };

  constructor(props) {
    super(props);

    const { enableTravelersLimit } = useTravelPermissions();

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    };
    this.labels = { ...this.labels, ...props.labels };

    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.components.adult, this);

    if (!enableTravelersLimit) {
      componentUtil.react.bindMethods(this.components.children, this);
      componentUtil.react.bindMethods(this.components.infant, this);
    }

    this.state = {
      dataControlledByParent: props.data !== undefined,
      adults: enableTravelersLimit ? 1 : 0,
      childrens: 0,
      infants: 0,
      childrenVisibility: false,
      enableTravelersLimit: enableTravelersLimit,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let data = state.dataControlledByParent ? props.data : state;

    return {
      adults: data.adults || (state.enableTravelersLimit ? 1 : 0),
      childrens: state.enableTravelersLimit ? 0 : data.childrens || 0,
      infants: state.enableTravelersLimit ? 0 : data.infants || 0,
    };
  }

  render() {
    const { enableTravelersLimit } = this.state;

    return (
      <PassengersTemplate.main
        adultProps={this.components.adult.getProps()}
        childrenProps={
          enableTravelersLimit ? null : this.components.children.getProps()
        }
        infantProps={
          enableTravelersLimit ? null : this.components.infant.getProps()
        }
        labels={this.labels}
        childrenVisibility={this.state.childrenVisibility}
        onOpenChildrenClicked={
          enableTravelersLimit ? null : this.events.openChildrenClicked
        }
      />
    );
  }

  components = {
    adult: {
      getProps: function () {
        let _this = this;
        const maxAdults = _this.state.enableTravelersLimit ? 1 : 7;
        return {
          data: listItemsResolver.getRangeItem(this.state.adults),
          options: {
            dataSource: {
              load: function () {
                return new Promise((resolve) => {
                  resolve(listItemsResolver.resolveRange(1, maxAdults));
                });
              },
            },
            events: {
              onSelected: _this.state.enableTravelersLimit
                ? null
                : _this.events.onAdultSelected,
            },
          },
        };
      },
    },
    children: {
      getProps: function () {
        let _this = this;
        return {
          data: listItemsResolver.getRangeItem(this.state.childrens),
          options: {
            dataSource: {
              load: function () {
                return new Promise((resolve) => {
                  resolve(listItemsResolver.resolveRange(0, 4));
                });
              },
            },
            events: {
              onSelected: _this.events.onChildrenSelected,
            },
          },
        };
      },
    },
    infant: {
      getProps: function () {
        let _this = this;
        return {
          data: listItemsResolver.getRangeItem(this.state.infants),
          options: {
            dataSource: {
              load: function () {
                return new Promise((resolve) => {
                  resolve(listItemsResolver.resolveRange(0, 2));
                });
              },
            },
            events: {
              onSelected: _this.events.onInfantSelected,
            },
          },
        };
      },
    },
  };

  getData = function () {
    return {
      adults: this.state.adults,
      childrens: this.state.childrens,
      infants: this.state.infants,
    };
  };

  setData = function (data) {
    this.setState({
      adults: data.adults,
      childrens: data.childrens,
      infants: data.infants,
    });
  };

  setChildrenVisibility = (visible) => {
    this.setState({
      childrenVisibility: visible,
    });
  };

  events = {
    onAdultSelected: function (optionsSelected) {
      let quantity = optionsSelected.value;

      let state = this.state;
      state.adults = quantity;

      this.setState(state);

      this.callExternalEvent(state);
    },
    onChildrenSelected: function (optionsSelected) {
      let quantity = optionsSelected.value;

      let state = this.state;
      state.childrens = quantity;

      this.setState(state);

      this.callExternalEvent(state);
    },
    onInfantSelected: function (optionsSelected) {
      let quantity = optionsSelected.value;

      let state = this.state;
      state.infants = quantity;

      this.setState(state);

      this.callExternalEvent(state);
    },
    openChildrenClicked: function (event) {
      this.setChildrenVisibility(true);
    },
  };

  callExternalEvent = function (data) {
    if (this.externalOptions.events.onSelected)
      this.externalOptions.events.onSelected({
        adults: data.adults,
        childrens: data.childrens,
        infants: data.infants,
      });
  };
}
