import { saveServiceRequestItem } from '@api/connectOn/connectOn.service';
import { AdditionalComments } from '@components/RequestTravel/RequestTravelItemOffline/AdditionalComments';
import { AgencyReserve } from '@components/RequestTravel/RequestTravelItemOffline/AgencyReserve';
import { DetailsVehicle } from '@components/RequestTravel/RequestTravelItemOffline/DetailsVehicle';
import PolicyViolationAlert from '@components/RequestTravel/RequestTravelItemOffline/PolicyViolationAlert';
import { TravelTypeItem } from '@components/RequestTravel/RequestTravelItemOffline/TravelTypeItem';
import travelFormItemSchema from '@components/RequestTravel/RequestTravelItemOffline/validationFormItemSchema';
import * as SC from '@components/RequestTravel/RequestTravelOffline/styled';
import { Modal } from '@flash-tecnologia/hros-web-ui-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import { statusFromCodes } from '@legacy-utils/statusCodes';
import { RentalCompanyInfo } from '@shared/components/RentalCompanyInfo';
import {
  convertDateObjectToDate,
  convertDateToDateObject,
  convertDateToHourMinute,
} from '@shared/utils/date.utils';
import { normalize } from '@shared/utils/text.utils';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useLoading } from '../../../contexts/LoadingContext';
import { useTravelItemStore } from '../../../store/RequestTravelItem.store';
import { useTravelStore } from '../../../store/RequestTravel.store';
import dayjs from 'dayjs';
import useModalStore, { ModalState } from '../../../store/Modal.store';
import { useNavigate } from 'react-router-dom';

export interface RequestTravelItemOfflineProps {
  isModalOpened?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

export default function RequestTravelItemOffline({
  isModalOpened,
  onClose,
  onSuccess,
}: RequestTravelItemOfflineProps) {
  const { showLoading, hideLoading } = useLoading();
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const isAgency = useTravelStore((state) => state.isAgency);
  const navigate = useNavigate();
  type FormItemDataProps = Yup.InferType<typeof travelFormItemSchema>;

  const [contextId] = useTravelStore((state) => [state.contextId]);
  const [serviceRequestId, serviceRequestItem, isModalOpen, setModalOpen] =
    useTravelItemStore((state) => [
      state.id,
      state.currentServiceRequestItem,
      state.isModalOpen,
      state.setModalOpen,
    ]);

  const [policyViolationAlert, setPolicyViolationAlert] = useState(null);
  const [justificationPolicy, setJustificationPolicy] = useState<any | null>(
    null,
  );

  let vehicleData = serviceRequestItem?.vehicle;
  let alreadyHasSegment = false;

  if (
    !vehicleData?.rentalCompany &&
    vehicleData?.originalRequest?.rentalCompany
  ) {
    alreadyHasSegment = false;
    vehicleData = vehicleData.originalRequest;
  } else if (vehicleData?.rentalCompany) {
    alreadyHasSegment = true;
  }

  const driver =
    serviceRequestItem?.travelers && serviceRequestItem?.travelers[0];

  const checkInDate = convertDateObjectToDate(vehicleData?.checkIn);
  const checkOutDate = convertDateObjectToDate(vehicleData?.checkOut);

  const isAgencyInput =
    serviceRequestItem?.workflow?.status?.code == statusFromCodes.toBook;

  useEffect(() => {
    setModalOpen(isModalOpened);
  }, [isModalOpened]);

  const openPolicyViolationModal = useCallback((alert) => {
    const violation = alert.violations[0];
    openModal({
      title: violation.policyName,
      subTitle: violation.policyDescription,
      content: (
        <PolicyViolationAlert
          policyViolationAlert={alert}
          contextId={contextId}
          onSubmitJustification={(itemJustification) => {
            setJustificationPolicy({
              ...itemJustification,
              policyId: violation.policyId,
            });
            closeModal();
          }}
        />
      ),
      status: 'error',
    });
  }, []);

  useEffect(() => {
    if (policyViolationAlert) {
      openPolicyViolationModal(policyViolationAlert);
    }
  }, [policyViolationAlert, openPolicyViolationModal]);

  const dateAgencyReserve = convertDateObjectToDate(
    serviceRequestItem?.expiration?.expireAt,
  );

  const methods = useForm({
    defaultValues: {
      type: 3,
      system_agency_reserve:
        {
          label: serviceRequestItem?.sourceTypeName,
          value: serviceRequestItem?.sourceType,
        } || null,
      location_agency_reserve: serviceRequestItem?.bookingLocator || null,
      date_agency_reserve: dateAgencyReserve || dayjs().add(1, 'day').toDate(),
      time_agency_reserve:
        convertDateToHourMinute(dateAgencyReserve) || '00:00',
      travelers: {
        value: driver?.id,
        label: driver?.email,
        traveler: driver,
      },
      vehicle: {
        rentalCompany: {
          value: vehicleData?.rentalCompanyId,
          label: vehicleData?.rentalCompany,
        },
        category: {
          value: vehicleData?.categoryId,
          label: vehicleData?.category,
        },
        type: {
          value: vehicleData?.typeId,
          label: vehicleData?.type,
        },
        transmission:
          normalize(vehicleData?.transmission) == 'manual'
            ? 'manual'
            : 'automatic',
        hasGPS: vehicleData?.hasGPS,
        hasAirbag: vehicleData?.hasAirbag,
        hasAC: vehicleData?.hasAC,
        check_in_Date: checkInDate,
        check_in_Time: convertDateToHourMinute(checkInDate),
        check_in_Address:
          vehicleData?.pickUpLocationId && vehicleData?.pickUpLocationId != '0'
            ? {
                value: vehicleData?.pickUpLocationId,
                label: vehicleData?.pickUpLocation,
              }
            : null,
        check_in_City: vehicleData?.pickUpLocationCityId,
        checkout_Date: checkOutDate,
        checkout_Time: convertDateToHourMinute(checkOutDate),
        checkout_Address:
          vehicleData?.returnLocationId && vehicleData?.returnLocationId != '0'
            ? {
                value: vehicleData?.returnLocationId,
                label: vehicleData?.returnLocation,
              }
            : null,
        checkout_City: vehicleData?.returnLocationCityId,
        comments: vehicleData?.originalRequest?.comments || null,
      },
    },
    resolver: yupResolver(travelFormItemSchema),
  });

  // Log the errors for debugging
  useEffect(() => {
    console.log('Form Errors:', methods.formState.errors);
  }, [methods.formState.errors]);

  const onCheckFormAndSubmit = async (data: FormItemDataProps) => {
    try {
      showLoading('Aguarde, estamos cadastrando o item.');
      const checkInApiFormat = convertDateToDateObject(
        data.vehicle.check_in_Date,
        data.vehicle.check_in_Time,
      );
      const checkOutApiFormat = convertDateToDateObject(
        data.vehicle.checkout_Date,
        data.vehicle.checkout_Time,
      );
      const expirationDate = convertDateToDateObject(
        data.date_agency_reserve,
        data.time_agency_reserve,
      );

      const vehicleApiRequest = {
        rentalCompany: data.vehicle.rentalCompany.label,
        rentalCompanyId: data.vehicle.rentalCompany.value,
        category: data.vehicle.category.label,
        categoryId: data.vehicle.category.value,
        type: data.vehicle.type.label,
        typeId: data.vehicle.type.value,
        transmission: data.vehicle.transmission,
        hasGPS: data.vehicle.hasGPS,
        hasAirbag: data.vehicle.hasAirbag,
        hasAC: data.vehicle.hasAC,
        checkIn: checkInApiFormat,
        checkOut: checkOutApiFormat,
        pickUpLocation: data.vehicle.check_in_Address.label,
        pickUpLocationId: data.vehicle.check_in_Address.value,
        pickUpLocationCityId: data.vehicle.check_in_City,
        returnLocation: data.vehicle.check_in_Address.label,
        returnLocationId: data.vehicle.check_in_Address.value,
        returnLocationCityId: data.vehicle.checkout_City,
      };

      const apiRequest: {
        contextId: number;
        serviceRequestItem: any;
        policyViolationAlertsJustified?: any;
      } = {
        contextId: Number(contextId),
        serviceRequestItem: {
          ...serviceRequestItem,
          type: 'vehiclebooking',
          serviceRequestId: Number(serviceRequestId),
          sourceType: data.system_agency_reserve.value,
          sourceTypeName: data.system_agency_reserve.label,
          contextId: Number(contextId),
          bookingLocator: data.location_agency_reserve,
          expiration: {
            expireAt: expirationDate,
            expired: false,
            ignore: !isAgencyInput,
          },
          bookingStatus: 'bookingPending',
          connectionSource: 'CarOffline',
          travelers: [(data.travelers as any).traveler],
          payments: [
            {
              paymentData: {
                type: 0,
                paymentForm: {
                  type: 2,
                  productType: 1,
                  instalments: 1,
                  storedCreditCard: 28,
                },
              },
            },
          ],
          vehicle: {},
        },
      };

      if (justificationPolicy !== null) {
        apiRequest.policyViolationAlertsJustified = {
          justifications: [
            {
              policyId: justificationPolicy.policyId,
              justification: {
                code: justificationPolicy.justificationOption.value,
                name: justificationPolicy.justificationOption.label,
                description: justificationPolicy.justificationDetails,
              },
            },
          ],
        };
      }

      if (isAgencyInput) {
        apiRequest.serviceRequestItem.vehicle = {
          originalRequest: vehicleData.originalRequest,
          ...vehicleApiRequest,
        };
      } else {
        apiRequest.serviceRequestItem.vehicle = {
          originalRequest: alreadyHasSegment
            ? vehicleData.originalRequest
            : {
                ...vehicleApiRequest,
                comments: data.vehicle.comments,
              },
          ...vehicleApiRequest,
        };
      }

      console.log('apiRequest', apiRequest);

      const response = await saveServiceRequestItem(apiRequest);

      if (response.policyViolationAlert) {
        setPolicyViolationAlert(response.policyViolationAlert);
      }

      if (response.successful) {
        const modalConfig: Partial<ModalState> = {
          title: isAgency
            ? 'Dados confirmados!'
            : 'Item enviado para a agência!',
          subTitle: isAgency
            ? 'Por favor verifique se todas as informações (tarifas, taxas e demais informações) já estão preenchidas no item para finalizar o atendimento.'
            : 'O item foi adicionado à sua requisição e agora segue para atendimento da agência.',
          content: isAgency ? null : (
            <>
              Você pode acompanhar o andamento do seu pedido na área de
              Requisições, clicando na aba Em Atendimento.
            </>
          ),
          status: 'success',
          primaryButtonLabel: 'Concluir',
          primaryButtonAction: () => {
            closeModal();
            setModalOpen(false);
            const queryStringParameters = `?requestId=${response.serviceRequestItem.serviceRequestId}`;
            navigate(`/travel/list${queryStringParameters}`);
          },
        };

        if (!isAgency) {
          modalConfig.secondaryButtonLabel = 'Adicionar novo item';
          modalConfig.secondaryButtonAction = () => {
            methods.reset();
            closeModal();
            setModalOpen(true);
          };
        }

        openModal(modalConfig);
      }

      if (!response.successful) {
        openModal({
          title: 'Erro',
          subTitle: 'Ocorreu um erro ao salvar a solicitação',
          content: <>Por favor, tente novamente.</>,
          status: 'error',
          primaryButtonLabel: 'Fechar',
          primaryButtonAction: () => {
            closeModal();
          },
        });
      } else {
        onSuccess && onSuccess();
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideLoading();
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Modal.Root
          open={isModalOpen}
          onClose={() => {
            isModalOpen && onClose && onClose();
            setModalOpen(false);
          }}
          size={'md'}
        >
          <>
            <Modal.Header
              title={
                alreadyHasSegment
                  ? `Editar Item - ${serviceRequestItem?.id}`
                  : 'Adicionar item'
              }
            ></Modal.Header>
            <Modal.Content>
              <SC.RequestContainer>
                <div>
                  {isAgency && (
                    <div>
                      <AgencyReserve
                        control={methods.control}
                        errors={methods.formState.errors}
                      />
                    </div>
                  )}
                  {!isAgencyInput && (
                    <TravelTypeItem
                      control={methods.control}
                      errors={methods.formState.errors}
                    />
                  )}
                  <DetailsVehicle control={methods.control} />
                  {isAgency && (
                    <AdditionalComments
                      title={'Comentário Registrado'}
                      disabled={true}
                    />
                  )}
                  <RentalCompanyInfo
                    control={methods.control}
                    errors={methods.formState.errors}
                    title={'Retirada'}
                    namePrefix={'check_in'}
                  />
                  <RentalCompanyInfo
                    control={methods.control}
                    errors={methods.formState.errors}
                    title={'Devolução'}
                    namePrefix={'checkout'}
                  />
                  {!isAgencyInput && !isAgency && (
                    <AdditionalComments title={'Comentários adicionais'} />
                  )}
                </div>
              </SC.RequestContainer>
            </Modal.Content>
            <Modal.Footer
              cancelText={'Cancelar'}
              onCancel={() => {
                isModalOpen && onClose && onClose();
                setModalOpen(false);
              }}
              onConfirm={methods.handleSubmit(onCheckFormAndSubmit)}
              confirmText={alreadyHasSegment ? 'Salvar' : 'Adicionar'}
            ></Modal.Footer>
          </>
        </Modal.Root>
      </form>
    </FormProvider>
  );
}
