import { create } from 'zustand';
import { LocationTypes } from '../types/Location.types';

type LocationUpdateType = 'check_in' | 'checkout';

interface CarRental {
  label: string;
  value: string;
}

type RequestTravelItemStore = {
  id: number | null;
  updateID: (id: number) => void;
  // Gerenciamento check_in/checkout
  pickUpLocation: LocationTypes | null;
  returnLocation: LocationTypes | null;
  updateLocation: (type: LocationUpdateType, location: LocationTypes) => void;
  updateDefaultLocation: (location: LocationTypes) => void;
  location: LocationTypes | null;
  // Gerenciamento do estado do modal
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
  sameLocation: boolean;
  setSameLocation: (sameLocation: boolean) => void;
  currentServiceRequestItem: any | null;
  updateCurrentServiceRequestItem: (serviceRequestItem: any) => void;
  carRentals: CarRental[];
  airTicket: any | null;
  setAirTicket: (airTicket: any) => void;
};

export const useTravelItemStore = create<RequestTravelItemStore>((set) => ({
  id: null,
  updateID: (id) => set({ id: id }),
  // Gerenciamento check_in/checkout
  pickUpLocation: null,
  returnLocation: null,
  location: null,
  updateDefaultLocation: (location) => set({ location: location }),
  updateLocation: (type, location) =>
    set((state) => {
      if (type === 'check_in') {
        return { ...state, pickUpLocation: location };
      } else if (type === 'checkout') {
        return { ...state, returnLocation: location };
      }
    }),
  isModalOpen: false,
  sameLocation: false,
  setSameLocation: (sameLocation) => set({ sameLocation }),
  setModalOpen: (isModalOpen) => set((state) => ({ ...state, isModalOpen })),
  currentServiceRequestItem: null,
  updateCurrentServiceRequestItem: (serviceRequestItem) =>
    set({ currentServiceRequestItem: serviceRequestItem }),
  carRentals: [
    { label: 'Localiza', value: 'localiza' },
    { label: 'Movida', value: 'movida' },
    { label: 'Unidas', value: 'unidas' },
    { label: 'Hertz', value: 'hertz' },
    { label: 'Avis', value: 'avis' },
    { label: 'Foco', value: 'foco' },
    { label: 'Budget', value: 'budget' },
    { label: 'National', value: 'national' },
  ],
  airTicket: null,
  setAirTicket: (airTicket) => set({ airTicket }),
}));
