import * as Yup from 'yup';

const dateTimeSchema = Yup.date().required(
  'O campo data de início é obrigatório',
);

const valueLabelSchema = Yup.object({
  label: Yup.string().required(),
  value: Yup.string().required(),
});

const vehicleTypeSchema = Yup.object().shape({
  rentalCompany: valueLabelSchema.required('O campo locadora é obrigatório'),
  category: valueLabelSchema.required('O campo categoria é obrigatório'),
  type: valueLabelSchema.required('O campo tipo é obrigatório'),
  hasGPS: Yup.boolean().optional(),
  hasAirbag: Yup.boolean().optional(),
  hasAC: Yup.boolean().optional(),
  transmission: Yup.string().required(),

  check_in_Date: dateTimeSchema,
  check_in_Time: Yup.string().required('O campo hora é obrigatório'),
  check_in_City: Yup.string().required('O campo cidade é obrigatório'),
  check_in_Address: valueLabelSchema.required(
    'O endereço de retirada é obrigatório',
  ),

  checkout_Date: dateTimeSchema.min(
    Yup.ref('check_in_Date'),
    'A data de devolução deve ser posterior à data de retirada',
  ),
  checkout_Time: Yup.string().required('O campo hora é obrigatório'),
  checkout_City: Yup.string().required('O campo cidade é obrigatório'),
  checkout_Address: valueLabelSchema.required(
    'O endereço de devolução é obrigatório',
  ),
  comments: Yup.string().nullable(),
});

const travelFormItemSchema = Yup.object().shape({
  type: Yup.number().required('O campo tipo do item deve ser preenchido'),
  vehicle: vehicleTypeSchema,
  travelers: Yup.object().required('A seleção de viajantes é obrigatória'),
  // Dados da agência não são obrigatórios
  location_agency_reserve: Yup.string().nullable(),
  system_agency_reserve: Yup.object({
    label: Yup.string().optional(),
    value: Yup.string().optional(),
  }).nullable(),
  date_agency_reserve: Yup.date().nullable(),
  time_agency_reserve: Yup.string().nullable(),
});

export default travelFormItemSchema;
