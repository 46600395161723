'use strict';

import React from 'react';
import Template from './wizard.template.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class WizardComponent extends React.Component {
  firstStepPrevButtonLabel = 'Cancelar';
  prevButtonLabel = 'Voltar';
  nextButtonLabel = 'Avançar';
  lastStepNextButtonLabel = 'Concluir';

  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    const currStep = props.currentStep || 1;
    const totalSteps = React.Children.toArray(this.props.children).length;

    this.state = {
      stepsControl: {
        currentStep: currStep,
        totalSteps: totalSteps,
        ...this.getButtonLabels(currStep, totalSteps),
        disableNextButton: props.disableNextButton || false,
        ...WizardComponent.getChildrenWizardProps(props, currStep - 1),
      },
    };
  }

  render() {
    return (
      <Template.main
        stepsControl={this.state.stepsControl}
        children={React.Children.toArray(this.props.children)}
        events={this.events}
      />
    );
  }

  static getDerivedStateFromProps(props, state) {
    const currentStep = props.currentStep || state.stepsControl.currentStep;

    let stepsControl = {
      ...state.stepsControl,
      disableNextButton: props.disableNextButton,
      currentStep: currentStep,
      ...WizardComponent.getChildrenWizardProps(props, currentStep - 1),
    };

    return {
      stepsControl,
    };
  }

  events = {
    onPrevClicked: () => {
      let control = this.state.stepsControl;
      if (control.currentStep == 1)
        this.props.onCancelClick && this.props.onCancelClick();
      else {
        this.events._setState(control.currentStep - 1);
      }
    },
    onNextClicked: () => {
      let control = this.state.stepsControl;

      if (control.currentStep == control.totalSteps) {
        this.props.onFinishClick && this.props.onFinishClick();
      } else {
        this.events._setState(control.currentStep + 1);
      }
    },

    _setState: (nextStep) => {
      const control = this.state.stepsControl;
      this.props.onStepChange &&
        this.props.onStepChange(nextStep, control.currentStep);

      this.setState({
        stepsControl: {
          ...control,
          ...this.getButtonLabels(nextStep, control.totalSteps),
          currentStep: nextStep,
          ...WizardComponent.getChildrenWizardProps(this.props, nextStep - 1),
        },
      });
    },
  };

  static getChildrenWizardProps = (props, stepIndex) => {
    const childProps = React.Children.toArray(props.children)[stepIndex].props;
    const showNavigation =
      childProps.showNavigation != undefined
        ? childProps.showNavigation
        : props.showNavigation != undefined
        ? props.showNavigation
        : true;
    const showHeader =
      childProps.showHeader != undefined
        ? childProps.showHeader
        : props.showHeader != undefined
        ? props.showHeader
        : false;

    return {
      allowSkipStep: childProps.allowSkip || false,
      showNavigation: showNavigation,
      showHeader: showHeader,
    };
  };

  getButtonLabels(nextStep, totalSteps) {
    let prevButtonLabel = this.props.prevButtonLabel || this.prevButtonLabel;
    let nextButtonLabel = this.props.nextButtonLabel || this.nextButtonLabel;

    if (nextStep == 1)
      prevButtonLabel =
        this.props.firstStepPrevButtonLabel || this.firstStepPrevButtonLabel;

    if (nextStep == totalSteps)
      nextButtonLabel =
        this.props.lastStepNextButtonLabel || this.lastStepNextButtonLabel;

    let nextStepItem = this.props.children[nextStep - 1];

    if (nextStepItem && nextStepItem.props.prevButtonLabel)
      prevButtonLabel = nextStepItem.props.prevButtonLabel;

    if (nextStepItem && nextStepItem.props.nextButtonLabel)
      nextButtonLabel = nextStepItem.props.nextButtonLabel;

    return {
      prevButtonLabel,
      nextButtonLabel,
    };
  }
}
