import Popup from '../../popup/popup.component.js';
import HotelBaseInfos from '../../products-travel/hotel-product/hotel-base-infos/hotel-base-infos.component.js';
import HotelAddress from '../../products-travel/hotel-product/hotel-address/hotel-address.component.js';
import Icon from '../../common/icon/icon.component.js';
import { formatterUtil } from '../../../utils/formatter.js';
import { enums } from '../../../utils/enums.js';
import { utils } from '../../../utils/utils.js';

var HotelProductTemplate = {
  main: ({
    product,
    hotelIndex,
    onDeleteProductClick,
    displayCancelationPolicy,
    onCloseCancelationPolicyClicked,
    showOpenCancelationPolicyButton,
    onDisplayCancelationPolicyClicked,
    preBooking,
  }) => {
    let hotel = product.hotel;
    let room = null;
    const showRadiusInfo = (filter => ![null,undefined].includes(filter?.geoLocation));
    if (product.hotel.rooms)
      room = product.hotel.rooms.filter((room) => {
        return room.id === product.selectedRooms[0].room.id;
      })[0];
    else room = hotel.room;

    const resolveCancelationWarning = (preBooking) => {
      if (!preBooking) return;

      let message = '';
      if (preBooking.hotel && preBooking.hotel.hotels.length > 0) {
        let SelectedRoom = preBooking.hotel.hotels[0].rooms[0];
        let now = new Date();
        let maxCancelDeadline = now.setHours(now.getHours() + 2);
        let cancelationDeadLine;
        if (SelectedRoom.cancelDeadline) {
          cancelationDeadLine = formatterUtil.dateObjToDate(
            SelectedRoom.cancelDeadline,
          );
        }

        let nonRefundable =
          room.cancellationPolicies && room.cancellationPolicies.length > 0
            ? room.cancellationPolicies[0].nonRefundable
            : false;
        if (nonRefundable) message = 'Atenção: Essa opção é Não Reembolsável';
        else if (
          cancelationDeadLine &&
          cancelationDeadLine <= maxCancelDeadline
        )
          message =
            'Atenção: Essa opção não possui prazo para cancelamento sem a cobrança de multa.';
      }
      return message;
    };

    let guestsCount = '';
    let cancelationWarning = room
      ? resolveCancelationWarning(preBooking)
      : null;

    if (product.selectedRooms && product.selectedRooms.length) {
      let adultCount = product.selectedRooms.reduce(
        (total, element) =>
          (total += element.adultCount ? element.adultCount : 0),
        0,
      );
      let childrenCount = product.selectedRooms.reduce(
        (total, element) =>
          (total += element.childCount ? element.childCount : 0),
        0,
      );

      guestsCount = adultCount + childrenCount;
    } else if (room && room.capacity) {
      guestsCount = room.capacity;
    }

    let fareInformation =
      room.fares && room.fares[0] && room.fares[0].fareInformation
        ? room.fares[0].fareInformation
        : null;
    let mealDescription =
      fareInformation && fareInformation.board
        ? fareInformation.board.name || fareInformation.board.description
        : room.mealOption;

    return (
      <div className="exon-hotel-summary exon-hotel-summary_extended">
        <div className="exon-hotel-summary-description">
          <div className="exon-hotel-infos">
            <HotelBaseInfos
              name={hotel.name}
              rating={hotel.stars}
              connectionSource={room.sourceIdentifier || room.connectionSource}
              sourceSupplier={room.sourceSupplier}
              chain={hotel.chain}
            />

            <div className="exon-hotel-provider">
              {showOpenCancelationPolicyButton && (
                <span
                  className="exon-box-row__piece-policy"
                  onClick={() => onDisplayCancelationPolicyClicked()}
                >
                  Política de cancelamento
                </span>
              )}

              {cancelationWarning && (
                <span className="exon-box-row__piece-refundable">
                  {cancelationWarning}
                </span>
              )}
            </div>
          </div>

          <div className="exon-hotel-room">
            {hotel.location && (
              <div
                className={'exon-hotel-location__small exon-hotel-location '}
              >
                <HotelAddress
                  distance={hotel.distance?.toFixed(2) || "--"}
                  address={hotel.location}
                  showMap={false}
                  addressLabel={product?.searchData?.label}
                  distanceIsFromCityCenter={!showRadiusInfo(product?.searchData?.searchViewParameters?.filter)}
                  />
              </div>
            )}
          </div>

          <div className="exon-hotel-room">
            <div className="exon-hotel-room">
              {product.selectedRooms && product.selectedRooms.length == 1 && (
                <div className="exon-hotel-room-meal">
                  {
                    <Icon
                      icon={
                        mealDescription
                          ? 'exon-icon-coffee-unfilled_enabled'
                          : 'exon-icon-coffee-unfilled_disabled'
                      }
                      description={
                        mealDescription ? mealDescription : 'Sem refeição'
                      }
                    />
                  }

                  <Icon
                    className="exon-icon-spacing"
                    icon={`exon-icon-cashback-unfilled_enabled${
                      room.cancellationPolicies &&
                      room.cancellationPolicies.length > 0 &&
                      room.cancellationPolicies[0].nonRefundable
                        ? '-gray'
                        : ''
                    }`}
                    description={`${
                      room.cancellationPolicies &&
                      room.cancellationPolicies.length > 0
                        ? room.cancellationPolicies[0].nonRefundable
                          ? 'Não Reembolsável'
                          : 'Reembolsável'
                        : 'Sem informação'
                    }`}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="exon-hotel-room">
            <div className={'exon-hotel-room-meal'}>
              {product.selectedRooms && (
                <div>
                  <p
                    className={'trip-policy-and-values__description-and-value'}
                  >
                    <b>
                      {product.selectedRooms &&
                      product.selectedRooms.length >= 2
                        ? `Quartos : `
                        : `Quarto: `}
                    </b>
                  </p>
                  <ul>
                    {product.selectedRooms.slice(0, 2).map((selected, i) => (
                      <li key={i}>{selected.room.name}</li>
                    ))}
                  </ul>

                  {product.selectedRooms.length > 2 && (
                    <p>
                      Mais {product.selectedRooms.length - 2}{' '}
                      {product.selectedRooms.length - 2 == 1
                        ? 'quarto...'
                        : 'quartos...'}
                    </p>
                  )}
                </div>
              )}

              {room.name && !product.selectedRooms && (
                <div>
                  Quarto: <br />
                  <b>{room.name}</b>
                </div>
              )}

              {guestsCount > 0 && (
                <p className={'trip-policy-and-values__description-and-value'}>
                  <b>Hóspedes:</b> {guestsCount}
                </p>
              )}
            </div>
          </div>

          <div className="exon-hotel-room">
            <div className={'exon-hotel-room-meal'}>
              <p className={'trip-policy-and-values__description-and-value'}>
                <b>Check in: </b>
                {product.searchData.checkin
                  ? formatterUtil.formatToDayMonth(
                      formatterUtil.dateObjToDate(product.searchData.checkin),
                    )
                  : '--'}
              </p>
              <p className={'trip-policy-and-values__description-and-value'}>
                <span>
                  {product.searchData.checkin
                    ? utils.date.dayDiff(
                        formatterUtil.dateObjToDate(product.searchData.checkin),
                        formatterUtil.dateObjToDate(
                          product.searchData.checkout,
                        ),
                      )
                    : product.searchData.totalDays
                    ? product.searchData.totalDays
                    : '--'}{' '}
                  diárias
                </span>
              </p>
            </div>
          </div>
        </div>
        {onDeleteProductClick && (
          <div className="exon-box-actions-product">
            <span
              className="exon-icon exon-icon-dump-unfilled_disabled"
              onClick={() =>
                onDeleteProductClick(hotelIndex, enums.products.type.hotel)
              }
            ></span>
          </div>
        )}

        <HotelProductTemplate.popupCancelationPolicy
          displayCancelationPolicy={displayCancelationPolicy}
          onCloseCancelationPolicyClicked={onCloseCancelationPolicyClicked}
        />
      </div>
    );
  },

  popupCancelationPolicy: ({
    displayCancelationPolicy,
    onCloseCancelationPolicyClicked,
  }) => {
    return (
      displayCancelationPolicy.display && (
        <div className="exon-hotel-display-cancelation-policy-container">
          <Popup
            okButtonLabel={null}
            cancelButtonLabel={null}
            title={null}
            cancelButtonClick={onCloseCancelationPolicyClicked}
            okButtonClick={null}
          >
            {!displayCancelationPolicy.popupLoad && (
              <div className={'exon-hotel-display-cancelation-policy'}>
                <div
                  className={
                    'exon-hotel-display-cancelation-policy__item ' +
                    (!displayCancelationPolicy.deadlineFormatted ||
                    displayCancelationPolicy.deadlineFormatted == ''
                      ? 'hide'
                      : '')
                  }
                >
                  <h4>Prazo para cancelamento</h4>
                  <p className="exon-hotel-policy-date">
                    {displayCancelationPolicy.deadlineFormatted}
                  </p>
                </div>
                <div
                  className={
                    'exon-hotel-display-cancelation-policy__item ' +
                    (!displayCancelationPolicy.policy ||
                    displayCancelationPolicy.policy == ''
                      ? 'hide'
                      : '')
                  }
                >
                  <h4>Políticas</h4>
                  <p>{displayCancelationPolicy.policy}</p>
                </div>
                <div
                  className={
                    'exon-hotel-display-cancelation-policy__item ' +
                    (!displayCancelationPolicy.termsAndConditions ||
                    displayCancelationPolicy.termsAndConditions == ''
                      ? 'hide'
                      : '')
                  }
                >
                  <h4>Termos e condições</h4>
                  <p>
                    {displayCancelationPolicy.termsAndConditions &&
                      displayCancelationPolicy.termsAndConditions}
                  </p>
                </div>
                {displayCancelationPolicy.penalty && (
                  <div className="exon-hotel-display-cancelation-policy__item ">
                    <h4>Multa</h4>
                    <p>{`${
                      displayCancelationPolicy.penalty.currencyCode
                    } ${utils.formatters.money.getFormatted(
                      displayCancelationPolicy.penalty.value,
                      displayCancelationPolicy.penalty.currencyCode,
                    )}`}</p>
                  </div>
                )}
              </div>
            )}
            {displayCancelationPolicy.popupLoad && (
              <span className="loader exon-icon-loader exon-hotel-loader-popup"></span>
            )}
          </Popup>
        </div>
      )
    );
  },
};

export default HotelProductTemplate;
