import HotelBaseInfos from "../hotel-base-infos/hotel-base-infos.component.js";
import HotelAddress from "../hotel-address/hotel-address.component.js";
import HotelDescription from "../hotel-description/hotel-description.component.js";
import RoomSummaryCard from "../hotel-rooms/room-summary-card/room-summary-card.component.js";
import ImageMosaic from "../../../common/image-mosaic/image-mosaic.component.js";
import Icon from "../../../common/icon/icon.component.js";

let Template = (props) => {
  let room = props.hotel.rooms.find(
    (room) => room.id == props.roomIdsSelected[0],
  );
  let commercialAgreement =
    room.fares && room.fares[0].fareInformation
      ? room.fares[0].fareInformation.commercialAgreement
      : null;

  const showRadiusInfo = (filter => ![null,undefined].includes(filter?.geoLocation));

  return (
    <div className="exon-hotel-informations">
      <div className="exon-hotel-informations-gallery">
        <ImageMosaic
          images={
            props.hotel.multimidia
              ? props.hotel.multimidia.images
              : props.hotel.images
          }
          imageDefault={{
            url: require('../../../../assets/images/gallery-hotel-placeholder.svg'),
          }}
          headerPopup={
            <HotelBaseInfos
              name={props.hotel.name}
              rating={props.hotel.rating}
              connectionSource={props.hotel.connectionSource}
              sourceSupplier={props.hotel.sourceSupplier}
              chain={props.hotel.chain}
              commercialAgreement={commercialAgreement}
            />
          }
          randomize
        />
      </div>
      <div className="exon-hotel-informations-infos">
        <HotelBaseInfos
          name={props.hotel.name}
          rating={props.hotel.rating}
          connectionSource={props.hotel.connectionSource}
          sourceSupplier={props.hotel.sourceSupplier}
          chain={props.hotel.chain}
          commercialAgreement={commercialAgreement}
        />

        <HotelDescription
          description={room.description ? room.description : ''}
        />

        <RoomSummaryCard
          rooms={props.hotel.rooms}
          roomIdsSelected={props.roomIdsSelected}
          searchParameters={props.searchParameters}
          onShopingCartClick={props.onIncludeInCartClick}
          onSelectClick={props.onSendSelectedProductCheckoutClick}
          onShowPurchaseDetailsClick={props.onShowPurchaseDetailsClick}
        />
      </div>

      <HotelAddress
        distance={props.hotel.distance?.toFixed(2) || "--"}
        address={props.hotel.address}
        onOpenMapClick={props.onAddressClick}
        addressLabel={props?.originalSearchParameters?.label}
        distanceIsFromCityCenter={!showRadiusInfo(props?.originalSearchParameters?.searchViewParameters?.filter)}
        completeAddress
        showMap
      />

      <Icon
        className={`exon-hotel-informations-phone ${
          !props.hotel.contacts ||
          (!props.hotel.contacts[0] && !props.hotel.contacts[0].description)
            ? 'exon-text-disabled'
            : ''
        }`}
        icon={'exon-icon-phone-filled_enabled'}
        description={
          props.hotel.contacts &&
          props.hotel.contacts[0] &&
          props.hotel.contacts[0].description
            ? props.hotel.contacts[0].description
            : 'Contato não informado'
        }
      />
    </div>
  );
};

export default Template;
