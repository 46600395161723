import { PaymentItem } from '@models/PaymentItem';
import { PaymentOptions } from '../PaymentOptions';
import * as SC from '../styled';

type FlashPayOptionsListProps = {
  title: string;
  options: PaymentItem[];
  isVisible: boolean;
  onSelect: (item: PaymentItem) => void;
  price: number;
  isCurrentUserATravelerProfile: boolean;
};

export function FlashPayOptionsList({
  title,
  options,
  isVisible,
  onSelect,
  price,
  isCurrentUserATravelerProfile,
}: FlashPayOptionsListProps) {
  return (
    <>
      {isVisible && (
        <>
          {title && (
            <SC.FlexRow>
              <SC.PaymentMethodTitle>{title}</SC.PaymentMethodTitle>
            </SC.FlexRow>
          )}
          <PaymentOptions
            paymentItems={options}
            onCheck={onSelect}
            price={price}
            isCurrentUserATravelerProfile={isCurrentUserATravelerProfile}
          />
        </>
      )}
    </>
  );
}
