import React from 'react';
import Template from './air-ticket-issue-flow.template.js';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component.js';
import { enums } from '@legacy-utils/enums';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import { utils } from '@legacy-utils/utils';
import { componentUtil } from '@legacy-utils/componentUtil';
import { paymentResolver } from '@legacy-utils/resolvers/paymentResolver';
import { travelerResolver } from '@legacy-utils/resolvers/travelerResolver';

export default class AirTicketIssueFlowComponent extends React.Component {
  defaultMoneyValue = { value: 0, currencyCode: 'BRL' };

  constructor(props) {
    super(props);

    componentUtil.react.bindMethods(this.stepsControl, this);
    componentUtil.react.bindMethods(this.ticketManager, this);

    const tickets = this.props.tickets;

    for (let i = 0; i < tickets.length; i++) {
      let ticket = tickets[i];

      ticket.checked = true;

      ticket.fare.travelerValue = {
        ...this.ticketSelection.defaultFieldValues.getFare(),
        ...ticket.fare.travelerValue,
      };
      ticket.info = {
        ...this.ticketSelection.defaultFieldValues.getTicketInfo(),
        ...ticket.info,
      };
      ticket.payments = [];

      this.formatFareValues(ticket.fare.travelerValue);
      this.fillTicketIdentifier(ticket);
      this.fillTravelerFullName(ticket.traveler);
    }

    this.state = {
      tickets,
      stepsControl: {
        disableNextButton: false,
      },
      alert: {
        show: false,
        title: '',
        message: '',
        details: '',
      },
      defaultCurrencyCode: 'BRL',

      paymentSelection: {
        paymentForms: [],
        secondaryPaymentForms: [],
        inputTicketNumber: false,
        ticketNumber: '',
        replacementData: null,
        confirmedReplacementTicket: null,
        showSecondaryPayment: false,
      },

      validation: {
        showValidationResult: false,
      },

      replacement: {
        disableInputTicketNumber: false, //this.props.replacement,
        replacing: this.props.replacement,
        ticketNumber: this.props.replacement ? tickets[0].number : '',
        simulationResult: null,
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          stepsControl={this.stepsControl.getOptions()}
          replacement={this.replacement.getOptions()}
          ticketSelection={this.ticketSelection.getOptions()}
          paymentSelection={this.paymentSelection.getOptions()}
          alert={this.alert.getOptions()}
        />
      </ErrorBoundary>
    );
  }

  componentDidMount() {
    const products = 2;
    const supplier = this.state.tickets[0].airTrips[0].supplier; //todo: mudar para obter por ticket (criar dicionario de suppliers para pagamentos)
    const travelers = this.props.booking.travelers
      .filter((t) => t.userId)
      .map((t) => t.userId);
    const requester = this.props.requester.userId;
    const costCenter = this.props.costCenter.id;
    this.paymentSelection.dataSource.callLoad({
      products,
      supplier,
      travelers,
      requester,
      costCenter,
    });
  }

  stepsControl = {
    getOptions: () => {
      const _this = this;
      return {
        props: this.state.stepsControl,
        events: {
          onStepChanged: (currentStep, prevStep) => {
            if (
              _this.state.replacement.replacing &&
              currentStep == 2 &&
              _this.state.replacement.ticketNumber &&
              currentStep > prevStep
            ) {
              _this.ticketManager.simulateIssue(_this.state.tickets[0]);
            }
          },
          onCancelClicked: () => {
            _this.props.onCloseFlowClick && _this.props.onCloseFlowClick();
          },
          onFinishClicked: () => {
            if (_this.validationManager.validate()) {
              _this.ticketManager.issue(_this.state.tickets);
            } else {
              _this.setState({
                validation: {
                  ..._this.state.validation,
                  showValidationResult: true,
                },
              });
            }
          },
        },
      };
    },
  };

  ticketSelection = {
    getOptions: () => {
      return {
        props: {
          tickets: this.state.tickets,
          dataSources: this.props.dataSources,
        },
        events: {
          onTicketCheckboxChanged: (event, ticketId) => {
            let tickets = this.state.tickets;
            let ticket = tickets.find((t) => t.id == ticketId);
            ticket.checked = event.target.checked;

            this.setState({
              tickets,
              stepsControl: {
                ...this.state.stepsControl,
                disableNextButton: !tickets.some((t) => t.checked),
              },
            });
          },
          onTicketInfoFieldChange: (ticketId, propRef, value) => {
            let tickets = this.state.tickets;
            let ticket = tickets.find((t) => t.id == ticketId);
            ticket.info[propRef] = value;

            this.setState({
              tickets,
            });
          },
          onFareFieldChange: (ticketId, propRef, value) => {
            let tickets = this.state.tickets;
            let ticket = tickets.find((t) => t.id == ticketId);
            ticket.travelerValue.fare[propRef].value =
              (value && parseFloat(value)) || 0;

            this.setState({
              tickets,
            });
          },
        },
      };
    },
    defaultFieldValues: {
      getTicketInfo: () => {
        return {
          endorsement: '',
          tourCode: '',
          issuerAirLine: null,
          foidType: null,
          foidInfo: '',
          foidDocumentContry: null,
        };
      },
      getFare: () => {
        return {
          netFare: { ...this.defaultMoneyValue },
          equivalentFare: { ...this.defaultMoneyValue },
          basicFare: { ...this.defaultMoneyValue },
          sale: { ...this.defaultMoneyValue },
          taxes: { ...this.defaultMoneyValue },
          du: { ...this.defaultMoneyValue },
          fee: { ...this.defaultMoneyValue },
          markedFare: { ...this.defaultMoneyValue },
          comission: { ...this.defaultMoneyValue },
          incentive: { ...this.defaultMoneyValue },
          incentiveType: { ...this.defaultMoneyValue, value: 100 },
        };
      },
    },
  };

  paymentSelection = {
    getOptions: () => {
      const _this = this;
      return {
        props: {
          ...this.state.paymentSelection,
          validation: {
            showValidationResult: _this.state.validation.showValidationResult,
          },
        },
        events: {
          onPaymentSelected: (referenceCode, paymentData, index) => {
            const tickets = _this.state.tickets;
            const ticket = tickets.find(
              (t) => t.referenceCode == referenceCode,
            );

            if (ticket.payments[index]) ticket.payments[index] = paymentData;
            else ticket.payments.push(paymentData);

            _this.setState({
              tickets,
              paymentSelection: {
                ..._this.state.paymentSelection,
                inputTicketNumber:
                  paymentData.value ==
                  _this.paymentSelection._selectTicketIdentifier,
                showSecondaryPayment: index == 1,
              },
            });
          },
          onCloneForAllButtonClicked: (ticketId) => {
            const tickets = _this.state.tickets;
            const paymentsToClone = tickets.find(
              (t) => t.id == ticketId,
            ).payments;

            tickets.forEach((ticket) => {
              if (ticket.id != ticketId) ticket.payments = paymentsToClone;
            });

            _this.setState({ tickets: tickets });
          },

          onTicketNumberChanged: (ticketNumber) => {
            _this.setState({
              paymentSelection: {
                ..._this.state.paymentSelection,
                ticketNumber,
              },
            });
          },
          onSearchTicketClicked: (ticketId) => {
            if (_this.state.paymentSelection.ticketNumber) {
              _this.ticketManager.simulateReplacement(
                _this.state.paymentSelection.ticketNumber,
                _this.state.tickets.find((t) => t.id == ticketId),
              );
            } else {
              _this.setState({
                validation: {
                  ..._this.state.validation,
                  showValidationResult: true,
                },
              });
            }
          },

          onConfirmTicketReplacementClicked: (ticketId) => {
            //Adiciona à opção de pagamento selecionado a forma de pagamento como airTicket
            let tickets = _this.state.tickets;
            tickets.find((t) => t.id == ticketId).payments[0].paymentForm = {
              airTicket: this.state.paymentSelection.replacementData.airTicket,
            };

            let paymentForms = _this.state.paymentSelection.paymentForms;
            let selectOption = paymentForms.find(
              (pf) =>
                pf.value == _this.paymentSelection._selectTicketIdentifier,
            );
            selectOption.label = `Bilhete (${_this.state.paymentSelection.ticketNumber})`;

            let replacementData = this.state.paymentSelection.replacementData;

            _this.setState({
              tickets,
              paymentSelection: {
                ..._this.state.paymentSelection,
                paymentForms,

                showSecondaryPayment:
                  replacementData &&
                  replacementData.summary.amountToPay.value > 0,

                inputTicketNumber: false,
                confirmedReplacementTicket: replacementData,
                replacementData: null,
              },
            });
          },
          onCancelTicketReplacementClicked: () => {
            let paymentForms = _this.state.paymentSelection.paymentForms;
            let selectOption = paymentForms.find(
              (pf) =>
                pf.value == _this.paymentSelection._selectTicketIdentifier,
            );
            selectOption.label = this.paymentSelection._selectTicketLabel;

            _this.setState({
              paymentSelection: {
                ..._this.state.paymentSelection,
                paymentForms,
                replacementData: null,
                showSecondaryPayment: false,
              },
            });
          },
        },
      };
    },

    _selectTicketIdentifier: '_select-ticket',
    _selectTicketLabel: 'Selecionar outro bilhete como pagamento',

    dataSource: {
      callLoad: (loadOptions) => {
        const _this = this;
        const dataSource = this.props.dataSources.paymentForms;

        dataSource.events &&
          dataSource.events.beforeLoad &&
          dataSource.events.beforeLoad(loadOptions);

        dataSource.load(loadOptions).then((paymentForms) => {
          //Adiciona a possibilidade de selecionar um bilhete existente como opção de pagamento
          let ticketAsPaymentForm = listItemsResolver.getItem(
            this.paymentSelection._selectTicketIdentifier,
            this.paymentSelection._selectTicketLabel,
          );
          ticketAsPaymentForm.disabled = true;

          let primaryPaymentForms = [...paymentForms, ticketAsPaymentForm];

          _this.setState({
            paymentSelection: {
              ..._this.state.paymentSelection,
              paymentForms: primaryPaymentForms,
              secondaryPaymentForms: paymentForms,
            },
          });

          dataSource.events &&
            dataSource.events.afterLoad &&
            dataSource.events.afterLoad(loadOptions, paymentForms);
        });
      },
    },
  };

  replacement = {
    getOptions: () => {
      const _this = this;
      return {
        props: this.state.replacement,
        events: {
          onTicketNumberChanged: (ticketNumber) => {
            _this.setState({
              replacement: {
                ..._this.state.replacement,
                ticketNumber,
              },
            });
          },
          onSimulateIssueClicked: () => {
            _this.ticketManager.simulateIssue({
              number: _this.state.replacement.ticketNumber,
              info: {
                tourCode: _this.state.tickets[0].info.tourCode,
              },
            });
          },
        },
      };
    },
    getSimulationResult: (simulationResponse) => {
      const newTicketFareData = simulationResponse.airTicket.fare.travelerValue;
      const oldTicketFareData = this.state.tickets[0].fare.travelerValue;

      const oldTicketTaxesValue =
        oldTicketFareData.taxes.value + oldTicketFareData.du.value;
      const newTicketTaxesValue =
        newTicketFareData.taxes.value + newTicketFareData.du.value;

      let result = {
        oldFareData: {
          fare: oldTicketFareData.equivalentFare,
          thirdPartTransfer: {
            value: 0,
            currencyCode: 'BRL',
          },
          taxes: {
            value: oldTicketTaxesValue,
            currencyCode: oldTicketFareData.taxes.currencyCode,
          },
        },
        newFareData: {
          fare: newTicketFareData.equivalentFare,
          thirdPartTransfer: {
            value: 0,
            currencyCode: 'BRL',
          },
          taxes: {
            value: newTicketTaxesValue,
            currencyCode: newTicketFareData.taxes.currencyCode,
          },
        },
        difference: {
          fare: {
            value:
              newTicketFareData.equivalentFare.value -
              oldTicketFareData.equivalentFare.value,
            currencyCode: newTicketFareData.equivalentFare.currencyCode,
          },
          thirdPartTransfer: {
            value: 0,
            currencyCode: 'BRL',
          },
          taxes: {
            value: newTicketTaxesValue - oldTicketTaxesValue,
            currencyCode: newTicketFareData.taxes.currencyCode,
          },
        },
        summary: {
          penalty: simulationResponse.summary.penalty,
          amountToPay: simulationResponse.summary.amountToPay,
          amountToReceive: simulationResponse.summary.amountToReceive,
        },
      };

      this.formatFareValues(result.oldFareData);
      this.formatFareValues(result.newFareData);
      this.formatFareValues(result.difference);
      this.formatFareValues(result.summary);

      return result;
    },
  };

  alert = {
    getOptions: () => {
      const _this = this;
      return {
        props: _this.state.alert,
        events: {
          onCloseClicked: () => {
            _this.setState({
              alert: {
                ..._this.state.alert,
                show: false,
              },
            });
          },
        },
      };
    },
  };

  formatFareValues(fare) {
    for (let fareProp in fare) {
      let fareValue = fare[fareProp];

      if (
        fareValue &&
        fareValue.value != null &&
        fareValue.currencyCode != null
      )
        fareValue.formatted = utils.formatters.formatMoney(fare[fareProp]);
    }
  }

  fillTicketIdentifier(ticket) {
    ticket.identifier = `${ticket.airTrips[0].departure.iata}-${
      ticket.airTrips[ticket.airTrips.length - 1].arrival.iata
    }`;
  }

  fillTravelerFullName(traveler) {
    traveler.fullName = travelerResolver.getFullName(traveler);
  }

  ticketManager = {
    issue: (tickets) => {
      const _this = this;
      let dataSource = _this.props.dataSources.airTicket;
      dataSource.events &&
        dataSource.events.beforeIssue &&
        dataSource.events.beforeIssue(tickets);

      const request = _this.ticketManager.getIssueTicketRequest(tickets);

      dataSource.issue(request).then((response) => {
        dataSource.events &&
          dataSource.events.afterIssue &&
          dataSource.events.afterIssue(tickets, response);

        if (response.successful) {
          _this.props.onFinishFlowClick &&
            _this.props.onFinishFlowClick(_this.state.tickets);
        } else {
          let detailsErrors = response.errors
            ? response.errors.map((data) => {
                return data.detail ? data.detail : data.message;
              })
            : response.messages
            ? response.messages.map((data) => {
                return data.message;
              })
            : ['Sem detalhes'];

          _this.setState({
            alert: {
              ..._this.state.alert,
              show: true,
              type: enums.feedbackType.warning,
              message: 'Não foi possível realizar a emissão dos bilhetes.',
              details: detailsErrors,
            },
          });
        }
      });
    },
    simulateIssue: (ticket) => {
      const dataSource = this.props.dataSources.airTicket;
      const _this = this;

      dataSource.events &&
        dataSource.events.beforeSimulateIssue &&
        dataSource.events.beforeSimulateIssue(ticket);

      dataSource.simulateIssue(ticket).then((response) => {
        let simulationResult = _this.replacement.getSimulationResult(response);

        /*TODO:
         *  - verificar diferença (se sobra saldo)
         *  - validar se vai ser uma forma de pagamento ou um checkbox para utilizar o bilhete simulado
         */
        //var newPaymentForm = listItemsResolver.getItem(-1, `Bilhete simulado (${ticket.number})`);
        //newPaymentForm.airTicket = response.airTicket;

        dataSource.events &&
          dataSource.events.afterSimulateIssue &&
          dataSource.events.afterSimulateIssue(ticket, response);

        _this.setState({
          replacement: {
            ..._this.state.replacement,
            simulationResult,
          },
          //paymentForms: [..._this.state.paymentSelection.paymentForms, newPaymentForm]
        });
      });
    },

    simulateReplacement: (originTicketNumber, newTicket) => {
      const dataSource = this.props.dataSources.airTicket;
      const _this = this;

      dataSource.events &&
        dataSource.events.beforeGetTicket &&
        dataSource.events.beforeGetTicket(originTicketNumber);

      dataSource
        .simulateReplacement(originTicketNumber, newTicket)
        .then((replacementData) => {
          dataSource.events &&
            dataSource.events.afterGetTicket &&
            dataSource.events.afterGetTicket(
              originTicketNumber,
              replacementData,
            );
          _this.setState({
            paymentSelection: {
              ..._this.state.paymentSelection,
              replacementData:
                _this.ticketManager.formatReplacementData(replacementData),
            },
          });
        });
    },

    formatReplacementData: (replacementData) => {
      if (!replacementData.successfully) {
        return {
          successfully: false,
          errorMessage: replacementData.errors[0].message,
        };
      } else {
        const newTicketFareData = replacementData.airTicket.fare.travelerValue;
        const oldTicketFareData = this.state.tickets[0].fare.travelerValue;

        const oldTicketTaxesValue =
          oldTicketFareData.taxes.value + oldTicketFareData.du.value;
        const newTicketTaxesValue =
          newTicketFareData.taxes.value + newTicketFareData.du.value;

        let result = {
          successfully: true,
          airTicket: replacementData.airTicket,
          summary: {
            penalty: replacementData.summary.penalty,
            amountToPay: replacementData.summary.amountToPay,
            amountToReceive: replacementData.summary.amountToReceive,
            //amount: {
            //    value:
            //        newTicketFareData.equivalentFare.value
            //        + replacementData.summary.amountToPay.value
            //        + replacementData.summary.penalty.value
            //        - replacementData.summary.amountToReceive.value,

            //    currencyCode: newTicketFareData.equivalentFare.currencyCode
            //}
          },
        };

        this.formatFareValues(result.summary);

        return result;
      }
    },

    getIssueTicketRequest: (tickets) => {
      let request = {
        airBookingId: this.props.booking.id,
        ticketsRequest: [],
      };

      for (let tIndex = 0; tIndex < tickets.length; tIndex++) {
        let ticket = tickets[tIndex];

        let ticketRequestTraveler = {
          ...ticket.traveler,
        };

        let equivalentBookingTraveler =
          this.ticketManager.getEquivalentTraveler(
            ticket.traveler,
            this.props.booking.travelers,
          );
        if (equivalentBookingTraveler) {
          ticketRequestTraveler.id = equivalentBookingTraveler.id;
          ticketRequestTraveler.userId = equivalentBookingTraveler.userId;
        }

        let ticketRequestItem = {
          ...ticket.originalFromResponse,

          traveler: ticketRequestTraveler,

          tourCode: ticket.info.tourCode,
          endorsement: ticket.info.endorsement,

          paymentData: [],
        };

        delete ticketRequestItem.fare;
        delete ticketRequestItem.startAt;
        delete ticketRequestItem.endAt;

        for (let pIndex = 0; pIndex < ticket.payments.length; pIndex++) {
          let payment = ticket.payments[pIndex];
          let paymentForm = paymentResolver.convertPaymentFormComponentObjToApi(
            payment.paymentForm,
          );

          ticketRequestItem.paymentData.push({
            type: 0,
            paymentForm: paymentForm,
            value: ticket.fare.travelerValue.total,
          });
        }

        request.ticketsRequest.push(ticketRequestItem);
      }

      return request;
    },

    getEquivalentTraveler: (ticketTraveler, bookingTravelers) => {
      let ticketTravelerHash = travelerResolver.getTravelerHash(ticketTraveler);
      let equivalentTraveler = bookingTravelers.find(
        (traveler) =>
          travelerResolver.getTravelerHash(traveler) == ticketTravelerHash,
      );

      return equivalentTraveler;
    },
  };

  validationManager = {
    validate: () => {
      let valid = true;
      let tickets = this.props.tickets.filter((t) => t.checked);

      tickets.forEach((ticket, index) => {
        if (
          !ticket.payments ||
          !ticket.payments.length ||
          !ticket.payments.every((p) => p.paymentForm)
        ) {
          valid = false;
          return;
        } else if (
          ticket.payments.length < 2 &&
          ticket.payments[0].paymentForm.airTicket &&
          this.state.paymentSelection.confirmedReplacementTicket.summary
            .amountToPay.value > 0
        ) {
          valid = false;
          return;
        }
      });

      return valid;
    },
  };
}
