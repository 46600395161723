export const creditCardUtil = {
  getCreditCardBrandCode: (cardNumber) => {
    let cardnumber = cardNumber.replace(/[^0-9]+/g, '');

    let cards = {
      amex: /^3[47][0-9]{13}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
      mastercard:
        /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[0-1]\d|2720)[0-9]{12}|^2[2-7][0-9]{14}$"/,
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
    };

    for (let flag in cards) {
      if (cards[flag].test(cardnumber)) {
        return flag;
      }
    }

    return false;
  },

  maskCreditCardNumber: (cardNumber) => {
    let numberBegin = cardNumber.substring(0, 4);
    let numberEnd = cardNumber.substring(cardNumber.length - 4);

    return numberBegin + '.XXXX.XXXX.' + numberEnd;
  },

  finalCreditCardNumber: (cardNumber) => {
    return cardNumber.substring(cardNumber.length - 4);
  },
};
