import { enums, travelEnums } from '../enums';
import { statusFromCodes } from '../statusCodes';

export const statusFlowResolver = {
  getRecordAllowedActions: (
    record,
    requester,
    currentUserId,
    currentUserTravelProfile,
    companyData,
  ) => {
    const _this = statusFlowResolver;
    const bookingSystem = record.suplierName || record.connectionSource || null;

    return _this.getAllowedActions(
      record.workflow.status,
      companyData,
      record.expiration,
      record.audit.dataOrigin,
      requester,
      currentUserId,
      currentUserTravelProfile,
      bookingSystem,
      record.bookingStatus,
      record.bookingLocator,
      record.itemsSummary,
    );
  },

  getAllowedActions: (
    workflowStatusData,
    companyData,
    expiration,
    dataOrigin,
    requester,
    currentUserId,
    currentUserTravelProfile,
    bookingSystem,
    bookingStatus,
    bookingLocator,
    itemsSummary,
  ) => {
    const isCurrentUserSRRequestor = requester?.userId == currentUserId;

    const allowedActions = {
      canView: true,

      canFetch: false,

      canAdd: false,
      canCancel: false,

      canSendToAuthorization: false,
      canAuthorize: false,
      canRefuse: false,

      canIssue: false,
      canConfirmData: false,
      canConfirmIssuance: false,

      sendNotification: true,
      canEditFares: false,
      canEditCostSplit: false,
      canEdit: false,
      integrations: {
        apps: {
          isEnabled: false,
          canDispatch: false,
          canCancel: false,
        },
      },
    };

    if (
      currentUserTravelProfile == enums.userTravelProfile.consultant ||
      isCurrentUserSRRequestor
    ) {
      allowedActions.canFetch = true;
      allowedActions.canEditCostSplit = true;
    }

    if (
      companyData &&
      companyData.isAgency &&
      currentUserTravelProfile == enums.userTravelProfile.consultant
    ) {
      allowedActions.integrations.apps.isEnabled = true;

      if (
        dataOrigin == enums.requestDataOrigin.manual ||
        dataOrigin == enums.requestDataOrigin.mixed
      )
        allowedActions.canEdit = true;
    }

    switch (workflowStatusData.code) {
      case statusFromCodes.toBook:
        if (isCurrentUserSRRequestor) {
          allowedActions.canCancel = true;
        }

        if (currentUserTravelProfile == enums.userTravelProfile.consultant) {
          allowedActions.canIssue = true;
          allowedActions.canFetch = true;
          allowedActions.canCancel = true;

          if (bookingSystem == travelEnums.hotelSources.offline)
            allowedActions.canEditFares = true;
        }

        if (
          bookingStatus == enums.bookingStatus.bookPending &&
          bookingSystem == travelEnums.hotelSources.offline &&
          currentUserTravelProfile == enums.userTravelProfile.consultant &&
          dataOrigin == 1
        )
          allowedActions.canConfirmData = true;

        break;

      case statusFromCodes.opened:
      case statusFromCodes.inQuotation:
        allowedActions.sendNotification = false;

        if (
          isCurrentUserSRRequestor ||
          currentUserTravelProfile == enums.userTravelProfile.consultant
        ) {
          allowedActions.canCancel = true;
        }

        break;

      case statusFromCodes.booked:
        if (
          isCurrentUserSRRequestor ||
          currentUserTravelProfile == enums.userTravelProfile.consultant
        ) {
          if (expiration && !expiration.expired) {
            allowedActions.canSendToAuthorization = true;
          }
          allowedActions.canCancel = true;
        }

        if (currentUserTravelProfile == enums.userTravelProfile.consultant) {
          allowedActions.canCancel = true;
        }

        break;

      case statusFromCodes.inAuthorization:
        if (
          isCurrentUserSRRequestor ||
          currentUserTravelProfile == enums.userTravelProfile.consultant
        ) {
          allowedActions.canCancel = true;
        }

        if (
          currentUserTravelProfile == enums.userTravelProfile.approver ||
          currentUserTravelProfile == enums.userTravelProfile.consultant
        ) {
          if (expiration && !expiration.expired) {
            allowedActions.canAuthorize = true;
          }
          allowedActions.canCancel = true;
          allowedActions.canRefuse = true;
        }

        allowedActions.canEditCostSplit &&= false;

        break;

      case statusFromCodes.inIssue:
        if (isCurrentUserSRRequestor) {
          allowedActions.canCancel = true;
        }

        if (currentUserTravelProfile == enums.userTravelProfile.consultant) {
          allowedActions.canIssue = true;
          allowedActions.canFetch = true;
          allowedActions.canCancel = true;
        }

        if (
          bookingStatus == enums.bookingStatus.bookPending &&
          bookingSystem == travelEnums.hotelSources.offline &&
          currentUserTravelProfile == enums.userTravelProfile.consultant &&
          dataOrigin == 1
        )
          allowedActions.canConfirmIssuance = true;

        allowedActions.canEditCostSplit &&= false;

        break;

      case statusFromCodes.issued:
        if (currentUserTravelProfile == enums.userTravelProfile.consultant) {
          allowedActions.canCancel = true;

          allowedActions.integrations.apps.canDispatch = true;
          allowedActions.integrations.apps.canCancel = true;
        }

        allowedActions.canEditCostSplit &&= false;

        break;

      case statusFromCodes.errorIssue:
        if (currentUserTravelProfile == enums.userTravelProfile.consultant) {
          allowedActions.canIssue = true;
          allowedActions.canFetch = true;
          allowedActions.canCancel = true;
        }

        allowedActions.canEditCostSplit &&= false;

        break;

      case statusFromCodes.inCancelation:
        allowedActions.sendNotification = false;

        if (
          isCurrentUserSRRequestor ||
          currentUserTravelProfile == enums.userTravelProfile.consultant
        ) {
          allowedActions.canCancel = true;
        }

        allowedActions.canEditCostSplit &&= false;

        break;

      case statusFromCodes.errorIntegrationIssue:
        if (
          companyData &&
          companyData.isAgency &&
          currentUserTravelProfile == enums.userTravelProfile.consultant
        ) {
          allowedActions.integrations.apps.canDispatch = true;
          allowedActions.integrations.apps.canCancel = true;
          allowedActions.canCancel = true;
        }

        allowedActions.canEditCostSplit &&= false;

        break;

      case statusFromCodes.integratedIssue:
        if (
          companyData?.isAgency &&
          currentUserTravelProfile == enums.userTravelProfile.consultant
        )
          allowedActions.integrations.apps.canCancel = true;

        allowedActions.canEditCostSplit &&= false;

        break;

      case statusFromCodes.errorBook:
        allowedActions.sendNotification = false;
        //allowedActions.canFetch = false;
        if (currentUserTravelProfile == enums.userTravelProfile.consultant) {
          allowedActions.canCancel = true;
        }

        allowedActions.canEditCostSplit &&= false;

        break;

      case statusFromCodes.optionRefused:
      case statusFromCodes.reproved:
      case statusFromCodes.inAutomaticIssue:
      case statusFromCodes.canceled:
      case statusFromCodes.inIntegration:
      case statusFromCodes.integratedCancelation:
        allowedActions.canEditCostSplit &&= false;

        break;
    }

    if (
      (itemsSummary || []).some((item) => {
        return [
          statusFromCodes.inAuthorization,
          statusFromCodes.inIssue,
          statusFromCodes.inAutomaticIssue,
          statusFromCodes.errorIssue,
          statusFromCodes.issued,
          statusFromCodes.inIntegration,
          statusFromCodes.integratedIssue,
          statusFromCodes.errorIntegrationIssue,
          statusFromCodes.integratedCancelation,
        ].includes(item.workflow.status.code);
      })
    )
      allowedActions.canEditCostSplit &&= false;

    return allowedActions;
  },

  getEmailAdreesseeByStatus: (statusId) => {
    switch (statusId) {
      case statusFromCodes.inAuthorization:
        return {
          to: { email: '@approvers', name: '' },
          cc: { email: '@travelers, @requester', name: '' },
          cco: { email: '@consultants', name: '' },
        };
      default:
        return {
          to: { email: '@travelers', name: '' },
          cc: { email: '@requester', name: '' },
          cco: { email: '@consultants', name: '' },
        };
    }
  },

  getFormattedEmailObject: (fieldsData) => {
    return {
      addressee: {
        to: {
          email: fieldsData.to.email,
          name: fieldsData.to.name,
        },
        cc: {
          email: fieldsData.cc.email,
          name: fieldsData.cc.name,
        },
        cco: {
          email: fieldsData.cco.email,
          name: fieldsData.cco.name,
        },
      },
      subject: fieldsData.subject,
      message: fieldsData.message,
    };
  },
};
