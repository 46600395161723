import { useState } from 'react';

export function useWizard() {
  const [step, setStep] = useState<number>(0);

  return {
    activeStep: step,
    onStepChange: setStep,
  };
}
