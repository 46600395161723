'use strict';
import React from 'react';
import Template from './flight-details.template.js';

export default class FlightDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Template.main flight={this.props.flight} />;
  }
}
