import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const CardContainer = styled.div`
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[80]};
  border-radius: ${(props) => props.theme.borders.radius.m};
  padding: ${(props) => props.theme.spacings.xs2};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColumnsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.s};
`;

export const RowColumn = styled.div``;

export const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .MuiButtonBase-root {
    margin-left: ${(props) => props.theme.spacings.xs4};
  }
`;

export const ColumnTitle = styled(Typography).attrs({
  variant: 'body4',
})`
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacings.xs5};
`;
