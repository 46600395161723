import { useTravelDataTable } from '@components/TravelDataTable/hooks/useDataTable';
import { TravelDataTableProps } from '@components/TravelDataTable/types';
import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';

export function TableFilters<T>(props: TravelDataTableProps<T>) {
  const { handleFilters } = useTravelDataTable(props);

  const tableFilters = props.filters;

  const handleFilterChange = (filters) => {
    const formattedFilters = filters.reduce((acc, filter) => {
      acc[filter.name] = filter.values;
      return acc;
    }, {});
    console.log('formattedFilters', formattedFilters);
    handleFilters(formattedFilters);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Table.Filters
        style={{ width: 'auto' }}
        label="Filtrar por:"
        filters={tableFilters}
        onChange={handleFilterChange}
      />

      {props.headerEndContent}
    </Box>
  );
}
