import React from 'react';
import Template from './split-grid-cell-cost-center.template';

export default class SplitCellCostCenterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedItem: this.props.selected, input: '' };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ selectedItem: this.props.selected, input: '' });
    } else if (prevProps.selected !== this.props.selected) {
      this.setState({ selectedItem: this.props.selected });
    }
  }

  onChange(selectedItem) {
    this.props.editAction(this.props.id, selectedItem);
    this.setState({ selectedItem, input: '' });
  }

  onInputChange(input) {
    this.setState({ input });
  }

  render() {
    return (
      <Template.main
        {...this.props}
        notValid={!this.state.selectedItem?.value}
        filter={this.props.filter}
        selectedItem={this.state.selectedItem}
        input={this.state.input}
        onChange={this.onChange.bind(this)}
        onInputChange={this.onInputChange.bind(this)}
      />
    );
  }
}
