import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styled';
interface RoundedIconProps {
  icon:
    | 'IconCheck'
    | 'IconPlaneTilt'
    | 'IconBed'
    | 'IconX'
    | 'IconAlertCircle'
    | 'IconShoppingBag';
  size?: SC.Size;
  children?: string;
  variant?: SC.Variant;
}
const iconSize = {
  lg: 36,
  md: 24,
  sm: 12,
};
export function RoundedIcon({
  variant = 'primary',
  icon,
  children,
  size = 'lg',
}: RoundedIconProps) {
  return (
    <SC.Container>
      <SC.RoundedIconContainer variant={variant} size={size}>
        <div>
          <Icons
            name={icon}
            width={iconSize[size]}
            height={iconSize[size]}
          ></Icons>
        </div>
      </SC.RoundedIconContainer>
      <Typography variant="body2" weight={600} variantColor="#53464F">
        {children}
      </Typography>
    </SC.Container>
  );
}
