let Template = (props) => {
  let provider = null;

  if (props.sourceSupplier && props.connectionSource)
    provider = <p> {`${props.sourceSupplier} (${props.connectionSource})`} </p>;
  else
    provider = (
      <p>
        {' '}
        {props.connectionSource
          ? props.connectionSource
          : props.sourceSupplier
          ? props.sourceSupplier
          : null}{' '}
      </p>
    );

  return <div className="exon-hotel-provider">{provider}</div>;
};

export default Template;
