'use strict';

import React from 'react';
import Template from './booking-air-fariffs-and-fess.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';

import { enums } from '@legacy-utils/enums';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import { utils } from '@legacy-utils/utils';
import { componentUtil } from '@legacy-utils/componentUtil.js';
import { getUserProfile } from '@legacy-dataSources/profile.dataSources';

export default class BookingAirTariffsAndFeesComponent extends React.Component {
  constructor(props) {
    super(props);
    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.formManager, this);

    this.state = {
      userProfile: null,
      tariffsAndFees: this.props.tariffsAndFees
        ? this.props.tariffsAndFees
        : null,
      fares: this.props.fares,

      form: {
        show: false,
        events: this.events.form,
        fields: this.formManager.getFields(),
        editIndex: null,
        forceValidation: false,
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          userProfile={this.state.userProfile}
          tariffsAndFees={this.state.tariffsAndFees}
          connectionSource={this.props.connectionSource}
          canEdit={this.props.canEdit}
          form={this.state.form}
          events={this.events}
        />
      </ErrorBoundary>
    );
  }

  events = {
    form: {
      popup: {
        onOpenFormPopupClicked: (action, index) => {
          let form = this.state.form;

          if (action == enums.actionsForm.edit) {
            this.formManager.filledFields(form, index);
            form.editIndex = index;
          }

          form.show = true;

          this.setState({ form });
        },

        onCloseFormPopupClicked: () => {
          let form = this.state.form;

          this.formManager.clearFields(form);

          this.setState({
            form: {
              ...this.state.form,
              show: false,
              forceValidation: false,
              editIndex: null,
            },
          });
        },

        onConfirmPopupClicked: () => {
          let fares = this.state.fares;

          if (this.formManager.fieldsValidate()) {
            this.formManager.prepareForShipping(
              fares,
              this.state.form.editIndex,
            );

            this.props.events.onEditAirTariffsAndFessClick &&
              this.props.events.onEditAirTariffsAndFessClick(fares);

            this.setState({
              form: {
                ...this.state.form,
                show: false,
                forceValidation: false,
                editIndex: null,
              },
            });
          }
        },
      },

      fields: {
        onLocationChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value.name = inputText;

          this.setState({ form });
        },

        onChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value = inputText;

          this.setState({ form });
        },

        onSelected: (refName, selectedData) => {
          let form = this.state.form;

          if (refName == 'baggage')
            form.fields[refName].value = selectedData.value;
          else form.fields[refName].value = selectedData;

          this.setState({ form });
        },
      },
    },

    onDeleteFareClicked: (index) => {
      let fares = this.state.fares;
      fares.splice(index, 1);

      if (this.props.events.onEditAirTariffsAndFessClick)
        this.props.events.onEditAirTariffsAndFessClick(fares);
    },
  };

  formManager = {
    getFields: () => {
      let labelCurrencyCode = 'Código da moeda';

      return {
        supplierSystem: this.formManager.generateField.select({
          label: 'Sistema',
          refName: `supplierSystem`,
          dataSource: this.props.dataSources.airSuppliers,
          productType: enums.products.type.air,
          readOnly:
            this.props.connectionSource && this.props.connectionSource !== ''
              ? true
              : false,
          value: this.props.connectionSource,
          groupType: 'fare',
        }),
        type: this.formManager.generateField.select({
          label: 'Tipo Passageiro',
          refName: 'type',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.passengerType),
              );
            },
          },
        }),
        //Tarifa Neto
        originalFareCurrencyCode: this.formManager.generateField.select({
          label: `${labelCurrencyCode} (Tarifa Neto)`,
          refName: 'originalFareCurrencyCode',
          groupType: 'currencyCode',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.currencyCode),
              );
            },
          },
        }),
        originalFare: this.formManager.generateField.number({
          label: 'Tarifa Neto',
          refName: 'originalFare',
        }),

        //Tarifa Equivalente
        fareCurrencyCode: this.formManager.generateField.select({
          label: `${labelCurrencyCode} (Tarifa Equivalente)`,
          refName: 'fareCurrencyCode',
          groupType: 'currencyCode',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.currencyCode),
              );
            },
          },
        }),
        fare: this.formManager.generateField.number({
          label: 'Tarifa Equivalente',
          refName: 'fare',
        }),

        //Tarifa DU
        duCurrencyCode: this.formManager.generateField.select({
          label: `${labelCurrencyCode} (Tarifa DU)`,
          refName: 'duCurrencyCode',
          groupType: 'currencyCode',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.currencyCode),
              );
            },
          },
        }),
        du: this.formManager.generateField.number({
          label: 'Tarifa DU',
          refName: 'du',
        }),

        //Taxas
        taxesCurrencyCode: this.formManager.generateField.select({
          label: `${labelCurrencyCode} (Taxas)`,
          refName: 'taxesCurrencyCode',
          groupType: 'currencyCode',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.currencyCode),
              );
            },
          },
        }),
        taxes: this.formManager.generateField.number({
          label: 'Taxas',
          refName: 'taxes',
        }),

        //Multa
        penaltyCurrencyCode: this.formManager.generateField.select({
          label: `${labelCurrencyCode} (Multa)`,
          refName: 'penaltyCurrencyCode',
          required: false,
          groupType: 'currencyCode',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.currencyCode),
              );
            },
          },
        }),
        penalty: this.formManager.generateField.number({
          label: 'Multa',
          refName: 'penalty',
          required: false,
        }),

        //Markup
        markupCurrencyCode: this.formManager.generateField.select({
          label: `${labelCurrencyCode} (Markup)`,
          refName: 'markupCurrencyCode',
          groupType: 'currencyCode',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.currencyCode),
              );
            },
          },
        }),
        markup: this.formManager.generateField.number({
          label: 'Markup',
          refName: 'markup',
        }),

        //Valor câmbio
        exchangeRateCurrencyCode: this.formManager.generateField.select({
          label: `${labelCurrencyCode} (Valor câmbio)`,
          refName: 'exchangeRateCurrencyCode',
          groupType: 'exchangeRate',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.currencyCode),
              );
            },
          },
        }),
        exchangeRate: this.formManager.generateField.number({
          label: 'Valor câmbio',
          refName: 'exchangeRate',
          groupType: 'exchangeRate',
        }),

        exchangeDate: this.formManager.generateField.mask({
          label: 'Data câmbio',
          refName: 'exchangeDate',
          mask: '99/99/9999',
          placeholder: 'dd/mm/aaaa',
          removeMask: false,
          groupType: 'exchangeRate',
        }),
        fareBasis: this.formManager.generateField.text({
          label: 'Base Tarifária',
          refName: 'fareBasis',
          groupType: 'fare',
        }),
        //tourCode: this.formManager.generateField.text({
        //    label: "TourCode",
        //    refName: "tourCode",
        //    groupType: "fare",
        //})
      };
    },

    filledFields: (form, index) => {
      let tariffsAndFees = this.state.tariffsAndFees[index];
      let originalTariffsAndFees = tariffsAndFees.originalTariffsAndFees;
      let getFormattedDateObjFromDateObject =
        utils.formatters.date.getFormattedDateObjFromDateObject;
      let moneyFormatted = utils.formatters.money.getFormatted;

      Object.keys(tariffsAndFees).forEach((refName) => {
        if (originalTariffsAndFees[refName] && form.fields[refName])
          form.fields[refName].value = originalTariffsAndFees[refName];

        if (
          originalTariffsAndFees.travelerValue[refName] !== undefined &&
          originalTariffsAndFees.travelerValue[refName] !== null
        ) {
          if (refName == 'type') {
            form.fields[refName].value =
              originalTariffsAndFees.travelerValue[refName];
          } else {
            form.fields[refName].value =
              originalTariffsAndFees.travelerValue[refName].value.toFixed(2);
            form.fields[`${refName}CurrencyCode`].value =
              enums.currency[
                originalTariffsAndFees.travelerValue[refName].currencyCode
              ];
          }
        }

        if (refName == 'exchangeRate') {
          form.fields[refName]
            ? (form.fields[refName].value = originalTariffsAndFees.exchangeRate
                ? originalTariffsAndFees.exchangeRate.value
                : null)
            : null;
          form.fields.exchangeRateCurrencyCode
            ? (form.fields.exchangeRateCurrencyCode.value =
                originalTariffsAndFees.exchangeRate
                  ? enums.currency[originalTariffsAndFees.exchangeRate.origin]
                  : null)
            : null;
        }
        if (refName == 'exchangeDate')
          form.fields[refName]
            ? (form.fields[refName].value =
                originalTariffsAndFees.exchangeRate &&
                originalTariffsAndFees.exchangeRate.date
                  ? getFormattedDateObjFromDateObject(
                      originalTariffsAndFees.exchangeRate.date,
                    ).dateFormatted
                  : null)
            : null;

        if (refName == 'supplierSystem')
          form.fields[refName].value = this.props.connectionSource;
      });
    },

    clearFields: (form) => {
      Object.keys(form.fields).forEach((refName) => {
        if (refName !== 'supplierSystem') form.fields[refName].value = null;
      });
    },

    generateField: {
      number: (data) => {
        let numberField = this.formManager.generateField.commomFields(data);
        numberField.type = enums.fieldType.number;

        return numberField;
      },
      text: (data) => {
        let textField = this.formManager.generateField.commomFields(data);
        textField.type = enums.fieldType.text;

        return textField;
      },
      select: (data) => {
        let selectField = this.formManager.generateField.commomFields(data);
        selectField.type = enums.fieldType.select;
        selectField.items = [];
        selectField.onFieldChange = this.events.form.fields.onSelected;
        selectField.dataSource = data.dataSource;
        selectField.completeItem = false;
        selectField.isZeroValid = true;
        return selectField;
      },

      mask: (data) => {
        let fieldMask = this.formManager.generateField.commomFields(data);
        fieldMask.type = enums.fieldType.mask;
        fieldMask.mask = data.mask;
        data.removeMask !== undefined
          ? (fieldMask.removeMask = data.removeMask)
          : null;

        return fieldMask;
      },
      commomFields: (data) => {
        return {
          placeholder: data.placeholder ? data.placeholder : `Digite aqui`,
          label: data.label,
          refName: data.refName,
          value: data.value ? data.value : null,
          required:
            data.required != null || data.required != undefined
              ? data.required
              : true,
          disabled:
            data.disabled != null || data.disabled != undefined
              ? data.disabled
              : false,
          onFieldChange: this.events.form.fields.onChanged,
          groupType: data.groupType,
          readOnly: data.readOnly ? data.readOnly : false,
        };
      },
    },

    fieldsValidate: () => {
      let isValid = true;
      let fields = this.state.form.fields;

      Object.keys(fields).forEach((refName) => {
        let fieldValue = fields[refName].value;

        if (refName != 'penaltyCurrencyCode' && refName != 'penalty')
          isValid =
            isValid &&
            fieldValue !== null &&
            fieldValue !== undefined &&
            fieldValue !== '';
      });

      if (!isValid)
        this.setState({
          form: {
            ...this.state.form,
            forceValidation: true,
          },
        });

      return isValid;
    },

    prepareForShipping: (fares, index) => {
      let form = this.state.form;
      let fare = {
        travelerValue: {},
        exchangeRate: {},
      };

      Object.keys(form.fields).forEach((refName) => {
        let fields = form.fields;

        switch (fields[refName].groupType) {
          case 'fare':
            if (
              refName == 'tourCode' ||
              refName == 'fareBasis' ||
              refName == 'supplierSystem'
            )
              fare[refName] = fields[refName].value;
            break;

          case 'exchangeRate':
            if (refName == 'exchangeDate')
              fare.exchangeRate.date = utils.converters.date.stringToDateObject(
                fields[refName].value,
              );
            if (refName == 'exchangeRate')
              fare.exchangeRate.value = parseFloat(fields[refName].value);
            if (refName == 'exchangeRateCurrencyCode')
              fare.exchangeRate.origin =
                enums.currencyCode[fields[refName].value];
            break;

          default:
            if (
              refName != 'type' &&
              (!fields[refName].groupType ||
                fields[refName].groupType != 'currencyCode')
            )
              fare.travelerValue[refName] = {
                currencyCode:
                  enums.currencyCode[fields[`${refName}CurrencyCode`].value],
                value: parseFloat(fields[refName].value),
              };
            if (!refName == 'type')
              fare.travelerValue[refName] = fields[refName].value;
        }
      });

      if (index != null && !isNaN(index) && index >= 0) {
        fares[index] = fare;
      } else {
        fares.push(fare);
      }
    },
  };

  componentDidMount() {
    getUserProfile().then(({ userProfile }) => {
      this.setState({ userProfile });
    });
  }
}
