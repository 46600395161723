let BookingItemAnalysisTemplate = ({ action }) => {
  return (
    <div className="exon-booking-item-analysis">
      <div className="box-styleless-table">
        <table className="exon-styleless-table">
          <thead>
            <tr>
              <th>Preços, tarifas e comparativos </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{action}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingItemAnalysisTemplate;
