'use strict';

import React from 'react';

import Template from './vehicle-booking-fares-and-fees.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';

import { getUserProfile } from '@legacy-dataSources/profile.dataSources';

export default class VehicleBookingFaresAndFeesComponent extends React.Component {
  constructor(props) {
    super(props);

     this.state = {
       userProfile: null,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main {...this.props} />
      </ErrorBoundary>
    );
  }

  componentDidMount() {
    getUserProfile().then(({ userProfile }) => {
      this.setState({ userProfile });
    });
  }
}
