import RangeTimeComponent from '../range-time/range-time.component.js';

var TimeCourseTemplate = {
  main: ({
    periods,
    itemSelected,
    itensPeriodsPresetsVisibility,
    itemPeriodsCustomVisibility,
    showBackgroundMask,
    onOpenPresetClicked,
    onBackgroundMaskClicked,
    onPresetClicked,
    setRange,
  }) => (
    <div className="exon-time-course">
      <div
        className={
          'exon-outside-options-background-mask ' +
          (!showBackgroundMask ? 'hide' : '')
        }
        onClick={() => onBackgroundMaskClicked(false)}
      ></div>
      <button
        className="exon-time-course__button-open-presets"
        onClick={onOpenPresetClicked}
      >
        {itemSelected.name}
      </button>
      <TimeCourseTemplate.periodPresets
        periods={periods}
        itensPeriodsPresetsVisibility={itensPeriodsPresetsVisibility}
        itemPeriodsCustomVisibility={itemPeriodsCustomVisibility}
        onPresetClicked={onPresetClicked}
        itemSelected={itemSelected}
        setRange={setRange}
      ></TimeCourseTemplate.periodPresets>
    </div>
  ),

  periodPresets: ({
    periods,
    itensPeriodsPresetsVisibility,
    itemPeriodsCustomVisibility,
    itemSelected,
    onPresetClicked,
    setRange,
  }) => {
    return (
      <div
        className={
          'exon-time-course__period-presets ' +
          (!itensPeriodsPresetsVisibility ? 'hide' : '')
        }
      >
        <label className="exon-time-course__button-open">
          <strong>Período</strong>
        </label>
        {periods.map((option, index) => {
          return (
            <button
              className={
                'exon-time-course__botton-item' +
                (itemSelected.id == option.id ? '-active ' : '')
              }
              key={index}
              onClick={(ev) => onPresetClicked(ev, option.id)}
            >
              {option.id == 5 ? 'Escolher' : option.name}
            </button>
          );
        })}
        {itemPeriodsCustomVisibility && (
          <RangeTimeComponent
            initialValue={itemSelected.range.start}
            finalValue={itemSelected.range.end}
            onValuesChange={setRange}
          />
        )}
      </div>
    );
  },
};

export default TimeCourseTemplate;
