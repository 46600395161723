import Popup from '../../../popup/popup.component.js';
import Select from '../../../select/select.component.js';

let SearchTemplate = {
  main: ({
    showPopup,
    companiesRequestSearcherProps,
    events,
    requestSearcher,
    onRequestNumberChange,
    forceValidation,
  }) => {
    return (
      <div className="exon-requests-travel-header-input">
        {showPopup && (
          <Popup
            okButtonLabel={'Pesquisar'}
            title={''}
            closeButtonClick={events.onClosePopupclicked}
            cancelButtonClick={events.onClosePopupclicked}
            cancelButtonLabel={'Cancelar'}
            exonStyle={true}
            okButtonClick={events.onSearchByNumberClick}
          >
            <div className="exon-box-popop">
              <div>
                <h2>Buscar</h2>
              </div>
              <div className="exon-search-field">
                <label className="exon-search-label">Requisição</label>
                <div className="exon-search-input">
                  <input
                    placeholder={'Digite o nº'}
                    className="dm-grid-filter form-control exon-input-request-number"
                    value={requestSearcher.requestNumber}
                    onKeyPress={(e) => events.onRequestKeyPress(e)}
                    onChange={(e) => onRequestNumberChange(e)}
                  />
                </div>
              </div>
              <div className="exon-search-field">
                <label className="exon-search-label">Cliente</label>
                <div className="exon-search-input">
                  <Select
                    data={companiesRequestSearcherProps.data}
                    externalOptions={companiesRequestSearcherProps.options}
                    placeholder={companiesRequestSearcherProps.placeholder}
                    required={true}
                    forceValidation={forceValidation}
                    showSearch={true}
                  ></Select>
                </div>
              </div>
            </div>
          </Popup>
        )}

        <div className="exon-field">
          <label className="exon-label-field">Requisição</label>
          <input
            placeholder={'Digite o nº'}
            className="dm-grid-filter form-control exon-input-request-number"
            value={requestSearcher.requestNumber}
            onKeyPress={(e) => events.onRequestKeyPress(e)}
            onChange={(e) => onRequestNumberChange(e)}
          />
        </div>
        <i
          className="exon-icon exon-requests-travel-header-icon-search exon-icon-search-unfilled_disabled exon-icon-button"
          onClick={() => events.onSearchByNumberClick()}
        ></i>
      </div>
    );
  },
};

export default SearchTemplate;
