'use strict';

import React, { useEffect } from 'react';

import Template from './vehicle-booking.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

// import { enums } from '@legacy-utils/enums';
import { statusFlowResolver } from '@legacy-utils/resolvers/statusFlowResolver.js';
import { componentUtil } from '@legacy-utils/componentUtil';
// import { utils, gritter } from '@legacy-utils/utils.js';

//#region HOC
import { useTravelItemStore } from '../../../../store/RequestTravelItem.store';
export default function VehicleBookingComponent(props) {
  const updateCurrentServiceRequestItem = useTravelItemStore(
    (state) => state.updateCurrentServiceRequestItem,
  );

  useEffect(() => {
    updateCurrentServiceRequestItem(props.booking);
  }, [props.booking?.identifier, props.booking?.id]);

  return <_VehicleBookingComponent {...props} />;
}
//#endregion

class _VehicleBookingComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      booking: {},
      popupEditSegments: {
        show: false,
        data: null,
        events: {
          confirm: null,
          cancel: null,
        },
      },
      popupEditFaresAndFees: {
        show: false,
        data: null,
        events: {
          confirm: null,
          cancel: null,
        },
      },
      popupDeleteFaresAndFees: {
        show: false,
        data: null,
        events: {
          confirm: null,
          cancel: null,
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    let booking = props.booking;

    if (!booking.loading)
      booking.allowedActions = statusFlowResolver.getRecordAllowedActions(
        booking,
        props.requester,
        props.currentUserData.userId,
        props.currentUserData.travelProfile,
        props.currentUserData.company,
      );

    return {
      booking,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          booking={this.state.booking}
          events={this.events}
          dataSources={this.props.dataSources}
          onShowEmailPopupClick={this.props.onShowEmailPopupClick}
          popupEditSegments={this.state.popupEditSegments}
          popupEditFaresAndFees={this.state.popupEditFaresAndFees}
          popupDeleteFaresAndFees={this.state.popupDeleteFaresAndFees}
        />
      </ErrorBoundary>
    );
  }

  events = {
    booking: {
      onUpdateBookingClicked: () => {
        this.props.events.onRefreshBookingClicked &&
          this.props.events.onRefreshBookingClicked();
      },

      onSendAppsClicked: (id) => {
        this.props.events.onSendAppsClicked &&
          this.props.events.onSendAppsClicked(id);
      },
    },
    editItems: {
      onEditSegmentsClicked: (data) => {
        this.popup.editSegments.onShowPopupClicked();
      },
      onConfirmEditSegmentsClicked: (data) => {
        this.props.events.onRefreshBookingClicked &&
          this.props.events.onRefreshBookingClicked();

        this.popup.editSegments.onClosePopupClicked();
      },
      onEditFaresAndFeesClicked: (data) => {
        this.popup.editFaresAndFees.onShowPopupClicked();
      },
      onConfirmEditFaresAndFeesClicked: () => {
        this.props.events.onRefreshBookingClicked &&
          this.props.events.onRefreshBookingClicked();

        this.popup.editFaresAndFees.onClosePopupClicked();
      },

      onDeleteFaresAndFeesClicked: () => {
        this.popup.deleteFaresAndFees.onShowPopupClicked();
      },
      onConfirmDeleteFaresAndFeesClicked: () => {
        let booking = this.props.booking;
        booking.vehicle.values = null;

        this.props.events.onEditVehicleFaresAndFessClicked &&
          this.props.events.onEditVehicleFaresAndFessClicked(booking);

        this.popup.deleteFaresAndFees.onClosePopupClicked();
      },
    },
  };

  popup = {
    editSegments: {
      onClosePopupClicked: () => {
        this.setState({
          popupEditSegments: {
            show: false,
            data: null,
            events: {
              confirm: null,
              cancel: null,
            },
          },
        });
      },
      onShowPopupClicked: () => {
        this.setState({
          popupEditSegments: {
            show: true,
            data: this.state.booking,
            events: {
              confirm: this.events.editItems.onConfirmEditSegmentsClicked,
              cancel: this.popup.editSegments.onClosePopupClicked,
            },
          },
        });
      },
    },
    editFaresAndFees: {
      onClosePopupClicked: () => {
        this.setState({
          popupEditFaresAndFees: {
            show: false,
            data: null,
            events: {
              confirm: null,
              cancel: null,
            },
          },
        });
      },
      onShowPopupClicked: () => {
        this.setState({
          popupEditFaresAndFees: {
            show: true,
            data: this.state.booking,
            events: {
              confirm: this.events.editItems.onConfirmEditFaresAndFeesClicked,
              cancel: this.popup.editFaresAndFees.onClosePopupClicked,
            },
          },
        });
      },
    },
    deleteFaresAndFees: {
      onClosePopupClicked: () => {
        this.setState({
          popupDeleteFaresAndFees: {
            show: false,
          },
        });
      },
      onShowPopupClicked: () => {
        this.setState({
          popupDeleteFaresAndFees: {
            show: true,
            data: this.state.booking,
            events: {
              confirm: this.events.editItems.onConfirmDeleteFaresAndFeesClicked,
              cancel: this.popup.deleteFaresAndFees.onClosePopupClicked,
            },
          },
        });
      },
    },
  };
}
