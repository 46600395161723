'use strict';

import React from 'react';
import BookingPaymentTemplate from './booking-payment.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class BookingPaymentComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <BookingPaymentTemplate {...this.props} />
      </ErrorBoundary>
    );
  }
}
