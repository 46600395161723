'use strict';

import React from 'react';
import AirTicketCardTemplate from './air-ticket-card.template.js';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class AirTicketCardComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
  }

  render() {
    return (
      <ErrorBoundary>
        <AirTicketCardTemplate
          ticket={this.props.ticket}
          selectedTicketIndex={this.props.selectedTicketIndex}
          ticketIndex={this.props.ticketIndex}
          events={this.events}
        />
      </ErrorBoundary>
    );
  }

  events = {
    onSelectedTicketClicked: () => {
      if (this.props.onSelectedTicketClick)
        this.props.onSelectedTicketClick(this.props.ticketIndex);
    },
  };
}
