'use strict';
import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import { listItemsResolver } from '../../utils/resolvers/listItemresolver.js';
import GuestsTemplate from './guests.template.js';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';

export default class GuestsComponent extends React.Component {
  initialRooms = null;

  externalOptions = {
    events: {
      onGuestsApply: null,
      onClearExternal: null,
    },
  };

  constructor(props) {
    super(props);

    const { enableTravelersLimit } = useTravelPermissions(); // Obter a flag

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    };

    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.components, this);

    for (let component in this.components) {
      let componentFunctions = this.components[component];
      componentUtil.react.bindMethods(componentFunctions, this);
    }

    this.state = this.getFieldsInitialValue(enableTravelersLimit); // Passar a flag para o estado
  }

  static getDerivedStateFromProps(props, state) {
    if (props.initialData !== state.rooms) {
      return {
        rooms: props.initialData,
      };
    }
    return null;
  }

  getFieldsInitialValue = function (enableTravelersLimit) {
    return {
      labels: {
        room: 'Quarto',
        rooms: 'Quartos',
        guest: 'Hóspede',
        guests: 'Hóspedes',
        adults: 'Adultos',
        children: 'Crianças',
      },
      isVisible: false,
      listAdults: enableTravelersLimit
        ? this.getLimitedAdultsOptions()
        : this.getCounterAdults(0, 0),
      listChilds: this.getCounterChilds(1, 0, enableTravelersLimit),
      enableTravelersLimit: enableTravelersLimit,
    };
  };

  getLimitedAdultsOptions = function () {
    return [listItemsResolver.resolveRange(1, 1)]; // Apenas uma opção com valor 1
  };

  getResumeField = function () {
    let _this = this;

    let rooms = _this.state.rooms;

    let labelRoom =
      rooms.length > 1 ? _this.state.labels.rooms : _this.state.labels.room;
    let guestCount = 0;
    rooms.map((room) => {
      guestCount += room.adults + room.children;
    });

    let labelGuest =
      guestCount > 1 ? _this.state.labels.guests : _this.state.labels.guest;

    return (
      rooms.length + ' ' + labelRoom + ' - ' + guestCount + ' ' + labelGuest
    );
  };

  getCounterChilds = function (adults, index, enableTravelersLimit) {
    if (enableTravelersLimit) return []; // Ocultar opções de crianças se a flag estiver ativa
    let listChilds = this.state ? this.state.listChilds : [];
    listChilds[index] = listItemsResolver.resolveRange(0, 4 - adults);
    return listChilds;
  };

  getCounterAdults = function (childs, index) {
    let listAdults = this.state ? this.state.listAdults : [];
    listAdults[index] = listItemsResolver.resolveRange(0, 4 - childs);
    return listAdults;
  };

  setComponentVisibility = (visible) => {
    this.setState({
      isVisible: visible,
    });
  };

  validate = function (roomsLocal) {
    let message = '';
    roomsLocal.map((room, x) => {
      let i = x + 1;
      if (room.adults + room.children < 1) {
        message += '\nQuarto ' + i + ' deve ter pelo menos 1 hóspede';
      }
      if (room.adults + room.children > 4) {
        message += '\nQuarto ' + i + ' deve ter até 4 hóspede';
      }
    });

    if (message != '') {
      alert('Verifique as inconsistências abaixo' + message);
      return false;
    }
    return true;
  };

  render() {
    return (
      <GuestsTemplate.main
        roomsProps={this.components.rooms.getProps()}
        onFieldsClicked={this.events.onFieldsClicked}
        onCloseClicked={this.events.onCloseClicked}
        onClearFieldsClicked={this.events.onClearFieldsClicked}
        onRoomCloseClicked={this.events.onRoomCloseClicked}
        onAddRoom={
          this.state.enableTravelersLimit ? null : this.events.onAddRoom
        } // Desativar adicionar quarto se a flag estiver ativa
        onApplyFieldsClicked={this.events.onApplyFieldsClicked}
        childsProps={
          this.state.enableTravelersLimit
            ? null
            : this.components.childs.getProps()
        } // Ocultar seleção de crianças se a flag estiver ativa
        adultsProps={this.components.adults.getProps()}
      />
    );
  }

  components = {
    rooms: {
      getProps: function () {
        let _this = this;

        return {
          rooms: _this.state.rooms,
          roomCount: _this.state.rooms.length,
          labels: _this.state.labels,
          showComponent: _this.state.isVisible,
          resumeField: _this.getResumeField(),
        };
      },
    },
    childs: {
      getProps: function () {
        let _this = this;

        return {
          data: 0,
          options: _this.state.rooms.map(({ adults, children }, index) => {
            return {
              events: {
                onSelected: function (data, i) {
                  let inputText = event.target.value;
                  let roomsLocal = _this.state.rooms;

                  let children = parseInt(inputText);
                  roomsLocal[i].children = children;

                  _this.setState({ rooms: roomsLocal });

                  _this.setState({
                    listAdults: _this.getCounterAdults(children, index),
                  });
                },
              },
              items: _this.state.listChilds[index],
            };
          }),
        };
      },
    },
    adults: {
      getProps: function () {
        let _this = this;
        return {
          data: 0,
          options: _this.state.rooms.map(({ adults, children }, index) => {
            return {
              events: {
                onSelected: function (data, i) {
                  let inputText = event.target.value;
                  let roomsLocal = _this.state.rooms;

                  let adults = parseInt(inputText);
                  roomsLocal[i].adults = adults;

                  _this.setState({ rooms: roomsLocal });
                  _this.setState({
                    listChilds: _this.getCounterChilds(
                      adults,
                      index,
                      this.state.enableTravelersLimit,
                    ),
                  });
                },
              },
              items: _this.state.listAdults[index],
            };
          }),
        };
      },
    },
  };

  events = {
    onFieldsClicked: function () {
      this.setComponentVisibility(true);
    },
    onCloseClicked: function () {
      if (!this.validate(this.state.rooms)) {
        return;
      }
      this.setComponentVisibility(false);
    },
    onClearFieldsClicked: function () {
      if (this.props.onGuestsClearFieldsClick)
        this.props.onGuestsClearFieldsClick();
    },
    onRoomCloseClicked: function (event, i) {
      let roomsLocal = this.state.rooms;

      roomsLocal.splice(i, 1);
      this.setState({ rooms: roomsLocal });
    },
    onApplyFieldsClicked: function () {
      if (!this.validate(this.state.rooms)) {
        return;
      }

      let resumeF = this.getResumeField();

      this.setState({ resumeField: resumeF });
      this.setComponentVisibility(false);

      let dataSource = this.props.externalOptions;
      if (dataSource.events && dataSource.events.onGuestsApply) {
        dataSource.events.onGuestsApply(this.state.rooms);
      }
    },
    onAddRoom: function () {
      let roomsLocal = this.state.rooms;
      roomsLocal.push({
        adults: 1,
        children: 0,
      });

      let obj = {
        listChilds: this.getCounterChilds(1, this.state.listChilds.length),
        listAdults: this.getCounterAdults(0, this.state.listAdults.length),
        rooms: roomsLocal,
      };

      this.setState(obj);
    },
  };
}
