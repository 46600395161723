import * as SC from './styled';
import useShoppingCartStore from '../../../store/ShoppingCart.store';
import { useSpring, animated } from '@react-spring/web';
import { useEffect, useState } from 'react';

export default function ShoppingCartCounter() {
  const onShowShoppingCartClick = useShoppingCartStore(
    (state) => state.onShowShoppingCartClick,
  );
  const counterAir = useShoppingCartStore((state) => state.counterAir);
  const counterHotel = useShoppingCartStore((state) => state.counterHotel);

  const [animateAir, setAnimateAir] = useState(false);
  const [animateHotel, setAnimateHotel] = useState(false);

  const bounceAir = useSpring({
    transform: animateAir ? 'scale(1.4)' : 'scale(1)',
    config: { tension: 150, friction: 10 },
    onRest: () => setAnimateAir(false),
  });

  const bounceHotel = useSpring({
    transform: animateHotel ? 'scale(1.4)' : 'scale(1)',
    config: { tension: 150, friction: 10 },
    onRest: () => setAnimateHotel(false),
  });

  useEffect(() => {
    setAnimateAir(true);
  }, [counterAir]);

  useEffect(() => {
    setAnimateHotel(true);
  }, [counterHotel]);

  return (
    <SC.Container
      onClick={onShowShoppingCartClick}
      noPadding={counterAir === 0 && counterHotel === 0}
    >
      {(counterAir > 0 || counterHotel > 0) && (
        <SC.CountersCart>
          <SC.Aereo>
            <div>{counterAir}</div>
            <animated.span
              style={bounceAir}
              className="exon-icon exon-icon-aircraft-unfilled_disabled"
            />
          </SC.Aereo>
          <SC.Hotel>
            <div>{counterHotel}</div>
            <animated.span
              style={bounceHotel}
              className="exon-icon exon-icon-bed-unfilled_disabled"
            />
          </SC.Hotel>
        </SC.CountersCart>
      )}

      <SC.IconCart noBorderLeft={counterAir === 0 && counterHotel === 0}>
        <span className="exon-icon exon-icon-marketing-unfilled_disabled"></span>
        {(counterAir > 0 || counterHotel > 0) && (
          <SC.IconDot>{counterAir + counterHotel}</SC.IconDot>
        )}
      </SC.IconCart>
    </SC.Container>
  );
}
