import { PaymentItem } from '@models/PaymentItem';
import {
  CreditCard,
  GetForEmployeesResponse,
} from 'bff/src/routers/employee-infos';
import { addHours } from 'date-fns';

export type PaymentOption = {
  type: number;
  reference?: string;
  storedCreditCard?: string;
  creditCard?: LegacyCreditCard;
  productType: number;
  instalments?: number;
  flashPayment?: FlashPayment;
};

export type FlashPayment = {
  employeeId: string;
};

export type LegacyCreditCard = {
  number: string;
  provider: string;
  expireYear: string;
  expireMonth: string;
  printedName: string;
  securityCode: string;
};

export type PaymentForm = {
  creditCard: LegacyCreditCardForm;
  paymentType: number;
  storedCreditCard: string;
  reference: string;
  instalments: number;
};

export type LegacyCreditCardForm = {
  brandCode: string;
  companyId: number;
  expirationDate: string;
  holderName: string;
  id: number;
  number: string;
  securityCode: string;
  cvv: string;
  holder: string;
};

export const parseFlashPayToPaymentOption = (
  item: PaymentItem,
  productType: number,
) => {
  let option: PaymentOption = {
    type: 5,
    instalments: 1,
    productType,
    flashPayment: {
      employeeId: item.employee,
    },
  };
  if (item.card) option.storedCreditCard = item.card.cardId;
  return option;
};

export const parseToPaymentOption = (
  paymentData: PaymentForm,
  productType: number,
): PaymentOption => {
  let option: PaymentOption = {
    type: paymentData.paymentType,
    reference: paymentData.reference,
    productType,
  };
  if (paymentData.creditCard) {
    option.instalments = paymentData.instalments || 1;
    if (paymentData.creditCard.id)
      option.storedCreditCard = paymentData.creditCard.id.toString();
    else option.creditCard = parseCreditCard(paymentData.creditCard);
  }

  return option;
};

export const parseCreditCard = (
  creditCard: LegacyCreditCardForm,
): LegacyCreditCard => {
  let [expireMonth, expireYear] = creditCard.expirationDate.split('/');
  return {
    number: creditCard.number,
    provider: creditCard.brandCode,
    expireYear: expireYear,
    expireMonth: expireMonth,
    printedName: creditCard.holder || creditCard.holderName,
    securityCode: creditCard.cvv || creditCard.securityCode,
  };
};

export const parseBalanceData = (
  data: GetForEmployeesResponse[],
): PaymentItem[] => {
  return data.map((balance: GetForEmployeesResponse): PaymentItem => {
    return {
      employee: balance.employee,
      balance: balance.balance,
      name: balance.name,
      card:
        balance.cards.filter(isValidCreditCard).sort(sortByExpirationDate)[0] ||
        {},
      hasCard: balance.cards.filter(isValidCreditCard).length > 0,
    };
  });
};

const isValidCreditCard = (card: CreditCard): boolean =>
  new Date(card.expirationDate) > addHours(new Date(), 48);

const sortByExpirationDate = (card1: CreditCard, card2: CreditCard) => {
  const date1 = new Date(card1.expirationDate);
  const date2 = new Date(card2.expirationDate);

  if (date1 < date2) return -1;
  if (date1 > date2) return 1;
  return 0;
};
