var TripManagementTemplate = {
  main: ({
    tripType,
    tripItems,
    itemSelected,
    allTripsSelected,
    tariffedPriceFareResult,
    onPartTripClicked,
    onClearTripClicked,
    onIncludeInCartClick,
    onSelectMultipleTripClicked,
    onTryAgainTariffedPriceMultipleTripClick,
  }) => {
    let tariffedPriceSuccess =
      tariffedPriceFareResult &&
      tariffedPriceFareResult.total &&
      tariffedPriceFareResult.total.value != 0;
    return (
      <div className="exon-trip-management">
        <div
          className={
            'exon-trip-management__trips' +
            (!tariffedPriceFareResult || !allTripsSelected
              ? ' exon-trip-management__trips-max-width'
              : '')
          }
        >
          {tripItems.map((tripPart, index) => {
            return (
              <TripManagementTemplate.partTrip
                key={index}
                index={index}
                itemSelected={itemSelected}
                tripType={tripType}
                tripPart={tripPart}
                onPartTripClicked={onPartTripClicked}
                onClearTripClicked={onClearTripClicked}
              />
            );
          })}
        </div>
        {tariffedPriceFareResult &&
          tariffedPriceFareResult.total &&
          allTripsSelected && (
            <div
              className={
                'exon-trip-management__value' +
                (tripType == 2 ? ' exon-trip-management__value' : '') +
                (!tariffedPriceFareResult ? ' hide' : '')
              }
            >
              {!tariffedPriceSuccess && (
                <div className="exon-msg-error-tariffed">
                  <div>
                    <span className="exon-icon exon-icon-warning-filled_enabled-yellow"></span>
                    <p>Não foi possível Efetuar a tarifação!</p>
                  </div>
                </div>
              )}

              {tariffedPriceSuccess && (
                <div>
                  <p className="exon-trip-management__value-fare">
                    <span>
                      {tariffedPriceFareResult.totalFare.currencySymbol}
                    </span>
                    {tariffedPriceFareResult.totalFare.formatted}
                  </p>
                  <p className="exon-trip-management__value-taxes">
                    Taxa de embarque
                    <strong>
                      {` ${tariffedPriceFareResult.totalTaxes.currencySymbol} ${tariffedPriceFareResult.totalTaxes.formatted}`}
                    </strong>
                  </p>
                  <p className="exon-trip-management__value-services">
                    Taxa de serviços
                    <strong>
                      {` ${tariffedPriceFareResult.totalServices.currencySymbol} ${tariffedPriceFareResult.totalServices.formatted}`}
                    </strong>
                  </p>

                  <p className="exon-trip-management__value-total">
                    <strong>Total</strong>
                    <span>
                      {` ${tariffedPriceFareResult.total.currencySymbol} ${tariffedPriceFareResult.total.formatted}`}
                    </span>
                  </p>
                </div>
              )}

              <div className="box-btn-trips">
                <div className="box-button-select">
                  <div className="box-double-button">
                    <button
                      className={
                        'secondary-button' +
                        (!tariffedPriceSuccess ? ' hide' : '')
                      }
                      onClick={() => onIncludeInCartClick()}
                    >
                      <span></span>
                    </button>
                    <button
                      className="primary-button"
                      onClick={
                        tariffedPriceSuccess
                          ? onSelectMultipleTripClicked
                          : onTryAgainTariffedPriceMultipleTripClick
                      }
                    >
                      {tariffedPriceSuccess ? 'Selecionar' : 'Tentar novamente'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  },

  partTrip: ({
    tripType,
    tripPart,
    index,
    onPartTripClicked,
    onClearTripClicked,
    itemSelected,
  }) => {
    return (
      <div
        className={
          'exon-trip-management__trips-box-item' +
          (tripType != 2 ? ' part-trip-box' : '')
        }
      >
        <div
          className={
            'exon-trip-management__trips-item' +
            (tripType == 2 ? ' part-trip' : '') +
            (itemSelected == index ? ' trip-active' : '')
          }
          onClick={() => onPartTripClicked(index)}
        >
          <div className="exon-box-row ">
            <span className="trips-item__identification">
              {tripPart.tripType == 2
                ? index + 1
                : tripPart.tripType == 1
                ? 'IDA'
                : 'VOLTA'}
            </span>
            <div
              className={
                'trips-item__airLine' +
                (tripPart.tripSelected != null ? '' : ' hide')
              }
            >
              <span
                style={{
                  backgroundImage: `url(${
                    tripPart.tripSelected != null
                      ? tripPart.tripSelected.trip.airLine.imageUrl
                      : ''
                  })`,
                }}
                className="exon-logo-airline"
              >
                {tripPart.tripSelected != null
                  ? !tripPart.tripSelected.trip.airLine.imageUrl
                    ? tripPart.tripSelected.trip.airLine.name ||
                      tripPart.tripSelected.trip.airLine.code
                    : ''
                  : ''}
              </span>
            </div>
            <span
              className="close exon-button-close"
              onClick={() => onClearTripClicked(index)}
            ></span>
          </div>
          {tripPart.tripSelected != null ? (
            <TripManagementTemplate.tripDetails tripPart={tripPart} />
          ) : (
            <TripManagementTemplate.tripNotSelected label={tripPart.label} />
          )}
        </div>
      </div>
    );
  },

  tripDetails: ({ tripPart }) => {
    return (
      <div className="trip-details ">
        <div className="trip-details__date">
          <div className="trips-item__airLine">
            <span
              style={{
                backgroundImage: `url(${tripPart.tripSelected.trip.airLine.imageUrl})`,
              }}
              className="exon-logo-airline"
            ></span>
          </div>
          <p>
            {tripPart.tripSelected.trip.departure.date.dayOfWeek},
            <span>
              {tripPart.tripSelected.trip.departure.date.dateShortFormatted}
            </span>
          </p>
          <p>classe economica</p>
        </div>
        <div>
          <div className="trip-details__airport-info">
            <span>{tripPart.tripSelected.trip.departure.iata}</span>
            <span>
              {tripPart.tripSelected.trip.departure.date.timeFormatted}
            </span>
          </div>
          <div className="trip-details__airport-info">
            {tripPart.tripSelected.trip.arrival.iata}
            <span>{tripPart.tripSelected.trip.arrival.date.timeFormatted}</span>
          </div>
          <div className="exon-box-row trip-info__duration">
            <p>
              {tripPart.tripSelected.trip.stops == 0
                ? 'Vôo direto'
                : tripPart.tripSelected.trip.stops != 1
                ? tripPart.tripSelected.trip.stops + ' paradas'
                : tripPart.tripSelected.trip.stops + ' parada'}
            </p>
            <span className="stops__icons">
              <span className="stops__icons-item"></span>
            </span>
            <p>{tripPart.tripSelected.trip.duration.durationFormatted}</p>
          </div>
        </div>
        <div className="trip-details__trip-info">
          <div className="trip-info__baggages">
            <span
              className={
                'exon-icon-baggage-gray' +
                '-' +
                tripPart.tripSelected.fare.baggages
              }
            ></span>
            <p>
              {tripPart.tripSelected.fare.additionalInfo
                ? tripPart.tripSelected.fare.additionalInfo.baggage
                : ['Sem Informação']}
            </p>
          </div>
        </div>
      </div>
    );
  },

  tripNotSelected: ({ label }) => {
    return (
      <div className="exon-trip-management__trip-item-not-selected">
        <p>{'Selecione seu trecho ' + label}</p>
      </div>
    );
  },
};

export default TripManagementTemplate;
