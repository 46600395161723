'use strict';
import React from 'react';
import { componentUtil } from '../../../../../utils/componentUtil.js';
import Template from './room-card.template.js';

export default class RoomCardComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = { unsubscribeShow: false };
  }

  render() {
    return (
      <Template
        props={this.props}
        args={{
          unsubscribeShow: this.state.unsubscribeShow,
        }}
        events={this.events}
      />
    );
  }

  events = {
    onUnsubscribeClick: () => {
      this.setState({ unsubscribeShow: !this.state.unsubscribeShow });
    },
  };
}
