import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { airTrip } from '@legacy-utils/resolvers/airTripResolver';
import { fareBaggage } from '@legacy-utils/fareMapping';

export const retrieve = {
  fetchExternalReserveConvert: (response) => {
    if (response.airBooking) {
      let airBooking = response.airBooking;

      return {
        productsData: {
          trips: [
            {
              trips: !airBooking.airTrips
                ? []
                : airBooking.airTrips.map((trip, index) => {
                    return {
                      additionalInfo: {
                        baggage: fareBaggage[trip.baggages],
                      },
                      departure: trip.departure.id,
                      arrival: trip.arrival.id,
                      departureDate: trip.departureDate,
                      arrivalDate: trip.arrivalDate,
                      issuerAirline: trip.issuerAirline.code,
                      supplier: trip.supplier,
                      duration: trip.duration,
                      stops: trip.stops,
                      flights: !trip.flights
                        ? []
                        : trip.flights.map((flight) => {
                            return {
                              arrival: flight.arrival,
                              departure: flight.departure,
                              duration: flight.duration,
                              layover: flight.layover,
                              arrivalDate: flight.arrivalDate,
                              departureDate: flight.departureDate,
                              cabinType: flight.cabinType,
                              airline: flight.airline.code,
                              status: flight.status,
                              operatedBy: flight.operatedBy.code,
                              flightNumber: flight.flightNumber,
                              classOfService: flight.classOfService,
                              aircraftType: flight.aircraftType,
                            };
                          }),
                      searchId: trip.searchId,

                      baggages: trip.baggages,

                      airLine: trip.issuerAirline,

                      compressedAdditionalProperties: '',

                      formattedObject: {
                        originalAirTrip: {
                          departure: trip.departure.id,
                          arrival: trip.arrival.id,
                          departureDate: trip.departureDate,
                          arrivalDate: trip.arrivalDate,
                          issuerAirline: trip.issuerAirline.code,
                          supplier: trip.supplier,
                          duration: trip.duration,
                          stops: trip.stops,
                          flights: !trip.flights
                            ? []
                            : trip.flights.map((flight) => {
                                return {
                                  arrival: flight.arrival,
                                  departure: flight.departure,
                                  duration: flight.duration,
                                  layover: flight.layover,
                                  arrivalDate: flight.arrivalDate,
                                  departureDate: flight.departureDate,
                                  cabinType: flight.cabinType,
                                  airline: flight.airline.code,
                                  status: flight.status,
                                  operatedBy: flight.operatedBy.code,
                                  flightNumber: flight.flightNumber,
                                  classOfService: flight.classOfService,
                                  aircraftType: flight.aircraftType,
                                };
                              }),
                          compressedAdditionalProperties: '',
                          searchId: trip.searchId,
                        },
                        airLine: trip.issuerAirline,
                        airClass: {
                          id: airTrip.getCabinType(trip.flights),
                          name: enums.airClasses[
                            airTrip.getCabinType(trip.flights)
                          ],
                        },
                        departure: {
                          iata: trip.departure.iata,
                          fullName: `${trip.departure.name} (${trip.departure.iata}) / ${trip.departure.city}`,
                          date: utils.formatters.date.getFormattedDateObjFromDateObject(
                            trip.departureDate,
                          ),
                        },
                        arrival: {
                          iata: trip.arrival.iata,
                          fullName: `${trip.arrival.name} (${trip.arrival.iata}) / ${trip.arrival.city}`,
                          date: utils.formatters.date.getFormattedDateObjFromDateObject(
                            trip.arrivalDate,
                          ),
                        },
                        stops: trip.stops,
                        duration: {
                          durationInMinutes: trip.duration,
                          durationFormatted:
                            utils.formatters.time.durationHoursMinute(
                              trip.duration,
                            ),
                        },
                        agreementApplied: true,
                        searchId: trip.searchId,
                      },
                    };
                  }),
              fares: !airBooking.fares
                ? []
                : airBooking.fares.map((fare) => {
                    return {
                      travellersFareValues: {
                        travellersCount: fare.travelerValue.travellersCount,
                        type: fare.travelerValue.type,

                        fare: fare.travelerValue.fare,
                        originalFare: fare.travelerValue.originalFare,
                        originalTaxes: fare.travelerValue.originalTaxes,
                        taxes: fare.travelerValue.taxes,
                        markupedFare: fare.travelerValue.markupedFare,
                        du: fare.travelerValue.du,
                      },
                      connectionSource: fare.connectionSource,
                      fareBasis: fare.fareBasis,
                      fareType: fare.travelerValue.fareType,
                      incentiveValue: fare.incentiveValue,
                      exchangeRate: 0,
                      compressedAdditionalProperties: '',
                    };
                  }),
            },
          ],
          hotels: [],
          allTariffedPrice: retrieve.air.sumOfAirFares(airBooking.fares),
          tariffedPriceInspired: false,
          searchParameters: {
            travelers: retrieve.air.sumOfTravelers(airBooking.travelers),
          },
        },
        compressedSearchResult: {},
        preBookingData: {
          summary: retrieve.air.sumOfAirFares(airBooking.fares),
          air: {
            airTrips: !airBooking.airTrips
              ? []
              : airBooking.airTrips.map((trip, index) => {
                  return {
                    departure: trip.departure.id,
                    arrival: trip.arrival.id,

                    departureDate: retrieve.air.dateObjToString(
                      trip.departureDate,
                    ),
                    arrivalDate: retrieve.air.dateObjToString(trip.arrivalDate),
                    issuerAirline: trip.issuerAirline.code,
                    supplier: trip.supplier,
                    duration: trip.duration,
                    stops: trip.stops,
                    baggages: trip.baggages,
                    flights: !trip.flights
                      ? []
                      : trip.flights.map((flight) => {
                          return {
                            arrival: flight.arrival.id,
                            departure: flight.departure.id,
                            duration: flight.duration,
                            layover: flight.layover,
                            arrivalDate: flight.arrivalDate,
                            departureDate: flight.departureDate,
                            cabinType: flight.cabinType,
                            airline: flight.airline.code,
                            status: flight.status,
                            operatedBy: flight.operatedBy.code,
                            flightNumber: flight.flightNumber,
                            classOfService: flight.classOfService,
                            aircraftType: flight.aircraftType,
                            locator: flight.locator,
                          };
                        }),
                    searchId: trip.searchId,
                  };
                }),
          },
        },
        travelers: airBooking.travelers ? airBooking.travelers : [],
      };
    }

    if (response.hotelBooking) {
      let hotel = response.hotelBooking.hotel;
      let address = response.hotelBooking.hotel.address;
      let rooms = response.hotelBooking.hotel.rooms;

      return {
        productsData: {
          hotels: {
            selectedHotels: [
              {
                name: hotel.name,
                hotel: {
                  name: hotel.name,
                  searchId: hotel.searchId,
                  stars: hotel.rating,
                  address: {
                    locationId: address.locationId,
                    country: address.country,
                    city: address.city,
                    street: address.street,
                    geoLocation: address.geoLocation,
                  },
                  description: hotel.description,
                  images: hotel.images,
                  roomOptions: hotel.roomOptions,
                  location: {
                    name: address.street,
                    city: address.city,
                  },
                  rooms: !rooms
                    ? []
                    : rooms.map((room) => {
                        return {
                          id: room.id,
                          name: room.name,
                          mealOption: '',
                          supplier:
                            room.sourceIdentifier['Omnibees' || 'B2b' || 'B2B'],
                          price: retrieve.hotel.sumOfPrice(rooms),
                          capacity: room.capacity.max,
                          original: {
                            id: room.id,
                            name: room.name,
                            description: room.description,
                            capacity: room.capacity,
                            fares: room.fares,
                            images: room.images,
                            sourceIdentifier: room.sourceIdentifier,
                            compressedAdditionalProperties:
                              room.compressedAdditionalProperties,
                          },
                          guaranteesAccepted: [],
                          cancelDeadline: room.cancelDeadline,
                          cancellationPolicies: room.cancellationPolicies,
                          sourceIdentifier: room.sourceIdentifier,
                        };
                      }),
                  searchId: hotel.searchId,
                  comppressedAdditionalProperties:
                    hotel.comppressedAdditionalProperties,
                },
                searchData: {
                  totalDays: retrieve.hotel.totalDays(rooms),
                },
                selectedRooms: !rooms
                  ? []
                  : rooms.map((room) => {
                      let roomOption = {};
                      hotel.roomOptions.map((option) => {
                        if (option.rooms.some((id) => id === room.id))
                          roomOption = option;
                      });

                      return {
                        room: {
                          id: room.id,
                          name: room.name,
                          mealOption: '',
                          supplier:
                            room.sourceIdentifier['Omnibees' || 'B2b' || 'B2B'],
                          price: retrieve.hotel.sumOfPrice(rooms),
                          capacity: room.capacity,
                          original: {
                            id: room.id,
                            name: room.name,
                            description: room.description,
                            capacity: room.capacity,
                            fares: room.fares,
                            images: room.images,
                            sourceIdentifier: room.sourceIdentifier,
                            compressedAdditionalProperties:
                              room.compressedAdditionalProperties,
                          },
                          guaranteesAccepted: [],
                          cancelDeadline: room.cancelDeadline,
                          cancellationPolicies: room.cancellationPolicies,
                          sourceIdentifier: room.sourceIdentifier,
                        },
                        adultCount: roomOption.adultCount,
                        childCount: roomOption.childCount,
                      };
                    }),
                comppressedAdditionalProperties:
                  hotel.comppressedAdditionalProperties,
              },
            ],
          },
          allTariffedPrice: retrieve.hotel.sumOfSummary(rooms),
          travelers: retrieve.hotel.guestsCount(hotel.roomOptions),
          trips: [],
        },
        compressedSearchResult: {},
        preBookingData: {
          summary: retrieve.hotel.sumOfSummary(rooms),
          hotel: {
            hotels: [
              {
                name: hotel.name,
                searchId: hotel.searchId,
                stars: hotel.rating,
                address: {
                  locationId: address.locationId,
                  country: address.country,
                  city: address.city,
                  street: address.street,
                  geoLocation: address.geoLocation,
                },
                description: hotel.description,
                images: hotel.images,
                roomOptions: hotel.roomOptions,
                location: {
                  name: address.street,
                  city: address.city,
                },
                rooms: !rooms
                  ? []
                  : rooms.map((room) => {
                      return {
                        id: room.id,
                        name: room.name,
                        mealOption: '',
                        supplier:
                          room.sourceIdentifier['Omnibees' || 'B2b' || 'B2B'],
                        price: retrieve.hotel.sumOfPrice(rooms),
                        capacity: room.capacity,
                        original: {
                          id: room.id,
                          name: room.name,
                          description: room.description,
                          capacity: room.capacity,
                          fares: room.fares,
                          images: room.images,
                          sourceIdentifier: room.sourceIdentifier,
                          compressedAdditionalProperties:
                            room.compressedAdditionalProperties,
                          cancelDeadline: room.cancelDeadline,
                          cancellationPolicies: room.cancellationPolicies,
                        },
                        guaranteesAccepted: [],
                        cancelDeadline: room.cancelDeadline,
                        cancellationPolicies: room.cancellationPolicies,
                        sourceIdentifier: room.sourceIdentifier,
                      };
                    }),
                searchId: hotel.searchId,
                comppressedAdditionalProperties:
                  hotel.comppressedAdditionalProperties,
              },
            ],
          },
        },
        travelers: response.hotelBooking.travelers
          ? response.hotelBooking.travelers
          : [],
      };
    }

    return {};
  },

  hotel: {
    sumOfPrice: (rooms) => {
      let dailyFare = 0;
      let dailyTaxes = 0;
      let totalDays = 0;
      let currencySymbol = '';

      rooms.map((room) => {
        room.fares.map((fare) => {
          dailyFare += fare.roomValue.originalDailyFare.value;
          dailyTaxes += fare.roomValue.originalDailyTax.value;
          totalDays += fare.fareInformation.days;
          currencySymbol = fare.roomValue.originalDailyFare.currencyCode;
        });
      });

      return {
        currencySymbol: currencySymbol,
        dailyFare: dailyFare,
        dailyTaxes: dailyTaxes,
        totalDaily: dailyFare + dailyTaxes,
        totalDays: totalDays,
        total: (dailyFare + dailyTaxes) * totalDays,
      };
    },
    sumOfSummary: (rooms) => {
      let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
      let moneyFormatted = utils.formatters.money.getFormatted;

      let totalDays = 0;
      let total = { value: 0, currencyCode: '' };
      let totalTaxes = { value: 0, currencyCode: '' };
      let totalFare = { value: 0, currencyCode: '' };
      let totalServices = { value: 0, currencyCode: 'BRL' };

      rooms.map((room) => {
        room.fares.map((fare) => {
          let roomValue = fare.roomValue;

          totalDays += fare.fareInformation.days;

          totalTaxes.value += roomValue.originalDailyTax.value;
          totalTaxes.currencyCode = roomValue.originalDailyTax.currencyCode;

          totalFare.value += roomValue.originalDailyFare.value;
          totalFare.currencyCode = roomValue.originalDailyFare.currencyCode;
        });
      });

      total.value = (totalTaxes.value + totalFare.value) * totalDays;
      total.currencyCode = totalTaxes.currencyCode;

      return {
        total: {
          value: total.value,
          currencyCode: total.currencyCode,
          formatted: moneyFormatted(total.value, total.currencyCode),
          currencySymbol: getCurrencySymbol(total.currencyCode),
        },
        totalTaxes: {
          value: totalTaxes.value,
          currencyCode: totalTaxes.currencyCode,
          formatted: moneyFormatted(totalTaxes.value, totalTaxes.currencyCode),
          currencySymbol: getCurrencySymbol(totalTaxes.currencyCode),
        },
        totalFare: {
          value: totalFare.value,
          currencyCode: totalFare.currencyCode,
          formatted: moneyFormatted(totalFare.value, totalFare.currencyCode),
          currencySymbol: getCurrencySymbol(totalFare.currencyCode),
        },
        totalServices: {
          value: totalServices.value,
          currencyCode: totalServices.currencyCode,
          formatted: moneyFormatted(
            totalServices.value,
            totalServices.currencyCode,
          ),
          currencySymbol: getCurrencySymbol(totalServices.currencyCode),
        },
      };
    },
    guestsCount: (roomOptions) => {
      let adultCount = 0;
      let childCount = 0;

      roomOptions.map((options) => {
        adultCount += options.adultCount;
        childCount += options.childCount;
      });

      return {
        adultCount: adultCount,
        childCount: childCount,
      };
    },
    totalDays: (rooms) => {
      let totalDays = 0;

      rooms.map((room) => {
        room.fares.map((fare) => {
          totalDays += fare.fareInformation.days;
        });
      });

      return totalDays;
    },
  },

  air: {
    sumOfAirFares: (fares) => {
      let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
      let moneyFormatted = utils.formatters.money.getFormatted;

      let total = { value: 0, currencyCode: '' };
      let totalTaxes = { value: 0, currencyCode: '' };
      let totalFare = { value: 0, currencyCode: '' };
      let totalServices = { value: 0, currencyCode: '' };

      fares.map((fare) => {
        let travelerValue = fare.travelerValue;

        total.value += travelerValue.total.value;
        total.currencyCode = travelerValue.total.currencyCode;

        totalTaxes.value += travelerValue.originalTaxes.value;
        totalTaxes.currencyCode = travelerValue.originalTaxes.currencyCode;

        totalFare.value += travelerValue.originalFare.value;
        totalFare.currencyCode = travelerValue.originalFare.currencyCode;

        totalServices.value += travelerValue.du.value;
        totalServices.currencyCode = travelerValue.du.currencyCode;
      });

      return {
        total: {
          value: total.value,
          currencyCode: total.currencyCode,
          formatted: moneyFormatted(total.value, total.currencyCode),
          currencySymbol: getCurrencySymbol(total.currencyCode),
        },
        totalTaxes: {
          value: totalTaxes.value,
          currencyCode: totalTaxes.currencyCode,
          formatted: moneyFormatted(totalTaxes.value, totalTaxes.currencyCode),
          currencySymbol: getCurrencySymbol(totalTaxes.currencyCode),
        },
        totalFare: {
          value: totalFare.value,
          currencyCode: totalFare.currencyCode,
          formatted: moneyFormatted(totalFare.value, totalFare.currencyCode),
          currencySymbol: getCurrencySymbol(totalFare.currencyCode),
        },
        totalServices: {
          value: totalServices.value,
          currencyCode: totalServices.currencyCode,
          formatted: moneyFormatted(
            totalServices.value,
            totalServices.currencyCode,
          ),
          currencySymbol: getCurrencySymbol(totalServices.currencyCode),
        },
      };
    },
    sumOfTravelers: (travelers) => {
      let adults = 0;
      let childrens = 0;
      let infants = 0;

      travelers.map((traveler) => {
        if (traveler.type === 0) {
          adults += 1;
        } else if (traveler.type === 1) {
          childrens += 1;
        } else if (traveler.type === 2) {
          infants += 1;
        }
      });

      return {
        adults: adults,
        childrens: childrens,
        infants: infants,
      };
    },
    dateObjToString: (date) => {
      return (
        date.year.toString().padStart(2, '0') +
        '-' +
        date.month.toString().padStart(2, '0') +
        '-' +
        date.day.toString().padStart(2, '0') +
        'T' +
        date.hour.toString().padStart(2, '0') +
        ':' +
        date.minute.toString().padStart(2, '0') +
        ':' +
        date.second.toString().padStart(2, '0')
      );
    },
  },
};
