import { LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import Popup from '@legacy-components/popup/popup.component.js';
import HotelCancellationPolicies from '@legacy-components/products-travel/hotel-product/hotel-cancellation-policies/hotel-cancellation-policies.component.js';
import { useState } from 'react';

type CancelationPolicyModalProps = {
  hotelName: string;
  policy: any;
};
export function CancelationPolicyModal({
  hotelName,
  policy,
}: CancelationPolicyModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <LinkButton variant="default" onClick={() => setIsOpen(true)}>
        <Typography variant="body4">Visualizar politica</Typography>
      </LinkButton>
      {isOpen && (
        <Popup
          exonStyle={true}
          title={`Cancelamento: ${hotelName}`}
          cancelButtonClick={() => {
            setIsOpen(false);
          }}
        >
          <HotelCancellationPolicies cancellationPolicies={policy} />
        </Popup>
      )}
    </>
  );
}
