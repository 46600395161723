import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { useApprovalTable } from '../useApprovalTable';
import { TableHeader } from './components/TableHeader';
import { TableFilters } from './components/TableFilters';
import { TablePagination } from './components/TablePagination';
import AddApprovalFlow from '../AddApprovalFlow';

export function ApprovalTable() {
  const [showTable, setShowTable] = useState(true); // Estado para controlar a exibição
  const {
    filteredItems,
    pagination,
    search,
    isLoading,
    table,
    handleSearch,
    setPagination,
  } = useApprovalTable();

  return (
    <Box width="100%">
      {showTable ? (
        <Box>
          <Table.Root>
            <TableHeader filteredItemsCount={filteredItems.length} />
            <Table.Search
              label="Buscar por fluxo de aprovação..."
              onChange={handleSearch}
              value={search}
            />
            <TableFilters setShowTable={setShowTable} />{' '}
            <Table.Grid.Root loading={isLoading}>
              <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
              {table.rows.map((row) => (
                <Table.Grid.Row key={row.id} row={row} />
              ))}
            </Table.Grid.Root>
            <TablePagination
              filteredItemsCount={filteredItems.length}
              pagination={pagination}
              setPagination={setPagination}
            />
          </Table.Root>
        </Box>
      ) : (
        <AddApprovalFlow />
      )}
    </Box>
  );
}
