import Popup from '../../../popup/popup.component.js';
import Field from '../../../field/field.component.js';
import Form from '../../../common/form/form.component.js';
import { enums } from '@legacy-utils/enums';

var Template = {
  main: ({
    userProfile,
    tariffsAndFees,
    form,
    canEdit,
    connectionSource,
    events,
  }) => {
    let isMarkupVisible =
      userProfile?.company?.isAgency || userProfile?.travelProfile === 4;

    return (
      <div className="exon-booking-air-tariffs-and-fees">
        <table className="exon-table-line" cellSpacing="0">
          <thead>
            <tr>
              <th>Sistema</th>
              <th>Tipo Passageiro</th>
              <th>Tarifa Neto</th>
              <th>Tarifa Equivalente</th>
              <th>Tarifa DU</th>
              <th>Valor da venda</th>
              <th>Taxas</th>
              <th>Multa</th>
              {isMarkupVisible && <th>Markup</th>}
              <th>Valor câmbio</th>
              <th>Data câmbio</th>
              <th>Base Tarifária</th>
              <th>TourCode</th>
              {canEdit && (
                <th className="exon-table-actions-header">
                  <span
                    className="exon-icon exon-icon-button exon-icon-add-unfilled_enabled"
                    onClick={() =>
                      events.form.popup.onOpenFormPopupClicked(
                        enums.actionsForm.add,
                      )
                    }
                  ></span>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {tariffsAndFees &&
              tariffsAndFees.length > 0 &&
              tariffsAndFees.map((item, index) => {
                let empty = '-';
                let row = (
                  <tr key={index}>
                    <td
                      className={!item.supplierSystem ? 'exon-text-center' : ''}
                    >
                      {item.supplierSystem
                        ? item.supplierSystem
                        : connectionSource
                        ? connectionSource
                        : empty}
                    </td>
                    <td className={!item.type ? 'exon-text-center' : ''}>
                      {item.type ? item.type : empty}
                    </td>
                    <td
                      className={!item.originalFare ? 'exon-text-center' : ''}
                    >
                      {item.originalFare ? item.originalFare : empty}
                    </td>
                    <td className={!item.fare ? 'exon-text-center' : ''}>
                      {item.fare ? item.fare : empty}
                    </td>
                    <td className={!item.du ? 'exon-text-center' : ''}>
                      {item.du ? item.du : empty}
                    </td>
                    <td className={!item.sale ? 'exon-text-center' : ''}>
                      {item.sale ? item.sale : empty}
                    </td>
                    <td className={!item.taxes ? 'exon-text-center' : ''}>
                      {item.taxes ? item.taxes : empty}
                    </td>
                    <td className={!item.penalty ? 'exon-text-center' : ''}>
                      {item.penalty ? item.penalty : empty}
                    </td>
                    {isMarkupVisible && (
                      <td className={!item.markup ? 'exon-text-center' : ''}>
                        {item.markup ? item.markup : empty}
                      </td>
                    )}
                    <td
                      className={!item.exchangeRate ? 'exon-text-center' : ''}
                    >
                      {item.exchangeRate ? item.exchangeRate : empty}
                    </td>
                    <td
                      className={!item.exchangeDate ? 'exon-text-center' : ''}
                    >
                      {item.exchangeDate ? item.exchangeDate : empty}
                    </td>
                    <td className={!item.fareBasis ? 'exon-text-center' : ''}>
                      {item.fareBasis ? item.fareBasis : empty}
                    </td>
                    <td className={!item.tourCode ? 'exon-text-center' : ''}>
                      {item.tourCode ? item.tourCode : empty}
                    </td>

                    {canEdit && (
                      <td className="exon-table-action-item">
                        <span
                          className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
                          onClick={() =>
                            events.form.popup.onOpenFormPopupClicked(
                              enums.actionsForm.edit,
                              index,
                            )
                          }
                        ></span>
                        <span
                          className="exon-icon exon-icon-button exon-icon-dump-unfilled_enabled"
                          onClick={() => {
                            events.onDeleteFareClicked(index);
                          }}
                        ></span>
                      </td>
                    )}
                  </tr>
                );

                return row;
              })}
          </tbody>
        </table>

        {form.show && (
          <div className="exon-box-popup-form-rv">
            <Template.form data={form} events={events.form.popup} />
          </div>
        )}
      </div>
    );
  },

  form: ({ data, events }) => {
    return (
      <Popup
        okButtonLabel={'Salvar'}
        cancelButtonLabel={'Cancelar'}
        title={'Taxas e tarifas aéreas'}
        cancelButtonClick={events.onCloseFormPopupClicked}
        exonStyle={true}
        okButtonClick={events.onConfirmPopupClicked}
      >
        <Form>
          {Object.keys(data.fields).map((refName, index) => {
            let field = data.fields[refName];
            return (
              <div key={index} className="exon-box-form-field">
                <Field
                  type={field.type}
                  label={field.label}
                  value={field.value}
                  items={field.items}
                  refName={field.refName}
                  mask={field.mask}
                  completeItem={field.completeItem}
                  locationType={field.locationType}
                  placeholder={field.placeholder}
                  forceValidation={data.forceValidation}
                  disabled={field.disabled}
                  required={field.required}
                  readOnly={field.readOnly}
                  isZeroValid={field.isZeroValid}
                  options={field.options}
                  dataSource={field.dataSource}
                  removeMask={field.removeMask}
                  onFieldChange={field.onFieldChange}
                />
              </div>
            );
          })}
        </Form>
      </Popup>
    );
  },
};
export default Template;
