import { travelerResolver } from '@legacy-utils/resolvers/travelerResolver';

export const serviceRequestItemResolver = {
  resolveServiceRequestItem(serviceRequestItem, rootContextId) {
    //TODO: Avaliar para remover o resolver uma vez que todas as propriedades deverão ser preenchidas e as que não forem devem ser tratadas nos templates que dependem da informação

    const _this = serviceRequestItemResolver;

    return {
      airTrips: serviceRequestItem.airTrips
        ? _this.resolveAirTrips(serviceRequestItem.airTrips)
        : [],
      travelers: serviceRequestItem.travelers
        ? _this.resolveTravelers(serviceRequestItem.travelers)
        : null,

      ...serviceRequestItem,
      airTickets: _this.resolveAirTickets(serviceRequestItem),
    };
  },

  resolveAirTrips: (airTrips) => {
    const _this = serviceRequestItemResolver;

    airTrips.forEach((airTrip) => {
      if (!airTrip.baggages) airTrip.baggages = '1';

      airTrip.flights = _this.resolveFlights(airTrip.flights);
    });
  },

  resolveFlights: (flights) => {
    flights.forEach((flight) => {
      if (flight.fareRuleIndex == null) flight.fareRuleIndex = 0;

      if (flight.airline && !flight.airline.name)
        flight.airline.name = flight.airline.code;

      if (!flight.flightNumber) flight.flightNumber = null;

      if (!flight.arrival) flight.arrival = 0;

      if (!flight.departure) flight.departure = 0;

      if (!flight.locCia) flight.locCia = null; //TODO: Mudar para locator
    });

    return flights;
  },

  resolveTravelers: (travelers) => {
    travelers.forEach((traveler) => {
      if (!traveler.name)
        traveler.name = travelerResolver.getFullName(traveler);

      if (!traveler.honorificPrefix) traveler.honorificPrefix = null;
    });

    return travelers;
  },

  resolveAirTickets: (serviceRequestItem) => {
    const tickets = serviceRequestItem.airTickets || serviceRequestItem.tickets;
    let airTicketsSummary = null;

    if (tickets && tickets.length) {
      airTicketsSummary = [];

      for (let i = 0; i < tickets.length; i++) {
        let airTicket = tickets[i];
        const roundTrip = airTicket.arrival == airTicket.departure;

        const defaultValues = {
          roundTrip: roundTrip,
          stops: 0,
          status: {
            id: 9,
            name: 'Emitido',
          },
        };

        let airTicketSummary = {
          ...defaultValues,
          ...airTicket,
        };

        airTicketsSummary.push(airTicketSummary);
      }

      return airTicketsSummary;
    }
  },

  //TODO GRAF 001: remover após recebimento da informação via API
  resolveAirTicketRequestItem: (serviceRequestItem, airItem) => {
    if (!serviceRequestItem.airTrips)
      serviceRequestItem.airTrips = airItem.airTrips;

    if (!serviceRequestItem.departure) {
      let firstAirTrip = serviceRequestItem.airTrips[0];
      serviceRequestItem.departure = firstAirTrip
        ? firstAirTrip.departure
        : null;
    }

    if (!serviceRequestItem.arrival) {
      let lastAirTrip =
        serviceRequestItem.airTrips[serviceRequestItem.airTrips.length - 1];
      serviceRequestItem.arrival = lastAirTrip ? lastAirTrip.arrival : null;
    }

    if (!serviceRequestItem.traveler)
      serviceRequestItem.traveler = airItem.travelers[0];

    if (!serviceRequestItem.fare)
      serviceRequestItem.fare = airItem.fares ? airItem.fares[0] : null;

    if (serviceRequestItem.fare)
      serviceRequestItem.fare.travelerValue = {
        total: {
          value: 0,
          currencyCode: 'BRL',
        },

        ...serviceRequestItem.fare.travelerValue,
      };

    if (!serviceRequestItem.createdAt)
      serviceRequestItem.createdAt = airItem.startAt;

    if (!serviceRequestItem.issuedAt)
      serviceRequestItem.issuedAt = airItem.startAt;

    return serviceRequestItem;
  },
};
