import SplitComponent from '../../../cost-center-split/split.component.js';
import ToggleComponent from '../../../toggle/toggle.component.js';
import Select from '../../../select/select.component.js';
import { Travelers } from './travelers';
import AutoComplete from '../../../common/auto-complete/auto-complete.component.js';
import { CustomFields } from './custom-fields';
import { useMemo } from 'react';
import { enums } from '../../../../utils/enums';
import { Company, LegacyPayment } from '@legacy/Legacy-Payment';
import TitleSectionItem from '@legacy-components/TitleSectionItem';
import {
  GuaranteeOptions,
  Product,
} from '@legacy/Legacy-Payment/models/index.js';

export const PurchaseInfos = ({
  currentProducts,
  checkoutPreBookingData,
  serviceRequestExisting,
  roomOptions,
  hotelsOptions,
  showValidationAdditionalFieldsTraveler,
  reasonTravelOptions,
  isCostCenterSplitEnabled,
  isCostCenterSplitAvailable,
  costCenterSplit,
  costCenterOptions,
  isCostCenterAllowed,
  paymentInformationOptions,
  travelers,
  travelersDataSources,
  customFieldsOptions,
  hotelGuaranteeOptions,
  showHotelGuarantee,
  onTravelerConfirm,
  onTravelerAutoCompleteInputChange,
  onValidationAdditionalFieldsClick,
  onCloseTravelerClick,
  showValidationsFields,
  agencyAndCompany,
  requesterOptions,
  disabledFields,
  isRvOffline,
  isImportExternal,
  disablePaymentforms,
  selectedHotel,
  isFlashPayEnabled,
  separatePaymentPerProduct,
}) => {
  const guaranteeOptionsFactory = (): GuaranteeOptions => ({
    items: hotelGuaranteeOptions?.options?.items || [],
    selectedItem: hotelGuaranteeOptions.data,
    products: hotelGuaranteeOptions.products,
    supplier: hotelGuaranteeOptions.supplier,
    disabled: disablePaymentforms,
    onSelected: (paymentData) => {
      hotelGuaranteeOptions.options.events.onSelected(paymentData);
    },
  });

  const sumHotelFares = () => {
    let hotelRoomsFares = (currentProducts?.hotels?.selectedHotels || [])
      .flatMap((hotel) => hotel.selectedRooms)
      .flatMap((room) => room.room.fares)
      .map((fare) => {
        return {
          days: fare.fareInformation.days,
          price: fare.roomValue.markupedDailyFare,
        };
      });
    return hotelRoomsFares.reduce(
      (acc, fare) => (acc += fare.days * fare.price.value),
      0,
    );
  };
  const sumAirTripsFares = () => {
    const arrayOfArrays = currentProducts.trips.flatMap((trip) => {
      return trip.fares.travellersFareValues;
    });
    const result = arrayOfArrays.reduce((acc, fare) => {
      acc = acc + fare.total.value;
      return acc;
    }, 0);

    return result;
  };
  const faresSumDictionary = {
    [enums.purchaseConfiguration.product.air]: sumAirTripsFares,
    [enums.purchaseConfiguration.product.hotel]: sumHotelFares,
  };
  const hasItems = {
    [enums.purchaseConfiguration.product.air]:
      currentProducts?.trips?.length > 0 === true,
    [enums.purchaseConfiguration.product.hotel]:
      currentProducts?.hotels?.selectedHotels?.length > 0 === true,
    [enums.purchaseConfiguration.product.none]:
      currentProducts?.hotels?.selectedHotels?.length > 0 === true ||
      currentProducts?.trips?.length > 0 === true,
  };
  const products = useMemo<Product[]>(() => {
    if (!separatePaymentPerProduct)
      return [
        {
          type: 0,
          price: currentProducts?.allTariffedPrice?.total?.value || 0,
          separatePaymentPerProduct,
          products: paymentInformationOptions.products,
          supplier: paymentInformationOptions.supplier,
          selectedHotelsLength:
            currentProducts?.hotels?.selectedHotels?.length || 0,
          guaranteeOptions: guaranteeOptionsFactory(),
          hasItems: hasItems[enums.purchaseConfiguration.product.none],
          showHotelGuarantee,
        },
      ];

    return (currentProducts?.products || []).map((type): Product => {
      return {
        ...type,
        price:
          faresSumDictionary[type.type]() ||
          currentProducts.allTariffedPrice.total.value,
        separatePaymentPerProduct,
        products: paymentInformationOptions.products,
        supplier: paymentInformationOptions.supplier,
        selectedHotelsLength:
          type.type == enums.purchaseConfiguration.product.hotel
            ? currentProducts?.hotels?.selectedHotels?.length
            : 0,
        showHotelGuarantee,
        guaranteeOptions:
          type.type == enums.purchaseConfiguration.product.hotel
            ? guaranteeOptionsFactory()
            : null,
        hasItems: hasItems[type.type],
      };
    });
  }, [currentProducts, separatePaymentPerProduct, disablePaymentforms]);
  const company: Company = {
    isAgency: agencyAndCompany.agency.isAgency,
    travelerProfile: agencyAndCompany.agency.travelProfile,
  };
  const hasWoobaAndInternacional = () => {
    let isWoobaAndInternacional = false;
    if (
      currentProducts?.trips == null ||
      currentProducts?.trips.length == 0 ||
      (checkoutPreBookingData != null &&
        checkoutPreBookingData.summary &&
        !checkoutPreBookingData.summary.isInternational)
    )
      return false;

    for (let trip of currentProducts.trips) {
      if (trip?.trips) {
        var tripWooba = trip.trips.find((t) => t.supplier === 'NDC');
        if (tripWooba != undefined) return true;
      }
    }
  };

  return (
    <div className="exon-purchase-infos">
      <Travelers
        dataSources={travelersDataSources}
        checkoutPreBookingData={checkoutPreBookingData}
        serviceRequestExisting={serviceRequestExisting}
        roomOptions={roomOptions}
        hotelsOptions={hotelsOptions}
        showValidationAdditionalFieldsTraveler={
          showValidationAdditionalFieldsTraveler
        }
        travelers={travelers}
        onTravelerConfirm={onTravelerConfirm}
        onTravelerAutoCompleteInputChange={onTravelerAutoCompleteInputChange}
        onValidationAdditionalFieldsClick={onValidationAdditionalFieldsClick}
        onCloseTravelerClick={onCloseTravelerClick}
        agencyAndCompany={agencyAndCompany}
        requesterOptions={requesterOptions}
        showValidationsFields={showValidationsFields}
        disabledFields={disabledFields}
        isRvOffline={isRvOffline}
        isImportExternal={isImportExternal}
        selectedHotel={selectedHotel}
        isWoobaAndInternacional={hasWoobaAndInternacional()}
      />

      <div className="exon-reason-trip exon-section-item">
        <div className="exon-box-field">
          <TitleSectionItem title="Motivo da viagem" />
          <div className="exon-box-fields-row">
            <div className="exon-field">
              <Select
                data={reasonTravelOptions.data}
                placeholder={reasonTravelOptions.placeholder}
                externalOptions={reasonTravelOptions.options}
                forceValidation={showValidationsFields}
                disabled={disabledFields}
                required={true}
              />
            </div>

            {((reasonTravelOptions.data &&
              reasonTravelOptions.data.reason &&
              reasonTravelOptions.data.reason.IsDescriptionRequired) ||
              (reasonTravelOptions.data &&
                reasonTravelOptions.data.description)) && (
              <div
                className={
                  'exon-field' +
                  (reasonTravelOptions.data &&
                  reasonTravelOptions.data.reason &&
                  reasonTravelOptions.data.reason.IsDescriptionRequired &&
                  !reasonTravelOptions.data.description &&
                  showValidationsFields
                    ? ' exon-alert-border'
                    : '')
                }
              >
                <textarea
                  value={reasonTravelOptions.data.description}
                  disabled={disabledFields}
                  placeholder="Digite descrição do motivo"
                  onChange={(e) =>
                    reasonTravelOptions.onChangeDescription(e.target.value)
                  }
                ></textarea>

                {reasonTravelOptions.data &&
                  reasonTravelOptions.data.reason &&
                  reasonTravelOptions.data.reason.IsDescriptionRequired &&
                  showValidationsFields &&
                  !reasonTravelOptions.data.description && (
                    <span className="exon-msg-alert-field">
                      campo obrigatório
                    </span>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isCostCenterAllowed && (
        <div className="exon-cost-center exon-section-item">
          <TitleSectionItem title="Centro de custo" />
          <div className="exon-box-fields-row">
            <div className="exon-field exon-select-list-top">
              <AutoComplete
                options={costCenterOptions.options}
                selectedItem={costCenterOptions.data}
                inputText={costCenterOptions?.fildText || ''}
                waitForMinInput={false}
                placeholder={'Informe o nome do centro de custo.'}
                dataSource={costCenterOptions.dataSource}
                onChange={costCenterOptions.events.onChanged}
                onInputChange={costCenterOptions.events.onInputChanged}
                required={!isCostCenterSplitEnabled}
                forceValidation={
                  isCostCenterSplitEnabled ? false : showValidationsFields
                }
                disabled={isCostCenterSplitEnabled || disabledFields}
              />
            </div>
            {isCostCenterSplitAvailable && (
              <div className="exon-field exon-toggle">
                <ToggleComponent {...costCenterSplit.toggleSplit} />
              </div>
            )}
          </div>
          {isCostCenterSplitEnabled && (
            <SplitComponent costCenterSplit={costCenterSplit} />
          )}
        </div>
      )}
      <LegacyPayment
        products={products}
        onSelect={(paymentData) => {
          paymentInformationOptions.options.events.onPaymentSelected(
            paymentData,
          );
        }}
        isFlashPayEnabled={isFlashPayEnabled}
        required={isImportExternal ? false : true}
        confirmedTravelers={travelers.confirmedTravelers}
        travelersCount={travelers.adults}
        company={company}
        items={paymentInformationOptions.options.items}
        disableSelector={disablePaymentforms}
        showValidationsFields={showValidationsFields}
      />
      {customFieldsOptions.customFields.length > 0 && (
        <div>
          <div className="exon-additional-fields">
            <TitleSectionItem title="Informações Adicionais" />
            <CustomFields
              fields={customFieldsOptions.customFields}
              showValidationsFields={showValidationsFields}
              onFieldChange={customFieldsOptions.events.onFieldChange}
              disabled={disabledFields}
            />
          </div>
          <div className="exon-box-fields-row exon-warranty-hotel"></div>
        </div>
      )}
    </div>
  );
};
