'use strict';
import React from 'react';
import Template from './actions-request-history.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class ActionsRequestHistoryComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      fieldModify: false,
      sendEmail: false,
      message: false,
      approvers: false,
      service: false,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main events={this.events} filters={this.state} />
      </ErrorBoundary>
    );
  }

  events = {
    onFieldModifyChanged: (value) => {
      this.setState({ fieldModify: value });
    },

    onSendEmailChanged: (value) => {
      this.setState({ sendEmail: value });
    },

    onMessageChanged: (value) => {
      this.setState({ message: value });
    },

    onApproversChanged: (value) => {
      this.setState({ approvers: value });
    },

    onServiceChanged: (value) => {
      this.setState({ service: value });
    },

    onApplyFiltersClicked: () => {
      console.log('aplicou o filtro ', this.state);
    },
  };
}
