import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { setLocale } from 'yup';
import { pt } from 'yup-locales';

import translations from './locales';

const i18nConfig = {
  detection: { order: ['navigator'] },
  resources: translations,
  fallbackLng: 'pt-BR',
  defaultNS: 'translations',
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

setLocale(pt);

export default i18n;
