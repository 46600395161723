import { utils } from '../../../../utils/utils';

let Template = (props) => {
  let moneyFormatted = utils.formatters.money.getFormatted;
  let currencyCodeFormatted = utils.converters.money.getCurrencySymbol;
  let dailyTaxRooms = 0;
  let totalValueRooms = 0;
  let currencyCode = 'BRL';
  let roomCurrent = props.rooms.find(
    (room) => room.id == props.roomIdsSelected[0],
  );
  let days = roomCurrent.fares
    ? roomCurrent.fares[0].fareInformation.days
    : roomCurrent.original.fares[0].fareInformation.days;

  return (
    <div className={`exon-hotel-price-details-component ${props.className}`}>
      <div className="exon-hotel-price-details-rooms">
        {props.roomIdsSelected.map((id, index) => {
          let room = props.rooms.find((room) => room.id == id);
          let roomValue = room.fares[0].roomValue;
          let fareInformation = room.fares[0].fareInformation;
          let dailyFare = roomValue.dailyFare;
          let markupedDailyFare = roomValue.markupedDailyFare;
          let originalDailyFare = roomValue.originalDailyFare;
          let dailyTax = roomValue.dailyTax;
          let originalDailyTax = roomValue.originalDailyTax;

          let totalRoom = markupedDailyFare.value
            ? markupedDailyFare.value
            : originalDailyFare.value;

          dailyTaxRooms += dailyTax.value
            ? dailyTax.value
            : originalDailyTax.value;
          totalValueRooms +=
            (markupedDailyFare.value
              ? markupedDailyFare.value
              : originalDailyFare.value) +
            (dailyTax.value ? dailyTax.value : originalDailyTax.value);
          currencyCode =
            dailyFare.currencyCode ||
            dailyTax.currencyCode ||
            originalDailyFare.currencyCode ||
            originalDailyTax.currencyCode;

          return (
            <div key={index} className="exon-hotel-price-details-rooms-box">
              <div className="exon-hotel-price-details-room-box">
                <h6 className="exon-hotel-price-details-room-name">
                  {`Quarto ${index + 1}`}
                </h6>
                <div className="exon-hotel-price-details-price-details">
                  <p className="exon-hotel-price-details-price-day">
                    {`${currencyCodeFormatted(currencyCode)} ${moneyFormatted(
                      totalRoom,
                      currencyCode,
                    )}`}
                  </p>
                  <p className="exon-hotel-price-details-price-description">
                    por dia
                  </p>
                </div>
              </div>
              <div className="exon-hotel-price-details-amenities-box">
                <p className="exon-hotel-price-details-amenities-item">
                  {`${
                    fareInformation.board && fareInformation.board.name
                      ? fareInformation.board.name
                      : 'Sem café da manhã'
                  }`}
                </p>
                <p className="exon-hotel-price-details-amenities-item">
                  {`${
                    fareInformation.refundable
                      ? 'Reembolsável'
                      : 'Não reembolsável'
                  }`}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <hr />
      <div className="exon-hotel-price-details-tariffs-and-fees">
        <div>
          <p>Taxas e impostos totais</p>
          <p>
            <strong>
              {`${currencyCodeFormatted(currencyCode)} ${moneyFormatted(
                dailyTaxRooms,
                currencyCode,
              )}`}
            </strong>
          </p>
        </div>
      </div>
      <hr />
      <div className="exon-hotel-price-details-total">
        <p>{`Total ${days} ${days != 1 ? 'diárias' : 'diária'}`}</p>
        <p>
          {`${currencyCodeFormatted(currencyCode)} ${moneyFormatted(
            totalValueRooms * days,
            currencyCode,
          )}`}
        </p>
      </div>
    </div>
  );
};

export default Template;
