import { enums } from '@legacy-utils/enums';
import React from 'react';
import GridTemplate from './grid.template.js';

export default class GridComponent extends React.Component {
  constructor(props) {
    super(props);

    let rows = props.rows;
    GridComponent.fillRowsChecked(rows, [], props.identifier);

    let columns = props.columns;
    for (let i = 0; i < columns.length; i++) {
      columns[i].filterValue = '';
    }

    let columnIdentifier = columns.find((column) => column.identifier);

    this.state = {
      columns: columns,
      rows: rows,
      rowsFiltered: rows,
      identifierRefName: (columnIdentifier && columnIdentifier.refName) || null,

      checkedRowsId: [],

      respectInternalRowsUpdate: false, //utilizado quando a manipulação de rows deve ser respeitada internamente (desconsiderando o props.rows)
    };
  }

  static getDerivedStateFromProps(props, state) {
    let rows = state.rowsFiltered;
    let rowsOriginal = state.rows;
    let checkedRowsId = state.checkedRowsId;

    if (!state.respectInternalRowsUpdate) {
      rows = props.rows;

      let rowsChecked = rows.filter((row) => row.checked);
      checkedRowsId = rowsChecked.map(
        (check) => check[state.identifierRefName],
      );
    }

    if (rowsOriginal && rowsOriginal.length) {
      let listColumns = state.columns.filter(
        (c) => c.formatter == enums.formatters.list,
      );
      listColumns.forEach((column) => {
        column._filterItems = [
          ...new Set(rowsOriginal.map((r) => r[column.refName])),
        ];
      });
    }

    GridComponent.fillRowsChecked(
      rows,
      state.checkedRowsId,
      state.identifierRefName,
    );

    return {
      rows: !state.respectInternalRowsUpdate ? rows : state.rows,
      checkedRowsId: !state.respectInternalRowsUpdate
        ? checkedRowsId
        : state.checkedRowsId,
      rowsFiltered: rows,
      respectInternalRowsUpdate: false,
    };
  }

  static fillRowsChecked(rows, ids, identifier) {
    for (let i = 0; i < rows.length; i++) {
      rows[i].checked = ids.some((id) => id == rows[i][identifier]);
    }
  }

  onRowClicked(identifier, index) {
    if (this.props.onRowClick) this.props.onRowClick(identifier, index);
  }

  checkboxChange = (ev, id) => {
    let rows = this.state.rows;
    let row = rows.find((row) => row[this.state.identifierRefName] === id);
    row.checked = ev.target.checked;

    let checkeds = rows.filter((row) => row.checked);

    this.setState({
      rows: rows,
      checkedRowsId: checkeds.map(
        (check) => check[this.state.identifierRefName],
      ),
      respectInternalRowsUpdate: true,
    });

    if (this.props.onRowsSelected) this.props.onRowsSelected(checkeds);
  };

  checkAllLines = (ev) => {
    let rows = this.state.rows;
    for (let i = 0; i < rows.length; i++) {
      rows[i].checked = ev.target.checked;
    }

    let checkeds = rows.filter((row) => row.checked);

    this.setState({
      rows: rows,
      checkedRowsId: checkeds.map(
        (check) => check[this.state.identifierRefName],
      ),
      respectInternalRowsUpdate: true,
    });

    if (this.props.onRowsSelected) this.props.onRowsSelected(checkeds);
  };

  cleanFilter() {
    const columns = this.state.columns;
    for (let i = 0; i < columns.length; i++) {
      columns[i].filterValue = '';
    }

    this.setState({
      rowsFiltered: this.state.rows,
      columns: columns,
      respectInternalRowsUpdate: true,
    });
  }

  onClickDelete() {
    let rows = this.state.rows;
    let checkedRows = rows.filter((row) => row.checked);

    if (this.props.onDeleteClicked) this.props.onDeleteClicked(checkedRows);
  }

  filterValueChange(event, refColumn) {
    let rowsFiltered = this.state.rows;

    let columns = this.state.columns;
    columns.filter((c) => c.refName == refColumn.refName)[0].filterValue =
      event.target.value;

    for (let i = 0; i < columns.length; i++) {
      let column = columns[i];

      if (!column.hide) {
        if (column.formatter != 4 && column.formatter != 3) {
          switch (column.formatter) {
            case enums.formatters.boolean:
              const inputValue =
                column.filterValue == ''
                  ? null
                  : column.filterValue == '1'
                  ? true
                  : false;
              rowsFiltered =
                inputValue == null
                  ? rowsFiltered
                  : rowsFiltered.filter(
                      (row) => row[column.refName] == inputValue,
                    );

              break;
            case enums.formatters.list:
              const inputList =
                column.filterValue == '' ? null : column.filterValue;
              rowsFiltered =
                inputList == null
                  ? rowsFiltered
                  : rowsFiltered.filter(
                      (row) => row[column.refName] == inputList,
                    );

              break;
            default:
              const inputText = this.slugify(column.filterValue);
              rowsFiltered = rowsFiltered.filter((row) =>
                this.slugify(row[column.refName]).includes(inputText),
              );
              break;
          }
        }
      }
    }

    this.setState({
      rowsFiltered: rowsFiltered,
      columns: columns,
      respectInternalRowsUpdate: true,
    });
  }

  slugify(str) {
    const map = {
      '-': ' |_',
      a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
      e: 'é|è|ê|ë|É|È|Ê|Ë',
      i: 'í|ì|î|ï|Í|Ì|Î|Ï',
      o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
      u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
      c: 'ç|Ç',
      n: 'ñ|Ñ',
    };
    for (let pattern in map) {
      str = str.replace(new RegExp(map[pattern], 'g'), pattern);
    }
    return str.toLowerCase();
  }

  render() {
    return (
      <GridTemplate.main
        columnsGroup={this.props.group}
        columns={this.state.columns}
        rowsFiltered={this.state.rowsFiltered}
        identifierRefName={this.state.identifierRefName}
        filterValueChange={this.filterValueChange.bind(this)}
        checkboxChange={this.checkboxChange.bind(this)}
        checkAllLines={this.checkAllLines.bind(this)}
        onClickDelete={this.onClickDelete.bind(this)}
        onRowClick={this.onRowClicked.bind(this)}
        cleanFilter={this.cleanFilter.bind(this)}
        urlImage={this.props.urlImage}
        hideCheckboxes={this.props.hideCheckboxes ? true : false}
        hideFilter={this.props.hideFilter ? true : false}
        rowsDiff={this.props.rowsDiff}
      />
    );
  }
}
