import HotelBaseInfos from '../../hotel-base-infos/hotel-base-infos.component.js';
import { utils } from '@legacy-utils/utils';

let Template = (props) => {
  let totalValue = 0;
  let currencyCode = 'BRL';
  let room = props.hotel.rooms.find(
    (room) => room.id == props.roomIdsSelected[0],
  );

  props.roomIdsSelected.map((roomId, index) => {
    let room = props.hotel.rooms.find((room) => room.id == roomId);
    let roomValue = room.fares[0].roomValue;

    if (room) {
      totalValue +=
        (roomValue.markupedDailyFare.value
          ? roomValue.markupedDailyFare.value
          : roomValue.originalDailyFare.value) +
        (roomValue.dailyTax.value
          ? roomValue.dailyTax.value
          : roomValue.originalDailyTax.value);
      currencyCode =
        roomValue.markupedDailyFare.currencyCode ||
        roomValue.dailyTax.currencyCode ||
        roomValue.originalDailyFare.currencyCode ||
        roomValue.originalDailyTax.currencyCode;
    }
  });

  return (
    <div
      className={`exon-room-booking-summary ${
        props.className && props.className
      }`}
    >
      <div className="exon-room-booking-summary-hotel-infos">
        <HotelBaseInfos
          name={props.hotel.name}
          rating={props.hotel.rating}
          connectionSource={props.hotel.connectionSource}
          sourceSupplier={props.hotel.sourceSupplier}
          chain={props.hotel.chain}
          commercialAgreement={
            room.fares[0].fareInformation.commercialAgreement
          }
        />
        <div className="exon-room-booking-summary-dates">
          <div className="exon-room-booking-summary-date">
            <p>Check-in</p>
            <p>
              {utils.formatters.date.dateObjToString(
                room.fares[0].fareInformation.checkIn,
              )}
            </p>
          </div>
          <div className="exon-room-booking-summary-date">
            <p>Check-out</p>
            <p>
              {utils.formatters.date.dateObjToString(
                room.fares[0].fareInformation.checkOut,
              )}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="exon-room-booking-summary-rooms">{props.children}</div>
      <div className="exon-room-booking-summary-actions">
        <div className="exon-room-booking-summary-total-value">
          <p>Total de {room.fares[0].fareInformation.days} diárias</p>
          <p>
            <span>
              {utils.converters.money.getCurrencySymbol(currencyCode)}
            </span>
            {utils.formatters.money.getFormatted(
              totalValue * room.fares[0].fareInformation.days,
              currencyCode,
            )}
          </p>
        </div>
        <div className="exon-room-booking-summary-buttons">
          <div className="exon-box-button-double exon-box-button-double-small">
            <button
              className={
                'exon-button exon-button-secondary exon-button-secondary_enabled'
              }
              onClick={() => props.onIncludeInCartClick()}
            >
              <span className="exon-icon exon-icon-marketing-unfilled_disabled"></span>
            </button>
            <button
              className={'exon-button exon-button-primary'}
              onClick={() => props.onSendSelectedProductCheckoutClick()}
            >
              Selecionar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
