import { enums } from '../../utils/enums';

var TabPanelTemplate = {
  main: ({
    children,
    identifier,
    activeTabIdentifier,
    loading,
    actions,
    displayActionsTabPanel,
    loaderType,
  }) => {
    const show = identifier === activeTabIdentifier;
    return (
      <div className={'exon-tab-panel' + (show ? '' : ' hide')}>
        <div>
          <div className={displayActionsTabPanel || loading ? ' hide' : ''}>
            <TabPanelTemplate.content children={children} />
          </div>

          {!displayActionsTabPanel &&
            loading &&
            loaderType == enums.productType.air && (
              <div className="exon-box-air-loading">
                <div>
                  <span className="exon-icon exon-icon-loading-plane-unfilled_enabled"></span>
                  <p>
                    Por favor aguarde!
                    <br />
                    Estamos carregando as opções de vôo...
                  </p>
                </div>
              </div>
            )}

          {!displayActionsTabPanel &&
            loading &&
            loaderType == enums.productType.hotel && (
              <div className="exon-box-hotel-loading">
                <div>
                  <span className="exon-icon exon-icon-loading-hotel-unfilled_enabled"></span>
                  <p>
                    Por favor aguarde!
                    <br />
                    Estamos carregando as opções de hotel...
                  </p>
                </div>
              </div>
            )}

          {!displayActionsTabPanel && loading && !loaderType && (
            <div className="exon-box-common-loading">
              <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
              <p>
                Por favor aguarde!
                <br />
                Estamos carregando as informações...
              </p>
            </div>
          )}

          {displayActionsTabPanel && actions && (
            <div>
              <TabPanelTemplate.actions actions={actions} />
            </div>
          )}
        </div>
      </div>
    );
  },

  content: ({ children }) => {
    return <div className="exon-tab-panel__content">{children}</div>;
  },

  actions: ({ actions }) => {
    return <div>{actions}</div>;
  },
};

export default TabPanelTemplate;
