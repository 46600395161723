import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { travelerResolver } from '@legacy-utils/resolvers/travelerResolver';
import { statusFromCodes } from '../statusCodes';

export const serviceRequestResolver = {
  resolveServiceRequestResponse(serviceRequest) {
    const _this = serviceRequestResolver;

    return {
      ...serviceRequest,
      identifier:
        (serviceRequest.costCenter && serviceRequest.costCenter.identifier) ||
        `RV ${serviceRequest.id}`,
      costCenter: serviceRequest.costCenter || { id: 1, name: '[ND]' },
      reason: serviceRequest.reason || { id: 1, name: '[ND]' },
      travelType: serviceRequest.isInternational ? 2 : 1,

      travelers: _this.resolveTravelers(serviceRequest.travelers),
      itemsSummary: _this.resolveItemsSummary(serviceRequest.itemsSummary),

      workflow: serviceRequest.workflow || {},
    };
  },

  resolveTravelers: (travelers) => {
    travelers.forEach((traveler) => {
      if (!traveler.name)
        traveler.name = travelerResolver.getFullName(traveler);

      if (!traveler.costCenter) traveler.costCenter = { id: -1, name: '--' };

      if (!traveler.context.companyName) traveler.context.companyName = '--';
    });

    return travelers;
  },

  resolveItemsSummary: (itemsSummary) => {
    const _this = serviceRequestResolver;

    if (!itemsSummary) itemsSummary = [];

    itemsSummary.forEach((item) => {
      if (!item.bookingLocator) {
        item.bookingLocator = '[ND]';

        if (item.workflow?.status?.code == statusFromCodes.toBook)
          item.bookingLocator = '(book pending)';
      }

      if (!item.workflow) {
        item.workflow = {
          status: { id: 0, name: '[ND]' },
          approvers: [
            {
              level: 1,
              user: {
                id: 123,
                fullName: 'Lorenzo Viana',
              },
              approvalDate: {
                day: 26,
                month: 4,
                year: 2021,
                hour: 12,
                minute: 0,
                second: 0,
              },
              status: 1,
            },
          ],
        };
      }

      if (item.type == enums.products.namedType.airBooking) {
        _this.resolveAirItemSummary(item);
      } else if (item.type == enums.products.namedType.hotelBooking) {
        _this.resolveHotelItemSummary(item);
      } else if (item.type == enums.products.namedType.vehicleBooking) {
        _this.resolveVehicleItemsSummary(item);
      }

      if (!item.valueData.cheapestValue)
        item.valueData.cheapestValue = item.valueData.totalValue;
    });

    return itemsSummary.filter(
      (item) =>
        item.type == enums.products.namedType.airBooking ||
        item.type == enums.products.namedType.hotelBooking ||
        item.type == enums.products.namedType.vehicleBooking,
    );
  },

  resolveAirItemSummary: (itemSummary) => {
    if (itemSummary.airTrips) {
      itemSummary.airTrips.forEach((airTrip) => {
        if (!airTrip.departureDate.day)
          airTrip.departureDate =
            utils.formatters.date.getFormattedDateObjFromDate(
              new Date(airTrip.departureDate),
            );

        if (!airTrip.arrivalDate.day)
          airTrip.arrivalDate =
            utils.formatters.date.getFormattedDateObjFromDate(
              new Date(airTrip.arrivalDate),
            );
      });
    }
  },

  resolveHotelItemSummary: (itemSummary) => {
    if (itemSummary.hotel) {
      if (!itemSummary.hotel.address) itemSummary.hotel.address = {};

      if (itemSummary.hotel.room) {
        if (!itemSummary.hotel.room.sourceIdentifier)
          itemSummary.hotel.room.sourceIdentifier = {};
      }
    }
  },

  resolveVehicleItemsSummary: (itemSummary) => {},
};
