import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';
import * as SCBase from '../../styled';

export default {
  ...SCBase,
  AvailableValue: styled(Typography).attrs({
    variant: 'headline8',
    weight: 700,
    variantColor: `#53464f`,
    tag: `p`,
  })`
    color: ${({ theme }) => theme.colors.neutral[30]};
    font-size: 16px;
  `,
  Description: styled(Typography).attrs({
    variant: 'body3',
    weight: 600,
    variantColor: `#53464f`,
    tag: `p`,
  })`
    color: ${({ theme }) => theme.colors.neutral[40]};
    font-size: 14px;
  `,
  BalanceNumberContainer: styled.div`
    border-left: 1px solid ${({ theme }) => theme.colors.neutral[80]};
    padding: 0 1rem;
    min-width: 15.1rem;
  `,
};
