'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import OptionsListTemplate from './options-list.templante.js';

export default class OptionsListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      options: this.props.options,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      options: props.options,
    };
  }

  render() {
    return (
      <OptionsListTemplate
        options={this.state.options}
        onChange={this.events.onChange}
      />
    );
  }

  events = {
    onChange: function (event, index) {
      if (this.props.onItemChange) this.props.onItemChange(event, index);
    },
  };
}
