import Popup from '../popup/popup.component.js';
import Select from '../select/select.component.js';
import HotelBaseInfos from '../products-travel/hotel-product/hotel-base-infos/hotel-base-infos.component.js';
import HotelAddress from '../products-travel/hotel-product/hotel-address/hotel-address.component.js';
import Icon from '../common/icon/icon.component.js';
import HotelDescription from '../products-travel/hotel-product/hotel-description/hotel-description.component.js';
import HotelCancellationPolicies from '../products-travel/hotel-product/hotel-cancellation-policies/hotel-cancellation-policies.component.js';
import HotelRoomsGroup from '../products-travel/hotel-product/hotel-rooms/hotel-rooms-group/hotel-rooms-group.component.js';
import HotelRoomListGroups from '../products-travel/hotel-product/hotel-rooms/hotel-room-list-groups/hotel-room-list-groups.component.js';
import { utils } from '../../utils/utils.js';
import { travelEnums } from '../../utils/enums.js';

const getMoneyFormatted = utils.formatters.money.getFormatted;
const getCurrencySymbol = utils.converters.money.getCurrencySymbol;
const showRadiusInfo = (filter => ![null,undefined].includes(filter?.geoLocation))
const RADIUS_TEXT = "O cálculo da distância é realizado obtendo o menor tempo para deslocamento ao local de destino.";

var HotelSearchResultTemplate = {
  main: ({
    hotels,
    itemsQuantity,
    showRooms,
    tabIdentifier,
    onShowHideMoreRoomsClicked,
    onRoomSelect,
    searchData,
    onCancelationPolicyClick,
    displayCancelationPolicy,
    onHideCancelationPolicyClick,
    onOrderHotelSearchResultClick,
    selectedRoom,
    isLoad,
    onIncludeInCartClick,
    onSelectHotelCheckoutClick,
    onOpenHotelDetailsClick,
    onShowHideRoomDescriptionClicked,
    showRoomsDescription,
    onRedoSearchClick,
    onDeleteTabAndSearchClick,
    listOrderSelect,
  }) => {
    return (
      <div className="exon-hotel-search-result">
        <HotelSearchResultTemplate.popupPolicy
          displayCancelationPolicy={displayCancelationPolicy}
          onHideCancelationPolicyClick={onHideCancelationPolicyClick}
        />

        <HotelSearchResultTemplate.noResultsFound
          itemsQuantity={itemsQuantity}
          onRedoSearchClick={onRedoSearchClick}
          onDeleteTabAndSearchClick={onDeleteTabAndSearchClick}
        />

        <HotelSearchResultTemplate.result
          itemsQuantity={itemsQuantity}
          searchData={searchData}
          onOrderHotelSearchResultClick={onOrderHotelSearchResultClick}
          hotels={hotels}
          selectedRoom={selectedRoom}
          showRooms={showRooms}
          onIncludeInCartClick={onIncludeInCartClick}
          onSelectHotelCheckoutClick={onSelectHotelCheckoutClick}
          onCancelationPolicyClick={onCancelationPolicyClick}
          onRoomSelect={onRoomSelect}
          onShowHideMoreRoomsClicked={onShowHideMoreRoomsClicked}
          isLoad={isLoad}
          showRoomsDescription={showRoomsDescription}
          onShowHideRoomDescriptionClicked={onShowHideRoomDescriptionClicked}
          onOpenHotelDetailsClick={onOpenHotelDetailsClick}
          tabIdentifier={tabIdentifier}
          listOrderSelect={listOrderSelect}
        />
      </div>
    );
  },

  noResultsFound: ({ itemsQuantity, onRedoSearchClick, onDeleteTabAndSearchClick }) => {
    return (
      <div>
        {itemsQuantity == 0 && (
          <div className={'exon-no-data-box exon-hotel-search-not-result'}>
            <div>
              <span className="exon-icon exon-icon-noData-unfilled_disabled"></span>
              <p>Nenhum resultado encontrado!</p>
            </div>
            <div className="exon-tab-panel-search-options">
              <div className="exon-box-buttons-group">
                <button
                  className="exon-button exon-button-secondary"
                  onClick={onDeleteTabAndSearchClick}>
                  Fechar aba atual
                </button>
                <button
                  className="exon-button exon-button-primary"
                  onClick={onRedoSearchClick}>
                  Renovar Pesquisa
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },

  result: ({
    itemsQuantity,
    searchData,
    listOrderSelect,
    hotels,
    selectedRoom,
    showRooms,
    onIncludeInCartClick,
    onSelectHotelCheckoutClick,
    onCancelationPolicyClick,
    onRoomSelect,
    onShowHideMoreRoomsClicked,
    isLoad,
    onShowHideRoomDescriptionClicked,
    onOpenHotelDetailsClick,
    showRoomsDescription,
    tabIdentifier,
  }) => {
    return (
      <div>
        {itemsQuantity > 0 && (
          <div>
            <div className="exon-box-order-policy exon-box-order-policy-row">
              <Select
                data={listOrderSelect.data}
                placeholder={listOrderSelect.placeholder}
                externalOptions={listOrderSelect.options}
              />
            </div>

            <div className="box-description exon-box-description">
              <p className="descriptionm exon-description-item">
                <span className="highlight exon-description-item__highlight">
                  {itemsQuantity}
                </span>
                {itemsQuantity > 1
                  ? ' resultados retornados'
                  : ' resultado retornardo'}
              </p>
              {showRadiusInfo(searchData?.searchViewParameters?.filter) && RADIUS_TEXT && (
                <p className="descriptionm exon-description-item">
                  {RADIUS_TEXT}
                </p>
              )}
            </div>

            <div className="exon-hotel-search-result-box">
              {hotels &&
                hotels.map((hotel, index) => {
                  // listar opções de hotel

                  let roomsOrderedByPrice = hotel.rooms.sort((initial, final) => {
                    if (initial.price.totalDaily === final.price.totalDaily) {
                      return 0;
                    }

                    if ([
                      travelEnums.hotelSearchOrderBy.stars,
                      travelEnums.hotelSearchOrderBy.starsDistance,
                      travelEnums.hotelSearchOrderBy.starsPrice,
                    ].includes(searchData.searchViewParameters.sortingData.by))
                    {
                      return initial.price.totalDaily > final.price.totalDaily ? 1 : -1;
                    }

                    if (!searchData.searchViewParameters.sortingData.desc) {
                      return initial.price.totalDaily > final.price.totalDaily ? 1 : -1;
                    }

                    return initial.price.totalDaily < final.price.totalDaily ? 1 : -1;
                  });

                  let arr = [];
                  searchData.rooms.map((parameterRoom, parameterIndex) => {
                    if (isLoad) {
                      arr[parameterIndex] = roomsOrderedByPrice[0];
                      selectedRoom[index] = arr;
                    }
                  });
                  return (
                    <HotelSearchResultTemplate.hotel
                      key={index}
                      index={index}
                      tabIdentifier={tabIdentifier}
                      hotel={hotel}
                      selectedRoom={selectedRoom}
                      showRooms={showRooms}
                      onIncludeInCartClick={onIncludeInCartClick}
                      onSelectHotelCheckoutClick={onSelectHotelCheckoutClick}
                      searchData={searchData}
                      roomsOrderedByPrice={roomsOrderedByPrice}
                      onCancelationPolicyClick={onCancelationPolicyClick}
                      onRoomSelect={onRoomSelect}
                      onOpenHotelDetailsClick={onOpenHotelDetailsClick}
                      onShowHideMoreRoomsClicked={onShowHideMoreRoomsClicked}
                      onShowHideRoomDescriptionClicked={
                        onShowHideRoomDescriptionClicked
                      }
                      showRoomsDescription={showRoomsDescription}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
    );
  },

  hotel: ({
    index,
    hotel,
    tabIdentifier,
    selectedRoom,
    onShowHideMoreRoomsClicked,
    showRooms,
    onIncludeInCartClick,
    onSelectHotelCheckoutClick,
    searchData,
    onCancelationPolicyClick,
    onRoomSelect,
    onShowHideRoomDescriptionClicked,
    showRoomsDescription,
    onOpenHotelDetailsClick,
  }) => {
    let option =
      hotel.roomOptions.filter((item) => {
        return (
          item.adultCount === searchData.rooms[0].adultCount &&
          item.childCount === searchData.rooms[0].childCount
        );
      })[0] || null;
    let roomsSelected = selectedRoom[index].filter((room) => {
      return room.id;
    });
    let roomIdsSelected = roomsSelected.map((room) => {
      return room.id;
    });

    let cancellationPolicies =
      selectedRoom[index][0].cancellationPolicies &&
        selectedRoom[index][0].cancellationPolicies.length > 0
        ? selectedRoom[index][0].cancellationPolicies[0]
        : null;

    let isHotelOffline =
      selectedRoom[index][0].sourceIdentifier ===
      travelEnums.hotelSources.offline;

    let isGeoLocation = showRadiusInfo(searchData?.searchViewParameters?.filter);

    return (
      <div className="exon-hotel-card box">
        <div className="exon-box-row">
          <div className="exon-hotel-images-box">
            {hotel.images.length && hotel.images[0] && (
              <div className="exon-carroussel-item">
                <img
                  className="exon-hotel-images-box__img"
                  src={hotel.images[0].url}
                  onError={event => {
                    event.target.src = require('../../assets/images/thumb-hotel-placeholder.svg');
                    event.onerror = null;
                  }}
                />
              </div>
            )}
          </div>

          <div className="exon-hotel-infos">
            <div className="exon-hotel-name-box">
              <div>
                <div className="exon-box-row__piece">
                  <HotelBaseInfos
                    name={hotel.name}
                    hotelNameCharacterLimit={35}
                    rating={hotel.stars}
                    connectionSource={selectedRoom[index][0].sourceIdentifier}
                    sourceSupplier={''}
                    chain={hotel.chain}
                    onClick={() =>
                      onOpenHotelDetailsClick({
                        indexHotelClicked: index,
                        identifierSearch: tabIdentifier,
                      })
                    }
                    isHotelOffline={isHotelOffline}
                  />
                </div>

                <div className="exon-box-row__piece exon-icon-spacing">
                  {selectedRoom[index][0].fares[0].fareInformation.board && (
                    <Icon
                      icon="exon-icon-coffee-unfilled_enabled"
                      description={
                        selectedRoom[index][0].fares[0].fareInformation.board
                          .name ||
                        selectedRoom[index][0].fares[0].fareInformation.board
                          .description
                      }
                    />
                  )}
                  {!selectedRoom[index][0].fares[0].fareInformation.board && (
                    <Icon
                      icon="exon-icon-coffee-unfilled_disabled"
                      description="Sem refeição"
                    />
                  )}

                  <Icon
                    icon={`exon-icon-cashback-unfilled_enabled${
                      cancellationPolicies && cancellationPolicies.nonRefundable ? "-gray" : ""
                      }`}
                    description={`${
                      cancellationPolicies !== null
                      ? cancellationPolicies.nonRefundable
                        ? 'Não Reembolsável'
                        : 'Reembolsável'
                      : 'Sem informação'
                      }`}
                  />
                </div>
              </div>
              <div className="exon-hotel-description-box">
                <HotelDescription
                  description={selectedRoom[index][0].description}
                />
              </div>
            </div>

            <div className="exon-hotel-row-location">
              <div className={'exon-hotel-location'}>
                <HotelAddress
                  addressLabel={searchData?.label}
                  distance={hotel.distance?.toFixed(2) || "--"} // @vpcmps Colocar a distância "do centro" / "de Ponto de referência" aqui
                  address={hotel.location}
                  showMap={false}
                  distanceTooltip={isGeoLocation && RADIUS_TEXT}
                  distanceIsFromCityCenter={!isGeoLocation}
                />
              </div>
            </div>

            <div className="exon-hotel-other-rooms-box">
              <div className="exon-cancelation-policy__text">
                <p>
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      option.room = selectedRoom[index][0].original;
                      onCancelationPolicyClick(
                        e,
                        hotel.name,
                        option,
                        hotel.comppressedAdditionalProperties,
                      );
                    }}
                  >
                    Política de cancelamento
                  </a>
                </p>
              </div>

              <div className="exon-box-row__piece exon-box-row__piece__bottom exon-about-room exon-button-max-content">
                <button
                  className="exon-button exon-button-info"
                  onClick={(e) => {
                    e.stopPropagation();
                    onShowHideRoomDescriptionClicked(index);
                  }}
                >
                  Sobre o quarto
                  <span
                    className={
                      'exon-icon' +
                      (showRoomsDescription[index]
                        ? ' exon-icon-down-unfilled_disabled'
                        : ' exon-icon-right-unfilled_disabled')
                    }
                  ></span>
                </button>
              </div>

              <div className="exon-box-row__piece exon-box-row__piece__bottom exon-button-max-content">
                <button
                  className="exon-button exon-button-info"
                  onClick={(e) => {
                    e.stopPropagation();
                    onShowHideMoreRoomsClicked(index);
                  }}
                >
                  Outros quartos
                  <span
                    className={
                      'exon-icon' +
                      (showRooms[index]
                        ? ' exon-icon-down-unfilled_disabled'
                        : ' exon-icon-right-unfilled_disabled')
                    }
                  ></span>
                </button>
              </div>
            </div>

            <div className="exon-hotel-values-box">
              <div className="exon-box-row__piece">
                <div className="exon-box-row__piece-values">
                  <div className="trip-policy-and-values__box-amount">
                    <div>
                      <span className="trip-policy-and-values__box-icons exon-content-icon exon-icon-policy-in-out-filled_enabled hide"></span>
                    </div>
                    <div className="trip-policy-and-values__amount">
                      <span>
                        {getCurrencySymbol(
                          selectedRoom[index][0].price.currencySymbol,
                        )}

                        <span className="exon-tooltip trip-policy-and-values__amount-tooltip">
                          {getMoneyFormatted(
                            selectedRoom[index].reduce(
                              (total, element) =>
                                (total += element.price.totalDaily),
                              0,
                            ),
                            selectedRoom[index][0].price.currencySymbol,
                          )}
                          <div className="tooltip">
                            <p>
                              {selectedRoom[index].length > 1
                                ? 'Valor total das tarifas'
                                : 'Valor da tarifa:'}{' '}
                              {`${getCurrencySymbol(
                                selectedRoom[index][0].price.currencySymbol,
                              )} ${getMoneyFormatted(
                                selectedRoom[index].reduce(
                                  (total, element) =>
                                  (total +=
                                    element.price.markupedDailyFare.value),
                                  0,
                                ),
                                selectedRoom[index][0].price.currencySymbol,
                              )}`}
                            </p>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="trip-policy-and-values__price-details">
                    <p>
                      Taxas e impostos
                      <span>
                        {`${getCurrencySymbol(
                          selectedRoom[index][0].price.currencySymbol,
                        )} ${getMoneyFormatted(
                          selectedRoom[index].reduce(
                            (total, element) =>
                              (total += element.price.dailyTaxes),
                            0,
                          ),
                          selectedRoom[index][0].price.currencySymbol,
                        )}`}
                      </span>
                    </p>
                    <p className="exon-trip-value">
                      Total
                      <br />
                      {selectedRoom[index][0].price.totalDays}{' '}
                      {selectedRoom[index][0].price.totalDays == 1
                        ? 'diária'
                        : 'diárias'}
                      <span className="exon-trip-value-total">
                        {`${getCurrencySymbol(
                          selectedRoom[index][0].price.currencySymbol,
                        )} ${getMoneyFormatted(
                          selectedRoom[index].reduce(
                            (total, element) => (total += element.price.total),
                            0,
                          ),
                          selectedRoom[index][0].price.currencySymbol,
                        )}`}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="exon-box-row__piece exon-box-row__piece__bottom">
                <div className="exon-box-button-double exon-box-button-double-small">
                  <button
                    className="exon-button exon-button-secondary exon-button-secondary_enabled"
                    onClick={(e) => {
                      e.stopPropagation();
                      onIncludeInCartClick(index);
                    }}
                  >
                    <span className="exon-icon exon-icon-marketing-unfilled_disabled"></span>
                  </button>
                  <button
                    className="exon-button exon-button-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectHotelCheckoutClick(index);
                    }}
                  >
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HotelSearchResultTemplate.descriptionRoom
          showRoomsDescription={showRoomsDescription}
          index={index}
          description={selectedRoom[index][0].description}
        />

        <HotelSearchResultTemplate.room
          hotel={hotel}
          tabIdentifier={tabIdentifier}
          roomIdsSelected={roomIdsSelected}
          searchData={searchData}
          showRooms={showRooms}
          index={index}
          onRoomSelect={onRoomSelect}
          onOpenHotelDetailsClick={onOpenHotelDetailsClick}
        />
      </div>
    );
  },

  descriptionRoom: ({ showRoomsDescription, index, description }) => {
    return (
      <div
        className={
          'exon-hotel-room-about exon-hotel-details-box exon-hotel-rooms row ' +
          (showRoomsDescription[index] ? '' : 'hide')
        }
      >
        <hr />
        {description && <p>{description}</p>}
        {!description && (
          <p>Informações sobre o quarto não informadas pelo hotel . . . </p>
        )}
      </div>
    );
  },

  room: ({
    hotel,
    tabIdentifier,
    roomIdsSelected,
    searchData,
    showRooms,
    index,
    onRoomSelect,
    onOpenHotelDetailsClick,
  }) => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className={
          'exon-hotel-details-box exon-hotel-rooms row ' +
          (showRooms[index] ? '' : 'hide')
        }
      >
        <hr />
        {searchData.rooms.length == 1 && (
          <HotelRoomsGroup
            rooms={hotel.rooms}
            roomIdSelected={roomIdsSelected[0]}
            showFilterRooms={false}
            onSelectRoomClick={(roomId, indexRoom) =>
              onRoomSelect(roomId, indexRoom, index)
            }
            onDetailsHotelClick={() =>
              onOpenHotelDetailsClick({
                indexHotelClicked: index,
                identifierSearch: tabIdentifier,
              })
            }
          />
        )}
        {searchData.rooms.length > 1 && (
          <HotelRoomListGroups
            searchParameters={searchData}
            roomIdsSelected={roomIdsSelected}
            showFilterRooms={false}
            rooms={hotel.rooms}
            onSelectRoomClick={(roomId, indexRoom) =>
              onRoomSelect(roomId, indexRoom, index)
            }
            onDetailsHotelClick={() =>
              onOpenHotelDetailsClick({
                indexHotelClicked: index,
                identifierSearch: tabIdentifier,
              })
            }
          />
        )}
      </div>
    );
  },

  popupPolicy: ({ displayCancelationPolicy, onHideCancelationPolicyClick }) => {
    return (
      <div>
        {displayCancelationPolicy.display && (
          <div className="">
            <Popup
              exonStyle={true}
              title={`Cancelamento: ${displayCancelationPolicy.roomName}`}
              cancelButtonClick={onHideCancelationPolicyClick}
            >
              <HotelCancellationPolicies
                cancellationPolicies={
                  displayCancelationPolicy.cancellationPolicies
                }
                cancelDeadline={displayCancelationPolicy.cancelDeadline}
                popupLoad={displayCancelationPolicy.popupLoad}
              />
            </Popup>
          </div>
        )}
      </div>
    );
  },
};

export default HotelSearchResultTemplate;
