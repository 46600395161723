import Popup from '../../../popup/popup.component.js';
import Form from '../../../common/form/form.component.js';
import Field from '../../../field/field.component.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';

var Template = {
  main: ({
    userProfile,
    tariffsAndFees,
    canEdit,
    canEditFares,
    form,
    events,
  }) => {
    let empty = '-';
    let moneyFormatted = utils.formatters.money.getFormatted;
    let isMarkupVisible =
      userProfile?.company?.isAgency || userProfile?.travelProfile === 4;

    return (
      <div className="exon-booking-hotel-tariffs-and-fees">
        <table className="exon-table-line" cellSpacing="0">
          <thead>
            <tr>
              <th>Sistema</th>
              <th>Quarto</th>
              <th>Regime</th>
              <th>Status</th>
              <th>Q. Diárias</th>
              <th className="exon-text-center">Detalhes das tarifas</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tariffsAndFees.map((item, index) => {
              return (
                <tr key={index}>
                  <td className={!item.system ? 'exon-text-center' : ''}>
                    {item.system ? item.system : empty}
                  </td>
                  <td className={!item.name ? 'exon-text-center' : ''}>
                    {item.name ? item.name : empty}
                  </td>
                  <td className={!item.mealOption ? 'exon-text-center' : ''}>
                    {item.mealOption ? item.mealOption : empty}
                  </td>
                  <td className={!item.status ? 'exon-text-center' : ''}>
                    {item.status ? item.status : empty}
                  </td>
                  <td
                    className={
                      item.days == null || item.totalDays == undefined
                        ? 'exon-text-center'
                        : ''
                    }
                  >
                    {item.days !== null && item.days !== undefined
                      ? item.days
                      : empty}
                  </td>
                  <td>
                    <table className="box-styleless-table" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Valor câmbio</th>
                          <th>Valor Tarifa</th>
                          <th>Taxas Diária</th>
                          {isMarkupVisible && <th>Total de Markup</th>}
                          <th>Valor total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.fares &&
                          item.fares.originalDailyFare &&
                          item.fares.originalDailyTax &&
                          item.fares.roomValue &&
                          item.fares.roomValue.originalDailyFare
                            .currencyCode !==
                            item.fares.roomValue.dailyFare.currencyCode && (
                            <tr>
                              <td>1</td>
                              <td
                                className={
                                  !item.fares.roomValue.originalDailyFare
                                    ? 'exon-text-center'
                                    : ''
                                }
                              >
                                {item.fares.roomValue.originalDailyFare
                                  ? `${
                                      item.fares.roomValue.originalDailyFare
                                        .currencyCode
                                    } ${moneyFormatted(
                                      item.fares.roomValue.originalDailyFare
                                        .value,
                                      item.fares.roomValue.originalDailyFare
                                        .currencyCode,
                                    )}`
                                  : empty}
                              </td>
                              <td
                                className={
                                  !item.fares.roomValue.originalDailyTax
                                    ? 'exon-text-center'
                                    : ''
                                }
                              >
                                {item.fares.roomValue.originalDailyTax
                                  ? `${
                                      item.fares.roomValue.originalDailyTax
                                        .currencyCode
                                    } ${moneyFormatted(
                                      item.fares.roomValue.originalDailyTax
                                        .value,
                                      item.fares.roomValue.originalDailyTax
                                        .currencyCode,
                                    )}`
                                  : empty}
                              </td>

                              {isMarkupVisible && (
                                <td
                                  className={
                                    !item.fares.markup ? 'exon-text-center' : ''
                                  }
                                >
                                  {item.fares.markup
                                    ? item.fares.markup.value
                                    : empty}
                                </td>
                              )}
                              <td
                                className={
                                  !item.fares ||
                                  !item.fares.roomValue.originalTotal
                                    ? 'exon-text-center'
                                    : ''
                                }
                              >
                                {item.fares &&
                                item.fares.roomValue.originalTotal
                                  ? `${
                                      item.fares.roomValue.originalTotal
                                        .currencyCode
                                    } ${moneyFormatted(
                                      item.fares.roomValue.originalTotal.value,
                                      item.fares.roomValue.originalTotal
                                        .currencyCode,
                                    )}`
                                  : empty}
                              </td>
                            </tr>
                          )}

                        <tr>
                          <td
                            className={
                              !item.fares ||
                              !item.fares.exchangeRate ||
                              !item.fares.exchangeRate.value
                                ? 'exon-text-center'
                                : ''
                            }
                          >
                            {item.fares &&
                            item.fares.exchangeRate &&
                            item.fares.exchangeRate.value
                              ? `${
                                  item.fares.roomValue.dailyFare.currencyCode
                                } ${moneyFormatted(
                                  item.fares.exchangeRate.value,
                                  item.fares.roomValue.dailyFare.currencyCode,
                                )}`
                              : empty}
                          </td>
                          <td
                            className={
                              !item.fares || !item.fares.roomValue.dailyFare
                                ? 'exon-text-center'
                                : ''
                            }
                          >
                            {item.fares &&
                            item.fares.roomValue.markupedDailyFare &&
                            item.fares.roomValue.markupedDailyFare.value > 0
                              ? `${
                                  item.fares.roomValue.markupedDailyFare
                                    .currencyCode
                                } ${moneyFormatted(
                                  item.fares.roomValue.markupedDailyFare.value,
                                  item.fares.roomValue.markupedDailyFare
                                    .currencyCode,
                                )}`
                              : item.fares && item.fares.roomValue.dailyFare
                              ? `${
                                  item.fares.roomValue.dailyFare.currencyCode
                                } ${moneyFormatted(
                                  item.fares.roomValue.dailyFare.value,
                                  item.fares.roomValue.dailyFare.currencyCode,
                                )}`
                              : empty}
                          </td>
                          <td
                            className={
                              !item.fares || !item.fares.roomValue.dailyTax
                                ? 'exon-text-center'
                                : ''
                            }
                          >
                            {item.fares && item.fares.roomValue.dailyTax
                              ? `${
                                  item.fares.roomValue.dailyTax.currencyCode
                                } ${moneyFormatted(
                                  item.fares.roomValue.dailyTax.value,
                                  item.fares.roomValue.dailyTax.currencyCode,
                                )}`
                              : empty}
                          </td>

                          {isMarkupVisible && (
                            <td
                              className={
                                !item.fares || !item.fares.roomValue.markup
                                  ? 'exon-text-center'
                                  : ''
                              }
                            >
                              {item.fares && item.fares.roomValue.markup
                                ? `${
                                    item.fares.roomValue.markup.currencyCode
                                  } ${moneyFormatted(
                                    item.fares.roomValue.markup.value,
                                    item.fares.roomValue.markup.currencyCode,
                                  )}`
                                : empty}
                            </td>
                          )}
                          <td
                            className={
                              !item.totalValue ? 'exon-text-center' : ''
                            }
                          >
                            {item.totalValue
                              ? `${
                                  item.totalValue.currencyCode
                                } ${moneyFormatted(
                                  item.totalValue.value,
                                  item.totalValue.currencyCode,
                                )}`
                              : empty}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  {(canEdit || canEditFares) && (
                    <td className="exon-table-action-item exon-text-center">
                      <span
                        className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
                        onClick={() =>
                          form.events.popup.onOpenFormPopupClicked(
                            enums.actionsForm.edit,
                            index,
                          )
                        }
                      ></span>
                      <span
                        className="exon-icon exon-icon-button exon-icon-dump-unfilled_enabled"
                        onClick={() =>
                          events.onDeletetariffsAndFeesClicked(index)
                        }
                      ></span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>

        {form.show && (
          <div className="exon-box-popup-form exon-box-popup-form-rv">
            <Template.form data={form} events={form.events.popup} />
          </div>
        )}
      </div>
    );
  },

  form: ({ data, events }) => {
    return (
      <Popup
        okButtonLabel={'Salvar'}
        cancelButtonLabel={'Cancelar'}
        title={'Tarifas e taxas'}
        cancelButtonClick={events.onCloseFormPopupClicked}
        exonStyle={true}
        okButtonClick={events.onConfirmPopupClicked}
      >
        <Form>
          {Object.keys(data.fields).map((refName, index) => {
            let field = data.fields[refName];
            return (
              <div
                key={index}
                className={`exon-box-form-field ${
                  field.additionalClass ? field.additionalClass : ''
                }`}
              >
                <Field
                  type={field.type}
                  label={field.label}
                  value={field.value}
                  items={field.items}
                  refName={field.refName}
                  mask={field.mask}
                  completeItem={field.completeItem}
                  locationType={field.locationType}
                  placeholder={field.placeholder}
                  forceValidation={data.forceValidation}
                  disabled={field.disabled}
                  required={field.required}
                  options={field.options}
                  isZeroValid={field.isZeroValid}
                  dataSource={field.dataSource}
                  onFieldChange={field.onFieldChange}
                  removeMask={field.removeMask}
                />
              </div>
            );
          })}
        </Form>
      </Popup>
    );
  },
};
export default Template;
