import Icons from '../../../common/icon/icon.component.js';

let Template = (props) => {
  let stars = [];

  if (!props.ratingDescription)
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          className={`exon-icon ${
            props.rating >= i
              ? 'exon-icon-star-filled_enabled'
              : 'exon-icon-star-no-border-unfilled_disabled'
          }`}
          key={i}
        ></span>,
      );
    }

  return (
    <div>
      {!props.ratingDescription && (
        <div className="exon-hotel-rating">{stars}</div>
      )}

      {props.ratingDescription && (
        <div className="exon-hotel-rating-description">
          <Icons
            icon={
              props.rating > 0
                ? 'exon-icon-star-filled_enabled'
                : 'exon-icon-star-unfilled_disabled'
            }
            description={`${props.rating == 0 ? '' : props.rating} ${
              props.rating == 0
                ? 'Sem avaliações'
                : props.rating != 1
                ? 'estrelas'
                : 'estrela'
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default Template;
