export const componentUtil = {
  _hideClassName: 'hide',
  showWithCss: function (element) {
    $(element).removeClass(componentUtil._hideClassName);
  },

  hideWithCss: function (element) {
    $(element)
      .removeClass(componentUtil._hideClassName)
      .addClass(componentUtil._hideClassName);
  },

  react: {
    assignElement: function (
      containerElement,
      options,
      reactElement,
      componentName,
    ) {
      let thisEl = containerElement[0];

      let attrs = {};
      //if (options && options.length)
      attrs['externalOptions'] = options;

      if (!thisEl) {
        console.error(
          'O componente ' + containerElement.selector + ' não existe no html',
        );
      } else {
        for (let i = 0; i < thisEl.attributes.length; i++) {
          let attribute = thisEl.attributes[i];
          if (attribute.name !== 'id') attrs[attribute.name] = attribute.value;
        }

        var reactElement = ReactDOM.render(
          React.createElement(reactElement, attrs),
          thisEl,
        );
        containerElement.data(componentName, reactElement);

        return reactElement;
      }
    },
    bindMethods: function (methods, that) {
      if (!methods) return;

      Object.keys(methods).forEach((method) => {
        if (typeof methods[method] === 'function') {
          methods[method] = methods[method].bind(that);
        }
      });
    },
  },
};
