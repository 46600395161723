import { PillButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';

import { PolicyRuleConfigurationField } from '../../types';
import { minutesToTime } from '../PolicyRuleField/utils';
import { ParameterFormValues } from '../PolicyRuleParametersModal';
import {
  ActionsRow,
  CardContainer,
  ColumnsContainer,
  ColumnTitle,
  RowColumn,
} from './styled';
import { Box } from '@mui/material';
import React, { ReactElement } from 'react';

type Props = {
  fields: PolicyRuleConfigurationField[];
  values: ParameterFormValues;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
};

export const PolicyRuleParameterCard = (props: Props) => {
  function getValueFromField(field: PolicyRuleConfigurationField) {
    const value = props.values[field.key];

    if (field.type === 'options') {
      const findOption = field.options.find((option) => option.value === value);

      if (!findOption) return null;

      return (
        <Box display="flex" flexDirection="row" gap={0.5} alignItems="center">
          {findOption.icon
            ? React.cloneElement(findOption.icon as ReactElement, { size: 18 })
            : null}
          {findOption?.label}
        </Box>
      );
    }

    if (field.type === 'time') {
      return minutesToTime(value as number);
    }

    if (field.type === 'toggle') {
      return value ? 'Sim' : 'Não';
    }

    if (field.type === 'currency' && typeof value === 'number') {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return `${formatter.format(value)}`;
    }

    return String(value || '-');
  }

  return (
    <CardContainer>
      <ColumnsContainer>
        {props.fields.map((field) => (
          <RowColumn key={field.key}>
            <ColumnTitle variant="body4">{field.name}</ColumnTitle>
            <Typography variant="body3">
              {getValueFromField(field) || '-'}
            </Typography>
          </RowColumn>
        ))}
      </ColumnsContainer>

      <ActionsRow>
        <PillButton
          variant="default"
          size="small"
          icon="IconPencil"
          onClick={() => props.onEditClick?.()}
        />

        <PillButton
          variant="default"
          size="small"
          icon="IconTrash"
          onClick={() => props.onDeleteClick?.()}
        />
      </ActionsRow>
    </CardContainer>
  );
};
