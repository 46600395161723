import HotelOption from '../../../../products-travel/hotel-product/hotel-option/hotel-option.component.js';
import Pagination from '../../../../pagination/pagination.componente.js';

var Template = {
  main: ({ hotelOptions, dataPagination }) => {
    return (
      <div className="exon-search-results-options exon-hotel-search-results-options">
        <Template.hotelOptions
          hotelOptions={hotelOptions}
          dataPagination={dataPagination}
        />

        <div className="exon-air-search-results-options-footer">
          <Pagination
            itemsQuantity={hotelOptions.items.length}
            itemsPerPage={dataPagination.itemsPerPage}
            onPageClick={dataPagination.onPageClick}
            currentPage={dataPagination.currentPage}
          />
        </div>
      </div>
    );
  },

  hotelOptions: ({ hotelOptions, dataPagination }) => {
    let items = hotelOptions.items;

    let begin =
      dataPagination.currentPage > 1
        ? dataPagination.itemsPerPage * (dataPagination.currentPage - 1)
        : 0;
    let end =
      dataPagination.currentPage > 1
        ? begin + dataPagination.itemsPerPage
        : dataPagination.itemsPerPage;

    return (
      <div className="exon-air-search-results-options-body">
        {items.slice(begin, end).map((item, index) => {
          let hotel = item.hotel;
          let fare = item.fare;

          return (
            <HotelOption
              key={index}
              hotel={{
                images: [], //hotel.images,
                name: hotel.name,
                evaluation: hotel.evaluation,
                services: hotel.services,
                localization: hotel.localization,
                description: hotel.description,
              }}
              fareData={{
                totalDaily: {
                  currencySymbol: fare.totalDaily.currencySymbol,
                  value: fare.totalDaily.value,
                },
                dailyFare: fare.dailyFare,
                dailyTaxes: fare.dailyTaxes,
                total: fare.total,
              }}
              actions={{
                details: null,
                hotel: null,
              }}
            />
          );
        })}
      </div>
    );
  },
};

export default Template;
