import { create } from 'zustand';

interface ShoppingCartState {
  counterAir: number;
  counterHotel: number;
  setCounterAir: (count: number) => void;
  setCounterHotel: (count: number) => void;
  displayShoppingCart: boolean;
  setDisplayShoppingCart: (display: boolean) => void;
  onShowShoppingCartClick: () => void;
}

const useShoppingCartStore = create<ShoppingCartState>((set) => ({
  counterAir: 0,
  counterHotel: 0,
  setCounterAir: (count) => set({ counterAir: count }),
  setCounterHotel: (count) => set({ counterHotel: count }),
  displayShoppingCart: false,
  setDisplayShoppingCart: (display) => set({ displayShoppingCart: display }),
  onShowShoppingCartClick: () =>
    set((state) => ({ displayShoppingCart: !state.displayShoppingCart })),
}));

export default useShoppingCartStore;
