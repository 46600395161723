import { Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';
import * as StyledRoot from '../styled';

export const ToggleButton = styled(Toggle)`
  input.PrivateSwitchBase-input.MuiSwitch-input {
    opacity: 0;
  }
`;

export const SC = {
  ...StyledRoot,
  ToggleButton,
};
