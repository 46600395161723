'use strict';
import React from 'react';
import Template from './button-group.template.js';

export default class ButtonGroupComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showItems: false
    }
  }

  toggleShowItems = () => {
    this.setState({
      showItems: !this.state.showItems
    });
  }

  render() {
    const templateProps = {
      ...this.props,
      ...this.state,
      toggleShowItems: this.toggleShowItems
    };

    return <Template {...templateProps} />;
  }
}
