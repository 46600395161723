import styled from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

export const PageContainer = styled.div`
  padding: 0px
    ${(props) =>
      `${props.theme.spacings.xs} ${props.theme.spacings.xs} ${props.theme.spacings.xs}`};

  .MuiPopper-root {
    z-index: 999;
  }
`;

export const PageTitle = styled(Typography).attrs({ variant: 'headline6' })`
  color: ${({ theme }) => theme.colors.secondary[10]};
  font-size: 36px;
  padding: 1.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacings.m} 0px;
`;

export const TitleDescription = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs3};
`;

export const ShoppingCartCounterContainer = styled.div`
  margin-right: 15px;
  margin-left: auto;
`;
