import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const PaymentFormsItemContainer = styled.div`
  display: flex;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
  padding: 15px;
  align-items: center;
`;

export const CardInfo = styled.div`
  display: flex;
`;

export const FlashCardInfo = styled.div`
  margin-left: 15px;
`;

export const FlashPaymentBalance = styled.div`
  margin-left: 15px;
  border-left: 1px solid #ebe6e9;
  padding-left: 10px;
`;

export const Text = styled(Typography)`
  color: ${(props) => props.theme.colors.neutral[50]};
  text-align: center;
`;

export const Container = styled.div`
  /* padding: 15px 0; */
`;
