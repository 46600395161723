import styled from 'styled-components';

export const GuaranteeContainer = styled.div`
  margin: 2rem 0;
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: initial;
  gap: 5px;
  margin: 2rem 0;
`;
