const Template = ({ props, events }) => {
  let keyPrefix = `accordion-item-${props.childIndex}-${
    props.opened ? 'opened' : 'closed'
  }`;
  return (
    <div
      key={`${keyPrefix}_container`}
      className={`exon-accordion-item${props.opened ? ' opened' : ''}`}
    >
      <div className="exon-accordion_title" onClick={events.onTitleClicked}>
        {props.title || ''}
      </div>
      <div
        className={`exon-accordion_content ${
          !props.opened ? 'exon-accordion_content-close' : ''
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Template;
