import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

interface ModalFooterDismissButtonProps {
  /**
   * Cancel button custom text or item
   *
   */
  children?: React.ReactNode;

  /**
   * Cancel event
   *
   */
  onCancelClick?(): void;

  /**
   * Close event
   *
   */
  onCloseClick?(): void;
}
export function ModalFooterDismissButton({
  children,
  onCancelClick,
  onCloseClick,
}: ModalFooterDismissButtonProps) {
  return (
    <>
      <div>
        <LinkButton variant="default" onClick={onCancelClick || onCloseClick}>
          {children}
        </LinkButton>
      </div>
    </>
  );
}
