import React from 'react';
import Link from '../../../common/link/link.component.js';
import { Icons, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

const RadiusInfo = React.forwardRef((props, ref) => {
  return <span {...props} ref={ref}>
    <Icons {...props} name="IconInfoCircle" fill="transparent" size={18}/>
  </span>
})

const Template = (props) => {
  const NO_ADDRESS = 'Endereço não informado';
  let address = '';

  if (props.completeAddress)
    address = `${props.address.street ? props.address.street + ', ' : ''}${
      props.address.number ? props.address.number + ' - ' : ''
    }${props.address.district ? props.address.district + ', ' : ''}${
      props.address.city ? props.address.city + ' ' : ''
    }${props.address.state ? ' - ' + props.address.state + ', ' : ''}${
      props.address.postalCode ? props.address.postalCode : ''
    }`;
  else
    address = `${props.address.district ? props.address.district + ', ' : ''}${
      props.address.city ? props.address.city + ' ' : ''
    }${props.address.state ? ' - ' + props.address.state : ''}`;

  if (!address) address = NO_ADDRESS;

  return (
    <div className="exon-hotel-address">
      <i className="exon-icon exon-icon-pin-unfilled_enabled" />

      {props.showMap && props.address.geoLocation && address && (
        <Link
          description={address}
          href={
            props.address.geoLocation
              ? `https://www.google.com/maps/search/?api=1&query=${props.address.geoLocation.latitude},${props.address.geoLocation.longitude}`
              : null
          }
          newTab
        />
      )}

      {(!props.showMap || !props.address.geoLocation) && address && (
        <p title={address} className={"exon-address-line " + (address === NO_ADDRESS ? 'exon-text-disabled' : '')}>
          {address}
        </p>
      )}

      {props?.distanceIsFromCityCenter && <>
        <p className={"exon-address-distance " + (address === NO_ADDRESS ? 'exon-text-disabled' : '')}>
          &nbsp;-&nbsp;{props?.distance || 0} km do centro
        </p>
      </>}

      {!props?.distanceIsFromCityCenter && <>
        <p title={props?.addressLabel} className={"exon-address-distance " + (address === NO_ADDRESS ? 'exon-text-disabled' : '')}>
          &nbsp;-&nbsp;{props?.distance || 0} km de {(props?.addressLabel || '').split('-')[0].trim()}
        </p>
      </>}

      {props.showMap && props.address.geoLocation && (
        <i className="exon-icon exon-icon-link-unfilled_enabled" />
      )}

      {(!!props?.distanceTooltip) && <span className="exon-distance-tooltip">
        <Tooltip title={props.distanceTooltip} placement="top">
          <RadiusInfo />
        </Tooltip>
      </span>}
    </div>
  );
};

export default Template;
