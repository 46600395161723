import Icon from '../../../common/icon/icon.component.js';
import { enums } from '../../../../utils/enums.js';
import { hotelDetails } from '../../../../utils/resolvers/hotelDetailsResolver .js';

var Template = {
  main: (props) => {
    let amenitiesHeader = [];
    let amenitiesBody = [];

    props.amenities &&
      props.amenities.length &&
      props.amenities.forEach((amenitie) => {
        if (
          !amenitiesHeader.find(
            (amenitieHeader) => amenitieHeader.type == amenitie.type,
          ) &&
          amenitiesHeader.length <= 4
        )
          amenitiesHeader.push(amenitie);

        if (amenitiesBody.length) {
          !amenitiesBody.find(
            (item) => item.title == hotelDetails.getAmenitie(amenitie.type),
          )
            ? amenitiesBody.push({
                title: hotelDetails.getAmenitie(amenitie.type),
                items: props.amenities.filter(
                  (item) => item.type == amenitie.type,
                ),
              })
            : null;
        } else {
          amenitiesBody.push({
            title: hotelDetails.getAmenitie(amenitie.type),
            items: props.amenities.filter((item) => item.type == amenitie.type),
          });
        }
      });

    let sliceCountEnd =
      amenitiesBody && amenitiesBody.length > 1 ? amenitiesBody.length / 2 : 1;

    if (sliceCountEnd % 2 !== 0 && sliceCountEnd > 1 && amenitiesBody)
      sliceCountEnd = (amenitiesBody.length + 1) / 2;

    return (
      <div className="exon-hotel-details-amenities">
        <div className="exon-hotel-details-amenities-header">
          {amenitiesHeader &&
            amenitiesHeader.length > 0 &&
            amenitiesHeader.map((amenitie, index) => {
              return (
                <Icon
                  key={index}
                  className="exon-hotel-details-amenities-icon"
                  icon={`exon-icon-${
                    enums.hotelAmenitiesType[amenitie.type]
                  }-unfilled_disabled`}
                  description={hotelDetails.getAmenitie(amenitie.type)}
                  descriptionDown
                />
              );
            })}

          {(!amenitiesHeader || amenitiesHeader.length == 0) && (
            <Icon
              className={'exon-hotel-details-amenities-icon exon-text-disabled'}
              icon={`exon-icon-noData-unfilled_disabled`}
              description={'Nenhuma informação encontrada'}
              descriptionDown
            />
          )}
        </div>

        {amenitiesHeader && amenitiesHeader.length > 0 && <hr />}

        <div className="exon-hotel-details-amenities-body">
          <div
            className={`col-flex col-flex-${
              props.amenities && props.amenities.length > 1 ? 6 : 12
            }`}
          >
            {amenitiesBody &&
              amenitiesBody.length > 0 &&
              amenitiesBody
                .slice(0, sliceCountEnd)
                .map((amenitie, index) => (
                  <Template.amenitieList
                    key={index}
                    title={amenitie.title}
                    amenitieItems={amenitie.items}
                  />
                ))}
          </div>

          {amenitiesBody && amenitiesBody.length > 1 && (
            <div className="col-flex col-flex-6">
              {amenitiesBody.slice(sliceCountEnd).map((amenitie, index) => (
                <Template.amenitieList
                  key={index}
                  title={amenitie.title}
                  amenitieItems={amenitie.items}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  },

  amenitieList: ({ title, amenitieItems }) => {
    return (
      <div>
        <h6>{title}</h6>

        {amenitieItems.map((item, index) => {
          return <p key={index}>{item.description}</p>;
        })}
      </div>
    );
  },
};

export default Template;
