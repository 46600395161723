'use strict';

import React from 'react';
import { componentUtil } from '../../../utils/componentUtil.js';
import Template from './carroussel.template.js';

export default class CarrousselComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      moveClass: '',
      carrousselItems: [...this.props.images],
    };
  }

  render() {
    return (
      <Template
        props={this.props}
        carrousselItems={this.state.carrousselItems}
        moveClass={this.state.moveClass}
        events={this.events}
        handleAnimationEnd={this.handleAnimationEnd}
      />
    );
  }

  events = {
    onSetMoveClassClick: (moveClass) => this.setState({ moveClass }),
  };

  shiftPrev = (copy) => {
    let lastcard = copy.pop();
    copy.splice(0, 0, lastcard);
    this.setState({ carrousselItems: copy });
  };

  shiftNext = (copy) => {
    let firstcard = copy.shift();
    copy.splice(copy.length, 0, firstcard);
    this.setState({ carrousselItems: copy });
  };

  handleAnimationEnd = () => {
    if (this.state.moveClass === 'prev')
      this.shiftNext([...this.state.carrousselItems]);
    if (this.state.moveClass === 'next')
      this.shiftPrev([...this.state.carrousselItems]);
    this.setState({ moveClass: '' });
  };
}
