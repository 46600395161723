import TextBox from '../../textbox/textbox.component.js';
import { enums } from '@legacy-utils/enums';

const CreditCardTemplate = {
  main: ({
    data,
    showBrandCodeChoice,
    validations,
    forceValidation,
    events,
  }) => {
    return (
      <div className="exon-credit-card">
        <div className="exon-field">
          <label className="exon-label-field">Nome do titular</label>
          <TextBox
            placeholder={'Nome completo'}
            autocomplete={'cc-name'}
            name={'ccname'}
            onChange={events.onCardHolderChanged}
            value={data.holder}
            required={true}
            customValidation={validations.validateHolderName}
            forceValidation={forceValidation}
          />
        </div>
        <div className="exon-field">
          <label className="exon-label-field">Numero do cartão</label>
          <div className="cc-number-container">
            <div className="cc-number">
              <TextBox
                placeholder={'Numero do cartão'}
                autocomplete={'cc-number'}
                name={'cardnumber'}
                type={enums.components.textBoxType.mask}
                mask={'9999.9999.9999.9999'}
                onChange={events.onCardNumberChanged}
                value={data.number}
                required={true}
                customValidation={validations.validateCardNumber}
                forceValidation={forceValidation}
              />
            </div>
            <div
              className={
                'cc-brand-code' +
                (data.brandCode ? ' exon-cc-brand-' + data.brandCode : '')
              }
              onClick={events.onBrandCodeClicked}
            ></div>
            {showBrandCodeChoice && (
              <CreditCardTemplate.brandCodeChoice events={events} />
            )}
          </div>
        </div>
        <div className="exon-box-fields-row">
          <div className="exon-field-item">
            <div className="exon-field">
              <label className="exon-label-field">CVC</label>
              <TextBox
                placeholder={'CVC'}
                autocomplete={'cc-csc'}
                name={'cvc'}
                onChange={events.onCvcChanged}
                value={data.cvc}
                required={true}
                customValidation={validations.validateCvv}
                forceValidation={forceValidation}
              />
            </div>
          </div>
          <div className="exon-field-item">
            <div className="exon-field">
              <label className="exon-label-field">Data de Validade</label>
              <TextBox
                placeholder={'MM/YY'}
                removeMask={false}
                autocomplete={'cc-exp'}
                name={'exp-date'}
                type={enums.components.textBoxType.mask}
                mask={'99/99'}
                onChange={events.onExpirationDateChanged}
                value={data.expirationDate}
                required={true}
                customValidation={validations.validateExpirationDate}
                forceValidation={forceValidation}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },

  brandCodeChoice: ({ events }) => (
    <div className="exon-credit-card-brand-code-choice">
      <div
        className="exon-overlay exon-outside-options-background-mask"
        onClick={events.onCancelBrandCodeSelectionClicked}
      ></div>
      <ul className="exon-credit-card-brand-code-choice__list">
        <CreditCardTemplate.brandCodeItem
          brandCode={enums.creditCardBrandCodes.AX}
          events={events}
        />
        <CreditCardTemplate.brandCodeItem
          brandCode={enums.creditCardBrandCodes.DI}
          events={events}
        />
        <CreditCardTemplate.brandCodeItem
          brandCode={enums.creditCardBrandCodes.EL}
          events={events}
        />
        <CreditCardTemplate.brandCodeItem
          brandCode={enums.creditCardBrandCodes.HC}
          events={events}
        />
        <CreditCardTemplate.brandCodeItem
          brandCode={enums.creditCardBrandCodes.JCB}
          events={events}
        />
        <CreditCardTemplate.brandCodeItem
          brandCode={enums.creditCardBrandCodes.MC}
          events={events}
        />
        <CreditCardTemplate.brandCodeItem
          brandCode={enums.creditCardBrandCodes.VI}
          events={events}
        />
      </ul>
    </div>
  ),

  brandCodeItem: ({ brandCode, events }) => (
    <li
      className={
        'exon-credit-card-brand-code-choice__list-item cc-brand-code exon-cc-brand-' +
        brandCode
      }
      onClick={() => events.onBrandCodeItemClicked(brandCode)}
    ></li>
  ),
};

export default CreditCardTemplate;
