import { Typography, TextArea } from '@flash-tecnologia/hros-web-ui-v2';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import * as SC from '../styled';

interface TravelReasonProps {
  disabled?: boolean;
  title: string;
}

export function AdditionalComments({ disabled, title }: TravelReasonProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <SC.ItemFormContainer>
      <div style={{ marginBottom: '20px' }}>
        <Typography variant={'headline8'} variantColor={'#3B2E37'}>
          {title}
        </Typography>
      </div>
      <div>
        <Controller
          control={control}
          name="vehicle.comments"
          render={({ field: { onChange, value, ...field } }) => (
            <div>
              <TextArea
                onChange={onChange}
                value={value}
                {...(field as any)}
                placeholder={
                  'Informe qual o modelo do veiculo de sua preferência e também qualquer outra informação adicional'
                }
                errorMessage={
                  errors.additionalComments ? 'Campo obrigatório' : ''
                }
                disabled={disabled}
              />
            </div>
          )}
        />
      </div>
    </SC.ItemFormContainer>
  );
}
