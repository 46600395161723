export const statusFromCodes = {
  opened: 'Aberto',
  inQuotation: 'Cotacao',
  inOption: 'Opcao',
  optionRefused: 'Recusado',
  toBook: 'Reservar',
  errorBook: 'ErroReserva',
  booked: 'Reservado',
  inAuthorization: 'EmAprovacao',
  reproved: 'Reprovado',
  inIssue: 'Emitir',
  inAutomaticIssue: 'EmissaoAutomatica',
  errorIssue: 'ErroEmissao',
  issued: 'Emitido',
  inCancelation: 'EmCancelamento',
  canceled: 'Cancelado',

  inIntegration: 'EmIntegracao',
  integratedIssue: 'Integrado',
  errorIntegrationIssue: 'ErroIntegracao',
  integratedCancelation: 'IntegradoCancelamento',
};
