'use strict';

import React from 'react';
import Template from './notifier-fields.template.js';

export default class NotifierFieldsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forceValidation: false,
    };
  }

  render() {
    return (
      <Template.main
        fieldsValues={this.props.fieldValues ? this.props.fieldValues : {}}
        notificationType={this.props.notificationType}
        forceValidation={this.state.forceValidation}
        showAddresseeName={this.props.showAddresseeName}
        events={this.props.events}
        placeholder={this.props.placeholder}
      />
    );
  }
}
