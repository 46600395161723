'use strict';

import React from 'react';
import Template from './hotel-option-details.template.js';

export default class HotelDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Template.main hotel={this.props.hotel} actions={this.props.actions} />
    );
  }
}
