import { enums } from '@legacy-utils/enums';

let RequestStatusBarTemplate = ({ statusInfos }) => {
  if (statusInfos) {
    return (
      <div className="exon-request-status-bar">
        <h6>{`RV - ${statusInfos.requestNumber}`}</h6>

        <p>
          {'Status: ' +
            (statusInfos.status && statusInfos.status.name
              ? statusInfos.status.name
              : 'Status não informado')}
        </p>

        <p>{`Tipo: ${enums.travelType[statusInfos.travelType]}`}</p>

        <p>
          {'Avaliação: ' +
            (statusInfos.evaluation
              ? 'Dentro da política'
              : 'Fora da Política')}
        </p>

        {statusInfos.dataOrigin == enums.requestDataOrigin.manual && (
          <p className="highlight">Via Formulário</p>
        )}

        {statusInfos.dataOrigin == enums.requestDataOrigin.externalBooking && (
          <p className="highlight">External</p>
        )}
      </div>
    );
  } else {
    return (
      <p>Ocorreu um erro, verifique as informações fornecias ao componente.</p>
    );
  }
};

export default RequestStatusBarTemplate;
