import { Dropzone, PDFViewer } from '@flash-tecnologia/hros-web-ui-v2';
import {
  DropzoneFile,
  DropzoneInterface,
} from '@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { PolicyRegisterForm } from '../../../types';

export const PolicyFileSelector = () => {
  const policyForm = useFormContext<PolicyRegisterForm>();

  const { field } = useController({
    control: policyForm.control,
    name: 'document',
  });

  const document = useWatch({
    control: policyForm.control,
    name: 'document',
  });

  const filePreview = useMemo(() => {
    if (document?.file) {
      return URL.createObjectURL(document.file);
    }

    return document?.url || null;
  }, [document]);

  const dropzoneProps: Pick<DropzoneInterface, 'accept' | 'title'> = {
    title: 'Política',
    accept: ['pdf'],
  };

  return (
    <>
      {!!document?.name && (
        <Dropzone
          onRemove={() => field.onChange(null)}
          initialFiles={[
            {
              id: document.name,
              progress: 100,
              loaded: true,
              error: false,
              mime: 'application/pdf',
              file: new File([], document.name),
            } as DropzoneFile,
          ]}
          {...dropzoneProps}
        />
      )}

      {!document?.name && (
        <Dropzone
          title="Política"
          onChange={([result]) => {
            if (!result?.file) return field.onChange(null);

            return field.onChange({
              name: result.file.name,
              file: result.file,
              url: null,
            });
          }}
          accept={['pdf']}
          {...dropzoneProps}
        />
      )}

      {filePreview && (
        <Box mt={3}>
          <PDFViewer
            src={filePreview}
            options={{ fit: 'auto', showToolbar: true }}
            style={{ width: '100%', height: 600 }}
          />
        </Box>
      )}
    </>
  );
};
