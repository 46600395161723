import { enums } from '@legacy-utils/enums';

let ProductFareTemplate = ({ productFare, actions, productType }) => {
  return (
    <div className="exon-box-fare-product">
      {productFare && productType == enums.productType.air && (
        <div className="exon-value-details">
          <div className="exon-box-value">
            <span className="exon-value-currency">R$</span>
            <span className="exon-value">1.440</span>
          </div>
          <div className="exon-descriptions-values">
            <div className="exon-description">
              <p>Tarifas</p>
              <p>Taxas</p>
            </div>
            <div className="exon-values">
              <p>R$ 1.246</p>
              <p>R$ 69</p>
            </div>
          </div>

          {actions && <div className="exon-product-actions">{actions}</div>}
        </div>
      )}
    </div>
  );
};

export default ProductFareTemplate;
