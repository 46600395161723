import {
  TextArea,
  Button,
  SelectField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getTravelJustifications } from '@api/connectOn/connectOn.service';
import React, { useEffect, useState } from 'react';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { dispatchToast } from '@shared/toast';

interface PolicyViolationAlertModalProps {
  policyViolationAlert: any;
  onSubmitJustification: (data: any) => void;
  contextId: any;
}

const schemaJustification = Yup.object().shape({
  justificationDetails: Yup.string().required(
    'O preenchimento dos detalhes da justificativa é obrigatório',
  ),
  justificationOption: Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }).required('A seleção da justificativa é obrigatória'),
});

export default function PolicyViolationAlert({
  policyViolationAlert,
  onSubmitJustification,
  contextId,
}: PolicyViolationAlertModalProps) {
  const [justifications, setJustifications] = useState<any[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaJustification),
  });

  useEffect(() => {
    const fetchJustifications = async () => {
      try {
        const data = await getTravelJustifications({
          productType: '2',
          contextId: contextId,
        });

        const options = data.justifications.map((justification) => ({
          value: justification.code,
          label: justification.name,
          id: justification.id,
        }));

        setJustifications(options);
      } catch (error) {
        dispatchToast({
          type: 'error',
          content: 'Houve um erro ao coletar as justificativas cadastradas.',
        });
      }
    };

    fetchJustifications();
  }, [contextId]);

  if (!policyViolationAlert) {
    return null;
  }

  const violation = policyViolationAlert.violations[0];

  return (
    <div>
      {violation.actionType === 3 ? (
        <>
          <Controller
            control={control}
            name="justificationOption"
            render={({ field: { onChange } }) => (
              <SelectField
                label={'Selecione a justificativa'}
                onSelectChange={(event, selectedItem) => {
                  if (selectedItem) {
                    onChange(selectedItem);
                  }
                }}
                options={justifications}
                error={!!errors.justificationOption}
                helperText={
                  <DisplayErrorFormValidation
                    errors={errors}
                    fieldName="justificationOption"
                  />
                }
              />
            )}
          />
          <Controller
            control={control}
            name="justificationDetails"
            render={({ field: { onChange, value, ...field } }) => (
              <div>
                <TextArea
                  onChange={onChange}
                  value={value}
                  {...(field as any)}
                  placeholder={'Dê mais detalhes sobre a sua solicitação'}
                  errorMessage={!!errors.justificationDetails}
                />
              </div>
            )}
          />
        </>
      ) : (
        <ul>
          {violation.violatedRules.map((rule) => (
            <li key={rule.ruleId}>{rule.message.join(', ')}</li>
          ))}
        </ul>
      )}
      <Button
        size={'large'}
        variant={'primary'}
        onClick={handleSubmit(onSubmitJustification)}
      >
        {violation.actionType === 3 ? 'Continuar' : 'OK'}
      </Button>
    </div>
  );
}
