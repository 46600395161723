import styled from 'styled-components';

interface MultipleProductContainerProps {
  showBadge?: boolean;
}

export const StyledLegacyProductPayment = styled.div<MultipleProductContainerProps>`
  display: flex;
  ${({ showBadge }) => (showBadge ? 'padding: 0 2rem;' : '')}
  gap: 4rem;
`;

export const Container = styled.div<MultipleProductContainerProps>`
  div.list > div:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
  ${({ showBadge }) => (showBadge ? 'padding: 2rem 1rem;' : '')}
  width: 100%;
  margin-bottom: 5px;
`;
