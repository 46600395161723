import React from 'react';
import { enums } from '../../utils/enums.js';
import { utils } from '../../utils/utils.js';
import TextboxTemplate from './textbox.template.js';

export default class TextboxComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      validationResult: {
        isValid: true,
        message: props.validationMessage || '',
      },
      propsMemory: {},
      value: props.value,
    };
  }

  removeMask(str) {
    return str.replace(/[^0-9]/g, '');
  }
  onBlured(event) {
    let value = event.target.value;
    value = this.resolveValue(value);

    this.validate(value);
  }
  onChanged(event) {
    let value = event.target.value;
    value = this.resolveValue(value);

    if (this.props.validateOnChange) this.validate(value);

    if (this.props.onChange) this.props.onChange(value);
  }

  resolveValue(value) {
    var value = event.target.value;
    switch (this.props.type) {
      case enums.components.textBoxType.cnpj:
      case enums.components.textBoxType.cpf:
      case enums.components.textBoxType.mask:
      case enums.components.textBoxType.phone:
      case enums.components.textBoxType.phoneGlobal:
      case enums.components.textBoxType.card:
      case enums.components.textBoxType.cep:
        if (this.props.removeMask !== false) value = this.removeMask(value);
        break;
      case enums.components.textBoxType.number:
        if (
          this.props.maxLength &&
          event.target.value > event.target.maxLength
        ) {
          value = event.target.maxLength;
          alert(
            `Valor digitado deve ser menor ou igual a ${event.target.maxLength}`,
          );
        }
        break;
    }

    return value;
  }

  onShowPasswordClicked(value) {
    this.setState({ showPassword: !value });
  }

  static getDerivedStateFromProps(props, state) {
    let value =
      props.value != state.propsMemory.value ? props.value : state.value;

    return {
      propsMemory: {
        ...state.propsMemory,
        value,
      },
      validationResult: {
        isValid:
          props.isValidExternal === undefined
            ? state.validationResult.isValid
            : props.isValidExternal,
        message:
          props.validationMessage === undefined
            ? state.validationResult.message
            : props.validationMessage,
      },
      value,
    };
  }

  render() {
    return (
      <TextboxTemplate.main
        value={this.state.value}
        placeholder={this.props.placeholder}
        name={this.props.name}
        autoComplete={this.props.autoComplete}
        mask={this.props.mask}
        type={this.props.type}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        validationResult={this.state.validationResult}
        maxLength={this.props.maxLength || null}
        min={this.props.min || null}
        max={this.props.max || null}
        rows={this.props.rows}
        cols={this.props.cols}
        showPassword={this.state.showPassword}
        onShowPasswordClick={this.onShowPasswordClicked.bind(this)}
        onChange={this.onChanged.bind(this)}
        onBlur={this.onBlured.bind(this)}
      />
    );
  }

  componentDidMount() {
    if (this.props.validateOnChange && this.state.value)
      this.validate(this.state.value);
  }

  componentDidUpdate() {
    if (
      this.state.propsMemory.forceValidation != this.props.forceValidation &&
      this.props.forceValidation &&
      this.state.validationResult.isValid
    ) {
      this.setState({
        propsMemory: {
          ...this.state.propsMemory,
          forceValidation: this.props.forceValidation,
        },
      });

      this.validate(this.state.value);
    }
  }

  validate(value) {
    let validationResult = this.getValidationResult(value);

    if (this.props.customValidation && validationResult.isValid)
      validationResult = this.props.customValidation(value);

    this.setState({
      validationResult,
    });

    this.props.onValidate && this.props.onValidate(validationResult);
  }

  getValidationResult(value) {
    let validationResult = {
      isValid: true,
      message: '',
    };

    if (this.props.type == enums.components.textBoxType.number && value === 0)
      value = '0';

    if (this.props.required && (!value || !value.toString().trim())) {
      validationResult.isValid = false;
      validationResult.message = 'Campo obrigatório';

      return validationResult;
    }

    if (this.props.ignoreValidation) {
      validationResult.isValid = true;
      validationResult.message = '';

      return validationResult;
    }

    switch (this.props.type) {
      //case enums.components.textBoxType.mask:
      case enums.components.textBoxType.cpf:
        let cpf = value;
        if (cpf && !utils.document.validateCpf(cpf)) {
          validationResult.isValid = false;
          validationResult.message = 'Cpf inválido';
        }
        break;
      case enums.components.textBoxType.cnpj:
        let cnpj = value;
        if (cnpj && !utils.document.validateCnpj(cnpj)) {
          validationResult.isValid = false;
          validationResult.message = 'Cnpj inválido';
        }
        break;
      case enums.components.textBoxType.cep:
        let cep = value;

        var regexCep = /^[0-9]{8}$/;
        if (cep && !regexCep.test(cep)) {
          validationResult.isValid = false;
          validationResult.message = 'Cep inválido';
        }
        break;
      case enums.components.textBoxType.phone:
        let phone = value;
        var regexPhone =
          /^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$/;
        if (phone && !regexPhone.test(phone)) {
          validationResult.isValid = false;
          validationResult.message = 'Telefone inválido';
        }
        break;
      case enums.components.textBoxType.phoneGlobal:
        let phoneGlobal = value;
        var regexPhoneGlobal =
          /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
        if (phoneGlobal && !regexPhoneGlobal.test(phoneGlobal)) {
          validationResult.isValid = false;
          validationResult.message = 'Telefone inválido';
        }
        break;
      case enums.components.textBoxType.date:
        let date = value;
        if (date && date.length == 10) {
          let [day, month, year] = date.split('/');
          let parsedDate = Date.parse(year + '-' + month + '-' + day);
          if (
            !parsedDate ||
            isNaN(parsedDate) ||
            parsedDate < new Date(1900, 1, 1)
          ) {
            validationResult.isValid = false;
            validationResult.message = 'Data inválida';
          }
        }
        break;
      case enums.components.textBoxType.email:
        let email = value;
        let regexEmail =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email && !regexEmail.test(email)) {
          validationResult.isValid = false;
          validationResult.message = 'Email inválido';
        }
        break;
      case enums.components.textBoxType.number:
        if (
          this.props.zeroIsValid !== undefined &&
          this.props.zeroIsValid !== null &&
          this.props.zeroIsValid === false
        ) {
          if (value == 0) {
            validationResult.isValid = false;
            validationResult.message = 'Número inválido';
          }
        }
        break;
    }

    return validationResult;
  }
}
