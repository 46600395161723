import { BigNumberItem } from '../sections/BigNumbers';
import * as SC from '../styled';
export function useBenefitsNumbers(): BigNumberItem[] {
  return [
    {
      number: '-25%',
      text: (
        <div>
          com <SC.HighlightedText>custos totais</SC.HighlightedText> de viagens
        </div>
      ),
    },
    {
      number: '+ 16mil',
      text: (
        <div>
          <SC.HighlightedText>reservas</SC.HighlightedText> realizadas na
          plataforma
        </div>
      ),
    },
    {
      number: '+ 3mil',
      text: (
        <div>
          <SC.HighlightedText>colaboradores</SC.HighlightedText> que já viajaram
          conosco
        </div>
      ),
    },
    {
      number: '+ 2mil',
      text: (
        <div>
          empresas que <SC.HighlightedText>confiam</SC.HighlightedText> na Flash
        </div>
      ),
    },
  ];
}
