import React from 'react';
import Template from './air-trip-replacement.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class AirTripReplacementComponent extends React.Component {
  constructor(props) {
    super(props);

    componentUtil.react.bindMethods(this.stepsControl, this);

    this.state = {
      stepsControl: {
        disableNextButton: false,
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          stepsControl={this.stepsControl.getOptions()}
          airOptionSelection={this.airOptionSelection.getOptions()}
        />
      </ErrorBoundary>
    );
  }

  stepsControl = {
    getOptions: () => {
      const _this = this;
      return {
        props: this.state.stepsControl,
        events: {
          onCancelClicked: () => {
            _this.props.onCloseFlowClick && _this.props.onCloseFlowClick();
          },
          onFinishClicked: () => {
            _this.props.onCloseFlowClick && _this.props.onCloseFlowClick();
          },
        },
      };
    },
  };

  airOptionSelection = {
    getOptions: () => {
      return {
        props: {
          airTrips: this.props.airTrips,
        },
        events: {
          onAirTripCheckboxChanged: (event, index) => {},
        },
      };
    },
  };
}
