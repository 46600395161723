import { ModalRoot } from './ModalRoot';
import { ModalBody } from './ModalBody';
import { ModalHeader } from './ModalHeader';
import { ModalFooter } from './ModalFooter';

export const Modal = {
  Root: ModalRoot,
  Body: ModalBody,
  Header: ModalHeader,
  Footer: ModalFooter,
};
