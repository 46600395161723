import { CircularProgress } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styled';

export const Loading = () => {
  return (
    <SC.LoaderContainer>
      <CircularProgress size={50} variant="indeterminate" />
    </SC.LoaderContainer>
  );
};
