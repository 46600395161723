'use strict';

import React from 'react';
import Template from './template-preview.template.js';

export default class TemplatePreviewComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Template.main innerHtml={this.props.content} />;
  }
}
