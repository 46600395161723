import * as SC from './styled';
import { Toggle, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { memo, useState } from 'react';
import PaymentSelector from '@legacy-components/common/payment-selector/payment-selector.component.js';
import { parseToPaymentOption } from '../parsers';
import { enums } from '../../utils/enums';
import { GuaranteeOptions } from '../models';

export interface HotelGuaranteeProps {
  hotelHasGuarantee: boolean;
  guaranteeOptions: GuaranteeOptions;
  showValidationsFields: boolean;
  onToggle?: (checked: boolean) => void;
}

export default memo(function HotelGuarantee({
  hotelHasGuarantee,
  guaranteeOptions,
  showValidationsFields,
  onToggle,
}: HotelGuaranteeProps) {
  const [showHotelGuarantee, setShowHotelGuarantee] = useState(false);
  const [selectorItem, setSelectorItem] = useState(null);
  const toggleGuarantee = () => {
    onToggle && onToggle(!showHotelGuarantee);
    setShowHotelGuarantee(!showHotelGuarantee);
  };
  const onSelectCard = (paymentData) => {
    setSelectorItem(paymentData);
    const data = parseToPaymentOption(
      paymentData.paymentForm,
      enums.products.type.hotel,
    );

    guaranteeOptions.onSelected(data);
  };
  if (hotelHasGuarantee)
    return (
      <>
        <SC.ToggleContainer>
          <Toggle onChange={toggleGuarantee} />
          <Typography variant="body3" color="textSecondary">
            Selecionar outra opção para garantia
          </Typography>
        </SC.ToggleContainer>
        {showHotelGuarantee && (
          <SC.GuaranteeContainer>
            <PaymentSelector
              items={guaranteeOptions.items || []}
              selectedItem={selectorItem}
              required={true}
              forceValidation={showValidationsFields}
              addCard={true}
              products={guaranteeOptions.products}
              supplier={guaranteeOptions.supplier}
              onPaymentSelected={onSelectCard}
              disabled={guaranteeOptions.disabled}
            />
          </SC.GuaranteeContainer>
        )}
      </>
    );
  return <></>;
});
