import { enums } from '@legacy-utils/enums';
import {
  findLocations,
  getMonitoring,
} from '../../api/connectOn/connectOn.service';

import { ILoader } from '@shared/loader';
import {
  dataSources as profileDataSources,
  setLoader as setProfileDSLoader,
} from './profile.dataSources';
import {
  setLoader as setTravelRequestsDSLoader,
  dataSources as travelRequestsDataSources,
} from './travelRequests.dataSources';

let _loader: ILoader;
export const setLoader = (loader: ILoader) => {
  _loader = loader;

  setProfileDSLoader(loader);
  setTravelRequestsDSLoader(loader);
};
const toggleModalLoading = (show: boolean) => {
  _loader.toggleLoading(show);
};

export const dataSources = {
  ...travelRequestsDataSources,
  ...profileDataSources,
  monitoring: {
    locations: {
      load: (loadOptions) => {
        const request = {
          keyword: loadOptions.keywords,
          types: [
            enums.locationType.city,
            enums.locationType.airport,
            enums.locationType.country,
          ],
          scopes: [2],
        };
        return findLocations(request);
      },
      events: {},
    },
    monitoring: {
      load: (request) => {
        return getMonitoring(request.filter);
      },
      events: {
        beforeLoad: function (loadOptions) {
          toggleModalLoading(true);
        },
        afterLoad: function (items) {
          toggleModalLoading(false);
        },
      },
    },
    serviceRequest: {
      sendNotify: travelRequestsDataSources.serviceRequest.sendNotify,
      previewNotify:
        travelRequestsDataSources.serviceRequest.previewNotification,

      events: {
        beforeSendNotify: () => {
          toggleModalLoading(true);
        },
        afterSendNotify: () => {
          toggleModalLoading(false);
        },
        beforePreviewNotify: () => {
          toggleModalLoading(true);
        },
        afterPreviewNotify: () => {
          toggleModalLoading(false);
        },
      },
    },
    serviceRequestItem: {
      sendNotifyItem:
        travelRequestsDataSources.serviceRequestItem.sendNotifyItem,

      previewNotifyItem:
        travelRequestsDataSources.serviceRequestItem.previewNotification,

      events: {
        beforeSendNotifyItem: () => {
          toggleModalLoading(true);
        },
        afterSendNotifyItem: () => {
          toggleModalLoading(false);
        },
        beforePreviewNotifyItem: () => {
          toggleModalLoading(true);
        },
        afterPreviewNotifyItem: () => {
          toggleModalLoading(false);
        },
      },
    },
    companies: profileDataSources.companies,
  },
};
