import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';
import * as SCBase from '../../styled';

export default {
  ...SCBase,
  Title: styled(Typography).attrs({
    variant: 'headline8',
    weight: 700,
  })`
    color: ${({ theme }) => theme.colors.neutral[30]};
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: inherit;
    white-space: nowrap;
  `,
  Name: styled(Typography).attrs({
    variant: 'body3',
    weight: 600,
  })`
    color: ${({ theme }) => theme.colors.neutral[40]};
    font-size: 14px;
  `,
  TitleContainer: styled(SCBase.FlexRow)`
    flex-wrap: nowrap !important;
  `,
};
