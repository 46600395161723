'use strict';

import React from 'react';
import { componentUtil } from '../../../../../utils/componentUtil.js';
import Template from './hotel-room-list-groups.template.js';

export default class HotelRoomListGroupsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      show: false,
      indexRoomGroupSelected: 0,
      roomsDisplayed: props.searchParameters.rooms.map((room) => 3),
    };
  }

  render() {
    return (
      <Template
        {...this.props}
        {...this.state}
        events={this.events}
        onAccordionClick={this.events.onAccordionClick}
      />
    );
  }

  events = {
    onClick: () => this.setState({ show: !this.state.show }),

    onSelectRoomClick: (roomIndex, indexRoom) => {
      this.setState({ indexRoomGroupSelected: indexRoom + 1 });
      this.props.onSelectRoomClick &&
        this.props.onSelectRoomClick(roomIndex, indexRoom);
    },

    onMoreRoomsClick: (indexRoomGroup) => {
      let roomsDisplayed = this.state.roomsDisplayed;
      roomsDisplayed[indexRoomGroup] = roomsDisplayed[indexRoomGroup] + 3;

      this.setState({ roomsDisplayed });
    },

    onAccordionClick: (indexRoom) => {
      this.setState({
        indexRoomGroupSelected:
          indexRoom == this.state.indexRoomGroupSelected ? null : indexRoom,
      });
    },
  };
}
