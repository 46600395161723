import Pagination from '../../pagination/pagination.componente.js';
import { utils } from '@legacy-utils/utils';

let Template = {
  main: ({
    historyList,
    loading,
    dataPagination,
    onPageClick,
    actionEvents,
    access,
  }) => {
    return (
      <div>
        {loading && (
          <div className="exon-box-air-loading">
            <span
              className={'exon-icon exon-icon-loading-plane-unfilled_enabled'}
            ></span>
            <p>
              Por favor aguarde!
              <br />
              Estamos processando sua solicitação...
            </p>
          </div>
        )}
        {!loading && (
          <div className="exon-request-history-logs-content">
            <div className="exon-request-history-logs-table">
              {historyList && !loading && dataPagination.totalResults > 0 && (
                <div className="box-styleless-table">
                  <table className="exon-styleless-table table">
                    <thead>
                      <tr>
                        <th>Data/Hora</th>
                        <th>Usuário</th>
                        <th>Descrição</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {
                                utils.formatters.date.getFormattedDateObjFromDate(
                                  new Date(item.ServiceRequestHistoryDateTime),
                                ).dateTimeFormatted
                              }
                            </td>
                            <td>
                              {utils.history.getUserName(
                                item.UserName,
                                item.IsAutomaticFlowAction,
                              )}
                            </td>
                            <td>{item.ServiceRequestHistoryDescription}</td>

                            {access.hasLogAccess && (
                              <td>
                                <span
                                  className="exon-icon exon-icon-add-unfilled_enabled"
                                  onClick={() =>
                                    actionEvents.redirect(
                                      item.CorrelationId,
                                      item.CompanyId,
                                    )
                                  }
                                ></span>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {!loading && dataPagination.totalResults > 0 && (
              <div className="exon-request-history-logs-pagination">
                <Pagination
                  itemsQuantity={dataPagination.totalResults}
                  itemsPerPage={dataPagination.itemsPerPage}
                  onPageClick={onPageClick}
                  currentPage={dataPagination.currentPage}
                />
              </div>
            )}
            {!loading && dataPagination.totalResults == 0 && (
              <div>
                <label>Não há dados de histórico registrados</label>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
};

export default Template;
