import React from 'react';
import { Tab, TabItens, IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import { ApprovalTable } from './ApprovalTable';
import { PolicyTable } from './PolicyTable';

const TravelConfigurationPage: React.FC = () => {
  const onTabChanged = (index: number) => {
    console.log('onTabChanged', index);
  };

  const tabItens: TabItens[] = [
    {
      label: 'Políticas',
      component: <PolicyTable />,
      iconLabel: 'IconUsers' as IconsProps['name'],
    },
    {
      label: 'Aprovações',
      component: <ApprovalTable />,
      iconLabel: 'IconChartBar' as IconsProps['name'],
    },
  ];

  return (
    <Tab tabItens={tabItens} onTabChanged={onTabChanged} variant="secondary" />
  );
};

export default TravelConfigurationPage;
