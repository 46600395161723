'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import TimeCourseTemplate from '../time_course/time-course.template.js';

export default class TimeCourseComponent extends React.Component {
  externalOptions = {
    events: {
      onSelected: null,
    },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.events.periodPresets, this);
    componentUtil.react.bindMethods(this.events.periodCustom, this);
    componentUtil.react.bindMethods(this.events.backgroundMask, this);

    this.state = {
      itensPeriodsPresetsVisibility: false,
      itemPeriodsCustomVisibility: false,
      showBackgroundMask: false,
      itemSelected: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      itemSelected: props.period,
    };
  }

  render() {
    return (
      <TimeCourseTemplate.main
        periods={this.periods}
        itensPeriodsPresetsVisibility={this.state.itensPeriodsPresetsVisibility}
        itemPeriodsCustomVisibility={this.state.itemPeriodsCustomVisibility}
        showBackgroundMask={this.state.showBackgroundMask}
        itemSelected={this.state.itemSelected || this.periods[0]}
        setRange={this.setRange}
        onOpenPresetClicked={this.events.periodPresets.toggle}
        onBackgroundMaskClicked={this.events.backgroundMask.hide}
        onPresetClicked={this.events.setPeriod}
      />
    );
  }

  setPeriodSelected = function (period) {
    this.setState({
      itemSelected: period,
    });
  };

  setRange = (values) => {
    let period = this.state.itemSelected;
    if (period) {
      period.range = {
        start: values.min,
        end: values.max,
      };
      this.setState({ period });
    }
  };

  events = {
    periodPresets: {
      hide: function (ev) {
        let _this = this;
        _this.setState({ itensPeriodsPresetsVisibility: false });
      },
      show: function (ev) {
        let _this = this;
        _this.setState({ itensPeriodsPresetsVisibility: true });
      },
      toggle: function (ev) {
        let _this = this;
        let visibility = _this.state.itensPeriodsPresetsVisibility;
        _this.setState({ itensPeriodsPresetsVisibility: !visibility });
        _this.events.backgroundMask.toggle(!visibility);
      },
    },
    periodCustom: {
      hide: function (ev) {
        this.setState({ itemPeriodsCustomVisibility: false });
      },
      show: function (ev) {
        this.setState({ itemPeriodsCustomVisibility: true });
      },
      toggle: function (ev) {
        let visibility = this.state.itemPeriodsCustomVisibility;
        this.setState({ itemPeriodsCustomVisibility: !visibility });
        this.events.backgroundMask.toggle(!visibility);
      },
    },

    backgroundMask: {
      hide: function (ev) {
        this.setState({ showBackgroundMask: false });
        this.events.periodPresets.hide();
      },
      show: function (ev) {
        this.setState({ showBackgroundMask: true });
      },
      toggle: function (ev) {
        let visibility = this.state.showBackgroundMask;
        this.setState({ showBackgroundMask: !visibility });
      },
    },

    setPeriod: function (ev, periodId) {
      let periodSelected = this.periods.find((p) => p.id == periodId);
      this.setPeriod(periodSelected);
    },
  };

  setPeriod(period, callExternalEvent) {
    if (callExternalEvent == undefined) callExternalEvent = true;

    this.setPeriodSelected(period);

    if (period.id != 5) {
      this.events.periodPresets.hide();
      this.events.backgroundMask.hide();
      this.events.periodCustom.hide();
    } else {
      let visibility = this.state.itemPeriodsCustomVisibility;
      this.setState({ itemPeriodsCustomVisibility: !visibility });
    }

    if (callExternalEvent && this.externalOptions.events.onSelected)
      this.externalOptions.events.onSelected(period);
  }

  periods = [
    {
      id: 1,
      name: 'Todos',
      range: {
        start: {
          hour: 0,
          minute: 1,
        },
        end: {
          hour: 24,
          minute: 0,
        },
      },
    },

    {
      id: 2,
      name: 'Manhã',
      range: {
        start: {
          hour: 6,
          minute: 0,
        },
        end: {
          hour: 12,
          minute: 0,
        },
      },
    },

    {
      id: 3,
      name: 'Tarde',
      range: {
        start: {
          hour: 12,
          minute: 0,
        },
        end: {
          hour: 18,
          minute: 0,
        },
      },
    },

    {
      id: 4,
      name: 'Noite',

      range: {
        start: {
          hour: 18,
          minute: 0,
        },
        end: {
          hour: 24,
          minute: 0,
        },
      },
    },

    {
      id: 5,
      name: 'Personalizado',
      range: {
        start: {
          hour: 10,
          minute: 0,
        },
        end: {
          hour: 16,
          minute: 0,
        },
      },
    },
  ];
}
