'use strict';

import React from 'react';
import Template from './booking-status-bar.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class BookingStatusBarComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <Template statusInfos={this.props.statusInfos} />
      </ErrorBoundary>
    );
  }
}
