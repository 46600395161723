import { useState } from 'react';

import { UserProfileData } from '@shared/types';
import { setLoader } from '@legacy-dataSources/profile.dataSources';

import PageBase from '../../PageBase';
import { Skeleton } from '@mui/material';
import { Loading } from '@components/Loading/Loading';
import { ILoader } from '@shared/loader';
import ReportBookings from '@components/Reports/ReportBookings';

const Page = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const loader: ILoader = {
    toggleLoading(isLoading) {
      setLoading(isLoading);
    },
  };
  setLoader(loader);

  const [userProfile, setUserProfile] = useState<UserProfileData>(null);
  return (
    <PageBase
      pageTitleKey="pages.reports.bookings.title"
      userProfileLoaded={(profileData) => setUserProfile(profileData)}
      skeleton={<Skeleton variant="rectangular" height="100px" width="100%" />}
    >
      {loading && <Loading />}
      {userProfile && <ReportBookings userProfile={userProfile} />}
    </PageBase>
  );
};

export default Page;
