'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import RadioGroupTemplate from './radio-group.template.js';

export default class RadioGroupComponent extends React.Component {
  externalOptions = {
    events: {
      onChanged: null,
    },
    items: null,
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      dataControlledByParent: props.item != undefined,
      value: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      value: state.dataControlledByParent
        ? (props.item && props.item.value) || null
        : state.value,
    };
  }

  render() {
    return (
      <RadioGroupTemplate.main
        items={this.externalOptions.items}
        name={this.props.name}
        selectedValue={this.state.value}
        onChange={this.events.onChanged}
      />
    );
  }

  events = {
    onChanged: function (item) {
      this.setData(item);
    },
  };

  setData = function (item) {
    this.setState({ value: item.value });

    if (this.externalOptions.events.onChanged)
      this.externalOptions.events.onChanged(item);
  };
}
