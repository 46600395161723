import { Button, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

interface ModalFooterActionButtonProps {
  /**
   * if 'true' the button shows loading status
   */
  isLoading?: boolean;
  /**
   * Displays tooltip text on the action button
   *
   */
  tooltipButtonAction?: string;
  /**
   * Allows Display tooltip text on action button
   *
   */
  showTooltipButtonAction?: boolean;
  /**
   * if 'true' loading button appears
   */
  isActionLoading?: boolean;
  /**
   * if 'true' disable button action
   */
  actionDisabled?: boolean;
  /**
   * Confirmation button custom text or item
   *
   */
  children?: React.ReactNode;
  /**
   * Action event
   *
   */
  onActionClick?(): void;
  variant?: 'primary' | 'secondary';
  variantType?: 'default' | 'error';
}
export function ModalFooterActionButton({
  isLoading,
  tooltipButtonAction,
  showTooltipButtonAction,
  isActionLoading,
  actionDisabled,
  children,
  onActionClick,
  variant = 'primary',
  variantType = 'default',
}: ModalFooterActionButtonProps) {
  return (
    <div>
      <Tooltip
        arrow
        title={
          tooltipButtonAction && showTooltipButtonAction
            ? tooltipButtonAction
            : ''
        }
        placement="top-start"
      >
        <div>
          <Button
            size="large"
            variant={variant}
            variantType={variantType}
            loading={isLoading || isActionLoading}
            disabled={actionDisabled}
            onClick={onActionClick}
          >
            {children}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}
