export const search = {
  request: {
    trips: [
      {
        iataOrigin: 'FLN',
        iataDestination: 'CWB',
        departureDate: {
          day: 9,
          month: 6,
          year: 2023,
        },
        cabinType: null,
      },
    ],
    travelers: [],
    directFlight: false,
    baggageIncluded: false,
    groupingType: 1,
    sendPriceMatrix: false,
    travellers: [
      {
        type: 0,
        count: 1,
      },
    ],
  },
  response: {
    groupingType: 1,
    airTrips: [
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T04:45:00',
        arrivalDate: '2023-06-09T09:00:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 255,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 110,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 4,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3307',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 7,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3282',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T04:45:00',
        arrivalDate: '2023-06-09T16:10:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 685,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 540,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 4,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3307',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3286',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T05:35:00',
        arrivalDate: '2023-06-09T10:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 280,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 150,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 5,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3089',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 10,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3060',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T05:35:00',
        arrivalDate: '2023-06-09T11:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 340,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 210,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 5,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3089',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 10,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3062',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T05:35:00',
        arrivalDate: '2023-06-09T16:20:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 645,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 515,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 5,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3089',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3068',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T05:35:00',
        arrivalDate: '2023-06-09T16:55:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 680,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 545,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 5,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3089',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3070',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T08:05:00',
        arrivalDate: '2023-06-09T11:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 190,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 65,
            layover: 65,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 8,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3091',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 10,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3062',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T08:05:00',
        arrivalDate: '2023-06-09T16:20:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 495,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 65,
            layover: 370,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 8,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3091',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3068',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T08:05:00',
        arrivalDate: '2023-06-09T16:55:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 530,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 65,
            layover: 400,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 8,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3091',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3070',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T09:45:00',
        arrivalDate: '2023-06-09T16:10:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 385,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 245,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3301',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3286',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T09:45:00',
        arrivalDate: '2023-06-09T19:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 570,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 430,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3301',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 18,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3288',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T11:15:00',
        arrivalDate: '2023-06-09T16:20:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 305,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 175,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 12,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3083',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3068',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T11:15:00',
        arrivalDate: '2023-06-09T16:55:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 340,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 205,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 12,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3083',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3070',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T11:15:00',
        arrivalDate: '2023-06-09T20:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 540,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 410,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 12,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3083',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 20,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3064',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T11:15:00',
        arrivalDate: '2023-06-09T22:00:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 645,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 515,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 12,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3083',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 22,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 21,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3072',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T14:45:00',
        arrivalDate: '2023-06-09T19:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 270,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 130,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3303',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 18,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3288',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T14:45:00',
        arrivalDate: '2023-06-10T00:25:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 580,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 440,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3303',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 10,
              dayOfWeek: 6,
              hour: 0,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 23,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3290',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T15:40:00',
        arrivalDate: '2023-06-09T20:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 275,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 145,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 40,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3079',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 20,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3064',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T15:40:00',
        arrivalDate: '2023-06-09T22:00:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 380,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 250,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 40,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3079',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 22,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 21,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3072',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T18:15:00',
        arrivalDate: '2023-06-09T22:00:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 225,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 95,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 18,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3973',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 22,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 21,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3072',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T19:25:00',
        arrivalDate: '2023-06-10T00:25:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 300,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 155,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 20,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3305',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 10,
              dayOfWeek: 6,
              hour: 0,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 23,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3290',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T05:35:00',
        arrivalDate: '2023-06-09T16:10:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 635,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 500,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 5,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3089',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3286',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T08:05:00',
        arrivalDate: '2023-06-09T16:10:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 485,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 65,
            layover: 355,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 8,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3091',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3286',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T08:05:00',
        arrivalDate: '2023-06-09T19:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 670,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 65,
            layover: 540,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 8,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3091',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 18,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3288',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T11:15:00',
        arrivalDate: '2023-06-09T19:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 480,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 345,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 12,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3083',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 18,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3288',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T15:40:00',
        arrivalDate: '2023-06-10T00:25:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 525,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 390,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 40,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3079',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 10,
              dayOfWeek: 6,
              hour: 0,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 23,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3290',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T18:15:00',
        arrivalDate: '2023-06-10T00:25:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 370,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 235,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 18,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3973',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 10,
              dayOfWeek: 6,
              hour: 0,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 23,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3290',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T20:30:00',
        arrivalDate: '2023-06-10T08:40:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 730,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 595,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 21,
              minute: 40,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 20,
              minute: 30,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3081',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 10,
              dayOfWeek: 6,
              hour: 8,
              minute: 40,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 10,
              dayOfWeek: 6,
              hour: 7,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3282',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T04:45:00',
        arrivalDate: '2023-06-09T11:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 390,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 250,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 4,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3307',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 10,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3062',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T04:45:00',
        arrivalDate: '2023-06-09T16:20:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 695,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 555,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 4,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3307',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3068',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T04:45:00',
        arrivalDate: '2023-06-09T16:55:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 730,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 585,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 4,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3307',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3070',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T09:45:00',
        arrivalDate: '2023-06-09T16:20:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 395,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 260,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3301',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3068',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T09:45:00',
        arrivalDate: '2023-06-09T16:55:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 430,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 290,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3301',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3070',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T09:45:00',
        arrivalDate: '2023-06-09T20:15:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 630,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 495,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3301',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 20,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3064',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T09:45:00',
        arrivalDate: '2023-06-09T22:00:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 735,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 600,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 11,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3301',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '321',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 22,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 21,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3072',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T14:45:00',
        arrivalDate: '2023-06-09T22:00:00',
        issuerAirline: 'JJ',
        supplier: 'Latam',
        duration: 435,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 75,
            layover: 300,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3303',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '320',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 22,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 21,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'LA',
            status: 'Unknwon',
            operatedBy: 'LA',
            flightNumber: '3072',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            classOfService: 'H',
            aircraftType: '319',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslLycQxx9I13LkpNSc0ryUzMUXDLSSzOiA8oyk9RqgUAdno7bDYAAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T06:35:00',
        arrivalDate: '2023-06-09T15:00:00',
        issuerAirline: 'G3',
        supplier: 'Gol',
        duration: 505,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 360,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 7,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1243',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '7M8',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 13,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1124',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '7M8',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslIKcw3ydQzyVAh29VVwCY0pIQYq1QIAKFOxC08AAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T08:25:00',
        arrivalDate: '2023-06-09T15:30:00',
        issuerAirline: 'G3',
        supplier: 'Gol',
        duration: 425,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 290,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 8,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1259',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '738',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 30,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1488',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '738',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslIKcw3ydQzyVAh29VVwCY0pIQYq1QIAKFOxC08AAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T08:25:00',
        arrivalDate: '2023-06-09T16:50:00',
        issuerAirline: 'G3',
        supplier: 'Gol',
        duration: 505,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 70,
            layover: 375,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 35,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 8,
              minute: 25,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1259',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '738',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1140',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '738',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslIKcw3ydQzyVAh29VVwCY0pIQYq1QIAKFOxC08AAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T12:55:00',
        arrivalDate: '2023-06-09T16:50:00',
        issuerAirline: 'G3',
        supplier: 'Gol',
        duration: 235,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 75,
            layover: 100,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 12,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1261',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '738',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1140',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '738',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslIKcw3ydQzyVAh29VVwCY0pIQYq1QIAKFOxC08AAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T12:55:00',
        arrivalDate: '2023-06-09T22:20:00',
        issuerAirline: 'G3',
        supplier: 'Gol',
        duration: 565,
        stops: 1,
        flights: [
          {
            arrival: 2702586,
            departure: 2702983,
            duration: 75,
            layover: 420,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 12,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1261',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '738',
          },
          {
            arrival: 2701180,
            departure: 2702586,
            duration: 70,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 22,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 21,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1530',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '738',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslIKcw3ydQzyVAh29VVwCY0pIQYq1QIAKFOxC08AAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T15:30:00',
        arrivalDate: '2023-06-09T23:50:00',
        issuerAirline: 'G3',
        supplier: 'Gol',
        duration: 500,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 355,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 16,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 15,
              minute: 30,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1245',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '73G',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 23,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 22,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1128',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '7M8',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslIKcw3ydQzyVAh29VVwCY0pIQYq1QIAKFOxC08AAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T19:00:00',
        arrivalDate: '2023-06-09T23:50:00',
        issuerAirline: 'G3',
        supplier: 'Gol',
        duration: 290,
        stops: 1,
        flights: [
          {
            arrival: 2702587,
            departure: 2702983,
            duration: 80,
            layover: 145,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 20,
              minute: 20,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1251',
            additionalProperties: {
              MarriageGrp: 'O',
              SegmentNumber: null,
            },
            aircraftType: '7M8',
          },
          {
            arrival: 2701180,
            departure: 2702587,
            duration: 65,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 23,
              minute: 50,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 22,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'G3',
            status: 'Unknwon',
            operatedBy: 'G3',
            flightNumber: '1128',
            additionalProperties: {
              MarriageGrp: 'I',
              SegmentNumber: null,
            },
            aircraftType: '7M8',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtW8slPz8zzzEvLL8pNLMnMz/NLzE1VslIKcw3ydQzyVAh29VVwCY0pIQYq1QIAKFOxC08AAAA=',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T05:30:00',
        arrivalDate: '2023-06-09T10:05:00',
        issuerAirline: 'AD',
        supplier: 'Azul',
        duration: 275,
        stops: 1,
        baggages: 0,
        flights: [
          {
            arrival: 2701199,
            departure: 2702983,
            duration: 75,
            layover: 140,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 5,
              minute: 30,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '4199',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'G',
            aircraftType: 'E95',
          },
          {
            arrival: 2701180,
            departure: 2701199,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 10,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 9,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '2621',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'G',
            aircraftType: '295',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtWCk7NyfFOrVSyUnJ0qTMxtLSsU6irc/PxqzMw0zew1DcyMDJWMDC1MjaoC3MOQBE0szIxrauLA2ozMjMyBGlDV2FpZWBa5xzuhCxoaAASrFPSUfLJT8/M88xLyy/KTSzJzM/zS8xNBTkjKtRHQTcsFShalKmgqxCcmqvgEqpUCwDwfKbyqwAAAA==',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T05:30:00',
        arrivalDate: '2023-06-09T14:55:00',
        issuerAirline: 'AD',
        supplier: 'Azul',
        duration: 565,
        stops: 1,
        baggages: 0,
        flights: [
          {
            arrival: 2701199,
            departure: 2702983,
            duration: 75,
            layover: 430,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 6,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 5,
              minute: 30,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '4199',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'G',
            aircraftType: 'E95',
          },
          {
            arrival: 2701180,
            departure: 2701199,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 13,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '2784',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'G',
            aircraftType: 'E95',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtWCk7NyfFOrVSyUnJ0qTMxtLSsU6irc/PxqzMw0zew1DcyMDJWMDC1MjaoC3MOQBE0szIxrauLA2ozMrcwAWlDU2FobGVqWucc7oQiaAISrFPSUfLJT8/M88xLyy/KTSzJzM/zS8xNBTkjKtRHQTcsFShalKmgqxCcmqvgEqpUCwBqsq+8qwAAAA==',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T08:40:00',
        arrivalDate: '2023-06-09T14:55:00',
        issuerAirline: 'AD',
        supplier: 'Azul',
        duration: 375,
        stops: 1,
        baggages: 0,
        flights: [
          {
            arrival: 2701199,
            departure: 2702983,
            duration: 80,
            layover: 235,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 10,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 8,
              minute: 40,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '9053',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'G',
            aircraftType: '295',
          },
          {
            arrival: 2701180,
            departure: 2701199,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 13,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '2784',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'G',
            aircraftType: 'E95',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtWCk7NyfFOrVSyUnJ0qbM0MDWuU6irc/PxqzMw0zew1DcyMDJWMLCwMjGoC3MOQBY0NLAyMKiriwNqMzK3MAFpQ1dhbGVqWucc7oQiaAISrFPSUfLJT8/M88xLyy/KTSzJzM/zS8xNBTkjKtRHQTcsFShalKmgqxCcmqvgEqpUCwAgrB9/qwAAAA==',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T10:45:00',
        arrivalDate: '2023-06-09T14:55:00',
        issuerAirline: 'AD',
        supplier: 'Azul',
        duration: 250,
        stops: 1,
        baggages: 0,
        flights: [
          {
            arrival: 2701199,
            departure: 2702983,
            duration: 80,
            layover: 110,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 12,
              minute: 5,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 10,
              minute: 45,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '4483',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'F',
            aircraftType: '295',
          },
          {
            arrival: 2701180,
            departure: 2701199,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 14,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 13,
              minute: 55,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '2784',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'F',
            aircraftType: 'E95',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtWCk7NyfFOrVSyUnJ0qTMxsTCuU6irc/PxqzMw0zew1DcyMDJWMDSwMjGtC3MOQBE0sjIwrauLA2ozMrcwAWlDV2FsZWpa5xzuhCJoAhKsU9JR8slPz8zzzEvLL8pNLMnMz/NLzE0FOSMq1EdBNywVKFqUqaCrEJyaq+ASqlQLAFVJ8W+rAAAA',
      },
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '2023-06-09T19:00:00',
        arrivalDate: '2023-06-10T00:10:00',
        issuerAirline: 'AD',
        supplier: 'Azul',
        duration: 310,
        stops: 1,
        baggages: 0,
        flights: [
          {
            arrival: 2701199,
            departure: 2702983,
            duration: 75,
            layover: 175,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 20,
              minute: 15,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 19,
              minute: 0,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '2615',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'Y',
            aircraftType: 'E95',
          },
          {
            arrival: 2701180,
            departure: 2701199,
            duration: 60,
            layover: 0,
            arrivalDate: {
              year: 2023,
              month: 6,
              day: 10,
              dayOfWeek: 6,
              hour: 0,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            departureDate: {
              year: 2023,
              month: 6,
              day: 9,
              dayOfWeek: 5,
              hour: 23,
              minute: 10,
              second: 0,
              millisecond: 0,
            },
            cabinType: 2,
            airline: 'AD',
            status: 'Unknwon',
            operatedBy: 'AD',
            flightNumber: '2865',
            additionalProperties: {
              OpSuffix: ' ',
              LegStatuses: 'Normal',
            },
            classOfService: 'Y',
            aircraftType: '295',
          },
        ],
        compressedAdditionalProperties:
          'H4sIAAAAAAAACqtWCk7NyfFOrVSyUnJ0qTMyMzStU6irc/PxqzMw0zew1DcyMDJWMLS0MjCoC3MOQBY0MrACKq6LA2mzMANrQ1dhbGVoUOcc7gQSNDSACBoAtRnU1SnpKPnkp2fmeeal5RflJpZk5uf5JeamgpwRFeqjoBuWChQtylTQVQhOzVVwCVWqBQDkfppyqwAAAA==',
      },
    ],
    tripOptions: [
      {
        departure: 2702983,
        arrival: 2701180,
        departureDate: '0001-01-01T00:00:00',
        pricedAirTrips: [
          {
            airTrips: [36],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'TNAAAG2GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWa2/bNhT9KwXRj54kK1nXGV4AWa4VdX7FsrsWTdDREi0ToyiBpGwrQf77SEqyZStZBwRJHPDey5NzHzw3T8CPQM/qgBHB8VZ4LM2zyuLmjCEaFqAHBosx6ACfT1GcBhkMEehtIOGoA5bw4CRpTgXoXX8wfv2tYXFvhxLHsBomfzq6NAULtzYxnC2LTGJ3JR3IUA1sW79bxvXHDhhiHirTAgpUXVoFw2aots0YjjGF5EWMtvPEs+07EW77TsxrX6tinw7hFtIYlYS7Kna4kqkjXt10oh2kIZrnTAbyOimXQM5nmwCxHVa1BsvO2Reo26UDFdh3oIyv/zxIULjGtKyvXdZ3ADmWd8Fy6jiOZ3t+AErHIicKlOaEdIA6TPNkjVhtcTF000jx8q7kjQmmgYDFbZqzOq3KJCMsq6e/VRw8tI2BvKVSvJbjRdeyrNEsF/p3OYNpkiAWYkicmCGUINXLksXRUHIpjXOGQ0zjI9M0yRiSJYoqR5AnCWSKw7fV447stj8c8uFx6d+9X8Wru1x48dd47QcD234/ubJG3ufRwl6t0Ud3+3kkPLwqBpu/0Lc5Wk1jR7K/RC3/6sAefDoIRDlOaW2bQPZPnqFIlfd8ojMCixcndU6ZgmFysOYszRATWHUF9B3GYDHb/ImKL5DkaLbhgkkW5ee7Q0Io/+MebIXIeqbJwy1KIDcSHLKUpxthhGli2pZ1ZXYtU46YrC1+hEJyNTUwvwclRg+fUPb7vbG/MlIWq6td8+tkHGjgXzDlQo3wPbjpv8xI228GDNLIH/ZNdejrsJvxsjNe9s3yoD2vXX8VtvnCjBmJzvDlg3sbev2gW8jyeb8N+dTxFnY1AG/DP+prC17L9M/Bzf+cshstbu3hfAJVn+Wc6u5eiKBiI12yMdLRrO5RMs8LI81VOZSMNXOSHp0JeO6AuxxSgUVRLrKWsJ8Za0VX4ruHLDrbHCrMTekOMfV2la4+/Vzco5ZHF6zS+zpKF+fFd/7/Cbd3j9XewM/PD69EDmAcQ8nEpyHJIxTpRTuBWSVLU5joXQMZ3sB3tsxD97J08hFMMFEpjn3vVrV1Nhgh5AyXtcLps86gcdbkL/S6NoxUsaFUETliZ1soKLhAieGmhKBQCRM3PESlUoXGGHPxd/d7FRHoWXz4odfQXJKU6FrJFHTVhLMyl+tPluILZiIvl/llXOWq/rGovHo6Li812nXpajTt0nVsyPO/J2zJf3wJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 200.0,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 200.0,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 4.0,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 200.0,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 204.0,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'TNAAAG3GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWbW/aOhT+K5O1j9wkQNddIW6lBAZj4yUlZHfTWu2axARrjhPZDpBW/e/XdhIIpN0mVW2pfF6ePj7n+Dl9BJMQ9KwWGBEcbcWYJVlaWgYZY4gGOegBZzkFLTDhcxQlXgoDBHobSDhqgRU82HGSUQF6V9fGu/c1y+DjUOIYVs00mY8uTd5yUJkYTld5KrHbkg5kqALudKwro3vdAkPMA2VaQoHKJN8b1kO1bcFwhCkkz2I0nSeeTd+JcNN3Yl75GhX7cAi2kEaoINxWsUNfXh3xMtMOd5AGyM2YDOTVpQYEcr7YeIjtsKo1WLXOvkDVLh2owL4DZXz5516CwjWmRX07RX0dyLHMBau5bdvj7njigcKxzIgCpRkhLaAO8yxeI1ZZBhgOklDxGndlxgxTT8D8Y5Kx6lqlSUZYVk9/qzh4aBo9maWueCXHi65lWcNFJvTvYgaTOEYswJDYEUMoRqqXBYujoeBSGF2GA0yjI9MkThmSJQpLh5fFMWSKwzf/YUd22x82uX5YTW7f+pF/m4lx9DVaTzyn03k761qj8afRsuOv0d+D7aeRGGM/dzb/om8u8ueRLdlfohZ/1ek4Hw4CUY4TWtlmkP3MUhSq8p5PdEpg/uykupQpGCYHy2VJipjAqiugbzMG88XmM8q/QJKhxYYLJlkUn28OMaH8nzuwFSLtmSYPtiiG3IhxwBKebIQRJLHZsayu2bZMOWKytvgBCsnV1MD8DhQYPXxC2e/3xr5rJCxSqW3z62zqaeC/MOVCjfAduOk/z0jbbxwGaTgZ9k116OuwG3facqd9szhoz0vpL8LWX5ixIOEZvnxwr0OvHnQDWT7v1yGfOt7ALgfgdfhHfW3Aa5n+Pbj5yym70eLWHM5HUPZZzqnu7oUIKjbSJRsjHfXqHiXzvDDSXJZDyVj9TtKjbwKeWuA2g1RgkReLrCHsZ8ZK0ZX47iELzzaHChskdIeYertKVx9/L+5hw6MLVup9FaWL8+w7/3PCzd1jNTfw09P9C5EOjCIomUxoQLIQhXoRzGBaytIcxnrXQIY38I1Sdt3LwslHMMZEXdGd+mpPLJwRQvZwVQmcPusL1M6a+4VcV4aRqjWUIiIn7GwJeTkXKDYGCSEoULrEjTGiUqgCY4q5+K/9vYzw9Cje/9BbyJUcJboWMgVd9uCsysX2k5X4gpnIil1+GVe6yv8rSq8ejsukWrcuXbWeXbqO/Xj6HzybtaR7CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2250.93,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MAX',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'TNAAAG4GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW/W/aOhT9VyZrP/KS8LHtCfEqJTAYW/kogb1Oa7VnEhOsJU5kO0Ba9X9/104CgbTbpKotlc+9Pj2+9/q4j2jso67VQMOQBls54nGaFEg/5ZwwL0Nd5CyuUQONxZQEsZtgj6DuBoeCNNASH+woTplE3c57492HCtL/NAAew6pA4+nwEnIX/RLiNFlmCXA3QQ7mpCRutdsdow3UAyo8BS2wJMWmlTuopmpsxmlAGQ6f5agHTzrrsZPgeuykvIzVKvbx4G0xC0guuKlyBys4OhHFTtvfYeaRecohUZSH6odYiNnGJXxHVa3RsnH2hcp26URF9h0p8OWfeyDFa8ry+rby+jpYUNiLllPbtked0dhFeWCRhoqUpWHYQGoxTaM14SXSp7gf+0rXqA07JpS5Emef4pSXxyogyLCsrv5WefhQB13YpY7YgfFiayirP0ul/p3PYBxFhHsUh3bACYmI6mWu4gjkWnJwzqlHWXBUGkcJJ1Aivwi4aRRhrjR8Wz3swt32hx2+f1iOb96ugtVNKkfBbbAeu06r9XbStoajz8NFa7Umf/e3n4dyRFeZs/mXfJuT1TSwQf0la/5XnZbz8SAJEzRmJTbB/GeaEF+V93yikxBnz07qnHFFw2Gw5jxOCJdUdQX1bM5xNtt8IdlXHKZkthGSg4r8880hCpn45w5tpUy6pim8LYmwMCLq8VjEG2l4cWS2LKttNi0TRgxqSx+wBK2mJhZ3KOfo0hPLfr839m0j5oHa2jRvJ9euJv6LMiHVCN+hq97zijR+5XDM/PGgZ6pFT6ddTW4bk9uemS905KXtL9JWb5gxC/0zfrhwr2MvL3SNGa7365hPHa9xFwPwOv6jv9botU3/ntz85ZRdaXOrD+cjKvoMc6q7e2GCSg2EoDEQqFb3aJnnhQG4KIeyseqZIKJPgp4a6CbFTFKZ5Q9ZzdjPwNLRlfnuMffPXg6V1o/ZjnB1d5WvPv7e3P1aRBes8PsySxfn2Xv+54Lrb49Vf4Gfnu5fyHRwEGBQMmZemPoESthWxpQUtjTFkX5rMKcb/KYD59C9zINiiCMaqiNObNXUmTMkxB4sS3/Ta62/stbSL9y6BIaq1Bg8BAbs7A1yMyFJZPTjMCSesiVhjAgDn/KMayrkf83vRYarJ/H+h36E5iAR2LWPKeqiBWdFzh8/KMRXymWaP+WXeUWo+LeiiOrZuNxUadZlqNKyy9CxHU//A0qvRvl6CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2380.94,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [37],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'TNAAAG2GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWa2/bNhT9KwXRj54kK1nXGV4AWa4VdX7FsrsWTdDREi0ToyiBpGwrQf77SEqyZStZBwRJHPDey5NzHzw3T8CPQM/qgBHB8VZ4LM2zyuLmjCEaFqAHBosx6ACfT1GcBhkMEehtIOGoA5bw4CRpTgXoXX8wfv2tYXFvhxLHsBomfzq6NAULtzYxnC2LTGJ3JR3IUA1sW79bxvXHDhhiHirTAgpUXVoFw2aots0YjjGF5EWMtvPEs+07EW77TsxrX6tinw7hFtIYlYS7Kna4kqkjXt10oh2kIZrnTAbyOimXQM5nmwCxHVa1BsvO2Reo26UDFdh3oIyv/zxIULjGtKyvXdZ3ADmWd8Fy6jiOZ3t+AErHIicKlOaEdIA6TPNkjVhtcTF000jx8q7kjQmmgYDFbZqzOq3KJCMsq6e/VRw8tI2BvKVSvJbjRdeyrNEsF/p3OYNpkiAWYkicmCGUINXLksXRUHIpjXOGQ0zjI9M0yRiSJYoqR5AnCWSKw7fV447stj8c8uFx6d+9X8Wru1x48dd47QcD234/ubJG3ufRwl6t0Ud3+3kkPLwqBpu/0Lc5Wk1jR7K/RC3/6sAefDoIRDlOaW2bQPZPnqFIlfd8ojMCixcndU6ZgmFysOYszRATWHUF9B3GYDHb/ImKL5DkaLbhgkkW5ee7Q0Io/+MebIXIeqbJwy1KIDcSHLKUpxthhGli2pZ1ZXYtU46YrC1+hEJyNTUwvwclRg+fUPb7vbG/MlIWq6td8+tkHGjgXzDlQo3wPbjpv8xI228GDNLIH/ZNdejrsJvxsjNe9s3yoD2vXX8VtvnCjBmJzvDlg3sbev2gW8jyeb8N+dTxFnY1AG/DP+prC17L9M/Bzf+cshstbu3hfAJVn+Wc6u5eiKBiI12yMdLRrO5RMs8LI81VOZSMNXOSHp0JeO6AuxxSgUVRLrKWsJ8Za0VX4ruHLDrbHCrMTekOMfV2la4+/Vzco5ZHF6zS+zpKF+fFd/7/Cbd3j9XewM/PD69EDmAcQ8nEpyHJIxTpRTuBWSVLU5joXQMZ3sB3tsxD97J08hFMMFEpjn3vVrV1Nhgh5AyXtcLps86gcdbkL/S6NoxUsaFUETliZ1soKLhAieGmhKBQCRM3PESlUoXGGHPxd/d7FRHoWXz4odfQXJKU6FrJFHTVhLMyl+tPluILZiIvl/llXOWq/rGovHo6Li812nXpajTt0nVsyPO/J2zJf3wJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2137.05,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'TNAAAG3GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWbW/aOhT+K5O1j9wkQNddIW6lBAZj4yUlZHfTWu2axARrjhPZDpBW/e/XdhIIpN0mVW2pfF6ePj7n+Dl9BJMQ9KwWGBEcbcWYJVlaWgYZY4gGOegBZzkFLTDhcxQlXgoDBHobSDhqgRU82HGSUQF6V9fGu/c1y+DjUOIYVs00mY8uTd5yUJkYTld5KrHbkg5kqALudKwro3vdAkPMA2VaQoHKJN8b1kO1bcFwhCkkz2I0nSeeTd+JcNN3Yl75GhX7cAi2kEaoINxWsUNfXh3xMtMOd5AGyM2YDOTVpQYEcr7YeIjtsKo1WLXOvkDVLh2owL4DZXz5516CwjWmRX07RX0dyLHMBau5bdvj7njigcKxzIgCpRkhLaAO8yxeI1ZZBhgOklDxGndlxgxTT8D8Y5Kx6lqlSUZYVk9/qzh4aBo9maWueCXHi65lWcNFJvTvYgaTOEYswJDYEUMoRqqXBYujoeBSGF2GA0yjI9MkThmSJQpLh5fFMWSKwzf/YUd22x82uX5YTW7f+pF/m4lx9DVaTzyn03k761qj8afRsuOv0d+D7aeRGGM/dzb/om8u8ueRLdlfohZ/1ek4Hw4CUY4TWtlmkP3MUhSq8p5PdEpg/uykupQpGCYHy2VJipjAqiugbzMG88XmM8q/QJKhxYYLJlkUn28OMaH8nzuwFSLtmSYPtiiG3IhxwBKebIQRJLHZsayu2bZMOWKytvgBCsnV1MD8DhQYPXxC2e/3xr5rJCxSqW3z62zqaeC/MOVCjfAduOk/z0jbbxwGaTgZ9k116OuwG3facqd9szhoz0vpL8LWX5ixIOEZvnxwr0OvHnQDWT7v1yGfOt7ALgfgdfhHfW3Aa5n+Pbj5yym70eLWHM5HUPZZzqnu7oUIKjbSJRsjHfXqHiXzvDDSXJZDyVj9TtKjbwKeWuA2g1RgkReLrCHsZ8ZK0ZX47iELzzaHChskdIeYertKVx9/L+5hw6MLVup9FaWL8+w7/3PCzd1jNTfw09P9C5EOjCIomUxoQLIQhXoRzGBaytIcxnrXQIY38I1Sdt3LwslHMMZEXdGd+mpPLJwRQvZwVQmcPusL1M6a+4VcV4aRqjWUIiIn7GwJeTkXKDYGCSEoULrEjTGiUqgCY4q5+K/9vYzw9Cje/9BbyJUcJboWMgVd9uCsysX2k5X4gpnIil1+GVe6yv8rSq8ejsukWrcuXbWeXbqO/Xj6HzybtaR7CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2250.93,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MAX',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'TNAAAG4GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW/W/aOhT9VyZrP/KS8LHtCfEqJTAYW/kogb1Oa7VnEhOsJU5kO0Ba9X9/104CgbTbpKotlc+9Pj2+9/q4j2jso67VQMOQBls54nGaFEg/5ZwwL0Nd5CyuUQONxZQEsZtgj6DuBoeCNNASH+woTplE3c57492HCtL/NAAew6pA4+nwEnIX/RLiNFlmCXA3QQ7mpCRutdsdow3UAyo8BS2wJMWmlTuopmpsxmlAGQ6f5agHTzrrsZPgeuykvIzVKvbx4G0xC0guuKlyBys4OhHFTtvfYeaRecohUZSH6odYiNnGJXxHVa3RsnH2hcp26URF9h0p8OWfeyDFa8ry+rby+jpYUNiLllPbtked0dhFeWCRhoqUpWHYQGoxTaM14SXSp7gf+0rXqA07JpS5Emef4pSXxyogyLCsrv5WefhQB13YpY7YgfFiayirP0ul/p3PYBxFhHsUh3bACYmI6mWu4gjkWnJwzqlHWXBUGkcJJ1Aivwi4aRRhrjR8Wz3swt32hx2+f1iOb96ugtVNKkfBbbAeu06r9XbStoajz8NFa7Umf/e3n4dyRFeZs/mXfJuT1TSwQf0la/5XnZbz8SAJEzRmJTbB/GeaEF+V93yikxBnz07qnHFFw2Gw5jxOCJdUdQX1bM5xNtt8IdlXHKZkthGSg4r8880hCpn45w5tpUy6pim8LYmwMCLq8VjEG2l4cWS2LKttNi0TRgxqSx+wBK2mJhZ3KOfo0hPLfr839m0j5oHa2jRvJ9euJv6LMiHVCN+hq97zijR+5XDM/PGgZ6pFT6ddTW4bk9uemS905KXtL9JWb5gxC/0zfrhwr2MvL3SNGa7365hPHa9xFwPwOv6jv9botU3/ntz85ZRdaXOrD+cjKvoMc6q7e2GCSg2EoDEQqFb3aJnnhQG4KIeyseqZIKJPgp4a6CbFTFKZ5Q9ZzdjPwNLRlfnuMffPXg6V1o/ZjnB1d5WvPv7e3P1aRBes8PsySxfn2Xv+54Lrb49Vf4Gfnu5fyHRwEGBQMmZemPoESthWxpQUtjTFkX5rMKcb/KYD59C9zINiiCMaqiNObNXUmTMkxB4sS3/Ta62/stbSL9y6BIaq1Bg8BAbs7A1yMyFJZPTjMCSesiVhjAgDn/KMayrkf83vRYarJ/H+h36E5iAR2LWPKeqiBWdFzh8/KMRXymWaP+WXeUWo+LeiiOrZuNxUadZlqNKyy9CxHU//A0qvRvl6CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2380.94,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [38],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'TNAAAG2GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWa2/bNhT9KwXRj54kK1nXGV4AWa4VdX7FsrsWTdDREi0ToyiBpGwrQf77SEqyZStZBwRJHPDey5NzHzw3T8CPQM/qgBHB8VZ4LM2zyuLmjCEaFqAHBosx6ACfT1GcBhkMEehtIOGoA5bw4CRpTgXoXX8wfv2tYXFvhxLHsBomfzq6NAULtzYxnC2LTGJ3JR3IUA1sW79bxvXHDhhiHirTAgpUXVoFw2aots0YjjGF5EWMtvPEs+07EW77TsxrX6tinw7hFtIYlYS7Kna4kqkjXt10oh2kIZrnTAbyOimXQM5nmwCxHVa1BsvO2Reo26UDFdh3oIyv/zxIULjGtKyvXdZ3ADmWd8Fy6jiOZ3t+AErHIicKlOaEdIA6TPNkjVhtcTF000jx8q7kjQmmgYDFbZqzOq3KJCMsq6e/VRw8tI2BvKVSvJbjRdeyrNEsF/p3OYNpkiAWYkicmCGUINXLksXRUHIpjXOGQ0zjI9M0yRiSJYoqR5AnCWSKw7fV447stj8c8uFx6d+9X8Wru1x48dd47QcD234/ubJG3ufRwl6t0Ud3+3kkPLwqBpu/0Lc5Wk1jR7K/RC3/6sAefDoIRDlOaW2bQPZPnqFIlfd8ojMCixcndU6ZgmFysOYszRATWHUF9B3GYDHb/ImKL5DkaLbhgkkW5ee7Q0Io/+MebIXIeqbJwy1KIDcSHLKUpxthhGli2pZ1ZXYtU46YrC1+hEJyNTUwvwclRg+fUPb7vbG/MlIWq6td8+tkHGjgXzDlQo3wPbjpv8xI228GDNLIH/ZNdejrsJvxsjNe9s3yoD2vXX8VtvnCjBmJzvDlg3sbev2gW8jyeb8N+dTxFnY1AG/DP+prC17L9M/Bzf+cshstbu3hfAJVn+Wc6u5eiKBiI12yMdLRrO5RMs8LI81VOZSMNXOSHp0JeO6AuxxSgUVRLrKWsJ8Za0VX4ruHLDrbHCrMTekOMfV2la4+/Vzco5ZHF6zS+zpKF+fFd/7/Cbd3j9XewM/PD69EDmAcQ8nEpyHJIxTpRTuBWSVLU5joXQMZ3sB3tsxD97J08hFMMFEpjn3vVrV1Nhgh5AyXtcLps86gcdbkL/S6NoxUsaFUETliZ1soKLhAieGmhKBQCRM3PESlUoXGGHPxd/d7FRHoWXz4odfQXJKU6FrJFHTVhLMyl+tPluILZiIvl/llXOWq/rGovHo6Li812nXpajTt0nVsyPO/J2zJf3wJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2137.05,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'TNAAAG3GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWbW/aOhT+K5O1j9wkQNddIW6lBAZj4yUlZHfTWu2axARrjhPZDpBW/e/XdhIIpN0mVW2pfF6ePj7n+Dl9BJMQ9KwWGBEcbcWYJVlaWgYZY4gGOegBZzkFLTDhcxQlXgoDBHobSDhqgRU82HGSUQF6V9fGu/c1y+DjUOIYVs00mY8uTd5yUJkYTld5KrHbkg5kqALudKwro3vdAkPMA2VaQoHKJN8b1kO1bcFwhCkkz2I0nSeeTd+JcNN3Yl75GhX7cAi2kEaoINxWsUNfXh3xMtMOd5AGyM2YDOTVpQYEcr7YeIjtsKo1WLXOvkDVLh2owL4DZXz5516CwjWmRX07RX0dyLHMBau5bdvj7njigcKxzIgCpRkhLaAO8yxeI1ZZBhgOklDxGndlxgxTT8D8Y5Kx6lqlSUZYVk9/qzh4aBo9maWueCXHi65lWcNFJvTvYgaTOEYswJDYEUMoRqqXBYujoeBSGF2GA0yjI9MkThmSJQpLh5fFMWSKwzf/YUd22x82uX5YTW7f+pF/m4lx9DVaTzyn03k761qj8afRsuOv0d+D7aeRGGM/dzb/om8u8ueRLdlfohZ/1ek4Hw4CUY4TWtlmkP3MUhSq8p5PdEpg/uykupQpGCYHy2VJipjAqiugbzMG88XmM8q/QJKhxYYLJlkUn28OMaH8nzuwFSLtmSYPtiiG3IhxwBKebIQRJLHZsayu2bZMOWKytvgBCsnV1MD8DhQYPXxC2e/3xr5rJCxSqW3z62zqaeC/MOVCjfAduOk/z0jbbxwGaTgZ9k116OuwG3facqd9szhoz0vpL8LWX5ixIOEZvnxwr0OvHnQDWT7v1yGfOt7ALgfgdfhHfW3Aa5n+Pbj5yym70eLWHM5HUPZZzqnu7oUIKjbSJRsjHfXqHiXzvDDSXJZDyVj9TtKjbwKeWuA2g1RgkReLrCHsZ8ZK0ZX47iELzzaHChskdIeYertKVx9/L+5hw6MLVup9FaWL8+w7/3PCzd1jNTfw09P9C5EOjCIomUxoQLIQhXoRzGBaytIcxnrXQIY38I1Sdt3LwslHMMZEXdGd+mpPLJwRQvZwVQmcPusL1M6a+4VcV4aRqjWUIiIn7GwJeTkXKDYGCSEoULrEjTGiUqgCY4q5+K/9vYzw9Cje/9BbyJUcJboWMgVd9uCsysX2k5X4gpnIil1+GVe6yv8rSq8ejsukWrcuXbWeXbqO/Xj6HzybtaR7CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2250.93,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MAX',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'TNAAAG4GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW/W/aOhT9VyZrP/KS8LHtCfEqJTAYW/kogb1Oa7VnEhOsJU5kO0Ba9X9/104CgbTbpKotlc+9Pj2+9/q4j2jso67VQMOQBls54nGaFEg/5ZwwL0Nd5CyuUQONxZQEsZtgj6DuBoeCNNASH+woTplE3c57492HCtL/NAAew6pA4+nwEnIX/RLiNFlmCXA3QQ7mpCRutdsdow3UAyo8BS2wJMWmlTuopmpsxmlAGQ6f5agHTzrrsZPgeuykvIzVKvbx4G0xC0guuKlyBys4OhHFTtvfYeaRecohUZSH6odYiNnGJXxHVa3RsnH2hcp26URF9h0p8OWfeyDFa8ry+rby+jpYUNiLllPbtked0dhFeWCRhoqUpWHYQGoxTaM14SXSp7gf+0rXqA07JpS5Emef4pSXxyogyLCsrv5WefhQB13YpY7YgfFiayirP0ul/p3PYBxFhHsUh3bACYmI6mWu4gjkWnJwzqlHWXBUGkcJJ1Aivwi4aRRhrjR8Wz3swt32hx2+f1iOb96ugtVNKkfBbbAeu06r9XbStoajz8NFa7Umf/e3n4dyRFeZs/mXfJuT1TSwQf0la/5XnZbz8SAJEzRmJTbB/GeaEF+V93yikxBnz07qnHFFw2Gw5jxOCJdUdQX1bM5xNtt8IdlXHKZkthGSg4r8880hCpn45w5tpUy6pim8LYmwMCLq8VjEG2l4cWS2LKttNi0TRgxqSx+wBK2mJhZ3KOfo0hPLfr839m0j5oHa2jRvJ9euJv6LMiHVCN+hq97zijR+5XDM/PGgZ6pFT6ddTW4bk9uemS905KXtL9JWb5gxC/0zfrhwr2MvL3SNGa7365hPHa9xFwPwOv6jv9botU3/ntz85ZRdaXOrD+cjKvoMc6q7e2GCSg2EoDEQqFb3aJnnhQG4KIeyseqZIKJPgp4a6CbFTFKZ5Q9ZzdjPwNLRlfnuMffPXg6V1o/ZjnB1d5WvPv7e3P1aRBes8PsySxfn2Xv+54Lrb49Vf4Gfnu5fyHRwEGBQMmZemPoESthWxpQUtjTFkX5rMKcb/KYD59C9zINiiCMaqiNObNXUmTMkxB4sS3/Ta62/stbSL9y6BIaq1Bg8BAbs7A1yMyFJZPTjMCSesiVhjAgDn/KMayrkf83vRYarJ/H+h36E5iAR2LWPKeqiBWdFzh8/KMRXymWaP+WXeUWo+LeiiOrZuNxUadZlqNKyy9CxHU//A0qvRvl6CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2380.94,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [39],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'TNAAAG2GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWa2/bNhT9KwXRj54kK1nXGV4AWa4VdX7FsrsWTdDREi0ToyiBpGwrQf77SEqyZStZBwRJHPDey5NzHzw3T8CPQM/qgBHB8VZ4LM2zyuLmjCEaFqAHBosx6ACfT1GcBhkMEehtIOGoA5bw4CRpTgXoXX8wfv2tYXFvhxLHsBomfzq6NAULtzYxnC2LTGJ3JR3IUA1sW79bxvXHDhhiHirTAgpUXVoFw2aots0YjjGF5EWMtvPEs+07EW77TsxrX6tinw7hFtIYlYS7Kna4kqkjXt10oh2kIZrnTAbyOimXQM5nmwCxHVa1BsvO2Reo26UDFdh3oIyv/zxIULjGtKyvXdZ3ADmWd8Fy6jiOZ3t+AErHIicKlOaEdIA6TPNkjVhtcTF000jx8q7kjQmmgYDFbZqzOq3KJCMsq6e/VRw8tI2BvKVSvJbjRdeyrNEsF/p3OYNpkiAWYkicmCGUINXLksXRUHIpjXOGQ0zjI9M0yRiSJYoqR5AnCWSKw7fV447stj8c8uFx6d+9X8Wru1x48dd47QcD234/ubJG3ufRwl6t0Ud3+3kkPLwqBpu/0Lc5Wk1jR7K/RC3/6sAefDoIRDlOaW2bQPZPnqFIlfd8ojMCixcndU6ZgmFysOYszRATWHUF9B3GYDHb/ImKL5DkaLbhgkkW5ee7Q0Io/+MebIXIeqbJwy1KIDcSHLKUpxthhGli2pZ1ZXYtU46YrC1+hEJyNTUwvwclRg+fUPb7vbG/MlIWq6td8+tkHGjgXzDlQo3wPbjpv8xI228GDNLIH/ZNdejrsJvxsjNe9s3yoD2vXX8VtvnCjBmJzvDlg3sbev2gW8jyeb8N+dTxFnY1AG/DP+prC17L9M/Bzf+cshstbu3hfAJVn+Wc6u5eiKBiI12yMdLRrO5RMs8LI81VOZSMNXOSHp0JeO6AuxxSgUVRLrKWsJ8Za0VX4ruHLDrbHCrMTekOMfV2la4+/Vzco5ZHF6zS+zpKF+fFd/7/Cbd3j9XewM/PD69EDmAcQ8nEpyHJIxTpRTuBWSVLU5joXQMZ3sB3tsxD97J08hFMMFEpjn3vVrV1Nhgh5AyXtcLps86gcdbkL/S6NoxUsaFUETliZ1soKLhAieGmhKBQCRM3PESlUoXGGHPxd/d7FRHoWXz4odfQXJKU6FrJFHTVhLMyl+tPluILZiIvl/llXOWq/rGovHo6Li812nXpajTt0nVsyPO/J2zJf3wJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2137.05,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'TNAAAG3GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWbW/aOhT+K5O1j9wkQNddIW6lBAZj4yUlZHfTWu2axARrjhPZDpBW/e/XdhIIpN0mVW2pfF6ePj7n+Dl9BJMQ9KwWGBEcbcWYJVlaWgYZY4gGOegBZzkFLTDhcxQlXgoDBHobSDhqgRU82HGSUQF6V9fGu/c1y+DjUOIYVs00mY8uTd5yUJkYTld5KrHbkg5kqALudKwro3vdAkPMA2VaQoHKJN8b1kO1bcFwhCkkz2I0nSeeTd+JcNN3Yl75GhX7cAi2kEaoINxWsUNfXh3xMtMOd5AGyM2YDOTVpQYEcr7YeIjtsKo1WLXOvkDVLh2owL4DZXz5516CwjWmRX07RX0dyLHMBau5bdvj7njigcKxzIgCpRkhLaAO8yxeI1ZZBhgOklDxGndlxgxTT8D8Y5Kx6lqlSUZYVk9/qzh4aBo9maWueCXHi65lWcNFJvTvYgaTOEYswJDYEUMoRqqXBYujoeBSGF2GA0yjI9MkThmSJQpLh5fFMWSKwzf/YUd22x82uX5YTW7f+pF/m4lx9DVaTzyn03k761qj8afRsuOv0d+D7aeRGGM/dzb/om8u8ueRLdlfohZ/1ek4Hw4CUY4TWtlmkP3MUhSq8p5PdEpg/uykupQpGCYHy2VJipjAqiugbzMG88XmM8q/QJKhxYYLJlkUn28OMaH8nzuwFSLtmSYPtiiG3IhxwBKebIQRJLHZsayu2bZMOWKytvgBCsnV1MD8DhQYPXxC2e/3xr5rJCxSqW3z62zqaeC/MOVCjfAduOk/z0jbbxwGaTgZ9k116OuwG3facqd9szhoz0vpL8LWX5ixIOEZvnxwr0OvHnQDWT7v1yGfOt7ALgfgdfhHfW3Aa5n+Pbj5yym70eLWHM5HUPZZzqnu7oUIKjbSJRsjHfXqHiXzvDDSXJZDyVj9TtKjbwKeWuA2g1RgkReLrCHsZ8ZK0ZX47iELzzaHChskdIeYertKVx9/L+5hw6MLVup9FaWL8+w7/3PCzd1jNTfw09P9C5EOjCIomUxoQLIQhXoRzGBaytIcxnrXQIY38I1Sdt3LwslHMMZEXdGd+mpPLJwRQvZwVQmcPusL1M6a+4VcV4aRqjWUIiIn7GwJeTkXKDYGCSEoULrEjTGiUqgCY4q5+K/9vYzw9Cje/9BbyJUcJboWMgVd9uCsysX2k5X4gpnIil1+GVe6yv8rSq8ejsukWrcuXbWeXbqO/Xj6HzybtaR7CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2250.93,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MAX',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'TNAAAG4GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW/W/aOhT9VyZrP/KS8LHtCfEqJTAYW/kogb1Oa7VnEhOsJU5kO0Ba9X9/104CgbTbpKotlc+9Pj2+9/q4j2jso67VQMOQBls54nGaFEg/5ZwwL0Nd5CyuUQONxZQEsZtgj6DuBoeCNNASH+woTplE3c57492HCtL/NAAew6pA4+nwEnIX/RLiNFlmCXA3QQ7mpCRutdsdow3UAyo8BS2wJMWmlTuopmpsxmlAGQ6f5agHTzrrsZPgeuykvIzVKvbx4G0xC0guuKlyBys4OhHFTtvfYeaRecohUZSH6odYiNnGJXxHVa3RsnH2hcp26URF9h0p8OWfeyDFa8ry+rby+jpYUNiLllPbtked0dhFeWCRhoqUpWHYQGoxTaM14SXSp7gf+0rXqA07JpS5Emef4pSXxyogyLCsrv5WefhQB13YpY7YgfFiayirP0ul/p3PYBxFhHsUh3bACYmI6mWu4gjkWnJwzqlHWXBUGkcJJ1Aivwi4aRRhrjR8Wz3swt32hx2+f1iOb96ugtVNKkfBbbAeu06r9XbStoajz8NFa7Umf/e3n4dyRFeZs/mXfJuT1TSwQf0la/5XnZbz8SAJEzRmJTbB/GeaEF+V93yikxBnz07qnHFFw2Gw5jxOCJdUdQX1bM5xNtt8IdlXHKZkthGSg4r8880hCpn45w5tpUy6pim8LYmwMCLq8VjEG2l4cWS2LKttNi0TRgxqSx+wBK2mJhZ3KOfo0hPLfr839m0j5oHa2jRvJ9euJv6LMiHVCN+hq97zijR+5XDM/PGgZ6pFT6ddTW4bk9uemS905KXtL9JWb5gxC/0zfrhwr2MvL3SNGa7365hPHa9xFwPwOv6jv9botU3/ntz85ZRdaXOrD+cjKvoMc6q7e2GCSg2EoDEQqFb3aJnnhQG4KIeyseqZIKJPgp4a6CbFTFKZ5Q9ZzdjPwNLRlfnuMffPXg6V1o/ZjnB1d5WvPv7e3P1aRBes8PsySxfn2Xv+54Lrb49Vf4Gfnu5fyHRwEGBQMmZemPoESthWxpQUtjTFkX5rMKcb/KYD59C9zINiiCMaqiNObNXUmTMkxB4sS3/Ta62/stbSL9y6BIaq1Bg8BAbs7A1yMyFJZPTjMCSesiVhjAgDn/KMayrkf83vRYarJ/H+h36E5iAR2LWPKeqiBWdFzh8/KMRXymWaP+WXeUWo+LeiiOrZuNxUadZlqNKyy9CxHU//A0qvRvl6CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2380.94,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [40],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'TNAAAG2GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWa2/bNhT9KwXRj54kK1nXGV4AWa4VdX7FsrsWTdDREi0ToyiBpGwrQf77SEqyZStZBwRJHPDey5NzHzw3T8CPQM/qgBHB8VZ4LM2zyuLmjCEaFqAHBosx6ACfT1GcBhkMEehtIOGoA5bw4CRpTgXoXX8wfv2tYXFvhxLHsBomfzq6NAULtzYxnC2LTGJ3JR3IUA1sW79bxvXHDhhiHirTAgpUXVoFw2aots0YjjGF5EWMtvPEs+07EW77TsxrX6tinw7hFtIYlYS7Kna4kqkjXt10oh2kIZrnTAbyOimXQM5nmwCxHVa1BsvO2Reo26UDFdh3oIyv/zxIULjGtKyvXdZ3ADmWd8Fy6jiOZ3t+AErHIicKlOaEdIA6TPNkjVhtcTF000jx8q7kjQmmgYDFbZqzOq3KJCMsq6e/VRw8tI2BvKVSvJbjRdeyrNEsF/p3OYNpkiAWYkicmCGUINXLksXRUHIpjXOGQ0zjI9M0yRiSJYoqR5AnCWSKw7fV447stj8c8uFx6d+9X8Wru1x48dd47QcD234/ubJG3ufRwl6t0Ud3+3kkPLwqBpu/0Lc5Wk1jR7K/RC3/6sAefDoIRDlOaW2bQPZPnqFIlfd8ojMCixcndU6ZgmFysOYszRATWHUF9B3GYDHb/ImKL5DkaLbhgkkW5ee7Q0Io/+MebIXIeqbJwy1KIDcSHLKUpxthhGli2pZ1ZXYtU46YrC1+hEJyNTUwvwclRg+fUPb7vbG/MlIWq6td8+tkHGjgXzDlQo3wPbjpv8xI228GDNLIH/ZNdejrsJvxsjNe9s3yoD2vXX8VtvnCjBmJzvDlg3sbev2gW8jyeb8N+dTxFnY1AG/DP+prC17L9M/Bzf+cshstbu3hfAJVn+Wc6u5eiKBiI12yMdLRrO5RMs8LI81VOZSMNXOSHp0JeO6AuxxSgUVRLrKWsJ8Za0VX4ruHLDrbHCrMTekOMfV2la4+/Vzco5ZHF6zS+zpKF+fFd/7/Cbd3j9XewM/PD69EDmAcQ8nEpyHJIxTpRTuBWSVLU5joXQMZ3sB3tsxD97J08hFMMFEpjn3vVrV1Nhgh5AyXtcLps86gcdbkL/S6NoxUsaFUETliZ1soKLhAieGmhKBQCRM3PESlUoXGGHPxd/d7FRHoWXz4odfQXJKU6FrJFHTVhLMyl+tPluILZiIvl/llXOWq/rGovHo6Li812nXpajTt0nVsyPO/J2zJf3wJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2137.05,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'TNAAAG3GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWbW/aOhT+K5O1j9wkQNddIW6lBAZj4yUlZHfTWu2axARrjhPZDpBW/e/XdhIIpN0mVW2pfF6ePj7n+Dl9BJMQ9KwWGBEcbcWYJVlaWgYZY4gGOegBZzkFLTDhcxQlXgoDBHobSDhqgRU82HGSUQF6V9fGu/c1y+DjUOIYVs00mY8uTd5yUJkYTld5KrHbkg5kqALudKwro3vdAkPMA2VaQoHKJN8b1kO1bcFwhCkkz2I0nSeeTd+JcNN3Yl75GhX7cAi2kEaoINxWsUNfXh3xMtMOd5AGyM2YDOTVpQYEcr7YeIjtsKo1WLXOvkDVLh2owL4DZXz5516CwjWmRX07RX0dyLHMBau5bdvj7njigcKxzIgCpRkhLaAO8yxeI1ZZBhgOklDxGndlxgxTT8D8Y5Kx6lqlSUZYVk9/qzh4aBo9maWueCXHi65lWcNFJvTvYgaTOEYswJDYEUMoRqqXBYujoeBSGF2GA0yjI9MkThmSJQpLh5fFMWSKwzf/YUd22x82uX5YTW7f+pF/m4lx9DVaTzyn03k761qj8afRsuOv0d+D7aeRGGM/dzb/om8u8ueRLdlfohZ/1ek4Hw4CUY4TWtlmkP3MUhSq8p5PdEpg/uykupQpGCYHy2VJipjAqiugbzMG88XmM8q/QJKhxYYLJlkUn28OMaH8nzuwFSLtmSYPtiiG3IhxwBKebIQRJLHZsayu2bZMOWKytvgBCsnV1MD8DhQYPXxC2e/3xr5rJCxSqW3z62zqaeC/MOVCjfAduOk/z0jbbxwGaTgZ9k116OuwG3facqd9szhoz0vpL8LWX5ixIOEZvnxwr0OvHnQDWT7v1yGfOt7ALgfgdfhHfW3Aa5n+Pbj5yym70eLWHM5HUPZZzqnu7oUIKjbSJRsjHfXqHiXzvDDSXJZDyVj9TtKjbwKeWuA2g1RgkReLrCHsZ8ZK0ZX47iELzzaHChskdIeYertKVx9/L+5hw6MLVup9FaWL8+w7/3PCzd1jNTfw09P9C5EOjCIomUxoQLIQhXoRzGBaytIcxnrXQIY38I1Sdt3LwslHMMZEXdGd+mpPLJwRQvZwVQmcPusL1M6a+4VcV4aRqjWUIiIn7GwJeTkXKDYGCSEoULrEjTGiUqgCY4q5+K/9vYzw9Cje/9BbyJUcJboWMgVd9uCsysX2k5X4gpnIil1+GVe6yv8rSq8ejsukWrcuXbWeXbqO/Xj6HzybtaR7CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2250.93,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MAX',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'TNAAAG4GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW/W/aOhT9VyZrP/KS8LHtCfEqJTAYW/kogb1Oa7VnEhOsJU5kO0Ba9X9/104CgbTbpKotlc+9Pj2+9/q4j2jso67VQMOQBls54nGaFEg/5ZwwL0Nd5CyuUQONxZQEsZtgj6DuBoeCNNASH+woTplE3c57492HCtL/NAAew6pA4+nwEnIX/RLiNFlmCXA3QQ7mpCRutdsdow3UAyo8BS2wJMWmlTuopmpsxmlAGQ6f5agHTzrrsZPgeuykvIzVKvbx4G0xC0guuKlyBys4OhHFTtvfYeaRecohUZSH6odYiNnGJXxHVa3RsnH2hcp26URF9h0p8OWfeyDFa8ry+rby+jpYUNiLllPbtked0dhFeWCRhoqUpWHYQGoxTaM14SXSp7gf+0rXqA07JpS5Emef4pSXxyogyLCsrv5WefhQB13YpY7YgfFiayirP0ul/p3PYBxFhHsUh3bACYmI6mWu4gjkWnJwzqlHWXBUGkcJJ1Aivwi4aRRhrjR8Wz3swt32hx2+f1iOb96ugtVNKkfBbbAeu06r9XbStoajz8NFa7Umf/e3n4dyRFeZs/mXfJuT1TSwQf0la/5XnZbz8SAJEzRmJTbB/GeaEF+V93yikxBnz07qnHFFw2Gw5jxOCJdUdQX1bM5xNtt8IdlXHKZkthGSg4r8880hCpn45w5tpUy6pim8LYmwMCLq8VjEG2l4cWS2LKttNi0TRgxqSx+wBK2mJhZ3KOfo0hPLfr839m0j5oHa2jRvJ9euJv6LMiHVCN+hq97zijR+5XDM/PGgZ6pFT6ddTW4bk9uemS905KXtL9JWb5gxC/0zfrhwr2MvL3SNGa7365hPHa9xFwPwOv6jv9botU3/ntz85ZRdaXOrD+cjKvoMc6q7e2GCSg2EoDEQqFb3aJnnhQG4KIeyseqZIKJPgp4a6CbFTFKZ5Q9ZzdjPwNLRlfnuMffPXg6V1o/ZjnB1d5WvPv7e3P1aRBes8PsySxfn2Xv+54Lrb49Vf4Gfnu5fyHRwEGBQMmZemPoESthWxpQUtjTFkX5rMKcb/KYD59C9zINiiCMaqiNObNXUmTMkxB4sS3/Ta62/stbSL9y6BIaq1Bg8BAbs7A1yMyFJZPTjMCSesiVhjAgDn/KMayrkf83vRYarJ/H+h36E5iAR2LWPKeqiBWdFzh8/KMRXymWaP+WXeUWo+LeiiOrZuNxUadZlqNKyy9CxHU//A0qvRvl6CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2380.94,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [41],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'TNAAAG2GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWa2/bNhT9KwXRj54kK1nXGV4AWa4VdX7FsrsWTdDREi0ToyiBpGwrQf77SEqyZStZBwRJHPDey5NzHzw3T8CPQM/qgBHB8VZ4LM2zyuLmjCEaFqAHBosx6ACfT1GcBhkMEehtIOGoA5bw4CRpTgXoXX8wfv2tYXFvhxLHsBomfzq6NAULtzYxnC2LTGJ3JR3IUA1sW79bxvXHDhhiHirTAgpUXVoFw2aots0YjjGF5EWMtvPEs+07EW77TsxrX6tinw7hFtIYlYS7Kna4kqkjXt10oh2kIZrnTAbyOimXQM5nmwCxHVa1BsvO2Reo26UDFdh3oIyv/zxIULjGtKyvXdZ3ADmWd8Fy6jiOZ3t+AErHIicKlOaEdIA6TPNkjVhtcTF000jx8q7kjQmmgYDFbZqzOq3KJCMsq6e/VRw8tI2BvKVSvJbjRdeyrNEsF/p3OYNpkiAWYkicmCGUINXLksXRUHIpjXOGQ0zjI9M0yRiSJYoqR5AnCWSKw7fV447stj8c8uFx6d+9X8Wru1x48dd47QcD234/ubJG3ufRwl6t0Ud3+3kkPLwqBpu/0Lc5Wk1jR7K/RC3/6sAefDoIRDlOaW2bQPZPnqFIlfd8ojMCixcndU6ZgmFysOYszRATWHUF9B3GYDHb/ImKL5DkaLbhgkkW5ee7Q0Io/+MebIXIeqbJwy1KIDcSHLKUpxthhGli2pZ1ZXYtU46YrC1+hEJyNTUwvwclRg+fUPb7vbG/MlIWq6td8+tkHGjgXzDlQo3wPbjpv8xI228GDNLIH/ZNdejrsJvxsjNe9s3yoD2vXX8VtvnCjBmJzvDlg3sbev2gW8jyeb8N+dTxFnY1AG/DP+prC17L9M/Bzf+cshstbu3hfAJVn+Wc6u5eiKBiI12yMdLRrO5RMs8LI81VOZSMNXOSHp0JeO6AuxxSgUVRLrKWsJ8Za0VX4ruHLDrbHCrMTekOMfV2la4+/Vzco5ZHF6zS+zpKF+fFd/7/Cbd3j9XewM/PD69EDmAcQ8nEpyHJIxTpRTuBWSVLU5joXQMZ3sB3tsxD97J08hFMMFEpjn3vVrV1Nhgh5AyXtcLps86gcdbkL/S6NoxUsaFUETliZ1soKLhAieGmhKBQCRM3PESlUoXGGHPxd/d7FRHoWXz4odfQXJKU6FrJFHTVhLMyl+tPluILZiIvl/llXOWq/rGovHo6Li812nXpajTt0nVsyPO/J2zJf3wJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2137.05,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'TNAAAG3GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWbW/aOhT+K5O1j9wkQNddIW6lBAZj4yUlZHfTWu2axARrjhPZDpBW/e/XdhIIpN0mVW2pfF6ePj7n+Dl9BJMQ9KwWGBEcbcWYJVlaWgYZY4gGOegBZzkFLTDhcxQlXgoDBHobSDhqgRU82HGSUQF6V9fGu/c1y+DjUOIYVs00mY8uTd5yUJkYTld5KrHbkg5kqALudKwro3vdAkPMA2VaQoHKJN8b1kO1bcFwhCkkz2I0nSeeTd+JcNN3Yl75GhX7cAi2kEaoINxWsUNfXh3xMtMOd5AGyM2YDOTVpQYEcr7YeIjtsKo1WLXOvkDVLh2owL4DZXz5516CwjWmRX07RX0dyLHMBau5bdvj7njigcKxzIgCpRkhLaAO8yxeI1ZZBhgOklDxGndlxgxTT8D8Y5Kx6lqlSUZYVk9/qzh4aBo9maWueCXHi65lWcNFJvTvYgaTOEYswJDYEUMoRqqXBYujoeBSGF2GA0yjI9MkThmSJQpLh5fFMWSKwzf/YUd22x82uX5YTW7f+pF/m4lx9DVaTzyn03k761qj8afRsuOv0d+D7aeRGGM/dzb/om8u8ueRLdlfohZ/1ek4Hw4CUY4TWtlmkP3MUhSq8p5PdEpg/uykupQpGCYHy2VJipjAqiugbzMG88XmM8q/QJKhxYYLJlkUn28OMaH8nzuwFSLtmSYPtiiG3IhxwBKebIQRJLHZsayu2bZMOWKytvgBCsnV1MD8DhQYPXxC2e/3xr5rJCxSqW3z62zqaeC/MOVCjfAduOk/z0jbbxwGaTgZ9k116OuwG3facqd9szhoz0vpL8LWX5ixIOEZvnxwr0OvHnQDWT7v1yGfOt7ALgfgdfhHfW3Aa5n+Pbj5yym70eLWHM5HUPZZzqnu7oUIKjbSJRsjHfXqHiXzvDDSXJZDyVj9TtKjbwKeWuA2g1RgkReLrCHsZ8ZK0ZX47iELzzaHChskdIeYertKVx9/L+5hw6MLVup9FaWL8+w7/3PCzd1jNTfw09P9C5EOjCIomUxoQLIQhXoRzGBaytIcxnrXQIY38I1Sdt3LwslHMMZEXdGd+mpPLJwRQvZwVQmcPusL1M6a+4VcV4aRqjWUIiIn7GwJeTkXKDYGCSEoULrEjTGiUqgCY4q5+K/9vYzw9Cje/9BbyJUcJboWMgVd9uCsysX2k5X4gpnIil1+GVe6yv8rSq8ejsukWrcuXbWeXbqO/Xj6HzybtaR7CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2250.93,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MAX',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'TNAAAG4GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW/W/aOhT9VyZrP/KS8LHtCfEqJTAYW/kogb1Oa7VnEhOsJU5kO0Ba9X9/104CgbTbpKotlc+9Pj2+9/q4j2jso67VQMOQBls54nGaFEg/5ZwwL0Nd5CyuUQONxZQEsZtgj6DuBoeCNNASH+woTplE3c57492HCtL/NAAew6pA4+nwEnIX/RLiNFlmCXA3QQ7mpCRutdsdow3UAyo8BS2wJMWmlTuopmpsxmlAGQ6f5agHTzrrsZPgeuykvIzVKvbx4G0xC0guuKlyBys4OhHFTtvfYeaRecohUZSH6odYiNnGJXxHVa3RsnH2hcp26URF9h0p8OWfeyDFa8ry+rby+jpYUNiLllPbtked0dhFeWCRhoqUpWHYQGoxTaM14SXSp7gf+0rXqA07JpS5Emef4pSXxyogyLCsrv5WefhQB13YpY7YgfFiayirP0ul/p3PYBxFhHsUh3bACYmI6mWu4gjkWnJwzqlHWXBUGkcJJ1Aivwi4aRRhrjR8Wz3swt32hx2+f1iOb96ugtVNKkfBbbAeu06r9XbStoajz8NFa7Umf/e3n4dyRFeZs/mXfJuT1TSwQf0la/5XnZbz8SAJEzRmJTbB/GeaEF+V93yikxBnz07qnHFFw2Gw5jxOCJdUdQX1bM5xNtt8IdlXHKZkthGSg4r8880hCpn45w5tpUy6pim8LYmwMCLq8VjEG2l4cWS2LKttNi0TRgxqSx+wBK2mJhZ3KOfo0hPLfr839m0j5oHa2jRvJ9euJv6LMiHVCN+hq97zijR+5XDM/PGgZ6pFT6ddTW4bk9uemS905KXtL9JWb5gxC/0zfrhwr2MvL3SNGa7365hPHa9xFwPwOv6jv9botU3/ntz85ZRdaXOrD+cjKvoMc6q7e2GCSg2EoDEQqFb3aJnnhQG4KIeyseqZIKJPgp4a6CbFTFKZ5Q9ZzdjPwNLRlfnuMffPXg6V1o/ZjnB1d5WvPv7e3P1aRBes8PsySxfn2Xv+54Lrb49Vf4Gfnu5fyHRwEGBQMmZemPoESthWxpQUtjTFkX5rMKcb/KYD59C9zINiiCMaqiNObNXUmTMkxB4sS3/Ta62/stbSL9y6BIaq1Bg8BAbs7A1yMyFJZPTjMCSesiVhjAgDn/KMayrkf83vRYarJ/H+h36E5iAR2LWPKeqiBWdFzh8/KMRXymWaP+WXeUWo+LeiiOrZuNxUadZlqNKyy9CxHU//A0qvRvl6CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2380.94,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [42],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'TNAAAG2GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWa2/bNhT9KwXRj54kK1nXGV4AWa4VdX7FsrsWTdDREi0ToyiBpGwrQf77SEqyZStZBwRJHPDey5NzHzw3T8CPQM/qgBHB8VZ4LM2zyuLmjCEaFqAHBosx6ACfT1GcBhkMEehtIOGoA5bw4CRpTgXoXX8wfv2tYXFvhxLHsBomfzq6NAULtzYxnC2LTGJ3JR3IUA1sW79bxvXHDhhiHirTAgpUXVoFw2aots0YjjGF5EWMtvPEs+07EW77TsxrX6tinw7hFtIYlYS7Kna4kqkjXt10oh2kIZrnTAbyOimXQM5nmwCxHVa1BsvO2Reo26UDFdh3oIyv/zxIULjGtKyvXdZ3ADmWd8Fy6jiOZ3t+AErHIicKlOaEdIA6TPNkjVhtcTF000jx8q7kjQmmgYDFbZqzOq3KJCMsq6e/VRw8tI2BvKVSvJbjRdeyrNEsF/p3OYNpkiAWYkicmCGUINXLksXRUHIpjXOGQ0zjI9M0yRiSJYoqR5AnCWSKw7fV447stj8c8uFx6d+9X8Wru1x48dd47QcD234/ubJG3ufRwl6t0Ud3+3kkPLwqBpu/0Lc5Wk1jR7K/RC3/6sAefDoIRDlOaW2bQPZPnqFIlfd8ojMCixcndU6ZgmFysOYszRATWHUF9B3GYDHb/ImKL5DkaLbhgkkW5ee7Q0Io/+MebIXIeqbJwy1KIDcSHLKUpxthhGli2pZ1ZXYtU46YrC1+hEJyNTUwvwclRg+fUPb7vbG/MlIWq6td8+tkHGjgXzDlQo3wPbjpv8xI228GDNLIH/ZNdejrsJvxsjNe9s3yoD2vXX8VtvnCjBmJzvDlg3sbev2gW8jyeb8N+dTxFnY1AG/DP+prC17L9M/Bzf+cshstbu3hfAJVn+Wc6u5eiKBiI12yMdLRrO5RMs8LI81VOZSMNXOSHp0JeO6AuxxSgUVRLrKWsJ8Za0VX4ruHLDrbHCrMTekOMfV2la4+/Vzco5ZHF6zS+zpKF+fFd/7/Cbd3j9XewM/PD69EDmAcQ8nEpyHJIxTpRTuBWSVLU5joXQMZ3sB3tsxD97J08hFMMFEpjn3vVrV1Nhgh5AyXtcLps86gcdbkL/S6NoxUsaFUETliZ1soKLhAieGmhKBQCRM3PESlUoXGGHPxd/d7FRHoWXz4odfQXJKU6FrJFHTVhLMyl+tPluILZiIvl/llXOWq/rGovHo6Li812nXpajTt0nVsyPO/J2zJf3wJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2090.48,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2137.05,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'TNAAAG3GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWbW/aOhT+K5O1j9wkQNddIW6lBAZj4yUlZHfTWu2axARrjhPZDpBW/e/XdhIIpN0mVW2pfF6ePj7n+Dl9BJMQ9KwWGBEcbcWYJVlaWgYZY4gGOegBZzkFLTDhcxQlXgoDBHobSDhqgRU82HGSUQF6V9fGu/c1y+DjUOIYVs00mY8uTd5yUJkYTld5KrHbkg5kqALudKwro3vdAkPMA2VaQoHKJN8b1kO1bcFwhCkkz2I0nSeeTd+JcNN3Yl75GhX7cAi2kEaoINxWsUNfXh3xMtMOd5AGyM2YDOTVpQYEcr7YeIjtsKo1WLXOvkDVLh2owL4DZXz5516CwjWmRX07RX0dyLHMBau5bdvj7njigcKxzIgCpRkhLaAO8yxeI1ZZBhgOklDxGndlxgxTT8D8Y5Kx6lqlSUZYVk9/qzh4aBo9maWueCXHi65lWcNFJvTvYgaTOEYswJDYEUMoRqqXBYujoeBSGF2GA0yjI9MkThmSJQpLh5fFMWSKwzf/YUd22x82uX5YTW7f+pF/m4lx9DVaTzyn03k761qj8afRsuOv0d+D7aeRGGM/dzb/om8u8ueRLdlfohZ/1ek4Hw4CUY4TWtlmkP3MUhSq8p5PdEpg/uykupQpGCYHy2VJipjAqiugbzMG88XmM8q/QJKhxYYLJlkUn28OMaH8nzuwFSLtmSYPtiiG3IhxwBKebIQRJLHZsayu2bZMOWKytvgBCsnV1MD8DhQYPXxC2e/3xr5rJCxSqW3z62zqaeC/MOVCjfAduOk/z0jbbxwGaTgZ9k116OuwG3facqd9szhoz0vpL8LWX5ixIOEZvnxwr0OvHnQDWT7v1yGfOt7ALgfgdfhHfW3Aa5n+Pbj5yym70eLWHM5HUPZZzqnu7oUIKjbSJRsjHfXqHiXzvDDSXJZDyVj9TtKjbwKeWuA2g1RgkReLrCHsZ8ZK0ZX47iELzzaHChskdIeYertKVx9/L+5hw6MLVup9FaWL8+w7/3PCzd1jNTfw09P9C5EOjCIomUxoQLIQhXoRzGBaytIcxnrXQIY38I1Sdt3LwslHMMZEXdGd+mpPLJwRQvZwVQmcPusL1M6a+4VcV4aRqjWUIiIn7GwJeTkXKDYGCSEoULrEjTGiUqgCY4q5+K/9vYzw9Cje/9BbyJUcJboWMgVd9uCsysX2k5X4gpnIil1+GVe6yv8rSq8ejsukWrcuXbWeXbqO/Xj6HzybtaR7CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2204.36,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2250.93,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MAX',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'TNAAAG4GIS',
                    flightClasses: ['T', 'T', 'T', 'T', 'T', 'T', 'T', 'T'],
                    cabinType: 2,
                    supplier: 'Gol',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW/W/aOhT9VyZrP/KS8LHtCfEqJTAYW/kogb1Oa7VnEhOsJU5kO0Ba9X9/104CgbTbpKotlc+9Pj2+9/q4j2jso67VQMOQBls54nGaFEg/5ZwwL0Nd5CyuUQONxZQEsZtgj6DuBoeCNNASH+woTplE3c57492HCtL/NAAew6pA4+nwEnIX/RLiNFlmCXA3QQ7mpCRutdsdow3UAyo8BS2wJMWmlTuopmpsxmlAGQ6f5agHTzrrsZPgeuykvIzVKvbx4G0xC0guuKlyBys4OhHFTtvfYeaRecohUZSH6odYiNnGJXxHVa3RsnH2hcp26URF9h0p8OWfeyDFa8ry+rby+jpYUNiLllPbtked0dhFeWCRhoqUpWHYQGoxTaM14SXSp7gf+0rXqA07JpS5Emef4pSXxyogyLCsrv5WefhQB13YpY7YgfFiayirP0ul/p3PYBxFhHsUh3bACYmI6mWu4gjkWnJwzqlHWXBUGkcJJ1Aivwi4aRRhrjR8Wz3swt32hx2+f1iOb96ugtVNKkfBbbAeu06r9XbStoajz8NFa7Umf/e3n4dyRFeZs/mXfJuT1TSwQf0la/5XnZbz8SAJEzRmJTbB/GeaEF+V93yikxBnz07qnHFFw2Gw5jxOCJdUdQX1bM5xNtt8IdlXHKZkthGSg4r8880hCpn45w5tpUy6pim8LYmwMCLq8VjEG2l4cWS2LKttNi0TRgxqSx+wBK2mJhZ3KOfo0hPLfr839m0j5oHa2jRvJ9euJv6LMiHVCN+hq97zijR+5XDM/PGgZ6pFT6ddTW4bk9uemS905KXtL9JWb5gxC/0zfrhwr2MvL3SNGa7365hPHa9xFwPwOv6jv9botU3/ntz85ZRdaXOrD+cjKvoMc6q7e2GCSg2EoDEQqFb3aJnnhQG4KIeyseqZIKJPgp4a6CbFTFKZ5Q9ZzdjPwNLRlfnuMffPXg6V1o/ZjnB1d5WvPv7e3P1aRBes8PsySxfn2Xv+54Lrb49Vf4Gfnu5fyHRwEGBQMmZemPoESthWxpQUtjTFkX5rMKcb/KYD59C9zINiiCMaqiNObNXUmTMkxB4sS3/Ta62/stbSL9y6BIaq1Bg8BAbs7A1yMyFJZPTjMCSesiVhjAgDn/KMayrkf83vRYarJ/H+h36E5iAR2LWPKeqiBWdFzh8/KMRXymWaP+WXeUWo+LeiiOrZuNxUadZlqNKyy9CxHU//A0qvRvl6CQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2334.37,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2380.94,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [43],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'Azul',
                    mappedFareName: 'Tarifa 1',
                    fareBasis: 'G00CXMBG',
                    flightClasses: ['G', 'G', 'G', 'G', 'G', 'G', 'G', 'G'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWaW/bOBD9KwXRj64kO9tua3gDSHLtuo2PWHabogm6tETLxFIHSMpHgui375CSfClpCwQ5DM4MHx9nhm/8gAYBalsN1GM0XMk+T7K0tLgZ5yT2d6iNnOkVaqCBGJEw8VLsE9ReYiZIA83w1o6SLJao/dc74+3fRxb3UxdwDOvINBj1zk3e1K1MnKazXQrYTaCDOamAWy2rZXyAiC4VvjJNsSTlprnXPQ7VtjGnIY0xewLDetJ9YFr3HSjXfQfula+Ws49bf4XjkBSUmyq2O4fLE1HutIM1jn0yyTgEiupaLsNCjJce4Wuqso36jZMfVBVMByqwH0gZn/+7A1C8oHGR4VaRYQcLCntR37Lcm6GjYcE8zZiCjDPGGkgtRlm0IBwCv32xJhDkUuwmgeJld2E5pLEn8e5TkvHqWqUJIiyrrX9VHN7WjR7sUldsvocOixeQ12CcSf1ZtGESRYT7FDM75IRERJWzoLY3FGQK44RTn8ZhtYTtKSeQo6B0eFkUYa5IfJ/fr9l69dNm7+5ng+vX83B+ncl+eBMuBp7Tar0eXli9/ufetDVfkPfu6nNP9ul85yy/ke8TMh+FNtA/Ry1OdVrOx60ksaBJXNmGmP+XpSRQGT5t6pTh3ZMNP4m5guHQWROepIRLqgqDOjbneDdefiG7r5hlZLwUkgOL4v+rbcRi8c8tWkmZtk1T+CsSYWFE1OeJSJbS8JPIbFnWhdm0TOgxyC29xxK4mhpY3KICo00PKJvNxthcGAkP1dameTO88jTwGxoLqXr4Fl12nmak7ZceYQw+O6ZadHTYpZX389zu5lUH5qrD8tzK3zbz/KZjFlF6y3O4z553/PaMMQtODoan+DL06qnXkOHhvwz50Ao17L2MveyEvfrWDtAi/ntw85cNeKmFr963D6hsAaUAvyz8mXIqmrAHaqbE5yjxe509zRmY95kC58l1wacviR4b6DrDsaQS+DRLRTwdCGou1iaBEu0N5sHJzFFhbhKvCVdPXunxw++HQlDz6GSWc6KK0ol7Uh7+nHB9Zln12f34ePdMpIPDEAOTQeyzLCCBHtFDnJZqNsKRmgUzzOkSv2rCPRyO46Bwih6OKFNXtO8zBr6x0yPE7s4qXdRrfYGjteZ+pvKVoadyjUF7ik5SjVN1kbcTkkSGmzBGfCVowuiTGBTON66okP82f5QRnm7Uu596fk2AJeBrBVTgZRVO8lzMTVh8pVxmxbeA87jSVX4nKb26Pc43HdXr3HVUtXPXviKP/wPcpt/QtwkAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2249.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MaisAzul',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'G00CXMAT',
                    flightClasses: ['G', 'G', 'G', 'G', 'G', 'G', 'G', 'G'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWaW/bOBD9KwXRj64k29tjDTeALNeu2/iIZbcpmqBLS7RMLEUJpOQjQfTbd0hJvuS0CwQ5DM4MHx9nhm/8iAY+alk11GM0WCV9EaVxYXFSIQj3dqiFOtNrVEMDOSJB5MbYI6i1xEySGprhrR1GKU9Q6693xtv3RxbncxdwDOvINBj1zk3u1ClNgsazXQzYdaCDBSmBG836e+NviOhS6SnTFCek2DR3u8eh2jYWNKAcswsY1kX3gWnVd6Bc9R24l75Kzj5tvRXmAckp11Vsdw6XJ7LYaftrzD0ySQUEyvJaDsNSjpcuEWuqso36tZMfVBZMByqwn0gZn/+7B1C8oDzPcCPPcAdLCntR37Kc26E9Q7l5mjIFyVPGakgtRmm4IAICv3+1JhDkUOxEvuJld2E5pNxN8O5zlIryWoUJIiyrpX9VHN5WjS7sUlesf4AO4wvIqz9OE/2Zt2EUhkR4FDM7EISERJUzp7Y35GRy40RQj/KgXML2WBDIkV843DQMsVAkfswf1my9+mWzdw+zwc3reTC/SZN+cBssBm6n0Xg9bFq9/pfetDFfkA/O6ksv6dP5rrP8Tn5MyHwU2ED/HDU/tdPofNomhEsa8dI2xOLfNCa+yvBpU8cM7y42/IQLBSOgsyYiiolIqCoMattC4N14+ZXsvmGWkvFSJgJY5P9fbUPG5cc7tEqSuGWa0luREEsjpJ6IZLRMDC8KzYZlNc26ZUKPQW7pA06Aq6mB5R3KMVr0gLLZbIxN04hEoLbWzdvhtauB31AuE9XDd+iqfZmRtl+5hDH4bJtq0dZhV1bWzzK7m5UdmKkOyzIre1vPstu2mUfpLc/hPnve8dszxsw/ORie4svQy6deQYaH/zLkQytUsPcy9rIT9upbOUCL+J/Bzd824JUWvmrfPqKiBZQC/LbwZ8qpaMIeqJkSn6PE73X2NGdg3mcKnCfXBZ++JHqqoZsU84QmwKdeKOLpQFBzsTIJlGhvsPBPZo4KcyK+JkI9eaXHj38eCn7Fo5NZzIkySifuojz8f8LVmWVVZ/fT0/0zkR0cBBiYDLjHUp9ADiFdQxwXajbCoZoFMyzoEr9qwj06AnM/d8oeDilTVxxiKu2HlIF/3OkRYndnpTbqtb7E0VrzP1P60tBT+cagP3k3qeYpO8ndyYSEhhMxRjwlatLoEw4q5xnXVCb/1H8WEa5u1vtfeoZNgCngaxVU4EUlTnKdz05YfKMiSfNvAudxhav4XlJ4dYucbzqq2bnrqHLnrn1Vnv4DKO0tRLsJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2364.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [44],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'Azul',
                    mappedFareName: 'Tarifa 1',
                    fareBasis: 'G00CXMBG',
                    flightClasses: ['G', 'G', 'G', 'G', 'G', 'G', 'G', 'G'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWaW/bOBD9KwXRj64kO9tua3gDSHLtuo2PWHabogm6tETLxFKUQFE+EkS/fYeU5EtJWyDIYXCOxzcHZ/yABgFqWw3UYzRcyb6Is6SUuJkQhPs71EbO9Ao10CAdkTD2EuwT1F5ilpIGmuGtHcUZl6j91zvj7d9HEvdTF3AM60g0GPXORd7UrUSCJrNdAthNoIMFqYBbLatlfACLLk19JZpiSUqnudc9NtWysaAh5Zg9gWE9qT4wresOlOu6A/dKV8vZx62/wjwkBeWmsu3OIXiSlp52sMbcJ5NMgGFaheUynKbjpUfEmqpso37j5AdVBdOGCuwHUsLn/+4AFC8oLzLcKjLs4JSCL+pblnszdDQsiKcZU5A8Y6yB1GGURQsiwPDbF2sCRi7FbhwoXnYXjkPKPYl3n+JMVGGVIrCwrLb+VXZ4Wxd64KVCbL6HDuMLyGswzqT+LNowjiIifIqZHQpCIqLKWVDbCwoyhXAiqE95WB3BPREEchSUCi+LIiwUie/z+zVbr37a7N39bHD9eh7OrzPZD2/CxcBzWq3Xwwur1//cm7bmC/LeXX3uyT6d75zlN/J9Quaj0Ab656jFrU7L+biVhKc05pVsiMV/WUICleHTpk4Y3j3Z8BMuFIyAzpqIOCFCUlUY1LGFwLvx8gvZfcUsI+NlKgWwKP6/2kaMp//copWUSds0U39FIpwaEfVFnMZLafhxZLYs68JsWib0GOSW3mMJXE0NnN6iAqNNDyibzcbYXBixCJVr07wZXnka+A3lqVQ9fIsuO08z0vJLjzAGnx1THTra7NLK+3lud/OqA3PVYXlu5W+beX7TMQsr7fIc7rP3Hb89Y8yCk4vhKb4MvXrqNWR4+C9DPrRCDXs/xl52w3761i7QQ/z34OYvG/BSD7563z6gsgXUBPhl4c8mp6IJPlAzNXyOEr+fs6c5A/E+U6A8CRd0Okj02EDXGeaSSuDz4Rii2gdqLdYWgZrZGyyCk5WjzNyYr4lQL16N44ff74SgptG5LNdEZaXz9uR0+HPC9ZVl1Vf34+PdM5YODkMMTAbcZ1lAAr2hhzgph9kIR2oVzLCgS/yqCXE4AvOgUKY9HFGmQrTvMwa6sdMjxO7OqrGozzqAo7PmfjbkK0FP5RrD6CkaSfVN1UTeLpUkMtyYMeKreZYafcJhwPnGFU3lv80fpYWn+/Tup15fE2AJ+HoAKvCyCid5LtYmHL5SIbPiS8C5Xakqv5KUWt0e505H9TpXHVXtXLWvyOP/yhvRvbYJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2249.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MaisAzul',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'G00CXMAT',
                    flightClasses: ['G', 'G', 'G', 'G', 'G', 'G', 'G', 'G'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW+Y/aOBT+VyqrP7JJgO2F2JFCKJR2OIZAO1Vn1DWJCdY6TmQnHDOa/O19dhKuMO1KozmQ3/H5e4ff4xENfNSyaqjHaLBK+iJK40LipEIQ7u1QC3Wm16iGBnJEgsiNsUdQa4mZJDU0w1s7jFKeoNbfb403744kzqcu4BjWkWgw6p2L3KlTigSNZ7sYsOtABwtSAjea9XfGB7DoUukp0RQnpHCau91jUy0bCxpQjtkFDOui+sC0qjtQruoO3EtdJWcft94K84DklOvKtjuH4IksPG1/jblHJqkAQ1mG5TAs5XjpErGmKtuoXzv5QWXBtKEC+4GU8Pm/ewDFC8rzDDfyDHewpOCL+pbl3A7tGcrF05QpSJ4yVkPqMErDBRFg+O2LNQEjh2In8hUvuwvHIeVugnefolSUYRUisLCslv5VdnhbFbrgpUKsv4cO4wvIqz9OE/2Zt2EUhkR4FDM7EISERJUzp7YX5GRy4URQj/KgPIJ7LAjkyC8UbhqGWCgS3+cPa7Ze/bTZ24fZ4Ob1PJjfpEk/uA0WA7fTaLweNq1e/3Nv2pgvyHtn9bmX9Ol811l+I98nZD4KbKB/jprf2ml0Pm4TwiWNeCkbYvFfGhNfZfi0qWOGdxcbfsKFghHQWRMRxUQkVBUGtW0h8G68/EJ2XzFLyXgpEwEs8v+vtiHj8p87tEqSuGWa0luREEsjpJ6IZLRMDC8KzYZlNc26ZUKPQW7pA06Aq6mB5R3KMVr0gLLZbIxN04hEoFzr5u3w2tXAf1EuE9XDd+iqfZmRll+5hDH4bJvq0NZmV1bWzzK7m5UdmKkOyzIre1PPstu2mVtpl+dwn73v+O0ZY+afXAxP8WXo5VOvIMPDfxnyoRUq2Psx9rIb9tO3coEe4n8GN3/bgFd68FX79hEVLaAmwG8LfzY5FU3wgZqp4XOU+P2cPc0ZiPeZAuVJuKDTQaKnGrpJMU9oAnw+HEOU+0CtxcoiUDN7g4V/snKUmRPxNRHqxatx/PjnneBXNDqXxZoorXTeLk6H/0+4urKs6up+erp/xrKDgwADkwH3WOoTX++PIY6LYTbCoVoFMyzoEr9qQhwdgbmfK2UPh5SpEIeYSvshZaAfd3qE2N1ZORr1WQdxdNb8zwZ9KeipfGMYP3kzqd4pG8ndyYSEhhMxRjw106TRJxyGnGdcU5n8W/9RWLi6V+9/6hU2AaaAr4egAi8qcZLrfHXC4SsVSZp/ETi3K1TF15JCq1vk3OmoZueqo8qdq/ZVefoFPSwDnLoJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2364.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [45],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'Azul',
                    mappedFareName: 'Tarifa 1',
                    fareBasis: 'G00CXMBG',
                    flightClasses: ['G', 'G', 'G', 'G', 'G', 'G', 'G', 'G'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWaW/bOBD9KwXRj64kO9tua3gDSHLtuo2PWHabogm6tETLxFKUQFE+EkS/fYeU5EtJWyDIYXCOxzcHZ/yABgFqWw3UYzRcyb6Is6SUuJkQhPs71EbO9Ao10CAdkTD2EuwT1F5ilpIGmuGtHcUZl6j91zvj7d9HEvdTF3AM60g0GPXORd7UrUSCJrNdAthNoIMFqYBbLatlfACLLk19JZpiSUqnudc9NtWysaAh5Zg9gWE9qT4wresOlOu6A/dKV8vZx62/wjwkBeWmsu3OIXiSlp52sMbcJ5NMgGFaheUynKbjpUfEmqpso37j5AdVBdOGCuwHUsLn/+4AFC8oLzLcKjLs4JSCL+pblnszdDQsiKcZU5A8Y6yB1GGURQsiwPDbF2sCRi7FbhwoXnYXjkPKPYl3n+JMVGGVIrCwrLb+VXZ4Wxd64KVCbL6HDuMLyGswzqT+LNowjiIifIqZHQpCIqLKWVDbCwoyhXAiqE95WB3BPREEchSUCi+LIiwUie/z+zVbr37a7N39bHD9eh7OrzPZD2/CxcBzWq3Xwwur1//cm7bmC/LeXX3uyT6d75zlN/J9Quaj0Ab656jFrU7L+biVhKc05pVsiMV/WUICleHTpk4Y3j3Z8BMuFIyAzpqIOCFCUlUY1LGFwLvx8gvZfcUsI+NlKgWwKP6/2kaMp//copWUSds0U39FIpwaEfVFnMZLafhxZLYs68JsWib0GOSW3mMJXE0NnN6iAqNNDyibzcbYXBixCJVr07wZXnka+A3lqVQ9fIsuO08z0vJLjzAGnx1THTra7NLK+3lud/OqA3PVYXlu5W+beX7TMQsr7fIc7rP3Hb89Y8yCk4vhKb4MvXrqNWR4+C9DPrRCDXs/xl52w3761i7QQ/z34OYvG/BSD7563z6gsgXUBPhl4c8mp6IJPlAzNXyOEr+fs6c5A/E+U6A8CRd0Okj02EDXGeaSSuDz4Rii2gdqLdYWgZrZGyyCk5WjzNyYr4lQL16N44ff74SgptG5LNdEZaXz9uR0+HPC9ZVl1Vf34+PdM5YODkMMTAbcZ1lAAr2hhzgph9kIR2oVzLCgS/yqCXE4AvOgUKY9HFGmQrTvMwa6sdMjxO7OqrGozzqAo7PmfjbkK0FP5RrD6CkaSfVN1UTeLpUkMtyYMeKreZYafcJhwPnGFU3lv80fpYWn+/Tup15fE2AJ+HoAKvCyCid5LtYmHL5SIbPiS8C5Xakqv5KUWt0e505H9TpXHVXtXLWvyOP/yhvRvbYJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2202.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2249.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MaisAzul',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'G00CXMAT',
                    flightClasses: ['G', 'G', 'G', 'G', 'G', 'G', 'G', 'G'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW+Y/aOBT+VyqrP7JJgO2F2JFCKJR2OIZAO1Vn1DWJCdY6TmQnHDOa/O19dhKuMO1KozmQ3/H5e4ff4xENfNSyaqjHaLBK+iJK40LipEIQ7u1QC3Wm16iGBnJEgsiNsUdQa4mZJDU0w1s7jFKeoNbfb403744kzqcu4BjWkWgw6p2L3KlTigSNZ7sYsOtABwtSAjea9XfGB7DoUukp0RQnpHCau91jUy0bCxpQjtkFDOui+sC0qjtQruoO3EtdJWcft94K84DklOvKtjuH4IksPG1/jblHJqkAQ1mG5TAs5XjpErGmKtuoXzv5QWXBtKEC+4GU8Pm/ewDFC8rzDDfyDHewpOCL+pbl3A7tGcrF05QpSJ4yVkPqMErDBRFg+O2LNQEjh2In8hUvuwvHIeVugnefolSUYRUisLCslv5VdnhbFbrgpUKsv4cO4wvIqz9OE/2Zt2EUhkR4FDM7EISERJUzp7YX5GRy4URQj/KgPIJ7LAjkyC8UbhqGWCgS3+cPa7Ze/bTZ24fZ4Ob1PJjfpEk/uA0WA7fTaLweNq1e/3Nv2pgvyHtn9bmX9Ol811l+I98nZD4KbKB/jprf2ml0Pm4TwiWNeCkbYvFfGhNfZfi0qWOGdxcbfsKFghHQWRMRxUQkVBUGtW0h8G68/EJ2XzFLyXgpEwEs8v+vtiHj8p87tEqSuGWa0luREEsjpJ6IZLRMDC8KzYZlNc26ZUKPQW7pA06Aq6mB5R3KMVr0gLLZbIxN04hEoFzr5u3w2tXAf1EuE9XDd+iqfZmRll+5hDH4bJvq0NZmV1bWzzK7m5UdmKkOyzIre1PPstu2mVtpl+dwn73v+O0ZY+afXAxP8WXo5VOvIMPDfxnyoRUq2Psx9rIb9tO3coEe4n8GN3/bgFd68FX79hEVLaAmwG8LfzY5FU3wgZqp4XOU+P2cPc0ZiPeZAuVJuKDTQaKnGrpJMU9oAnw+HEOU+0CtxcoiUDN7g4V/snKUmRPxNRHqxatx/PjnneBXNDqXxZoorXTeLk6H/0+4urKs6up+erp/xrKDgwADkwH3WOoTX++PIY6LYTbCoVoFMyzoEr9qQhwdgbmfK2UPh5SpEIeYSvshZaAfd3qE2N1ZORr1WQdxdNb8zwZ9KeipfGMYP3kzqd4pG8ndyYSEhhMxRjw106TRJxyGnGdcU5n8W/9RWLi6V+9/6hU2AaaAr4egAi8qcZLrfHXC4SsVSZp/ETi3K1TF15JCq1vk3OmoZueqo8qdq/ZVefoFPSwDnLoJAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2317.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2364.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [0],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [1],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [28],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UaW/aQBD9K9WqH6ltSI8U0UjYlCPlSgxtqqZqB3sxq67X1u4acKL+9+5hF0JoBRjtm5nnN8fOIxrFqO01UJ+SZCMHPCvyCgkKzjGLStRmBaUNNBJTnGRhDhFG7TVQgRtoAftumhVMovblW+eNd4QEw56icY6h0bR/CoW3QQ1xki/KXHE3lRrguCZuvb58Z6h7REQaugWJq6Bl2Dt2NdiMk4QwoGc5nhsPOp/bDoKf2w7Ka9tpwT7uow2wBFu9Te3aW6rMsagCu/EWWITnBVeOos4poCDEbB1iviW61GjYePJBdbOMoyb7hjT47993RQorwmx5W7a8PgiiYtHw2rvzBk3X9zxkLbcF1aw2CX2YFukK8xoJCARZrIWNuypiQlgooRxmBa/zqiDl4Xlt89V+sH8OhipK5/jmvZovtlJ1jWeFNP92BrM0xTwiQLsJxzjFuplWxl/AirHgnJOIsOSv1CzNOVZFiitDWKQpcC3i6/JhS7ebH1369mExunm5TJY3hRwkd8lqFPqt1svJhdcfXPdvW8sVvgw21305IMvSX3/BX+d4OU107qes9q1+y/+4l5gJkrEamwD/VeQ41vV9OtI5hfLsqM4Z1zRcTdacZznmkui2oE6Xcyhn60+4/Ay0wLO1kFypsM8X+5Qy8eEebaTM264rog1OQTgpiXgmsrV0oix1W5534TY9Vw2Zqi15AKm0uoZY3CPL0SYHlt1u5+wunIwnOrTp3k3GoSF+RZiQeojv0VXnvCKDX/kcWDzqdVx96Bi3q3DcCMcd1x6M5Wy4+9+Er1QfztXpEVWvVCUzL0K/G+imACaJLO3KO7MDzt397g54/GTFaK8gY1vMdY/1DVRX7Nxq8CFJIMFixCJaxNgO9QTyahKmkOp7tABO1vCipTIxmq1R9CElVM/qeDQYLpRx5vcx7vYW9VCZs9F+dDa6T65IDfS1blCNU6V8evXDUkicOkFGKY70MAhngJmajsgZEyF/Nr9VHqEp+vcf5u7PlUpFb6ZHc5v2HI2wxuzSUYfPhMvCbtBTv8pUbfPKagp4GnTo1Knl0K9TS92O338AZuVQ7e0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2574,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgIpSxEbCJhDS8EiMu6maqh3sixl1PLZmxoBT9b/vPOxCCLsCjObch8+998z9haYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1L/qOR+8EyS4Hak0zik0nY/PofAxaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQ49YQh0UhaNTV4MtOEkJw/RijrfGI8+3tiPht7Yj88Z23rCbQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUa3rVcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWi2zvvyZtcub7nIWt5LKnOaovQh3mZrYE3SEBwkCea2P1QRcwICyWubvOSN3XVkPLwvL75aj98eAuGKkrX+OGj0hdbq74mi1Kaf6vBPMuAxwTTYcoBMtDDtDT+AJaMBZecxISlf6jmWcFBNSmpDWGZZZhrEl+ilx3dbb8Pae9lNX14H6XRQykn6VO6noZ+p/N+1vXGk7vxYydaw1WwvRvLCYkqf/MPfFlCNE917edZ7Vv9jn9zkMAEyVmDzTD/WRaQ6P6+lnRBcXVRqkvGdRqulLXkeQFcEj0WNBhyjqvF5hNUnzEtYbERkisW9vnukFEm/n5GWymLvuuKeAsZFk5GYp6LfCOdOM/cjud13bbnKpGp3pIXLBVX1yQWz8jm6JNjlv1+7+y7Ts5THdp2n2b3oUn8F2FCahE/o+vBZUYGv/Y5Zsl0NHD1YWDcrsObVngzcO3BWC6Gu/9b8LWaw6U+/UL1K1XLzIvQ7xZ6KDGTRFZ25V3YAZfu/nCPefJqxWivIGc74HrG+gaqK3ZpNfg4TXEKYspiWiZgRT3DRa2EOc70PVphTjb4XVdVYjhboxjjjFCt1eV9FCrbwh8DDEerRlPmbKifnA3tsxvSAGNNG6u5qU6+vvlhJSRkTpBTCrHWgnAmwJQ4YueeCPmj/bX2CE3Pv303V3+pSKr0Rjw6t5nOiYI1ZneOqvoz4bK0C/TcrzbVy7y2mv6dBx0HdW45juvc0kzj97/MZCxw7AYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2750.11,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5XVjywJVN06xColoVC6AikhW6t12kxigjXHiWwHSKv+9/mRFErZBAT53HtP7uP4PoNRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaB78dE6d/YQ/7ovaax9aDQZHELhzG8ghot5VUjutswGMtQQdz61P1mO9OhjHitoBgWqg6Kwv++qsSnDKaaQHOV4b9zl+d62S/i9bZd5Yzts2NU2XkGaIpNvW7n2I1k54nWgm6whjVFQMunIm5p8AjmfLkPE1li1GgStNx/QDEs7KrIfQIH//v2UpHCBqWlvx7TXgxzLWHB949w7w47tOQ4wlllJFKspQh0mZbZArEF8DP08UYndujJijGkoYHWdl6ypq4akh+N09Vf5we17MJRRqsbzz1JfdCH7mkxLof+NBvMsQyzGkLgpQyhDapgmjVfAJGPAgOEY0/Q11TwrGJJNSmpDWGYZZCqJh+hpTdarXy75+DQf3Z1GaXRXimF6ny5GodfpnI7PnMHwZjDrRAt04a9uBmKIo8pbfkcPAYomqar9kNW81et4V1uBKMc5bbAxZH/KAiWqv28lXRBYHZVqQJmiYVJZAcsLxARWYwE9lzFYTZdfUfUNkhJNl1wwmYV5nmwzQvmXR7ASoujaNo9XKIPcynDMcp4vhRXnmd1xnDO77dhSZLK3+AkKmautifkjMBxdvGPZbDbW5szKWapC2/b9+DbUxB8w5UKJ+BFc9o5npPFLj0GajPo9Wx162u1y5rZmbs82B205Gm7/t+BLOYdjfXoG9Stly/SLwEsL3JWQCiwqs/KO7IBjd9/dQJa8WTHKy8/pGjE1Y3UD5RU7tho8mKYwRXxEY1ImyIh6DItaCROYqXs0hwwv4cm5rETnbIx8ADNMlFaD2dV4FI1PrvzpZDp+OAluo1D6Tr0BQm5/3mhMn3Upe2ddxsGNaYCBKgPKOcrOvt0EYcUFyiw/JwTFShvcGiIqxRJbt5iL3+0ftUeoZ/Dzl14FgUxa0msxKW49rT1FK8zsIHn4hpkozUI99KtN9XKvrbqfh0G7wR1aduM7tDTTefkLbz0iL/wGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2803.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdJ1axCqRsFC68mgDW6d12kxyCdYcJ7IdIJ323+dHMihlExDkcx85997j+wuNEtT1WiikJF3LIc/LokaCknNgcYW6rKS0hUZiAmkeFTgG1F1hKqCF5njXz/KSSdS9eOecewdIcD1QaZxDaDQJj6HoPmggTop5VajcbcUGc2gSdy7O3zqe8hgQEWvoHkuogxbR4NDVYFNOUsIwPZnjpXHP86VtT/ilbc+8sR037MMuXmOWguXb1q6DhaocRB3YTzaYxTAruXIUTU0BxUJMVxHwDdGtRtetZx/UDMs46mRfkQb//fumkuIlYba9HdteHwuiYtH1jffgDS9d3/OQtdyXVGe1RejDpMyWwBskIDjIE03stq8ixoRFElfXecmbumpIeXhe13y1H969BCMVpWs8v1T6YkvV12RaSvNvNZhnGfCYYNpPOUAGepiWxl/AkrHgjJOYsPQv1TwrOKgmJbUhKrMMc03iy+JpQzfr73367mk+unu9SBd3pRymD+lyFPmdzuvxmRcOb8L7zmIJF8H6JpRDsqj81Wf4MoPFJNW1H2e1b/U7/oedBCZIzhpsjPnPsoBE9/e5pAuKq5NSnTGu03ClrBnPC+CS6LGgXp9zXE1XH6H6hGkJ05WQXLGwz1e7jDLx/hGtpSy6riviNWRYOBmJeS7ylXTiPHM7nnfmtj1XiUz1ljxhqbi6JrF4RDZHl+yzbLdbZ3vm5DzVoW33YXwbmcRvCBNSi/gRXfVOMzL4lc8xS0aDnqsPPeN2FYWtKOy59mAsJ8Pd/xZ8peZwqk+/UP1K1TLzIvS7he5KzCSRlV15J3bAqbvf32KePFsx2ivI2Qa4nrG+geqKnVoNPk5TnIIYsZiWCVhRj3FRK2GCM32P5piTFX71VlViOFujCHFGqNbqfDpTpqkfAvQH80ZS5myYH5wN66ML0gChZo3V2FQjn1/8qBISMifIKYVYS0E4Q2BKG7FzS4T80f5ae0Sm5d++m5s/UxxVeqMdndsM50DAGrMrRx0+ES5Luz+P/WpTvctrq2nfcdB+TseW/bSOLc0wfv8BTMAVEusGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2940.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJVKtWxColoVC6QighbdE6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8X8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtD9fGF9cg4Q/6YvaaxDaDQZHEPhzG8ghot5VUjutswGMtQQdy47l5YjPfqYxwqaQYHqoCjsH7pqLGA4xRSSkxzvjfs839v2Cb+37TNvbMcNu97Fa0hTZPJtK9d+JCtHvA50kw2kMZqWTDrypiafQM6DVYjYBqtWg8fWmw9ohqUdFdk3oMB//75LUrjE1LS3Y9rrQY5lLLi5dZ6coWt7jgOMZVYSxWqKUIdJmS0RaxAfQz9PVGJ3rowYYxoKWN3kJWvqqiHp4Thd/VV+cPceDGWUqvHTpdQXXcq+JkEp9L/RYJ5liMUYEjdlCGVIDdOk8RcwyRhwynCMafo31TwrGJJNSmpDWGYZZCqJRfSyIZv1D5dcvMxH9x+iNLovxTB9Spej0Ot0PozPncHwdjDrREv02V/fDsQQR5W3ekSLKYomqar9mNW81et41zuBKMc5bbAxZL/KAiWqv28lXRBYnZTqlDJFw6SypiwvEBNYjQX0XMZgFay+ouoBkhIFKy6YzMI8z3YZofzLM1gLUXRtm8drlEFuZThmOc9XworzzO44zrnddmwpMtlb/AKFzNXWxPwZGI4u3rNst1tre27lLFWhbftpfBdq4o+YcqFE/Ayueqcz0viVxyBNRv2erQ497XY1W7Rmi55tDtpyMtz+b8FXcg6n+vQK6lfKlukXgd8tcF9CKrCozMo7sQNO3X13C1nyZsUoLz+nG8TUjNUNlFfs1GrwYJrCFPERjUmZICPqMSxqJUxgpu7RHDK8gmcXshKdszHyAcwwUVqdzq7Ho2h8du0Hk2C8OJsHU+kaeAOE3P68kZg+60oOzrqKowvTAANVBZRjlI19uwjCiguUWX5OCIqVNLg1RFRqJbbuMBc/299qj1CP4PsPvQmmMmdJr7WkuPWwDgStMLOC5OEBM1GafXrsV5vq3V5bdTuPg/ZzO7bsp3dsaYbz+w8OD5NU+wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3015.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [29],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UaW/aQBD9K9WqH6ltSI8U0UjYlCPlSgxtqqZqB3sxq67X1u4acKL+9+5hF0JoBRjtm5nnN8fOIxrFqO01UJ+SZCMHPCvyCgkKzjGLStRmBaUNNBJTnGRhDhFG7TVQgRtoAftumhVMovblW+eNd4QEw56icY6h0bR/CoW3QQ1xki/KXHE3lRrguCZuvb58Z6h7REQaugWJq6Bl2Dt2NdiMk4QwoGc5nhsPOp/bDoKf2w7Ka9tpwT7uow2wBFu9Te3aW6rMsagCu/EWWITnBVeOos4poCDEbB1iviW61GjYePJBdbOMoyb7hjT47993RQorwmx5W7a8PgiiYtHw2rvzBk3X9zxkLbcF1aw2CX2YFukK8xoJCARZrIWNuypiQlgooRxmBa/zqiDl4Xlt89V+sH8OhipK5/jmvZovtlJ1jWeFNP92BrM0xTwiQLsJxzjFuplWxl/AirHgnJOIsOSv1CzNOVZFiitDWKQpcC3i6/JhS7ebH1369mExunm5TJY3hRwkd8lqFPqt1svJhdcfXPdvW8sVvgw21305IMvSX3/BX+d4OU107qes9q1+y/+4l5gJkrEamwD/VeQ41vV9OtI5hfLsqM4Z1zRcTdacZznmkui2oE6Xcyhn60+4/Ay0wLO1kFypsM8X+5Qy8eEebaTM264rog1OQTgpiXgmsrV0oix1W5534TY9Vw2Zqi15AKm0uoZY3CPL0SYHlt1u5+wunIwnOrTp3k3GoSF+RZiQeojv0VXnvCKDX/kcWDzqdVx96Bi3q3DcCMcd1x6M5Wy4+9+Er1QfztXpEVWvVCUzL0K/G+imACaJLO3KO7MDzt397g54/GTFaK8gY1vMdY/1DVRX7Nxq8CFJIMFixCJaxNgO9QTyahKmkOp7tABO1vCipTIxmq1R9CElVM/qeDQYLpRx5vcx7vYW9VCZs9F+dDa6T65IDfS1blCNU6V8evXDUkicOkFGKY70MAhngJmajsgZEyF/Nr9VHqEp+vcf5u7PlUpFb6ZHc5v2HI2wxuzSUYfPhMvCbtBTv8pUbfPKagp4GnTo1Knl0K9TS92O338AZuVQ7e0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2574,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgIpSxEbCJhDS8EiMu6maqh3sixl1PLZmxoBT9b/vPOxCCLsCjObch8+998z9haYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1L/qOR+8EyS4Hak0zik0nY/PofAxaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQ49YQh0UhaNTV4MtOEkJw/RijrfGI8+3tiPht7Yj88Z23rCbQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUa3rVcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWi2zvvyZtcub7nIWt5LKnOaovQh3mZrYE3SEBwkCea2P1QRcwICyWubvOSN3XVkPLwvL75aj98eAuGKkrX+OGj0hdbq74mi1Kaf6vBPMuAxwTTYcoBMtDDtDT+AJaMBZecxISlf6jmWcFBNSmpDWGZZZhrEl+ilx3dbb8Pae9lNX14H6XRQykn6VO6noZ+p/N+1vXGk7vxYydaw1WwvRvLCYkqf/MPfFlCNE917edZ7Vv9jn9zkMAEyVmDzTD/WRaQ6P6+lnRBcXVRqkvGdRqulLXkeQFcEj0WNBhyjqvF5hNUnzEtYbERkisW9vnukFEm/n5GWymLvuuKeAsZFk5GYp6LfCOdOM/cjud13bbnKpGp3pIXLBVX1yQWz8jm6JNjlv1+7+y7Ts5THdp2n2b3oUn8F2FCahE/o+vBZUYGv/Y5Zsl0NHD1YWDcrsObVngzcO3BWC6Gu/9b8LWaw6U+/UL1K1XLzIvQ7xZ6KDGTRFZ25V3YAZfu/nCPefJqxWivIGc74HrG+gaqK3ZpNfg4TXEKYspiWiZgRT3DRa2EOc70PVphTjb4XVdVYjhboxjjjFCt1eV9FCrbwh8DDEerRlPmbKifnA3tsxvSAGNNG6u5qU6+vvlhJSRkTpBTCrHWgnAmwJQ4YueeCPmj/bX2CE3Pv303V3+pSKr0Rjw6t5nOiYI1ZneOqvoz4bK0C/TcrzbVy7y2mv6dBx0HdW45juvc0kzj97/MZCxw7AYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2750.11,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5XVjywJVN06xColoVC6AikhW6t12kxigjXHiWwHSKv+9/mRFErZBAT53HtP7uP4PoNRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaB78dE6d/YQ/7ovaax9aDQZHELhzG8ghot5VUjutswGMtQQdz61P1mO9OhjHitoBgWqg6Kwv++qsSnDKaaQHOV4b9zl+d62S/i9bZd5Yzts2NU2XkGaIpNvW7n2I1k54nWgm6whjVFQMunIm5p8AjmfLkPE1li1GgStNx/QDEs7KrIfQIH//v2UpHCBqWlvx7TXgxzLWHB949w7w47tOQ4wlllJFKspQh0mZbZArEF8DP08UYndujJijGkoYHWdl6ypq4akh+N09Vf5we17MJRRqsbzz1JfdCH7mkxLof+NBvMsQyzGkLgpQyhDapgmjVfAJGPAgOEY0/Q11TwrGJJNSmpDWGYZZCqJh+hpTdarXy75+DQf3Z1GaXRXimF6ny5GodfpnI7PnMHwZjDrRAt04a9uBmKIo8pbfkcPAYomqar9kNW81et4V1uBKMc5bbAxZH/KAiWqv28lXRBYHZVqQJmiYVJZAcsLxARWYwE9lzFYTZdfUfUNkhJNl1wwmYV5nmwzQvmXR7ASoujaNo9XKIPcynDMcp4vhRXnmd1xnDO77dhSZLK3+AkKmautifkjMBxdvGPZbDbW5szKWapC2/b9+DbUxB8w5UKJ+BFc9o5npPFLj0GajPo9Wx162u1y5rZmbs82B205Gm7/t+BLOYdjfXoG9Stly/SLwEsL3JWQCiwqs/KO7IBjd9/dQJa8WTHKy8/pGjE1Y3UD5RU7tho8mKYwRXxEY1ImyIh6DItaCROYqXs0hwwv4cm5rETnbIx8ADNMlFaD2dV4FI1PrvzpZDp+OAluo1D6Tr0BQm5/3mhMn3Upe2ddxsGNaYCBKgPKOcrOvt0EYcUFyiw/JwTFShvcGiIqxRJbt5iL3+0ftUeoZ/Dzl14FgUxa0msxKW49rT1FK8zsIHn4hpkozUI99KtN9XKvrbqfh0G7wR1aduM7tDTTefkLbz0iL/wGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2803.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdJ1axCqRsFC68mgDW6d12kxyCdYcJ7IdIJ323+dHMihlExDkcx85997j+wuNEtT1WiikJF3LIc/LokaCknNgcYW6rKS0hUZiAmkeFTgG1F1hKqCF5njXz/KSSdS9eOecewdIcD1QaZxDaDQJj6HoPmggTop5VajcbcUGc2gSdy7O3zqe8hgQEWvoHkuogxbR4NDVYFNOUsIwPZnjpXHP86VtT/ilbc+8sR037MMuXmOWguXb1q6DhaocRB3YTzaYxTAruXIUTU0BxUJMVxHwDdGtRtetZx/UDMs46mRfkQb//fumkuIlYba9HdteHwuiYtH1jffgDS9d3/OQtdyXVGe1RejDpMyWwBskIDjIE03stq8ixoRFElfXecmbumpIeXhe13y1H969BCMVpWs8v1T6YkvV12RaSvNvNZhnGfCYYNpPOUAGepiWxl/AkrHgjJOYsPQv1TwrOKgmJbUhKrMMc03iy+JpQzfr73367mk+unu9SBd3pRymD+lyFPmdzuvxmRcOb8L7zmIJF8H6JpRDsqj81Wf4MoPFJNW1H2e1b/U7/oedBCZIzhpsjPnPsoBE9/e5pAuKq5NSnTGu03ClrBnPC+CS6LGgXp9zXE1XH6H6hGkJ05WQXLGwz1e7jDLx/hGtpSy6riviNWRYOBmJeS7ylXTiPHM7nnfmtj1XiUz1ljxhqbi6JrF4RDZHl+yzbLdbZ3vm5DzVoW33YXwbmcRvCBNSi/gRXfVOMzL4lc8xS0aDnqsPPeN2FYWtKOy59mAsJ8Pd/xZ8peZwqk+/UP1K1TLzIvS7he5KzCSRlV15J3bAqbvf32KePFsx2ivI2Qa4nrG+geqKnVoNPk5TnIIYsZiWCVhRj3FRK2GCM32P5piTFX71VlViOFujCHFGqNbqfDpTpqkfAvQH80ZS5myYH5wN66ML0gChZo3V2FQjn1/8qBISMifIKYVYS0E4Q2BKG7FzS4T80f5ae0Sm5d++m5s/UxxVeqMdndsM50DAGrMrRx0+ES5Luz+P/WpTvctrq2nfcdB+TseW/bSOLc0wfv8BTMAVEusGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2940.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJVKtWxColoVC6QighbdE6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8X8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtD9fGF9cg4Q/6YvaaxDaDQZHEPhzG8ghot5VUjutswGMtQQdy47l5YjPfqYxwqaQYHqoCjsH7pqLGA4xRSSkxzvjfs839v2Cb+37TNvbMcNu97Fa0hTZPJtK9d+JCtHvA50kw2kMZqWTDrypiafQM6DVYjYBqtWg8fWmw9ohqUdFdk3oMB//75LUrjE1LS3Y9rrQY5lLLi5dZ6coWt7jgOMZVYSxWqKUIdJmS0RaxAfQz9PVGJ3rowYYxoKWN3kJWvqqiHp4Thd/VV+cPceDGWUqvHTpdQXXcq+JkEp9L/RYJ5liMUYEjdlCGVIDdOk8RcwyRhwynCMafo31TwrGJJNSmpDWGYZZCqJRfSyIZv1D5dcvMxH9x+iNLovxTB9Spej0Ot0PozPncHwdjDrREv02V/fDsQQR5W3ekSLKYomqar9mNW81et41zuBKMc5bbAxZL/KAiWqv28lXRBYnZTqlDJFw6SypiwvEBNYjQX0XMZgFay+ouoBkhIFKy6YzMI8z3YZofzLM1gLUXRtm8drlEFuZThmOc9XworzzO44zrnddmwpMtlb/AKFzNXWxPwZGI4u3rNst1tre27lLFWhbftpfBdq4o+YcqFE/Ayueqcz0viVxyBNRv2erQ497XY1W7Rmi55tDtpyMtz+b8FXcg6n+vQK6lfKlukXgd8tcF9CKrCozMo7sQNO3X13C1nyZsUoLz+nG8TUjNUNlFfs1GrwYJrCFPERjUmZICPqMSxqJUxgpu7RHDK8gmcXshKdszHyAcwwUVqdzq7Ho2h8du0Hk2C8OJsHU+kaeAOE3P68kZg+60oOzrqKowvTAANVBZRjlI19uwjCiguUWX5OCIqVNLg1RFRqJbbuMBc/299qj1CP4PsPvQmmMmdJr7WkuPWwDgStMLOC5OEBM1GafXrsV5vq3V5bdTuPg/ZzO7bsp3dsaYbz+w8OD5NU+wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3015.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [30],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UaW/aQBD9K9WqH6ltSI8U0UjYlCPlSgxtqqZqB3sxq67X1u4acKL+9+5hF0JoBRjtm5nnN8fOIxrFqO01UJ+SZCMHPCvyCgkKzjGLStRmBaUNNBJTnGRhDhFG7TVQgRtoAftumhVMovblW+eNd4QEw56icY6h0bR/CoW3QQ1xki/KXHE3lRrguCZuvb58Z6h7REQaugWJq6Bl2Dt2NdiMk4QwoGc5nhsPOp/bDoKf2w7Ka9tpwT7uow2wBFu9Te3aW6rMsagCu/EWWITnBVeOos4poCDEbB1iviW61GjYePJBdbOMoyb7hjT47993RQorwmx5W7a8PgiiYtHw2rvzBk3X9zxkLbcF1aw2CX2YFukK8xoJCARZrIWNuypiQlgooRxmBa/zqiDl4Xlt89V+sH8OhipK5/jmvZovtlJ1jWeFNP92BrM0xTwiQLsJxzjFuplWxl/AirHgnJOIsOSv1CzNOVZFiitDWKQpcC3i6/JhS7ebH1369mExunm5TJY3hRwkd8lqFPqt1svJhdcfXPdvW8sVvgw21305IMvSX3/BX+d4OU107qes9q1+y/+4l5gJkrEamwD/VeQ41vV9OtI5hfLsqM4Z1zRcTdacZznmkui2oE6Xcyhn60+4/Ay0wLO1kFypsM8X+5Qy8eEebaTM264rog1OQTgpiXgmsrV0oix1W5534TY9Vw2Zqi15AKm0uoZY3CPL0SYHlt1u5+wunIwnOrTp3k3GoSF+RZiQeojv0VXnvCKDX/kcWDzqdVx96Bi3q3DcCMcd1x6M5Wy4+9+Er1QfztXpEVWvVCUzL0K/G+imACaJLO3KO7MDzt397g54/GTFaK8gY1vMdY/1DVRX7Nxq8CFJIMFixCJaxNgO9QTyahKmkOp7tABO1vCipTIxmq1R9CElVM/qeDQYLpRx5vcx7vYW9VCZs9F+dDa6T65IDfS1blCNU6V8evXDUkicOkFGKY70MAhngJmajsgZEyF/Nr9VHqEp+vcf5u7PlUpFb6ZHc5v2HI2wxuzSUYfPhMvCbtBTv8pUbfPKagp4GnTo1Knl0K9TS92O338AZuVQ7e0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2574,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgIpSxEbCJhDS8EiMu6maqh3sixl1PLZmxoBT9b/vPOxCCLsCjObch8+998z9haYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1L/qOR+8EyS4Hak0zik0nY/PofAxaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQ49YQh0UhaNTV4MtOEkJw/RijrfGI8+3tiPht7Yj88Z23rCbQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUa3rVcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWi2zvvyZtcub7nIWt5LKnOaovQh3mZrYE3SEBwkCea2P1QRcwICyWubvOSN3XVkPLwvL75aj98eAuGKkrX+OGj0hdbq74mi1Kaf6vBPMuAxwTTYcoBMtDDtDT+AJaMBZecxISlf6jmWcFBNSmpDWGZZZhrEl+ilx3dbb8Pae9lNX14H6XRQykn6VO6noZ+p/N+1vXGk7vxYydaw1WwvRvLCYkqf/MPfFlCNE917edZ7Vv9jn9zkMAEyVmDzTD/WRaQ6P6+lnRBcXVRqkvGdRqulLXkeQFcEj0WNBhyjqvF5hNUnzEtYbERkisW9vnukFEm/n5GWymLvuuKeAsZFk5GYp6LfCOdOM/cjud13bbnKpGp3pIXLBVX1yQWz8jm6JNjlv1+7+y7Ts5THdp2n2b3oUn8F2FCahE/o+vBZUYGv/Y5Zsl0NHD1YWDcrsObVngzcO3BWC6Gu/9b8LWaw6U+/UL1K1XLzIvQ7xZ6KDGTRFZ25V3YAZfu/nCPefJqxWivIGc74HrG+gaqK3ZpNfg4TXEKYspiWiZgRT3DRa2EOc70PVphTjb4XVdVYjhboxjjjFCt1eV9FCrbwh8DDEerRlPmbKifnA3tsxvSAGNNG6u5qU6+vvlhJSRkTpBTCrHWgnAmwJQ4YueeCPmj/bX2CE3Pv303V3+pSKr0Rjw6t5nOiYI1ZneOqvoz4bK0C/TcrzbVy7y2mv6dBx0HdW45juvc0kzj97/MZCxw7AYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2750.11,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5XVjywJVN06xColoVC6AikhW6t12kxigjXHiWwHSKv+9/mRFErZBAT53HtP7uP4PoNRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaB78dE6d/YQ/7ovaax9aDQZHELhzG8ghot5VUjutswGMtQQdz61P1mO9OhjHitoBgWqg6Kwv++qsSnDKaaQHOV4b9zl+d62S/i9bZd5Yzts2NU2XkGaIpNvW7n2I1k54nWgm6whjVFQMunIm5p8AjmfLkPE1li1GgStNx/QDEs7KrIfQIH//v2UpHCBqWlvx7TXgxzLWHB949w7w47tOQ4wlllJFKspQh0mZbZArEF8DP08UYndujJijGkoYHWdl6ypq4akh+N09Vf5we17MJRRqsbzz1JfdCH7mkxLof+NBvMsQyzGkLgpQyhDapgmjVfAJGPAgOEY0/Q11TwrGJJNSmpDWGYZZCqJh+hpTdarXy75+DQf3Z1GaXRXimF6ny5GodfpnI7PnMHwZjDrRAt04a9uBmKIo8pbfkcPAYomqar9kNW81et4V1uBKMc5bbAxZH/KAiWqv28lXRBYHZVqQJmiYVJZAcsLxARWYwE9lzFYTZdfUfUNkhJNl1wwmYV5nmwzQvmXR7ASoujaNo9XKIPcynDMcp4vhRXnmd1xnDO77dhSZLK3+AkKmautifkjMBxdvGPZbDbW5szKWapC2/b9+DbUxB8w5UKJ+BFc9o5npPFLj0GajPo9Wx162u1y5rZmbs82B205Gm7/t+BLOYdjfXoG9Stly/SLwEsL3JWQCiwqs/KO7IBjd9/dQJa8WTHKy8/pGjE1Y3UD5RU7tho8mKYwRXxEY1ImyIh6DItaCROYqXs0hwwv4cm5rETnbIx8ADNMlFaD2dV4FI1PrvzpZDp+OAluo1D6Tr0BQm5/3mhMn3Upe2ddxsGNaYCBKgPKOcrOvt0EYcUFyiw/JwTFShvcGiIqxRJbt5iL3+0ftUeoZ/Dzl14FgUxa0msxKW49rT1FK8zsIHn4hpkozUI99KtN9XKvrbqfh0G7wR1aduM7tDTTefkLbz0iL/wGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2803.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdJ1axCqRsFC68mgDW6d12kxyCdYcJ7IdIJ323+dHMihlExDkcx85997j+wuNEtT1WiikJF3LIc/LokaCknNgcYW6rKS0hUZiAmkeFTgG1F1hKqCF5njXz/KSSdS9eOecewdIcD1QaZxDaDQJj6HoPmggTop5VajcbcUGc2gSdy7O3zqe8hgQEWvoHkuogxbR4NDVYFNOUsIwPZnjpXHP86VtT/ilbc+8sR037MMuXmOWguXb1q6DhaocRB3YTzaYxTAruXIUTU0BxUJMVxHwDdGtRtetZx/UDMs46mRfkQb//fumkuIlYba9HdteHwuiYtH1jffgDS9d3/OQtdyXVGe1RejDpMyWwBskIDjIE03stq8ixoRFElfXecmbumpIeXhe13y1H969BCMVpWs8v1T6YkvV12RaSvNvNZhnGfCYYNpPOUAGepiWxl/AkrHgjJOYsPQv1TwrOKgmJbUhKrMMc03iy+JpQzfr73367mk+unu9SBd3pRymD+lyFPmdzuvxmRcOb8L7zmIJF8H6JpRDsqj81Wf4MoPFJNW1H2e1b/U7/oedBCZIzhpsjPnPsoBE9/e5pAuKq5NSnTGu03ClrBnPC+CS6LGgXp9zXE1XH6H6hGkJ05WQXLGwz1e7jDLx/hGtpSy6riviNWRYOBmJeS7ylXTiPHM7nnfmtj1XiUz1ljxhqbi6JrF4RDZHl+yzbLdbZ3vm5DzVoW33YXwbmcRvCBNSi/gRXfVOMzL4lc8xS0aDnqsPPeN2FYWtKOy59mAsJ8Pd/xZ8peZwqk+/UP1K1TLzIvS7he5KzCSRlV15J3bAqbvf32KePFsx2ivI2Qa4nrG+geqKnVoNPk5TnIIYsZiWCVhRj3FRK2GCM32P5piTFX71VlViOFujCHFGqNbqfDpTpqkfAvQH80ZS5myYH5wN66ML0gChZo3V2FQjn1/8qBISMifIKYVYS0E4Q2BKG7FzS4T80f5ae0Sm5d++m5s/UxxVeqMdndsM50DAGrMrRx0+ES5Luz+P/WpTvctrq2nfcdB+TseW/bSOLc0wfv8BTMAVEusGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2940.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJVKtWxColoVC6QighbdE6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8X8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtD9fGF9cg4Q/6YvaaxDaDQZHEPhzG8ghot5VUjutswGMtQQdy47l5YjPfqYxwqaQYHqoCjsH7pqLGA4xRSSkxzvjfs839v2Cb+37TNvbMcNu97Fa0hTZPJtK9d+JCtHvA50kw2kMZqWTDrypiafQM6DVYjYBqtWg8fWmw9ohqUdFdk3oMB//75LUrjE1LS3Y9rrQY5lLLi5dZ6coWt7jgOMZVYSxWqKUIdJmS0RaxAfQz9PVGJ3rowYYxoKWN3kJWvqqiHp4Thd/VV+cPceDGWUqvHTpdQXXcq+JkEp9L/RYJ5liMUYEjdlCGVIDdOk8RcwyRhwynCMafo31TwrGJJNSmpDWGYZZCqJRfSyIZv1D5dcvMxH9x+iNLovxTB9Spej0Ot0PozPncHwdjDrREv02V/fDsQQR5W3ekSLKYomqar9mNW81et41zuBKMc5bbAxZL/KAiWqv28lXRBYnZTqlDJFw6SypiwvEBNYjQX0XMZgFay+ouoBkhIFKy6YzMI8z3YZofzLM1gLUXRtm8drlEFuZThmOc9XworzzO44zrnddmwpMtlb/AKFzNXWxPwZGI4u3rNst1tre27lLFWhbftpfBdq4o+YcqFE/Ayueqcz0viVxyBNRv2erQ497XY1W7Rmi55tDtpyMtz+b8FXcg6n+vQK6lfKlukXgd8tcF9CKrCozMo7sQNO3X13C1nyZsUoLz+nG8TUjNUNlFfs1GrwYJrCFPERjUmZICPqMSxqJUxgpu7RHDK8gmcXshKdszHyAcwwUVqdzq7Ho2h8du0Hk2C8OJsHU+kaeAOE3P68kZg+60oOzrqKowvTAANVBZRjlI19uwjCiguUWX5OCIqVNLg1RFRqJbbuMBc/299qj1CP4PsPvQmmMmdJr7WkuPWwDgStMLOC5OEBM1GafXrsV5vq3V5bdTuPg/ZzO7bsp3dsaYbz+w8OD5NU+wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3015.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [2],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [3],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [4],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [5],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [21],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/aOhD9K5XVj9wkZPtEdCUSymPLazfQbtWtek0yBKuOE9kOkK363+tHUliWewUE+cwjZ2aO5xcaJ6jjtdCAknQrhzwvixoJS86BxRXqsJLSFhqLGaR5VOAYUGeDqYAWWuJDL8tLJlHn7RvH906QcNRXaZxTaDwbnEPRXdhAnBTLqlC524oN5tAk9l+9e+u8Vh59ImIN3WEJddAq6p+6GmzOSUoYphdzPDceeT63HQk/tx2ZN7bzhn08xFvMUrB829q1v1KVg6gDe8kOsxgWJVeOoqkppFiI+SYCviO61WjUevJBzbCMo072DWnwv3/fVVK8Jsy217ftDbAgKhaNbrx7b9h2A89D1nJXUp3VFqEPszJbA2+QkOAwTzSxSU9FTAmLJK5GecmbumpIeXhex3y1Hz48ByMVpWt8/V7pi61VX5N5Kc2/1WCeZcBjgmkv5QAZ6GFaGn8BS8aCC05iwtK/VPOs4KCalNSGqMwyzDWJr6vHHd1tf/Tom8fl+PblKl3dlnKY3qfrcRT4/svplTcY3gzu/NUa3oXbm4EcklUVbL7A1wWsZqmu/TyrfWvgBx8PEpggOWuwKeY/ywIS3d+nki4ori5KdcG4TsOVshY8L4BLoseCuj3OcTXffILqM6YlzDdCcsXCPl8cMsrEhwe0lbLouK6It5Bh4WQk5rnIN9KJ88z1Pe/KbXuuEpnqLXnEUnF1TWLxgGyODjlm2e/3zv7KyXmqQ9vu/XQSmcT/ECakFvEDuu5eZmTw64Bjloz7XVcfusbtOpq0oknXtQdjuRju/m/B12oOl/r0C9WvVC0zL0K/W+i2xEwSWdmVd2EHXLr7vT3myZMVo73CnO2A6xnrG6iu2KXVEOA0xSmIMYtpmYAV9RQXtRJmONP3aIk52eAXvqrEcLZGMcAZoVqrk/FwtFTGeTAA6PWXjajM2XA/ORveZ1ekAQaaN1aDU618evWjSkjInDCnFGItBuEMgSl1xM6ECPlv+1vtEZmmf/9h7v5CsVTpjXp0bjOeEwlrzC4ddfhMuCztBj33q031Nq+tpoHnQcdJnVuO8zq3NOP4/QfeOn2H7QYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2563.7,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgJZtEY2ETSCk4ZEYt6maqjvYFzPqeGzNjAGn6n/vPOxCCLsCjObch8+998z9iaYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1P+n53S8EyS4Gak0zik0nY/PofAhaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQw9YQh0UhaNTV4MtOEkJw/RijtfGI8/XtiPh17Yj88Z23rDrQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUY3rRcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWim1vv0Zu8c33PQ9byUFKd1RahD/MyWwNvkIDgIE80sbuhipgRFkpc3eQlb+qqIeXheX3z1X748BoMVZSu8e/3Sl9srfqaLEpp/q0G8ywDHhNMhykHyEAP09L4A1gyFlxyEhOW/qGaZwUH1aSkNoRllmGuSXyJnnd0t/0+pL3n1fT+bZRG96WcpI/pehr6nc7bWdcbT27HD51oDe+C7e1YTkhU+ZvP8GUJ0TzVtZ9ntW/1O/71QQITJGcNNsP8R1lAovv7UtIFxdVFqS4Z12m4UtaS5wVwSfRY0GDIOa4Wm49QfcK0hMVGSK5Y2OebQ0aZ+PCEtlIWfdcV8RYyLJyMxDwX+UY6cZ65Hc/rum3PVSJTvSXPWCqurkksnpDN0SfHLPv93tl3nZynOrTtPs7uQpP4L8KE1CJ+QleDy4wMfuVzzJLpaODqw8C4XYXXrfB64NqDsVwMd/+34Cs1h0t9+onqV6qWmRehXy10X2ImiazsyruwAy7d/eEe8+TFitFeQc52wPWM9Q1UV+zSavBxmuIUxJTFtEzAinqGi1oJc5zpe7TCnGzwm66qxHC2RjHGGaFaq8u7KFS2hT8GGI5WjabM2VA/ORvaZzekAcaaNlZzU518efPDSkjInCCnFGKtBeFMgClxxM4dEfLf9tfaIzQ9//bdXP2lIqnSG/Ho3GY6JwrWmN05qupPhMvSLtBzv9pUL/Paavp3HnQc1LnlOK5zSzONX78B3S7T7ewGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2739.81,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdE/EKpGwULryaANdp3XaLokJ1hwnsh0gnfbf50dSKGUTEORzHzn33uP7G40S1PVaKKQkXcshz8uiRoKSc8ziCnVZSWkLjcQEp3lUQIxRdwVU4Baaw66f5SWTqPvurdPxDpDgYqDSOIfQaBIeQ9FN0ECcFPOqULnbig1w3CTuvH/z2vGUx4CIWEM3IHEdtIgGh64Gm3KSEgb0ZI7nxj3P57Y94ee2PfPGdtywT7t4DSzFlm9buw4WqnIs6sB+sgEW41nJlaNoagooCDFdRZhviG41umg9+aBmWMZRJ/uGNPjv33eVFJaE2fZ2bHt9EETFootL784bfnB9z0PWclNSndUWoQ+TMlti3iABgSBPNLGrvooYExZJqC7ykjd11ZDy8Lyu+Wo/2D0HIxWla3zzQemLLVVfk2kpzb/VYJ5lmMcEaD/lGGdYD9PSeAQsGQvOOIkJSx+p5lnBsWpSUhuiMsuAaxJfFw8buln/6NO3D/PR9ctFurgu5TC9S5ejyO90Xo7PvHB4Gd50Fkv8PlhfhnJIFpW/+oK/zvBikuraj7Pat/od/9NOYiZIzhpsDPxXWeBE9/eppAsK1UmpzhjXabhS1oznBeaS6LGgXp9zqKarz7i6BVri6UpIrljY54tdRpn4eI/WUhZd1xXxGmcgnIzEPBf5Sjpxnrkdzztz256rRKZ6Sx5AKq6uSSzukc3RJfss2+3W2Z45OU91aNu9G19FJvErwoTUIr5H573TjAx+7nNgyWjQc/WhZ9zOo7AVhT3XHozlZLj734LP1RxO9ek3ql+pWmZehP600HUJTBJZ2ZV3Ygecuvv9LfDkyYrRXkHONpjrGesbqK7YqdXgQ5pCisWIxbRMsBX1GIpaCRPI9D2aAycrePFaVWI4W6MIISNUa3U+nSnT1A8x7g/mjaTM2TA/OBvWRxekAULNGtTYVCOfXvyoEhJnTpBTimMtBeEMMVPaiJ0rIuTP9rfaIzIt//7D3PyZ4qjSG+3o3GY4BwLWmF056nBLuCzt/jz2q031Lq+tpn3HQfs5HVv20zq2NMP48xfJfig/6wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2930.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UbW/aOhT+K5W1jywJqdZ7i1ilJBRKbyGUkK1onTaTmGBdx4lsB0in/ff5JRmUsgkI8nPOeXJeHp8fYJyCntMBQ4KzjRixoiobJKgYQzSpQY9WhHTAmE9RVkQlTBDorSHhqAMWcO/lRUUF6P1zZbnOERLcDSSNdQyNp8NTKJoHLcRwuahLyd2V2UCGWmL32r22HOkxwDxR0BwK1ATF0eDYVWMhwxmmkJzleGs85PnWdkj4re2QeWs7bdjtPtlAmiGTb1e5DmJZOeJNoJduIU3QrGLSkbc1BQRyHq4jxLZYtRp87rz6gHZY2lGRfQEK/PPvqySFK0xNe13TXh9yLGPB3b3z5Iw823ccYCzziihWU4Q6TKt8hViLBBgGRaoSe/BkxATTSMD6rqhYW1cDSQ/H6emv8oP7t2Ako1SNH66lvuhK9jUNK6H/jQaLPEcswZB4GUMoR2qYJo3fgEnGgDOGE0yz36kWecmQbFLaGKIqzyFTSSzjly3Zbr555OplMX58F2fxYyVG2VO2Gke+676bXDrD0f1w7sYr9G+wuR+KEY5rf/0ZLWconmaq9lNW81bf9W/3AlGOC9piE8j+r0qUqv6+lnRJYH1WqjPKFA2TypqxokRMYDUW0PcYg3W4/g/VnyCpULjmgskszPNinxPKPz6DjRBlz7Z5skE55FaOE1bwYi2spMht13Eu7a5jS5HJ3uIXKGSutibmz8Bw9PCBZbfbWbtLq2CZCu3aT5OHSBO/x5QLJeJncNM/n5HGb3wGaToe9G116Gu3m/myM1/2bXPQlrPh9l8LvpFzONenH6B5pWyZfhH42QGPFaQCi9qsvDM74Nzd93aQpa9WjPIKCrpFTM1Y3UB5xc6tBh9mGcwQH9OEVCkyop7AslHCFObqHi0gw2t4cSUr0TkbIx/CHBOl1dn8djKOJxe3QTgNJ8uLRTiTrqE/RMgbLFqJ6bOu5Oisqzi5MC0wVFVAOUbZ2NeLIKq5QLkVFISgREmDWyNEpVYS6wFz8b37pfGI9Ai+ftObYCZzlvRaS4pbD+tI0AozK0gePmEmKrNPT/0aU7PbG6tu52nQYW6nlsP0Ti3tcH7+ApdpGvX7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3005.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [6],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [7],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [8],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [22],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/aOhD9K5XVj9wkZPtEdCUSymPLazfQbtWtek0yBKuOE9kOkK363+tHUliWewUE+cwjZ2aO5xcaJ6jjtdCAknQrhzwvixoJS86BxRXqsJLSFhqLGaR5VOAYUGeDqYAWWuJDL8tLJlHn7RvH906QcNRXaZxTaDwbnEPRXdhAnBTLqlC524oN5tAk9l+9e+u8Vh59ImIN3WEJddAq6p+6GmzOSUoYphdzPDceeT63HQk/tx2ZN7bzhn08xFvMUrB829q1v1KVg6gDe8kOsxgWJVeOoqkppFiI+SYCviO61WjUevJBzbCMo072DWnwv3/fVVK8Jsy217ftDbAgKhaNbrx7b9h2A89D1nJXUp3VFqEPszJbA2+QkOAwTzSxSU9FTAmLJK5GecmbumpIeXhex3y1Hz48ByMVpWt8/V7pi61VX5N5Kc2/1WCeZcBjgmkv5QAZ6GFaGn8BS8aCC05iwtK/VPOs4KCalNSGqMwyzDWJr6vHHd1tf/Tom8fl+PblKl3dlnKY3qfrcRT4/svplTcY3gzu/NUa3oXbm4EcklUVbL7A1wWsZqmu/TyrfWvgBx8PEpggOWuwKeY/ywIS3d+nki4ori5KdcG4TsOVshY8L4BLoseCuj3OcTXffILqM6YlzDdCcsXCPl8cMsrEhwe0lbLouK6It5Bh4WQk5rnIN9KJ88z1Pe/KbXuuEpnqLXnEUnF1TWLxgGyODjlm2e/3zv7KyXmqQ9vu/XQSmcT/ECakFvEDuu5eZmTw64Bjloz7XVcfusbtOpq0oknXtQdjuRju/m/B12oOl/r0C9WvVC0zL0K/W+i2xEwSWdmVd2EHXLr7vT3myZMVo73CnO2A6xnrG6iu2KXVEOA0xSmIMYtpmYAV9RQXtRJmONP3aIk52eAXvqrEcLZGMcAZoVqrk/FwtFTGeTAA6PWXjajM2XA/ORveZ1ekAQaaN1aDU618evWjSkjInDCnFGItBuEMgSl1xM6ECPlv+1vtEZmmf/9h7v5CsVTpjXp0bjOeEwlrzC4ddfhMuCztBj33q031Nq+tpoHnQcdJnVuO8zq3NOP4/QfeOn2H7QYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2563.7,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgJZtEY2ETSCk4ZEYt6maqjvYFzPqeGzNjAGn6n/vPOxCCLsCjObch8+998z9iaYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1P+n53S8EyS4Gak0zik0nY/PofAhaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQw9YQh0UhaNTV4MtOEkJw/RijtfGI8/XtiPh17Yj88Z23rDrQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUY3rRcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWim1vv0Zu8c33PQ9byUFKd1RahD/MyWwNvkIDgIE80sbuhipgRFkpc3eQlb+qqIeXheX3z1X748BoMVZSu8e/3Sl9srfqaLEpp/q0G8ywDHhNMhykHyEAP09L4A1gyFlxyEhOW/qGaZwUH1aSkNoRllmGuSXyJnnd0t/0+pL3n1fT+bZRG96WcpI/pehr6nc7bWdcbT27HD51oDe+C7e1YTkhU+ZvP8GUJ0TzVtZ9ntW/1O/71QQITJGcNNsP8R1lAovv7UtIFxdVFqS4Z12m4UtaS5wVwSfRY0GDIOa4Wm49QfcK0hMVGSK5Y2OebQ0aZ+PCEtlIWfdcV8RYyLJyMxDwX+UY6cZ65Hc/rum3PVSJTvSXPWCqurkksnpDN0SfHLPv93tl3nZynOrTtPs7uQpP4L8KE1CJ+QleDy4wMfuVzzJLpaODqw8C4XYXXrfB64NqDsVwMd/+34Cs1h0t9+onqV6qWmRehXy10X2ImiazsyruwAy7d/eEe8+TFitFeQc52wPWM9Q1UV+zSavBxmuIUxJTFtEzAinqGi1oJc5zpe7TCnGzwm66qxHC2RjHGGaFaq8u7KFS2hT8GGI5WjabM2VA/ORvaZzekAcaaNlZzU518efPDSkjInCCnFGKtBeFMgClxxM4dEfLf9tfaIzQ9//bdXP2lIqnSG/Ho3GY6JwrWmN05qupPhMvSLtBzv9pUL/Paavp3HnQc1LnlOK5zSzONX78B3S7T7ewGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2739.81,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdE/EKpGwULryaANdp3XaLokJ1hwnsh0gnfbf50dSKGUTEORzHzn33uP7G40S1PVaKKQkXcshz8uiRoKSc8ziCnVZSWkLjcQEp3lUQIxRdwVU4Baaw66f5SWTqPvurdPxDpDgYqDSOIfQaBIeQ9FN0ECcFPOqULnbig1w3CTuvH/z2vGUx4CIWEM3IHEdtIgGh64Gm3KSEgb0ZI7nxj3P57Y94ee2PfPGdtywT7t4DSzFlm9buw4WqnIs6sB+sgEW41nJlaNoagooCDFdRZhviG41umg9+aBmWMZRJ/uGNPjv33eVFJaE2fZ2bHt9EETFootL784bfnB9z0PWclNSndUWoQ+TMlti3iABgSBPNLGrvooYExZJqC7ykjd11ZDy8Lyu+Wo/2D0HIxWla3zzQemLLVVfk2kpzb/VYJ5lmMcEaD/lGGdYD9PSeAQsGQvOOIkJSx+p5lnBsWpSUhuiMsuAaxJfFw8buln/6NO3D/PR9ctFurgu5TC9S5ejyO90Xo7PvHB4Gd50Fkv8PlhfhnJIFpW/+oK/zvBikuraj7Pat/od/9NOYiZIzhpsDPxXWeBE9/eppAsK1UmpzhjXabhS1oznBeaS6LGgXp9zqKarz7i6BVri6UpIrljY54tdRpn4eI/WUhZd1xXxGmcgnIzEPBf5Sjpxnrkdzztz256rRKZ6Sx5AKq6uSSzukc3RJfss2+3W2Z45OU91aNu9G19FJvErwoTUIr5H573TjAx+7nNgyWjQc/WhZ9zOo7AVhT3XHozlZLj734LP1RxO9ek3ql+pWmZehP600HUJTBJZ2ZV3Ygecuvv9LfDkyYrRXkHONpjrGesbqK7YqdXgQ5pCisWIxbRMsBX1GIpaCRPI9D2aAycrePFaVWI4W6MIISNUa3U+nSnT1A8x7g/mjaTM2TA/OBvWRxekAULNGtTYVCOfXvyoEhJnTpBTimMtBeEMMVPaiJ0rIuTP9rfaIzIt//7D3PyZ4qjSG+3o3GY4BwLWmF056nBLuCzt/jz2q031Lq+tpn3HQfs5HVv20zq2NMP48xfJfig/6wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2930.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UbW/aOhT+K5W1jywJqdZ7i1ilJBRKbyGUkK1onTaTmGBdx4lsB0in/ff5JRmUsgkI8nPOeXJeHp8fYJyCntMBQ4KzjRixoiobJKgYQzSpQY9WhHTAmE9RVkQlTBDorSHhqAMWcO/lRUUF6P1zZbnOERLcDSSNdQyNp8NTKJoHLcRwuahLyd2V2UCGWmL32r22HOkxwDxR0BwK1ATF0eDYVWMhwxmmkJzleGs85PnWdkj4re2QeWs7bdjtPtlAmiGTb1e5DmJZOeJNoJduIU3QrGLSkbc1BQRyHq4jxLZYtRp87rz6gHZY2lGRfQEK/PPvqySFK0xNe13TXh9yLGPB3b3z5Iw823ccYCzziihWU4Q6TKt8hViLBBgGRaoSe/BkxATTSMD6rqhYW1cDSQ/H6emv8oP7t2Ako1SNH66lvuhK9jUNK6H/jQaLPEcswZB4GUMoR2qYJo3fgEnGgDOGE0yz36kWecmQbFLaGKIqzyFTSSzjly3Zbr555OplMX58F2fxYyVG2VO2Gke+676bXDrD0f1w7sYr9G+wuR+KEY5rf/0ZLWconmaq9lNW81bf9W/3AlGOC9piE8j+r0qUqv6+lnRJYH1WqjPKFA2TypqxokRMYDUW0PcYg3W4/g/VnyCpULjmgskszPNinxPKPz6DjRBlz7Z5skE55FaOE1bwYi2spMht13Eu7a5jS5HJ3uIXKGSutibmz8Bw9PCBZbfbWbtLq2CZCu3aT5OHSBO/x5QLJeJncNM/n5HGb3wGaToe9G116Gu3m/myM1/2bXPQlrPh9l8LvpFzONenH6B5pWyZfhH42QGPFaQCi9qsvDM74Nzd93aQpa9WjPIKCrpFTM1Y3UB5xc6tBh9mGcwQH9OEVCkyop7AslHCFObqHi0gw2t4cSUr0TkbIx/CHBOl1dn8djKOJxe3QTgNJ8uLRTiTrqE/RMgbLFqJ6bOu5Oisqzi5MC0wVFVAOUbZ2NeLIKq5QLkVFISgREmDWyNEpVYS6wFz8b37pfGI9Ai+ftObYCZzlvRaS4pbD+tI0AozK0gePmEmKrNPT/0aU7PbG6tu52nQYW6nlsP0Ti3tcH7+ApdpGvX7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3005.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [23],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/aOhD9K5XVj9wkZPtEdCUSymPLazfQbtWtek0yBKuOE9kOkK363+tHUliWewUE+cwjZ2aO5xcaJ6jjtdCAknQrhzwvixoJS86BxRXqsJLSFhqLGaR5VOAYUGeDqYAWWuJDL8tLJlHn7RvH906QcNRXaZxTaDwbnEPRXdhAnBTLqlC524oN5tAk9l+9e+u8Vh59ImIN3WEJddAq6p+6GmzOSUoYphdzPDceeT63HQk/tx2ZN7bzhn08xFvMUrB829q1v1KVg6gDe8kOsxgWJVeOoqkppFiI+SYCviO61WjUevJBzbCMo072DWnwv3/fVVK8Jsy217ftDbAgKhaNbrx7b9h2A89D1nJXUp3VFqEPszJbA2+QkOAwTzSxSU9FTAmLJK5GecmbumpIeXhex3y1Hz48ByMVpWt8/V7pi61VX5N5Kc2/1WCeZcBjgmkv5QAZ6GFaGn8BS8aCC05iwtK/VPOs4KCalNSGqMwyzDWJr6vHHd1tf/Tom8fl+PblKl3dlnKY3qfrcRT4/svplTcY3gzu/NUa3oXbm4EcklUVbL7A1wWsZqmu/TyrfWvgBx8PEpggOWuwKeY/ywIS3d+nki4ori5KdcG4TsOVshY8L4BLoseCuj3OcTXffILqM6YlzDdCcsXCPl8cMsrEhwe0lbLouK6It5Bh4WQk5rnIN9KJ88z1Pe/KbXuuEpnqLXnEUnF1TWLxgGyODjlm2e/3zv7KyXmqQ9vu/XQSmcT/ECakFvEDuu5eZmTw64Bjloz7XVcfusbtOpq0oknXtQdjuRju/m/B12oOl/r0C9WvVC0zL0K/W+i2xEwSWdmVd2EHXLr7vT3myZMVo73CnO2A6xnrG6iu2KXVEOA0xSmIMYtpmYAV9RQXtRJmONP3aIk52eAXvqrEcLZGMcAZoVqrk/FwtFTGeTAA6PWXjajM2XA/ORveZ1ekAQaaN1aDU618evWjSkjInDCnFGItBuEMgSl1xM6ECPlv+1vtEZmmf/9h7v5CsVTpjXp0bjOeEwlrzC4ddfhMuCztBj33q031Nq+tpoHnQcdJnVuO8zq3NOP4/QfeOn2H7QYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2563.7,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgJZtEY2ETSCk4ZEYt6maqjvYFzPqeGzNjAGn6n/vPOxCCLsCjObch8+998z9iaYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1P+n53S8EyS4Gak0zik0nY/PofAhaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQw9YQh0UhaNTV4MtOEkJw/RijtfGI8/XtiPh17Yj88Z23rDrQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUY3rRcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWim1vv0Zu8c33PQ9byUFKd1RahD/MyWwNvkIDgIE80sbuhipgRFkpc3eQlb+qqIeXheX3z1X748BoMVZSu8e/3Sl9srfqaLEpp/q0G8ywDHhNMhykHyEAP09L4A1gyFlxyEhOW/qGaZwUH1aSkNoRllmGuSXyJnnd0t/0+pL3n1fT+bZRG96WcpI/pehr6nc7bWdcbT27HD51oDe+C7e1YTkhU+ZvP8GUJ0TzVtZ9ntW/1O/71QQITJGcNNsP8R1lAovv7UtIFxdVFqS4Z12m4UtaS5wVwSfRY0GDIOa4Wm49QfcK0hMVGSK5Y2OebQ0aZ+PCEtlIWfdcV8RYyLJyMxDwX+UY6cZ65Hc/rum3PVSJTvSXPWCqurkksnpDN0SfHLPv93tl3nZynOrTtPs7uQpP4L8KE1CJ+QleDy4wMfuVzzJLpaODqw8C4XYXXrfB64NqDsVwMd/+34Cs1h0t9+onqV6qWmRehXy10X2ImiazsyruwAy7d/eEe8+TFitFeQc52wPWM9Q1UV+zSavBxmuIUxJTFtEzAinqGi1oJc5zpe7TCnGzwm66qxHC2RjHGGaFaq8u7KFS2hT8GGI5WjabM2VA/ORvaZzekAcaaNlZzU518efPDSkjInCCnFGKtBeFMgClxxM4dEfLf9tfaIzQ9//bdXP2lIqnSG/Ho3GY6JwrWmN05qupPhMvSLtBzv9pUL/Paavp3HnQc1LnlOK5zSzONX78B3S7T7ewGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2739.81,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJqbZuiFVKQqF0BVJCtlbrtJnEBGuOE9kOkFb77/MjGZSyCQjyufee3MfxfQbjFPScDhgSnK3FiBVV2SBBxRiiSQ16tCKkA8Z8irIiKmGCQG8FCUcdsIA7Ly8qKkDv4r3lOgdIcD2QNNYhNJ4Oj6FoHrQQw+WiLiV3V2YDGWqJ3YvuheVIjwHmiYLmUKAmKI4Gh64amzGcYQrJSY7Xxn2er237hF/b9pm3tuOGXe2SNaQZMvl2lesglpUj3gR66QbSBIUVk468rSkgkPPZKkJsg1WrQdh58QHtsLSjIvsGFPjv33dJCpeYmva6pr0+5FjGgusb594ZubbvOMBY5hVRrKYIdZhW+RKxFgkwDIpUJXbryYgJppGA9XVRsbauBpIejtPTX+UHd6/BSEapGt99lPqiS9nXdFYJ/W80WOQ5YgmGxMsYQjlSwzRp/AVMMgYMGU4wzf6mWuQlQ7JJaWOIqjyHTCXxED9tyGb9wyPvnxbjuzdxFt9VYpTdZ8tx5Lvum8m5MxzdDOduvEQfgvXNUIxwXPurr+ghRPE0U7Ufs5q3+q5/tROIclzQFptA9qsqUar6+1LSJYH1SamGlCkaJpUVsqJETGA1FtD3GIP1bPUZ1V8gqdBsxQWTWZjn2S4nlH96BGshyp5t82SNcsitHCes4MVKWEmR267jnNtdx5Yik73FT1DIXG1NzB+B4ejhPct2u7W251bBMhXate8nt5EmfospF0rEj+CyfzojjV/6DNJ0POjb6tDXbpdzrzP3+rY5aMvJcPu/BV/KOZzq0zNoXilbpl8EfnfAXQWpwKI2K+/EDjh1970tZOmLFaO8goJuEFMzVjdQXrFTq8GHWQYzxMc0IVWKjKgnsGyUMIW5ukcLyPAKnr2TleicjZEPYY6J0mo4v5qM48nZVTCbziYPZ+FtHEnfmT9EyBssWo3psy7l4KzLOLoxLTBUZUA5R9nZl5sgqrlAuRUUhKBEaYNbI0SlWBLrFnPxs/ut8Yj0DL7/0KsglElLei0mxa2ndaBohZkdJA9fMBOVWajHfo2pWe6NVffzOGg/uGPLfnzHlnY6v/8A6Z9QpvwGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2793.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdE/EKpGwULryaANdp3XaLokJ1hwnsh0gnfbf50dSKGUTEORzHzn33uP7G40S1PVaKKQkXcshz8uiRoKSc8ziCnVZSWkLjcQEp3lUQIxRdwVU4Baaw66f5SWTqPvurdPxDpDgYqDSOIfQaBIeQ9FN0ECcFPOqULnbig1w3CTuvH/z2vGUx4CIWEM3IHEdtIgGh64Gm3KSEgb0ZI7nxj3P57Y94ee2PfPGdtywT7t4DSzFlm9buw4WqnIs6sB+sgEW41nJlaNoagooCDFdRZhviG41umg9+aBmWMZRJ/uGNPjv33eVFJaE2fZ2bHt9EETFootL784bfnB9z0PWclNSndUWoQ+TMlti3iABgSBPNLGrvooYExZJqC7ykjd11ZDy8Lyu+Wo/2D0HIxWla3zzQemLLVVfk2kpzb/VYJ5lmMcEaD/lGGdYD9PSeAQsGQvOOIkJSx+p5lnBsWpSUhuiMsuAaxJfFw8buln/6NO3D/PR9ctFurgu5TC9S5ejyO90Xo7PvHB4Gd50Fkv8PlhfhnJIFpW/+oK/zvBikuraj7Pat/od/9NOYiZIzhpsDPxXWeBE9/eppAsK1UmpzhjXabhS1oznBeaS6LGgXp9zqKarz7i6BVri6UpIrljY54tdRpn4eI/WUhZd1xXxGmcgnIzEPBf5Sjpxnrkdzztz256rRKZ6Sx5AKq6uSSzukc3RJfss2+3W2Z45OU91aNu9G19FJvErwoTUIr5H573TjAx+7nNgyWjQc/WhZ9zOo7AVhT3XHozlZLj734LP1RxO9ek3ql+pWmZehP600HUJTBJZ2ZV3Ygecuvv9LfDkyYrRXkHONpjrGesbqK7YqdXgQ5pCisWIxbRMsBX1GIpaCRPI9D2aAycrePFaVWI4W6MIISNUa3U+nSnT1A8x7g/mjaTM2TA/OBvWRxekAULNGtTYVCOfXvyoEhJnTpBTimMtBeEMMVPaiJ0rIuTP9rfaIzIt//7D3PyZ4qjSG+3o3GY4BwLWmF056nBLuCzt/jz2q031Lq+tpn3HQfs5HVv20zq2NMP48xfJfig/6wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2930.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UbW/aOhT+K5W1jywJqdZ7i1ilJBRKbyGUkK1onTaTmGBdx4lsB0in/ff5JRmUsgkI8nPOeXJeHp8fYJyCntMBQ4KzjRixoiobJKgYQzSpQY9WhHTAmE9RVkQlTBDorSHhqAMWcO/lRUUF6P1zZbnOERLcDSSNdQyNp8NTKJoHLcRwuahLyd2V2UCGWmL32r22HOkxwDxR0BwK1ATF0eDYVWMhwxmmkJzleGs85PnWdkj4re2QeWs7bdjtPtlAmiGTb1e5DmJZOeJNoJduIU3QrGLSkbc1BQRyHq4jxLZYtRp87rz6gHZY2lGRfQEK/PPvqySFK0xNe13TXh9yLGPB3b3z5Iw823ccYCzziihWU4Q6TKt8hViLBBgGRaoSe/BkxATTSMD6rqhYW1cDSQ/H6emv8oP7t2Ako1SNH66lvuhK9jUNK6H/jQaLPEcswZB4GUMoR2qYJo3fgEnGgDOGE0yz36kWecmQbFLaGKIqzyFTSSzjly3Zbr555OplMX58F2fxYyVG2VO2Gke+676bXDrD0f1w7sYr9G+wuR+KEY5rf/0ZLWconmaq9lNW81bf9W/3AlGOC9piE8j+r0qUqv6+lnRJYH1WqjPKFA2TypqxokRMYDUW0PcYg3W4/g/VnyCpULjmgskszPNinxPKPz6DjRBlz7Z5skE55FaOE1bwYi2spMht13Eu7a5jS5HJ3uIXKGSutibmz8Bw9PCBZbfbWbtLq2CZCu3aT5OHSBO/x5QLJeJncNM/n5HGb3wGaToe9G116Gu3m/myM1/2bXPQlrPh9l8LvpFzONenH6B5pWyZfhH42QGPFaQCi9qsvDM74Nzd93aQpa9WjPIKCrpFTM1Y3UB5xc6tBh9mGcwQH9OEVCkyop7AslHCFObqHi0gw2t4cSUr0TkbIx/CHBOl1dn8djKOJxe3QTgNJ8uLRTiTrqE/RMgbLFqJ6bOu5Oisqzi5MC0wVFVAOUbZ2NeLIKq5QLkVFISgREmDWyNEpVYS6wFz8b37pfGI9Ai+ftObYCZzlvRaS4pbD+tI0AozK0gePmEmKrNPT/0aU7PbG6tu52nQYW6nlsP0Ti3tcH7+ApdpGvX7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3005.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [9],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [10],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [31],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UaW/aQBD9K9WqH6ltSI8U0UjYlCPlSgxtqqZqB3sxq67X1u4acKL+9+5hF0JoBRjtm5nnN8fOIxrFqO01UJ+SZCMHPCvyCgkKzjGLStRmBaUNNBJTnGRhDhFG7TVQgRtoAftumhVMovblW+eNd4QEw56icY6h0bR/CoW3QQ1xki/KXHE3lRrguCZuvb58Z6h7REQaugWJq6Bl2Dt2NdiMk4QwoGc5nhsPOp/bDoKf2w7Ka9tpwT7uow2wBFu9Te3aW6rMsagCu/EWWITnBVeOos4poCDEbB1iviW61GjYePJBdbOMoyb7hjT47993RQorwmx5W7a8PgiiYtHw2rvzBk3X9zxkLbcF1aw2CX2YFukK8xoJCARZrIWNuypiQlgooRxmBa/zqiDl4Xlt89V+sH8OhipK5/jmvZovtlJ1jWeFNP92BrM0xTwiQLsJxzjFuplWxl/AirHgnJOIsOSv1CzNOVZFiitDWKQpcC3i6/JhS7ebH1369mExunm5TJY3hRwkd8lqFPqt1svJhdcfXPdvW8sVvgw21305IMvSX3/BX+d4OU107qes9q1+y/+4l5gJkrEamwD/VeQ41vV9OtI5hfLsqM4Z1zRcTdacZznmkui2oE6Xcyhn60+4/Ay0wLO1kFypsM8X+5Qy8eEebaTM264rog1OQTgpiXgmsrV0oix1W5534TY9Vw2Zqi15AKm0uoZY3CPL0SYHlt1u5+wunIwnOrTp3k3GoSF+RZiQeojv0VXnvCKDX/kcWDzqdVx96Bi3q3DcCMcd1x6M5Wy4+9+Er1QfztXpEVWvVCUzL0K/G+imACaJLO3KO7MDzt397g54/GTFaK8gY1vMdY/1DVRX7Nxq8CFJIMFixCJaxNgO9QTyahKmkOp7tABO1vCipTIxmq1R9CElVM/qeDQYLpRx5vcx7vYW9VCZs9F+dDa6T65IDfS1blCNU6V8evXDUkicOkFGKY70MAhngJmajsgZEyF/Nr9VHqEp+vcf5u7PlUpFb6ZHc5v2HI2wxuzSUYfPhMvCbtBTv8pUbfPKagp4GnTo1Knl0K9TS92O338AZuVQ7e0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2574,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgIpSxEbCJhDS8EiMu6maqh3sixl1PLZmxoBT9b/vPOxCCLsCjObch8+998z9haYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1L/qOR+8EyS4Hak0zik0nY/PofAxaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQ49YQh0UhaNTV4MtOEkJw/RijrfGI8+3tiPht7Yj88Z23rCbQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUa3rVcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWi2zvvyZtcub7nIWt5LKnOaovQh3mZrYE3SEBwkCea2P1QRcwICyWubvOSN3XVkPLwvL75aj98eAuGKkrX+OGj0hdbq74mi1Kaf6vBPMuAxwTTYcoBMtDDtDT+AJaMBZecxISlf6jmWcFBNSmpDWGZZZhrEl+ilx3dbb8Pae9lNX14H6XRQykn6VO6noZ+p/N+1vXGk7vxYydaw1WwvRvLCYkqf/MPfFlCNE917edZ7Vv9jn9zkMAEyVmDzTD/WRaQ6P6+lnRBcXVRqkvGdRqulLXkeQFcEj0WNBhyjqvF5hNUnzEtYbERkisW9vnukFEm/n5GWymLvuuKeAsZFk5GYp6LfCOdOM/cjud13bbnKpGp3pIXLBVX1yQWz8jm6JNjlv1+7+y7Ts5THdp2n2b3oUn8F2FCahE/o+vBZUYGv/Y5Zsl0NHD1YWDcrsObVngzcO3BWC6Gu/9b8LWaw6U+/UL1K1XLzIvQ7xZ6KDGTRFZ25V3YAZfu/nCPefJqxWivIGc74HrG+gaqK3ZpNfg4TXEKYspiWiZgRT3DRa2EOc70PVphTjb4XVdVYjhboxjjjFCt1eV9FCrbwh8DDEerRlPmbKifnA3tsxvSAGNNG6u5qU6+vvlhJSRkTpBTCrHWgnAmwJQ4YueeCPmj/bX2CE3Pv303V3+pSKr0Rjw6t5nOiYI1ZneOqvoz4bK0C/TcrzbVy7y2mv6dBx0HdW45juvc0kzj97/MZCxw7AYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2750.11,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5XVjywJVN06xColoVC6AikhW6t12kxigjXHiWwHSKv+9/mRFErZBAT53HtP7uP4PoNRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaB78dE6d/YQ/7ovaax9aDQZHELhzG8ghot5VUjutswGMtQQdz61P1mO9OhjHitoBgWqg6Kwv++qsSnDKaaQHOV4b9zl+d62S/i9bZd5Yzts2NU2XkGaIpNvW7n2I1k54nWgm6whjVFQMunIm5p8AjmfLkPE1li1GgStNx/QDEs7KrIfQIH//v2UpHCBqWlvx7TXgxzLWHB949w7w47tOQ4wlllJFKspQh0mZbZArEF8DP08UYndujJijGkoYHWdl6ypq4akh+N09Vf5we17MJRRqsbzz1JfdCH7mkxLof+NBvMsQyzGkLgpQyhDapgmjVfAJGPAgOEY0/Q11TwrGJJNSmpDWGYZZCqJh+hpTdarXy75+DQf3Z1GaXRXimF6ny5GodfpnI7PnMHwZjDrRAt04a9uBmKIo8pbfkcPAYomqar9kNW81et4V1uBKMc5bbAxZH/KAiWqv28lXRBYHZVqQJmiYVJZAcsLxARWYwE9lzFYTZdfUfUNkhJNl1wwmYV5nmwzQvmXR7ASoujaNo9XKIPcynDMcp4vhRXnmd1xnDO77dhSZLK3+AkKmautifkjMBxdvGPZbDbW5szKWapC2/b9+DbUxB8w5UKJ+BFc9o5npPFLj0GajPo9Wx162u1y5rZmbs82B205Gm7/t+BLOYdjfXoG9Stly/SLwEsL3JWQCiwqs/KO7IBjd9/dQJa8WTHKy8/pGjE1Y3UD5RU7tho8mKYwRXxEY1ImyIh6DItaCROYqXs0hwwv4cm5rETnbIx8ADNMlFaD2dV4FI1PrvzpZDp+OAluo1D6Tr0BQm5/3mhMn3Upe2ddxsGNaYCBKgPKOcrOvt0EYcUFyiw/JwTFShvcGiIqxRJbt5iL3+0ftUeoZ/Dzl14FgUxa0msxKW49rT1FK8zsIHn4hpkozUI99KtN9XKvrbqfh0G7wR1aduM7tDTTefkLbz0iL/wGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2803.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdJ1axCqRsFC68mgDW6d12kxyCdYcJ7IdIJ323+dHMihlExDkcx85997j+wuNEtT1WiikJF3LIc/LokaCknNgcYW6rKS0hUZiAmkeFTgG1F1hKqCF5njXz/KSSdS9eOecewdIcD1QaZxDaDQJj6HoPmggTop5VajcbcUGc2gSdy7O3zqe8hgQEWvoHkuogxbR4NDVYFNOUsIwPZnjpXHP86VtT/ilbc+8sR037MMuXmOWguXb1q6DhaocRB3YTzaYxTAruXIUTU0BxUJMVxHwDdGtRtetZx/UDMs46mRfkQb//fumkuIlYba9HdteHwuiYtH1jffgDS9d3/OQtdyXVGe1RejDpMyWwBskIDjIE03stq8ixoRFElfXecmbumpIeXhe13y1H969BCMVpWs8v1T6YkvV12RaSvNvNZhnGfCYYNpPOUAGepiWxl/AkrHgjJOYsPQv1TwrOKgmJbUhKrMMc03iy+JpQzfr73367mk+unu9SBd3pRymD+lyFPmdzuvxmRcOb8L7zmIJF8H6JpRDsqj81Wf4MoPFJNW1H2e1b/U7/oedBCZIzhpsjPnPsoBE9/e5pAuKq5NSnTGu03ClrBnPC+CS6LGgXp9zXE1XH6H6hGkJ05WQXLGwz1e7jDLx/hGtpSy6riviNWRYOBmJeS7ylXTiPHM7nnfmtj1XiUz1ljxhqbi6JrF4RDZHl+yzbLdbZ3vm5DzVoW33YXwbmcRvCBNSi/gRXfVOMzL4lc8xS0aDnqsPPeN2FYWtKOy59mAsJ8Pd/xZ8peZwqk+/UP1K1TLzIvS7he5KzCSRlV15J3bAqbvf32KePFsx2ivI2Qa4nrG+geqKnVoNPk5TnIIYsZiWCVhRj3FRK2GCM32P5piTFX71VlViOFujCHFGqNbqfDpTpqkfAvQH80ZS5myYH5wN66ML0gChZo3V2FQjn1/8qBISMifIKYVYS0E4Q2BKG7FzS4T80f5ae0Sm5d++m5s/UxxVeqMdndsM50DAGrMrRx0+ES5Luz+P/WpTvctrq2nfcdB+TseW/bSOLc0wfv8BTMAVEusGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2940.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJVKtWxColoVC6QighbdE6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8X8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtD9fGF9cg4Q/6YvaaxDaDQZHEPhzG8ghot5VUjutswGMtQQdy47l5YjPfqYxwqaQYHqoCjsH7pqLGA4xRSSkxzvjfs839v2Cb+37TNvbMcNu97Fa0hTZPJtK9d+JCtHvA50kw2kMZqWTDrypiafQM6DVYjYBqtWg8fWmw9ohqUdFdk3oMB//75LUrjE1LS3Y9rrQY5lLLi5dZ6coWt7jgOMZVYSxWqKUIdJmS0RaxAfQz9PVGJ3rowYYxoKWN3kJWvqqiHp4Thd/VV+cPceDGWUqvHTpdQXXcq+JkEp9L/RYJ5liMUYEjdlCGVIDdOk8RcwyRhwynCMafo31TwrGJJNSmpDWGYZZCqJRfSyIZv1D5dcvMxH9x+iNLovxTB9Spej0Ot0PozPncHwdjDrREv02V/fDsQQR5W3ekSLKYomqar9mNW81et41zuBKMc5bbAxZL/KAiWqv28lXRBYnZTqlDJFw6SypiwvEBNYjQX0XMZgFay+ouoBkhIFKy6YzMI8z3YZofzLM1gLUXRtm8drlEFuZThmOc9XworzzO44zrnddmwpMtlb/AKFzNXWxPwZGI4u3rNst1tre27lLFWhbftpfBdq4o+YcqFE/Ayueqcz0viVxyBNRv2erQ497XY1W7Rmi55tDtpyMtz+b8FXcg6n+vQK6lfKlukXgd8tcF9CKrCozMo7sQNO3X13C1nyZsUoLz+nG8TUjNUNlFfs1GrwYJrCFPERjUmZICPqMSxqJUxgpu7RHDK8gmcXshKdszHyAcwwUVqdzq7Ho2h8du0Hk2C8OJsHU+kaeAOE3P68kZg+60oOzrqKowvTAANVBZRjlI19uwjCiguUWX5OCIqVNLg1RFRqJbbuMBc/299qj1CP4PsPvQmmMmdJr7WkuPWwDgStMLOC5OEBM1GafXrsV5vq3V5bdTuPg/ZzO7bsp3dsaYbz+w8OD5NU+wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3015.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [32],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UaW/aQBD9K9WqH6ltSI8U0UjYlCPlSgxtqqZqB3sxq67X1u4acKL+9+5hF0JoBRjtm5nnN8fOIxrFqO01UJ+SZCMHPCvyCgkKzjGLStRmBaUNNBJTnGRhDhFG7TVQgRtoAftumhVMovblW+eNd4QEw56icY6h0bR/CoW3QQ1xki/KXHE3lRrguCZuvb58Z6h7REQaugWJq6Bl2Dt2NdiMk4QwoGc5nhsPOp/bDoKf2w7Ka9tpwT7uow2wBFu9Te3aW6rMsagCu/EWWITnBVeOos4poCDEbB1iviW61GjYePJBdbOMoyb7hjT47993RQorwmx5W7a8PgiiYtHw2rvzBk3X9zxkLbcF1aw2CX2YFukK8xoJCARZrIWNuypiQlgooRxmBa/zqiDl4Xlt89V+sH8OhipK5/jmvZovtlJ1jWeFNP92BrM0xTwiQLsJxzjFuplWxl/AirHgnJOIsOSv1CzNOVZFiitDWKQpcC3i6/JhS7ebH1369mExunm5TJY3hRwkd8lqFPqt1svJhdcfXPdvW8sVvgw21305IMvSX3/BX+d4OU107qes9q1+y/+4l5gJkrEamwD/VeQ41vV9OtI5hfLsqM4Z1zRcTdacZznmkui2oE6Xcyhn60+4/Ay0wLO1kFypsM8X+5Qy8eEebaTM264rog1OQTgpiXgmsrV0oix1W5534TY9Vw2Zqi15AKm0uoZY3CPL0SYHlt1u5+wunIwnOrTp3k3GoSF+RZiQeojv0VXnvCKDX/kcWDzqdVx96Bi3q3DcCMcd1x6M5Wy4+9+Er1QfztXpEVWvVCUzL0K/G+imACaJLO3KO7MDzt397g54/GTFaK8gY1vMdY/1DVRX7Nxq8CFJIMFixCJaxNgO9QTyahKmkOp7tABO1vCipTIxmq1R9CElVM/qeDQYLpRx5vcx7vYW9VCZs9F+dDa6T65IDfS1blCNU6V8evXDUkicOkFGKY70MAhngJmajsgZEyF/Nr9VHqEp+vcf5u7PlUpFb6ZHc5v2HI2wxuzSUYfPhMvCbtBTv8pUbfPKagp4GnTo1Knl0K9TS92O338AZuVQ7e0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2574,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgIpSxEbCJhDS8EiMu6maqh3sixl1PLZmxoBT9b/vPOxCCLsCjObch8+998z9haYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1L/qOR+8EyS4Hak0zik0nY/PofAxaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQ49YQh0UhaNTV4MtOEkJw/RijrfGI8+3tiPht7Yj88Z23rCbQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUa3rVcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWi2zvvyZtcub7nIWt5LKnOaovQh3mZrYE3SEBwkCea2P1QRcwICyWubvOSN3XVkPLwvL75aj98eAuGKkrX+OGj0hdbq74mi1Kaf6vBPMuAxwTTYcoBMtDDtDT+AJaMBZecxISlf6jmWcFBNSmpDWGZZZhrEl+ilx3dbb8Pae9lNX14H6XRQykn6VO6noZ+p/N+1vXGk7vxYydaw1WwvRvLCYkqf/MPfFlCNE917edZ7Vv9jn9zkMAEyVmDzTD/WRaQ6P6+lnRBcXVRqkvGdRqulLXkeQFcEj0WNBhyjqvF5hNUnzEtYbERkisW9vnukFEm/n5GWymLvuuKeAsZFk5GYp6LfCOdOM/cjud13bbnKpGp3pIXLBVX1yQWz8jm6JNjlv1+7+y7Ts5THdp2n2b3oUn8F2FCahE/o+vBZUYGv/Y5Zsl0NHD1YWDcrsObVngzcO3BWC6Gu/9b8LWaw6U+/UL1K1XLzIvQ7xZ6KDGTRFZ25V3YAZfu/nCPefJqxWivIGc74HrG+gaqK3ZpNfg4TXEKYspiWiZgRT3DRa2EOc70PVphTjb4XVdVYjhboxjjjFCt1eV9FCrbwh8DDEerRlPmbKifnA3tsxvSAGNNG6u5qU6+vvlhJSRkTpBTCrHWgnAmwJQ4YueeCPmj/bX2CE3Pv303V3+pSKr0Rjw6t5nOiYI1ZneOqvoz4bK0C/TcrzbVy7y2mv6dBx0HdW45juvc0kzj97/MZCxw7AYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2750.11,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5XVjywJVN06xColoVC6AikhW6t12kxigjXHiWwHSKv+9/mRFErZBAT53HtP7uP4PoNRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaB78dE6d/YQ/7ovaax9aDQZHELhzG8ghot5VUjutswGMtQQdz61P1mO9OhjHitoBgWqg6Kwv++qsSnDKaaQHOV4b9zl+d62S/i9bZd5Yzts2NU2XkGaIpNvW7n2I1k54nWgm6whjVFQMunIm5p8AjmfLkPE1li1GgStNx/QDEs7KrIfQIH//v2UpHCBqWlvx7TXgxzLWHB949w7w47tOQ4wlllJFKspQh0mZbZArEF8DP08UYndujJijGkoYHWdl6ypq4akh+N09Vf5we17MJRRqsbzz1JfdCH7mkxLof+NBvMsQyzGkLgpQyhDapgmjVfAJGPAgOEY0/Q11TwrGJJNSmpDWGYZZCqJh+hpTdarXy75+DQf3Z1GaXRXimF6ny5GodfpnI7PnMHwZjDrRAt04a9uBmKIo8pbfkcPAYomqar9kNW81et4V1uBKMc5bbAxZH/KAiWqv28lXRBYHZVqQJmiYVJZAcsLxARWYwE9lzFYTZdfUfUNkhJNl1wwmYV5nmwzQvmXR7ASoujaNo9XKIPcynDMcp4vhRXnmd1xnDO77dhSZLK3+AkKmautifkjMBxdvGPZbDbW5szKWapC2/b9+DbUxB8w5UKJ+BFc9o5npPFLj0GajPo9Wx162u1y5rZmbs82B205Gm7/t+BLOYdjfXoG9Stly/SLwEsL3JWQCiwqs/KO7IBjd9/dQJa8WTHKy8/pGjE1Y3UD5RU7tho8mKYwRXxEY1ImyIh6DItaCROYqXs0hwwv4cm5rETnbIx8ADNMlFaD2dV4FI1PrvzpZDp+OAluo1D6Tr0BQm5/3mhMn3Upe2ddxsGNaYCBKgPKOcrOvt0EYcUFyiw/JwTFShvcGiIqxRJbt5iL3+0ftUeoZ/Dzl14FgUxa0msxKW49rT1FK8zsIHn4hpkozUI99KtN9XKvrbqfh0G7wR1aduM7tDTTefkLbz0iL/wGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2803.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdJ1axCqRsFC68mgDW6d12kxyCdYcJ7IdIJ323+dHMihlExDkcx85997j+wuNEtT1WiikJF3LIc/LokaCknNgcYW6rKS0hUZiAmkeFTgG1F1hKqCF5njXz/KSSdS9eOecewdIcD1QaZxDaDQJj6HoPmggTop5VajcbcUGc2gSdy7O3zqe8hgQEWvoHkuogxbR4NDVYFNOUsIwPZnjpXHP86VtT/ilbc+8sR037MMuXmOWguXb1q6DhaocRB3YTzaYxTAruXIUTU0BxUJMVxHwDdGtRtetZx/UDMs46mRfkQb//fumkuIlYba9HdteHwuiYtH1jffgDS9d3/OQtdyXVGe1RejDpMyWwBskIDjIE03stq8ixoRFElfXecmbumpIeXhe13y1H969BCMVpWs8v1T6YkvV12RaSvNvNZhnGfCYYNpPOUAGepiWxl/AkrHgjJOYsPQv1TwrOKgmJbUhKrMMc03iy+JpQzfr73367mk+unu9SBd3pRymD+lyFPmdzuvxmRcOb8L7zmIJF8H6JpRDsqj81Wf4MoPFJNW1H2e1b/U7/oedBCZIzhpsjPnPsoBE9/e5pAuKq5NSnTGu03ClrBnPC+CS6LGgXp9zXE1XH6H6hGkJ05WQXLGwz1e7jDLx/hGtpSy6riviNWRYOBmJeS7ylXTiPHM7nnfmtj1XiUz1ljxhqbi6JrF4RDZHl+yzbLdbZ3vm5DzVoW33YXwbmcRvCBNSi/gRXfVOMzL4lc8xS0aDnqsPPeN2FYWtKOy59mAsJ8Pd/xZ8peZwqk+/UP1K1TLzIvS7he5KzCSRlV15J3bAqbvf32KePFsx2ivI2Qa4nrG+geqKnVoNPk5TnIIYsZiWCVhRj3FRK2GCM32P5piTFX71VlViOFujCHFGqNbqfDpTpqkfAvQH80ZS5myYH5wN66ML0gChZo3V2FQjn1/8qBISMifIKYVYS0E4Q2BKG7FzS4T80f5ae0Sm5d++m5s/UxxVeqMdndsM50DAGrMrRx0+ES5Luz+P/WpTvctrq2nfcdB+TseW/bSOLc0wfv8BTMAVEusGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2940.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJVKtWxColoVC6QighbdE6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8X8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtD9fGF9cg4Q/6YvaaxDaDQZHEPhzG8ghot5VUjutswGMtQQdy47l5YjPfqYxwqaQYHqoCjsH7pqLGA4xRSSkxzvjfs839v2Cb+37TNvbMcNu97Fa0hTZPJtK9d+JCtHvA50kw2kMZqWTDrypiafQM6DVYjYBqtWg8fWmw9ohqUdFdk3oMB//75LUrjE1LS3Y9rrQY5lLLi5dZ6coWt7jgOMZVYSxWqKUIdJmS0RaxAfQz9PVGJ3rowYYxoKWN3kJWvqqiHp4Thd/VV+cPceDGWUqvHTpdQXXcq+JkEp9L/RYJ5liMUYEjdlCGVIDdOk8RcwyRhwynCMafo31TwrGJJNSmpDWGYZZCqJRfSyIZv1D5dcvMxH9x+iNLovxTB9Spej0Ot0PozPncHwdjDrREv02V/fDsQQR5W3ekSLKYomqar9mNW81et41zuBKMc5bbAxZL/KAiWqv28lXRBYnZTqlDJFw6SypiwvEBNYjQX0XMZgFay+ouoBkhIFKy6YzMI8z3YZofzLM1gLUXRtm8drlEFuZThmOc9XworzzO44zrnddmwpMtlb/AKFzNXWxPwZGI4u3rNst1tre27lLFWhbftpfBdq4o+YcqFE/Ayueqcz0viVxyBNRv2erQ497XY1W7Rmi55tDtpyMtz+b8FXcg6n+vQK6lfKlukXgd8tcF9CKrCozMo7sQNO3X13C1nyZsUoLz+nG8TUjNUNlFfs1GrwYJrCFPERjUmZICPqMSxqJUxgpu7RHDK8gmcXshKdszHyAcwwUVqdzq7Ho2h8du0Hk2C8OJsHU+kaeAOE3P68kZg+60oOzrqKowvTAANVBZRjlI19uwjCiguUWX5OCIqVNLg1RFRqJbbuMBc/299qj1CP4PsPvQmmMmdJr7WkuPWwDgStMLOC5OEBM1GafXrsV5vq3V5bdTuPg/ZzO7bsp3dsaYbz+w8OD5NU+wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3015.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [33],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UaW/aQBD9K9WqH6ltSI8U0UjYlCPlSgxtqqZqB3sxq67X1u4acKL+9+5hF0JoBRjtm5nnN8fOIxrFqO01UJ+SZCMHPCvyCgkKzjGLStRmBaUNNBJTnGRhDhFG7TVQgRtoAftumhVMovblW+eNd4QEw56icY6h0bR/CoW3QQ1xki/KXHE3lRrguCZuvb58Z6h7REQaugWJq6Bl2Dt2NdiMk4QwoGc5nhsPOp/bDoKf2w7Ka9tpwT7uow2wBFu9Te3aW6rMsagCu/EWWITnBVeOos4poCDEbB1iviW61GjYePJBdbOMoyb7hjT47993RQorwmx5W7a8PgiiYtHw2rvzBk3X9zxkLbcF1aw2CX2YFukK8xoJCARZrIWNuypiQlgooRxmBa/zqiDl4Xlt89V+sH8OhipK5/jmvZovtlJ1jWeFNP92BrM0xTwiQLsJxzjFuplWxl/AirHgnJOIsOSv1CzNOVZFiitDWKQpcC3i6/JhS7ebH1369mExunm5TJY3hRwkd8lqFPqt1svJhdcfXPdvW8sVvgw21305IMvSX3/BX+d4OU107qes9q1+y/+4l5gJkrEamwD/VeQ41vV9OtI5hfLsqM4Z1zRcTdacZznmkui2oE6Xcyhn60+4/Ay0wLO1kFypsM8X+5Qy8eEebaTM264rog1OQTgpiXgmsrV0oix1W5534TY9Vw2Zqi15AKm0uoZY3CPL0SYHlt1u5+wunIwnOrTp3k3GoSF+RZiQeojv0VXnvCKDX/kcWDzqdVx96Bi3q3DcCMcd1x6M5Wy4+9+Er1QfztXpEVWvVCUzL0K/G+imACaJLO3KO7MDzt397g54/GTFaK8gY1vMdY/1DVRX7Nxq8CFJIMFixCJaxNgO9QTyahKmkOp7tABO1vCipTIxmq1R9CElVM/qeDQYLpRx5vcx7vYW9VCZs9F+dDa6T65IDfS1blCNU6V8evXDUkicOkFGKY70MAhngJmajsgZEyF/Nr9VHqEp+vcf5u7PlUpFb6ZHc5v2HI2wxuzSUYfPhMvCbtBTv8pUbfPKagp4GnTo1Knl0K9TS92O338AZuVQ7e0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2574,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgIpSxEbCJhDS8EiMu6maqh3sixl1PLZmxoBT9b/vPOxCCLsCjObch8+998z9haYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1L/qOR+8EyS4Hak0zik0nY/PofAxaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQ49YQh0UhaNTV4MtOEkJw/RijrfGI8+3tiPht7Yj88Z23rCbQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUa3rVcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWi2zvvyZtcub7nIWt5LKnOaovQh3mZrYE3SEBwkCea2P1QRcwICyWubvOSN3XVkPLwvL75aj98eAuGKkrX+OGj0hdbq74mi1Kaf6vBPMuAxwTTYcoBMtDDtDT+AJaMBZecxISlf6jmWcFBNSmpDWGZZZhrEl+ilx3dbb8Pae9lNX14H6XRQykn6VO6noZ+p/N+1vXGk7vxYydaw1WwvRvLCYkqf/MPfFlCNE917edZ7Vv9jn9zkMAEyVmDzTD/WRaQ6P6+lnRBcXVRqkvGdRqulLXkeQFcEj0WNBhyjqvF5hNUnzEtYbERkisW9vnukFEm/n5GWymLvuuKeAsZFk5GYp6LfCOdOM/cjud13bbnKpGp3pIXLBVX1yQWz8jm6JNjlv1+7+y7Ts5THdp2n2b3oUn8F2FCahE/o+vBZUYGv/Y5Zsl0NHD1YWDcrsObVngzcO3BWC6Gu/9b8LWaw6U+/UL1K1XLzIvQ7xZ6KDGTRFZ25V3YAZfu/nCPefJqxWivIGc74HrG+gaqK3ZpNfg4TXEKYspiWiZgRT3DRa2EOc70PVphTjb4XVdVYjhboxjjjFCt1eV9FCrbwh8DDEerRlPmbKifnA3tsxvSAGNNG6u5qU6+vvlhJSRkTpBTCrHWgnAmwJQ4YueeCPmj/bX2CE3Pv303V3+pSKr0Rjw6t5nOiYI1ZneOqvoz4bK0C/TcrzbVy7y2mv6dBx0HdW45juvc0kzj97/MZCxw7AYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2750.11,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5XVjywJVN06xColoVC6AikhW6t12kxigjXHiWwHSKv+9/mRFErZBAT53HtP7uP4PoNRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaB78dE6d/YQ/7ovaax9aDQZHELhzG8ghot5VUjutswGMtQQdz61P1mO9OhjHitoBgWqg6Kwv++qsSnDKaaQHOV4b9zl+d62S/i9bZd5Yzts2NU2XkGaIpNvW7n2I1k54nWgm6whjVFQMunIm5p8AjmfLkPE1li1GgStNx/QDEs7KrIfQIH//v2UpHCBqWlvx7TXgxzLWHB949w7w47tOQ4wlllJFKspQh0mZbZArEF8DP08UYndujJijGkoYHWdl6ypq4akh+N09Vf5we17MJRRqsbzz1JfdCH7mkxLof+NBvMsQyzGkLgpQyhDapgmjVfAJGPAgOEY0/Q11TwrGJJNSmpDWGYZZCqJh+hpTdarXy75+DQf3Z1GaXRXimF6ny5GodfpnI7PnMHwZjDrRAt04a9uBmKIo8pbfkcPAYomqar9kNW81et4V1uBKMc5bbAxZH/KAiWqv28lXRBYHZVqQJmiYVJZAcsLxARWYwE9lzFYTZdfUfUNkhJNl1wwmYV5nmwzQvmXR7ASoujaNo9XKIPcynDMcp4vhRXnmd1xnDO77dhSZLK3+AkKmautifkjMBxdvGPZbDbW5szKWapC2/b9+DbUxB8w5UKJ+BFc9o5npPFLj0GajPo9Wx162u1y5rZmbs82B205Gm7/t+BLOYdjfXoG9Stly/SLwEsL3JWQCiwqs/KO7IBjd9/dQJa8WTHKy8/pGjE1Y3UD5RU7tho8mKYwRXxEY1ImyIh6DItaCROYqXs0hwwv4cm5rETnbIx8ADNMlFaD2dV4FI1PrvzpZDp+OAluo1D6Tr0BQm5/3mhMn3Upe2ddxsGNaYCBKgPKOcrOvt0EYcUFyiw/JwTFShvcGiIqxRJbt5iL3+0ftUeoZ/Dzl14FgUxa0msxKW49rT1FK8zsIHn4hpkozUI99KtN9XKvrbqfh0G7wR1aduM7tDTTefkLbz0iL/wGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2803.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdJ1axCqRsFC68mgDW6d12kxyCdYcJ7IdIJ323+dHMihlExDkcx85997j+wuNEtT1WiikJF3LIc/LokaCknNgcYW6rKS0hUZiAmkeFTgG1F1hKqCF5njXz/KSSdS9eOecewdIcD1QaZxDaDQJj6HoPmggTop5VajcbcUGc2gSdy7O3zqe8hgQEWvoHkuogxbR4NDVYFNOUsIwPZnjpXHP86VtT/ilbc+8sR037MMuXmOWguXb1q6DhaocRB3YTzaYxTAruXIUTU0BxUJMVxHwDdGtRtetZx/UDMs46mRfkQb//fumkuIlYba9HdteHwuiYtH1jffgDS9d3/OQtdyXVGe1RejDpMyWwBskIDjIE03stq8ixoRFElfXecmbumpIeXhe13y1H969BCMVpWs8v1T6YkvV12RaSvNvNZhnGfCYYNpPOUAGepiWxl/AkrHgjJOYsPQv1TwrOKgmJbUhKrMMc03iy+JpQzfr73367mk+unu9SBd3pRymD+lyFPmdzuvxmRcOb8L7zmIJF8H6JpRDsqj81Wf4MoPFJNW1H2e1b/U7/oedBCZIzhpsjPnPsoBE9/e5pAuKq5NSnTGu03ClrBnPC+CS6LGgXp9zXE1XH6H6hGkJ05WQXLGwz1e7jDLx/hGtpSy6riviNWRYOBmJeS7ylXTiPHM7nnfmtj1XiUz1ljxhqbi6JrF4RDZHl+yzbLdbZ3vm5DzVoW33YXwbmcRvCBNSi/gRXfVOMzL4lc8xS0aDnqsPPeN2FYWtKOy59mAsJ8Pd/xZ8peZwqk+/UP1K1TLzIvS7he5KzCSRlV15J3bAqbvf32KePFsx2ivI2Qa4nrG+geqKnVoNPk5TnIIYsZiWCVhRj3FRK2GCM32P5piTFX71VlViOFujCHFGqNbqfDpTpqkfAvQH80ZS5myYH5wN66ML0gChZo3V2FQjn1/8qBISMifIKYVYS0E4Q2BKG7FzS4T80f5ae0Sm5d++m5s/UxxVeqMdndsM50DAGrMrRx0+ES5Luz+P/WpTvctrq2nfcdB+TseW/bSOLc0wfv8BTMAVEusGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2940.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJVKtWxColoVC6QighbdE6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8X8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtD9fGF9cg4Q/6YvaaxDaDQZHEPhzG8ghot5VUjutswGMtQQdy47l5YjPfqYxwqaQYHqoCjsH7pqLGA4xRSSkxzvjfs839v2Cb+37TNvbMcNu97Fa0hTZPJtK9d+JCtHvA50kw2kMZqWTDrypiafQM6DVYjYBqtWg8fWmw9ohqUdFdk3oMB//75LUrjE1LS3Y9rrQY5lLLi5dZ6coWt7jgOMZVYSxWqKUIdJmS0RaxAfQz9PVGJ3rowYYxoKWN3kJWvqqiHp4Thd/VV+cPceDGWUqvHTpdQXXcq+JkEp9L/RYJ5liMUYEjdlCGVIDdOk8RcwyRhwynCMafo31TwrGJJNSmpDWGYZZCqJRfSyIZv1D5dcvMxH9x+iNLovxTB9Spej0Ot0PozPncHwdjDrREv02V/fDsQQR5W3ekSLKYomqar9mNW81et41zuBKMc5bbAxZL/KAiWqv28lXRBYnZTqlDJFw6SypiwvEBNYjQX0XMZgFay+ouoBkhIFKy6YzMI8z3YZofzLM1gLUXRtm8drlEFuZThmOc9XworzzO44zrnddmwpMtlb/AKFzNXWxPwZGI4u3rNst1tre27lLFWhbftpfBdq4o+YcqFE/Ayueqcz0viVxyBNRv2erQ497XY1W7Rmi55tDtpyMtz+b8FXcg6n+vQK6lfKlukXgd8tcF9CKrCozMo7sQNO3X13C1nyZsUoLz+nG8TUjNUNlFfs1GrwYJrCFPERjUmZICPqMSxqJUxgpu7RHDK8gmcXshKdszHyAcwwUVqdzq7Ho2h8du0Hk2C8OJsHU+kaeAOE3P68kZg+60oOzrqKowvTAANVBZRjlI19uwjCiguUWX5OCIqVNLg1RFRqJbbuMBc/299qj1CP4PsPvQmmMmdJr7WkuPWwDgStMLOC5OEBM1GafXrsV5vq3V5bdTuPg/ZzO7bsp3dsaYbz+w8OD5NU+wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3015.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [34],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UaW/aQBD9K9WqH6ltSI8U0UjYlCPlSgxtqqZqB3sxq67X1u4acKL+9+5hF0JoBRjtm5nnN8fOIxrFqO01UJ+SZCMHPCvyCgkKzjGLStRmBaUNNBJTnGRhDhFG7TVQgRtoAftumhVMovblW+eNd4QEw56icY6h0bR/CoW3QQ1xki/KXHE3lRrguCZuvb58Z6h7REQaugWJq6Bl2Dt2NdiMk4QwoGc5nhsPOp/bDoKf2w7Ka9tpwT7uow2wBFu9Te3aW6rMsagCu/EWWITnBVeOos4poCDEbB1iviW61GjYePJBdbOMoyb7hjT47993RQorwmx5W7a8PgiiYtHw2rvzBk3X9zxkLbcF1aw2CX2YFukK8xoJCARZrIWNuypiQlgooRxmBa/zqiDl4Xlt89V+sH8OhipK5/jmvZovtlJ1jWeFNP92BrM0xTwiQLsJxzjFuplWxl/AirHgnJOIsOSv1CzNOVZFiitDWKQpcC3i6/JhS7ebH1369mExunm5TJY3hRwkd8lqFPqt1svJhdcfXPdvW8sVvgw21305IMvSX3/BX+d4OU107qes9q1+y/+4l5gJkrEamwD/VeQ41vV9OtI5hfLsqM4Z1zRcTdacZznmkui2oE6Xcyhn60+4/Ay0wLO1kFypsM8X+5Qy8eEebaTM264rog1OQTgpiXgmsrV0oix1W5534TY9Vw2Zqi15AKm0uoZY3CPL0SYHlt1u5+wunIwnOrTp3k3GoSF+RZiQeojv0VXnvCKDX/kcWDzqdVx96Bi3q3DcCMcd1x6M5Wy4+9+Er1QfztXpEVWvVCUzL0K/G+imACaJLO3KO7MDzt397g54/GTFaK8gY1vMdY/1DVRX7Nxq8CFJIMFixCJaxNgO9QTyahKmkOp7tABO1vCipTIxmq1R9CElVM/qeDQYLpRx5vcx7vYW9VCZs9F+dDa6T65IDfS1blCNU6V8evXDUkicOkFGKY70MAhngJmajsgZEyF/Nr9VHqEp+vcf5u7PlUpFb6ZHc5v2HI2wxuzSUYfPhMvCbtBTv8pUbfPKagp4GnTo1Knl0K9TS92O338AZuVQ7e0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2574,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgIpSxEbCJhDS8EiMu6maqh3sixl1PLZmxoBT9b/vPOxCCLsCjObch8+998z9haYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1L/qOR+8EyS4Hak0zik0nY/PofAxaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQ49YQh0UhaNTV4MtOEkJw/RijrfGI8+3tiPht7Yj88Z23rCbQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUa3rVcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWi2zvvyZtcub7nIWt5LKnOaovQh3mZrYE3SEBwkCea2P1QRcwICyWubvOSN3XVkPLwvL75aj98eAuGKkrX+OGj0hdbq74mi1Kaf6vBPMuAxwTTYcoBMtDDtDT+AJaMBZecxISlf6jmWcFBNSmpDWGZZZhrEl+ilx3dbb8Pae9lNX14H6XRQykn6VO6noZ+p/N+1vXGk7vxYydaw1WwvRvLCYkqf/MPfFlCNE917edZ7Vv9jn9zkMAEyVmDzTD/WRaQ6P6+lnRBcXVRqkvGdRqulLXkeQFcEj0WNBhyjqvF5hNUnzEtYbERkisW9vnukFEm/n5GWymLvuuKeAsZFk5GYp6LfCOdOM/cjud13bbnKpGp3pIXLBVX1yQWz8jm6JNjlv1+7+y7Ts5THdp2n2b3oUn8F2FCahE/o+vBZUYGv/Y5Zsl0NHD1YWDcrsObVngzcO3BWC6Gu/9b8LWaw6U+/UL1K1XLzIvQ7xZ6KDGTRFZ25V3YAZfu/nCPefJqxWivIGc74HrG+gaqK3ZpNfg4TXEKYspiWiZgRT3DRa2EOc70PVphTjb4XVdVYjhboxjjjFCt1eV9FCrbwh8DDEerRlPmbKifnA3tsxvSAGNNG6u5qU6+vvlhJSRkTpBTCrHWgnAmwJQ4YueeCPmj/bX2CE3Pv303V3+pSKr0Rjw6t5nOiYI1ZneOqvoz4bK0C/TcrzbVy7y2mv6dBx0HdW45juvc0kzj97/MZCxw7AYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2750.11,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5XVjywJVN06xColoVC6AikhW6t12kxigjXHiWwHSKv+9/mRFErZBAT53HtP7uP4PoNRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaB78dE6d/YQ/7ovaax9aDQZHELhzG8ghot5VUjutswGMtQQdz61P1mO9OhjHitoBgWqg6Kwv++qsSnDKaaQHOV4b9zl+d62S/i9bZd5Yzts2NU2XkGaIpNvW7n2I1k54nWgm6whjVFQMunIm5p8AjmfLkPE1li1GgStNx/QDEs7KrIfQIH//v2UpHCBqWlvx7TXgxzLWHB949w7w47tOQ4wlllJFKspQh0mZbZArEF8DP08UYndujJijGkoYHWdl6ypq4akh+N09Vf5we17MJRRqsbzz1JfdCH7mkxLof+NBvMsQyzGkLgpQyhDapgmjVfAJGPAgOEY0/Q11TwrGJJNSmpDWGYZZCqJh+hpTdarXy75+DQf3Z1GaXRXimF6ny5GodfpnI7PnMHwZjDrRAt04a9uBmKIo8pbfkcPAYomqar9kNW81et4V1uBKMc5bbAxZH/KAiWqv28lXRBYHZVqQJmiYVJZAcsLxARWYwE9lzFYTZdfUfUNkhJNl1wwmYV5nmwzQvmXR7ASoujaNo9XKIPcynDMcp4vhRXnmd1xnDO77dhSZLK3+AkKmautifkjMBxdvGPZbDbW5szKWapC2/b9+DbUxB8w5UKJ+BFc9o5npPFLj0GajPo9Wx162u1y5rZmbs82B205Gm7/t+BLOYdjfXoG9Stly/SLwEsL3JWQCiwqs/KO7IBjd9/dQJa8WTHKy8/pGjE1Y3UD5RU7tho8mKYwRXxEY1ImyIh6DItaCROYqXs0hwwv4cm5rETnbIx8ADNMlFaD2dV4FI1PrvzpZDp+OAluo1D6Tr0BQm5/3mhMn3Upe2ddxsGNaYCBKgPKOcrOvt0EYcUFyiw/JwTFShvcGiIqxRJbt5iL3+0ftUeoZ/Dzl14FgUxa0msxKW49rT1FK8zsIHn4hpkozUI99KtN9XKvrbqfh0G7wR1aduM7tDTTefkLbz0iL/wGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2803.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdJ1axCqRsFC68mgDW6d12kxyCdYcJ7IdIJ323+dHMihlExDkcx85997j+wuNEtT1WiikJF3LIc/LokaCknNgcYW6rKS0hUZiAmkeFTgG1F1hKqCF5njXz/KSSdS9eOecewdIcD1QaZxDaDQJj6HoPmggTop5VajcbcUGc2gSdy7O3zqe8hgQEWvoHkuogxbR4NDVYFNOUsIwPZnjpXHP86VtT/ilbc+8sR037MMuXmOWguXb1q6DhaocRB3YTzaYxTAruXIUTU0BxUJMVxHwDdGtRtetZx/UDMs46mRfkQb//fumkuIlYba9HdteHwuiYtH1jffgDS9d3/OQtdyXVGe1RejDpMyWwBskIDjIE03stq8ixoRFElfXecmbumpIeXhe13y1H969BCMVpWs8v1T6YkvV12RaSvNvNZhnGfCYYNpPOUAGepiWxl/AkrHgjJOYsPQv1TwrOKgmJbUhKrMMc03iy+JpQzfr73367mk+unu9SBd3pRymD+lyFPmdzuvxmRcOb8L7zmIJF8H6JpRDsqj81Wf4MoPFJNW1H2e1b/U7/oedBCZIzhpsjPnPsoBE9/e5pAuKq5NSnTGu03ClrBnPC+CS6LGgXp9zXE1XH6H6hGkJ05WQXLGwz1e7jDLx/hGtpSy6riviNWRYOBmJeS7ylXTiPHM7nnfmtj1XiUz1ljxhqbi6JrF4RDZHl+yzbLdbZ3vm5DzVoW33YXwbmcRvCBNSi/gRXfVOMzL4lc8xS0aDnqsPPeN2FYWtKOy59mAsJ8Pd/xZ8peZwqk+/UP1K1TLzIvS7he5KzCSRlV15J3bAqbvf32KePFsx2ivI2Qa4nrG+geqKnVoNPk5TnIIYsZiWCVhRj3FRK2GCM32P5piTFX71VlViOFujCHFGqNbqfDpTpqkfAvQH80ZS5myYH5wN66ML0gChZo3V2FQjn1/8qBISMifIKYVYS0E4Q2BKG7FzS4T80f5ae0Sm5d++m5s/UxxVeqMdndsM50DAGrMrRx0+ES5Luz+P/WpTvctrq2nfcdB+TseW/bSOLc0wfv8BTMAVEusGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2940.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJVKtWxColoVC6QighbdE6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8X8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtD9fGF9cg4Q/6YvaaxDaDQZHEPhzG8ghot5VUjutswGMtQQdy47l5YjPfqYxwqaQYHqoCjsH7pqLGA4xRSSkxzvjfs839v2Cb+37TNvbMcNu97Fa0hTZPJtK9d+JCtHvA50kw2kMZqWTDrypiafQM6DVYjYBqtWg8fWmw9ohqUdFdk3oMB//75LUrjE1LS3Y9rrQY5lLLi5dZ6coWt7jgOMZVYSxWqKUIdJmS0RaxAfQz9PVGJ3rowYYxoKWN3kJWvqqiHp4Thd/VV+cPceDGWUqvHTpdQXXcq+JkEp9L/RYJ5liMUYEjdlCGVIDdOk8RcwyRhwynCMafo31TwrGJJNSmpDWGYZZCqJRfSyIZv1D5dcvMxH9x+iNLovxTB9Spej0Ot0PozPncHwdjDrREv02V/fDsQQR5W3ekSLKYomqar9mNW81et41zuBKMc5bbAxZL/KAiWqv28lXRBYnZTqlDJFw6SypiwvEBNYjQX0XMZgFay+ouoBkhIFKy6YzMI8z3YZofzLM1gLUXRtm8drlEFuZThmOc9XworzzO44zrnddmwpMtlb/AKFzNXWxPwZGI4u3rNst1tre27lLFWhbftpfBdq4o+YcqFE/Ayueqcz0viVxyBNRv2erQ497XY1W7Rmi55tDtpyMtz+b8FXcg6n+vQK6lfKlukXgd8tcF9CKrCozMo7sQNO3X13C1nyZsUoLz+nG8TUjNUNlFfs1GrwYJrCFPERjUmZICPqMSxqJUxgpu7RHDK8gmcXshKdszHyAcwwUVqdzq7Ho2h8du0Hk2C8OJsHU+kaeAOE3P68kZg+60oOzrqKowvTAANVBZRjlI19uwjCiguUWX5OCIqVNLg1RFRqJbbuMBc/299qj1CP4PsPvQmmMmdJr7WkuPWwDgStMLOC5OEBM1GafXrsV5vq3V5bdTuPg/ZzO7bsp3dsaYbz+w8OD5NU+wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3015.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [11],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [12],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [13],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [14],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [24],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/aOhD9K5XVj9wkZPtEdCUSymPLazfQbtWtek0yBKuOE9kOkK363+tHUliWewUE+cwjZ2aO5xcaJ6jjtdCAknQrhzwvixoJS86BxRXqsJLSFhqLGaR5VOAYUGeDqYAWWuJDL8tLJlHn7RvH906QcNRXaZxTaDwbnEPRXdhAnBTLqlC524oN5tAk9l+9e+u8Vh59ImIN3WEJddAq6p+6GmzOSUoYphdzPDceeT63HQk/tx2ZN7bzhn08xFvMUrB829q1v1KVg6gDe8kOsxgWJVeOoqkppFiI+SYCviO61WjUevJBzbCMo072DWnwv3/fVVK8Jsy217ftDbAgKhaNbrx7b9h2A89D1nJXUp3VFqEPszJbA2+QkOAwTzSxSU9FTAmLJK5GecmbumpIeXhex3y1Hz48ByMVpWt8/V7pi61VX5N5Kc2/1WCeZcBjgmkv5QAZ6GFaGn8BS8aCC05iwtK/VPOs4KCalNSGqMwyzDWJr6vHHd1tf/Tom8fl+PblKl3dlnKY3qfrcRT4/svplTcY3gzu/NUa3oXbm4EcklUVbL7A1wWsZqmu/TyrfWvgBx8PEpggOWuwKeY/ywIS3d+nki4ori5KdcG4TsOVshY8L4BLoseCuj3OcTXffILqM6YlzDdCcsXCPl8cMsrEhwe0lbLouK6It5Bh4WQk5rnIN9KJ88z1Pe/KbXuuEpnqLXnEUnF1TWLxgGyODjlm2e/3zv7KyXmqQ9vu/XQSmcT/ECakFvEDuu5eZmTw64Bjloz7XVcfusbtOpq0oknXtQdjuRju/m/B12oOl/r0C9WvVC0zL0K/W+i2xEwSWdmVd2EHXLr7vT3myZMVo73CnO2A6xnrG6iu2KXVEOA0xSmIMYtpmYAV9RQXtRJmONP3aIk52eAXvqrEcLZGMcAZoVqrk/FwtFTGeTAA6PWXjajM2XA/ORveZ1ekAQaaN1aDU618evWjSkjInDCnFGItBuEMgSl1xM6ECPlv+1vtEZmmf/9h7v5CsVTpjXp0bjOeEwlrzC4ddfhMuCztBj33q031Nq+tpoHnQcdJnVuO8zq3NOP4/QfeOn2H7QYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2563.7,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgJZtEY2ETSCk4ZEYt6maqjvYFzPqeGzNjAGn6n/vPOxCCLsCjObch8+998z9iaYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1P+n53S8EyS4Gak0zik0nY/PofAhaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQw9YQh0UhaNTV4MtOEkJw/RijtfGI8/XtiPh17Yj88Z23rDrQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUY3rRcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWim1vv0Zu8c33PQ9byUFKd1RahD/MyWwNvkIDgIE80sbuhipgRFkpc3eQlb+qqIeXheX3z1X748BoMVZSu8e/3Sl9srfqaLEpp/q0G8ywDHhNMhykHyEAP09L4A1gyFlxyEhOW/qGaZwUH1aSkNoRllmGuSXyJnnd0t/0+pL3n1fT+bZRG96WcpI/pehr6nc7bWdcbT27HD51oDe+C7e1YTkhU+ZvP8GUJ0TzVtZ9ntW/1O/71QQITJGcNNsP8R1lAovv7UtIFxdVFqS4Z12m4UtaS5wVwSfRY0GDIOa4Wm49QfcK0hMVGSK5Y2OebQ0aZ+PCEtlIWfdcV8RYyLJyMxDwX+UY6cZ65Hc/rum3PVSJTvSXPWCqurkksnpDN0SfHLPv93tl3nZynOrTtPs7uQpP4L8KE1CJ+QleDy4wMfuVzzJLpaODqw8C4XYXXrfB64NqDsVwMd/+34Cs1h0t9+onqV6qWmRehXy10X2ImiazsyruwAy7d/eEe8+TFitFeQc52wPWM9Q1UV+zSavBxmuIUxJTFtEzAinqGi1oJc5zpe7TCnGzwm66qxHC2RjHGGaFaq8u7KFS2hT8GGI5WjabM2VA/ORvaZzekAcaaNlZzU518efPDSkjInCCnFGKtBeFMgClxxM4dEfLf9tfaIzQ9//bdXP2lIqnSG/Ho3GY6JwrWmN05qupPhMvSLtBzv9pUL/Paavp3HnQc1LnlOK5zSzONX78B3S7T7ewGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2739.81,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJqbZuiFVKQqF0BVJCtlbrtJnEBGuOE9kOkFb77/MjGZSyCQjyufee3MfxfQbjFPScDhgSnK3FiBVV2SBBxRiiSQ16tCKkA8Z8irIiKmGCQG8FCUcdsIA7Ly8qKkDv4r3lOgdIcD2QNNYhNJ4Oj6FoHrQQw+WiLiV3V2YDGWqJ3YvuheVIjwHmiYLmUKAmKI4Gh64amzGcYQrJSY7Xxn2er237hF/b9pm3tuOGXe2SNaQZMvl2lesglpUj3gR66QbSBIUVk468rSkgkPPZKkJsg1WrQdh58QHtsLSjIvsGFPjv33dJCpeYmva6pr0+5FjGgusb594ZubbvOMBY5hVRrKYIdZhW+RKxFgkwDIpUJXbryYgJppGA9XVRsbauBpIejtPTX+UHd6/BSEapGt99lPqiS9nXdFYJ/W80WOQ5YgmGxMsYQjlSwzRp/AVMMgYMGU4wzf6mWuQlQ7JJaWOIqjyHTCXxED9tyGb9wyPvnxbjuzdxFt9VYpTdZ8tx5Lvum8m5MxzdDOduvEQfgvXNUIxwXPurr+ghRPE0U7Ufs5q3+q5/tROIclzQFptA9qsqUar6+1LSJYH1SamGlCkaJpUVsqJETGA1FtD3GIP1bPUZ1V8gqdBsxQWTWZjn2S4nlH96BGshyp5t82SNcsitHCes4MVKWEmR267jnNtdx5Yik73FT1DIXG1NzB+B4ejhPct2u7W251bBMhXate8nt5EmfospF0rEj+CyfzojjV/6DNJ0POjb6tDXbpdzrzP3+rY5aMvJcPu/BV/KOZzq0zNoXilbpl8EfnfAXQWpwKI2K+/EDjh1970tZOmLFaO8goJuEFMzVjdQXrFTq8GHWQYzxMc0IVWKjKgnsGyUMIW5ukcLyPAKnr2TleicjZEPYY6J0mo4v5qM48nZVTCbziYPZ+FtHEnfmT9EyBssWo3psy7l4KzLOLoxLTBUZUA5R9nZl5sgqrlAuRUUhKBEaYNbI0SlWBLrFnPxs/ut8Yj0DL7/0KsglElLei0mxa2ndaBohZkdJA9fMBOVWajHfo2pWe6NVffzOGg/uGPLfnzHlnY6v/8A6Z9QpvwGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2793.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdE/EKpGwULryaANdp3XaLokJ1hwnsh0gnfbf50dSKGUTEORzHzn33uP7G40S1PVaKKQkXcshz8uiRoKSc8ziCnVZSWkLjcQEp3lUQIxRdwVU4Baaw66f5SWTqPvurdPxDpDgYqDSOIfQaBIeQ9FN0ECcFPOqULnbig1w3CTuvH/z2vGUx4CIWEM3IHEdtIgGh64Gm3KSEgb0ZI7nxj3P57Y94ee2PfPGdtywT7t4DSzFlm9buw4WqnIs6sB+sgEW41nJlaNoagooCDFdRZhviG41umg9+aBmWMZRJ/uGNPjv33eVFJaE2fZ2bHt9EETFootL784bfnB9z0PWclNSndUWoQ+TMlti3iABgSBPNLGrvooYExZJqC7ykjd11ZDy8Lyu+Wo/2D0HIxWla3zzQemLLVVfk2kpzb/VYJ5lmMcEaD/lGGdYD9PSeAQsGQvOOIkJSx+p5lnBsWpSUhuiMsuAaxJfFw8buln/6NO3D/PR9ctFurgu5TC9S5ejyO90Xo7PvHB4Gd50Fkv8PlhfhnJIFpW/+oK/zvBikuraj7Pat/od/9NOYiZIzhpsDPxXWeBE9/eppAsK1UmpzhjXabhS1oznBeaS6LGgXp9zqKarz7i6BVri6UpIrljY54tdRpn4eI/WUhZd1xXxGmcgnIzEPBf5Sjpxnrkdzztz256rRKZ6Sx5AKq6uSSzukc3RJfss2+3W2Z45OU91aNu9G19FJvErwoTUIr5H573TjAx+7nNgyWjQc/WhZ9zOo7AVhT3XHozlZLj734LP1RxO9ek3ql+pWmZehP600HUJTBJZ2ZV3Ygecuvv9LfDkyYrRXkHONpjrGesbqK7YqdXgQ5pCisWIxbRMsBX1GIpaCRPI9D2aAycrePFaVWI4W6MIISNUa3U+nSnT1A8x7g/mjaTM2TA/OBvWRxekAULNGtTYVCOfXvyoEhJnTpBTimMtBeEMMVPaiJ0rIuTP9rfaIzIt//7D3PyZ4qjSG+3o3GY4BwLWmF056nBLuCzt/jz2q031Lq+tpn3HQfs5HVv20zq2NMP48xfJfig/6wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2930.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UbW/aOhT+K5W1jywJqdZ7i1ilJBRKbyGUkK1onTaTmGBdx4lsB0in/ff5JRmUsgkI8nPOeXJeHp8fYJyCntMBQ4KzjRixoiobJKgYQzSpQY9WhHTAmE9RVkQlTBDorSHhqAMWcO/lRUUF6P1zZbnOERLcDSSNdQyNp8NTKJoHLcRwuahLyd2V2UCGWmL32r22HOkxwDxR0BwK1ATF0eDYVWMhwxmmkJzleGs85PnWdkj4re2QeWs7bdjtPtlAmiGTb1e5DmJZOeJNoJduIU3QrGLSkbc1BQRyHq4jxLZYtRp87rz6gHZY2lGRfQEK/PPvqySFK0xNe13TXh9yLGPB3b3z5Iw823ccYCzziihWU4Q6TKt8hViLBBgGRaoSe/BkxATTSMD6rqhYW1cDSQ/H6emv8oP7t2Ako1SNH66lvuhK9jUNK6H/jQaLPEcswZB4GUMoR2qYJo3fgEnGgDOGE0yz36kWecmQbFLaGKIqzyFTSSzjly3Zbr555OplMX58F2fxYyVG2VO2Gke+676bXDrD0f1w7sYr9G+wuR+KEY5rf/0ZLWconmaq9lNW81bf9W/3AlGOC9piE8j+r0qUqv6+lnRJYH1WqjPKFA2TypqxokRMYDUW0PcYg3W4/g/VnyCpULjmgskszPNinxPKPz6DjRBlz7Z5skE55FaOE1bwYi2spMht13Eu7a5jS5HJ3uIXKGSutibmz8Bw9PCBZbfbWbtLq2CZCu3aT5OHSBO/x5QLJeJncNM/n5HGb3wGaToe9G116Gu3m/myM1/2bXPQlrPh9l8LvpFzONenH6B5pWyZfhH42QGPFaQCi9qsvDM74Nzd93aQpa9WjPIKCrpFTM1Y3UB5xc6tBh9mGcwQH9OEVCkyop7AslHCFObqHi0gw2t4cSUr0TkbIx/CHBOl1dn8djKOJxe3QTgNJ8uLRTiTrqE/RMgbLFqJ6bOu5Oisqzi5MC0wVFVAOUbZ2NeLIKq5QLkVFISgREmDWyNEpVYS6wFz8b37pfGI9Ai+ftObYCZzlvRaS4pbD+tI0AozK0gePmEmKrNPT/0aU7PbG6tu52nQYW6nlsP0Ti3tcH7+ApdpGvX7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3005.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [15],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [16],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [35],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UaW/aQBD9K9WqH6ltSI8U0UjYlCPlSgxtqqZqB3sxq67X1u4acKL+9+5hF0JoBRjtm5nnN8fOIxrFqO01UJ+SZCMHPCvyCgkKzjGLStRmBaUNNBJTnGRhDhFG7TVQgRtoAftumhVMovblW+eNd4QEw56icY6h0bR/CoW3QQ1xki/KXHE3lRrguCZuvb58Z6h7REQaugWJq6Bl2Dt2NdiMk4QwoGc5nhsPOp/bDoKf2w7Ka9tpwT7uow2wBFu9Te3aW6rMsagCu/EWWITnBVeOos4poCDEbB1iviW61GjYePJBdbOMoyb7hjT47993RQorwmx5W7a8PgiiYtHw2rvzBk3X9zxkLbcF1aw2CX2YFukK8xoJCARZrIWNuypiQlgooRxmBa/zqiDl4Xlt89V+sH8OhipK5/jmvZovtlJ1jWeFNP92BrM0xTwiQLsJxzjFuplWxl/AirHgnJOIsOSv1CzNOVZFiitDWKQpcC3i6/JhS7ebH1369mExunm5TJY3hRwkd8lqFPqt1svJhdcfXPdvW8sVvgw21305IMvSX3/BX+d4OU107qes9q1+y/+4l5gJkrEamwD/VeQ41vV9OtI5hfLsqM4Z1zRcTdacZznmkui2oE6Xcyhn60+4/Ay0wLO1kFypsM8X+5Qy8eEebaTM264rog1OQTgpiXgmsrV0oix1W5534TY9Vw2Zqi15AKm0uoZY3CPL0SYHlt1u5+wunIwnOrTp3k3GoSF+RZiQeojv0VXnvCKDX/kcWDzqdVx96Bi3q3DcCMcd1x6M5Wy4+9+Er1QfztXpEVWvVCUzL0K/G+imACaJLO3KO7MDzt397g54/GTFaK8gY1vMdY/1DVRX7Nxq8CFJIMFixCJaxNgO9QTyahKmkOp7tABO1vCipTIxmq1R9CElVM/qeDQYLpRx5vcx7vYW9VCZs9F+dDa6T65IDfS1blCNU6V8evXDUkicOkFGKY70MAhngJmajsgZEyF/Nr9VHqEp+vcf5u7PlUpFb6ZHc5v2HI2wxuzSUYfPhMvCbtBTv8pUbfPKagp4GnTo1Knl0K9TS92O338AZuVQ7e0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2574,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgIpSxEbCJhDS8EiMu6maqh3sixl1PLZmxoBT9b/vPOxCCLsCjObch8+998z9haYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1L/qOR+8EyS4Hak0zik0nY/PofAxaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQ49YQh0UhaNTV4MtOEkJw/RijrfGI8+3tiPht7Yj88Z23rCbQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUa3rVcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWi2zvvyZtcub7nIWt5LKnOaovQh3mZrYE3SEBwkCea2P1QRcwICyWubvOSN3XVkPLwvL75aj98eAuGKkrX+OGj0hdbq74mi1Kaf6vBPMuAxwTTYcoBMtDDtDT+AJaMBZecxISlf6jmWcFBNSmpDWGZZZhrEl+ilx3dbb8Pae9lNX14H6XRQykn6VO6noZ+p/N+1vXGk7vxYydaw1WwvRvLCYkqf/MPfFlCNE917edZ7Vv9jn9zkMAEyVmDzTD/WRaQ6P6+lnRBcXVRqkvGdRqulLXkeQFcEj0WNBhyjqvF5hNUnzEtYbERkisW9vnukFEm/n5GWymLvuuKeAsZFk5GYp6LfCOdOM/cjud13bbnKpGp3pIXLBVX1yQWz8jm6JNjlv1+7+y7Ts5THdp2n2b3oUn8F2FCahE/o+vBZUYGv/Y5Zsl0NHD1YWDcrsObVngzcO3BWC6Gu/9b8LWaw6U+/UL1K1XLzIvQ7xZ6KDGTRFZ25V3YAZfu/nCPefJqxWivIGc74HrG+gaqK3ZpNfg4TXEKYspiWiZgRT3DRa2EOc70PVphTjb4XVdVYjhboxjjjFCt1eV9FCrbwh8DDEerRlPmbKifnA3tsxvSAGNNG6u5qU6+vvlhJSRkTpBTCrHWgnAmwJQ4YueeCPmj/bX2CE3Pv303V3+pSKr0Rjw6t5nOiYI1ZneOqvoz4bK0C/TcrzbVy7y2mv6dBx0HdW45juvc0kzj97/MZCxw7AYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2750.11,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdJ1axCqRsFC68mgDW6d12kxyCdYcJ7IdIJ323+dHMihlExDkcx85997j+wuNEtT1WiikJF3LIc/LokaCknNgcYW6rKS0hUZiAmkeFTgG1F1hKqCF5njXz/KSSdS9eOecewdIcD1QaZxDaDQJj6HoPmggTop5VajcbcUGc2gSdy7O3zqe8hgQEWvoHkuogxbR4NDVYFNOUsIwPZnjpXHP86VtT/ilbc+8sR037MMuXmOWguXb1q6DhaocRB3YTzaYxTAruXIUTU0BxUJMVxHwDdGtRtetZx/UDMs46mRfkQb//fumkuIlYba9HdteHwuiYtH1jffgDS9d3/OQtdyXVGe1RejDpMyWwBskIDjIE03stq8ixoRFElfXecmbumpIeXhe13y1H969BCMVpWs8v1T6YkvV12RaSvNvNZhnGfCYYNpPOUAGepiWxl/AkrHgjJOYsPQv1TwrOKgmJbUhKrMMc03iy+JpQzfr73367mk+unu9SBd3pRymD+lyFPmdzuvxmRcOb8L7zmIJF8H6JpRDsqj81Wf4MoPFJNW1H2e1b/U7/oedBCZIzhpsjPnPsoBE9/e5pAuKq5NSnTGu03ClrBnPC+CS6LGgXp9zXE1XH6H6hGkJ05WQXLGwz1e7jDLx/hGtpSy6riviNWRYOBmJeS7ylXTiPHM7nnfmtj1XiUz1ljxhqbi6JrF4RDZHl+yzbLdbZ3vm5DzVoW33YXwbmcRvCBNSi/gRXfVOMzL4lc8xS0aDnqsPPeN2FYWtKOy59mAsJ8Pd/xZ8peZwqk+/UP1K1TLzIvS7he5KzCSRlV15J3bAqbvf32KePFsx2ivI2Qa4nrG+geqKnVoNPk5TnIIYsZiWCVhRj3FRK2GCM32P5piTFX71VlViOFujCHFGqNbqfDpTpqkfAvQH80ZS5myYH5wN66ML0gChZo3V2FQjn1/8qBISMifIKYVYS0E4Q2BKG7FzS4T80f5ae0Sm5d++m5s/UxxVeqMdndsM50DAGrMrRx0+ES5Luz+P/WpTvctrq2nfcdB+TseW/bSOLc0wfv8BTMAVEusGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2940.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJVKtWxColoVC6QighbdE6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8X8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtD9fGF9cg4Q/6YvaaxDaDQZHEPhzG8ghot5VUjutswGMtQQdy47l5YjPfqYxwqaQYHqoCjsH7pqLGA4xRSSkxzvjfs839v2Cb+37TNvbMcNu97Fa0hTZPJtK9d+JCtHvA50kw2kMZqWTDrypiafQM6DVYjYBqtWg8fWmw9ohqUdFdk3oMB//75LUrjE1LS3Y9rrQY5lLLi5dZ6coWt7jgOMZVYSxWqKUIdJmS0RaxAfQz9PVGJ3rowYYxoKWN3kJWvqqiHp4Thd/VV+cPceDGWUqvHTpdQXXcq+JkEp9L/RYJ5liMUYEjdlCGVIDdOk8RcwyRhwynCMafo31TwrGJJNSmpDWGYZZCqJRfSyIZv1D5dcvMxH9x+iNLovxTB9Spej0Ot0PozPncHwdjDrREv02V/fDsQQR5W3ekSLKYomqar9mNW81et41zuBKMc5bbAxZL/KAiWqv28lXRBYnZTqlDJFw6SypiwvEBNYjQX0XMZgFay+ouoBkhIFKy6YzMI8z3YZofzLM1gLUXRtm8drlEFuZThmOc9XworzzO44zrnddmwpMtlb/AKFzNXWxPwZGI4u3rNst1tre27lLFWhbftpfBdq4o+YcqFE/Ayueqcz0viVxyBNRv2erQ497XY1W7Rmi55tDtpyMtz+b8FXcg6n+vQK6lfKlukXgd8tcF9CKrCozMo7sQNO3X13C1nyZsUoLz+nG8TUjNUNlFfs1GrwYJrCFPERjUmZICPqMSxqJUxgpu7RHDK8gmcXshKdszHyAcwwUVqdzq7Ho2h8du0Hk2C8OJsHU+kaeAOE3P68kZg+60oOzrqKowvTAANVBZRjlI19uwjCiguUWX5OCIqVNLg1RFRqJbbuMBc/299qj1CP4PsPvQmmMmdJr7WkuPWwDgStMLOC5OEBM1GafXrsV5vq3V5bdTuPg/ZzO7bsp3dsaYbz+w8OD5NU+wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 86.5,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3015.5,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [17],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [18],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [25],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/aOhD9K5XVj9wkZPtEdCUSymPLazfQbtWtek0yBKuOE9kOkK363+tHUliWewUE+cwjZ2aO5xcaJ6jjtdCAknQrhzwvixoJS86BxRXqsJLSFhqLGaR5VOAYUGeDqYAWWuJDL8tLJlHn7RvH906QcNRXaZxTaDwbnEPRXdhAnBTLqlC524oN5tAk9l+9e+u8Vh59ImIN3WEJddAq6p+6GmzOSUoYphdzPDceeT63HQk/tx2ZN7bzhn08xFvMUrB829q1v1KVg6gDe8kOsxgWJVeOoqkppFiI+SYCviO61WjUevJBzbCMo072DWnwv3/fVVK8Jsy217ftDbAgKhaNbrx7b9h2A89D1nJXUp3VFqEPszJbA2+QkOAwTzSxSU9FTAmLJK5GecmbumpIeXhex3y1Hz48ByMVpWt8/V7pi61VX5N5Kc2/1WCeZcBjgmkv5QAZ6GFaGn8BS8aCC05iwtK/VPOs4KCalNSGqMwyzDWJr6vHHd1tf/Tom8fl+PblKl3dlnKY3qfrcRT4/svplTcY3gzu/NUa3oXbm4EcklUVbL7A1wWsZqmu/TyrfWvgBx8PEpggOWuwKeY/ywIS3d+nki4ori5KdcG4TsOVshY8L4BLoseCuj3OcTXffILqM6YlzDdCcsXCPl8cMsrEhwe0lbLouK6It5Bh4WQk5rnIN9KJ88z1Pe/KbXuuEpnqLXnEUnF1TWLxgGyODjlm2e/3zv7KyXmqQ9vu/XQSmcT/ECakFvEDuu5eZmTw64Bjloz7XVcfusbtOpq0oknXtQdjuRju/m/B12oOl/r0C9WvVC0zL0K/W+i2xEwSWdmVd2EHXLr7vT3myZMVo73CnO2A6xnrG6iu2KXVEOA0xSmIMYtpmYAV9RQXtRJmONP3aIk52eAXvqrEcLZGMcAZoVqrk/FwtFTGeTAA6PWXjajM2XA/ORveZ1ekAQaaN1aDU618evWjSkjInDCnFGItBuEMgSl1xM6ECPlv+1vtEZmmf/9h7v5CsVTpjXp0bjOeEwlrzC4ddfhMuCztBj33q031Nq+tpoHnQcdJnVuO8zq3NOP4/QfeOn2H7QYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2563.7,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgJZtEY2ETSCk4ZEYt6maqjvYFzPqeGzNjAGn6n/vPOxCCLsCjObch8+998z9iaYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1P+n53S8EyS4Gak0zik0nY/PofAhaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQw9YQh0UhaNTV4MtOEkJw/RijtfGI8/XtiPh17Yj88Z23rDrQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUY3rRcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWim1vv0Zu8c33PQ9byUFKd1RahD/MyWwNvkIDgIE80sbuhipgRFkpc3eQlb+qqIeXheX3z1X748BoMVZSu8e/3Sl9srfqaLEpp/q0G8ywDHhNMhykHyEAP09L4A1gyFlxyEhOW/qGaZwUH1aSkNoRllmGuSXyJnnd0t/0+pL3n1fT+bZRG96WcpI/pehr6nc7bWdcbT27HD51oDe+C7e1YTkhU+ZvP8GUJ0TzVtZ9ntW/1O/71QQITJGcNNsP8R1lAovv7UtIFxdVFqS4Z12m4UtaS5wVwSfRY0GDIOa4Wm49QfcK0hMVGSK5Y2OebQ0aZ+PCEtlIWfdcV8RYyLJyMxDwX+UY6cZ65Hc/rum3PVSJTvSXPWCqurkksnpDN0SfHLPv93tl3nZynOrTtPs7uQpP4L8KE1CJ+QleDy4wMfuVzzJLpaODqw8C4XYXXrfB64NqDsVwMd/+34Cs1h0t9+onqV6qWmRehXy10X2ImiazsyruwAy7d/eEe8+TFitFeQc52wPWM9Q1UV+zSavBxmuIUxJTFtEzAinqGi1oJc5zpe7TCnGzwm66qxHC2RjHGGaFaq8u7KFS2hT8GGI5WjabM2VA/ORvaZzekAcaaNlZzU518efPDSkjInCCnFGKtBeFMgClxxM4dEfLf9tfaIzQ9//bdXP2lIqnSG/Ho3GY6JwrWmN05qupPhMvSLtBzv9pUL/Paavp3HnQc1LnlOK5zSzONX78B3S7T7ewGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2739.81,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdE/EKpGwULryaANdp3XaLokJ1hwnsh0gnfbf50dSKGUTEORzHzn33uP7G40S1PVaKKQkXcshz8uiRoKSc8ziCnVZSWkLjcQEp3lUQIxRdwVU4Baaw66f5SWTqPvurdPxDpDgYqDSOIfQaBIeQ9FN0ECcFPOqULnbig1w3CTuvH/z2vGUx4CIWEM3IHEdtIgGh64Gm3KSEgb0ZI7nxj3P57Y94ee2PfPGdtywT7t4DSzFlm9buw4WqnIs6sB+sgEW41nJlaNoagooCDFdRZhviG41umg9+aBmWMZRJ/uGNPjv33eVFJaE2fZ2bHt9EETFootL784bfnB9z0PWclNSndUWoQ+TMlti3iABgSBPNLGrvooYExZJqC7ykjd11ZDy8Lyu+Wo/2D0HIxWla3zzQemLLVVfk2kpzb/VYJ5lmMcEaD/lGGdYD9PSeAQsGQvOOIkJSx+p5lnBsWpSUhuiMsuAaxJfFw8buln/6NO3D/PR9ctFurgu5TC9S5ejyO90Xo7PvHB4Gd50Fkv8PlhfhnJIFpW/+oK/zvBikuraj7Pat/od/9NOYiZIzhpsDPxXWeBE9/eppAsK1UmpzhjXabhS1oznBeaS6LGgXp9zqKarz7i6BVri6UpIrljY54tdRpn4eI/WUhZd1xXxGmcgnIzEPBf5Sjpxnrkdzztz256rRKZ6Sx5AKq6uSSzukc3RJfss2+3W2Z45OU91aNu9G19FJvErwoTUIr5H573TjAx+7nNgyWjQc/WhZ9zOo7AVhT3XHozlZLj734LP1RxO9ek3ql+pWmZehP600HUJTBJZ2ZV3Ygecuvv9LfDkyYrRXkHONpjrGesbqK7YqdXgQ5pCisWIxbRMsBX1GIpaCRPI9D2aAycrePFaVWI4W6MIISNUa3U+nSnT1A8x7g/mjaTM2TA/OBvWRxekAULNGtTYVCOfXvyoEhJnTpBTimMtBeEMMVPaiJ0rIuTP9rfaIzIt//7D3PyZ4qjSG+3o3GY4BwLWmF056nBLuCzt/jz2q031Lq+tpn3HQfs5HVv20zq2NMP48xfJfig/6wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2930.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UbW/aOhT+K5W1jywJqdZ7i1ilJBRKbyGUkK1onTaTmGBdx4lsB0in/ff5JRmUsgkI8nPOeXJeHp8fYJyCntMBQ4KzjRixoiobJKgYQzSpQY9WhHTAmE9RVkQlTBDorSHhqAMWcO/lRUUF6P1zZbnOERLcDSSNdQyNp8NTKJoHLcRwuahLyd2V2UCGWmL32r22HOkxwDxR0BwK1ATF0eDYVWMhwxmmkJzleGs85PnWdkj4re2QeWs7bdjtPtlAmiGTb1e5DmJZOeJNoJduIU3QrGLSkbc1BQRyHq4jxLZYtRp87rz6gHZY2lGRfQEK/PPvqySFK0xNe13TXh9yLGPB3b3z5Iw823ccYCzziihWU4Q6TKt8hViLBBgGRaoSe/BkxATTSMD6rqhYW1cDSQ/H6emv8oP7t2Ako1SNH66lvuhK9jUNK6H/jQaLPEcswZB4GUMoR2qYJo3fgEnGgDOGE0yz36kWecmQbFLaGKIqzyFTSSzjly3Zbr555OplMX58F2fxYyVG2VO2Gke+676bXDrD0f1w7sYr9G+wuR+KEY5rf/0ZLWconmaq9lNW81bf9W/3AlGOC9piE8j+r0qUqv6+lnRJYH1WqjPKFA2TypqxokRMYDUW0PcYg3W4/g/VnyCpULjmgskszPNinxPKPz6DjRBlz7Z5skE55FaOE1bwYi2spMht13Eu7a5jS5HJ3uIXKGSutibmz8Bw9PCBZbfbWbtLq2CZCu3aT5OHSBO/x5QLJeJncNM/n5HGb3wGaToe9G116Gu3m/myM1/2bXPQlrPh9l8LvpFzONenH6B5pWyZfhH42QGPFaQCi9qsvDM74Nzd93aQpa9WjPIKCrpFTM1Y3UB5xc6tBh9mGcwQH9OEVCkyop7AslHCFObqHi0gw2t4cSUr0TkbIx/CHBOl1dn8djKOJxe3QTgNJ8uLRTiTrqE/RMgbLFqJ6bOu5Oisqzi5MC0wVFVAOUbZ2NeLIKq5QLkVFISgREmDWyNEpVYS6wFz8b37pfGI9Ai+ftObYCZzlvRaS4pbD+tI0AozK0gePmEmKrNPT/0aU7PbG6tu52nQYW6nlsP0Ti3tcH7+ApdpGvX7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3005.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [19],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY PLUS',
                    mappedFareName: 'Tarifa 5',
                    fareBasis: 'HJ0X0G2/B00',
                    flightClasses: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdF03xColoVC6AikhXat22kxigjXHiWwHSKv99/mRDErZBAT53HtP7uP4voBRArpOCwwITldiyPKyqBG/ZAzRuAJdWhLSAiM+QWkeFjBGoLuEhKMWmMOtm+UlFaD74aN1dr6H+Fd9SWM5e9BoMjiEwpnfQAwX86qQ3G2ZDWSoIe6ct88tR3r0MY8VNIMC1UFR2N931diU4RRTSI5yvDXu8nxr2yX81rbLvLEdNuxyG68gTZHJt61c+5GsHPE60E3WkMYoKJl05E1NPoGcT5chYmusWg2C1qsPaIalHRXZI1Dgv3/fJSlcYGra2zHt9SDHMhZcXTv3zrBje44DjGVWEsVqilCHSZktEGsQH0M/T1RiN66MGGMaClhd5SVr6qoh6eE4Xf1VfnD7FgxllKrx7LPUF13IvibTUuh/o8E8yxCLMSRuyhDKkBqmSeMvYJIxYMBwjGn6N9U8KxiSTUpqQ1hmGWQqiYfoeU3Wqx8u+fg8H92+i9LothTD9D5djEKv03k3PnUGw+vBrBMt0Cd/dT0QQxxV3vIbeghQNElV7Yes5q1ex7vcCkQ5zmmDjSH7VRYoUf19LemCwOqoVAPKFA2TygpYXiAmsBoL6LmMwWq6/IqqO0hKNF1ywWQW5nmyzQjlX57ASoiia9s8XqEMcivDMct5vhRWnGd2x3FO7bZjS5HJ3uJnKGSutibmT8BwdPGOZbPZWJtTK2epCm3b9+ObUBO/x5QLJeIncNE7npHGLzwGaTLq92x16Gm3i5nbmrk92xy05Wi4/d+CL+QcjvXpBdSvlC3TLwK/W+C2hFRgUZmVd2QHHLv77gay5NWKUV5+TteIqRmrGyiv2LHV4ME0hSniIxqTMkFG1GNY1EqYwEzdozlkeAlPzmQlOmdj5AOYYaK0Gswux6NofHLpTyfT8cNJcBOF0nfqDRBy+/NGY/qsS9k76zIObkwDDFQZUM5Rdvb1JggrLlBm+TkhKFba4NYQUSmW2LrBXPxsP9YeoZ7B9x96FQQyaUmvxaS49bT2FK0ws4Pk4Q4zUZqFeuhXm+rlXlt1Pw+DdoM7tOzGd2hppvP7D9bbQFf8BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2717,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2763.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5W1jywJdOtWxColoVC6QighW9E6bSYxwZrjRLYDpNX++/xIBqVsAoJ87r0n93F8n8EoAV2nBQYEp2sxZHlZ1IhfMoZoXIEuLQlpgRGfoDQPCxgj0F1BwlELzOHOzfKSCtB9d2G9/3CA+Dd9SWM5B9BoMjiGwpnfQAwX86qQ3G2ZDWSoIe5cdi4tR3r0MY8VNIMC1UFR2D901VjAcIopJCc5Xhv3eb627RN+bdtn3tiOG3a9i9eQpsjk21au/UhWjngd6CYbSGM0LZl05E1NPoGcB6sQsQ1WrQZfWy8+oBmWdlRk34AC//37LknhElPT3o5prwc5lrHg5tZ5cIau7TkOMJZZSRSrKUIdJmW2RKxBfAz9PFGJ3bkyYoxpKGB1k5esqauGpIfjdPVX+cHdazCUUarG95dSX3Qp+5oEpdD/RoN5liEWY0jclCGUITVMk8ZfwCRjwCnDMabp31TzrGBINimpDWGZZZCpJBbR04Zs1j9ccvE0H92/idLovhTD9CFdjkKv03kzPncGw9vBrBMt0Ud/fTsQQxxV3uorWkxRNElV7ces5q1ex7veCUQ5zmmDjSH7VRYoUf19KemCwOqkVKeUKRomlTVleYGYwGosoOcyBqtg9RlVXyApUbDigskszPNslxHKPz2CtRBF17Z5vEYZ5FaGY5bzfCWsOM/sjuOc223HliKTvcVPUMhcbU3MH4Hh6OI9y3a7tbbnVs5SFdq2H8Z3oSZ+iykXSsSP4Kp3OiONX3kM0mTU79nq0NNuV7NFa7bo2eagLSfD7f8WfCXncKpPz6B+pWyZfhH43QL3JaQCi8qsvBM74NTdd7eQJS9WjPLyc7pBTM1Y3UB5xU6tBg+mKUwRH9GYlAkyoh7DolbCBGbqHs0hwyt4diEr0TkbIx/ADBOl1ensejyKxmfXfjAJxouzeTCVroE3QMjtzxuJ6bOu5OCsqzi6MA0wUFVAOUbZ2JeLIKy4QJnl54SgWEmDW0NEpVZi6w5z8bP9rfYI9Qi+/9CbYCpzlvRaS4pbD+tA0AozK0gevmAmSrNPj/1qU73ba6tu53HQfm7Hlv30ji3NcH7/AUYUvDH7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2975.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [26],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/aOhD9K5XVj9wkZPtEdCUSymPLazfQbtWtek0yBKuOE9kOkK363+tHUliWewUE+cwjZ2aO5xcaJ6jjtdCAknQrhzwvixoJS86BxRXqsJLSFhqLGaR5VOAYUGeDqYAWWuJDL8tLJlHn7RvH906QcNRXaZxTaDwbnEPRXdhAnBTLqlC524oN5tAk9l+9e+u8Vh59ImIN3WEJddAq6p+6GmzOSUoYphdzPDceeT63HQk/tx2ZN7bzhn08xFvMUrB829q1v1KVg6gDe8kOsxgWJVeOoqkppFiI+SYCviO61WjUevJBzbCMo072DWnwv3/fVVK8Jsy217ftDbAgKhaNbrx7b9h2A89D1nJXUp3VFqEPszJbA2+QkOAwTzSxSU9FTAmLJK5GecmbumpIeXhex3y1Hz48ByMVpWt8/V7pi61VX5N5Kc2/1WCeZcBjgmkv5QAZ6GFaGn8BS8aCC05iwtK/VPOs4KCalNSGqMwyzDWJr6vHHd1tf/Tom8fl+PblKl3dlnKY3qfrcRT4/svplTcY3gzu/NUa3oXbm4EcklUVbL7A1wWsZqmu/TyrfWvgBx8PEpggOWuwKeY/ywIS3d+nki4ori5KdcG4TsOVshY8L4BLoseCuj3OcTXffILqM6YlzDdCcsXCPl8cMsrEhwe0lbLouK6It5Bh4WQk5rnIN9KJ88z1Pe/KbXuuEpnqLXnEUnF1TWLxgGyODjlm2e/3zv7KyXmqQ9vu/XQSmcT/ECakFvEDuu5eZmTw64Bjloz7XVcfusbtOpq0oknXtQdjuRju/m/B12oOl/r0C9WvVC0zL0K/W+i2xEwSWdmVd2EHXLr7vT3myZMVo73CnO2A6xnrG6iu2KXVEOA0xSmIMYtpmYAV9RQXtRJmONP3aIk52eAXvqrEcLZGMcAZoVqrk/FwtFTGeTAA6PWXjajM2XA/ORveZ1ekAQaaN1aDU618evWjSkjInDCnFGItBuEMgSl1xM6ECPlv+1vtEZmmf/9h7v5CsVTpjXp0bjOeEwlrzC4ddfhMuCztBj33q031Nq+tpoHnQcdJnVuO8zq3NOP4/QfeOn2H7QYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2563.7,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgJZtEY2ETSCk4ZEYt6maqjvYFzPqeGzNjAGn6n/vPOxCCLsCjObch8+998z9iaYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1P+n53S8EyS4Gak0zik0nY/PofAhaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQw9YQh0UhaNTV4MtOEkJw/RijtfGI8/XtiPh17Yj88Z23rDrQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUY3rRcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWim1vv0Zu8c33PQ9byUFKd1RahD/MyWwNvkIDgIE80sbuhipgRFkpc3eQlb+qqIeXheX3z1X748BoMVZSu8e/3Sl9srfqaLEpp/q0G8ywDHhNMhykHyEAP09L4A1gyFlxyEhOW/qGaZwUH1aSkNoRllmGuSXyJnnd0t/0+pL3n1fT+bZRG96WcpI/pehr6nc7bWdcbT27HD51oDe+C7e1YTkhU+ZvP8GUJ0TzVtZ9ntW/1O/71QQITJGcNNsP8R1lAovv7UtIFxdVFqS4Z12m4UtaS5wVwSfRY0GDIOa4Wm49QfcK0hMVGSK5Y2OebQ0aZ+PCEtlIWfdcV8RYyLJyMxDwX+UY6cZ65Hc/rum3PVSJTvSXPWCqurkksnpDN0SfHLPv93tl3nZynOrTtPs7uQpP4L8KE1CJ+QleDy4wMfuVzzJLpaODqw8C4XYXXrfB64NqDsVwMd/+34Cs1h0t9+onqV6qWmRehXy10X2ImiazsyruwAy7d/eEe8+TFitFeQc52wPWM9Q1UV+zSavBxmuIUxJTFtEzAinqGi1oJc5zpe7TCnGzwm66qxHC2RjHGGaFaq8u7KFS2hT8GGI5WjabM2VA/ORvaZzekAcaaNlZzU518efPDSkjInCCnFGKtBeFMgClxxM4dEfLf9tfaIzQ9//bdXP2lIqnSG/Ho3GY6JwrWmN05qupPhMvSLtBzv9pUL/Paavp3HnQc1LnlOK5zSzONX78B3S7T7ewGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2739.81,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdE/EKpGwULryaANdp3XaLokJ1hwnsh0gnfbf50dSKGUTEORzHzn33uP7G40S1PVaKKQkXcshz8uiRoKSc8ziCnVZSWkLjcQEp3lUQIxRdwVU4Baaw66f5SWTqPvurdPxDpDgYqDSOIfQaBIeQ9FN0ECcFPOqULnbig1w3CTuvH/z2vGUx4CIWEM3IHEdtIgGh64Gm3KSEgb0ZI7nxj3P57Y94ee2PfPGdtywT7t4DSzFlm9buw4WqnIs6sB+sgEW41nJlaNoagooCDFdRZhviG41umg9+aBmWMZRJ/uGNPjv33eVFJaE2fZ2bHt9EETFootL784bfnB9z0PWclNSndUWoQ+TMlti3iABgSBPNLGrvooYExZJqC7ykjd11ZDy8Lyu+Wo/2D0HIxWla3zzQemLLVVfk2kpzb/VYJ5lmMcEaD/lGGdYD9PSeAQsGQvOOIkJSx+p5lnBsWpSUhuiMsuAaxJfFw8buln/6NO3D/PR9ctFurgu5TC9S5ejyO90Xo7PvHB4Gd50Fkv8PlhfhnJIFpW/+oK/zvBikuraj7Pat/od/9NOYiZIzhpsDPxXWeBE9/eppAsK1UmpzhjXabhS1oznBeaS6LGgXp9zqKarz7i6BVri6UpIrljY54tdRpn4eI/WUhZd1xXxGmcgnIzEPBf5Sjpxnrkdzztz256rRKZ6Sx5AKq6uSSzukc3RJfss2+3W2Z45OU91aNu9G19FJvErwoTUIr5H573TjAx+7nNgyWjQc/WhZ9zOo7AVhT3XHozlZLj734LP1RxO9ek3ql+pWmZehP600HUJTBJZ2ZV3Ygecuvv9LfDkyYrRXkHONpjrGesbqK7YqdXgQ5pCisWIxbRMsBX1GIpaCRPI9D2aAycrePFaVWI4W6MIISNUa3U+nSnT1A8x7g/mjaTM2TA/OBvWRxekAULNGtTYVCOfXvyoEhJnTpBTimMtBeEMMVPaiJ0rIuTP9rfaIzIt//7D3PyZ4qjSG+3o3GY4BwLWmF056nBLuCzt/jz2q031Lq+tpn3HQfs5HVv20zq2NMP48xfJfig/6wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2930.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UbW/aOhT+K5W1jywJqdZ7i1ilJBRKbyGUkK1onTaTmGBdx4lsB0in/ff5JRmUsgkI8nPOeXJeHp8fYJyCntMBQ4KzjRixoiobJKgYQzSpQY9WhHTAmE9RVkQlTBDorSHhqAMWcO/lRUUF6P1zZbnOERLcDSSNdQyNp8NTKJoHLcRwuahLyd2V2UCGWmL32r22HOkxwDxR0BwK1ATF0eDYVWMhwxmmkJzleGs85PnWdkj4re2QeWs7bdjtPtlAmiGTb1e5DmJZOeJNoJduIU3QrGLSkbc1BQRyHq4jxLZYtRp87rz6gHZY2lGRfQEK/PPvqySFK0xNe13TXh9yLGPB3b3z5Iw823ccYCzziihWU4Q6TKt8hViLBBgGRaoSe/BkxATTSMD6rqhYW1cDSQ/H6emv8oP7t2Ako1SNH66lvuhK9jUNK6H/jQaLPEcswZB4GUMoR2qYJo3fgEnGgDOGE0yz36kWecmQbFLaGKIqzyFTSSzjly3Zbr555OplMX58F2fxYyVG2VO2Gke+676bXDrD0f1w7sYr9G+wuR+KEY5rf/0ZLWconmaq9lNW81bf9W/3AlGOC9piE8j+r0qUqv6+lnRJYH1WqjPKFA2TypqxokRMYDUW0PcYg3W4/g/VnyCpULjmgskszPNinxPKPz6DjRBlz7Z5skE55FaOE1bwYi2spMht13Eu7a5jS5HJ3uIXKGSutibmz8Bw9PCBZbfbWbtLq2CZCu3aT5OHSBO/x5QLJeJncNM/n5HGb3wGaToe9G116Gu3m/myM1/2bXPQlrPh9l8LvpFzONenH6B5pWyZfhH42QGPFaQCi9qsvDM74Nzd93aQpa9WjPIKCrpFTM1Y3UB5xc6tBh9mGcwQH9OEVCkyop7AslHCFObqHi0gw2t4cSUr0TkbIx/CHBOl1dn8djKOJxe3QTgNJ8uLRTiTrqE/RMgbLFqJ6bOu5Oisqzi5MC0wVFVAOUbZ2NeLIKq5QLkVFISgREmDWyNEpVYS6wFz8b37pfGI9Ai+ftObYCZzlvRaS4pbD+tI0AozK0gePmEmKrNPT/0aU7PbG6tu52nQYW6nlsP0Ti3tcH7+ApdpGvX7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3005.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [20],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/SQBT9K2biR2wL6+pKcBNa5LHy2i3oGtfopR3KxOm0mZkCXeN/dx6tsCwaoGTOffTce8/cX2gUo7bXQH1Kko0c8KzIKyQoOMcsKlGbFZQ20EhMcZKFOUQYtddABW6gBey7aVYwidqv3ziXb4+QYNhTaRzvCBpN+6dQeBfUECf5osxV7qZiAxzXiVuvr946l8qjR0SkoTuQuApahr1jV4PNOEkIA3o2x3Pjgedz24Hwc9uBeW07bdiHfbQBlmDLt6lde0tVORZVYDfeAovwvODKUdQ1BRSEmK1DzLdEtxoNG08+qB6WcdTJviIN/vv3TSWFFWG2vS3bXh8EUbFoeOPde4Om63sespa7guqstgh9mBbpCvMaCQgEWayJjbsqYkJYKKEcZgWv66og5eF5bfPVfrB/DoYqStd4+U7pi61UX+NZIc2/1WCWpphHBGg34RinWA/T0vgLWDIWnHMSEZb8pZqlOceqSXFlCIs0Ba5JfFk+bul2871L3zwuRrcvl8nytpCD5D5ZjUK/1Xo5ufD6g5v+XWu5wlfB5qYvB2RZ+uvP+MscL6eJrv00q32r3/I/7CVmgmSsxibAfxY5jnV/n0o6p1CeleqccZ2GK2XNeZZjLokeC+p0OYdytv6Iy09ACzxbC8kVC/t8sU8pE+8f0EbKvO26ItrgFISTkohnIltLJ8pSt+V5F27Tc5XIVG/JI0jF1TWJxQOyOdrkkGW32zm7CyfjiQ5tuveTcWgSvyJMSC3iB3TdOc/I4Nc+BxaPeh1XHzrG7TocN8Jxx7UHYzkb7v634Gs1h3N9+oWqV6qWmReh3w10WwCTRJZ25Z3ZAefufncHPH6yYrRXkLEt5nrG+gaqK3ZuNfiQJJBgMWIRLWJsRT2BvFLCFFJ9jxbAyRpetFQlhrM1ij6khGqtjkeD4UIZZ34f425vUYvKnA33o7PhfXJFaqCveYManGrl06sflkLi1AkySnGkxSCcAWZKHZEzJkL+aH6tPELT9G/fzd2fK5YqvVGPzm3GcyRhjdmlow6fCJeF3aCnfpWp2uaV1TTwNOgwqVPLYV6nlnocv/8AFH6lSe0GAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2534.07,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH6ltoGW7iI2ETSBkwyMx7qZqqnawL2a047E1Mwacqv9952EXQtgKMJpzHz733jP3B5omqO+10JiSdCsnPC+LGglKzoHFFeqzktIWmoo5pHlY4BhQf4OpgBZa4cMwy0smUf99z/nwxwkS3IxUGsc7gabz8TkUPgQNxEmxqgqVu63YYA5N4k6v13V6ChwREWvoAUuog6JwdOpqsAUnKWGYXszx2njk+dp2JPzadmTe2M4bdn2It5ilYPm2tesoUpWDqAOHyQ6zGJYlV46iqSmgWIjFJgS+I7rV6Kb14oOaYRlHnewL0uD//76qpHhNmG1vx7bXx4KoWHRz6z16k4+u73nIWh5KqrPaIvRhXmZr4A0SEBzkiSZ2N1QRM8JCiaubvORNXTWkPDyvb77aDx9eg6GK0jV++FPpi61VX5NFKc2/1WCeZcBjgukw5QAZ6GFaGr8AS8aCS05iwtJfVPOs4KCalNSGsMwyzDWJz9Hzju6234a097ya3r+N0ui+lJP0MV1PQ7/TeTvreuPJ7fihE63hY7C9HcsJiSp/8w98XkI0T3Xt51ntW/2Of32QwATJWYPNMP+3LCDR/X0p6YLi6qJUl4zrNFwpa8nzArgkeixoMOQcV4vN31B9wrSExUZIrljY55tDRpn46wltpSz6riviLWRYOBmJeS7yjXTiPHM7ntd1256rRKZ6S56xVFxdk1g8IZujT45Z9vu9s+86OU91aNt9nN2FJvE7woTUIn5CV4PLjAx+5XPMkulo4OrDwLhdhdet8Hrg2oOxXAx3f1vwlZrDpT79QPUrVcvMi9DPFrovMZNEVnblXdgBl+7+cI958mLFaK8gZzvgesb6Bqordmk1+DhNcQpiymJaJmBFPcNFrYQ5zvQ9WmFONvhNV1ViOFujGOOMUK3V5V0UKtvCHwMMR6tGU+ZsqJ+cDe2zG9IAY00bq7mpTr68+WElJGROkFMKsdaCcCbAlDhi544I+b39pfYITc+/fjNXf6lIqvRGPDq3mc6JgjVmd46q+hPhsrQL9NyvNtXLvLaa/p0HHQd1bjmO69zSTOPnf6p7c1TsBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2710.18,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJtN0DsUokLJSuPNrA1mmdtktigjXHiWwHSKf99/mRDErZBAT53EfOvff4/kKjBHW9FgopSddyyPOyqJGg5ByzuEJdVlLaQiMxwWkeFRBj1F0BFbiF5rDrZ3nJJOqev3Yu3hwgwdVApXG8A2g0CY+h6C5oIE6KeVWo3G3FBjhuEnfeXpw7nvIYEBFr6A4kroMW0eDQ1WBTTlLCgJ7M8dy45/nctif83LZn3tiOG/ZhF6+BpdjybWvXwUJVjkUd2E82wGI8K7lyFE1NAQUhpqsI8w3RrUZXrScf1AzLOOpkX5EG//37ppLCkjDb3o5trw+CqFh0de3de8N3ru95yFruSqqz2iL0YVJmS8wbJCAQ5IkmdtNXEWPCIgnVVV7ypq4aUh6e1zVf7Qe752CkonSNF++UvthS9TWZltL8Ww3mWYZ5TID2U45xhvUwLY2/gCVjwRknMWHpX6p5VnCsmpTUhqjMMuCaxJfF44Zu1t/79PXjfHT7cpEubks5TO/T5SjyO52X4zMvHF6Hd53FEr8N1tehHJJF5a8+4y8zvJikuvbjrPatfsf/sJOYCZKzBhsD/1kWONH9fSrpgkJ1UqozxnUarpQ143mBuSR6LKjX5xyq6eojrj4BLfF0JSRXLOzzxS6jTLx/QGspi67riniNMxBORmKei3wlnTjP3I7nnbltz1UiU70ljyAVV9ckFg/I5uiSfZbtdutsz5ycpzq07d6PbyKT+BVhQmoRP6DL3mlGBr/0ObBkNOi5+tAzbpdR2IrCnmsPxnIy3P1vwZdqDqf69AvVr1QtMy9Cv1votgQmiazsyjuxA07d/f4WePJkxWivIGcbzPWM9Q1UV+zUavAhTSHFYsRiWibYinoMRa2ECWT6Hs2BkxW8OFeVGM7WKELICNVanU9nyjT1Q4z7g3kjKXM2zA/OhvXRBWmAULMGNTbVyKcXP6qExJkT5JTiWEtBOEPMlDZi54YI+aP9tfaITMu/fTc3f6Y4qvRGOzq3Gc6BgDVmV446fCJclnZ/HvvVpnqX11bTvuOg/ZyOLftpHVuaYfz+A78AqfnrBgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2900.57,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [27],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'LIGHT',
                    mappedFareName: 'Tarifa 2',
                    fareBasis: 'HJ0X0G1/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua4/aOhD9K5XVj9wkZPtEdCUSymPLazfQbtWtek0yBKuOE9kOkK363+tHUliWewUE+cwjZ2aO5xcaJ6jjtdCAknQrhzwvixoJS86BxRXqsJLSFhqLGaR5VOAYUGeDqYAWWuJDL8tLJlHn7RvH906QcNRXaZxTaDwbnEPRXdhAnBTLqlC524oN5tAk9l+9e+u8Vh59ImIN3WEJddAq6p+6GmzOSUoYphdzPDceeT63HQk/tx2ZN7bzhn08xFvMUrB829q1v1KVg6gDe8kOsxgWJVeOoqkppFiI+SYCviO61WjUevJBzbCMo072DWnwv3/fVVK8Jsy217ftDbAgKhaNbrx7b9h2A89D1nJXUp3VFqEPszJbA2+QkOAwTzSxSU9FTAmLJK5GecmbumpIeXhex3y1Hz48ByMVpWt8/V7pi61VX5N5Kc2/1WCeZcBjgmkv5QAZ6GFaGn8BS8aCC05iwtK/VPOs4KCalNSGqMwyzDWJr6vHHd1tf/Tom8fl+PblKl3dlnKY3qfrcRT4/svplTcY3gzu/NUa3oXbm4EcklUVbL7A1wWsZqmu/TyrfWvgBx8PEpggOWuwKeY/ywIS3d+nki4ori5KdcG4TsOVshY8L4BLoseCuj3OcTXffILqM6YlzDdCcsXCPl8cMsrEhwe0lbLouK6It5Bh4WQk5rnIN9KJ88z1Pe/KbXuuEpnqLXnEUnF1TWLxgGyODjlm2e/3zv7KyXmqQ9vu/XQSmcT/ECakFvEDuu5eZmTw64Bjloz7XVcfusbtOpq0oknXtQdjuRju/m/B12oOl/r0C9WvVC0zL0K/W+i2xEwSWdmVd2EHXLr7vT3myZMVo73CnO2A6xnrG6iu2KXVEOA0xSmIMYtpmYAV9RQXtRJmONP3aIk52eAXvqrEcLZGMcAZoVqrk/FwtFTGeTAA6PWXjajM2XA/ORveZ1ekAQaaN1aDU618evWjSkjInDCnFGItBuEMgSl1xM6ECPlv+1vtEZmmf/9h7v5CsVTpjXp0bjOeEwlrzC4ddfhMuCztBj33q031Nq+tpoHnQcdJnVuO8zq3NOP4/QfeOn2H7QYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2487.5,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2563.7,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PLUS',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'HJ0X0G8/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aSBT9K9WoH1nbgJZtEY2ETSCk4ZEYt6maqjvYFzPqeGzNjAGn6n/vPOxCCLsCjObch8+998z9iaYJ6nstNKYk3coJz8uiRoKSc2BxhfqspLSFpmIOaR4WOAbU32AqoIVW+DDM8pJJ1P+n53S8EyS4Gak0zik0nY/PofAhaCBOilVVqNxtxQZzaBJ3er2u01PgiIhYQw9YQh0UhaNTV4MtOEkJw/RijtfGI8/XtiPh17Yj88Z23rDrQ7zFLAXLt61dR5GqHEQdOEx2mMWwLLlyFE1NAcVCLDYh8B3RrUY3rRcf1AzLOOpkX5EG//v3TSXFa8Jsezu2vT4WRMWim1vv0Zu8c33PQ9byUFKd1RahD/MyWwNvkIDgIE80sbuhipgRFkpc3eQlb+qqIeXheX3z1X748BoMVZSu8e/3Sl9srfqaLEpp/q0G8ywDHhNMhykHyEAP09L4A1gyFlxyEhOW/qGaZwUH1aSkNoRllmGuSXyJnnd0t/0+pL3n1fT+bZRG96WcpI/pehr6nc7bWdcbT27HD51oDe+C7e1YTkhU+ZvP8GUJ0TzVtZ9ntW/1O/71QQITJGcNNsP8R1lAovv7UtIFxdVFqS4Z12m4UtaS5wVwSfRY0GDIOa4Wm49QfcK0hMVGSK5Y2OebQ0aZ+PCEtlIWfdcV8RYyLJyMxDwX+UY6cZ65Hc/rum3PVSJTvSXPWCqurkksnpDN0SfHLPv93tl3nZynOrTtPs7uQpP4L8KE1CJ+QleDy4wMfuVzzJLpaODqw8C4XYXXrfB64NqDsVwMd/+34Cs1h0t9+onqV6qWmRehXy10X2ImiazsyruwAy7d/eEe8+TFitFeQc52wPWM9Q1UV+zSavBxmuIUxJTFtEzAinqGi1oJc5zpe7TCnGzwm66qxHC2RjHGGaFaq8u7KFS2hT8GGI5WjabM2VA/ORvaZzekAcaaNlZzU518efPDSkjInCCnFGKtBeFMgClxxM4dEfLf9tfaIzQ9//bdXP2lIqnSG/Ho3GY6JwrWmN05qupPhMvSLtBzv9pUL/Paavp3HnQc1LnlOK5zSzONX78B3S7T7ewGAAA=',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2663.61,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2739.81,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'TOP',
                    mappedFareName: 'Tarifa 4',
                    fareBasis: 'HJ0X0G9/B00',
                    flightClasses: ['H', 'H', 'H', 'H', 'H', 'H', 'H', 'H'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1Ua2/aMBT9K5O1jywJdE/EKpGwULryaANdp3XaLokJ1hwnsh0gnfbf50dSKGUTEORzHzn33uP7G40S1PVaKKQkXcshz8uiRoKSc8ziCnVZSWkLjcQEp3lUQIxRdwVU4Baaw66f5SWTqPvurdPxDpDgYqDSOIfQaBIeQ9FN0ECcFPOqULnbig1w3CTuvH/z2vGUx4CIWEM3IHEdtIgGh64Gm3KSEgb0ZI7nxj3P57Y94ee2PfPGdtywT7t4DSzFlm9buw4WqnIs6sB+sgEW41nJlaNoagooCDFdRZhviG41umg9+aBmWMZRJ/uGNPjv33eVFJaE2fZ2bHt9EETFootL784bfnB9z0PWclNSndUWoQ+TMlti3iABgSBPNLGrvooYExZJqC7ykjd11ZDy8Lyu+Wo/2D0HIxWla3zzQemLLVVfk2kpzb/VYJ5lmMcEaD/lGGdYD9PSeAQsGQvOOIkJSx+p5lnBsWpSUhuiMsuAaxJfFw8buln/6NO3D/PR9ctFurgu5TC9S5ejyO90Xo7PvHB4Gd50Fkv8PlhfhnJIFpW/+oK/zvBikuraj7Pat/od/9NOYiZIzhpsDPxXWeBE9/eppAsK1UmpzhjXabhS1oznBeaS6LGgXp9zqKarz7i6BVri6UpIrljY54tdRpn4eI/WUhZd1xXxGmcgnIzEPBf5Sjpxnrkdzztz256rRKZ6Sx5AKq6uSSzukc3RJfss2+3W2Z45OU91aNu9G19FJvErwoTUIr5H573TjAx+7nNgyWjQc/WhZ9zOo7AVhT3XHozlZLj734LP1RxO9ek3ql+pWmZehP600HUJTBJZ2ZV3Ygecuvv9LfDkyYrRXkHONpjrGesbqK7YqdXgQ5pCisWIxbRMsBX1GIpaCRPI9D2aAycrePFaVWI4W6MIISNUa3U+nSnT1A8x7g/mjaTM2TA/OBvWRxekAULNGtTYVCOfXvyoEhJnTpBTimMtBeEMMVPaiJ0rIuTP9rfaIzIt//7D3PyZ4qjSG+3o3GY4BwLWmF056nBLuCzt/jz2q031Lq+tpn3HQfs5HVv20zq2NMP48xfJfig/6wYAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2854,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2930.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'PREMIUM ECONOMY TOP',
                    mappedFareName: 'Tarifa 6',
                    fareBasis: 'HJ0X0GA/B00',
                    flightClasses: ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
                    cabinType: 2,
                    supplier: 'Latam',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACn1UbW/aOhT+K5W1jywJqdZ7i1ilJBRKbyGUkK1onTaTmGBdx4lsB0in/ff5JRmUsgkI8nPOeXJeHp8fYJyCntMBQ4KzjRixoiobJKgYQzSpQY9WhHTAmE9RVkQlTBDorSHhqAMWcO/lRUUF6P1zZbnOERLcDSSNdQyNp8NTKJoHLcRwuahLyd2V2UCGWmL32r22HOkxwDxR0BwK1ATF0eDYVWMhwxmmkJzleGs85PnWdkj4re2QeWs7bdjtPtlAmiGTb1e5DmJZOeJNoJduIU3QrGLSkbc1BQRyHq4jxLZYtRp87rz6gHZY2lGRfQEK/PPvqySFK0xNe13TXh9yLGPB3b3z5Iw823ccYCzziihWU4Q6TKt8hViLBBgGRaoSe/BkxATTSMD6rqhYW1cDSQ/H6emv8oP7t2Ako1SNH66lvuhK9jUNK6H/jQaLPEcswZB4GUMoR2qYJo3fgEnGgDOGE0yz36kWecmQbFLaGKIqzyFTSSzjly3Zbr555OplMX58F2fxYyVG2VO2Gke+676bXDrD0f1w7sYr9G+wuR+KEY5rf/0ZLWconmaq9lNW81bf9W/3AlGOC9piE8j+r0qUqv6+lnRJYH1WqjPKFA2TypqxokRMYDUW0PcYg3W4/g/VnyCpULjmgskszPNinxPKPz6DjRBlz7Z5skE55FaOE1bwYi2spMht13Eu7a5jS5HJ3uIXKGSutibmz8Bw9PCBZbfbWbtLq2CZCu3aT5OHSBO/x5QLJeJncNM/n5HGb3wGaToe9G116Gu3m/myM1/2bXPQlrPh9l8LvpFzONenH6B5pWyZfhH42QGPFaQCi9qsvDM74Nzd93aQpa9WjPIKCrpFTM1Y3UB5xc6tBh9mGcwQH9OEVCkyop7AslHCFObqHi0gw2t4cSUr0TkbIx/CHBOl1dn8djKOJxe3QTgNJ8uLRTiTrqE/RMgbLFqJ6bOu5Oisqzi5MC0wVFVAOUbZ2NeLIKq5QLkVFISgREmDWyNEpVYS6wFz8b37pfGI9Ai+ftObYCZzlvRaS4pbD+tI0AozK0gePmEmKrNPT/0aU7PbG6tu52nQYW6nlsP0Ti3tcH7+ApdpGvX7BgAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 76.2,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2929,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3005.2,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [46],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'Azul',
                    mappedFareName: 'Tarifa 1',
                    fareBasis: 'F100XBG',
                    flightClasses: ['F', 'F', 'F', 'F', 'F', 'F', 'F', 'F'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVW+2/aOhT+VyZrP3KTQHd3V8QqJbAwdsujBDamtdo1iQnWdZzIdni0av72azsJBEK3K1VtQT6Pz995+Jw+gUEA2lYDuASHa9FncZoUkm7KGKL+HrSBM70FDTDgIxTGXgJ9BNorSDhqgBnc2VGcUgHa794bf/5VkXQ/9ySOYVVEg5F7LvKm3VLEcDLbJxK7KelAhkrg1rvra+NaWvQw95VoCgUqnOZer2qqZWOGQ0whuYBhXVQfmdZ1R8p13ZF7qavl7NPOX0MaopxyU9n25jJ4xAtPO9hA6qNJyqQhL8PqEsj5eOUhtsEq28BtnPyAsmDaUIH9AFr44t+DBIVLTPMMt/IMO5Bj6QvcpmUtnD7IpdOUKESaEtIA6jBKoyViKqaF1ZRGXQy7caBo2T15HGLqCbj/HKesjKoQSQvLautfZQd3daEnvVSEzQ+ywehSpjUYp0J/510YRxFiPobEDhlCEVLVzKkdBDmZXDhh2Mc0LI/SPWFIpigoFF4aRZApEt/njxuyWf+0yfvH2eDu7Tyc36WiHy7C5cBzWq23wyvL7X9xp635En3orr+4oo/ne2f1DX2foPkotCX9c9T8VqflfNoJRDmOaSkbQvZvmqBAZfi0pxMC9xf7fUKZgmGysSYsThATWBUGdGzG4H68+hvtv0KSovGKCyZZ5J9vdhGh/OM9WAuRtE2T+2sUQW5E2Gcxj1fC8OPIbFnWldm0TNliMrf4EQrJ1dTA/B7kGG18RNlut8b2yohZqFyb5mJ462ngPzDlQrXwPbjpXGak5TceIkR+d0x16GizGytzs8zuZUUDZqrBsszKrrJs0TFzG+3wEuqLt1UfnjEmwcm18h2+Dr185zVk+epfh3xshBr2YYa97obD6K1doCf478HNX7bfjZ569a59AkUDqPf/i7KfDU1FUnrIiqnBU0n7YcSeZkyKD3mSypNgpU6HCJ4b4C6FVGAh2VxXIcpVoDZibQeocb2FLDjZNsqsG9MNYuq1q0n89Pt1ENQ0OpPFhiitdNYuTob/T7i+raz61n5+fnjB0oFhCCWTAfVJGqBAL+chTIpBNoKRWgMzyPAKvlG7wWGQBrmSuzDCRIVoP6ZE6saOi5Ddm5UjUZ91AJWz5n424EuBq3IN5djJ20i1TdFC3p4LFBndmBDkq1HGjT6icrb5xi3m4p/mj8LC00368FNvrokkKeH17FPYRRFO0pwvTHn4iplI8/V/bleoin9GCq3ujnOnSrnOVZWinasOBXn+DxMonRawCQAA',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2499.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2499.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2499.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2546.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MaisAzul',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'F100XAD',
                    flightClasses: ['F', 'F', 'F', 'F', 'F', 'F', 'F', 'F'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWaW/bOBD9KwXRj15JtrvZ1nADyHbluhsfsezWRRN0aYmWiaUogZR8JIh++w4pyZeSdoEgh8E5Ht8cnPEjGvioZdWQw2iwTvoiSuNC0k2FINzboxbqTG9QDQ3kiASRG2OPoNYKM0lqaIZ3dhilPEGtd1fGn3+dSLqfe4BjWCeiwci5FLnTbikSNJ7tY8CuAx0sSAncuKq/Mz6ARY9KT4mmOCGF09ztnZpq2VjQgHLMnsGwnlUfmVZ1R8pV3ZF7qavk7NPOW2MekJxyXdn25hA8kYWn7W8w98gkFWAoy7C6DEs5XrlEbKjKNnJqZz+oLJg2VGA/kBa++HcPoHhJeZ7hRp7hDpYUfJFTt6yF3UO5dJoyhchTxmpIHUZpuCQC7CyrvgCjLsXdyFe0tM+QcjfB+89RKsqoCpF2aelfZYd3VaELXirC+ntoML6EtPrjNNGfeRdGYUiERzGzA0FISFQ1c2oHQU4mF04E9SgPyiO4x4JAivxC4aZhiIUi8X3+sGGb9U+bXT3MBrdv58H8Nk36wSJYDtxOo/F22LSc/hdn2pgvyfvu+ouT9Ol831l9I98nZD4KbKB/iZrf2ml0Pu0SwiWNeCkbYvFvGhNfZfi8p2OG98/2+4QLBSOgsSYiiolIqCoMattC4P149TfZf8UsJeOVTASwyP+/2YWMy493aJ0kccs0pbcmIZZGSD0RyWiVGF4Umg3Lapp1y4QWg9zSB5wAV1MDyzuUY7ToEWW73RrbphGJQLnWzcXwxtXAf1AuE9XCd+i6/TwjLb92CWPw2TbVoa3Nrq3MyTK7lxUNmKkGyzIra2bZom3mNtrhJdQXbzt9eMaY+WfXwjt8HXr5zivI8Opfh3xshAr2YYa97obD6K1coCf478HNX7bftZ561a59REUDqPf/i7JfDE1FEjygYmrwnKT9MGLPMwbiQ55AeRYs6HSI6KmGblPME5oAmw+nEOUqUBuxsgPUuN5i4Z9tG2XWjfiGCPXa1SR+/P068CsancliQ5RWOmvPTob/T7i6razq1n56un/BsoODAAOTAfdY6hNfr44hjotBNsKhWgMzLOgKv2lCHB2BuZ8rpYNDylSIQ0yl/ZAy0I87DiF2b1aORX3WQZycNf+LIV8KHJVvDKOnaCVonaKN3L1MSGh0I8aIp8aZNPqEw3zzjBsqk3/qPwoLVzfq/U+9vSZAFOD1/FPYRSHOUp0vTTh8pSJJ868Al3aFqvhCUmh1h1w6nZTsUnVSuEvVoShP/wGv9eOotAkAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 2614.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 2614.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 2614.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 2661.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
          {
            airTrips: [47],
            fares: [
              {
                fareInformations: [
                  {
                    name: 'Azul',
                    mappedFareName: 'Tarifa 1',
                    fareBasis: 'Y100BG',
                    flightClasses: ['Y', 'Y', 'Y', 'Y', 'Y', 'Y', 'Y', 'Y'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: false,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWaW/bOBD9KwXRj15JdtJsa3gDSHbtuhsfsew2QRN0aYmWiaUogaJ8JIh++w4pyZecdoEgiQ3O8TgX3+QZ9X3UtGqoy2iwlD0RpXEhaadCEO5tURM5kxtUQ/1kSILIjbFHUHOBWUJqaIo3dhilXKLm5ZXx4c8DSftLB3AM60DUH3ZPRe6kXYoEjafbGLDrEA4WpAS+uLr8ZHwCiw5NPCWaYEkKp5nbOTTVspGgAeWYncGwzqr3kVZ1+5Crun3spa5Ss88bb4l5QPKQ68q2M4PkSVJ42v4Kc4+MUwGGSZlWm+EkGS1cIlZUVRvd145+UNkwbajAfiAlfP3vEUDxnPK8wo28wg5OKPii+7plOT2UCycpU4A8ZayG1GGYhnMiwGz0fTgBozbF7chXUdkdOA4odyXefolSUSZViMDCspr6V9nhTVXogpdKsP4R5ovPoar+KJX6Ox/CKAyJ8ChmdiAICYlqZh7aTpAHkwvHgnqUB+UR3GNBoEJ+oXDTMMRCBXE/e1qx1fKnza6epv3b97NgdpvKXnAXzPuu02i8H1xY3d7X7qQxm5OP7eXXruzR2dZZfCf3YzIbBjaEf4qa3+o0nM8bSXhCI17KBlj8m8bEVxU+HumY4e3ZcR9zoWAEzNVYRDERkqrGoJYtBN6OFn+T7TfMUjJaJFJAFPnnu03IePLXA1pKGTdNM/GWJMSJEVJPREm0kIYXhWbDsi7MumXChEFt6ROWEKupgZMHlGM06R5lvV4b6wsjEoFyrZt3gxtXA/9BeSLVBD+g69b5iLT82iWMwXfLVIeWNru2svsssztZPn+Zmq8ss7LGh8ssu2uZuZH2eA321esOH54xYv7RvfAO34ZevvMKMrz6tyHvJ6GCveOwt92wo97KBZrBfw9u/nL+rjXrVcf2GRUToAjgV30/YU0VJbhAyxT1HNR9x7HHJQPxrlCgPMoWdDpH9FJDtynmksrtjgyPd4FaiZUloPh6jYV/tG6UWTviKyLUe1dU/Pz7feBXNLqUxYoorXTZznLD/w+4uq6s6tp+eXl8xdLBQYAhkj73WOoTX2/nAY4LKhviUC2CKRZ0gd/VIQ9HYO7nyqSLQ8pUivZTykA3crqE2J1pSYr6rBM4OOvYTyi+FHRVrTEQTz5HanDyGXK3iSSh0Y4YI57issToEQ7k5hk3NJH/1H8UFq4e0sefenWNIUZA1+SnoIseHFU5X5hw+EaFTPP1f2pXqIp/RgqtHo5Tp4NunaoOenaq2vXj5T976xhOsAkAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 3649.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 3649.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 3649.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3696.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
              {
                fareInformations: [
                  {
                    name: 'MaisAzul',
                    mappedFareName: 'Tarifa 3',
                    fareBasis: 'Y100AD',
                    flightClasses: ['Y', 'Y', 'Y', 'Y', 'Y', 'Y', 'Y', 'Y'],
                    cabinType: 2,
                    supplier: 'Azul',
                    isRoundTrip: false,
                    airTrip: 0,
                    baggagesIncluded: true,
                    compressedAdditionalProperties:
                      'H4sIAAAAAAAACqVWa2/aOhj+K5O1j5wkQNftIE6lBAZjp1xKYCtaqx2TmGAdx4lsB0ir5rcf20m4hW5HqnpBfi+P35ufl2cw8EHLqoEewcFa9FmUxIWkkzCGqJeCFnCmt6AGBnyEgsiNoYdAawUJRzUwgzs7jBIqQOvq2vjw8UjS+dKVOIZ1JBqMeucid9opRQzHszSW2HUZDmSoBG5+vL4y/pQWXcw9JZpCgQqnuds9NtWyMcMBppBcwLAuqg+RVnWHkKu6Q+ylrlKzzztvDWmA8pDryrY7l8kjXnja/gZSD00SJg15mVaHQM7HKxexDVbVBovayQ8oG6YNFdgPoISv/z1KULjENK9wI6+wAzmWvmBRtyy7C3LhNCEKkCaE1IA6jJJwiZg0s6zxd2nUwbAT+Soq7TPE1BUw/RIlrEyqEGmXlv5VdnBXFbrSSyVY/yTniy5lVf1xIvRnPoRRGCLmYUjsgCEUItXMPLS9IA8mF04Y9jANyqN0jxmSFfILhZuEIWQqiMX8aUM26582uX6aDe7ez4P5XSL6wX2wHLhOo/F+2LR6/a+9aWO+RJ8666890cfz1Fl9R4sJmo8CW4Z/jprf6jSczzuBKMcRLWVDyP5NYuSrCp+OdExgenHcJ5QpGCbnasKiGDGBVWNA22YMpuPV3yj9BkmCxisumIwi//9uFxLK/3oAayHilmlyb41CyI0Qeyzi0UoYXhSaDctqmnXLlBMma4ufoJCxmhqYP4Aco4UPKNvt1tg2jYgFyrVu3g9vXQ38B6ZcqAl+ADftyxFp+Y2LCJGfbVMd2trsxsoWWWZ3s3z+MjVfWWZlV9cfsuy+beZG2uM12FevO354xpj4J/fKd/g29PKdV5Dlq38b8mESKth7DnvbDXvqrVygGfz34OYv5+9Gs151bJ9BMQGKAH7V9zPWVFFKF9kyRT1Hdd9z7GnJpHhfKKk8yVbqdI7gpQbuEkgFFumeDE93gVqJlSWg+HoLmX+ybpRZJ6IbxNR7V1T8/Pt94Fc0upTFiiitdNkucsP/D7i6rqzq2n55eXzF0oFBAGUkA+qRxEe+LtcQxgWVjWCoFsEMMryC75oyD4dB6udK3oMhJirFIcTcfkqI1I+dHkJ2d1YSoz7rJI7OOv4zmi8FPVVvKMmnmCU5PPkcuSkXKDQ6ESHIU3zGjT6ikuA84xZz8U/9R2Hh6kF9/KnX10TGKdE1ASroog8nlc6Xpjx8w0wk+VeAc7tCVXwhKbR6QM6djjp2rjrq27lq35OX/wDEFf7NtAkAAA==',
                  },
                ],
                pricing: {
                  id: 0,
                  markup: {
                    value: 0,
                    percentage: 0,
                  },
                  fee: {
                    value: 0,
                    percentage: 0,
                  },
                  calculatedMarkup: {
                    value: 0,
                  },
                  calculatedFee: {
                    value: 0,
                  },
                  totalFee: 0,
                  subPricings: [],
                },
                exchangeRate: {
                  value: 1,
                },
                travellersFareValues: [
                  {
                    type: 0,
                    travellersCount: 0,
                    fare: {
                      value: 3764.9,
                      currencyCode: 'BRL',
                    },
                    originalFare: {
                      value: 3764.9,
                      currencyCode: 'BRL',
                    },
                    taxes: {
                      value: 46.57,
                      currencyCode: 'BRL',
                    },
                    markupedFare: {
                      value: 3764.9,
                      currencyCode: 'BRL',
                    },
                    du: {
                      value: 0,
                      currencyCode: 'BRL',
                    },
                    total: {
                      value: 3811.47,
                      currencyCode: 'BRL',
                    },
                  },
                ],
                compressedPricingSummary:
                  'YUzvlvh_Al6zTIQ$UgUQutGgXgbISB22$M30FGJFR2Ube8ChJFtGiUyBfWeYPeUNgA',
              },
            ],
          },
        ],
      },
    ],
    airlines: {
      G3: {
        code: 'G3',
        name: 'Gol Linhas Aéreas',
        displayName: 'Gol Linhas Aéreas',
      },
      AD: {
        code: 'AD',
        name: 'Azul Linhas Aéreas Brasileiras S.A.',
        displayName: 'Azul Linhas Aéreas',
      },
      JJ: {
        code: 'JJ',
        name: 'LATAM (JJ)',
        displayName: 'LATAM (JJ)',
      },
      LA: {
        code: 'LA',
        name: 'LATAM Airlines',
        displayName: 'LATAM',
      },
    },
    summary: {
      searchId: 'e18dfae4-f822-4cc2-89da-50a90cc1c1f0',
      count: 0,
    },
    locations: {
      '255767': {
        i: 255767,
        t: 4,
        ia: 'CWB',
        n: 'Curitiba / PR, Brasil (CWB)',
        p: 797,
      },
      '258559': {
        i: 258559,
        t: 4,
        ia: 'FLN',
        n: 'Florianópolis / SC, Brasil (FLN)',
        p: 805,
      },
      '275279': {
        i: 275279,
        t: 4,
        ia: 'SAO',
        n: 'São Paulo / SP, Brasil (SAO)',
        p: 806,
      },
      '2701180': {
        i: 2701180,
        t: 5,
        ia: 'CWB',
        n: 'Afonso Pena International Airport / PR, Brasil (CWB)',
        p: 255767,
      },
      '2701199': {
        i: 2701199,
        t: 5,
        ia: 'VCP',
        n: 'Viracopos (Campinas International) / SP, Brasil (VCP)',
        p: 275279,
      },
      '2702586': {
        i: 2702586,
        t: 5,
        ia: 'CGH',
        n: 'Congonhas Airport / SP, Brasil (CGH)',
        p: 275279,
      },
      '2702587': {
        i: 2702587,
        t: 5,
        ia: 'GRU',
        n: 'Aeroporto Internacional de Guarulhos / SP, Brasil (GRU)',
        p: 275279,
      },
      '2702983': {
        i: 2702983,
        t: 5,
        ia: 'FLN',
        n: 'Hercilio Luz / SC, Brasil (FLN)',
        p: 258559,
      },
    },
    successful: true,
    errors: [],
    messages: [],
  },
};
