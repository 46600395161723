import axios from 'axios';
import {
  getFromLS,
  setInLS,
  parseJWT,
} from '@flash-tecnologia/hros-web-utility';
import { config } from '@shared/config';
import {
  CONNECT_ON_ACCESS_TOKEN,
  TRAVEL_FLASH_OS_EMPLOYEE_ID,
  TRAVEL_FLASH_OS_COMPANY_ID,
  HROS_ACCESS_TOKEN,
} from '@shared/constants/Storage.constants';
import { checkIsExpired } from '@shared/jwt';
import { apiResponse } from '@legacy-utils/apiResponse';

let callRetryCount = 0;

const secondsDiff = (startDate: Date, endDate: Date) => {
  const diff = endDate.getTime() - startDate.getTime();
  return Math.abs(diff / 1000 / 60);
};

const mustRevalidateToken = (error: any) => {
  const status = error?.response?.status;
  const now = new Date();
  const connectOnAccessToken = getConnectOnAccessToken();
  return (
    status &&
    [401, 403].includes(status) &&
    callRetryCount < 2 &&
    (!connectOnAccessToken.issuedAt ||
      secondsDiff(new Date(connectOnAccessToken.issuedAt), now) >= 10)
  );
};

export const getAuthContextData = () => {
  const employeeId = getFromLS(TRAVEL_FLASH_OS_EMPLOYEE_ID);
  let selectedCompanyId = getFromLS(TRAVEL_FLASH_OS_COMPANY_ID);

  if (!selectedCompanyId) {
    const company = getFromLS('selectedCompany');
    selectedCompanyId = company?.id;
  }

  return { employeeId, selectedCompanyId };
};

const getConnectOnAccessToken = () => {
  const { employeeId, selectedCompanyId } = getAuthContextData();
  const connectOnAccessTokenLSKey = `${CONNECT_ON_ACCESS_TOKEN}-${employeeId}-${selectedCompanyId}`;

  const connectOnAccessToken = getFromLS(connectOnAccessTokenLSKey) || {
    accessToken: '',
    issuedAt: null,
  };

  return connectOnAccessToken;
};

const registerAuthToken = async (flashOsAccessToken: string) => {
  const { employeeId, selectedCompanyId } = getAuthContextData();
  const connectOnAccessTokenLSKey = `${CONNECT_ON_ACCESS_TOKEN}-${employeeId}-${selectedCompanyId}`;

  let token: { accessToken: any; issuedAt: Date };
  if (!token) {
    const accessToken = await signInByToken(
      flashOsAccessToken,
      selectedCompanyId,
    );
    token = {
      accessToken,
      issuedAt: new Date(),
    };

    setInLS({
      key: connectOnAccessTokenLSKey,
      value: token,
    });
  }

  return token;
};

const signInByToken = async (token: string, selectedCompanyId: string) => {
  const { data } = await callApi({
    method: 'post',
    url: 'security/signin/token',
    data: { token, selectedExternalCompanyId: selectedCompanyId || null },
    axiosOptions: {
      noHeaders: true,
    },
  });
  return data;
};

export const resolveAccessToken = async (force = false) => {
  const flashOS = getFromLS(HROS_ACCESS_TOKEN);
  const accessToken = flashOS?.accessToken;

  const connectOnAccessToken = getConnectOnAccessToken();

  const token = connectOnAccessToken?.accessToken || '';

  try {
    const parsedToken = token && parseJWT({ token });

    const now = new Date();
    const isExpired =
      !parsedToken ||
      checkIsExpired(parsedToken) ||
      connectOnAccessToken.issuedAt > now.setHours(now.getHours() - 2);

    let updatedToken = null;
    if (force || isExpired) {
      const authorization: string = !accessToken
        ? null
        : `Bearer ${accessToken}`;

      updatedToken = await registerAuthToken(authorization);
    }

    return updatedToken?.accessToken || token;
  } catch (err) {
    return token;
  }
};

interface IApiCallProps {
  method: 'get' | 'post' | 'put' | 'delete';
  url: string;
  data?: { [key: string]: any };
  axiosOptions?: { [key: string]: any };
}

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  async (config) => {
    const axiosOptions = config as any;

    if (axiosOptions?.noHeaders) return config;

    config.headers['Access-Control-Allow-Origin'] = '*';

    const refreshedAccessToken = await resolveAccessToken();

    if (refreshedAccessToken)
      config.headers['Authorization'] = `Bearer ${refreshedAccessToken}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
axiosInstance.interceptors.response.use(
  (response) => {
    callRetryCount = 0;
    return response;
  },
  async (error) => {
    if (mustRevalidateToken(error)) {
      const refreshedAccessToken = await resolveAccessToken(true);
      error.config.headers['Authorization'] = 'Bearer ' + refreshedAccessToken;
      callRetryCount++;
      return axiosInstance.request(error.config);
    }

    return Promise.reject(error);
  },
);

export const callApi = async (
  { method, url, data, axiosOptions }: IApiCallProps,
  contextId?: string,
) => {
  const options = {
    ...axiosOptions,
    baseURL: config.connectOnApi.url,
    method,
    url,
    data,
  };

  if (contextId) {
    options['headers'] = options['headers'] || {};
    options['headers']['exon-context-id'] = contextId;
  }

  try {
    const response = await axiosInstance(options);
    (response || {})?.data
      ? apiResponse.successful.showErrorIfExists(response.data)
      : apiResponse.http.showErrorIfExists(response || {});

    return response || { data: {} };
  } catch (error) {
    apiResponse.http.showErrorIfExists(error);
    return { data: {} };
  }
};
