'use strict';

import React from 'react';
import Template from './booking-hotel-fariffs-and-fess.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';
import { enums } from '@legacy-utils/enums';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import { componentUtil } from '@legacy-utils/componentUtil';
import { getUserProfile } from '@legacy-dataSources/profile.dataSources';

export default class BookingHotelTariffsAndFeesComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.formManager, this);
    componentUtil.react.bindMethods(this.events.form.fields, this);

    this.state = {
      userProfile: null,
      displayFareRule: false,
      fareRuleIndex: null,
      tariffsAndFees: this.props.tariffsAndFees
        ? this.props.tariffsAndFees
        : null,

      form: {
        show: false,
        events: this.events.form,
        fields: this.formManager.getFields(),
        forceValidation: false,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      tariffsAndFees: props.tariffsAndFees
        ? props.tariffsAndFees
        : state.tariffsAndFees,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          userProfile={this.state.userProfile}
          tariffsAndFees={this.state.tariffsAndFees}
          canEdit={this.props.canEdit}
          canEditFares={this.props.canEditFares}
          form={this.state.form}
          events={this.events}
        />
      </ErrorBoundary>
    );
  }

  events = {
    form: {
      popup: {
        onOpenFormPopupClicked: (action, index) => {
          let form = this.state.form;

          if (action == enums.actionsForm.edit) {
            this.formManager.filledFields(form, index);
          }

          form.show = true;
          form.index = index;

          this.setState({ form });
        },

        onCloseFormPopupClicked: () => {
          let form = this.state.form;

          this.formManager.clearFields(form);

          this.setState({
            form: {
              ...this.state.form,
              show: false,
              forceValidation: false,
            },
          });
        },

        onConfirmPopupClicked: () => {
          let form = this.state.form;
          let booking = this.props.booking;

          if (this.formManager.fieldsValidate()) {
            this.formManager.prepareForShipping(booking, form.index);
            console.log(booking);

            this.props.events.onEdittariffsAndFeesClick &&
              this.props.events.onEdittariffsAndFeesClick(booking);

            this.setState({
              form: {
                ...this.state.form,
                show: false,
                forceValidation: false,
              },
            });
          }
        },
      },

      fields: {
        onLocationChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value.name = inputText;
          this.setState({ form });
        },

        onChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value = inputText;
          this.setState({ form });
        },

        onSelected: (refName, selectedData) => {
          let form = this.state.form;
          form.fields[refName].value = selectedData.value;
          this.setState({ form });
        },
      },
    },

    onDeletetariffsAndFeesClicked: (index) => {
      let booking = this.props.booking;
      booking.hotel.rooms = booking.hotel.rooms.filter((room, i) => i != index);

      this.props.events.onEdittariffsAndFeesClick &&
        this.props.events.onEdittariffsAndFeesClick(booking);
    },
  };

  formManager = {
    getFields: () => {
      return {
        system: this.formManager.generateField.text({
          label: 'Sistema',
          refName: 'system',
          groupField: 'room',
        }),
        name: this.formManager.generateField.text({
          label: 'Quarto',
          refName: 'name',
          groupField: 'room',
        }),
        mealOption: this.formManager.generateField.text({
          label: 'Regime',
          refName: 'mealOption',
          groupField: 'fareInformation',
          required: false,
        }),
        status: this.formManager.generateField.text({
          label: 'Status',
          refName: 'status',
          groupField: 'room',
        }),
        days: this.formManager.generateField.number({
          label: 'Q. Diárias',
          refName: 'days',
          groupField: 'fareInformation',
          additionalClass: 'exon-field-row',
        }),
        exchangeRateCurrencyCode: this.formManager.generateField.select({
          label: 'Moeda do câmbio',
          refName: 'exchangeRateCurrencyCode',
          required: true,
          groupType: 'currencyCode',
          dataSource: {
            load: function () {
              return Promise.resolve(
                listItemsResolver.resolveEnum(enums.currencyCode),
              );
            },
          },
        }),
        exchangeRate: this.formManager.generateField.number({
          label: 'Valor câmbio',
          refName: 'exchangeRate',
          groupField: 'fare',
          required: true,
        }),
        dailyFare: this.formManager.generateField.number({
          label: 'Tarifa diária',
          refName: 'dailyFare',
          groupField: 'fare',
        }),
        dailyTax: this.formManager.generateField.number({
          label: 'Taxa diária',
          refName: 'dailyTax',
          groupField: 'fare',
        }),
        markup: this.formManager.generateField.number({
          label: 'Total de margem',
          refName: 'markup',
          groupField: 'fare',
        }),
      };
    },

    filledFields: (form, index) => {
      let tariffsAndFees = this.state.tariffsAndFees[index];
      Object.keys(form.fields).forEach((refName) => {
        if (form.fields[refName])
          switch (form.fields[refName].groupField) {
            case 'room':
              form.fields[refName].value = tariffsAndFees
                ? tariffsAndFees[refName]
                : null;
              break;
            case 'fare':
              if (form.fields[refName])
                if (refName == 'exchangeRate')
                  form.fields[refName].value =
                    tariffsAndFees &&
                    tariffsAndFees.fares &&
                    tariffsAndFees.fares.exchangeRate &&
                    tariffsAndFees.fares.exchangeRate.value
                      ? tariffsAndFees.fares.exchangeRate.value
                      : null;
                else
                  form.fields[refName].value =
                    tariffsAndFees &&
                    tariffsAndFees.fares &&
                    tariffsAndFees.fares.roomValue &&
                    tariffsAndFees.fares.roomValue[refName]
                      ? tariffsAndFees.fares.roomValue[refName].value
                      : null;
              break;
            case 'fareInformation':
              form.fields[refName].value = tariffsAndFees
                ? tariffsAndFees[refName]
                : null;
              if (
                tariffsAndFees.fares &&
                tariffsAndFees.fares.fareInformation &&
                refName == 'days'
              ) {
                form.fields[refName].disabled =
                  tariffsAndFees.fares.fareInformation.checkIn &&
                  tariffsAndFees.fares.fareInformation.checkOut;
              }
              break;
            default:
              if (refName == 'exchangeRateCurrencyCode')
                form.fields[refName].value =
                  tariffsAndFees &&
                  tariffsAndFees.fares &&
                  tariffsAndFees.fares.exchangeRate &&
                  tariffsAndFees.fares.exchangeRate.origin
                    ? enums.currency[tariffsAndFees.fares.exchangeRate.origin]
                    : null;
          }
      });
    },

    clearFields: (form) => {
      Object.keys(form.fields).forEach((refName) => {
        form.fields[refName].value = null;
      });
    },

    generateField: {
      location: (data) => {
        let _this = this;
        return {
          type: enums.fieldType.locations,
          value: {},
          label: data.label,
          refName: data.refName,
          placeholder: `Digite ao menos 3 letras`,
          options: {
            events: {
              onInputChanged: (inputText) => {
                this.events.form.fields.onLocationChanged(
                  data.refName,
                  inputText,
                );
              },
              onSelected: (selectedData) => {
                this.events.form.fields.onSelected(data.refName, selectedData);
              },
            },
            dataSource: _this.props.dataSources.hotelLocations,
          },
          locationType: enums.locationType.city,
          disabled: false,
          required: data.required ? data.required : true,
          additionalClass: data.additionalClass ? data.additionalClass : null,
          groupField: data.groupField ? data.groupField : null,
        };
      },
      number: (data) => {
        let numberField = this.formManager.generateField.commomFields(data);
        numberField.type = enums.fieldType.number;

        return numberField;
      },
      text: (data) => {
        let textField = this.formManager.generateField.commomFields(data);
        textField.type = enums.fieldType.text;

        return textField;
      },
      select: (data) => {
        let selectField = this.formManager.generateField.commomFields(data);
        selectField.type = enums.fieldType.select;
        selectField.items = [];
        selectField.onFieldChange = this.events.form.fields.onSelected;
        selectField.dataSource = data.dataSource;
        selectField.completeItem = true;
        selectField.isZeroValid = true;

        return selectField;
      },
      mask: (data) => {
        let fieldMask = this.formManager.generateField.commomFields(data);
        fieldMask.type = enums.fieldType.mask;
        fieldMask.mask = data.mask;
        data.removeMask !== undefined
          ? (fieldMask.removeMask = data.removeMask)
          : null;

        return fieldMask;
      },
      commomFields: (data) => {
        return {
          placeholder: data.placeholder ? data.placeholder : `Digite aqui`,
          additionalClass: data.additionalClass ? data.additionalClass : null,
          label: data.label,
          refName: data.refName,
          value: data.valeu ? data.value : null,
          required:
            data.required != null || data.required != undefined
              ? data.required
              : true,
          disabled:
            data.disabled != null || data.disabled != undefined
              ? data.disabled
              : false,
          onFieldChange: this.events.form.fields.onChanged,
          groupField: data.groupField ? data.groupField : null,
        };
      },
    },

    fieldsValidate: () => {
      let isValid = true;
      let fields = this.state.form.fields;

      Object.keys(fields).forEach((refName) => {
        let fieldValue = fields[refName].value;

        if(fields[refName].required)
          isValid =
            isValid &&
            fieldValue !== null &&
            fieldValue !== undefined &&
            fieldValue !== '';
      });

      if (!isValid)
        this.setState({
          form: {
            ...this.state.form,
            forceValidation: true,
          },
        });

      return isValid;
    },

    prepareForShipping: (booking, index) => {
      let fields = this.state.form.fields;
      let room =
        booking.hotel && booking.hotel.rooms && booking.hotel.rooms[index]
          ? booking.hotel.rooms[index]
          : {};
      let fares =
        room && room.fares && room.fares[0]
          ? room.fares[0]
          : {
              roomValue: {
                dailyFare: {},
                dailyTax: {},
                markup: {},
              },
              exchangeRate: {
                value: null,
                origin: null,
              },
              fareInformation: {
                board: {},
              },
            };

      Object.keys(fields).forEach((refName) => {
        if (fields[refName])
          switch (fields[refName].groupField) {
            case 'room':
              room[refName] = fields[refName].value;
              break;
            case 'fare':
              if (refName == 'exchangeRate')
                fares[refName] = {
                  value: fields[refName].value,
                  origin:
                    enums.currencyCode[fields[`${refName}CurrencyCode`].value],
                };
              else {
                fares.roomValue[refName] = {
                  value: fields[refName].value,
                  currencyCode:
                    enums.currencyCode[
                      fields['exchangeRateCurrencyCode'].value
                    ],
                };
              }
              break;
            case 'fareInformation':
              fares['fareInformation'][refName] = fields[refName].value;

              if (refName == 'mealOption')
                fares['fareInformation'].board = {
                  description: fields[refName].value,
                };
              break;
          }
      });

      room.fares[0] = fares;

      if (!isNaN(index) && index >= 0) booking.hotel.rooms[index] = room;
      else booking.hotel.rooms.push(room);
    },
  };

  componentDidMount() {
    getUserProfile().then(({ userProfile }) => {
      this.setState({ userProfile });
    });
  }
}
