import React from 'react';
import PopupTemplate from './popup.template.js';

export default class PoupopComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PopupTemplate.main
        okButtonLabel={this.props.okButtonLabel}
        cancelButtonLabel={this.props.cancelButtonLabel}
        title={this.props.title}
        children={this.props.children}
        okButtonClick={this.props.okButtonClick}
        disabledOkButton={
          this.props.disabledOkButton ? this.props.disabledOkButton : null
        }
        cancelButtonClick={this.props.cancelButtonClick}
        closeButtonClick={
          this.props.closeButtonClick
            ? this.props.closeButtonClick
            : this.props.cancelButtonClick
        }
        exonStyle={this.props.exonStyle || false}
      />
    );
  }
}
