import { SearchField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import React, { useState } from 'react';
import { dataSources } from '@legacy-dataSources/travelRequests.dataSources';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { useTravelStore } from '../../../../store/RequestTravel.store';

interface TravelCostCenterProps {
  control: Control;
  errors: FieldErrors;
}

export function TravelCostCenter({ errors, control }: TravelCostCenterProps) {
  const [optionsCostCenters, setOptionsCostCenters] = useState<any[]>([]);
  const contextId = useTravelStore((state) => state.contextId);
  const updateCostCenter = useTravelStore((state) => state.updateCostCenter);
  const [isLoading, setIsLoading] = useState(false);

  const loadOptions = async (searchText) => {
    setIsLoading(true);
    try {
      const loadOptions = {
        keywords: searchText,
        contextId: contextId,
      };
      const responses = await dataSources.costCenter.load(loadOptions);
      setOptionsCostCenters(responses);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={'centro-custo'}
      style={{ paddingTop: 20, paddingBottom: 20 }}
    >
      <div style={{ marginBottom: '10px' }}>
        <Typography variant={'headline8'} variantColor={'#3B2E37'}>
          Centro de custo
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <div style={{ width: '100%' }}>
          <Controller
            control={control}
            name={'cost_center'}
            render={({ field: { onChange, value, ...field } }) => (
              <div>
                <SearchField
                  onInputChange={(event, value) => {
                    loadOptions(value);
                  }}
                  onSearchChange={(event, selectedItem) => {
                    if (selectedItem) {
                      updateCostCenter({
                        code: selectedItem.code,
                        name: selectedItem.label,
                        id: selectedItem.value,
                      });
                      onChange(selectedItem);
                    }
                  }}
                  searchable={true}
                  value={value}
                  options={optionsCostCenters}
                  noOptionsText={'Nenhum centro de custo encontrado'}
                  label="Selecione centro de custo"
                  error={!!errors.cost_center}
                  {...(field as any)}
                  helperText={
                    <DisplayErrorFormValidation
                      errors={errors}
                      fieldName="cost_center"
                    />
                  }
                />
                {isLoading && <div></div>}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}
