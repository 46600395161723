import { SlideItem } from '../components/slider';

export function useSolutionFeatures(): SlideItem[] {
  return [
    {
      icon: 'IconPlaneDeparture',
      title: 'Agilidade nas reservas',
      description: 'Solicite uma viagem em menos de 3 minutos',
    },
    {
      icon: 'IconDatabase',
      //   icon: "IconChevronLeft",
      title: 'Economia nos custos da empresa',
      description: 'Mecanismos que te ajudam a encontrar as melhores tarifas',
    },
    {
      icon: 'IconChecklist',
      title: 'Poíliticas e aprovações personalizadas',
      description: 'Configurações sob medida para a sua empresa',
    },
    {
      icon: 'IconMailFast',
      title: 'Agilidade de atendimento',
      description: 'Time de atendimento preparado e disponível 24h',
    },
    {
      icon: 'IconChartAreaLine',
      title: 'Controle e monitoramento',
      description:
        'Acompanhe todas as viagens em tempo real e extraia relatórios completos',
    },
  ];
}
