import LocationsComponent from '../locations/locations.component.js';
import DateComponent from '../date/date.component.js';
import GuestsComponent from '../guests/guests.component.js';
import SelectComponent from '../select/select.component.js';
import Message from '../message/message.component.js';
import Popup from '../popup/popup.component.js';
import RadioGroup from '../radio-group/radio-group.component.js';

let HotelSearchEngineTemplate = {
  main: ({
    destinationProps,
    onSearchClicked,
    onClearFieldsClicked,
    onGuestsClearFieldsClicked,
    checkinDateProps,
    checkoutDateProps,
    providerProps,
    showAdvancedOptions,
    onShowAdvancedOptionsClicked,
    onHotelNameChanged,
    guestsProps,
    hotelName,
    guestsData,
    searchForTypesProps,
    searchForTypeSelected,
    searchForTypes,
    locationsProps,
  }) => {
    return (
      <div className="exon-hotel-search-engine">
        {/* Radio Group */}
        <div className="exon-components exon-hotel-search-option">
          <div className="exon-components__container-row justify-content">
            <div className="exon-components__container-box exon-hotel-radio-group">
              <label>Pesquisar por:</label>
              <RadioGroup
                name="searchForTypes"
                item={searchForTypesProps.data}
                externalOptions={searchForTypesProps.options}
              />
            </div>
          </div>
        </div>

        <div className="exon-hotel-search-engine-infos">
          {/*destinations and guests*/}
          <div className="exon-components">
            <div className="exon-components__container-row">
              {searchForTypeSelected.value == searchForTypes.city.value && (
                <div className="exon-components__container-box">
                  <label>Cidade</label>
                  <div className="exon-hotel-search-engine-location">
                    <LocationsComponent
                      data={destinationProps.data}
                      placeholder={destinationProps.placeholder}
                      externalOptions={destinationProps.options}
                    />
                  </div>
                </div>
              )}
              {searchForTypeSelected.value ==
                searchForTypes.districtAndTouristSpot.value && (
                <div className="exon-components__container-box">
                  <label>Bairros/pontos turísticos</label>
                  <div className="exon-hotel-search-engine-location">
                    <LocationsComponent
                      data={locationsProps.data}
                      placeholder={locationsProps.placeholder}
                      externalOptions={locationsProps.options}
                    />
                  </div>
                </div>
              )}

              <div className="exon-components__container-box">
                <GuestsComponent
                  externalOptions={guestsProps.options}
                  initialData={guestsData.roomsStart}
                  onGuestsClearFieldsClick={onGuestsClearFieldsClicked}
                />
              </div>
            </div>
          </div>

          {/*checkin and checkout*/}
          <div className="exon-components">
            <div className="exon-components__container-row">
              <div className="exon-components__container-box width-date-engine exon-checkin-checkout exon-date-and-period-width">
                <label className="exon-label-title-field exon-date-and-period__box-label-date">
                  Check-in
                </label>
                <DateComponent
                  data={checkinDateProps.data}
                  startDate={checkinDateProps.startDate}
                  externalOptions={checkinDateProps.options}
                  placeholder={checkinDateProps.placeholder}
                />
              </div>
              <div
                className={
                  'exon-components__container-box exon-checkin-checkout exon-date-and-period-width'
                }
              >
                <label className="exon-label-title-field exon-date-and-period__box-label-date">
                  Check-out
                </label>
                <DateComponent
                  data={checkoutDateProps.data}
                  startDate={checkoutDateProps.startDate}
                  externalOptions={checkoutDateProps.options}
                  placeholder={checkoutDateProps.placeholder}
                />
              </div>
            </div>
          </div>

          <div className="exon-hotel-search-engine-button-search-show">
            <div className="box-double-button">
              <button
                className="secondary-button"
                onClick={onClearFieldsClicked}
              >
                <span></span>
              </button>
              <button className="primary-button" onClick={onSearchClicked}>
                Pesquisar
              </button>
            </div>
          </div>
        </div>

        {/*Opçoes avançadas*/}
        <div className="exon-components">
          <div className="exon-components__container-row">
            <div className="box-double-button">
              <a
                onClick={onShowAdvancedOptionsClicked}
                className="exon-button-info exon-advanced-options"
              >
                Opções avançadas
                <i
                  className={
                    (showAdvancedOptions
                      ? 'exon-icon-down-unfilled_disabled'
                      : 'exon-icon-right-unfilled_disabled') +
                    ' exon-icon exon-advanced-options__icon'
                  }
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
        {showAdvancedOptions && (
          <div className="exon-components">
            <div className="exon-components__container-row">
              <div className="exon-components__container-box width-date-engine">
                <label className="exon-label-title-field">Nome do hotel</label>
                <input
                  className={'exon-textbox exon-locations__field'}
                  name="text"
                  type="text"
                  value={hotelName}
                  onChange={onHotelNameChanged}
                />
              </div>
              <div className={'exon-components__container-box '}>
                <label className="exon-label-title-field">Fornecedor</label>
                <SelectComponent
                  data={providerProps.data}
                  externalOptions={providerProps.options}
                  readonly="true"
                  placeholder="Todos"
                ></SelectComponent>
              </div>
            </div>
          </div>
        )}

        <div className="exon-hotel-search-engine-button-search-noshow">
          <div className="box-double-button">
            <button className="secondary-button" onClick={onClearFieldsClicked}>
              <span></span>
            </button>
            <button className="primary-button" onClick={onSearchClicked}>
              Pesquisar
            </button>
          </div>
        </div>
      </div>
    );
  },
};

export default HotelSearchEngineTemplate;
