import { useCheckFlagPermissions } from './useCheckFlagPermissions';
import {
  FLASH_OS_TRAVEL,
  VEHICLE_OFFLINE,
  TRAVELERS_LIMIT,
} from '@shared/constants/FeatureFlags.constants';

export interface TravelPermissions {
  enableTravel: boolean;
  enableVehicleOffline: boolean;
  enableTravelersLimit: boolean;
}

export function useTravelPermissions(): TravelPermissions {
  const checkFlagPermission = useCheckFlagPermissions();
  return {
    enableTravel: checkFlagPermission(FLASH_OS_TRAVEL),
    enableVehicleOffline: checkFlagPermission(VEHICLE_OFFLINE),
    enableTravelersLimit: checkFlagPermission(TRAVELERS_LIMIT),
  };
}
