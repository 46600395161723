'use strict';

import React from 'react';
import { componentUtil } from '../../../../utils/componentUtil.js';
import Template from './hotel-details.template.js';

export default class HotelDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);
    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    let hotel = this.hotelInfosConfig(this.props.hotel);

    this.state = {
      hotel: hotel,
      roomIdsSelected: this.props.searchParameters.rooms.map(
        () => hotel.rooms[0].id,
      ),
      showPurchaseDetails: false,
      searchByIdPerformed: false,
    };
  }

  render() {
    return (
      <Template.main
        hotel={this.state.hotel}
        roomIdsSelected={this.state.roomIdsSelected}
        showPurchaseDetails={this.state.showPurchaseDetails}
        props={this.props}
        events={this.events}
      />
    );
  }

  static getDerivedStateFromProps(props, state) {
    return {
      roomIdsSelected:
        state.roomIdsSelected.length != props.searchParameters.rooms.length
          ? props.searchParameters.rooms.map(() => props.hotel.rooms[0].id)
          : state.roomIdsSelected,
    };
  }

  events = {
    onSelectRoomClick: (roomId, indexRoomGroup) => {
      let roomIdsSelected = this.state.roomIdsSelected;
      roomIdsSelected[indexRoomGroup] = roomId;
      this.setState({ roomIdsSelected });
    },

    onShowPurchaseDetailsClick: () =>
      this.setState({ showPurchaseDetails: !this.state.showPurchaseDetails }),

    onHotelOptionClick: (hotelIndex) => {
      this.props.onHotelOptionClick &&
        this.props.onHotelOptionClick(hotelIndex);
    },

    onSearchIdClick: (externalCode, checkin, checkout, guests) => {
      let searchParameters = this.createSearchParameter(
        { ...this.props.searchParameters },
        this.state.hotel,
      );

      if (guests) {
        guests &&
          guests.forEach((guest) => {
            guest.adultCount = guest.adults;
            guest.childCount = guest.children;
          });
      }

      externalCode ? (searchParameters.externalCode = externalCode) : null;
      checkin ? (searchParameters.checkin = checkin) : null;
      checkout ? (searchParameters.checkout = checkout) : null;
      guests ? (searchParameters.rooms = guests) : null;

      this.setState({ searchParameters });

      this.props.onHotelIdClick && this.props.onHotelIdClick(searchParameters);
    },

    onSendSelectedProductCheckoutClick: () => {
      let item = this.prepareObjectForShipping();
      this.props.onSendSelectedProductCheckoutHotelResultClick &&
        this.props.onSendSelectedProductCheckoutHotelResultClick(
          item.includeItem,
          item.guestsCounter,
        );
    },

    onIncludeInCartClick: () => {
      let item = this.prepareObjectForShipping();
      this.props.onIncludeInCartClick &&
        this.props.onIncludeInCartClick(item.includeItem, item.guestsCounter);
    },
  };

  createSearchParameter = (searchParameters, hotel) => {
    searchParameters.searchViewParameters.filter.hotelName = hotel.name;
    searchParameters.searchViewParameters.filter.externalCode =
      hotel.externalCode;
    searchParameters.searchViewParameters.filter.connectionSource =
      hotel.connectionSource;

    searchParameters.location = hotel.address.locationId;

    searchParameters.searchViewParameters.sortingData.by = 0;
    searchParameters.searchViewParameters.sortingData.desc = false;
    searchParameters.searchViewParameters.pagingData.itemsPerPage = 10;
    searchParameters.searchViewParameters.pagingData.page = 1;

    return searchParameters;
  };

  prepareObjectForShipping = () => {
    let hotelSelected = { ...this.props.hotelSelectedSearch };
    let searchParameters = this.createSearchParameter(
      { ...this.props.searchParameters },
      this.state.hotel,
    );
    let originalSearchParameters = { ...this.props.originalSearchParameters };
    hotelSelected.selectedRooms = this.getRoomsSelected(hotelSelected);

    searchParameters.searchId = originalSearchParameters.searchId;
    searchParameters.searchViewParameters.searchId =
      originalSearchParameters.searchId;
    searchParameters.label  = originalSearchParameters.label;

    return {
      guestsCounter: searchParameters.rooms.reduce((item1, item2) => {
        return {
          adultCount: item1.adultCount + item2.adultCount,
          childCount: item1.childCount + item2.childCount,
        };
      }),
      includeItem: {
        selected: false,
        hotel: hotelSelected,
        tabIdentifier: this.props.identifierSearch,
        searchData: searchParameters,
        selectedRooms: hotelSelected.selectedRooms,
      },
    };
  };

  getRoomsSelected = (hotel) => {
    return this.state.roomIdsSelected.map((roomId) => {
      return hotel.rooms.find((room) => roomId == room.id);
    });
  };

  hotelInfosConfig = (hotel) => {
    hotel.rooms =
      hotel.rooms &&
      hotel.rooms.map((room, index) => {
        if (room.original) {
          room.original.index = index;
          room.original.travelers = room.original.travelers
            ? room.original.travelers
            : this.roomTravelers();
          this.roomFaresConfig(room.original);

          return room.original;
        } else {
          room.index = index;
          room.travelers = room.travelers
            ? room.travelers
            : this.roomTravelers();
          this.roomFaresConfig(room);

          return room;
        }
      });

    return hotel;
  };

  roomFaresConfig = (room) => {
    room.fares.forEach((fare) => {
      if (!fare.roomValue.total)
        fare.roomValue.total = {
          value: fare.roomValue.dailyTax.value + fare.roomValue.dailyFare.value,
          currencyCode: fare.roomValue.dailyTax.currencyCode,
        };
    });
  };

  roomTravelers = () => {
    return this.props.searchParameters.rooms.map((room) => {
      room;
    });
  };
}
