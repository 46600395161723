import { enums } from '@legacy-utils/enums';

let MessageTemplate = {
  main: ({
    onSendMessageClick,
    onCheckChange,
    checkboxObject,
    messageHistoric,
    currentUser,
    inputText,
    onTextChanged,
    componentRef,
  }) => {
    let senderPrevious = false;
    return (
      <div className="exon-message">
        <div className="exon-message-historic" ref={componentRef}>
          {messageHistoric.map((message, index) => {
            if (
              message.sender.id == currentUser.id ||
              message.destinationProfile.indexOf(currentUser.profile) != -1
            ) {
              let response = (
                <div
                  key={index}
                  className={
                    'exon-message-historic-item ' +
                    (message.sender.id == currentUser.id
                      ? 'exon-message-historic-item_sent'
                      : 'exon-message-historic-item_received')
                  }
                >
                  <div
                    className={
                      message.sender.id == currentUser.id
                        ? 'exon-message-sent'
                        : 'exon-message-received'
                    }
                  >
                    {!(
                      senderPrevious && senderPrevious == message.sender.id
                    ) && <label>{message.sender.name}</label>}
                    <div className="exon-message-text">
                      <p>{message.text}</p>
                    </div>
                  </div>
                </div>
              );
              senderPrevious = message.sender.id;
              return response;
            }
          })}
        </div>

        <div className="exon-components__container-box">
          <label>Mensagem:</label>
          <div className="exon-message-box-input">
            <input
              className={'exon-textbox exon-locations__field-active'}
              value={inputText}
              onChange={(e) => onTextChanged(e)}
              onKeyPress={(e) =>
                e.key === 'Enter' || e.keyCode === 13
                  ? onSendMessageClick(e)
                  : ''
              }
              autoComplete="off"
              name="text"
              type="text"
            />
            <div className="box-double-button">
              <button
                className="primary-button"
                onClick={(e) => onSendMessageClick(e)}
              >
                Enviar
              </button>
            </div>
          </div>

          <div className="exon-message-destination-profile_check">
            <input
              type="checkbox"
              checked={
                checkboxObject.traveler &&
                checkboxObject.requester &&
                checkboxObject.consultant &&
                checkboxObject.approver
              }
              onChange={(e) => {
                onCheckChange(e, enums.destinationProfile.all);
              }}
            />{' '}
            <span>Todos</span>
            <input
              type="checkbox"
              checked={checkboxObject.traveler}
              onChange={(e) => {
                onCheckChange(e, enums.destinationProfile.traveler);
              }}
            />{' '}
            <span>Viajante(s)</span>
            <input
              type="checkbox"
              checked={checkboxObject.requester}
              onChange={(e) => {
                onCheckChange(e, enums.destinationProfile.requester);
              }}
            />{' '}
            <span>Solicitante</span>
            <input
              type="checkbox"
              checked={checkboxObject.consultant}
              onChange={(e) => {
                onCheckChange(e, enums.destinationProfile.consultant);
              }}
            />{' '}
            <span>Consultor(es)</span>
            <input
              type="checkbox"
              checked={checkboxObject.approver}
              onChange={(e) => {
                onCheckChange(e, enums.destinationProfile.approver);
              }}
            />{' '}
            <span>Aprovador(es)</span>
          </div>
        </div>
      </div>
    );
  },
};

export default MessageTemplate;
