'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import HotelSearchResultTemplate from './hotel-search-result.template.js';
import { apiResponse } from '../../utils/apiResponse.js';

export default class HotelSearchResultComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      hotels: this.props.hotels,
      tabIdentifier: this.props.tabIdentifier,
      showRooms: [],
      showRoomsDescription: [],
      itemsQuantity: this.props.itemsQuantity,
      searchData: this.props.searchParameters,
      displayCancelationPolicy: this.getInitialDisplayCancelationPolicyValues(),
      selectedRoom: [],
      isLoad: true,
    };
  }

  externalOptions = {
    events: {
      onOrderHotelSearchResultClicked: null,
    },
    dataSources: {
      hotelsCancelationPolicy: null,
    },
  };

  static getDerivedStateFromProps(props, state) {
    return {
      hotels: props.hotels,
      itemsQuantity: props.itemsQuantity,
    };
  }

  render() {
    return (
      <HotelSearchResultTemplate.main
        hotels={this.state.hotels}
        isLoad={this.state.isLoad}
        searchData={this.state.searchData}
        listOrderSelect={this.listOrderSelect.getProps()}
        tabIdentifier={this.state.tabIdentifier}
        itemsQuantity={this.state.itemsQuantity}
        showRooms={this.state.showRooms}
        onShowHideMoreRoomsClicked={this.events.onShowHideMoreRoomsClicked}
        onRoomSelect={this.events.onRoomSelected}
        onCancelationPolicyClick={this.events.onCancelationPolicyClicked}
        displayCancelationPolicy={this.state.displayCancelationPolicy}
        onHideCancelationPolicyClick={
          this.events.onHideCancelationPolicyClicked
        }
        onOrderHotelSearchResultClick={
          this.externalOptions.events.onOrderHotelSearchResultClicked
        }
        selectedRoom={this.state.selectedRoom}
        onIncludeInCartClick={this.events.onIncludeInCartClicked}
        onSelectHotelCheckoutClick={this.events.onSelectHotelCheckoutClicked}
        onOpenHotelDetailsClick={this.props.onOpenHotelDetailsClick}
        onShowHideRoomDescriptionClicked={
          this.events.onShowHideRoomDescriptionClicked
        }
        showRoomsDescription={this.state.showRoomsDescription}
        onRedoSearchClick={this.props.onRedoSearchClick}
        onDeleteTabAndSearchClick={this.props.onDeleteTabAndSearchClick}
      />
    );
  }

  dataSources = {
    callLoadHotelsRoomDetails: (
      hotelName,
      room,
      hotelComppressedAdditionalProperties,
    ) => {
      this.externalOptions.dataSources.hotelsRoomDetails
        .load({
          hotelName: hotelName,
          selectedRoom: room,
          hotelComppressedAdditionalProperties:
            hotelComppressedAdditionalProperties,
        })
        .then((response) => {
          apiResponse.successful.showErrorIfExists(response);

          let cancelDate = formatterUtil.dateObjToDate(
            response.room.cancelDeadline,
          );
          let cancellationPolicies = response.room.cancellationPolicies;

          let obj = {
            displayCancelationPolicy: {
              roomName: response.room.name,
              display: true,
              cancellationPolicies: cancellationPolicies,
              cancelDeadline: cancelDate,
              popupLoad: false,
            },
          };

          this.setState(obj);
        })
        .catch((error) => {
          apiResponse.http.showErrorIfExists(error);
          this.setState({
            displayCancelationPolicy:
              this.getInitialDisplayCancelationPolicyValues(),
          });
        });
    },
  };

  events = {
    onShowHideMoreRoomsClicked: function (index) {
      let showRooms = this.state.showRooms;

      showRooms[index] = !showRooms[index];
      this.setState({ showRooms: showRooms });
    },

    onShowHideRoomDescriptionClicked: function (index) {
      let showRoomsDescription = this.state.showRoomsDescription;

      showRoomsDescription[index] = !showRoomsDescription[index];
      this.setState({ showRoomsDescription: showRoomsDescription });
    },

    onIncludeInCartClicked: function (hotelIndex) {
      let obj = this.createCheckoutObject(hotelIndex);

      if (this.props.onIncludeInCartClicked)
        this.props.onIncludeInCartClicked(obj.includeInCart, obj.guestsCounter);
    },

    onSelectHotelCheckoutClicked: function (hotelIndex) {
      let obj = this.createCheckoutObject(hotelIndex);

      if (this.props.onSendSelectedProductCheckoutHotelResultClick)
        this.props.onSendSelectedProductCheckoutHotelResultClick(
          obj.includeInCart,
          obj.guestsCounter,
        );
    },

    onRoomSelected: function (roomId, indexRoom, indexHotel) {
      let selectedRoom = this.state.selectedRoom;
      let hotels = this.state.hotels;
      let rooms = hotels[indexHotel].rooms;
      let room = rooms.find((r) => r.id == roomId);

      selectedRoom[indexHotel][indexRoom] = room;

      this.setState({ selectedRoom: selectedRoom, isLoad: false });
    },

    onCancelationPolicyClicked: (
      event,
      hotelName,
      room,
      hotelComppressedAdditionalProperties,
    ) => {
      let obj = {
        displayCancelationPolicy: {
          display: true,
          cancellationPolicies: [],
          cancelDeadline: null,
          roomName: '',
          popupLoad: true,
        },
      };
      this.setState(obj);

      if (!room.room.cancellationPolicies) {
        this.dataSources.callLoadHotelsRoomDetails(
          hotelName,
          room,
          hotelComppressedAdditionalProperties,
        );
      } else {
        let roomSelected = room.room;

        let obj = {
          displayCancelationPolicy: {
            roomName: roomSelected.name,
            display: true,
            cancellationPolicies: roomSelected.cancellationPolicies,
            cancelDeadline: roomSelected.cancelDeadline,
            popupLoad: false,
          },
        };

        this.setState(obj);
      }
    },

    onHideCancelationPolicyClicked: function () {
      this.setState({
        displayCancelationPolicy:
          this.getInitialDisplayCancelationPolicyValues(),
      });
    },
  };

  listOrderSelect = {
    getProps: function () {
      const sortingData = (this?.state?.searchData?.searchViewParameters?.sortingData || {})
      const sortOrder = [
        '0_false',
        '0_true',
        '5_false',
        '6_true',
        '7_false',
      ].indexOf(`${sortingData.by}_${sortingData.desc}`)
      return {
        data: `${sortOrder + 1}`,
        placeholder: 'Ordenar por',
        options: {
          items: [
            { value: '1', checked: true, order: { by: 0, desc: false }, label: 'Menor preço' },
            { value: '2', checked: false, order: { by: 0, desc: true }, label: 'Maior preço' },
            { value: '3', checked: false, order: { by: 5, desc: false }, label: 'Menor distância' },
            { value: '4', checked: false, order: { by: 6, desc: true }, label: 'Melhor classificação e menor preço' },
            { value: '5', checked: false, order: { by: 7, desc: true }, label: 'Melhor classificação e mais perto' },

          ],
          events: {
            onSelected: selected => {
              this?.externalOptions?.events?.onOrderHotelSearchResultClicked(selected.order)
            },
          },
        },
      };
    }.bind(this),
  };

  getInitialDisplayCancelationPolicyValues = () => {
    return {
      display: false,
      deadlineFormatted: '',
      policy: '',
      termsAndConditions: '',
      popupLoad: false,
    };
  };

  createCheckoutObject = function (hotelIndex) {
    const guestsCounter = this.state.searchData.rooms.reduce((item1, item2) => {
      return {
        adultCount: item1.adultCount + item2.adultCount,
        childCount: item1.childCount + item2.childCount,
      };
    });

    const includeInCart = {
      selected: false,
      hotel: this.state.hotels[hotelIndex],
      tabIdentifier: this.state.tabIdentifier,
      searchData: this.state.searchData,
      selectedRooms: this.state.searchData.rooms.map(
        (_, parameterIndex) => this.state.selectedRoom[hotelIndex][parameterIndex],
      ),
    };

    return { guestsCounter, includeInCart };
  };
}
