'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import { enums } from '../../utils/enums.js';
import LocationsTemplate from './locations.template.js';
import { gritter } from '@legacy-utils/utils';

export default class LocationsComponent extends React.Component {
  selected = null;

  //Quando for necessário o uso de refs
  //components = {
  //    input: null,
  //};

  externalOptions = {
    events: {
      onSelected: null,
    },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      dataControlledByParent: props.data != undefined,
      data: null,
      inputText: '',
      showBackgroundMask: false,
      hideOptions: true,
      hideLoader: true,
      options: [],
      validationResult: {
        isValid: true,
        message: props.validationMessage || '',
      },
      forceValidationPropMemory: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let data = state.dataControlledByParent ? props.data : state.data;

    return {
      data: data,
      inputText: (data && data.name) || '',
    };
  }

  render() {
    let selectComponent = (
      <LocationsTemplate.options
        options={this.state.options}
        onClick={this.events.optionSelected}
        hideOptions={this.state.hideOptions}
      />
    );

    return (
      <LocationsTemplate.main
        placeholder={this.props.placeholder}
        readOnly={this.props.readOnly}
        selectOptions={selectComponent}
        showBackgroundMask={this.state.showBackgroundMask}
        hideLoader={this.state.hideLoader}
        inputText={this.state.inputText}
        validationResult={this.state.validationResult}
        onClickOutsideOptions={this.events.outsideOptionsClicked}
        onChange={this.events.inputChanged}
        onClick={this.events.inputClicked}
        onKeyDown={this.events.onInputKeyDown.bind(this)}
      />
    );
  }

  componentDidUpdate() {
    if (
      this.state.forceValidationPropMemory != this.props.forceValidation &&
      this.props.forceValidation &&
      this.state.validationResult.isValid
    ) {
      this.setState({
        forceValidationPropMemory: this.props.forceValidation,
      });
      this.validate();
    }
  }

  getData = function () {
    return this.state.data;
  };

  setData = function (data) {
    this.setState({
      data: data,
      inputText: (data && data.name) || '',
      hideOptions: true,
    });
  };

  clear = function () {
    this.setData(null);
  };

  timeoutId = null;
  events = {
    inputChanged: function (event) {
      const _this = this;
      let inputText = event.target.value;

      this.setState({ inputText: inputText, data: { name: inputText } });

      if (this.timeoutId) window.clearTimeout(this.timeoutId);

      this.timeoutId = window.setTimeout(() => {
        if (inputText.length >= 3) {
          _this.setLoaderSearchVisibility(true);
          _this.dataSource.callLoad({
            keywords: inputText,
            types: [this.props.locationType],
          });
        } else {
          this.setOptionsItems({});
        }
      }, 250);

      if (this.externalOptions.events.onInputChanged)
        this.externalOptions.events.onInputChanged(inputText);
    },
    inputClicked: function (event) {
      event.target.select();
    },
    optionSelected: function (event) {
      let selectedValue = event.target.value;
      let optionSelected = this.state.options.filter(
        (o) => o._identifier == selectedValue,
      )[0];

      this.fireOptionSelected(optionSelected);
    },
    outsideOptionsClicked: function () {
      this.setState({
        inputText: '',
        showBackgroundMask: false,
        hideOptions: true,
      });

      this.validate();

      if (this.externalOptions.events.onInputChanged)
        this.externalOptions.events.onInputChanged('');
    },
    onInputKeyDown: (event) => {
      if (event.which == 9) {
        let optionSelected = this.state.options[0];
        this.fireOptionSelected(optionSelected);
      }
    },
  };

  fireOptionSelected = (optionSelected) => {
    this.setData(optionSelected);
    this.events.outsideOptionsClicked();

    this.validate(optionSelected);

    if (this.externalOptions.events.onSelected)
      this.externalOptions.events.onSelected(optionSelected);
  };

  setLoaderSearchVisibility = (visible) => {
    this.setState({
      hideLoader: !visible,
    });
  };

  setOptionsItems = (items) => {
    let locations = [];

    // Verifica se a resposta contém itens e se sim, os processa.
    if (items.l && items.l.length > 0) {
      items.l.forEach((location, index) => {
        locations.push({
          _identifier: index + 1,
          id: location.i,
          type: this.getIconByLocationType(
            this.props.locationType || location.t,
          ),
          iata: location.ia ? location.ia : '',
          name: location.n ? location.n : 'Localidade não encontrada',
        });
      });
    } else if (items.results && items.results.length > 0) {
      items.results.forEach((place, index) => {
        locations.push({
          _identifier: index + 1,
          id: place.placeId,
          type: 'pin',
          formattedAddress: place.formattedAddress,
          city: place.city,
          name: `${place.name}${place.city ? ' - ' + place.city : ''}`,
          geoLocation: place.geoLocation
            ? {
                latitude: place.geoLocation.latitude,
                longitude: place.geoLocation.longitude,
              }
            : null,
          types: place.types,
          placeId: place.placeId
        });
      });
    } else {
      locations.push({
        _identifier: 0,
        id: null,
        type: 'info',
        name: 'Nenhum resultado encontrado',
      });
    }

    this.setState({
      options: locations,
      hideOptions: locations.length <= 0,
      showBackgroundMask: true,
    });
  };

  getIconByLocationType(locationType) {
    let icon = 'pin';

    if (locationType) {
      switch (locationType) {
        case enums.locationType.airport:
          icon = 'aircraft';
          break;
      }
    }
    return icon;
  }

  dataSource = {
    callLoad: (loadOptions) => {
      let _this = this;

      let dataSource = this.props.externalOptions.dataSource;

      if (dataSource.events && dataSource.events.beforeLoad)
        dataSource.events.beforeLoad(loadOptions);

      dataSource
        .load(loadOptions)
        .then((response) => {
          _this.setLoaderSearchVisibility(false);
          _this.setOptionsItems(response);
          if (dataSource.events && dataSource.events.afterLoad) {
            dataSource.events.afterLoad(loadOptions);
          }
        })
        .catch((error) => {
          gritter.Error(error);
        });
    },
  };

  validate(selectedItems) {
    let validationResult = this.getValidationResult(selectedItems);

    this.setState({
      validationResult,
    });

    this.props.onValidate && this.props.onValidate(validationResult);
  }

  getValidationResult(selectedItem) {
    if (!selectedItem) selectedItem = this.state.data;

    let validationResult = {
      isValid: true,
      message: '',
    };

    if (this.props.required && (!selectedItem || !selectedItem.name)) {
      validationResult.isValid = false;
      validationResult.message = 'Campo obrigatório';

      return validationResult;
    }

    return validationResult;
  }
}
