'use strict';

import React from 'react';
import RequestStatusBarTemplate from './request-status-bar.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class RequestStatusBarComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <RequestStatusBarTemplate
          statusInfos={this.props.statusInfos ? this.props.statusInfos : null}
        />
      </ErrorBoundary>
    );
  }
}
