'use strict';

import React from 'react';
import Template from './vehicle-booking-infos.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';

export default class VehicleBookingInfosComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <Template {...this.props} />
      </ErrorBoundary>
    );
  }
}
