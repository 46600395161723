import { Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';

import * as SC from './styled';

export const LandingPageLoading = () => {
  return (
    <SC.Container px={{ xs: '10%', lg: '30%' }}>
      <Loader size="large" variant="primary" />

      <Box mt={6} mb={3}>
        <SC.Title variant="headline6">
          Buscando informações do seu plano...
        </SC.Title>
      </Box>

      <SC.Description variant="body3">
        Em breve você terá acesso a todas as informações do seu plano.
      </SC.Description>
    </SC.Container>
  );
};
