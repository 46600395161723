import LocationsComponent from '../locations/locations.component.js';
import ButtonCollectionComponent from '../button-collection/button-collection.component.js';
import DateAndPeriodComponent from '../date-and-period/date-and-period.component.js';
import SelectComponent from '../select/select.component.js';
import PassengersComponent from '../passengers/passengers.component.js';
import ToggleComponent from '../toggle/toggle.component.js';
import RadioGroupComponent from '../radio-group/radio-group.component.js';
import { enums } from '../../utils/enums.js';

//import TabsGroupComponent from '../tabs-group/tabs-group.component.js';
//import TabComponent from '../tab/tab.component.js';

let AirSearchEngineTemplate = {
  main: ({
    tripTypesProps,
    originProps,
    destinationProps,
    multiTripsProps,
    goingDateProps,
    returnDateProps,
    airClassesProps,
    airLinesProps,
    passengersProps,
    directFlightsProps,
    baggagesProps,
    separeResultsProps,
    onSearchClicked,
    onClearFieldsClicked,
  }) => {
    return (
      <div
        className="exon-air-search-engine engine"
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            onSearchClicked(event);
          }
        }}
      >
        {/*Radio Group -> trip-types*/}
        <div className="exon-components">
          <div className="exon-components__container-row justify-content">
            <RadioGroupComponent
              name="tripTypes"
              item={tripTypesProps.data}
              externalOptions={tripTypesProps.options}
            />
          </div>
        </div>

        {/*Button Colection ->  multi-trips*/}
        {tripTypesProps.data.value == 3 && (
          <div className="exon-components">
            <div className="exon-components__container-row justify-content">
              <ButtonCollectionComponent
                value={multiTripsProps.value}
                name={multiTripsProps.name}
                defaultbuttontextprefix={
                  multiTripsProps.defaultButtonTextPrefix
                }
                externalOptions={multiTripsProps.options}
                labels={multiTripsProps.labels}
              />
            </div>
          </div>
        )}

        <div className="exon-air-search-engine-infos">
          {/*Origin and destinations*/}
          <div className="exon-components">
            <div className="exon-components__container-row">
              <div className="exon-components__container-box">
                <label>Origem</label>
                <div className="exon-air-search-engine-location">
                  <LocationsComponent
                    data={originProps.data}
                    locationType={enums.locationType.airport}
                    placeholder={originProps.placeholder}
                    externalOptions={originProps.options}
                  />
                </div>
              </div>

              <div className="exon-components__container-box">
                <label>Destino</label>
                <div className="exon-air-search-engine-location">
                  <LocationsComponent
                    data={destinationProps.data}
                    locationType={enums.locationType.airport}
                    placeholder={destinationProps.placeholder}
                    externalOptions={destinationProps.options}
                  />
                </div>
              </div>
            </div>
          </div>

          {/*Date and Period*/}
          <div className="exon-components">
            <div className="exon-components__container-row ">
              <div className="exon-components__container-box width-date-engine exon-date-and-period-width">
                <DateAndPeriodComponent
                  data={goingDateProps.data}
                  startDate={goingDateProps.startDate}
                  externalOptions={goingDateProps.options}
                  labeldate={goingDateProps.labels.date}
                  labelperiod={goingDateProps.labels.period}
                  placeholder={goingDateProps.placeholder}
                />
              </div>
              {tripTypesProps.data.value == 1 && (
                <div className="exon-components__container-box exon-date-and-period-width">
                  <DateAndPeriodComponent
                    data={returnDateProps.data}
                    startDate={returnDateProps.startDate}
                    externalOptions={returnDateProps.options}
                    labeldate={returnDateProps.labels.date}
                    labelperiod={returnDateProps.labels.period}
                    placeholder={returnDateProps.placeholder}
                  />
                </div>
              )}
            </div>
          </div>

          {/*Classe and Airline*/}
          <div className="exon-components exon-components__class-and-airline">
            <div className="exon-components__item">
              <label>Classe</label>

              <SelectComponent
                data={airClassesProps.data}
                placeholder={airClassesProps.placeholder}
                externalOptions={airClassesProps.options}
              />
            </div>
            <div className="exon-components__item">
              <label>Companhias aéreas</label>

              <SelectComponent
                data={airLinesProps.data}
                externalOptions={airLinesProps.options}
                placeholder={airLinesProps.placeholder}
                ismultiple={airLinesProps.isMultiple}
              />
            </div>
          </div>
        </div>

        <div className="exon-air-search-engine-check">
          {/*Passengers*/}
          <div className="exon-components">
            <div id="passengers"></div>
            <PassengersComponent
              data={passengersProps.data}
              labels={passengersProps.labels}
              externalOptions={passengersProps.options}
            />
          </div>

          {/*Toggle -> Direct Flights | Baggage | Separate Excerpt*/}
          <div className="exon-components">
            <div className="exon-components__container-row justify-content">
              <div className="exon-components">
                <ToggleComponent
                  checked={directFlightsProps.data}
                  placeholder={directFlightsProps.placeholder}
                  externalOptions={directFlightsProps.options}
                />
              </div>
              <div className="exon-components">
                <ToggleComponent
                  checked={baggagesProps.data}
                  placeholder={baggagesProps.placeholder}
                  externalOptions={baggagesProps.options}
                />
              </div>
            </div>
            <div
              className={
                'exon-components' +
                (tripTypesProps.data.value != 1 ? ' hide' : '')
              }
            >
              <ToggleComponent
                checked={separeResultsProps.data}
                placeholder={separeResultsProps.placeholder}
                externalOptions={separeResultsProps.options}
              />
            </div>
          </div>

          <div className="exon-box-button-double">
            <button
              className="exon-button exon-button-secondary"
              onClick={onClearFieldsClicked}
            >
              <span className="exon-icon exon-icon-dump-unfilled_disabled"></span>
            </button>
            <button
              className="exon-button exon-button-primary"
              onClick={onSearchClicked}
            >
              Pesquisar
            </button>
          </div>
        </div>
      </div>
    );
  },
};

export default AirSearchEngineTemplate;
