export enum CardStatus {
  generated,
  expired,
  blocked,
  withoutBalance,
}

export const FrameworkErrorsCodes = {
  blockedCard: 'BR-VCC-001',
  expiredCard: 'BR-VCC-003',
  withoutBalanceCard: 'FL-VCC-001',
};
export enum ApprovalStatus {
  Success = 'success',
  Error = 'error',
  Loading = 'loading',
}
