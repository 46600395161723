import { Box, Grid } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StepDescription, StepTitle } from './styled';

type Props = PropsWithChildren<{
  title: string;
  description: string;
}>;

export const WizardStep = (props: Props) => {
  return (
    <Grid container spacing={4} pt={1}>
      <Grid item xs={12} xl={3}>
        <Box width="80%">
          <StepTitle variant="headline7">{props.title}</StepTitle>
          <StepDescription variant="body3">{props.description}</StepDescription>
        </Box>
      </Grid>
      <Grid item xs={12} xl={9}>
        <Box width="100%">{props.children}</Box>
      </Grid>
    </Grid>
  );
};
