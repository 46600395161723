import Popup from '../../popup/popup.component.js';
import AutoComplete from '../../common/auto-complete/auto-complete.component.js';
import Select from '../../select/select.component.js';
import { enums } from '@legacy-utils/enums';
import { creditCardUtil } from '@legacy-utils/creditCardUtil';
import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

let Template = {
  main: ({
    travelerInfos,
    costCenterData,
    requesterData,
    reasonTravelData,
    editDataPopup,
    goToSplit,
  }) => {
    return (
      <div className="exon-traveler-informations">
        <table className="exon-styleless-table">
          <thead>
            <tr>
              <th>Solicitante</th>
              <th>Viajantes</th>
              <th>C. de custo do viajante</th>
              <th>Empresa/Filial</th>
              <th>C. de custo da viagem</th>
              <th>Motivo da viagem</th>
            </tr>
          </thead>
          <tbody>
            {travelerInfos.travelers.map((traveler, index) => {
              return (
                <tr key={index}>
                  <td>
                    {index == 0 ? travelerInfos.travelInfo.requester.name : ''}
                  </td>
                  <td>
                    {traveler.name
                      ? traveler.name
                      : `${traveler.firstName} ${traveler.lastName}`}
                  </td>
                  <td>
                    {(traveler.costCenter && traveler.costCenter.name) || ''}
                  </td>
                  <td title={traveler.context?.id || false}>
                    {traveler.context
                      ? `${traveler.context.companyName} ${
                          traveler.context.subsidiaryName
                            ? '/' + traveler.context.subsidiaryName
                            : ''
                        }`
                      : ''}
                  </td>
                  <td>
                    {index == 0 ? (
                      travelerInfos.travelInfo?.costSplit?.length > 1 ? (
                        <span>
                          Custos divididos
                          <LinkButton
                            onClick={goToSplit}
                            className="exon-split-anchor"
                            variant={'neutral'}
                          >
                            Ver divisão
                          </LinkButton>
                        </span>
                      ) : (
                        travelerInfos.travelInfo?.costCenter?.name || ''
                      )
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {index == 0
                      ? travelerInfos.travelInfo?.reason?.name || ''
                      : ''}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="exon-custon-filds">
          {travelerInfos.travelInfo.paymentForms && (
            <div>
              <span>Forma de Pagamento</span>
              {travelerInfos.travelInfo.paymentForms.map(
                (paymentForm, index) => {
                  return (
                    <p key={index}>{`${enums.paymentType[paymentForm.type]} ${
                      paymentForm.creditCard
                        ? ` Final ${creditCardUtil.finalCreditCardNumber(
                            paymentForm.creditCard.number,
                          )}`
                        : ''
                    }`}</p>
                  );
                },
              )}
            </div>
          )}
          {travelerInfos.travelInfo.customFieldValues &&
            travelerInfos.travelInfo.customFieldValues.map((fild, index) => {
              return (
                <div key={index}>
                  <span>{fild.currentDescription}</span>
                  <p>{fild.value}</p>
                </div>
              );
            })}
        </div>
        {editDataPopup.show && (
          <Popup
            okButtonLabel={'Salvar'}
            cancelButtonLabel={'Cancelar'}
            title={'Informações do viajante'}
            exonStyle={true}
            cancelButtonClick={editDataPopup.events.onCloseClicked}
            okButtonClick={editDataPopup.events.onSaveClicked}
          >
            <div className="exon-box-field">
              <label className="exon-label-field">Solicitante</label>
              <div className="exon-field">
                <AutoComplete
                  selectedItem={requesterData.selected}
                  inputText={requesterData.inputText}
                  placeholder={requesterData.placeholder}
                  dataSource={requesterData.dataSource}
                  required={true}
                  onChange={requesterData.events.onChanged}
                  onInputChange={requesterData.events.onInputChanged}
                />
              </div>
            </div>

            <div className="exon-box-field">
              <label className="exon-label-field">
                Centro de custo da viagem
              </label>
              <div className="exon-field">
                <AutoComplete
                  selectedItem={costCenterData.selected}
                  inputText={costCenterData.inputText}
                  placeholder={costCenterData.placeholder}
                  dataSource={costCenterData.dataSource}
                  required={true}
                  onChange={costCenterData.events.onChanged}
                  onInputChange={costCenterData.events.onInputChanged}
                />
              </div>
            </div>

            <div className="exon-box-field">
              <label className="exon-label-field">Motivo da viagem</label>
              <div className="exon-field">
                <Select
                  data={reasonTravelData.selected.value}
                  placeholder={reasonTravelData.placeholder}
                  externalOptions={{
                    dataSource: reasonTravelData.dataSource,
                    events: {
                      onSelected: reasonTravelData.events.onSelected,
                    },
                  }}
                  required={true}
                />
              </div>
            </div>
          </Popup>
        )}
      </div>
    );
  },

  editField: ({}) => {
    return <div></div>;
  },
};

export default Template;
