import TabGroup from '../../tabs-group/tabs-group.component.js';
import Tab from '../../tab/tab.component.js';
import TabPanel from '../../tab-panel/tab-panel.component.js';
import AirOption from '../../products-travel/air-product/air-option/air-option.component.js';
import BookingBox from '../booking-box/booking-box.component.js';
import HotelOption from '../../products-travel/hotel-product/hotel-option/hotel-option.component.js';
import AirSearchResultsOptions from '../analysis-items/search-results-analysis/air-search-results-options/air-search-results-options.component.js';
import HotelSearchResultsOptions from '../analysis-items/search-results-analysis/hotel-search-results-options/hotel-search-results-options.component.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';

var Template = {
  main: ({ analysisItems, productType, identifierActiveTab, events }) => {
    return (
      <div className="exon-analysis-items">
        <TabGroup>
          {Object.keys(analysisItems).map((identifier, index) => {
            let analysis = analysisItems[identifier];
            let label = '';

            if (productType == enums.products.type.air) {
              let departureIata = analysis.searchData.trips[0].iataOrigin;
              let arrivalIata = analysis.searchData.trips[0].iataDestination;
              let countTrips = analysis.searchResults.length;

              label = `${departureIata} > ${
                arrivalIata + (countTrips > 1 ? ' +' + (countTrips - 1) : '')
              }`;
            } else {
              label = analysis.searchData.locationName;
            }

            return (
              <Tab
                key={'tab' + index}
                tabIndex={index}
                identifier={identifier}
                label={label}
                selectedTab={identifierActiveTab}
                showCloseButton={false}
                loading={false}
                icon={enums.products.type[productType]}
                onTabClick={events.tabEvents.onSelectTabClicked}
                onDeleteTabClick={null}
              />
            );
          })}
        </TabGroup>

        {Object.keys(analysisItems).map((identifier, index) => {
          let analysis = analysisItems[identifier];
          let showSearchResultsAnalysis = analysis.showSearchResultsAnalysis;
          let selectedOption = analysis.selectedOption;
          let cheapestOption = analysis.cheapestOption;
          let selectedFareOption = null;
          let cheapestFareOption = null;

          if (productType == enums.products.type.air) {
            selectedFareOption =
              analysis.selectedOption.fare.travellersFareValues[0];
            cheapestFareOption =
              analysis.cheapestOption.fare.travellersFareValues[0];
          } else {
            selectedFareOption = analysis.selectedOption.fare;
            cheapestFareOption = analysis.cheapestOption.fare;
          }

          let buttonLabel = !showSearchResultsAnalysis
            ? 'Ver resultado da pesquisa'
            : 'Ver comparativos';

          return (
            <TabPanel
              key={'tabPanel' + index}
              identifier={identifier}
              activeTabIdentifier={identifierActiveTab}
              loading={false}
            >
              <div className="exon-analysis-items-box">
                <div className="exon-analysis-items-list">
                  {!showSearchResultsAnalysis &&
                    productType == enums.products.type.air && (
                      <Template.airComparison
                        selectedOption={selectedOption}
                        selectedFareOption={selectedFareOption}
                        cheapestOption={cheapestOption}
                        cheapestFareOption={cheapestFareOption}
                      />
                    )}

                  {!showSearchResultsAnalysis &&
                    productType == enums.products.type.hotel && (
                      <Template.hotelComparison
                        selectedOption={selectedOption}
                        selectedFareOption={selectedFareOption}
                        cheapestOption={cheapestOption}
                        cheapestFareOption={cheapestFareOption}
                      />
                    )}

                  {showSearchResultsAnalysis &&
                    productType == enums.products.type.air && (
                      <AirSearchResultsOptions
                        items={analysis.searchResults}
                        searchData={analysis.searchData}
                        isMultiple={analysis.additionalProperties.isMultiple}
                      />
                    )}
                  {showSearchResultsAnalysis &&
                    productType == enums.products.type.hotel && (
                      <HotelSearchResultsOptions
                        items={analysis.searchResults}
                      />
                    )}
                </div>
                <div className="exon-box-button">
                  <button
                    className="exon-button exon-button-primary"
                    onClick={events.onViewModeClicked}
                  >
                    {buttonLabel}
                  </button>
                </div>
              </div>
            </TabPanel>
          );
        })}
      </div>
    );
  },

  airComparison: ({
    selectedOption,
    selectedFareOption,
    cheapestOption,
    cheapestFareOption,
  }) => {
    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;
    let getDurationHoursMinute = utils.formatters.time.durationHoursMinute;
    let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
    let getFormattedRounded = utils.formatters.money.getFormattedRounded;

    return (
      <div>
        <div className="exon-selected-item">
          <BookingBox title="Item escolhido">
            <AirOption
              flights={selectedOption.trips.map((trip, index) => {
                return {
                  flightPart:
                    selectedOption.trips.length > 1
                      ? index == 0
                        ? 'Ida'
                        : 'Volta'
                      : 'Opção',
                  ciaCode: trip.issuerAirline.code,
                  departureDate: getFormattedDateObjFromDateObject(
                    trip.departureDate,
                  ),
                  cabinType: trip.firstFlightCabinType,
                  departure: {
                    iata: trip.departure.iata,
                    name: trip.departure.name,
                    hour: getFormattedDateObjFromDateObject(trip.departureDate)
                      .timeFormatted,
                  },
                  stops: trip.stops,
                  duration: getDurationHoursMinute(trip.duration),
                  arrival: {
                    iata: trip.arrival.iata,
                    name: trip.arrival.name,
                    hour: getFormattedDateObjFromDateObject(trip.arrivalDate)
                      .timeFormatted,
                  },
                  bagagge: {
                    baggage: trip.baggages,
                    descriptions: ['1 Bagagem até 32kg'],
                  },
                };
              })}
              fareData={{
                fare: {
                  currencySymbol: getCurrencySymbol(
                    selectedFareOption.markupedFare.currencyCode,
                  ),
                  value: getFormattedRounded(
                    selectedFareOption.markupedFare.value,
                    selectedFareOption.markupedFare.currencyCode,
                  ),
                },
                taxes: `${getCurrencySymbol(
                  selectedFareOption.taxes.currencyCode,
                )} ${getFormattedRounded(
                  selectedFareOption.taxes.value,
                  selectedFareOption.taxes.currencyCode,
                )}`,
                service: `${getCurrencySymbol(
                  selectedFareOption.du.currencyCode,
                )} ${getFormattedRounded(
                  selectedFareOption.du.value,
                  selectedFareOption.du.currencyCode,
                )}`,
                total: `${getCurrencySymbol(
                  selectedFareOption.fare.currencyCode,
                )} ${getFormattedRounded(
                  selectedFareOption.du.value +
                    selectedFareOption.markupedFare.value +
                    selectedFareOption.taxes.value,
                  selectedFareOption.fare.currencyCode,
                )}`,
              }}
            />
          </BookingBox>
        </div>

        <div className="exon-item-search">
          <BookingBox title="Opção de menor valor">
            <AirOption
              flights={cheapestOption.trips.map((trip, index) => {
                return {
                  flightPart:
                    selectedOption.trips.length > 1
                      ? index == 0
                        ? 'Ida'
                        : 'Volta'
                      : 'Opção',
                  ciaCode: trip.issuerAirline.code,
                  departureDate: getFormattedDateObjFromDateObject(
                    trip.departureDate,
                  ),
                  cabinType: trip.firstFlightCabinType,
                  departure: {
                    iata: trip.departure.iata,
                    name: trip.departure.name,
                    hour: getFormattedDateObjFromDateObject(trip.departureDate)
                      .timeFormatted,
                  },
                  stops: trip.stops,
                  duration: getDurationHoursMinute(trip.duration),
                  arrival: {
                    iata: trip.arrival.iata,
                    name: trip.arrival.name,
                    hour: getFormattedDateObjFromDateObject(trip.arrivalDate)
                      .timeFormatted,
                  },
                  bagagge: {
                    baggage: trip.baggages,
                    descriptions: ['1 Bagagem até 32kg'],
                  },
                };
              })}
              fareData={{
                fare: {
                  currencySymbol: getCurrencySymbol(
                    cheapestFareOption.markupedFare.currencyCode,
                  ),
                  value: getFormattedRounded(
                    cheapestFareOption.markupedFare.value,
                    cheapestFareOption.markupedFare.currencyCode,
                  ),
                },
                taxes: `${getCurrencySymbol(
                  cheapestFareOption.taxes.currencyCode,
                )} ${getFormattedRounded(
                  cheapestFareOption.taxes.value,
                  cheapestFareOption.taxes.currencyCode,
                )}`,
                service: `${getCurrencySymbol(
                  cheapestFareOption.du.currencyCode,
                )} ${getFormattedRounded(
                  cheapestFareOption.du.value,
                  cheapestFareOption.du.currencyCode,
                )}`,
                total: `${getCurrencySymbol(
                  cheapestFareOption.fare.currencyCode,
                )} ${getFormattedRounded(
                  cheapestFareOption.du.value +
                    cheapestFareOption.markupedFare.value +
                    cheapestFareOption.taxes.value,
                  cheapestFareOption.fare.currencyCode,
                )}`,
              }}
            />
          </BookingBox>
        </div>
      </div>
    );
  },

  hotelComparison: ({ selectedOption, cheapestOption }) => {
    return (
      <div>
        <div className="exon-selected-item">
          <BookingBox title="Item escolhido">
            <HotelOption
              hotel={{
                images: [],
                name: selectedOption.hotel.name,
                evaluation: selectedOption.hotel.evaluation,
                services: selectedOption.hotel.services,
                localization: selectedOption.hotel.localization,
                description: selectedOption.hotel.description,
              }}
              fareData={{
                totalDaily: {
                  currencySymbol: selectedOption.fare.totalDaily.currencySymbol,
                  value: selectedOption.fare.totalDaily.value,
                },
                dailyFare: selectedOption.fare.dailyFare,
                dailyTaxes: selectedOption.fare.dailyTaxes,
                total: selectedOption.fare.total,
              }}
              actions={{
                details: null,
                hotel: null,
              }}
            />
          </BookingBox>
        </div>

        <div className="exon-item-search">
          <BookingBox title="Opção de menor valor">
            <HotelOption
              hotel={{
                images: [],
                name: cheapestOption.hotel.name,
                evaluation: cheapestOption.hotel.evaluation,
                services: cheapestOption.hotel.services,
                localization: cheapestOption.hotel.localization,
                description: cheapestOption.hotel.description,
              }}
              fareData={{
                totalDaily: {
                  currencySymbol: cheapestOption.fare.totalDaily.currencySymbol,
                  value: cheapestOption.fare.totalDaily.value,
                },
                dailyFare: cheapestOption.fare.dailyFare,
                dailyTaxes: cheapestOption.fare.dailyTaxes,
                total: cheapestOption.fare.total,
              }}
              actions={{
                details: null,
                hotel: null,
              }}
            />
          </BookingBox>
        </div>
      </div>
    );
  },
};

export default Template;
