import { gritter } from '@legacy-utils/utils';

export const messageUtils = {
  showError: (message: string, response: any) => {
    gritter.Warning(
      message +
        ' Tente novamente em instantes e caso o erro persista contate o suporte.',
    );
    console.log(response);
  },
  showErrorInsertUpdateTravellers: (response: any) => {
    gritter.Warning(
      'Ocorreu um erro ao registrar o viajante. Tente novamente em instantes e caso o erro persista contate o suporte.',
    );
    console.log(response);
  },
};
