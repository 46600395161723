let Template = ({ children, aditionalClass }) => {
  return (
    <div
      className={`exon-container-group ${aditionalClass ? aditionalClass : ''}`}
    >
      {children}
    </div>
  );
};

export default Template;
