import SelectComponent from '../select/select.component.js';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';

let GuestsTemplate = {
  main: ({
    roomsProps,
    onFieldsClicked,
    onCloseClicked,
    onApplyFieldsClicked,
    onClearFieldsClicked,
    onRoomCloseClicked,
    onAddRoom,
    childsProps,
    adultsProps,
  }) => {
    const { enableTravelersLimit } = useTravelPermissions();

    return (
      <div>
        <div
          className={
            'exon-outside-options-background-mask ' +
            (roomsProps.showComponent ? '' : 'hide')
          }
          onClick={onCloseClicked}
        ></div>
        <div>
          <label>{roomsProps.labels.guests}</label>
          <input
            className={'exon-textbox exon-locations__field'}
            readOnly
            name="text"
            type="text"
            pattern="[a-z\s]+$"
            value={roomsProps.resumeField}
            onClick={onFieldsClicked}
          />
        </div>

        <div
          className={(roomsProps.showComponent ? '' : 'hide') + ' exon-guests'}
        >
          <div className="row">
            <div className="exon-span-title-component-guests">
              {roomsProps.labels.guests}
            </div>
            <div
              className="exon-icon-close-unfilled_enabled float-right"
              onClick={onCloseClicked}
            ></div>
          </div>
          <div className="row">
            <div className="alert alert-success exon-summary-board">
              <i className="exon-icon-room-key-unfilled_enabled"></i>
              <span>{roomsProps.resumeField}</span>
            </div>
          </div>
          {roomsProps.rooms.map(({ adults, children }, index) => {
            let showCloseIcon = roomsProps.rooms.length <= 1;
            return (
              <div className="row" key={index}>
                <div className="exon-rooms">
                  <div className="exon-span-title-component-room">
                    {roomsProps.labels.room} {index + 1}
                  </div>
                  <div className="exon-span-title-component-room">
                    <label>{roomsProps.labels.adults}</label>
                    <div className="exon-components__container-box exon-room-guests-fields">
                      <div className={'exon-textbox exon-type-guest-fields'}>
                        <SelectComponent
                          data={adultsProps.data}
                          externalOptions={adultsProps.options[index]}
                          placeholder={adults}
                          name={index}
                        ></SelectComponent>
                      </div>
                    </div>
                  </div>

                  {!enableTravelersLimit && (
                    <div className="exon-span-title-component-room">
                      <label>{roomsProps.labels.children}</label>
                      <div className="exon-components__container-box exon-room-guests-fields">
                        <div className={'exon-textbox exon-type-guest-fields'}>
                          <SelectComponent
                            data={childsProps.data}
                            externalOptions={childsProps.options[index]}
                            placeholder={children}
                            name={index}
                          ></SelectComponent>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={
                      'float-right exon-icon-close-box ' +
                      (showCloseIcon
                        ? 'exon-icon-close-unfilled_disabled'
                        : 'exon-icon-close-unfilled_enabled')
                    }
                    onClick={(e) => {
                      if (!showCloseIcon) {
                        onRoomCloseClicked(e, index);
                      }
                    }}
                  ></div>
                </div>
              </div>
            );
          })}

          {!enableTravelersLimit && (
            <div className="row">
              <div className="box-double-button">
                <a onClick={onAddRoom} className="exon-cursor-pointer">
                  + Adicionar quarto
                </a>
              </div>
            </div>
          )}

          <div className="row">
            <div className="box-double-button">
              <button
                className="secondary-button"
                onClick={onClearFieldsClicked}
              >
                <span></span>
              </button>
              <button className="primary-button" onClick={onApplyFieldsClicked}>
                Aplicar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export default GuestsTemplate;
