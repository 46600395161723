import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';

let BookingTravelerListTemplate = ({ travelerList, isEdit }) => {
  return (
    <div className="exon-booking-traveler-list">
      <div className="box-styleless-table">
        <table className="exon-styleless-table">
          <thead>
            <tr>
              <th></th>
              <th>Nome</th>
              <th>D. de nascimento</th>

              {
                //isEdit &&
                //<th className="exon-table-actions-header">
                //    <span
                //        className="exon-icon exon-icon-button exon-icon-add-unfilled_enabled"
                //        onClick={() => null //events.form.popup.onOpenFormPopupClicked(enums.actionsForm.add)
                //        }>
                //    </span>
                //</th>
              }
            </tr>
          </thead>
          <tbody>
            {travelerList.map((traveler, index) => {
              return (
                <tr key={index}>
                  <td>{traveler.honorificPrefix}</td>
                  <td>
                    {traveler.name
                      ? traveler.name
                      : `${traveler.firstName} ${traveler.lastName}`}
                  </td>
                  <td>
                    {
                      utils.formatters.date.getFormattedDateObjFromDateObject(
                        traveler.birthDate,
                      ).dateFormatted
                    }
                  </td>

                  {
                    //isEdit &&
                    //<td className="exon-table-action-item">
                    //    <span
                    //        className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
                    //        onClick={() => null //events.form.popup.onOpenFormPopupClicked(enums.actionsForm.edit, index)
                    //        }></span>
                    //    <span
                    //        className="exon-icon exon-icon-button exon-icon-dump-unfilled_enabled"
                    //        onClick={null}></span>
                    //</td>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingTravelerListTemplate;
