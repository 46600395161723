import * as Yup from 'yup';

const FeeTariffRatesSchema = Yup.object().shape({
  currency: Yup.string().required('O campo moeda deve ser preenchido'),
  exchangeRate: Yup.string().required(
    'O campo exchangeRate deve ser preenchido',
  ),
  dailyCount: Yup.number().required('O campo dailyCount deve ser preenchido'),
  dailyRate: Yup.string()
    .required('O campo dailyRate deve ser preenchido')
    .test('is-number', 'dailyRate deve ser um número', (value) => {
      const numberValue = parseFloat(value.replace(',', '.'));
      return !isNaN(numberValue);
    }),
  extrasTotal: Yup.string()
    .required('O campo extrasTotal deve ser preenchido')
    .test('is-number', 'extrasTotal deve ser um número', (value) => {
      const numberValue = parseFloat(value.replace(',', '.'));
      return !isNaN(numberValue);
    }),
  feesTotal: Yup.string()
    .required('O campo feesTotal deve ser preenchido')
    .test('is-number', 'feesTotal deve ser um número', (value) => {
      const numberValue = parseFloat(value.replace(',', '.'));
      return !isNaN(numberValue);
    }),
  markupValue: Yup.string()
    .required('O campo markupValue deve ser preenchido')
    .test('is-number', 'markupValue deve ser um número', (value) => {
      const numberValue = parseFloat(value.replace(',', '.'));
      return !isNaN(numberValue);
    }),
});

export default FeeTariffRatesSchema;
