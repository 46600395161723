import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root, { TravelConfiguration } from './root.component';

import { dispatchToast } from '@shared/toast';
import { ErrorBoundary } from '@shared/monitoring/ErrorBoundary';

import './legacy/assets/bootstrap/css/bootstrap.min.css';
import './legacy/assets/site.css';
import './legacy/assets/travel.css';
import './legacy/assets/exon-style.less';
import './legacy/assets/adjusts-after-migration.css';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    dispatchToast({
      type: 'error',
      content: 'Ocorreu um erro ao renderizar o componente',
    });
    return <div>ERRO</div>;
  },
  errorBoundaryClass: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;
export const TravelWeb = Root;
export const TravelCorporateTab = TravelConfiguration;
