'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import TripManagementTemplate from './trip-management.template.js';

export default class TripManagementComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      tripType: 0,
      tripItems: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let tripItems = props.tripItems ? props.tripItems : [];
    let tripsSelected = [];

    tripItems.forEach((trip) => {
      if (trip.tripSelected) tripsSelected.push(1);
    });

    return {
      itemSelected: props.tripPartsIndexSelected
        ? props.tripPartsIndexSelected
        : 0,
      tripType: props.tripType,
      tripItems: tripItems,
      allTripsSelected:
        tripItems.length > 0
          ? tripsSelected.length === tripItems.length
          : false,
    };
  }

  render() {
    return (
      <TripManagementTemplate.main
        tripType={this.state.tripType}
        tripItems={this.state.tripItems}
        itemSelected={this.state.itemSelected}
        allTripsSelected={this.state.allTripsSelected}
        tariffedPriceFareResult={this.props.tariffedPriceFareResult}
        onPartTripClicked={this.events.onPartTripClicked}
        onClearTripClicked={this.events.onClearTripClicked}
        onSelectMultipleTripClicked={this.events.onSelectMultipleTripClicked}
        onIncludeInCartClick={this.events.onIncludeInCartClicked}
        onTryAgainTariffedPriceMultipleTripClick={
          this.props.onTryAgainTariffedPriceMultipleTripClick
        }
      />
    );
  }

  events = {
    onClearTripClicked: (index) => {
      this.props.onClearTripClicked(index);
    },

    onPartTripClicked: (index) => {
      this.props.onTripClicked(index);
    },

    onIncludeInCartClicked: () => {
      if (this.props.onIncludeInCartClick) this.props.onIncludeInCartClick();
    },

    onSelectMultipleTripClicked: () => {
      if (this.props.onSelectMultipleTripClick)
        this.props.onSelectMultipleTripClick();
    },
  };
}
