import { Pill } from '@components/Pills';
import { FutureFeatures } from '../../hooks';
import * as SCPage from '../../styled';
import * as SCFeature from './styled';
const SC = { ...SCPage, ...SCFeature };

interface FutureFeaturesProps {
  title: string;
  features: FutureFeatures[];
  buttonText: string;
  loadingButton: boolean;
  onClickButton?: () => void;
}

export const FutureFeaturesSection = ({
  title,
  features,
  buttonText,
  loadingButton,
  onClickButton,
}: FutureFeaturesProps): JSX.Element => {
  return (
    <SC.Section>
      <SC.FeaturesContainer>
        <SC.Center>
          <SC.Title variant="headline6">{title}</SC.Title>
        </SC.Center>
        <SC.Container>
          {features.map((feature, index) => (
            <SC.Card key={index}>
              <p>{feature.name}</p>
              <SC.BadgeContainer>
                {feature.released ? (
                  <Pill icon="IconSparkles" variant="primary">
                    Já disponível!
                  </Pill>
                ) : (
                  <Pill icon="IconHourglassHigh" variant="neutral">
                    Em breve!
                  </Pill>
                )}
              </SC.BadgeContainer>
            </SC.Card>
          ))}
        </SC.Container>
        <SC.ButtonContainer>
          <SC.StyledButton
            variant="primary"
            size="large"
            type={'button'}
            onClick={onClickButton}
            loading={loadingButton}
            disabled={loadingButton}
          >
            {buttonText}
          </SC.StyledButton>
        </SC.ButtonContainer>
      </SC.FeaturesContainer>
    </SC.Section>
  );
};
