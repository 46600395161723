import React from 'react';
import Template from './split-modal.template';

export default class SplitModalComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Template.main {...this.props} />;
  }
}
