'use strict';

import React from 'react';
import ProductListTemplate from './product-list.template.js';

export default class ProductListComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let products = this.props.products ? this.props.products : null;
    let preBooking = this.props.preBooking ? this.props.preBooking : null;
    let tariffedPriceFare = this.props.tariffedPriceFare
      ? this.props.tariffedPriceFare
      : null;
    let airActions = this.props.airActions ? this.props.airActions : null;
    let hotelActions = this.props.hotelActions ? this.props.hotelActions : null;
    let displayCheckbox = this.props.displayCheckbox
      ? this.props.displayCheckbox
      : false;
    let displaytariffedPriceFare = this.props.displaytariffedPriceFare
      ? this.props.displaytariffedPriceFare
      : false;
    let onSelectedMoveTripProductChange = this.props
      .onSelectedMoveTripProductChange
      ? this.props.onSelectedMoveTripProductChange
      : null;
    let displayFareDatails = this.props.displayFareDatails
      ? this.props.displayFareDatails
      : false;
    let onDeleteProductClick = this.props.onDeleteProductClick
      ? this.props.onDeleteProductClick
      : null;
    let dataSources = this.props.dataSources ? this.props.dataSources : null;

    if (products && products.trips)
      products.trips.forEach((trip, index) => {
        trip.index = index;
      });

    if (products && products.hotels && products.hotels.selectedHotels)
      products.hotels.selectedHotels.forEach((hotel, index) => {
        hotel.index = index;
      });

    return (
      <ProductListTemplate.main
        products={products}
        preBooking={preBooking}
        dataSources={dataSources}
        tariffedPriceFare={tariffedPriceFare}
        airActions={airActions}
        hotelActions={hotelActions}
        onDeleteProductClick={onDeleteProductClick}
        displayCheckbox={displayCheckbox}
        displayFareDatails={displayFareDatails}
        displaytariffedPriceFare={displaytariffedPriceFare}
        onSelectedMoveTripProductChange={onSelectedMoveTripProductChange}
      />
    );
  }
}
