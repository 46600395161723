import { useTranslation as useI18n } from 'react-i18next';

interface ITranslator {
  path?: string;
  prefix: string;
  options?: any;
}

export const useTranslation = useI18n;

export const Translator = ({ path, prefix, options }: ITranslator) => {
  const { t } = useI18n();
  const concatPath = prefix ? prefix + '.' + path : path;
  return t(concatPath, options);
};

export const translate = (props: ITranslator) => {
  return Translator(props);
};
