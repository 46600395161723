'use strict';

import { useState } from 'react';
import { SliderField } from '@flash-tecnologia/hros-web-ui-v2';

interface DistanceRangeProps {
  min: number;
  max: number;
  step?: number;
  value: number[];
  initialValue: number;
  finalValue: number;
  onChange: (value: number | number[]) => void;
}
export const DistanceRange = ({
  min = 0,
  max = 5,
  step = 0.05,
  initialValue,
  finalValue,
  onChange,
}: DistanceRangeProps) => {
  const [minValue, setMinValue] = useState<number>(min);
  const [maxValue, setMaxValue] = useState<number>(max);
  return (
    <div className="exon-range-time">
      <div className="exon-range-time-values">
        <span>Min: {minValue.toFixed(2)} km</span>
        <span>Máx: {maxValue.toFixed(2)} km</span>
      </div>
      <SliderField
        value={[initialValue ?? min, finalValue ?? max]}
        step={step}
        min={min}
        max={max}
        type="range"
        valueLabelDisplay="off"
        valueLabelFormat={() => {
          return '';
        }}
        fieldMask={() => ''}
        onChange={(event: Event, value: number[], activeThumb: number) => {
          setMinValue(value[0]);
          setMaxValue(value[1]);
          if (onChange && typeof onChange === 'function') onChange(value);
        }}
      />
    </div>
  );
};
