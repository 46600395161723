import { enums } from '@legacy-utils/enums';
import { getLocationFormatted, getVehicleAdditionalsText } from '@legacy-utils/resolvers/vehicleResolver.ts';

const Template = {
  main: ({
    booking,
    canEdit,
    events,
  }) => {
    const empty = '--';
    const hasSegmentsData =
      booking.vehicle?.rentalCompany;

    return (
      <div className="exon-booking-vehicle-segments">
        <table className="exon-table-line" cellSpacing="0">
          <thead>
            <tr>
              <th>Locadora</th>
              <th>Categoria</th>
              <th>Tipo</th>
              <th>Câmbio</th>
              <th>Adicionais</th>
              <th>Retirada</th>
              <th>Devolução</th>
              <th>Localizador</th>

              {canEdit && (
                <th className="exon-table-actions-header">
                  {!hasSegmentsData && (
                    <span
                      className="exon-icon exon-icon-button exon-icon-add-unfilled_enabled"
                      onClick={() =>
                        events.onEditClick(
                          enums.actionsForm.add,
                        )
                      }
                    ></span>
                  )}
                </th>
              )}
            </tr>
          </thead>
          {hasSegmentsData && (
            <tbody>
              <tr>
                <td>
                  {booking.vehicle.rentalCompany || empty}
                </td>
                <td>
                  {booking.vehicle.category || empty}
                </td>
                <td>
                  {booking.vehicle.type || empty}
                </td>
                <td>
                  {booking.vehicle.transmission || empty}
                </td>
                <td>
                  {getVehicleAdditionalsText(booking.vehicle) || empty}
                </td>
                <td className='break-line'>
                  {getLocationFormatted(booking.vehicle.checkIn, booking.vehicle.pickUpLocation) || empty}
                </td>
                <td className='break-line'>
                  {getLocationFormatted(booking.vehicle.checkOut, booking.vehicle.returnLocation) || empty}
                </td>
                <td>
                  {booking.bookingLocator || empty}
                </td>

                {canEdit && (
                  <td className="exon-table-action-item">
                    <span
                      className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
                      onClick={() =>
                        events.onEditClick(
                          enums.actionsForm.edit,
                        )
                      }
                    ></span>
                  </td>
                )}
              </tr>
            </tbody>
          )}
        </table>
      </div>
    );
  },
};

export default Template;
