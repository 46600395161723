import { NumericFormat } from 'react-number-format';
import SC from './styled';
type BalanceValueSectionProps = {
  value: number;
};
export function BalanceValueSection({ value }: BalanceValueSectionProps) {
  return (
    <>
      <SC.BalanceNumberContainer>
        <SC.AvailableValue>
          <NumericFormat
            value={value}
            thousandSeparator="."
            decimalSeparator=","
            prefix={'R$ '}
            decimalScale={2}
            fixedDecimalScale
            displayType="text"
          />
        </SC.AvailableValue>
        <SC.Description>Valor corporativo disponível.</SC.Description>
      </SC.BalanceNumberContainer>
    </>
  );
}
