'use strict';

import React from 'react';
import TabsGroupTemplate from './tabs-group.template.js';

export default class TabsGroupComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <TabsGroupTemplate children={this.props.children} />;
  }
}
