import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { DefaultTheme, css } from 'styled-components';

export interface PillStyleProps {
  variant?: 'primary' | 'neutral';
}

const variantStyles = (
  theme: DefaultTheme,
  { variant = 'primary' }: PillStyleProps,
) => {
  return {
    primary: css`
      background-color: ${theme.colors.secondary[90]};
      color: ${theme.colors.secondary[50]};
    `,
    success: css`
      background-color: ${theme.colors.success90};
      color: ${theme.colors.success10};
    `,
    error: css`
      background-color: ${theme.colors.error90};
      color: ${theme.colors.error10};
    `,
    neutral: css`
      color: ${(props) => props.theme.colors.neutral[70]};
      background-color: ${theme.colors.neutral[90]};
    `,
  }[variant];
};

export const Pill = styled.button<PillStyleProps>`
  border-radius: ${({ theme }) => theme.borders.radius.pill};
  min-width: 2rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 600;
  display: flex;
  flex-wrap: nowrap;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  ${({ theme, variant }) => {
    return variantStyles(theme, { variant });
  }};
`;

export const Icon = styled(Icons).attrs(() => ({
  fill: 'transparent',
}))`
  color: inherit;
  background-color: none !important;
`;
