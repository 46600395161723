import Grid from '../../../grid/grid.component.js';

import Accordion from '../../../common/accordion/accordion.component.js';
import AccordionItem from '../../../common/accordion/accordion-item.component.js';

import Wizard from '../../../common/wizard/wizard.component.js';
import Step from '../../../common/wizard/wizard-step.component.js';
import { utils } from '@legacy-utils/utils';

const Template = {
  main: ({
    stepsControl,
    airOptionSelection,
    ticketReplacement,
    confirmation,
    alert,
  }) => {
    return (
      <div className="exon-air-trip-replacement">
        <Wizard
          onFinishClick={stepsControl.events.onFinishClicked}
          onCancelClick={stepsControl.events.onCancelClicked}
          disableNextButton={stepsControl.props.disableNextButton}
        >
          <Step>
            <Template.steps.airOptionSelection {...airOptionSelection} />
          </Step>
          <Step>
            <Template.steps.ticketReplacement {...ticketReplacement} />
          </Step>
          <Step>
            <Template.steps.confirmation {...confirmation} />
          </Step>
        </Wizard>
      </div>
    );
  },

  steps: {
    airOptionSelection: ({ props, events }) => (
      <div className="exon-air-trip-replacement_air-option-selection-step">
        <Accordion key={`air-option-selection_accordion`} retractable={true}>
          {props.airTrips.map((airTrip, index) => {
            let title = (
              <ul>
                <li>
                  <input
                    name={`air-trip-selection-${index}`}
                    type="checkbox"
                    value={index}
                    checked={airTrip.checked}
                    onChange={(event) =>
                      events.onAirTripCheckboxChanged(event, index)
                    }
                  />
                </li>
                <li>{`${airTrip.departure.iata} > ${airTrip.arrival.iata}`}</li>
              </ul>
            );

            return (
              <AccordionItem
                key={`air-option-selection_air-trip-${index}_accordion-item`}
                title={title}
                opened={index == 0}
              >
                <Grid
                  hideCheckboxes={true}
                  hideFilter={true}
                  columns={[
                    {
                      label: '',
                      refName: 'index',
                      identifier: true,
                      hide: true,
                    },
                    { label: 'Cia Aérea', refName: 'airCompany' },
                    { label: 'Nº Voo', refName: 'flightNumber' },
                    { label: 'Classe', refName: 'airClass' },
                    { label: 'Origem (IATA)', refName: 'departure' },
                    { label: 'Destino (IATA)', refName: 'arrival' },
                    { label: 'Saída', refName: 'departureDate' },
                    { label: 'Chegada', refName: 'arrivalDate' },
                    { label: 'Escalas', refName: 'layover' },
                  ]}
                  rows={airTrip.flights.map((flight, fIndex) => {
                    return {
                      index: fIndex,
                      airCompany: flight.airline.name,
                      flightNumber: flight.flightNumber,
                      airClass: flight.cabinType,
                      departure: flight.departure.iata,
                      arrival: flight.arrival.iata,
                      departureDate: utils.formatters.dateObjToString(
                        flight.departureDate,
                        true,
                      ),
                      arrivalDate: utils.formatters.dateObjToString(
                        flight.arrivalDate,
                        true,
                      ),
                      layover: flight.layover,
                    };
                  })}
                />
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    ),
    ticketReplacement: ({ props, events }) => <label>TICKET REPLACEMENT</label>,
    confirmation: ({ props, events }) => <label>CONFIRMATION</label>,
  },
};

export default Template;
