import {
  getCompanyLogoUrl,
  getLocationFormatted,
  getVehicleBookingFullName,
} from '../../../utils/resolvers/vehicleResolver.ts';

const Template = {
  main: ({ product, showRentalCompanyImage, onRentalCompanyImageErrored }) => {
    return (
      <div className="exon-product-summary">
        <div className="exon-product-details">
          <div className="exon-vehicle-product-item">
            <Template.travelDetails
              product={product}
              showRentalCompanyImage={showRentalCompanyImage}
              onRentalCompanyImageErrored={onRentalCompanyImageErrored}
            />
          </div>
        </div>
      </div>
    );
  },

  travelDetails: ({
    product,
    showRentalCompanyImage,
    onRentalCompanyImageErrored,
  }) => {
    let vehicleData = product.vehicle;
    if (!vehicleData.rentalCompany && product.vehicle?.originalRequest)
      vehicleData = product.vehicle?.originalRequest;

    console.log('product', product);

    return (
      <div>
        <div className="exon-box-product">
          <div className="exon-company">
            <div className="exon-logo-vehicle">
              {showRentalCompanyImage ? (
                <img
                  src={getCompanyLogoUrl(vehicleData.rentalCompany)}
                  onError={onRentalCompanyImageErrored}
                />
              ) : (
                vehicleData.rentalCompany
              )}
            </div>
          </div>
          <div>
            <h4>{getVehicleBookingFullName(vehicleData)}</h4>
            <span>
              {vehicleData.days} {vehicleData.days == 1 ? 'diária' : 'diárias'}
            </span>
            <div className="two-columns">
              <div>
                <span className="highlight">Retirada</span>
                <span>
                  {getLocationFormatted(
                    vehicleData.checkIn,
                    vehicleData.pickUpLocation,
                  ) || '--'}
                </span>
              </div>
              <div>
                <span className="highlight">Devolução</span>
                <span>
                  {getLocationFormatted(
                    vehicleData.checkOut,
                    vehicleData.returnLocation,
                  ) || '--'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export default Template;
