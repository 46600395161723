'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import ProductSummaryTemplate from './product-summary.template.js';
import { enums } from '@legacy-utils/enums';

export default class ProductSummaryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    let productType = props.productType
      ? props.productType
      : enums.productType.none;
    let product = props.product ? props.product : null;
    let travelers = props.travelers ? props.travelers : null;
    let hasPreBooking = props.hasPreBooking ? props.hasPreBooking : false;
    let enableProductCheckbox = props.enableProductCheckbox
      ? props.enableProductCheckbox
      : false;
    let productActions = props.productActions ? props.productActions : null;

    return {
      productType: productType,
      product: product,
      travelers: travelers,
      hasPreBooking: hasPreBooking,
      enableProductCheckbox: enableProductCheckbox,
      productActions: productActions,
    };
  }

  render() {
    return (
      <ProductSummaryTemplate.main
        productType={this.state.productType}
        product={this.state.product}
        travelers={this.state.travelers}
        hasPreBooking={this.state.hasPreBooking}
        enableProductCheckbox={this.state.enableProductCheckbox}
        productActions={this.state.productActions}
        onSelectedMoveTripProductChange={
          this.events.onSelectedMoveTripProductChange
        }
      />
    );
  }

  events = {
    onSelectedMoveTripProductChange: (
      ev,
      indexGroup,
      indexProduct,
      productType,
    ) => {
      if (this.props.onSelectedMoveTripProductChange)
        this.props.onSelectedMoveTripProductChange(
          ev,
          indexGroup,
          indexProduct,
          productType,
        );
    },
  };
}
