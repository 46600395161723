'use strict';

import React from 'react';
import Template from './hotel-booking.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { enums } from '@legacy-utils/enums';
import { statusFlowResolver } from '@legacy-utils/resolvers/statusFlowResolver.js';
import { componentUtil } from '@legacy-utils/componentUtil';
import { utils, gritter } from '@legacy-utils/utils.js';

export default class HotelBookingComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      displayLoader: true,
      analysisItemData: {
        show: false,
      },
      booking: {},
      popupExpirationDate: {
        show: false,
        data: null,
        events: {
          confirm: null,
          cancel: null,
        },
      },
      popupConfirmData: {
        show: false,
        data: null,
        events: {
          confirm: null,
          cancel: null,
        },
      },
      popupConfirmIssuance: {
        show: false,
        data: null,
        events: {
          confirm: null,
          cancel: null,
        },
      },
      expirationDate: null,
      confirmedBy: '',
      locatorValue: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let booking = props.booking;

    if (!booking.loading)
      booking.allowedActions = statusFlowResolver.getRecordAllowedActions(
        booking,
        props.requester,
        props.currentUserData.userId,
        props.currentUserData.travelProfile,
        props.currentUserData.company
      );

    if (booking.confirmedBy && !state.confirmedBy)
      state.confirmedBy = booking.confirmedBy;

    if (
      !state.expirationDate &&
      booking &&
      booking.expiration &&
      booking.expiration.expireAt &&
      !utils.formatters.date.isDefaultDate(booking.expiration.expireAt)
    )
      state.expirationDate = utils.formatters.date.dateObjToString(
        booking.expiration.expireAt,
        enums.dateFormat.monthYearHourMinute,
      );

    return {
      booking,
      confirmedBy: state.confirmedBy,
      expirationDate: state.expirationDate,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          booking={this.state.booking}
          editItemsEvents={this.events.editItens}
          bookingEvents={this.events.booking}
          analysisItemData={this.state.analysisItemData}
          analysisItemEvents={this.events.analysisItem}
          dataSources={this.props.dataSources}
          onShowEmailPopupClick={this.props.onShowEmailPopupClick}
          popupExpirationDate={this.state.popupExpirationDate}
          expirationEvents={this.events.popup.expirationDate}
          expirationDateValue={this.state.expirationDate}
          confirmedByValue={this.state.confirmedBy}
          popupConfirmData={this.state.popupConfirmData}
          confirmDataEvents={this.events.popup.confirmData}
          popupConfirmIssuance={this.state.popupConfirmIssuance}
          locatorValue={this.state.locatorValue}
          confirmIssuanceEvents={this.events.popup.confirmIssuance}
        />
      </ErrorBoundary>
    );
  }

  events = {
    analysisItem: {
      onShowAnalysisItemClicked: () => {
        this.props.events.onAnalysisItemClicked &&
          this.props.events.onAnalysisItemClicked(enums.products.type.hotel);

        this.setState({
          analysisItemData: { ...this.state.analysisItemData, show: true },
        });
      },
      onHideAnalysisItemClicked: () => {
        this.setState({
          analysisItemData: { ...this.state.analysisItemData, show: false },
        });
      },
    },
    booking: {
      onUpdateBookingClicked: () => {
        this.props.events.onUpdateHotelBookingClicked &&
          this.props.events.onUpdateHotelBookingClicked();
      },

      onSendAppsClicked: (id) => {
        this.props.events.onSendAppsClicked &&
          this.props.events.onSendAppsClicked(id);
      },
    },
    editItens: {
      onEditHotelSegmentsClicked: (data) => {
        if (this.props.rvOfflineEvents.onEditHotelSegmentsClicked)
          this.props.rvOfflineEvents.onEditHotelSegmentsClicked(
            data,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );
      },
      onEditTariffsAndFessClicked: (data) => {
        if (this.props.rvOfflineEvents.onEditHotelTariffsAndFessClicked)
          this.props.rvOfflineEvents.onEditHotelTariffsAndFessClicked(
            data,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );
      },
      onEditExpirationDateClicked: () => {
        let expirationDate = this.state.expirationDate;
        if (!expirationDate) {
          gritter.Error('Data de expiração deve ser preenchida.');
          return;
        }

        let expiration = {
          expireAt: utils.date.strDateTimeToObjectDate(
            this.state.expirationDate,
            '/',
          ),
          expired: false,
          ignore: false,
        };

        if (this.props.rvOfflineEvents.onEditExpirationDate)
          this.props.rvOfflineEvents.onEditExpirationDate(
            expiration,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );

        this.events.popup.expirationDate.onClosePopupClicked();
      },
      onConfirmDataClicked: () => {
        let expirationDate = this.state.expirationDate;
        let expiration = null;
        let confirmedBy = this.state.confirmedBy;

        if (!expirationDate && !confirmedBy) {
          gritter.Error(
            'Um dos campos devem ser preenchidos "confirmado por" ou "data de expiração".',
          );
          return;
        }

        if (expirationDate) {
          expiration = {
            expireAt: utils.date.strDateTimeToObjectDate(
              this.state.expirationDate,
              '/',
            ),
            expired: false,
            ignore: false,
          };
        }

        let confirmData = { expiration, confirmedBy };

        if (this.props.rvOfflineEvents.onConfirmDataClicked)
          this.props.rvOfflineEvents.onConfirmDataClicked(
            confirmData,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );

        this.events.popup.confirmData.onClosePopupClicked();
      },
      onConfirmIssuanceClicked: () => {
        let locator = this.state.locatorValue;

        if (!locator) {
          gritter.Error(
            'O campo "Localizador" deve ser preenchido para continuar esta ação.',
          );
          return;
        }

        if (this.props.rvOfflineEvents.onConfirmIssuanceClicked)
          this.props.rvOfflineEvents.onConfirmIssuanceClicked(
            locator,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );

        this.events.popup.confirmIssuance.onClosePopupClicked();
      },
    },

    popup: {
      expirationDate: {
        onClosePopupClicked: () => {
          this.setState({
            popupExpirationDate: {
              show: false,
              data: null,
              events: {
                confirm: null,
                cancel: null,
              },
            },
            expirationDate: null,
          });
        },
        onShowPopupClicked: () => {
          this.setState({
            popupExpirationDate: {
              show: true,
              data: {
                mask: '99/99/9999 99:99',
                placeholder: '00/00/00 00:00',
                onChange: this.events.onChanged.onExpirationDateChanged,
              },
              events: {
                confirm: this.events.editItens.onEditExpirationDateClicked,
                cancel: this.events.popup.expirationDate.onClosePopupClicked,
              },
            },
          });
        },
      },
      confirmData: {
        onClosePopupClicked: () => {
          this.setState({
            popupConfirmData: {
              show: false,
              data: null,
              events: {
                confirm: null,
                cancel: null,
              },
            },
            expirationDate: null,
            confirmedBy: null,
          });
        },
        onShowPopupClicked: () => {
          this.setState({
            popupConfirmData: {
              show: true,
              data: {
                expirationDate: {
                  mask: '99/99/9999 99:99',
                  placeholder: '00/00/00 00:00',
                  onChange: this.events.onChanged.onExpirationDateChanged,
                },
                confirmedBy: {
                  placeholder: 'Digite aqui',
                  value: this.state.confirmedBy,
                  onChange: this.events.onChanged.onConfirmedByChanged,
                },
              },
              events: {
                confirm: this.events.editItens.onConfirmDataClicked,
                cancel: this.events.popup.confirmData.onClosePopupClicked,
              },
            },
          });
        },
      },
      confirmIssuance: {
        onClosePopupClicked: () => {
          this.setState({
            popupConfirmIssuance: {
              show: false,
              data: null,
              events: {
                confirm: null,
                cancel: null,
              },
            },
            locatorValue: null,
          });
        },
        onShowPopupClicked: () => {
          this.setState({
            popupConfirmIssuance: {
              show: true,
              data: {
                locator: {
                  placeholder: 'Digite aqui',
                  value: this.state.locatorValue,
                  onChange: this.events.onChanged.onLocatorChanged,
                },
              },
              events: {
                confirm: this.events.editItens.onConfirmIssuanceClicked,
                cancel: this.events.popup.confirmIssuance.onClosePopupClicked,
              },
            },
          });
        },
      },
    },

    onChanged: {
      onExpirationDateChanged: (data) => {
        this.setState({
          expirationDate: data,
        });
      },
      onConfirmedByChanged: (data) => {
        this.setState({
          confirmedBy: data,
        });
      },
      onLocatorChanged: (data) => {
        this.setState({
          locatorValue: data,
        });
      },
    },
  };
}
