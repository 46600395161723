import {
  getFromLS,
  setInLS,
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { createContext } from 'react';

import {
  HROS_ACCESS_TOKEN,
  TRAVEL_FLASH_OS_EMPLOYEE_ID,
  TRAVEL_FLASH_OS_COMPANY_ID,
} from '@shared/constants/Storage.constants';
import { ISessionContext, ISessionProviderProps } from './sessionContext.types';
import { useAuthData } from '@shared/hooks/useAuthData';

export const SessionContext = createContext<ISessionContext>(null);

export const SessionContextProvider = ({ children }: ISessionProviderProps) => {
  const permissionsData = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const { employeeId } = useAuthData();
  const selectedCompanyId = selectedCompany?.id;
  const token = getFromLS(HROS_ACCESS_TOKEN);

  //TODO - Refatoração futura será necessária devido a mudanças na lib "hros-web-utility"
  setInLS({ key: TRAVEL_FLASH_OS_EMPLOYEE_ID, value: employeeId });
  setInLS({ key: TRAVEL_FLASH_OS_COMPANY_ID, value: selectedCompanyId });

  return (
    <SessionContext.Provider
      value={{
        employeeId,
        companyId: selectedCompanyId,
        companyName: selectedCompany?.name,
        companyCNPJ: selectedCompany?.registrationNumber,
        accessToken: token?.accessToken,
        isAdmin: permissionsData.isAdmin,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
