import React from 'react';
import MonitoringGridTemplate from './monitoring-grid.template.js';
import { enums } from '@legacy-utils/enums';
import { apiResponse } from '@legacy-utils/apiResponse';
import { statusFlowResolver } from '@legacy-utils/resolvers/statusFlowResolver.js';
import { gritter } from '@legacy-utils/utils';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class MonitoringGridComponent extends React.Component {
  constructor(props) {
    super(props);

    componentUtil.react.bindMethods(this.dataSource, this);

    this.state = {
      isAgency: this.props.agency.isAgency,
      viewBy: this.props.viewBy,
      rows: this.props.rowsGrid,
      pagination: {},

      notifier: {
        show: false,
        fieldsData: {},
        item: null,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    let pagination = {
      totalResults: props.rowsGrid.length,
      itemsPerPage: 5,
      currentPage: props.currentPage,
    };

    return {
      rows: props.rowsGrid,
      viewBy: props.viewBy,
      pagination: pagination,
    };
  }

  render() {
    return (
      <MonitoringGridTemplate.main
        isAgency={this.state.isAgency}
        existItems={this.props.rowsGrid.length}
        rows={this.state.rows}
        viewBy={this.state.viewBy}
        onShowEmailPopupClick={this.events.onShowEmailPopupClicked.bind(this)}
        notifierProps={this.notifier.getProps()}
        notifierEvents={this.events.notifier}
        pagination={this.state.pagination}
        onPageClick={this.events.onPageClicked.bind(this)}
        onRVClick={this.events.onRVClicked.bind(this)}
      />
    );
  }

  events = {
    onPageClicked: (currentPage) => {
      this.props.onPageClicked && this.props.onPageClicked(currentPage);
    },

    onRVClicked: (id, rootContextId) => {
      this.props.requetsEvents.onOpenRequestClicked(id, rootContextId);
    },

    onShowEmailPopupClicked: (item) => {
      this.setState({
        notifier: {
          ...this.state.notifier,
          show: true,
          item: item,
          fieldsData: statusFlowResolver.getEmailAdreesseeByStatus(
            item.status.id,
          ),
        },
      });
    },

    notifier: {
      onHidePopupClicked: () => {
        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData: {},
            show: false,
            item: null,
          },
        });
      },

      onConfirmNotifyClicked: () => {
        let item = this.state.notifier.item;
        let statusId = item.status.id;
        let objEmail = statusFlowResolver.getFormattedEmailObject(
          this.state.notifier.fieldsData,
        );

        if (
          statusId != enums.workflowStatus.inIssue &&
          statusId != enums.workflowStatus.issued &&
          statusId != enums.workflowStatus.canceled
        ) {
          objEmail.serviceRequestId = item.parentId;
          this.dataSource.serviceRequest.notify(objEmail, item.rootContextId);
        } else {
          objEmail.serviceRequestItemId = item.id;
          this.dataSource.serviceRequestItem.notifyItem(
            objEmail,
            item.rootContextId,
          );
        }

        this.events.notifier.onHidePopupClicked();
      },

      onFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef] = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },

      onNameFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef].name = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },

      onEmailFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef].email = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },
    },
  };

  dataSource = {
    serviceRequest: {
      notify: (loadOption, contextId) => {
        const _this = this;
        const dataSource = this.props.dataSource.serviceRequest;

        if (dataSource.events && dataSource.events.beforeSendNotify)
          dataSource.events.beforeSendNotify();

        dataSource
          .sendNotify(loadOption, contextId)
          .then((result) => {
            apiResponse.successful.showErrorIfExists(result);

            if (result.successful)
              gritter.Success('Seu e-mail será enviado em instantes.');

            if (dataSource.events && dataSource.events.afterSendNotify)
              dataSource.events.afterSendNotify();
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(
              error,
              'Ocorreu um erro ao Client/Servidor ao enviar e-mail',
            );

            if (dataSource.events && dataSource.events.afterSendNotify)
              dataSource.events.afterSendNotify();
          });
      },
    },
    serviceRequestItem: {
      notifyItem: (loadOption, contextId) => {
        const _this = this;
        const dataSource = this.props.dataSource.serviceRequestItem;

        if (dataSource.events && dataSource.events.beforeSendNotifyItem)
          dataSource.events.beforeSendNotifyItem();

        dataSource
          .sendNotifyItem(loadOption, contextId)
          .then((result) => {
            apiResponse.successful.showErrorIfExists(result);

            if (result.successful)
              gritter.Success('Seu e-mail será enviado em instantes.');

            if (dataSource.events && dataSource.events.afterSendNotifyItem)
              dataSource.events.afterSendNotifyItem();
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(
              error,
              'Ocorreu um erro ao Client/Servidor ao enviar e-mail',
            );

            if (dataSource.events && dataSource.events.afterSendNotifyItem)
              dataSource.events.afterSendNotifyItem();
          });
      },
    },
  };

  notifier = {
    getProps: () => {
      return {
        ...this.state.notifier,
        notificationType: enums.notificationType.email,
        dataSources: {
          serviceRequest: {
            previewTemplate: (args) => {
              if (!args) args = {};

              let item = this.state.notifier.item;
              let contextId;
              if (item) {
                contextId =
                  this.state.isAgency && item.rootContextId
                    ? item.rootContextId
                    : null;
                args.serviceRequestId = item.parentId;
              }

              const dataSource = this.props.dataSource.serviceRequest;
              dataSource.events &&
                dataSource.events.beforePreviewNotify &&
                dataSource.events.beforePreviewNotify(args);

              return new Promise((resolve, reject) => {
                dataSource
                  .previewNotify(args, contextId)
                  .then((response) => {
                    dataSource.events &&
                      dataSource.events.afterPreviewNotify &&
                      dataSource.events.afterPreviewNotify(args, response);
                    resolve(response);
                  })
                  .catch((result) => {
                    dataSource.events &&
                      dataSource.events.afterPreviewNotify &&
                      dataSource.events.afterPreviewNotify(args, result);
                    reject(result);
                  });
              });
            },
          },

          serviceRequestItem: {
            previewTemplate: (args) => {
              if (!args) args = {};

              let item = this.state.notifier.item;
              let contextId;
              if (item) {
                contextId =
                  this.state.isAgency && item.rootContextId
                    ? item.rootContextId
                    : null;
                args.serviceRequestItemId = item.id;
              }

              const dataSource = this.props.dataSource.serviceRequestItem;
              dataSource.events &&
                dataSource.events.beforePreviewNotifyItem &&
                dataSource.events.beforePreviewNotifyItem(args);

              return new Promise((resolve, reject) => {
                dataSource
                  .previewNotifyItem(args, contextId)
                  .then((response) => {
                    dataSource.events &&
                      dataSource.events.afterPreviewNotifyItem &&
                      dataSource.events.afterPreviewNotifyItem(args, response);
                    resolve(response);
                  })
                  .catch((result) => {
                    dataSource.events &&
                      dataSource.events.afterPreviewNotifyItem &&
                      dataSource.events.afterPreviewNotifyItem(args, result);
                    reject(result);
                  });
              });
            },
          },
        },
      };
    },
  };
}
