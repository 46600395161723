'use strict';

import React from 'react';
import TravelExpirationTemplate from './travel-expiration.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class TravelExpirationComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <TravelExpirationTemplate
          expirationInfos={this.props.expirationInfos}
        />
      </ErrorBoundary>
    );
  }
}
