'use strict';

import React from 'react';
import Template from './vehicle-booking-segments.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';

export default class vehicleBookingSegmentsComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main {...this.props}/>
      </ErrorBoundary>
    );
  }
}
