import {
  Checkbox,
  DatePicker,
  Modal,
  SelectField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from '@components/RequestTravel/RequestTravelOffline/styled';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { SearchFieldLocation } from '@shared/components/SearchFieldLocation';
import {
  insertTraveler,
  updateTraveler,
} from '@api/connectOn/connectOn.service';
import { useLoading } from '../../../../../contexts/LoadingContext';
import { useTravelItemStore } from '../../../../../store/RequestTravelItem.store';
import { convertDateStringToDate } from '@shared/utils/date.utils';
import { useTravelStore } from '../../../../../store/RequestTravel.store';
import useModalStore from '../../../../../store/Modal.store';

interface DetailsTravelersProps {
  handleClose: (isSuccess: boolean) => void;
  isModal: boolean;
}

export default function DetailsTravelers({
  isModal,
  handleClose,
}: DetailsTravelersProps) {
  const [useSocialName, setUseSocialName] = useState<boolean>(false);
  const contextId = useTravelStore((state) => state.contextId);
  const traveler = useTravelStore((state) => state.traveler);
  const idUserProfile = useTravelStore((state) => state.idUserProfile);
  const location = useTravelItemStore((state) => state.location);
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);
  const { showLoading, hideLoading } = useLoading();

  const brazilLocationId = 210;
  const today = new Date();
  const minDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate(),
  );

  const validationSchema = yup.object({
    title: yup.string().required('O título é obrigatório.'),
    firstName: yup.string().required('O primeiro nome é obrigatório.'),
    middleName: yup.string(),
    lastName: yup.string().required('O sobrenome é obrigatório.'),
    socialName: yup.boolean().required('O campo nome social é obrigatório'),
    titleSocial: useSocialName
      ? yup.string().required('O título é obrigatório.')
      : yup.string(),
    firstNameSocial: useSocialName
      ? yup.string().required('O primeiro nome é obrigatório.')
      : yup.string(),
    middleNameSocial: useSocialName ? yup.string() : yup.string(),
    lastNameSocial: useSocialName
      ? yup.string().required('O sobrenome é obrigatório.')
      : yup.string(),
    birthDate: yup
      .date()
      .required('A data de nascimento é obrigatória.')
      .max(minDate, 'Você deve ter pelo menos 18 anos de idade.')
      .typeError('Data inválida, deve ser uma data válida.'),
    gender: yup.string().required('O sexo é obrigatório.'),
    nationality: yup.string().required('A nacionalidade é obrigatória.'),
    phoneNumber: yup.string().required('O número de telefone é obrigatório.'),
    email: yup
      .string()
      .email('O e-mail deve ser válido.')
      .required('O e-mail é obrigatório.'),
    documentType: yup.string().required('O tipo de documento é obrigatório.'),
    documentNumber: yup
      .string()
      .required('O número do documento é obrigatório.'),
    documentName: yup.string().required('O nome no documento é obrigatório.'),
  });
  type FormDataProps = yup.InferType<typeof validationSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (traveler) {
      reset({
        title: traveler.name.honorificPrefix,
        firstName: traveler.name.firstName,
        middleName: traveler.name.middleName,
        lastName: traveler.name.lastName,
        socialName: traveler.useSocialName,
        birthDate: convertDateStringToDate(traveler.birthDate),
        gender: traveler.gender && traveler.gender === 1 ? 'M' : 'F',
        phoneNumber: traveler.phone,
        email: traveler.email,
        documentType: 'cpf',
        documentNumber: traveler?.documents[0]?.number,
        documentName: traveler.fullName,
        nationality: brazilLocationId.toString(), // Certifique-se de que a nacionalidade está sendo definida
      });
    }
  }, [traveler, reset]);

  const socialNameChecked = watch('socialName');
  const firstName = watch('firstName');
  const middleName = watch('middleName');
  const lastName = watch('lastName');

  useEffect(() => {
    const fullName = [firstName, middleName, lastName]
      .filter(Boolean)
      .join(' ');
    setValue('documentName', fullName);
  }, [firstName, middleName, lastName, setValue]);

  useEffect(() => {
    setUseSocialName(socialNameChecked);
  }, [socialNameChecked]);

  const handleCloseModal = async () => {
    openModal({
      title: 'Tem certeza que deseja cancelar?',
      status: 'alert',
      subTitle:
        'Todas as informações preenchidas serão perdidas e essa ação não poderá ser desfeita.',
      primaryButtonLabel: 'Continuar',
      primaryButtonAction: () => {
        closeModal();
      },
      secondaryButtonLabel: 'Cancelar preenchimento',
      secondaryButtonAction: () => {
        closeModal();
        handleClose(false);
      },
    });
  };

  const handleSuccess = async () => {
    closeModal();
    handleClose(true);
  };

  const onSubmit = async (formData: FormDataProps) => {
    const mappedDocuments = traveler.documents.map((doc) => ({
      Id: doc.id,
      TravelerId: traveler.id,
      DocumentTypeId: doc.type,
      Number: doc.number,
      PrintedName: doc.printedName,
    }));

    console.log('FormTraveler >', formData);

    const dataToSend = {
      Id: traveler.id,
      ContextId: contextId,
      CreatedAt: new Date(),
      CreatedBy: idUserProfile,
      UserId: traveler.userId,
      HonorificPrefix: formData.title,
      FirstName: formData.firstName,
      MiddleName: formData.middleName,
      LastName: formData.lastName,
      UseSocialName: formData.socialName,
      BirthDate: formData.birthDate,
      Gender: formData.gender,
      Genre: formData.gender,
      Email: formData.email,
      NationalityLocationId: location.i,
      PhoneNumber: formData.phoneNumber,
      IsVIP: traveler.isVIP,
      TravelerAddressId: traveler.travelerAddressId,
      Documents: mappedDocuments,
      CostCenter: null,
      Address: traveler.address,
    };

    try {
      showLoading('Salvando as informações do viajante.');
      if (traveler.id === 0) {
        await insertTraveler(dataToSend, contextId);
      } else {
        await updateTraveler(dataToSend, contextId);
        openModal({
          title: 'Dados do viajante atualizado com sucesso!',
          subTitle: 'Os dados do viajente foram atualizados com sucesso!',
          primaryButtonLabel: 'Continuar',
          primaryButtonAction: handleSuccess,
          secondaryButtonLabel: '',
          status: 'success',
        });
      }
    } catch (e) {
      openModal({
        title: 'Ocorreu um erro no processo da requisição de viagem!',
        subTitle: 'Ocorreu um erro no processo da requisição de viagem!',
        status: 'error',
        primaryButtonLabel: 'Continuar',
        primaryButtonAction: closeModal,
        secondaryButtonLabel: '',
      });
    } finally {
      hideLoading();
    }
  };

  const opionsTitles = [
    {
      label: 'Mr',
      value: 'Mr',
    },
    {
      label: 'Mrs',
      value: 'Mrs',
    },
    {
      label: 'Miss',
      value: 'Miss',
    },
    {
      label: 'Dr',
      value: 'Dr',
    },
    {
      label: 'Mx',
      value: 'Mx',
    },
  ];

  return (
    <div>
      <Modal.Root open={isModal} onClose={handleCloseModal} size={'md'}>
        <>
          <Modal.Header title={'Detalhes do viajante'}></Modal.Header>
          <Modal.Content>
            <SC.RequestContainer>
              <div style={{ position: 'relative' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-row">
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      <div>
                        <Controller
                          name="title"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <SelectField
                              label="Título"
                              value={value}
                              onSelectChange={(event, selectedItem) => {
                                if (selectedItem) {
                                  onChange(selectedItem.value);
                                }
                              }}
                              inputRef={ref}
                              error={!!errors.title}
                              helperText={errors.title?.message}
                              options={opionsTitles}
                            />
                          )}
                        />
                      </div>
                      <div>
                        <TextField
                          {...(register as any)}
                          label="Primeiro Nome"
                          {...register('firstName')}
                          error={!!errors.firstName}
                          helperText={errors.firstName?.message}
                        />
                      </div>
                      <div>
                        <TextField
                          {...(register as any)}
                          label="Nome do Meio"
                          {...register('middleName')}
                        />
                      </div>
                      <div>
                        <TextField
                          {...(register as any)}
                          label="Último Nome"
                          {...register('lastName')}
                          error={!!errors.lastName}
                          helperText={errors.lastName?.message}
                        />
                      </div>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <Checkbox
                            {...register('socialName')}
                            onChange={(e) => {
                              console.log(
                                'Checkbox alterado:',
                                e.target.checked,
                              );
                              setUseSocialName(e.target.checked);
                            }}
                          />
                          <div className={'title-social-name'}>
                            Usar nome social na reserva
                          </div>
                        </div>
                      </div>
                    </div>
                    {useSocialName && (
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          marginBottom: '20px',
                        }}
                      >
                        <div>
                          <Controller
                            name="titleSocial"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <SelectField
                                label="Título"
                                value={value}
                                onSelectChange={(event, selectedItem) => {
                                  if (selectedItem) {
                                    onChange(selectedItem.value);
                                  }
                                }}
                                inputRef={ref}
                                error={!!errors.titleSocial}
                                helperText={errors.titleSocial?.message}
                                options={opionsTitles}
                              />
                            )}
                          />
                        </div>
                        <div>
                          <TextField
                            label="Primeiro Nome Social"
                            {...(register as any)}
                            {...register('firstNameSocial')}
                            error={!!errors.firstNameSocial}
                            helperText={errors.firstNameSocial?.message}
                          />
                        </div>
                        <div>
                          <TextField
                            {...(register as any)}
                            label="Nome do Meio Social"
                            {...register('middleNameSocial')}
                          />
                        </div>
                        <div>
                          <TextField
                            {...(register as any)}
                            label="Último Nome Social"
                            {...register('lastNameSocial')}
                            error={!!errors.lastNameSocial}
                            helperText={errors.lastNameSocial?.message}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <Controller
                          name="birthDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              value={field.value}
                              onDateChange={(date) => field.onChange(date)}
                              label="Data de Nascimento"
                              error={!!errors.birthDate}
                              helperText={
                                errors.birthDate ? errors.birthDate.message : ''
                              }
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <Controller
                          name="gender"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <SelectField
                              style={{ width: '100%' }}
                              label="Sexo"
                              value={value}
                              inputRef={ref}
                              error={!!errors.gender}
                              helperText={errors.gender?.message}
                              onSelectChange={(event, selectedItem) => {
                                if (selectedItem) {
                                  onChange(selectedItem.value);
                                }
                              }}
                              options={[
                                { value: 'M', label: 'Masculino' },
                                { value: 'F', label: 'Feminino' },
                              ]}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <Controller
                          name="nationality"
                          control={control}
                          render={({ field }) => (
                            <SearchFieldLocation
                              {...field}
                              errors={errors}
                              label={'Nacionalidade'}
                              type={2}
                              defaultValue={brazilLocationId}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <TextField
                          {...(register as any)}
                          style={{ width: '100%' }}
                          label="Telefone"
                          {...register('phoneNumber')}
                          error={!!errors.phoneNumber}
                          helperText={errors.phoneNumber?.message}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <TextField
                          {...(register as any)}
                          style={{ width: '100%' }}
                          label="E-mail"
                          {...register('email')}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        marginBottom: '20px',
                        borderTop: '2px solid #e3e3e3',
                        paddingTop: '20px',
                      }}
                    >
                      <div style={{ width: '60%' }}>
                        <Controller
                          name="documentType"
                          control={control}
                          defaultValue="cpf"
                          render={({ field }) => (
                            <SelectField
                              style={{ width: '60%' }}
                              label="Tipo de Documento"
                              {...field}
                              options={[{ value: 'cpf', label: 'CPF' }]}
                              disabled={true}
                              error={!!errors.documentType}
                              helperText={errors.documentType?.message}
                            ></SelectField>
                          )}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <TextField
                          {...(register as any)}
                          style={{ width: '100%' }}
                          label="Número do Documento"
                          {...register('documentNumber')}
                          error={!!errors.documentNumber}
                          helperText={errors.documentNumber?.message}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <TextField
                          {...(register as any)}
                          style={{ width: '100%' }}
                          disabled={true}
                          placeholder={'Nome Impresso no Documento'}
                          error={!!errors.documentName}
                          helperText={errors.documentName?.message}
                          {...register('documentName')}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </SC.RequestContainer>
          </Modal.Content>
          <Modal.Footer
            cancelText={'Cancelar'}
            onCancel={handleCloseModal}
            onConfirm={handleSubmit(onSubmit)}
            confirmText={'Confirmar'}
          ></Modal.Footer>
        </>
      </Modal.Root>
    </div>
  );
}
