import { create } from 'zustand';

type FeeTariffRatesStore = {
  isModalOpen: boolean;
  toggleModal: () => void;
  tariff: any | null;
  setTariff: (tariff: any) => void;
  onSaveSuccess: () => void;
  setOnSaveSuccess: (fn: () => void) => void;
};

export const useFeeTariffRatesStore = create<FeeTariffRatesStore>((set) => ({
  isModalOpen: false,
  tariff: null,
  toggleModal: () => set((state) => ({ isModalOpen: !state.isModalOpen })),
  setTariff: (tariff) => set({ tariff }),
  onSaveSuccess: () => {},
  setOnSaveSuccess: (fn) => set({ onSaveSuccess: fn }),
}));
