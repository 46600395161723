import { BASE_URL, LANDING_PAGE_URL } from '@shared/constants/Routes.constants';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Approvals from '../pages/Approvals';
import LandingPage from '../pages/LandingPage';
import Monitoring from '../pages/Monitoring';
import Sale from '../pages/Sale';
import Travels from '../pages/Travels';

import ReportChoice from '../pages/Reports/ReportChoice';
import ReportTravelRequests from '../pages/Reports/ReportTravelRequests';
import ReportBookings from '../pages/Reports/ReportBookings';
import ReportAirTickets from '../pages/Reports/ReportAirTickets';

import { RoutesGuard } from './routesGuard';
import TravelConfigurationPage from '../pages/TravelConfiguration';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={LANDING_PAGE_URL} element={<LandingPage />} />
        <Route path={BASE_URL} element={<RoutesGuard />}>
          <Route path="sale" element={<Sale />} />
          <Route path="list" element={<Travels />} />
          <Route path="approvals" element={<Approvals />} />
          <Route path="monitoring" element={<Monitoring />} />
          <Route path="reports">
            <Route path="" element={<ReportChoice />} />
            <Route path="travel-requests" element={<ReportTravelRequests />} />
            <Route path="bookings" element={<ReportBookings />} />
            <Route path="air-tickets" element={<ReportAirTickets />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export const AppTravelConfigurationRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={BASE_URL} element={<RoutesGuard />}>
          <Route path="approvalflow">
            <Route path="approvalflow" element={<TravelConfigurationPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
