import Container from '../../../../common/container/container.component.js';
import HotelRating from '../../hotel-rating/hotel-rating.component.js';
import HotelAddress from '../../hotel-address/hotel-address.component.js';
import { utils } from '../../../../../utils/utils.js';

let Template = (props) => {
  let moneyFormatted = utils.formatters.money.getFormatted;
  let currencyCodeFormatted = utils.converters.money.getCurrencySymbol;

  return (
    <div className="exon-hotel-card-item" onClick={() => props.onClick()}>
      <Container>
        <div className="exon-hotel-card-item-image">
          <img src={props.images[0].url} />
        </div>
        <div className="exon-hotel-card-item-price">
          <p> A partir de</p>
          <h4>
            {`${currencyCodeFormatted(
              props.fareInformationSummary.value.currencyCode,
            )} ${moneyFormatted(
              props.fareInformationSummary.value.value,
              props.fareInformationSummary.value.currencyCode,
            )}`}
          </h4>
          <p>
            {`${props.fareInformationSummary.days} ${
              props.fareInformationSummary.days != 1 ? 'diárias' : 'diária'
            }`}
          </p>
        </div>
        <h5 className="exon-hotel-card-item-name">{props.name}</h5>
        <HotelRating rating={props.rating} ratingDescription />
        <p className="exon-hotel-card-item-check-in">
          {`Check-in ${
            typeof props.fareInformationSummary.checkIn === 'object'
              ? utils.formatters.date.dateObjToString(
                  props.fareInformationSummary.checkIn,
                )
              : utils.converters.date.dateToString(
                  new Date(props.fareInformationSummary.checkIn),
                )
          }`}
        </p>

        <HotelAddress address={props.address} />
      </Container>
    </div>
  );
};

export default Template;
