import {
  approvalServiceRequest,
  approvalServiceRequestItem,
  cancelServiceRequest,
  cancelServiceRequestItem,
  countServiceRequests,
  fetchServiceRequest,
  fetchServiceRequestItem,
  findHotelLocations,
  findLocations,
  getAirlines,
  getLicenceFeaturesByContext,
  getServiceRequest,
  getServiceRequestHistory,
  getServiceRequestItem,
  issueAirTicket,
  listServiceRequests,
  previewServiceRequestItemNotification,
  previewServiceRequestNotification,
  retrieveExternalBooking,
  saveServiceRequest,
  saveServiceRequestItem,
  sendServiceRequestItemNotification,
  sendServiceRequestNotification,
  sendToAppsIntegration,
  submitServiceRequest,
  updateCostSplitServiceRequest,
  processBooking,
} from '../../api/connectOn/connectOn.service';
import {
  setLoader as setProfileDSLoader,
  dataSources as profileDataSources,
} from './profile.dataSources';
import {
  itensAnalysis,
  history,
} from '../../api/connectOn/mocks/serviceRequest.mocks';
import { retrieve as retrieveResolver } from '@legacy-utils/resolvers/retrieveExternalResolver';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver';
import { serviceRequestItemResolver } from '@legacy-utils/resolvers/serviceRequestItemResolver';
import { serviceRequestResolver } from '@legacy-utils/resolvers/serviceRequestResolver';
import { gritter } from '@legacy-utils/utils';
import { travelEnums } from '@legacy-utils/enums';
import { ILoader } from '@shared/loader';

const resolveBaseResponse = (response: any) => {
  if (response.errors && response.errors.length) {
    var concatenatedMessage = response.errors.map((e) => e.message).join(' | ');
    gritter.Warning(concatenatedMessage);
  }

  if (response.messages && response.messages.length) {
    var concatenatedMessage = response.messages
      .map((e) => e.message)
      .join(' | ');
    gritter.Warning(concatenatedMessage);
  }
};

let _loader: ILoader;
export const setLoader = (loader: ILoader) => {
  _loader = loader;
  setProfileDSLoader(loader);
};
const toggleModalLoading = (show: boolean) => {
  _loader.toggleLoading(show);
};

export const dataSources = {
  ...profileDataSources,
  serviceRequest: {
    load: async (loadOptions) => {
      const response = await getServiceRequest(loadOptions);
      resolveBaseResponse(response);

      if (response.serviceRequest) {
        response.serviceRequest =
          serviceRequestResolver.resolveServiceRequestResponse(
            response.serviceRequest,
          );
      }

      return response;
    },
    update: async (loadOptions) => {
      const response = await getServiceRequest(loadOptions);
      resolveBaseResponse(response);

      if (response.serviceRequest) {
        response.serviceRequest =
          serviceRequestResolver.resolveServiceRequestResponse(
            response.serviceRequest,
          );
      }

      return response;
    },
    fetch: async (loadOptions) => {
      const response = await fetchServiceRequest(loadOptions);
      resolveBaseResponse(response);

      if (response.serviceRequest) {
        response.serviceRequest =
          serviceRequestResolver.resolveServiceRequestResponse(
            response.serviceRequest,
          );
      }

      return response;
    },
    approve: (loadOptions, contextId) => {
      return approvalServiceRequest(loadOptions, contextId);
    },
    refuse: (loadOptions, contextId) => {
      return approvalServiceRequest(loadOptions, contextId);
    },
    cancel: async (loadOptions) => {
      const cancelResponse = await cancelServiceRequest(loadOptions);
      if (cancelResponse.successful) {
        const getResponse = await getServiceRequest({
          id: loadOptions.id,
          contextId: loadOptions.rootContextId || loadOptions.contextId,
        });
        getResponse.messages = [
          ...getResponse.messages,
          ...cancelResponse.messages,
        ];

        return getResponse;
      } else {
        return cancelResponse;
      }
    },
    costSplitUpdate: (loadOptions) => {
      return updateCostSplitServiceRequest(loadOptions);
    },
    sendToAuthorization: async (loadOptions) => {
      const submitResponse = await submitServiceRequest(loadOptions);
      if (submitResponse.successful) {
        const getResponse = await getServiceRequest(loadOptions);
        getResponse.messages = [
          ...getResponse.messages,
          ...submitResponse.messages,
        ];

        return getResponse;
      } else {
        return submitResponse;
      }
    },
    add: (loadOptions) => {
      return saveServiceRequest(loadOptions);
    },
    edit: (loadOptions) => {
      return saveServiceRequest(loadOptions);
    },
    previewNotification: (request, contextId) => {
      return previewServiceRequestNotification(request, contextId);
    },
    sendNotify: (request, contextId) => {
      return sendServiceRequestNotification(request, contextId);
    },

    events: {
      beforeSendNotify: () => {
        toggleModalLoading(true);
      },
      afterSendNotify: () => {
        toggleModalLoading(false);
      },
      beforePreviewNotification: () => {
        toggleModalLoading(true);
      },
      afterPreviewNotification: () => {
        toggleModalLoading(false);
      },
    },
  },
  serviceRequestItem: {
    load: async (loadOptions) => {
      const response = await getServiceRequestItem(loadOptions);
      if (response.serviceRequestItem) {
        response.serviceRequestItem =
          serviceRequestItemResolver.resolveServiceRequestItem(
            response.serviceRequestItem,
            loadOptions.contextId,
          );
      }

      return response;
    },
    fetch: async (loadOptions) => {
      const response = await fetchServiceRequestItem(loadOptions);
      resolveBaseResponse(response);

      if (response.serviceRequestItem) {
        response.serviceRequestItem =
          serviceRequestItemResolver.resolveServiceRequestItem(
            response.serviceRequestItem,
            loadOptions,
          );
      }

      return response;
    },
    approve: (loadOptions, contextId) => {
      return approvalServiceRequestItem(loadOptions, contextId);
    },
    refuse: (loadOptions, contextId) => {
      return approvalServiceRequestItem(loadOptions, contextId);
    },
    cancelBookingItem: (loadOptions) => {
      return cancelServiceRequestItem(loadOptions);
    },
    add: (loadOptions) => {
      return saveServiceRequestItem(loadOptions);
    },
    edit: (loadOptions) => {
      return saveServiceRequestItem(loadOptions);
    },
    previewNotification: (request, contextId) => {
      return previewServiceRequestItemNotification(request, contextId);
    },
    sendNotifyItem: (request, contextId) => {
      return sendServiceRequestItemNotification(request, contextId);
    },
    sendToAppsIntegration: (
      serviceRequestId,
      serviceRequestItemId,
      contextId,
    ) => {
      return sendToAppsIntegration({
        serviceRequestItemId,
        serviceRequestId,
        contextId,
      });
    },

    events: {
      beforeSendNotifyItem: () => {
        toggleModalLoading(true);
      },
      afterSendNotifyItem: () => {
        toggleModalLoading(false);
      },
      beforePreviewNotification: () => {
        toggleModalLoading(true);
      },
      afterPreviewNotification: () => {
        toggleModalLoading(false);
      },
      beforeSendToAppsIntegration: () => {
        toggleModalLoading(true);
      },
      afterSendToAppsIntegration: () => {
        toggleModalLoading(false);
      },
    },
  },
  itemsForAnalysis: {
    load: (loadOptions) => {
      return Promise.resolve(itensAnalysis);
    },
  },
  listTravelRequest: {
    //TODO: incluir metodo no dominio: serviceRequest
    load: (loadOptions) => {
      return listServiceRequests(loadOptions);
    },
  },
  counterTravelRequest: {
    //TODO: incluir metodo no dominio: serviceRequest
    load: (loadOptions) => {
      return countServiceRequests(loadOptions);
    },
  },
  externalReserve: {
    load: async (loadOptions) => {
      const response = await retrieveExternalBooking(loadOptions);
      response.checkoutData =
        retrieveResolver.fetchExternalReserveConvert(response);

      return response;
    },
  },
  serviceRequestHistory: {
    load: async (id, contextId, page, itemsPerPage) => {
      const response = await getServiceRequestHistory({
        serviceRequestId: id,
        page,
        pageSize: itemsPerPage,
        contextId,
      });
      const items = response.interactions.map((_) => {
        return {
          UserMetadata: `{"Email":"${_.actor.name}","IsAutomaticFlowAction":false}`,
          Body: `{"Content": {"Current": {"ServiceRequestHistoryUser": {"Name":"${_.actor.name}"}, "ServiceRequestHistoryDateTime": "${_.registeredAt}", "ServiceRequestHistoryDescription": "${_.description}"}}}`,
        };
      });
      return {
        Count: response.count,
        Page: page,
        PageSize: itemsPerPage,
        Items: items,
      };
    },
  },
  airSuppliers: {
    load: (loadOptions) => {
      const suppliersList = travelEnums.suppliers.air.filter(
        (supplier) => supplier.id != 'all',
      );

      const listItems = listItemsResolver.resolve(suppliersList, 'id', 'text');

      return Promise.resolve(listItems);
    },
  },
  hotelSuppliers: {
    load: (loadOptions) => {
      const suppliersList = travelEnums.suppliers.hotel.filter(
        (supplier) => supplier.id != 'all',
      );

      const listItems = listItemsResolver.resolve(suppliersList, 'id', 'text');

      return Promise.resolve(listItems);
    },
  },
  locations: {
    load: function (loadOptions) {
      return findLocations({
        keyword: loadOptions.keywords,
        types: loadOptions.types,
      });
    },
    events: {
      beforeLoad: function (loadOptions) {},
      afterLoad: function (items) {},
    },
  },
  hotelLocations: {
    load: function (loadOptions) {
      return findHotelLocations(loadOptions.keywords);
    },
  },
  airLines: {
    load: async (loadOptions) => {
      const response = await getAirlines();
      const resolvedItems = [];

      response.airlines.forEach((item) => {
        const resolvedItem = listItemsResolver.resolveItem(
          item,
          'code',
          'displayName',
        );
        resolvedItem.code = item.code;
        resolvedItem.imageUrl = item.imageUrl;

        resolvedItems.push(resolvedItem);
      });

      //resolvedItems = resolvedItems.sortBy(i => i.label);

      return resolvedItems;
    },
  },
  foidTypes: {
    load: async (loadOptions) => {
      const foidTypes = [{ id: '1', name: 'Cartão de Crédito' }];
      const listItem = listItemsResolver.resolve(foidTypes, 'id', 'name');
      return Promise.resolve(listItem);
    },
  },
  airTicket: {
    simulateReplacement: (
      ticketNumberToReplace,
      newTicketDetails,
      contextId,
    ) => {},
    issue: (issueData, contextId) => {
      return issueAirTicket(issueData, contextId);
    },

    //TODO GRAF 001: Remover airBooking
    load: async (loadOptions, contextId, airItem) => {
      const response = await getServiceRequestItem(loadOptions, contextId);
      resolveBaseResponse(response);

      if (response.serviceRequestItem) {
        response.serviceRequestItem =
          serviceRequestItemResolver.resolveAirTicketRequestItem(
            response.serviceRequestItem,
            airItem,
          );
      }

      return response;
    },

    cancel: (airTicketId, contextId) => {
      return cancelServiceRequestItem({ productId: airTicketId, contextId });
    },

    events: {
      //beforeSimulateIssue: () => { toggleModalLoading(true); },
      //afterSimulateIssue: () => { toggleModalLoading(false); },

      beforeIssue: () => {
        toggleModalLoading(true);
      },
      afterIssue: () => {
        toggleModalLoading(false);
      },

      beforeLoad: () => {
        toggleModalLoading(true);
      },
      afterLoad: () => {
        toggleModalLoading(false);
      },

      beforeCancel: () => {
        toggleModalLoading(true);
      },
      afterCancel: () => {
        toggleModalLoading(false);
      },

      //beforeGetTicket: () => { toggleModalLoading(true); },
      //afterGetTicket: () => { toggleModalLoading(false); },
    },
  },
  licenceFeatures: {
    load: async (contextId: string) => {
      return getLicenceFeaturesByContext(contextId);
    },
  },
  requester: {
    load: async (loadOptions) => {
      const requesters = await profileDataSources.requesters.load(
        loadOptions.keywords,
        loadOptions.contextId,
      );
      return listItemsResolver.resolve(requesters, 'RequesterId', 'Name');
    },
  },
  processBooking: {
    load: async (loadOptions) => {
      return processBooking(loadOptions);
    },
  },
};
