export function truncateTitle(input: string, length: number) {
  return input.length > length ? input.substring(0, length) + '...' : input;
}

export const removeAccentuation = (strData: string) => {
  return strData.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const normalize = (strData: string) => {
  if (!strData) return null;
  return removeAccentuation(strData).toLowerCase();
};
