'use strict';

import React from 'react';
import { componentUtil } from '../../../../utils/componentUtil.js';
import Template from './hotel-additional-information.template.js';

export default class HotelAdditionalInformationComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = { show: false };
  }

  render() {
    return (
      <Template {...this.props} show={this.state.show} events={this.events} />
    );
  }

  events = {
    onClick: () => this.setState({ show: !this.state.show }),
  };
}
