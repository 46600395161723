import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';

import { TableFilters } from './components/TableFilters';
import { TableHeader } from './components/TableHeader';
import { TablePagination } from './components/TablePagination';
import { useTravelDataTable } from './hooks/useDataTable';
import { TravelDataTableProps } from './types';

export function TravelDataTable<T>(props: TravelDataTableProps<T>) {
  const {
    filteredItems,
    pagination,
    search,
    isLoading,
    table,
    handleSearch,
    setPagination,
  } = useTravelDataTable(props);

  return (
    <Box width="100%">
      <Table.Root>
        <TableHeader
          header={props.header}
          headerEndContent={props.headerEndContent}
          filteredItemsCount={filteredItems.length}
        />
        <Table.Search
          label={props.search.placeholder || 'Buscar por um item...'}
          onChange={handleSearch}
          value={search}
        />
        <TableFilters {...props} />
        <Table.Grid.Root loading={isLoading}>
          <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table.rows.map((row) => (
            <Table.Grid.Row key={row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <TablePagination
          filteredItemsCount={filteredItems.length}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Table.Root>
    </Box>
  );
}
