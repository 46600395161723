import { utils } from '@legacy-utils/utils';

let AirTicketCardTemplate = ({
  ticket,
  ticketIndex,
  selectedTicketIndex,
  events,
}) => {
  let getFormattedDateObjFromDateObject =
    utils.formatters.date.getFormattedDateObjFromDateObject;

  return (
    <div
      className={
        'exon-air-ticket-card' +
        (ticketIndex == selectedTicketIndex
          ? ' exon-air-ticket-card_active'
          : '')
      }
    >
      <div
        className="exon-air-ticket-card-header"
        onClick={events.onSelectedTicketClicked}
      >
        <div className="exon-air-ticket-card-header-icon">
          <span className="exon-icon exon-icon-ticket-unfilled_disabled"></span>
        </div>
        <p>{ticket.number}</p>
        <div className="exon-air-ticket-card-header-icon">
          <span className="exon-icon exon-icon-expand-unfilled_disabled"></span>
        </div>
      </div>
      <div className="exon-air-ticket-card-body">
        <div className="exon-air-ticket-card-trip">
          <h6>{ticket.departure || '--'}</h6>
          <span
            className={
              'exon-icon-stops-card' + (ticket.stops == 0 ? ' hide' : '')
            }
          >
            <span className="exon-icon-stops-card-item"></span>
            <span className="exon-icon-stops-card-amount">{ticket.stops}</span>
          </span>
          <span
            className={
              (ticket.roundTrip
                ? 'exon-icon exon-icon-round-trip-unfilled_enabled'
                : 'exon-icon exon-icon-right-arrow-unfilled_enabled') +
              (ticket.stops !== 0 ? ' hide' : '')
            }
          ></span>
          <h6>{ticket.arrival || '--'}</h6>
        </div>
        <p className="exon-air-ticket-card-date">
          {ticket.startAt
            ? getFormattedDateObjFromDateObject(ticket.startAt).dateFormatted
            : '--'}
        </p>
        <p className="exon-air-ticket-card-traveler">
          {ticket.traveler.lastName + '/' + ticket.traveler.firstName}
        </p>
      </div>
      <div className="exon-air-ticket-card-footer">
        <div className="exon-air-ticket-card-status">
          <span>{ticket.status ? ticket.status.name : '--'}</span>
          {ticket.createdBy && ticket.createdBy.name == 'SystemUser' && (
            <span className="exon-icon exon-icon-lightning-filled_enabled-green exon-tooltip">
              <span className="tooltip">Gerado automaticamente</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AirTicketCardTemplate;
