import React from 'react';
import FieldTemplate from './field.template.js';
import { enums } from '../../utils/enums.js';

export default class FieldComponent extends React.Component {
  components = {
    richTextField: null,
  };

  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
    this.fieldChanged = this.fieldChanged.bind(this);

    this.state = {
      value: props.value || '',
      visible: this.props.visible == undefined ? true : this.props.visible,

      maxLength: this.props.maxLength,
      charsLeft: null,
    };

    this.components.richTextField = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      value: props.value,
      disabled: props.disabled,
      forceValidation: props.forceValidation,
      visible: props.visible == undefined ? true : props.visible,
    };
  }

  render() {
    let visible = this.state.visible;
    let forceValidation = this.state.forceValidation;

    if (visible) {
      switch (this.props.type) {
        case enums.fieldType.text:
        case enums.fieldType.email:
        case enums.fieldType.password:
          let type =
            this.props.type == enums.fieldType.email
              ? enums.components.textBoxType.email
              : this.props.type == enums.fieldType.password
              ? enums.components.textBoxType.password
              : enums.components.textBoxType.default;
          return (
            <FieldTemplate.textField
              additionalClass={this.props.additionalClass}
              label={this.props.label}
              type={type}
              value={this.state.value}
              required={this.props.required}
              readOnly={this.props.readOnly}
              maxLength={this.props.maxLength}
              charsLeft={this.state.charsLeft}
              forceValidation={forceValidation}
              disabled={this.state.disabled}
              placeholder={this.props.placeholder}
              onChange={this.onFieldChange}
              handleCharacterCount={this.handleCharacterCount.bind(this)}
            />
          );
        case enums.fieldType.textArea:
          return (
            <FieldTemplate.textAreaField
              label={this.props.label}
              value={this.state.value}
              required={this.props.required}
              maxLength={this.props.maxLength}
              charsLeft={this.state.charsLeft}
              forceValidation={this.props.forceValidation}
              disabled={this.state.disabled}
              placeholder={this.props.placeholder}
              onChange={this.onFieldChange}
              handleCharacterCount={this.handleCharacterCount.bind(this)}
            />
          );
        case enums.fieldType.richText:
          return (
            <FieldTemplate.richTextField
              additionalClass={this.props.additionalClass}
              label={this.props.label}
              value={this.state.value}
              required={this.props.required}
              forceValidation={forceValidation}
              fieldRef={this.components.richTextField}
              onChange={this.onFieldChange}
            />
          );
        case enums.fieldType.select:
          return (
            <FieldTemplate.selectField
              additionalClass={this.props.additionalClass}
              label={this.props.label}
              value={this.state.value}
              required={this.props.required}
              items={this.props.items}
              disabled={this.state.disabled}
              readOnly={this.props.readOnly}
              forceValidation={forceValidation}
              dataSource={this.props.dataSource}
              onChange={this.onFieldChange}
              isZeroValid={this.props.isZeroValid}
              isMultiple={this.props.isMultiple}
            />
          );
        case enums.fieldType.image:
          return (
            <FieldTemplate.imageField
              additionalClass={this.props.additionalClass}
              required={this.props.required}
              forceValidation={forceValidation}
              imageUrlValue={this.state.value}
              url={this.props.url}
              disabled={this.state.disabled}
              hotelId={this.props.hotelId}
              hotelImageDataSource={this.props.hotelImageDataSource}
              onChanged={this.fieldChanged}
            />
          );
        case enums.fieldType.date:
          return (
            <FieldTemplate.dateField
              additionalClass={this.props.additionalClass}
              label={this.props.label}
              forceValidation={forceValidation}
              value={this.state.value}
              disabled={this.state.disabled}
              required={this.props.required}
              onChange={this.onFieldChange}
            />
          );
        case enums.fieldType.number:
          return (
            <FieldTemplate.numberField
              additionalClass={this.props.additionalClass}
              label={this.props.label}
              value={this.state.value}
              forceValidation={forceValidation}
              required={this.props.required}
              disabled={this.state.disabled}
              onChange={this.onFieldChange}
              maxLength={this.props.maxLength}
              handleCharacterCount={this.handleCharacterCount.bind(this)}
              charsLeft={this.state.charsLeft}
            />
          );
        case enums.fieldType.locations:
          return (
            <FieldTemplate.locations
              additionalClass={this.props.additionalClass}
              label={this.props.label}
              value={this.state.value}
              refName={this.props.refName}
              locationType={this.props.locationType}
              placeholder={this.props.placeholder}
              options={this.props.options}
              dataSource={this.props.dataSource}
              forceValidation={forceValidation}
              required={this.props.required}
              disabled={this.state.disabled}
            />
          );
        case enums.fieldType.mask:
          return (
            <FieldTemplate.mask
              additionalClass={this.props.additionalClass}
              label={this.props.label}
              mask={this.props.mask}
              placeholder={this.props.placeholder}
              value={this.state.value}
              required={this.props.required}
              maxLength={this.props.maxLength}
              charsLeft={this.state.charsLeft}
              forceValidation={forceValidation}
              disabled={this.state.disabled}
              refName={this.props.refName}
              removeMask={this.props.removeMask}
              onChange={this.onFieldChange}
              handleCharacterCount={this.handleCharacterCount.bind(this)}
            />
          );
        case enums.fieldType.paymentSelector:
          return (
            <FieldTemplate.PaymentSelector
              additionalClass={this.props.additionalClass}
              label={this.props.label}
              value={this.state.value}
              paymentForms={this.props.paymentForms}
              refName={this.props.refName}
              onPaymentSelect={this.props.onPaymentSelect}
              required={this.props.required}
              forceValidation={forceValidation}
              disabled={this.state.disabled}
            />
          );
      }
    }
    return '';
  }

  componentDidMount() {
    const _this = this;
    if (this.props.type == enums.fieldType.richText) {
      //TODO: mudar para CKEditor5-react
      ClassicEditor.create(this.components.richTextField.current, {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'numberedList',
            'bulletedList',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
      })
        .then((editor) => {
          editor.data.set(_this.state.value);

          editor.model.document.on('change', () => {
            if (editor.model.document.differ.getChanges().length > 0) {
              const value = editor.data.get();
              _this.fieldChanged(value);
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  onFieldChange(ev) {
    this.fieldChanged(
      ev.target
        ? ev.target.value
        : typeof ev == 'object' && !this.props.completeItem
        ? ev.value
        : ev,
    );

    if (this.props.maxLength) this.handleCharacterCount(ev);
  }

  fieldChanged(value) {
    this.setState({ value: value });

    if (this.props.onFieldChange)
      this.props.onFieldChange(this.props.refName, value);
  }

  handleCharacterCount(event) {
    const charCount = event.target.value.length;
    const maxChar = this.state.maxLength;
    const charLength = maxChar - charCount;
    this.setState({ charsLeft: charLength });
  }
}
