'use strict';

import React from 'react';
import Template from './slider.template.js';

export default class SliderComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)

    this.state = { activeSlide: 0 };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      activeSlide:
        props.activeSlide != null || props.activeSlide != undefined
          ? props.activeSlide
          : state.activeSlide,
    };
  }

  render() {
    return (
      <Template
        props={this.props}
        args={{ activeSlide: this.state.activeSlide }}
        events={this.events}
      />
    );
  }

  events = {
    onPrevSlideClick: () => {
      let activeSlide =
        this.state.activeSlide - 1 < 0
          ? this.props.images.length - 1
          : this.state.activeSlide - 1;

      this.props.onClick && this.props.onClick(activeSlide, 'prev');

      this.setState({ activeSlide });
    },

    onNextSlideClick: () => {
      let activeSlide =
        this.state.activeSlide + 1 < this.props.images.length
          ? this.state.activeSlide + 1
          : 0;

      this.props.onClick && this.props.onClick(activeSlide, 'next');

      this.setState({ activeSlide });
    },
  };
}
