import { Icon } from '@components/Pills/styled';
import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import React from 'react';
import useSelectedCompanyStore from '../../../store/SelectedCompany.store';

const SelectedCompany: React.FC = () => {
  const { companySelected, setDisplayPopupAgency } = useSelectedCompanyStore();

  const handleAlterCompanyClick = () => {
    setDisplayPopupAgency(true);
  };

  return (
    <div>
      <LinkButton onClick={handleAlterCompanyClick} variant={'default'}>
        {!companySelected && <div>Selecione o cliente: </div>}
        <React.Fragment key=".0">
          {companySelected && <div>Cliente: </div>}
          {companySelected?.label} <Icon name="IconRefresh" />
        </React.Fragment>
      </LinkButton>
    </div>
  );
};

export default SelectedCompany;
