import { PaymentItem } from '@models/PaymentItem';
import { PaymentOptionCard } from '../PaymentOptionCard';
import * as SC from '../styled';
import { useEffect, useState } from 'react';

type PaymentOptionsProps = {
  paymentItems: PaymentItem[];
  onCheck: (item: PaymentItem) => void;
  price: number;
  isCurrentUserATravelerProfile: boolean;
};
export function PaymentOptions({
  paymentItems,
  onCheck,
  price,
  isCurrentUserATravelerProfile,
}: PaymentOptionsProps) {
  const [selectedValue, setSelectedValue] = useState('');
  const handleChange = (item: PaymentItem) => {
    onCheck(item);
    setSelectedValue(item.employee);
  };
  useEffect(() => {
    if (
      paymentItems?.length === 1 &&
      !selectedValue &&
      price <= paymentItems[0].balance
    )
      handleChange(paymentItems[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentItems]);
  return (
    <SC.FlexRow>
      {paymentItems?.map((item) => (
        <SC.CardContainer single={paymentItems.length === 1}>
          <PaymentOptionCard
            item={item}
            selectable={paymentItems.length > 1}
            checked={selectedValue === item.employee}
            onChange={() => handleChange(item)}
            price={price}
            key={item.employee}
            isCurrentUserATravelerProfile={isCurrentUserATravelerProfile}
          />
        </SC.CardContainer>
      ))}
    </SC.FlexRow>
  );
}
