export const listItemsResolver = {
  resolve: function (collection, valueProperty, labelProperty) {
    const items = [];
    if (collection && collection.length) {
      for (let i = 0; i < collection.length; i++) {
        let item = collection[i];
        items.push(this.resolveItem(item, valueProperty, labelProperty));
      }
    }
    return items;
  },

  resolveItem: function (item, valueProperty, labelProperty) {
    if (valueProperty == null) {
      return this.getItem(item, item);
    } else {
      return this.getItem(item[valueProperty], item[labelProperty]);
    }
  },

  resolveRange: function (begin, end) {
    const items = [];
    for (let i = begin; i <= end; i++) {
      items.push(this.getRangeItem(i));
    }
    return items;
  },

  getRangeItem: function (number) {
    return this.getItem(number, number);
  },

  resolveEnum: function (enumCollection, defaultLabel) {
    let items = [];

    if (defaultLabel) {
      items.push(this.getItem(0, defaultLabel));
    }

    for (var key in enumCollection) {
      let value = enumCollection[key];
      if (items.find((i) => i.label == key) == undefined) {
        items.push(this.getItem(isNaN(key) ? key : parseInt(key), value));
      }
    }

    return items;
  },

  resolveMapEnum: function (enumCollection, defaultLabel) {
    let items = [];

    for (let key of enumCollection) {
      items.push(this.getItem(key[0], key[1]));
    }

    return items;
  },

  getItem: function (value, label) {
    return {
      value: value,
      label: label,
    };
  },

  mockedListItems: [
    { value: '1', label: 'Item 1' },
    { value: '2', label: 'Item 2' },
  ],

  sort: (listItems) => {
    return listItems.sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase()
        ? -1
        : b.label.toLowerCase() > a.label.toLowerCase()
        ? 1
        : 0,
    );
  },
};
