import { Modal } from '@components/Modal';
interface ContactRequestedModalProps {
  userName: string;
  open: boolean;
  onCloseClick?: () => void;
  onCancelClick?: () => void;
  onActionClick?: () => void;
}
export function ContactRequestedModal({
  userName,
  open,
  onCloseClick,
  onCancelClick,
  onActionClick,
}: ContactRequestedModalProps) {
  return (
    <Modal.Root open={open}>
      <Modal.Header.Root
        onCloseClick={onCloseClick}
        onCancelClick={onCancelClick}
      >
        <Modal.Header.Icon icon="IconCheck" label=""></Modal.Header.Icon>
        <Modal.Header.Title textAlign="center">
          Agradecemos o seu interesse, <br />
          {userName}!
        </Modal.Header.Title>
        <Modal.Header.Subtitle textAlign="center">
          Em breve, um de nossos especialistas entrará em contato!
        </Modal.Header.Subtitle>
      </Modal.Header.Root>
      <Modal.Footer.Root>
        <Modal.Footer.ActionButton onActionClick={onActionClick}>
          Fechar
        </Modal.Footer.ActionButton>
      </Modal.Footer.Root>
    </Modal.Root>
  );
}
