import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';
import * as SCBase from '../../styled';

export default {
  ...SCBase,
  IconRounded: styled.div`
    border-radius: ${({ theme }) => theme.borders.radius.circular};
    background-color: ${({ theme }) => {
      return theme.colors.success90;
    }};
    width: 4.5rem;
    height: 4.5rem;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Title: styled(Typography).attrs({
    variant: 'body3',
    weight: 600,
    tag: `p`,
  })`
    color: ${({ theme }) => theme.colors.neutral[30]};
    line-height: normal !important;
    font-size: 16px;
    max-width: 27rem;
  `,
  Name: styled(Typography).attrs({
    variant: 'headline8',
    weight: 600,
    tag: `p`,
  })`
    color: ${({ theme }) => theme.colors.neutral[30]};
    line-height: normal !important;
    font-size: 16px;
    max-width: 27rem;
  `,
};
