import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';

export function TravelersQuantity() {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const updateTravelersQuantity = useTravelStore(
    (state) => state.updateTravelersQuantity,
  );
  const { enableTravelersLimit } = useTravelPermissions();

  // Gerar as opções de quantidade de viajantes com base na flag
  const travelersOptions = useMemo(() => {
    const maxTravelers = enableTravelersLimit ? 1 : 7;
    return Array.from({ length: maxTravelers }, (_, i) => ({
      label: `${i + 1}`,
      value: i + 1,
    }));
  }, [enableTravelersLimit]);

  // Garantir que o número de viajantes seja ajustado para 1 se a flag estiver ativa
  useEffect(() => {
    if (enableTravelersLimit) {
      setValue('number_travelers', 1); // Ajusta o valor inicial para 1
      updateTravelersQuantity(1);
    }
  }, [enableTravelersLimit, updateTravelersQuantity, setValue]);

  return (
    <Controller
      control={control}
      name="number_travelers"
      defaultValue={enableTravelersLimit ? 1 : undefined}
      render={({ field: { onChange, value } }) => {
        const fieldValue = value ?? (enableTravelersLimit ? 1 : undefined);

        return (
          <div>
            <SelectField
              onSelectChange={(event, selectedItem) => {
                if (selectedItem) {
                  const newValue = selectedItem.value;
                  onChange(newValue);
                  updateTravelersQuantity(newValue);
                }
              }}
              value={fieldValue}
              options={travelersOptions}
              label={'Quantidade de viajantes'}
              error={!!errors.number_travelers}
              helperText={
                <DisplayErrorFormValidation
                  errors={errors}
                  fieldName="number_travelers"
                />
              }
            />
          </div>
        );
      }}
    />
  );
}
