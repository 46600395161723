'use strict';

import React from 'react';
import AirProductTemplate from './air-product.template.js';

export default class AirProductComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AirProductTemplate.travel
        travel={this.props.travels}
        travelIndex={this.props.travelIndex}
        showDots={this.props.showDots ? this.props.showDots : false}
        actions={this.props.actions ? this.props.actions : null}
        onDeleteProductClick={this.props.onDeleteProductClick}
      />
    );
  }
}
