import React, { useRef } from 'react';
import { Icons, TextField } from '@flash-tecnologia/hros-web-ui-v2';

const Template = {
  main: ({
    parsedValue,
    lastEditError,
    isEditing,
    editAction,
    saveAction,
    changeValue,
    id,
    type,
    value,
  }) => {
    const className = 'table-cell-input';
    const ref = useRef(null);
    const imaskProps = (type) => {
      if (type === 'money') {
        return {
          mask: Number,
          scale: 2,
          thousandsSeparator: '.',
          padFractionalZeros: true,
          radix: ',',
          mapToRadix: ['.'],
        };
      }

      if (type === 'percentage') {
        return { mask: /^\d*,?\d{0,3} ?%?$/ };
      }

      return null;
    };
    const notValid = parsedValue === 0 || lastEditError;

    return (
      <div className={`exon-split-inline${notValid ? ' exon-error' : ''}`}>
        <span className="exon-edit-cell">
          <TextField
            disabled={!isEditing}
            className={className}
            onChange={changeValue}
            onBlur={saveAction}
            onKeyDown={(evt) => {
              evt.key === 'Enter' && ref.current.blur();
            }}
            value={value}
            hiddenLabel={true}
            aria-label={`input-${type}-${id}`}
            name={`input-${type}-${id}`}
            inputProps={{ className, ref }}
            InputProps={{ className }}
            imaskProps={imaskProps(type)}
          />
          {notValid && (
            <span className="exon-error-message">
              <Icons
                className="exon-error-icon"
                fill="transparent"
                name="IconAlertCircle"
                size={12}
              />
              &nbsp;
              <p>
                {parsedValue === 0
                  ? 'Valor deve ser maior que 0'
                  : 'Valor excedeu o total'}
              </p>
            </span>
          )}
        </span>
        <span className="exon-split-icon">
          {isEditing ? (
            <span title="Salvar">
              <Icons
                className="clickable"
                name="IconCheck"
                fill="transparent"
                onClick={saveAction}
              />
            </span>
          ) : (
            <span title="Editar">
              <Icons
                className="clickable"
                name="IconPencil"
                fill="transparent"
                onClick={() => {
                  editAction(ref);
                }}
              />
            </span>
          )}
        </span>
      </div>
    );
  },
};

export default Template;
