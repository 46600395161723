'use strict';

import React from 'react';
import Template from './slide.template.js';

export default class Slide extends React.Component {
  render() {
    return <Template {...this.props} />;
  }
}
