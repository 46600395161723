//import InputMask from 'react-input-mask';
import { enums } from '../../utils/enums';
import InputMask from 'react-input-mask';

var TextboxTemplate = {
  main: ({
    value,
    type,
    mask,
    placeholder,
    autoComplete,
    name,
    readOnly,
    disabled,
    validationResult,
    maxLength,
    min,
    max,
    rows,
    cols,
    showPassword,
    onShowPasswordClick,
    onChange,
    onBlur,
  }) => {
    let currentValue = value == undefined || value == null ? '' : value;
    let finalMask = mask;
    let finalPlaceholder = placeholder;

    //let displayAlert = currentValue.length == 0 && validate;

    switch (type) {
      case enums.components.textBoxType.cpf:
        finalMask = '999.999.999-99';
        finalPlaceholder = placeholder || '000.000.000-00';
        break;
      case enums.components.textBoxType.cnpj:
        finalMask = '99.999.999/9999-99';
        finalPlaceholder = placeholder || '00.000.000/0000-00';
        break;
      case enums.components.textBoxType.cep:
        finalMask = '99999-999';
        finalPlaceholder = placeholder || '00000-000';
        break;
      case enums.components.textBoxType.phone:
        finalMask = '(99)99999-9999';
        finalPlaceholder = placeholder || '(00)00000-0000';
        break;
      case enums.components.textBoxType.phoneGlobal:
        finalMask = '+99 (99) 99999-9999';
        finalPlaceholder = placeholder || '+00 (00) 00000-0000';
        break;
      case enums.components.textBoxType.date:
        finalMask = '99/99/9999';
        finalPlaceholder = placeholder || 'dd/mm/aaaa';
        break;
      case enums.components.textBoxType.email:
        finalPlaceholder = placeholder || 'email@empresa.com.br';
        break;
      case enums.components.textBoxType.card:
        finalMask = '9999.9999.9999.9999';
        finalPlaceholder = placeholder || '0000.0000.0000.0000';
        break;
    }

    //Atributos comuns do textbox
    let textBoxAttrs = {
      value: currentValue,
      placeholder: finalPlaceholder,
      autoComplete,
      name,
      readOnly,
      disabled,

      onChange,
      onBlur,
    };

    let additionalClassName = '';
    let inputComponent;

    switch (type) {
      case enums.components.textBoxType.mask:
      case enums.components.textBoxType.cpf:
      case enums.components.textBoxType.cnpj:
      case enums.components.textBoxType.cep:
      case enums.components.textBoxType.phone:
      case enums.components.textBoxType.phoneGlobal:
      case enums.components.textBoxType.date:
      case enums.components.textBoxType.numberMask:
        textBoxAttrs = { ...textBoxAttrs, mask: finalMask };
        inputComponent = <TextboxTemplate.inputMask {...textBoxAttrs} />;
        break;
      case enums.components.textBoxType.number:
        textBoxAttrs = {
          ...textBoxAttrs,
          inputType: 'number',
          maxLength,
          min,
          max,
        };
        inputComponent = <TextboxTemplate.inputDefault {...textBoxAttrs} />;
        break;
      case enums.components.textBoxType.password:
        textBoxAttrs = {
          ...textBoxAttrs,
          maxLength,
          showPassword,
          onShowPasswordClick,
        };
        inputComponent = <TextboxTemplate.inputPassword {...textBoxAttrs} />;
        break;
      case enums.components.textBoxType.textarea:
        additionalClassName = 'exon-textbox-textarea';

        textBoxAttrs = { ...textBoxAttrs, rows, cols };
        inputComponent = <TextboxTemplate.textArea {...textBoxAttrs} />;
        break;
      default:
        textBoxAttrs = { ...textBoxAttrs, inputType: 'text', maxLength };
        inputComponent = <TextboxTemplate.inputDefault {...textBoxAttrs} />;
        break;
    }

    return (
      <div
        className={
          additionalClassName +
          (!validationResult.isValid ? ' exon-alert-border' : '') +
          `${readOnly ? 'readonly' : ''}`
        }
      >
        {inputComponent}
        {!validationResult.isValid && validationResult.message && (
          <TextboxTemplate.alertField message={validationResult.message} />
        )}
      </div>
    );
  },

  inputMask: ({ value, mask, placeholder, disabled, onChange, onBlur }) => {
    return (
      <InputMask
        value={value}
        className="form-control"
        disabled={disabled}
        mask={mask}
        maskChar={null}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  },

  inputDefault: ({
    value,
    inputType,
    autoComplete,
    placeholder,
    maxLength,
    min,
    max,
    readOnly,
    disabled,
    onChange,
    onBlur,
  }) => (
    <input
      className="form-control exon-textbox-input-text-or-number"
      readOnly={readOnly}
      disabled={disabled}
      value={value}
      type={inputType}
      placeholder={placeholder}
      maxLength={maxLength}
      min={min}
      max={max}
      autoComplete={autoComplete}
      onChange={onChange}
      onBlur={onBlur}
    />
  ),
  textArea: ({
    value,
    placeholder,
    rows,
    cols,
    readOnly,
    disabled,
    onChange,
  }) => (
    <textarea
      className="exon-textbox-textarea"
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      rows={rows}
      cols={cols}
    />
  ),

  inputPassword: ({
    value,
    showPassword,
    placeholder,
    maxLength,
    min,
    max,
    autoComplete,
    readOnly,
    disabled,
    onShowPasswordClick,
    onChange,
    onBlur,
  }) => (
    <div className={'exon-textbox-password'}>
      <input
        className="form-control exon-textbox-input-text-or-number"
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete={autoComplete}
        maxLength={maxLength}
        min={min}
        max={max}
        onBlur={onBlur}
      />
      <i
        className={
          showPassword ? 'ace-icon fa fa-eye-slash' : 'ace-icon fa fa-eye'
        }
        onClick={() => onShowPasswordClick(showPassword)}
      ></i>
    </div>
  ),

  alertField: ({ message }) => (
    <span className="exon-msg-alert-field">{message}</span>
  ),
};

export default TextboxTemplate;
