type EType = 'error' | 'success' | 'warning';

interface dispatchToastParams {
  content: any;
  type: EType;
  title?: string;
  description?: any;
}

export function dispatchToast(detail: dispatchToastParams) {
  dispatchEvent(new CustomEvent('showToast', { detail }));
}
