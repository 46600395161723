import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

type UploadOptions = {
  url: string;
  file: File;
};

export function useUploadFile() {
  return useMutation({
    mutationKey: ['upload-file'],
    mutationFn: (options: UploadOptions) => {
      return axios.put(options.url, options.file, {
        headers: {
          'Content-Type': options.file.type,
        },
      });
    },
  });
}
