import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const IconApprovalContainer = styled.div`
  max-width: 100%;
  text-align: center;
`;
export const Text = styled(Typography)`
  color: ${(props) => props.theme.colors.neutral[50]};
  text-align: center;
`;
