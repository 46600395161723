import Popup from '../../../popup/popup.component.js';
import Field from '../../../field/field.component.js';
import Form from '../../../common/form/form.component.js';
import { enums } from '@legacy-utils/enums';

var Template = {
  main: ({
    segment,
    canEdit,
    events,

    //Edição e adição de itens
    form,
  }) => {
    let empty = '-';

    return (
      <div className="exon-booking-hotel-segments">
        <table className="exon-table-line" cellSpacing="0">
          <thead>
            <tr>
              <th>Nome do Hotel</th>
              <th>Cidade</th>
              <th>Endereço</th>
              <th>Check-in</th>
              <th>Check-out</th>
              <th>Localizador</th>
              <th>Voucher</th>
              <th>Data do voucher</th>
              {/*<th>Tipo de venda</th>*/}
              {/*<th>Loc. Passivo</th>*/}
              <th>Status do Fornecedor</th>
              {canEdit && (
                <th className="exon-table-actions-header">
                  {!segment && (
                    <span
                      className="exon-icon exon-icon-button exon-icon-add-unfilled_enabled"
                      onClick={() =>
                        form.events.popup.onOpenFormPopupClicked(
                          enums.actionsForm.add,
                        )
                      }
                    ></span>
                  )}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {segment && (
              <tr>
                <td className={!segment.name ? 'exon-text-center' : ''}>
                  {segment.name ? segment.name : empty}
                </td>
                <td className={!segment.city ? 'exon-text-center' : ''}>
                  {segment.city ? segment.city : empty}
                </td>
                <td className={!segment.address ? 'exon-text-center' : ''}>
                  {segment.address ? segment.address : empty}
                </td>
                <td className={!segment.checkIn ? 'exon-text-center' : ''}>
                  {segment.checkIn ? segment.checkIn : empty}
                </td>
                <td className={!segment.checkOut ? 'exon-text-center' : ''}>
                  {segment.checkOut ? segment.checkOut : empty}
                </td>
                <td className={!segment.locator ? 'exon-text-center' : ''}>
                  {segment.locator && !segment.locatorToBeConfirmed
                    ? segment.locator
                    : segment.locatorToBeConfirmed
                    ? 'A confirmar'
                    : empty}
                </td>
                <td className={!segment.voucher ? 'exon-text-center' : ''}>
                  {segment.voucher && !segment.locatorToBeConfirmed
                    ? segment.voucher
                    : segment.locatorToBeConfirmed
                    ? 'A confirmar'
                    : empty}
                </td>
                <td className={!segment.voucherDate ? 'exon-text-center' : ''}>
                  {segment.voucherDate ? segment.voucherDate : empty}
                </td>
                <td
                  className={
                    segment.cancellation == null ? 'exon-text-center' : ''
                  }
                >
                  {segment.bookingStatus != null
                    ? segment.bookingStatus
                    : empty}
                </td>

                {canEdit && (
                  <td className="exon-table-action-item">
                    <span
                      className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
                      onClick={() =>
                        form.events.popup.onOpenFormPopupClicked(
                          enums.actionsForm.edit,
                        )
                      }
                    ></span>
                    <span
                      className="exon-icon exon-icon-button exon-icon-dump-unfilled_enabled"
                      onClick={events.onDeleteSegmentClicked}
                    ></span>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>

        {form.show && (
          <div className="exon-box-popup-form exon-box-popup-form-rv">
            <Template.form data={form} events={form.events.popup} />
          </div>
        )}
      </div>
    );
  },

  form: ({ data, events }) => {
    return (
      <Popup
        okButtonLabel={'Salvar'}
        cancelButtonLabel={'Cancelar'}
        title={'Segmentos Hotel'}
        cancelButtonClick={events.onCloseFormPopupClicked}
        exonStyle={true}
        okButtonClick={events.onConfirmPopupClicked}
      >
        <Form>
          {Object.keys(data.fields).map((refName, index) => {
            let field = data.fields[refName];
            return (
              <div
                key={index}
                className={`exon-box-form-field ${
                  field.additionalClass ? field.additionalClass : ''
                }`}
              >
                <Field
                  type={field.type}
                  label={field.label}
                  value={field.value}
                  items={field.items}
                  refName={field.refName}
                  mask={field.mask}
                  completeItem={field.completeItem}
                  locationType={field.locationType}
                  placeholder={field.placeholder}
                  forceValidation={data.forceValidation}
                  disabled={field.disabled}
                  required={field.required}
                  options={field.options}
                  isZeroValid={field.isZeroValid}
                  dataSource={field.dataSource}
                  onFieldChange={field.onFieldChange}
                  removeMask={field.removeMask}
                />

                {(index == 0 || index == 1) && (
                  <div>
                    <hr />
                  </div>
                )}
              </div>
            );
          })}
        </Form>
      </Popup>
    );
  },
};

export default Template;
