import Select from '../../../../../select/select.component.js';
import { enums } from '@legacy-utils/enums.js';

export const AdditionalFieldRoom = ({
  roomOptions,
  hotelsOptions,
  travelerNumber,
  validationFields,
  travelerType,
}) => {
  roomOptions.options.events.onSelected = (selectedItem) => {
    roomOptions.options.events.onSelectedRoom(
      selectedItem,
      travelerNumber,
      travelerType,
    );
  };

  let roomListOptions = roomOptions.options.items.filter((room) => {
    if (travelerType == enums.travelerType.Adt && room.adultCount) return room;
    if (travelerType == enums.travelerType.Chd && room.childCount) return room;
  });

  if (roomListOptions.length == 1 && roomOptions.data == 0)
    roomOptions.data = roomListOptions[0];

  return (
    <div className="exon-box-fields-row">
      <div className="exon-field exon-box-roow-list-traveler exon-box-list-ontop">
        <label className="exon-label-field">Hotel</label>
        <Select
          data={
            hotelsOptions.options.items.length == 1
              ? hotelsOptions.options.items[0]
              : hotelsOptions.data
          }
          placeholder={hotelsOptions.placeholder}
          required={true}
          externalOptions={hotelsOptions.options}
          disabled={
            hotelsOptions.options.items.length > 0 &&
            hotelsOptions.options.items.length == 1
          }
          forceValidation={validationFields}
        />
      </div>

      <div className="exon-field exon-box-roow-list-traveler exon-box-list-ontop">
        <label className="exon-label-field">Quarto</label>
        <Select
          data={roomOptions.data}
          placeholder={roomOptions.placeholder}
          required={true}
          externalOptions={roomOptions.options}
          disabled={roomListOptions.length > 0 && roomListOptions.length == 1}
          forceValidation={validationFields}
        />
      </div>
    </div>
  );
};
