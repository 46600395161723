'use strict';
import React from 'react';
import Template from './form.template.js';

export default class FormComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Template
        children={this.props.children}
        additionalClass={this.props.additionalClass}
      />
    );
  }
}
