import HotelRating from '../hotel-rating/hotel-rating.component.js';
import HotelProvider from '../hotel-provider/hotel-provider.component.js';
import Icon from '../../../common/icon/icon.component.js';
import { formatterUtil } from '../../../../utils/formatter.js';

let Template = (props) => {
  let hotelNameCharacterLimit = props.hotelNameCharacterLimit || 60;

  return (
    <div
      className={`exon-hotel-base-infos ${
        props.onClick && !props.isHotelOffline
          ? 'exon-hotel-base-infos-link'
          : ''
      }`}
      onClick={props.onClick && !props.isHotelOffline ? props.onClick : null}
    >
      <div className="exon-hotel-base-infos-name">
        <h4 className="exon-base-infos-hotel-name">
          {formatterUtil.stringReduce(props.name, hotelNameCharacterLimit)}
        </h4>

        {props.onClick && !props.isHotelOffline && (
          <Icon icon={'exon-icon-link-unfilled_enabled'} />
        )}

        {props.commercialAgreement && (
          <Icon icon={'exon-icon-star-filled_enabled'} />
        )}

        {props.name.length > hotelNameCharacterLimit && (
          <div className="exon-hotel-base-infos-name-tooltip">
            <h4>{props.name}</h4>

            {props.commercialAgreement && (
              <Icon icon={'exon-icon-star-filled_enabled'} />
            )}
          </div>
        )}
      </div>
      <div>
        <div className="exon-hotel-base-infos-box">
          <HotelRating rating={props.rating} ratingDescription />

          {(props.connectionSource || props.sourceSupplier) && (
            <HotelProvider
              connectionSource={
                props.connectionSource &&
                typeof props.connectionSource === 'object'
                  ? Object.keys(props.connectionSource)[0]
                  : props.connectionSource
              }
              sourceSupplier={
                props.sourceSupplier && typeof props.sourceSupplier === 'object'
                  ? Object.keys(props.sourceSupplier)[0]
                  : props.sourceSupplier
              }
            />
          )}

          {props.chain && props.chain.name && (
            <p className="exon-hotel-base-infos-chain">
              {`| Rede ${props.chain.name}`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Template;
