import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 15rem;
  background-color: ${(props) => props.theme.colors.neutral[100]};
  border-radius: ${(props) => props.theme.borders.radius.s};
  display: flex;
  padding: ${(props) => props.theme.spacings.xs};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const Content = styled.div`
  display: grid;
  row-gap: ${(props) => props.theme.spacings.xs2};
  align-items: center;
`;

export const Icon = styled(Icons)`
  color: ${(props) => props.theme.colors.secondary[50]};
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme.colors.secondary[50]};
  font-weight: 700 !important;
  text-align: left;
  font-size: 20px !important;
`;
export const Description = styled.p`
  font-size: 14px !important;
  color: gray;
`;
export const ContainerIcon = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
`;
