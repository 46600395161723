import { enums } from '../enums';

export const hotelDetails = {
  getAmenitie: (type) => {
    switch (enums.hotelAmenitiesType[type]) {
      case 'meal':
        return 'Refeição por quarto';
        break;
      case 'cashback':
        return 'Reembolsável';
        break;
      case 'swimming':
        return 'Piscina';
        break;
      case 'parking':
        return 'Estacionamento';
        break;
      case 'weight':
        return 'Academia';
        break;
      case 'accessibility':
        return 'Acessibilidade';
        break;
      case 'restaurants':
        return 'Restaurantes';
        break;
      case 'noSmokers':
        return 'Não fumantes';
        break;
      case 'roomService':
        return 'Serviço de quarto';
        break;
      case 'pub':
        return 'Bar';
        break;
      case 'wifi':
        return 'Wifi';
        break;
      case 'crystal':
        return 'Ar-condicionado';
        break;
      case 'services':
        return 'Serviços';
        break;
      case 'pets':
        return 'Animais de estimação';
        break;
      default:
        return 'Outros';
    }
  },
};
