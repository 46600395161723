let ToggleTemplate = {
  main: ({ status, placeholder, setStatus, className }) => (
    <div className={`exon-toggle ${className ? className : ''}`}>
      <label className="switch">
        <input checked={status} onChange={setStatus} type="checkbox" />
        <span className="slider round"></span>
      </label>
      <label className="exon-toggle__placeholder" onClick={setStatus}>
        {placeholder}
      </label>
    </div>
  ),
};

export default ToggleTemplate;
