import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { getVehicleAdditionalsText } from '@legacy-utils/resolvers/vehicleResolver.ts';
import { useFeeTariffRatesStore } from '../../../../../store/FeeTariffRates.store';
import { FeeTariffRates } from '@components/RequestTravel/FeeTariffRates';

const Template = {
  main: ({ booking, canEdit, userProfile, events }) => {
    const empty = '--';
    const moneyFormatted = utils.formatters.money.getFormatted;
    const isMarkupVisible =
      userProfile?.company?.isAgency || userProfile?.travelProfile === 4;

    const totalTaxes = {
      value:
        (booking.vehicle.values?.dailyTax?.value || 0 * booking.vehicle.days) +
          booking.vehicle.values?.serviceValue?.value || 0,
      currencyCode:
        booking.vehicle.values?.dailyTax?.currencyCode ||
        booking.vehicle.values?.serviceValue?.currencyCode ||
        booking.vehicle.values?.dailyFare?.currencyCode ||
        'BRL',
    };

    if (!isMarkupVisible)
      totalTaxes.value += booking.vehicle.values?.markup?.value || 0;

    const hasFaresAndFees = !!booking.vehicle.values;
    const toggleModal = useFeeTariffRatesStore((state) => state.toggleModal);
    return (
      <div className="exon-booking-vehicle-fares-and-fees">
        <table className="exon-table-line" cellSpacing="0">
          <thead>
            <tr>
              <th>Sistema</th>
              <th>Categoria</th>
              <th>Adicionais</th>
              <th>Diárias</th>
              <th className="exon-text-center">Detalhes das tarifas</th>
              {canEdit && (
                <th className="exon-table-actions-header">
                  {!hasFaresAndFees && (
                    <span
                      className="exon-icon exon-icon-button exon-icon-add-unfilled_enabled"
                      onClick={() => events.onEditClick(enums.actionsForm.add)}
                    ></span>
                  )}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {hasFaresAndFees && (
              <tr>
                <td>{booking.sourceTypeName || empty}</td>
                <td>{booking.vehicle.category || empty}</td>
                <td>{getVehicleAdditionalsText(booking.vehicle) || empty}</td>
                <td>{booking.vehicle.days || empty}</td>
                <td>
                  <div className="box-styleless-table">
                    <table cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Valor da diária</th>
                          <th>Total dos adicionais</th>
                          <th>Taxas totais</th>
                          {isMarkupVisible && <th>Markup</th>}
                          <th>Valor total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {booking.vehicle.values.dailyFare
                              ? `${
                                  booking.vehicle.values.dailyFare.currencyCode
                                } ${moneyFormatted(
                                  booking.vehicle.values.dailyFare.value,
                                  booking.vehicle.values.dailyFare.currencyCode,
                                )}`
                              : empty}
                          </td>
                          <td>
                            {booking.vehicle.values.serviceValue
                              ? `${
                                  booking.vehicle.values.serviceValue
                                    .currencyCode
                                } ${moneyFormatted(
                                  booking.vehicle.values.serviceValue.value,
                                  booking.vehicle.values.serviceValue
                                    .currencyCode,
                                )}`
                              : empty}
                          </td>
                          <td>
                            {totalTaxes
                              ? `${totalTaxes.currencyCode} ${moneyFormatted(
                                  totalTaxes.value,
                                  totalTaxes.currencyCode,
                                )}`
                              : empty}
                          </td>

                          {isMarkupVisible && (
                            <td>
                              {booking.vehicle.values.markup
                                ? `${
                                    booking.vehicle.values.markup.currencyCode
                                  } ${moneyFormatted(
                                    booking.vehicle.values.markup.value,
                                    booking.vehicle.values.markup.currencyCode,
                                  )}`
                                : empty}
                            </td>
                          )}
                          <td>
                            {booking.vehicle.values.total
                              ? `${
                                  booking.vehicle.values.total.currencyCode
                                } ${moneyFormatted(
                                  booking.vehicle.values.total.value,
                                  booking.vehicle.values.total.currencyCode,
                                )}`
                              : empty}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>

                {canEdit && (
                  <>
                    <td className="exon-table-action-item exon-text-center">
                      <span
                        className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
                        onClick={() => {
                          toggleModal();
                        }}
                      ></span>
                      <span
                        className="exon-icon exon-icon-button exon-icon-dump-unfilled_enabled"
                        onClick={() => events.onDeleteClick()}
                      ></span>
                    </td>
                    <FeeTariffRates />
                  </>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  },
};
export default Template;
