import Select from '../../../../../select/select.component.js';

let Template = ({ args, events }) => {
  return (
    <div className="exon-filter-rooms">
      <Select {...args.bedTypeSelectData} />

      <Select {...args.mealTypeSelectData} />

      <button
        className="exon-button exon-button-primary exon-button-unfilled"
        onClick={() => events.onFilterClick()}
      >
        Filtrar
      </button>
    </div>
  );
};

export default Template;
