import { SavePolicyRequest } from 'bff/src/routers/policies';
import { ReactNode } from 'react';

export enum PolicyRegisterFormStep {
  BASIC_INFORMATION = 0,
  POLICY_PROPERTIES = 1,
  RESUME = 2,
}

export type PolicyRegisterForm = SavePolicyRequest & {
  document: {
    file?: File;
  };
};

export type PolicyRegisterBasicInformation = Pick<
  PolicyRegisterForm,
  'description' | 'name' | 'document'
>;

export type PolicyRegisterProperties = Pick<PolicyRegisterForm, 'conditions'>;

export type PolicyRuleConfigurationField = (
  | {
      type: 'time' | 'currency' | 'text' | 'number' | 'local' | 'toggle';
    }
  | {
      type: 'options';
      options: Array<{
        label: string;
        value: string | boolean;
        caption?: string;
        icon?: ReactNode;
      }>;
    }
) & {
  name: string;
  key: keyof PolicyRegisterForm['rules'][number]['parameters'][number];
  helperText: string;
};

export type PolicyRuleConfiguration = {
  code: string;
  description: string;
  fields: Array<PolicyRuleConfigurationField>;
};
