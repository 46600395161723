'use strict';

import React from 'react';
import Template from './link.template.js';

export default class LinkComponent extends React.Component {
  render() {
    return <Template {...this.props} />;
  }
}
