import BookingProductSummary from '../booking-product-summary/booking-product-summary.component.js';

let BookingProductSummaryListTemplate = ({
  bookings,
  requestId,
  userTravelProfile,
  events,
  justificationEvents,
}) => {
  return (
    <div className="exon-booking-product-summary-list">
      {bookings.map((booking, index) => {
        return (
          <BookingProductSummary
            key={index}
            booking={booking}
            userTravelProfile={userTravelProfile}
            events={events}
            justificationEvents={justificationEvents}
          />
        );
      })}
    </div>
  );
};

export default BookingProductSummaryListTemplate;
