'use strict';

import React from 'react';
import BookingBoxTemplate from './booking-box.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class BookingBoxComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <BookingBoxTemplate
          title={this.props.title ? this.props.title : null}
          subtitle={this.props.subtitle}
          children={this.props.children ? this.props.children : null}
          className={this.props.className}
        />
      </ErrorBoundary>
    );
  }
}
