import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

type FlexRowProps = {
  disabled?: boolean;
};
export const FlexRow = styled.div<FlexRowProps>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: initial;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
export const FlexItem = styled.div`
  flex: auto;
`;
export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
type BoxProps = {
  checked?: boolean;
  single?: boolean;
  disabled?: boolean;
};

export const Box = styled.div<BoxProps>`
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.colors.primary : theme.colors.neutral[80]};
  padding: 0.5rem 0.5rem;
  border-radius: ${({ theme }) => theme.borders.radius.l};
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: initial;
  align-items: center;
  max-height: 6rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral[95] : 'transparent'};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  & input[type='radio'].PrivateSwitchBase-input {
    opacity: 0 !important;
  }
`;

export const CardTitleContainer = styled(FlexRow)`
  max-width: 40rem;
  min-width: 18rem;
`;

export const CardContainer = styled.div<{ single?: boolean }>`
  flex: 1;
  flex-grow: inherit;
  flex-basis: auto;
  width: ${({ single }) => (!!single ? 'none' : '38.5rem')};
`;

export const PaymentMethodTitle = styled(Typography).attrs({
  weight: 600,
  variant: 'body3',
})`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 16px;
  font-weight: '600';
`;
