import { NotificationCard, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InferType } from 'yup';
import { schema } from '../../index';
import CriteriaFields from '@components/CriteriaFields';

type FormValues = InferType<typeof schema>;

const ApprovalCriteriaForm: React.FC = () => {
  const { watch, control } = useFormContext<FormValues>();

  const [showPolicyAlert, setShowPolicyAlert] = useState(false);
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.startsWith('criteria') && name?.endsWith('field')) {
        const index = name.split('.')[1];
        if (value.criteria?.[index]?.field === 'policy_result') {
          setShowPolicyAlert(true);
        } else {
          setShowPolicyAlert(false);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box p={2} borderRadius={4} border={1} borderColor={'#EBE6E9'}>
      <Typography
        variant={'headline7'}
        color={'primary'}
        variantColor={'#FE2B8F'}
      >
        Critérios de aprovação
      </Typography>
      <Typography variant="body4">
        Acionar fluxo de aprovação quando as seguintes condições forem
        abrangidas.
      </Typography>
      {showPolicyAlert && (
        <NotificationCard.Root type={'error'} variant={'outlined'}>
          <NotificationCard.WrapperIconWithText>
            <NotificationCard.Icon iconName={'IconAlertTriangle'} />
            <NotificationCard.WrapperTexts>
              <NotificationCard.Subtitle>
                A definição do <strong>Resultado da Política</strong> será
                respeitada quando uma ou mais politicas estiverem vinculadas à
                este fluxo de aprovação.
              </NotificationCard.Subtitle>
            </NotificationCard.WrapperTexts>
          </NotificationCard.WrapperIconWithText>
        </NotificationCard.Root>
      )}

      <CriteriaFields
        debug
        control={control}
        name="criteria"
        fields={{
          branch: { value: 'branch' },
          area: { value: 'area' },
          position: { value: 'position' },
          costCenterResponsible: { value: 'cost_center_responsible' },
          travelType: { value: 'travel_type' },
          policyResult: { value: 'policy_result' },
          totalRvValue: { value: 'total_rv_value' },
        }}
      />
    </Box>
  );
};

export default ApprovalCriteriaForm;
