import Checkbox from '../../common/checkbox/checkbox.component.js';

var Template = {
  main: ({ filters, historic, events }) => {
    return (
      <div className="exon-actions-request-history">
        <Template.filters filters={filters} events={events} />

        <div className="exon-actions-request-history-itens-box"></div>
      </div>
    );
  },

  filters: ({ filters, events }) => {
    return (
      <div className="exon-actions-request-history-filters">
        <h6>Filtros:</h6>
        <div className="exon-actions-request-history-filters-itens">
          <Checkbox
            checked={filters.fieldModify}
            onChange={events.onFieldModifyChanged}
            label={'Retrieve'}
          />
          <Checkbox
            checked={filters.sendEmail}
            onChange={events.onSendEmailChanged}
            label={'Notificação'}
          />
          <Checkbox
            checked={filters.message}
            onChange={events.onMessageChanged}
            label={'Transição de status'}
          />
          <Checkbox
            checked={filters.approvers}
            onChange={events.onApproversChanged}
            label={'Fluxo de aprovação'}
          />
          <Checkbox
            checked={filters.service}
            onChange={events.onServiceChanged}
            label={'Demais Opções'}
          />
        </div>
        <button
          className="exon-button exon-button-primary"
          onClick={events.onApplyFiltersClicked}
        >
          Aplicar Filtros
        </button>
      </div>
    );
  },

  historic: ({ data }) => {
    return (
      <div className="exon-actions-request-history-item">
        <div className="exon-actions-request-history-item-header">
          <h6>
            <span>11/10/2021 22:56:56 -</span>
            Processamento de Ordem de serviço
            <span>- Fluxo automático</span>
          </h6>
          <p>
            <span>Responsável:</span>
            Maria Jose da Costa Dantas Brito
            <span>(187.48.163.247)</span>
          </p>
        </div>
        <div className="exon-actions-request-history-item-body">
          <table className="exon-table-line" cellSpacing="0">
            <thead>
              <tr>
                <th>Campo</th>
                <th>Valor Anterior</th>
                <th>Novo Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Status</td>
                <td>Em frila de espera</td>
                <td>Aguardando Pagamento</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  },
};

export default Template;
