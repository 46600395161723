import { getVehicleAdditionalsText } from "../../../../utils/resolvers/vehicleResolver";
import { utils } from '../../../../utils/utils.js';

const Template = ({ booking }) => {
  const empty = '--';
  const expirationDate = booking.expiration
      ? utils.formatters.date.getFormattedDateObjFromDateObject(booking.expiration.expireAt)
      : null;

  return (
    <div className="exon-vehicle-booking-infos">
      <div className="box-styleless-table">
        <table className="exon-styleless-table">
          <thead>
            <tr>
              <th>Localizador</th>
              <th>Data de Expiração</th>
              <th>Locadora</th>
              <th>Categoria</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {booking.bookingLocator
                  ? `${booking.bookingLocator} (${booking.bookingStatus})`
                  : '(booking pending)'}
              </td>
              <td>
                {expirationDate?.dateTimeFormatted || "A confirmar"}
              </td>
              <td>
                {booking.vehicle?.rentalCompany || empty}
              </td>
              <td>
                {booking.vehicle?.category} <br />
                {getVehicleAdditionalsText(booking.vehicle) || empty}
              </td>
              <td>
                {booking.vehicle?.type || empty }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Template;
