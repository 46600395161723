let Template = (props) => {
  return (
    <li
      className={`exon-carroussel-item exon-carousel-wrapper-item ${
        props.index
      } ${
        props.imageIndexActive == props.image.index && !props.moveClass
          ? 'exon-carousel-wrapper-item-active'
          : ''
      } ${props.className ? props.className : ''}`}
      onClick={() => props.onClick()}
    >
      <img src={props.image ? props.image.url : ''} />
    </li>
  );
};
export default Template;
