import { getFromLS } from '@flash-tecnologia/hros-web-utility';

interface UseAuthData {
  employeeId: string;
  companyId: string;
  isAdmin: boolean;
}

export function useAuthData(): UseAuthData {
  const { state: stateAuth } = JSON.parse(getFromLS('auth_store'));
  const isAdmin = getFromLS('employeeIsAdmin');

  return {
    employeeId: stateAuth?.accessToken?.employeeId,
    companyId: stateAuth?.accessToken?.company.id,
    isAdmin,
  };
}
