import Popup from '../../../popup/popup.component.js';
import { formatterUtil } from '../../../../utils/formatter.js';

let Template = (props) => {
  let characterLimit = props.characterLimit || 125;

  return (
    <div className="exon-hotel-description">
      {props.description && (
        <p>{formatterUtil.stringReduce(props.description, characterLimit)}</p>
      )}

      {!props.description && (
        <p className="exon-hotel-description-empty">
          Informações sobre o quarto não informadas pelo hotel
        </p>
      )}

      {props.description && props.description.length > characterLimit && (
        <p className="exon-link">
          <span
            onClick={(e) => {
              e.stopPropagation();
              return props.events.onClick();
            }}
          >
            Ver mais
          </span>
        </p>
      )}

      {props.show && (
        <Popup
          exonStyle={true}
          title={props.title || 'Descrição do hotel'}
          cancelButtonClick={(e) => {
            e.stopPropagation();
            return props.events.onClick();
          }}
        >
          <p>{props.description}</p>
        </Popup>
      )}
    </div>
  );
};

export default Template;
