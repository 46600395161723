import { TravelDataTableProps } from '@components/TravelDataTable/types';
import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { Grid } from '@mui/material';

interface TableHeaderProps
  extends Pick<TravelDataTableProps, 'header' | 'headerEndContent'> {
  filteredItemsCount: number;
}

export function TableHeader({ filteredItemsCount, header }: TableHeaderProps) {
  return (
    <Grid container>
      <Grid item sm={12} lg={9}>
        <Table.Header
          title={header.title}
          tagLabel={`${filteredItemsCount} ${header.tagLabel}`}
          tag="gray"
        />
      </Grid>
    </Grid>
  );
}
