import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 1200px) {
    display: block;
    width: 100%;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 69rem;
  width: 100%;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const RightContainer = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 480px;
  height: 495.06px;
  min-width: 480px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 4rem;
  margin-left: 30px;

  @media screen and (max-width: 1200px) {
    height: 0px;
  }
`;

export const GreetingsText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 568px;
`;

export const Title = styled(Typography)`
  && {
    margin-top: 0;
    font-size: 40px !important;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
    font-size: 16px;
    margin-top: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
`;
