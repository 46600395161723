import { Pill } from '@components/Pills';
import { CircularProgress, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { SC } from './styled';

type FlashPayControllers = {
  showOptionsList: (event: any, checked: boolean) => void;
  optionListIsVisible: boolean;
  variant?: 'active' | 'soon' | 'disabled' | 'insufficient';
  isLoading?: boolean;
};
const badge = (variant) => {
  switch (variant) {
    case 'soon':
      return (
        <Pill icon="IconHourglassHigh" variant="neutral">
          Em breve!
        </Pill>
      );
    case 'active':
      return (
        <Pill icon="IconSparkles" variant="primary">
          Novo!
        </Pill>
      );
    case 'disabled':
    case 'insufficient':
      return (
        <Pill icon="IconSparkles" variant="neutral">
          Novo!
        </Pill>
      );
  }
};
export function FlashPayControllers({
  showOptionsList,
  optionListIsVisible,
  variant = 'active',
  isLoading = false,
}: FlashPayControllers) {
  const text = {
    active:
      'Agora você pode pagar suas viagens usando um cartão virtual corporativo da Flash, Experimente!',
    disabled:
      'Agora você pode pagar suas viagens usando um cartão virtual corporativo da Flash, Experimente!',
    soon: 'Em breve você poderá utilizar o cartão Flash para pagar reservas de hotel',
    insufficient:
      'Saldo corporativo insuficiente. Para poder usar o cartão corporativo Flash, solicite mais saldo à sua gestão.',
  };
  return (
    <SC.FlexItem>
      <SC.FlexRow disabled={variant === 'insufficient'}>
        <SC.Center>
          {isLoading ? (
            <CircularProgress size={38} variant="indeterminate" />
          ) : (
            <SC.ToggleButton
              onChange={showOptionsList}
              checked={optionListIsVisible && variant === 'active'}
              disabled={variant !== 'active'}
            ></SC.ToggleButton>
          )}
        </SC.Center>

        <div>
          <SC.FlexRow>
            <Typography variant="body3" weight={600}>
              Pagar com Cartão Flash
            </Typography>
            {badge(variant)}
          </SC.FlexRow>

          <Typography tag="span" variant="body4">
            {text[variant]}
          </Typography>
        </div>
      </SC.FlexRow>
    </SC.FlexItem>
  );
}
