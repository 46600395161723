'use strict';

import React from 'react';
import DashboardTemplate from './dashboard.template.js';
import DateComponent from '../../date/date.component.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils//componentUtil.js';
import { enums } from '@legacy-utils/enums';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import { utils, gritter } from '@legacy-utils/utils';
import { apiResponse } from '@legacy-utils/apiResponse';
import { travelEnums } from '../../../utils/enums.js';
import { Loading } from '@components/Loading/Loading';

export default class DashboardComponent extends React.Component {
  externalOptions = {
    onOpenRequestClicked: null,
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    for (let component in this.components) {
      let componentFunctions = this.components[component];
      componentUtil.react.bindMethods(componentFunctions, this);
    }

    this.state = {
      loading: false,
      requests: [],
      pagedRequests: [],
      summary: {},
      locator: '',
      isFilterOpened: false,
      isExternalReserveOpened: false,
      isLoading: true,
      isCheckoutLoading: true,
      finalStartDate: null,
      productType: 0,
      provider: 0,
      showOptions: false,
      order: {
        By: 1,
        Desc: true,
      },
      orderByFromResults: 1,
      pagination: {
        totalResults: 0,
        itemsPerPage: 10,
        currentPage: 1,
      },
      requesterAutoCompleteInputText: null,
      travelerAutoCompleteInputText: null,

      filters: this.getInitialFilters(),

      companySelected: { value: 0 },
      requestSearcher: {
        requestNumber: '',
        contextId: { value: 0 },
      },

      displayCheckout: false,
      validateExternalReserve: false,

      serviceRequestSelected: [],
      isActive: true,

      popupAprovedData: {
        show: false,
        events: {
          confirm: null,
          cancel: null,
        },
      },
      popupRecuseJustificationData: {
        show: false,
        events: {
          confirm: null,
          cancel: null,
        },
      },
      descriptionJustification: null,
      showValidationsfieldsJustification: false,
      updateAt: null,
      intervalOption: false,
      viewAllBox: false,
      currentUserData: this.props.currentUserData
    };
  }

  //Region metodos
  getDateInterval(isForward) {
    let start = new Date();
    let end = new Date();

    if (isForward) end.setDate(end.getDate() + 30);
    else start.setDate(start.getDate() - 30);

    let startObj = utils.formatters.date.getFormattedDateObjFromDate(start);
    let endObj = utils.formatters.date.getFormattedDateObjFromDate(end);
    return {
      start: {
        day: startObj.day,
        month: startObj.month,
        year: startObj.year,
        hour: 0,
        minute: 0,
      },
      end: {
        day: endObj.day,
        month: endObj.month,
        year: endObj.year,
        hour: 23,
        minute: 59,
      },
    };
  }
  getInitialFilters = function () {
    let interval = this.getDateInterval();

    return {
      status: this.props.isApproval ? [5] : [],
      contextId: null,
      travelType: 0,
      serviceRequestItemType: null,
      travelerId: 0,
      requesterId: 0,
      period: {
        start: interval.start,
        end: interval.end,
        field: 1,
      },
      travelerOnly: false,
      onlySons: false,
      filterByPolicy: false,
      BookingsWithViolatedPolicyOnly: false,
      purpose: this.props.isApproval ? 1 : 0,
      count: 0,
    };
  };

  resolveFilterCount(filters) {
    let keys = Object.keys(filters);
    let interval = this.getDateInterval();
    let count = 0;
    keys.forEach((key) => {
      if (key == 'isApproval') return;

      if (key == 'period') {
        let period = filters[key];
        if (
          utils.formatters.dateObjToString(period.start) !=
            utils.formatters.dateObjToString(interval.start) ||
          utils.formatters.dateObjToString(period.end) !=
            utils.formatters.dateObjToString(interval.end)
        ) {
          count += 1;
        }
        return;
      }

      if (key == 'status') {
        if (filters[key].length == 1 && !filters.isApproval) {
          count += 1;
        }
        return;
      }
      if (filters[key] && key != 'count') count += 1;
    });

    filters.count = count;
    return filters;
  }

  disabledOkButtonExternalReserve = function () {
    return (
      this.state.companySelected.value == 0 ||
      this.state.productType == 0 ||
      this.state.locator == '' ||
      this.state.provider == 0
    );
  };

  callOpenRequestClicked() {
    let requestSearcher = this.state.requestSearcher;

    if (this.externalOptions.onOpenRequestClicked)
      this.externalOptions.onOpenRequestClicked(
        requestSearcher.requestNumber,
        requestSearcher.contextId.value,
      );

    requestSearcher.requestNumbe = '';

    this.setState({ requestSearcher: requestSearcher });
  }

  refresh() {
    let filters = this.getInitialFilters();

    this.setState({
      filters,
      travelerAutoCompleteInputText: '',
      requesterAutoCompleteInputText: '',
    });

    this.dataSources.getRequests(1, filters, this.state.order, true);
  }

  intervalKey = null;
  setAutoRefresh() {
    let interval = this.state.intervalOption;

    if (interval) {
      this.intervalKey = setInterval(() => this.refresh(), interval);

      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState == 'hidden')
          clearInterval(this.intervalKey);
        if (document.visibilityState == 'visible') this.autoRefresh();
      });
    }
  }

  //End Region metodos

  componentDidMount() {
    this.dataSources.getRequests(1, this.state.filters, this.state.order, true);

    this.setAutoRefresh(); // TODO Ezequiel implementar auto refresh após paginação no back-end
  }

  componentDidUpdate() {
    if (this.props.currentUserData.company.contextId != this.state.currentUserData.company.contextId) {
      this.refresh();

      this.setState({
        currentUserData: this.props.currentUserData
      });
    }
  }

  render() {
    return (
      <ErrorBoundary>
        {this.state.loading && <Loading />}
        <DashboardTemplate.main
          requests={this.state.requests}
          summary={this.state.summary}
          requestSearcher={this.state.requestSearcher}
          isApproval={this.props.isApproval ? this.props.isApproval : false}
          isAgency={this.props.isAgency}
          isFilterOpened={this.state.isFilterOpened}
          onFilterApplyClick={this.events.onFilterApplyClicked}
          onFilterOpenCloseClick={this.events.onFilterOpenCloseClicked}
          onFilterClearClick={this.events.onFilterClearClicked}
          traveler={this.state.filters.traveler}
          isLoading={this.state.isLoading}
          pagination={this.state.pagination}
          isExternalReserveOpened={this.state.isExternalReserveOpened}
          locator={this.state.locator}
          showOptions={this.state.showOptions}
          userTravelProfile={this.props.userTravelProfile}
          orderByFromResults={this.state.orderByFromResults}
          displayCheckout={this.state.displayCheckout}
          agencyAndCompany={{
            agency: {
              isAgency: this.props.isAgency,
              travelProfile: this.props.userTravelProfile,
            },
            company: this.state.companySelected,
          }}
          requesterEvents={this.events.requesterEvents}
          travelerEvents={this.events.travelerEvents}
          requestersDataSources={this.dataSources.requesters}
          travelersDataSources={this.dataSources.travelers}
          requesterAutoCompleteInputText={
            this.state.requesterAutoCompleteInputText
          }
          travelerAutoCompleteInputText={
            this.state.travelerAutoCompleteInputText
          }
          isCheckoutLoading={this.state.isCheckoutLoading}
          disabledOkButtonExternalReserve={this.disabledOkButtonExternalReserve()}
          validateExternalReserve={this.state.validateExternalReserve}
          orderByProps={this.components.orderBy.getProps()}
          statusProps={this.components.status.getProps()}
          filterProductTypesProps={this.components.filterProductTypes.getProps()}
          travelTypeProps={this.components.travelType.getProps()}
          initialDateProps={this.components.initialDate.getProps()}
          finalDateProps={this.components.finalDate.getProps()}
          travelerOnlyProps={this.components.travelerOnly.getProps()}
          onlySonsProps={this.components.onlySons.getProps()}
          filterByPolicyProps={this.components.filterByPolicy.getProps()}
          filterByOutPolicyProps={this.components.filterByOutPolicy.getProps()}
          productTypeProps={this.components.productType.getProps()}
          providerProps={this.components.provider.getProps()}
          companiesProps={this.components.companies.getProps()}
          companiesRequestSearcherProps={this.components.companiesRequestSearcher.getProps()}
          companiesFilterSelectProps={this.components.companiesFilterSelect.getProps()}
          checkoutProps={this.components.checkout.getProps()}
          onOpenRequestClick={this.events.onOpenRequestClicked}
          onOrderClick={this.events.onOrderClicked}
          onCardClick={this.events.onCardClick}
          onRequestNumberChange={this.events.onRequestNumberChanged}
          onRequestKeyPress={this.events.onRequestKeyPress}
          onSeachByNumberClick={this.events.onSeachByNumberClicked}
          onTravelerChange={this.events.onTravelerChanged}
          onPageClick={this.events.onPageClicked}
          onExternalReserveOpenCloseClick={
            this.events.onExternalReserveOpenCloseClicked
          }
          onExternalReserveApplyClick={
            this.events.onExternalReserveApplyClicked
          }
          onLocatorChange={this.events.onLocatorChanged}
          onShowHideOptionClick={this.events.onShowHideOptionClicked}
          eventsAdd={this.events.add}
          onCloseCheckoutClick={this.events.onCloseCheckoutClicked}
          isActive={this.state.isActive}
          onCheckboxChange={this.events.onCheckboxChanged}
          aprovedEvents={this.events.popup.aproved}
          popupAprovedData={this.state.popupAprovedData}
          refuseEvents={this.events.popup.refuse}
          popupRecuseJustificationData={this.state.popupRecuseJustificationData}
          serviceRequestSelected={this.state.serviceRequestSelected}
          descriptionJustification={this.state.descriptionJustification}
          showValidationsfieldsJustification={
            this.state.showValidationsfieldsJustification
          }
          externalBooking={{
            source: this.state.provider,
            locator: this.state.locator,
          }}
          onRefreshClick={this.events.onRefreshClicked}
          updateAt={this.state.updateAt}
          intervalOption={this.state.intervalOption}
          selectUpdateParameters={this.components.selectUpdateParameters.getProps()}
          filters={this.state.filters}
          viewAllBox={this.state.viewAllBox}
          onViewModeClick={this.events.onViewModeClicked}
        />
      </ErrorBoundary>
    );
  }

  toggleModalLoading = (loading) => {
    this.setState({ loading });
  };

  dataSources = {
    checkout: {
      justificationsList: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          return this.props.dataSources.justificationsList.load(loadOptions);
        },
      },
      costCenter: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          this.AppendContextIdForAgency(loadOptions);

          return this.props.dataSources.costCenter.load(loadOptions);
        },
      },
      paymentForms: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          this.AppendContextIdForAgency(loadOptions);

          return this.props.dataSources.paymentForms.load(loadOptions);
        },
        events: {
          beforeLoad: () => {
            this.props.dataSources.paymentForms.beforeLoad();
          },
          afterLoad: () => {
            this.props.dataSources.paymentForms.afterLoad();
          },
        },
      },
      customField: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          this.AppendContextIdForAgency(loadOptions);

          return this.props.dataSources.customField.load(loadOptions);
        },
        events: {
          beforeLoad: () => {
            this.props.dataSources.customField.beforeLoad();
          },
          afterLoad: () => {
            this.props.dataSources.customField.afterLoad();
          },
        },
      },
      travelReason: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          this.AppendContextIdForAgency(loadOptions);

          return this.props.dataSources.travelReason.load(loadOptions);
        },
      },
      nationality: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          return this.props.dataSources.nationality.load(loadOptions);
        },
        get: (id) => {
          return this.props.dataSources.nationality.get(id);
        },
      },
      licenceFeatures: {
        load: (contextId) => {
          return this.props.dataSources.licenceFeatures.load(contextId);
        },
      },
      locations: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          return this.props.dataSources.locations.load(loadOptions);
        },
      },
      processBooking: {
        callProcessBooking: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          return this.props.dataSources.processBooking.load(loadOptions);
        },
      },
      companySettings: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          return this.props.dataSources.companySettings.load(loadOptions);
        },
      },
    },
    travelers: {
      load: (loadOptions) => {
        const _this = this;
        const dataSource = this.props.dataSources.travelers;
        const isFilter = this.state.isFilterOpened;

        loadOptions.travelerType = _this.state.filters.travelType;
        loadOptions.loadUsers = false;

        this.AppendContextIdForAgency(loadOptions, isFilter);

        return new Promise((resolve, reject) => {
          dataSource.load(loadOptions).then((items) => {
            resolve(items);
          });
        });
      },
      insert: (traveler) => {
        if (!traveler) traveler = {};

        let company = this.state.companySelected;
        if (
          this.props.isAgency &&
          this.props.userTravelProfile === 4 &&
          company.value !== 0
        )
          traveler.contextId = company.value;

        return this.props.dataSources.travelers.insert(traveler);
      },
      update: (traveler) => {
        if (!traveler) traveler = {};

        let company = this.state.companySelected;
        if (
          this.props.isAgency &&
          this.props.userTravelProfile === 4 &&
          company.value !== 0
        )
          traveler.contextId = company.value;

        return this.props.dataSources.travelers.update(traveler);
      },
      events: {
        beforeLoad: () => {},
        afterLoad: () => {},
        beforeInsert: () => {
          this.toggleModalLoading(true);
        },
        afterInsert: () => {
          this.toggleModalLoading(false);
        },
        beforeUpdate: () => {
          this.toggleModalLoading(true);
        },
        afterUpdate: () => {
          this.toggleModalLoading(false);
        },
      },
    },

    getExternalReserve: {
      load: () => {
        const dataSource = this.props.dataSources.externalReserve;

        this.setState({
          displayCheckout: true,
          isCheckoutLoading: true,
        });

        let loadOptions = {
          contextId: this.state.companySelected.value,
          source: this.state.provider,
          locator: this.state.locator,
        };
        let product = this.state.productType;
        dataSource
          .load(loadOptions)
          .then((response) => {
            if (response.successful) {
              if (this.props.airLines.length > 0) {
                response.checkoutData.productsData.trips.map((airTrips) => {
                  airTrips.trips.map((trip) => {
                    let airLine = this.getAirline(
                      trip.formattedObject.airLine.code,
                    );
                    trip.formattedObject.airLine = airLine;
                  });
                });
              }

              this.setState({
                checkoutData: response.checkoutData,
                isCheckoutLoading: false,
              });
            } else {
              apiResponse.successful.showErrorIfExists(
                response,
                `Ocorreu um erro ao busca ${
                  product == 2
                    ? 'a reserva aérea pelo'
                    : 'a reserva de hotel pelo'
                } localizador ${loadOptions.locator}`,
              );

              this.setState({
                displayCheckout: !this.state.displayCheckout,
              });
            }
          })
          .catch((response) => {
            apiResponse.http.showErrorIfExists(
              response,
              `Ocorreu um erro ao realizar a busca pelo localizador ${loadOptions.locator}, tente novamente em alguns instantes.`,
            );

            this.setState({
              displayCheckout: !this.state.displayCheckout,
            });
          });
      },
    },

    requesters: {
      load: (loadOptions) => {
        const _this = this;
        const dataSource = this.props.dataSources.requesters;
        const isFilter = this.state.isFilterOpened;

        this.AppendContextIdForAgency(loadOptions, isFilter);

        return new Promise((resolve, reject) => {
          dataSource
            .load(loadOptions.keywords, loadOptions.contextId)
            .then((items) => {
              resolve(listItemsResolver.resolve(items, 'RequesterId', 'Name'));
            });
        });
      },
    },

    getRequests: (localCurrentPage, filters, order, isUpdateCount) => {
      this.setState({
        isLoading: true,
        requests: null,
      });

      let searchViewParameters = {
        filter: filters ? filters : this.state.filters,
        sortingData: order ? order : this.state.order,
      };

      let counterParameters = {
        filter: filters
          ? JSON.parse(JSON.stringify(filters))
          : JSON.parse(JSON.stringify(this.state.filters)),
        sortingData: order ? order : this.state.order,
      };
      counterParameters.filter.status = [];

      let counterPromise = null;
      let itemsPromise = null;

      if (!this.props.isApproval && isUpdateCount) {
        counterPromise = this.props.dataSources.counterTravelRequest.load({
          searchViewParameters: counterParameters,
        });
        counterPromise.then((counterResponse) => {
          let group = this.getDefaultGroupByTravelProfile(
            counterResponse.groups,
            this.props.userTravelProfile,
          );
          if (group && searchViewParameters.filter.status.length == 0) {
            group.active = true;
            searchViewParameters.filter.status = group.status;
          }

          itemsPromise = this.props.dataSources.listTravelRequest.load({
            searchViewParameters: {
              ...searchViewParameters,
              pagingData: {
                page: localCurrentPage,
                itemsPerPage: this.state.pagination.itemsPerPage,
              },
            },
          });
          this.waitPromiseAndSetItems(itemsPromise, localCurrentPage);
          this.setState({
            summary: counterResponse,
          });
        });
      } else {
        itemsPromise = this.props.dataSources.listTravelRequest.load({
          searchViewParameters: {
            ...searchViewParameters,
            pagingData: {
              page: localCurrentPage,
              itemsPerPage: this.state.pagination.itemsPerPage,
            },
          },
        });

        this.waitPromiseAndSetItems(itemsPromise, localCurrentPage);
      }
    },
  };

  AppendContextIdForAgency(loadOptions, Isfilter) {
    let contextId = null;

    if (Isfilter) contextId = this.state.filters.contextId;
    else contextId = this.state.companySelected.value;

    if (
      this.props.isAgency &&
      this.props.userTravelProfile === 4 &&
      contextId !== 0
    )
      loadOptions.contextId = contextId;
  }
  waitPromiseAndSetItems(itemsPromise, localCurrentPage) {
    itemsPromise
      .then((itemsResponse) => {
        if (itemsResponse.summary != null) {
          this.setPagedData(itemsResponse, localCurrentPage);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }
  getDefaultGroupByTravelProfile(groups, userTravelProfile) {
    let reference = null;
    if (userTravelProfile == enums.userTravelProfile.consultant)
      reference = 'in_attendance';
    else reference = 'created';
    return groups.find((group) => group.reference == reference);
  }

  setPagedData(itemsResponse, localCurrentPage) {
    if (this.props.isAgency && itemsResponse.items) {
      for (var i = 0; i < itemsResponse.items.length; i++) {
        let company = this.props.companies.find(
          (c) => c.value == itemsResponse.items[i].rootContextId,
        );

        company !== undefined
          ? (itemsResponse.items[i].companyName = company.label)
          : (itemsResponse.items[i].companyName = '--');
      }
    }

    let pagedRequests = utils.array.chunk(
      itemsResponse.items,
      this.state.pagination.itemsPerPage,
    );

    let currentPage = pagedRequests[localCurrentPage - 1];

    let paging = this.state.pagination;
    paging.currentPage = localCurrentPage;

    paging.totalResults = itemsResponse.summary.count;

    this.setState({
      requests: currentPage,
      pagedRequests: pagedRequests,
      pagination: paging,
      orderByFromResults: this.state.order.By,
      updateAt: new Date(),
    });
  }

  components = {
    orderBy: {
      getProps: function () {
        let _this = this;
        return {
          data: _this.state.order.By,
          options: {
            events: {
              onSelected: function (data) {
                let obj = _this.state.order;
                obj.By = data.value;

                let interval = null;
                if (data.value > 1) interval = _this.getDateInterval(true);
                else interval = _this.getDateInterval();

                let filter = _this.state.filters;
                filter.period.start = interval.start;
                filter.period.end = interval.end;
                filter.period.field = data.value;

                _this.setState({
                  filters: filter,
                  order: obj,
                });
              },
            },
            items: listItemsResolver.resolve(
              travelEnums.orderByDateDash,
              'id',
              'text',
            ),
          },
        };
      },
    },

    travelType: {
      getProps: function () {
        let _this = this;
        return {
          data: _this.state.filters.travelType,
          options: {
            events: {
              onSelected: function (data) {
                let obj = _this.state.filters;
                obj.travelType = data.value;

                _this.setState({ filters: obj });
              },
            },
            items: listItemsResolver.resolve(
              travelEnums.travelTypes,
              'id',
              'text',
            ),
          },
        };
      },
    },

    productType: {
      getProps: function () {
        let _this = this;
        return {
          data: _this.state.productType,
          options: {
            events: {
              onSelected: function (data) {
                _this.setState({
                  productType: data.value,
                });
              },
            },
            items: listItemsResolver.resolve(
              travelEnums.productsRetrieve,
              'id',
              'text',
            ),
          },
        };
      },
    },

    provider: {
      getProps: function () {
        let _this = this;
        return {
          data: _this.state.provider,
          options: {
            events: {
              onSelected: function (data) {
                _this.setState({ provider: data.value });
              },
            },
            items: _this.components.provider.getProvidersByProductType(
              _this.state.productType,
            ),
          },
        };
      },
      getProvidersByProductType: function (productType) {
        if (productType == 2) {
          return listItemsResolver.resolve(
            travelEnums.suppliersRetrieve.air,
            'id',
            'text',
          );
        } else if (productType == 3) {
          return listItemsResolver.resolve(
            travelEnums.suppliersRetrieve.hotel,
            'id',
            'text',
          );
        }
      },
    },

    status: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.filters.status[0]
            ? _this.state.filters.status[0]
            : 0,
          options: {
            events: {
              onSelected: function (data) {
                let obj = _this.state.filters;
                obj.status = [data.value];

                _this.setState({ filters: obj });
              },
            },
            items: listItemsResolver.resolve(
              travelEnums.serviceRequestStatus,
              'id',
              'name',
            ),
          },
        };
      },
    },

    filterProductTypes: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.filters.travelProduct,
          options: {
            events: {
              onSelected: function (data) {
                let filters = _this.state.filters;
                filters.serviceRequestItemType = data.value;

                _this.setState({ filters: filters });
              },
            },
            items: [
              { value: null, label: 'Todos' },
              {
                value: enums.products.namedType.airBooking,
                label: 'Aéreo',
              },
              {
                value: enums.products.namedType.hotelBooking,
                label: 'Hotel',
              },
            ],
          },
        };
      },
    },

    initialDate: {
      getProps: function () {
        let _this = this;
        return {
          data: _this.state.filters.period.start,
          placeholder: 'Data início',
          options: {
            events: {
              onSelected: function (data) {
                let obj = _this.state.filters;
                obj.period.start = data;

                if (data) {
                  _this.setState({
                    finalStartDate: DateComponent.convertDateObjectToDate(data),
                  });
                }
                _this.setState({ filters: obj });
              },
            },
          },
        };
      },
    },

    finalDate: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.filters.period.end,
          startDate: _this.state.finalStartDate,
          placeholder: 'Data final',
          options: {
            events: {
              onSelected: function (data) {
                let obj = _this.state.filters;
                obj.period.end = data;

                if (obj.period.end){
                  obj.period.end.hour = 23;
                  obj.period.end.minute = 59;
                }

                _this.setState({ filters: obj });
              },
            },
          },
        };
      },
    },

    travelerOnly: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.filters.travelerOnly,
          placeholder: '',
          options: {
            events: {
              onChanged: (checked) => {
                let obj = _this.state.filters;
                obj.travelerOnly = checked;

                _this.setState({ filters: obj });
              },
            },
          },
        };
      },
    },

    onlySons: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.filters.onlySons,
          placeholder: '',
          options: {
            events: {
              onChanged: (checked) => {
                let obj = _this.state.filters;
                obj.onlySons = checked;

                _this.setState({ filters: obj });
              },
            },
          },
        };
      },
    },

    filterByPolicy: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.filters.filterByPolicy,
          placeholder: '',
          options: {
            events: {
              onChanged: (checked) => {
                let obj = _this.state.filters;
                obj.filterByPolicy = checked;

                _this.setState({ filters: obj });
              },
            },
          },
        };
      },
    },

    filterByOutPolicy: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.filters.BookingsWithViolatedPolicyOnly,
          placeholder: '',
          options: {
            events: {
              onChanged: (checked) => {
                let obj = _this.state.filters;
                obj.BookingsWithViolatedPolicyOnly = checked;

                _this.setState({ filters: obj });
              },
            },
          },
        };
      },
    },

    companies: {
      getProps: function () {
        let _this = this;
        let companies = this.props.companies;

        return {
          data: _this.state.companySelected,
          placeholder: 'Selecione o cliente',
          options: {
            items: companies,
            events: {
              onSelected: function (selectedItems) {
                _this.setState({
                  companySelected: selectedItems,
                });
              },
            },
          },
        };
      },
    },

    companiesRequestSearcher: {
      getProps: function () {
        let _this = this;
        let companies = this.props.companies;

        return {
          data: _this.state.requestSearcher.contextId,
          placeholder: 'Selecione o cliente',
          options: {
            items: companies,
            events: {
              onSelected: function (selectedItem) {
                _this.setState({
                  requestSearcher: {
                    ..._this.state.requestSearcher,
                    contextId: selectedItem,
                  },
                });
              },
            },
          },
        };
      },
    },

    companiesFilterSelect: {
      getProps: function () {
        let _this = this;
        let companies = this.props.companies;

        return {
          data: _this.state.filters.contextId,
          placeholder: 'Selecione o cliente',
          options: {
            items: companies,
            events: {
              onSelected: function (selectedItem) {
                let filter = _this.state.filters;
                filter.contextId = selectedItem.value;

                _this.setState({
                  filters: filter,
                });
              },
            },
          },
        };
      },
    },

    selectUpdateParameters: {
      getProps: () => {
        let options = [
          {
            value: 1,
            label: 'Atualizar em 5 minutos',
            interval: 5000 /** 60*/,
          },
          {
            value: 2,
            label: 'Atualizar em 10 minutos',
            interval: 10000 /** 60*/,
          },
          {
            value: 3,
            label: 'Atualizar em 15 minutos',
            interval: 15000 /** 60*/,
          },
          { value: 4, label: 'Não atualizar', interval: null },
        ];

        return {
          data: this.state.intervalOption.value,
          placeholder: 'Selecione',
          options: {
            items: options,
            events: {
              onSelected: (selectedItem) => {
                clearInterval(this.intervalKey);
                this.autoRefresh(selectedItem.interval);
                this.setState({ intervalOption: selectedItem });
              },
            },
          },
        };
      },
    },

    checkout: {
      getProps: () => {
        return {
          dataSources: {
            requester: this.dataSources.requesters,
            travelers: this.dataSources.travelers,
            costCenter: this.dataSources.checkout.costCenter,
            companySettings: this.dataSources.checkout.companySettings,
            justificationsList: this.dataSources.checkout.justificationsList,
            paymentForms: this.dataSources.checkout.paymentForms,
            customField: this.dataSources.checkout.customField,
            travelReason: this.dataSources.checkout.travelReason,
            nationality: this.dataSources.checkout.nationality,
            licenceFeatures: this.dataSources.checkout.licenceFeatures,
            locations: this.dataSources.checkout.locations,
            processBooking: this.dataSources.checkout.processBooking,
          },
          data: this.state.checkoutData,
          userId: this.externalOptions.userId,
        };
      },
    },
  };

  events = {
    add: {
      onShowHideOptionClicked: () => {
        this.setState({ showOptions: !this.state.showOptions });
      },

      onExternalReserveOpenCloseClicked: () => {
        this.setState({
          isExternalReserveOpened: !this.state.isExternalReserveOpened,
        });
      },

      onCreateRvOnlineClicked: () => {
        let urlOrigin = window.location.origin;
        window.location.href = urlOrigin + `/travel/sale`;
      },

      onCreateRvOffline: () => {
        this.events.add.onShowHideOptionClicked();
        this.props.eventsRvOffline.onOpenCreateRvOfflineClicked &&
          this.props.eventsRvOffline.onOpenCreateRvOfflineClicked();
      },
    },

    requesterEvents: {
      onAutoCompleteChanged: (selectedItem) => {
        let obj = this.state.filters;
        obj.requesterId = selectedItem.value;

        this.setState({
          filters: obj,
          requesterAutoCompleteInputText: selectedItem.label,
        });
      },

      onAutoCompleteInputChanged: (inputText) => {
        this.setState({
          requesterAutoCompleteInputText: inputText,
        });
      },
    },

    travelerEvents: {
      onAutoCompleteChanged: (selectedItem) => {
        let obj = this.state.filters;
        obj.travelerId = selectedItem.value.split('_')[0];

        this.setState({
          filters: obj,
          travelerAutoCompleteInputText: selectedItem.label,
        });
      },

      onAutoCompleteInputChanged: (inputText) => {
        this.setState({
          travelerAutoCompleteInputText: inputText,
        });
      },
    },

    onPageClicked: (currentPage) => {
      let requests = this.state.pagedRequests[currentPage - 1];
      if (requests) {
        let paging = this.state.pagination;
        paging.currentPage = currentPage;

        this.setState({
          requests: requests,
          pagination: paging,
        });
      } else {
        this.dataSources.getRequests(
          currentPage,
          this.state.filters,
          this.state.order,
        );
      }
    },

    onOpenRequestClicked: (event, identifier, rootContextId) => {
      if (
        this.externalOptions.onOpenRequestClicked &&
        event.target.type !== 'checkbox'
      )
        this.externalOptions.onOpenRequestClicked(identifier, rootContextId);
    },

    onOrderClicked: () => {
      let order = this.state.order;
      order.Desc = !this.state.order.Desc;

      //this.dataSources.getRequests(1, this.state.filters, order);

      let pagedRequestsTotal = [];
      let pagedRequests = [];

      this.state.pagedRequests.map((page, index) => {
        page.map((item, index) => {
          pagedRequestsTotal.push(item);
        });
      });

      pagedRequestsTotal.sort(function (a, b) {
        let sortField = order.Desc
          ? a.createDate > b.createDate
            ? 1
            : -1
          : a.createDate < b.createDate
          ? 1
          : -1;
        switch (order.By) {
          case 1:
            sortField = order.Desc
              ? a.createDate > b.createDate
                ? 1
                : -1
              : a.createDate < b.createDate
              ? 1
              : -1;
            break;
          case 2:
            sortField = order.Desc
              ? a.expiresIn > b.expiresIn
                ? 1
                : -1
              : a.expiresIn < b.expiresIn
              ? 1
              : -1;
            break;
          case 3:
            sortField = order.Desc
              ? a.embarkOrCheckinDate > b.embarkOrCheckinDate
                ? 1
                : -1
              : a.embarkOrCheckinDate < b.embarkOrCheckinDate
              ? 1
              : -1;
            break;
        }

        return sortField;
      });

      let localCurrentPage = 1;
      for (
        let i = 0;
        i * this.state.pagination.itemsPerPage < pagedRequestsTotal.length;
        i++
      ) {
        let end =
          i * this.state.pagination.itemsPerPage +
          this.state.pagination.itemsPerPage;
        pagedRequests[localCurrentPage + i] = pagedRequestsTotal.slice(
          i * this.state.pagination.itemsPerPage,
          end,
        );
      }

      let currentPage = pagedRequests[localCurrentPage];

      let paging = this.state.pagination;
      paging.currentPage = localCurrentPage;

      this.setState({
        order: order,
        requests: currentPage,
        pagedRequests: pagedRequests,
        pagination: paging,
      });
    },
    onViewModeClicked: () => {
      this.setState({ viewAllBox: !this.state.viewAllBox });
    },

    onCardClick: (status, index) => {
      let filter = this.state.filters;

      let summary = this.state.summary;
      summary.groups.forEach((group, i) =>
        index == i ? (group.active = !group.active) : (group.active = false),
      );
      let active = summary.groups[index].active;

      filter.status = active ? status : [];

      this.setState({
        filters: filter,
        pagedRequests: [],
      });

      this.dataSources.getRequests(1, filter, this.state.order);
    },
    onRefreshClicked: () => this.refresh(),

    onFilterClearClicked: () => {
      this.setState({
        filters: this.getInitialFilters(),
        order: {
          By: 1,
          Desc: this.state.order.Desc,
        },
        travelerAutoCompleteInputText: '',
        requesterAutoCompleteInputText: '',
      });
    },

    onFilterOpenCloseClicked: () => {
      this.setState({
        isFilterOpened: !this.state.isFilterOpened,
      });
    },

    onExternalReserveOpenCloseClicked: () => {
      if (!this.state.isExternalReserveOpened) {
        this.dataSources.companies.load();
      }
      this.clearFieldsFromExternalReserve({
        isExternalReserveOpened: !this.state.isExternalReserveOpened,
      });
    },

    onShowHideOptionClicked: () => {
      this.setState({ showOptions: !this.state.showOptions });
    },

    onFilterApplyClicked: () => {
      let filters = this.state.filters;
      if (!this.validateFilterFilds(filters)) return;
      this.dataSources.getRequests(1, filters, this.state.order, true);

      this.setState({
        isFilterOpened: !this.state.isFilterOpened,
        pagedRequests: [],
        filters: this.resolveFilterCount(filters),
      });
    },

    onExternalReserveApplyClicked: () => {
      if (!this.disabledOkButtonExternalReserve()) {
        this.setState({ validateExternalReserve: false });
        this.dataSources.getExternalReserve.load();
      } else {
        this.setState({ validateExternalReserve: true });
      }
    },

    onRequestNumberChanged: (event) => {
      this.setState({
        requestSearcher: {
          ...this.state.requestSearcher,
          requestNumber: event.target.value,
        },
      });
    },

    onCloseCheckoutClicked: (event) => {
      this.setState({
        displayCheckout: false,
      });
    },

    onLocatorChanged: (locator) => {
      this.setState({ locator: locator });
    },

    onTravelerChanged: (event) => {
      let obj = this.state.filters;
      obj.traveler = event.target.value;

      this.setState(obj);
    },

    onSeachByNumberClicked: () => {
      if (
        (!this.state.isAgency ||
          this.state.requestSearcher.contextId.value != 0) &&
        this.state.requestSearcher.requestNumber != ''
      )
        this.callOpenRequestClicked();
    },

    onRequestKeyPress: (event) => {
      if (event.charCode === 13) {
        this.callOpenRequestClicked();
      }
    },

    onCheckboxChanged: (event, requestId) => {
      let requests = this.state.requests;
      let request = requests.find((request) => request.id === requestId);
      request.checked = event.target.checked;

      let selectedRequests = requests.filter((request) => request.checked);

      this.setState({
        isActive: selectedRequests.length > 0 ? false : true,
        serviceRequestSelected: selectedRequests.map((request) => {
          return { id: request.id, rootContextId: request.rootContextId };
        }),
      });
    },

    onConfirmRefuseRequestClicked: () => {
      if (this.validateFieldsJustification()) {
        this.props.approvalMethods
          .reproveInBatch(
            this.state.serviceRequestSelected,
            this.state.descriptionJustification,
          )
          .then((result) => {
            for (let i = 0; i < result.length; i++) {
              if (result[i].response.successful) {
                if (
                  result[i].response.messages &&
                  result[i].response.messages.length > 0
                ) {
                  gritter.Info(
                    `RV ${result[i].id}: ` +
                      result[i].response.messages[0].message,
                  );
                } else {
                  gritter.Success(`RV ${result[i].id} recusado com sucesso.`);
                }
              } else {
                apiResponse.successful.showErrorIfExists(
                  result[i].response,
                  `Ocorreu um erro ao tentar recusar a RV ${result[i].id}.`,
                );
              }
            }
          });

        this.events.popup.refuse.onClosePopupClicked();

        this.dataSources.getRequests(
          1,
          this.state.filters,
          this.state.order,
          true,
        );

        this.setState({
          showValidationsfieldsJustification: false,
          serviceRequestSelected: [],
          isActive: true,
        });
      } else {
        this.setState({ showValidationsfieldsJustification: true });
      }
    },
    onConfirmApproveRequestClicked: () => {
      this.props.approvalMethods
        .approveInBatch(this.state.serviceRequestSelected)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            if (result[i].response.successful) {
              if (
                result[i].response.messages &&
                result[i].response.messages.length > 0
              ) {
                gritter.Info(
                  `RV ${result[i].id}: ` +
                    result[i].response.messages[0].message,
                );
              } else {
                gritter.Success(`RV ${result[i].id} aprovada com sucesso.`);
              }
            } else {
              apiResponse.successful.showErrorIfExists(
                result[i].response,
                `Ocorreu um erro ao tentar aprovar a RV ${result[i].id}.`,
              );
            }
          }
        });

      this.events.popup.aproved.onClosePopupClicked();

      this.dataSources.getRequests(
        1,
        this.state.filters,
        this.state.order,
        true,
      );

      this.setState({ serviceRequestSelected: [], isActive: true });
    },

    popup: {
      aproved: {
        onClosePopupClicked: () => {
          this.setState({
            popupAprovedData: {
              show: false,
              description: null,
              events: {
                confirm: null,
                cancel: null,
              },
            },
          });
        },

        onApproveRequestClicked: (serviceRequestIds) => {
          let lastId = serviceRequestIds[serviceRequestIds.length - 1].id;
          let description = `Confirma a APROVAÇÃO da requisição: RV${serviceRequestIds.map(
            (obj) => ' ' + obj.id + (obj.id === lastId ? '.' : ''),
          )}`;

          this.setState({
            popupAprovedData: {
              show: true,
              description: description,
              events: {
                confirm: this.events.onConfirmApproveRequestClicked,
                cancel: this.events.popup.aproved.onClosePopupClicked,
              },
            },
          });
        },
      },

      refuse: {
        onClosePopupClicked: () => {
          this.setState({
            popupRecuseJustificationData: {
              show: false,
              description: null,
              events: {
                confirm: null,
                cancel: null,
              },
            },
            showValidationsfieldsJustification: false,
          });
        },

        onRefuseRequestClicked: (serviceRequestIds) => {
          let lastId = serviceRequestIds[serviceRequestIds.length - 1].id;
          let description = `Confirma a REPROVAÇÃO da requisição: RV${serviceRequestIds.map(
            (obj) => ' ' + obj.id + (obj.id === lastId ? '.' : ''),
          )}`;

          this.setState({
            popupRecuseJustificationData: {
              show: true,
              description: description,
              isRequest: true,
              events: {
                confirm: this.events.onConfirmRefuseRequestClicked,
                cancel: this.events.popup.refuse.onClosePopupClicked,
                onChangeDescription:
                  this.events.popup.refuse.onChangeDescription,
              },
            },
            descriptionJustification: '',
          });
        },

        onChangeDescription: (value) => {
          this.setState({ descriptionJustification: value });
        },
      },
    },
  };

  validateFilterFilds(filter) {
    let isValid = true;
    if (filter.period) {
      let start = filter.period.start;
      let end = filter.period.end;
      isValid = this.validateDateObj(start) && this.validateDateObj(end);
    } else {
      isValid = false;
    }
    return isValid;
  }
  validateDateObj(date) {
    if (date && date.day && date.month && date.year) return true;
    return false;
  }

  validateFieldsJustification() {
    let isValid = true;

    isValid =
      isValid &&
      this.state.descriptionJustification != null &&
      this.state.descriptionJustification !== '';

    return isValid;
  }

  clearFieldsFromExternalReserve(objState) {
    this.setState({
      companySelected: { value: 0 },
      productType: 0,
      provider: 0,
      locator: '',
      ...objState,
    });
  }

  getAirline(code) {
    let airline = this.props.airLines.find((x) => x.code == code);
    if (!airline)
      airline = {
        code: code,
      };

    return {
      code: airline.code,
      name:
        airline.displayName || airline.label || airline.name || airline.code,
      imageUrl: airline.imageUrl,
    };
  }
}
