import { format, parse } from 'date-fns';
import { DateObject } from 'src/types/Date.types';

export const convertDateToDateObject = (
  date: Date,
  hourMinute?: string,
): DateObject => {
  let hour = date.getHours();
  let minute = date.getMinutes();

  if (hourMinute)
    [hour, minute] = hourMinute.split(':').map((_) => parseInt(_));

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1, // Adiciona 1 porque JavaScript conta meses de 0 a 11
    day: date.getDate(),
    hour,
    minute,
    second: 0,
  };
};

export const convertDateObjectToDate = (dateObject: any) => {
  if (!dateObject || dateObject.getFullYear) return dateObject;
  return new Date(
    dateObject.year,
    dateObject.month - 1,
    dateObject.day,
    dateObject.hour,
    dateObject.minute,
    dateObject.second,
  );
};

export const convertDateToHourMinute = (date?: Date) => {
  if (!date) return null;
  return (
    date.getHours().toString().padStart(2, '0') +
    ':' +
    date.getMinutes().toString().padStart(2, '0')
  );
};

export const convertDateStringToDate = (
  dateString: string,
  formatString = 'dd/MM/yyyy',
) => {
  return parse(dateString, formatString, new Date());
};

export const convertDateStringToDateObject = (
  dateString: string,
  formatString = 'dd/MM/yyyy',
): DateObject => {
  const date = convertDateStringToDate(dateString, formatString);
  return convertDateToDateObject(date);
};

export const calculateDaysDifference = (startDate, endDate): number => {
  const diffTime = Math.abs(endDate - startDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formatDateToString = (date: Date) => {
  return format(date, 'dd/MM/yyyy hh:mm');
};
