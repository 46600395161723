//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from '../select/select.component.js';
import InputFile from '../input-file/input-file.component.js';
import TextBox from '../textbox/textbox.component.js';
import Locations from '../locations/locations.component.js';
import PaymentSelector from '../common/payment-selector/payment-selector.component.js';
import { enums } from '@legacy-utils/enums';

let FieldTemplate = {
  textField: ({
    additionalClass,
    placeholder,
    label,
    value,
    required,
    maxLength,
    charsLeft,
    onChange,
    disabled,
    readOnly,
    type,
    forceValidation,
  }) => (
    <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
      <label>{label}</label>
      {/*<input type="text" value={value} onChange={onChange} maxLength={maxLength} />*/}
      <TextBox
        value={value}
        type={type}
        required={required}
        forceValidation={forceValidation}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      {charsLeft && (
        <div className="exon-field-character-counter">
          <span>{charsLeft} caracteres restantes</span>
        </div>
      )}
    </div>
  ),

  textAreaField: ({
    label,
    placeholder,
    value,
    required,
    maxLength,
    charsLeft,
    onChange,
    disabled,
    forceValidation,
  }) => (
    <div className="exon-field">
      <label>{label}</label>
      {/*<input type="text" value={value} onChange={onChange} maxLength={maxLength} />*/}
      <TextBox
        value={value}
        type={enums.components.textBoxType.textarea}
        required={required}
        forceValidation={forceValidation}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      {charsLeft && (
        <div className="exon-field-character-counter">
          <span>{charsLeft} caracteres restantes</span>
        </div>
      )}
    </div>
  ),

  richTextField: ({
    additionalClass,
    label,
    value,
    fieldRef,
    onChange,
    forceValidation,
  }) => (
    <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
      <label>{label}</label>
      {/*<CKEditor editor={ClassicEditor} data={value} onChange={onChange} />*/}
      <div ref={fieldRef}></div>
    </div>
  ),

  selectField: ({
    additionalClass,
    label,
    value,
    required,
    items,
    dataSource,
    onChange,
    disabled,
    forceValidation,
    isZeroValid,
    readOnly,
    isMultiple,
  }) => {
    let externalOptions = {
      items: items,
      dataSource: dataSource,
      events: {
        onSelected: onChange,
      },
    };

    return (
      <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
        <label>{label}</label>
        <div className="exon-field-select">
          <Select
            data={value}
            disabled={disabled}
            required={required}
            readonly={readOnly}
            forceValidation={forceValidation}
            externalOptions={externalOptions}
            placeholder={'Selecione'}
            onChange={onChange}
            isZeroValid={isZeroValid}
            ismultiple={isMultiple}
          ></Select>
        </div>
      </div>
    );
  },

  imageField: ({
    additionalClass,
    imageUrlValue,
    url,
    hotelId,
    hotelImageDataSource,
    onChanged,
    disabled,
    forceValidation,
  }) => {
    return (
      <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
        <InputFile
          url={url}
          type={enums.inputFile.image}
          hotelId={hotelId}
          disabled={disabled}
          imageUrlValue={imageUrlValue}
          hotelImageDataSource={hotelImageDataSource}
          onChange={onChanged}
        />
      </div>
    );
  },

  dateField: ({
    additionalClass,
    label,
    value,
    required,
    onChange,
    disabled,
    forceValidation,
  }) => (
    <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
      <label>{label}</label>
      <TextBox
        type={enums.components.textBoxType.date}
        value={value}
        disabled={disabled}
        required={required}
        forceValidation={forceValidation}
        onChange={onChange}
      />
    </div>
  ),

  numberField: ({
    additionalClass,
    label,
    value,
    required,
    onChange,
    disabled,
    forceValidation,
  }) => (
    <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
      <label>{label}</label>
      <TextBox
        type={enums.components.textBoxType.number}
        value={value}
        disabled={disabled}
        required={required}
        forceValidation={forceValidation}
        onChange={onChange}
      />
    </div>
  ),

  locations: ({
    additionalClass,
    label,
    value,
    locationType,
    placeholder,
    options,
    forceValidation,
    disabled,
    required,
  }) => (
    <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
      <label>{label}</label>
      <Locations
        data={value}
        locationType={locationType}
        placeholder={placeholder}
        forceValidation={forceValidation}
        disabled={disabled}
        required={required}
        externalOptions={options}
      />
    </div>
  ),

  mask: ({
    additionalClass,
    label,
    value,
    mask,
    required,
    maxLength,
    charsLeft,
    onChange,
    disabled,
    placeholder,
    removeMask,
    forceValidation,
  }) => (
    <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
      <label>{label}</label>
      <TextBox
        type={enums.components.textBoxType.mask}
        value={value}
        placeholder={placeholder}
        mask={mask}
        required={required}
        forceValidation={forceValidation}
        disabled={disabled}
        removeMask={removeMask}
        onChange={onChange}
        maxLength={maxLength}
      />
      {charsLeft && (
        <div className="exon-field-character-counter">
          <span>{charsLeft} caracteres restantes</span>
        </div>
      )}
    </div>
  ),
  PaymentSelector: ({
    additionalClass,
    label,
    value,
    paymentForms,
    forceValidation,
    disabled,
    required,
    refName,
    onPaymentSelect,
  }) => (
    <div className={`exon-field ${additionalClass ? additionalClass : ''}`}>
      <label>{label}</label>
      <PaymentSelector
        items={paymentForms || []}
        selectedItem={value}
        required={required}
        forceValidation={forceValidation}
        addCard={true}
        onPaymentSelected={(payment) => onPaymentSelect(refName, payment)}
        disabled={disabled}
      />
    </div>
  ),
};

export default FieldTemplate;
