interface ModalBodyProps {
  children?: React.ReactNode;
  className?: string;
  collapse?: boolean;
}
import * as SC from './styled';

export function ModalBody({
  children,
  className,
  collapse = false,
}: ModalBodyProps) {
  return (
    <SC.ModalBodyContainer className={className} collapse={collapse}>
      {children}
    </SC.ModalBodyContainer>
  );
}
