'use strict';

import React from 'react';
import Template from './room-summary-card.template.js';

export default class RoomSummaryCardComponent extends React.Component {
  render() {
    return <Template {...this.props} />;
  }
}
