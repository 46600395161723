import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';

const ApprovalStatusTemplate = ({ statusData }) => {
  const { getFormattedDateObjFromDateObject } = utils.formatters.date;

  return (
    <div className="exon-approval-status">
      <div className="exon-box-approval-status">
        <table className="exon-styleless-table">
          <thead>
            <tr>
              <th>Possíveis aprovadores</th>
            </tr>
          </thead>
          <tbody>
            {statusData.map((item, index) => {
              const { dateFormatted, timeFormatted } = item.approvalDate
                ? getFormattedDateObjFromDateObject(item.approvalDate)
                : {};

              return (
                <tr key={index}>
                  {item?.costCenterApprover ? (
                    <td title="Responsável Centro de Custo">Resp. CC</td>
                  ) : (
                    <td>{`Nível ${item.level}`}</td>
                  )}
                  <td>{item.name}</td>
                  <td>{`Status: ${enums.approvalStatus[item.status]}`}</td>
                  <td>
                    {dateFormatted ? `${dateFormatted} ${timeFormatted}` : ''}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApprovalStatusTemplate;
