import Grid from '../../../../grid/grid.component.js';
import TextBox from '../../../../textbox/textbox.component.js';
import Locations from '../../../../locations/locations.component.js';
import Select from '../../../../select/select.component.js';
import PaymentSelector from '../../../../common/payment-selector/payment-selector.component.js';
import Accordion from '../../../../common/accordion/accordion.component.js';
import AccordionItem from '../../../../common/accordion/accordion-item.component.js';
import Popup from '../../../../popup/popup.component.js';
import AirTicketDetails from '../../booking-air-tickets/booking-ticket-details/booking-ticket-details.component.js';

import Wizard from '../../../../common/wizard/wizard.component.js';
import Step from '../../../../common/wizard/wizard-step.component.js';
import Feedback from '../../../../common/feedback/feedback.component.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';

const Template = {
  main: ({
    stepsControl,
    ticketSelection,
    paymentSelection,
    replacement,
    alert,
  }) => {
    const steps = [
      <Step
        key={'step-1'}
        nextButtonLabel={
          replacement.props.replacing && !replacement.props.inputTicketNumber
            ? 'Simular Reemissão'
            : null
        }
      >
        <Template.steps.ticketSelection
          props={ticketSelection.props}
          stepNumber={1}
          events={ticketSelection.events}
          replacement={replacement.props.replacing}
        />
      </Step>,
    ];

    if (replacement.props.replacing) {
      steps.push(
        <Step key={'step-2'}>
          <Template.steps.replacementSimulation
            ticketProps={ticketSelection.props}
            stepNumber={2}
            replacementProps={replacement.props}
            replacementEvents={replacement.events}
          />
        </Step>,
      );
    }

    steps.push(
      <Step key={'step-3'} nextButtonLabel={'Emitir'}>
        <Template.steps.paymentSelection
          ticketProps={ticketSelection.props}
          paymentProps={paymentSelection.props}
          replacementProps={replacement.props}
          stepNumber={3}
          paymentSelectionEvents={paymentSelection.events}
        />
      </Step>,
    );

    return (
      <div className="exon-air-ticket-issue-flow">
        <Wizard
          onFinishClick={stepsControl.events.onFinishClicked}
          onCancelClick={stepsControl.events.onCancelClicked}
          onStepChange={stepsControl.events.onStepChanged}
          disableNextButton={stepsControl.props.disableNextButton}
        >
          {steps}
        </Wizard>

        {alert.props.show && (
          <div className="exon-box-popup-feedback">
            <Popup
              exonStyle={false}
              cancelButtonClick={alert.events.onCloseClicked}
            >
              <Feedback
                description={alert.props.message}
                details={alert.props.details}
                feedbackType={alert.type}
              />
            </Popup>
          </div>
        )}
      </div>
    );
  },

  steps: {
    ticketSelection: ({ props, events, stepNumber, replacement }) => {
      let keyPrefix = `step-${stepNumber}_ticket-selection`;
      return (
        <div className="exon-air-ticket-issue-flow_ticket-selection-step">
          <Accordion key={`${keyPrefix}_accordion`} retractable={true}>
            {props.tickets.map((ticket, index) => {
              let ticketKeyPrefix = `${keyPrefix}-${ticket.id}_${index}`;
              let title = (
                <ul>
                  <li>{ticket.id}</li>
                  <li>
                    {!replacement && (
                      <input
                        name={`ticket-selection-${ticket.id}`}
                        type="checkbox"
                        value={ticket.id}
                        checked={ticket.checked}
                        onChange={(event) =>
                          events.onTicketCheckboxChanged(event, ticket.id)
                        }
                      />
                    )}
                  </li>
                  <li>{ticket.traveler.fullName || ticket.traveler.name}</li>
                  <li>{ticket.identifier}</li>
                  <li>
                    Total{' '}
                    <strong>{ticket.fare.travelerValue.total.formatted}</strong>
                  </li>
                </ul>
              );

              return (
                <AccordionItem
                  key={`${ticketKeyPrefix}_accordion-item`}
                  title={title}
                  opened={index == 0}
                >
                  <Template.ticket.detail
                    ticket={ticket}
                    dataSources={props.dataSources}
                    events={events}
                  />
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      );
    },
    paymentSelection: ({
      ticketProps,
      paymentProps,
      replacementProps,
      stepNumber,
      paymentSelectionEvents,
    }) => {
      let keyPrefix = `step-${stepNumber}_payment-selection`;
      const selectedTickets = ticketProps.tickets.filter((t) => t.checked);
      return (
        <div className="exon-air-ticket-issue-flow_payment-selection-step">
          <Accordion
            key={`${keyPrefix}_accordion`}
            retractable={true}
            openMultiple={true}
          >
            {selectedTickets?.map((ticket, index) => {
              let title = (
                <ul>
                  <li>{ticket.id}</li>
                  <li></li>
                  <li>{ticket.traveler.fullName || ticket.traveler.name}</li>
                  <li>{ticket.identifier}</li>
                  <li>
                    Total{' '}
                    <strong>{ticket.fare.travelerValue.total.formatted}</strong>
                  </li>
                </ul>
              );

              return (
                <AccordionItem
                  key={`${keyPrefix}_accordion-item`}
                  title={title}
                  opened={true}
                >
                  <Template.payment
                    ticket={ticket}
                    canClone={selectedTickets.length > 1}
                    replacementProps={replacementProps}
                    paymentProps={paymentProps}
                    paymentSelectionEvents={paymentSelectionEvents}
                  />
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      );
    },
    replacementSimulation: ({
      stepNumber,
      ticketProps,
      replacementProps,
      replacementEvents,
    }) => {
      let keyPrefix = `step-${stepNumber}_replacement-simulation`;
      return (
        <div className="exon-air-ticket-issue_replacement-simulation-step">
          <Accordion key={`${keyPrefix}_accordion`} retractable={true}>
            {ticketProps.tickets.map((ticket, index) => {
              let ticketKeyPrefix = `${keyPrefix}-${ticket.id}_${index}`;
              let title = (
                <ul>
                  <li>{ticket.id}</li>
                  <li></li>
                  <li>{ticket.traveler.fullName || ticket.traveler.name}</li>
                  <li>{ticket.identifier}</li>
                  <li>
                    Total{' '}
                    <strong>{ticket.fare.travelerValue.total.formatted}</strong>
                  </li>
                </ul>
              );

              return (
                <AccordionItem
                  key={`${ticketKeyPrefix}_accordion-item`}
                  title={title}
                  opened={index == 0}
                >
                  <Template.replacement
                    ticket={ticket}
                    replacementProps={replacementProps}
                    replacementEvents={replacementEvents}
                  />
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      );
    },
  },

  ticket: {
    summary: ({ ticket }) => {
      let rows = [];
      ticket.airTrips.forEach((airTrip, tripIndex) => {
        airTrip.flights.forEach((flight, flightIndex) => {
          rows.push({
            identifier: `${tripIndex}_${flightIndex}`,
            tripIndex,
            flightIndex,
            airCompany: flight.airline.name,
            flightNumber: flight.flightNumber,
            airClass: flight.cabinType,
            departure: flight.departure.iata,
            arrival: flight.arrival.iata,
            departureDate: utils.formatters.dateObjToString(
              flight.departureDate,
              true,
            ),
            arrivalDate: utils.formatters.dateObjToString(
              flight.arrivalDate,
              true,
            ),
          });
        });
      });

      let ticketFare = ticket.fare.travelerValue;

      return (
        <div className="exon-air-ticket-issue_ticket-summary">
          <div className="exon-air-flights-table">
            <Grid
              hideCheckboxes={true}
              hideFilter={true}
              columns={[
                {
                  label: '',
                  refName: 'identifier',
                  identifier: true,
                  hide: true,
                },
                { label: '', refName: 'airCompany' },
                { label: 'Nº Voo', refName: 'flightNumber' },
                { label: 'Classe', refName: 'airClass' },
                { label: 'Origem (IATA)', refName: 'departure' },
                { label: 'Destino (IATA)', refName: 'arrival' },
                { label: 'Saída', refName: 'departureDate' },
                { label: 'Chegada', refName: 'arrivalDate' },
              ]}
              rows={rows}
            />
          </div>
          <div className="exon-air-ticket-pricing-summary">
            <ul>
              <li>{`Tarifa: ${ticketFare.fare.formatted}`}</li>
              <li>{`Taxas: ${ticketFare.taxes.formatted}`}</li>
              <li>{`Tx. DU: ${ticketFare.du.formatted}`}</li>
              <li>{`FEE: ${ticketFare.fee.formatted}`}</li>
            </ul>
          </div>
        </div>
      );
    },
    detail: ({ ticket, dataSources, events }) => {
      const onChangeFareFields = (ticket, field, value) => {
        events.onFareFieldChange(ticket.id, field.propRef, value);
      };
      const onChangeTicketFields = (ticket, field, value) => {
        events.onTicketInfoFieldChange(ticket.id, field.propRef, value);
      };

      //const firstBlockFields = [
      //    {
      //        propRef: 'netFare',
      //        label: 'Tarifa cheia:',
      //        value: ticket.fare.netFare.value,
      //        readOnly: true,
      //        type: enums.components.textBoxType.number
      //    },
      //    {
      //        propRef: 'equivalentFare',
      //        label: 'Tarifa comparativa:',
      //        value: ticket.fare.equivalentFare.value,
      //        readOnly: true,
      //        type: enums.components.textBoxType.number
      //    },
      //    {
      //        propRef: 'basicFare',
      //        label: 'Tarifa base:',
      //        value: ticket.fare.basicFare.value,
      //        readOnly: true,
      //        type: enums.components.textBoxType.number
      //    },
      //    {
      //        propRef: 'sale',
      //        label: 'Tarifa vendida:',
      //        value: ticket.fare.sale.value,
      //        readOnly: true,
      //        type: enums.components.textBoxType.number
      //    },
      //    {
      //        propRef: 'markedFare',
      //        label: 'Tarifa de mercado:',
      //        value: ticket.fare.markedFare.value,
      //        type: enums.components.textBoxType.number,
      //        onChange: onChangeFareFields
      //    },
      //];
      //const secondBlockFields = [
      //    {
      //        propRef: 'comission',
      //        label: 'Sobre Comissão:',
      //        value: ticket.fare.comission.value,
      //        onChange: onChangeFareFields,
      //        type: enums.components.textBoxType.number
      //    },
      //    {
      //        propRef: 'incentive',
      //        label: 'Incentivo:',
      //        value: ticket.fare.incentive.value,
      //        onChange: onChangeFareFields,
      //        type: enums.components.textBoxType.number
      //    },
      //    {
      //        propRef: 'incentiveType',
      //        label: 'Tipo de Incentivo:',
      //        value: ticket.fare.incentiveType.value,
      //        onChange: onChangeFareFields,
      //        type: enums.components.textBoxType.number
      //    }
      //];
      const thirdBlockFields = [
        //{
        //    propRef: 'endosement',
        //    label: 'Endosso:',
        //    value: ticket.info.endosement,
        //    onChange: onChangeTicketFields
        //},
        {
          propRef: 'tourCode',
          label: 'TourCode:',
          value: ticket.info.tourCode,
          onChange: onChangeTicketFields,
        },
        {
          propRef: 'issuerAirLine',
          label: 'Companhia Emissora:',
          value:
            ticket.airTrips[0].issuerAirline &&
            ticket.airTrips[0].issuerAirline.code,
          onChange: onChangeTicketFields,
          type: Template.ticket._fieldType.select,
          readOnly: true,
          dataSource: dataSources.airLines,
        },
        {
          propRef: 'foidType',
          label: 'FOID type:',
          value: ticket.info.foidType,
          onChange: onChangeTicketFields,
          type: Template.ticket._fieldType.select,
          readOnly: true,
          dataSource: dataSources.foidTypes,
        },
        {
          propRef: 'foidInfo',
          label: 'FOID Info:',
          value: ticket.info.foidInfo,
          readOnly: true,
          onChange: onChangeTicketFields,
        },
        {
          propRef: 'foidDocumentContry',
          label: 'Pais de Origem do documento FOID:',
          placeholder: 'Digite o país...',
          readOnly: true,
          value: ticket.info.foidDocumentContry,
          onChange: onChangeTicketFields,
          type: Template.ticket._fieldType.location,
          locationType: enums.locationType.country,
          dataSource: dataSources.locations,
        },
      ];

      return (
        <div className="exon-air-ticket-issue_ticket-detail-container">
          <Template.ticket.summary ticket={ticket} />
          <div className="exon-air-ticket-issue_ticket-detail">
            {/*<div className="exon-box-fields first-block">*/}
            {/*    {firstBlockFields.map((field, index) => (<Template.ticket.detailField key={`first-block_field-${index}`} fieldData={field} ticket={ticket} />))}*/}
            {/*</div>*/}
            {/*<hr />*/}
            {/*<div className="exon-box-fields second-block">*/}
            {/*    {secondBlockFields.map((field, index) => (<Template.ticket.detailField key={`second-block_field-${index}`} fieldData={field} ticket={ticket} />))}*/}
            {/*</div>*/}
            <hr />
            <div className="exon-box-fields third-block">
              {thirdBlockFields.map((field, index) => (
                <Template.ticket.detailField
                  key={`first-block_field-${index}`}
                  fieldData={field}
                  ticket={ticket}
                />
              ))}
            </div>
          </div>
        </div>
      );
    },

    _fieldType: {
      location: 'LOCATION',
      select: 'SELECT',
    },

    detailField: ({ fieldData, ticket }) => {
      let fieldComponent = null;
      if (fieldData.type == Template.ticket._fieldType.location) {
        fieldComponent = (
          <Locations
            data={fieldData.value}
            locationType={fieldData.locationType}
            placeholder={fieldData.placeholder}
            externalOptions={{
              dataSource: fieldData.dataSource,
              events: {
                onSelected: (value) => {
                  fieldData.onChange(ticket, fieldData, value);
                },
              },
            }}
          />
        );
      } else if (fieldData.type == Template.ticket._fieldType.select) {
        fieldComponent = (
          <Select
            data={fieldData.value}
            placeholder={fieldData.placeholder}
            externalOptions={{
              dataSource: fieldData.dataSource,
              events: {
                onSelected: (value) => {
                  fieldData.onChange(ticket, fieldData, value);
                },
              },
            }}
          />
        );
      } else {
        fieldComponent = (
          <TextBox
            value={fieldData.value}
            type={fieldData.type}
            onChange={(value) => {
              fieldData.onChange(ticket, fieldData, value);
            }}
          />
        );
      }

      return (
        <div className={`exon-field ${fieldData.readOnly ? 'readonly' : ''}`}>
          <label>{fieldData.label}</label>
          {fieldComponent}
        </div>
      );
    },
  },
  replacement: ({ ticket, replacementProps, replacementEvents }) => {
    let oldFareData = null;
    let newFareData = null;
    let difference = null;
    let summary = null;

    if (replacementProps.simulationResult) {
      oldFareData = replacementProps.simulationResult.oldFareData;
      newFareData = replacementProps.simulationResult.newFareData;
      difference = replacementProps.simulationResult.difference;
      summary = replacementProps.simulationResult.summary;
    }

    return (
      <div className="exon-air-ticket-issue_replacement-container">
        <Template.ticket.summary ticket={ticket} />
        <hr />
        {
          //replacementProps.inputTicketNumber &&
          <div className="exon-box-fields">
            <div
              className={`exon-field simulation-actions ${
                replacementProps.disableInputTicketNumber ? 'readonly' : ''
              }`}
            >
              <label>Simulação</label>
              <TextBox
                value={replacementProps.ticketNumber}
                placeholder="Informe o número de bilhete"
                onChange={replacementEvents.onTicketNumberChanged}
              />
              <button
                type="button"
                className="exon-button exon-button-primary"
                onClick={replacementEvents.onSimulateIssueClicked}
              >
                Simular
              </button>
            </div>
          </div>
        }
        {replacementProps.simulationResult && (
          <div className="exon-air-ticket-issue_replacement-simulation-result">
            <label>Simulação</label>
            <Grid
              hideCheckboxes={true}
              hideFilter={true}
              columns={[
                {
                  label: '',
                  refName: 'identifier',
                  identifier: true,
                  hide: true,
                },
                { label: '', refName: 'fieldName' },
                {
                  label: 'Crédito do Bilhete Substituído',
                  refName: 'oldValue',
                },
                { label: 'Débitos da Nova Reserva', refName: 'newValue' },
                { label: 'Saldo', refName: 'balance' },
              ]}
              rows={[
                {
                  identifier: 0,
                  fieldName: 'Tarifa',
                  oldValue: oldFareData.fare.formatted,
                  newValue: newFareData.fare.formatted,
                  balance: difference.fare.formatted,
                },
                {
                  identifier: 1,
                  fieldName: 'Rep a Terceiros',
                  oldValue: oldFareData.thirdPartTransfer.formatted,
                  newValue: newFareData.thirdPartTransfer.formatted,
                  balance: difference.thirdPartTransfer.formatted,
                },
                {
                  identifier: 2,
                  fieldName: 'Taxas',
                  oldValue: oldFareData.taxes.formatted,
                  newValue: newFareData.taxes.formatted,
                  balance: difference.taxes.formatted,
                },
              ]}
            />
            <ul>
              <li>
                Multa: <strong>{summary.penalty.formatted}</strong>
              </li>
              <li>
                Valor a pagar: <strong>{summary.amountToPay.formatted}</strong>
              </li>
              <li>
                Valor a receber:{' '}
                <strong>{summary.amountToReceive.formatted}</strong>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  },
  payment: ({
    key,
    ticket,
    canClone,
    paymentProps,
    replacementProps,
    paymentSelectionEvents,
  }) => {
    return (
      <div className="exon-air-ticket-issue_payment-detail-container">
        <Template.ticket.summary ticket={ticket} />
        <div className="exon-air-ticket-issue_payment-detail">
          <hr />

          {replacementProps.simulationResult &&
            replacementProps.simulationResult.summary.amountToReceive.value && (
              <label>
                <input type="checkbox" /> Utilizar crédito do bilhete simulado (
                {
                  replacementProps.simulationResult.summary.amountToReceive
                    .formatted
                }
                )
              </label>
            )}
          <div className="exon-box-fields payment-field">
            <div className="exon-field">
              <label>Forma de Pagamento</label>
              {paymentProps.paymentForms && paymentProps.paymentForms.length && (
                <PaymentSelector
                  items={paymentProps.paymentForms}
                  selectedItem={
                    ticket.payments &&
                    ticket.payments.length &&
                    ticket.payments.length > 0
                      ? ticket.payments[0]
                      : null
                  }
                  required={true}
                  forceValidation={paymentProps.validation.showValidationResult}
                  addCard={true}
                  onPaymentSelected={(paymentData) => {
                    paymentSelectionEvents.onPaymentSelected(
                      ticket.referenceCode,
                      paymentData,
                      0,
                    );
                  }}
                />
              )}
            </div>
          </div>

          {paymentProps.inputTicketNumber && (
            <div className="exon-box-fields">
              <div className="exon-field ticket-number-input ">
                <label>Bilhete</label>
                <TextBox
                  value={paymentProps.ticketNumber}
                  placeholder="Informe o número de bilhete"
                  required={true}
                  forceValidation={paymentProps.validation.showValidationResult}
                  onChange={paymentSelectionEvents.onTicketNumberChanged}
                />

                <button
                  type="button"
                  className="exon-button exon-button-secondary"
                  onClick={(event) =>
                    paymentSelectionEvents.onSearchTicketClicked(ticket.id)
                  }
                >
                  Consultar
                </button>
              </div>
            </div>
          )}

          {paymentProps.replacementData && (
            <div className="exon-box-fields ticket-replacement-data">
              {paymentProps.replacementData.successfully && (
                <div className="air-ticket-replacement-details">
                  <AirTicketDetails
                    airTicket={paymentProps.replacementData.airTicket}
                    showActions={false}
                  />
                  <ul>
                    <li>
                      Multa:{' '}
                      <strong>
                        {paymentProps.replacementData.summary.penalty.formatted}
                      </strong>
                    </li>
                    <li>
                      Valor a Pagar:{' '}
                      <strong>
                        {
                          paymentProps.replacementData.summary.amountToPay
                            .formatted
                        }
                      </strong>
                    </li>
                    <li>
                      Valor a Receber:{' '}
                      <strong>
                        {
                          paymentProps.replacementData.summary.amountToReceive
                            .formatted
                        }
                      </strong>
                    </li>
                  </ul>
                  <div className="ticket-selection-actions">
                    <button
                      type="button"
                      className="exon-button exon-button-secondary"
                      onClick={
                        paymentSelectionEvents.onCancelTicketReplacementClicked
                      }
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="exon-button exon-button-secondary_enabled"
                      onClick={(event) =>
                        paymentSelectionEvents.onConfirmTicketReplacementClicked(
                          ticket.id,
                        )
                      }
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              )}
              {!paymentProps.replacementData.successfully && (
                <div className="exon-field error">
                  {paymentProps.replacementData.errorMessage}
                </div>
              )}
            </div>
          )}

          {paymentProps.showSecondaryPayment && (
            <div className="exon-box-fields second-payment-field">
              <div className="exon-field">
                <label>
                  Forma de Pagamento{' '}
                  <strong>
                    para Saldo Restante (
                    {
                      paymentProps.confirmedReplacementTicket.summary
                        .amountToPay.formatted
                    }
                    )
                  </strong>
                </label>
                <PaymentSelector
                  items={paymentProps.secondaryPaymentForms}
                  selectedItem={
                    ticket.payments &&
                    ticket.payments.length &&
                    ticket.payments.length > 1
                      ? ticket.payments[1]
                      : null
                  }
                  required={true}
                  forceValidation={paymentProps.validation.showValidationResult}
                  addCard={true}
                  onPaymentSelected={(paymentData) => {
                    paymentSelectionEvents.onPaymentSelected(
                      ticket.id,
                      paymentData,
                      1,
                    );
                  }}
                />
              </div>
            </div>
          )}

          {canClone && (
            <button
              type="button"
              className="exon-button exon-button-secondary"
              onClick={(event) =>
                paymentSelectionEvents.onCloneForAllButtonClicked(ticket.id)
              }
            >
              Copiar para todos
            </button>
          )}
        </div>
      </div>
    );
  },
};

export default Template;
