'use strict';

import {
  convertDateObjectToDate,
  formatDateToString
} from '@shared/utils/date.utils';
import React from 'react';
import { apiResponse } from '../../../utils/apiResponse.js';
import { componentUtil } from '../../../utils/componentUtil.js';
import { enums, travelEnums } from '../../../utils/enums.js';
import { paymentResolver } from '../../../utils/resolvers/paymentResolver.js';
import { utils } from '../../../utils/utils.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import FeedbackComponent from '../../common/feedback/feedback.component.js';
import splitMethods from '../../cost-center-split/split-methods.js';
import CheckoutTemplate from './checkout.template.js';

export default class CheckoutComponent extends React.Component {
  newCreditCardIdentifier = '_new_cc';
  addedCreditCardIdentifier = '_added_cc';

  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.selects.selectRooms, this);
    componentUtil.react.bindMethods(this.selects.selectCards, this);
    componentUtil.react.bindMethods(this.selects.selectActionRv, this);
    componentUtil.react.bindMethods(this.selects.selectCostCenter, this);
    componentUtil.react.bindMethods(this.selects.selectReasonTravel, this);
    componentUtil.react.bindMethods(this.selects.selectJustifications, this);
    componentUtil.react.bindMethods(
      this.selects.selectPaymentInformation,
      this,
    );
    componentUtil.react.bindMethods(this.selects.selectRequester, this);
    componentUtil.react.bindMethods(this.hotelGuarantee, this);

    componentUtil.react.bindMethods(this.selects.prebookingManager, this);
    componentUtil.react.bindMethods(this.costCenterSplit, this);

    this.getCustomValidationRS = this.getCustomValidationRS.bind(this);

    const isRvOffline = this.props.isRvOffline;

    const productsData = !isRvOffline ? this.props.data.productsData : null;

    let travelers = {};

    if (!isRvOffline && productsData.travelers)
      travelers = {
        adults: productsData.travelers.adultCount,
        childrens: productsData.travelers.childCount,
        infants: productsData.travelers.infantCount,
      };
    else
      travelers = !isRvOffline
        ? productsData.searchParameters.travelers
        : props.data.travelers;

    travelers.confirmedTravelers = [];
    travelers.travelersOnReservation = [];

    //Se vier preenchido - Retrieve Externa
    if (this.props.data.travelers && this.props.importExternalBooking) {
      this.props.data.travelers.map((traveler, index) => {
        travelers.travelersOnReservation.push(traveler);
      });
    }

    let hotelList = !isRvOffline ? this.getHotelList(productsData) : [];
    let roomList = !isRvOffline ? this.getRoomList(productsData) : [];

    const preBookingData = !isRvOffline ? this.props.data.preBookingData : null;

    this.state = {
      contextId: null,
      serviceRequestExisting: null,
      serviceRequestId: null,
      requester: this.props.user,
      preBookingData: preBookingData,
      agency: this.props.agencyAndCompany.agency,
      companySelected: this.props.agencyAndCompany.company,

      currentProducts: productsData,
      rvProducts: null,

      tripTotalValue: !isRvOffline ? preBookingData.summary.total : null,
      requestNumber: '',
      loadingSearchRV: false,

      selectedRoom:
        roomList.length > 0 && roomList.length == 1 ? roomList[0] : 0,
      selectedHotel:
        hotelList.length > 0 && hotelList.length == 1 ? hotelList[0] : 0,
      selectedReasonTravel: {},
      costCenterList: [],
      selectedCostCenter: 0,
      isCostCenterSplitEnabled: false,
      isCostCenterSplitAvailable: false,
      isEqualSplitEnabled: false,
      costCenterSplit: [],
      isCostCenterAllowed: false,
      selectActionRv: {
        value: 1,
        label: 'Criar Nova',
      },
      selectedPaymentInformation: null,
      selectedCard: 0,
      selectedHotelGuarantee: null,
      selectedJustification: 0,
      selectedRequester: null,
      hotelList: hotelList,
      roomList: roomList,

      loaderTravelRequest: false,
      travelers: travelers,

      popupFeedbackData: {
        show: false,
      },
      popupFeedbackConfirmData: {
        show: false,
      },
      popupJustifyPolicyData: {
        show: false,
      },
      popupUnconfirmedRequestData: {
        show: false,
      },
      showLoadingMask: false,
      customFields: [],
      hotelGuarantees:
        preBookingData && preBookingData.hotel
          ? this.hotelGuarantee.getHotelGuarantees(preBookingData.hotel.hotels)
          : [],
      showHotelGuarantee:
        !isRvOffline && preBookingData && preBookingData.hotel
          ? this.hotelGuarantee.getHotelGuaranteeVisibility(
            preBookingData.hotel.hotels,
          )
          : false,

      cardFiedsData: {},
      hotelGuaranteeCardData: {},

      newlyAddedCards: [],
      paymentForms: [],
      disablePaymentforms: true,

      showValidationsfields: false,
      showValidationsfieldsJustification: false,
      policyViolationAlertsJustified: null,
      policyViolationAlerts: null,
      processBookingData: null,
      descriptionJustification: null,
      errorMessages: [],
      disabledFields: false,
      validationServiceRequestId: true,
      validationAccommodationTraveler: true,
      popupConfirmationAlertsData: {
        show: false,
      },
      policyViolationAlertResp: null,
      confirmationAlertsData: null,
      confirmationAlerts: {
        none: 0,
        approvalFlowByRequester: 1,
      },
      companySettings: {},
      disableFlashPay: true,
      popupProcessBookingData: {
        show: false,
      },
      isReserveAndSubmit: true,
      payments: [],
    };
  }

  separatePaymentPerProduct() {
    return this.state.companySettings.separatePaymentPerProduct && !this.props.isRvOffline;
  }

  componentDidMount() {
    this.dataSources.customFields.callLoad();
    this.isCostCenterAllowed();
    this.dataSources.companySettings.callLoad();
  }

  render() {
    return (
      <ErrorBoundary>
        <CheckoutTemplate.main
          //product List
          rvProducts={this.state.rvProducts}
          serviceRequestExisting={this.state.serviceRequestExisting}
          currentProducts={this.state.currentProducts}
          tripTotalValue={this.state.tripTotalValue}
          onOpenExistingRequestClick={this.events.onOpenExistingRequestClicked}
          requestNumber={this.state.requestNumber}
          loadingSearchRV={this.state.loadingSearchRV}
          checkoutPreBookingData={this.props.checkoutPreBookingData}
          //select RV
          selectActionRv={this.selects.selectActionRv.getProps()}
          loaderTravelRequest={this.state.loaderTravelRequest}
          onRequestNumberTextboxOnChange={
            this.events.onRequestNumberTextboxOnChanged
          }
          onSearchExistingTravelRequestClick={
            this.events.onSearchExistingTravelRequestClicked
          }
          onDeleteCurrentProductClick={
            this.events.onDeleteCurrentProductClicked
          }
          onDeleteRvProductClick={this.events.onDeleteRvProductClicked}
          getCustomValidationRS={this.getCustomValidationRS}
          //Quartos
          roomOptions={this.selects.selectRooms.getProps()}
          hotelsOptions={this.selects.selectHotels.getProps()}
          showValidationAdditionalFieldsTraveler={
            this.state.showValidationAdditionalFieldsTraveler
          }
          onValidationAdditionalFieldsClick={
            this.events.onValidationAdditionalFieldsClicked
          }
          //Centro de custo
          costCenterSplit={this.costCenterSplit.getProps(
            this.props.dataSources.costCenter,
            this.state.costCenterList,
            this.CallLoadPaymentIfAllowed.bind(this),
          )}
          isCostCenterSplitEnabled={this.state.isCostCenterSplitEnabled}
          isEqualSplitEnabled={this.state.isEqualSplitEnabled}
          isCostCenterSplitAvailable={
            !this.props.isRvOffline && this.state.isCostCenterSplitAvailable
          }
          costCenterOptions={this.selects.selectCostCenter.getProps()}
          isCostCenterAllowed={this.state.isCostCenterAllowed}
          //Forma de pagamento
          paymentInformationOptions={this.selects.selectPaymentInformation.getProps()}
          cardFiedsData={this.state.cardFiedsData}
          disablePaymentforms={this.state.disablePaymentforms}
          //Motivos da Viagem
          reasonTravelOptions={this.selects.selectReasonTravel.getProps()}
          //RV Infos
          travelers={this.state.travelers}
          travelersDataSources={this.props.dataSources}
          onTravelerConfirm={this.events.onTravelerConfirmed}
          onTravelerAutoCompleteInputChange={
            this.events.onTravelerAutoCompleteInputChanged
          }
          onCloseTravelerClick={this.events.onCloseTravelerClicked}
          //Custom Fields
          customFieldsOptions={this.customFields.getProps()}
          //Hotel Guarantee
          hotelGuaranteeOptions={this.hotelGuarantee.getProps()}
          hotelGuaranteeCardOptions={this.hotelGuarantee.getCardProps()}
          showHotelGuarantee={this.state.showHotelGuarantee}
          //Alerts
          showInvalidSamePassengerAlert={
            this.state.showInvalidSamePassengerAlert
          }
          onCloseSamePassengerAlertClick={
            this.events.onCloseSamePassengerAlertClicked
          }
          //processBooking
          popupFeedbackData={this.state.popupFeedbackData}
          popupFeedbackConfirmData={this.state.popupFeedbackConfirmData}
          popupJustifyPolicyData={this.state.popupJustifyPolicyData}
          popupUnconfirmedRequestData={this.state.popupUnconfirmedRequestData}
          selectJustificationsOptions={this.selects.selectJustifications.getProps()}
          eventsProcessBooking={this.events.processBooking}
          showLoadingMask={this.state.showLoadingMask}
          isImportExternal={
            this.props.importExternalBooking
              ? this.props.importExternalBooking
              : false
          }
          popupConfirmationAlertsData={this.state.popupConfirmationAlertsData}
          // Agency And Company
          agencyAndCompany={this.props.agencyAndCompany}
          requesterOptions={this.selects.selectRequester.getProps()}
          showValidationsFields={this.state.showValidationsfields}
          showValidationsfieldsJustification={
            this.state.showValidationsfieldsJustification
          }
          descriptionJustification={this.state.descriptionJustification}
          disabledFields={this.state.disabledFields}
          //Erros
          errorMessages={this.state.errorMessages}
          //Requisição off-line
          isRvOffline={this.props.isRvOffline}
          eventsRvOffline={this.events.eventsRvOffline}
          selectedHotel={this.state.selectedHotel}
          isFlashPayEnabled={(!this.props.isRvOffline) && this.state.companySettings.allowUseOfFlashPay}
          separatePaymentPerProduct={this.separatePaymentPerProduct()}
          popupProcessBookingData={this.state.popupProcessBookingData}
        />
      </ErrorBoundary>
    );
  }

  static getDerivedStateFromProps(props, state) {
    let travelers = props.isRvOffline
      ? { ...state.travelers, adults: props.data.travelers.adults }
      : state.travelers;

    return {
      travelers,
    };
  }

  getCustomValidationRS(data) {
    //TODO: Aplicar validação
    return {
      isValid: true,
      message: null,
    };
  }

  closePopupFeedback() {
    const popupFeedbackData = { ...this.state.popupFeedbackData, show: false };
    this.setState({ popupFeedbackData, policyViolationAlertsJustified: null });
  }

  closePopupConfirmationAlerts() {
    const popupConfirmationAlertsData = {
      ...this.state.popupConfirmationAlertsData,
      show: false,
    };
    this.setState({ popupConfirmationAlertsData });
  }

  events = {
    eventsRvOffline: {
      onCancelRvOfflineClicked: () => {
        this.props.data.events.onCancelRvOfflineClick &&
          this.props.data.events.onCancelRvOfflineClick();
      },
      onCreateRvOfflineClicked: () => {
        if (this.validateFieldsRV())
          this.props.data.events.onCreateRvOfflineClick &&
            this.props.data.events.onCreateRvOfflineClick(
              this.convertCheckoutDataToApiRequest(null),
            );
        else this.setState({ showValidationsfields: true });
      },
    },

    onRequestNumberTextboxOnChanged: (requestNumber) => {
      this.setState({ requestNumber });
    },

    onSearchExistingTravelRequestClicked: () => {
      let requestNumber = this.state.requestNumber;
      const contextId = this.state.companySelected?.value;

      let loadOptions = {
        id: requestNumber,
        contextId,
      };

      if (requestNumber && requestNumber !== '')
        this.dataSources.travelRequest.callLoad(loadOptions);
    },

    onDeleteCurrentProductClicked: (index, productType) => {
      let products = this.state.currentProducts;

      if (productType == enums.products.type.air)
        products.trips.splice(index, 1);
      else products.hotels.selectedHotels.splice(index, 1);

      products.allTariffedPrice = null;

      this.setState({
        currentProducts: products,
        tripTotalValue: null,
      });

      this.dataSources.preBooking.callPreBooking();
    },

    onDeleteRvProductClicked: (index, productType) => {
      let products = this.state.rvProducts;

      if (productType == enums.productType.air)
        products.travels.splice(index, 1);
      else products.hotels.selectedHotels.splice(index, 1);

      this.setState({
        rvProducts: products,
      });
    },

    onValidationAdditionalFieldsClicked: () => {
      this.setState({ showValidationAdditionalFieldsTraveler: true });
    },

    onCloseTravelerClicked: () => {
      let selectedRoom = this.state.selectedRoom;
      let selectedHotel = this.state.selectedHotel;
      let currentProducts = this.state.currentProducts;

      if (
        currentProducts.hotels &&
        currentProducts.hotels.selectedHotels &&
        currentProducts.hotels.selectedHotels.length > 0
      ) {
        selectedHotel = this.state.hotelList.length == 1 ? selectedHotel : 0;
        selectedRoom = this.state.roomList.length == 1 ? selectedRoom : 0;
      }
      this.setState({
        showValidationAdditionalFieldsTraveler: true,
        selectedHotel,
        selectedRoom,
      });
    },

    onTravelerConfirmed: (traveler, travelerNumber, selectedRoom) => {
      let travelers = this.state.travelers;
      let selectedHotel = this.state.selectedHotel;
      let currentProducts = this.state.currentProducts;

      traveler.travelerReference = travelerNumber;

      let indexConfirmedTravelers = travelers.confirmedTravelers.findIndex(
        (t) =>
          t &&
          t.id != undefined &&
          t.id != null &&
          t.id == traveler.id &&
          t.travelerReference != travelerNumber,
      );

      if (indexConfirmedTravelers != -1) {
        this.setState({ showInvalidSamePassengerAlert: true });
        return;
      }
      let roomList = [];
      if (
        currentProducts &&
        currentProducts.hotels &&
        currentProducts.hotels.selectedHotels
      ) {
        traveler.selectedRoom = { ...selectedRoom };
        traveler.selectedHotel = { ...selectedHotel };

        this.state.roomList.forEach((room) => {
          if (room.value == selectedRoom.value) {
            if (traveler.type == enums.travelerType.Adt) room.adultCount -= 1;
            if (traveler.type == enums.travelerType.Chd) room.childCount -= 1;
          }

          if (room.adultCount > 0 || room.childCount > 0) roomList.push(room);
        });

        selectedHotel = this.state.hotelList.length == 1 ? selectedHotel : 0;
        selectedRoom = roomList.length == 1 ? roomList[0] : 0;
      }

      travelers.confirmedTravelers[travelerNumber - 1] = traveler;
      this.resolveCostCenter(travelers.confirmedTravelers);

      this.setState({
        travelers,
        selectedRoom,
        roomList,
        selectedHotel,
        showValidationAdditionalFieldsTraveler: false,
        disableFlashPay: false,
      });

      this.CallLoadPaymentIfAllowed(null, null, travelers.confirmedTravelers);
    },
    onTravelerAutoCompleteInputChanged: (travelerId) => {
      let travelers = this.state.travelers;
      let roomList = [];
      let selectedRoom = this.state.selectedRoom;
      let currentProducts = this.state.currentProducts;

      if (
        currentProducts &&
        currentProducts.hotels &&
        currentProducts.hotels.selectedHotels
      ) {
        let traveler = travelers.confirmedTravelers.find(
          (traveler) => traveler && traveler.id == travelerId,
        );
        let travelerRoom = traveler.selectedRoom;
        travelerRoom.checked = false;

        if (traveler.type == enums.travelerType.Adt)
          travelerRoom.adultCount = 1;
        if (traveler.type == enums.travelerType.Chd)
          travelerRoom.childCount = 1;

        this.state.roomList.forEach((room) => {
          if (room.value == travelerRoom.value) {
            if (traveler.type == enums.travelerType.Adt) room.adultCount += 1;
            if (traveler.type == enums.travelerType.Chd) room.childCount += 1;
          }
          roomList.push(room);
        });

        if (!roomList.some((room) => room.value == travelerRoom.value)) {
          roomList.push(travelerRoom);
        }
        if (!roomList.length == 1) selectedRoom = travelerRoom;
      }

      travelers.confirmedTravelers = travelers.confirmedTravelers.map(
        (traveler) => (traveler && traveler.id != travelerId ? traveler : null),
      );

      this.setState({ travelers, roomList, selectedRoom });
    },

    onCloseSamePassengerAlertClicked: () => {
      this.setState({
        showInvalidSamePassengerAlert: false,
      });
    },

    processBooking: {
      feedbacks: {
        onClosePopupFeedbackClick: () => {
          this.closePopupFeedback();
        },

        onClosePopupUnconfirmedRequestClick: () => {
          const popupUnconfirmedRequestData = {
            ...this.state.popupUnconfirmedRequestData,
            show: false,
          };
          this.setState({ popupUnconfirmedRequestData });
        },

        onCloseFeedbackConfirmClick: () => {
          this.props.onCloseCheckoutClick && this.props.onCloseCheckoutClick();
        },

        onOpenRequestFeedbackConfirmData: () => {
          const popupFeedbackConfirmData = {
            ...this.state.popupFeedbackConfirmData,
            type: 1,
            description:
              'Em instantes você será direcionado(a) para a requisição gerada...',
          };

          let company = this.state.companySelected;
          let agency = this.state.agency;

          this.setState({ popupFeedbackConfirmData });

          let queryStringParameters = `?requestId=${popupFeedbackConfirmData.serviceRequestId}`;
          if (agency.isAgency && company.value !== 0)
            queryStringParameters += `&rootContextId=${company.value}`;

          this.props.navigate(`/travel/list${queryStringParameters}`);
        },

        onJustifyConfirmClicked: () => {
          let action = this.state.processBookingData.action;
          let policyViolationAlertsJustified =
            this.state.policyViolationAlertsJustified;
          let selectedJustification = this.state.selectedJustification;

          if (this.validateFieldsJustification()) {
            policyViolationAlertsJustified.justifications.forEach((item) => {
              item.justification.code = selectedJustification.code;
              item.justification.name = selectedJustification.label;
              item.justification.description =
                this.state.descriptionJustification;
            });

            const popupJustifyPolicyData = {
              ...this.state.popupJustifyPolicyData,
              show: false,
            };

            this.setState({
              popupJustifyPolicyData,
              selectedJustification: 0,
              policyViolationAlertsJustified,
              showValidationsfieldsJustification: false,
            });

            this.dataSources.processBooking.callLoad(action);
          } else {
            this.setState({ showValidationsfieldsJustification: true });
          }
        },
        onViolationsConfirmClicked: () => {
          let action = this.state.processBookingData.action;

          this.closePopupFeedback();
          this.dataSources.processBooking.callLoad(action);
        },

        onClosePopupJustifyPolicyClick: () => {
          const popupJustifyPolicyData = {
            ...this.state.popupJustifyPolicyData,
            show: false,
          };
          this.setState({ popupJustifyPolicyData, selectedJustification: 0 });
        },

        onConfirmationAlertsClick: () => {
          let policyViolationAlert = this.state.policyViolationAlertResp;

          let confirmationAlertsData = { ApprovalFlowByRequester: true };
          this.setState({ confirmationAlertsData });

          if (
            policyViolationAlert &&
            policyViolationAlert.violations &&
            !policyViolationAlert.violations[0].justification
          ) {
            this.closePopupConfirmationAlerts();
            let indexBlockPolicy = policyViolationAlert.violations.findIndex(
              (p) => p.actionType == enums.policyType.block,
            );
            let notifiesPolicy = policyViolationAlert.violations.filter(
              (p) => p.actionType == enums.policyType.notifies,
            );

            if (indexBlockPolicy != -1) {
              const popupFeedbackData = {
                ...this.state.popupFeedbackData,
                show: true,
                type: enums.feedbackType.info,
                description:
                  'Houve quebra de politicas, não será possivel efetuar a reserva solicitada!',
                policyViolation: policyViolationAlert.violations,
              };

              this.feedbacksDataManager.feedbacks(popupFeedbackData);
            } else if (notifiesPolicy.length > 0) {
              const popupFeedbackData = {
                ...this.state.popupFeedbackData,
                show: true,
                type: enums.feedbackType.info,
                description: 'Houve quebra de politicas!',
                policyViolation: notifiesPolicy,
                okButtonLabel: 'Confirmar',
              };

              this.feedbacksDataManager.feedbacks(popupFeedbackData, true);
            } else {
              this.feedbacksDataManager.justification(policyViolationAlert);
            }
          } else {
            let action = this.state.processBookingData.action;
            this.closePopupConfirmationAlerts();

            if (this.timeoutId) window.clearTimeout(this.timeoutId);

            this.timeoutId = setTimeout(() => {
              this.dataSources.processBooking.callLoad(action);
            }, 1000);
          }
        },
        onClosePopupConfirmationAlertsClick: () => {
          const popupConfirmationAlertsData = {
            ...this.state.popupConfirmationAlertsData,
            show: false,
          };
          this.setState({ popupConfirmationAlertsData });
        },

        onClosePopupProcessBookingClicked: () => {
          const popupProcessBookingData = {
            ...this.state.popupProcessBookingData,
            show: false,
          };

          this.setState({ popupProcessBookingData });
        },
        onCloseCheckoutClicked: () => {
          const popupProcessBookingData = {
            ...this.state.popupProcessBookingData,
            show: false,
          };

          this.setState({ popupProcessBookingData });

          this.props.onCloseCheckoutClick && this.props.onCloseCheckoutClick();
        },
        onOpenRequestConfirmData: () => {
          const popupProcessBookingData = {
            ...this.state.popupProcessBookingData,
            description:
              'Em instantes você será direcionado(a) para a requisição gerada...',
            paymentForms: [],
          };

          let company = this.state.companySelected;
          let agency = this.state.agency;

          this.setState({ popupProcessBookingData });

          let queryStringParameters = `?requestId=${popupProcessBookingData.serviceRequestId}`;
          if (agency.isAgency && company.value !== 0)
            queryStringParameters += `&rootContextId=${company.value}`;

          let urlOrigin = window.location.origin;
          window.location.href =
            urlOrigin + `/travel/list${queryStringParameters}`;
        },

        onSelectedPaymentDefaultClicked: async () => {
          const { paymentForms, selectedPaymentInformation, companySettings } = this.state;
          const { separatePaymentPerProduct } = companySettings;
          const { product } = enums.purchaseConfiguration;

          const updatedPaymentInfo = selectedPaymentInformation.map((selectedPayment) => {
            let defaultPayment = separatePaymentPerProduct
              ? paymentForms.find((payment) => payment.paymentForm.productType === selectedPayment.productType)
              : paymentForms.find((payment) => [product.all, product.air, product.hotel].includes(payment.paymentForm.productType));

            if (defaultPayment !== null)
              defaultPayment = paymentResolver.convertPaymentFormComponentObjToApi(defaultPayment.paymentForm)

            return defaultPayment || selectedPayment;
          });

          this.setState({ selectedPaymentInformation: updatedPaymentInfo });

          const BOOKING_RETRY_DELAY = 2000;
          setTimeout(async () => {
            await this.events.processBooking.actions.onBookTryAgain();
          }, BOOKING_RETRY_DELAY);
        },

      },

      actions: {
        onBookingClicked: () => {
          if (this.validateFieldsRV()) {
            this.setState({ isReserveAndSubmit: false });
            this.dataSources.processBooking.callLoad(
              enums.actionsBooking.reserve,
            );
          } else this.setState({ showValidationsfields: true });
        },

        onBookAndSubmitClicked: () => {
          if (this.validateFieldsRV()) {
            this.setState({ isReserveAndSubmit: true });
            this.dataSources.processBooking.callLoad(
              enums.actionsBooking.reserveAndSubmit,
            );
          } else this.setState({ showValidationsfields: true });
        },

        onBookTryAgain: () => {
          let popupProcessBookingData = {
            show: false,
          };
          this.setState({ showLoadingMask: true, popupProcessBookingData });

          if (this.state.isReserveAndSubmit)
            this.events.processBooking.actions.onBookAndSubmitClicked();
          else this.events.processBooking.actions.onBookingClicked();
        },
      },
    },
  };

  isCostCenterAllowed() {
    const licenceFeatureCostCenter = 'FT10006';
    const contextId = this.state.companySelected?.value;

    let dataSource = this.props.dataSources.licenceFeatures;
    dataSource
      .load(contextId)
      .then((response) => {
        let features = response ? response.features : null;
        let isCostCenterAllowed =
          features &&
          features.length > 0 &&
          features.includes(licenceFeatureCostCenter);

        this.setState({
          isCostCenterAllowed: isCostCenterAllowed,
        });
      })
      .catch((error) => {
        this.setState({
          isCostCenterAllowed: false,
        });
      });
  }

  resolveCostCenter(travelers) {
    let travelersCostCenter = travelers
      .filter((traveler) => traveler.costCenter)
      .map((traveler) => traveler.costCenter);
    let costCenterList = [];

    travelersCostCenter.forEach((tCostCenter) => {
      if (!costCenterList.some((cc) => cc.value == tCostCenter.id)) {
        let costCenter = {
          value: tCostCenter.id,
          label: `${tCostCenter.name} (${tCostCenter.code})`,
        };
        costCenterList.push(costCenter);
      }
    });

    if (costCenterList.length == 1) {
      let selectedCostCenter = costCenterList[0];
      this.setState({ selectedCostCenter, costCenterList });
      return;
    }

    this.setState({ selectedCostCenter: null, costCenterList });
  }

  feedbacksDataManager = {
    justification: (data) => {
      const popupJustifyPolicyData = {
        ...this.state.popupJustifyPolicyData,
        show: true,
        description: `Essa RV violou a política de viagens existente`,
        items: data.violations.map((violation) => {
          return {
            policyName: violation.policyName,
            violatedRules: violation.violatedRules.filter(
              (rule) => !rule.isValid,
            ),
          };
        }),
      };

      const policyViolationAlertsJustified = {
        justifications: data.violations.map((violation) => {
          return {
            policyId: violation.policyId,
            justification: {
              code: null,
              name: null,
              description: null,
            },
          };
        }),
      };

      this.setState({
        popupJustifyPolicyData,
        policyViolationAlertsJustified,
        showLoadingMask: false,
      });
    },

    feedbacks: (data, isNotify) => {
      let policyViolationAlertsJustified = null;

      if (isNotify)
        policyViolationAlertsJustified = {
          justifications: data.policyViolation.map((policy) => {
            return {
              policyId: policy.policyId,
              justification: null,
            };
          }),
        };

      this.setState({
        popupFeedbackData: data,
        policyViolationAlertsJustified,
        showLoadingMask: false,
      });
    },

    feedbackConfirm: (data) => {
      this.setState({
        popupFeedbackConfirmData: data,
        showLoadingMask: false,
      });
    },

    confirmationAlerts: (data, policyViolation) => {
      this.setState({
        popupConfirmationAlertsData: data,
        policyViolationAlertResp: policyViolation,
        showLoadingMask: false,
      });
    },
  };

  checkoutDataManager = {
    feedbackConfirm: (data) => {
      this.setState({
        popupProcessBookingData: data,
        showLoadingMask: false,
      });
    },

    processBookingResponse: (response) => {
      let hasErrors = response.errors && response.errors.length > 0;

      const isReserveAndSubmit = this.state.isReserveAndSubmit;

      if (hasErrors || !response.successful) {
        var errors = response.errors;

        const flashCardError = errors && errors.find(error => [
          travelEnums.flashCardErrors.insufficientBalance,
          travelEnums.flashCardErrors.balanceReserve,
          travelEnums.flashCardErrors.airlineBookingValue,
          travelEnums.flashCardErrors.genericErrorException
        ].includes(error.code));

        let popupProcessBooking = {
          ...this.state.popupProcessBookingData,
          show: true,
          title: 'Não foi possível seguir com o pagamento via Flash',
          description:
            'Você pode tentar novamente ou trocar a forma de pagamento da requisição para o padrão da empresa.',
          icon: 'IconAlertCircle',
          variant: 'error',
          events: {
            onCloseClick: this.events.processBooking.feedbacks.onClosePopupProcessBookingClicked,
            onDismissButtonClick: this.events.processBooking.actions.onBookTryAgain,
            onActionButtonClick: this.events.processBooking.feedbacks.onSelectedPaymentDefaultClicked,
          },
          buttons: {
            hasIconRefresh: true,
            isActionButtonDisabled: false,
            actionButtonText: 'Usar pagamento padrão',
            dismissButtonText: 'Tentar novamente',
          },
        };

        if (response.paymentForms && response.paymentForms.length > 0) {
          popupProcessBooking.paymentForms = response.paymentForms;
        }

        let paymentDefaultWasNotFound = !this.checkoutDataManager.hasDefaultPayment(this.state.paymentForms);
        if (paymentDefaultWasNotFound) {
          popupProcessBooking.buttons.isActionButtonDisabled = paymentDefaultWasNotFound;
          popupProcessBooking.buttons.tooltipActionDescription = 'Não existem pagamentos padrões configurados.';
        }

        //SPECIFIC ERRORS
        if (flashCardError !== undefined) {
          switch (flashCardError.code) {
            case travelEnums.flashCardErrors.insufficientBalance:
              let customTitle = 'Não foi possível gerar um cartão virtual corporativo para o item ';
              let customDescription = 'O cartão virtual corporativo não possui saldo suficiente para o pagamento da(s) reserva(s) ';
              let customSubDescription = 'Você pode tentar novamente mais tarde ou trocar a forma de pagamento para o padrão da empresa.';

              if (response.paymentForms && response.paymentForms.length > 0) {
                response.paymentForms.forEach(form => {
                  switch (form.productType) {
                    case enums.purchaseConfiguration.product.air: // Air
                      customTitle += 'Aéreo';
                      customDescription += 'aérea(s).';
                      break;
                    case enums.purchaseConfiguration.product.hotel: // Hotel
                      customTitle += 'Hotel';
                      customDescription += 'de hotel.';
                      break;
                    default:
                      break;
                  }
                });
              }

              popupProcessBooking.title = customTitle;
              popupProcessBooking.description = customDescription;
              popupProcessBooking.subDescription = customSubDescription;
              break;
            case travelEnums.flashCardErrors.balanceReserve:
              popupProcessBooking.description =
                'Não foi possível realizar a reserva de saldo, ocorreu um erro durante o processo.';
              break;
            case travelEnums.flashCardErrors.airlineBookingValue:
              popupProcessBooking.description =
                'Os valores do aéreo não foram fornecidos, contate o nosso suporte!';
              break;
            case travelEnums.flashCardErrors.genericErrorException:
              popupProcessBooking.description = 'Não foi possível gerar os cartões virtuais corporativos';
              popupProcessBooking.subDescription = 'Você pode tentar novamente mais tarde ou trocar a forma de pagamento para o padrão da empresa.';
              break;
            default:
              popupProcessBooking.title = "Não foi possível seguir com o pagamento";
              popupProcessBooking.subDescription = "Ocorreu erro durante a geração do cartão virtual!";
              break;
          }

          this.checkoutDataManager.feedbackConfirm(popupProcessBooking);
        } else {
          const feedbackData = FeedbackComponent.ConvertMessagesResponseToFeedbackObj(response);
          const popupFeedbackData = {
            ...this.state.popupFeedbackData,
            show: true,
            policyViolation: null,
            ...feedbackData,
            copiedItem: false,
          };

          this.feedbacksDataManager.feedbacks(popupFeedbackData);
        }
      } else {
        if (response.serviceRequestId !== 0) {
          const expiration = convertDateObjectToDate(response.expiration);
          const errorTitle = `Sua reserva ${response.serviceRequestId} foi gerada, porém não foi submetida para aprovação`
          const successTitle = `Requisição de viagem nº: ${response.serviceRequestId} ${isReserveAndSubmit? 'enviada para aprovação!' : 'reservada com sucesso!'}`
          let popupProcessBooking = {
            ...this.state.popupProcessBookingData,
            show: true,
            title: response.hasApprovalFlowError ? errorTitle : successTitle,
            serviceRequestId: response.serviceRequestId,
            icon: response.hasApprovalFlowError ? 'IconAlertTriangle' : 'IconCheck',
            variant: response.hasApprovalFlowError ? 'error': 'success',
            events: {
              onCloseClick: this.events.processBooking.feedbacks.onClosePopupProcessBookingClicked,
              onDismissButtonClick: this.events.processBooking.feedbacks.onCloseCheckoutClicked,
              onActionButtonClick: this.events.processBooking.feedbacks.onOpenRequestConfirmData,
            },
            buttons: {
              actionButtonText: 'Abrir requisição gerada',
              dismissButtonText: 'Continuar em cotações',
            },
          };
          if(response.hasApprovalFlowError) {
            popupProcessBooking.description = `Atente-se ao prazo de expiração ${formatDateToString(expiration)} para que defina um aprovador para a requisição de viagens e possa submetê-la.`;
          }


          if (response.paymentForms && response.paymentForms.length > 0) {
            popupProcessBooking.description = 'O seguinte cartão virtual corporativo foi utilizado:';
            popupProcessBooking.paymentForms = response.paymentForms;
          }

          this.checkoutDataManager.feedbackConfirm(popupProcessBooking);
        } else if(response.hasApprovalFlowError) {
          let popupProcessBooking = {
            ...this.state.popupProcessBookingData,
            show: true,
            title: 'Não há um aprovador configurado para essa viagem, por favor entre em contato com o gestor da sua plataforma para verificação das configurações.',
            icon: 'IconAlertTriangle',
            variant:'error',
            events: {
              onCloseClick: this.events.processBooking.feedbacks.onClosePopupProcessBookingClicked,
              onDismissButtonClick: this.events.processBooking.feedbacks.onCloseCheckoutClicked,
              onActionButtonClick: this.events.processBooking.feedbacks.onClosePopupProcessBookingClicked,
            },
            buttons: {
              dismissButtonText: 'Continuar em cotações',
              actionButtonText: 'Fechar',
            },
          };

          this.checkoutDataManager.feedbackConfirm(popupProcessBooking);
        }
      }
    },

    hasDefaultPayment: (payments) => {
      if (payments && payments.length > 0) {
        //TODO JEFF: Após implementar hotel validar os produtos é se tem pagamento de cada produto.
        const paymentForms = payments.filter(
          (_) =>
            _.paymentForm.productType ===
            enums.purchaseConfiguration.product.air ||
            _.paymentForm.productType ===
            enums.purchaseConfiguration.product.all,
        );
        return paymentForms.length > 0;
      } else return false;
    },
  };

  dataSources = {
    travelRequest: {
      callLoad: (loadOptions) => {
        let dataSource = this.props.dataSources.serviceRequest;
        this.setState({ loadingSearchRV: true, showValidationsfields: false });

        dataSource
          .load(loadOptions)
          .then((response) => {
            if (response.errors.length == 0 && response.successful)
              this.includeInformationServiceRequestExisting(
                response.serviceRequest,
              );

            if (response.errors.length > 0 || !response.successful) {
              apiResponse.successful.showErrorIfExists(
                response,
                'Ocorreu um erro!',
              );

              let customFields = this.state.customFields;

              customFields.forEach((field) => {
                delete field.value;
              });

              this.setState({
                loadingSearchRV: false,
                disabledFields: false,
                serviceRequestExisting: null,
                selectedReasonTravel: null,
                selectedCostCenter: null,
                customFields,
                serviceRequestId: null,
                validationServiceRequestId: false,
              });
            }
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(error);
          });
      },
    },
    paymentForms: {
      callLoad: (loadOptions) => {
        let _this = this;
        loadOptions =
          _this.selects.selectPaymentInformation._getLoadOptions(loadOptions);
        let dataSource = this.props.dataSources.paymentForms;
        let company = this.state.companySelected;
        let agency = this.state.agency;

        if (
          agency.isAgency &&
          agency.travelProfile === 4 &&
          company.value !== 0
        )
          loadOptions.ContextId = company.value;

        dataSource.load(loadOptions).then((paymentForms) => {
          let hotelGuarantees = this.state.hotelGuarantees;
          hotelGuarantees = hotelGuarantees.concat(
            paymentForms.filter(
              (payment) =>
                payment.paymentForm.paymentType != enums.paymentType.invoice,
            ),
          );

          if (paymentForms.length == 1) {
            paymentForms[0].label = 'Usar pagamento padrão';
            paymentForms[0].checked = true;

            if (this.state.preBookingData && this.state.preBookingData.hotel)
              hotelGuarantees = this.hotelGuarantee.getHotelGuarantees(
                this.state.preBookingData.hotel.hotels,
                paymentForms[0],
                hotelGuarantees,
              );

            /*
            //TODO: percorrer todos os produtos (tipos) existentes na checkout
            //e colocar como "selecionado" dependendo do tipo especificado em cada pagamento padrão

            const defaultPaymentInformation = paymentForms.map(_ => {
              return {
                type: _.paymentForm.paymentType,
                storedCreditCard: _.paymentForm.storedCreditCard,
                productType: _.paymentForm.productType == 1 ? enums.purchaseConfiguration.product.all, //default "all" (enums.purchaseConfiguration.product.all) instead paymentForm.productType because is "the default"?
                instalments: 1
              }
            });
            */

            this.setState({
              selectedPaymentInformation: null,
              paymentForms: paymentForms,
              hotelGuarantees: hotelGuarantees,
              disablePaymentforms: false,
              selectedCard: 0,
            });
          } else {
            this.setState({
              paymentForms: paymentForms,
              hotelGuarantees: hotelGuarantees,
              disablePaymentforms: false,
              selectedPaymentInformation: null,
              selectedCard: 0,
              selectedHotelGuarantee: null,
            });
          }
        });
      },
    },
    customFields: {
      callLoad: () => {
        const _this = this;
        const dataSource = this.props.dataSources.customField;

        dataSource.load().then((fields) => {
          _this.setState({
            customFields: fields,
          });
        });
      },
    },
    processBooking: {
      callLoad: (loadOptions) => {
        this.setState({ showLoadingMask: true });

        const dataSource = this.props.dataSources.processBooking;
        const requestData = this.convertCheckoutDataToApiRequest(loadOptions);

        this.setState({
          showLoadingMask: true,
          processBookingData: requestData,
        });

        dataSource.callProcessBooking(requestData).then((response) => {
          this.checkoutDataManager.processBookingResponse(response);

          if (response.successful) {
            if (
              response.confirmationAlerts &&
              response.confirmationAlerts.length > 0
            ) {
              if (
                response.confirmationAlerts[0] ==
                this.state.confirmationAlerts.approvalFlowByRequester
              ) {
                const popupConfirmationAlertsData = {
                  ...this.state.popupConfirmationAlertsData,
                  show: true,
                  type: enums.feedbackType.info,
                  description:
                    'Devido à presença de mais de 1 viajante o fluxo de aprovação será determinado pelo solicitante da requisição. Caso não seja o adequado, por favor gerar requisições de viagens para cada viajante de forma separada.',
                  okButtonLabel: 'Confirmar',
                };

                let policyViolationAlert =
                  response.policyViolationAlert &&
                    response.policyViolationAlert.violations &&
                    !response.policyViolationAlert.violations[0].justification
                    ? response.policyViolationAlert
                    : null;
                this.feedbacksDataManager.confirmationAlerts(
                  popupConfirmationAlertsData,
                  policyViolationAlert,
                );
              }
            } else if (
              response.policyViolationAlert &&
              response.policyViolationAlert.violations &&
              !response.policyViolationAlert.violations[0].justification
            ) {
              let indexBlockPolicy =
                response.policyViolationAlert.violations.findIndex(
                  (p) => p.actionType == enums.policyType.block,
                );
              let notifiesPolicy =
                response.policyViolationAlert.violations.filter(
                  (p) => p.actionType == enums.policyType.notifies,
                );

              if (indexBlockPolicy != -1) {
                const popupFeedbackData = {
                  ...this.state.popupFeedbackData,
                  show: true,
                  type: enums.feedbackType.info,
                  description:
                    'Houve quebra de politicas, não será possivel efetuar a reserva solicitada!',
                  policyViolation: response.policyViolationAlert.violations,
                };

                this.feedbacksDataManager.feedbacks(popupFeedbackData);
              } else if (notifiesPolicy.length > 0) {
                const popupFeedbackData = {
                  ...this.state.popupFeedbackData,
                  show: true,
                  type: enums.feedbackType.info,
                  description: 'Houve quebra de politicas!',
                  policyViolation: notifiesPolicy,
                  okButtonLabel: 'Confirmar',
                };

                this.feedbacksDataManager.feedbacks(popupFeedbackData, true);
              } else {
                this.feedbacksDataManager.justification(
                  response.policyViolationAlert,
                );
              }
            } else {
              if (
                response.messages.length > 0 &&
                response.serviceRequestId == 0 &&
                !response.hasApprovalFlowError
              ) {
                const popupFeedbackData = {
                  ...this.state.popupFeedbackData,
                  show: true,
                  type: enums.feedbackType.info,
                  description: response.messages.map((data) => {
                    return data.message;
                  }),
                  details: response.messages
                };

                this.feedbacksDataManager.feedbacks(popupFeedbackData);
              }
            }
          }
        });
      },
    },
    preBooking: {
      callPreBooking: () => {
        let currentProducts = this.state.currentProducts;
        let dataSource = this.props.dataSources.preBooking;
        let products =
          this.prebookingManager.convertToPreBookingRequest(currentProducts);
        let request = this.prebookingManager.sendPreBooking(
          products.selectedFlights,
          products.selectedHotels,
          currentProducts.travelers,
        );
        let company = this.state.companySelected;
        let agency = this.state.agency;

        if (
          agency.isAgency &&
          agency.travelProfile === 4 &&
          company.value !== 0
        )
          request.contextId = company.value;

        dataSource
          .load(request)
          .then((response) => {
            let valuesFormatted =
              this.prebookingManager.convertPreBookingResponseToFareResult(
                response,
              );

            this.setState({
              currentProducts: {
                ...this.state.currentProducts,
                allTariffedPrice: valuesFormatted,
              },
              tripTotalValue: {
                currencyCode: valuesFormatted.total.currencyCode,
                value: valuesFormatted.total.value,
              },
              hotelGuarantees: this.hotelGuarantee.getHotelGuarantees(
                response.hotel.hotels,
              ),
              showHotelGuarantee:
                this.hotelGuarantee.getHotelGuaranteeVisibility(
                  response.hotel.hotels,
                ),
              preBookingData: response,
            });
          });
      },
    },
    policyViolationJustification: {
      load: () => {
        let dataSource = this.props.dataSources.justificationsList;
        let currentProducts = this.state.currentProducts;

        let productType = enums.travelSections.Both;
        if (
          currentProducts.trips &&
          currentProducts.trips.length &&
          (!currentProducts.hotels || !currentProducts.hotels.length)
        )
          productType = enums.travelSections.Air;
        else if (
          currentProducts.hotels &&
          currentProducts.hotels.length &&
          (!currentProducts.trips || !currentProducts.trips.length)
        )
          productType = enums.travelSections.Hotel;

        let company = this.state.companySelected;
        let agency = this.state.agency;

        let loadOptions = {
          productType: productType,
        };

        let message =
          'Não há justificativas cadastradas para a quebra de politica, Favor entrar em contato com o administrador';
        if (
          agency.isAgency &&
          agency.travelProfile === 4 &&
          company.value !== 0
        ) {
          message = `Não há justificativas cadastradas para a quebra de politica, Favor entrar em contato com a empresa ${company.label}`;
          loadOptions.contextId = company.value;
        }

        let justification = dataSource.load(loadOptions).then((response) => {
          if (response.length == 0) {
            const popupFeedbackData = {
              show: true,
              type: enums.feedbackType.info,
              description: message,
            };

            this.setState({ popupJustifyPolicyData: { show: false } });
            this.feedbacksDataManager.feedbacks(popupFeedbackData);
          }
          return response;
        });

        return justification;
      },
    },
    companySettings: {
      callLoad: () => {
        const contextId = this.state.companySelected?.value;
        let dataSource = this.props.dataSources.companySettings;

        dataSource
          .load(contextId)
          .then((response) => {
            if (response.errors.length > 0 || !response.successful)
              apiResponse.successful.showErrorIfExists(
                response,
                'Ocorreu um erro!',
              );

            const companySettings = response.companySettings;
            this.setState({
              companySettings,
              isCostCenterSplitAvailable: companySettings.splitCostCenter,
            });
          })
          .catch(apiResponse.http.showErrorIfExists);
      },
    },
  };

  includeInformationServiceRequestExisting(data) {
    let selectedReasonTravel = {
      ...this.state.selectedReasonTravel,
      value: data.reason.id,
      label: data.reason.name,
      description: data.reason.description,
      IsDescriptionRequired:
        data.reason.description &&
        data.reason.description != '' &&
        data.reason.description != undefined,
    };

    let selectedCostCenter = {
      ...this.state.selectedCostCenter,
      value: data.costCenter.id,
      label: data.costCenter.name,
    };

    let customFields = [...this.state.customFields];
    data.customFieldValues &&
      customFields.forEach((field) => {
        let index = data.customFieldValues.findIndex(
          (f) => f.customFieldKey == field.CustomFieldId,
        );
        if (index != -1) field.value = data.customFieldValues[index].value;
      });

    let travelers = {
      ...this.state.travelers,
      adults: 0,
      childrens: 0,
      infants: 0,
      confirmedTravelers: [],
    };

    travelers.adults = 0;
    travelers.childrens = 0;
    travelers.infants = 0;
    travelers.confirmedTravelers = [];

    data.travelers.length > 0 &&
      data.travelers.forEach((traveler, index) => {
        traveler.labelToShow = `${traveler.lastName
            ? traveler.lastName.toUpperCase()
            : traveler.middleName
              ? traveler.middleName.toUpperCase()
              : ''
          }, ${traveler.firstName ? traveler.firstName : 'N/A'}`;
        if (traveler.userId == null) traveler.labelToShow += ' (Externo)';

        let confirmedTravelers = [...travelers.confirmedTravelers];
        confirmedTravelers.push(traveler);

        if (
          traveler.type ==
          (enums.travelerType.None || traveler.type == enums.travelerType.Adt)
        )
          travelers.adults = travelers.adults + 1;
        if (traveler.type == enums.travelerType.Chd)
          travelers.childrens = travelers.childrens + 1;
        if (traveler.type == enums.travelerType.Inf)
          travelers.infants = travelers.infants + 1;

        travelers.confirmedTravelers = confirmedTravelers;
      });

    this.setState({
      serviceRequestId: data.id,
      serviceRequestExisting: data,
      selectedReasonTravel,
      selectedCostCenter,
      loadingSearchRV: false,
      errorMessages: [],
      disabledFields: true,
      customFields,
      travelers,
      validationServiceRequestId: true,
    });
    this.CallLoadPaymentIfAllowed(null, null, travelers.confirmedTravelers);
  }

  validateFieldsJustification() {
    let isValid = true;

    isValid =
      isValid &&
      this.state.descriptionJustification != null &&
      this.state.descriptionJustification !== '';
    isValid = isValid && this.state.selectedJustification != 0;

    return isValid;
  }

  validateCostCenters() {
    if (!this.state.isCostCenterAllowed) return true;
    const { isCostCenterSplitAvailable, isCostCenterSplitEnabled } = this.state;

    return isCostCenterSplitAvailable && isCostCenterSplitEnabled
      ? this.costCenterSplit.getProps().isValid()
      : !!this.state.selectedCostCenter;
  }

  validateFieldsRV() {
    let isValid = true;
    let customFields = this.state.customFields;
    let selectedReasonOption = this.state.selectedReasonTravel;

    isValid &&= this.validateTravelers();

    isValid &&= this.validateCostCenters();

    isValid &&= !!selectedReasonOption && !!selectedReasonOption.value;
    isValid &&=
      !selectedReasonOption.reason.IsDescriptionRequired ||
      !!selectedReasonOption.description;

    const isMissingAnyProducts = (products, payments) => {
      return products.filter(product => product.hasItems).some(product =>
        !payments.some(payment => payment.productType === product.type));
    }

    if (this.separatePaymentPerProduct()) {
      isValid &&= !isMissingAnyProducts(this.state.currentProducts.products,
        this.state.selectedPaymentInformation);
    } else {
      isValid &&= !!this.state.selectedPaymentInformation;
    }

    if (
      this.props.agencyAndCompany.agency.isAgency &&
      this.props.agencyAndCompany.agency.travelProfile === 4
    )
      isValid &&= !!this.state.selectedRequester;

    if (this.state.showHotelGuarantee)
      isValid &&= !!this.state.selectedHotelGuarantee;

    customFields.length > 0 &&
      customFields.forEach((field) => {
        let customFieldIdBefore;
        let customFieldIdafter = field.CustomFieldId;

        if (customFieldIdafter != customFieldIdBefore)
          customFieldIdBefore = customFieldIdafter;

        if (customFieldIdBefore == customFieldIdafter) {
          if (field.IsRequired) isValid &&= field.value;
        }
      });

    if (this.state.selectActionRv.value != 1)
      isValid &&= !!this.state.requestNumber;

    return isValid;
  }

  validateAccommodationTraveler() {
    let isValid = true;
    let selectedRoom = this.state.selectedRoom;
    let selectedHotel = this.state.selectedHotel;

    if (this.getHotelList().length > 1)
      isValid == isValid &&
        selectedHotel != 0 &&
        selectedHotel != null &&
        selectedHotel != undefined;

    if (this.getRoomList().length > 1)
      isValid =
        isValid &&
        selectedRoom != 0 &&
        selectedRoom != null &&
        selectedRoom != undefined;

    return isValid;
  }

  validateTravelers() {
    let numberOfTravelers = 0;
    let travelers = this.state.travelers;
    let isValid = true;

    if (travelers.adults)
      numberOfTravelers = numberOfTravelers + travelers.adults;

    if (travelers.childrens)
      numberOfTravelers = numberOfTravelers + travelers.childrens;

    if (travelers.infants)
      numberOfTravelers = numberOfTravelers + travelers.infants;

    let indexConfirmedTravelers = travelers.confirmedTravelers.findIndex(
      (i) => i == null || i == undefined,
    );

    if (indexConfirmedTravelers != -1) isValid = false;

    return travelers.confirmedTravelers.length == numberOfTravelers && isValid;
  }

  _resolveCreditCardListItemLabel(cardNumber) {
    return 'Cartão (' + cardNumber + ')';
  }

  transformCostCenter(data) {
    return data ? { id: data.value, code: data.code, name: data.label } : null;
  }

  convertCheckoutDataToApiRequest(action) {
    let isRvOffline = this.props.isRvOffline;
    let importExternalBooking = this.props.importExternalBooking;
    let selectedRequester = this.state.selectedRequester;
    let reasonData = this.state.selectedReasonTravel;
    let customFieldsData = this.state.customFields;
    let costCenter = this.transformCostCenter(this.state.selectedCostCenter);
    if (this.state.isCostCenterSplitEnabled) {
      costCenter = this.transformCostCenter(
        this.state.costCenterSplit[0].costCenter,
      );
    }
    let costSplit = this.state.isCostCenterSplitEnabled
      ? this.state.costCenterSplit.map((item) => {
        return {
          CostCenter: this.transformCostCenter(item.costCenter),
          Percentage: item.percentage,
        };
      })
      : null;
    let air = !isRvOffline ? this.state.preBookingData.air : null;
    let hotel = !isRvOffline ? this.state.preBookingData.hotel : null;
    let serviceRequestId = this.state.serviceRequestId;
    let contextId = importExternalBooking
      ? this.state.companySelected.value
      : this.state.contextId;
    let requester = null;
    let confirmationAlerts = this.state.confirmationAlertsData;

    if (selectedRequester != null)
      requester = {
        name: selectedRequester.label,
        userId: selectedRequester.value,
        email: selectedRequester.email,
      };

    let policyViolationAlertsJustified = !isRvOffline
      ? this.state.policyViolationAlertsJustified
      : null;

    let requestAction = action;
    if (importExternalBooking) {
      if (action == enums.actionsBooking.reserve)
        requestAction = enums.actionsBooking.fetchExternalBooking;

      if (action == enums.actionsBooking.reserveAndSubmit)
        requestAction = enums.actionsBooking.submitExternalBooking;
    }

    let requestObject = {
      action: requestAction,
      travelers: this.convertTravelersDataToRequest(),
      paymentForms: [...this.state.selectedPaymentInformation],
      policyViolationAlertsJustified: null,
      confirmationAlerts: confirmationAlerts,
      costCenter,
      costSplit,
      reason: {
        id: reasonData.reason ? reasonData.reason.Id : reasonData.value,
        name: reasonData.reason ? reasonData.reason.Name : reasonData.label,
        description: reasonData.description,
      },
      customFieldValues: customFieldsData.map((field) => {
        return {
          customFieldKey: field.CustomFieldId,
          currentDescription: field.Label,
          type: field.ControlType,
          value: field.value,
        };
      }),
    };

    policyViolationAlertsJustified
      ? (requestObject.policyViolationAlertsJustified =
        policyViolationAlertsJustified)
      : null;
    contextId ? (requestObject.contextId = contextId) : null;
    requester ? (requestObject.requester = requester) : null;
    serviceRequestId
      ? (requestObject.serviceRequestId = serviceRequestId)
      : null;
    air ? (requestObject.air = air) : null;
    hotel
      ? (requestObject.hotel = this.convertHotelDataProcessBooking(hotel))
      : null;
    action ? requestObject.action : null;
    !isRvOffline
      ? (requestObject.compressedSearchResult =
        this.props.data.compressedSearchResult)
      : null;

    if (importExternalBooking) {
      requestObject.externalBooking = {
        bookingLocator: this.props.externalBooking.locator,
        connectionSource: this.props.externalBooking.source,
      };
    }
    requestObject.searchData = this.props.data.searchData;

    return requestObject;
  }

  convertHotelDataProcessBooking(hotelData) {
    let travelers = [...this.state.travelers.confirmedTravelers];
    let hotel = hotelData;

    hotel.hotelGuarantee = this.hotelGuarantee.getProcessBookingObj(hotel);

    hotel.hotels.forEach((hotel) => {
      hotel.rooms.forEach((room) => {
        room.travelers = [];
      });
    });

    travelers &&
      travelers.forEach((traveler) => {
        let hotelIndex = traveler.selectedHotel
          ? traveler.selectedHotel.value - 1
          : 0;
        let roomIndex = traveler.selectedRoom
          ? traveler.selectedRoom.value - 1
          : 0;

        if (
          hotel.hotels[hotelIndex] &&
          hotel.hotels[hotelIndex].rooms[roomIndex]
        )
          hotel.hotels[hotelIndex].rooms[roomIndex].travelers.push(traveler.id);
      });

    return hotel;
  }

  convertTravelersDataToRequest() {
    let travelers = [...this.state.travelers.confirmedTravelers];
    let travelersRequest = [];

    travelers.forEach((traveler) => {
      let travelerDocuments = [];
      traveler.documents &&
        traveler.documents.forEach((document) => {
          let travelerDocument = {
            ...document,
            expirationDate: document.expiration
              ? utils.converters.date.stringToDateObject(document.expiration)
              : null,
          };

          delete travelerDocument.expiration;
          travelerDocuments.push(travelerDocument);
        });

      let item = {
        id: traveler.id,
        userId: traveler.userId,
        contextId: traveler.contextId,
        costCenter: traveler.costCenter,
        useSocialName: traveler.useSocialName,
        email: traveler.email,
        type: traveler.type - 1,
        phoneNumber: traveler.phone,

        honorificPrefix:
          traveler.name && traveler.name.honorificPrefix
            ? traveler.name.honorificPrefix
            : traveler.honorificPrefix
              ? traveler.honorificPrefix
              : null,
        firstName:
          traveler.name && traveler.name.firstName
            ? traveler.name.firstName
            : traveler.firstName
              ? traveler.firstName
              : null,
        middleName:
          traveler.name && traveler.name.middleName
            ? traveler.name.middleName
            : traveler.middleName
              ? traveler.middleName
              : null,
        lastName:
          traveler.name && traveler.name.lastName
            ? traveler.name.lastName
            : traveler.lastName
              ? traveler.lastName
              : null,

        socialHonorificPrefix:
          traveler.socialName && traveler.socialName.honorificPrefix
            ? traveler.socialName.honorificPrefix
            : traveler.socialHonorificPrefix
              ? traveler.socialHonorificPrefix
              : null,
        socialFirstName:
          traveler.socialName && traveler.socialName.firstName
            ? traveler.socialName.firstName
            : traveler.socialFirstName
              ? traveler.socialFirstName
              : null,
        socialMiddleName:
          traveler.socialName && traveler.socialName.middleName
            ? traveler.socialName.middleName
            : traveler.socialMiddleName
              ? traveler.socialMiddleName
              : null,
        socialLastName:
          traveler.socialName && traveler.socialName.lastName
            ? traveler.socialName.lastName
            : traveler.socialLastName
              ? traveler.socialLastName
              : null,
        gender: traveler.gender == 1 ? 'M' : 'F',
        birthDate:
          typeof traveler.birthDate != 'object'
            ? utils.converters.date.stringToDateObject(traveler.birthDate)
            : traveler.birthDate,
        nationality: traveler.nationalityLocationId && {
          i: traveler.nationalityLocationId,
        },
        documents: travelerDocuments,
        address: traveler.address,
      };

      travelersRequest.push(item);
    });

    return travelersRequest;
  }

  convertPaymentDataToRequest() {
    let paymenData = this.state.selectedPaymentInformation;
    let paymentForm = paymentResolver.convertPaymentFormComponentObjToApi(
      paymenData.paymentForm,
    );

    return paymentForm;
  }

  costCenterSplit = {
    getProps: splitMethods.bind(this),
  };

  selects = {
    selectActionRv: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.selectActionRv,
          placeholder: 'Selecione RV',
          options: {
            dataSource: {
              load: function () {
                return Promise.resolve(_this.getActionsSelectRv());
              },
            },
            events: {
              onSelected: function (selectedItem) {
                const productsData = { ..._this.props.data.productsData };
                let travelers = {};
                if (productsData.travelers)
                  travelers = {
                    adults: productsData.travelers.adultCount,
                    childrens: productsData.travelers.childCount,
                    infants: productsData.travelers.infantCount,
                  };
                else travelers = productsData.searchParameters.travelers;

                travelers.confirmedTravelers = [];

                if (selectedItem.value == 1) {
                  let customFields = _this.state.customFields;

                  customFields.forEach((field) => {
                    field.value = null;
                  });

                  _this.setState({
                    selectActionRv: selectedItem,
                    requestNumber: null,
                    loadingSearchRV: false,
                    disabledFields: false,
                    serviceRequestExisting: null,
                    selectedReasonTravel: null,
                    selectedCostCenter: {
                      value: 0,
                      code: null,
                      label: '',
                    },
                    travelers,
                    customFields,
                    serviceRequestId: null,
                    validationServiceRequestId: true,
                  });
                } else {
                  _this.setState({
                    selectActionRv: selectedItem,
                    travelers,
                  });
                }
              },
            },
          },
        };
      },
    },

    selectRooms: {
      getProps: function () {
        let _this = this;
        let selectedRoom = this.state.selectedRoom;
        let rooms = this.state.roomList;

        return {
          data: selectedRoom,
          placeholder: 'Selecione o quarto',
          options: {
            items: rooms,
            events: {
              onSelectedRoom: function (
                selectedItem,
                travelerNumber,
                travelerType,
              ) {
                //switch (travelerType) {
                //    case enums.travelerType.Adt:
                //        selectedRooms.adt[travelerNumber - 1] = selectedItem;
                //        break;
                //    case enums.travelerType.Chd:
                //        selectedRooms.chd[travelerNumber - 1] = selectedItem;
                //        break;
                //    case enums.travelerType.Inf:
                //        selectedRooms.inf[travelerNumber - 1] = selectedItem;
                //}

                _this.setState({
                  selectedRoom: selectedItem,
                });
              },
            },
          },
        };
      },
    },

    selectHotels: {
      getProps: () => {
        let _this = this;
        let selectedHotel = this.state.selectedHotel;
        let hotels = this.state.hotelList;
        return {
          data: selectedHotel,
          placeholder: 'Selecione o hotel',
          options: {
            items: hotels,
            events: {
              onSelectedRoom: function (selectedItem) {
                _this.setState({ selectedHotel: selectedItem });
              },
            },
          },
        };
      },
    },

    selectReasonTravel: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.selectedReasonTravel,
          placeholder: 'Selecione o motivo',
          options: {
            dataSource: this.props.dataSources.travelReason,
            events: {
              onSelected: function (selectedItem) {
                _this.setState({ selectedReasonTravel: selectedItem });
              },
            },
          },
          onChangeDescription: (value) => {
            this.setState({
              selectedReasonTravel: {
                ...this.state.selectedReasonTravel,
                description: value,
              },
            });
          },
        };
      },
    },

    selectCostCenter: {
      getProps: function () {
        let _this = this;
        return {
          options: this.state.costCenterList,
          data: this.state.selectedCostCenter,
          fildText: this.state.costCenterFildText,
          placeholder: 'Selecione o centro de custo',
          dataSource: this.props.dataSources.costCenter,
          events: {
            onChanged: function (selectedItem) {
              _this.setState({ selectedCostCenter: selectedItem });
              _this.CallLoadPaymentIfAllowed(null, selectedItem);
            },
            onInputChanged: (text) => {
              this.setState({ costCenterFildText: text });
            },
          },
        };
      },
    },

    selectPaymentInformation: {
      _getLoadOptions: (loadOptions) => {
        let products = enums.purchaseConfiguration.product.all;
        let supplier = 'all';

        if (!this.props.isRvOffline) {
          //Descobre quais os produtos existentes na checkout
          if (
            this.state.currentProducts.trips &&
            this.state.currentProducts.trips.length &&
            !(
              this.state.currentProducts.hotels.selectedHotels &&
              this.state.currentProducts.hotels.selectedHotels.length
            )
          )
            products = enums.purchaseConfiguration.product.air;
          else if (
            !(
              this.state.currentProducts.trips &&
              this.state.currentProducts.trips.length
            ) &&
            this.state.currentProducts.hotels.selectedHotels &&
            this.state.currentProducts.hotels.selectedHotels.length
          )
            products = enums.purchaseConfiguration.product.hotel;

          //Define os fornecedores conforme os produtos existentes na checkout
          if (products == enums.purchaseConfiguration.product.air) {
            let currentAirSuppliers = this.state.currentProducts.trips.map(
              (trip) =>
                trip.fares.fareInformations
                  ? trip.fares.fareInformations[0].supplier
                  : null,
            );
            let allAirSuppliers = [...new Set(currentAirSuppliers)];

            if (allAirSuppliers.length == 1) supplier = allAirSuppliers[0];
          } else if (products == enums.purchaseConfiguration.product.hotel) {
            let currentHotelSuppliers =
              this.state.currentProducts.hotels.selectedHotels.map(
                (selectedHotel) => {
                  return Object.keys(
                    selectedHotel.selectedRooms[0].room.sourceIdentifier,
                  )[0];
                },
              );

            let distinctHotelSuppliers = [...new Set(currentHotelSuppliers)];

            if (distinctHotelSuppliers.length == 1)
              supplier = distinctHotelSuppliers[0];
          }
        }

        loadOptions.products = products;
        loadOptions.supplier = supplier;

        return loadOptions;
      },
      getProps: () => {
        let _this = this;
        let hotelGuarantees = this.state.hotelGuarantees;

        return {
          data: this.state.selectedPaymentInformation,
          placeholder: 'Forma de pagamento',
          options: {
            items: this.state.paymentForms,
            events: {
              onPaymentSelected: function (selectedItem) {
                if (
                  _this.state.preBookingData &&
                  _this.state.preBookingData.hotel
                )
                  hotelGuarantees = _this.hotelGuarantee.getHotelGuarantees(
                    _this.state.preBookingData.hotel.hotels,
                    selectedItem,
                    hotelGuarantees,
                  );

                _this.setState({
                  selectedPaymentInformation: selectedItem,
                  hotelGuarantees,
                });
              },
            },
          },
          validation: {
            showValidationResult: false,
          },
        };
      },
    },

    selectJustifications: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.selectedJustification,
          placeholder: 'Selecione uma justificativa',
          options: {
            dataSource: this.dataSources.policyViolationJustification,
            events: {
              onSelected: function (selectedItem) {
                _this.setState({ selectedJustification: selectedItem });
              },
            },
          },
          onChangeDescription: (value) => {
            this.setState({ descriptionJustification: value });
          },
        };
      },
    },

    selectRequester: {
      getProps: function () {
        let _this = this;
        return {
          data: this.state.selectedRequester,
          placeholder: 'Digite no mínimo 3 letras.',
          dataSource: this.props.dataSources.requester,
          events: {
            onChanged: function (selectedItem) {
              _this.setState({ selectedRequester: selectedItem });
              _this.CallLoadPaymentIfAllowed(selectedItem);
            },
          },
        };
      },
    },
  };

  getActionsSelectRv() {
    return [
      { value: 1, label: 'Criar Nova' },
      { value: 2, label: 'Existente' },
    ];
  }

  getRoomList(productsData) {
    let listRooms = [];
    productsData.hotels.selectedHotels &&
      productsData.hotels.selectedHotels.map((selectedHotel, hotelIndex) => {
        selectedHotel.selectedRooms.map((selectedRoom, roomIndex) => {
          listRooms.push({
            value: roomIndex + 1,
            label: selectedRoom.room.name,
            adultCount: selectedRoom.adultCount,
            childCount: selectedRoom.childCount,
          });
        });
      });

    return listRooms;
  }

  getHotelList(productsData) {
    let hotelList = [];

    productsData.hotels.selectedHotels &&
      productsData.hotels.selectedHotels.map((hotel, index) => {
        hotelList.push({
          value: index + 1,
          label: hotel.name,
          needCompleteAddress: hotel.hotel.needCompleteAddress,
        });
      });

    return hotelList;
  }

  generateDateObject(dateReceived) {
    const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    const daysOfWeek = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex'];

    let date = new Date(dateReceived);

    let objDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours() < 10,
      minute: date.getMinutes(),
      dayOfWeek: daysOfWeek[date.getDay()],
      dateShortFormatted: date.getDate() + ' de ' + months[date.getMonth()],
      timeFormatted:
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':' +
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
    };

    return objDate;
  }

  generateDurationFormatted(timeStamp) {
    let hours = Math.floor(timeStamp / 60);
    let minutes = timeStamp - hours * 60;

    if (hours.length == 1) hours = '0' + hours;
    if (minutes.length == 1) minutes = '0' + minutes;
    if (hours >= 12) {
      if (hours == 12) {
        hours = hours;
        minutes = minutes;
      } else if (hours == 24) {
        minutes = '59';
      } else {
        minutes = minutes;
      }
    } else {
      hours = hours;
      minutes = minutes;
    }

    return hours + 'h ' + minutes + 'min';
  }

  customFields = {
    getProps: () => {
      return {
        customFields: this.state.customFields,
        events: {
          onFieldChange: (fieldRef, value) => {
            let customFields = this.state.customFields;
            let field = customFields.find((f) => f.CustomFieldId == fieldRef);
            field.value = value;

            this.setState({ customFields });
          },
        },
      };
    },
  };

  hotelGuarantee = {
    getHotelGuarantees: (hotels, selectedPaymentInformation, guarantees) => {
      let hotelGuarantees = guarantees ? guarantees : [];
      let invoiceGuarantee = {
        type: enums.hotelGuaranteeType.companyName,
        value: enums.hotelGuaranteeType.companyName,
        label: 'Faturado',
      };

      let guaranteeAcceptance =
        this.hotelGuarantee.getHotelGuaranteeAcceptance(hotels);
      let allowedPaymentTypes = this.props.allowedPaymentTypes;

      if (
        allowedPaymentTypes &&
        allowedPaymentTypes.some(
          (type) => type.PaymentType == enums.paymentType.invoice,
        )
      ) {
        if (
          hotelGuarantees.length == 0 &&
          guaranteeAcceptance.acceptsInvoice &&
          guaranteeAcceptance.acceptsCreditCard
        )
          hotelGuarantees.push(invoiceGuarantee);
      }

      if (
        selectedPaymentInformation &&
        selectedPaymentInformation.type != enums.paymentType.flashPay &&
        selectedPaymentInformation.paymentForm?.paymentType !=
        enums.paymentType.invoice &&
        guaranteeAcceptance.acceptsCreditCard
      ) {
        let clonedPayment = { ...selectedPaymentInformation };

        if (clonedPayment.value == this.newCreditCardIdentifier) {
          clonedPayment.value = this.addedCreditCardIdentifier;
          hotelGuarantees.push(clonedPayment);
        }

        this.setState({ selectedHotelGuarantee: clonedPayment });
      }

      if (
        selectedPaymentInformation &&
        selectedPaymentInformation.paymentForm?.paymentType ==
        enums.paymentType.invoice &&
        guaranteeAcceptance.acceptsInvoice
      )
        this.setState({ selectedHotelGuarantee: invoiceGuarantee });

      return hotelGuarantees;
    },
    getHotelGuaranteeVisibility: (hotels) => {
      let showHotelGuarantee = false;

      let guaranteeAcceptance =
        this.hotelGuarantee.getHotelGuaranteeAcceptance(hotels);
      showHotelGuarantee = guaranteeAcceptance.acceptsCreditCard;

      return showHotelGuarantee;
    },
    getHotelGuaranteeAcceptance: (hotels) => {
      let acceptance = {
        acceptsInvoice: false,
        acceptsCreditCard: false,
      };

      const importExternalBooking = this.props.importExternalBooking;

      if (hotels && hotels.length) {
        acceptance.acceptsInvoice = true;
        acceptance.acceptsCreditCard = true;

        hotels.forEach((hotel) => {
          hotel.rooms.forEach((room) => {
            let hasInvoice = room.guaranteesAccepted.some(
              (guarantee) =>
                guarantee.type == enums.hotelGuaranteeType.companyName,
            );
            let hasCreditCard = room.guaranteesAccepted.some(
              (guarantee) =>
                guarantee.type == enums.hotelGuaranteeType.creditCard,
            );

            if (!hasInvoice) acceptance.acceptsInvoice = false;

            if (!hasCreditCard) acceptance.acceptsCreditCard = false;

            if (importExternalBooking
              && room.guaranteesAccepted
              && room.guaranteesAccepted.length === 0)
              acceptance.acceptsCreditCard = true;
          });
        });
      }

      return acceptance;
    },
    getProcessBookingObj: (hotelData) => {
      let selectedHotelGuarantee = this.state.selectedHotelGuarantee;

      if (!this.state.showHotelGuarantee) {
        return {
          guaranteeType: enums.hotelGuaranteeType.companyName,
        };
      }

      if (selectedHotelGuarantee) {
        const paymentForm = selectedHotelGuarantee.paymentForm ?? selectedHotelGuarantee;
        const paymentType = selectedHotelGuarantee.paymentForm?.paymentType ?? selectedHotelGuarantee.type;

        let creditCard = paymentForm.creditCard;

        switch (paymentType) {
          case enums.paymentType.b2bPay:
            return {
              guaranteeType: enums.hotelGuaranteeType.b2bPay
            };
          case enums.paymentType.flashPay:
            return {
              guaranteeType: enums.hotelGuaranteeType.flashPay
            };
          default:
            var guaranteeObj = {
              guaranteeType: enums.hotelGuaranteeType.creditCard,
              storedCreditCard: paymentForm.storedCreditCard,
            };

            if (creditCard) {
              guaranteeObj.creditCard = paymentResolver.convertCreditCardComponentObjToApi(creditCard);
            }

            return guaranteeObj;
        }
      }

      return null;
    },

    getProps: () => {
      const _this = this;
      return {
        data: this.state.selectedHotelGuarantee,
        placeholder: 'Selecione a garantia do hotel',
        options: {
          items: this.state.hotelGuarantees,
          events: {
            onSelected: (selectedItem) => {
              if (selectedItem?.value == 1) {
                this.setState({
                  displayPopupHotelGuaranteeCard: true,
                });
              } else {
                _this.setState({
                  selectedHotelGuarantee: selectedItem,
                });
              }
            },
          },
        },
        validation: {
          showValidationResult: false,
        },
      };
    },

    getCardProps: () => {
      return {
        data: this.state.hotelGuaranteeCardData,
        events: {
          onCardHolderChanged: (value) => {
            let cardFiedsData = this.state.hotelGuaranteeCardData;
            cardFiedsData.cardholder = value;
            this.setState({ hotelGuaranteeCardData: cardFiedsData });
          },
          onCardNumberChanged: (value) => {
            let cardFiedsData = this.state.hotelGuaranteeCardData;
            cardFiedsData.cardNumber = value;
            this.setState({ hotelGuaranteeCardData: cardFiedsData });
          },
          onCvcChanged: (value) => {
            let cardFiedsData = this.state.hotelGuaranteeCardData;
            cardFiedsData.cvc = value;
            this.setState({ hotelGuaranteeCardData: cardFiedsData });
          },
          onExpirationDateChanged: (value) => {
            let cardFiedsData = this.state.hotelGuaranteeCardData;
            cardFiedsData.expirationDate = value;
            this.setState({ hotelGuaranteeCardData: cardFiedsData });
          },
        },
      };
    },
  };

  prebookingManager = {
    convertToPreBookingRequest(data) {
      let currentProducts = { ...data };
      let _this = this;

      let selectedFlights = [];
      currentProducts.trips &&
        currentProducts.trips.map((flights, index) => {
          if (Array.isArray(flights)) {
            flights.map((travel) => {
              travel.trips.map((trip, index) => {
                selectedFlights.push({
                  airTrip: trip.formattedObject.originalAirTrip,
                  fareInfo: travel.fares.fareInformations[index], //TODO THIAGO: verificar com WELL
                });
              });
            });
          } else {
            flights.trips.map((trip, index) => {
              let fareInfo = trip.fareOptions
                ? trip.fareOptions.originalFare.fareInformations[index]
                : flights.fares
                  ? flights.fares.fareInformations[index]
                  : trip.formattedObject.fareOptions.originalFare
                    .fareInformations[index];

              selectedFlights.push({
                airTrip: trip.formattedObject.originalAirTrip,
                fareInfo: fareInfo, //TODO THIAGO: verificar com WELL
              });
            });
          }
        });

      let selectedHotels = currentProducts.hotels
        ? currentProducts.hotels
        : null;

      return {
        selectedHotels,
        selectedFlights,
      };
    },

    sendPreBooking: (selectedFlights, selectedHotels, travelers) => {
      var travelers = {
        adults: travelers.adultCount,
        childrens: travelers.childCount,
        infants: travelers.infantCount,
      };

      let preBookingObj = {
        action: 0,
        travellers:
          this.prebookingManager.convertPassengersToTravelers(travelers),
        mock: null,
      };

      if (selectedFlights.length) {
        preBookingObj.air = {
          selectedFlights: selectedFlights,
        };
      }

      if (
        selectedHotels.selectedHotels &&
        selectedHotels.selectedHotels.length
      ) {
        preBookingObj.hotel = selectedHotels;
      }

      return preBookingObj;
    },

    convertPassengersToTravelers(passengers) {
      let passengersList = Object.entries(passengers);
      let travelers = [];

      passengersList.forEach(([key, value]) => {
        if (value !== 0) {
          travelers.push({
            type:
              key == 'adults' || key == 'adultCount'
                ? 0
                : key == 'childrens' || key == 'childCount'
                  ? 1
                  : 2,
            count: value,
          });
        }
      });

      return travelers;
    },

    convertPreBookingResponseToFareResult(response) {
      let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
      let moneyFormattedRounded = utils.formatters.money.getFormattedRounded;

      let total = response.summary.total;
      total.formatted = moneyFormattedRounded(total.value, total.currencyCode);
      total.currencySymbol = getCurrencySymbol(total.currencyCode);

      let totalFare = response.summary.totalFare;
      totalFare.formatted = moneyFormattedRounded(
        totalFare.value,
        totalFare.currencyCode,
      );
      totalFare.currencySymbol = getCurrencySymbol(totalFare.currencyCode);

      let totalTaxes = response.summary.totalTaxes;
      totalTaxes.formatted = moneyFormattedRounded(
        totalTaxes.value,
        totalTaxes.currencyCode,
      );
      totalTaxes.currencySymbol = getCurrencySymbol(totalTaxes.currencyCode);

      let totalServices = response.summary.totalServices;
      totalServices.formatted = moneyFormattedRounded(
        totalServices.value,
        totalTaxes.currencyCode,
      );
      totalServices.currencySymbol = getCurrencySymbol(
        totalServices.currencyCode,
      );

      //return response;
      return {
        total: total,
        totalTaxes: totalTaxes,
        totalFare: totalFare,
        totalServices: totalServices,
      };
    },
  };

  CallLoadPaymentIfAllowed(
    selectedRequester,
    selectedCostCenter,
    confirmedTravelers,
  ) {
    let agency = this.state.agency;

    let requester = selectedRequester || this.state.selectedRequester;
    let costCenter =
      selectedCostCenter ||
      this.state.selectedCostCenter ||
      this.state.costCenterSplit[0]?.costCenter;
    let travelers =
      confirmedTravelers || this.state.travelers.confirmedTravelers;

    let loadPaymentsForm = false;

    if (agency.isAgency && agency.travelProfile === 4) {
      loadPaymentsForm = this.state.isCostCenterAllowed
        ? Boolean(requester && costCenter && travelers)
        : Boolean(requester && travelers);
    } else {
      loadPaymentsForm = this.state.isCostCenterAllowed
        ? Boolean(costCenter && travelers)
        : Boolean(travelers);
    }

    if (loadPaymentsForm) {
      let userTraveler = travelers.filter((t) => t.userId);
      let loadOptions = {
        requester: requester ? requester.value : null,
        costCenter: costCenter ? costCenter.value : null,
        travelers: userTraveler.map((t) => t.userId),
      };
      this.dataSources.paymentForms.callLoad(loadOptions);
    } else {
      this.setState({ disablePaymentforms: true });
    }
  }
}
