import Pagination from '../../pagination/pagination.componente.js';
import Notifier from '../../common/notifier/notifier.component.js';
import Popup from '../../popup/popup.component.js';
import { enums } from '@legacy-utils/enums';
import { statusFlowResolver } from '@legacy-utils/resolvers/statusFlowResolver.js';

var MonitoringGridTemplate = {
  main: ({
    existItems,
    isAgency,

    rows,
    viewBy,

    notifierProps,
    notifierEvents,
    onShowEmailPopupClick,

    pagination,
    onPageClick,
    onRVClick,
  }) => {
    return (
      <div className="exon-monitoring-grid-container">
        {existItems === 0 ? (
          <div className="exon-monitoring-grid-alert">
            <p>
              Clique em uma das celulas da matriz acima para exibir os itens de
              viagens...
            </p>
          </div>
        ) : (
          <MonitoringGridTemplate.grid
            isAgency={isAgency}
            rows={rows}
            onShowEmailPopupClick={onShowEmailPopupClick}
            viewBy={viewBy}
            pagination={pagination}
            onPageClick={onPageClick}
            onRVClick={onRVClick}
          />
        )}
        {notifierProps.show && (
          <div className="exon-box-notifier">
            <MonitoringGridTemplate.notifier
              notifierProps={notifierProps}
              notifierEvents={notifierEvents}
            />
          </div>
        )}
      </div>
    );
  },

  grid: ({
    rows,
    onShowEmailPopupClick,
    viewBy,
    pagination,
    onPageClick,
    onRVClick,
    isAgency,
  }) => {
    let begin =
      pagination.currentPage > 1
        ? pagination.itemsPerPage * (pagination.currentPage - 1)
        : 0;
    let end =
      pagination.currentPage > 1
        ? begin + pagination.itemsPerPage
        : pagination.itemsPerPage;

    return (
      <div className="exon-monitoring-grid-container-table">
        <table className="exon-monitoring-grid-table">
          <thead className="exon-monitoring-grid-table-head">
            <tr>
              <th
                className="exon-monitoring-grid-table-head-column"
                title="Requisição de viagem"
              >
                RV
              </th>
              <th className="exon-monitoring-grid-table-head-column">Item</th>
              <th className="exon-monitoring-grid-table-head-column">
                Produto
              </th>
              <th className="exon-monitoring-grid-table-head-column">
                Solicitante
              </th>
              <th className="exon-monitoring-grid-table-head-column">
                {viewBy.label}
              </th>
              <th className="exon-monitoring-grid-table-head-column">
                Viajante
              </th>
              <th className="exon-monitoring-grid-table-head-column">
                Aprovador
              </th>
              <th className="exon-monitoring-grid-table-head-column"></th>
            </tr>
          </thead>
          <tbody className="exon-monitoring-grid-table-body">
            {rows.slice(begin, end).map((item, i) => {
              let allowedActions = statusFlowResolver.getAllowedActions(
                item.status,
                { isAgency: isAgency },
              );
              let dt1 =
                item[
                  viewBy.value == 1
                    ? 'expiresAt'
                    : viewBy.value == 2
                    ? 'startDate'
                    : 'createdAt'
                ];
              let date = '-';
              if (dt1)
                date = new Date(
                  dt1.year,
                  dt1.month - 1,
                  dt1.day,
                  dt1.hour,
                  dt1.minute,
                  dt1.second,
                ).toLocaleString('pt-BR');
              return (
                <tr key={'rows-' + i}>
                  <td
                    key={'rv-' + i}
                    className="exon-monitoring-grid-table-body-rows-rv"
                  >
                    <label
                      onClick={() =>
                        onRVClick(item.parentId, item.rootContextId)
                      }
                    >
                      {item.parentId}
                    </label>
                  </td>
                  <td
                    key={'id-' + i}
                    className="exon-monitoring-grid-table-body-rows"
                  >
                    {item.id}
                  </td>
                  <td
                    key={'type-' + i}
                    className="exon-monitoring-grid-table-body-rows"
                  >
                    {item.type == 'airbooking'
                      ? 'Aéreo'
                      : item.type == 'hotelbooking'
                      ? 'Hotel'
                      : '-'}
                  </td>
                  <td
                    key={'requester-' + i}
                    className="exon-monitoring-grid-table-body-rows"
                  >
                    {item.requester.fullName}
                  </td>
                  <td
                    key={'date-' + i}
                    className="exon-monitoring-grid-table-body-rows"
                  >
                    {date}
                  </td>
                  <td
                    key={'trevelers-' + i}
                    className="exon-monitoring-grid-table-body-rows"
                  >
                    {item.travelers &&
                      item.travelers.map((traveler, j) => {
                        return (
                          <span key={j}>
                            {traveler.fullName} <br />
                          </span>
                        );
                      })}
                  </td>
                  <td
                    key={'approvers-' + i}
                    className="exon-monitoring-grid-table-body-rows"
                  >
                    {item.approvers &&
                      item.approvers.map((approver, i) => {
                        return (
                          <span key={i}>
                            {approver.fullName} <br />
                          </span>
                        );
                      })}
                  </td>
                  <td key={'notification-' + i} className="">
                    {allowedActions.sendNotification && (
                      <span
                        title={`Enviar notificação via email (${item.status.name})`}
                        className="exon-icon exon-icon-envelope-unfilled_enabled exon-monitoring-grid-notification"
                        onClick={() => onShowEmailPopupClick(item)}
                      ></span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Pagination
          itemsQuantity={pagination.totalResults}
          itemsPerPage={pagination.itemsPerPage}
          onPageClick={onPageClick}
          currentPage={pagination.currentPage}
        />
      </div>
    );
  },

  notifier: ({ notifierProps, notifierEvents }) => {
    return (
      <Popup
        title={'Notificação da RV'}
        exonStyle={true}
        cancelButtonClick={notifierEvents.onHidePopupClicked}
        okButtonClick={notifierEvents.onConfirmNotifyClicked}
        cancelButtonLabel="Cancelar"
        okButtonLabel="Enviar"
      >
        <Notifier
          notificationType={notifierProps.notificationType}
          dataSource={
            notifierProps.item.status.id != enums.workflowStatus.inIssue &&
            notifierProps.item.status.id != enums.workflowStatus.issued &&
            notifierProps.item.status.id != enums.workflowStatus.canceled
              ? notifierProps.dataSources.serviceRequest
              : notifierProps.dataSources.serviceRequestItem
          }
          fieldValues={notifierProps.fieldsData}
          events={notifierEvents}
        />
      </Popup>
    );
  },
};

export default MonitoringGridTemplate;
