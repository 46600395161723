import BookingBox from '../booking-box/booking-box.component.js';
import TravelerInformations from '../traveler-information/traveler-information.component.js';
import TravelExpiration from '../travel-expiration/travel-expiration.component.js';
import BookingProductSummaryList from '../booking-product-summary-list/booking-product-summary-list.component.js';
import SplitModal from '../../cost-center-split/split-modal.component';
import { Loading } from '../../../../components/Loading/Loading';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { resetSplitData } from '../../cost-center-split/split-methods.js';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';

const Template = {
  main: ({
    request,
    canEdit,
    userTravelProfile,
    decisionEvents,
    justificationEvents,
    events,
    dataSources,
    splitRef,
    splitProps,
    loading,
  }) => {
    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;
    let getDurationHoursMinute = utils.formatters.time.durationHoursMinute;
    let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
    let moneyFormattedRounded = utils.formatters.money.getFormattedRounded;
    let moneyFormatted = utils.formatters.money.getFormatted;
    let haveProducts = request.itemsSummary
      ? request.itemsSummary.some((booking) => booking.type)
      : null;
    let expirationMain = haveProducts
      ? request.itemsSummary.find(
          (booking) =>
            booking.id == request.expiration.nearestExpirationProductId,
        )
      : null;

    const moneyHelper = ({ currencyCode, value }) => {
      return `${getCurrencySymbol(currencyCode)} ${moneyFormatted(
        value,
        currencyCode,
      )}`;
    };

    const { enableTravelOffline } = useTravelPermissions();

    //let airTrip = expirationMain.airTrips && expirationMain.airTrips[0] || null;
    //let hotel = expirationMain.hotel;

    let getExpirationBookingInfos = (serviceItemData) => {
      let airTrip =
        (serviceItemData &&
          serviceItemData.airTrips &&
          serviceItemData.airTrips[0]) ||
        null;
      let hotel = serviceItemData && serviceItemData.hotel;

      if (
        serviceItemData &&
        serviceItemData.type == enums.products.namedType.airBooking
      ) {
        return {
          trip: airTrip
            ? {
                departure: {
                  iata: airTrip.departure.iata,
                  date: getFormattedDateObjFromDateObject(
                    airTrip.departureDate,
                  ),
                },
                arrival: {
                  iata: airTrip.arrival.iata,
                  date: getFormattedDateObjFromDateObject(airTrip.arrivalDate),
                },
              }
            : null,
          price:
            serviceItemData &&
            serviceItemData.valueData &&
            serviceItemData.valueData.totalValue
              ? `${getCurrencySymbol(
                  serviceItemData.valueData.totalValue.currencyCode,
                )} ${moneyFormatted(
                  serviceItemData.valueData.totalValue.value,
                )}`
              : null,
        };
      } else if (
        serviceItemData &&
        serviceItemData.type == enums.products.namedType.hotelBooking
      ) {
        let city = hotel.city ? hotel.city.split('-')[0] : null;
        return {
          hotel: hotel ? hotel.name : null,
          location: hotel
            ? {
                city: city,
                state: hotel.state,
              }
            : null,
          checkin:
            hotel && hotel.room
              ? getFormattedDateObjFromDateObject(hotel.room.checkIn)
              : null,
          price:
            serviceItemData &&
            serviceItemData.valueData &&
            serviceItemData.valueData.totalValue
              ? `${getCurrencySymbol(
                  serviceItemData.valueData.totalValue.currencyCode,
                )} ${
                  (serviceItemData.valueData.totalValue.currencyCode,
                  moneyFormatted(serviceItemData.valueData.totalValue.value))
                }`
              : null,
        };
      } else if (serviceItemData?.type == enums.products.namedType.vehicleBooking) {
        return {
          vehicle: serviceItemData.vehicle,
          price:
            serviceItemData?.valueData?.totalValue
              ? `${getCurrencySymbol(
                  serviceItemData.valueData.totalValue.currencyCode,
                )} ${
                  (serviceItemData.valueData.totalValue.currencyCode,
                  moneyFormatted(serviceItemData.valueData.totalValue.value))
                }`
              : null,
        }
      }
    };

    return (
      <>
        {loading && <Loading />}
        {splitProps?.isCostSplitAvailable && (
          <SplitModal {...{ splitProps, events }} />
        )}
        <div className="exon-travel-request-details">
          <div className="exon-row-itens exon-traveler-expiration exon-w-100 exon-row-aling-itens-top">
            <div
              className={`exon-w-100 ${
                haveProducts ? 'exon-box-travaler-information-details' : ''
              }`}
            >
              <TravelerInformations
                splitRef={splitRef}
                canEdit={canEdit}
                dataSources={dataSources}
                travelerInformations={{
                  travelers: request.travelers,
                  travelInfo: request,
                }}
              />
            </div>
            {request.expiration &&
              !request.expiration.ignore &&
              expirationMain && (
                <div className="exon-w-100">
                  <TravelExpiration
                    expirationInfos={{
                      main: {
                        bookingType: expirationMain.type,
                        expirationData: expirationMain.expiration.expireAt,
                        expired: expirationMain.expiration.expired,
                        bookingInfos: getExpirationBookingInfos(expirationMain),
                        id: expirationMain.id,
                      },
                      expirationBookings: request.itemsSummary.map(
                        (itemSummary) => {
                          return {
                            bookingType: itemSummary.type,
                            expirationData: itemSummary.expiration
                              ? itemSummary.expiration.expireAt
                              : null,
                            expired: itemSummary.expiration
                              ? itemSummary.expiration.expired
                              : null,
                            bookingInfos: getExpirationBookingInfos(
                              itemSummary.airTrips || itemSummary.hotel || itemSummary.vehicle
                                ? itemSummary
                                : null,
                            ),
                            id: itemSummary.id,
                          };
                        },
                      ),
                    }}
                  />
                </div>
              )}
          </div>
          <div className="exon-w-100">
            <BookingBox title="Resumo dos itens" />
            {request.audit.dataOrigin == enums.requestDataOrigin.manual &&
              !haveProducts && (
                <>
                  <div
                    className="exon-empty-product"
                    onClick={events.onOpenSelectItemTypeClicked}
                  >
                    <span>
                      <span className="exon-icon exon-icon-add-unfilled_enabled"></span>
                      Adicione novo item
                    </span>
                  </div>
                </>
              )}

            {haveProducts && (
              <BookingProductSummaryList
                requestId={request.id}
                bookings={request.itemsSummary}
                userTravelProfile={userTravelProfile}
                justificationEvents={justificationEvents}
                events={decisionEvents}
              />
            )}
          </div>
          {request.valueData && (
            <div className="exon-price-summary exon-w-100">
              {request?.valueData?.totalValue && (
                <span ref={splitRef} className="exon-price-summary-block">
                  <span className="exon-total-block">
                    Sua viagem está saindo por:
                    <span className="exon-split-block">
                      <span className="exon-value-block">
                        {moneyHelper(splitProps.costCenterSplit.total)}
                        {request?.costSplit?.length > 1 && (
                          <Icons
                            name="IconArrowsSplit2"
                            className="exon-split-icon"
                            fill="transparent"
                            size={24}
                          />
                        )}
                        {request?.valueData?.totalValue?.value > 0 &&
                          splitProps?.isCostSplitAvailable &&
                          request?.allowedActions?.canEditCostSplit && (
                            <span
                              onClick={() =>
                                events.toggleSplitModal(
                                  request?.costSplit?.length > 1,
                                )
                              }
                              className="exon-add-edit-split"
                            >
                              <Icons
                                name="IconPencil"
                                fill="transparent"
                                size={22}
                              />
                              <span>
                                {request?.costSplit?.length > 1
                                  ? 'Editar'
                                  : 'Adicionar'}{' '}
                                divisão
                              </span>
                            </span>
                          )}
                      </span>
                      {request?.costSplit?.length > 1 && (
                        <span className="exon-cost-centers">
                          {resetSplitData(
                            request.costSplit,
                            splitProps.costCenterSplit.total,
                          ).map((cc) => (
                            <p key={cc.key}>
                              <span>
                                {moneyHelper(cc.value)} (
                                {cc.percentage.toFixed(2).replace('.', ',')}%)
                              </span>
                              <span
                                className="exon-cost-center-name"
                                style={{ marginLeft: 24 }}
                              >
                                {cc.costCenter.label}
                              </span>
                            </p>
                          ))}
                        </span>
                      )}
                    </span>
                  </span>
                </span>
              )}

              {request?.valueData?.unsavedPotentialValue && (
                <span
                  className="exon-total-saved"
                  title="Valor calculado com base nos itens que possuem o melhor preço da cotação"
                >
                  Potencial não economizado*:
                  <span className="exon-value-block">
                    {moneyHelper(request.valueData.unsavedPotentialValue)}
                  </span>
                </span>
              )}
            </div>
          )}
        </div>
      </>
    );
  },
};

export default Template;
