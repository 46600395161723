let Template = (props) => {
  return (
    <div
      className={`exon-icon-component ${props.className} ${
        props.descriptionDown ? 'exon-display-block' : ''
      }`}
    >
      <i
        className={`exon-icon ${props.icon} ${
          props.descriptionDown ? 'exon-icon-center' : ''
        }`}
      />

      {props.description && <p>{props.description}</p>}
    </div>
  );
};

export default Template;
