import { enums } from '@legacy-utils/enums.js';
import Field from '../../../field/field.component.js';
import { listItemsResolver } from '../../../../utils/resolvers/listItemresolver.js';

export const CustomFields = ({
  fields,
  disabled,
  showValidationsFields,
  onFieldChange,
}) => (
  <div className="exon-fields-list">
    {fields &&
      fields.map((field, index) => {
        let fieldValue =
          field.value == undefined ? field.defaultValue : field.value;

        if (field.ControlType == enums.fieldControlType.options) {
          const optionsObj = JSON.parse(field.Options);
          const optionItems = listItemsResolver.resolve(optionsObj);

          return (
            <Field
              key={'checkout-custom_field-' + index}
              type={enums.fieldType.select}
              label={field.Label}
              value={fieldValue}
              refName={field.CustomFieldId}
              disabled={disabled}
              required={field.IsRequired}
              items={optionItems}
              onFieldChange={onFieldChange}
              forceValidation={showValidationsFields}
            />
          );
        } else {
          let type = enums.fieldType.text;
          switch (field.ControlType) {
            case enums.fieldControlType.date:
              type = enums.fieldType.date;
              break;
            case enums.fieldControlType.number:
              type = enums.fieldType.number;
              break;
          }

          return (
            <Field
              key={'checkout-custom_field-' + index}
              type={type}
              label={field.Label}
              value={fieldValue}
              disabled={disabled}
              refName={field.CustomFieldId}
              required={field.IsRequired}
              onFieldChange={onFieldChange}
              forceValidation={showValidationsFields}
            />
          );
        }
      })}
  </div>
);
