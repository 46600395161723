import {
  Button,
  Focused,
  Icons,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { WizardContainer, WizardLimitBox } from './styled';

type Props = PropsWithChildren<{
  steps: string[];
  activeStep?: number;
  onStepChange?: (step: number) => void;
  onCancel?: () => void;
  onComplete?: () => void;
  isSubmitting?: boolean;
}>;

export const Wizard = (props: Props) => {
  return (
    <WizardContainer>
      <WizardLimitBox>
        <Focused
          stepper={{
            activeStep: props.activeStep || 0,
            steps: props.steps,
            setActiveStep: props.onStepChange,
          }}
          containerStyle={{}}
          hasHeader={true}
          routes={[]}
          footer={{
            startActions: [
              <LinkButton
                variant="neutral"
                key={1}
                onClick={() => props.onCancel?.()}
                disabled={props.isSubmitting}
              >
                Cancelar
              </LinkButton>,
            ],
            endActions: [
              props.activeStep > 0 && (
                <Button
                  variant="secondary"
                  size="medium"
                  key={1}
                  onClick={() => props.onStepChange?.(props.activeStep - 1)}
                  disabled={props.isSubmitting}
                >
                  <Icons name="IconArrowLeft" fill="transparent" /> Voltar
                </Button>
              ),
              <Button
                variant="primary"
                size="medium"
                key={2}
                onClick={() => {
                  const isLast = props.activeStep >= props.steps.length - 1;

                  if (isLast) {
                    return props.onComplete?.();
                  }

                  return props.onStepChange?.(props.activeStep + 1);
                }}
                loading={props.isSubmitting}
              >
                Continuar <Icons name="IconArrowRight" fill="transparent" />
              </Button>,
            ],
          }}
        >
          <Box p={3}>{props.children}</Box>
        </Focused>
      </WizardLimitBox>
    </WizardContainer>
  );
};
