const Template = props => {
  const liProps = {...props}
  if (liProps?.iconClassName) delete liProps.iconClassName

  return <li {...liProps}>
    <i className={props.iconClassName}></i>
    {props.children}
  </li>
};

export default Template;
