import { useState, useMemo, useCallback } from 'react';
import { trpc } from '@api/bff/client';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';
import { ApprovalItem } from './types';
import { useTableColumns } from './ApprovalTable/hooks/useTableColumns';

const INITIAL_PAGINATION: PaginationState = {
  pageNumber: 1,
  pageSize: 10,
};

export function useApprovalTable() {
  const [pagination, setPagination] =
    useState<PaginationState>(INITIAL_PAGINATION);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Record<string, string[]>>({});

  const { data, isLoading, refetch, isError } = trpc.getApprovalFlows.useQuery(
    undefined,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const approvalItems: ApprovalItem[] = useMemo(
    () =>
      data?.approvalFlows.map((flow) => ({
        id: flow.id,
        name: flow.name,
        approvalType: flow.approvalType,
        description: flow.description,
        priority: flow.priority ?? 0,
        status: flow.status,
      })) ?? [],
    [data],
  );

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  const handleFilters = useCallback((newFilters) => {
    console.log('newFilters', newFilters);
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, pageNumber: 1 }));
  }, []);

  const filteredItems = useMemo(() => {
    return approvalItems.filter((item) => {
      // Aplicar filtros de pesquisa
      const matchesSearch = Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase()),
      );

      // Aplicar filtros específicos
      const matchesFilters = Object.entries(filters).every(([key, values]) => {
        // Acessa a propriedade correta do item e compara com os valores do filtro
        const itemValue = item[key as keyof ApprovalItem]?.toString() ?? '';

        // Verifica se o itemValue está incluído nos valores do filtro
        return values.length === 0 || values.includes(itemValue);
      });

      return matchesSearch && matchesFilters;
    });
  }, [approvalItems, search, filters]);

  const paginatedItems = useMemo(
    () =>
      filteredItems.slice(
        (pagination.pageNumber - 1) * pagination.pageSize,
        pagination.pageNumber * pagination.pageSize,
      ),
    [filteredItems, pagination],
  );

  console.log('paginatedItems', paginatedItems);

  const table = useTableColumns({
    data: paginatedItems,
    onPaginationChange: setPagination,
    pagination,
    refetch,
  });

  return {
    filteredItems,
    pagination,
    search,
    isLoading,
    table,
    handleSearch,
    handleFilters,
    setPagination,
    refetch,
    isError,
  };
}
