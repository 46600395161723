'use strict';

import React from 'react';
import Template from './air-search-results-options.template.js';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class AirSearchResultsOptionsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    let items = this.props.items;

    let currentPageTrips = items.map((item) => {
      return { currentPage: 1 };
    });

    this.state = {
      selectedTrip: 0,
      currentPageTrips: currentPageTrips,
    };
  }

  render() {
    let items = this.props.items;
    let selectedTrip = this.state.selectedTrip;

    return (
      <ErrorBoundary>
        <Template.main
          airOptions={items}
          dataSearchParameters={{
            searchData: this.props.searchData,
            isMultiple: this.props.isMultiple,
          }}
          dataNavigator={{
            searchData: this.props.searchData,
            isMultiple: this.props.isMultiple,
            amountTrips: items.length,
            selectedTrip: selectedTrip,
            navigatorEvents: this.events.navigator,
          }}
          dataPagination={{
            itemsPerPage: 5,
            currentPage: this.state.currentPageTrips[selectedTrip].currentPage,
            onPageClick: this.events.pagination.onPageClicked,
          }}
        />
      </ErrorBoundary>
    );
  }

  events = {
    navigator: {
      onTripClicked: (selectedTrip) => {
        this.setState({ selectedTrip });
      },
    },
    pagination: {
      onPageClicked: (currentPage) => {
        let selectedTrip = this.state.selectedTrip;
        let currentPageTrips = this.state.currentPageTrips;

        currentPageTrips[selectedTrip].currentPage = currentPage;
        this.setState({ currentPageTrips });
      },
    },
  };
}
