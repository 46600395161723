import { create } from 'zustand';

interface SelectedCompanyState {
  companySelected: any;
  displayPopupAgency: boolean;
  setCompany: (company: any) => void;
  setDisplayPopupAgency: (display: boolean) => void;
}

const useSelectedCompanyStore = create<SelectedCompanyState>((set) => ({
  companySelected: null,
  displayPopupAgency: true,
  setCompany: (company) => set({ companySelected: company }),
  setDisplayPopupAgency: (display) => set({ displayPopupAgency: display }),
}));

export default useSelectedCompanyStore;
