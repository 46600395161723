'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import TabTemplate from './tab.template.js';

export default class TabComponent extends React.Component {
  externalOptions = {
    events: {
      onSelected: null,
      onDeleted: null,
    },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      selectedTab: null,
    };
  }

  render() {
    return (
      <TabTemplate
        label={this.props.label}
        timerData={this.props.timerData}
        isExpired={this.props.isExpired}
        icon={this.props.icon}
        loading={this.props.loading}
        identifier={this.props.identifier}
        tooltip={this.props.tooltip}
        showDeleteButton={this.props.showDeleteButton || false}
        selectedTab={this.props.selectedTab}
        showCloseButton={this.props.showCloseButton || false}
        onTabClicked={this.events.onTabClicked}
        onTabDeleted={this.events.onTabDeleted}
      />
    );
  }

  events = {
    onTabClicked: function (identifier) {
      this.setState({ selectedTab: identifier });

      if (this.externalOptions.events.onTabClicked)
        // TODO: remover externalOption
        this.externalOptions.events.onTabClicked(identifier);

      if (this.props.onTabClick) this.props.onTabClick(identifier);
    },

    onTabDeleted: function (e, identifier) {
      e.stopPropagation();

      this.setState({ selectedTab: identifier });

      if (this.externalOptions.events.onTabDeleted)
        this.externalOptions.events.onTabDeleted(
          identifier,
          this.props.tabIndex,
        );

      if (this.props.onDeleteTabClick) this.props.onDeleteTabClick(identifier);
    },
  };
}
