import { enums } from '../../utils/enums';
import { utils } from '../../utils/utils';

function splitMethods(dataSource, options = [], callback) {
  return {
    isValid: () => {
      const data = this.state.costCenterSplit;
      const total = this.state.tripTotalValue;

      if (!data.length) return false;

      let value = total.value;
      let costCenter = true;
      let percentage = 100;

      for (let i = 0; i < data.length; i++) {
        value = parseFloat((value - data[i].value.value).toFixed(2));
        costCenter &&= !!data[i].costCenter;
        percentage = parseFloat((percentage - data[i].percentage).toFixed(2));
      }

      return costCenter && !value && !percentage;
    },
    isEdit: !!this.state?.isEdit,
    data: this.state.costCenterSplit,
    selected: this.state.selectedCostCenter,
    equalSplit: this.state.isEqualSplitEnabled,
    options,
    dataSource,
    total: this.state.tripTotalValue,
    edit: (newItems, equalSplit) => {
      this.setState(
        {
          isCostCenterSplitEnabled: !!newItems.length,
          isEqualSplitEnabled: !!newItems.length && equalSplit,
          costCenterSplit: newItems,
        },
        () => {
          if (!callback) return;

          if (newItems.length && newItems[0].costCenter) {
            return callback(null, newItems[0].costCenter);
          }

          callback();
        },
      );
    },
    toggleSplit: {
      checked: this.state.isCostCenterSplitEnabled,
      placeholder: 'Dividir custos?',
      externalOptions: {
        events: {
          onChanged: (isCostCenterSplitEnabled) => {
            this.setState({ isCostCenterSplitEnabled });
          },
        },
      },
    },
  };
}

function equalizeSplit(data, total, currencyCode) {
  let totalPercentage = 100;
  let totalValue = total;

  return data.map((item, i, a) => {
    let percentage = utils.floatingPointFix(100 / a.length, 6);
    let value = parseFloat((total * (percentage / 100)).toFixed(2));

    if (i + 1 !== a.length) {
      totalPercentage = utils.floatingPointFix(totalPercentage - percentage);
      totalValue -= value;
    } else {
      percentage = totalPercentage;
      value = totalValue;
    }

    return {
      key: item.key,
      costCenter: item.costCenter,
      value: new utils.money(value, currencyCode || enums.currencyCode.real),
      percentage: parseFloat(percentage.toFixed(2)),
    };
  });
}

function resetSplitData(costSplit, totalValue) {
  const costCenterSplit = [];
  let total = totalValue.value;

  for (let i = 0; i < costSplit.length; i++) {
    const value =
      i + 1 === costSplit.length
        ? total
        : totalValue.value * (costSplit[i].percentage / 100);

    const item = {
      percentage: costSplit[i].percentage,
      costCenter: {
        value: costSplit[i].costCenter.id,
        label: costSplit[i].costCenter.name,
        code: costSplit[i].costCenter.code,
      },
      value: new utils.money(
        parseFloat(value.toFixed(2)),
        totalValue.currencyCode,
      ),
      key: Math.floor(Math.random() * 10000),
    };

    total -= item.value.value;

    costCenterSplit.push(item);
  }

  return costCenterSplit;
}

export default splitMethods;

export { splitMethods, equalizeSplit, resetSplitData };
