import { enums } from '@legacy-utils/enums';

let TravelRequestNavigationTemplate = ({
  items,
  identifierActiveItem,
  isOffline,
  events,
}) => {
  return (
    <div className="exon-travel-request-navigation">
      <div className="exon-box-travel-request-navigation">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={
                'exon-travel-request-navigation-item' +
                (identifierActiveItem == item.identifier
                  ? ' exon-travel-request-navigation-item_active'
                  : '')
              }
              onClick={() => events.onItemClicked(item, index)}
            >
              {item.productType == enums.productType.air && (
                <span
                  className={
                    'exon-icon' +
                    (identifierActiveItem == item.identifier
                      ? ' exon-icon-aircraft-unfilled_disabled-white'
                      : ' exon-icon-aircraft-unfilled_disabled')
                  }
                ></span>
              )}

              {item.productType == enums.productType.hotel && (
                <span
                  className={
                    'exon-icon' +
                    (identifierActiveItem == item.identifier
                      ? ' exon-icon-bed-unfilled_disabled-white'
                      : ' exon-icon-bed-unfilled_disabled')
                  }
                ></span>
              )}

              {item.productType == enums.productType.vehicle && (
                <span
                  className={
                    'exon-icon' +
                    (identifierActiveItem == item.identifier
                      ? ' exon-icon-car-unfilled_disabled-white'
                      : ' exon-icon-car-unfilled_disabled')
                  }
                ></span>
              )}

              <span>{item.label}</span>
            </div>
          );
        })}
        {isOffline && (
          <div
            className="exon-travel-request-navigation-item"
            onClick={() => events.onAddItemClicked()}
          >
            <span className="exon-icon exon-icon-add-unfilled_enabled"></span>
            <span>Adicionar item</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TravelRequestNavigationTemplate;
