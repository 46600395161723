//CSS no padrão BEM (Bloco Elemento Modificador)

var SelectTemplate = {
  //Template principal "pai" dos demais componentes
  main: ({
    disabled,
    placeholder,
    selectOptions,
    showBackgroundMask,
    inputText,
    numberItemsSelected,
    isReadOnly,
    validationResult,
    onClick,
    onClickOutsideOptions,
  }) => (
    <div
      className={
        'exon-select' +
        (!validationResult.isValid ? ' exon-alert-border' : '') +
        `${isReadOnly ? ' readonly' : ''}`
      }
    >
      <div
        className={
          'exon-outside-options-background-mask ' +
          (!showBackgroundMask ? 'hide' : '')
        }
        onClick={onClickOutsideOptions}
      ></div>
      <input
        className="exon-textbox exon-select__field"
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        tooltip={inputText}
        value={inputText}
        readOnly
        onClick={(e) => {
          if (!isReadOnly) {
            onClick(e);
          }
        }}
      />
      <span className="exon-icon-down-arrow"></span>
      <span
        className={
          'exon-amount-itens-selected exon-select__amount-itens-selected ' +
          (numberItemsSelected <= 0 ? 'hide' : '')
        }
      >
        + {numberItemsSelected}
      </span>
      {!validationResult.isValid && (
        <span className={'exon-msg-alert-field'}>
          {validationResult.message}
        </span>
      )}
      {selectOptions}
    </div>
  ),

  //Template das opções a serem renderizadas a partir do resultado da busca
  options: ({
    options,
    showOptions,
    isMultiple,
    isZeroValid,
    onChange,
    onInputChange,
    searchInputText,
    showSearch,
  }) => (
    <ul
      className={
        'exon-select-itens exon-select__list ' + (!showOptions ? 'hide' : '')
      }
      tabIndex="1"
    >
      {showSearch && (
        <li className="exon-select-itens-search">
          <input
            value={searchInputText}
            type="text"
            placeholder={'Pesquisar'}
            onChange={(e) => onInputChange(e)}
          ></input>
        </li>
      )}
      {options ? (
        options.map((option, index) => {
          let disabled =
            (!isZeroValid && option.value === 0) || option.disabled;
          return (
            <SelectTemplate.option
              option={option}
              disabled={disabled}
              key={index}
              isMultiple={isMultiple}
              checked={option.checked}
              onChange={onChange}
            >
              {option.label}
            </SelectTemplate.option>
          );
        })
      ) : (
        <SelectTemplate.option disabled>
          <span className="exon-loader-icon"></span>
          Carregando itens...
        </SelectTemplate.option>
      )}
    </ul>
  ),

  //Template de cada item do resultado da busca
  option: ({ disabled, option, children, isMultiple, checked, onChange }) => {
    let value = (option && option.value) || '0';
    return (
      <li disabled={disabled} value={value} className="exon-select__list-item">
        <label className="exon-select__list-item-box">
          <input
            type={isMultiple ? 'checkbox' : 'radio'}
            name="exon-select-option"
            value={value}
            checked={checked}
            onClick={(e) => onChange(e, option)}
            onChange={() => null}
          />
          <label
            className={'exon-label-checkbox ' + (isMultiple ? '' : 'hide')}
          >
            <span></span>
          </label>
          {children}
        </label>
      </li>
    );
  },
};

export default SelectTemplate;
