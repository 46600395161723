import { SearchField } from '@flash-tecnologia/hros-web-ui-v2';
import { useDebounce } from 'use-debounce';
import { findRequesters } from '@api/connectOn/connectOn.service';
import React, { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { useTravelStore } from '../../../../store/RequestTravel.store';

interface TravelRequestersProps {
  control: Control;
  errors: FieldErrors;
}

export function TravelRequesters({ errors, control }: TravelRequestersProps) {
  const contextId = useTravelStore((state) => state.contextId);
  const company = useTravelStore((state) => state.company);
  const updateRequester = useTravelStore((state) => state.updateRequester);

  const [searchText, setSearchText] = useState('');
  const [requesters, setRequesters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [debouncedSearchText] = useDebounce(searchText, 300); // Debounce de 300ms

  useEffect(() => {
    const fetchRequesters = async () => {
      if (debouncedSearchText.length >= 3) {
        setLoading(true);
        try {
          const fetchedRequesters = await findRequesters(
            debouncedSearchText,
            contextId,
          );
          setRequesters(fetchedRequesters.requesters);
        } catch (error) {
          console.error('Erro ao buscar requesters:', error);
          setRequesters([]);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchRequesters();
  }, [debouncedSearchText]);

  return (
    <div>
      <Controller
        control={control}
        name="requester"
        render={({ field: { onChange } }) => (
          <div>
            <SearchField
              {...(onChange as any)}
              label="Solicitante"
              value={searchText}
              error={!!errors.requester}
              onInputChange={(event, value) => setSearchText(value)}
              onSearchChange={async (event, selectedItem) => {
                console.log('Solicitante', selectedItem);
                if (selectedItem) {
                  const item = {
                    name: selectedItem.label,
                    userId: selectedItem.value,
                  };
                  onChange(item);
                  updateRequester(item);
                }
              }}
              disabled={!company}
              options={requesters.map((requester) => ({
                label: requester.name,
                value: requester.userId,
              }))}
              loading={loading}
            />
            <DisplayErrorFormValidation errors={errors} fieldName="requester" />
          </div>
        )}
      />
    </div>
  );
}
