import { create } from 'zustand';
import React from 'react';

export type ModalState = {
  isOpen: boolean;
  title: string;
  subTitle: string;
  content: React.ReactNode | null;
  status: string;
  primaryButtonLabel?: string;
  primaryButtonAction?: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  isLoading?: boolean;
  isAlert?: boolean;
  isPrimaryButtonLoading: boolean;
  openModal: (modalData: Partial<ModalState>) => void;
  closeModal: () => void;
  setPrimaryButtonLoading: (isLoading: boolean) => void;
};

const initialState: Omit<
  ModalState,
  'openModal' | 'closeModal' | 'setPrimaryButtonLoading'
> = {
  isOpen: false,
  title: '',
  subTitle: '',
  content: null,
  status: '',
  primaryButtonLabel: undefined,
  primaryButtonAction: undefined,
  secondaryButtonLabel: undefined,
  secondaryButtonAction: undefined,
  isLoading: false,
  isAlert: false,
  isPrimaryButtonLoading: false,
};

const useModalStore = create<ModalState>((set) => ({
  ...initialState,
  openModal: (modalData) =>
    set(() => ({
      ...initialState,
      ...modalData,
      isOpen: true,
    })),
  closeModal: () => set(initialState),
  setPrimaryButtonLoading: (isLoading) =>
    set((state) => ({ ...state, isPrimaryButtonLoading: isLoading })),
}));

export default useModalStore;
