'use strict';

import React from 'react';
import Template from './booking-hotel-segments.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class BookingHotelSegmentsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.formManager, this);
    componentUtil.react.bindMethods(this.events.form.fields, this);

    this.state = {
      displayFareRule: false,
      fareRuleIndex: null,
      segments: this.props.segment ? this.props.segment : null,

      form: {
        show: false,
        events: this.events.form,
        fields: this.formManager.getFields(),
        forceValidation: false,
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          segment={this.props.segment ? this.props.segment : null}
          events={this.events}
          //Edição e adição de itens
          form={this.state.form}
          canEdit={this.props.canEdit}
        />
      </ErrorBoundary>
    );
  }
  events = {
    form: {
      popup: {
        onOpenFormPopupClicked: (action) => {
          let form = this.state.form;

          if (action == enums.actionsForm.edit) {
            this.formManager.filledFields(form);
          }

          form.show = true;

          this.setState({ form });
        },

        onCloseFormPopupClicked: () => {
          let form = this.state.form;

          this.formManager.clearFields(form);

          this.setState({
            form: {
              ...this.state.form,
              show: false,
              forceValidation: false,
            },
          });
        },

        onConfirmPopupClicked: () => {
          let booking = {
            hotel: this.props.hotel,
            saleType: null,
            bookingLocator: null,
          };

          if (this.formManager.fieldsValidate()) {
            this.formManager.prepareForShipping(booking);
            console.log(booking);

            this.props.events.onEditSegmentsClick &&
              this.props.events.onEditSegmentsClick(booking);

            this.setState({
              form: {
                ...this.state.form,
                show: false,
                forceValidation: false,
              },
            });
          }
        },
      },

      fields: {
        onLocationChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value.name = inputText;

          this.setState({ form });
        },

        onChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value = inputText;

          this.setState({ form });
        },

        onSelected: (refName, selectedData) => {
          let form = this.state.form;
          form.fields[refName].value = selectedData;

          this.setState({ form });
        },
      },
    },

    onDeleteSegmentClicked: () => {
      let booking = {};
      booking.hotel = {};

      this.props.events.onEditSegmentsClick &&
        this.props.events.onEditSegmentsClick(booking);
    },
  };

  formManager = {
    getFields: () => {
      return {
        city: this.formManager.generateField.location({
          label: 'Cidade',
          refName: 'city',
          groupField: 'location',
        }),
        name: this.formManager.generateField.text({
          label: 'Nome do Hotel',
          refName: 'name',
        }),
        street: this.formManager.generateField.text({
          label: 'Endereço',
          refName: 'street',
          groupField: 'location',
        }),
        number: this.formManager.generateField.number({
          label: 'Número',
          refName: 'number',
          groupField: 'location',
        }),
        postalCode: this.formManager.generateField.mask({
          label: 'CEP',
          refName: 'postalCode',
          mask: '99999-999',
          placeholder: '00000-000',
          groupField: 'location',
        }),
        checkIn: this.formManager.generateField.mask({
          label: 'Check In (da/mm/aaaa hh:mm)',
          refName: 'checkIn',
          mask: '99/99/9999 99:99',
          placeholder: '00/00/00 00:00',
          removeMask: false,
          groupField: 'date',
        }),
        checkOut: this.formManager.generateField.mask({
          label: 'Check Out (da/mm/aaaa hh:mm)',
          refName: 'checkOut',
          mask: '99/99/9999 99:99',
          placeholder: '00/00/00 00:00',
          groupField: 'date',
          removeMask: false,
        }),
        locator: this.formManager.generateField.text({
          label: 'Localizador',
          refName: 'locator',
          groupField: 'booking',
        }),
        voucher: this.formManager.generateField.text({
          label: 'Voucher',
          refName: 'voucher',
          required: false,
        }),
        voucherDate: this.formManager.generateField.mask({
          label: 'Data do voucher (da/mm/aaaa)',
          refName: 'voucherDate',
          mask: '99/99/9999',
          placeholder: '00/00/00',
          groupField: 'date',
          required: false,
          removeMask: false,
        }),
        //saleType: this.formManager.generateField.text({
        //    label: "Tipo de venda",
        //    refName: "saleType",
        //    groupField: "booking"
        //}),
        //passiveLoc: this.formManager.generateField.text({
        //    label: "Localizador passivo",
        //    refName: "passiveLoc",
        //    required: false
        //})
      };
    },

    filledFields: (form) => {
      let segment = this.state.segments;
      let addressHotel = segment.addressHotel;
      let getFormattedDateObjFromDateObject =
        utils.formatters.date.getFormattedDateObjFromDateObject;

      Object.keys(form.fields).forEach((refName) => {
        if (form.fields[refName])
          switch (refName) {
            case 'checkIn':
              segment.rooms &&
              segment.rooms[0] &&
              segment.rooms[0].fares &&
              segment.rooms[0].fares[0].fareInformation &&
              segment.rooms[0].fares[0].fareInformation.checkIn
                ? (form.fields[refName].value =
                    getFormattedDateObjFromDateObject(
                      segment.rooms[0].fares[0].fareInformation.checkIn,
                    ).dateFormatted +
                    ' ' +
                    getFormattedDateObjFromDateObject(
                      segment.rooms[0].fares[0].fareInformation.checkIn,
                    ).timeFormatted)
                : null;

              break;
            case 'checkOut':
              segment.rooms &&
              segment.rooms[0] &&
              segment.rooms[0].fares &&
              segment.rooms[0].fares[0].fareInformation &&
              segment.rooms[0].fares[0].fareInformation.checkOut
                ? (form.fields[refName].value =
                    getFormattedDateObjFromDateObject(
                      segment.rooms[0].fares[0].fareInformation.checkOut,
                    ).dateFormatted +
                    ' ' +
                    getFormattedDateObjFromDateObject(
                      segment.rooms[0].fares[0].fareInformation.checkOut,
                    ).timeFormatted)
                : null;

              break;
            case 'voucherDate':
              segment[refName]
                ? (form.fields[refName].value = segment[refName])
                : null;
              break;
            case 'location':
              form.fields[refName] == addressHotel[refName];
              break;
            default:
              if (form.fields[refName].groupField == 'location')
                if (refName == 'city')
                  form.fields[refName].value = {
                    id: addressHotel.locationId,
                    name: addressHotel[refName],
                  };
                else form.fields[refName].value = addressHotel[refName];
              else form.fields[refName].value = segment[refName];
          }
      });
    },

    clearFields: (form) => {
      Object.keys(form.fields).forEach((refName) => {
        if (form.fields[refName].type == enums.fieldType.locations)
          form.fields[refName].value = {};
        else form.fields[refName].value = null;
      });
    },

    generateField: {
      location: (data) => {
        let _this = this;
        return {
          type: enums.fieldType.locations,
          value: {},
          label: data.label,
          refName: data.refName,
          placeholder: `Digite ao menos 3 letras`,
          options: {
            events: {
              onInputChanged: (inputText) => {
                this.events.form.fields.onLocationChanged(
                  data.refName,
                  inputText,
                );
              },
              onSelected: (selectedData) => {
                this.events.form.fields.onSelected(data.refName, selectedData);
              },
            },
            dataSource: _this.props.dataSources.hotelLocations,
          },
          locationType: enums.locationType.city,
          disabled: false,
          required: data.required ? data.required : true,
          additionalClass: data.additionalClass ? data.additionalClass : null,
          groupField: data.groupField ? data.groupField : null,
        };
      },
      number: (data) => {
        let numberField = this.formManager.generateField.commomFields(data);
        numberField.type = enums.fieldType.number;

        return numberField;
      },
      text: (data) => {
        let textField = this.formManager.generateField.commomFields(data);
        textField.type = enums.fieldType.text;

        return textField;
      },
      select: (data) => {
        let selectField = this.formManager.generateField.commomFields(data);
        selectField.type = enums.fieldType.select;
        selectField.items = [];
        selectField.onFieldChange = this.events.form.fields.onSelected;
        selectField.dataSource = data.dataSource;
        selectField.completeItem = true;
        selectField.isZeroValid = true;

        return selectField;
      },
      mask: (data) => {
        let fieldMask = this.formManager.generateField.commomFields(data);
        fieldMask.type = enums.fieldType.mask;
        fieldMask.mask = data.mask;
        data.removeMask !== undefined
          ? (fieldMask.removeMask = data.removeMask)
          : null;

        return fieldMask;
      },
      commomFields: (data) => {
        return {
          placeholder: data.placeholder ? data.placeholder : `Digite aqui`,
          additionalClass: data.additionalClass ? data.additionalClass : null,
          label: data.label,
          refName: data.refName,
          value: data.valeu ? data.value : null,
          required:
            data.required != null || data.required != undefined
              ? data.required
              : true,
          disabled:
            data.disabled != null || data.disabled != undefined
              ? data.disabled
              : false,
          onFieldChange: this.events.form.fields.onChanged,
          groupField: data.groupField ? data.groupField : null,
        };
      },
    },

    fieldsValidate: () => {
      let isValid = true;
      let fields = this.state.form.fields;

      Object.keys(fields).forEach((refName) => {
        let fieldValue = fields[refName].value;

        if (
          refName != 'locCia' &&
          refName != 'voucher' &&
          refName != 'voucherDate' &&
          refName &&
          refName != 'passiveLoc'
        )
          isValid =
            isValid &&
            fieldValue !== null &&
            fieldValue !== undefined &&
            fieldValue !== '';
      });

      if (!isValid)
        this.setState({
          form: {
            ...this.state.form,
            forceValidation: true,
          },
        });

      return isValid;
    },

    prepareForShipping: (booking) => {
      let fields = this.state.form.fields;
      let address = {};

      Object.keys(fields).forEach((refName) => {
        let field = fields[refName];

        switch (field.groupField) {
          case 'booking':
            if (refName == 'locator') booking.bookingLocator = field.value;
            else booking[refName] = field.value;
            break;
          case 'location':
            if (refName == 'city') {
              address[refName] = field.value.name
                ? field.value.name
                : field.value;
              address.locationId = field.value.id;
            } else address[refName] = field.value;

            booking.hotel.address = address;
            break;
          case 'date':
            if (refName == 'checkIn' || refName == 'checkOut') {
              !booking.hotel.rooms || booking.hotel.rooms.length == 0
                ? (booking.hotel.rooms = [])
                : null;
              !booking.hotel.rooms[0].fares ||
              booking.hotel.rooms[0].fares.length == 0
                ? (booking.hotel.rooms[0].fares = [])
                : null;

              booking.hotel.rooms.forEach((room) => {
                if (!room.fares[0]) room.fares[0] = {};

                !room.fares[0].fareInformation
                  ? (room.fares[0].fareInformation = {})
                  : null;
                room.fares[0].fareInformation[refName] =
                  utils.date.strDateTimeToObjectDate(field.value, '/');
              });
            } else
              booking.hotel[refName] = utils.converters.date.stringToDateObject(
                field.value,
              );
            break;

          default:
            booking.hotel[refName] = field.value;
        }
      });
    },
  };
}
