import React from 'react';
import MonitoringManagerTemplate from './monitoring-manager.template.js';
import { enums } from '@legacy-utils/enums';
import { utils, gritter } from '@legacy-utils/utils';
import { apiResponse } from '@legacy-utils/apiResponse';
import { componentUtil } from '@legacy-utils/componentUtil';
import { formatterUtil } from '../../../utils/formatter.js';

export default class MonitoringManagerComponent extends React.Component {
  externalOptions = {
    dataSources: this.props.dataSources,
    agency: { isAgency: this.props.isAgency },
  };

  constructor(props) {
    super(props);

    componentUtil.react.bindMethods(this.dataSource, this);
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      viewBySelected: {
        value: 1,
        label: 'Hora de Expiração',
      },
      typeSelected: 'servicerequest',

      intervals: [
        enums.dateIntervals.lessThenThree,
        enums.dateIntervals.minusThree,
        enums.dateIntervals.minusTwo,
        enums.dateIntervals.minusOne,
        enums.dateIntervals.zero,
        enums.dateIntervals.one,
        enums.dateIntervals.two,
        enums.dateIntervals.three,
        enums.dateIntervals.greaterThenThree,
      ],
      matrixStatus: [
        { id: [0], name: 'Inicial' },
        { id: [1], name: 'Aberto' },
        //{ id: 2, name: 'Em Cotação' },
        //{ id: 3, name: 'Em Opção' },
        { id: [21], name: 'Erro ao Reservar' },
        { id: [4], name: 'Reservado' },
        { id: [5], name: 'Em Aprovação' },
        { id: [6], name: 'Reprovado' },
        { id: [7], name: 'Emissão Agência' },
        { id: [12], name: 'Emissão Autom.' },
        { id: [8], name: 'Erro na Emissão' },
        { id: [9, 13, 14, 15, 16], name: 'Emitido' },
        { id: [10], name: 'Em Cancelamento' },
        { id: [11, 17, 18, 19, 20], name: 'Cancelado' },
      ],

      matrix: [],
      rows: [],
      rowsGrid: [],

      currentPage: 1,
      updateAt: null,
    };
  }

  render() {
    let filterDataSources = {
      companies: this.externalOptions.dataSources.companies,
      locations: this.externalOptions.dataSources.locations,
    };

    return (
      <MonitoringManagerTemplate.main
        events={this.events}
        requetsEvents={this.props.requetsEvents}
        agency={this.externalOptions.agency}
        filterDataSources={filterDataSources}
        viewBySelected={this.state.viewBySelected}
        rows={this.state.rows}
        onIdentifierCellClicked={this.events.onIdentifierCellClicked.bind(this)}
        rowsGrid={this.state.rowsGrid}
        gridDataSource={{
          serviceRequest: this.externalOptions.dataSources.serviceRequest,
          serviceRequestItem:
            this.externalOptions.dataSources.serviceRequestItem,
        }}
        currentPage={this.state.currentPage}
        onPaginationClick={this.events.onPaginationClicked.bind(this)}
        updateAt={this.state.updateAt}
      />
    );
  }

  componentDidMount() {
    this.update();
  }

  update() {
    let searchDefault = {
      filter: {
        viewBy: 1,
        travelType: 'servicerequest',
        company: { contextId: 0 },
        locationId: 0,
      },
    };
    this.dataSource.monitoring.load(searchDefault);
  }

  dataSource = {
    monitoring: {
      load: (loadOption) => {
        const _this = this;
        const dataSource = this.externalOptions.dataSources.monitoring;

        if (dataSource.events && dataSource.events.beforeLoad)
          dataSource.events.beforeLoad();

        dataSource
          .load(loadOption)
          .then((results) => {
            if (results.successful) {
              let items = _this.convertFromApiFormat(results);

              let matrix = _this.buildMatrix();
              _this.setIdentifier(matrix);

              let matrixPopulate = _this.populateMatrixItems(
                matrix,
                items,
                loadOption.filter.viewBy,
              );
              let transformMatrixInRows = _this.transformsMatrixIntoCells(
                matrixPopulate,
                items,
              );
              let rows = _this.calculatorTotalItems(transformMatrixInRows);

              this.setState({
                items: items,
                rows: rows,
                matrix: matrixPopulate,
                rowsGrid: [],
                updateAt: new Date(),
              });

              if (dataSource.events && dataSource.events.afterLoad)
                dataSource.events.afterLoad(items);
            } else {
              gritter.Error(results.messages[0].message);
              console.log(results);

              if (dataSource.events && dataSource.events.afterLoad)
                dataSource.events.afterLoad(results);
            }
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(
              error,
              'Ocorreu um erro ao buscar os registro de viagens.',
            );

            if (dataSource.events && dataSource.events.afterLoad)
              dataSource.events.afterLoad(error);
          });
      },
    },
  };

  events = {
    onApplyFilterClicked(obj) {
      let viewBy = {
        value: obj.filter.viewBy.value,
        label: obj.filter.viewBy.label,
      };
      let searchparamters = { ...obj };
      searchparamters.filter.viewBy = obj.filter.viewBy.value;

      this.dataSource.monitoring.load(searchparamters);

      this.setState({
        viewBySelected: viewBy,
        typeSelected: obj.filter.travelType,
        rowsGrid: [],
      });
    },

    onIdentifierCellClicked(identifier) {
      let matrix = this.state.matrix;
      let monitoringItems = this.state.items;

      let itemIdsClicked = matrix.find((t) => t._identifier === identifier);
      let rows = [];

      if (this.state.typeSelected === 'servicerequest') {
        for (var i = 0; i < itemIdsClicked.items.length; i++) {
          let obj = monitoringItems.items.find(
            (m) =>
              m.id === itemIdsClicked.items[i].id &&
              m.rootContextId === itemIdsClicked.items[i].rootContextId,
          );

          if (obj.items) {
            for (let j = 0; j < obj.items.length; j++) {
              rows.push(obj.items[j]);
            }
          } else {
            rows.push({
              parentId: obj.id,
              id: '-',
              date: '-',
              requester: obj.requester ? obj.requester : { fullName: '-' },
              travelers: obj.travelers ? obj.travelers : [{ fullName: '-' }],
              approvers: obj.approvers ? obj.approvers : [{ fullName: '-' }],
              status: { id: '' },
            });
          }
        }
      } else {
        for (var i = 0; i < itemIdsClicked.items.length; i++) {
          let obj = monitoringItems.items.find(
            (m) =>
              m.id == itemIdsClicked.items[i].id &&
              m.rootContextId === itemIdsClicked.items[i].rootContextId,
          );

          rows.push(obj);
        }
      }

      rows.sort(function (x, y) {
        return x.parentId - y.parentId;
      });

      this.setState({
        rowsGrid: rows,
        currentPage: 1,
      });
    },

    onPaginationClicked: (currentPage) => {
      this.setState({ currentPage });
    },

    onUpdateClicked: () => {
      this.update();
    },
  };

  convertFromApiFormat(result) {
    let items = [];

    if (this.state.typeSelected === 'servicerequest') {
      items = result.travelRequest.items.map((item) => {
        return {
          id: item.id,
          type: item.type,
          status: item.status,
          expiresAt: item.expiresAt,
          createdAt: item.createdAt,
          startDate: item.startDate,
          endDate: item.endDate,
          rootContextId: item.rootContextId,
          requester: {
            id: !item.requester ? null : item.requester.userId,
            fullName: !item.requester ? '-' : item.requester.name,
          },
          travelers: !item.travelers
            ? [{ fullName: '-' }]
            : item.travelers.map((traveler) => {
                return {
                  id: traveler.userId,
                  fullName: traveler.name,
                };
              }),
          approvers: !item.approvers
            ? [{ fullName: '-' }]
            : item.approvers.map((approver) => {
                return {
                  id: approver.userId,
                  fullName: approver.name,
                };
              }),
          items: !item.items
            ? null
            : item.items.map((subItem) => {
                return {
                  id: subItem.id,
                  type: subItem.type,
                  parentId: item.id,
                  status: subItem.status,
                  expiresAt: subItem.expiresAt,
                  createdAt: subItem.createdAt,
                  startDate: subItem.startDate,
                  endDate: subItem.endDate,
                  rootContextId: item.rootContextId,
                  requester: {
                    id: !item.requester ? null : item.requester.userId,
                    fullName: !item.requester ? '-' : item.requester.name,
                  },
                  travelers: !item.travelers
                    ? [{ fullName: '-' }]
                    : item.travelers.map((traveler) => {
                        return {
                          id: traveler.userId,
                          fullName: traveler.name,
                        };
                      }),
                  approvers: !item.approvers
                    ? [{ fullName: '-' }]
                    : item.approvers.map((approver) => {
                        return {
                          id: approver.userId,
                          fullName: approver.name,
                        };
                      }),
                };
              }),
        };
      });
    } else {
      result.travelRequest.items.map((item) => {
        item.items.map((subItem) => {
          items.push({
            id: subItem.id,
            type: subItem.type,
            parentId: item.id,
            status: subItem.status,
            expiresAt: subItem.expiresAt,
            createdAt: subItem.createdAt,
            startDate: subItem.startDate,
            endDate: subItem.endDate,
            rootContextId: item.rootContextId,
            requester: {
              id: !item.requester ? null : item.requester.userId,
              fullName: !item.requester ? '-' : item.requester.name,
            },
            travelers: !item.travelers
              ? [{ fullName: '-' }]
              : item.travelers.map((traveler) => {
                  return {
                    id: traveler.userId,
                    fullName: traveler.name,
                  };
                }),
            approvers: !item.approvers
              ? [{ fullName: '-' }]
              : item.approvers.map((approver) => {
                  return {
                    id: approver.userId,
                    fullName: approver.name,
                  };
                }),
          });
        });
      });
    }

    return {
      items: items,
      currentDate: result.travelRequest.currentDate,
    };
  }

  buildMatrix() {
    let intervals = this.state.intervals;
    let matrixStatus = this.state.matrixStatus;
    let matrix = [];

    matrixStatus.forEach((statusItem) => {
      intervals.forEach((interval) => {
        matrix.push({
          key: {
            status: statusItem.id,
            interval: interval,
            name: statusItem.name,
          },
          items: [],
        });
      });
    });

    return matrix;
  }
  transformsMatrixIntoCells(matrixPopulate) {
    let rows = [];

    this.state.matrixStatus.forEach((status) => {
      let row = { name: status.name, cells: [], total: 0 };

      this.state.intervals.forEach((interval) => {
        let cellData = matrixPopulate.find(
          (m) =>
            m.key.status.join('_') === status.id.join('_') &&
            m.key.interval === interval,
        );
        row.cells.push(cellData);
      });

      rows.push(row);
    });

    return rows;
  }
  populateMatrixItems(matrix, monitoringItems, viewBy) {
    let items = [];

    if (monitoringItems.items) {
      items = monitoringItems.items;
    }

    let currentDate = new Date(monitoringItems.currentDate);
    currentDate.setHours(currentDate.getHours());

    if (items.length > 0) {
      matrix.forEach((matrixItem) => {
        this.populateInterval(
          items,
          currentDate,
          matrixItem,
          viewBy,
          monitoringItems,
        );
      });
    }

    return matrix;
  }
  populateInterval(items, currentDate, matrixItem, viewBy, monitoringItems) {
    items.forEach((item) => {
      if (matrixItem.key.status.some((_) => _ == item.status.id)) {
        let compareValue;
        if (viewBy == 1) {
          compareValue =
            item.expiresAt && item.expiresAt.year > 1
              ? utils.converters.date.dateObjectToDate(item.expiresAt)
              : currentDate;
        } else if (viewBy == 2) {
          compareValue = formatterUtil.dateObjToDate(item.startDate);
        } else {
          compareValue = formatterUtil.dateObjToDate(item.createdAt);
        }

        let interval;
        if (viewBy == 1) {
          interval = utils.date.hoursDiff(compareValue, currentDate);
        } else {
          let initialDate = this.resolveDateTime(monitoringItems.currentDate);
          interval = this.dayDiff(
            compareValue,
            new Date(initialDate.year, initialDate.month - 1, initialDate.day),
          );
        }

        if (matrixItem.key.interval <= -4 && interval <= -4) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        } else if (matrixItem.key.interval === -3 && interval === -3) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        } else if (matrixItem.key.interval === -2 && interval === -2) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        } else if (matrixItem.key.interval === -1 && interval === -1) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        } else if (matrixItem.key.interval === 0 && interval === 0) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        } else if (matrixItem.key.interval === 1 && interval === 1) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        } else if (matrixItem.key.interval === 2 && interval === 2) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        } else if (matrixItem.key.interval === 3 && interval === 3) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        } else if (matrixItem.key.interval >= 4 && interval >= 4) {
          matrixItem.items.push({
            id: item.id,
            rootContextId: item.rootContextId,
          });
        }
      }
    });
  }
  calculatorTotalItems(rows) {
    rows.forEach((row) => {
      this.state.intervals.forEach((interval) => {
        let cell = row.cells.find((c) => c.key.interval == interval);
        if (cell.items.length > 0) row.total += cell.items.length;
      });
    });

    return rows;
  }

  setIdentifier(items) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item._identifier = i;
    }
  }
  dayDiff = function (initialDate, finalDate) {
    const day = 24 * 60 * 60 * 1000;
    let dif = finalDate.getTime() - initialDate.getTime();
    return Math.ceil(dif / day);
  };
  resolveDateTime(dateAndTime) {
    const [date, time] = dateAndTime.split('T');
    const resolveTime = time.split(':');
    const resolveDate = date.split('-');

    const resolveDateTime = {
      day: parseInt(resolveDate[2]),
      month: parseInt(resolveDate[1]),
      year: parseInt(resolveDate[0]),
      hour: parseInt(resolveTime[0]),
      minute: parseInt(resolveTime[1]),
      second: parseInt(resolveTime[2]),
    };

    return resolveDateTime;
  }
}
