import HotelDetails from '../hotel-option-details/hotel-option-details.component.js';

var Template = {
  main: ({ hotel, fareData, actions }) => {
    return (
      <div className="exon-hotel-option">
        <div className="exon-hotel-option-box">
          <HotelDetails hotel={hotel} actions={actions.details} />

          <Template.fare data={fareData} actions={actions.hotel} />
        </div>
      </div>
    );
  },

  fare: ({ data, actions }) => {
    return (
      <div className="exon-hotel-option-fare">
        <div className="exon-hotel-option-fare-box">
          <div className="exon-hotel-option-politics"></div>
          <div className="exon-hotel-option-fare-value">
            <p>
              <span>{data.totalDaily.currencySymbol}</span>
              {data.totalDaily.value}
            </p>
          </div>
        </div>
        <span className="exon-hotel-option-taxes">
          <p>
            Tarifas
            <strong>{data.dailyFare}</strong>
          </p>
        </span>
        <span className="exon-hotel-option-services">
          <p>
            Taxa e impostos
            <strong>{data.dailyTaxes}</strong>
          </p>
        </span>
        <span className="exon-hotel-option-total">
          <p>
            <strong>Total 1 diária</strong>
            <span>{data.total}</span>
          </p>
        </span>
        {actions && (
          <div className="exon-hotel-option-fare-actions">{actions}</div>
        )}
      </div>
    );
  },
};

export default Template;
