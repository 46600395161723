import { useState } from 'react';

import {
  dataSources as monitoringDataSources,
  setLoader,
} from '@legacy-dataSources/monitoring.dataSources';
import { resolveCompanies } from '@legacy-dataSources/profile.dataSources';

import { ILoader } from '@shared/loader';
import { UserProfileData } from '@shared/types';
import { Loading } from '@components/Loading/Loading';

import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import TravelRequests from '@legacy-components/travel-requests/travel-requests.component';

import PageBase from '../PageBase';

const Page = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const loader: ILoader = {
    toggleLoading(isLoading) {
      setLoading(isLoading);
    },
  };
  setLoader(loader);

  const [userProfile, setUserProfile] = useState<UserProfileData>(null);
  monitoringDataSources.companies.load = async () =>
    resolveCompanies(userProfile);

  return (
    <PageBase
      pageTitleKey="pages.monitoring.title"
      userProfileLoaded={(profileData) => setUserProfile(profileData)}
      skeleton={<Skeleton variant="rectangular" height="100px" width="100%" />}
    >
      {loading && <Loading />}
      {userProfile && (
        <TravelRequests
          externalOptions={{
            isAgency: userProfile.company.isAgency,
            userTravelProfile: userProfile.travelProfile,
            currentUserId: userProfile.id,
            userData: userProfile,
            dataSources: monitoringDataSources,
            monitoring: true,
          }}
        />
      )}
    </PageBase>
  );
};

export default Page;
