export enum FareFamily {
  FAMILY_1 = 'FAMILY_1',
  FAMILY_2 = 'FAMILY_2',
  FAMILY_3 = 'FAMILY_3',
  FAMILY_4 = 'FAMILY_4',
}

export enum PolicyAction {
  NOTIFY = 'NOTIFY',
  BLOCK = 'BLOCK',
  JUSTIFY = 'JUSTIFY',
  NO_ACTION = 'NO_ACTION',
}

export enum PolicyConditionField {
  PROJECT = 'PROJECT',
  COMPANY = 'COMPANY',
  SUBSIDIARY = 'SUBSIDIARY',
  AREA = 'AREA',
  JOB_POSITION = 'JOB_POSITION',
  USER = 'USER',
  USER_CUSTOM_FIELD_1 = 'USER_CUSTOM_FIELD_1',
  USER_CUSTOM_FIELD_2 = 'USER_CUSTOM_FIELD_2',
  USER_CUSTOM_FIELD_3 = 'USER_CUSTOM_FIELD_3',
  USER_CUSTOM_FIELD_4 = 'USER_CUSTOM_FIELD_4',
  USER_CUSTOM_FIELD_5 = 'USER_CUSTOM_FIELD_5',
  USER_CUSTOM_FIELD_6 = 'USER_CUSTOM_FIELD_6',
  USER_CUSTOM_FIELD_7 = 'USER_CUSTOM_FIELD_7',
  USER_CUSTOM_FIELD_8 = 'USER_CUSTOM_FIELD_8',
  USER_CUSTOM_FIELD_9 = 'USER_CUSTOM_FIELD_9',
  USER_CUSTOM_FIELD_10 = 'USER_CUSTOM_FIELD_10',
}

export enum PolicyRuleCode {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  P6 = 'P6',
  P7 = 'P7',
  P8 = 'P8',
}

export enum PolicyRuleStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum PolicyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TravelProduct {
  AIR = 'AIR',
  HOTEL = 'HOTEL',
  VEHICLE = 'VEHICLE',
  ALL = 'ALL',
}

export enum TravelSource {
  B2B = 'B2B',
  OMNIBEES = 'OMNIBEES',
  ITERPEC = 'ITERPEC',
  OFFLINE = 'OFFLINE',
  AZUL = 'AZUL',
  GOL = 'GOL',
  LATAM = 'LATAM',
  SABRE = 'SABRE',
  WOOBA = 'WOOBA',
}

export enum TravelType {
  ALL = 'ALL',
  NATIONAL = 'NATIONAL',
  INTERNATIONAL = 'INTERNATIONAL',
}

export enum PolicyLevel {
  PROJECT = 'PROJECT',
  USER = 'USER',
  JOB_POSITION = 'JOB_POSITION',
  AREA = 'AREA',
  SUBSIDIARY = 'SUBSIDIARY',
  COMPANY = 'COMPANY',
}
