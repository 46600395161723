import { ILoader } from '@shared/loader';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import {
  doAirSearch,
  doHotelSeach,
  findAirLocations,
  findHotelLocations,
  findHotelPointsOfInterest,
  getAirlines,
  getCompanySettings,
  getHotelDetails,
  getHotelRoomDetails,
  getLicenceFeaturesByContext,
  preBooking,
  previewTemplate,
  processBooking,
  sendTemplate,
} from '../../api/connectOn/connectOn.service';
import {
  dataSources as profileDataSources,
  setLoader as setProfileDSLoader,
} from './profile.dataSources';
import {
  setLoader as setTravelRequestsDSLoader,
  dataSources as travelRequestsDataSources,
} from './travelRequests.dataSources';

const captalizeResponseObject = (obj) => {
  if (obj && Array.isArray(obj)) {
    obj.forEach((_) => {
      if (typeof _ == 'object') captalizeResponseObject(_);
    });
  } else {
    Object.keys(obj).map((key) => {
      const captalized = key.charAt(0).toUpperCase() + key.slice(1);
      if (captalized !== key) {
        if (typeof obj[key] == 'object') captalizeResponseObject(obj[key]);

        obj[captalized] = obj[key];
        delete obj[key];
      }
    });
  }
};

let _loader: ILoader;
export const setLoader = (loader: ILoader) => {
  _loader = loader;

  setProfileDSLoader(loader);
  setTravelRequestsDSLoader(loader);
};
const toggleModalLoading = (show: boolean) => {
  _loader.toggleLoading(show);
};

export const dataSources = {
  ...profileDataSources,
  serviceRequest: {
    load: travelRequestsDataSources.serviceRequest.load,
  },
  requester: {
    load: async (loadOptions: any) => {
      const requesters = await profileDataSources.requesters.load(
        loadOptions.keywords,
        loadOptions.contextId,
      );

      return requesters.map((_: any) => {
        return {
          ...listItemsResolver.resolveItem(_, 'RequesterId', 'Name'),
          email: _.Email,
        };
      });
    },
    events: {
      beforeLoad: () => {
        toggleModalLoading(true);
      },
      afterLoad: () => {
        toggleModalLoading(false);
      },
    },
  },
  companies: { load: async () => {} },
  airLines: {
    load: async (loadOptions) => {
      const response = await getAirlines();

      let listItems = [];
      response.airlines.forEach((item) => {
        const resolvedItem = listItemsResolver.resolveItem(
          item,
          'code',
          'displayName',
        );
        listItems.push({ ...resolvedItem, ...item });
      });

      listItems = listItemsResolver.sort(listItems);

      return listItems;
    },
  },
  airSearch: {
    load: function (loadOptions) {
      return doAirSearch(loadOptions.request, loadOptions.contextId);
    },
    events: {
      beforeLoad: function (loadOptions) {},
      afterLoad: function (items) {},
    },
  },
  hotelDetails: {
    load: function (loadOptions) {
      return getHotelDetails(loadOptions);
    },
    events: {
      beforeLoad: function (loadOptions) {},
      afterLoad: function (items) {},
    },
  },
  locations: {
    load: function (loadOptions) {
      return findAirLocations(loadOptions.keywords);
    },
    events: {
      beforeLoad: function (loadOptions) {},
      afterLoad: function (items) {},
    },
  },
  budget: {
    load: (loadOptions, contextId) => {
      return previewTemplate(loadOptions, contextId);
    },
    send: (data, contextId) => {
      return sendTemplate(data, contextId);
    },
    events: {
      beforeLoad: () => {
        toggleModalLoading(true);
      },
      afterLoad: () => {
        toggleModalLoading(false);
      },
    },
  },

  //#region Hotel
  destination: {
    load: function (loadOptions) {
      return findHotelLocations(loadOptions.keywords);
    },
  },
  pointOfInterest: {
    load: function (loadOptions) {
      return findHotelPointsOfInterest(loadOptions.keywords);
    },
  },
  hotels: {
    load: function (loadOptions) {
      return doHotelSeach(loadOptions);
    },
  },
  hotelsRoomDetails: {
    load: async (loadOptions) => {
      return getHotelRoomDetails(loadOptions);
    },
  },
  //#endregion

  //#region Checkout
  preBooking: {
    load: function (loadOptions) {
      return preBooking(loadOptions);
    },
  },
  processBooking: {
    load: async (loadOptions) => {
      return processBooking(loadOptions);
    },
  },
  //#endregion

  //#region Licence Features
  licenceFeatures: {
    load: async (contextId: string) => {
      return getLicenceFeaturesByContext(contextId);
    },
  },
  //#endregion
};
