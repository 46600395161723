import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { useTravelItemStore } from '../../../../store/RequestTravelItem.store';

interface TravelReasonProps {
  control: Control;
  errors: FieldErrors;
}

export function TravelTypeItem({ errors, control }: TravelReasonProps) {
  const setModalOpen = useTravelItemStore((state) => state.setModalOpen);

  const typeProduct = [
    {
      label: 'Veículo',
      value: 3,
    },
  ];

  return (
    <div className={'type-item'} style={{ paddingTop: 20, paddingBottom: 20 }}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value, ...field } }) => (
              <SelectField
                onSelectChange={(event, selectedItem) => {
                  if (
                    selectedItem &&
                    (selectedItem.value === 1 || selectedItem.value === 2)
                  ) {
                    setModalOpen(false);
                  }
                  onChange(selectedItem?.value);
                }}
                error={!!errors.type}
                value={value}
                options={typeProduct}
                label={'Selecione o tipo do item'}
                helperText={
                  <DisplayErrorFormValidation
                    errors={errors}
                    fieldName={'type'}
                  />
                }
                {...(field as any)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
