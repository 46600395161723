import BookingBox from '../booking-box/booking-box.component.js';
import BookingTravelerList from '../booking-traveler-list/booking-traveler-list.component.js';

import Infos from './infos/vehicle-booking-infos.component.js';
import Segments from './segments/vehicle-booking-segments.component.js';
import FaresAndFees from './fares-and-fees/vehicle-booking-fares-and-fees.component.js';
import OriginalRequest from './original-request/vehicle-booking-original-request.component.js';

import Popup from '../../popup/popup.component.js';

import BookingStatusBar from '../booking-status-bar/booking-status-bar.component.js';
import BookingPayment from '../booking-payment/booking-payment.component.js';
import BookingIntegrations from '../../integrations/booking-integrations.component.js';

import { utils } from '../../../utils/utils.js';
import { Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { enums } from '../../../utils/enums.js';

import RequestTravelItemOffline from '../../../../components/RequestTravel/RequestTravelItemOffline';
import { useEffect } from 'react';
import { useFeeTariffRatesStore } from '../../../../store/FeeTariffRates.store';

const Template = {
  main: ({
    booking,
    events,

    dataSources,
    onShowEmailPopupClick,

    popupEditSegments,
    popupEditFaresAndFees,
    popupDeleteFaresAndFees,
  }) => {
    const getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;

    const expirationDate = booking.expiration
      ? getFormattedDateObjFromDateObject(booking.expiration.expireAt)
      : null;

    let canEdit = false;
    let canEditFares = false;

    if (!booking.loading) {
      canEdit = booking.allowedActions.canEdit;
      canEditFares = booking.allowedActions.canEditFares;
    }
    const setOnSaveSuccess = useFeeTariffRatesStore(
      (state) => state.setOnSaveSuccess,
    );

    useEffect(() => {
      setOnSaveSuccess(() => {
        events.booking.onUpdateBookingClicked();
      });
    }, [setOnSaveSuccess, popupEditSegments]);

    //TODO: alterar para API não retornar originalRequest caso não esteja preenchido
    const hasOriginalRequest = booking.vehicle?.originalRequest?.rentalCompany;

    return (
      <div className="exon-vehicle-booking">
        {booking.loading && (
          <div className="exon-box-vehicle-loading">
            <div>
              <span className="exon-icon">
                <Loader variant="primary" size="medium" />
              </span>
              <p>
                Por favor aguarde!
                <br />
                Estamos carregando as informações da{' '}
                <span>Reserva de veículo</span>...
              </p>
            </div>
          </div>
        )}

        {!booking.loading && (
          <div className="exon-vehicle-booking-box">
            <div className="exon-vehicle-booking-box-status exon-row-itens exon-row-aling-itens-center">
              <BookingStatusBar
                statusInfos={{
                  status: booking.workflow.status,
                  lock: booking.lock,
                  expirationDate: expirationDate
                    ? `${expirationDate.dateFormatted} ${expirationDate.timeFormatted}`
                    : 'Sem data',
                  ignoreExpiration: booking.expiration?.ignore,
                  canEdit: canEdit,
                  onEditClick: events.editItems.onEditSegmentsClicked,
                }}
              />
              <div className="exon-actions-travel-request exon-row-itens exon-row-justify-content-space-between exon-row-aling-itens-center exon-w-100">
                <div className="exon-row-itens ">
                  <span
                    className="exon-icon exon-icon-double-update-unfilled_enabled exon-row-aling-itens-center"
                    onClick={events.booking.onUpdateBookingClicked}
                  ></span>
                  {booking.allowedActions.sendNotification && (
                    <span
                      className="exon-icon exon-icon-envelope-filled_enabled"
                      onClick={() =>
                        onShowEmailPopupClick(
                          booking.id,
                          booking.workflow.status.id,
                          false,
                        )
                      }
                    ></span>
                  )}
                </div>
              </div>
            </div>
            <div className="exon-row-itens">
              <BookingBox title="Condutor">
                <BookingTravelerList
                  travelerList={booking.travelers}
                  dataSources={dataSources}
                />
              </BookingBox>

              <BookingBox title="Reserva" className="fill-available-width">
                <Infos booking={booking} />
              </BookingBox>
              {booking.payments && (
                <BookingBox title="Pagamento">
                  <BookingPayment
                    paymentForms={booking.payments
                      .filter((payment) => payment.isGuarantee)
                      .map((payment) => payment.paymentData.paymentForm)}
                  />
                </BookingBox>
              )}
            </div>

            <BookingBox
              title="Segmentos"
              subtitle={hasOriginalRequest && 'Reserva feita pela Agência'}
            >
              <Segments
                booking={booking}
                canEdit={canEdit}
                events={{
                  onEditClick: events.editItems.onEditSegmentsClicked,
                }}
              />
            </BookingBox>

            <BookingBox title="Tarifas e taxas">
              <FaresAndFees
                booking={booking}
                canEdit={canEdit}
                events={{
                  onEditClick: events.editItems.onEditFaresAndFeesClicked,
                  onDeleteClick: events.editItems.onDeleteFaresAndFeesClicked,
                }}
              />
            </BookingBox>

            {hasOriginalRequest && (
              <BookingBox
                title="Detalhes do pedido"
                subtitle={`Solicitado por ${
                  booking.audit.createdBy.name
                } em ${utils.formatters.date.dateObjToString(
                  booking.audit.createdAt,
                  enums.dateFormat.monthYearHourMinute,
                )}`}
              >
                <OriginalRequest booking={booking} />
              </BookingBox>
            )}

            {booking.allowedActions.integrations.apps.isEnabled && (
              <div className="exon-booking-box text-right">
                <BookingIntegrations
                  serviceRequestId={booking.serviceRequestId}
                  serviceRequestItemId={booking.id}
                  allowedActions={booking.allowedActions}
                  integrations={booking.integrations}
                  onDispatchClick={() =>
                    events.booking.onSendAppsClicked(booking.id)
                  }
                />
              </div>
            )}
          </div>
        )}

        {popupEditSegments.show && (
          <div>
            <Template.popup.editSegments
              events={popupEditSegments.events}
              booking={booking}
            />
          </div>
        )}

        {popupEditFaresAndFees.show && (
          <div>
            <Template.popup.editFaresAndFees
              events={popupEditFaresAndFees.events}
              booking={booking}
            />
          </div>
        )}

        {popupDeleteFaresAndFees.show && (
          <div>
            <Template.popup.deleteFaresAndFees
              events={popupDeleteFaresAndFees.events}
              booking={booking}
            />
          </div>
        )}
      </div>
    );
  },

  popup: {
    editSegments: ({ events, booking }) => (
      <RequestTravelItemOffline
        isModalOpened={true}
        onClose={events.cancel}
        onSuccess={events.confirm}
      />
    ),
    editFaresAndFees: ({ events, booking }) => {
      return (
        <Popup
          title={`Tarifas e Taxas - IRV ${booking.id}`}
          okButtonLabel={'Salvar'}
          okButtonClick={events.confirm}
          cancelButtonLabel={'Cancelar'}
          cancelButtonClick={events.cancel}
          exonStyle={true}
        >
          <div>AQUI O FORMULÁRIO DE TARIFAS E TAXAS DO VEICULO</div>
        </Popup>
      );
    },
    deleteFaresAndFees: ({ events, booking }) => {
      return (
        <Popup
          title={`Deletar Tarifas e Taxas - IRV ${booking.id}`}
          okButtonLabel={'Confirmar'}
          okButtonClick={events.confirm}
          cancelButtonLabel={'Cancelar'}
          cancelButtonClick={events.cancel}
          exonStyle={true}
        >
          <p>Deseja realmente remover as informações de tarifa do veículo?</p>
        </Popup>
      );
    },
  },
};

export default Template;
