'use strict';
import React from 'react';
import { componentUtil } from '../../../../../../utils/componentUtil.js';
import Template from './filter-rooms.template.js';

export default class FilterRoomsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    let mealsItems = this.props.mealTypes;
    let bedsItems = this.props.bedTypes;
    mealsItems.unshift({ value: 'all', label: 'Todas as refeições' });
    bedsItems.unshift({ value: 'all', label: 'Todos os tipos de cama' });

    this.state = {
      mealTypeSelected: [],
      bedTypeSelected: [],
      mealsItems,
      bedsItems,
    };
  }

  render() {
    return (
      <Template
        args={{
          bedTypeSelectData: {
            data: [...this.state.bedTypeSelected],
            externalOptions: {
              items: this.state.bedsItems,
              events: {
                onSelected: (data) => this.events.onBedTypeSelectClick(data),
              },
            },
            disabled: !this.props.bedTypes || this.props.bedTypes.length <= 1,
            placeholder: 'Tipo de cama',
            ismultiple: true,
          },
          mealTypeSelectData: {
            data: [...this.state.mealTypeSelected],
            externalOptions: {
              items: this.state.mealsItems,
              events: {
                onSelected: (data) => this.events.onMealTypeSelectClick(data),
              },
            },
            disabled: !this.props.mealTypes || this.props.mealTypes.length <= 1,
            placeholder: 'Tipo de refeição',
            ismultiple: true,
          },
        }}
        events={this.events}
      />
    );
  }

  events = {
    onMealTypeSelectClick: (mealTypeSelected) => {
      if (!mealTypeSelected.some((item) => item.value == 'all')) {
        if (this.state.mealTypeSelected.some((item) => item.value == 'all'))
          mealTypeSelected = [];

        if (mealTypeSelected.length == this.props.mealTypes.length - 1) {
          mealTypeSelected = [...this.state.mealsItems];
        }
      } else {
        if (!this.state.mealTypeSelected.some((item) => item.value == 'all')) {
          mealTypeSelected = [...this.state.mealsItems];
        } else {
          mealTypeSelected.splice(
            mealTypeSelected.findIndex((item) => item.value == 'all'),
            1,
          );
        }
      }

      this.setState({ mealTypeSelected });
    },

    onBedTypeSelectClick: (bedTypeSelected) => {
      if (!bedTypeSelected.some((item) => item.value == 'all')) {
        if (this.state.bedTypeSelected.some((item) => item.value == 'all'))
          bedTypeSelected = [];

        if (bedTypeSelected.length == this.props.bedTypes.length - 1) {
          bedTypeSelected = [...this.state.bedsItems];
        }
      } else {
        if (!this.state.bedTypeSelected.some((item) => item.value == 'all')) {
          bedTypeSelected = [...this.state.bedsItems];
        } else {
          bedTypeSelected.splice(
            bedTypeSelected.findIndex((item) => item.value == 'all'),
            1,
          );
        }
      }

      this.setState({ bedTypeSelected });
    },

    onFilterClick: () => {
      if (
        this.state.mealTypeSelected.length ||
        this.state.bedTypeSelected.length
      )
        this.props.onFilterClick &&
          this.props.onFilterClick({
            mealTypeSelected: this.state.mealTypeSelected,
            bedTypeSelected: this.state.bedTypeSelected,
          });
    },
  };
}
