'use strict';

import React from 'react';
import { componentUtil } from '../../../utils/componentUtil.js';
import AutoCompleteTemplate from './auto-complete.template.js';

export default class AutoCompleteComponent extends React.Component {
  selected = null;

  externalOptions = {
    events: {
      onSelected: null,
    },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      isInternalChange: false,
      selectedItem: null,
      inputText: '',
      waitForMinInput: true,
      items: [],

      showOverlay: false,
      showOptions: false,
      showLoader: false,
      validationResult: {
        isValid: true,
        message: props.validationMessage || '',
      },
      forceValidationPropMemory: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const selectedItem = props.selectedItem;
    let inputText = state.inputText;
    let waitForMinInput = state.waitForMinInput;

    if (!state.isInternalChange) {
      if (props.inputText != undefined && !selectedItem)
        inputText = props.inputText;
      else if (selectedItem && selectedItem.label != undefined)
        inputText = selectedItem.label;
    }

    if (props.waitForMinInput != undefined)
      waitForMinInput = props.waitForMinInput;

    return {
      selectedItem: selectedItem,
      inputText: inputText,
      isInternalChange: false,
      disabled: props.disabled,
      waitForMinInput: waitForMinInput,
    };
  }

  render() {
    let itemsElement = (
      <AutoCompleteTemplate.items
        items={this.state.items}
        onClick={this.events.optionSelected}
        showOptions={this.state.showOptions}
      />
    );

    return (
      <AutoCompleteTemplate.main
        placeholder={this.props.placeholder}
        itemsElement={itemsElement}
        showOverlay={this.state.showOverlay}
        showLoader={this.state.showLoader}
        inputText={this.state.inputText}
        disabled={this.state.disabled}
        validationResult={this.state.validationResult}
        onCancelSelectionClick={this.events.cancelSelectionClicked}
        onChange={this.events.inputChanged}
        onClick={this.events.inputClicked}
        onInputBlur={this.events.inputBlured}
      />
    );
  }

  componentDidUpdate() {
    if (
      this.state.forceValidationPropMemory != this.props.forceValidation &&
      this.props.forceValidation &&
      this.state.validationResult.isValid
    ) {
      this.setState({
        forceValidationPropMemory: this.props.forceValidation,
      });
      this.validate();
    }
  }

  timeoutId = null;
  events = {
    inputChanged: (event) => {
      let inputText = event.target.value;

      if (this.state.selectedItem && this.state.selectedItem.value) {
        this.props.onChange && this.props.onChange(null);
      }

      this.setState({
        inputText: inputText,
        selectedItem: null,
        isInternalChange: true,
      });

      if (this.timeoutId) window.clearTimeout(this.timeoutId);

      this.timeoutId = window.setTimeout(() => {
        if (!this.state.waitForMinInput || inputText.length >= 3) {
          this.dataSource.callLoad({ keywords: inputText });
        } else {
          this.showItems([]);
        }
      }, 250);

      if (this.props.onInputChange) this.props.onInputChange(inputText);
    },
    inputClicked: (event) => {
      event.target.select();
      if (this.props.options) this.showItems(this.props.options);

      this.props.onClick && this.props.onClick(this.state.selectedItem);
    },
    inputBlured: () => {
      this.validate();
    },
    optionSelected: (value) => {
      let selectedItem = this.state.items.filter((o) => o.value == value)[0];

      this.setState({
        selectedItem: selectedItem,
        inputText: (selectedItem && selectedItem.label) || '',
        showOverlay: false,
        showOptions: false,
      });

      this.validate(selectedItem);

      if (this.props.onChange) this.props.onChange(selectedItem);

      if (this.props.onInputChanged) this.props.onInputChanged('');
    },
    cancelSelectionClicked: () => {
      this.setState({
        inputText: '',
        showOverlay: false,
        showOptions: false,
      });

      this.validate();

      if (this.props.onInputChanged) this.props.onInputChanged('');
    },
  };

  setLoaderVisibility(visible) {
    this.setState({
      showLoader: visible,
    });
  }

  showItems(items) {
    let filtered = [...items];
    if (this.externalOptions.filter) {
      filtered = filtered.filter(this.externalOptions.filter);
    }

    this.setState({
      items: filtered,
      showOptions: filtered.length > 0,
      showOverlay: true,
    });
  }

  dataSource = {
    callLoad: (loadOptions) => {
      let dataSource = this.props.dataSource;

      if (dataSource.events && dataSource.events.beforeLoad)
        dataSource.events.beforeLoad(loadOptions);

      this.setState({
        showLoader: true,
        isInternalChange: true,
      });

      let _this = this;
      dataSource
        .load(loadOptions)
        .then((items) => {
          _this.showItems(items);
          this.setState({
            showLoader: false,
            isInternalChange: true,
          });

          if (dataSource.events && dataSource.events.afterLoad)
            dataSource.events.afterLoad(loadOptions);
        })
        .catch((error) => {
          _this.showItems([]);
          console.log(error);
        })
        .finally(() => {
          _this.setLoaderVisibility(false);
        });
    },
  };

  validate(selectedItem) {
    let validationResult = this.getValidationResult(selectedItem);

    this.setState({
      validationResult,
    });

    this.props.onValidate && this.props.onValidate(validationResult);
  }

  getValidationResult(selectedItem) {
    if (!selectedItem) selectedItem = this.state.selectedItem;

    let validationResult = {
      isValid: true,
      message: '',
    };

    if (this.props.required && (!selectedItem || !selectedItem.value)) {
      validationResult.isValid = false;
      validationResult.message = 'Campo obrigatório';

      return validationResult;
    }

    return validationResult;
  }
}
