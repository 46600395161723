import TemplatePreview from '../template-preview/template-preview.component.js';
import NotifierFields from './notifier-fields/notifier-fields.component.js';

let Template = {
  main: ({
    notificationType,
    documentContent,
    fieldValues,
    showAddresseeName,
    events,
    placeholder,
  }) => (
    <div className="exon-notifier">
      <div className="template-preview">
        <TemplatePreview content={documentContent} />
        <small className="exon-danger">
          * O resultado acima trata-se de um preview. O template final poder
          possuir algumas alterações de layout.
        </small>
      </div>
      <div className="notifier-fields-box">
        <NotifierFields
          notificationType={notificationType}
          events={events}
          fieldValues={fieldValues}
          showAddresseeName={showAddresseeName}
          placeholder={placeholder}
        />
      </div>
    </div>
  ),
};

export default Template;
