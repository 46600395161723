export default {
  translations: {
    pages: {
      sale: {
        title: 'Travel Quote',
      },
      list: {
        title: 'Travel Requests',
      },
      approvals: {
        title: 'Travel Approvals',
      },
      monitoring: {
        title: 'Travel Monitoring',
      },
      reports: {
        main: {
          title: 'Travel Reports',
        },
        travelRequests: {
          title: 'Travel Requests',
        },
        bookings: {
          title: 'Travel Bookings',
        },
        airTickets: {
          title: 'Air Tickets',
        },
      },
    },
  },
};
