import {
  TravelTableColumn,
  TravelTableFilter,
} from '@components/TravelDataTable/types';
import { GetPoliciesResponse } from 'bff/src/routers/policies';
import { PolicyLevel, PolicyStatus } from 'bff/src/routers/policies/enums';
import { format } from 'date-fns';
import { TogglePolicyStatus } from '../components/TogglePolicyStatus';

type Policy = GetPoliciesResponse['policies'][number];

export function usePoliciesColumns(): TravelTableColumn<Policy>[] {
  return [
    {
      accessorKey: 'name',
      header: 'Nome da política',
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
    },
    {
      accessorKey: 'updatedAt',
      header: 'Última atualização',
      cell: ({ row }) =>
        format(new Date(row.original.updatedAt), 'dd/MM/yyyy HH:mm'),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => <TogglePolicyStatus policy={row.original} />,
    },
  ];
}

export function usePoliciesFilters(): TravelTableFilter[] {
  return [
    {
      name: 'level',
      label: 'Nível',
      options: [
        { label: 'Projeto', value: PolicyLevel.PROJECT },
        { label: 'Usuário', value: PolicyLevel.USER },
        { label: 'Cargo', value: PolicyLevel.JOB_POSITION },
        { label: 'Área', value: PolicyLevel.AREA },
        { label: 'Filial', value: PolicyLevel.SUBSIDIARY },
        { label: 'Empresa', value: PolicyLevel.COMPANY },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      options: [
        { label: 'Ativo', value: PolicyStatus.ACTIVE },
        { label: 'Inativo', value: PolicyStatus.INACTIVE },
      ],
    },
  ];
}
