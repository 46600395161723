'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import { utils } from '../../utils/utils.js';
import SelectTemplate from './select.template.js';

export default class SelectComponent extends React.Component {
  selected = null;

  state = {};

  externalOptions = {
    events: {
      onSelected: null,
    },
    datasource: null,
    items: null,
  };

  isMultiple = false;
  isReadOnly = false;
  name = '';

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    this.isMultiple =
      this.props.ismultiple == 'true' || this.props.ismultiple == true;
    this.isReadOnly =
      this.props.readonly == 'true' || this.props.readonly == true;
    this.name = this.props.name;

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.dataSource.callLoad, this);
    componentUtil.react.bindMethods(this.listItems, this);
    componentUtil.react.bindMethods(this.listItems.backgroundMask, this);

    this.state = {
      dataControlledByParent: props.data != undefined,
      inputText: '',
      searchInputText: '',
      showOptions: false,
      showBackgroundMask: false,
      showLoader: true,
      numberItemsSelected: 0,
      options: [],
      selectedItems: [],
      validationResult: {
        validated: false,
        isValid: true,
        message: props.validationMessage || '',
      },
      forceValidationPropMemory: false,

      propsMemory: {
        data: null,
        items: null,
      },
    };
  }

  //Recomendado que os eventos internos que necessitam ser chamados na inicialização do componente sejam declarados dentro do componentDidMount
  componentDidMount() {
    if (
      this.props.externalOptions.items &&
      this.props.externalOptions.items.length
    )
      this.listItems.setItems(this.props.externalOptions.items);
    else if (this.externalOptions.dataSource) this.dataSource.callLoad();
  }

  componentDidUpdate() {
    if (
      this.state.forceValidationPropMemory != this.props.forceValidation &&
      this.props.forceValidation &&
      this.state.validationResult.isValid
    ) {
      this.setState({
        forceValidationPropMemory: this.props.forceValidation,
      });
      this.validate();
    }
  }

  static getDerivedStateFromProps(props, state) {
    let selectedItems =
      state.propsMemory.data != props.data ? props.data : state.selectedItems;
    let options =
      props.externalOptions.items &&
      !utils.array.equals(state.propsMemory.items, props.externalOptions.items)
        ? props.externalOptions.items
        : state.options;

    state.propsMemory.data = props.data;
    state.propsMemory.items = props.externalOptions.items;

    let stateObject = SelectComponent.getStateObject(
      options,
      selectedItems,
      props.isMultiple || props.ismultiple,
    );
    return stateObject;
  }

  render() {
    let selectComponent = (
      <SelectTemplate.options
        options={this.state.options}
        isMultiple={this.isMultiple}
        showOptions={this.state.showOptions}
        onChange={this.events.optionChanged}
        isZeroValid={this.props.isZeroValid}
        onInputChange={this.events.onInputChanged}
        searchInputText={this.state.searchInputText}
        showSearch={this.props.showSearch}
      />
    );

    return (
      <SelectTemplate.main
        placeholder={this.props.placeholder}
        isReadOnly={this.isReadOnly}
        selectOptions={selectComponent}
        inputText={this.state.inputText}
        showBackgroundMask={this.state.showBackgroundMask}
        numberItemsSelected={this.state.numberItemsSelected}
        validationResult={this.state.validationResult}
        disabled={this.props.disabled ? this.props.disabled : false}
        onClick={this.events.inputClicked}
        onClickOutsideOptions={this.events.outsideOptionsClicked}
      />
    );
  }

  static fillOptionsCheckState = function (options, selectedItems, isMultiple) {
    for (let i = 0; i < options.length; i++) {
      let option = options[i];

      if (isMultiple)
        option.checked =
          (selectedItems &&
            selectedItems.length &&
            selectedItems.map((item) => item.value).indexOf(option.value) !=
              -1) ||
          false;
      else
        option.checked =
          (selectedItems && selectedItems.value == option.value) || false;
    }
  };

  validate(selectedItems) {
    let validationResult = this.getValidationResult(selectedItems);

    this.setState({
      validationResult,
    });

    this.props.onValidate && this.props.onValidate(validationResult);
  }

  getValidationResult(selectedItems) {
    if (!selectedItems) selectedItems = this.state.selectedItems;

    let validationResult = {
      isValid: true,
      message: '',
      validated: true,
    };

    let selectedValue = false;
    if (Array.isArray(selectedItems)) {
      if (selectedItems.length > 0) selectedValue = selectedItems[0].value;
    } else if (
      typeof selectedItems == 'number' ||
      typeof selectedItems == 'string'
    )
      selectedValue = selectedItems;
    else selectedValue = selectedItems && selectedItems.value;

    if (this.props.required && !selectedValue) {
      if (
        (!this.props.isZeroValid && selectedValue === 0) ||
        selectedValue === null ||
        selectedValue === undefined ||
        selectedValue === ''
      ) {
        validationResult.isValid = false;
        validationResult.message = 'Campo obrigatório';
      }

      return validationResult;
    }

    return validationResult;
  }

  getData = function () {
    return this.state.selectedItems;
  };

  setData = function (selectedItems) {
    let stateObject = SelectComponent.getStateObject(
      this.state.options,
      selectedItems,
      this.isMultiple,
    );
    this.setState(stateObject);
  };

  static getStateObject = function (options, selectedItems, isMultiple) {
    SelectComponent.fillOptionsCheckState(options, selectedItems, isMultiple);

    let numOfSelItems = 0;
    let inputText = '';

    if (isMultiple) {
      numOfSelItems = selectedItems ? selectedItems.length - 1 : null;
      inputText =
        selectedItems && selectedItems.length > 0 ? selectedItems[0].label : '';
    } else {
      numOfSelItems = null;

      const isArray = Array.isArray(selectedItems);
      const isObj = typeof selectedItems === 'object';

      if (isArray) selectedItems = selectedItems[0];

      let selectedItem =
        selectedItems && isObj
          ? selectedItems
          : options.find((o) => o.value == selectedItems);
      inputText = selectedItem && selectedItem.label ? selectedItem.label : '';
    }

    return {
      options: options,
      selectedItems: selectedItems,
      numberItemsSelected: numOfSelItems,
      inputText: inputText,
    };
  };

  clear = function () {
    this.setData(null);

    let options = this.state.options;
    options.forEach((o) => (o.checked = false));
    this.setState({ options: options });
  };

  events = {
    _timeControl: null,
    inputClicked: function (event) {
      this.listItems.show();
    },
    optionChanged: function (event, option) {
      if (!option.value && isNaN(option.value)) return;
      let optionsSelected = this.state.selectedItems || [];

      if (this.isMultiple) {
        if (event.target.checked) {
          optionsSelected.push(option);
        } else {
          const index = optionsSelected.findIndex(
            (ch) => ch.value === option.value,
          );
          if (index != -1) optionsSelected.splice(index, 1);
        }
      } else {
        optionsSelected = [];
        optionsSelected.push(option);
        this.listItems.hide();
      }

      this.setData(optionsSelected);

      this.validate(optionsSelected);

      if (this.externalOptions.events.onSelected)
        this.externalOptions.events.onSelected(
          this.isMultiple
            ? optionsSelected
            : (optionsSelected && optionsSelected[0]) || null,
          this.name,
        );
    },

    outsideOptionsClicked: function (event) {
      this.listItems.hide();
      this.validate();
    },

    onInputChanged: (e) => {
      let options = null;
      if (e.target.value) {
        options = this.props.externalOptions.items.filter(
          (option) =>
            option.label.toLowerCase().includes(e.target.value) ||
            option.value.toString().toLowerCase().includes(e.target.value),
        );
        if (options.length == 0) {
          options.push({ label: 'sem resultado' });
        }
      } else options = this.props.externalOptions.items;

      this.setState({ searchInputText: e.target.value, options });
    },
  };

  listItems = {
    setItems: function (items) {
      this.setState({
        options: items,
      });
    },
    show: function () {
      this.listItems._setVisibility(true);
      this.listItems.backgroundMask.show();
    },
    hide: function () {
      this.listItems._setVisibility(false);
      this.listItems.backgroundMask.hide();
    },
    backgroundMask: {
      show: function () {
        this.listItems.backgroundMask._setVisibility(true);
      },
      hide: function () {
        this.listItems.backgroundMask._setVisibility(false);
      },
      _setVisibility: function (visible) {
        this.setState({
          showBackgroundMask: visible,
        });
      },
    },

    _setVisibility: function (visible) {
      this.setState({
        showOptions: visible,
      });
    },
  };

  dataSource = {
    callLoad: (params) => {
      let _this = this;

      let dataSource = this.props.externalOptions.dataSource;

      if (dataSource && dataSource.events && dataSource.events.beforeLoad)
        dataSource.events.beforeLoad(params);

      let promise = dataSource.load(params);

      let resolveFunc = (items) => {
        items.forEach((o) => (o.checked = false));
        _this.listItems.setItems(items);

        if (dataSource.events && dataSource.events.afterLoad)
          dataSource.events.afterLoad(items);
      };

      if (promise.done) promise.done(resolveFunc); //$.Deferred
      else if (promise.then) promise.then(resolveFunc); //new Promisse
    },
  };
}
