import {
  LinkButton,
  PillButton,
  SelectField,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import IMask from 'imask';
import {
  ArrayPath,
  Controller,
  ControllerFieldState,
  Path,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { useConditionFields } from './hooks/useConditionFields';
import { useConditionValues } from './hooks/useConditionValues';
import { CriteriaFieldsProps } from './types';

const extendedOperatorOptions = [
  { label: 'igual a', value: 'equal' },
  { label: 'maior que', value: 'greater' },
  { label: 'maior ou igual a', value: 'greater_equal' },
  { label: 'diferente de', value: 'not_equal' },
  { label: 'menor que', value: 'less' },
  { label: 'menor ou igual a', value: 'less_equal' },
];

const standardOperatorOptions = [
  { label: 'igual a', value: 'equal' },
  { label: 'diferente de', value: 'not_equal' },
];

const conditionOptions = [
  { label: 'E', value: 'and' },
  { label: 'OU', value: 'or' },
];

const getOption = (options: typeof conditionOptions, value: string) => {
  return options.find((o) => o.value === value)?.label || '-';
};

const CriteriaFields = <T,>({ control, ...props }: CriteriaFieldsProps<T>) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: props.name as ArrayPath<T>,
  });

  const { customFields, fixedOptions } = useConditionFields(props);

  const { conditionValues } = useConditionValues(props);

  const watchCriteria = useWatch({ name: props.name, control });

  const currencyMask = IMask.createMask({
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ',',
    mapToRadix: ['.'],
    prepare: (str) => str.replace(/[^\d,]/g, ''),
    overwrite: true,
  });

  const renderValueField = (
    index: number,
    field: any,
    state: ControllerFieldState,
  ) => {
    const selectedField = watchCriteria[index]?.field;
    const customField = customFields.find(
      (cf) => cf.customFieldId === selectedField,
    );

    if (customField) {
      switch (customField.controlType) {
        case 1: // Campo Data
          return (
            <TextField
              {...field}
              type="date"
              fullWidth
              sx={{ width: 200, mr: 2 }}
              label={customField.label}
              error={state.invalid}
              helperText={state.error?.message}
            />
          );
        case 2: // Campo Texto
          return (
            <TextField
              {...field}
              fullWidth
              sx={{ width: 200, mr: 2 }}
              label={customField.label}
              error={state.invalid}
              helperText={state.error?.message}
            />
          );
        case 3: // Campo Numérico
          return (
            <TextField
              {...field}
              type="number"
              fullWidth
              sx={{ width: 200, mr: 2 }}
              label={customField.label}
              error={state.invalid}
              helperText={state.error?.message}
            />
          );
        case 4: // Campo Opções
          const options = JSON.parse(customField.options || '[]');
          return (
            <SelectField
              {...field}
              fullWidth
              options={options.map((opt: string) => ({
                label: opt,
                value: opt,
              }))}
              label={customField.label}
              sx={{ width: 200, mr: 2 }}
              error={state.invalid}
              helperText={state.error?.message}
            />
          );
        default:
          return null;
      }
    }

    switch (selectedField) {
      case 'cost_center_responsible':
        return (
          <TextField
            {...field}
            value="Solicitante"
            label={'Centro de Custo'}
            disabled
            fullWidth
            sx={{ width: 200, mr: 2 }}
            error={state.invalid}
            helperText={state.error?.message}
          />
        );
      case 'total_rv_value':
        return (
          <TextField
            {...field}
            type="number"
            label={'Valor Total da RV'}
            fullWidth
            imaskProps={currencyMask}
            sx={{ width: 200, mr: 2 }}
            startAdornment="R$"
            error={state.invalid}
            helperText={state.error?.message}
          />
        );
      default:
        return (
          <SelectField
            value={field.value}
            onSelectChange={(_, selectedItem) => {
              field.onChange(selectedItem?.value);
            }}
            fullWidth
            options={conditionValues[selectedField] || []}
            label="Selecione um valor"
            sx={{ width: 200, mr: 2 }}
            error={state.invalid}
            helperText={state.error?.message}
          />
        );
    }
  };

  return (
    <Box>
      {fields.map((field, index) => (
        <Box key={field.id} display="flex" alignItems="center" my={2} gap={2}>
          <Typography variant="body4">{index + 1}</Typography>

          {index === 0 ? (
            <Typography variant="body4">Quando</Typography>
          ) : props.static?.condition ? (
            <Typography variant="body4">
              {getOption(conditionOptions, props.static?.condition)}
            </Typography>
          ) : (
            <Controller
              name={`${props.name}.${index}.condition` as Path<T>}
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  value={field.value || props.static.condition}
                  fullWidth
                  onSelectChange={(_, selectedItem) => {
                    field.onChange(selectedItem?.value);
                  }}
                  options={conditionOptions}
                  label="Selecione"
                  sx={{ width: 100, mr: 2 }}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}

          <Controller
            name={`${props.name}.${index}.field` as Path<T>}
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                value={field.value}
                onSelectChange={(_, selectedItem) => {
                  field.onChange(selectedItem?.value);
                }}
                fullWidth
                options={fixedOptions}
                label="Selecione um campo"
                sx={{ width: 200, mr: 2 }}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />

          {props.static.operator ? (
            <Typography
              variant="body4"
              style={{ width: 100, textAlign: 'center' }}
            >
              {getOption(standardOperatorOptions, props.static?.operator)}
            </Typography>
          ) : (
            <Controller
              name={`${props.name}.${index}.operator` as Path<T>}
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  value={field.value || props.static.operator}
                  onSelectChange={(_, selectedItem) => {
                    field.onChange(selectedItem?.value);
                  }}
                  fullWidth
                  options={
                    watchCriteria[index]?.field === 'total_rv_value' ||
                    customFields.some(
                      (cf) =>
                        cf.customFieldId === watchCriteria[index]?.field &&
                        cf.controlType === 3,
                    )
                      ? extendedOperatorOptions
                      : standardOperatorOptions
                  }
                  label="Selecione um operador"
                  sx={{ width: 100, mr: 2 }}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  disabled={!!props.static?.operator}
                />
              )}
            />
          )}

          <Controller
            name={`${props.name}.${index}.value` as Path<T>}
            control={control}
            render={({ field, fieldState }) =>
              renderValueField(index, field, fieldState)
            }
          />

          {index > 0 && (
            <Box display="flex" gap={1}>
              <PillButton
                onClick={() => remove(index)}
                variant={'pink'}
                size="small"
                type="secondary"
                icon="IconTrash"
              />
            </Box>
          )}
        </Box>
      ))}

      <LinkButton
        variant={'default'}
        onClick={() =>
          append({
            condition: props.static?.condition || undefined,
            operator: props.static?.operator || undefined,
          } as any)
        }
      >
        Adicionar novo critério
      </LinkButton>

      {props.debug && (
        <Box mt={4}>
          <Typography variant="headline10">Debug: Form State</Typography>
          <pre>{JSON.stringify(watchCriteria, null, 2)}</pre>
        </Box>
      )}
    </Box>
  );
};

export default CriteriaFields;
