import Date from '../../../date/date.component.js';
import GuestsComponent from '../../../guests/guests.component.js';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';

let Template = ({ props, guests, checkinDate, checkoutDate, events }) => {
  const { enableTravelersLimit } = useTravelPermissions();
  props.searchParameters;
  return (
    <div className="exon-hotel-search-card-engine">
      <div className="exon-hotel-search-card-engine-fields">
        <div className="exon-field">
          <label className="exon-label-field">Check-in</label>
          <Date {...checkinDate} />
        </div>
        <div className="exon-field">
          <label className="exon-label-field">Check-out</label>
          <Date {...checkoutDate} />
        </div>
        <div className="exon-field exon-box-guests">
          <GuestsComponent
            externalOptions={guests.options}
            initialData={guests.roomsStart}
            onGuestsClearFieldsClick={() => events.onClick()}
          />
        </div>
      </div>
      <button
        onClick={events.onClick}
        className="exon-button exon-button-primary"
      >
        Pesquisar
      </button>
    </div>
  );
};

export default Template;
