import Popup from '../../popup/popup.component.js';

var TravelRequestsNotificationsTemplate = {
  main: ({
    requestIdentifier,
    notifications,
    displayNotificationsList,
    events,
  }) => {
    let violations = 0;
    if (notifications && notifications.length)
      notifications.map(
        (notification) => (violations += notification.violatedRules.length),
      );
    return (
      <div className="exon-travel-requests-notifications">
        <span className="exon-icon exon-icon-warning-unfilled_enabled-yellow"></span>
        {notifications && notifications.length && (
          <div className="exon-item-notifications">
            <p>
              {'Essa requisição de viagens possui ' +
                violations +
                (violations > 1 ? ' violações' : ' violação')}
            </p>
            <p>
              Política:
              <span>
                {`${
                  notifications[0].policyName
                } ${notifications[0].violatedRules.map(
                  (rule) => rule.ruleCode,
                )}`}
              </span>
            </p>
            {notifications[0].justification && (
              <p>
                Justificativa:
                <span>
                  {notifications[0].justification.code} -{' '}
                  {notifications[0].justification.name}
                </span>
              </p>
            )}
          </div>
        )}
        <p className="exon-button-text" onClick={events.onOpenPopupClicked}>
          ver mais
        </p>

        {displayNotificationsList && (
          <TravelRequestsNotificationsTemplate.notificationList
            requestIdentifier={requestIdentifier}
            notifications={notifications}
            events={events}
          />
        )}
      </div>
    );
  },

  notificationList: ({ requestIdentifier, notifications, events }) => {
    return (
      <div className="exon-box-popup-travel-requests-notifications">
        <Popup
          okButtonLabel={'ok'}
          cancelButtonLabel={null}
          title={
            <span>
              <span className="exon-icon exon-icon-warning-filled_enabled-yellow"></span>
              Notificações da
              <span>{requestIdentifier}</span>
            </span>
          }
          cancelButtonClick={events.onCloseClicked}
          exonStyle={true}
          okButtonClick={events.onCloseClicked}
        >
          <div className="exon-box-travel-requests-notifications-list">
            {notifications.map((item, index) => {
              return (
                <div
                  key={'policy-' + index}
                  className="exon-item-notifications"
                >
                  <p>
                    <span className="exon-icon exon-icon-hammer-filled_disabled"></span>
                    Política:
                    <span>{item.policyName}</span>
                  </p>
                  {item.violatedRules.map((rule, index) => {
                    return (
                      <p key={index}>
                        Regra:
                        <span>
                          {rule.message[0] ? rule.message[0] : rule.ruleCode}
                        </span>
                      </p>
                    );
                  })}
                  {item.justification && (
                    <div>
                      <p>
                        Justificativa:
                        <span>
                          {item.justification.code} - {item.justification.name}
                        </span>
                      </p>
                      <p>
                        Descrição:
                        <span>{item.justification.description}</span>
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Popup>
      </div>
    );
  },
};

export default TravelRequestsNotificationsTemplate;
