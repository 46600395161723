'use strict';

import React from 'react';
import BookingTicketDetailsTemplate from './booking-ticket-details.template.js';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component.js';
import { useTravelItemStore } from '../../../../../../store/RequestTravelItem.store';

export default class BookingTicketDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmation: {
        showCancelConfirmationPopup: false,
      },
    };
  }

  componentDidMount() {
    const { airTicket } = this.props;
    const setAirTicket = useTravelItemStore.getState().setAirTicket;
    if (airTicket) {
      setAirTicket(airTicket);
    }
  }

  componentDidUpdate(prevProps) {
    const { airTicket } = this.props;
    const setAirTicket = useTravelItemStore.getState().setAirTicket;
    if (airTicket !== prevProps.airTicket) {
      setAirTicket(airTicket);
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <BookingTicketDetailsTemplate.main
          airTicket={this.props.airTicket}
          allowedActions={this.props.allowedActions}
          confirmationProps={this.state.confirmation}
          showActions={
            this.props.showActions == undefined ? true : this.props.showActions
          }
          onShowEmailPopupClick={this.props.onShowEmailPopupClick}
          events={{
            onReplaceButtonClicked: this.props.onReplaceButtonClick,
            onCancelButtonClick: this.events.cancelAirTicketClicked,
            onOpenFormPopupClicked: this.events.onOpenFormPopupClicked,
            onSendAppsClicked: this.props.onSendAppsClicked,

            confirmation: { ...this.events.confirmation },
          }}
          canEdit={this.props.canEdit}
        />
      </ErrorBoundary>
    );
  }

  events = {
    cancelAirTicketClicked: (airTicketId) => {
      this.setState({
        confirmation: {
          ...this.state.confirmation,

          showCancelConfirmationPopup: true,
        },
      });
    },
    confirmation: {
      cancelAirTicketCancellation: () => {
        this.setState({
          confirmation: {
            ...this.state.confirmation,
            showCancelConfirmationPopup: false,
          },
        });
      },
      confirmAirTicketCancellation: () => {
        this.setState({
          confirmation: {
            ...this.state.confirmation,
            showCancelConfirmationPopup: false,
          },
        });

        this.props.onCancelButtonClick &&
          this.props.onCancelButtonClick(this.props.airTicket);
      },
    },
    onOpenFormPopupClicked: (action) => {
      this.props.onOpenFormPopupClick &&
        this.props.onOpenFormPopupClick(action);
    },
  };
}
