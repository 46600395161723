import React from 'react';
import * as SC from './styled';
import { RoundedIcon } from '@components/RoundedIcon';
import { enums } from '../../utils/enums';
type LegacyProductPaymentProps = {
  productType: number;
  children: React.ReactNode;
  showBadges?: boolean;
};
const Badge = (product) => {
  switch (product) {
    case enums.purchaseConfiguration.product.air:
      return (
        <RoundedIcon icon="IconPlaneTilt" variant="primary" size="md">
          Aéreo
        </RoundedIcon>
      );
    case enums.purchaseConfiguration.product.hotel:
      return (
        <RoundedIcon icon="IconBed" variant="primary" size="md">
          Hotel
        </RoundedIcon>
      );
    default:
      return (
        <RoundedIcon icon="IconShoppingBag" variant="primary" size="md">
          Outro
        </RoundedIcon>
      );
  }
};
export const LegacyProductPayment = ({
  children,
  productType,
  showBadges = false,
}: LegacyProductPaymentProps) => {
  return (
    <SC.Container showBadge={showBadges}>
      <SC.StyledLegacyProductPayment className="divider" showBadge={showBadges}>
        {showBadges && <div>{Badge(productType)}</div>}
        <div>{children}</div>
      </SC.StyledLegacyProductPayment>
    </SC.Container>
  );
};
