let ButtonCollectionTemplate = {
  main: ({
    items,
    textPrefix,
    labels,
    indexSelected,
    onAddClicked,
    onDeleteClicked,
    onButtonClicked,
  }) => {
    let item = 0;
    return (
      <div className="exon-button-collection">
        {items.map((item, index) => {
          let itemNumber = index + 1;
          return (
            <div className="exon-button-collection__box-button" key={index}>
              <button
                className={
                  'exon-button-collection__button-item' +
                  (indexSelected === index ? '-active' : '')
                }
                key={index}
                onClick={() => onButtonClicked(index)}
              >
                {labels[index]
                  ? labels[index]
                  : textPrefix
                  ? textPrefix + ' ' + itemNumber
                  : itemNumber}
              </button>
              <span
                className={
                  'exon-button-collection__button-close ' +
                  (index == 0 || index == 1 ? 'hide' : '')
                }
                onClick={() => onDeleteClicked(index)}
              >
                <span></span>
              </span>
            </div>
          );
        })}
        <button
          className={
            'exon-button-collection__button-add ' +
            (items.length >= 7 ? 'hide' : '')
          }
          onClick={onAddClicked}
        >
          +
        </button>
      </div>
    );
  },
};

export default ButtonCollectionTemplate;
