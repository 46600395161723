'use strict';

import React from 'react';
import Template from './hotel-search-results-options.template.js';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class HotelSearchResultsOptionsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      currentPage: 1,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          hotelOptions={this.props.items}
          dataPagination={{
            itemsPerPage: 5,
            currentPage: this.state.currentPage,
            onPageClick: this.events.pagination.onPageClicked,
          }}
        />
      </ErrorBoundary>
    );
  }

  events = {
    pagination: {
      onPageClicked: (currentPage) => {
        this.setState({ currentPage });
      },
    },
  };
}
