import {
  IconButton,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Dialog } from '@mui/material';
import styled from 'styled-components';

import { TextAlign } from '../../themes/types/Variants';

interface HeaderTextAlign {
  align: TextAlign;
}

export const ModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.neutral[100]};
  max-width: 100%;
`;

export const ModalDefault = styled(Dialog)`
  &.MuiDialog-root {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    outline: none;

    .MuiDialog-container {
      width: 100%;
      padding: ${(props) => props.theme.spacings.m};
    }

    .MuiDialog-paper {
      width: auto;
      margin: 0;
      border-radius: ${(props) => props.theme.borders.radius.l} !important;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
      width: 100%;
    }
  }
`;

export const ModalHeaderContainer = styled.div<{
  showDivider?: boolean;
  collapse?: boolean;
}>`
  position: relative;
  padding: ${(props) => props.theme.spacings.m}
    ${(props) => props.theme.spacings.xl4}
    ${(props) =>
      props.collapse ? props.theme.spacings.xs3 : props.theme.spacings.m}
    ${(props) => props.theme.spacings.xl4};
  width: 100%;
  ${(props) =>
    props.showDivider
      ? `border-bottom: ${props.theme.borders.width.xs2} solid ${props.theme.colors.neutral[90]};`
      : ''}
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    padding: ${(props) => props.theme.spacings.m};
  }
`;

export const IconClose = styled(IconButton).attrs({
  icon: 'IconX',
  variant: 'line',
  size: 'small',
})`
  position: absolute;
  top: ${(props) => props.theme.spacings.xs3};
  right: ${(props) => props.theme.spacings.xs};
`;

export const Title = styled(Typography).attrs({
  variant: 'headline6',
})<HeaderTextAlign>`
  font-weight: 700;
  text-align: ${(props) => props.align || 'left'};
  color: ${(props) => props.theme.colors.neutral[10]};

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    font-size: 22px !important;
    line-height: 28px !important;
  }
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'body3',
})<HeaderTextAlign>`
  font-weight: 400;
  text-align: ${(props) => props.align || 'left'};
  color: ${(props) => props.theme.colors.neutral[50]};
  max-width: 535px;
  margin-top: ${(props) => props.theme.spacings.xs4};
  align-self: ${(props) => (props.align ? 'center' : 'unset')};
`;

export const ModalBodyContainer = styled.div<{ collapse?: boolean }>`
  padding: ${(props) =>
      props.collapse ? props.theme.spacings.xs3 : props.theme.spacings.m}
    ${(props) => props.theme.spacings.xl4};
  max-height: calc(100vh - 385px);
  height: 100%;
  overflow: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    padding: ${(props) => props.theme.spacings.m};
  }
`;

export const ModalFooterContainer = styled.div<{
  center: boolean;
}>`
  padding: ${(props) => props.theme.spacings.xs}
    ${(props) => props.theme.spacings.xl4};
  border-top: 1px solid ${(props) => props.theme.colors.neutral[90]};
  display: flex;
  justify-content: ${(props) => (props?.center ? 'center' : 'space-between')};
  align-items: center;
  background-color: ${(props) => props.theme.colors.neutral[100]};
  border-bottom-left-radius: ${(props) => props.theme.borders.radius.l};
  border-bottom-right-radius: ${(props) => props.theme.borders.radius.l};
  justify-content: space-evenly;
  gap: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    padding: ${(props) => props.theme.spacings.m}
      ${(props) => props.theme.spacings.m};
  }
`;

export const HeaderIcon = styled(Icons)`
  border-radius: ${(props) => props.theme.borders.radius.circular};
  background-color: ${(props) =>
    `rgba(${hexToRgb(props.color || props.theme.colors.secondary[99])}, 0.1)`};
  color: ${(props) => props.color || props.theme.colors.secondary[50]};
`;

export const HeaderUpperItem = styled.div``;

export const IconHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    padding: 20px;
  }
`;

export const IconLabel = styled(Typography).attrs({
  variant: 'body3',
})`
  text-align: center;
  font-weight: 700 !important;
  color: ${(props) => props.theme.colors.secondary[50]};
  margin-top: ${(props) => props.theme.spacings.xs3};
  margin-bottom: ${(props) => props.theme.spacings.xs2};
`;

function hexToRgb(hex) {
  let r = 0,
    g = 0,
    b = 0;
  // 3 dígitos
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 dígitos
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `${r}, ${g}, ${b}`;
}
