let PaginationTemplate = ({
  numberPages,
  currentPage,
  visibleOptionsPages,

  onPageClick,
  onNextPagesClick,
  onPrevPagesClick,
}) => {
  return (
    <div className={'exon-pagination' + (numberPages <= 1 ? ' hide' : '')}>
      <button
        className="exon-button exon-button-secondary"
        onClick={() => onPrevPagesClick()}
      >
        <span className="exon-icon exon-icon-left-unfilled_disabled"></span>
      </button>
      <button
        className={
          'exon-button exon-button-secondary' +
          (currentPage == 1 ? ' exon-button-secondary_enabled' : '')
        }
        onClick={() => onPageClick(1)}
      >
        1
      </button>
      <span
        className={
          'exon-separator' +
          (currentPage < 5 || numberPages <= 6 ? ' hide' : '')
        }
      >
        ...
      </span>

      {visibleOptionsPages.map((page, index) => {
        return (
          <button
            className={
              'exon-button exon-button-secondary' +
              (currentPage == page ? ' exon-button-secondary_enabled' : '')
            }
            onClick={() => onPageClick(page)}
            key={index}
          >
            {page}
          </button>
        );
      })}

      <span
        className={
          'exon-separator' +
          (currentPage <= numberPages - 3 ? '' : ' hide') +
          (numberPages <= 6 ? ' hide' : '')
        }
      >
        ...
      </span>
      <button
        className={
          'exon-button exon-button-secondary' +
          (currentPage == numberPages ? ' exon-button-secondary_enabled' : '')
        }
        onClick={() => onPageClick(numberPages)}
      >
        {numberPages}
      </button>
      <button
        className="exon-button exon-button-secondary"
        onClick={() => onNextPagesClick()}
      >
        <span className="exon-icon exon-icon-right-unfilled_disabled"></span>
      </button>
    </div>
  );
};

export default PaginationTemplate;
