import { MonitoringErrorBoundary } from '@flash-tecnologia/hros-web-utility';
import { AppProps } from 'single-spa';

export class ErrorBoundary extends MonitoringErrorBoundary {
  project =
    'https://589b6f72ecc2d95dad7de593edfb8405@o266934.ingest.us.sentry.io/4507102153998336';

  constructor(props: React.PropsWithChildren<AppProps>) {
    super(props);
  }
}
