import React from 'react';

import * as SC from './styled';

export interface DefaultModalProps {
  /**
   * if 'true' open the modal
   */
  open: boolean;

  /**
   * Modal content
   */
  children?: React.ReactNode;

  /**
   * Close event
   *
   */
  onCloseClick?(): void;

  /**
   * class name
   *
   */
  className?: string;

  /**
   * container class name
   *
   */
  containerClassName?: string;
  size?: 'sm' | 'md';
}

export const ModalRoot = ({
  open,
  onCloseClick,
  className,
  containerClassName,
  children,
  size = 'md',
}: DefaultModalProps) => {
  return (
    <SC.ModalDefault
      open={open}
      onClose={onCloseClick}
      className={className}
      scroll="body"
      maxWidth={size}
      fullWidth={true}
    >
      <SC.ModalContainer className={containerClassName}>
        {children}
      </SC.ModalContainer>
    </SC.ModalDefault>
  );
};
