import styled, { css, DefaultTheme } from 'styled-components';
export type Size = 'lg' | 'md' | 'sm';
export type Variant = 'primary' | 'success' | 'error';
export type RoundedIconContainerProps = {
  variant: Variant;
  size: Size;
};
const variantStyles = (theme: DefaultTheme, variant = 'primary') => {
  return {
    primary: css`
      background-color: ${theme.colors.secondary[90]};
      color: ${theme.colors.secondary[50]};
    `,
    success: css`
      background-color: ${theme.colors.success90};
      color: ${theme.colors.success10};
    `,
    error: css`
      background-color: ${theme.colors.error90};
      color: ${theme.colors.error10};
    `,
  }[variant];
};

const sizeStyles = (size = 'lg') => {
  return {
    lg: css`
      padding: 2rem;
    `,
    md: css`
      padding: 1.5rem;
    `,
    sm: css`
      padding: 1rem;
    `,
  }[size];
};

export const RoundedIconContainer = styled.div<RoundedIconContainerProps>`
  padding: 2rem;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;

  ${({ theme, variant }) => {
    return variantStyles(theme, variant);
  }};
  ${({ size }) => {
    return sizeStyles(size);
  }};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
