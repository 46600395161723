import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useCallback } from 'react';
import useModalStore from '../../../../store/Modal.store';

type Actions = 'delete' | 'edit' | 'duplicate';

type ButtonOptions = {
  title?: string;
  message?: string;
  primaryButtonLabel?: string;
  status?: 'error';
  loading?: boolean;
};

interface Props {
  delete?: ButtonOptions;
  edit?: Pick<ButtonOptions, 'loading'>;
  duplicate?: Pick<ButtonOptions, 'loading'>;
  onDelete?: () => Promise<void> | void;
  onEdit?: () => Promise<void> | void;
  onDuplicate?: () => Promise<void> | void;
  actions?: Actions[];
}

export const TableBaseActions = (props: Props) => {
  const actions = props.actions || ['delete', 'edit'];

  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);

  const handleDeleteFlow = useCallback(() => {
    openModal({
      title: props.delete?.title || 'Deseja realmente excluir?',
      subTitle:
        props.delete?.message ||
        'Essa ação não poderá ser desfeita, deseja realmente excluir?',
      status: props.delete?.status || 'error',
      primaryButtonLabel: props.delete?.primaryButtonLabel || 'Excluir',
      primaryButtonAction: async () => {
        try {
          await props.onDelete?.();

          closeModal();
        } catch (error) {
          throw error;
        }
      },
    });
  }, [props.onDelete, props.delete]);

  return (
    <>
      {actions.includes('edit') && (
        <PillButton
          icon="IconEdit"
          size="small"
          type="secondary"
          variant="default"
          onClick={() => props.onEdit?.()}
          loading={props.edit?.loading}
        />
      )}

      {actions.includes('delete') && (
        <PillButton
          icon="IconTrash"
          size="small"
          type="secondary"
          variant="default"
          onClick={() => handleDeleteFlow()}
          loading={props.delete?.loading}
        />
      )}

      {actions.includes('duplicate') && (
        <PillButton
          icon="IconCopy"
          size="small"
          type="secondary"
          variant="default"
          onClick={() => props.onDuplicate?.()}
          loading={props.duplicate?.loading}
        />
      )}
    </>
  );
};
