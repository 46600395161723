import React from 'react';
import Template from './error-boundary.template.js';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);

    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError && this.props.fallBack) {
      return this.props.fallBack;
    }
    if (this.state.hasError) return <Template />;

    return this.props.children;
  }
}
