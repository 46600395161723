'use strict';

import React from 'react';
import BookingProductSummaryListTemplate from './booking-product-summary-list.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class BookingProductSummaryListComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <BookingProductSummaryListTemplate
          requestId={this.props.requestId}
          bookings={this.props.bookings}
          userTravelProfile={this.props.userTravelProfile}
          events={this.props.events}
          justificationEvents={this.props.justificationEvents}
        />
      </ErrorBoundary>
    );
  }
}
