'use strict';

import React from 'react';
import { componentUtil } from '../../../../../utils/componentUtil.js';
import Template from './hotel-rooms-group.template.js';
import { listItemsResolver } from '../../../../../utils/resolvers/listItemresolver.js';

export default class HotelRoomsGroupComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      roomsDisplayed: 3,
      otherRooms: false,
      rooms: props.rooms,
      valuesFilters: {
        bedTypes: [],
        mealTypes: [],
      },
    };
  }

  render() {
    return (
      <Template
        props={this.props}
        args={{ ...this.state }}
        events={this.events}
      />
    );
  }

  componentDidMount() {
    this.getValuesFilter(this.props.rooms);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      roomsDisplayed: props.roomsDisplayed
        ? props.roomsDisplayed
        : state.roomsDisplayed,
    };
  }

  events = {
    onMoreRoomsClick: () => {
      this.props.onMoreRoomsClick && this.props.onMoreRoomsClick();

      this.setState({
        roomsDisplayed: this.state.roomsDisplayed + 3,
      });
    },

    onSelectRoomClick: (index) => {
      this.setState({
        show: false,
        otherRooms: index > 2,
      });

      this.props.onSelectRoomClick &&
        this.props.onSelectRoomClick(index, this.props.indexRoomGroup || 0);
    },

    onApplyFilterRoomClick: (filters) => {
      let rooms = this.props.rooms;

      if (
        filters.mealTypeSelected.length &&
        !filters.mealTypeSelected.some((item) => item.value == 'all')
      )
        rooms = rooms.filter((room) =>
          filters.mealTypeSelected.some((item) =>
            room.filterItens.mealTypes.some(item.label),
          ),
        );

      if (
        filters.bedTypeSelected.length &&
        !filters.bedTypeSelected.some((item) => item.value == 'all')
      )
        rooms = rooms.filter((room) =>
          filters.bedTypeSelected.some((item) =>
            room.filterItens.bedTypes.some(item.label),
          ),
        );

      this.setState({ rooms });
    },
  };

  getValuesFilter = (rooms) => {
    let valuesFilters = this.state.valuesFilters;
    let singleBed = 'Cama de Solteiro';
    let doubleBed = 'Cama de Casal';
    let noInformation = 'Não informado';
    let noMeal = 'Sem Refeição';

    listItemsResolver.resolveItem(this.props.mealTypes);

    rooms.forEach((room) => {
      let roomName = room.name ? room.name.toLowerCase() : null;
      let roomDescription = room.description
        ? room.description.toLowerCase()
        : null;
      let existSingleBed =
        (roomName &&
          (roomName.includes((_) => _ == 'solteiro') ||
            roomName.includes((_) => _ == 'single bed'))) ||
        (roomDescription &&
          (roomDescription.includes((_) => _ == 'solteiro') ||
            roomDescription.includes((_) => _ == 'single bed')));
      let existDoubleBed =
        (roomName &&
          (roomName.includes((_) => _ == 'casal') ||
            roomName.includes((_) => _ == 'double bed'))) ||
        (roomDescription &&
          (roomDescription.includes((_) => _ == 'casal') ||
            roomDescription.includes((_) => _ == 'double bed')));

      let meal =
        room.fares[0] && room.fares[0].fareInformation.board
          ? room.fares[0].fareInformation.board.description ||
            room.fares[0].fareInformation.board.name
          : room.mealOption
          ? room.mealOption
          : null;

      room.filterItens = {
        bedTypes: [],
        mealTypes: [],
      };

      if (existSingleBed) {
        !valuesFilters.bedTypes.some((item) => item.label == singleBed)
          ? valuesFilters.bedTypes.push(
              listItemsResolver.resolveItem(singleBed),
            )
          : null;
        !room.filterItens.bedTypes.some((_) => _ == singleBed)
          ? room.filterItens.bedTypes.push(singleBed)
          : null;
      }

      if (existDoubleBed) {
        !valuesFilters.bedTypes.some((item) => item.label == doubleBed)
          ? valuesFilters.bedTypes.push(
              listItemsResolver.resolveItem(doubleBed),
            )
          : null;
        !room.filterItens.bedTypes.some((_) => _ == doubleBed)
          ? room.filterItens.bedTypes.push(doubleBed)
          : null;
      }

      if (!existDoubleBed && !existSingleBed) {
        !valuesFilters.bedTypes.some((item) => item.label == noInformation)
          ? valuesFilters.bedTypes.push(
              listItemsResolver.resolveItem(noInformation),
            )
          : null;
        !room.filterItens.bedTypes.some((_) => _ == noInformation)
          ? room.filterItens.bedTypes.push(noInformation)
          : null;
      }

      if (meal) {
        !valuesFilters.mealTypes.some((item) => item.label == meal)
          ? valuesFilters.mealTypes.push(listItemsResolver.resolveItem(meal))
          : null;
        !room.filterItens.mealTypes.some((_) => _ == meal)
          ? room.filterItens.mealTypes.push(meal)
          : null;
      } else {
        !valuesFilters.mealTypes.some((item) => item.label == noMeal)
          ? valuesFilters.mealTypes.push(listItemsResolver.resolveItem(noMeal))
          : null;
        !room.filterItens.mealTypes.some((_) => _ == noMeal)
          ? room.filterItens.mealTypes.push(noMeal)
          : null;
      }
    });

    this.setState({ valuesFilters, rooms });
  };
}
