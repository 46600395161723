import Field from '../../../field/field.component.js';
import { enums } from '@legacy-utils/enums';

var Template = {
  main: ({
    fieldsValues,
    notificationType,
    forceValidation,
    events,
    showAddresseeName,
    placeholder,
  }) => {
    let fieldsSet = [];

    if (notificationType == enums.notificationType.email)
      fieldsSet = Template.emailFieldsSet(
        fieldsValues,
        forceValidation,
        events,
        showAddresseeName,
        placeholder,
      );

    return <div className="notifier-fields">{fieldsSet}</div>;
  },

  emailFieldsSet: (
    fieldsValues,
    forceValidation,
    events,
    showAddresseeName,
    placeholder,
  ) => {
    let fieldKeyPrefix = 'notifier-field';
    return [
      showAddresseeName && (
        <Field
          key={`${fieldKeyPrefix}_to_name`}
          type={enums.fieldType.text}
          label={'Nome Destinatário'}
          placeholder={'Nome'}
          value={fieldsValues.to.name}
          refName={'to'}
          required={true}
          forceValidation={forceValidation}
          onFieldChange={events.onNameFieldChanged}
        />
      ),
      <Field
        key={`${fieldKeyPrefix}_to_email`}
        type={enums.fieldType.text}
        label={'Enviar para'}
        placeholder={
          placeholder
            ? placeholder
            : "Informar email ou @travelers, @requester, @approvers, @consultants (caso +1 separar com virgula ',')"
        }
        value={fieldsValues.to.email}
        refName={'to'}
        required={true}
        forceValidation={forceValidation}
        onFieldChange={events.onEmailFieldChanged}
      />,
      <Field
        key={`${fieldKeyPrefix}_cc_email`}
        type={enums.fieldType.text}
        label={'CC'}
        placeholder={
          placeholder
            ? placeholder
            : "Informar email ou @travelers, @requester, @approvers, @consultants (caso +1 separar com virgula ',')"
        }
        value={fieldsValues.cc.email}
        refName={'cc'}
        onFieldChange={events.onEmailFieldChanged}
      />,
      <Field
        key={`${fieldKeyPrefix}_cco_email`}
        type={enums.fieldType.text}
        label={'CCO'}
        placeholder={
          placeholder
            ? placeholder
            : "Informar email ou @travelers, @requester, @approvers, @consultants (caso +1 separar com virgula ',')"
        }
        value={fieldsValues.cco.email}
        refName={'cco'}
        onFieldChange={events.onEmailFieldChanged}
      />,
      <Field
        key={`${fieldKeyPrefix}_subject`}
        type={enums.fieldType.text}
        label={'Assunto'}
        placeholder={'Insira o assunto do  email'}
        value={fieldsValues.subject}
        refName={'subject'}
        required={true}
        forceValidation={forceValidation}
        onFieldChange={events.onFieldChanged}
      />,
      <Field
        key={`${fieldKeyPrefix}_message`}
        type={enums.fieldType.textArea}
        label={'Mensagem'}
        placeholder={
          'Insira a mensagem adicional a ser enviado no corpo do email'
        }
        value={fieldsValues.message}
        refName={'message'}
        onFieldChange={events.onFieldChanged}
      />,
    ];
  },
};

export default Template;
