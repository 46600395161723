import 'swiper/css';

import { useCallback, useState } from 'react';

import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { Card } from './Card/index';
import * as SC from './styled';

import { useIsMobile } from '@shared/hooks';

type ButtonsPosition = 'bottom' | 'top';

interface SlideProps {
  items: SlideItem[];
  title: string;
  showDots?: boolean;
  buttonsPosition?: ButtonsPosition;
}

export interface SlideItem {
  icon: string;
  title: string;
  description: string;
}

export const Slider = ({
  items,
  title,
  showDots = true,
  buttonsPosition = 'bottom',
}: SlideProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const isMobile = useIsMobile();

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
    setActiveIndex(swiperRef.realIndex);
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
    setActiveIndex(swiperRef.realIndex);
  }, [swiperRef]);

  return (
    <Box width={'100%'}>
      <SC.TitleContainer>
        <SC.Title variant="headline6">{title}</SC.Title>
        <SC.ContainerNavigationButtons>
          <IconButton
            size="medium"
            variant="line"
            icon="IconChevronLeft"
            onClick={handlePrevious}
          />
          <IconButton
            size="medium"
            variant="line"
            icon="IconChevronRight"
            onClick={handleNext}
          />
        </SC.ContainerNavigationButtons>
      </SC.TitleContainer>
      <SC.Slider
        onSwiper={setSwiperRef}
        onTouchMove={(touchData) => setActiveIndex(touchData.realIndex)}
        slidesPerView={isMobile ? 1 : 4}
        spaceBetween={15}
        loop={true}
        style={{ overflow: isMobile ? 'visible' : 'hidden' }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <Card
              iconName={item.icon}
              description={item.description}
              title={item.title}
            />
          </SwiperSlide>
        ))}
      </SC.Slider>
      <SC.ContainerNavigation>
        {showDots && (
          <SC.ContainerNavigationDots>
            {items.map((_, index) => (
              <SC.Dot key={index} isActive={activeIndex === index} />
            ))}
          </SC.ContainerNavigationDots>
        )}
        {buttonsPosition === 'bottom' && (
          <SC.ContainerNavigationButtons>
            <IconButton
              size="medium"
              variant="line"
              icon="IconChevronLeft"
              onClick={handlePrevious}
            />
            <IconButton
              size="medium"
              variant="line"
              icon="IconChevronRight"
              onClick={handleNext}
            />
          </SC.ContainerNavigationButtons>
        )}
      </SC.ContainerNavigation>
    </Box>
  );
};
