import React, { createContext, useContext, useState } from 'react';
import { Loader, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

interface ILoadingContext {
  isLoading: boolean;
  loadingText: string;
  showLoading: (text?: string) => void;
  hideLoading: () => void;
}

const LoadingContext = createContext<ILoadingContext | null>(null);

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading deve ser usado dentro de um LoadingProvider');
  }
  return context;
};

export const LoadingProvider = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>('Aguarde...');

  const showLoading = (text = 'Aguarde...') => {
    setLoadingText(text);
    setLoading(true);
  };
  const hideLoading = () => {
    setLoading(false);
  };

  // Definindo o contêiner estilizado para centralizar o loader
  const LoaderContainer = styled.div`
    position: fixed;
    flex-direction: column;
    gap: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgb(255 255 255 / 61%);
  `;

  return (
    <LoadingContext.Provider
      value={{ isLoading, loadingText, showLoading, hideLoading }}
    >
      {children}
      {isLoading && (
        <LoaderContainer>
          <Loader size={'large'} variant={'primary'} />
          <Typography variant={'body3'}>{loadingText}</Typography>
        </LoaderContainer>
      )}
    </LoadingContext.Provider>
  );
};
