import { PropsWithChildren, ReactNode } from 'react';

import * as SC from './styled';

type Props = PropsWithChildren<{
  title?: ReactNode;
  caption?: ReactNode;
  leftAdornment?: ReactNode;
  action?: ReactNode;
  showDivider?: boolean;
}>;

export function Section({
  title,
  caption,
  children,
  leftAdornment,
  action,
  showDivider,
}: Props) {
  return (
    <SC.Container hasSelection={Boolean(leftAdornment)}>
      <SC.HeaderContent hasChildren={Boolean(children)}>
        {leftAdornment && leftAdornment}
        <SC.HeaderContainer>
          <SC.Header hasSelection={Boolean(leftAdornment)}>
            {title && (
              <SC.Title variant={leftAdornment ? 'body3' : 'headline8'}>
                {title}
              </SC.Title>
            )}
            {caption && <SC.Caption variant="body4">{caption}</SC.Caption>}
          </SC.Header>
          {action && action}
        </SC.HeaderContainer>
      </SC.HeaderContent>

      {showDivider && <SC.Divider />}

      {children && (
        <SC.Body hasSelection={Boolean(leftAdornment)}>{children}</SC.Body>
      )}
    </SC.Container>
  );
}
