import React from 'react';
import * as SC from '../styled';

interface ModalFooterRootProps {
  children: React.ReactNode;
  center?: boolean;
  className?: string;
}
export function ModalFooterRoot({
  children,
  center = true,
  className,
}: ModalFooterRootProps) {
  return (
    <SC.ModalFooterContainer center={center} className={className}>
      {children}
    </SC.ModalFooterContainer>
  );
}
