'use strict';

import React from 'react';
import Template from './credit-card.template.js';
import { creditCardUtil } from '@legacy-utils/creditCardUtil';

export default class CreditCardComponent extends React.Component {
  constructor(props) {
    super(props);

    let propsData = this.props.data;
    propsData.brandAutoIdentified = propsData.brandCode != null;

    this.state = {
      showBrandCodeChoice: false,
      data: this.props.data || {
        holder: '',
        number: '',
        cvc: '',
        expirationDate: '',
        brandCode: '',
        numberData: '',
      },
    };
  }

  render() {
    return (
      <Template.main
        data={this.state.data}
        forceValidation={this.props.forceValidation}
        showBrandCodeChoice={this.state.showBrandCodeChoice}
        validations={{
          validateCardNumber: CreditCardComponent.validateCardNumber,
          validateHolderName: CreditCardComponent.validateHolderName,
          validateCvv: CreditCardComponent.validateCvv,
          validateExpirationDate: CreditCardComponent.validateExpirationDate,
        }}
        events={this.events}
      />
    );
  }

  events = {
    onCardHolderChanged: (value) => {
      let data = this.state.data;
      data.holder = value;
      this.setState({ data });

      this.props.onCardHolderChange && this.props.onCardHolderChange(value);
    },
    onCardNumberChanged: (cardNumber) => {
      let brandCode = creditCardUtil.getCreditCardBrandCode(cardNumber);

      if (cardNumber.length == 16 && !brandCode) brandCode = 'unknown';

      let data = this.state.data;
      data.number = cardNumber;
      data.brandCode = brandCode;

      this.setState({
        data,
        brandAutoIdentified: brandCode != 'unknown',
      });

      this.props.onCardNumberChange &&
        this.props.onCardNumberChange(cardNumber);
      this.props.onCardBrandCodeChange &&
        this.props.onCardBrandCodeChange(brandCode);
    },
    onCvcChanged: (value) => {
      let data = this.state.data;
      data.cvc = value;
      this.setState({ data });

      this.props.onCvvChange && this.props.onCvvChange(value);
    },
    onExpirationDateChanged: (value) => {
      let data = this.state.data;
      data.expirationDate = value;
      this.setState({ data });

      this.props.onExpirationDateChange &&
        this.props.onExpirationDateChange(value);
    },
    onBrandCodeClicked: () => {
      if (!this.state.brandCode && !this.state.brandAutoIdentified) {
        this.setState({
          showBrandCodeChoice: true,
        });
      }
    },
    onBrandCodeItemClicked: (brandCode) => {
      let data = this.state.data;
      data.brandCode = brandCode;
      this.setState({
        showBrandCodeChoice: false,
        data,
      });

      this.props.onCardBrandCodeChange &&
        this.props.onCardBrandCodeChange(brandCode);
    },
    onCancelBrandCodeSelectionClicked: () => {
      this.setState({
        showBrandCodeChoice: false,
      });
    },
  };

  static validateCardNumber(cardNumber) {
    if (!cardNumber || cardNumber.length < 11)
      return {
        isValid: false,
        message: 'Número do cartão inválido',
      };

    return {
      isValid: true,
    };
  }
  static validateBrandCode(brandCode) {
    if (!brandCode || brandCode == "unknown")
      return {
        isValid: false,
        message: 'Bandeira inválida',
      };

    return {
      isValid: true,
    };
  }
  static validateHolderName(holderName) {
    if (!holderName || holderName.length < 5)
      return {
        isValid: false,
        message: 'Nome do titular inválido',
      };

    return {
      isValid: true,
    };
  }
  static validateCvv(cvv) {
    if (!cvv || cvv.length < 3 || cvv.length > 4)
      return {
        isValid: false,
        message: 'CVC inválido',
      };

    return {
      isValid: true,
    };
  }
  static validateExpirationDate(expirationDate) {
    let [strMonth, strYear] = expirationDate && expirationDate.split('/');
    let month = parseInt(strMonth);
    let year = parseInt(strYear);
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear().toString().substring(1);
    let currentMonth = currentDate.getMonth() + 1;

    if (isNaN(month) || isNaN(year) || month < 1 || month > 12)
      return {
        isValid: false,
        message: 'Expiração inválida',
      };

    if (year < currentYear || (year == currentYear && month < currentMonth))
      return {
        isValid: false,
        message: 'Cartão vencido',
      };

    return {
      isValid: true,
    };
  }

  static validate(cardNumber, brandCode, holderName, cvv, expirationDate) {
    const validations = [
      {
        func: CreditCardComponent.validateCardNumber,
        arg: cardNumber,
      },
      {
        func: CreditCardComponent.validateBrandCode,
        arg: brandCode,
      },
      {
        func: CreditCardComponent.validateHolderName,
        arg: holderName,
      },
      {
        func: CreditCardComponent.validateCvv,
        arg: cvv,
      },
      {
        func: CreditCardComponent.validateExpirationDate,
        arg: expirationDate,
      },
    ];

    let validationResult = {
      isValid: true,
    };

    for (let i = 0; i < validations.length; i++) {
      let validation = validations[i];
      validationResult = validation.func(validation.arg);
      if (!validationResult.isValid) break;
    }

    return validationResult;
  }
}
