import Link from '../../../../common/link/link.component.js';
import Popup from '../../../../popup/popup.component.js';
import HotelRoomsList from '../hotel-rooms-group/hotel-rooms-list/hotel-rooms-list.component.js';
import FilterRooms from '../hotel-rooms-group/filter-rooms/filter-rooms.component.js';

let Template = ({ props, args, events }) => {
  return (
    <div className="exon-hotel-rooms-group">
      <div className="exon-hotel-rooms-group-filter">
        {props.showFilterRooms && (
          <FilterRooms
            {...args.valuesFilters}
            onFilterClick={(data) => events.onApplyFilterRoomClick(data)}
          />
        )}
      </div>

      <HotelRoomsList
        identifierGroup={props.identifierGroup}
        roomIdSelected={props.roomIdSelected}
        rooms={args.rooms.slice(0, args.roomsDisplayed)}
        onSelectRoomClick={events.onSelectRoomClick}
        onDetailsHotelClick={props.onDetailsHotelClick}
        showFilterRooms={props.showFilterRooms}
      />

      {args.rooms.length > args.roomsDisplayed && (
        <div className="exon-row-itens exon-row-aling-itens-bottom exon-row-justify-content-center">
          <Link
            className="exon-link-rooms-list"
            description={`Ver mais ${
              args.rooms.slice(args.roomsDisplayed, args.rooms.length).length
            } ${
              args.rooms.slice(args.roomsDisplayed, args.rooms.length).length !=
              1
                ? 'quartos'
                : 'quarto'
            }`}
            onClick={() => events.onMoreRoomsClick()}
          />
        </div>
      )}
    </div>
  );
};

export default Template;
