'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import PopupFilterTemplate from './popup-filters.template.js';

export default class PopupFilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {};
  }

  render() {
    return (
      <PopupFilterTemplate
        children={this.props.children}
        onAppliedFilterClick={this.enents.onAppliedFilterClicked}
        onCloseFilterClick={this.enents.onCloseFilterClicked}
        title={this.props.title}
        identifier={this.props.identifier}
      />
    );
  }

  enents = {
    onCloseFilterClicked: () => {
      if (this.props.onPopupCloseButtonClick)
        this.props.onPopupCloseButtonClick(this.props.identifier);
    },

    onAppliedFilterClicked: () => {
      if (this.props.onPopupApplyButtonClick)
        this.props.onPopupApplyButtonClick(this.props.identifier);
    },
  };
}
