import { create } from 'zustand';
import { enums } from '@legacy-utils/enums';
import {
  getLicenceFeaturesByContext,
  getUserProfileData,
} from '@api/connectOn/connectOn.service';
import { CostCenterTypes } from '../types/CostCenter.types';
import { RequesterTypes } from '../types/Requester.types';
import { gritter } from '@legacy-utils/utils';

type RequestTravelStore = {
  contextId: string | null;
  updateContextId: (newContextId: string) => void;
  isAgency: boolean;
  rootContexts: any[];
  company: any;
  saveCompany: (company: any) => void;
  costCenter: CostCenterTypes | null;
  updateCostCenter: (newCostCenter: CostCenterTypes) => void;
  requester: RequesterTypes | null;
  updateRequester: (requester: RequesterTypes) => void;
  idUserProfile: number | null;
  hasCostCenter: boolean;
  isModalRV: boolean;
  updateIsModalRV: (isOpen: boolean) => void;
  saveTraveler: (data: any) => void;
  traveler: any;
  numberTravelers: number;
  updateTravelersQuantity: (number: number) => void;
  fetchUserData: () => void;
  getEnums: () => typeof enums;
};

export const useTravelStore = create<RequestTravelStore>((set) => ({
  contextId: null,
  updateContextId: (contextId) => set({ contextId }),
  isAgency: false,
  rootContexts: [],
  idUserProfile: null,
  hasCostCenter: false,
  requester: null,
  company: null,
  costCenter: null,
  isModalRV: false,
  traveler: null,
  numberTravelers: 0,

  updateIsModalRV: (isModalRV) => set({ isModalRV }),
  updateTravelersQuantity: (numberTravelers) => set({ numberTravelers }),
  saveTraveler: (traveler) => set({ traveler }),
  saveCompany: (company) => set({ company }),
  updateCostCenter: (costCenter) => set({ costCenter }),
  updateRequester: (requester) => set({ requester }),
  fetchUserData: async () => {
    try {
      const { userProfile } = await getUserProfileData();
      const { company, accessContexts, id, name } = userProfile || {};
      if (company) {
        const { contextId, isAgency } = company;
        const { features } = await getLicenceFeaturesByContext(contextId);
        const allowCostCenterInput = features.includes('FT10006');
        const filteredCompanies = accessContexts.filter(
          (company) => !company.subsidiaryId,
        );

        set({
          contextId,
          isAgency,
          rootContexts: filteredCompanies,
          idUserProfile: id,
          hasCostCenter: allowCostCenterInput,
          requester: {
            userId: id,
            name: name, // Inserir o nome do usuário no momento do carregamento
          },
        });
      }
    } catch (error) {
      gritter.Error(`Erro ao buscar dados do perfil do usuário: ${error}`);
    }
  },
  getEnums: () => enums,
}));
