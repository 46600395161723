import { getFromLS } from '@flash-tecnologia/hros-web-utility';

export function useUserAuth() {
  const userAuth = getFromLS('userAuth');

  return {
    name: userAuth?.attributes?.name || '',
    phone: userAuth?.attributes?.phone_number || '',
    email: userAuth?.attributes?.email || '',
  };
}
