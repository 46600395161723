import styled from 'styled-components';

export const TravelerContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: 'flex-start';
`;

export const TravelerItem = styled.div`
  flex-basis: 'calc(50% - 10px)';
  max-width: 'calc(50% - 10px)';
`;
