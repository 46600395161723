import { Button, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  padding: 60px 60px 0 60px;
  width: 100%;
  margin: 0 auto;
  flex: 1;

  @media screen and (max-width: 992px) {
    padding: 60px 80px 0 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 60px 24px 0 24px;
  }
`;

export const SectionTitle = styled(Typography)`
  text-align: center;
  font-size: 16px !important;
`;

export const StyledButton = styled(Button)`
  &&.MuiButtonBase-root.size-large {
    padding: 19px 53px;

    @media screen and (max-width: 420px) {
      margin: 0 auto;
    }
  }
`;

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  font-weight: bold;
`;
