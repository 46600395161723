import { PaymentFormsProps } from '..';
import { PaymentFormsItem } from '../PaymentFormsItem';
import { ApprovalStatus } from '../models';

import * as SC from './styled';

interface PaymentFormsListProps {
  paymentForms: PaymentFormsProps[];
  approvalStatus: ApprovalStatus;
}

export function PaymentFormsList({
  paymentForms,
  approvalStatus,
}: PaymentFormsListProps) {
  return (
    <SC.Container>
      {(paymentForms || []).map((paymentForm, index) => {
        return (
          <PaymentFormsItem
            key={index}
            paymentForm={paymentForm}
            approvalStatus={approvalStatus}
          />
        );
      })}
    </SC.Container>
  );
}
