import {
  Accordion,
  Icons,
  LinkButton,
  Toggle,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box, Grid } from '@mui/material';
import { PolicyRuleStatus } from 'bff/src/routers/policies/enums';
import { useState } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { PolicyRegisterForm, PolicyRuleConfiguration } from '../../types';
import { PolicyRuleParameterCard } from '../PolicyRuleParameterCard';
import {
  ParameterFormValues,
  PolicyRuleParametersModal,
} from '../PolicyRuleParametersModal';

type Props = {
  index: number;
  rule: PolicyRuleConfiguration;
  isActive: boolean;
};

const PolicyRuleItem = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const canAddParameters = props.rule.fields?.length > 0;

  const policyForm = useFormContext<PolicyRegisterForm>();

  const ruleStatus = useController({
    control: policyForm.control,
    name: `rules.${props.index}.status` as const,
  });

  const parametersField = useFieldArray({
    control: policyForm.control,
    name: `rules.${props.index}.parameters` as const,
  });

  const [editing, setEditing] = useState<{
    index: number;
    parameter: ParameterFormValues;
  } | null>(null);

  function onSaveParameter(newParameter: ParameterFormValues) {
    if (editing) {
      parametersField.update(editing.index, newParameter);
    } else {
      parametersField.append(newParameter);
    }

    setIsModalOpen(false);
    setEditing(null);
  }

  function onDeleteParameter(index: number) {
    const countParameters = parametersField.fields.length - 1;

    parametersField.remove(index);

    if (countParameters === 0) {
      ruleStatus.field.onChange(PolicyRuleStatus.INACTIVE);
    }
  }

  function onCloseParameterModal() {
    setIsModalOpen(false);
    setEditing(null);

    if (parametersField.fields.length === 0) {
      ruleStatus.field.onChange(PolicyRuleStatus.INACTIVE);
    }
  }

  function onToggleRuleStatus(checked: boolean) {
    ruleStatus.field.onChange(
      checked ? PolicyRuleStatus.ACTIVE : PolicyRuleStatus.INACTIVE,
    );

    if (parametersField.fields.length === 0) {
      setIsModalOpen(canAddParameters);
    }
  }

  function onEditParameter(field: ParameterFormValues, index: number) {
    setEditing({ index, parameter: field });

    setIsModalOpen(canAddParameters);
  }

  return (
    <Accordion
      expanded={
        ruleStatus.field.value === PolicyRuleStatus.ACTIVE && canAddParameters
      }
      variant="default"
      title={props.rule.code}
      onChange={(_) => {
        const isActive = ruleStatus.field.value === PolicyRuleStatus.ACTIVE;

        onToggleRuleStatus(!isActive);
      }}
      description={props.rule.description}
      leftIcon={
        <Toggle
          checked={ruleStatus.field.value === PolicyRuleStatus.ACTIVE}
          onChange={(e, checked) => onToggleRuleStatus(checked)}
        />
      }
    >
      <Grid container spacing={3}>
        {parametersField.fields.map((field, index) => (
          <Grid
            item
            xs={12}
            xl={props.rule.fields.length > 3 ? 12 : 6}
            key={field.id}
          >
            <PolicyRuleParameterCard
              fields={props.rule.fields}
              values={field}
              onEditClick={() => onEditParameter(field, index)}
              onDeleteClick={() => onDeleteParameter(index)}
            />
          </Grid>
        ))}
      </Grid>

      <Box mt={3}>
        <LinkButton variant="default" onClick={() => setIsModalOpen(true)}>
          Adicionar
          <Icons name="IconPlus" fill="transparent" size={18} />
        </LinkButton>
      </Box>

      {isModalOpen && (
        <PolicyRuleParametersModal
          defaultValues={editing?.parameter}
          onClose={onCloseParameterModal}
          rule={props.rule}
          onSave={onSaveParameter}
        />
      )}
    </Accordion>
  );
};

export default PolicyRuleItem;
