import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from '@locale/Translator';
import { getUserProfile } from '@legacy-dataSources/profile.dataSources';
import { ensureEmployeeSync } from '@api/expenseGateway/expenseGateway.service';
import { LANDING_PAGE_URL } from '@shared/constants/Routes.constants';
import { dispatchToast } from '@shared/toast';
import { UserData, UserProfileData } from '@shared/types';
import NewFunctionsFlash from '@components/NewFunctionsFlash';
import RequestTravelItemOffline from '@components/RequestTravel/RequestTravelItemOffline';
import RequestTravelOffline from '@components/RequestTravel/RequestTravelOffline';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';
import { SessionContext } from '../../contexts/SessionContext/sessionContext';
import * as SC from './styled';
import SelectedCompany from '@shared/components/SelectedCompany';
import ShoppingCartCounter from '@shared/components/ShoppingCartCounter';
import { useTravelStore } from '../../store/RequestTravel.store';

type TProps = {
  pageTitleKey: string;
  children?: React.ReactNode;
  skeleton?: React.ReactNode;
  userProfileLoaded: (profileData: UserProfileData) => void;
  showNewsDisclaimer?: boolean;
  showAddTravelRequest?: boolean;
};

const PageBase = ({
  pageTitleKey,
  children,
  skeleton,
  userProfileLoaded,
  showNewsDisclaimer = false,
  showAddTravelRequest = false,
}: TProps) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState<UserData>(null);
  const { enableVehicleOffline } = useTravelPermissions();
  const fetchUserData = useTravelStore((state) => state.fetchUserData);
  const { companyId } = useContext(SessionContext);

  useEffect(() => {
    fetchUserData();
  }, [companyId]);

  const toastEmployeeSyncError = (_error: any) => {
    dispatchToast({
      type: 'error',
      content: 'Erro na confirmação do usuário.',
      description:
        'Houve um erro ao confirmar o usuário para a utilização do módulo de viagens corporativas. Caso este erro persista, por favor, contate o suporte informando o ocorrido.',
    });
  };

  const tryEnsureSync = async () => {
    let employeeSyncAssured = false;

    try {
      employeeSyncAssured = await ensureEmployeeSync();
    } catch (error) {
      console.error('Erro na garantia de sync.', error);
    }

    return employeeSyncAssured;
  };

  useEffect(() => {
    setUserData(null);
    const getUserData = async () => {
      try {
        const employeeSyncAssured = await tryEnsureSync();
        const { userProfile } = await getUserProfile();

        userProfileLoaded(userProfile);
        setUserData({
          userProfile,
          employeeSyncAssured,
        });
      } catch (error) {
        toastEmployeeSyncError(error);
      }
    };
    getUserData();
  }, [companyId]);

  if (userData?.userProfile?.company.travelModuleActive === false) {
    return <Navigate to={LANDING_PAGE_URL} />;
  }

  return (
    <SC.PageContainer>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <div>
          <SC.PageTitle>{t(pageTitleKey)}</SC.PageTitle>
        </div>
        <div>
          {showAddTravelRequest && enableVehicleOffline && (
            <>
              <RequestTravelOffline isListItem={false} />
            </>
          )}
          {enableVehicleOffline && <RequestTravelItemOffline />}
        </div>
        <div>
          {showAddTravelRequest && userData?.userProfile?.company?.isAgency && (
            <div>
              <SelectedCompany />
            </div>
          )}
        </div>
        <SC.ShoppingCartCounterContainer>
          {showAddTravelRequest && (
            <div>
              <ShoppingCartCounter />
            </div>
          )}
        </SC.ShoppingCartCounterContainer>
      </div>

      {showNewsDisclaimer && <NewFunctionsFlash />}
      {!userData ? skeleton : children}
      <br />
    </SC.PageContainer>
  );
};

export default PageBase;
