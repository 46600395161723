import React from 'react';
import { Modal } from '../../../components/Modal';
import Split from './split.component';

const { Root, Body } = Modal;
const { Root: Header, Title } = Modal.Header;
const { Root: Footer, ActionButton, DismissButton } = Modal.Footer;

const Template = {
  main: ({ events, splitProps }) => {
    return (
      <Root open={splitProps.openSplitModal} size="full">
        <Header
          hasDismissButton={true}
          onCloseClick={() => events.toggleSplitModal()}
        >
          <Title>
            {splitProps.costCenterSplit.isEdit ? 'Editar' : 'Adicionar'} divisão
            de custos
          </Title>
        </Header>
        <Body>
          <Split {...splitProps} />
        </Body>
        <Footer center={false}>
          <DismissButton onCancelClick={() => events.toggleSplitModal()}>
            Cancelar
          </DismissButton>
          <ActionButton onActionClick={events.updateCostSplit}>
            Confirmar
          </ActionButton>
        </Footer>
      </Root>
    );
  },
};

export default Template;
