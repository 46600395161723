import { Box } from '@mui/material';
import PolicyRuleItem from '../../PolicyRuleItem';
import { usePolicyRules } from './hooks/usePolicyRules';

const PolicyRules = () => {
  const rules = usePolicyRules();

  return (
    <Box gap={2} display="flex" flexDirection="column" mb={2}>
      {rules.map((rule, index) => (
        <PolicyRuleItem index={index} rule={rule} isActive />
      ))}
    </Box>
  );
};

export default PolicyRules;
