import { Box } from '@mui/material';
import styled from 'styled-components';

export const ActionSelectorContainer = styled(Box).attrs({
  gap: 2,
})<{ isFull: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isFull ? 'column' : 'row')};

  ${(props) =>
    props.isFull &&
    `
      > .MuiButtonBase-root {
        width: 100% !important;
      }
  `}
`;
