import Popup from '../../popup/popup.component.js';
import Slider from '../../common/slider/slider.component.js';
import Carroussel from '../../common/carroussel/carroussel.component.js';

let Template = ({
  props,
  images,
  show,
  imageIndexActive,
  moveClassCarroussel,
  events,
}) => {
  let currentImages = props.images.map((img, index) => {
    if (!img) img = props.imageDefault;

    img['index'] = index;
    return img;
  });

  return (
    <div className="exon-image-mosaic">
      <div className={`exon-grid-container exon-box-items-${images.length}`}>
        {images.map((image, index) => {
          if (!image) image = props.imageDefault;

          if (index + 1 <= 4) {
            return (
              <div
                key={index}
                className={`exon-grid-cell exon-grid-cell-${index + 1}`}
              >
                <img
                  src={image.url}
                  onError={event => {
                    event.target.src = props.imageDefault;
                    event.onerror = null;
                  }} />
              </div>
            );
          }
        })}
      </div>

      {images.length > 1 && (
        <button
          className="exon-button exon-button-primary"
          onClick={() => events.onClick()}
        >
          Ver galeria
          {images.length > 1 && (
            <span className="exon-secondary-color">
              {images.length > 4 ? '+' + (images.length - 4) : ''}
            </span>
          )}
        </button>
      )}

      {show && (
        <Popup
          exonStyle={true}
          title={props.headerPopup ? props.headerPopup : 'Galeria de fotos'}
          cancelButtonClick={() => events.onClick()}
        >
          {currentImages.length && (
            <Slider
              images={currentImages}
              activeSlide={imageIndexActive}
              onClick={events.onCurrentImageClick}
            />
          )}

          {currentImages.length > 1 && (
            <div className="exon-image-mosaic-carroussel">
              <hr />
              <Carroussel
                images={currentImages}
                imageIndexActive={imageIndexActive}
                moveClass={moveClassCarroussel}
                displayButtons={currentImages.length >= 6}
                onClick={(index) => events.onCurrentImageClick(index)}
              />
            </div>
          )}
        </Popup>
      )}
    </div>
  );
};

export default Template;
