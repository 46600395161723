import React from 'react';
import { Table } from '@flash-tecnologia/hros-web-ui-v2';

interface TableHeaderProps {
  filteredItemsCount: number;
}

export function TableHeader({ filteredItemsCount }: TableHeaderProps) {
  return (
    <Table.Header
      title="Fluxos de Aprovação"
      tagLabel={`${filteredItemsCount} fluxos`}
      tag="gray"
    />
  );
}
