export const getAirClasses = () => {
  let airClasses = new Map();
  airClasses.set('all', 'Todas as Classes');
  airClasses.set('2', 'Economica');
  airClasses.set('3', 'Economica Premium');
  airClasses.set('1', 'Executiva');
  airClasses.set('4', 'Executiva Premium');
  airClasses.set('0', 'Primeira Classe');
  return airClasses;
};

export const enums = {
  airClasses: {
    0: 'Primeira Classe',
    1: 'Executiva',
    2: 'Economica',
    3: 'Economica Premium',
    4: 'Executiva Premium',
  },

  airClassesMap: getAirClasses(),

  actionHistory: {
    1: 'Adicionado',
    2: 'Alterado',
    3: 'Excluido',
  },

  tripPartTypes: {
    goingAndReturn: 0, // ida e volta juntos
    going: 1, // ida
    tripOption: 2, // opção do multi-trecho
    return: 3, // volta
  },

  formatters: {
    boolean: 0,
    html: 1,
    list: 2,
    image: 3,
    noFilter: 4,
    date: 5,
  },

  destinationProfile: {
    traveler: 1,
    requester: 2,
    consultant: 3,
    approver: 4,
  },

  components: {
    textBoxType: {
      default: 0,
      mask: 1,
      number: 2,
      cpf: 3,
      cnpj: 4,
      cep: 5,
      phone: 6,
      date: 7,
      email: 8,
      numberMask: 9,
      textarea: 10,
      password: 11,
      card: 12,
      phoneGlobal: 13,
    },
  },

  fieldType: {
    text: 'text',
    select: 'select',
    richText: 'richText',
    phone: 'phone',
    date: 'date',
    image: 'image',
    number: 'number',
    locations: 'locations',
    mask: 'mask',
    textArea: 'textarea',
    paymentSelector: 'paymentSelector',
    password: 'password',
    email: 'email',
  },

  inputFile: {
    image: 'image',
  },

  locationType: {
    0: 'world',
    1: 'continent',
    2: 'country',
    3: 'state',
    4: 'city',
    5: 'airport',
    6: 'region',
    7: 'spot',
    8: 'port',
    9: 'busStation',

    world: 0,
    continent: 1,
    country: 2,
    state: 3,
    city: 4,
    airport: 5,
    region: 6,
    spot: 7,
    port: 8,
    busStation: 9,
  },

  productType: {
    air: 'air',
    hotel: 'hotel',
    vehicle: 'vehicle',
    none: 'none',

    0: 'none',
    1: 'air',
    2: 'hotel',
    3: 'vehicle',
  },

  products: {
    type: {
      0: 'none',
      1: 'air',
      2: 'hotel',
      3: 'vehicle',

      none: 0,
      air: 1,
      hotel: 2,
      vehicle: 2,
    },

    offlineType: {
      1: 'Aéreo',
      2: 'Hotel',
      3: 'Veículo',
    },

    //TODO: Avaliar a remoção do type acima
    namedType: {
      airBooking: 'airbooking',
      airTicket: 'airticket',
      hotelBooking: 'hotelbooking',
      vehicleBooking: 'vehiclebooking',
    },
  },

  quotationSteps: {
    hotelDetails: 'hotelDetails',
  },

  tripTypes: {
    goingAndReturn: 1, // ida e volta juntos
    going: 2, // ida
    multipleTrips: 3, // opção do multi-trecho
    return: 4, // volta
  },

  gender: {
    Masculino: 1,
    Feminino: 2,

    1: 'Masculino',
    2: 'Feminino',
  },

  travelerDocumentType: {
    RG: 1,
    Cpf: 2,
    Passport: 3,
    CNH: 4,

    1: 'RG',
    2: 'Cpf',
    3: 'Passport',
    4: 'CNH',
  },

  travelerHonorificPrefix: {
    Mr: 'Mr',
    Mrs: 'Mrs',
    Miss: 'Miss',
    Dr: 'Dr',
    Mx: 'Mx',
  },

  travelerType: {
    None: 0,
    Adt: 1,
    Chd: 2,
    Inf: 3,
  },

  daysOfWeek: {
    0: 'domingo',
    1: 'segunda',
    2: 'terça',
    3: 'quarta',
    4: 'quinta',
    5: 'sexta',
    6: 'sábado',
  },

  months: {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  },

  currencyCode: {
    real: 'BRL',
    euro: 'EUR',
    dolar: 'USD',
  },

  currency: {
    BRL: 'real',
    EUR: 'euro',
    USD: 'dolar',
  },

  currencyLocation: {
    BRL: 'pt-BR',
    EUR: 'de-DE',
    USD: 'en-IN',
  },

  currencySymbol: {
    BRL: 'R$',
    EUR: '€',
    USD: 'US$',
  },

  dateIntervals: {
    lessThenThree: -4,
    minusThree: -3,
    minusTwo: -2,
    minusOne: -1,
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    greaterThenThree: 4,
  },

  //Enum para FormField (CGM)
  fieldControlType: {
    date: 1,
    text: 2,
    number: 3,
    options: 4,
    //LeitorCodigoBarra: 5
  },

  creditCardBrandCodes: {
    unknown: 'unknown',
    AX: 'amex',
    EL: 'elo',
    HC: 'hipercard',
    JCB: 'jcb',
    MC: 'mastercard',
    VI: 'visa',
    DI: 'diners',
  },

  approvalStatus: {
    0: 'Pendente',
    1: 'Aprovado',
    2: 'Recusado',

    pending: 0,
    Approved: 1,
    refused: 2,
  },

  approvalMode: {
    0: 'Sequencial',
    1: 'Paralela',

    sequential: 0,
    parallel: 1,
  },

  passengerType: {
    0: 'Adulto',
    1: 'Criança',
    2: 'Bebê',
    3: 'Senior',

    adult: 0,
    child: 1,
    baby: 2,
    senior: 3,
  },

  feedbackType: {
    confirm: 1,
    info: 2,
    warning: 3,

    1: 'confirm',
    2: 'info',
    3: 'warning',
  },

  dateFormat: {
    dayMonthHourMinute: 'dd/MM HH:mm',
    monthYearHourMinute: 'dd/MM/yy HH:mm',
    dayMonth: 'dd/MM',
  },

  paymentType: {
    invoice: 0,
    creditCard: 1,
    storedCreditCard: 2,
    b2bPay: 3,
    thirdParty: 4,
    flashPay: 5,

    0: 'Faturado',
    1: 'Cartão de Credito',
    2: 'Cartão salvo',
    3: 'B2B Pay',
    4: 'Terceiros',
    5: 'Cartão Flash',
  },

  hotelGuaranteeType: {
    creditCard: 0,
    companyIata: 1,
    companyName: 2,
    b2bPay: 4,
    flashPay: 5,
  },

  /**
   * @deprecated Use statusFlow.statusCodes instead.
   */
  workflowStatus: {
    opened: 1,
    inQuotation: 2,
    inOption: 3,
    booked: 4,
    inAuthorization: 5,
    reproved: 6,
    inIssue: 7,
    errorIssue: 8,
    issued: 9,
    inCancelation: 10,
    canceled: 11,
    InIntegration: 14,
    Integrated: 15,
    ErrorIntegration: 16,
    IntegratedCancelation: 19,
    errorBooked: 21,
  },

  bookingStatus: {
    booked: 'Booked',
    canceled: 'Canceled',
    bookPending: 'BookPending',
  },

  userTravelProfile: {
    traveler: 1,
    requester: 2,
    approver: 3,
    consultant: 4,
  },

  actionsBooking: {
    reserveAndSubmit: 0,
    reserve: 1,
    submitExternalBooking: 2,
    fetchExternalBooking: 3,
  },

  //TODO: WELL/GRAF DISCUTIR ALTERAÇÃO DA ORDEM NO DB.
  policyType: {
    block: 1,
    notifies: 2,
    justify: 3,
    noAction: 4,
  },

  //TODO: UNIFICAR EM UM ÚNICO ENUM
  //TODO: REVISAR SEQUENCIA (0 = BOTH, 1 = AIR, 2 = HOTEL)
  travelSections: {
    Air: 0,
    Hotel: 1,
    Both: 2,
  },

  notificationType: {
    email: 1,
  },

  actionsForm: {
    add: 1,
    edit: 2,
    createItem: 3,
    createAirItem: 4,
    createHotelItem: 5,
    delete: 6,
  },

  flightStatus: {
    1: 'Hold',
    2: 'Canceled',
    3: 'Booked',
    4: 'Refunded',
    5: 'Reimbursed',
    6: 'Confirmed',
    7: 'Open',
    8: 'Used',

    Hold: 1,
    Canceled: 2,
    Booked: 3,
    Refunded: 4,
    Reimbursed: 5,
    Confirmed: 6,
    Open: 7,
    Used: 8,
  },

  baggagesDescritions: {
    0: 'Sem bagagem',
    1: '1 Bagagem',
    2: '2 Bagagens',
    3: '3 Bagagens',
  },

  connectionType: {
    offline: 0,
    online: 1,
    both: 2,
  },

  travelType: {
    offline: 0,
    nacional: 1,
    internacional: 2,

    0: 'Off-line',
    1: 'Nacional',
    2: 'Internacional',
  },

  requestDataOrigin: {
    undefined: 0,
    sourceOnly: 1,
    manual: 2,
    mixed: 3,
    externalBooking: 4,
  },

  purchaseConfiguration: {
    product: {
      none: 0,
      all: 1,
      air: 2,
      hotel: 3,
    },
  },

  hotelAmenitiesType: {
    0: 'others',
    1: 'meal',
    2: 'cashback',
    3: 'swimming',
    4: 'parking',
    5: 'weight',
    6: 'accessibility',
    7: 'restaurants',
    8: 'noSmokers',
    9: 'roomService',
    10: 'pub',
    11: 'wifi',
    12: 'crystal',
    13: 'services',
    14: 'pets',
  },

  integrationBackOfficeName: {
    Apps: 0,
  },

  integrationStatus: {
    EmissionIntegrated: 0,
    EmissionError: 1,
    Processing: 2,
    CancelationIntegrated: 3,
    CancelationError: 4,
  },

  companyLicenceChangeType: {
    none: 0,
    downgrade: 1,
    upgrade: 2,
  },

  userPendenciesDetailsItemType: {
    advance: 1,
    report: 2,
    serviceRequest: 3,
  },
};

export const travelEnums = {
  fieldTypes: {
    text: 0,
    number: 1,
    time: 2,
    timeRange: 3,
    currency: 4,
    travelProduct: 5,
    travelType: 6,
    fareFamily: 7,
    hotelSupplier: 8,
    location: 9,
    bool: 11,
  },
  travelProducts: [
    { id: 1, text: 'Todos' },
    { id: 2, text: 'Aéreo' },
    { id: 3, text: 'Hotel' },
  ],
  boolOptions: [
    { id: 1, text: 'Sim' },
    { id: 2, text: 'Não' },
  ],
  travelTypes: [
    { id: 0, text: 'Todos' },
    { id: 1, text: 'Nacional' },
    { id: 2, text: 'Internacional' },
  ],
  suppliers: {
    all: [{ id: 'all', text: 'Todos' }],
    air: [
      { id: 'all', text: 'Todos' },
      { id: 'Azul', text: 'Azul' },
      { id: 'Gol', text: 'Gol' },
      { id: 'Latam', text: 'Latam' },
      { id: 'Sabre', text: 'Sabre' },
    ],
    hotel: [
      { id: 'all', text: 'Todos' },
      { id: 'b2b', text: 'B2B Reservas' },
      { id: 'omnibees', text: 'Omnibees' },
      { id: 'HotelOffline', text: 'HotelOffline' },
    ],
  },
  hotelSearchOrderBy: {
    price: 0,
    name: 1,
    stars: 2,
    preferedHotel: 3,
    custom: 4,
    distance: 5,
    starsPrice: 6,
    starsDistance: 7,
  },
  paymentTypes: [
    { id: 0, text: 'Faturado' },
    { id: 1, text: 'Cartão de Crédito' },
    { id: 3, text: 'B2BPay' },
    { id: 5, text: 'FlashPay' },
  ],
  paymentBrokers: [
    { id: 1, text: 'Cliente (Broker de Pagamento)' },
    { id: 2, text: 'Fornecedor' },
  ],
  orderByDateDash: [
    { id: 1, text: 'Data de Criação' },
    { id: 2, text: 'Data de Expiração' },
    { id: 3, text: 'Data de Embarque / Checkin' },
  ],
  serviceRequestStatus: [
    //{ id: undefined, name: 'Todos' },
    { id: 0, name: 'Inicial' },
    { id: 1, name: 'Aberto' },
    { id: 2, name: 'Em Cotação' },
    { id: 3, name: 'Em Opção' },
    { id: 21, name: 'Erro ao Reservar' },
    { id: 4, name: 'Reservado' },
    { id: 5, name: 'Em Aprovação' },
    { id: 6, name: 'Reprovado' },
    { id: 7, name: 'Em Emissão' },
    { id: 8, name: 'Erro na Emissão' },
    { id: 9, name: 'Emitido' },

    { id: 13, name: 'Integrar Emissão' },
    { id: 14, name: 'Em Integração (Emissão)' },
    { id: 15, name: 'Emissão Integrada' },
    { id: 16, name: 'Erro Integração (Emissão)' },

    { id: 10, name: 'Em Cancelamento' },
    { id: 11, name: 'Cancelado' },

    { id: 17, name: 'Integrar Cancelamento' },
    { id: 18, name: 'Em Integração (Cancelamento)' },
    { id: 19, name: 'Cancelamento Integrado' },
    { id: 20, name: 'Erro Integração (Cancelamento)' },
  ],
  operatorGroups: {
    generalOperators: [
      { id: 1, text: '=' },
      { id: 4, text: '≠' },
    ],
    textOperators: [
      { id: 1, text: '=' },
      { id: 4, text: '≠' },
      { id: 7, text: 'Contém' },
      { id: 8, text: 'Não Contém' },
    ],
    booleanOperators: [
      { id: 1, text: 'E' },
      { id: 2, text: 'Ou' },
    ],
  },
  productsRetrieve: [
    { id: 2, text: 'Aéreo' },
    { id: 3, text: 'Hotel' },
  ],
  suppliersRetrieve: {
    air: [
      { id: 'azul', text: 'Azul' },
      { id: 'gol', text: 'Gol' },
      { id: 'latam', text: 'Latam' },
      { id: 'sabre', text: 'Sabre' },
      { id: 'wooba', text: 'Wooba' },
    ],
    hotel: [
      { id: 'b2b', text: 'B2B Reservas' },
      { id: 'omnibees', text: 'Omnibees' },
      { id: 'iterpec', text: 'Iterpec' },
    ],
  },
  hotelSources: {
    omnibees: 'Omnibees',
    b2b: 'B2B',
    offline: 'HotelOffline',
  },
  flashCardErrors: {
    insufficientBalance: 'FL-VCC-001',
    balanceReserve: 'FL-VCC-003',
    airlineBookingValue: 'PG-VCC-001',
    genericErrorException: 'PG-VCC-002',
    blockedCard: 'BR-VCC-001',
    noPaymentDefaultRecord: 'BR-VCC-002',
  },
};
