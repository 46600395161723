'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import MoneyRangeTemplate from './money-range.template.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';

export default class MoneyRangeComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    const min = props.min ? Math.floor(props.min) : 0;
    const max = props.max ? Math.floor(props.max) : 1440;

    this.state = {
      min,
      max,
      step: 5,
      currencySymbol: enums.currencyCode.real,
      values: [min, max],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      min: props.min ? Math.floor(props.min) : 0,
      max: props.max ? Math.floor(props.max) : 1440,
      step: props.step ? props.step : 5,
    };
  }

  formatValues = (values) => {
    let first = utils.formatters.formatMoney(
      {
        value: Math.floor(values[0]),
        currencyCode: this.state.currencySymbol,
      },
      true,
    );
    let last = utils.formatters.formatMoney(
      {
        value: Math.floor(values[1]),
        currencyCode: this.state.currencySymbol,
      },
      true,
    );

    return [first, last];
  };

  render() {
    const formattedValues = this.formatValues(this.state.values);
    return (
      <MoneyRangeTemplate
        {...this.state}
        formattedValues={formattedValues}
        events={this.events}
      />
    );
  }

  events = {
    onChange: (event, values) => {
      this.setState({ values });
      if (this.props.onValuesChange) this.props.onValuesChange(values);
    },
  };
}
