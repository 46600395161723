import { queryClient, trpc, trpcClient } from '@api/bff/client';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { SessionContextProvider } from './contexts/SessionContext/sessionContext';
import { useForm, FormProvider } from 'react-hook-form';
import { QueryClientProvider } from '@tanstack/react-query';
import AppRouter from './routes';
import './i18n';
import { LoadingProvider } from './contexts/LoadingContext';
import AlertModalCustom from '@shared/components/AlertModalCustom';
import TravelConfigurationPage from './pages/TravelConfiguration';

export default function Root() {
  const methods = useForm();
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <SessionContextProvider>
          <ThemeProvider>
            <FormProvider {...methods}>
              <LoadingProvider>
                <AppRouter />
                <AlertModalCustom />
              </LoadingProvider>
            </FormProvider>
          </ThemeProvider>
        </SessionContextProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export function TravelConfiguration() {
  const methods = useForm();
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <SessionContextProvider>
          <ThemeProvider>
            <FormProvider {...methods}>
              <LoadingProvider>
                <TravelConfigurationPage />
                <AlertModalCustom />
              </LoadingProvider>
            </FormProvider>
          </ThemeProvider>
        </SessionContextProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
