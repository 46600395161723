import Container from '../../../../../../common/container/container.component.js';
import Icon from '../../../../../../common/icon/icon.component.js';
import { utils } from '@legacy-utils/utils';

let Template = (props) => {
  let getMoneyFormatted = utils.formatters.money.getFormatted;
  let getCurrencyCodeFormatted = utils.converters.money.getCurrencySymbol;
  let hasAdults = props.room.capacity && props.room.capacity.adults;
  let hasChildren = props.room.capacity && props.room.capacity.children;
  let capacityMax = props.room.capacity && props.room.capacity.max;

  let roomValue = props.room.fares[0].roomValue;
  let fareInformation = props.room.fares[0].fareInformation;
  let dailyTax = roomValue.dailyTax;
  let originalDailyTax = roomValue.originalDailyTax;
  let dailyFare = roomValue.dailyFare;
  let markupedDailyFare = roomValue.markupedDailyFare;
  let originalDailyFare = roomValue.originalDailyFare;

  let tax = dailyTax.value ? dailyTax.value : originalDailyTax.value;
  let fare = markupedDailyFare.value
    ? markupedDailyFare.value
    : originalDailyFare.value;
  let currencyCode =
    dailyTax.currencyCode ||
    originalDailyTax.currencyCode ||
    dailyFare.currencyCode ||
    originalDailyFare.currencyCode;
  let total = fare + tax;

  return (
    <div
      className={`exon-selected-room-card ${
        props.className && props.className
      }`}
    >
      <Container>
        <div className="exon-selected-room-card-identifier">
          <p>
            {`Quarto ${props.roomNumber} - ${
              hasAdults && hasAdults > 0
                ? hasAdults
                : capacityMax && capacityMax > 0
                ? capacityMax
                : ''
            } ${
              (hasAdults != 1 && hasAdults > 0) ||
              (capacityMax != 1 && capacityMax > 0)
                ? 'adultos'
                : 'adulto'
            } ${hasChildren && hasChildren > 0 && hasChildren ? 'e' : ''} ${
              hasChildren && hasChildren > 0 ? hasChildren : ''
            }  ${
              hasChildren && hasChildren > 0
                ? hasChildren != 1
                  ? 'crianças'
                  : 'criança'
                : ''
            }`}
          </p>
        </div>
        <div className="exon-selected-room-card-box">
          <div className="exon-room-card-base-infos">
            <div>
              <h5 className="exon-selected-room-card-name">
                {(props.room && props.room.name) || props.room.title}
              </h5>
              <div className="exon-selected-room-card-amenities">
                {fareInformation && (
                  <Icon
                    icon={
                      fareInformation.board &&
                      (fareInformation.board.description ||
                        fareInformation.board.name)
                        ? 'exon-icon-coffee-unfilled_enabled'
                        : 'exon-icon-coffee-unfilled_disabled'
                    }
                    description={
                      fareInformation.board
                        ? fareInformation.board.description ||
                          fareInformation.board.name
                        : 'Sem refeição'
                    }
                  />
                )}

                <Icon
                  icon={`exon-icon-cashback-unfilled_enabled${
                    !fareInformation.refundable ? '-gray' : ''
                  }`}
                  description={`${
                    !fareInformation.refundable ? 'Não ' : ''
                  }Reembolsável`}
                />
              </div>
            </div>
          </div>
          <div className="exon-selected-room-card-price">
            <div>
              <div className="exon-selected-room-card-price-fares">
                <p className="exon-selected-room-card-price-fares-description">
                  Tarifa diária
                </p>
                <p className="exon-selected-room-card-price-fares-total">
                  {`${getCurrencyCodeFormatted(
                    currencyCode,
                  )} ${getMoneyFormatted(fare, currencyCode)}`}
                </p>
              </div>
              <div className="exon-selected-room-card-price-fares">
                <p className="exon-selected-room-card-price-fares-description">
                  Taxas e impostos
                </p>
                <p className="exon-selected-room-card-price-fares-total">
                  {`${getCurrencyCodeFormatted(
                    currencyCode,
                  )} ${getMoneyFormatted(tax, currencyCode)}`}
                </p>
              </div>
            </div>
            <div className="exon-selected-room-card-price-total-box">
              <p className="exon-selected-room-card-price-description">
                {`Total de ${fareInformation.days} ${
                  fareInformation.days != 1 ? 'diárias' : 'diária'
                }`}
              </p>
              <p className="exon-selected-room-card-price-total">
                {`${getCurrencyCodeFormatted(currencyCode)} ${getMoneyFormatted(
                  total * fareInformation.days,
                  currencyCode,
                )}`}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Template;
