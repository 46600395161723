'use strict';

import React from 'react';
import Template from './hotel-option.template.js';

export default class HotelOptionComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Template.main
        hotel={this.props.hotel}
        fareData={this.props.fareData}
        actions={this.props.actions}
      />
    );
  }
}
