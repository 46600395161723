'use strict';

import React from 'react';
import BookingProductSummaryTemplate from './booking-product-summary.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class BookingProductSummaryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messager: {
        show: false,
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <BookingProductSummaryTemplate.main
          booking={this.props.booking}
          messager={this.state.messager}
          userTravelProfile={this.props.userTravelProfile}
          events={{ ...this.props.events, ...this.events }}
          justificationEvents={this.props.justificationEvents}
        />
      </ErrorBoundary>
    );
  }

  events = {
    onOpenMessagerClicked: (bookingId) => {
      this.setState({
        messager: { ...this.state.messager, show: true },
      });
    },
    onCloseMessagerClicked: () => {
      this.setState({
        messager: { ...this.state.messager, show: false },
      });
    },
  };
}
