import React from 'react';
import { IconButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';

const IconDuplicar = React.forwardRef((props, ref) => {
  return (
    <span {...props} ref={ref}>
      <IconButton {...props} className="exon-split-action" />
    </span>
  );
});

const IconExcluir = React.forwardRef((props, ref) => {
  return (
    <span {...props} ref={ref}>
      <IconButton {...props} />
    </span>
  );
});

const Template = {
  main: ({ canDuplicate, canDelete, duplicateLine, deleteLine, item }) => {
    return (
      <span className="exon-split-inline">
        <Tooltip title="Duplicar" placement="top">
          <IconDuplicar
            disabled={!canDuplicate}
            icon="IconCopy"
            size="small"
            variant="line"
            onClick={() => canDuplicate && duplicateLine(item)}
          />
        </Tooltip>
        <Tooltip title="Excluir" placement="top">
          <IconExcluir
            disabled={!canDelete}
            icon="IconTrash"
            size="small"
            variant="line"
            onClick={() => canDelete && deleteLine(item)}
          />
        </Tooltip>
      </span>
    );
  },
};

export default Template;
