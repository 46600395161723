'use strict';

import React from 'react';
import Template from './container-group.template.js';

export default class ContainerGroupComponent extends React.Component {
  render() {
    return (
      <Template
        children={this.props.children}
        aditionalClass={this.props.aditionalClass}
      />
    );
  }
}
