import { useEffect, useState } from 'react';
import { Modal } from '@flash-tecnologia/hros-web-ui-v2';
import { getApprovalAdvances } from '@api/expenseGateway/expenseGateway.service';
import { useLoading } from '../../../../../contexts/LoadingContext';

export function ModalReorderingList() {
  const { showLoading, hideLoading } = useLoading();
  const [approvalAdvances, setApprovalAdvances] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchApprovalAdvances = async () => {
      try {
        showLoading();
        const data = await getApprovalAdvances();
        setApprovalAdvances(data); // Armazena os dados no estado
      } catch (err) {
        setError('Erro ao carregar os fluxos de aprovação');
      } finally {
        hideLoading();
      }
    };
    fetchApprovalAdvances();
  }, []);

  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <div>
      <button onClick={toggleModal}>Exibir Fluxos de Aprovação</button>
      <Modal.Root open={isModalOpen} onClose={toggleModal} size={'md'}>
        <>
          <Modal.Header title={'Lista de Fluxos de Aprovação'} />
          <Modal.Content>
            {error ? (
              <div>{error}</div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Tipo</th>
                    <th>Descrição</th>
                    <th>Prioridade</th>
                    <th>Ativo</th>
                  </tr>
                </thead>
                <tbody>
                  {approvalAdvances?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.type}</td>
                      <td>{item.description}</td>
                      <td>{item.priority}</td>
                      <td>{item.active ? 'Sim' : 'Não'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Modal.Content>
          <Modal.Footer
            cancelText={'Fechar'}
            onCancel={toggleModal}
            onConfirm={() => console.log('OPa')}
            confirmText={'Criar requisição'}
          ></Modal.Footer>
        </>
      </Modal.Root>
    </div>
  );
}
