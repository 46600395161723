import { TypographyProps } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styled';
import React from 'react';

interface OptionalTypographyProps
  extends Omit<TypographyProps, 'variant' | 'children'> {
  variant?: TypographyProps['variant'];
  children?: TypographyProps['children'];
}

interface TitleSectionItemProps extends OptionalTypographyProps {
  title: string;
}

const TitleSectionItem: React.FC<TitleSectionItemProps> = (props) => {
  const { title, ...typographyProps } = props;
  return (
    <SC.TypographyContainer {...typographyProps} variant="body3" weight={700}>
      {title}
    </SC.TypographyContainer>
  );
};

export default TitleSectionItem;
