import React from 'react';

const Template = (props) => (
  <div className="exon-button-group btn-group">
    <button
      type="button"
      className={`btn ${props.buttonClassName}`}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="true"
      onClick={props.toggleShowItems}
    >
      {props.label}
      <span className="exon-icon exon-icon-expand-unfilled_disabled"></span>
    </button>
    <ul className={`dropdown-menu ${props.showItems ? "show" : ""}`}>
      {(props?.children || []).map((child, index) => {
        return React.cloneElement(child, {
          key: `exon-button-group_item-${index}`,
        });
      })}
    </ul>
  </div>
);

export default Template;
