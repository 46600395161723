import { enums } from './enums';
import { dispatchToast } from '@shared/toast';

export const utils = {
  money: class {
    constructor(value, currencyCode = enums.currencyCode.real) {
      this.currencyCode = currencyCode;
      this.currencySymbol = enums.currencySymbol[currencyCode];
      this._value = this.parseValue(value);
    }

    get value() {
      return this._value;
    }

    set value(newValue) {
      this._value = this.parseValue(newValue);
    }

    get formatted() {
      return utils.formatters.money.getFormatted(
        this._value || 0,
        this.currencyCode,
      );
    }

    parseValue(value) {
      return parseFloat(parseFloat(value).toFixed(2));
    }
  },
  floatingPointFix: function (value, places = 2) {
    return parseFloat((value + Number.EPSILON).toFixed(places));
  },
  formatters: {
    dateObjToString: function (date, format) {
      return utils.formatters.date.dateObjToString(date, format);
    },
    date: {
      dateObjToString: function (date, format) {
        switch (format) {
          case enums.dateFormat.dayMonthHourMinute:
            return (
              date.day.toString().padStart(2, '0') +
              '/' +
              date.month.toString().padStart(2, '0') +
              ' ' +
              date.hour.toString().padStart(2, '0') +
              ':' +
              date.minute.toString().padStart(2, '0')
            );
            break;

          case enums.dateFormat.monthYearHourMinute:
            return (
              date.day.toString().padStart(2, '0') +
              '/' +
              date.month.toString().padStart(2, '0') +
              '/' +
              date.year.toString().padStart(2, '0') +
              ' ' +
              date.hour.toString().padStart(2, '0') +
              ':' +
              date.minute.toString().padStart(2, '0')
            );
            break;

          default:
            return (
              date.day.toString().padStart(2, '0') +
              '/' +
              date.month.toString().padStart(2, '0') +
              '/' +
              date.year.toString().padStart(2, '0')
            );
        }
      },
      getFormattedDateObjFromDateObject: (dateObject) => {
        let date = utils.converters.date.dateObjectToDate(dateObject);
        return utils.formatters.date.getFormattedDateObjFromDate(date);
      },
      getFormattedDateObjFromDate: (date) => {
        return {
          //todo: remover
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
          hour: date.getHours(),
          minute: date.getMinutes(),
          dateFormatted: date.toLocaleDateString('pt-BR'),
          dayOfWeek: enums.daysOfWeek[date.getDay()],
          dateShortFormatted:
            date.getDate() + ' de ' + enums.months[date.getMonth() + 1],
          timeFormatted: date.toTimeString().substring(5, 0),
          dateTimeFormatted:
            date.toLocaleDateString('pt-BR') +
            ' ' +
            date.toTimeString().substring(5, 0),
        };
      },
      isDefaultDate: (date) => {
        return !date || (date.day == 1 && date.month == 1 && date.year == 1);
      },
    },
    time: {
      durationHoursMinute: (timeStamp) => {
        let hours = Math.floor(timeStamp / 60)
          .toString()
          .padStart(2, '0');
        let minutes = (timeStamp - hours * 60).toString().padStart(2, '0');

        if (hours >= 12) {
          if (hours == 12) {
            hours = hours;
            minutes = minutes;
          } else if (hours == 24) {
            minutes = '59';
          } else {
            minutes = minutes;
          }
        } else {
          hours = hours;
          minutes = minutes;
        }

        return hours + 'h ' + minutes + 'min';
      },
      durationHoursMinuteFromDateObj: (dateObj, separator) => {
        if (!separator) separator = ':';

        return `${dateObj.hour
          .toString()
          .padStart(2, '0')}${separator}${dateObj.minute
          .toString()
          .padStart(2, '0')}`;
      },
    },
    money: {
      getFormatted: (money, currencyCode) => {
        let location = currencyCode
          ? enums.currencyLocation[currencyCode]
          : enums.currencyLocation.BRL;
        return new Intl.NumberFormat(location, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
          .format(money)
          .toString();
      },
      getFormattedRounded: (money, currencyCode) => {
        let location = currencyCode
          ? enums.currencyLocation[currencyCode]
          : enums.currencyLocation.BRL;
        return new Intl.NumberFormat(location)
          .format(Math.round(money))
          .toString();
      },
    },
    formatMoney: function (money, withSymbol) {
      let localeOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      let prefix = `${money.currencyCode} `;

      if (withSymbol) {
        localeOptions.style = 'currency';
        localeOptions.currency = money.currencyCode;
        prefix = '';
      }

      return `${prefix}${money.value.toLocaleString(
        enums.currencyLocation[money.currencyCode],
        localeOptions,
      )}`;
    },
  },

  history: {
    getUserName: function (sessionUserName, isAutomaticFlowAction) {
      if (isAutomaticFlowAction === true) return 'Fluxo Automático';
      else return sessionUserName;
    },
  },

  document: {
    validateCpf: function (cpf) {
      let sum;
      let rest;
      sum = 0;
      if (cpf == '00000000000') return false;

      for (var i = 1; i <= 9; i++)
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      rest = (sum * 10) % 11;

      if (rest == 10 || rest == 11) rest = 0;
      if (rest != parseInt(cpf.substring(9, 10))) return false;

      sum = 0;
      for (i = 1; i <= 10; i++)
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      rest = (sum * 10) % 11;

      if (rest == 10 || rest == 11) rest = 0;
      if (rest != parseInt(cpf.substring(10, 11))) return false;
      return true;
    },
    validateCnpj: function (cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj == '') return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999'
      )
        return false;

      // Valida DVs
      tamanho = cnpj.length - 2;
      numeros = cnpj.substring(0, tamanho);
      digitos = cnpj.substring(tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },
    validateRg: function (rg) {
      return rg && (rg.length == 9 || rg.length == 10);
    },
    validateCnh: function (cnh) {
      let regex = /^[0-9]{12}$/;
      return regex.test(cnh);
    },
    validatePassport: function (passport) {
      let regex = /^[0-9a-z]{5,15}$/gi;
      return regex.test(passport);
    },
  },

  traveler: {
    travelersFormatted: function (travelers) {
      return (
        (travelers && travelers.adultCount > 0
          ? travelers.adultCount +
            (travelers && travelers.adultCount == 1 ? ' Adulto' : ' Adultos')
          : '') +
        (travelers && travelers.childCount > 0 && travelers.adultCount > 0
          ? ' e '
          : '') +
        (travelers && travelers.childCount > 0
          ? travelers.childCount +
            (travelers && travelers.childCount == 1 ? ' Criança' : ' Crianças')
          : '')
      );
    },
  },

  calculateDuration: function (begin, end) {
    let beginDate =
      typeof begin == 'object'
        ? utils.converters.date.dateObjectToDate(begin)
        : begin;
    let endDate =
      typeof end == 'object'
        ? utils.converters.date.dateObjectToDate(end)
        : end;

    return utils.formatters.time.durationHoursMinute(
      Math.abs(new Date(endDate) - new Date(beginDate)) / 60000,
    );
  },

  date: {
    dateIncrement: function (initialDate, increment, calendarItem) {
      let newDate = new Date(initialDate);
      switch (calendarItem) {
        case 'h':
          newDate.setHours(initialDate.getHours() + increment);
          break;
        case 'd':
          newDate.setDate(initialDate.getDate() + increment);
          break;
        case 'm':
          newDate.setMonth(initialDate.getMonth() + increment);
          break;
      }
      return newDate;
    },
    dayDiff: function (initialDate, finalDate) {
      let timeDiff = Math.abs(finalDate.getTime() - initialDate.getTime());
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    },
    hoursDiff: function (initialDate, finalDate) {
      let diff = finalDate - initialDate;
      return Math.ceil(diff / 1000 / 60 / 60);
    },

    strDateToDate: (strDate, separator) => {
      let [day, month, year] = strDate.split(separator);
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    },

    strDateTimeToObjectDate: (strDate, separator) => {
      let [date, time] = strDate.split(' ');
      time && time.length == 5 ? `${time}:00` : null;
      let [day, month, year] = date.split(separator);
      let [hour, minute, second] = time && time.split(':');

      return {
        year: year ? parseInt(year) : 0,
        month: month ? parseInt(month) : 0,
        day: day ? parseInt(day) : 0,
        hour: time && hour ? parseInt(hour) : 0,
        minute: time && minute ? parseInt(minute) : 0,
        second: time && second ? parseInt(second) : 0,
        millisecond: 0,
      };
    },

    getYearsOld: (date1, date2) => {
      return Math.floor((date1 - date2) / (1000 * 60 * 60 * 24 * 365));
    },
  },

  converters: {
    date: {
      /**@deprecated use utils.tsx instead */
      stringToDateObject: (dateString) => {
        let arrData = dateString.split('/');
        let date = new Date(arrData[2], arrData[1] - 1, arrData[0]);
        return {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
          hour: date.getHours(),
          minute: date.getMinutes(),
        };
      },
      /**@deprecated use utils.tsx instead */
      dateToDateObject: (date) => {
        let dtObj = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };

        let hour = date.getHours();
        let minute = date.getMinutes();

        if (hour) dtObj.hour = hour;
        if (minute) dtObj.minute = minute;

        return dtObj;
      },
      dateObjectToDate: (dateObject) => {
        return new Date(
          dateObject.year,
          dateObject.month - 1,
          dateObject.day,
          dateObject.hour ? dateObject.hour : 0,
          dateObject.minute ? dateObject.minute : 0,
          dateObject.second ? dateObject.second : 0,
        );
      },

      dateToString: (date) => {
        return (
          date.getDate().toString().padStart(2, '0') +
          '/' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '/' +
          date.getFullYear().toString().padStart(2, '0')
        );
      },
    },
    money: {
      getCurrencySymbol: (currencyCode) => {
        return currencyCode == enums.currencyCode.euro
          ? enums.currencySymbol.EUR
          : currencyCode == enums.currencyCode.dolar
          ? enums.currencySymbol.USD
          : enums.currencySymbol.BRL;
      },
    },
  },

  array: {
    equals: (arr1, arr2) => {
      // if the other array is a falsy value, return
      if (!arr1 || !arr2) return false;

      // compare lengths - can save a lot of time
      if (arr1.length != arr2.length) return false;

      for (let i = 0, l = arr1.length; i < l; i++) {
        //// Check if we have nested arrays
        //if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
        //    // recurse into the nested arrays
        //    if (!arr1[i].equals(arr2[i]))
        //        return false;
        //} else
        if (JSON.stringify(arr1[i]) != JSON.stringify(arr2[i])) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;
        }
      }
      return true;
    },
    distinct: (arr) => {
      return [...new Set(arr)];
    },
    chunk: (array, chunkSize) => {
      let partitionedArray = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        partitionedArray.push(chunk);
      }
      return partitionedArray;
    },
    groupBy: (items, key) => {
      return items.reduce((prev, curr) => {
        (prev[curr[key]] = prev[curr[key]] || []).push(curr);
        return prev;
      }, []);
    },
  },

  cookies: {
    setValue: (key, value, minutesToExpire) => {
      let now = new Date();
      let time = now.getTime();
      let expireTime = time + 1000 * minutesToExpire * 60;
      now.setTime(expireTime);
      document.cookie = `${key}=${value};expires=${now.toUTCString()};path=/`;
    },
    getValue: (key) => {
      let name = key + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          let value = c.substring(name.length, c.length);
          return value == 'null' ? null : value;
        }
      }

      return null;
    },
  },

  storage: {
    getValue: (key) => {
      const strStorageObj = localStorage.getItem(key);
      let storageObj = JSON.parse(strStorageObj);

      if (!storageObj) return null;
      else if (new Date(storageObj.expiresAt) < new Date()) {
        localStorage.removeItem(key);
        return null;
      } else {
        return storageObj.value;
      }
    },

    setValue: (key, value, minutesToExpire) => {
      let objToStore = utils.storage._getDefaultObject(value, minutesToExpire);
      const strObj = JSON.stringify(objToStore);
      localStorage.setItem(key, strObj);
    },

    _getDefaultObject: (value, minutesToExpire) => {
      let now = new Date();
      let time = now.getTime();
      let expireTime = time + 1000 * minutesToExpire * 60;
      now.setTime(expireTime);

      return {
        value: value,
        expiresAt: now.toUTCString(),
      };
    },
  },

  passwordValidatorRule: {
    isLetterOrDigit: function (value) {
      const regex = /\W|_/;
      return !regex.test(value);
    },
    isUpperCase: function (value) {
      const regex = /[A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/;
      return regex.test(value);
    },
    allowedLength: function (value) {
      return value.length >= 8 ? true : false;
    },
    validate: function (value) {
      let _this = utils.passwordValidatorRule;
      return {
        validLetterOrDigit: _this.isLetterOrDigit(value),
        validUpperCase: _this.isUpperCase(value),
        validLength: _this.allowedLength(value),
      };
    },
  },
};

export const gritter = {
  Success: (message) => {
    dispatchToast({
      type: 'success',
      content: message,
    });
  },
  Warning: (message) => {
    dispatchToast({
      type: 'warning',
      content: message,
    });
  },
  Error: (message) => {
    dispatchToast({
      type: 'error',
      content: message,
    });
  },
  Info: (message) => {
    //alert(`INFO: ${message}`);
    dispatchToast({
      type: 'warning',
      content: message,
      //description: "Tente novamente mais tarde.",
    });
  },
  ResponseError: (errors) => {
    var [error] = errors;
    dispatchToast({
      type: 'error',
      content: error.message,
      description: (
        <>
          <div style={{ marginTop: '10px' }}>{` ${error.detail}`}</div>
          <small
            style={{ display: 'block', marginTop: '10px', opacity: '0.7' }}
          >
            {`Código: ${error.code}`}
            <br />
            {`ID: ${error.correlationId}`}
          </small>
        </>
      ),
    });
  },
};
