import { SliderField } from '@flash-tecnologia/hros-web-ui-v2';

let MoneyRangeTemplate = ({
  values,
  formattedValues,
  min,
  max,
  step,
  events,
}) => {
  return (
    <div className="exon-range-time">
      {values && (
        <div className="exon-range-time-values">
          <span>Min: {formattedValues[0]}</span>
          <span>Máx: {formattedValues[1]}</span>
        </div>
      )}
      <SliderField
        value={values}
        step={step}
        min={min}
        max={max}
        type="range"
        valueLabelDisplay="off"
        valueLabelFormat={() => {
          return '';
        }}
        fieldMask={() => {}}
        onChange={events.onChange}
      />
    </div>
  );
};

export default MoneyRangeTemplate;
