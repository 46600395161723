import HotelSearchResultsFilter from '../hotel-search-result-filter/hotel-search-result-filter.component.js';
import Select from '../select/select.component.js';
import AirSearchEngine from '../air-search-engine/air-search-engine.component.js';
import HotelSearchEngine from '../hotel-search-engine/hotel-search-engine.component.js';
import AirSearchResult from '../air-search-result/air-search-result.component.js';
import HotelSearchResult from '../hotel-search-result/hotel-search-result.component.js';
import TabsGroup from '../tabs-group/tabs-group.component.js';
import Tab from '../tab/tab.component.js';
import TabPanel from '../tab-panel/tab-panel.component.js';
import AirSearchResultsFilter from '../air-search-result-filter/air-search-result-filter.component.js';
import Pagination from '../pagination/pagination.componente.js';
import ShoppingCart from '../shopping-cart/shopping-cart.component.js';
import Checkout from '../quotes/checkout/checkout.componente.js';
import Popup from '../popup/popup.component.js';
import ProductSummary from '../product-summary/product-summary.component.js';
import ProductList from '../products-travel/product-list/product-list.component.js';
import Timer from '../common/timer/timer.component.js';
import Feedback from '../common/feedback/feedback.component.js';
import HotelDetails from '../products-travel/hotel-product/hotel-details/hotel-details.component.js';
import { enums } from '../../utils/enums.js';
import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';

var QuotesTemplate = {
  main: ({
    searches,
    airSearchEngineProps,
    hotelSearchEngineProps,
    hotelsSearchResultsProps,
    tabsProps,
    engineSelected,
    activeTabIdentifier,
    showEngine,
    numberOfItems,

    checkoutProps,
    displayCheckout,
    displayConfirmSendproductsCheckout,
    productsToSendCheckout,
    allTariffedPriceProducts,
    displayPopupWarning,
    popupWarning,
    timeToExpirePreCheckout,

    onTripClicked,
    onShowEngineClicked,
    onSelectEngineClick,
    onAirSearchResultFilterApplyFiltersClicked,
    onHotelSearchResultFilterApplyFiltersClicked,
    onPageClick,
    onOrderAirSearchResultClick,
    onShowFaresIndexesClick,
    onClearTripMaganerItemClick,

    checkoutData,

    shoppingCart,
    budgetDataSource,
    onIncludeGroupShoppingCartClick,
    onIncludeProductShoppingCartClick,
    onDeleteProductShoppingCartClick,
    onDeleteGroupShoppingCartClick,
    onUpdateProductsShoppingCartClick,
    onSendBudgetShoppingCartClick,
    onSelectedGroupShoppingCartClick,
    onSelectedProductShoppingCartClick,
    onMoveProductShoppingCartClick,
    onSelectedMoveTripProductShoppingCartChange,
    onRenameGroupShoppingCartChange,
    onSelectedProductAirSearchResultClick,
    onHideMoveProductsShoppingCartClick,
    onMoveProductConfimrShoppingCartClick,
    onCloseCheckoutClick,
    onCancelSendCheckoutProductsClick,
    onConfirmSendCheckoutProductsClick,
    onSendSelectedProductCheckoutAirResultClick,
    onSendSelectedProductCheckoutHotelResultClick,
    onAllTripsSelected,
    onSelectedProductHotelSearchResultClick,
    onTryGetTariffedPriceClick,
    onRedoSearchClick,
    onDeleteTabAndSearchClick,
    onTryAgainTariffedPriceMultipleTripClick,
    onOpenHotelDetailsClick,
    onHotelSearchByIdClick,
    onHotelOptionClick,
    onSearchHotelDetailsClick,
    onFeedbackPopupCloseClick,
    searchExpire,
    displayPopupAgency,
    popupAgency,
    companiesProps,
    agencyAndCompany,
    popupFeedbackData,
    searchedHotelName,
    onShowFilterClicked,
    showFilter,
    navigate,
  }) => {
    let timerData = {
      totalSeconds: 600,
    };

    let productTypeActive = searches[activeTabIdentifier]
      ? searches[activeTabIdentifier].productType
      : null;

    return (
      <div className="travel container-main">
        <div className="wrapper">
          <div className="quotes-component">
            <div className="container-fluid">
              {displayPopupAgency && (
                <div className="exon-box-popup-agency">
                  <QuotesTemplate.popupAgency
                    popupAgency={popupAgency}
                    companiesProps={companiesProps}
                  />
                </div>
              )}

              <div className="box-center row-itens exon-quotes-content">
                <div className="config-box">
                  <button
                    className={
                      'to-recall-button to-recall-button-noshow' +
                      (showEngine ? ' to-recall-button-close' : '')
                    }
                    onClick={onShowEngineClicked}
                  ></button>
                  <div
                    className={
                      'config-box-itens' + (showEngine ? ' box-close' : '')
                    }
                  >
                    <div className="box">
                      <QuotesTemplate.engines
                        onSelectEngineClick={onSelectEngineClick}
                        engineSelected={engineSelected}
                        externalOptionsAir={airSearchEngineProps.options}
                        externalOptionsHotel={hotelSearchEngineProps.options}
                      />
                    </div>

                    {productTypeActive &&
                      (productTypeActive == enums.productType.hotel ||
                        productTypeActive == enums.productType.air) && (
                        <div
                          className={
                            'config-box-itens' +
                            (!showFilter ? ' box-close' : '')
                          }
                        >
                          <div className="box box-filters-quotes">
                            <button
                              className={
                                'to-recall-button exon-button-filter-noshow' +
                                (!showFilter ? ' to-recall-button-close' : '')
                              }
                              onClick={onShowFilterClicked}
                            ></button>
                            <QuotesTemplate.filters
                              searches={searches}
                              activeTabIdentifier={activeTabIdentifier}
                              applyFiltersAir={
                                onAirSearchResultFilterApplyFiltersClicked
                              }
                              applyFiltersHotel={
                                onHotelSearchResultFilterApplyFiltersClicked
                              }
                              searchedHotelName={searchedHotelName}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="box-result-search-engine">
                  <ShoppingCart
                    shoppingCart={shoppingCart}
                    budgetDataSource={budgetDataSource}
                    onIncludeGroupShoppingCartClick={
                      onIncludeGroupShoppingCartClick
                    }
                    onIncludeProductShoppingCartClick={
                      onIncludeProductShoppingCartClick
                    }
                    onDeleteProductShoppingCartClick={
                      onDeleteProductShoppingCartClick
                    }
                    onDeleteGroupShoppingCartClick={
                      onDeleteGroupShoppingCartClick
                    }
                    onUpdateProductsShoppingCartClick={
                      onUpdateProductsShoppingCartClick
                    }
                    onSendBudgetShoppingCartClick={
                      onSendBudgetShoppingCartClick
                    }
                    onSelectedGroupShoppingCartClick={
                      onSelectedGroupShoppingCartClick
                    }
                    onSelectedProductShoppingCartClick={
                      onSelectedProductShoppingCartClick
                    }
                    onMoveProductShoppingCartClick={
                      onMoveProductShoppingCartClick
                    }
                    onHideMoveProductsShoppingCartClick={
                      onHideMoveProductsShoppingCartClick
                    }
                    onSelectedMoveTripProductShoppingCartChange={
                      onSelectedMoveTripProductShoppingCartChange
                    }
                    onRenameGroupShoppingCartChange={
                      onRenameGroupShoppingCartChange
                    }
                    onMoveProductConfimrShoppingCartClick={
                      onMoveProductConfimrShoppingCartClick
                    }
                  />

                  <TabsGroup>
                    {Object.keys(searches).map((identifier) => {
                      let showTimer = !searches[identifier].loading;

                      return (
                        <Tab
                          key={identifier}
                          timerData={{
                            ...timerData,
                            showTimer,
                            actionEnd: searchExpire,
                          }}
                          tabIndex={identifier}
                          isExpired={
                            searches[identifier].displayActionsTabPanel
                          }
                          identifier={identifier}
                          externalOptions={tabsProps.options}
                          icon={searches[identifier].productType}
                          label={searches[identifier].label}
                          selectedTab={activeTabIdentifier}
                          showCloseButton={true}
                          loading={searches[identifier].loading}
                        />
                      );
                    })}
                  </TabsGroup>

                  <QuotesTemplate.results
                    searches={searches}
                    timerData={timerData}
                    hotelsSearchResultsProps={hotelsSearchResultsProps}
                    activeTabIdentifier={activeTabIdentifier}
                    numberOfItems={numberOfItems}
                    onOrderClick={onOrderAirSearchResultClick}
                    onShowFaresIndexesClick={onShowFaresIndexesClick}
                    onTripClicked={onTripClicked}
                    onPageClick={onPageClick}
                    onClearTripMaganerItemClick={onClearTripMaganerItemClick}
                    onIncludeInCartClicked={
                      onSelectedProductAirSearchResultClick
                    }
                    onIncludeHotelInCartClicked={
                      onSelectedProductHotelSearchResultClick
                    }
                    onSendSelectedProductCheckoutAirResultClick={
                      onSendSelectedProductCheckoutAirResultClick
                    }
                    onSendSelectedProductCheckoutHotelResultClick={
                      onSendSelectedProductCheckoutHotelResultClick
                    }
                    onAllTripsSelected={onAllTripsSelected}
                    onRedoSearchClick={onRedoSearchClick}
                    onDeleteTabAndSearchClick={onDeleteTabAndSearchClick}
                    onTryAgainTariffedPriceMultipleTripClick={
                      onTryAgainTariffedPriceMultipleTripClick
                    }
                    onOpenHotelDetailsClick={onOpenHotelDetailsClick}
                    onHotelSearchByIdClick={onHotelSearchByIdClick}
                    onSearchHotelDetailsClick={onSearchHotelDetailsClick}
                    onHotelOptionClick={onHotelOptionClick}
                  />
                </div>
              </div>

              {displayCheckout && (
                <QuotesTemplate.checkout
                  checkoutProps={checkoutProps}
                  onCloseClick={onCloseCheckoutClick}
                  checkoutPreBookingData={checkoutData.preBookingData}
                  allowedPaymentTypes={checkoutData.allowedPaymentTypes}
                  agencyAndCompany={agencyAndCompany}
                  navigate={navigate}
                />
              )}

              {displayConfirmSendproductsCheckout && (
                <QuotesTemplate.popupConfirmarProdutosCheckout
                  checkoutProductsData={checkoutData.productsData}
                  checkoutPreBookingData={checkoutData.preBookingData}
                  checkoutAllowedPaymentTypes={checkoutData.allowedPaymentTypes}
                  timeToExpirePreCheckout={timeToExpirePreCheckout}
                  dataSources={hotelsSearchResultsProps.options.dataSources}
                  allTariffedPriceProducts={allTariffedPriceProducts}
                  productsToSendCheckout={productsToSendCheckout}
                  onCancelSendCheckoutProductsClick={
                    onCancelSendCheckoutProductsClick
                  }
                  onConfirmSendCheckoutProductsClick={
                    onConfirmSendCheckoutProductsClick
                  }
                  onTryGetTariffedPriceClick={onTryGetTariffedPriceClick}
                />
              )}

              {displayPopupWarning && (
                <div className="exon-box-popup-warning">
                  <QuotesTemplate.popupWarning popupWarning={popupWarning} />
                </div>
              )}

              {popupFeedbackData.show && (
                <div className="exon-box-popup-policy-violation">
                  <Popup
                    exonStyle={true}
                    cancelButtonClick={onFeedbackPopupCloseClick}
                  >
                    <Feedback
                      description={popupFeedbackData.description}
                      details={popupFeedbackData.details}
                      feedbackType={popupFeedbackData.type}
                    />
                  </Popup>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },

  engines: ({
    onSelectEngineClick,
    engineSelected,
    externalOptionsAir,
    externalOptionsHotel,
  }) => {
    return (
      <div className="engine-container">
        <p className="exon-title-engine">Pesquisa</p>
        <div className="engine">
          <div className="content-tabs"></div>
          <input
            type="radio"
            id="airSearchEngine"
            name="search"
            onChange={() => onSelectEngineClick('air')}
            checked={engineSelected == 'air'}
          />
          <label htmlFor="airSearchEngine" className="tabs_item air">
            <img src={require('../../assets/icons/icon-air.svg')} />
            <span>Aéreo</span>
          </label>
          <input
            type="radio"
            id="hotelSearchEngine"
            onChange={() => onSelectEngineClick('hotel')}
            checked={engineSelected == 'hotel'}
            name="search"
          />
          <label htmlFor="hotelSearchEngine" className="tabs_item hotel">
            <img src={require('../../assets/icons/icon-hotel.svg')} />
            <span>Hotel</span>
          </label>
          <div className={engineSelected == 'air' ? '' : 'hide'}>
            <AirSearchEngine externalOptions={externalOptionsAir} />
          </div>
          <div className={engineSelected == 'hotel' ? '' : 'hide'}>
            <HotelSearchEngine externalOptions={externalOptionsHotel} />
          </div>
        </div>
      </div>
    );
  },

  filters: ({
    searches,
    activeTabIdentifier,
    applyFiltersAir,
    applyFiltersHotel,
    searchedHotelName,
  }) => {
    return Object.keys(searches).map((identifier) => {
      return (
        <div key={identifier}>
          <p className="exon-title-filters">Filtros</p>
          {searches[identifier].loading &&
            identifier === activeTabIdentifier && (
              <div className="exon-box-common-loading">
                <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
                <p>
                  Por favor aguarde!
                  <br />
                  Carregando as opções do filtro ...
                </p>
              </div>
            )}
          <TabPanel
            identifier={identifier}
            displayActionsTabPanel={searches[identifier].displayActionsTabPanel}
            activeTabIdentifier={activeTabIdentifier}
            loading={searches[identifier].loading}
            loaderType={enums.productType.air}
          >
            <div className="filter-container">
              {searches[identifier].productType == 'air' &&
                searches[identifier].results.length && (
                  <AirSearchResultsFilter
                    identifier={identifier}
                    originalResult={searches[identifier].originalResults}
                    indexTripPart={searches[identifier].tripPartSelectedIndex}
                    identifierName={searches[identifier].label}
                    applyFilters={applyFiltersAir}
                  />
                )}
              {searches[identifier].productType == 'hotel' &&
                searches[identifier].originalResults &&
                searches[identifier].originalResults.resultResume && (
                  <HotelSearchResultsFilter
                    identifier={identifier}
                    searchedHotelName={searchedHotelName}
                    originalResult={searches[identifier].originalResults}
                    identifierName={searches[identifier].label}
                    applyFilters={applyFiltersHotel}
                    geoLocation={
                      searches[identifier]?.searchParameters
                        ?.searchViewParameters?.filter?.geoLocation
                    }
                  />
                )}
            </div>
          </TabPanel>
        </div>
      );
    });
  },

  results: ({
    searches,
    timerData,
    activeTabIdentifier,
    numberOfItems,
    hotelsSearchResultsProps,

    onOrderClick,
    onShowFaresIndexesClick,
    onClearTripMaganerItemClick,
    onTripClicked,
    onPageClick,
    onIncludeInCartClicked,
    onIncludeHotelInCartClicked,
    onSendSelectedProductCheckoutAirResultClick,
    onSendSelectedProductCheckoutHotelResultClick,
    onAllTripsSelected,
    onRedoSearchClick,
    onDeleteTabAndSearchClick,
    onTryAgainTariffedPriceMultipleTripClick,
    onOpenHotelDetailsClick,
    onHotelSearchByIdClick,
    onSearchHotelDetailsClick,
    onHotelOptionClick,
  }) => {
    return (
      <div className="box">
        <div className="result-container">
          {Object.keys(searches).map((identifier) => {
            let productAir = searches[identifier].productType == 'air';
            let productHotel = searches[identifier].productType == 'hotel';
            let paginationParameters =
              searches[identifier].paginationParameters;
            let tripPartSelectedIndex = productAir
              ? searches[identifier].tripPartSelectedIndex
              : 0;
            let currentPage =
              paginationParameters && paginationParameters.length > 0
                ? paginationParameters[tripPartSelectedIndex].currentPage
                : 1;
            let itemsQuantity =
              paginationParameters && paginationParameters.length > 0
                ? paginationParameters[tripPartSelectedIndex].itemsQuantity
                : 0;
            let itemsPerPage =
              paginationParameters && paginationParameters.length > 0
                ? paginationParameters[tripPartSelectedIndex].itemsPerPage
                : 0;
            let showFaresIndexes = searches[identifier].showFaresIndexes;

            return (
              <TabPanel
                key={identifier}
                identifier={identifier}
                activeTabIdentifier={activeTabIdentifier}
                loading={searches[identifier].loading}
                displayActionsTabPanel={
                  searches[identifier].displayActionsTabPanel
                }
                loaderType={
                  productHotel
                    ? enums.productType.hotel
                    : productAir
                      ? enums.productType.air
                      : null
                }
                actions={
                  <div className="exon-tab-panel-search-options">
                    <div>
                      <span className="exon-icon exon-icon-expired-time-unfilled_disabled"></span>
                      <p>
                        Sessão Expirada para esses resultados!
                        <br />
                        Para continuar a sua busca por disponibilidades renove a
                        pesquisa.
                      </p>
                      <div className="exon-box-buttons-group">
                        <button
                          className="exon-button exon-button-secondary"
                          onClick={onDeleteTabAndSearchClick}
                        >
                          Fechar Resultados Expirados
                        </button>

                        {searches[identifier].productType !=
                          enums.quotationSteps.hotelDetails && (
                          <button
                            className="exon-button exon-button-primary"
                            onClick={onRedoSearchClick}
                          >
                            Renovar Pesquisa
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                }
              >
                {!searches[identifier].loading && (
                  <Timer
                    {...timerData}
                    showProgressBar={false}
                    countdown={true}
                    showLabel={true}
                    description={
                      'A exibição dos resultados da pesquisa expira em'
                    }
                  />
                )}

                {!searches[identifier].loading &&
                  searches[identifier].productType ==
                    enums.quotationSteps.hotelDetails &&
                  searches[identifier].result.hotel && (
                    <HotelDetails
                      hotelSelectedSearch={
                        searches[identifier].hotelSelectedSearch
                      }
                      hotel={searches[identifier].hotel}
                      loadingRooms={searches[identifier].loadingRooms}
                      relatedSeachResults={searches[identifier].cheapestHotels}
                      identifierSearch={searches[identifier].identifierSearch}
                      identifierHotelDetails={identifier}
                      searchParameters={searches[identifier].searchParameters}
                      originalSearchParameters={
                        searches[identifier].originalSearchParameters
                      }
                      onSearchHotelDetailsClick={onSearchHotelDetailsClick}
                      onIncludeInCartClick={(includeItem, guestsCounter) =>
                        onIncludeHotelInCartClicked(includeItem, guestsCounter)
                      }
                      onSendSelectedProductCheckoutHotelResultClick={(
                        includeItem,
                        guestsCounter,
                      ) =>
                        onSendSelectedProductCheckoutHotelResultClick(
                          includeItem,
                          guestsCounter,
                          identifier,
                        )
                      }
                      onHotelIdClick={(searchParameters) =>
                        onHotelSearchByIdClick(identifier, searchParameters)
                      }
                      onHotelOptionClick={(hotelIndex) =>
                        onHotelOptionClick(identifier, hotelIndex)
                      }
                    />
                  )}

                {productHotel && !searches[identifier].loading && (
                  <HotelSearchResult
                    hotels={searches[identifier].results}
                    tabIdentifier={identifier}
                    itemsQuantity={itemsQuantity}
                    searchParameters={searches[identifier].searchParameters}
                    externalOptions={hotelsSearchResultsProps.options}
                    onIncludeInCartClicked={onIncludeHotelInCartClicked}
                    onSendSelectedProductCheckoutHotelResultClick={
                      onSendSelectedProductCheckoutHotelResultClick
                    }
                    onOpenHotelDetailsClick={onOpenHotelDetailsClick}
                    onRedoSearchClick={onRedoSearchClick}
                    onDeleteTabAndSearchClick={onDeleteTabAndSearchClick}
                  />
                )}

                {productAir && (
                  <AirSearchResult
                    searchIdentifier={identifier}
                    searchTripType={
                      searches[identifier].searchParameters.tripType
                    }
                    clearTripsSelected={searches[identifier].clearTripsSelected}
                    apiReturn={searches[identifier].apiReturnError}
                    numberOfItems={numberOfItems}
                    separeResults={
                      searches[identifier].searchParameters.separeResults
                    }
                    showFaresIndexes={showFaresIndexes}
                    tripParts={searches[identifier].results}
                    tariffedPriceFareResult={
                      searches[identifier].tariffedPriceFareResult
                    }
                    tripIndeSelected={
                      searches[identifier].tripPartSelectedIndex
                    }
                    displayLoaderTariffedPrice={
                      searches[identifier].displayLoaderTariffedPrice
                    }
                    onTripClicked={onTripClicked}
                    onOrderClick={onOrderClick}
                    onClearTripMaganerItemClick={onClearTripMaganerItemClick}
                    onShowFaresIndexesClick={onShowFaresIndexesClick}
                    onIncludeInCartClicked={onIncludeInCartClicked}
                    onSendSelectedProductCheckoutAirResultClick={
                      onSendSelectedProductCheckoutAirResultClick
                    }
                    onAllTripsSelect={onAllTripsSelected}
                    onTryAgainTariffedPriceMultipleTripClick={
                      onTryAgainTariffedPriceMultipleTripClick
                    }
                  />
                )}

                <Pagination
                  itemsQuantity={itemsQuantity}
                  itemsPerPage={itemsPerPage}
                  onPageClick={onPageClick}
                  currentPage={currentPage}
                />
              </TabPanel>
            );
          })}
        </div>
      </div>
    );
  },

  popupConfirmarProdutosCheckout: ({
    checkoutProductsData,
    checkoutPreBookingData,
    timeToExpirePreCheckout,
    productsToSendCheckout,
    dataSources,

    onCancelSendCheckoutProductsClick,
    onConfirmSendCheckoutProductsClick,
    onTryGetTariffedPriceClick,
  }) => {
    let totalValue = 0;
    let okButtonLabel = 'Aguarde...';
    let disabledOkButton = false;
    let okButtonClick = onConfirmSendCheckoutProductsClick;

    if (checkoutProductsData.allTariffedPrice) {
      totalValue = checkoutProductsData.allTariffedPrice.total
        ? checkoutProductsData.allTariffedPrice.total.value
        : 0;
      okButtonLabel =
        checkoutProductsData.allTariffedPrice.total &&
        totalValue != 0 &&
        !checkoutProductsData.tariffedPriceExpired
          ? 'Confirmar'
          : (checkoutProductsData.allTariffedPrice.total && totalValue == 0) ||
              checkoutProductsData.tariffedPriceExpired
            ? 'Tarifar novamente'
            : 'Aguarde...';
      disabledOkButton =
        checkoutProductsData.allTariffedPrice.total && totalValue != 0
          ? true
          : checkoutProductsData.allTariffedPrice.total && totalValue == 0
            ? true
            : false;
      okButtonClick =
        (checkoutProductsData.allTariffedPrice.total && totalValue == 0) ||
        checkoutProductsData.tariffedPriceExpired
          ? onTryGetTariffedPriceClick
          : onConfirmSendCheckoutProductsClick;
    }

    let titlePopup = (
      <span>
        Confira os preços atualizados
        <span
          className={
            'exon-time-to-expire-pre-chechout ' +
            (checkoutProductsData.allTariffedPrice &&
            checkoutProductsData.allTariffedPrice.total &&
            checkoutProductsData.allTariffedPrice.total.value != 0 &&
            !checkoutProductsData.displayLoaderTariffedPrice &&
            timeToExpirePreCheckout != '00:00'
              ? ''
              : 'hide')
          }
        >
          (<span>expira em:</span>
          {timeToExpirePreCheckout})
        </span>
      </span>
    );

    return (
      <div className="exon-box-confirm-shipping-checkout">
        <Popup
          okButtonLabel={okButtonLabel}
          cancelButtonLabel={'Cancelar'}
          title={titlePopup}
          exonStyle={true}
          cancelButtonClick={onCancelSendCheckoutProductsClick}
          okButtonClick={okButtonClick}
          disabledOkButton={!disabledOkButton}
        >
          <div className="exon-box-products">
            {productsToSendCheckout.map((products) => {
              let travels = products.travels.map((productTravel, index) => {
                return (
                  <ProductSummary
                    key={index}
                    productType={enums.productType.air}
                    product={productTravel}
                    enableProductCheckbox={false}
                    productActions={null}
                  />
                );
              });
              let hotels = products.hotels.map((productTravel, index) => {
                return (
                  <ProductSummary
                    key={index}
                    productType={enums.productType.hotel}
                    product={productTravel}
                    enableProductCheckbox={false}
                    productActions={null}
                    allowedPaymentTypes={checkoutAllowedPaymentTypes}
                  />
                );
              });
              return travels + hotels;
            })}

            <ProductList
              products={checkoutProductsData}
              preBooking={checkoutPreBookingData}
              dataSources={dataSources}
              tariffedPriceFare={null}
              airActions={null}
              hotelActions={null}
              displayCheckbox={false}
              displayFareDatails={false}
              displaytariffedPriceFare={null}
            />
          </div>
        </Popup>
      </div>
    );
  },

  checkout: ({
    checkoutProps,
    checkoutPreBookingData,
    allowedPaymentTypes,
    onCloseClick,
    agencyAndCompany,
    navigate,
  }) => {
    return (
      <div className="exon-box-popop-checkout">
        <Popup
          okButtonLabel={null}
          cancelButtonLabel={null}
          title={'Checkout'}
          cancelButtonClick={onCloseClick}
          exonStyle={true}
          okButtonClick={null}
        >
          <Checkout
            dataSources={checkoutProps.dataSources}
            checkoutPreBookingData={checkoutPreBookingData}
            allowedPaymentTypes={allowedPaymentTypes}
            data={checkoutProps.data}
            user={checkoutProps.user}
            onCloseCheckoutClick={onCloseClick}
            agencyAndCompany={agencyAndCompany}
            navigate={navigate}
          />
        </Popup>
      </div>
    );
  },

  popupWarning: ({ popupWarning }) => {
    return (
      <Popup
        okButtonLabel={popupWarning.okButtonLabel}
        cancelButtonLabel={popupWarning.cancelButtonLabel}
        title={popupWarning.title}
        cancelButtonClick={popupWarning.cancelButtonClick}
        exonStyle={true}
        okButtonClick={popupWarning.okButtonClick}
      >
        <div className="exon-box-alert-popup">
          {popupWarning.messages.map((msg, index) => {
            return <p key={index}> {msg} </p>;
          })}
        </div>
      </Popup>
    );
  },

  popupAgency: ({ popupAgency, companiesProps }) => {
    return (
      <div>
        <Popup
          title={popupAgency.title}
          okButtonClick={popupAgency.okButtonClick}
          okButtonLabel={popupAgency.okButtonLabel}
          cancelButtonLabel={popupAgency.cancelButtonLabel}
          cancelButtonClick={popupAgency.cancelButtonClick}
          exonStyle={true}
        >
          <div className="exon-box-popup-agency-content">
            <label className="">Cliente</label>
            <Select
              data={companiesProps.data}
              externalOptions={companiesProps.options}
              placeholder={companiesProps.placeholder}
              required={true}
              showSearch={true}
            ></Select>
          </div>
        </Popup>
      </div>
    );
  },
};

export default QuotesTemplate;
