'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import { utils } from '../../utils/utils.js';
import { DatePicker } from '@flash-tecnologia/hros-web-ui-v2';

export default class DateComponent extends React.Component {
  selected = null;

  externalOptions = {
    events: {
      onSelected: null,
    },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      dataControlledByParent: props.data !== undefined,
      validationResult: {
        isValid: true,
      },
    };
  }

  render() {
    return (
      <DatePicker
        placeholder={this.props.placeholder}
        value={DateComponent.convertDateObjectToDate(this.props.data)}
        fromDate={this.props.startDate}
        onDateChange={(e) => this.onDateChanged(new Date(e))}
      />
    );
  }

  setData = function (data) {
    let isValid = this.validateDate(data);
    this.setState({
      //data: data,
      validationResult: { isValid },
    });
  };

  static stringToObject = function (date) {
    let objectDate = null;

    if (date) {
      let [day, month, year] = date.split('/');
      objectDate = {
        day: day || '',
        month: month || '',
        year: year || '',
      };
    }

    return objectDate;
  };

  static objectToString = function (objectDate) {
    let arrayDate = [objectDate.day, objectDate.month, objectDate.year].join(
      '-',
    );
    return arrayDate;
  };

  static convertDateObjectToDate = function (dateObject) {
    if (!dateObject || dateObject.getFullYear) return dateObject;
    return new Date(dateObject.year, dateObject.month - 1, dateObject.day);
  };

  static convertDateToDateObject = function (date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  };

  onDateChanged = (e) => this.events.dateChanged(e);

  events = {
    dateChanged(e) {
      let dateObj = e
        ? utils.converters.date.dateToDateObject(new Date(e))
        : null;

      this.setData(dateObj);

      if (this.externalOptions.events.onSelected)
        this.externalOptions.events.onSelected(dateObj);
    },
  };

  validateDate(date) {
    let isValid = false;
    if (
      date &&
      date.day &&
      date.month &&
      date.year &&
      date.year.toString().length >= 2
    )
      isValid = true;

    return isValid;
  }
}
