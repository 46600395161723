let PopupFilterTemplate = ({
  children,
  title,
  onCloseFilterClick,
  onAppliedFilterClick,
}) => {
  return (
    <div className="exon-pupup-filter">
      <div
        className="exon-outside-options-background-mask"
        onClick={() => onCloseFilterClick()}
      ></div>
      <div className="exon-pupup-filter__box-popup">
        <div className="exon-pupup-filter__header">
          <label>{title}</label>
          <span
            className="exon-button-close"
            onClick={() => onCloseFilterClick()}
          ></span>
        </div>
        <div className="exon-pupup-filter__body">{children}</div>
        <div className="exon-pupup-filter__footer">
          <button
            className="exon-button"
            onClick={() => onAppliedFilterClick()}
          >
            Concluir
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupFilterTemplate;
