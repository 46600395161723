import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from '../styled';

interface ModalIconHeaderProps {
  /**
   * Icon header
   *
   */
  icon: IconTypes;
  /**
   * Icon Label header
   *
   */
  label: string;
  color?: string;
}
export function ModalIconHeader({ icon, label, color }: ModalIconHeaderProps) {
  return (
    <>
      <SC.IconHeaderContainer>
        <SC.HeaderIcon name={icon} size={64} fill="transparent" color={color} />
      </SC.IconHeaderContainer>
      <SC.IconLabel>{label}</SC.IconLabel>
    </>
  );
}
