import InputRadio from '../../../../common/input-radio/input-radio.component.js';
import Container from '../../../../common/container/container.component.js';
import Icon from '../../../../common/icon/icon.component.js';
import Link from '../../../../common/link/link.component.js';
import Description from '../../hotel-description/hotel-description.component.js';
import Popup from '../../../../popup/popup.component.js';
import HotelCancellationPolicies from '../../hotel-cancellation-policies/hotel-cancellation-policies.component.js';
import Amenities from '../room-amenities/room-amenities.component.js';
import { utils } from '../../../../../utils/utils.js';
import { travelEnums } from '../../../../../utils/enums.js';

let Template = ({ props, args, events }) => {
  let getMoneyFormatted = utils.formatters.money.getFormatted;
  let getCurrencyCodeFormatted = utils.converters.money.getCurrencySymbol;
  let hasAdults = props.room.capacity && props.room.capacity.adults;
  let hasChildren = props.room.capacity && props.room.capacity.children;
  let capacityMax = props.room.capacity && props.room.capacity.max;

  let roomValue = props.room.fares[0].roomValue;
  let fareInformation = props.room.fares[0].fareInformation;
  let dailyFare = roomValue.dailyFare;
  let markupedDailyFare = roomValue.markupedDailyFare;
  let originalDailyFare = roomValue.originalDailyFare;
  let dailyTax = roomValue.dailyTax;
  let originalDailyTax = roomValue.originalDailyTax;

  let currencyCode =
    dailyFare.currencyCode ||
    markupedDailyFare.currencyCode ||
    dailyTax.currencyCode ||
    originalDailyFare.currencyCode ||
    originalDailyTax.currencyCode;
  let total =
    (markupedDailyFare.value
      ? markupedDailyFare.value
      : originalDailyFare.value) +
    (dailyTax.value ? dailyTax.value : originalDailyTax.value);

  let cancellationPolicies = props.room.cancellationPolicies;
  let filterItens = props.room.filterItens;
  let isHotelOffline =
    props.room.sourceIdentifier === travelEnums.hotelSources.offline;

  return (
    <div
      className={`exon-room-card ${
        props.room.id == props.roomIdSelected ? 'exon-room-card-active' : ''
      }`}
    >
      <Container>
        <div className="exon-room-card-box">
          <div className="exon-room-card-base-infos">
            <div className="exon-room-card-select-identifier">
              <InputRadio
                name={props.identifierGroup}
                selected={props.room.id == props.roomIdSelected}
                value={(props.room && props.room.id) || props.room.index}
                onChange={(e) => props.onSelectRoomClick(props.room.id)}
              />
            </div>
            <div className="exon-room-card-informations-about">
              <h5 className="exon-room-card-name">
                {(props.room && props.room.name) || props.room.title}
              </h5>
              <div className="exon-room-card-amenities">
                {fareInformation && (
                  <Icon
                    icon={
                      fareInformation.board &&
                      (fareInformation.board.description ||
                        fareInformation.board.name)
                        ? 'exon-icon-coffee-unfilled_enabled'
                        : 'exon-icon-coffee-unfilled_disabled'
                    }
                    description={
                      fareInformation.board
                        ? fareInformation.board.description ||
                          fareInformation.board.name
                        : 'Sem refeição'
                    }
                  />
                )}

                {props.room.cancellationPolicies && (
                  <Icon
                    icon={`exon-icon-cashback-unfilled_enabled${
                      cancellationPolicies &&
                      cancellationPolicies[0].nonRefundable
                        ? '-gray'
                        : ''
                    }`}
                    description={`${
                      cancellationPolicies[0].nonRefundable ? 'Não ' : ''
                    }Reembolsável`}
                  />
                )}
                {props.showFilterRooms &&
                  filterItens &&
                  filterItens.bedTypes && (
                    <Icon
                      icon={
                        filterItens.bedTypes && filterItens.bedTypes.length > 0
                          ? filterItens.bedTypes[0] == 'Não informado'
                            ? 'exon-icon-bed-unfilled_disabled'
                            : 'exon-icon-bed-unfilled_enabled'
                          : 'exon-icon-bed-unfilled_disabled'
                      }
                      description={
                        filterItens.bedTypes && filterItens.bedTypes.length > 0
                          ? filterItens.bedTypes.join(' - ')
                          : 'Não informado'
                      }
                    />
                  )}

                {(hasAdults || capacityMax) && props.showFilterRooms && (
                  <p className="exon-room-card-amenities-travelers">
                    {`${
                      hasAdults && hasAdults > 0
                        ? hasAdults
                        : capacityMax && capacityMax > 0
                        ? capacityMax
                        : ''
                    } ${
                      (hasAdults != 1 && hasAdults > 0) ||
                      (capacityMax != 1 && capacityMax > 0)
                        ? 'adultos'
                        : 'adulto'
                    } ${
                      hasChildren && hasChildren > 0 && hasChildren ? 'e' : ''
                    } ${hasChildren && hasChildren > 0 ? hasChildren : ''}  ${
                      hasChildren && hasChildren > 0
                        ? hasChildren != 1
                          ? 'crianças'
                          : 'criança'
                        : ''
                    }`}
                  </p>
                )}

                {args.unsubscribeShow && (
                  <Popup
                    exonStyle={true}
                    title={`Cancelamento: ${props.room.name}`}
                    cancelButtonClick={(e) => {
                      e.stopPropagation();
                      return events.onUnsubscribeClick();
                    }}
                  >
                    <HotelCancellationPolicies
                      cancellationPolicies={props.room.cancellationPolicies}
                      cancelDeadline={props.room.cancelDeadline}
                    />
                  </Popup>
                )}
              </div>
            </div>
          </div>
          <div className="exon-room-card-box-infos">
            <div className="exon-room-card-description">
              <Description
                description={props.room.description}
                title={`Descrição do quarto: ${props.room.name}`}
              />
            </div>
            <div className="exon-room-card-amenities-infos">
              <h6>Comodidades</h6>

              {isHotelOffline && <Amenities amenities={props.room.amenities} />}

              {props.room.amenities &&
                !props.room.amenities.length > 0 &&
                props.onDetailsHotelClick &&
                !isHotelOffline && (
                  <div className="exon-link-hotel-details-box">
                    <Link
                      className="exon-link-hotel-details"
                      description="Ver detalhes do hotel"
                      onClick={props.onDetailsHotelClick}
                    />
                    <Icon icon="exon-icon-link-unfilled_disabled" />
                  </div>
                )}

              <Link
                className="exon-link-unsubscribe"
                description="Politica de Cancelamento"
                onClick={() => events.onUnsubscribeClick()}
              />
            </div>
          </div>
          <div className="exon-room-card-price">
            <p className="exon-room-card-price-day">
              {`${getCurrencyCodeFormatted(currencyCode)} ${getMoneyFormatted(
                markupedDailyFare.value,
                currencyCode,
              )}`}
            </p>
            <div className="exon-room-card-price-total-box">
              <p className="exon-room-card-price-description">
                {`Total ${fareInformation.days} ${
                  fareInformation.days != 1 ? 'diárias' : 'diária'
                }`}
              </p>
              <p className="exon-room-card-price-total">
                {`${getCurrencyCodeFormatted(currencyCode)} ${getMoneyFormatted(
                  total * fareInformation.days,
                  currencyCode,
                )}`}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Template;
