import { utils } from '@legacy-utils/utils';

let ExternalBookingLocatorTemplate = ({
  externalLocator,
  canIssue,
  onIssueButtonClick,
}) => {
  return (
    <div className="exon-external-booking-locator">
      <div className="box-styleless-table">
        <table className="exon-styleless-table">
          <thead>
            <tr>
              <th>Localizador</th>
              <th>Data de Expiração</th>
              <th>Sistema</th>
              <th>Companhia Emissora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {externalLocator.locator}
                {externalLocator.bookingStatus
                  ? ` (${externalLocator.bookingStatus})`
                  : ''}
              </td>
              <td>
                {
                  utils.formatters.date.getFormattedDateObjFromDateObject(
                    externalLocator.expirationDate,
                  ).dateFormatted
                }
              </td>
              <td>{externalLocator.system}</td>
              <td>{externalLocator.issuingCompany}</td>
              <td>
                {canIssue && (
                  <button
                    className="exon-button exon-button-primary small"
                    type="button"
                    onClick={onIssueButtonClick}
                  >
                    Emitir Bilhete
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExternalBookingLocatorTemplate;
