import AirProducts from '../air-product/air-product.component.js';
import HotelProducts from '../hotel-product/hotel-product.component.js';
import ProductSummaryTariffed from '../product-summary-tariffed/product-summary-tariffed.component.js';
import ProductFare from '../product-fare/product-fare.component.js';
import { enums } from '@legacy-utils/enums';

var ProductListTemplate = {
  main: ({
    products,
    preBooking,
    dataSources,
    tariffedPriceFare,

    airActions,
    hotelActions,
    onDeleteProductClick,

    displayCheckbox,
    displaytariffedPriceFare,
    displayFareDatails,

    onSelectedMoveTripProductChange,
  }) => {
    return (
      <div>
        <ProductListTemplate.products
          products={products}
          preBooking={preBooking}
          dataSources={dataSources}
          tariffedPriceFare={tariffedPriceFare}
          airActions={airActions}
          hotelActions={hotelActions}
          onDeleteProductClick={onDeleteProductClick}
          displayFareDatails={displayFareDatails}
          displayCheckbox={displayCheckbox}
          onSelectedMoveTripProductChange={onSelectedMoveTripProductChange}
        />
      </div>
    );
  },

  products: ({
    products,
    preBooking,
    dataSources,
    tariffedPriceFare,

    airActions,
    hotelActions,
    onDeleteProductClick,

    displayFareDatails,
    displayCheckbox,

    onSelectedMoveTripProductChange,
  }) => {
    let airProduct = products.trips && products.trips.length > 0;
    let hotelProduct =
      products.hotels &&
      products.hotels.selectedHotels &&
      products.hotels.selectedHotels.length > 0;

    return (
      <div className="exon-products-item">
        <div className="exon-products-item-list">
          {airProduct &&
            products.trips.map((product, index) => {
              return (
                <div key={'air-' + index} className="exon-box-product-item">
                  <AirProducts
                    travels={product}
                    travelIndex={product.index}
                    tariffedPriceFare={tariffedPriceFare}
                    onDeleteProductClick={onDeleteProductClick}
                    actions={airActions}
                    displayFareDetails={false}
                    displayCheckbox={displayCheckbox}
                    onSelectedMoveTripProductChange={
                      onSelectedMoveTripProductChange
                    }
                  />

                  {displayFareDatails && (
                    <ProductFare
                      productFare={product.fares.fareOptions}
                      productType={enums.productType.air}
                      actions={null}
                    />
                  )}
                </div>
              );
            })}
          {hotelProduct &&
            products.hotels.selectedHotels.map((product, index) => {
              return (
                <div key={'hotel-' + index} className="exon-box-product-item">
                  <div className="exon-product-summary">
                    <div className="exon-product-details">
                      <HotelProducts
                        showOpenCancelationPolicyButton={true}
                        product={product}
                        preBooking={preBooking}
                        dataSources={dataSources}
                        hotelIndex={product.index}
                        onDeleteProductClick={onDeleteProductClick}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {!displayFareDatails && (
          <ProductSummaryTariffed
            allTariffedPrice={products.allTariffedPrice}
            tariffedPriceExpired={products.tariffedPriceExpired}
            searchParameters={products.searchParameters}
          />
        )}
      </div>
    );
  },
};

export default ProductListTemplate;
