import { TextAlign } from '@themes/types/Variants';
import * as SC from '../styled';

interface ModalHeaderSubtitleProps {
  /**
   * Subtitle header
   *
   */
  children: React.ReactNode;
  /**
   * Header text alignment
   */
  textAlign?: TextAlign;
}
export function ModalHeaderSubtitle({
  children,
  textAlign,
}: ModalHeaderSubtitleProps) {
  return <SC.Subtitle align={textAlign}>{children}</SC.Subtitle>;
}
