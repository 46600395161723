import CarrousselItem from '../../common/carroussel/carroussel-item/carroussel-item.component.js';
import Icon from '../../common/icon/icon.component.js';

let Template = ({
  props,
  carrousselItems,
  handleAnimationEnd,
  moveClass,
  events,
}) => {
  return (
    <div
      className={`exon-carousel-wrapper exon-module-wrapper ${props.className}`}
    >
      <div className="exon-carousel-wrapper-box">
        <ul
          className={`${moveClass} exon-carousel-wrapper-list`}
          onAnimationEnd={handleAnimationEnd}
        >
          {carrousselItems.map((carouselItem, index) => (
            <CarrousselItem
              key={`carousel-item-${index}`}
              moveClass={moveClass}
              image={carouselItem}
              imageIndexActive={props.imageIndexActive}
              onClick={() => props.onClick(carouselItem.index)}
            />
          ))}
        </ul>
      </div>
      {props.displayButtons && (
        <div className="exon-carousel-ui">
          <button
            className="exon-carousel-button exon-carousel-button-prev"
            onClick={() => events.onSetMoveClassClick('prev')}
          >
            <Icon icon={'exon-icon-left-unfilled_enabled'} />
          </button>
          <button
            className="exon-carousel-button exon-carousel-button-next"
            onClick={() => events.onSetMoveClassClick('next')}
          >
            <Icon icon={'exon-icon-right-unfilled_enabled'} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Template;
