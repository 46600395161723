import { PolicyConditionField } from 'bff/src/routers/policies/enums';
import { useFormContext } from 'react-hook-form';
import CriteriaFields from '@components/CriteriaFields';
import { PolicyRegisterForm } from '../../../types';

export const PolicyConditions = () => {
  const { control } = useFormContext<PolicyRegisterForm>();

  return (
    <CriteriaFields
      control={control}
      name="conditions"
      fields={{
        branch: { value: PolicyConditionField.SUBSIDIARY },
        area: { value: PolicyConditionField.AREA },
        position: { value: PolicyConditionField.JOB_POSITION },
        company: { value: PolicyConditionField.COMPANY },
        project: { value: PolicyConditionField.PROJECT },
        user: { value: PolicyConditionField.USER },
      }}
      static={{
        condition: 'and',
        operator: 'equal',
      }}
    />
  );
};
