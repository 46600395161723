import TextBlock from '../../../common/text-block/text-block.component.js';
import Popup from '../../../popup/popup.component.js';
import Link from '../../../common/link/link.component.js';
import { formatterUtil } from '../../../../utils/formatter.js';

let Template = (props) => {
  return (
    <div className="exon-hotel-additional-information">
      {props.additionalInformation && (
        <TextBlock
          text={formatterUtil.stringReduce(props.additionalInformation, 800)}
        />
      )}

      {!props.additionalInformation && <p>Não informado pelo hotel</p>}

      {props.additionalInformation &&
        props.additionalInformation.length > 800 && (
          <Link description="Ver mais" onClick={() => props.events.onClick()} />
        )}

      {props.show && (
        <Popup
          exonStyle={true}
          title={'Informações adicionais'}
          cancelButtonClick={() => props.events.onClick()}
        >
          <TextBlock text={props.additionalInformation} />
        </Popup>
      )}
    </div>
  );
};

export default Template;
