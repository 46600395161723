import Accordion from '../../../../common/accordion/accordion.component.js';
import AccordionItem from '../../../../common/accordion/accordion-item.component.js';
import HotelRoomsGroup from '../../hotel-rooms/hotel-rooms-group/hotel-rooms-group.component.js';
import Icon from '../../../../common/icon/icon.component.js';

let Template = (props) => {
  return (
    <div className="exon-hotel-room-list-groups">
      <p className="exon-hotel-room-list-groups-length">
        {`Você precisa selecionar ${props.searchParameters.rooms.length} ${
          props.searchParameters.rooms.length != 1 ? 'quartos' : 'quarto'
        }`}
      </p>

      <Accordion retractable={true} openeds={[props.indexRoomGroupSelected]}>
        {props.searchParameters &&
          props.searchParameters.rooms.map((item, indexRoomGroup) => {
            let opened = indexRoomGroup == props.indexRoomGroupSelected;
            return (
              <AccordionItem
                key={`${indexRoomGroup}_accordion-item`}
                onTitleClick={(e, children, childIndex) =>
                  props.onAccordionClick(indexRoomGroup)
                }
                title={
                  <div className="exon-accordion_title-box">
                    <h5>
                      {`Escolha o quarto para ${item.adultCount} ${
                        item.adultCount != 1 ? 'adultos' : 'adulto'
                      } ${
                        item.childCount > 0
                          ? `e ${item.childCount} ${
                              item.childCount != 1 ? 'crianças' : 'criança'
                            }`
                          : ''
                      }`}
                    </h5>

                    {(!opened && (
                      <Icon icon={'exon-icon-right-unfilled_disabled'} />
                    )) || <Icon icon={'exon-icon-down-unfilled_enabled'} />}
                  </div>
                }
                opened={indexRoomGroup == props.indexRoomGroupSelected}
              >
                <HotelRoomsGroup
                  identifierGroup={`identifier-group-${indexRoomGroup}`}
                  roomIdSelected={props.roomIdsSelected[indexRoomGroup]}
                  indexRoomGroup={indexRoomGroup}
                  rooms={props.rooms}
                  showFilterRooms={props.showFilterRooms}
                  onSelectRoomClick={(roomIndex) =>
                    props.events.onSelectRoomClick(
                      roomIndex,
                      indexRoomGroup,
                      props.searchParameters.rooms.length,
                    )
                  }
                  roomsDisplayed={props.roomsDisplayed[indexRoomGroup]}
                  onMoreRoomsClick={() =>
                    props.events.onMoreRoomsClick(indexRoomGroup)
                  }
                  onDetailsHotelClick={props.onDetailsHotelClick}
                />
              </AccordionItem>
            );
          })}
      </Accordion>
    </div>
  );
};

export default Template;
