import styled from 'styled-components';
interface IconCartProps {
  noBorderLeft: boolean;
}
export const IconCart = styled.div<IconCartProps>`
  display: flex;
  align-items: center;
  border-left: ${(props) =>
    props.noBorderLeft ? 'none' : '1px solid #dbdbdb'};
  transition: border-left-color 0.3s ease-in-out;
  padding: 0 10px;
  position: relative;
`;

interface ContainerProps {
  noPadding: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 20px;
  background: white;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  transition: border-color 0.3s ease-in-out, padding-left 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(170, 168, 168, 0.5);
  -webkit-box-shadow: 0px 2px 4px 0px rgba(170, 168, 168, 0.5);
  -moz-box-shadow: 0px 2px 4px 0px rgba(170, 168, 168, 0.5);
  -o-box-shadow: 0px 2px 4px 0px rgba(170, 168, 168, 0.5);
  padding-left: ${(props) => (props.noPadding ? 'none' : '20px')};

  &:hover {
    border-color: #c9c9c9;
  }

  &:hover ${IconCart} {
    border-left-color: #c9c9c9;
  }
`;

export const CountersCart = styled.div`
  align-items: center;
  line-height: 1;
  color: #a1a1a1;
  display: flex;
  gap: 20px;
`;

export const Aereo = styled.div`
  display: flex;
  -webkit-box-align: center;
  position: relative;
  gap: 4px;
  align-items: flex-end;
  font-size: 18px;
`;

export const Hotel = styled.div`
  display: flex;
  -webkit-box-align: center;
  position: relative;
  gap: 4px;
  align-items: flex-end;
  font-size: 18px;
`;

export const IconDot = styled.div<{ color?: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  background: ${(props) => props.color || 'rgb(254, 43, 143)'};
  border-radius: 100%;
  top: -5px;
  right: -4px;
  text-align: center;
  color: white;
  font-size: 10px;
  font-weight: 900;
  z-index: 9;
`;
