let InputFileTemplate = {
  image: ({ onFileChanged, selectedFileName, url, returnImageUrl }) => (
    <div className="exon-input-file">
      {returnImageUrl && (
        <img className="editable img-responsive" src={url + returnImageUrl} />
      )}
      <label htmlFor="file" className="exon-input-file-label">
        <span>{selectedFileName}</span>
        <span>Procurar</span>
      </label>

      <input
        name="file"
        id="file"
        type="file"
        className="exon-input-file-image"
        onChange={onFileChanged}
        accept="image/*"
      />
    </div>
  ),
};

export default InputFileTemplate;
