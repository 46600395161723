import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { getVehicleAdditionalsText } from '../../../utils/resolvers/vehicleResolver';

let TravelExpirationComponent = ({ expirationInfos }) => {
  let expirationMain = expirationInfos.main;

  return (
    <div
      className={
        'exon-travel-expiration exon-row-itens' +
        (expirationMain.expired ? ' exon-travel-expiration-danger' : '')
      }
    >
      <div className="exon-travel-expiration-main">
        <div>
          <h6>Expiração</h6>
          <div className="exon-travel-expiration-label">
            <span className="exon-icon exon-icon-info-unfilled_disabled"></span>
            <p>Item mais perto de expirar</p>
          </div>
          <div className="exon-travel-expiration-date">
            <p>
              {utils.formatters.dateObjToString(
                expirationMain.expirationData,
                enums.dateFormat.dayMonthHourMinute,
              )}
            </p>
          </div>
        </div>
        <div className="exon-travel-expiration-details exon-row-itens exon-row-aling-itens-center">
          <div className="exon-travel-expiration-details-items">
            {expirationMain.bookingType ==
              enums.products.namedType.airBooking && (
              <div className="exon-travel-expiration-box-details-items">
                <p className="exon-travel-expiration-details-location">
                  {expirationMain.bookingInfos.trip
                    ? expirationMain.bookingInfos.trip.departure.iata
                    : '--'}
                  <span>
                    {expirationMain.bookingInfos.trip
                      ? expirationMain.bookingInfos.trip.departure.date
                          .timeFormatted
                      : '--'}
                  </span>
                </p>
                <p className="exon-travel-expiration-details-location">
                  {expirationMain.bookingInfos.trip
                    ? expirationMain.bookingInfos.trip.arrival.iata
                    : '--'}
                  <span>
                    {expirationMain.bookingInfos.trip
                      ? expirationMain.bookingInfos.trip.arrival.date
                          .timeFormatted
                      : '--'}
                  </span>
                </p>
              </div>
            )}

            {expirationMain.bookingType ==
              enums.products.namedType.hotelBooking && (
              <div className="exon-travel-expiration-box-details-items">
                <p className="exon-travel-expiration-details-location-hotel">
                  {expirationMain.bookingInfos.hotel}
                </p>
                <p className="exon-travel-expiration-details-location-hotel">
                  {(expirationMain.bookingInfos.location.city
                    ? expirationMain.bookingInfos.location.city
                    : '--') +
                    '/' +
                    (expirationMain.bookingInfos.location.state
                      ? expirationMain.bookingInfos.location.state
                      : '--')}
                </p>
              </div>
            )}
          </div>
          {expirationMain.bookingType ==
            enums.products.namedType.airBooking && (
            <div className="exon-travel-expiration-details-date">
              <p>
                {expirationMain.bookingInfos.trip
                  ? expirationMain.bookingInfos.trip.departure.date.dayOfWeek
                  : '--'}
                ,
              </p>
              <p>
                {' '}
                {expirationMain.bookingInfos.trip
                  ? expirationMain.bookingInfos.trip.departure.date
                      .dateShortFormatted
                  : '--'}
              </p>
              <p>
                {' '}
                <strong className="">
                  {expirationMain.bookingInfos.price}
                </strong>
              </p>
            </div>
          )}

          {expirationMain.bookingType ==
            enums.products.namedType.hotelBooking && (
            <div className="exon-travel-expiration-details-date">
              <p>
                {expirationMain.bookingInfos.checkin
                  ? expirationMain.bookingInfos.checkin.dayOfWeek
                  : ''}
                ,
              </p>
              <p className="exon-secondary-color">
                {' '}
                {expirationMain.bookingInfos.checkin
                  ? expirationMain.bookingInfos.checkin.dateShortFormatted
                  : ''}
              </p>
              <p>
                {' '}
                <strong className="">
                  {expirationMain.bookingInfos.price}
                </strong>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="exon-travel-expiration-list">
        {expirationInfos.expirationBookings.map((booking, index) => {

          let vehicleData = booking.bookingInfos?.vehicle;
          if (vehicleData && !vehicleData.rentalCompany && vehicleData.originalRequest)
            vehicleData = vehicleData.originalRequest;

          return (
            <div key={index} className="exon-travel-expiration-list-item">
              {booking.expirationData && (
                <p>
                  {utils.formatters.dateObjToString(
                    booking.expirationData,
                    enums.dateFormat.dayMonthHourMinute,
                  )}
                </p>
              )}

              {booking.bookingInfos && booking.bookingInfos.hotel && (
                <p>
                  <span>{booking.bookingInfos.hotel}</span>

                  {booking.bookingInfos.checkin
                    ? utils.formatters.dateObjToString(
                        booking.bookingInfos.checkin,
                        enums.dateFormat.dayMonth,
                      )
                    : '--'}
                </p>
              )}

              {booking.bookingInfos && booking.bookingInfos.trip && (
                <p>
                  {booking.bookingInfos.trip.departure.iata +
                    ' ' +
                    booking.bookingInfos.trip.departure.date.timeFormatted +
                    ' - ' +
                    booking.bookingInfos.trip.arrival.iata +
                    ' ' +
                    booking.bookingInfos.trip.arrival.date.timeFormatted}
                </p>
              )}

              {booking.bookingInfos && booking.bookingInfos.hotel && (
                <p>
                  {(booking.bookingInfos.location.city
                    ? booking.bookingInfos.location.city
                    : '--') +
                    '/' +
                    (booking.bookingInfos.location.state
                      ? booking.bookingInfos.location.state
                      : '--') +
                    ' - ' +
                    booking.bookingInfos.price}
                </p>
              )}

              {booking.bookingInfos && booking.bookingInfos.trip && (
                <p>
                  {utils.formatters.dateObjToString(
                    booking.bookingInfos.trip.departure.date,
                    enums.dateFormat.dayMonth,
                  ) +
                    ' - ' +
                    booking.bookingInfos.price}
                </p>
              )}

              {vehicleData && (
                <p>
                  {`${vehicleData.category || vehicleData.originalRequest.type} (${getVehicleAdditionalsText(vehicleData)})${!!booking.bookingInfos.price ? ` - ${booking.bookingInfos.price}` : ''}`}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TravelExpirationComponent;
