'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import { listItemsResolver } from '../../utils/resolvers/listItemresolver.js';
import HotelSearchEngineTemplate from './hotel-search-engine.template.js';
import DateComponent from '../date/date.component.js';
import { gritter } from '@legacy-utils/utils.js';

export default class HotelSearchEngineComponent extends React.Component {
  externalOptions = {
    events: {
      onSearchClicked: null,
    },

    dataSources: {
      destination: null,
      locations: null,
    },
  };

  searchForTypes = {
    city: { value: 1, label: 'Cidades' },
    districtAndTouristSpot: { value: 2, label: 'Bairros e pontos turísticos' },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    componentUtil.react.bindMethods(this.events, this);

    for (let component in this.components) {
      let componentFunctions = this.components[component];
      componentUtil.react.bindMethods(componentFunctions, this);
    }

    this.state = this.getFieldsInitialValue();
  }

  getGuests = function () {
    return { roomsStart: this.state.guests };
  };

  getData = function (mock) {
    if (mock)
      return {
        location: 258559,
        locationName:
          'Florianópolis - Santa Catarina / BR, Brasil (FLN) / SC, Brasil',
        rooms: [
          {
            adultCount: 1,
            childCount: 0,
          },
        ],
        checkin: {
          day: 13,
          month: 6,
          year: 2023,
        },
        checkout: {
          day: 15,
          month: 6,
          year: 2023,
        },
        searchViewParameters: {
          filter: {
            hotelName: null,
            geoLocation: null,
          },
          sortingData: {
            by: 0,
            desc: false,
          },
          pagingData: {
            itemsPerPage: 10,
            page: 1,
          },
        },
      };

    return {
      location:
        this.state.destination &&
        this.state.searchForType.value == this.searchForTypes.city.value
          ? this.state.destination.id
          : 0,
      locationName:
        this.state.searchForType.value == this.searchForTypes.city.value
          ? this.state.destination.name
          : this.state.location.city,
      label:
        this.state.searchForType.value == this.searchForTypes.city.value
          ? this.state.destination.name
          : this.state.location.name,
      rooms: this.state.guests.map((room) => {
        return { adultCount: room.adults, childCount: room.children };
      }),
      checkin: {
        day: this.state.checkinDate.day,
        month: this.state.checkinDate.month,
        year: this.state.checkinDate.year,
      },
      checkout: {
        day: this.state.checkoutDate.day,
        month: this.state.checkoutDate.month,
        year: this.state.checkoutDate.year,
      },
      searchViewParameters: {
        filter: {
          hotelName: this.state.hotelName ? this.state.hotelName : null,
          geoLocation:
            this?.state?.location?.geoLocation &&
            this.state.searchForType.value ==
              this.searchForTypes.districtAndTouristSpot.value
              ? {
                  latitude: this.state.location.geoLocation.latitude,
                  longitude: this.state.location.geoLocation.longitude,
                }
              : null,
        },
        sortingData: {
          by: 0,
          desc: false,
        },
        pagingData: {
          itemsPerPage: 10,
          page: 1,
        },
      },
      placeId: this.state.location.placeId ? this.state.location.placeId : '',
    };
  };

  getFieldsInitialValue = function () {
    let beginDate = new Date();

    if (
      window.location.href.includes('qa.expenseon') ||
      window.location.href.includes('localhost') ||
      window.location.href.includes('dev-expenseon') ||
      window.location.href.includes('staging-platform')
    )
      beginDate = this.addDays(new Date(), 60);

    return {
      destination: {},
      checkinDate: null,
      checkoutDate: null,
      showAdvancedOptions: true,
      checkinStartDate: beginDate,
      checkoutStartDate: this.addDays(beginDate, 1),
      hotelName: '',
      guests: [
        {
          adults: 1,
          children: 0,
        },
      ],
      showMessage: false,
      searchForType: this.searchForTypes.city,
      location: {},
    };
  };

  addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  render() {
    return (
      <HotelSearchEngineTemplate.main
        destinationProps={this.components.destination.getProps()}
        onSearchClicked={this.events.onSearchClicked}
        onClearFieldsClicked={this.events.onClearFieldsClicked}
        onGuestsClearFieldsClicked={this.events.onGuestsClearFieldsClicked}
        checkinDateProps={this.components.checkinDate.getProps()}
        checkoutDateProps={this.components.checkoutDate.getProps()}
        providerProps={this.components.provider.getProps()}
        onShowAdvancedOptionsClicked={this.events.onShowAdvancedOptionsClicked}
        showAdvancedOptions={this.state.showAdvancedOptions}
        hotelName={this.state.hotelName}
        onHotelNameChanged={this.events.onHotelNameChanged}
        guestsProps={this.components.guests.getProps()}
        guestsData={this.getGuests()}
        showMessage={this.state.showMessage}
        searchForTypesProps={this.components.searchForTypes.getProps()}
        searchForTypeSelected={this.state.searchForType}
        searchForTypes={this.searchForTypes}
        locationsProps={this.components.locations.getProps()}
      />
    );
  }

  events = {
    onSearchClicked: (ev) => {
      let mockData =
        window.event.ctrlKey && window.location.href.indexOf('localhost') != -1;

      if (this.state.guests == null) {
        this.setState({
          guests: [
            {
              adults: 1,
              children: 0,
            },
          ],
        });
      }


      let data = this.getData(mockData);

      if (!mockData && !this.validate(data)) {
        gritter.Error('Revise o preenchimento dos campos para realizar a busca');
        return;
      }

      data.mock = mockData;
      if (this.externalOptions.events.onSearchClicked)
        this.externalOptions.events.onSearchClicked(data);
    },
    onClearFieldsClicked: (ev) => {
      this.setState(this.getFieldsInitialValue());
    },
    onShowAdvancedOptionsClicked: (ev) => {
      this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions });
    },
    onHotelNameChanged: (ev) => {
      this.setState({ hotelName: ev.target.value });
    },
    onGuestsClearFieldsClicked: () => {
      this.setState({
        guests: [
          {
            adults: 1,
            children: 0,
          },
        ],
      });
    },
  };

  components = {
    destination: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.destination,
          placeholder: 'Insira local',
          options: {
            events: {
              onInputChanged: (inputText) => {
                let data = _this.state.destination || {};
                data.name = inputText;
                _this.setState({ destination: data });
              },
              onSelected: (data) => {
                _this.setState({ destination: data });
              },
            },
            dataSource: _this.externalOptions.dataSources.destination,
          },
        };
      },
    },
    guests: {
      getProps: function () {
        let _this = this;

        return {
          options: {
            events: {
              onGuestsApply: (data) => {
                _this.setState({ guests: data });
              },
            },
          },
        };
      },
    },

    provider: {
      getProps: function () {
        let _this = this;
        return {
          data: 0,
          options: {
            events: {
              onSelected: function (data) {
                _this.setState({ provider: data });
              },
            },
            dataSource: {
              load: function (loadOptions) {
                return new Promise((resolve) => {
                  resolve(listItemsResolver.mockedListItems);
                });
              },
            },
          },
        };
      },
    },
    checkinDate: {
      getProps: () => {
        if (!this.state.checkinDate)
          this.state.checkinDate = this.state.checkinStartDate;

        return {
          data: this.state.checkinDate,
          startDate: this.state.checkinStartDate,
          placeholder: 'Selecione',
          options: {
            events: {
              onSelected: (checkinDate) => {
                this.setState({
                  checkinDate,
                  checkoutStartDate: checkinDate
                    ? this.addDays(
                        DateComponent.convertDateObjectToDate(checkinDate),
                        1,
                      )
                    : new Date(),
                });
              },
            },
          },
        };
      },
    },
    checkoutDate: {
      getProps: () => {
        if (
          !this.state.checkoutDate ||
          DateComponent.convertDateObjectToDate(this.state.checkoutDate) <=
            this.state.checkoutStartDate
        )
          this.state.checkoutDate = DateComponent.convertDateToDateObject(
            this.state.checkoutStartDate,
          );

        return {
          data: this.state.checkoutDate,
          startDate: this.state.checkoutStartDate,
          placeholder: 'Selecione',
          options: {
            events: {
              onSelected: (checkoutDate) => {
                this.setState({ checkoutDate });
              },
            },
          },
        };
      },
    },

    searchForTypes: {
      getProps: function () {
        let _this = this;
        return {
          data: this.state.searchForType,
          options: {
            items: [
              this.searchForTypes.city,
              this.searchForTypes.districtAndTouristSpot,
            ],
            events: {
              onChanged: function (item) {
                _this.setState({ searchForType: item });
              },
            },
          },
        };
      },
    },
    locations: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.location,
          placeholder: 'Insira local',
          options: {
            events: {
              onInputChanged: (inputText) => {
                let data = _this.state.location || {};
                data.name = inputText;
                _this.setState({ location: data });
              },
              onSelected: (data) => {
                _this.setState({ location: data });
              },
            },
            dataSource: _this.externalOptions.dataSources.locations,
          },
        };
      },
    },
  };

  validate = (data) => {
    let valid = !!data.checkin && !!data.checkout;

    if (this.state.searchForType.value == this.searchForTypes.districtAndTouristSpot.value)
      valid &= !!data.placeId;
    else
      valid &= !!data.location;

    return valid;
  }
}
