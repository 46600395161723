'use strict';

import React from 'react';
import ProductSummaryTariffedTemplate from './product-summary-tariffed.template.js';

export default class ProductSummaryTariffedComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ProductSummaryTariffedTemplate
        allTariffedPrice={this.props.allTariffedPrice}
        tariffedPriceExpired={this.props.tariffedPriceExpired}
        searchParameters={this.props.searchParameters}
      />
    );
  }
}
