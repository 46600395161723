import { CheckoutTraveler } from './checkout-traveler';
import { enums } from '@legacy-utils/enums.js';
import AutoComplete from '../../../../common/auto-complete/auto-complete.component.js';
import TitleSectionItem from '@legacy-components/TitleSectionItem';

export const Travelers = ({
  dataSources,
  checkoutPreBookingData,
  serviceRequestExisting,
  roomOptions,
  hotelsOptions,
  showValidationAdditionalFieldsTraveler,
  travelers,
  onTravelerConfirm,
  onTravelerAutoCompleteInputChange,
  onValidationAdditionalFieldsClick,
  onCloseTravelerClick,
  showValidationsFields,
  agencyAndCompany,
  requesterOptions,
  disabledFields,
  isRvOffline,
  isImportExternal,
  selectedHotel,
  isWoobaAndInternacional,
}) => {
  let isInternational = checkoutPreBookingData
    ? checkoutPreBookingData.summary.isInternational
    : false;
  let travelerADT = [];
  let travelerCHD = [];
  let travelersINF = [];

  if (travelers.adults > 0) {
    for (let i = 1; i <= travelers.adults; i++) {
      let travelerIdx = i - 1;
      const traveler = travelers.confirmedTravelers[travelerIdx];
      const selectedRoom = null; //roomOptions.data.adt[i];

      const travelerOnReservation = travelers.travelersOnReservation
        ? travelers.travelersOnReservation[travelerIdx]
        : null;

      travelerADT.push(
        <CheckoutTraveler
          key={i}
          travelerNumber={i}
          travelerType={enums.travelerType.Adt}
          serviceRequestExisting={serviceRequestExisting}
          confirmedTraveler={traveler}
          travelerOnReservation={travelerOnReservation}
          roomOptions={roomOptions}
          hotelsOptions={hotelsOptions}
          selectedRoom={selectedRoom}
          showValidationAdditionalFieldsTraveler={
            showValidationAdditionalFieldsTraveler
          }
          dataSources={dataSources}
          onTravelerConfirm={onTravelerConfirm}
          onTravelerAutoCompleteInputChange={onTravelerAutoCompleteInputChange}
          //onValidationAdditionalFieldsClick={onValidationAdditionalFieldsClick}
          //onCloseTravelerClick={onCloseTravelerClick}
          showValidationsFields={showValidationsFields}
          disabledFields={disabledFields}
          isImportExternal={isImportExternal}
          isInternational={isInternational}
          isRvOffline={isRvOffline}
          needCompleteAddress={
            selectedHotel && selectedHotel.needCompleteAddress
          }
          isWoobaAndInternacional={isWoobaAndInternacional}
        />,
      );
    }
  }

  if (travelers.childrens > 0) {
    for (let i = 1; i <= travelers.childrens; i++) {
      let travelerIdx = i - 1;
      let travelerNumber = travelers.adults + i;
      const traveler = travelers.confirmedTravelers[travelerNumber - 1];
      const selectedRoom = null; //roomOptions.data.chd[i];

      const travelerOnReservation = travelers.travelersOnReservation
        ? travelers.travelersOnReservation[travelerNumber - 1]
        : null;
      travelerCHD.push(
        <CheckoutTraveler
          key={i}
          travelerNumber={travelerNumber}
          travelerType={enums.travelerType.Chd}
          confirmedTraveler={traveler}
          travelerOnReservation={travelerOnReservation}
          roomOptions={roomOptions}
          hotelsOptions={hotelsOptions}
          selectedRoom={selectedRoom}
          showValidationAdditionalFieldsTraveler={
            showValidationAdditionalFieldsTraveler
          }
          dataSources={dataSources}
          onTravelerConfirm={onTravelerConfirm}
          onTravelerAutoCompleteInputChange={onTravelerAutoCompleteInputChange}
          //onValidationAdditionalFieldsClick={onValidationAdditionalFieldsClick}
          //onCloseTravelerClick={onCloseTravelerClick}
          showValidationsFields={showValidationsFields}
          disabledFields={disabledFields}
          isImportExternal={isImportExternal}
          isInternational={isInternational}
          isRvOffline={isRvOffline}
          needCompleteAddress={
            selectedHotel && selectedHotel.needCompleteAddress
          }
          isWoobaAndInternacional={isWoobaAndInternacional}
        />,
      );
    }
  }

  if (travelers.infants > 0) {
    for (let i = 1; i <= travelers.infants; i++) {
      let travelerIdx = i - 1;
      let travelerNumber = travelers.adults + travelers.childrens + i;
      const traveler = travelers.confirmedTravelers[travelerNumber - 1];
      const selectedRoom = null; //roomOptions.data.chd[i];

      const travelerOnReservation = travelers.travelersOnReservation
        ? travelers.travelersOnReservation[travelerNumber - 1]
        : null;
      travelersINF.push(
        <CheckoutTraveler
          key={i}
          travelerNumber={travelerNumber}
          travelerType={enums.travelerType.Inf}
          confirmedTraveler={traveler}
          travelerOnReservation={travelerOnReservation}
          roomOptions={roomOptions}
          hotelsOptions={hotelsOptions}
          selectedRoom={selectedRoom}
          dataSources={dataSources}
          onTravelerConfirm={onTravelerConfirm}
          onTravelerAutoCompleteInputChange={onTravelerAutoCompleteInputChange}
          //onValidationAdditionalFieldsClick={onValidationAdditionalFieldsClick}
          //onCloseTravelerClick={onCloseTravelerClick}
          showValidationAdditionalFieldsTraveler={
            showValidationAdditionalFieldsTraveler
          }
          showValidationsFields={showValidationsFields}
          disabledFields={disabledFields}
          isImportExternal={isImportExternal}
          needCompleteAddress={
            selectedHotel && selectedHotel.needCompleteAddress
          }
          isWoobaAndInternacional={isWoobaAndInternacional}
        />,
      );
    }
  }

  const noChildrenOrInfants =
    travelers.childrens === 0 && travelers.infants === 0;
  const classTitlenoChildren = noChildrenOrInfants ? 'hide' : '';

  return (
    <div className="exon-trevelers-fields exon-section-item">
      {agencyAndCompany.agency.isAgency &&
        agencyAndCompany.agency.travelProfile === 4 && (
          <div className="exon-box-company">
            <div className="exon-box-fields-row">
              <div className="exon-box-field">
                <label className="exon-label-field exon-box-company-label">
                  Cliente
                </label>
                <div className="exon-field">
                  <input
                    className="exon-box-company-input exon-box-company-text-color"
                    name="text"
                    type="text"
                    value={agencyAndCompany.company.label}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="exon-box-fields-row">
              <div className="exon-box-field">
                <label className="exon-label-field exon-box-company-label">
                  Informar Solicitante
                </label>
                <div className="exon-field exon-select-list-top">
                  <AutoComplete
                    selectedItem={requesterOptions.data}
                    placeholder={requesterOptions.placeholder}
                    dataSource={requesterOptions.dataSource}
                    onChange={requesterOptions.events.onChanged}
                    required={true}
                    forceValidation={showValidationsFields}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

      {travelers.adults > 0 && (
        <div>
          <TitleSectionItem
            title="Viajante(s) - Adulto"
            className={classTitlenoChildren}
          />
          <div
            className={`exon-box-fields-row exon-passenger-information ${
              isRvOffline ? 'flex-wrap' : ''
            }`}
          >
            {travelerADT}
          </div>
        </div>
      )}
      {travelers.childrens > 0 && (
        <div>
          <TitleSectionItem
            title="Viajante(s) - Criança"
            className={classTitlenoChildren}
          />
          <div
            className={`exon-box-fields-row exon-passenger-information ${
              isRvOffline ? 'flex-wrap' : ''
            }`}
          >
            {travelerCHD}
          </div>
        </div>
      )}
      {travelers.infants > 0 && (
        <div>
          <TitleSectionItem
            title="Viajante(s) - Bebê"
            className={classTitlenoChildren}
          />
          <div
            className={`exon-box-fields-row exon-passenger-information ${
              isRvOffline ? 'flex-wrap' : ''
            }`}
          >
            {travelersINF}
          </div>
        </div>
      )}
    </div>
  );
};
