let Template = (props) => {
  return (
    <div className="exon-input-radio">
      <label>
        <span className="exon-label-bnt-radio"></span>
        <input
          type="radio"
          value={props.value}
          checked={props.selected}
          name={props.name}
          onChange={() => props.onChange(props)}
        />
        <span></span>
        {props.label}
      </label>
    </div>
  );
};

export default Template;
