import Filter from '../monitoring-filter/monitoring-filter.component.js';
import Matrix from '../monitoring-matrix/monitoring-matrix.component.js';
import Grid from '../monitoring-grid/monitoring-grid.component.js';

var MonitoringManagerTemplate = {
  main: ({
    events,
    requetsEvents,

    agency,
    filterDataSources,

    viewBySelected,
    rows,
    onIdentifierCellClicked,

    rowsGrid,
    gridDataSource,
    currentPage,
    onPaginationClick,
    updateAt,
  }) => {
    return (
      <div className="container-fluid ">
        <div className="exon-monitoring">
          <div className="exon-monitoring-filter-position  exon-monitoring-shadow">
            <MonitoringManagerTemplate.monitorings.filter
              agency={agency}
              dataSources={filterDataSources}
              onApplyFilterClicked={events.onApplyFilterClicked}
            />
          </div>

          <div className="exon-monitoring-matrix-position  exon-monitoring-shadow">
            <MonitoringManagerTemplate.monitorings.matrix
              viewBySelected={viewBySelected}
              rows={rows}
              onIdentifierCellClick={onIdentifierCellClicked}
              updateAt={updateAt}
              updateClick={events.onUpdateClicked}
            />
          </div>
        </div>

        <div className="exon-monitoring-grid-position exon-monitoring-shadow">
          <MonitoringManagerTemplate.monitorings.grid
            agency={agency}
            rowsGrid={rowsGrid}
            viewBySelected={viewBySelected}
            gridDataSource={gridDataSource}
            currentPage={currentPage}
            onPaginationClick={onPaginationClick}
            requetsEvents={requetsEvents}
          />
        </div>
      </div>
    );
  },

  monitorings: {
    filter: ({ agency, dataSources, onApplyFilterClicked }) => (
      <Filter
        agency={agency}
        dataSources={dataSources}
        onApplyFilterClicked={onApplyFilterClicked}
      />
    ),
    matrix: ({
      viewBySelected,
      rows,
      onIdentifierCellClick,
      updateAt,
      updateClick,
    }) => (
      <Matrix
        viewBySelected={viewBySelected}
        rows={rows}
        onIdentifierCellClick={onIdentifierCellClick}
        updateAt={updateAt}
        updateClick={updateClick}
      />
    ),
    grid: ({
      agency,
      rowsGrid,
      viewBySelected,
      gridDataSource,
      currentPage,
      onPaginationClick,
      requetsEvents,
    }) => (
      <Grid
        agency={agency}
        rowsGrid={rowsGrid}
        viewBy={viewBySelected}
        dataSource={gridDataSource}
        onPageClicked={onPaginationClick}
        currentPage={currentPage}
        requetsEvents={requetsEvents}
      />
    ),
  },
};

export default MonitoringManagerTemplate;
