import { RadioButtonCard } from '@flash-tecnologia/hros-web-ui-v2';
import { useMediaQuery, useTheme } from '@mui/material';
import { PolicyAction } from 'bff/src/routers/policies/enums';
import { useController, useFormContext } from 'react-hook-form';
import { PolicyRegisterForm } from '../../../types';
import { ActionSelectorContainer } from './styled';

const PolicyActionField = () => {
  const theme = useTheme();
  const isMdOrDown = useMediaQuery(theme.breakpoints.down('md'));

  const { control } = useFormContext<PolicyRegisterForm>();

  const { field } = useController({ control, name: 'action' });

  return (
    <ActionSelectorContainer isFull={isMdOrDown}>
      <RadioButtonCard
        title="Bloquear"
        description="O acesso ao recurso será interrompido imediatamente após a violação da política."
        icon="IconLock"
        checked={field.value === PolicyAction.BLOCK}
        onClick={() => field.onChange(PolicyAction.BLOCK)}
      />

      <RadioButtonCard
        title="Notificar"
        description="Um alerta será enviado ao usuário e/ou administradores sobre a violação."
        icon="IconBellRinging"
        checked={field.value === PolicyAction.NOTIFY}
        onClick={() => field.onChange(PolicyAction.NOTIFY)}
      />

      <RadioButtonCard
        title="Justificar"
        description="O usuário precisará justificar a violação antes de prosseguir."
        icon="IconMessage"
        checked={field.value === PolicyAction.JUSTIFY}
        onClick={() => field.onChange(PolicyAction.JUSTIFY)}
      />

      <RadioButtonCard
        title="Sem Ação"
        description="Nenhuma ação será tomada; a violação será registrada para revisão futura."
        icon="IconCircleOff"
        checked={field.value === PolicyAction.NO_ACTION}
        onClick={() => field.onChange(PolicyAction.NO_ACTION)}
      />
    </ActionSelectorContainer>
  );
};

export default PolicyActionField;
