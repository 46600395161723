import React from 'react';

let Template = {
  main: ({ innerHtml }) => {
    let Fragment = React.Fragment;

    return (
      <div className="exon-template-preview">
        <div className="template-box">
          <div
            id="reset-this-root"
            className="template-content reset-this"
            dangerouslySetInnerHTML={{ __html: innerHtml }}
          ></div>
        </div>
      </div>
    );
  },
};

export default Template;
