let ProductSummaryTariffedTemplate = ({
  allTariffedPrice,
  tariffedPriceExpired,
  searchParameters,
}) => {
  let total = allTariffedPrice ? allTariffedPrice.total : 0;
  let totalFare = allTariffedPrice ? allTariffedPrice.totalFare : 0;
  let totalTaxes = allTariffedPrice ? allTariffedPrice.totalTaxes : 0;
  let totalServices = allTariffedPrice ? allTariffedPrice.totalServices : 0;
  let agreementApplied = allTariffedPrice
    ? allTariffedPrice.agreementApplied
    : false;

  let travelers = searchParameters ? searchParameters.travelers : null;
  let adults = 0;
  let childrens = 0;
  let infants = 0;
  if (travelers) {
    adults = travelers.adults;
    childrens = travelers.childrens;
    infants = travelers.infants;
  }

  return (
    <div className="exon-product-summary-tariffed">
      {!total && (
        <div className="exon-box-tariffed-loader">
          <div>
            <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
            <p>
              Por favor aguarde...
              <br />
              Estamos atualizando os valores!
            </p>
          </div>
        </div>
      )}

      {total && total.value == 0 && (
        <div className="exon-msg-error-tariffed">
          <div>
            <span className="exon-icon exon-icon-warning-filled_enabled-yellow"></span>
            <p>Não foi possível Efetuar a tarifação!</p>
          </div>
        </div>
      )}

      {tariffedPriceExpired && total && total.value != 0 && (
        <div className="exon-msg-error-tariffed">
          <div>
            <span className="exon-icon exon-icon-warning-filled_enabled-red"></span>
            <p>
              Tempo expirado!
              <br />
              Efetue a tarifação novamente.
            </p>
          </div>
        </div>
      )}

      {total && total.value != 0 && !tariffedPriceExpired && (
        <div className="exon-value-details">
          <div className="exon-product-option-features">
            {agreementApplied && (
              <span className="exon-icon exon-icon-policy-wake-up"></span>
            )}
          </div>
          <div className="exon-box-value">
            <span className="exon-value-currency">{total.currencySymbol}</span>
            <span className="exon-value">{total.formatted}</span>
          </div>
          <div className="exon-descriptions-values">
            <div className="exon-description">
              <p>Tarifas</p>
              <p>Taxas</p>
              <p>Serviços</p>
            </div>
            <div className="exon-values">
              <p>{totalFare.currencySymbol + ' ' + totalFare.formatted}</p>
              <p>{totalTaxes.currencySymbol + ' ' + totalTaxes.formatted}</p>
              <p>
                {totalServices.currencySymbol + ' ' + totalServices.formatted}
              </p>
            </div>
          </div>
        </div>
      )}
      {!tariffedPriceExpired && travelers && total && total.value != 0 && (
        <div className="exon-descriptions-values-obs">
          <p>
            *Valores referentes a
            {adults > 0 &&
              `${adults > 1 ? ` ${adults} adultos` : ` ${adults} adulto`}`}
            {childrens > 0 &&
              `${
                childrens > 1
                  ? `, ${childrens} crianças`
                  : `, ${childrens} criança`
              }`}
            {infants > 0 &&
              `${infants > 1 ? `, ${infants} bebês` : `, ${infants} bebê`}`}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProductSummaryTariffedTemplate;
