import { RoundedIcon } from '@components/RoundedIcon';
import { Radio } from '@flash-tecnologia/hros-web-ui-v2';
import { PaymentItem } from '@models/PaymentItem';
import { BalanceValueSection } from '../BalanceValueSection';
import SC from './styled';

type EmployeeCorporateBalanceProps = {
  item: PaymentItem;
  className?: string;
  selectable?: boolean;
  checked: boolean;
  onChange: () => void;
  showBalance?: boolean;
  disabled?: boolean;
  isCurrentUserATravelerProfile?: boolean;
};

export function EmployeeCorporateBalance({
  item,
  className,
  checked,
  onChange,
  selectable = false,
  showBalance = true,
  disabled = false,
  isCurrentUserATravelerProfile = false,
}: EmployeeCorporateBalanceProps) {
  return (
    <div className={className}>
      <SC.Box checked={checked} disabled={disabled}>
        <SC.FlexItem>
          <SC.CardTitleContainer>
            {selectable || !isCurrentUserATravelerProfile ? (
              <>
                <SC.Center>
                  <Radio
                    checked={checked}
                    onChange={onChange}
                    name="flash-payment-method"
                    disabled={disabled}
                  />
                </SC.Center>
                <SC.Name>{item.name}</SC.Name>
              </>
            ) : (
              <>
                <RoundedIcon icon="IconCheck" variant="success" />

                <SC.Title>
                  Um cartão virtual corporativo será criado quando você reservar
                  a viagem!
                </SC.Title>
              </>
            )}
          </SC.CardTitleContainer>
        </SC.FlexItem>
        <SC.FlexItem>
          {showBalance && (
            <BalanceValueSection value={item.balance}></BalanceValueSection>
          )}
        </SC.FlexItem>
      </SC.Box>
    </div>
  );
}
