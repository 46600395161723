import { Section } from '@components/Section';
import { WizardStep } from '@components/Wizard/components/WizardStep';
import {
  Skeleton,
  Tag,
  TextArea,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { PolicyRegisterForm } from '../../types';
import { PolicyFileSelector } from './PolicyFileSelector';
import { TitleSectionDocument } from './styled';

type Props = {
  isLoading?: boolean;
};

export const PolicyBasicInformation = ({ isLoading }: Props) => {
  const policyForm = useFormContext<PolicyRegisterForm>();

  const step = {
    title: 'Informações básicas',
    description:
      'Dê um nome e descreva a finalidade da política que está sendo criada.',
  };

  if (isLoading) {
    return (
      <WizardStep title={step.title} description={step.description}>
        <Skeleton width="100%" height="300px" />

        <Box mt={5}>
          <Skeleton width="100%" height="500px" />
        </Box>
      </WizardStep>
    );
  }

  return (
    <WizardStep title={step.title} description={step.description}>
      <Section title="Informações básicas">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={policyForm.control}
              name="name"
              render={({ field, fieldState }) => (
                <TextField
                  label="Nome da política"
                  fullWidth
                  required
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  inputRef={field.ref}
                  {...field}
                  onChange={(d) => field.onChange(d.target.value)}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={policyForm.control}
              name="description"
              render={({ field, fieldState }) => (
                <TextArea
                  placeholder="Descrição"
                  maxLength={255}
                  errorMessage={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Section>

      <Section
        title={
          <TitleSectionDocument>
            Documento da política
            <Tag size="small" variant="gray">
              Opcional
            </Tag>
          </TitleSectionDocument>
        }
        caption={
          'Faça o upload do documento da política da sua empresa que será disponibilizado à todos para consulta.'
        }
      >
        <PolicyFileSelector />
      </Section>
    </WizardStep>
  );
};
