import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import {
  Loader,
  SelectField,
  TextField,
  DatePicker,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { getCustomFields } from '@api/connectOn/connectOn.service';
import { useTravelStore } from '../../../../store/RequestTravel.store';

export function CustomFields({ errors, control }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customFields, setCustomFields] = useState([]);
  const contextId = useTravelStore((state) => state.contextId);

  useEffect(() => {
    const loadCustomFields = async () => {
      setIsLoading(true);
      try {
        const { fields } = await getCustomFields(contextId);
        setCustomFields(fields);
      } catch (error) {
        console.error('Failed to load custom fields:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadCustomFields();
  }, [contextId]);

  const renderField = (field) => {
    const { id, label, controlType, options } = field;
    switch (controlType) {
      case 1: // Data
        return (
          <Controller
            key={id}
            control={control}
            name={`customField_${id}`}
            render={({ field: { value, onChange, onBlur, ref } }) => (
              <DatePicker
                onDateChange={onChange}
                label={label}
                value={value}
                onBlur={onBlur}
                ref={ref}
                error={!!errors[`customField_${id}`]}
                helperText={
                  <DisplayErrorFormValidation
                    errors={errors}
                    fieldName={`customField_${id}`}
                  />
                }
              />
            )}
          />
        );
      case 2: // Numérico
        return (
          <Controller
            key={id}
            control={control}
            name={`customField_${id}`}
            render={({ field: { value, onChange, onBlur, ref } }) => (
              <TextField
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                type="number"
                error={!!errors[`customField_${id}`]}
                helperText={
                  <DisplayErrorFormValidation
                    errors={errors}
                    fieldName={`customField_${id}`}
                  />
                }
              />
            )}
          />
        );
      case 3: // Texto
        return (
          <Controller
            key={id}
            control={control}
            name={`customField_${id}`}
            render={({ field: { value, onChange, onBlur, ref } }) => (
              <TextField
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                error={!!errors[`customField_${id}`]}
                helperText={
                  <DisplayErrorFormValidation
                    errors={errors}
                    fieldName={`customField_${id}`}
                  />
                }
              />
            )}
          />
        );
      case 4: // Opções
        const parsedOptions = JSON.parse(options);
        return (
          <Controller
            key={id}
            control={control}
            name={`customField_${id}`}
            render={({ field: { value, onChange, onBlur, ref } }) => (
              <SelectField
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                options={parsedOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                error={!!errors[`customField_${id}`]}
                helperText={
                  <DisplayErrorFormValidation
                    errors={errors}
                    fieldName={`customField_${id}`}
                  />
                }
              />
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={'custom-fields'}
      style={{ paddingTop: 20, paddingBottom: 20 }}
    >
      {customFields.length > 0 && (
        <>
          <Typography variant={'headline8'}>Informações adicionais</Typography>
          <div>
            {isLoading ? (
              <Loader size={'large'} variant={'primary'} />
            ) : (
              customFields.map(renderField)
            )}
          </div>
        </>
      )}
    </div>
  );
}
