'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import ToggleTemplate from '../toggle/toggle.template.js';

export default class ToggleComponent extends React.Component {
  externalOptions = {
    events: {
      onChanged: null,
    },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.events.setStatus, this);

    this.state = {
      status: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      status: props.checked != undefined ? props.checked : state.status,
    };
  }

  render() {
    return (
      <ToggleTemplate.main
        status={this.state.status}
        placeholder={this.props.placeholder}
        setStatus={this.events.setStatus.toggle}
        className={this.props.className}
      />
    );
  }

  events = {
    setStatus: {
      toggle: function (ev) {
        let status = this.state.status;

        this.setState({ status: !status });

        if (this.externalOptions.events.onChanged)
          this.externalOptions.events.onChanged(!status);
      },
    },
  };

  setData = function (checked) {};
}
