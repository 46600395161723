import { trpc } from '@api/bff/client';
import { TableBaseActions } from '@components/TravelDataTable/components/TableBaseActions';
import { dispatchToast } from '@shared/toast';
import { GetPoliciesResponse } from 'bff/src/routers/policies';
import { useState } from 'react';

export type Props = {
  policy: GetPoliciesResponse['policies'][number];
  onRefresh: () => Promise<unknown> | unknown;
  onEdit: () => void;
};

const PolicyTableActions = ({ policy, onRefresh, onEdit }: Props) => {
  const duplicatePolicy = trpc.duplicatePolicy.useMutation();
  const deletePolicy = trpc.deletePolicy.useMutation();

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDuplicate() {
    try {
      setIsDuplicating(true);

      await duplicatePolicy.mutateAsync({ id: policy.id });

      await onRefresh?.();

      dispatchToast({
        type: 'success',
        content: `A política ${policy.name} foi duplicada com sucesso`,
      });
    } catch (err) {
      dispatchToast({
        content: `Erro ao duplicar a política. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDuplicating(false);
    }
  }

  async function handleDelete() {
    try {
      setIsDeleting(true);

      await deletePolicy.mutateAsync({ id: policy.id });

      await onRefresh?.();

      dispatchToast({
        type: 'success',
        content: `A política ${policy.name} foi excluída com sucesso`,
      });
    } catch (err) {
      dispatchToast({
        content: `Erro ao excluir a política. Por favor, tente novamente`,
        type: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <TableBaseActions
      delete={{
        title: 'Excluir política',
        message:
          'Deseja realmente excluir essa política? Essa ação não poderá ser desfeita',
        status: 'error',
        primaryButtonLabel: 'Excluir',
        loading: isDeleting,
      }}
      duplicate={{
        loading: isDuplicating,
      }}
      onDelete={handleDelete}
      onDuplicate={handleDuplicate}
      onEdit={onEdit}
      actions={['edit', 'delete', 'duplicate']}
    />
  );
};

export default PolicyTableActions;
