import styled from 'styled-components';

export const NumbersSection = styled.section`
  background-color: #fff5fa;
  padding: 10rem 0 3rem 0;
  position: relative;
  top: -12rem;
`;

export const BigNumberCard = styled.div`
  margin: 40px 8px 40px 8px;
  text-align: center;
  min-width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BigNumberText = styled.p`
  max-width: 180px;
`;

export const BigNumber = styled.p`
  font-size: 72px;
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  padding-top: 2rem;
`;
export const TitleContainer = styled.div`
  max-width: 70rem;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 32px;
  text-align: center;
  font-weight: bold;
`;
