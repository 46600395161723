import React from 'react';
import { Box } from '@mui/material';
import { Table, Button } from '@flash-tecnologia/hros-web-ui-v2';
import { Icon } from '@components/Pills/styled';
import { useApprovalTable } from '../../../useApprovalTable';

export function TableFilters({ setShowTable }) {
  const { handleFilters } = useApprovalTable();

  const tableFilters = [
    {
      name: 'status',
      label: 'Status',
      options: [
        { label: 'Ativo', value: 'ACTIVE' },
        { label: 'Inativo', value: 'INACTIVE' },
      ],
    },
    {
      name: 'priority',
      label: 'Prioridade',
      options: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
      ],
    },
  ];

  const handleFilterChange = (filters) => {
    const formattedFilters = filters.reduce((acc, filter) => {
      acc[filter.name] = filter.values;
      return acc;
    }, {});

    handleFilters(formattedFilters);
  };

  const handleAddFlow = () => {
    setShowTable(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Table.Filters
        style={{ width: 'auto' }}
        label="Filtrar por:"
        filters={tableFilters}
        onChange={handleFilterChange}
      />
      <Button size="large" variant={'primary'} onClick={handleAddFlow}>
        Adicionar fluxo <Icon name="IconPlus" />
      </Button>
    </Box>
  );
}
