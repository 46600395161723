'use strict';

import React from 'react';
import HotelProductTemplate from './hotel-product.template.js';
import { utils } from '@legacy-utils/utils';
import { formatterUtil } from '@legacy-utils/formatter';
import { apiResponse } from '@legacy-utils/apiResponse';

export default class HotelProductComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOpenCancelationPolicyButton: this.props
        .showOpenCancelationPolicyButton
        ? this.props.showOpenCancelationPolicyButton
        : false,
      displayCancelationPolicy: {
        display: false,
        deadlineFormatted: '',
        policy: '',
        termsAndConditions: '',
        popupLoad: true,
      },
    };
  }

  render() {
    return (
      <HotelProductTemplate.main
        showOpenCancelationPolicyButton={
          this.state.showOpenCancelationPolicyButton
        }
        product={this.props.product}
        preBooking={this.props.preBooking}
        hotelIndex={this.props.hotelIndex}
        onDeleteProductClick={this.props.onDeleteProductClick}
        displayCancelationPolicy={this.state.displayCancelationPolicy}
        onDisplayCancelationPolicyClicked={this.onDisplayCancelationPolicyClicked.bind(
          this,
        )}
        onCloseCancelationPolicyClicked={this.onCloseCancelationPolicyClicked.bind(
          this,
        )}
      />
    );
  }

  onDisplayCancelationPolicyClicked() {
    let obj = {
      displayCancelationPolicy: {
        display: true,
        deadlineFormatted: '',
        policy: '',
        termsAndConditions: '',
        popupLoad: true,
      },
    };

    this.setState(obj);

    let loadOptions = {
      hotelName: this.props.product.hotel.name,
      selectedRoom: this.props.product.selectedRooms[0],
      hotelComppressedAdditionalProperties:
        this.props.product.comppressedAdditionalProperties,
    };
    this.props.dataSources.hotelsRoomDetails
      .load(loadOptions)
      .then((response) => {
        apiResponse.successful.showErrorIfExists(response);

        let cancelDate = formatterUtil.dateObjToDate(
          response.room.cancelDeadline,
        );

        let policy = '';
        let termsAndConditions = '';
        let penalty = null;

        if (response.room.cancellationPolicies.length > 0) {
          policy = response.room.cancellationPolicies[0].nonRefundable
            ? 'O cancelamento da reserva não pode ser reembolsável.'
            : 'Reembolsável até ' +
              formatterUtil.dateTimeFormat(
                utils.date.dateIncrement(cancelDate, -1, 'd'),
              );
          termsAndConditions =
            response.room.cancellationPolicies[0].description;
          penalty = response.room.cancellationPolicies[0].penalty;
        } else {
          policy = 'Não fornecido';
          termsAndConditions = 'Não fornecido';
        }

        let obj = {
          displayCancelationPolicy: {
            display: true,
            deadlineFormatted: formatterUtil.dateTimeFormat(
              utils.date.dateIncrement(cancelDate, -1, 'h'),
            ),
            policy: policy,
            termsAndConditions: termsAndConditions,
            popupLoad: false,
            penalty: penalty,
          },
        };
        this.setState(obj);
      })
      .catch((error) => {
        apiResponse.http.showErrorIfExists(error);

        let obj = {
          displayCancelationPolicy: {
            display: false,
            deadlineFormatted: '',
            policy: '',
            termsAndConditions: '',
            popupLoad: false,
          },
        };

        this.setState(obj);
      });
  }

  onCloseCancelationPolicyClicked() {
    let displayCancelationPolicy = {
      display: false,
      deadlineFormatted: '',
      policy: '',
      termsAndConditions: '',
      popupLoad: true,
    };

    this.setState({ displayCancelationPolicy });
  }
}
