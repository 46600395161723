'use strict';

import React from 'react';
import Template from './timer.template.js';

export default class TimerComponent extends React.Component {
  constructor(props) {
    super(props);

    let totalSeconds = this.props.totalSeconds ? this.props.totalSeconds : 0;

    this.state = {
      stop: this.props.stop,
      currentSeconds: !this.props.countdown ? 0 : this.props.totalSeconds,
      totalSeconds: totalSeconds,
      showProgressBar:
        this.props.showProgressBar === null ||
        this.props.showProgressBar === undefined
          ? true
          : this.props.showProgressBar,
    };
  }

  componentDidMount() {
    this.timerreference = this.tell();
  }

  componentWillUnmount() {
    clearInterval(this.timerreference);
  }

  render() {
    return (
      <Template
        showLabel={this.props.showLabel}
        description={this.props.description}
        showProgressBar={this.state.showProgressBar}
        countdown={this.props.countdown}
        totalSeconds={this.state.totalSeconds}
        currentSeconds={this.state.currentSeconds}
      />
    );
  }

  tell() {
    let time = setInterval(() => {
      let currentSeconds = this.state.currentSeconds;

      if (this.props.stop) {
        clearInterval(time);
        currentSeconds = !this.props.countdown ? 0 : this.props.totalSeconds;
      } else {
        if (!this.props.countdown) {
          if (currentSeconds < this.props.totalSeconds) ++currentSeconds;
          else {
            this.props.actionEnd && this.props.actionEnd(this.props.identifier);
            clearInterval(time);
          }
        } else {
          if (currentSeconds > 0) --currentSeconds;
          else {
            this.props.actionEnd && this.props.actionEnd(this.props.identifier);
            clearInterval(time);
          }
        }
      }
      this.setState({ currentSeconds: currentSeconds });
    }, 1000);

    return time;
  }
}
