import React, { useEffect } from 'react';
import {
  DatePicker,
  Typography,
  TextField,
  SelectField,
  Checkbox,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Control,
  Controller,
  FieldErrors,
  useWatch,
  useFormContext,
} from 'react-hook-form';
import * as SC from '@components/RequestTravel/RequestTravelItemOffline/styled';
import { SearchFieldLocation } from '@shared/components/SearchFieldLocation';
import { trpc } from '@api/bff/client';
import { useTravelItemStore } from '../../../store/RequestTravelItem.store';
import dayjs from 'dayjs';

interface TravelReasonProps {
  control: Control;
  errors: FieldErrors;
  title: string;
  namePrefix: string;
}

export function RentalCompanyInfo({
  errors,
  control,
  title,
  namePrefix,
}: TravelReasonProps) {
  const { setValue, getValues } = useFormContext();

  const dateFieldName = `${namePrefix}_Date`;
  const timeFieldName = `${namePrefix}_Time`;
  const cityFieldName = `${namePrefix}_City`;
  const addressFieldName = `${namePrefix}_Address`;
  const pickUpLocation = useTravelItemStore((state) => state.pickUpLocation);
  const returnLocation = useTravelItemStore((state) => state.returnLocation);
  const sameLocation = useTravelItemStore((state) => state.sameLocation);
  const setSameLocation = useTravelItemStore((state) => state.setSameLocation);

  const idCity =
    namePrefix === 'check_in' ? pickUpLocation?.i : returnLocation?.i;
  const cityById = idCity?.toString();

  const { data } = trpc.findRentalCompanies.useQuery(
    {
      cityById: cityById ?? 'defaultCityId',
    },
    {
      enabled: !!cityById,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 0,
      cacheTime: 0,
      retry: 0,
      queryKey: [
        'findRentalCompanies',
        { cityById: cityById ?? 'defaultCityId' },
      ],
    },
  );

  const selectOptions = data
    ? data.map((company) => ({
        value: company.id,
        label: company.name,
      }))
    : [];

  const checkInDate = useWatch({ control, name: 'vehicle.check_in_Date' });
  const minCheckoutDate = checkInDate ? dayjs(checkInDate).toDate() : null;

  useEffect(() => {
    if (sameLocation && namePrefix === 'checkout') {
      const checkInAddress = getValues('vehicle.check_in_Address');
      const checkInCity = getValues('vehicle.check_in_City');
      const checkInTime = getValues('vehicle.check_in_Time');

      console.log('checkInAddress', checkInAddress);
      console.log('checkInCity', checkInCity);
      console.log('checkInTime', checkInTime);

      setValue('vehicle.checkout_Address', checkInAddress, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('vehicle.checkout_City', checkInCity, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('vehicle.checkout_Time', checkInTime, {
        shouldValidate: true,
        shouldDirty: true,
      });

      // Atualizar a data de devolução para corresponder ao mês da data de retirada
      setValue('vehicle.checkout_Date', checkInDate, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [sameLocation, namePrefix, getValues, setValue, checkInDate]);

  return (
    <SC.ItemFormContainer>
      {namePrefix === 'checkout' && (
        <div>
          <Checkbox
            checked={sameLocation}
            onChange={(e) => setSameLocation(e.target.checked)}
          />
          Devolver no mesmo local da retirada
        </div>
      )}
      <div style={{ marginBottom: '20px' }}>
        <Typography variant={'headline8'} variantColor={'#3B2E37'}>
          {title}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '33%' }}>
          <Controller
            name={`vehicle.${dateFieldName}`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                onDateChange={(date) => {
                  field.onChange(date);
                }}
                label="Data"
                error={!!error}
                helperText={error ? error.message : null}
                disabledDate={(date) =>
                  namePrefix === 'check_in'
                    ? dayjs(date).isBefore(dayjs(), 'day')
                    : namePrefix === 'checkout' && minCheckoutDate
                    ? dayjs(date).isBefore(dayjs(minCheckoutDate), 'day')
                    : false
                }
              />
            )}
          />
        </div>
        <div style={{ width: '33%' }}>
          <Controller
            name={`vehicle.${timeFieldName}`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...(field as any)}
                type="time"
                fullWidth
                label="Hora"
                error={!!error}
                helperText={error ? error.message : null}
                value={field.value || ''}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
        </div>
        <div style={{ width: '33%' }}>
          <Controller
            name={`vehicle.${cityFieldName}`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <SearchFieldLocation
                {...field}
                errors={errors}
                name={`vehicle.${cityFieldName}`}
                label={'Cidade'}
                defaultValue={sameLocation ? pickUpLocation?.i : field.value}
                locationType={
                  namePrefix === 'check_in' ? 'check_in' : 'checkout'
                }
                helperText={error ? error.message : null}
                disabled={sameLocation && namePrefix === 'checkout'}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Controller
          name={`vehicle.${addressFieldName}`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <SelectField
              {...(field as any)}
              options={selectOptions}
              onSelectChange={(event, selectedItem) => {
                field.onChange(selectedItem);
              }}
              label="Endereço"
              fullWidth
              disabled={
                !cityById || (sameLocation && namePrefix === 'checkout')
              }
              error={!!error}
              helperText={error ? error.message : null}
              defaultValue={sameLocation ? pickUpLocation?.n : field.value}
            />
          )}
        />
      </div>
    </SC.ItemFormContainer>
  );
}
