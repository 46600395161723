import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FieldErrors } from 'react-hook-form';
import { SearchField } from '@flash-tecnologia/hros-web-ui-v2';
import { findLocations, getLocation } from '@api/connectOn/connectOn.service';
import { LocationTypes } from '../../../types/Location.types';
import { useTravelItemStore } from '../../../store/RequestTravelItem.store';

interface SearchFieldLocationProps {
  errors: FieldErrors;
  name: string;
  label: string;
  type?: number;
  defaultValue?: number;
  locationType?: any;
  helperText?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
}

interface OptionsLocation {
  value: string;
  label: string;
}

export function SearchFieldLocation({
  errors,
  name,
  label,
  type = 4,
  defaultValue,
  locationType,
  helperText,
  onChange,
  disabled,
}: SearchFieldLocationProps) {
  const [options, setOptions] = useState<OptionsLocation[]>([]);
  const [locationsData, setLocationsData] = useState<LocationTypes[]>([]);
  const [selectedLocation, setSelectedLocation] =
    useState<LocationTypes | null>(null);
  const [selectedValue, setSelectedValue] = useState<OptionsLocation | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const debounce = useRef<NodeJS.Timeout>();
  const updateLocation = useTravelItemStore((state) => state.updateLocation);
  const updateDefaultLocation = useTravelItemStore(
    (state) => state.updateDefaultLocation,
  );

  const getLocationById = useCallback(
    async (locationId: number) => {
      setLoading(true);
      const response = await getLocation(locationId);
      if (response?.successful && response.l) {
        const option = { label: response.l.n, value: locationId.toString() };

        setLocationsData([response.l]);

        if (locationType)
          updateLocation(locationType, { ...response.l, i: locationId });
        else updateDefaultLocation({ ...response.l, i: locationId });

        setOptions([option]);
        setSelectedValue(option);
      } else {
        setOptions([]);
        setLocationsData([]);
      }
      setLoading(false);
    },
    [
      setOptions,
      setLocationsData,
      setSelectedValue,
      updateLocation,
      updateDefaultLocation,
      locationType,
    ],
  );

  const fetchLocations = useCallback(
    async (value: string) => {
      setLoading(true);
      try {
        const response = await findLocations({
          keyword: value,
          types: [type],
          scope: 'all',
        });

        if (response && response.successful && response.l) {
          const fetchedOptions = response.l.map((item) => ({
            label: item.n,
            value: item.i,
          }));
          setOptions(fetchedOptions);
          setLocationsData(response.l);

          const defaultOption = fetchedOptions.find(
            (option) => option.value === defaultValue?.toString(),
          );
          if (defaultOption) {
            setSelectedValue(defaultOption);
            const fullLocationData = response.l.find(
              (loc) => loc.i === defaultValue,
            );
            if (fullLocationData) {
              setSelectedLocation(fullLocationData);
              if (locationType) {
                updateLocation(locationType, fullLocationData);
              } else {
                updateDefaultLocation(fullLocationData);
              }
            }
          }
        } else {
          setOptions([]);
          setLocationsData([]);
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
        setOptions([]);
        setLocationsData([]);
      } finally {
        setLoading(false);
      }
    },
    [
      defaultValue,
      selectedValue,
      selectedLocation,
      updateLocation,
      setOptions,
      setLocationsData,
    ],
  );

  const handleSearchChange = useCallback(
    (event, selectedOption) => {
      if (selectedOption) {
        const locationData = locationsData.find(
          (loc) => loc.n === selectedOption.label,
        );
        if (locationType) {
          updateLocation(locationType, locationData);
        } else {
          updateDefaultLocation(locationData);
        }
      }
      onChange?.(selectedOption?.value);
    },
    [
      locationsData,
      updateLocation,
      updateDefaultLocation,
      locationType,
      onChange,
    ],
  );

  const handleInputChange = useCallback(
    (event, newValue, reason) => {
      if (debounce.current) clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        if (
          reason !== 'reset' &&
          reason !== 'clear' &&
          newValue.trim() !== ''
        ) {
          fetchLocations(newValue);
        }
      }, 300);
    },
    [fetchLocations],
  );

  useEffect(() => {
    return () => {
      if (debounce.current) clearTimeout(debounce.current);
    };
  }, []);

  useEffect(() => {
    if (defaultValue) {
      getLocationById(defaultValue);
    }
  }, [getLocationById, defaultValue]);

  return (
    <SearchField
      options={options}
      loading={loading}
      value={selectedValue ? selectedValue.label : ''}
      onInputChange={handleInputChange}
      onSearchChange={handleSearchChange}
      label={label}
      error={!!errors[name]}
      helperText={helperText}
      disabled={disabled}
    />
  );
}
