import FlightDetails from '../flight-details/flight-details.component.js';

var Template = {
  main: ({ flights, fareData, actions }) => {
    return (
      <div className="exon-travel-option">
        <div className="exon-travel-option-box">
          {flights.map((flight, index) => {
            return (
              <div key={index} className="exon-travel-option-flight">
                <div className="exon-travel-option-flight-identifier">
                  <span>{flight.flightPart}</span>
                </div>

                <FlightDetails flight={flight} />
              </div>
            );
          })}
        </div>

        {fareData && <Template.fare fareData={fareData} actions={actions} />}
      </div>
    );
  },

  fare: ({ fareData, actions }) => {
    return (
      <div className="exon-travel-option-fare-box">
        <div className="exon-travel-option-fare">
          <div className="exon-product-option-features">
            {fareData.agreementApplied && (
              <span
                title={`${fareData.agreementCode}`}
                class="exon-icon exon-icon-policy-wake-up"
              ></span>
            )}
          </div>
          <div className="exon-travel-option-fare-value">
            <p>
              <span>{fareData.fare.currencySymbol}</span>
              {fareData.fare.value}
            </p>
          </div>
        </div>
        <span className="exon-travel-option-taxes">
          <p>
            Taxa de embarque
            <strong>{fareData.taxes}</strong>
          </p>
        </span>
        <span className="exon-travel-option-services">
          <p>
            Taxa de serviços
            <strong>{fareData.service}</strong>
          </p>
        </span>
        <span className="exon-travel-option-total">
          <p>
            <strong>Total 1 passageiro</strong>
            <span>{fareData.total}</span>
          </p>
        </span>
        {actions && (
          <div className="exon-travel-option-fare-actions">{actions}</div>
        )}
      </div>
    );
  },
};

export default Template;
