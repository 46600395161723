import React from 'react';
import Template from './split-grid-cell.template';

export default class SplitGridCellComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      value: this.formatValues(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.setState({ value: this.formatValues() });
  }

  formatValues() {
    if (this.props.type === 'money') return `${this.props.value}`;
    if (this.props.type === 'percentage')
      return `${this.props.value.toFixed(2).replace('.', ',')} %`;
  }

  changeValue(evt) {
    this.setState({ value: evt.target.value });
  }

  editAction(ref) {
    this.setState({ isEditing: true }, () => {
      ref.current.focus();
    });
    this.props.editAction();
  }

  saveAction() {
    if (this.state.value.replace(/\D/g, '')) {
      this.props.saveAction(this.props.id, this.state.value);
    }

    this.setState({ isEditing: false, value: this.formatValues() });
  }

  render() {
    const { lastEdit, id, type, remaining } = this.props;
    const lastEditError =
      remaining < 0 &&
      lastEdit.key === id &&
      [type, 'both'].includes(lastEdit.field);

    return (
      <Template.main
        {...this.props}
        lastEditError={lastEditError}
        parsedValue={parseFloat(
          `${this.props.value || 0}`.replace(/[^0-9,]/g, '').replace(',', '.'),
        )}
        editAction={this.editAction.bind(this)}
        saveAction={this.saveAction.bind(this)}
        changeValue={this.changeValue.bind(this)}
        isEditing={this.state.isEditing}
        value={this.state.value}
      />
    );
  }
}
