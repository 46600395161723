let Template = (props) => (
  <p
    className={`exon-text-block ${props.className}`}
    style={{ fontSize: props.fontSize ? `${props.fontSize}px` : '10px' }}
  >
    {props.text}
  </p>
);

export default Template;
