import * as SC from '../styled';

interface ModalHeaderUpperItemProps {
  /**
   * Text or custom item in header
   *
   */
  children: React.ReactNode;
}
export function ModalHeaderUpperItem({ children }: ModalHeaderUpperItemProps) {
  return <SC.HeaderUpperItem>{children}</SC.HeaderUpperItem>;
}
