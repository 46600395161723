import { enums } from '@legacy-utils/enums';
import { creditCardUtil } from '@legacy-utils/creditCardUtil';

let BookingPaymentTemplate = ({ paymentForms }) => {
  return (
    <div className="exon-external-booking-locator">
      <div className="box-styleless-table">
        <table className="exon-styleless-table">
          <thead>
            <tr>
              <th>Forma de Pagamento</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {paymentForms.map((paymentForm, index) => {
                  return `${enums.paymentType[paymentForm.type]} ${
                    paymentForm.creditCard
                      ? ` Final ${creditCardUtil.finalCreditCardNumber(
                          paymentForm.creditCard.number,
                        )}`
                      : ''
                  }`;
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingPaymentTemplate;
