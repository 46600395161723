import React from 'react';
import Template from './split.template';
import ErrorBoundary from '../common/error-boundary/error-boundary.component';
import { enums } from '../../utils/enums';
import { utils } from '../../utils/utils';
import SplitActions from './split-actions.component';
import SplitGridCell from './split-grid-cell.component';
import SplitGridCellCostCenter from './split-grid-cell-cost-center.component';
import { equalizeSplit } from './split-methods';

export default class SplitComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { lastEdit: { key: null, field: '' } };

    this.MAX_SPLIT = 3;
    this.MIN_SPLIT = 1;
  }

  componentDidMount() {
    const { data, edit, equalSplit, selected, total } =
      this.props.costCenterSplit;

    if (!data.length) {
      edit(
        equalizeSplit(
          [
            {
              key: Math.floor(Math.random() * 10000),
              costCenter: selected || null,
            },
            {
              key: Math.floor(Math.random() * 10000),
              costCenter: null,
            },
          ],
          total.value,
          total?.currencyCode || enums.currencyCode.real,
        ),
        equalSplit,
      );
    }
  }

  buildItem({ key, value, percentage, costCenter }) {
    return {
      key: key,
      value: (
        <SplitGridCell
          id={key}
          value={value.formatted}
          type="money"
          remaining={this.remaining()}
          lastEdit={this.state.lastEdit}
          editAction={() => this.toggleEqualSplit(false)}
          saveAction={this.editItemValue.bind(this)}
        />
      ),
      percentage: (
        <SplitGridCell
          id={key}
          value={percentage}
          type="percentage"
          remaining={this.remaining()}
          lastEdit={this.state.lastEdit}
          editAction={() => this.toggleEqualSplit(false)}
          saveAction={this.editItemPercentage.bind(this)}
        />
      ),
      costCenter: (
        <SplitGridCellCostCenter
          id={key}
          options={this.props.costCenterSplit.options}
          dataSource={this.props.costCenterSplit.dataSource}
          editAction={this.editItemCostCenter.bind(this)}
          selected={costCenter}
          filter={((filter) => {
            return !this.props.costCenterSplit.data
              .map((item) => item?.costCenter?.value)
              .includes(filter.value);
          }).bind(this)}
        />
      ),
      action: (
        <SplitActions
          item={key}
          canDuplicate={this.props.costCenterSplit.data.length < this.MAX_SPLIT}
          canDelete={this.props.costCenterSplit.data.length > this.MIN_SPLIT}
          duplicateLine={this.duplicateItem.bind(this)}
          deleteLine={this.removeItem.bind(this)}
        />
      ),
    };
  }

  remaining() {
    return this.validation(this.props.costCenterSplit).value;
  }

  validation({ data, total }) {
    let value = total.value;
    let costCenter = true;
    let percentage = 100;

    for (let i = 0; i < data.length; i++) {
      value = parseFloat((value - data[i].value.value).toFixed(2));
      costCenter &&= !!data[i].costCenter;
      percentage = parseFloat((percentage - data[i].percentage).toFixed(2));
    }

    return { value, costCenter, percentage };
  }

  isValid() {
    const validation = this.validation(this.props.costCenterSplit);
    return validation.costCenter && !validation.value && !validation.percentage;
  }

  duplicateItem(key) {
    const { data, edit } = this.props.costCenterSplit;
    const item = data.find((item) => item.key === key);
    if (!item) return;

    const newKey = Math.floor(Math.random() * 10000);
    this.setState({ lastEdit: { key: newKey, field: 'both' } });

    edit([...data, { ...item, key: newKey, costCenter: '' }], false);
  }

  editItemValue(key, newValue) {
    const { data, edit, equalSplit, total } = this.props.costCenterSplit;

    this.setState({ lastEdit: { key, field: 'money' } });

    edit(
      data.map((item) => {
        if (item.key !== key) return item;

        const amount = parseFloat(
          newValue.replace(/[^0-9,]/g, '').replace(',', '.'),
        );
        const value = new utils.money(
          amount > total.value ? total.value : amount,
          item.value.currencyCode,
        );
        const percentage = parseFloat(
          ((value.value * 100) / total.value).toFixed(2),
        );

        return { ...item, value, percentage };
      }),
      equalSplit,
    );
  }

  editItemPercentage(key, newPercentage) {
    const { data, edit, equalSplit, total } = this.props.costCenterSplit;
    let sum = 0;
    let sumPercentage = 0;
    const newItems = data.map((item) => {
      if (item.key !== key) {
        sumPercentage += item.percentage;
        sum += item.value.value;
        return item;
      }

      let percentage = parseFloat(
        newPercentage.replace(/[^0-9,]/g, '').replace(',', '.'),
      );
      percentage = percentage > 100 ? 100 : percentage;
      sumPercentage += percentage;

      const value = new utils.money(
        total.value * parseFloat((percentage / 100).toFixed(4)),
        total.currencyCode,
      );

      return { ...item, value, percentage };
    });

    if (parseFloat(sumPercentage.toFixed(2)) === 100) {
      const item = newItems.find((item) => item.key === key);
      item.value = new utils.money(total.value - sum, total.currencyCode);
    }

    this.setState({ lastEdit: { key, field: 'percentage' } });

    edit(newItems, equalSplit);
  }

  editItemCostCenter(key, costCenter) {
    const { data, edit, equalSplit } = this.props.costCenterSplit;

    edit(
      data.map((item) => (item.key !== key ? item : { ...item, costCenter })),
      equalSplit,
    );
  }

  removeItem(key) {
    const { data, edit, equalSplit, total } = this.props.costCenterSplit;
    if (data.length <= this.MIN_SPLIT) return;

    edit(
      this.checkEqualSplit(
        [...data.filter((item) => item.key !== key)],
        total.value,
      ),
      equalSplit,
    );
  }

  addItem() {
    const { data, edit, equalSplit, total } = this.props.costCenterSplit;
    if (data.length >= this.MAX_SPLIT) return;

    const [value, percentage] = data.reduce(
      ([value, percentage], item) => {
        return [value + item.value.value, percentage + item.percentage];
      },
      [0, 0],
    );
    const newKey = Math.floor(Math.random() * 10000);
    const newItem = {
      key: newKey,
      costCenter: null,
      value: new utils.money(0, total.currencyCode),
      percentage: 0,
    };

    if (total.value - value > 0) {
      newItem.value.value = total.value - value;
      newItem.percentage = 100 - percentage;
    }

    this.setState({ lastEdit: { key: newKey, field: 'both' } });

    edit(
      this.checkEqualSplit(
        [...data, newItem],
        utils.floatingPointFix(total.value + newItem.value.value),
      ),
      equalSplit,
    );
  }

  checkEqualSplit(data, total) {
    if (!this.props.costCenterSplit.equalSplit) return data;
    return equalizeSplit(data, total);
  }

  toggleEqualSplit(toggle) {
    const { data, edit, total } = this.props.costCenterSplit;
    edit(toggle ? equalizeSplit(data, total.value) : data, toggle);
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          {...this.props}
          addItem={this.addItem.bind(this)}
          buildItem={this.buildItem.bind(this)}
          toggleEqualSplit={this.toggleEqualSplit.bind(this)}
          maxedOut={this.props.costCenterSplit.data.length >= this.MAX_SPLIT}
        />
      </ErrorBoundary>
    );
  }
}
