export const formatterUtil = {
  stringReduce: function (text, length) {
    //Reduz uma string para o tamanho especificado sem cortar palavras ao meio. Add reticencias
    if (text)
      return text.substring(0, length) + (text.length > length ? '...' : '');
  },

  dateObjToDate: function (dateReceived) {
    //Obs (mes - 1) pois o Date no JS carrega de 0 and 11
    if (dateReceived) {
      if (dateReceived.hour || dateReceived.minute) {
        return new Date(
          dateReceived.year,
          dateReceived.month - 1,
          dateReceived.day,
          dateReceived.hour,
          dateReceived.minute,
        );
      } else {
        return new Date(
          dateReceived.year,
          dateReceived.month - 1,
          dateReceived.day,
        );
      }
    }
  },

  dateTimeFormat: function (dateReceived) {
    //Obs (mes + 1) pois o Date no JS carrega de 0 and 11
    if (dateReceived) {
      return (
        dateReceived.getDate().toString().padStart(2, 0) +
        '/' +
        (dateReceived.getMonth() + 1).toString().padStart(2, 0) +
        '/' +
        dateReceived.getFullYear() +
        ' ' +
        dateReceived.getHours() +
        ':' +
        dateReceived.getMinutes().toString().padStart(2, 0)
      );
    }
  },

  formatToDayMonth: function (dateReceived) {
    return (
      dateReceived.getDate().toString().padStart(2, 0) +
      '/' +
      (dateReceived.getMonth() + 1).toString().padStart(2, 0)
    );
  },

  formatMoney: function (moneyObj) {
    if (moneyObj) {
      let currencySymbol =
        moneyObj.currencyCode == 'BRL'
          ? 'R$'
          : moneyObj.currencyCode == 'USD'
          ? 'US$'
          : '€';
      let valueFormatted = moneyObj.value.toLocaleString('pt-BR');

      return `${currencySymbol} ${valueFormatted}`;
    }
    return '';
  },
};
