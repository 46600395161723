import { enums } from '../../../../utils/enums';
import { utils } from '../../../../utils/utils';

var Template = {
  main: ({ flight }) => {
    return (
      <div className="exon-flight">
        <Template.details.cia flight={flight} />

        <div className="exon-flight-detail-item">
          <Template.details.date data={flight.departureDate} />

          <Template.details.class data={flight.cabinType} />
        </div>

        <Template.details.airport data={flight.departure} />

        <Template.details.flightDetails
          data={{
            stops: flight.stops,
            duration: flight.duration,
            number: flight.flightNumber,
            flights: flight.flights,
          }}
        />

        <Template.details.airport
          data={flight.arrival}
          departureDate={flight.departure.date}
        />

        <Template.details.baggage data={flight.bagagge} />
        {flight.flightNumber || flight.supplier ? (
          <Template.details.toolTip flight={flight} />
        ) : (
          ''
        )}
      </div>
    );
  },

  details: {
    cia: ({ flight }) => {
      return (
        <img
          src={flight.airLine.imageUrl}
          alt={flight.airLine.name}
          className="exon-logo-airline"
        ></img>
      );
    },

    date: ({ data }) => {
      return (
        <div className="exon-departure-date">
          <p>
            {data.dayOfWeek},
            <br />
            <span>{data.dateShortFormatted}</span>
          </p>
        </div>
      );
    },

    class: ({ data }) => {
      return (
        <div className="exon-flight-class">
          <p>
            classe
            <span>{enums.airClasses[data]}</span>
          </p>
        </div>
      );
    },

    airport: ({ data, departureDate }) => {
      let daysDifference = null;
      if (departureDate)
        daysDifference = utils.date.dayDiff(
          utils.date.strDateToDate(departureDate, '/'),
          utils.date.strDateToDate(data.date, '/'),
        );

      return (
        <div className="exon-airport-details">
          <div>
            <span className="exon-airport-iata">{data.iata}</span>
            <span className="exon-airport-hour">
              {data.hour}
              {daysDifference && daysDifference > 0 ? (
                <i>+{daysDifference}</i>
              ) : (
                ''
              )}
            </span>
          </div>
          <p className="exon-airport-name">{data.name}</p>
        </div>
      );
    },

    flightDetails: ({ data }) => {
      let stops = data.stops;

      return (
        <div
          className={`exon-flight-information ${
            data.stops > 0 ? 'exon-extra-information' : ''
          }`}
        >
          {data.stops > 0 && (
            <div className="exon-flight-stop-details">
              {data.flights.map((flight, index) => {
                let departure =
                  utils.formatters.time.durationHoursMinuteFromDateObj(
                    flight.departure.date,
                  );
                let arrival =
                  utils.formatters.time.durationHoursMinuteFromDateObj(
                    flight.arrival.date,
                  );

                return (
                  <div key={index}>
                    <span>
                      Voo {flight.flightNumber} - {flight.operatedBy.name}{' '}
                      {flight.operatedBy.name != flight.airLine.name
                        ? `(${flight.airLine.name})`
                        : ''}
                    </span>
                    <span>
                      <span className="exon-flight-iata">
                        {flight.departure.iata}
                      </span>{' '}
                      {departure} - {arrival}{' '}
                      <span className="exon-flight-iata">
                        {flight.arrival.iata}
                      </span>
                    </span>
                    {flight.layover != 0 && (
                      <div className="exon-flight-layover">
                        <span className="exon-flight-layover-description">
                          Parada de {flight.layover} em{' '}
                          {flight.arrival.fullName.split('/')[0]} (
                          {flight.arrival.iata}) para troca de avião
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className="exon-flight-details">
            <span className="exon-stops-description">
              {stops == 0
                ? 'vôo direto'
                : stops > 1
                ? stops + ' paradas'
                : stops + ' parada'}
            </span>
            <span className="exon-stops-icons">
              <span className="exon-icon-stops">
                <span
                  className={
                    'exon-icon-stops-item' + (stops == 0 ? ' hide' : '')
                  }
                ></span>
              </span>
            </span>
            <span className="exon-stops-duration">{data.duration}</span>
          </div>
        </div>
      );
    },

    baggage: ({ data }) => {
      return (
        <div className="exon-baggage">
          <div className="exon-baggage-icons">
            <span className="exon-icon exon-icon-baggages1-filled_disabled"></span>
          </div>
          <div className="exon-baggage-descriptions">
            {data.descriptions.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
          </div>
        </div>
      );
    },
    toolTip: ({ flight }) => {
      return (
        <span className="exon-icon exon-icon-info-unfilled_disabled exon-tooltip">
          <div className="tooltip">
            {flight.flightNumber && (
              <span>
                Nº vôo:{' '}
                <b>{flight.flightNumber.toString().replace(',', ' / ')}</b>
              </span>
            )}
            {flight.supplier && (
              <span>
                Fornecedor: <b>{flight.supplier}</b>
              </span>
            )}
          </div>
        </span>
      );
    },
  },
};

export default Template;
