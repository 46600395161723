import { CancelationPolicyModal } from '@components/Products/Hotel/Booking/CancelationPolicyModal/CancelationPolicyModal';

let HotelBookingInfosTemplate = ({ hotelBookingInfos }) => {
  let empty = '-';

  return (
    <div className="exon-hotel-booking-infos">
      <div className="box-styleless-table">
        <table className="exon-styleless-table">
          <thead>
            <tr>
              <th>Localizador</th>
              {hotelBookingInfos.confirmedBy && <th>Confirmado Por</th>}
              <th>Data de Expiração</th>
              <th>Sistema</th>
              <th>Hotel</th>
              <th>Quarto</th>
              <th>Politica de cancelamento</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={hotelBookingInfos.roomsName.length}>
                {hotelBookingInfos.locator &&
                !hotelBookingInfos.locatorToBeConfirmed
                  ? hotelBookingInfos.locator
                  : 'A confirmar'}
                {hotelBookingInfos.bookingStatus &&
                !hotelBookingInfos.locatorToBeConfirmed
                  ? ` (${hotelBookingInfos.bookingStatus})`
                  : ''}
              </td>
              {hotelBookingInfos.confirmedBy && (
                <td>
                  {hotelBookingInfos.confirmedBy
                    ? hotelBookingInfos.confirmedBy
                    : empty}
                </td>
              )}
              <td rowSpan={hotelBookingInfos.roomsName.length}>
                {hotelBookingInfos.expirationDate &&
                !hotelBookingInfos.isDateValueDefault
                  ? hotelBookingInfos.expirationDate
                  : hotelBookingInfos.isDateValueDefault
                  ? 'A confirmar'
                  : empty}
              </td>
              <td rowSpan={hotelBookingInfos.roomsName.length}>
                {hotelBookingInfos.bookingSystem
                  ? hotelBookingInfos.bookingSystem
                  : '[ND]'}
              </td>
              <td rowSpan={hotelBookingInfos.roomsName.length}>
                {hotelBookingInfos.hotelName
                  ? hotelBookingInfos.hotelName
                  : empty}
              </td>

              {hotelBookingInfos.roomsName
                ? hotelBookingInfos.roomsName.map((name, index) => {
                    return <td key={index}>{name}</td>;
                  })
                : empty}

              {
                hotelBookingInfos.cancelationPolicies &&
                  <td><CancelationPolicyModal hotelName={hotelBookingInfos.hotelName} policy={hotelBookingInfos.cancelationPolicies[0]}/></td>
              }

              {hotelBookingInfos.allowedActions.canConfirmData && (
                <td className="exon-confirm-data">
                  <button
                    className="exon-button exon-button-primary small"
                    type="button"
                    onClick={() =>
                      hotelBookingInfos.confirmDataEvents.onShowPopupClicked()
                    }
                  >
                    Confirmar Dados
                  </button>
                </td>
              )}

              {hotelBookingInfos.allowedActions.canConfirmIssuance && (
                <td className="exon-confirm-data">
                  <button
                    className="exon-button exon-button-primary small"
                    type="button"
                    onClick={() =>
                      hotelBookingInfos.confirmIssuanceEvents.onShowPopupClicked()
                    }
                  >
                    Confirmar Emissão
                  </button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HotelBookingInfosTemplate;
