import { TextField, TextFieldProps } from '@flash-tecnologia/hros-web-ui-v2';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

type Props = Omit<
  NumericFormatProps,
  'customInput' | 'prefix' | 'thousandSeparator' | 'decimalSeparator'
> &
  TextFieldProps;

export const CurrencyInput = (props: Props) => {
  return (
    <NumericFormat
      prefix="R$"
      customInput={TextField}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      maxLength={12}
      {...props}
    />
  );
};
