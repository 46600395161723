import React from 'react';
import { utils } from '../../utils/utils';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

const Template = {
  main: ({ total, split }) => {
    const remaining = new utils.money(
      total.value -
        (split || []).reduce((sum, item) => sum + item.value.value, 0),
      total.currencyCode,
    );

    return (
      <div className="exon-split-total">
        <div className="exon-split-trip-total">
          <p>
            <strong>
              {total.currencySymbol} {total.formatted}
            </strong>
          </p>
          <p>Valor total da viagem</p>
        </div>
        <div className="exon-split-trip-remaining">
          <p className={remaining.value === 0 ? null : 'exon-error'}>
            <strong>
              {remaining.currencySymbol} {remaining.formatted}
            </strong>
          </p>
          <span>
            {remaining.value >= 0 ? (
              <p>Valor restante para divisão</p>
            ) : (
              <>
                <Icons
                  className="exon-error-icon"
                  fill="transparent"
                  name="IconAlertCircle"
                  size={16}
                />
                <p> Valor excedeu o total</p>
              </>
            )}
          </span>
        </div>
      </div>
    );
  },
};

export default Template;
