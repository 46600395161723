let Template = ({
  showLabel,
  description,
  currentSeconds,
  totalSeconds,
  countdown,
  showProgressBar,
}) => {
  let hours = Math.floor(currentSeconds / 3600);
  let minutes = Math.floor((currentSeconds - hours * 3600) / 60);
  let seconds = currentSeconds - hours * 3600 - minutes * 60;

  return (
    <div className="exon-timer">
      <div
        className={
          'exon-timer-box' +
          ((!countdown && currentSeconds == totalSeconds) ||
          (countdown && currentSeconds == 0)
            ? ' exon-timer-warning'
            : '')
        }
      >
        {showProgressBar && (
          <progress value={currentSeconds} max={totalSeconds}></progress>
        )}

        {showLabel && (
          <div className="exon-timer-label">
            <p>
              {description ? description : ''}
              <strong>
                {` ${hours > 0 ? hours + ':' : ''}${
                  minutes <= 9 ? '0' + minutes : minutes
                }:${seconds <= 9 ? '0' + seconds : seconds}`}
              </strong>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default Template;
