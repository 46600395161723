import React from 'react';
import MonitoringFilterTemplate from './monitoring-filter.template.js';
import { apiResponse } from '@legacy-utils/apiResponse';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class MonitoringFilterComponent extends React.Component {
  constructor(props) {
    super(props);
    componentUtil.react.bindMethods(this.components, this);
    componentUtil.react.bindMethods(this.components.viewBy, this);
    componentUtil.react.bindMethods(this.components.types, this);
    componentUtil.react.bindMethods(this.components.company, this);
    componentUtil.react.bindMethods(this.components.location, this);

    this.state = {
      viewBySelected: 1,
      viewByLabel: 'Hora de Expiração',
      typeSelected: { value: 'servicerequest', label: 'RV' },
      companySelected: 0,
      companyItems: [],
      location: {},
    };
  }

  componentDidMount() {
    if (this.props.agency.isAgency) this.dataSource.companies.load();
  }

  render() {
    return (
      <MonitoringFilterTemplate.main
        isAgency={this.props.agency.isAgency}
        viewByProps={this.components.viewBy.getProps()}
        typesProps={this.components.types.getProps()}
        companyProps={this.components.company.getProps()}
        companySelected={this.state.companySelected}
        locationProps={this.components.location.getProps()}
        isLocationEmpty={this.isEmpty(this.state.location)}
        onApplyClick={this.events.onApplyClicked.bind(this)}
        onClearSelectedCompanyClick={this.events.onClearSelectedCompanyClicked.bind(
          this,
        )}
        onClearSelectedLocationClick={this.events.onClearSelectedLocationClicked.bind(
          this,
        )}
      />
    );
  }

  dataSource = {
    locations: {
      load: (loadOptions) => {
        const dataSource = this.props.dataSources.locations;
        return new Promise((resolve, reject) => {
          dataSource.load(loadOptions).then((items) => {
            resolve(items);
          });
        });
      },
    },
    companies: {
      load: () => {
        const _this = this;
        const dataSource = this.props.dataSources.companies;

        if (dataSource.events && dataSource.events.beforeLoad)
          dataSource.events.beforeLoad();

        dataSource
          .load()
          .then((company) => {
            _this.setState({
              companyItems: company,
            });

            if (dataSource.events && dataSource.events.afterLoad)
              dataSource.events.afterLoad(company);
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(
              error,
              'Ocorreu um erro ao buscar os registro de viagens.',
            );

            if (dataSource.events && dataSource.events.afterLoad)
              dataSource.events.afterLoad(error);
          });
      },
    },
  };

  components = {
    viewBy: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.viewBySelected,
          options: {
            events: {
              onSelected: function (selectedItem) {
                _this.setState({
                  viewBySelected: selectedItem.value,
                  viewByLabel: selectedItem.label,
                });
              },
            },
            dataSource: {
              load: function (loadOptions) {
                return Promise.resolve([
                  { value: 1, label: 'Hora de Expiração' },
                  { value: 2, label: 'Data de Embarque/Checkin' },
                  { value: 3, label: 'Data de Criação' },
                ]);
              },
              events: {
                beforeLoad: function (loadOptions) {},
                afterLoad: function (items) {},
              },
            },
          },
        };
      },
    },
    types: {
      getProps: function () {
        let _this = this;
        let types = [
          { value: 'servicerequest', label: 'RV' },
          { value: 'airbooking', label: 'Aéreo' },
          { value: 'hotelbooking', label: 'Hotel' },
        ];

        return {
          data: this.state.typeSelected,
          options: {
            items: types,
            events: {
              onChanged: function (item) {
                _this.setState({ typeSelected: item });
              },
            },
          },
        };
      },
    },
    company: {
      getProps: function () {
        let _this = this;
        const companyItems = this.state.companyItems;

        return {
          data: _this.state.companySelected,
          placeholder:
            companyItems.length > 0
              ? 'Escolha Empresa'
              : 'Não existem empresas sendo agenciadas',
          options: {
            items: companyItems,
            dataSource: {
              load: function () {
                return Promise.resolve(companyItems);
              },
            },
            events: {
              onSelected: function (selectedItems) {
                _this.setState({
                  companySelected: selectedItems.value,
                });
              },
            },
          },
        };
      },
    },
    location: {
      getProps: function () {
        let _this = this;
        return {
          data: _this.state.location,
          placeholder: 'Informe nome da localidade',
          options: {
            events: {
              onInputChanged: (inputText) => {
                let data = _this.state.location || {};
                data.name = inputText;
                _this.setState({ location: data });
              },
              onSelected: (data) => {
                _this.setState({
                  location: data,
                });
              },
            },
            dataSource: _this.props.dataSources.locations,
          },
        };
      },
    },
  };

  events = {
    onApplyClicked() {
      let obj = {
        filter: {
          viewBy: {
            value: this.state.viewBySelected,
            label: this.state.viewByLabel,
          },
          travelType: this.state.typeSelected.value,
          company: {
            contextId:
              this.state.companySelected != 0 ? this.state.companySelected : 0,
          },
          locationId: this.state.location.id ? this.state.location.id : 0,
        },
      };

      if (this.props.onApplyFilterClicked) this.props.onApplyFilterClicked(obj);
    },

    onClearSelectedCompanyClicked() {
      this.setState({ companySelected: 0 });
    },

    onClearSelectedLocationClicked() {
      this.setState({ location: {} });
    },
  };

  isEmpty(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }
}
