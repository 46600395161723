import styled from 'styled-components';
import { Divider } from '@flash-tecnologia/hros-web-ui-v2';

export const Choices = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 1.5rem;
`;
export const Choice = styled.button``;

export const ReportData = styled.div`
  width: 100%;
  padding: 1.5rem;
`;

export const GridHeader = styled.div``;

export const ReportFiltering = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 10px;
`;

export const Divisor = styled(Divider)`
  margin: 10px 0;
`;
