import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #ebe6e9;
  padding: 1rem;
  flex: 48%;
  font-weight: 700;
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 7rem;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Section = styled.section`
  padding: 0 0 0 0;
  top: -10rem;
  position: relative;
`;

export const Title = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    align-text: center;
    font-size: 24px;
  }
`;
export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4rem 0;
`;
export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  padding: 0 30px 0 30px;
  width: 100%;
  margin: 0 auto;
  flex: 1;

  @media screen and (max-width: 992px) {
    padding: 60px 80px 0 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 60px 24px 0 24px;
  }
`;
