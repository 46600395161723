import React from 'react';
import { FieldErrors, FieldError } from 'react-hook-form';
import * as SC from './styled';

interface DisplayErrorFormValidationProps {
  errors: FieldErrors | FieldError;
  fieldName: string;
}

export default function DisplayErrorFormValidation({
  errors,
  fieldName,
}: DisplayErrorFormValidationProps) {
  function getErrorMessage(errors, path) {
    const parts = path.split('.');
    let current = errors;
    for (const part of parts) {
      if (!current) return null;
      current = current[part];
    }
    return current?.message || null;
  }

  // Use a função getErrorMessage para obter a mensagem de erro
  const errorMessage = getErrorMessage(errors, fieldName);

  return (
    <>
      {errorMessage && (
        <SC.DisplayErrorContainer>
          <span>{errorMessage}</span>
        </SC.DisplayErrorContainer>
      )}
    </>
  );
}
