import RoomCard from '../../room-card/room-card.component.js';
import Icon from '../../../../../common/icon/icon.component.js';

let Template = (props) => {
  return (
    <div className="exon-hotel-rooms-list">
      {props.rooms.length > 0 &&
        props.rooms.map((room) => {
          return (
            <RoomCard
              key={`room${room.index}`}
              identifierGroup={props.identifierGroup}
              roomIdSelected={props.roomIdSelected}
              room={room}
              showFilterRooms={props.showFilterRooms}
              onSelectRoomClick={props.onSelectRoomClick}
              onDetailsHotelClick={props.onDetailsHotelClick}
            />
          );
        })}

      {props.rooms.length == 0 && (
        <div className="exon-hotel-rooms-list-empty">
          <Icon icon={'exon-icon-noData-unfilled_disabled'} />
          <p>Nenhum quarto encontrado</p>
        </div>
      )}
    </div>
  );
};

export default Template;
