let Template = (props) => {
  return (
    <div
      className={`exon-list-selected-rooms ${
        props.className && props.className
      }`}
    >
      {props.children}
    </div>
  );
};

export default Template;
