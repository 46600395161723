//CSS no padrão BEM (Bloco Elemento Modificador)

let CarrousselTemplate = {
  main: ({ items, componentRef }) => (
    <div ref={componentRef} className="exon-carroussel">
      {items}
    </div>
  ),
};

export default CarrousselTemplate;
