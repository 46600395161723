import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import Timer from '../common/timer/timer.component.js';
import { enums } from '../../utils/enums.js';

let TabTemplate = ({
  label,
  icon,
  loading,
  identifier,
  tooltip,
  selectedTab,
  showCloseButton,
  timerData,
  isExpired,
  onTabClicked,
  onTabDeleted,
}) => {
  return (
    <div className="exon-tab" onClick={() => onTabClicked(identifier)}>
      <div>
        <div
          role="button"
          className={
            'tab exon-tab-item ' +
            (selectedTab == identifier ? 'tab-active' : '')
          }
        >
          <i
            className="ace-icon fa fa-caret-right"
            style={{ display: 'none' }}
          ></i>
          <span className="exon-box-tab-infos">
            {icon == enums.productType.air && (
              <span
                className={
                  'exon-icon' +
                  (isExpired
                    ? ' exon-icon-aircraft-unfilled_enabled-red'
                    : ' exon-icon-aircraft-unfilled_enabled')
                }
              ></span>
            )}
            {icon == enums.productType.hotel && (
              <span
                className={
                  'exon-icon' +
                  (isExpired
                    ? ' exon-icon-hotel-provider-unfilled_enabled-red'
                    : ' exon-icon-hotel-provider-unfilled_enabled')
                }
              ></span>
            )}
            {icon == enums.quotationSteps.hotelDetails && (
              <span
                className={
                  'exon-icon' +
                  (isExpired
                    ? ' exon-icon-bed-unfilled_enabled-red'
                    : ' exon-icon-bed-unfilled_enabled')
                }
              ></span>
            )}
            <Typography variant="body4">{label}</Typography>
            <span
              className={'loader exon-icon-loader ' + (loading ? '' : 'hide')}
            ></span>
          </span>
          {showCloseButton && (
            <span
              className="close exon-button-close"
              onClick={(e) => onTabDeleted(e, identifier)}
            ></span>
          )}
          <span className="exon-tooltip">{tooltip}</span>
          {timerData && !isExpired && timerData.showTimer && (
            <Timer
              totalSeconds={timerData.totalSeconds}
              countdown={true}
              identifier={identifier}
              actionEnd={timerData.actionEnd}
              showLabel={timerData.showLabel}
              description={timerData.description}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TabTemplate;
