export const fareMapping = {
  G3: {
    national: {
      'Tarifa 1': {
        baggage: ['Sem Bagagem (1 bagagem de mão)'],
        cancellation: ['Valor em reais não reembolsável'],
        seat: ['Marcação de Assento com Custo Adicional'],
        anticipation: ['Não permite'],
        refund: ['Não reembolsável'],
        noShow: ['Não reembolsável'],
        baggageQuantity: 1,
      },
      'Tarifa 2': {
        baggage: ['Sem Bagagem (1 bagagem de mão)'],
        cancellation: ['R$ 275 ou 100% considerando o que for menor, em reais'],
        anticipation: ['Custo de R$ 100 (até 6 horas do voo original)'],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: ['Não reembolsável'],
        noShow: ['R$ 350 ou 100% considerando o que for menor, em reais'],
        baggageQuantity: 1,
      },
      'Tarifa 3': {
        baggage: ['1 Bagagem'],
        cancellation: ['R$ 250 ou 100% considerando o que for menor em reais'],
        anticipation: ['Gratuita (até 6 horas do voo original)'],
        seat: ['Marcação de Assento sem Custo Adicional'],
        refund: ['40% do valor'],
        noShow: ['R$ 330 ou 100% considerando o que for menor, em reais'],
        baggageQuantity: 2,
      },
      'Tarifa 4': {
        baggage: ['2 Bagagem'],
        cancellation: ['Isento'],
        anticipation: ['Gratuita'],
        seat: ['Marcação de Assento sem Custo Adicional'],
        refund: ['95% do valor'],
        noShow: ['Isento'],
        baggageQuantity: 3,
      },
    },
    international: {
      'Tarifa 1': {
        baggage: ['Sem Bagagem (1 bagagem de mão)'],
        cancellation: ['Valor em reais não reembolsável'],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: ['Não reembolsável'],
        anticipation: ['Não permite'],
        noShow: ['Não reembolsável'],
        baggageQuantity: 1,
      },
      'Tarifa 2': {
        baggage: ['Sem Bagagem (1 bagagem de mão)'],
        cancellation: [
          'U$ 150 ou U$ 300 a depender do destino ou 100% considerando o que for menor',
        ],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: ['Não reembolsável'],
        anticipation: ['Custo de R$ 100 (até 6 horas do voo original)'],
        noShow: [
          'U$ 200 ou U$ 300 (a depender do destino) ou 100% da tarifa considerando o que for menor',
        ],
        baggageQuantity: 1,
      },
      'Tarifa 3': {
        baggage: ['1 Bagagem'],
        cancellation: [
          'U$ 100 ou U$ 200 a depender do destino ou 100% da tarifa considerando o que for menor',
        ],
        seat: ['Marcação de Assento sem Custo Adicional'],
        refund: ['40% do valor'],
        anticipation: ['Gratuita (até 6 horas do voo original)'],
        noShow: ['R$ 330 ou 100% considerando o que for menor, em reais'],
        baggageQuantity: 2,
      },
      'Tarifa 4': {
        baggage: ['2 Bagagem'],
        cancellation: ['Isento'],
        seat: ['Marcação de Assento sem Custo Adicional'],
        refund: ['95% do valor'],
        anticipation: ['Gratuita'],
        noShow: ['Isento'],
        baggageQuantity: 3,
      },
    },
  },
  AD: {
    national: {
      'Tarifa 1': {
        baggage: ['Sem Bagagem (1 bagagem de mão)'],
        cancellation: ['R$ 250 ou 100% da tarifa'],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: [
          'Regular - 60% da tarifa do voo',
          'Imperdível (UU, V, W, V, X, OO, OL e Z) - Não reembolsavel',
          'Y - 5% da tarifa do voo',
        ],
        anticipation: ['Custo a partir de R$ 100'],
        noShow: ['R$ 350 ou 100% da tarifa'],
        baggageQuantity: 1,
      },
      'Tarifa 3': {
        baggage: ['1 Bagagem'],
        cancellation: ['R$ 250 ou 100% da tarifa'],
        seat: ['Marcação de Assento sem Custo Adicional'],
        refund: [
          'Regular - 60% da tarifa do voo',
          'Imperdível (UU, V, W, V, X, OO, OL e Z) - Não reembolsavel ',
          'Y - 5% da tarifa do voo',
        ],
        anticipation: ['Gratuita'],
        noShow: ['R$ 350 ou 100% da tarifa'],
        baggageQuantity: 2,
      },
    },
    international: {
      'Tarifa 1': {
        baggage: ['Sem Bagagem (1 bagagem de mão)'],
        cancellation: ['USD 200 / * a partir de R$ 835'],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: ['USD 200 / * a partir de R$ 835'],
        anticipation: [''],
        noShow: ['USD 120'],
        baggageQuantity: 1,
      },
      'Tarifa 2': {
        baggage: ['1 Bagagem'],
        cancellation: ['USD 175 / * a partir de R$ 731'],
        seat: ['Marcação de Assento sem Custo Adicional'],
        refund: ['USD 175 / * a partir de R$ 731'],
        anticipation: ['Gratuita'],
        noShow: ['USD 120'],
        baggageQuantity: 2,
      },
      'Tarifa 3': {
        baggage: ['1 Bagagem'],
        cancellation: ['USD 150 / * a partir de R$ 627'],
        seat: ['Marcação de Assento sem Custo Adicional'],
        refund: ['USD 150 / * a partir de R$ 627'],
        anticipation: ['Gratuita'],
        noShow: ['USD 120'],
        baggageQuantity: 2,
      },
      'Tarifa 4': {
        baggage: ['3 Bagagem'],
        cancellation: ['USD 200 / * a partir de R$ 835'],
        seat: ['Marcação de Assento sem Custo Adicional'],
        refund: ['USD 200 / * a partir de R$ 835'],
        anticipation: ['Gratuita'],
        noShow: ['USD 120'],
        baggageQuantity: 3,
      },
    },
  },
  JJ: {
    national: {
      'Tarifa 2': {
        //LIGHT
        baggage: ['Sem Bagagem (1 bagagem de mão)'],
        cancellation: ['Não reembolsável'],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: ['Não reembolsável'],
        anticipation: [],
        noShow: [],
        baggageQuantity: 1,
      },
      'Tarifa 3': {
        //STANDARD
        baggage: ['1 Bagagem'],
        cancellation: ['Não reembolsável'],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: ['Não reembolsável.'],
        anticipation: [],
        noShow: [],
        baggageQuantity: 2,
      },
      'Tarifa 4': {
        //FULL
        baggage: ['1 Bagagem'],
        cancellation: ['Gratuíta'],
        seat: ['Marcação Apenas de Assento Comum'],
        refund: [
          '100% do valor. O reembolso de taxas e impostos aplica-se de acordo com os regulamentos locais atuais.',
        ],
        anticipation: [],
        noShow: [],
        baggageQuantity: 2,
      },
      'Tarifa 5': {
        //PREMIUM ECONOMY
        baggage: ['1 Bagagem'],
        cancellation: ['Gratuíta'],
        seat: ['Marcação de Assento Premium Economy'],
        refund: [
          '100% do valor. O reembolso de taxas e impostos aplica-se de acordo com os regulamentos locais atuais.',
        ],
        anticipation: [],
        noShow: [],
        baggageQuantity: 2,
      },
      'Tarifa 6': {
        //PREMIUM BUSINESS
        baggage: ['2 Bagagens'],
        cancellation: ['Gratuíta'],
        seat: ['Marcação de Assento Premium Business'],
        refund: [
          '100% do valor. O reembolso de taxas e impostos aplica-se de acordo com os regulamentos locais atuais.',
        ],
        anticipation: [],
        noShow: [],
        baggageQuantity: 3,
      },
    },
    international: {
      'Tarifa 1': {
        //BASIC
        baggage: ['Sem Bagagem (Somente bolsa / mochila pequena)'],
        cancellation: ['Não reembolsável'],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: ['Não reembolsável'],
        anticipation: [],
        noShow: [],
        baggageQuantity: 0,
      },
      'Tarifa 2': {
        //LIGHT
        baggage: ['3 bagagens'],
        cancellation: ['Gratuita'],
        seat: ['Marcação de Assento com Custo Adicional'],
        refund: [
          '100% do valor. O reembolso de taxas e impostos aplica-se de acordo com os regulamentos locais atuais.',
        ],
        anticipation: [],
        noShow: [],
        baggageQuantity: 3,
      },
      'Tarifa 4': {
        //FULL
        baggage: ['1 Bagagem'],
        cancellation: ['Gratuíta'],
        seat: ['Marcação Apenas de Assento Comum'],
        refund: [
          '100% do valor. O reembolso de taxas e impostos aplica-se de acordo com os regulamentos locais atuais.',
        ],
        anticipation: [],
        noShow: [],
        baggageQuantity: 2,
      },
      'Tarifa 5': {
        //PREMIUM ECONOMY
        baggage: ['1 Bagagem'],
        cancellation: ['Gratuíta'],
        seat: ['Marcação em Assento Premium Economy'],
        refund: [
          '100% do valor. O reembolso de taxas e impostos aplica-se de acordo com os regulamentos locais atuais.',
        ],
        anticipation: [],
        noShow: [],
        baggageQuantity: 2,
      },
    },
  },
  default: {
    baggage: [''],
    cancellation: [''],
    seat: [''],
    refund: [''],
    anticipation: [''],
    noShow: [''],
    baggageQuantity: 1,
  },
};

fareMapping.LA = fareMapping.JJ;

export const fareBaggage = {
  0: ['Sem Informação'],
  1: ['Sem Bagagem (1 bagagem de mão)'],
  2: ['1 Bagagem'],
  3: ['2 Bagagem'],
  4: ['3 Bagagem'],
};
