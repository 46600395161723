import AirProducts from '../../products-travel/air-product/air-product.component.js';
import HotelProducts from '../../products-travel/hotel-product/hotel-product.component.js';
import VehicleSummary from '../../products-travel/vehicle-product/vehicle-product-summary.component.js';

import ApprovalStatus from '../approval-status/approval-status.component.js';
import Popup from '../../popup/popup.component.js';
import Messager from '../../message/message.component.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { fareBaggage } from '@legacy-utils/fareMapping';

var BookingProductSummaryTemplate = {
  main: ({
    booking,
    messager,
    userTravelProfile,
    events,
    justificationEvents,
  }) => {
    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;

    return (
      <div
        className={
          'exon-booking-product-summary' +
          (booking.workflow &&
          booking.workflow.status &&
          booking.workflow.status.id == enums.approvalStatus.refused
            ? ' exon-boobking-disabled'
            : '')
        }
      >
        <div className="exon-booking-product-item">
          <BookingProductSummaryTemplate.iconProduct
            bookingType={booking.type}
          />

          {booking.type == enums.products.namedType.airBooking && (
            <AirProducts
              travels={{
                trips: booking.airTrips
                  ? booking.airTrips.map((trip, index) => {
                      return {
                        airLine: trip.issuerAirline,
                        airClass: {
                          id: trip.firstFlightCabinType,
                          name: trip.firstFlightCabinType,
                        },
                        departure: {
                          iata: trip.departure.iata,
                          fullName: trip.departure.name,
                          date: getFormattedDateObjFromDateObject(
                            trip.departureDate,
                          ),
                        },
                        arrival: {
                          iata: trip.arrival.iata,
                          fullName: trip.arrival.name,
                          date: getFormattedDateObjFromDateObject(
                            trip.arrivalDate,
                          ),
                        },
                        stops: trip.stops,
                        duration: {
                          durationInMinutes: trip.duration,
                          durationFormatted:
                            trip.duration > 0
                              ? utils.formatters.time.durationHoursMinute(
                                  trip.duration,
                                )
                              : utils.calculateDuration(
                                  trip.departureDate,
                                  trip.arrivalDate,
                                ),
                        },
                        agreementApplied: true,
                        inPolicy: true,
                        additionalInfo: {
                          baggage: trip.baggages
                            ? fareBaggage[trip.baggages]
                            : fareBaggage[0],
                        },
                      };
                    })
                  : null,
              }}
              showDots={booking.airTrips && booking.airTrips.length > 1}
            />
          )}

          {booking.type == enums.products.namedType.hotelBooking && (
            <div className="exon-product-hotel-box">
              <HotelProducts
                product={{
                  searchData: {
                    checkin: booking.hotel.room.checkIn,
                    checkout: booking.hotel.room.checkOut,
                  },
                  hotel: {
                    name: booking.hotel.name,
                    stars: booking.hotel.rating,
                    room: {
                      mealOption: booking.hotel.room.boardDescription,
                      name: booking.hotel.room.name,
                      capacity: booking.hotel.room.capacity, // incluir no obj
                      supplier: booking.hotel.room.sourceIdentifier.name,
                    },
                    location: null,
                  },
                }}
              />
            </div>
          )}

          {booking.type == enums.products.namedType.vehicleBooking && (
            <VehicleSummary
              product={{...booking}}
            />
          )}

          <BookingProductSummaryTemplate.bookingDetails
            booking={booking}
            user={userTravelProfile}
            events={events}
            justificationEvents={justificationEvents}
          />
        </div>
        {booking.workflow &&
          booking.workflow.approvers &&
          booking.workflow.approvers.length > 0 && (
            <ApprovalStatus statusData={booking.workflow.approvers} />
          )}
        {messager.show && (
          <BookingProductSummaryTemplate.messager
            props={messager}
            events={events}
          />
        )}
      </div>
    );
  },

  iconProduct: ({ bookingType }) => {
    return (
      <div className="exon-icon-boobking-type">
        {bookingType == enums.products.namedType.airBooking && (
          <span className="exon-icon exon-icon-aircraft-unfilled_enabled"></span>
        )}

        {bookingType == enums.products.namedType.hotelBooking && (
          <span className="exon-icon exon-icon-bed-unfilled_enabled"></span>
        )}

        {bookingType == enums.products.namedType.vehicleBooking && (
          <span className="exon-icon exon-icon-car-unfilled_enabled"></span>
        )}
      </div>
    );
  },

  bookingDetails: ({ booking, user, events, justificationEvents }) => {
    let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
    let getFormattedRounded = utils.formatters.money.getFormattedRounded;
    let getFormatted = utils.formatters.money.getFormatted;

    return (
      <div className="exon-booking-details">
        <div className="exon-booking-details-item-price">
          <p className="exon-booking-details-item-description">Preço do item</p>
          <p className="exon-booking-details-item-value">
            <span className="exon-booking-details-item-value-currencyCode">
              {booking.valueData?.totalValue?.value
                ? getCurrencySymbol(booking.valueData.totalValue.currencyCode)
                : null}
            </span>
            {booking.valueData?.totalValue?.value
              ? `${getFormatted(booking.valueData.totalValue.value, booking.valueData.totalValue.currencyCode)}`
              : "A definir"}
          </p>
        </div>
        <div className="exon-booking-details-item-quote">
          <p className="exon-booking-details-item-description">
            Melhor preço na cotação
          </p>
          <p className="exon-booking-details-item-value">
            <span className="exon-booking-details-item-value-currencyCode">
              {getCurrencySymbol(
                booking.valueData?.cheapestValue?.currencyCode || booking.valueData?.totalValue?.currencyCode,
              )}
            </span>
            {booking.valueData?.totalValue?.value && booking.valueData?.cheapestValue?.value
              ? getFormatted(booking.valueData.cheapestValue.value, booking.valueData.cheapestValue.currencyCode)
              : "--"}
          </p>
        </div>
        <div className="exon-booking-details-item-locator">
          <p className="exon-booking-details-item-description">
            <span>Localizador:</span> {booking.bookingLocator}
            <span
              onClick={() =>
                navigator.clipboard.writeText(booking.bookingLocator)
              }
              className="exon-icon exon-icon-locator-details-unfilled_enabled "
            ></span>
          </p>
          <p className="exon-booking-details-item">
            Status:{' '}
            {booking.workflow && booking.workflow.status
              ? booking.workflow.status.name
              : ''}
            {booking.locked && (
              <span className="exon-icon exon-icon-locked-filled_enabled exon-tooltip">
                <span className="tooltip">Item Bloqueado</span>
              </span>
            )}
          </p>
          <div className="exon-booking-details-icons">
            {booking.inPolicy && (
              <span className="exon-icon exon-icon-hammer-filled_enabled"></span>
            )}
            {booking.agreement && (
              <span className="exon-icon exon-icon-star-filled_enabled"></span>
            )}
          </div>
        </div>
        <div className="exon-booking-details-actions">
          {/*TODO: Funcionalidade incompleta (implementar fluxo de mensagens no back)*/}
          {/*<span*/}
          {/*    className="exon-icon exon-icon-msg-filled_enabled"*/}
          {/*    onClick={() => events.onOpenMessagerClicked(booking.id)}*/}
          {/*>*/}
          {/*</span>*/}
          {booking.allowedActions &&
            booking.allowedActions.canCancel &&
            !booking.allowedActions.canRefuse &&
            !booking.allowedActions.canAuthorize && (
              <span
                className="exon-icon exon-icon-dump-unfilled_disabled"
                onClick={() => events.onDeleteProductClicked(booking.id)}
              ></span>
            )}
          {booking.allowedActions &&
            booking.allowedActions.canRefuse &&
            booking.allowedActions.currentApprover && (
              <span
                className="exon-icon exon-icon-refuse-unfilled_enabled"
                onClick={() =>
                  justificationEvents.onRefuseProductClicked(booking.id)
                }
              ></span>
            )}
        </div>
      </div>
    );
  },

  messager: ({ props, events }) => (
    <div className="messager-container">
      <Popup
        title={'Mensagens'}
        exonStyle={true}
        cancelButtonClick={events.onCloseMessagerClicked}
      >
        <Messager
          currentUser={{
            id: 1,
            name: 'Kelvim Santos Guidini',
            profile: enums.destinationProfile.traveler,
          }}
        />
      </Popup>
    </div>
  ),
};

export default BookingProductSummaryTemplate;
