import { useState } from 'react';

import { resolveCompanies } from '@legacy-dataSources/profile.dataSources';
import {
  setLoader,
  dataSources as travelRequestsDataSources,
} from '@legacy-dataSources/travelRequests.dataSources';

import useQuery from '@shared/hooks/useQuery';
import { ILoader } from '@shared/loader';
import { UserProfileData } from '@shared/types';
import { Loading } from '@components/Loading/Loading';

import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import TravelRequests from '@legacy-components/travel-requests/travel-requests.component';

import PageBase from '../PageBase';

const Page = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const loader: ILoader = {
    toggleLoading(isLoading) {
      setLoading(isLoading);
    },
  };
  setLoader(loader);

  const [userProfile, setUserProfile] = useState<UserProfileData>(null);
  const dataSources = travelRequestsDataSources;
  dataSources.companies.load = async () => resolveCompanies(userProfile);

  const query = useQuery();
  return (
    <PageBase
      pageTitleKey="pages.list.title"
      userProfileLoaded={(profileData) => setUserProfile(profileData)}
      skeleton={<Skeleton variant="rectangular" height="100px" width="100%" />}
      showNewsDisclaimer={true}
    >
      {loading && <Loading />}
      {userProfile && (
        <TravelRequests
          externalOptions={{
            isAgency: userProfile.company.isAgency,
            userTravelProfile: userProfile.travelProfile,
            currentUserId: userProfile.id,
            userData: userProfile,
            requestId: query.get('requestId'),
            rootContextId: query.get('rootContextId'),
            dataSources,
          }}
        />
      )}
    </PageBase>
  );
};

export default Page;
