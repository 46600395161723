import * as SC from './styled';

interface PillProps extends SC.PillStyleProps {
  icon: 'IconSparkles' | 'IconHourglassHigh';
  children: string;
}
export function Pill({ icon, children, variant }: PillProps) {
  return (
    <>
      <SC.Pill variant={variant}>
        <SC.Icon name={icon} fill="transparent" /> {children}
      </SC.Pill>
    </>
  );
}
