import Container from '../../../../common/container/container.component.js';
import Link from '../../../../common/link/link.component.js';
import { utils } from '../../../../../utils/utils.js';

let Template = (props) => {
  let room = props.rooms.find((room) => room.id == props.roomIdsSelected[0]);
  let days = room.fares[0].fareInformation.days;
  let travelers = 0;
  let totalValue = 0;
  let moneyFormatted = utils.formatters.money.getFormatted;
  let currencyCodeFormatted = utils.converters.money.getCurrencySymbol;
  let numberRooms = props.roomIdsSelected.length;

  props.searchParameters.rooms.forEach(
    (room) => (travelers = travelers + room.adultCount + room.childCount),
  );

  props.roomIdsSelected.forEach((roomId, index) => {
    let roomCurrent = props.rooms.find(
      (room) => room.id == props.roomIdsSelected[index],
    );

    if (roomCurrent && roomCurrent.id == roomId)
      totalValue =
        totalValue +
        (roomCurrent.fares[0].roomValue.markupedDailyFare.value
          ? roomCurrent.fares[0].roomValue.markupedDailyFare.value
          : roomCurrent.fares[0].roomValue.originalDailyFare.value) +
        (roomCurrent.fares[0].roomValue.dailyTax.value
          ? roomCurrent.fares[0].roomValue.dailyTax.value
          : roomCurrent.fares[0].roomValue.originalDailyTax.value);
  });

  return (
    <div className="exon-room-summary-card">
      <Container>
        <div className="exon-room-summary-card-header">
          <div className="exon-room-summary-card-name">
            <h4>
              {numberRooms > 1 ? 'Vários quartos' : room.name || room.title}
            </h4>
            <p>
              {`${days < 10 ? '0' + days : days} ${
                days != 1 ? 'noites' : 'noite'
              }, ${travelers < 10 ? '0' + travelers : travelers} ${
                travelers != 1 ? 'pessoas' : 'pessoa'
              }`}
            </p>

            {numberRooms > 1 && (
              <p>
                {`${
                  numberRooms <= 9 ? numberRooms : '0' + numberRooms
                } quartos`}
              </p>
            )}
          </div>
          <i className="exon-icon exon-icon-bed-unfilled_enabled" />
        </div>
        <div
          className="exon-room-summary-card-body"
          style={{ height: numberRooms > 1 ? '55px' : '70px' }}
        >
          <div className="exon-room-summary-card-amenities-box">
            {room.fares[0].fareInformation && numberRooms == 1 && (
              <div>
                <p>Incluso:</p>
                <div className="exon-room-summary-card-amenities">
                  <p>
                    <strong>
                      {room.fares[0].fareInformation.board
                        ? room.fares[0].fareInformation.board.name ||
                          room.fares[0].fareInformation.board.description
                        : 'Sem refeição'}
                    </strong>
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="exon-room-summary-card-value-box">
            {numberRooms > 1 && (
              <Link
                description="Ver resumo"
                onClick={() => props.onShowPurchaseDetailsClick()}
              />
            )}

            <div className="exon-room-summary-card-value">
              <p className="exon-room-summary-card-value-description">
                {`Total de ${days} ${days != 1 ? 'diárias' : 'diária'}`}
              </p>
              <p>
                <span>
                  {currencyCodeFormatted(
                    room.fares[0].roomValue.originalDailyFare.currencyCode ||
                      room.fares[0].roomValue.markupedDailyFare.currencyCode ||
                      room.fares[0].roomValue.dailyFare.currencyCode ||
                      room.fares[0].roomValue.originalDailyTax.currencyCode ||
                      room.fares[0].roomValue.dailyTax.currencyCode,
                  )}
                </span>
                {moneyFormatted(totalValue * days)}
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="exon-room-summary-card-footer">
          <div className="exon-room-summary-card-policy"></div>
          <div className="exon-box-button-double exon-box-button-double-small">
            <button
              className={
                'exon-button exon-button-secondary exon-button-secondary_enabled'
              }
              onClick={() => props.onShopingCartClick()}
            >
              <span className="exon-icon exon-icon-marketing-unfilled_disabled"></span>
            </button>
            <button
              className={'exon-button exon-button-primary'}
              onClick={() => props.onSelectClick()}
            >
              Selecionar
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Template;
