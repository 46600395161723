var MonitoringMatrixTemplate = {
  main: ({
    viewBySelected,

    columns,
    rows,
    identifierRefName,
    onCellsClicked,
    updateAt,
    updateClick,
  }) => {
    return (
      <div className="exon-monitoring-matrix">
        <div className="exon-monitoring-matrix-title-box">
          <div className="exon-monitoring-matrix-title">
            <h6>Itens de viagens prioritários</h6>
            <span className="exon-monitoring-matrix-subtitle">
              {'por ' + viewBySelected.label}
            </span>
          </div>
          <div className="exon-monitoring-matrix-update">
            {updateAt && (
              <span className="exon-requests-last-update">
                Atualizado em: {updateAt.toLocaleString()}
              </span>
            )}
            <span
              className="exon-icon exon-icon-double-update-unfilled_enabled exon-row-aling-itens-center"
              onClick={() => updateClick()}
            ></span>
          </div>
        </div>

        <MonitoringMatrixTemplate.matrix
          columns={columns}
          rows={rows}
          identifierRefName={identifierRefName}
          type={viewBySelected.label}
          onCellsClicked={onCellsClicked}
        />
      </div>
    );
  },

  matrix: ({ columns, rows, identifierRefName, type, onCellsClicked }) => {
    return (
      <div className="exon-monitoring-matrix-table-container">
        <table className="exon-monitoring-matrix-table">
          <thead className="exon-monitoring-matrix-table-head">
            <tr>
              <th colSpan="11">
                <p className="exon-monitoring-matrix-type">{type}</p>
              </th>
              <th className="exon-monitoring-matrix-type-column"></th>
              <th className="exon-monitoring-matrix-type-column"></th>
            </tr>
            <tr key={identifierRefName}>
              {columns.map((column, i) => {
                return (
                  <th
                    key={'header-' + i}
                    className={
                      column.refName === 'name'
                        ? 'exon-monitoring-matrix-table-head-status'
                        : column.refName === 'total'
                        ? 'exon-monitoring-matrix-table-head-total'
                        : column.refName === ''
                        ? ''
                        : 'exon-monitoring-matrix-table-head-intervals'
                    }
                  >
                    {column.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="exon-monitoring-matrix-table-body">
            {rows.map((item, i) => {
              return (
                <tr key={item[identifierRefName]}>
                  {columns.map((column, j) => {
                    let cellContent = '';

                    if (column.refName === 'name')
                      cellContent = item[column.refName];
                    else if (column.refName === 'total')
                      cellContent = item[column.refName];
                    else if (column.refName === '') cellContent = '';
                    else {
                      let cell = item.cells.find(
                        (t) => t.key.interval === column.refName,
                      );
                      cellContent = cell.items.length;
                    }

                    let onClick =
                      column.refName !== ''
                        ? () =>
                            onCellsClicked(
                              item[identifierRefName],
                              column.refName,
                            )
                        : null;
                    return (
                      <td
                        key={'matrix-' + j}
                        onClick={onClick}
                        className={
                          column.refName === 'name'
                            ? 'exon-monitoring-matrix-table-body-status ' +
                              (item[column.refName] === 'Inicial'
                                ? 'exon-monitoring-matrix-table-body-status-first'
                                : item[column.refName] === 'Cancelado'
                                ? 'exon-monitoring-matrix-table-body-status-end'
                                : '')
                            : column.refName === 'total'
                            ? 'exon-monitoring-matrix-table-body-total'
                            : 'exon-monitoring-matrix-table-body-cells'
                        }
                      >
                        {cellContent}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  },
};

export default MonitoringMatrixTemplate;
