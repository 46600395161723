'use strict';

import React from 'react';
import { componentUtil } from '@legacy-utils/componentUtil.js';
import { enums } from '@legacy-utils/enums';
import AirSearchResultsFilterTemplate from './air-search-result-filter.template.js';

export default class AirSearchResultsFilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    for (let filter in this.managerFilters) {
      let componentFunctions = this.managerFilters[filter];
      componentUtil.react.bindMethods(componentFunctions, this);
    }

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      openFilter: '',
      originalResult: this.props.originalResult,
      filteredResult: this.props.originalResult,
      indexTripPart: this.props.indexTripPart || 0,
      identifierName: this.props.identifierName,
      identifierFiltered: [],
      identifier: this.managerFilters.identifier,
      filterByStretch: this.convertToFilterOptions(),
      statusFilters: this.managerFilters.statusFilters,
      tripType: this.props.originalResult[this.props.indexTripPart || 0],
      //filterOptions: this.convertToFilterOptions()
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      indexTripPart: props.indexTripPart || 0,
      identifierName: props.identifierName,
    };
  }

  render() {
    //TODO: passar para state
    const indexTrip = this.state.indexTripPart;
    const selectedTabOriginalResult = this.state.originalResult[indexTrip];

    return (
      <AirSearchResultsFilterTemplate
        options={this.options}
        openFilter={this.state.openFilter}
        identifier={this.state.identifier}
        filterOptions={this.state.filterByStretch[indexTrip]}
        typeTrip={
          selectedTabOriginalResult
            ? selectedTabOriginalResult.tripPartType
            : null
        }
        tripIndex={indexTrip}
        identifierName={this.state.identifierName}
        identifierFiltered={this.state.identifierFiltered}
        onOpenPopupButtonClick={this.events.filter.show}
        onPopupCloseButtonClick={this.events.filter.cancel}
        onPopupApplyButtonClick={this.events.filter.apply}
        onApplyFiltersClick={this.events.onApplyFiltersClicked}
        onClearFiltersClick={this.events.onClearFiltersClicked}
        onOptionsListBaggageChange={this.managerFilters.baggage.onChange}
        onOptionsListHourDepartureChange={
          this.managerFilters.hourDeparture.onChange
        }
        onOptionsListHourArrivalChange={
          this.managerFilters.hourArrival.onChange
        }
        onOptionsListCiaChange={this.managerFilters.cia.onChange}
        onOptionsListAirportDepartureChange={
          this.managerFilters.airportDeparture.onChange
        }
        onOptionsListAirportArrivalChange={
          this.managerFilters.airportArrival.onChange
        }
        onOptionsListStopsChange={this.managerFilters.stops.onChange}
        onOptionsListDurationChange={this.managerFilters.duration.onChange}
        onOptionsListDurationGoingChange={this.managerFilters.duration.onChange}
        onOptionsListDurationReturnChange={
          this.managerFilters.duration.onChange
        }
      />
    );
  }

  events = {
    filter: {
      show: (identifier) => {
        this.generateFilteredList(identifier, this.state.filterByStretch);

        this.setState({
          openFilter: identifier,
          filterByStretch: this.setFiltersValuesAppliedToCurrent(identifier),
        });
      },

      hide: () => {
        this.setState({ openFilter: '' });
      },

      cancel: (identifier) => {
        this.events.filter.hide();
      },

      apply: (identifier) => {
        switch (identifier) {
          case this.managerFilters.identifier.hour:
            this.managerFilters.hourDeparture.apply();
            break;

          case this.managerFilters.identifier.baggage:
            this.managerFilters.baggage.apply();
            break;

          case this.managerFilters.identifier.cia:
            this.managerFilters.cia.apply();
            break;

          case this.managerFilters.identifier.airportDeparture:
            this.managerFilters.airportDeparture.apply();
            break;

          case this.managerFilters.identifier.airportArrival:
            this.managerFilters.airportArrival.apply();
            break;

          case this.managerFilters.identifier.stops:
            this.managerFilters.stops.apply();
            break;

          case this.managerFilters.identifier.duration:
            this.managerFilters.duration.apply();
            break;
        }

        this.events.filter.hide(identifier);
      },
    },

    onApplyFiltersClicked: () => {
      this.activeButtonsFiltered();
      let filteredResult = this.state.filteredResult;
      if (this.props.applyFilters)
        this.props.applyFilters(
          filteredResult,
          this.state.indexTripPart,
          this.props.identifier,
        );
    },

    onClearFiltersClicked: () => {
      let originalResults = this.state.originalResult;
      // let originalResultTrip = $.extend(
      //   false,
      //   {},
      //   originalResults[this.state.indexTripPart]
      // );
      let originalResultTrip = { ...originalResults[this.state.indexTripPart] };
      let filteredResult = originalResultTrip;

      this.clearOptionsFilter();
      this.setState({ filteredResult: filteredResult });

      if (this.props.applyFilters)
        this.props.applyFilters(
          filteredResult,
          this.state.indexTripPart,
          this.props.identifier,
        );
    },
  };

  clearOptionsFilter = () => {
    let filterByStretch = this.state.filterByStretch;
    let filterOptions = filterByStretch[this.state.indexTripPart];

    //AIRPORT ARRIVAL
    filterOptions.airportArrival.forEach((item) => {
      item.checked = false;
      item.applied = false;
      item.filtered = false;
      item.disabled = false;
    });
    filterOptions.statusFilters.airportArrival.pendantFilter = false;
    filterOptions.statusFilters.airportArrival.appliedFilter = false;

    //AIRPORT DEPARTURE
    filterOptions.airportDeparture.forEach((item) => {
      item.checked = false;
      item.applied = false;
      item.filtered = false;
      item.disabled = false;
    });
    filterOptions.statusFilters.airportDeparture.appliedFilter = false;
    filterOptions.statusFilters.airportDeparture.pendantFilter = false;

    //BAGGAGE
    filterOptions.baggage.forEach((item) => {
      item.checked = false;
      item.applied = false;
      item.filtered = false;
      item.disabled = false;
    });
    filterOptions.statusFilters.baggage.appliedFilter = false;
    filterOptions.statusFilters.baggage.pendantFilter = false;

    //CIA
    filterOptions.cia.forEach((item) => {
      item.checked = false;
      item.applied = false;
      item.filtered = false;
      item.disabled = false;
    });
    filterOptions.statusFilters.cia.appliedFilter = false;
    filterOptions.statusFilters.cia.pendantFilter = false;

    //STOPS
    filterOptions.stops.forEach((item) => {
      item.checked = false;
      item.applied = false;
      item.filtered = false;
      item.disabled = false;
    });
    filterOptions.statusFilters.stops.appliedFilter = false;
    filterOptions.statusFilters.stops.pendantFilter = false;

    // HOUR DEPARTURE
    filterOptions.hour.departure.limits =
      filterOptions.hour.departure.originalLimits;
    filterOptions.hour.departure.applied =
      filterOptions.hour.departure.originalLimits;
    filterOptions.hour.departure.set =
      filterOptions.hour.departure.originalLimits;

    // HOUR ARRIVAL
    filterOptions.hour.arrival.limits =
      filterOptions.hour.arrival.originalLimits;
    filterOptions.hour.arrival.applied =
      filterOptions.hour.arrival.originalLimits;
    filterOptions.hour.arrival.set = filterOptions.hour.arrival.originalLimits;

    // DURATION
    filterOptions.durationGoing.limits =
      filterOptions.durationGoing.originalLimits;
    filterOptions.durationGoing.applied =
      filterOptions.durationGoing.originalLimits;
    filterOptions.durationGoing.set =
      filterOptions.durationGoing.originalLimits;

    if (filterOptions.durationReturn) {
      filterOptions.durationReturn.limits =
        filterOptions.durationReturn.originalLimits;
      filterOptions.durationReturn.applied =
        filterOptions.durationReturn.originalLimits;
      filterOptions.durationReturn.set =
        filterOptions.durationReturn.originalLimits;
    }

    filterOptions.statusFilters.duration.appliedFilter = false;
    filterOptions.statusFilters.duration.pendantFilter = false;

    filterByStretch[this.state.indexTripPart] = filterOptions;
    this.setState({ filterByStretch: filterByStretch });
  };

  validateAvailableItems = (filteredResult) => {
    let filterByStretch = this.state.filterByStretch;
    let filterOptions = filterByStretch[this.state.indexTripPart];

    let ciaFound = [];
    let airportDepartureFound = [];
    let stopsItensFound = [];
    let baggageFound = [];
    let airportArrivalFound = [];
    let timeDeparture = [];
    let timeArrival = [];

    filteredResult.fullFlightOptions.forEach((fo) => {
      if (fo.trips) {
        fo.trips.forEach((trip) => {
          // CIA
          if (!ciaFound.includes(trip.airLine.code))
            ciaFound.push(trip.airLine.code);

          // AIRPORT DEPARTURE
          if (!airportDepartureFound.includes(trip.departure.iata))
            airportDepartureFound.push(trip.departure.iata);

          // AIRPORT ARRIVAL
          if (!airportArrivalFound.includes(trip.arrival.iata))
            airportArrivalFound.push(trip.arrival.iata);

          // STOPS
          if (!stopsItensFound.includes(trip.stops))
            stopsItensFound.push(trip.stops);

          //HOUR DEPARTURE
          let travelDepartureTime = this.convertToMinutes(
            trip.departure.date.hour,
            trip.departure.date.minute,
          );
          if (!timeDeparture.find((item) => item == travelDepartureTime))
            timeDeparture.push(travelDepartureTime);

          //HOUR ARRIVAL
          let travelArrivalTime = this.convertToMinutes(
            trip.arrival.date.hour,
            trip.arrival.date.minute,
          );
          if (!timeArrival.find((item) => item == travelArrivalTime))
            timeArrival.push(travelArrivalTime);
        });
      }

      if (fo.trips) {
        fo.fareOptions.forEach((fare) => {
          // BAGGAGE
          if (!baggageFound.includes(fare.baggages))
            baggageFound.push(fare.baggages);
        });
      }
    });

    if (filterOptions.baggage)
      filterOptions.baggage.forEach((item) => {
        item.disabled = !baggageFound.includes(item.value);
      });

    if (filterOptions.stops)
      filterOptions.stops.forEach((item) => {
        item.disabled = !stopsItensFound.includes(item.value);
      });

    if (filterOptions.cia)
      filterOptions.cia.forEach((item) => {
        item.disabled = !ciaFound.includes(item.value);
      });

    if (filterOptions.airportDeparture)
      filterOptions.airportDeparture.forEach((item) => {
        item.disabled = !airportDepartureFound.includes(item.value);
      });

    if (filterOptions.airportArrival)
      filterOptions.airportArrival.forEach((item) => {
        item.disabled = !airportArrivalFound.includes(item.value);
      });

    filterByStretch[this.state.indexTripPart] = filterOptions;
    this.setState({ filterByStretch: filterByStretch });
  };

  activeButtonsFiltered = () => {
    let filterByStretch = this.state.filterByStretch;
    let indexTripPart = this.state.indexTripPart;
    let filterByStretchItem = filterByStretch[indexTripPart];

    //AIRPORT ARRIVAL
    let filteredAirportArrival = filterByStretchItem.airportArrival.some(
      (item) => item.applied == true,
    );
    filterByStretchItem.airportArrival.forEach((item) => {
      item.filtered = item.applied;
    });
    filteredAirportArrival
      ? (filterByStretchItem.statusFilters.airportArrival.appliedFilter = true)
      : (filterByStretchItem.statusFilters.airportArrival.appliedFilter = false);
    filterByStretchItem.statusFilters.airportArrival.pendantFilter = false;

    //AIRPORT DEPARTURE
    let filteredAirportDeparture = filterByStretchItem.airportDeparture.some(
      (item) => item.applied == true,
    );
    filterByStretchItem.airportDeparture.forEach((item) => {
      item.filtered = item.applied;
    });
    filteredAirportDeparture
      ? (filterByStretchItem.statusFilters.airportDeparture.appliedFilter = true)
      : (filterByStretchItem.statusFilters.airportDeparture.appliedFilter = false);
    filterByStretchItem.statusFilters.airportDeparture.pendantFilter = false;

    //BAGGAGE
    let filteredBaggage = filterByStretchItem.baggage.some(
      (item) => item.applied == true,
    );
    filterByStretchItem.baggage.forEach((item) => {
      item.filtered = item.applied;
    });
    filteredBaggage
      ? (filterByStretchItem.statusFilters.baggage.appliedFilter = true)
      : (filterByStretchItem.statusFilters.baggage.appliedFilter = false);
    filterByStretchItem.statusFilters.baggage.pendantFilter = false;

    //CIA
    let filteredCia = filterByStretchItem.cia.some(
      (item) => item.applied == true,
    );
    filterByStretchItem.cia.forEach((item) => {
      item.filtered = item.applied;
    });
    filteredCia
      ? (filterByStretchItem.statusFilters.cia.appliedFilter = true)
      : (filterByStretchItem.statusFilters.cia.appliedFilter = false);
    filterByStretchItem.statusFilters.cia.pendantFilter = false;

    //STOPS
    let filteredStops = filterByStretchItem.stops.some(
      (item) => item.applied == true,
    );
    filterByStretchItem.stops.forEach((item) => {
      item.filtered = item.applied;
    });
    filteredStops
      ? (filterByStretchItem.statusFilters.stops.appliedFilter = true)
      : (filterByStretchItem.statusFilters.stops.appliedFilter = false);
    filterByStretchItem.statusFilters.stops.pendantFilter = false;

    //DURATION
    let filteredDuration =
      JSON.stringify(filterByStretchItem.durationGoing.applied) !=
      JSON.stringify(filterByStretchItem.durationGoing.limits);
    !filteredDuration && filterByStretchItem.durationReturn
      ? (filteredDuration =
          JSON.stringify(filterByStretchItem.durationReturn.applied) !=
          JSON.stringify(filterByStretchItem.durationReturn.limits))
      : null;

    filterByStretchItem.durationGoing.filtered =
      filterByStretchItem.durationGoing.set;
    filterByStretchItem.durationReturn
      ? (filterByStretchItem.durationReturn.filtered =
          filterByStretchItem.durationReturn.set)
      : null;

    filteredDuration
      ? (filterByStretchItem.statusFilters.duration.appliedFilter = true)
      : (filterByStretchItem.statusFilters.duration.appliedFilter = false);
    filterByStretchItem.statusFilters.duration.pendantFilter = false;

    //HOUR
    let hourDepartureApplied = JSON.stringify(
      filterByStretchItem.hour.departure.applied,
    );
    let hourDepartureLimits = JSON.stringify(
      filterByStretchItem.hour.departure.limits,
    );
    let hourArrivalApplied = JSON.stringify(
      filterByStretchItem.hour.arrival.applied,
    );
    let hourArrivalLimits = JSON.stringify(
      filterByStretchItem.hour.arrival.limits,
    );

    let filteredHour =
      hourDepartureApplied != hourDepartureLimits ||
      hourArrivalApplied != hourArrivalLimits;
    filterByStretchItem.hour.departure.filtered =
      filterByStretchItem.hour.departure.set;
    filterByStretchItem.hour.arrival.filtered =
      filterByStretchItem.hour.arrival.set;

    filteredHour
      ? (filterByStretchItem.statusFilters.hour.appliedFilter = true)
      : (filterByStretchItem.statusFilters.hour.appliedFilter = false);
    filterByStretchItem.statusFilters.hour.pendantFilter = false;

    filterByStretch[indexTripPart] = filterByStretchItem;

    this.setState({ filterByStretch: filterByStretch });
  };

  generateFilteredList = (identifier, filterByStretch) => {
    //TODO obter filterByStretch por parametro, não ler direto do state

    let filteredResults = this.state.originalResult;
    let filterOptions = filterByStretch[this.state.indexTripPart];

    filterOptions.cia.sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });

    // var filteredResult = $.extend(false, {}, filteredResults[this.state.indexTripPart]);
    let filteredResult = { ...filteredResults[this.state.indexTripPart] };

    filteredResult.fullFlightOptions.forEach((fo) => {
      fo.filteredTrips = fo.trips;

      //STOPS
      if (identifier != this.managerFilters.identifier.stops) {
        fo.filteredTrips = fo.filteredTrips.filter((trip) => {
          return (
            filterOptions.stops.every((stop) => !stop.checked) ||
            filterOptions.stops.some(
              (stop) => stop.checked && stop.value == trip.stops,
            ) ||
            filterOptions.stops.some(
              (stop) => stop.checked && stop.value == 2 && trip.stops >= 2,
            )
          );
        });
      }

      //CIA
      if (identifier != this.managerFilters.identifier.cia) {
        fo.filteredTrips = fo.filteredTrips.filter((trip) => {
          return (
            filterOptions.cia.every((cia) => !cia.checked) ||
            filterOptions.cia.some(
              (cia) => cia.checked && cia.value == trip.airLine.code,
            )
          );
        });
      }

      //DEPARTURE
      if (identifier != this.managerFilters.identifier.airportDeparture) {
        fo.filteredTrips = fo.filteredTrips.filter((trip) => {
          return (
            filterOptions.airportDeparture.some(
              (departure) =>
                departure.checked && departure.value == trip.departure.iata,
            ) ||
            filterOptions.airportDeparture.every(
              (departure) => !departure.checked,
            )
          );
        });
      }

      //ARRIVAL
      if (identifier != this.managerFilters.identifier.airportArrival) {
        fo.filteredTrips = fo.filteredTrips.filter((trip) => {
          return (
            filterOptions.airportArrival.some(
              (arrival) =>
                arrival.checked && arrival.value == trip.arrival.iata,
            ) ||
            filterOptions.airportArrival.every((arrival) => !arrival.checked)
          );
        });
      }

      //DURATION
      if (identifier != this.managerFilters.identifier.duration) {
        let validationTripsResult = fo.filteredTrips.map((trip, index) => {
          if (index == 0) {
            let min = this.convertToMinutes(
              filterOptions.durationGoing.set.min.hour,
              filterOptions.durationGoing.set.min.minute,
            );
            let max = this.convertToMinutes(
              filterOptions.durationGoing.set.max.hour,
              filterOptions.durationGoing.set.max.minute,
            );
            let durationItem = trip.duration.durationInMinutes;
            let ok = min <= durationItem && max >= durationItem;
            return ok;
          } else if (index == 1) {
            let min = this.convertToMinutes(
              filterOptions.durationReturn.set.min.hour,
              filterOptions.durationReturn.set.min.minute,
            );
            let max = this.convertToMinutes(
              filterOptions.durationReturn.set.max.hour,
              filterOptions.durationReturn.set.max.minute,
            );
            let durationItem = trip.duration.durationInMinutes;
            let ok = min <= durationItem && max >= durationItem;
            return ok;
          }
        });

        if (!validationTripsResult.every((_) => _ == true))
          fo.filteredTrips = [];
      }

      if (identifier != this.managerFilters.identifier.hour) {
        //HOUR DEPARTURE
        fo.filteredTrips = fo.filteredTrips.filter((trip) => {
          let min = this.convertToMinutes(
            filterOptions.hour.departure.set.min.hour,
            filterOptions.hour.departure.set.min.minute,
          );
          let max = this.convertToMinutes(
            filterOptions.hour.departure.set.max.hour,
            filterOptions.hour.departure.set.max.minute,
          );
          let hour = this.convertToMinutes(
            trip.departure.date.hour,
            trip.departure.date.minute,
          );

          let ok = min <= hour && max >= hour;

          return ok;
        });

        //HOUR ARRIVAL
        fo.filteredTrips = fo.filteredTrips.filter((trip) => {
          let min = this.convertToMinutes(
            filterOptions.hour.arrival.set.min.hour,
            filterOptions.hour.arrival.set.min.minute,
          );
          let max = this.convertToMinutes(
            filterOptions.hour.arrival.set.max.hour,
            filterOptions.hour.arrival.set.max.minute,
          );
          let hour = this.convertToMinutes(
            trip.arrival.date.hour,
            trip.arrival.date.minute,
          );

          let ok = min <= hour && max >= hour;

          return ok;
        });
      }

      //BAGGAGE
      if (identifier != this.managerFilters.identifier.baggage) {
        fo.filteredFareOptions = fo.fareOptions.filter((fareOption) => {
          return (
            filterOptions.baggage.every((baggage) => !baggage.checked) ||
            filterOptions.baggage.some(
              (baggage) =>
                baggage.checked && baggage.value == fareOption.baggages,
            )
          );
        });
      }
    });

    filteredResult.fullFlightOptions = filteredResult.fullFlightOptions.filter(
      (flightOption) => {
        if (identifier == this.managerFilters.identifier.baggage)
          return flightOption.filteredTrips[0] && flightOption.fareOptions[0];
        else
          return (
            flightOption.filteredTrips[0] && flightOption.filteredFareOptions[0]
          );
      },
    );

    this.setState({ filteredResult: filteredResult });

    this.validateAvailableItems(filteredResult);
  };

  setFiltersValuesAppliedToCurrent = (identifier) => {
    let filterByStretch = this.state.filterByStretch;

    switch (identifier) {
      case this.managerFilters.identifier.hour:
        filterByStretch[this.state.indexTripPart].hour.departure.set =
          filterByStretch[this.state.indexTripPart].hour.departure.applied;
        filterByStretch[this.state.indexTripPart].hour.arrival.set =
          filterByStretch[this.state.indexTripPart].hour.arrival.applied;
        break;

      case this.managerFilters.identifier.baggage:
        filterByStretch[this.state.indexTripPart].baggage.forEach((item) => {
          item.checked = item.applied;
        });
        break;

      case this.managerFilters.identifier.cia:
        filterByStretch[this.state.indexTripPart].cia.forEach((item) => {
          item.checked = item.applied;
        });
        break;

      case this.managerFilters.identifier.airportDeparture:
        filterByStretch[this.state.indexTripPart].airportDeparture.forEach(
          (item) => {
            item.checked = item.applied;
          },
        );
        break;

      case this.managerFilters.identifier.airportArrival:
        filterByStretch[this.state.indexTripPart].airportArrival.forEach(
          (item) => {
            item.checked = item.applied;
          },
        );
        break;

      case this.managerFilters.identifier.stops:
        filterByStretch[this.state.indexTripPart].stops.forEach((item) => {
          item.checked = item.applied;
        });
        break;

      case this.managerFilters.identifier.duration:
        filterByStretch[this.state.indexTripPart].durationGoing
          ? (filterByStretch[this.state.indexTripPart].durationGoing.set =
              filterByStretch[this.state.indexTripPart].durationGoing.applied)
          : null;
        filterByStretch[this.state.indexTripPart].durationReturn
          ? (filterByStretch[this.state.indexTripPart].durationReturn.set =
              filterByStretch[this.state.indexTripPart].durationReturn.applied)
          : null;
        break;
    }

    return filterByStretch;
  };

  managerFilters = {
    identifier: {
      hour: 1,
      cia: 2,
      airportDeparture: 3,
      airportArrival: 4,
      stops: 5,
      duration: 6,
      baggage: 7,
      policy: 8,
    },

    hourDeparture: {
      onChange: (period) => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].hour.departure.set = period;

        this.setState({ filterByStretch: filterByStretch });
      },
      apply: () => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].hour.departure.applied =
          filterByStretch[this.state.indexTripPart].hour.departure.set;
        filterByStretch[this.state.indexTripPart].hour.arrival.applied =
          filterByStretch[this.state.indexTripPart].hour.arrival.set;
        let hourDepartureApplied =
          filterByStretch[this.state.indexTripPart].hour.departure.applied;
        let hourDepartureFiltered =
          filterByStretch[this.state.indexTripPart].hour.departure.filtered;
        let hourArrivalApplied =
          filterByStretch[this.state.indexTripPart].hour.arrival.applied;
        let hourArrivalFiltered =
          filterByStretch[this.state.indexTripPart].hour.arrival.filtered;

        let appliedItens =
          JSON.stringify(hourDepartureApplied) !=
            JSON.stringify(hourDepartureFiltered) ||
          JSON.stringify(hourArrivalApplied) !=
            JSON.stringify(hourArrivalFiltered);

        if (appliedItens)
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.hour.pendantFilter = true;
        else
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.hour.pendantFilter = false;

        this.setState({ filterByStretch: filterByStretch });
        this.generateFilteredList(null, filterByStretch);
      },
    },

    hourArrival: {
      onChange: (period) => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].hour.arrival.set = period;

        this.setState({ filterByStretch: filterByStretch });
      },
      apply: () => {}, // usa o hourDeparture
    },

    stops: {
      onChange: (event, index) => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].stops[index].checked =
          event.target.checked;
        this.setState({ filterByStretch: filterByStretch });
      },

      apply: () => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].stops.forEach((item) => {
          item.applied = item.checked;
        });

        let appliedItens = filterByStretch[this.state.indexTripPart].stops.some(
          (item) => item.applied != item.filtered,
        );
        let differenceInItems = filterByStretch[
          this.state.indexTripPart
        ].stops.every((item) => item.applied == item.filtered && item.checked);

        if (appliedItens && !differenceInItems)
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.stops.pendantFilter = true;
        else
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.stops.pendantFilter = false;

        this.setState({ filterByStretch: filterByStretch });

        this.generateFilteredList(null, filterByStretch);
      },
    },

    baggage: {
      onChange: (event, index) => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].baggage[index].checked =
          event.target.checked;
        this.setState({ filterByStretch: filterByStretch });
      },
      apply: () => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].baggage.forEach((item) => {
          item.applied = item.checked;
        });

        let appliedItens = filterByStretch[
          this.state.indexTripPart
        ].baggage.some((item) => item.applied != item.filtered);
        let differenceInItems = filterByStretch[
          this.state.indexTripPart
        ].baggage.every(
          (item) => item.applied == item.filtered && item.checked,
        );

        if (appliedItens && !differenceInItems)
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.baggage.pendantFilter = true;
        else
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.baggage.pendantFilter = false;

        this.setState({ filterByStretch: filterByStretch });

        this.generateFilteredList(null, filterByStretch);
      },
    },

    cia: {
      onChange: (event, index) => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].cia[index].checked =
          event.target.checked;
        this.setState({ filterByStretch: filterByStretch });
      },
      apply: () => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].cia.forEach((item) => {
          item.applied = item.checked;
        });

        let appliedItens = filterByStretch[this.state.indexTripPart].cia.some(
          (item) => item.applied != item.filtered,
        );
        let differenceInItems = filterByStretch[
          this.state.indexTripPart
        ].cia.every((item) => item.applied == item.filtered && item.checked);

        if (appliedItens && !differenceInItems)
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.cia.pendantFilter = true;
        else
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.cia.pendantFilter = false;

        this.setState({ filterByStretch: filterByStretch });

        this.generateFilteredList(null, filterByStretch);
      },
    },

    airportDeparture: {
      onChange: (event, index) => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].airportDeparture[
          index
        ].checked = event.target.checked;
        this.setState({ filterByStretch: filterByStretch });
      },
      apply: () => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].airportDeparture.forEach(
          (item) => {
            item.applied = item.checked;
          },
        );

        let appliedItens = filterByStretch[
          this.state.indexTripPart
        ].airportDeparture.some((item) => item.applied != item.filtered);
        let differenceInItems = filterByStretch[
          this.state.indexTripPart
        ].airportDeparture.every(
          (item) => item.applied == item.filtered && item.checked,
        );

        if (appliedItens && !differenceInItems)
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.airportDeparture.pendantFilter = true;
        else
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.airportDeparture.pendantFilter = false;

        this.setState({ filterByStretch: filterByStretch });

        this.generateFilteredList(null, filterByStretch);
      },
    },

    airportArrival: {
      onChange: (event, index) => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].airportArrival[
          index
        ].checked = event.target.checked;
        this.setState({ filterByStretch: filterByStretch });
      },

      apply: () => {
        let filterByStretch = this.state.filterByStretch;
        filterByStretch[this.state.indexTripPart].airportArrival.forEach(
          (item) => {
            item.applied = item.checked;
          },
        );

        let appliedItens = filterByStretch[
          this.state.indexTripPart
        ].airportArrival.some((item) => item.applied != item.filtered);
        let differenceInItems = filterByStretch[
          this.state.indexTripPart
        ].airportArrival.every(
          (item) => item.applied == item.filtered && item.checked,
        );

        if (appliedItens && !differenceInItems)
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.airportArrival.pendantFilter = true;
        else
          filterByStretch[
            this.state.indexTripPart
          ].statusFilters.airportArrival.pendantFilter = false;

        this.setState({ filterByStretch: filterByStretch });

        this.generateFilteredList(null, filterByStretch);
      },
    },

    duration: {
      onChange: (period, tripType) => {
        let filterByStretch = this.state.filterByStretch;

        if (tripType == enums.tripPartTypes.going)
          filterByStretch[this.state.indexTripPart].durationGoing.set = period;
        else
          filterByStretch[this.state.indexTripPart].durationReturn.set = period;

        this.setState({ filterByStretch: filterByStretch });
      },
      apply: () => {
        let filterByStretch = this.state.filterByStretch;
        let filterByStretchItem = filterByStretch[this.state.indexTripPart];

        filterByStretchItem.durationGoing.applied =
          filterByStretch[this.state.indexTripPart].durationGoing.set;
        filterByStretchItem.durationReturn
          ? (filterByStretch[this.state.indexTripPart].durationReturn.applied =
              filterByStretch[this.state.indexTripPart].durationReturn.set)
          : null;

        let appliedItens =
          JSON.stringify(filterByStretchItem.durationGoing.applied) !=
          JSON.stringify(filterByStretchItem.durationGoing.filtered);
        filterByStretchItem.durationReturn && !appliedItens
          ? (appliedItens =
              JSON.stringify(filterByStretchItem.durationReturn.applied) !=
              JSON.stringify(filterByStretchItem.durationReturn.filtered))
          : null;

        if (appliedItens)
          filterByStretchItem.statusFilters.duration.pendantFilter = true;
        else filterByStretchItem.statusFilters.duration.pendantFilter = false;

        filterByStretch[this.state.indexTripPart] = filterByStretchItem;

        this.setState({ filterByStretch: filterByStretch });
        this.generateFilteredList(null, filterByStretch);
      },
    },
  };

  convertToFilterOptions = () => {
    let originalResult = this.props.originalResult;
    let filterByStretch = [];
    let identifierFiltered = [];

    originalResult.forEach((tripPart) => {
      let filterOptions = {};

      filterOptions.cia = [];
      filterOptions.airportDeparture = [];
      filterOptions.airportArrival = [];
      filterOptions.stops = [];
      filterOptions.baggage = [];

      let timeDeparture = [];
      let timeArrival = [];
      let timeDurationGoing = [];
      let timeDurationReturn = [];

      tripPart.fullFlightOptions.forEach((tripOptions) => {
        tripOptions.trips.forEach((trip, tripIndex) => {
          //cia
          if (
            !filterOptions.cia.find((item) => item.value == trip.airLine.code)
          )
            filterOptions.cia.push(
              this.generateFilterItemObject(
                trip.airLine.code,
                trip.airLine.name,
              ),
            );

          //airport
          if (trip.departure) {
            if (
              !filterOptions.airportDeparture.find(
                (item) => item.value == trip.departure.iata,
              )
            )
              filterOptions.airportDeparture.push(
                this.generateFilterItemObject(
                  trip.departure.iata,
                  trip.departure.fullName,
                ),
              );
          }

          if (trip.arrival) {
            if (
              !filterOptions.airportArrival.find(
                (item) => item.value == trip.arrival.iata,
              )
            )
              filterOptions.airportArrival.push(
                this.generateFilterItemObject(
                  trip.arrival.iata,
                  trip.arrival.fullName,
                ),
              );
          }
          //stops
          if (
            !filterOptions.stops.find((item) => item.value == trip.stops) &&
            trip.stops <= 2
          ) {
            let label =
              trip.stops == 0
                ? 'Vôo direto'
                : trip.stops == 1
                ? '1 parada'
                : '2+ paradas';
            filterOptions.stops.push(
              this.generateFilterItemObject(trip.stops, label),
            );

            filterOptions.stops.sort(function (x, y) {
              return x.value - y.value;
            });
          }
          //hours departure
          let travelDepartureTime = this.convertToMinutes(
            trip.departure.date.hour,
            trip.departure.date.minute,
          );
          if (!timeDeparture.find((item) => item == travelDepartureTime))
            timeDeparture.push(travelDepartureTime);
          //hours arrival
          let travelArrivalTime = this.convertToMinutes(
            trip.arrival.date.hour,
            trip.arrival.date.minute,
          );
          if (!timeArrival.find((item) => item == travelArrivalTime))
            timeArrival.push(travelArrivalTime);

          //duration
          if (tripIndex == 0)
            timeDurationGoing.push(trip.duration.durationInMinutes);
          else timeDurationReturn.push(trip.duration.durationInMinutes);
        });
        tripOptions.fareOptions.forEach((fare) => {
          //baggages
          if (
            !filterOptions.baggage.find((item) => item.value == fare.baggages)
          ) {
            let label =
              fare.baggages == 0
                ? 'Sem Bagagem'
                : fare.baggages == 1
                ? fare.baggages + ' Bagagem'
                : fare.baggages + ' Bagagens';
            filterOptions.baggage.push(
              this.generateFilterItemObject(fare.baggages, label),
            );
            filterOptions.baggage.sort(function (x, y) {
              return x.value - y.value;
            });
          }
        });
      });

      filterOptions.hour = {
        departure: {
          originalLimits: {
            min: this.getHourMinute(this.getMinValue(timeDeparture)),
            max: this.getHourMinute(this.getMaxValue(timeDeparture)),
          },
          limits: {
            min: this.getHourMinute(this.getMinValue(timeDeparture)),
            max: this.getHourMinute(this.getMaxValue(timeDeparture)),
          },
          set: {
            min: this.getHourMinute(this.getMinValue(timeDeparture)),
            max: this.getHourMinute(this.getMaxValue(timeDeparture)),
          },
          applied: {
            min: this.getHourMinute(this.getMinValue(timeDeparture)),
            max: this.getHourMinute(this.getMaxValue(timeDeparture)),
          },
        },
        arrival: {
          originalLimits: {
            min: this.getHourMinute(this.getMinValue(timeArrival)),
            max: this.getHourMinute(this.getMaxValue(timeArrival)),
          },
          limits: {
            min: this.getHourMinute(this.getMinValue(timeArrival)),
            max: this.getHourMinute(this.getMaxValue(timeArrival)),
          },
          set: {
            min: this.getHourMinute(this.getMinValue(timeArrival)),
            max: this.getHourMinute(this.getMaxValue(timeArrival)),
          },
          applied: {
            min: this.getHourMinute(this.getMinValue(timeArrival)),
            max: this.getHourMinute(this.getMaxValue(timeArrival)),
          },
        },
      };

      timeDurationGoing.length > 0
        ? (filterOptions.durationGoing = {
            originalLimits: {
              min: this.getHourMinute(this.getMinValue(timeDurationGoing)),
              max: this.getHourMinute(this.getMaxValue(timeDurationGoing)),
            },
            limits: {
              min: this.getHourMinute(this.getMinValue(timeDurationGoing)),
              max: this.getHourMinute(this.getMaxValue(timeDurationGoing)),
            },
            set: {
              min: this.getHourMinute(this.getMinValue(timeDurationGoing)),
              max: this.getHourMinute(this.getMaxValue(timeDurationGoing)),
            },
            applied: {
              min: this.getHourMinute(this.getMinValue(timeDurationGoing)),
              max: this.getHourMinute(this.getMaxValue(timeDurationGoing)),
            },
            filtered: {
              min: this.getHourMinute(this.getMinValue(timeDurationGoing)),
              max: this.getHourMinute(this.getMaxValue(timeDurationGoing)),
            },
          })
        : null;

      timeDurationReturn.length > 0
        ? (filterOptions.durationReturn = {
            originalLimits: {
              min: this.getHourMinute(this.getMinValue(timeDurationReturn)),
              max: this.getHourMinute(this.getMaxValue(timeDurationReturn)),
            },
            limits: {
              min: this.getHourMinute(this.getMinValue(timeDurationReturn)),
              max: this.getHourMinute(this.getMaxValue(timeDurationReturn)),
            },
            set: {
              min: this.getHourMinute(this.getMinValue(timeDurationReturn)),
              max: this.getHourMinute(this.getMaxValue(timeDurationReturn)),
            },
            applied: {
              min: this.getHourMinute(this.getMinValue(timeDurationReturn)),
              max: this.getHourMinute(this.getMaxValue(timeDurationReturn)),
            },
            filtered: {
              min: this.getHourMinute(this.getMinValue(timeDurationReturn)),
              max: this.getHourMinute(this.getMaxValue(timeDurationReturn)),
            },
          })
        : null;

      filterOptions.statusFilters = {
        hour: {
          appliedFilter: false,
          pendantFilter: false,
        },
        cia: {
          appliedFilter: false,
          pendantFilter: false,
        },
        baggage: {
          appliedFilter: false,
          pendantFilter: false,
        },
        airportDeparture: {
          appliedFilter: false,
          pendantFilter: false,
        },
        stops: {
          appliedFilter: false,
          pendantFilter: false,
        },
        airportArrival: {
          appliedFilter: false,
          pendantFilter: false,
        },
        duration: {
          appliedFilter: false,
          pendantFilter: false,
        },
      };

      filterByStretch.push(filterOptions);
      identifierFiltered.push([]);
    });

    return filterByStretch;
  };

  generateFilterItemObject = (value, label) => {
    let item = {
      value: value,
      label: label,
      checked: false,
      applied: false,
      filtered: false,
      disabled: false,
    };

    return item;
  };

  convertToMinutes = (hour, minute) => {
    let hoursInMinutes = hour * 60;
    return hoursInMinutes + minute;
  };

  getMinValue = (list) => {
    return Math.min.apply(Math, list);
  };

  getMaxValue = (list) => {
    return Math.max.apply(Math, list);
  };

  getHourMinute = (value) => {
    let hour = Math.floor(value / 60);
    let minute = value - hour * 60;

    return {
      hour: hour,
      minute: minute,
    };
  };
}
