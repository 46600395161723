let RadioGroupTemplate = {
  main: ({ items, name, selectedValue, onChange }) => (
    <div className="exon-radio">
      {items.map((item, index) => {
        return (
          //TODO: aplicar estilo de cursor:pointer para label inteira
          <label className="button-radio" key={index}>
            <span className="label-bnt-radio"></span>
            <input
              type="radio"
              value={item.value}
              checked={selectedValue == item.value}
              name={name}
              onChange={() => onChange(item)}
            />
            <span></span>
            {/*<label className="exon-radio__placeholder">*/}
            {item.label}
            {/*</label>*/}
          </label>
        );
      })}
    </div>
  ),
};

export default RadioGroupTemplate;
