import React from 'react';
import InputFileTemplate from './input-file.template.js';
import { gritter } from '@legacy-utils/utils';

export default class InputFileComponent extends React.Component {
  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    this.state = {
      selectedFile: null,
      selectedFileName: 'Selecione uma imagem...',
      returnImageUrl: props.imageUrlValue || null,
      contentType: null,
    };
  }

  render() {
    switch (this.props.type) {
      case 'image':
        return (
          <InputFileTemplate.image
            url={this.props.url}
            returnImageUrl={this.state.returnImageUrl}
            selectedFileName={this.state.selectedFileName}
            onFileChanged={this.onFileChange.bind(this)}
          />
        );
    }
  }

  onFileChange = (event) => {
    let file = event.target.files[0];

    this.setState({
      selectedFile: file,
      selectedFileName: file.name || 'Selecione uma imagem...',
    });

    this.dataSource.uploadImage(file, this.props.hotelId);
  };

  dataSource = {
    uploadImage: (file, hotelId) => {
      let _this = this;

      const dataSource = _this.props.hotelImageDataSource;

      if (dataSource.events && dataSource.events.beforeInsert)
        dataSource.events.beforeInsert();

      dataSource
        .insert(file, hotelId)
        .then((img) => {
          let imageKey = img.id_imagem;

          _this.setState({
            returnImageUrl: imageKey,
            contentType: img.content_type,
          });

          if (_this.props.onChange) _this.props.onChange(imageKey);

          if (dataSource.events && dataSource.events.afterInsert)
            dataSource.events.afterInsert(img);
        })
        .catch((result) => {
          gritter.Warning(result);

          if (dataSource.events && dataSource.events.afterInsert)
            dataSource.events.afterInsert();
        });
    },
  };

  externalOptions = {
    dataSources: null,
  };
}
