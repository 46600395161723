import React from 'react';

const Template = ({ control, children, events }) => (
  <div className="exon-accordion">
    {children.map &&
      children.map((child, index) => {
        return React.cloneElement(child, {
          key: `exon-accordion_item-${index}`,
          onTitleClick: events.onTitleClicked,
          opened: control.openeds.find((_) => _ == index) != null,
          childIndex: index,
        });
      })}
  </div>
);

export default Template;
