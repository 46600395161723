import { PolicyConditionField } from 'bff/src/routers/policies/enums';
import * as y from 'yup';
import '../../../../i18n';
import {
  PolicyRegisterBasicInformation,
  PolicyRegisterFormStep,
  PolicyRegisterProperties,
} from '../types';

const basicSchema: y.ObjectSchema<PolicyRegisterBasicInformation> = y.object({
  id: y.string().optional(),
  name: y.string().required().min(3).label('Nome da política'),
  description: y.string().required().max(255).label('Descrição'),
  document: y
    .object({
      name: y.string(),
      file: y.mixed<File>().optional(),
    })
    .optional()
    .nullable(),
});

const propertiesSchema: y.ObjectSchema<PolicyRegisterProperties> = y.object({
  conditions: y.array().of(
    y.object().shape({
      field: y
        .string()
        .oneOf(Object.values(PolicyConditionField))
        .required()
        .label('Campo'),
      value: y.string().required().label('Valor'),
    }),
  ),
});

type Props = {
  step: PolicyRegisterFormStep;
};

export function usePolicyFormValidation(props: Props) {
  if (props.step === PolicyRegisterFormStep.BASIC_INFORMATION) {
    return basicSchema;
  }

  if (props.step === PolicyRegisterFormStep.POLICY_PROPERTIES) {
    return propertiesSchema as y.ObjectSchema<any>;
  }

  return basicSchema;
}
