import Container from '../../../common/container/container.component.js';
import ContainerGroup from '../../../common/container-group/container-group.component.js';
import HotelInformations from '../hotel-informations/hotel-informations.component.js';
import HotelAdditionalInformation from '../hotel-additional-information/hotel-additional-information.component.js';
import HotelAmenities from '../hotel-amenities/hotel-amenities.component.js';
import HotelPriceDetails from '../hotel-price-details/hotel-price-details.component.js';
import HotelRoomsGroup from '../hotel-rooms/hotel-rooms-group/hotel-rooms-group.component.js';
import HotelRoomListGroups from '../hotel-rooms/hotel-room-list-groups/hotel-room-list-groups.component.js';
import HotelRulesAndPolicies from '../hotel-rules-and-policies/hotel-rules-and-policies.component.js';
import HotelCardsGroup from '../hotel-cards-group/hotel-cards-group.component.js';
import HotelCard from '../hotel-cards-group/hotel-card/hotel-card.component.js';
import HotelSearchEngineCard from '../hotel-search-card-engine/hotel-search-card-engine.component.js';
import Popup from '../../../popup/popup.component.js';
import RoomBookingSummary from '../hotel-rooms/room-booking-summary/room-booking-summary.component.js';
import ListSelectedRooms from '../hotel-rooms/room-booking-summary/list-selected-rooms/list-selected-rooms.component.js';
import SelectedRoomCard from '../hotel-rooms/room-booking-summary/list-selected-rooms/selected-room-card/selected-room-card.component.js';

let Template = {
  main: ({ hotel, props, roomIdsSelected, showPurchaseDetails, events }) => {
    return (
      <div
        className={`exon-hotel-details ${
          props.className ? props.className : ''
        }`}
      >
        <HotelInformations
          hotel={hotel}
          originalSearchParameters={props?.originalSearchParameters}
          roomIdsSelected={roomIdsSelected}
          searchParameters={props.searchParameters}
          onShopingCartClick={() => null}
          onSelectClick={() => null}
          onOpenRoomsClick={() => null}
          onAddressClick={() => null}
          onShowPurchaseDetailsClick={() => events.onShowPurchaseDetailsClick()}
          onSendSelectedProductCheckoutClick={() =>
            events.onSendSelectedProductCheckoutClick()
          }
          onIncludeInCartClick={() => events.onIncludeInCartClick()}
        />

        <div className="exon-hotel-details-box">
          <ContainerGroup>
            <Container aditionalClass="col-flex-3">
              <h6 className="exon-secondary-color">
                <strong>Informações adicionais</strong>
              </h6>

              <HotelAdditionalInformation
                additionalInformation={hotel.policies}
              />
            </Container>

            <Container aditionalClass="col-flex-6">
              <HotelAmenities amenities={hotel.amenities} />
            </Container>

            <Container aditionalClass="col-flex-3">
              <h6 className="exon-secondary-color">
                <strong>Resumo da compra</strong>
              </h6>
              <HotelPriceDetails
                rooms={hotel.rooms}
                roomIdsSelected={roomIdsSelected}
              />
            </Container>
          </ContainerGroup>

          <Container>
            <div className="exon-hotel-details-rooms">
              <h4 className="exon-secondary-color">
                <strong>Outros quartos</strong>
              </h4>
              <HotelSearchEngineCard
                searchParameters={props.searchParameters}
                onSearchIdClick={(checkin, checkout, guests) =>
                  events.onSearchIdClick(
                    hotel.externalCode,
                    checkin,
                    checkout,
                    guests,
                  )
                }
              />
            </div>
            <hr />

            {!props.loadingRooms && (
              <div>
                {props.searchParameters.rooms.length == 1 && (
                  <HotelRoomsGroup
                    rooms={hotel.rooms}
                    roomIdSelected={roomIdsSelected[0]}
                    showFilterRooms
                    onSelectRoomClick={events.onSelectRoomClick}
                  />
                )}

                {props.searchParameters.rooms.length > 1 && (
                  <HotelRoomListGroups
                    searchParameters={props.searchParameters}
                    roomIdsSelected={roomIdsSelected}
                    showFilterRooms
                    rooms={hotel.rooms}
                    onSelectRoomClick={events.onSelectRoomClick}
                  />
                )}
              </div>
            )}
            {props.loadingRooms && (
              <div className="exon-box-common-loading">
                <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
                <p>
                  Por favor aguarde!
                  <br />
                  Estamos carregando as informações...
                </p>
              </div>
            )}
          </Container>

          <Container>
            <h4 className="exon-secondary-color">
              <strong>Regras e políticas</strong>

              <HotelRulesAndPolicies policies={hotel.policies} />
            </h4>
          </Container>

          {props.relatedSeachResults && (
            <div className="exon-hotel-details-box-hotels">
              <h4 className="exon-secondary-color">
                <strong>Outros hoteis</strong>
              </h4>

              <HotelCardsGroup>
                {props.relatedSeachResults.map((hotel, index) => (
                  <HotelCard
                    key={`hotel-option-${index}`}
                    onClick={() => events.onHotelOptionClick(index)}
                    {...hotel}
                  />
                ))}
              </HotelCardsGroup>
            </div>
          )}
        </div>

        {showPurchaseDetails && (
          <div className="exon-hotel-details-purchase-details">
            <Popup
              exonStyle={true}
              title={'Resumo da compra'}
              cancelButtonClick={() => events.onShowPurchaseDetailsClick()}
            >
              <RoomBookingSummary
                hotel={hotel}
                roomIdsSelected={roomIdsSelected}
                onSendSelectedProductCheckoutClick={() =>
                  events.onSendSelectedProductCheckoutClick()
                }
                onIncludeInCartClick={() => events.onIncludeInCartClick()}
              >
                <ListSelectedRooms>
                  {roomIdsSelected.map((roomId, index) => {
                    return (
                      <SelectedRoomCard
                        key={`purchase-details-${index}`}
                        roomNumber={index + 1}
                        room={hotel.rooms.find((room) => room.id == roomId)}
                      />
                    );
                  })}
                </ListSelectedRooms>
              </RoomBookingSummary>
            </Popup>
          </div>
        )}
      </div>
    );
  },
};

export default Template;
