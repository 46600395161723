'use strict';

import React from 'react';
import { componentUtil } from '@legacy-utils/componentUtil.js';
import { utils } from '@legacy-utils/utils';
import Template from './hotel-search-card-engine.template.js';

export default class HotelSearchEngineCardComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.guests, this);
    componentUtil.react.bindMethods(this.components.checkinDate, this);
    componentUtil.react.bindMethods(this.components.checkoutDate, this);

    let guests =
      props.searchParameters.rooms &&
      props.searchParameters.rooms.map((room) => {
        return { adults: room.adultCount, children: room.childCount };
      });

    this.state = {
      show: false,
      guests: guests ? guests : [{ adults: 1, children: 0 }],
      checkinDate: props.searchParameters.checkin, // ? new Date(utils.formatters.date.dateObjToString(props.searchParameters.checkin)) : null
      checkoutDate: props.searchParameters.checkout,
      checkinStartDate: new Date(),
    };
  }

  render() {
    return (
      <Template
        props={this.props}
        show={this.state.show}
        events={this.events}
        guests={{
          options: {
            events: {
              onGuestsApply: (data) => this.setState({ guests: data }),
            },
          },
          roomsStart: this.state.guests,
        }}
        checkinDate={{
          data: this.state.checkinDate,
          startDate: this.state.checkinStartDate,
          placeholder: 'checkIn',
          externalOptions: {
            events: {
              onSelected: (data) =>
                this.setState({
                  checkinDate: data,
                  checkoutStartDate: data
                    ? utils.formatters.date.dateObjToString(data)
                    : new Date(),
                }),
            },
          },
        }}
        checkoutDate={{
          data: this.state.checkoutDate,
          startDate: this.state.checkoutStartDate,
          placeholder: 'checkOut',
          externalOptions: {
            events: {
              onSelected: (data) => this.setState({ checkoutDate: data }),
            },
          },
        }}
      />
    );
  }

  events = {
    onClick: () => {
      this.props.onSearchIdClick &&
        this.props.onSearchIdClick(
          this.state.checkinDate,
          this.state.checkoutDate,
          this.state.guests,
        );
      this.setState({ show: !this.state.show });
    },
  };

  components = {
    checkinDate: {
      getProps: function () {
        let _this = this;
        return {
          data: _this.state.checkinDate,
          startDate: _this.state.checkinStartDate,
          placeholder: 'checkIn',
          externalOptions: {
            events: {
              onSelected: function (data) {
                _this.setState({
                  checkinDate: data,
                  checkoutStartDate: data
                    ? utils.formatters.date.dateObjToString(data)
                    : new Date(),
                });
              },
            },
          },
        };
      },
    },
    checkoutDate: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.checkoutDate,
          startDate: _this.state.checkoutStartDate,
          placeholder: 'checkOut',
          externalOptions: {
            events: {
              onSelected: function (data) {
                let obj = { checkoutDate: data };

                _this.setState(obj);
              },
            },
          },
        };
      },
    },
  };
}
