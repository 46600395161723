'use strict';

import React from 'react';
import Template from './request-history-logs.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils//componentUtil.js';

export default class RequestHistoryLogsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      items: [],
      loading: true,
      pagination: {
        totalResults: 0,
        itemsPerPage: 10,
        currentPage: 1,
      },
      access: {
        hasLogAccess: false,
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          historyList={this.state.items}
          loading={this.state.loading}
          dataPagination={this.state.pagination}
          onPageClick={this.events.pagination.onPageClicked}
          actionEvents={this.events.action}
          access={this.state.access}
        />
      </ErrorBoundary>
    );
  }

  componentDidMount() {
    this.getItems(
      this.state.pagination.currentPage,
      this.state.pagination.itemsPerPage,
    );
  }

  getItems(nextPage, itemsPerPage) {
    this.props.dataSource
      .load(
        this.props.serviceRequestId,
        this.props.contextId,
        nextPage,
        itemsPerPage,
      )
      .then((response) => {
        this.setTemplateItems(response);
      });
  }

  setTemplateItems(result) {
    let response = [];
    result.Items.forEach((i) => {
      let finalBody = i.Body
        .replaceAll("\r", "")
        .replaceAll("\n", "")
        .replaceAll("\\", "/");

      let userMetadata = JSON.parse(i.UserMetadata);
      let body = JSON.parse(finalBody);
      let current = body.Content.Current;
      let userSession = current.ServiceRequestHistoryUser;
      let correlationId = body.CorrelationId;

      response.push({
        ServiceRequestHistoryDateTime: current.ServiceRequestHistoryDateTime,
        ServiceRequestHistoryDescription:
          current.ServiceRequestHistoryDescription,
        IsAutomaticFlowAction: userMetadata.IsAutomaticFlowAction || false,
        UserName: userSession.Name,
        CorrelationId: correlationId || null,
        CompanyId: i.CompanyId,
      });
    });

    let pagging = this.state.pagination;
    pagging.currentPage = result.Page;
    pagging.totalResults = result.Count;

    this.setState({
      items: response,
      loading: false,
      pagination: pagging,
    });
  }

  events = {
    pagination: {
      onPageClicked: (currentPage) => {
        if (currentPage != this.state.pagination.currentPage) {
          this.getItems(currentPage, this.state.pagination.itemsPerPage);
        }
      },
    },
    action: {
      redirect: (correlationId, companyId) => {},
    },
  };
}
