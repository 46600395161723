import { config } from '@shared/config';
import { formatterUtil } from '../formatter.js';

import { VehicleBooking } from 'src/types/Vehicle.types';
import { DateObject } from 'src/types/Date.types.js';

export const getCompanyLogoUrl = (rentalCompany: string) => {
  var resourcesUrl = config.awsBucket.url;
  return `${resourcesUrl}image/vehicle/companies/${rentalCompany.toLowerCase()}.svg`;
};

export const getVehicleAdditionalsText = (vehicle: VehicleBooking) => {
  if (!vehicle) return;

  const additionals = [];

  if (vehicle.hasAC) additionals.push('Com Ar');
  else additionals.push('Sem Ar');

  if (vehicle.hasAirbag) additionals.push('Com Airbag');
  else additionals.push('Sem Airbag');

  if (vehicle.hasGPS) additionals.push('Com GPS');
  else additionals.push('Sem GPS');

  return additionals.join(', ');
};

export const getVehicleBookingFullName = (vehicle: VehicleBooking) => {
  if (!vehicle) return;

  var additionals = getVehicleAdditionalsText(vehicle);
  return `${vehicle.category} (${additionals})`;
};

export const getLocationFormatted = (date: DateObject, location: string) => {
  if (!date && !location) return;

  return `${formatterUtil.dateTimeFormat(formatterUtil.dateObjToDate(date))} (${
    location || '--'
  })`;
};
