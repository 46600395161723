import { enums } from '../../../utils/enums';

var Template = {
  main: ({
    showDetails,
    detailsCopied,
    description,
    details,
    feedbackType,
    policyViolation,
    events,
  }) => {
    const warningIcon = 'exon-icon-popup-warning-unfilled_enabled';
    const infoIcon = 'exon-icon-popup-info-unfilled_enabled';
    const confirmIcon = 'exon-icon-popup-confirm-unfilled_enabled';
    const type = enums.feedbackType;

    return (
      <div className="exon-feedback">
        <span
          className={`exon-icon ${
            feedbackType == type.confirm
              ? confirmIcon
              : feedbackType == type.info
              ? infoIcon
              : warningIcon
          }`}
        ></span>

        <Template.description
          description={description}
          policyViolation={policyViolation}
        />

        {details && details.length > 0 && (
          <Template.buttons
            showDetails={showDetails}
            detailsCopied={detailsCopied}
            details={details}
            events={events}
          />
        )}

        {showDetails && <Template.details details={details} />}
      </div>
    );
  },

  description: ({ description, policyViolation }) => (
    <div className="exon-feedback-msg-list">
      {Array.isArray(description) &&
        description.map((item, index) => {
          return <p key={index}>{item}</p>;
        })}

      {!Array.isArray(description) && <p>{description}</p>}

      {policyViolation &&
        policyViolation.map((p, index) => {
          return (
            <div key={index}>
              <p>{p.policyName}</p>
              {p.violatedRules &&
                p.violatedRules.map((rule, index) => {
                  return <p key={index}>{rule.message[0]}</p>;
                })}
            </div>
          );
        })}
    </div>
  ),

  buttons: ({ showDetails, detailsCopied, details, events }) => (
    <div className="exon-box-button-details">
      <button
        className="exon-button exon-button-secondary exon-button-secondary_enabled"
        onClick={events.onShowDetailsClicked}
      >
        {!showDetails ? 'mostrar mais' : 'mostrar menos'}
        <span className="exon-icon exon-icon-down-arrow"></span>
      </button>
      {showDetails && (
        <div>
          <button
            className={
              'exon-button exon-button-secondary' +
              (detailsCopied ? ' exon-secondary-color' : '')
            }
            onClick={() => events.onCopyDetailClicked(details)}
          >
            {detailsCopied ? 'Copiado' : 'Copiar detalhes'}
            <span
              className={`exon-icon ${
                !detailsCopied
                  ? 'exon-icon-locator-details-unfilled_enabled'
                  : 'exon-icon exon-icon-check-unfilled_enabled'
              }`}
            ></span>
          </button>
        </div>
      )}
    </div>
  ),

  details: ({ details }) => (
    <div className="exon-details-feedback-box">
      <div className="exon-details-feedback-descriptions">
        {details.map((detail, index) => {
          return (
            <p key={index}>
              {detail.detail} <br />
              {detail.code && `Código: ${detail.code}`} <br />
              {detail.correlationId && `ID: ${detail.correlationId}`}
            </p>
          );
        })}
      </div>
    </div>
  ),
};

export default Template;
