'use strict';

import React from 'react';
import ExternalBookingLocatorTemplate from './external-booking-locator.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';

export default class ExternalBookingLocatorComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <ExternalBookingLocatorTemplate {...this.props} />
      </ErrorBoundary>
    );
  }
}
