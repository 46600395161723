'use strict';

import React from 'react';
import { componentUtil } from '../../../utils/componentUtil.js';
import Template from './accordion.template.js';

export default class AccordionComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      accordionControl: {
        openeds: this.getOpenedIndexes(),
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    let accordionControl = { ...state.accordionControl };
    props.openeds ? (accordionControl.openeds = props.openeds) : null;

    return {
      accordionControl: accordionControl,
    };
  }

  render() {
    return (
      <Template
        control={this.state.accordionControl}
        children={this.props.children}
        events={this.events}
      />
    );
  }

  events = {
    onTitleClicked: (event, child, childIndex) => {
      this.props.children[childIndex].props.onTitleClick &&
        this.props.children[childIndex].props.onTitleClick(
          event,
          child,
          childIndex,
        );

      if (event.target.localName == 'input') return;

      let openeds = this.state.accordionControl.openeds;
      if (this.props.retractable) {
        const indexToRemove = openeds.indexOf(childIndex);

        if (indexToRemove == -1)
          if (this.props.openMultiple) openeds.push(childIndex);
          else openeds = [childIndex];
        else openeds.splice(indexToRemove, 1);
      } else {
        if (this.props.openMultiple) openeds.push(childIndex);
        else openeds = [childIndex];
      }

      this.setState({
        accordionControl: { ...this.state.accordionControl, openeds },
      });
    },
  };

  getOpenedIndexes() {
    const openedIndexes = [];
    const children = this.props.children;
    if (children && children.forEach) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.props.opened) {
          openedIndexes.push(i);
        }
      }
      return openedIndexes;
    }
  }
}
