import { SelectField } from '@flash-tecnologia/hros-web-ui-v2';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { useTravelStore } from '../../../../store/RequestTravel.store';

interface TravelSelectCompanieProps {
  control: Control;
  errors: FieldErrors;
  handleResetForm: () => void;
}

export function TravelSelectCompanie({
  errors,
  control,
  handleResetForm,
}: TravelSelectCompanieProps) {
  const [optionsContexts, setOptionsCampanies] = useState([]);

  const saveCompany = useTravelStore((state) => state.saveCompany);
  const rootContexts = useTravelStore((state) => state.rootContexts);
  const contextId = useTravelStore((state) => state.contextId);
  const updateContextId = useTravelStore((state) => state.updateContextId);

  const loadOptions = useCallback(async () => {
    if (optionsContexts.length === 0) {
      try {
        setOptionsCampanies(
          rootContexts.map((company) => ({
            ...company,
            label: company.companyName,
            value: company.id,
          })),
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, [optionsContexts, contextId, setOptionsCampanies, rootContexts]);

  return (
    <div className={'info-payment'}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <Controller
            control={control}
            name="campany"
            render={({ field: { onChange, value, ...field } }) => (
              <>
                <SelectField
                  onSelectChange={(event, selectedItem) => {
                    if (!selectedItem) {
                      handleResetForm();
                    } else {
                      onChange(selectedItem);
                      updateContextId(selectedItem.value);
                      saveCompany(selectedItem);
                    }
                  }}
                  onFocus={loadOptions}
                  searchable={true}
                  value={value}
                  options={optionsContexts}
                  label={'Selecione o cliente'}
                  error={!!errors.campany}
                  helperText={
                    <DisplayErrorFormValidation
                      errors={errors}
                      fieldName="campany"
                    />
                  }
                  {...field}
                />
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
}
