import { Gallery } from '@components/Gallery/Gallery';
import { enums } from '../../../utils/enums.js';

var AirProductTemplate = {
  travel: ({
    travel,
    showDots,
    actions,
    travelIndex,
    onDeleteProductClick,
  }) => {
    let trips = Array.isArray(travel) ? travel : travel.trips;

    return (
      <div className="exon-product-summary">
        <div className="exon-product-details">
          <div className="exon-air-product-item">
            <div className="exon-box-carroussel">
              {trips && (
                <Gallery showNavigation={trips.length > 1}>
                  {trips.map((trip, indexTrip) => {
                    if (trip.trips) {
                      return trip.trips.map((item, indexItem) => {
                        return (
                          <AirProductTemplate.travelDetails
                            key={indexItem}
                            trip={
                              item.formattedObject ? item.formattedObject : item
                            }
                            baggageInfo={
                              item.additionalInfo === undefined
                                ? ['Sem Informação']
                                : item.additionalInfo.baggage
                            }
                            indexTrip={indexTrip + 1}
                          />
                        );
                      });
                    } else {
                      return (
                        <AirProductTemplate.travelDetails
                          key={'trip-' + indexTrip}
                          trip={
                            trip.formattedObject ? trip.formattedObject : trip
                          }
                          baggageInfo={
                            trip.additionalInfo === undefined
                              ? ['Sem Informação']
                              : trip.additionalInfo.baggage
                          }
                          indexTrip={indexTrip + 1}
                        />
                      );
                    }
                  })}
                </Gallery>
              )}
            </div>
            <div className="exon-box-actions-product">
              {actions}

              {onDeleteProductClick && (
                <span
                  className="exon-icon exon-icon-dump-unfilled_disabled"
                  onClick={() =>
                    onDeleteProductClick(travelIndex, enums.products.type.air)
                  }
                ></span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },

  travelDetails: ({ trip, indexTrip, baggageInfo }) => {
    return (
      <div>
        <div className="exon-box-product">
          <div className="exon-company">
            <span
              style={{ backgroundImage: `url(${trip.airLine.imageUrl})` }}
              className="exon-logo-airline"
            >
              {!trip.airLine.imageUrl
                ? trip.airLine.name || trip.airLine.code
                : ''}
            </span>
          </div>
          <div className="exon-trip-date">
            <p>
              {trip.departure.date.dayOfWeek},
              <br />
              <span>{trip.departure.date.dateShortFormatted}</span>
              <br />
            </p>
            <span>{trip.airClass.name}</span>
          </div>
          <div className="exon-trip-airports">
            <div>
              <p>{trip.departure.iata}</p>
              <p>{trip.arrival.iata}</p>
            </div>
            <div>
              <p>{trip.departure.date.timeFormatted}</p>
              <p>{trip.arrival.date.timeFormatted}</p>
            </div>
          </div>
          <div className="exon-trip-settings">
            <p>
              {trip.stops != 1
                ? trip.stops + ' paradas'
                : trip.stops + ' parada'}
            </p>
            <span>
              <span className="exon-icon exon-icon-clock-unfilled_enabled"></span>
              {trip.duration.durationFormatted}
            </span>

            {baggageInfo &&
              baggageInfo.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            {trip.originalAirTrip ? (
              <p>
                {' '}
                Nº Vôo{' '}
                {trip.originalAirTrip.flights
                  .map((flight) => flight.flightNumber)
                  .toString()
                  .replace(',', ' / ')}
              </p>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="exon-trip-type">
          {trip.partTrip == enums.tripTypes.going && <span>ida</span>}

          {trip.partTrip == enums.tripTypes.return && <span>volta</span>}

          {trip.partTrip == enums.tripTypes.multipleTrips && (
            <span>{'trecho ' + indexTrip}</span>
          )}
        </div>
      </div>
    );
  },
};

export default AirProductTemplate;
