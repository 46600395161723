'use strict';

import React from 'react';
import Template from './icon.template.js';

export default class IconComponent extends React.Component {
  render() {
    return <Template {...this.props} />;
  }
}
