import React from 'react';
import { useForm, Controller, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextField } from '@flash-tecnologia/hros-web-ui-v2';

const schema = yup.object().shape({
  referenceId: yup.string().required('Referência é obrigatória'),
});

export interface FormData {
  referenceId?: string;
}

interface ReferenceCodeFormProps {
  onFormReady: (methods: UseFormReturn<FormData>) => void;
}

const ReferenceCodeForm: React.FC<ReferenceCodeFormProps> = ({
  onFormReady,
}) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      referenceId: '',
    },
  });

  const {
    control,
    formState: { errors },
  } = methods;

  React.useEffect(() => {
    onFormReady(methods);
  }, [onFormReady, methods]);

  return (
    <>
      <Controller
        name="referenceId"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Nova Referência"
            placeholder={'Código de referência'}
            fullWidth
            margin="normal"
            error={!!errors.referenceId}
            helperText={errors.referenceId?.message}
          />
        )}
      />
    </>
  );
};

export default ReferenceCodeForm;
