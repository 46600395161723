import { useMemo } from 'react';
import { CriteriaFieldsProps, FieldTypes, SelectOption } from '../types';

const defaultOptions = {
  branch: [
    { label: 'Rio de Janeiro', value: 'rio_de_janeiro' },
    { label: 'Brasília', value: 'brasilia' },
    { label: 'São Paulo', value: 'sao_paulo' },
  ],
  area: [
    { label: 'Comercial', value: 'comercial' },
    { label: 'Marketing', value: 'marketing' },
  ],
  position: [
    { label: 'Cargo1', value: 'cargo1' },
    { label: 'Cargo2', value: 'cargo2' },
  ],
  travel_type: [
    { label: 'Nacional', value: 'nacional' },
    { label: 'Internacional', value: 'internacional' },
  ],
  policy_result: [
    { label: 'Conformidade', value: 'conformidade' },
    { label: 'Não Conformidade', value: 'nao_conformidade' },
  ],
};

export function useConditionValues({
  fields,
}: Pick<CriteriaFieldsProps, 'fields'>) {
  const options = useMemo(() => {
    const data: Partial<{ [key in FieldTypes]: SelectOption[] }> = {};

    if (fields.area?.value) {
      data[fields.area.value] = defaultOptions.area;
    }

    if (fields.branch?.value) {
      data[fields.branch.value] = defaultOptions.branch;
    }

    if (fields.position?.value) {
      data[fields.position.value] = defaultOptions.position;
    }

    if (fields.travelType?.value) {
      data[fields.travelType.value] = defaultOptions.travel_type;
    }

    if (fields.policyResult?.value) {
      data[fields.policyResult.value] = defaultOptions.policy_result;
    }

    return data;
  }, [fields]);

  return { conditionValues: options };
}
