import React from 'react';
import { DataGrid, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import Toggle from '../toggle/toggle.component';
import SplitTotals from './split-totals.component';

const Template = {
  main: ({
    addItem,
    buildItem,
    toggleEqualSplit,
    costCenterSplit,
    maxedOut,
  }) => {
    const columns = [
      {
        Header: 'Valor',
        accessor: 'value',
      },
      {
        Header: 'Porcentagem %',
        accessor: 'percentage',
      },
      {
        Header: 'Centro de custo',
        accessor: 'costCenter',
      },
      {
        Header: 'Ações',
        accessor: 'action',
      },
    ];

    return (
      <div className="exon-split-box">
        <div className="exon-split-box-row exon-split-top-row">
          <div className="exon-field">
            <div className="exon-split-criteria">
              <p>
                <small>Critério de divisão</small>
              </p>
              <p>
                <strong>Valor</strong>
              </p>
            </div>
            <div className="exon-toggle exon-split-equal">
              <Toggle
                placeholder="Dividir por porcentagens iguais"
                checked={costCenterSplit.equalSplit}
                externalOptions={{ events: { onChanged: toggleEqualSplit } }}
              />
            </div>
          </div>
          <div className="exon-field">
            <SplitTotals
              total={costCenterSplit.total}
              split={costCenterSplit.data}
            />
          </div>
        </div>
        <div className="exon-split-box-row">
          <div className="exon-field">
            <div className="exon-grid">
              <DataGrid
                hasPagination={false}
                columns={columns}
                data={costCenterSplit.data.map(buildItem)}
              />
            </div>
          </div>
        </div>
        <div className="exon-split-box-row">
          <div className="exon-field">
            <div className="exon-split-add-item">
              <LinkButton
                variant={'neutral'}
                onClick={addItem}
                disabled={maxedOut}
              >
                Adicionar divisão +
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export default Template;
