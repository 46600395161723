import React from 'react';

import { componentUtil } from '../../../utils/componentUtil.js';
import { enums } from "../../../utils/enums.js";

import Template from './feedback.template.js';

export default class FeedbackComponent extends React.Component {
  constructor(props) {
    super(props);

    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      showDetails: false,
      detailsCopied: false,
    };
  }

  render() {
    return (
      <Template.main
        showDetails={this.state.showDetails}
        detailsCopied={this.state.detailsCopied}
        description={this.props.description}
        details={this.props.details}
        feedbackType={this.props.feedbackType}
        policyViolation={this.props.policyViolation}
        events={this.events}
      />
    );
  }

  events = {
    onCopyDetailClicked: (data) => {
      navigator.clipboard.writeText(data.map(_ => `${_.detail} - ${_.code} - ${_.correlationId}. `));
      this.setState({ detailsCopied: true });
    },

    onShowDetailsClicked: () => {
      this.setState({
        showDetails: !this.state.showDetails,
        detailsCopied: false,
      });
    },
  };

  static ConvertMessagesResponseToFeedbackObj(response) {
    const errors = response.errors || [];
    const messages = response.messages || [];

    const allMessages = [...errors, ...messages];
    let messagesDetails = allMessages
      ? allMessages.map((message) => {
        var fullDetail = message.detail ? message.detail : message.message;
        return {
          detail: fullDetail,
          code: message.code,
          correlationId: message.correlationId
        }
      })
      : [{detail: 'Sem detalhes'}];

    return {
      type: enums.feedbackType.warning,
      description: (allMessages && allMessages[0]?.message) || 'Ocorreu um erro no momento da tarifação.',
      details: messagesDetails,
    };
  }
}
