import { FlashLogo } from '@components/FlashLogo';
import { Divider, Dot, Radio, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { BalanceValueSection } from '../BalanceValueSection';
import { PaymentItem } from '@models/PaymentItem';
import { MAX_CARD_NAME_LENGTH } from '@shared/constants/FlashPayment.constants';
import { truncateTitle } from '@shared/utils/text.utils';
import SC from './styled';

type FlashCardProps = {
  className?: string;
  paymentItem: PaymentItem;
  selectable: boolean;
  checked: boolean;
  onChange: () => void;
  showBalance?: boolean;
  disabled?: boolean;
};

export function FlashCard({
  className,
  paymentItem,
  checked,
  onChange,
  selectable = false,
  showBalance = true,
  disabled = false,
}: FlashCardProps) {
  const titleCardName = truncateTitle(
    paymentItem.card.cardName,
    MAX_CARD_NAME_LENGTH,
  );
  return (
    <div className={className}>
      <SC.Box checked={checked} disabled={disabled}>
        <SC.FlexItem>
          <SC.TitleContainer>
            {selectable ? (
              <Radio
                onChange={onChange}
                checked={checked}
                name="flash-payment-method"
                disabled={disabled}
              />
            ) : (
              <FlashLogo height="80" width="76"></FlashLogo>
            )}

            <SC.FlexItem>
              <SC.Title>
                {paymentItem.card.cardName.length >= MAX_CARD_NAME_LENGTH ? (
                  <Tooltip title={paymentItem.card.cardName}>
                    <div>{titleCardName}</div>
                  </Tooltip>
                ) : (
                  <div>{paymentItem.card.cardName}</div>
                )}
                <Dot variant="gray" style={{ color: '#53464F !important' }} />{' '}
                Final {paymentItem.card.lastFourDigits}
              </SC.Title>
              <SC.Name>{paymentItem.name}</SC.Name>
            </SC.FlexItem>
          </SC.TitleContainer>
          <Divider orientation="vertical" />
        </SC.FlexItem>
        <SC.FlexItem>
          {showBalance && (
            <BalanceValueSection
              value={paymentItem.balance}
            ></BalanceValueSection>
          )}
        </SC.FlexItem>
      </SC.Box>
    </div>
  );
}
