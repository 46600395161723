'use strict';

import React from 'react';
import { componentUtil } from '../../../utils/componentUtil.js';
import Template from './accordion-item.template.js';

export default class AccordionItemComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
  }

  render() {
    return <Template props={this.props} events={this.events} />;
  }

  events = {
    onTitleClicked: function (event) {
      this.props.onTitleClick &&
        this.props.onTitleClick(event, this.props, this.props.childIndex);
    },
  };
}
