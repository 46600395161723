import {
  exportServiceRequestReport,
  getServiceRequestReportData,
} from '@api/connectOn/connectOn.service';
import {
  DataGrid,
  DatePicker,
  Icons,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Button } from '@mui/material';
import { BASE_URL } from '@shared/constants/Routes.constants';
import { UserProfileData } from '@shared/types';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as SC from '../reports.styled';

export interface ReportTravelRequestProps {
  userProfile: UserProfileData;
}

export default function ReportTravelRequests({
  userProfile,
}: ReportTravelRequestProps) {
  const navigate = useNavigate();

  const contextId = userProfile?.company?.contextId?.toString();
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);

  const defaultStartAt = new Date();
  defaultStartAt.setMonth(defaultStartAt.getMonth() - 1);
  defaultStartAt.setDate(1);

  const [startAt, setStartAt] = useState(defaultStartAt);
  const [endAt, setEndAt] = useState(new Date());

  const pageSize = 50;
  const pageNumber = 1;
  // const customPagination = {
  //   gotoPage: 1,
  // };

  const columns = useMemo(() => {
    if (!reportData?.records?.length) return [];
    return (
      reportData?.records[0].fields?.map((field: any) => {
        return {
          Header: <SC.GridHeader>{field.fieldName}</SC.GridHeader>,
          accessor: field.fieldName,
          disableSortBy: true,
          sticky: false,
        };
      }) || []
    );
  }, [reportData]);

  const data = useMemo(() => {
    if (!reportData?.records?.length) return [];
    return (
      reportData?.records.map((record: any) => {
        const obj = {};
        record.fields.forEach((field: any) => {
          obj[field.fieldName] = field.formattedValue;
        });
        return obj;
      }) || []
    );
  }, [reportData]);

  const getReportData = async () => {
    setIsLoading(true);

    const request = {
      startAt,
      endAt,
      pagingData: { page: pageNumber, itemsPerPage: pageSize },
    };

    const response = await getServiceRequestReportData(request, contextId);

    setIsLoading(false);
    setReportData(response.reportData);
  };

  const extract = async (format: 'xlsx' | 'json') => {
    const request = {
      startAt,
      endAt,
    };

    await exportServiceRequestReport(request, format, contextId);
  };
  return (
    <SC.ReportData>
      <LinkButton
        variant="neutral"
        onClick={() => navigate(`${BASE_URL}/reports`)}
      >
        <React.Fragment key=".0">
          <Icons name="IconArrowLeft" />
          Voltar
        </React.Fragment>
      </LinkButton>

      <SC.Divisor orientation="horizontal" />

      <SC.ReportFiltering>
        <DatePicker
          label="Inicio"
          value={startAt}
          onDateChange={(dayJS) => setStartAt(dayJS.toDate())}
        />
        <DatePicker
          label="Fim"
          value={endAt}
          onDateChange={(dayJS) => setEndAt(dayJS.toDate())}
        />
      </SC.ReportFiltering>

      <SC.ReportFiltering>
        <Button
          variant="contained"
          size="medium"
          onClick={() => extract('xlsx')}
        >
          Extrair XLSX
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={() => extract('json')}
        >
          Extrair JSON
        </Button>
        <Button variant="outlined" size="small" onClick={() => getReportData()}>
          Exibir Prévia (somente {pageSize} primeiros registros)
        </Button>
      </SC.ReportFiltering>
      <SC.Divisor orientation="horizontal" />

      <DataGrid
        columns={columns}
        data={data}
        loading={isLoading}
        pageCount={reportData?.total}
        hasPagination={!!data.length}
      />
    </SC.ReportData>
  );
}
