export const justificationResolver = {
  resolve: (items) => {
    let resolvedList = [];

    for (let i = 0; i < items.length; i++) {
      let item = items[i];

      resolvedList.push({
        value: item.Id,
        code: item.Code,
        label: item.Name,
        needDescription: item.IsDescriptionRequired,
      });
    }

    return resolvedList;
  },
};
