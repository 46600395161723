import PopupFilterComponent from '../popup-filters/popup-filters.component.js';
import OptionsListTemplate from '../options-list/options-list.component.js';
import MoneyRangeComponent from '../money-range/money-range.component.js';
import { DistanceRange } from '../distance-range/index';
let HotelSearchResultsFilterTemplate = ({
  openFilter,
  identifier,
  identifierName,
  filterOptions,

  //Eventos do pop-up
  onOpenPopupButtonClick,
  onPopupCloseButtonClick,
  onPopupApplyButtonClick,

  //Evento para aplicar todos os filtros
  onApplyFiltersClick,
  onClearFiltersClick,

  //Eventos dos filtros
  onOptionsListPriceChange,
  onOptionsListDistanceChange,
  onOptionsListMealChange,
  onOptionsListStarChange,
  onOptionsListProviderChange,
}) => {
  return (
    <div className="exon-air-filter exon-hotel-filter">
      <label className="exon-air-filter__title">
        Filtros
        <div>
          <span>{identifierName || ''}</span>
        </div>
      </label>

      <div className="exon-hotel-filter-small-screen-items">
        <div className="exon-box-row">
          <div className="exon-box-column">
            <div className="box-button-filter">
              <button
                className={
                  'exon-button-icon' +
                  (filterOptions.statusFilters.price.pendentFilter
                    ? ' exon-button-icon-pending'
                    : filterOptions.statusFilters.price.appliedFilter
                    ? ' exon-button-icon-active'
                    : '')
                }
                disabled={
                  filterOptions.price.limits.min == 0 &&
                  filterOptions.price.limits.max == 0
                }
                onClick={() => onOpenPopupButtonClick(identifier.price)}
              >
                <span
                  className={
                    'icon exon-button-icon__icon' +
                    (!filterOptions.statusFilters.price.pendentFilter
                      ? ' exon-icon-money-unfilled_disabled'
                      : ' exon-icon-money-unfilled_enabled')
                  }
                ></span>
                Por Preço
              </button>

              {filterOptions.price.limits.min != 0 &&
                filterOptions.price.limits.max != 0 && (
                  <div
                    className={
                      'exon-air-filter__options-item' +
                      (openFilter != identifier.price ? ' hide' : '')
                    }
                  >
                    <PopupFilterComponent
                      title="Por Preço"
                      identifier={identifier.price}
                      onPopupCloseButtonClick={onPopupCloseButtonClick}
                      onPopupApplyButtonClick={onPopupApplyButtonClick}
                    >
                      <MoneyRangeComponent
                        currencySymbol={filterOptions.price.currency}
                        min={filterOptions.price.limits.min}
                        max={filterOptions.price.limits.max}
                        initialValue={filterOptions.price.set.min}
                        finalValue={filterOptions.price.set.max}
                        step={5}
                        onValuesChange={onOptionsListPriceChange}
                      />
                    </PopupFilterComponent>
                  </div>
                )}
            </div>
          </div>
          <div className="exon-box-column">
            <div className="box-button-filter">
              <button
                className={
                  'exon-button-icon' +
                  (filterOptions.statusFilters.distances.pendentFilter
                    ? ' exon-button-icon-pending'
                    : filterOptions.statusFilters.distances.appliedFilter
                    ? ' exon-button-icon-active'
                    : '')
                }
                disabled={
                  filterOptions.distances.limits.min == 0 &&
                  filterOptions.distances.limits.max == 0
                }
                onClick={() => onOpenPopupButtonClick(identifier.distances)}
              >
                <span
                  className={
                    'icon exon-button-icon__icon' +
                    (!filterOptions.statusFilters.distances.pendentFilter
                      ? ' exon-icon-pin-unfilled_disabled'
                      : ' exon-icon-pin-unfilled_enabled ')
                  }
                ></span>
                Por Distância
              </button>

              {filterOptions.distances.limits.max != 0 && (
                  <div
                    className={
                      'exon-air-filter__options-item' +
                      (openFilter != identifier.distances ? ' hide' : '')
                    }
                  >
                    <PopupFilterComponent
                      title="Por Distância"
                      identifier={identifier.distances}
                      onPopupCloseButtonClick={onPopupCloseButtonClick}
                      onPopupApplyButtonClick={onPopupApplyButtonClick}
                    >
                      <DistanceRange onChange={onOptionsListDistanceChange}
                        min={filterOptions.distances.limits.min}
                        max={filterOptions.distances.limits.max}
                        initialValue={filterOptions.distances.set.min}
                        finalValue={filterOptions.distances.set.max}
                        ></DistanceRange>
                    </PopupFilterComponent>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="exon-box-row">
          <div className="exon-box-column">
            <div className="box-button-filter">
              <button
                className={
                  'exon-button-icon' +
                  (filterOptions.statusFilters.star.pendentFilter
                    ? ' exon-button-icon-pending'
                    : filterOptions.statusFilters.star.appliedFilter
                    ? ' exon-button-icon-active'
                    : '')
                }
                disabled={filterOptions.star == null}
                onClick={() => onOpenPopupButtonClick(identifier.star)}
              >
                <span
                  className={
                    'icon exon-button-icon__icon' +
                    (!filterOptions.statusFilters.star.pendentFilter
                      ? '  exon-icon-star-circled-unfilled_disabled'
                      : '  exon-icon-star-circled-unfilled_enabled')
                  }
                ></span>
                Por quantidade de estrelas
              </button>

              <div
                className={
                  'exon-air-filter__options-item' +
                  (openFilter != identifier.star ? ' hide' : '')
                }
              >
                <PopupFilterComponent
                  title="Por quantidade de estrelas"
                  identifier={identifier.star}
                  onPopupCloseButtonClick={onPopupCloseButtonClick}
                  onPopupApplyButtonClick={onPopupApplyButtonClick}
                >
                  <OptionsListTemplate
                    options={filterOptions.star}
                    onItemChange={onOptionsListStarChange}
                  />
                </PopupFilterComponent>
              </div>
            </div>
          </div>
          <div className="exon-box-column">
            <div className="box-button-filter">
              <button
                className={
                  'exon-button-icon' +
                  (filterOptions.statusFilters.meal.pendentFilter
                    ? ' exon-button-icon-pending'
                    : filterOptions.statusFilters.meal.appliedFilter
                    ? ' exon-button-icon-active'
                    : '')
                }
                disabled={filterOptions.meal == null}
                onClick={() => onOpenPopupButtonClick(identifier.meal)}
              >
                <span
                  className={
                    'icon exon-button-icon__icon' +
                    (!filterOptions.statusFilters.meal.pendentFilter
                      ? ' exon-icon-meal-unfilled_disabled'
                      : ' exon-icon-meal-unfilled_enabled')
                  }
                ></span>
                Por refeições
              </button>

              <div
                className={
                  'exon-air-filter__options-item' +
                  (openFilter != identifier.meal ? ' hide' : '')
                }
              >
                <PopupFilterComponent
                  title="Por refeições"
                  identifier={identifier.meal}
                  onPopupCloseButtonClick={onPopupCloseButtonClick}
                  onPopupApplyButtonClick={onPopupApplyButtonClick}
                >
                  <OptionsListTemplate
                    options={filterOptions.meal}
                    onItemChange={onOptionsListMealChange}
                  />
                </PopupFilterComponent>
              </div>
            </div>
          </div>
        </div>

        <div className="exon-box-row">
          <div className="exon-box-column">
            <div className="box-button-filter">
              <button
                className={
                  'exon-button-icon' +
                  (filterOptions.statusFilters.provider.pendentFilter
                    ? ' exon-button-icon-pending'
                    : filterOptions.statusFilters.provider.appliedFilter
                    ? ' exon-button-icon-active'
                    : '')
                }
                disabled={filterOptions.meal == null}
                onClick={() => onOpenPopupButtonClick(identifier.provider)}
              >
                <span
                  className={
                    'icon exon-button-icon__icon' +
                    (!filterOptions.statusFilters.provider.pendentFilter
                      ? ' exon-icon-hotel-provider-unfilled_disabled'
                      : ' exon-icon-hotel-provider-unfilled_enabled')
                  }
                ></span>
                Por fornecedor
              </button>

              <div
                className={
                  'exon-air-filter__options-item' +
                  (openFilter != identifier.provider ? ' hide' : '')
                }
              >
                <PopupFilterComponent
                  title="Por fornecedor"
                  identifier={identifier.provider}
                  onPopupCloseButtonClick={onPopupCloseButtonClick}
                  onPopupApplyButtonClick={onPopupApplyButtonClick}
                >
                  <OptionsListTemplate
                    options={filterOptions.provider}
                    onItemChange={onOptionsListProviderChange}
                  />
                </PopupFilterComponent>
              </div>
            </div>
          </div>
          <div className="exon-box-column">
            <div className="box-button-filter">
              <button
                className={'exon-button-icon exon-button-icon_text-center'}
                disabled
              >
                <span className="icon exon-button-icon__icon exon-icon-policy-in-out-unfilled_disabled"></span>
                Pelas políticas da empresa
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="exon-hotel-filter-small-screen-search">
        <div className="exon-box-button-double">
          <button
            className="exon-button exon-button-secondary"
            onClick={onClearFiltersClick}
          >
            <span className="exon-icon exon-icon-dump-unfilled_disabled"></span>
          </button>
          <button
            className="exon-button exon-button-primary"
            onClick={onApplyFiltersClick}
          >
            Aplicar
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotelSearchResultsFilterTemplate;
