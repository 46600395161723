import BookingTravelerList from '../booking-traveler-list/booking-traveler-list.component.js';
import ExternalBookingLocator from './external-booking-locator/external-booking-locator.component.js';
import BookingItemAnalysis from '../booking-item-analysis/booking-item-analysis.component.js';
import BookingBox from '../booking-box/booking-box.component.js';
import BookingAirSegments from '../air-booking/booking-air-segments/booking-air-segments.component.js';
import BookingAirTariffsAndFees from '../air-booking/booking-air-fariffs-and-fess/booking-air-fariffs-and-fess.component.js';
import BookingAirTickets from '../air-booking/booking-air-tickets/booking-air-tickets.component.js';
import Popup from '../../popup/popup.component.js';
import AnalysisItems from '../analysis-items/analysis-items.component.js';
import BookingStatusBar from '../booking-status-bar/booking-status-bar.component.js';
import AirIssueFlow from './booking-air-tickets/air-booking-ticket-issue/air-ticket-issue-flow.component.js';
import AirTripReplacement from './air-trip-replacement/air-trip-replacement.component.js';
import BookingPayment from '../booking-payment/booking-payment.component.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { statusFromCodes } from '@legacy-utils/statusCodes.js';
import { serviceRequestResolver } from '@legacy-utils/resolvers/serviceRequestResolver';
import dayjs from 'dayjs';

var Template = {
  main: ({
    booking,
    isOffline,
    bookingEvents,
    analysisItemData,
    airTicketIssueFlowData,
    airTripReplacementData,
    travelersList,
    dataSources,
    analysisItemEvents,
    airTicketIssueFlowEvents,
    airTripReplacementEvents,

    editItemsEvents,
    onShowEmailPopupClick,
  }) => {
    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;
    let moneyFormatted = utils.formatters.money.getFormatted;
    let canEdit = false;
    let canIssue = false;

    if (!booking.loading) {
      canEdit = booking.allowedActions.canEdit;
      canIssue = booking.allowedActions.canIssue;

      if (
        !canIssue &&
        booking.workflow.status.code == statusFromCodes.inAutomaticIssue &&
        booking.workflow.approvalFlow?.status == 1 &&
        booking.workflow.approvalFlow?.approvers
      )
      {
        const lastApprovalDate = booking.workflow.approvalFlow.approvers
          .reduce((decisionDate, approverData) => {
            if (approverData.status != 1)
              return decisionDate;

            var approvedIn = utils.converters.date.dateObjectToDate(approverData.descisionDate)
            if (!decisionDate || decisionDate < approvedIn)
            {
              decisionDate = approvedIn;
            }

            return decisionDate;
          }, null);


        const minutesDiff = dayjs().diff(lastApprovalDate, 'minute');
        if (minutesDiff >= 60) {
          canIssue = true;
        }
      }
    }

    return (
      <div className="exon-air-booking">
        {booking.loading && (
          <div className="exon-box-air-loading">
            <div>
              <span className="exon-icon exon-icon-loading-plane-unfilled_enabled"></span>
              <p>
                Por favor aguarde!
                <br />
                Estamos carregando as informações da{' '}
                <span>Reserva de aéreo</span>...
              </p>
            </div>
          </div>
        )}

        {!booking.loading && (
          <div className="exon-air-booking-box">
            <div className="exon-air-booking-box-status exon-row-itens exon-row-aling-itens-center">
              <BookingStatusBar
                statusInfos={{
                  status: booking.workflow.status,
                  lock: booking.lock,
                  expirationDate: booking.expiration
                    ? `${
                        getFormattedDateObjFromDateObject(
                          booking.expiration.expireAt,
                        ).dateFormatted
                      } ${
                        getFormattedDateObjFromDateObject(
                          booking.expiration.expireAt,
                        ).timeFormatted
                      }`
                    : '',
                  ignoreExpiration: booking.expiration
                    ? booking.expiration.ignore
                    : true,
                  issueDate:
                    booking.airTickets && booking.airTickets.length > 0
                      ? `${
                          getFormattedDateObjFromDateObject(
                            booking.airTickets[0].createdAt,
                          ).dateFormatted
                        }`
                      : '',
                }}
              />
              <div className="exon-actions-travel-request exon-row-itens exon-row-justify-content-space-between exon-row-aling-itens-center exon-w-100">
                <div className="exon-row-itens ">
                  <span
                    className="exon-icon exon-icon-double-update-unfilled_enabled exon-row-aling-itens-center"
                    onClick={bookingEvents.onUpdateBookingClicked}
                  ></span>
                  {booking.allowedActions.sendNotification && (
                    <span
                      className="exon-icon exon-icon-envelope-filled_enabled"
                      onClick={() =>
                        onShowEmailPopupClick(
                          booking.id,
                          booking.workflow.status.id,
                          false,
                        )
                      }
                    ></span>
                  )}
                </div>
              </div>
            </div>
            <div className="exon-row-itens">
              <BookingBox title="Viajantes">
                <BookingTravelerList
                  travelerList={
                    booking.travelers
                      ? serviceRequestResolver.resolveTravelers(
                          booking.travelers,
                        )
                      : null
                  }
                  canEdit={canEdit}
                />
              </BookingBox>
              <BookingBox title="Localizador externo">
                <ExternalBookingLocator
                  externalLocator={{
                    locator: booking.bookingLocator
                      ? booking.bookingLocator
                      : '--',
                    bookingStatus: booking.bookingStatus,
                    expirationDate: booking.expiration
                      ? booking.expiration.expireAt
                      : null,
                    system: booking.connectionSource
                      ? booking.connectionSource
                      : '',
                    issuingCompany: booking.issuerAirline
                      ? booking.issuerAirline.name
                      : '',
                  }}
                  canEdit={canEdit}
                  canIssue={canIssue}
                  onIssueButtonClick={
                    airTicketIssueFlowEvents.onOpenIssueTicketFlowButtonClicked
                  }
                />
              </BookingBox>
              {booking.paymentForms && (
                <BookingBox title="Pagamento">
                  <BookingPayment paymentForms={booking.paymentForms} />
                </BookingBox>
              )}

              {!isOffline && (
                <div style={{ display: `none` }}>
                  <BookingBox title="Análise dos itens">
                    <BookingItemAnalysis
                      action={
                        <a
                          onClick={analysisItemEvents.onShowAnalysisItemClicked}
                        >
                          Clique aqui
                        </a>
                      }
                    />
                  </BookingBox>
                </div>
              )}
            </div>
            <BookingBox title="Segmentos">
              <BookingAirSegments
                booking={booking}
                bookingLocator={booking.bookingLocator}
                connectionSource={booking.connectionSource}
                airTrips={booking.airTrips ? booking.airTrips : [{}]}
                canEdit={canEdit}
                displayFlightReplacement={
                  false
                } /*TODO - implementar posteriormente*/
                onReplaceAirTripClick={
                  airTripReplacementEvents.onReplaceAirTripClicked
                }
                events={{
                  onEditSegmentsClick: editItemsEvents.onEditAirSegmentsClicked,
                }}
                dataSources={dataSources}
              />
            </BookingBox>

            <BookingBox title="Tarifas e taxas">
              <BookingAirTariffsAndFees
                key={booking.id}
                canEdit={canEdit}
                connectionSource={booking.connectionSource}
                tariffsAndFees={
                  booking.fares && booking.fares.length > 0
                    ? booking.fares.map((fare) => {
                        let empty = null;
                        let sale =
                          fare.travelerValue && fare.travelerValue.sale;

                        if (sale == null) {
                          let totalSale = 0;

                          if (fare.travellerValue) {
                            fare.travelerValue = fare.travellerValue;
                          }

                          if (fare.travelerValue.fare)
                            totalSale += fare.travelerValue.fare.value;

                          if (fare.travelerValue.originalTaxes)
                            totalSale += fare.travelerValue.originalTaxes.value;

                          if (fare.travelerValue.du)
                            totalSale += fare.travelerValue.du.value;

                          if (fare.travelerValue.penalty)
                            totalSale += fare.travelerValue.penalty.value;

                          sale = {
                            currencyCode:
                              fare.travelerValue.fare &&
                              fare.travelerValue.fare.currencyCode,
                            value: totalSale,
                          };
                        }

                        return {
                          connectionSource: fare.connectionSource,
                          type:
                            fare.travelerValue.type != null &&
                            fare.travelerValue.type != undefined
                              ? enums.passengerType[fare.travelerValue.type]
                              : empty,
                          originalFare: fare.travelerValue.originalFare
                            ? `${
                                fare.travelerValue.originalFare.currencyCode
                              } ${moneyFormatted(
                                fare.travelerValue.originalFare.value,
                                fare.travelerValue.originalFare.currencyCode,
                              )}`
                            : empty,
                          fare: fare.travelerValue.fare
                            ? `${
                                fare.travelerValue.fare.currencyCode
                              } ${moneyFormatted(
                                fare.travelerValue.fare.value,
                                fare.travelerValue.fare.currencyCode,
                              )}`
                            : empty,
                          du: fare.travelerValue.du
                            ? `${
                                fare.travelerValue.du.currencyCode
                              } ${moneyFormatted(
                                fare.travelerValue.du.value,
                                fare.travelerValue.du.currencyCode,
                              )}`
                            : empty,
                          sale: sale
                            ? `${sale.currencyCode} ${moneyFormatted(
                                sale.value,
                                sale.currencyCode,
                              )}`
                            : empty,
                          taxes: fare.travelerValue.taxes
                            ? `${
                                fare.travelerValue.taxes.currencyCode
                              } ${moneyFormatted(
                                fare.travelerValue.taxes.value,
                                fare.travelerValue.taxes.currencyCode,
                              )}`
                            : empty,
                          penalty: fare.travelerValue.penalty
                            ? `${
                                fare.travelerValue.penalty.currencyCode
                              } ${moneyFormatted(
                                fare.travelerValue.penalty.value,
                                fare.travelerValue.penalty.currencyCode,
                              )}`
                            : `${
                                fare.travelerValue.fare.currencyCode
                              } ${moneyFormatted(
                                0,
                                fare.travelerValue.fare.currencyCode,
                              )}`,
                          markup: fare.travelerValue.markup
                            ? `${
                                fare.travelerValue.markup.currencyCode
                              } ${moneyFormatted(
                                fare.travelerValue.markup.value,
                                fare.travelerValue.markup.currencyCode,
                              )}`
                            : empty,
                          exchangeRate: fare.exchangeRate
                            ? `${fare.exchangeRate.origin} ${moneyFormatted(
                                fare.exchangeRate.value,
                                fare.exchangeRate.origin,
                              )}`
                            : `${
                                fare.travelerValue.fare.currencyCode
                              } ${moneyFormatted(
                                1,
                                fare.travelerValue.fare.currencyCode,
                              )}`,
                          exchangeDate: fare.exchangeRate.date
                            ? getFormattedDateObjFromDateObject(
                                fare.exchangeRate.date,
                              ).dateFormatted
                            : booking.audit.createdAt
                            ? getFormattedDateObjFromDateObject(
                                booking.audit.createdAt,
                              ).dateFormatted
                            : empty,
                          fareBasis: fare.fareBasis ? fare.fareBasis : empty,
                          tourCode: fare.tourCode ? fare.tourCode : empty,
                          originalTariffsAndFees: fare,
                        };
                      })
                    : []
                }
                fares={booking.fares ? booking.fares : []}
                dataSources={dataSources}
                events={{
                  onEditAirTariffsAndFessClick:
                    editItemsEvents.onEditAirTariffsAndFessClicked,
                }}
              />
            </BookingBox>

            {
              <BookingBox title="Bilhete">
                <BookingAirTickets
                  key={booking.id}
                  canEdit={canEdit}
                  airTickets={booking.airTickets}
                  allowedActions={booking.allowedActions}
                  bookingLocator={booking.bookingLocator}
                  connectionSource={booking.connectionSource}
                  airBookingId={booking.id}
                  travelersList={travelersList}
                  onReplaceButtonClick={
                    airTicketIssueFlowEvents.onReplaceButtonClicked
                  }
                  dataSources={dataSources}
                  onEditAirTicketClick={editItemsEvents.onEditAirTicketClicked}
                  onAddAirTicketClick={editItemsEvents.onAddAirTicketClicked}
                  onShowEmailPopupClick={onShowEmailPopupClick}
                  onSendAppsClicked={bookingEvents.onSendAppsClicked}
                />
              </BookingBox>
            }
          </div>
        )}

        {analysisItemData.show && (
          <div className="exon-item-analysis-box">
            <Template.analysisItems
              data={booking.analysisItem}
              events={analysisItemEvents}
            />
          </div>
        )}

        {airTicketIssueFlowData.show && (
          <div className="exon-air-ticket-issue-flow-box">
            <Template.airTicketIssueFlow.issueFlow
              airTicketIssueData={airTicketIssueFlowData}
              events={airTicketIssueFlowEvents}
            />
          </div>
        )}

        {airTicketIssueFlowData.showNoTicketToIssueMessage && (
          <div className="exon-air-ticket-issue-flow-box">
            <Template.airTicketIssueFlow.noTicketToIssueMessage
              events={airTicketIssueFlowEvents}
            />
          </div>
        )}

        {airTripReplacementData.show && (
          <div className="exon-air-trip-replacement-flow-box">
            <Template.airTripReplacementFlow
              data={airTripReplacementData}
              events={airTripReplacementEvents}
            />
          </div>
        )}
      </div>
    );
  },

  analysisItems: ({ data, events }) => {
    return (
      <Popup
        okButtonLabel={null}
        title={'Analise dos itens'}
        exonStyle={true}
        cancelButtonClick={events.onHideAnalysisItemClicked}
        okButtonClick={null}
      >
        {!data.searchResults && (
          <div className="exon-box-air-loading">
            <span
              className={'exon-icon exon-icon-loading-plane-unfilled_enabled'}
            ></span>
            <p>
              Por favor aguarde!
              <br />
              Estamos carregando os itens para analise...
            </p>
          </div>
        )}

        {data.searchResults && (
          <AnalysisItems productType={enums.products.type.air} data={data} />
        )}
      </Popup>
    );
  },

  airTicketIssueFlow: {
    issueFlow: ({ airTicketIssueData, events }) => (
      <Popup
        title={'Emissão de Bilhetes'}
        exonStyle={true}
        cancelButtonClick={events.onCloseFlowClicked}
      >
        <AirIssueFlow
          dataSources={airTicketIssueData.dataSources}
          booking={airTicketIssueData.booking}
          requester={airTicketIssueData.requester}
          costCenter={airTicketIssueData.costCenter}
          tickets={airTicketIssueData.ticketsToIssue}
          replacement={airTicketIssueData.replacement}
          onCloseFlowClick={events.onCloseFlowClicked}
          onFinishFlowClick={events.onFinishFlowClick}
        />
      </Popup>
    ),
    noTicketToIssueMessage: ({ events }) => (
      <div className="exon-simple-message">
        <Popup
          title={''}
          exonStyle={true}
          cancelButtonClick={events.onCloseNoTicketToIssueMessage}
        >
          <span
            className={'exon-icon exon-icon-popup-info-unfilled_enabled'}
          ></span>
          <div className="exon-feedback-msg">
            Não existem bilhetes para a emissão
          </div>
        </Popup>
      </div>
    ),
  },

  airTripReplacementFlow: ({ data, events }) => (
    <Popup
      title={'Trocar segmento'}
      exonStyle={true}
      cancelButtonClick={events.onCloseFlowClicked}
    >
      <AirTripReplacement
        dataSources={data.dataSources}
        airTrips={data.airTrips}
        onCloseFlowClick={events.onCloseFlowClicked}
        onFinishFlowClick={events.onFinishFlowClick}
      />
    </Popup>
  ),
};

export default Template;
