'use strict';

import React from 'react';
import BookingTravelerListTemplate from './booking-traveler-list.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class BookingTravelerListComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <BookingTravelerListTemplate
          travelerList={
            this.props.travelerList ? this.props.travelerList : null
          }
          canEdit={this.props.canEdit}
        />
      </ErrorBoundary>
    );
  }
}
