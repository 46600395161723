export interface ExternalServiceConfig {
  url: string;
}

export interface Config {
  connectOnApi: ExternalServiceConfig;
  expenseGatewayApi: ExternalServiceConfig;
  awsBucket: ExternalServiceConfig;
}

export const config: Config = {
  connectOnApi: {
    url: process.env.CONNECTON_API_URL as string,
  },
  expenseGatewayApi: {
    url: process.env.EXPENSE_GATEWAY_API_URL as string,
  },
  awsBucket: {
    url: process.env.AWS_BUCKET_URL as string,
  },
};
