import {
  exportBookingsReport,
  getBookingsReportData,
} from '@api/connectOn/connectOn.service';
import {
  DataGrid,
  DateRangeField,
  Icons,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Button } from '@mui/material';
import { BASE_URL } from '@shared/constants/Routes.constants';
import { UserProfileData } from '@shared/types';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as SC from '../reports.styled';

export interface ReportBookingsProps {
  userProfile: UserProfileData;
}

type DateRange = { from: Date; to: Date };

export default function ReportBookings({ userProfile }: ReportBookingsProps) {
  const navigate = useNavigate();

  const contextId = userProfile?.company?.contextId?.toString();
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);

  const defaultStartAt = new Date();
  defaultStartAt.setMonth(defaultStartAt.getMonth() - 1);
  defaultStartAt.setDate(1);

  const [creationDateRange, setCreationDateRange] = useState<DateRange>({
    from: defaultStartAt,
    to: new Date(),
  });
  const [confirmDateRange, setConfirmDateRange] = useState<DateRange>({
    from: defaultStartAt,
    to: new Date(),
  });

  const pageSize = 50;
  const pageNumber = 1;

  const columns = useMemo(() => {
    if (!reportData?.records?.length) return [];
    return (
      reportData?.records[0].fields?.map((field: any) => {
        return {
          Header: <SC.GridHeader>{field.fieldName}</SC.GridHeader>,
          accessor: field.fieldName,
          disableSortBy: true,
          sticky: false,
        };
      }) || []
    );
  }, [reportData]);

  const data = useMemo(() => {
    if (!reportData?.records?.length) return [];
    return (
      reportData?.records.map((record: any) => {
        const obj = {};
        record.fields.forEach((field: any) => {
          obj[field.fieldName] = field.formattedValue;
        });
        return obj;
      }) || []
    );
  }, [reportData]);

  const getReportData = async () => {
    setIsLoading(true);

    const request = {
      startAt: creationDateRange.from,
      endAt: creationDateRange.to,
      confirmatedStartAt: confirmDateRange.from,
      confirmatedEndAt: confirmDateRange.to,
      pagingData: { page: pageNumber, itemsPerPage: pageSize },
    };

    const response = await getBookingsReportData(request, contextId);

    setIsLoading(false);
    setReportData(response.reportData);
  };

  const extract = async (format: 'xlsx' | 'json') => {
    const request = {
      startAt: creationDateRange.from,
      endAt: creationDateRange.to,
      confirmatedStartAt: confirmDateRange.from,
      confirmatedEndAt: confirmDateRange.to,
    };

    await exportBookingsReport(request, format, contextId);
  };

  return (
    <SC.ReportData>
      <LinkButton
        variant="neutral"
        onClick={() => navigate(`${BASE_URL}/reports`)}
      >
        <React.Fragment key=".0">
          <Icons name="IconArrowLeft" />
          Voltar
        </React.Fragment>
      </LinkButton>

      <SC.Divisor orientation="horizontal" />

      <SC.ReportFiltering>
        <DateRangeField
          label="Data de criação"
          fullWidth
          initialValue={creationDateRange}
          onChange={(e) => {
            setCreationDateRange({ from: e?.from, to: e?.to });
          }}
        />
        <DateRangeField
          label="Data de confirmação"
          fullWidth
          initialValue={confirmDateRange}
          onChange={(e) => {
            setConfirmDateRange({ from: e?.from, to: e?.to });
          }}
        />
      </SC.ReportFiltering>

      <SC.ReportFiltering>
        <Button
          variant="contained"
          size="medium"
          onClick={() => extract('xlsx')}
        >
          Extrair XLSX
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={() => extract('json')}
        >
          Extrair JSON
        </Button>
        <Button variant="outlined" size="small" onClick={() => getReportData()}>
          Exibir Prévia (somente {pageSize} primeiros registros)
        </Button>
      </SC.ReportFiltering>
      <SC.Divisor orientation="horizontal" />

      <DataGrid
        columns={columns}
        data={data}
        loading={isLoading}
        pageCount={reportData?.total}
        hasPagination={!!data.length}
      />
    </SC.ReportData>
  );
}
