'use strict';

import React from 'react';
import Template from './booking-air-segments.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';

import { enums } from '@legacy-utils/enums';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import { utils } from '@legacy-utils/utils';
import { componentUtil } from '@legacy-utils/componentUtil.js';

export default class BookingAirSegmentsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.formManager, this);
    componentUtil.react.bindMethods(this.events.form.fields, this);

    this.state = {
      displayFareRule: false,
      fareRuleIndex: null,
      airTripsEdit: JSON.parse(JSON.stringify(this.props.airTrips)),
      segments: this.props.segments ? this.props.segments : null,

      popupEditData: {
        show: false,
      },

      form: {
        show: false,
        events: this.events.form,
        fields: this.formManager.getFields(),
        segmentIndex: null,
        airTripIndex: null,
        forceValidation: false,
        action: null,
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          segments={this.state.segments}
          connectionSource={this.props.connectionSource}
          bookingLocator={this.props.bookingLocator}
          airTrips={this.props.airTrips ? this.props.airTrips : []}
          airTripsEdit={this.state.airTripsEdit}
          canEdit={this.props.canEdit}
          displayFareRule={this.state.displayFareRule}
          displayFlightReplacement={this.props.displayFlightReplacement}
          fareRuleIndex={this.state.fareRuleIndex}
          events={this.events}
          //Edição e adição de itens
          form={this.state.form}
          popupEditData={this.state.popupEditData}
        />
      </ErrorBoundary>
    );
  }

  events = {
    form: {
      popup: {
        onOpenFormPopupClicked: (action, segmentIndex, airTripIndex) => {
          let form = this.state.form;

          if (action == enums.actionsForm.edit) {
            this.formManager.filledFields(form, segmentIndex, airTripIndex);
          }

          this.generateFieldsAirTrips(form, this.state.airTripsEdit);

          form.segmentIndex = segmentIndex;
          form.airTripIndex = airTripIndex;
          form.action = action;
          form.show = true;

          this.setState({
            form,
            popupEditData: {
              popupEditData: {
                ...this.state.popupEditData,
                show: false,
              },
            },
          });
        },

        onCloseFormPopupClicked: () => {
          let form = this.state.form;
          this.formManager.clearFields(form);

          this.setState({
            form: {
              ...this.state.form,
              show: false,
              forceValidation: false,
              segmentIndex: null,
              airTripIndex: null,
              action: null,
            },
            popupEditData: {
              ...this.state.popupEditData,
              show: true,
            },
          });
        },

        onConfirmPopupClicked: () => {
          let airTripsEdit = this.state.airTripsEdit;
          let form = this.state.form;

          if (this.formManager.fieldsValidate()) {
            this.formManager.prepareSegmentForShipping(
              airTripsEdit,
              form.segmentIndex,
              form.airTripIndex,
            );
            this.formManager.clearFields(form);

            this.setState({
              form: {
                ...form,
                show: false,
                forceValidation: false,
                segmentIndex: null,
                airTripIndex: null,
                action: null,
              },
              popupEditData: {
                ...this.state.popupEditData,
                show: true,
              },
              airTripsEdit,
            });
          }
        },
      },

      fields: {
        onLocationChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value.name = inputText;

          this.setState({ form });
        },

        onChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value = inputText;

          this.setState({ form });
        },

        onSelected: (refName, selectedData) => {
          let form = this.state.form;

          if (refName == 'baggages')
            form.fields[refName].value = selectedData.value;
          else form.fields[refName].value = selectedData;

          this.setState({ form });
        },
      },
    },

    onOpenFareRuleClicked: (index) => {
      this.setState({
        displayFareRule: true,
        fareRuleIndex: index,
      });
    },

    onCloseFareRuleClicked: () => {
      this.setState({
        displayFareRule: false,
        fareRuleIndex: null,
      });
    },

    onReplaceAirTripClicked: (airTripIndex) => {
      this.props.onReplaceAirTripClick &&
        this.props.onReplaceAirTripClick(airTripIndex);
    },

    onOpenEditPopupClicked: () => {
      this.setState({
        popupEditData: {
          ...this.state.popupEditData,
          show: true,
        },
      });
    },

    onCloseEditPopupClicked: () => {
      let airTrips = JSON.parse(JSON.stringify(this.props.airTrips));
      let form = this.state.form;

      this.generateFieldsAirTrips(form, airTrips);

      this.setState({
        popupEditData: {
          ...this.state.popupEditData,
          show: false,
        },
        form,
        airTripsEdit: airTrips,
      });
    },

    onConfirmEditPopupClicked: () => {
      let airTripsEdit = this.state.airTripsEdit;

      if (this.formManager.fieldsValidadeAirTrips()) {
        this.formManager.prepareAirTripForShipping(airTripsEdit);

        this.props.events.onEditSegmentsClick &&
          this.props.events.onEditSegmentsClick(airTripsEdit);

        this.setState({
          popupEditData: {
            ...this.state.popupEditData,
            show: false,
          },
        });
      }
    },

    onAddAirTripClicked: () => {
      let airTripsEdit =
        this.state.airTripsEdit.length > 0 ? this.state.airTripsEdit : [{}];
      let form = this.state.form;
      airTripsEdit.push({});

      this.generateFieldsAirTrips(form, airTripsEdit);
      this.setState({ airTripsEdit, form });
    },

    onDeleteAirTripClicked: (index) => {
      let airTripsEdit = this.state.airTripsEdit;
      let form = this.state.form;
      airTripsEdit.splice(index, 1);
      delete form.fields[`supplier-${index}`];

      this.events.upsertAirTripFields(form, airTripsEdit, index);

      this.generateFieldsAirTrips(form, airTripsEdit);
      this.setState({ airTripsEdit, form });
    },

    //TODO: Melhorar
    upsertAirTripFields(form, airTrips, deletedIndex) {
      for (let index = deletedIndex; index < airTrips.length; index++) {
        let nextIndexValue = form.fields[`supplier-${index + 1}`];
        form.fields[`supplier-${index}`] = nextIndexValue;
      }

      if (deletedIndex + 1 <= airTrips.length)
        delete form.fields[`supplier-${deletedIndex + 1}`];
    },

    onDeleteSegmentClicked: (segmentIndex, airTripIndex) => {
      let airTripsEdit = this.state.airTripsEdit;
      airTripsEdit[airTripIndex].flights.splice(segmentIndex, 1);

      this.setState({ airTripsEdit });
    },
  };

  generateFieldsAirTrips(form, airTripsEdit) {
    this.props.airTrips.forEach((airTrip, index) => {
      delete form.fields[`supplier-${index}`];
      delete form.fields[`issuerAirline-${index}`];
    });

    airTripsEdit.forEach((airTrip, index) => {
      form.fields[`supplier-${index}`] = this.formManager.generateField.select({
        label: 'Fornecedor',
        refName: `supplier-${index}`,
        dataSource: this.props.dataSources.airSuppliers,
        productType: enums.products.type.air,
        readOnly:
          (this.props.connectionSource && this.props.connectionSource !== '') ||
          (airTrip.supplier && airTrip.supplier !== '')
            ? true
            : false,
        value:
          airTrip.supplier && airTrip.supplier !== ''
            ? airTrip.supplier
            : this.props.connectionSource,
        groupType: 'airTrip',
      });
    });
  }

  formManager = {
    getFields: () => {
      let airTrips = JSON.parse(JSON.stringify(this.props.airTrips));
      let fields = {};

      if (airTrips && airTrips.length > 0) {
        airTrips.forEach((airTrip, index) => {
          fields[`supplier-${index}`] = this.formManager.generateField.select({
            label: 'Fornecedor',
            refName: `supplier-${index}`,
            dataSource: this.props.dataSources.airSuppliers,
            productType: enums.products.type.air,
            readOnly:
              this.props.connectionSource && this.props.connectionSource !== ''
                ? true
                : false,
            value: this.props.connectionSource,
            groupType: 'airTrip',
          });
        });
      } else {
        fields[`supplier-${0}`] = this.formManager.generateField.select({
          label: 'Fornecedor',
          refName: `supplier-${0}`,
          dataSource: this.props.dataSources.airSuppliers,
          productType: enums.products.type.air,
          readOnly:
            this.props.connectionSource && this.props.connectionSource !== ''
              ? true
              : false,
          value: this.props.connectionSource,
          groupType: 'airTrip',
        });
      }

      fields.departure = this.formManager.generateField.location({
        label: 'Origem',
        refName: 'departure',
      });
      fields.arrival = this.formManager.generateField.location({
        label: 'Destino',
        refName: 'arrival',
      });
      fields.airline = this.formManager.generateField.select({
        label: 'Companhia aérea',
        refName: 'airline',
        dataSource: this.props.dataSources.airLines,
      });
      fields.operatedBy = this.formManager.generateField.select({
        label: 'Operado por',
        refName: 'operatedBy',
        dataSource: this.props.dataSources.airLines,
      });
      fields.flightNumber = this.formManager.generateField.text({
        label: 'Numero do vôo',
        refName: 'flightNumber',
      });
      fields.classOfService = this.formManager.generateField.text({
        label: 'Classe',
        refName: 'classOfService',
      });
      fields.cabinType = this.formManager.generateField.select({
        label: 'Cabine',
        refName: 'cabinType',
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolveMapEnum(enums.airClassesMap),
            );
          },
        },
      });
      fields.supplier = this.formManager.generateField.select({
        label: 'Fornecedor',
        refName: `supplier`,
        dataSource: this.props.dataSources.airSuppliers,
        productType: enums.products.type.air,
        readOnly:
          this.props.connectionSource && this.props.connectionSource !== ''
            ? true
            : false,
        value: this.props.connectionSource,
      });
      fields.departureDate = this.formManager.generateField.mask({
        label: 'Saída (da/mm/aaaa hh:mm)',
        refName: 'departureDate',
        mask: '99/99/9999 99:99',
        placeholder: '00/00/00 00:00',
        removeMask: false,
      });
      fields.arrivalDate = this.formManager.generateField.mask({
        label: 'Chegada (da/mm/aaaa hh:mm)',
        refName: 'arrivalDate',
        mask: '99/99/9999 99:99',
        placeholder: '00/00/00 00:00',
        removeMask: false,
      });
      fields.layover = this.formManager.generateField.number({
        label: 'Escala',
        refName: 'layover',
      });
      fields.status = this.formManager.generateField.select({
        label: 'Status do vôo',
        refName: 'status',
        adtionalClass: 'exon-select-top',
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolveEnum(enums.flightStatus),
            );
          },
        },
      });
      fields.locator = this.formManager.generateField.text({
        label: 'Loc Cia',
        refName: 'locator',
        required: false,
        readOnly: true,
        value: this.props.bookingLocator,
      });
      fields.baggages = this.formManager.generateField.select({
        label: 'Bagagem',
        refName: 'baggages',
        adtionalClass: 'exon-select-top',
        value: 0,
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolveEnum(enums.baggagesDescritions),
            );
          },
        },
      });

      return fields;
    },

    filledFields: (form, indexSegment, indexAirTrip) => {
      let airTripsEdit = this.state.airTripsEdit;
      let segment = airTripsEdit[indexAirTrip].flights[indexSegment];
      let getFormattedDateObjFromDateObject =
        utils.formatters.date.getFormattedDateObjFromDateObject;

      Object.keys(segment).forEach((refName, index) => {
        let currentFormField = form.fields[refName];
        let currentItem = segment[refName];

        if (
          currentFormField &&
          !currentFormField.groupType &&
          currentFormField.groupType !== 'airTrip'
        )
          switch (currentFormField.type) {
            case enums.fieldType.locations:
              form.fields[refName].value = currentItem;
              break;
            case enums.fieldType.number:
              form.fields[refName].value = currentItem;
              break;
            case enums.fieldType.select:
              if (refName == 'status')
                form.fields[refName].value = {
                  value: enums.flightStatus[currentItem],
                  label: currentItem,
                };
              else if (refName == 'baggages')
                form.fields[refName].value = currentItem ? currentItem - 1 : 0;
              else
                form.fields[refName].value =
                  typeof currentItem == 'object'
                    ? listItemsResolver.resolveItem(currentItem, 'code', 'name')
                    : currentItem;

              if (refName == 'supplier')
                form.fields[refName].value = this.props.connectionSource;
              break;
            default:
              if (refName == 'departureDate' || refName == 'arrivalDate')
                form.fields[refName].value = `${
                  getFormattedDateObjFromDateObject(currentItem).dateFormatted
                } ${
                  getFormattedDateObjFromDateObject(currentItem).timeFormatted
                }`;
              else {
                if (refName == 'locator')
                  form.fields[refName].value = this.props.bookingLocator;

                if (refName == 'supplier')
                  form.fields[refName].value = this.props.connectionSource;

                if (refName !== 'locator' && refName !== 'supplier')
                  form.fields[refName].value = currentItem;
              }
          }
      });
    },

    clearFields: (form) => {
      Object.keys(form.fields).forEach((refName) => {
        switch (form.fields[refName].type) {
          case enums.fieldType.locations:
            form.fields[refName].value = {};
            break;
          case enums.fieldType.select:
            if (
              !form.fields[refName].groupType &&
              form.fields[refName].groupType !== 'airTrip' &&
              refName !== 'supplier'
            )
              form.fields[refName].value = null;
            break;
          default:
            if (
              !form.fields[refName].groupType &&
              form.fields[refName].groupType !== 'airTrip' &&
              refName !== 'locator'
            )
              form.fields[refName].value = null;
        }
      });
    },

    generateField: {
      location: (data) => {
        let _this = this;
        return {
          type: enums.fieldType.locations,
          value: {},
          label: data.label,
          refName: data.refName,
          placeholder: `Digite ao menos 3 letras`,
          options: {
            events: {
              onInputChanged: (inputText) => {
                this.events.form.fields.onLocationChanged(
                  data.refName,
                  inputText,
                );
              },
              onSelected: (selectedData) => {
                this.events.form.fields.onSelected(data.refName, selectedData);
              },
            },
            dataSource: _this.props.dataSources.locations,
          },
          locationType: enums.locationType.airport,
          disabled: false,
          required: data.required ? data.required : true,
        };
      },
      number: (data) => {
        let numberField = this.formManager.generateField.commomFields(data);
        numberField.type = enums.fieldType.number;

        return numberField;
      },
      text: (data) => {
        let textField = this.formManager.generateField.commomFields(data);
        textField.type = enums.fieldType.text;

        return textField;
      },
      select: (data) => {
        let selectField = this.formManager.generateField.commomFields(data);
        selectField.type = enums.fieldType.select;
        selectField.items = [];
        selectField.onFieldChange = this.events.form.fields.onSelected;
        selectField.dataSource = data.dataSource;
        selectField.completeItem = true;
        selectField.isZeroValid = true;

        return selectField;
      },
      mask: (data) => {
        let fieldMask = this.formManager.generateField.commomFields(data);
        fieldMask.type = enums.fieldType.mask;
        fieldMask.mask = data.mask;
        data.removeMask !== undefined
          ? (fieldMask.removeMask = data.removeMask)
          : null;

        return fieldMask;
      },
      commomFields: (data) => {
        return {
          placeholder: data.placeholder ? data.placeholder : `Digite aqui`,
          label: data.label,
          refName: data.refName,
          value: data.value ? data.value : null,
          required:
            data.required != null || data.required != undefined
              ? data.required
              : true,
          readOnly: data.readOnly ? data.readOnly : false,
          disabled:
            data.disabled != null || data.disabled != undefined
              ? data.disabled
              : false,
          onFieldChange: this.events.form.fields.onChanged,
          adtionalClass: data.adtionalClass,
          groupType: data.groupType ? data.groupType : null,
        };
      },
    },

    fieldsValidate: () => {
      let isValid = true;
      let fields = this.state.form.fields;

      Object.keys(fields).forEach((refName) => {
        let fieldValue = fields[refName].value;
        let groupType = fields[refName].groupType;

        if (refName != 'locator' && (!groupType || groupType !== 'airTrip'))
          isValid =
            isValid &&
            fieldValue !== null &&
            fieldValue !== undefined &&
            fieldValue !== '';
      });

      if (!isValid)
        this.setState({
          form: {
            ...this.state.form,
            forceValidation: true,
          },
        });

      return isValid;
    },

    fieldsValidadeAirTrips: () => {
      let isValid = true;
      let form = this.state.form;
      let airTripsEdit = this.state.airTripsEdit;

      isValid = isValid && airTripsEdit && airTripsEdit.length > 0;

      airTripsEdit.forEach((airTrip, index) => {
        isValid = isValid && form.fields[`supplier-${index}`].value;
        /*isValid = isValid && form.fields[`issuerAirline-${index}`].value;*/
        isValid = isValid && airTrip.flights && airTrip.flights.length > 0;
      });

      if (isValid) return isValid;
      else {
        form.forceValidation = true;
        this.setState({ form });
      }
    },

    prepareAirTripForShipping: (airTripsEdit) => {
      let fields = this.state.form.fields;
      airTripsEdit.forEach((airTrip, index) => {
        airTrip.supplier = fields[`supplier-${index}`].value;
        //airTrip.issuerAirline = {
        //    code: fields[`issuerAirline-${index}`].value.value,
        //    name: fields[`issuerAirline-${index}`].value.label
        //}
      });
    },

    prepareSegmentForShipping: (airTripsEdit, segmentIndex, airTripIndex) => {
      let form = this.state.form;
      let segmentCurrent = {};

      if (!airTripsEdit[airTripIndex]) airTripsEdit[airTripIndex] = {};

      if (airTripsEdit[airTripIndex] && !airTripsEdit[airTripIndex].flights)
        airTripsEdit[airTripIndex].flights = [];

      Object.keys(form.fields).forEach((refName, index) => {
        let field = form.fields[refName];

        if (!field.groupType && field.groupType !== 'airTrip') {
          switch (field.type) {
            case enums.fieldType.locations:
              segmentCurrent[refName] = {
                city: field.value.city,
                iata: field.value.iata,
                id: field.value.id,
                name: field.value.name,
              };
              break;
            case enums.fieldType.number:
              segmentCurrent[refName] = field.value;
              break;
            case enums.fieldType.select:
              if (refName == 'airline' || refName == 'operatedBy')
                segmentCurrent[refName] = {
                  code: field.value.value,
                  name: field.value.label,
                };

              if (refName == 'cabinType')
                segmentCurrent[refName] =
                  typeof field.value == 'object'
                    ? parseInt(field.value.value)
                    : parseInt(field.value);

              if (refName == 'status')
                segmentCurrent[refName] = field.value.label;

              if (refName == 'baggages')
                airTripsEdit[airTripIndex][refName] = field.value + 1;

              break;
            default:
              if (refName == 'departureDate' || refName == 'arrivalDate')
                segmentCurrent[refName] = utils.date.strDateTimeToObjectDate(
                  field.value,
                  '/',
                );
              else segmentCurrent[refName] = field.value;
          }
        }
      });

      if (segmentIndex !== null)
        airTripsEdit[airTripIndex].flights[segmentIndex] = segmentCurrent;
      else {
        airTripsEdit[airTripIndex].flights.push(segmentCurrent);
      }
    },
  };
}
