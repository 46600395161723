import {
  SelectField,
  TextArea,
  Typography,
  Loader,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';
import { dataSources } from '@legacy-dataSources/travelRequests.dataSources';
import { useTravelStore } from '../../../../store/RequestTravel.store';

interface TravelReasonProps {
  control: Control;
  errors: FieldErrors;
  handleIsDescription: (isDescriptionRequired: boolean) => void;
}

export function TravelReason({
  errors,
  control,
  handleIsDescription,
}: TravelReasonProps) {
  const contextId = useTravelStore((state) => state.contextId);

  const [optionsReason, setOptionsReason] = useState([]);
  const [isDescriptionRequired, setNeedDescriptionRequired] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadOptions = useCallback(async () => {
    if (optionsReason.length === 0) {
      setIsLoading(true);
      try {
        const loadOptions = {
          contextId: contextId,
        };
        const travels_reasons_res = await dataSources.travelReason.load(
          loadOptions,
        );
        setOptionsReason(travels_reasons_res);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  }, [optionsReason, contextId, setOptionsReason]);

  return (
    <div
      className={'motivo-viajem'}
      style={{ paddingTop: 20, paddingBottom: 20 }}
    >
      <div style={{ marginBottom: '10px' }}>
        <Typography variant={'headline8'} variantColor={'#3B2E37'}>
          Motivo da viagem
        </Typography>
      </div>
      <Controller
        control={control}
        name="reason_travel"
        render={({ field: { onChange, value } }) => (
          <div style={{ position: 'relative' }}>
            <SelectField
              onSelectChange={(event, selectedItem) => {
                if (selectedItem) {
                  const isRequired =
                    !!selectedItem.reason.IsDescriptionRequired;
                  setNeedDescriptionRequired(isRequired);
                  handleIsDescription(isRequired);
                  onChange(selectedItem);
                }
              }}
              onFocus={loadOptions}
              searchable={true}
              value={value}
              options={optionsReason}
              label={'Selecione o motivo'}
              error={!!errors.reason_travel}
              helperText={
                <DisplayErrorFormValidation
                  errors={errors}
                  fieldName="reason_travel"
                />
              }
            />
            {isLoading && (
              <div style={{ position: 'absolute', top: '26px', left: '16px' }}>
                <Loader size={'extraSmall'} variant={'secondary'} />
              </div>
            )}
          </div>
        )}
      />
      {isDescriptionRequired && (
        <Controller
          control={control}
          name="description_reason_travel"
          render={({ field: { onChange, value, ...field } }) => (
            <div>
              <TextArea
                onChange={onChange}
                value={value}
                {...(field as any)}
                placeholder={'Motivo da viagem'}
              />
              <DisplayErrorFormValidation
                errors={errors}
                fieldName="description_reason_travel"
              />
            </div>
          )}
        />
      )}
    </div>
  );
}
