export const compressorResolver = {
  compress: (data) => {
    if (!data) return;
    let jsonData = JSON.stringify(data).replace(/[\u{0080}-\u{FFFF}]/gu, ''); // TODO: utilizar conversor que aceite UTF-8
    return btoa(jsonData);
    //let jsonData = JSON.stringify(data);
    //return LZString.compressToBase64(jsonData);
  },

  descompress: (data) => {
    if (!data) return;
    let decompressData = atob(data);
    return JSON.parse(decompressData);
    //let decompressData = LZString.decompressFromBase64(data);
    //return JSON.parse(decompressData);
  },

  hotel: {
    resolveProperties: (hotelOption) => {
      let utils = compressorResolver._utils;
      let hotel = hotelOption.hotel;

      hotel.description = utils.removeSpecialCharacteres(hotel.description);

      hotel.selectedRooms &&
        hotel.selectedRooms.forEach((selectedRoom) => {
          selectedRoom.room.description = utils.removeSpecialCharacteres(
            selectedRoom.room.description,
          );
        });

      return hotelOption;
    },
  },

  _utils: {
    removeSpecialCharacteres: (content) => {
      if (content) content = unescape(encodeURIComponent(content));

      return content;
    },
  },
};
