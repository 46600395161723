import { getCustomFields } from '@api/connectOn/connectOn.service';
import { useEffect, useState } from 'react';
import { useTravelStore } from '../../../store/RequestTravel.store';
import { CriteriaFieldsProps, CustomField, SelectOption } from '../types';

type Props = Pick<CriteriaFieldsProps, 'fields'>;

const getInitialFields = ({ fields }: Props) => {
  const options: SelectOption[] = [];

  options.push({
    label: 'Filial',
    value: fields.branch?.value,
  });

  options.push({
    label: 'Área',
    value: fields.area?.value,
  });

  options.push({
    label: 'Cargo',
    value: fields.position?.value,
  });

  options.push({
    label: 'Resp. Centro de Custo',
    value: fields.costCenterResponsible?.value,
  });

  options.push({
    label: 'Tipo de Viagem',
    value: fields.travelType?.value,
  });

  options.push({
    label: 'Resultado da Política',
    value: fields.policyResult?.value,
  });

  options.push({
    label: 'Valor total da RV',
    value: fields.totalRvValue?.value,
  });

  options.push({
    label: 'Empresa',
    value: fields.company?.value,
  });

  options.push({
    label: 'Projeto',
    value: fields.project?.value,
  });

  options.push({
    label: 'Usuário',
    value: fields.user?.value,
  });

  return options.filter((o) => !!o?.value);
};

export function useConditionFields({ fields }: Props) {
  const contextId = useTravelStore((state) => state.contextId);

  const [fixedOptions, setFixedOptions] = useState(
    getInitialFields({ fields }),
  );

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  useEffect(() => {
    const fetchCustomFields = async () => {
      try {
        const response = await getCustomFields(contextId);
        if (response.successful && response.fields) {
          setCustomFields(response.fields);

          const newOptions = response.fields.map((field) => ({
            label: field.label,
            value: field.customFieldId,
          }));

          setFixedOptions((current) => [...current, ...newOptions]);
        }
      } catch (error) {
        console.error('Error fetching custom fields:', error);
      }
    };

    fetchCustomFields();
  }, [contextId]);

  return {
    fixedOptions,
    customFields,
  };
}
