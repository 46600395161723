import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

export interface CardProps {
  iconName: IconsProps;
  description: string;
  title: string;
}

export const Card = ({ iconName, title, description }) => {
  return (
    <SC.Container>
      <SC.Content>
        <SC.ContainerIcon>
          <SC.Icon name={iconName} size={32} fill="transparent" />
        </SC.ContainerIcon>
        <SC.Title variant="body3">{title}</SC.Title>
        <SC.Description>{description}</SC.Description>
      </SC.Content>
    </SC.Container>
  );
};
