import { enums } from '../enums';

export const travelerResolver = {
  buildInsertTravelerRequest: (traveler) => {
    const _this = travelerResolver;
    return _this.convertComponentTravelerToApi(traveler);
  },
  buildUpdateTravelerRequest: (traveler) => {
    const _this = travelerResolver;
    return _this.convertComponentTravelerToApi(traveler);
  },

  //#region COMPONENT > API
  convertComponentTravelerToApi: (traveler) => {
    const _this = travelerResolver;

    const [bDay, bMonth, bYear] = traveler.birthDate.split('/');
    return {
      Id: traveler.id,
      ContextId: traveler.contextId,
      CreatedAt: traveler.createdAt,
      CreatedBy: traveler.createdBy,
      UserId: traveler.userId,
      HonorificPrefix: traveler.name.honorificPrefix,
      FirstName: traveler.name.firstName,
      MiddleName: traveler.name.middleName,
      LastName: traveler.name.lastName,
      UseSocialName: traveler.useSocialName,
      SocialHonorificPrefix: traveler.socialName.honorificPrefix,
      SocialFirstName: traveler.socialName.firstName,
      SocialMiddleName: traveler.socialName.middleName,
      SocialLastName: traveler.socialName.lastName,
      Alias: traveler.alias,
      BirthDate: new Date(bYear, bMonth - 1, bDay).toJSON().replace('Z', ''), //Remoção do timezone para preenchiento correto de acordo com data a do servidor
      Gender:
        traveler.gender == enums.gender.Masculino
          ? 'M'
          : enums.gender.Feminino
          ? 'F'
          : null,
      Genre:
        traveler.gender == enums.gender.Masculino
          ? 'M'
          : enums.gender.Feminino
          ? 'F'
          : null, // TODO: deletar após correção no back
      Email: traveler.email,
      NationalityLocationId: traveler.nationalityLocationId,
      PhoneNumber: traveler.phone,
      IsVIP: traveler.isVIP,
      TravelerAddressId: traveler.travelerAddressId,
      Documents: _this.convertComponentTravelerDocumentsToApi(
        traveler.documents,
        traveler.id,
      ),
      CostCenter: traveler.costCenter
        ? _this.convertComponentCostCenterToApi(traveler.costCenter)
        : null,
      Address: _this.convertComponentAddressToApi(traveler.address),
    };
  },
  convertComponentTravelerDocumentsToApi: (componentDocuments, travelerId) => {
    const documensApi = [];
    componentDocuments.forEach((document) => {
      let expirationDate = undefined;

      if (document.expiration) {
        const [expDay, expMonth, expYear] =
          document.expiration && document.expiration.split('/');

        if (expYear)
          expirationDate = new Date(expYear, expMonth - 1, expDay).toJSON();
      }

      documensApi.push({
        Id: document.id,
        TravelerId: travelerId || 0,
        DocumentTypeId: document.type,
        Number: document.number,
        PrintedName: document.printedName,
        ExpirationDate: expirationDate,
      });
    });
    return documensApi;
  },
  convertComponentCostCenterToApi: (costCenter) => {
    return {
      Id: costCenter.id,
      Name: costCenter.name,
      Code: costCenter.code,
    };
  },
  convertComponentAddressToApi: (address) => {
    return {
      Id: address.id,
      Street: address.street,
      Complement: address.complement,
      Number: address.number,
      State: address.state,
      City: address.city,
      ZipCode: address.zipCode,
    };
  },
  //#endregion

  //#region API > COMPONENT
  convertApiTravelerToComponent: (apiTraveler) => {
    const _this = travelerResolver;

    if (apiTraveler.Genre)
      // TODO: retirar após correção do backend de Genre para Gender
      apiTraveler.Gender = apiTraveler.Genre;

    return {
      id: apiTraveler.Id,
      userId: apiTraveler.UserId,
      reference: `${apiTraveler.Id}_${apiTraveler.UserId}`,
      label: `${apiTraveler.FullName}${
        apiTraveler.Alias ? ` - ${apiTraveler.Alias}` : ''
      }`,
      contextId: apiTraveler.ContextId,
      createdAt: apiTraveler.CreatedAt,
      createdBy: apiTraveler.CreatedBy,
      fullName: apiTraveler.FullName,
      name: {
        honorificPrefix: apiTraveler ? apiTraveler.HonorificPrefix : '',
        firstName: apiTraveler ? apiTraveler.FirstName : '',
        middleName: apiTraveler ? apiTraveler.MiddleName : '',
        lastName: apiTraveler ? apiTraveler.LastName : '',
      },
      useSocialName: apiTraveler ? apiTraveler.UseSocialName : false,
      socialName: {
        honorificPrefix: apiTraveler ? apiTraveler.SocialHonorificPrefix : '',
        firstName: apiTraveler ? apiTraveler.SocialFirstName : '',
        middleName: apiTraveler ? apiTraveler.SocialMiddleName : '',
        lastName: apiTraveler ? apiTraveler.SocialLastName : '',
      },
      alias: apiTraveler.Alias,
      birthDate:
        apiTraveler && apiTraveler.BirthDate
          ? new Date(apiTraveler.BirthDate).toLocaleDateString('pt-BR')
          : '',
      gender: apiTraveler
        ? apiTraveler.Gender == 'M'
          ? enums.gender.Masculino
          : apiTraveler.Gender == 'F'
          ? enums.gender.Feminino
          : ''
        : '',
      email: apiTraveler ? apiTraveler.Email : '',
      nationalityLocationId: apiTraveler
        ? apiTraveler.NationalityLocationId
        : '',
      phone: apiTraveler ? apiTraveler.PhoneNumber : '',
      isVIP: apiTraveler.IsVIP ? apiTraveler.IsVIP : false,
      travelerAddressId: apiTraveler.TravelerAddressId,
      externalId: apiTraveler.ExternalId,
      documents: apiTraveler
        ? _this.convertApiTravelerDocumentsToComponentObj(apiTraveler.Documents)
        : [],
      costCenter:
        apiTraveler && apiTraveler.CostCenter
          ? _this.convertApiCostCenterToComponentObj(apiTraveler.CostCenter)
          : null,
      address: _this.convertApiAddressToComponentObj(apiTraveler.Address),
    };
  },
  convertApiTravelerDocumentsToComponentObj: (apiDocuments) => {
    const documensObj = [];
    apiDocuments.forEach((dApi) => {
      documensObj.push({
        id: dApi.Id,
        type: dApi.DocumentTypeId,
        number: dApi.Number,
        printedName: dApi.PrintedName,
        expiration: dApi.ExpirationDate
          ? new Date(dApi.ExpirationDate).toLocaleDateString('pt-BR')
          : '',
      });
    });
    return documensObj;
  },
  convertApiCostCenterToComponentObj: (costCenter) => {
    return {
      id: costCenter.Id,
      name: costCenter.Name,
      code: costCenter.Code,
    };
  },
  convertApiAddressToComponentObj: (address) => {
    return {
      id: address && address.Id ? address.Id : 0,
      street: address && address.Street ? address.Street : '',
      complement: address && address.Complement ? address.Complement : '',
      number: address && address.Number ? address.Number : '',
      state: address && address.State ? address.State : '',
      city: address && address.City ? address.City : '',
      zipCode: address && address.ZipCode ? address.ZipCode : '',
    };
  },
  //#endregion

  getFullName: (traveler) => {
    const _this = travelerResolver;
    if (traveler.useSocialName)
      return _this._concatTravelerName({ ...traveler.socialName });
    else {
      let nameComposition = null;
      if (traveler.name && traveler.name.firstName)
        nameComposition = traveler.name;
      else nameComposition = traveler;

      return _this._concatTravelerName({ ...nameComposition });
    }
  },
  _concatTravelerName: (nameComposition) => {
    let fullName = nameComposition.firstName;
    if (nameComposition.middleName)
      fullName += ' ' + nameComposition.middleName;
    if (nameComposition.lastName) fullName += ' ' + nameComposition.lastName;

    return fullName;
  },

  getTravelerHash: (traveler) => {
    let _normalize = (str) => {
      return str ? str.toLowerCase().trim() : '';
    };

    let travelerHash = `${traveler.type}_${_normalize(
      traveler.gender,
    )}_${_normalize(traveler.firstName)}_${_normalize(
      traveler.middleName,
    )}_${_normalize(traveler.lastName)}`;
    return travelerHash;
  },
};
