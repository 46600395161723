'use strict';

import React from 'react';
import HotelBookingInfosTemplate from './hotel-booking-infos.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';

export default class HotelBookingInfosComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <HotelBookingInfosTemplate
          hotelBookingInfos={
            this.props.hotelBookingInfos ? this.props.hotelBookingInfos : null
          }
        />
      </ErrorBoundary>
    );
  }
}
