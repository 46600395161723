import React from 'react';
import AutoComplete from '../common/auto-complete/auto-complete.component';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

const Template = {
  main: ({
    options,
    selectedItem,
    input,
    dataSource,
    onChange,
    onInputChange,
    disabledFields,
    notValid,
    filter,
  }) => {
    return (
      <span
        title={selectedItem?.label || 'Informe o centro de custo'}
        className={`exon-split-inline exon-grid-split-cost-center${
          notValid ? ' exon-error' : ''
        }`}
      >
        <AutoComplete
          options={options}
          selectedItem={selectedItem}
          inputText={input ? input : ''}
          waitForMinInput={false}
          dataSource={dataSource}
          onChange={onChange}
          onInputChange={onInputChange}
          required={true}
          disabled={disabledFields}
          externalOptions={{ filter }}
          placeholder="Informe o centro de custo"
        />
        {notValid && (
          <span className="exon-error-message">
            <Icons
              className="exon-error-icon"
              fill="transparent"
              name="IconAlertCircle"
              size={12}
            />
            <p> O centro de custo é obrigatório</p>
          </span>
        )}
      </span>
    );
  },
};

export default Template;
