import styled from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

export const TypographyContainer = styled(Typography)`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  :after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: #e0e0e0;
    margin-left: 10px;
  }
`;
