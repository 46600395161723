import { HROS_ACCESS_TOKEN } from '@shared/constants/Storage.constants';
import { LOGIN_URL } from '@shared/constants/Routes.constants';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { Navigate, Outlet } from 'react-router-dom';

export const RoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const accessToken = getFromLS(HROS_ACCESS_TOKEN);
  if (!accessToken /*|| checkIsExpired(accessToken)*/) {
    return <Navigate to={LOGIN_URL} />;
  }

  return children ? children : <Outlet />;
};
