'use strict';

import React from 'react';
import Template from './travel-request-details.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { statusFlowResolver } from '@legacy-utils/resolvers/statusFlowResolver.js';
import { componentUtil } from '@legacy-utils/componentUtil.js';
import {
  splitMethods,
  resetSplitData,
} from '../../cost-center-split/split-methods';
import { utils } from '../../../utils/utils.js';
import { apiResponse } from '../../../utils/apiResponse.js';

export default class TravelRequestDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.splitRef = React.createRef();

    const { totalValue } = this.props.request.valueData;
    const { costSplit } = this.props.request;
    const tripTotalValue = totalValue?.value
      ? new utils.money(totalValue.value, totalValue.currencyCode)
      : new utils.money(0, 'BRL');

    this.state = {
      isEqualSplitEnabled: false,
      costCenterSplit:
        costSplit?.length > 1 ? resetSplitData(costSplit, tripTotalValue) : [],
      tripTotalValue,
      selectedCostCenter: null,
      openSplitModal: false,
      companySettings: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.dataSources.companySettings.callLoad();
  }

  render() {
    let request = this.fillItemsSummaryAllowedActions(
      this.props.request,
      this.props.currentUserData,
    );

    return (
      <ErrorBoundary>
        <Template.main
          request={request}
          canEdit={this.props.canEdit}
          decisionEvents={this.props.decisionEvents}
          justificationEvents={this.props.justificationEvents}
          userTravelProfile={this.props.userTravelProfile}
          events={this.events}
          dataSources={this.props.dataSources}
          loading={this.state.loading}
          splitRef={this.splitRef}
          splitProps={{
            isCostSplitAvailable: this.state.companySettings.splitCostCenter,
            openSplitModal: this.state.openSplitModal,
            costCenterSplit: this.costCenterSplit.getProps(
              this.props.dataSources.costCenter,
            ),
          }}
        />
      </ErrorBoundary>
    );
  }

  fillItemsSummaryAllowedActions = (request, currentUserData) => {
    if (request.itemsSummary) {
      request.itemsSummary.forEach((summary) => {
        if (summary.workflow) {
          summary.allowedActions = statusFlowResolver.getAllowedActions(
            summary.workflow.status,
            currentUserData.company,
            summary.expiration,
            request.audit.dataOrigin,
            request.requester,
            currentUserData.userId,
            this.props.userTravelProfile,
          );

          if (
            summary.allowedActions.canAuthorize ||
            summary.allowedActions.canRefuse
          )
            summary.allowedActions.currentApprover =
              request.allowedActions.currentApprover;
        }
      });
    }

    return request;
  };

  costCenterSplit = {
    getProps: splitMethods.bind(this),
  };

  dataSources = {
    companySettings: {
      callLoad: () => {
        const { dataSources, request } = this.props;

        dataSources.companySettings
          .load(request?.contextId)
          .then((response) => {
            if (response.errors.length > 0 || !response.successful)
              apiResponse.successful.showErrorIfExists(
                response,
                'Ocorreu um erro!',
              );

            this.setState({ companySettings: response.companySettings });
          })
          .catch(apiResponse.http.showErrorIfExists);
      },
    },
  };

  events = {
    onOpenSelectItemTypeClicked: () => {
      this.props?.events?.onOpenSelectItemTypeClick &&
        this.props.events.onOpenSelectItemTypeClick();
    },
    toggleSplitModal: (isEdit) => {
      const newState = {
        openSplitModal: !this.state.openSplitModal,
      };

      if (isEdit !== undefined) newState.isEdit = !!isEdit;

      if (!this.state.openSplitModal) {
        const { totalValue } = this.props.request.valueData;
        const { costSplit } = this.props.request;

        newState.isEqualSplitEnabled = false;
        newState.costCenterSplit =
          costSplit?.length > 1 ? resetSplitData(costSplit, totalValue) : [];
      }

      this.setState(newState);
    },
    updateCostSplit: () => {
      if (!this.costCenterSplit.getProps().isValid()) return;

      const { request, dataSources, events } = this.props;

      this.setState({ loading: true }, () => {
        dataSources.serviceRequest
          .costSplitUpdate({
            contextId: request?.contextId,
            rootContextId: request?.rootContextId,
            serviceRequestId: request?.id,
            costSplitItems: this.state.costCenterSplit.map((item) => {
              return {
                percentage: item.percentage,
                costCenter: {
                  id: item.costCenter.value,
                  code: item.costCenter.code,
                  description: item.costCenter.label,
                },
              };
            }),
          })
          .then((response) => {
            if (response.successful || !response?.errors?.length) {
              return this.setState(
                { loading: false, openSplitModal: false },
                () => {
                  events.onCostSplitChanged({ request });
                },
              );
            }

            return this.setState({ loading: false }, () => {
              apiResponse.successful.showErrorIfExists(
                response,
                'Ocorreu um erro!',
              );
            });
          })
          .catch((error) => {
            this.setState({ loading: false }, () => {
              apiResponse.http.showErrorIfExists(error, 'Ocorreu um erro!');
            });
          });
      });
    },
  };
}
