import { Gallery } from '@components/Gallery/Gallery';

import HotelBaseInfos from '../products-travel/hotel-product/hotel-base-infos/hotel-base-infos.component.js';
import Icon from '../common/icon/icon.component.js';
import HotelAddress from '../products-travel/hotel-product/hotel-address/hotel-address.component.js';
import { utils } from '../../utils/utils.js';
import { enums } from '../../utils/enums.js';

let getMoneyFormatted = utils.formatters.money.getFormatted;
let getCurrencySymbol = utils.converters.money.getCurrencySymbol;

var ProductSummaryTemplate = {
  main: ({
    productType,
    product,
    travelers,
    hasPreBooking,
    enableProductCheckbox,
    productActions,

    onSelectedMoveTripProductChange,
  }) => {
    if (productType == enums.productType.air) {
      return (
        <div className="exon-product-summary">
          <ProductSummaryTemplate.productTravel
            product={product}
            travelers={travelers}
            hasPreBooking={hasPreBooking}
            productActions={productActions}
            enableProductCheckbox={enableProductCheckbox}
            onSelectedMoveTripProductChange={onSelectedMoveTripProductChange}
          />
        </div>
      );
    }

    if (productType == enums.productType.hotel) {
      return (
        <div className="exon-product-summary">
          <ProductSummaryTemplate.productHotel
            product={product}
            productActions={productActions}
            enableProductCheckbox={enableProductCheckbox}
            onSelectedMoveTripProductChange={onSelectedMoveTripProductChange}
          />
        </div>
      );
    }

    if (productType == enums.productType.none) {
      return (
        <div>
          <p>
            Erro interno!
            <br />
            Verifique o productType.
          </p>
        </div>
      );
    }
  },

  productTravel: ({
    product,
    travelers,
    hasPreBooking,
    enableProductCheckbox,
    productActions,

    onSelectedMoveTripProductChange,
  }) => {
    if (product) {
      let trip = product.tripList ? product.tripList : product.trips;
      let fare = product.tripList
        ? product.tripList.fareOptions
        : product.fareOptions;
      let multipleTrips = trip.trips
        ? trip.trips.length > 1
          ? true
          : false
        : false;

      let tripsOptions;

      if (multipleTrips) {
        tripsOptions =
          trip.trips &&
          trip.trips.map((tripItem, index) => {
            return (
              <ProductSummaryTemplate.productAirDetails
                key={index}
                trip={tripItem}
                fare={tripItem.fareOptions}
                indexTrip={index + 1}
                totalItens={trip.trips.length}
              />
            );
          });
      } else {
        tripsOptions = trip.map((tripItem, index) => {
          return (
            <ProductSummaryTemplate.productAirDetails
              key={index}
              trip={tripItem}
              fare={fare}
              indexTrip={index + 1}
              totalItens={trip.length}
            />
          );
        });
      }

      return (
        <div className="exon-product-details">
          <div
            className={
              'exon-input-check' + (enableProductCheckbox ? '' : ' hide')
            }
          >
            <label className="exon-select__list-item-box">
              <input
                type="checkbox"
                name="exon-select-option"
                onChange={(ev) =>
                  onSelectedMoveTripProductChange(
                    ev,
                    product.groupIndex,
                    product.productIndex,
                  )
                }
                checked={product.selected}
              />
              <label className="exon-label-checkbox ">
                <span></span>
              </label>
            </label>
          </div>

          <Gallery showNavigation={tripsOptions.length > 1}>
            {tripsOptions}
          </Gallery>

          <div className="exon-value-details">
            <div className="exon-box-value">
              <span className="exon-value-currency">
                {fare.price.total.currencySymbol
                  ? fare.price.total.currencySymbol
                  : fare.price.currencySymbol}
              </span>
              <span className="exon-value">{fare.price.total.formatted}</span>
            </div>
            <div
              className={
                'exon-descriptions-values' +
                (enableProductCheckbox ? ' hide' : '')
              }
            >
              <div className="exon-description">
                <p>Tarifas</p>
                <p>Taxas</p>
                <p>Serviços</p>
              </div>
              <div className="exon-values">
                <p>
                  {(fare.price.totalFare
                    ? fare.price.totalFare.currencySymbol
                    : fare.price.currencySymbol) +
                    ' ' +
                    (fare.price.totalFare
                      ? fare.price.totalFare.formatted
                      : fare.price.fare.formatted)}
                </p>
                <p>
                  {(fare.price.totalTaxes
                    ? fare.price.totalTaxes.currencySymbol
                    : fare.price.currencySymbol) +
                    ' ' +
                    (fare.price.totalTaxes
                      ? fare.price.totalTaxes.formatted
                      : fare.price.embarkTaxes.formatted)}
                </p>
                {fare.price.totalServices && (
                  <p>
                    {fare.price.totalServices.currencySymbol +
                      ' ' +
                      fare.price.totalServices.formatted}
                  </p>
                )}

                {fare.price.serviceTaxes && (
                  <p>
                    {fare.price.currencySymbol +
                      ' ' +
                      fare.price.serviceTaxes.formatted}
                  </p>
                )}
              </div>
            </div>
            {productActions && (
              <div
                className={
                  'exon-product-actions' +
                  (enableProductCheckbox ? ' hide' : '')
                }
              >
                {productActions}
              </div>
            )}
            {travelers && (
              <div className="exon-travelers">
                {hasPreBooking ? (
                  <span>
                    * Valores referente a{' '}
                    {utils.traveler.travelersFormatted(travelers)}
                  </span>
                ) : (
                  <span>* Valores referente a 1 viajante</span>
                )}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Erro interno!
            <br />
            Verificque o product.
          </p>
        </div>
      );
    }
  },

  productAirDetails: ({ trip, fare, indexTrip, totalItens }) => {
    return (
      <div className="exon-box-product">
        <div className="exon-company">
          <span
            style={{ backgroundImage: `url(${trip.airLine.imageUrl})` }}
            className="exon-logo-airline"
          >
            {!trip.airLine.imageUrl
              ? trip.airLine.name || trip.airLine.code
              : ''}
          </span>
        </div>
        <div className="exon-trip-date">
          <p>
            {trip.departure.date.dayOfWeek},
            <br />
            <span>{trip.departure.date.dateShortFormatted}</span>
            <br />
          </p>
          <span>{trip.airClass.name}</span>
        </div>
        <div className="exon-trip-airports">
          <div>
            <p>{trip.departure.iata}</p>
            <p>{trip.arrival.iata}</p>
          </div>
          <div>
            <p>{trip.departure.date.timeFormatted}</p>
            <p>{trip.arrival.date.timeFormatted}</p>
          </div>
        </div>
        <div className="exon-trip-settings">
          <p>
            {trip.stops != 1 ? trip.stops + ' paradas' : trip.stops + ' parada'}
          </p>
          <span>
            <span className="exon-icon exon-icon-clock-unfilled_enabled"></span>
            {trip.duration.durationFormatted}
          </span>

          {(fare.additionalInfo &&
            fare.additionalInfo.baggage.map((item, index) => {
              return <p key={index}>{item}</p>;
            })) || <p>"Sem Informação"]</p>}
          {trip.originalAirTrip ? (
            <p>
              {' '}
              Nº Vôo{' '}
              {trip.originalAirTrip.flights
                .map((flight) => flight.flightNumber)
                .toString()
                .replace(',', ' / ')}
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  },

  productHotel: ({
    product,
    enableProductCheckbox,
    productActions,

    onSelectedMoveTripProductChange,
  }) => {
    if (product) {
      const showRadiusInfo = (filter => ![null,undefined].includes(filter?.geoLocation))
      let hotel = product.hotel;
      let room = product.selectedRooms[0];
      return (
        <div className="exon-product-details">
          <div
            className={
              'exon-input-check' + (enableProductCheckbox ? '' : ' hide')
            }
          >
            <label className="exon-select__list-item-box">
              <input
                type="checkbox"
                name="exon-select-option"
                onChange={(ev) =>
                  onSelectedMoveTripProductChange(
                    ev,
                    product.groupIndex,
                    product.productIndex,
                    enums.productType.hotel,
                  )
                }
                checked={product.selected}
              />
              <label className="exon-label-checkbox ">
                <span></span>
              </label>
            </label>
          </div>

          <div className="exon-box-product-hotel">
            <div className="exon-hotel-summary">
              <div className="exon-hotel-infos">
                <HotelBaseInfos
                  name={hotel.name}
                  rating={hotel.stars}
                  connectionSource={room.sourceIdentifier}
                  sourceSupplier={''}
                  chain={hotel.chain}
                />
              </div>

              <div className="exon-hotel-room">
                <div className="exon-hotel-room-meal">
                  {room.fares[0].fareInformation.board && (
                    <Icon
                      icon={
                        room.fares[0].fareInformation.board.name ||
                        room.fares[0].fareInformation.board.description
                          ? 'exon-icon-coffee-unfilled_enabled'
                          : 'exon-icon-coffee-unfilled_disabled'
                      }
                      description={
                        room.fares[0].fareInformation.board.name
                          ? room.fares[0].fareInformation.board.name
                          : room.fares[0].fareInformation.board.description
                          ? room.fares[0].fareInformation.board.description
                          : 'Sem refeição'
                      }
                    />
                  )}

                  <Icon
                    className="exon-icon-spacing"
                    icon={`exon-icon-cashback-unfilled_enabled${
                      !room.fares[0].fareInformation.refundable ? '-gray' : ''
                    }`}
                    description={`${
                      !room.fares[0].fareInformation.refundable ? 'Não ' : ''
                    }Reembolsável`}
                  />
                </div>
              </div>

              <div className="exon-hotel-room">
                {hotel.location && (
                  <div className="exon-hotel-location__small exon-hotel-location ">
                    <HotelAddress
                      distance={hotel.distance?.toFixed(2) ?? "--"}
                      address={hotel.location}
                      showMap={false}
                      addressLabel={product?.searchData?.label}
                      distanceIsFromCityCenter={!showRadiusInfo(product?.searchData?.searchViewParameters?.filter)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="exon-value-details">
            <div className="exon-box-value">
              <span className="exon-value-currency">
                {getCurrencySymbol(
                  product.selectedRooms[0].price.currencySymbol,
                )}
              </span>
              <span className="exon-value">
                {getMoneyFormatted(
                  product.selectedRooms.reduce(
                    (total, element) => (total += element.price.total),
                    0,
                  ),
                  product.selectedRooms[0].price.currencySymbol,
                )}
              </span>
            </div>
            <div
              className={
                'exon-descriptions-values' +
                (enableProductCheckbox ? ' hide' : '')
              }
            >
              <div className="exon-description">
                <p>Tarifas</p>
                <p>Taxas</p>
                <p>Qtd Diarias</p>
              </div>
              <div className="exon-values">
                <p>
                  {`${getCurrencySymbol(
                    product.selectedRooms[0].price.currencySymbol,
                  )} ${getMoneyFormatted(
                    product.selectedRooms.reduce(
                      (total, element) =>
                        (total += element.price.markupedDailyFare.value),
                      0,
                    ),
                    product.selectedRooms[0].price.currencySymbol,
                  )}`}
                </p>
                <p>
                  {`${getCurrencySymbol(
                    product.selectedRooms[0].price.currencySymbol,
                  )} ${getMoneyFormatted(
                    product.selectedRooms.reduce(
                      (total, element) => (total += element.price.dailyTaxes),
                      0,
                    ),
                    product.selectedRooms[0].price.currencySymbol,
                  )}`}
                </p>
                <p>{product.selectedRooms[0].price.totalDays}</p>
              </div>
            </div>
            {productActions && (
              <div
                className={
                  'exon-product-actions' +
                  (enableProductCheckbox ? ' hide' : '')
                }
              >
                {productActions}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Erro interno!
            <br />
            Verificque o product.
          </p>
        </div>
      );
    }
  },
};

export default ProductSummaryTemplate;
