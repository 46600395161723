import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';
import { listItemsResolver } from './listItemresolver';

export const paymentResolver = {
  getPaymentFormsListItems: (paymentForms) => {
    const listItems = [];
    paymentForms.forEach((paymentForm) => {
      let paymentType =
        paymentForm.PaymentType == undefined
          ? paymentForm.type
          : paymentForm.PaymentType;

      let paymentFormId = paymentType;
      if (paymentForm.CreditCard)
        paymentFormId += '_' + paymentForm.CreditCard.Id;

      let paymentTypeText =
        paymentResolver.resolvePaymentFormLabel(paymentForm);
      const listItem = listItemsResolver.getItem(
        paymentFormId,
        paymentTypeText,
      );
      listItem.paymentForm =
        paymentResolver.convertApiPaymentFormToComponentObj(paymentForm);
      //listItem.disabled = paymentForm.reference ? true : false;

      listItems.push(listItem);
    });

    return listItems;
  },
  resolvePaymentFormLabel: (paymentForm) => {
    let label = '';

    let creditCard = paymentForm.CreditCard || paymentForm.creditCard;
    let paymentType = paymentForm.PaymentType || paymentForm.type;

    if (paymentForm.PaymentType == 0 || paymentForm.type == 0) paymentType = 0;

    if (creditCard) {
      let number = creditCard.Number || creditCard.number;
      label += `Cartão final ${number.substring(number.length - 4)}`;
    }

    if (
      paymentType != enums.paymentType.creditCard &&
      paymentType != enums.paymentType.storedCreditCard
    )
      label += enums.paymentType[paymentType];

    if (paymentForm.SubsidiaryName) label += ` - ${paymentForm.SubsidiaryName}`;

    if (paymentForm.UserName) label += ` - ${paymentForm.UserName}`;

    if (paymentForm.CostCenterId) label += ' - Centro de Custo';

    if (paymentForm.CompanyName) label += ` - ${paymentForm.CompanyName}`;

    return label;
  },

  convertApiPaymentFormToComponentObj: (apiPaymentForm) => {
    let creditCard = apiPaymentForm.CreditCard || apiPaymentForm.creditCard;
    let paymentType =
      apiPaymentForm.type == undefined
        ? apiPaymentForm.PaymentType
        : apiPaymentForm.type;
    let storedCreditCard = apiPaymentForm.storedCreditCard;

    if (
      apiPaymentForm.PaymentType != null &&
      apiPaymentForm.PaymentType == enums.paymentType.creditCard &&
      apiPaymentForm.CreditCard &&
      apiPaymentForm.CreditCard.Id
    ) {
      paymentType = enums.paymentType.storedCreditCard;
      storedCreditCard = apiPaymentForm.CreditCard.Id;
    }

    return {
      creditCard: creditCard
        ? paymentResolver.convertApiCreditCardToComponentObj(creditCard)
        : null,
      paymentType,
      storedCreditCard,
      reference: apiPaymentForm.Reference,
      productType: apiPaymentForm.ProductType,
    };
  },
  convertApiCreditCardToComponentObj: (apiCreditCard) => {
    let currentYearPrefix = new Date().getFullYear().toString().substring(0, 2);
    let formatedExpirationDate = '';

    if (apiCreditCard.ExpirationDate) {
      let date = new Date(apiCreditCard.ExpirationDate);
      formatedExpirationDate = `${date.getMonth() + 1}/${date
        .getFullYear()
        .toString()
        .substring(2, 4)}`;
    }

    return {
      id: apiCreditCard.Id,
      companyId: apiCreditCard.CompanyId,
      number: apiCreditCard.Number || apiCreditCard.number,
      holderName: apiCreditCard.HolderName || apiCreditCard.printedName,
      brandCode: apiCreditCard.BrandCode || apiCreditCard.provider,
      expirationDate:
        formatedExpirationDate ||
        utils.converters.date.dateToString(
          new Date(
            currentYearPrefix + apiCreditCard.expireYear,
            apiCreditCard.expireMonth - 1,
            1,
          ),
        ),
      securityCode: apiCreditCard.SecurityCode || apiCreditCard.securityCode,
    };
  },

  convertPaymentFormComponentObjToApi: (paymentFormData) => {
    let paymentForm = {};

    paymentForm.type = paymentFormData.paymentType;
    paymentForm.reference = paymentFormData.reference;

    if (paymentFormData.creditCard) {
      paymentForm.instalments = paymentFormData.installments || 1;

      if (paymentFormData.creditCard.id)
        paymentForm.storedCreditCard = paymentFormData.creditCard.id;
      else if (paymentFormData.storedCreditCard)
        paymentForm.storedCreditCard = paymentFormData.storedCreditCard;
      else
        paymentForm.creditCard =
          paymentResolver.convertCreditCardComponentObjToApi(
            paymentFormData.creditCard,
          );
    }

    return paymentForm;
  },
  convertCreditCardComponentObjToApi: (creditCardData) => {
    let expireMonth= "";
    let expireYear = "";

    if(creditCardData.expirationDate){
      [expireMonth, expireYear] = creditCardData.expirationDate.split('/');
    } else {
      expireMonth = creditCardData.expireMonth;
      expireYear = creditCardData.expireYear;
    }

    return {
      number: creditCardData.number,
      provider: creditCardData.brandCode || creditCardData.provider,
      expireYear: expireYear,
      expireMonth: expireMonth,
      printedName: creditCardData.holder || creditCardData.holderName || creditCardData.printedName,
      securityCode: creditCardData.cvv || creditCardData.securityCode,
    };
  },
};
