import DateComponent from '../date/date.component.js';
//import { DatePicker } from "@flash-tecnologia/hros-web-ui-v2";
import TimeCourseComponent from '../time_course/time-course.component.js';

let DateAndPeriodTemplate = {
  main: ({
    date,
    period,
    startDate,
    placeholder,
    labelDate,
    labelPeriod,
    options,
  }) => (
    <div className="exon-date-and-period">
      <div className="exon-date-and-period__box">
        <label className="exon-label-title-field exon-date-and-period__box-label-date">
          {labelDate}
        </label>
        <DateComponent
          externalOptions={options.date}
          placeholder={placeholder}
          data={date}
          startDate={startDate}
        ></DateComponent>
        {/* <DatePicker placeholder={placeholder} value={date} fromDate={startDate} onDateChange={(e) => options.date.events.onSelected(new Date(e))} /> */}
      </div>
      <div className="exon-label-title-field exon-date-and-period__box">
        <label className="exon-date-and-period__box-label-period">
          {labelPeriod}
        </label>
        <TimeCourseComponent
          externalOptions={options.period}
          period={period}
        ></TimeCourseComponent>
      </div>
    </div>
  ),
};

export default DateAndPeriodTemplate;
