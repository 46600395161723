import { enums } from '@legacy-utils/enums';

var GridTemplate = {
  main: ({
    columns,
    rowsFiltered,
    identifierRefName,
    checkboxChange,
    checkAllLines,
    cleanFilter,
    filterValueChange,
    onRowClick,
    urlImage,

    hideCheckboxes,
    hideFilter,

    columnsGroup,
    rowsDiff,
  }) => {
    return (
      <div>
        <table className="gridcgm table table-striped table-hover">
          <thead>
            {columnsGroup && (
              <tr>
                {columnsGroup.map((group, i) => {
                  return (
                    <th
                      className={
                        'exon-grid-group' +
                        (group.refGroup != '' ? '-' + group.refGroup : '')
                      }
                      colSpan={group.colspan}
                      key={'header-' + i}
                    >
                      {group.label}
                    </th>
                  );
                })}
              </tr>
            )}

            <tr>
              {hideCheckboxes ? (
                <th> </th>
              ) : (
                <th>
                  <label className="exon-check pos-rel">
                    <input
                      className="ace"
                      type="checkbox"
                      onChange={(ev) => checkAllLines(ev)}
                    />
                    <span className="lbl"></span>
                  </label>
                </th>
              )}
              {columns.map((column, i) => {
                return column.hide ? (
                  <th hidden={column.hide} key={'header-' + i}></th>
                ) : (
                  <th className="exon-grid-column" key={'header-' + i}>
                    {column.label}
                  </th>
                );
              })}
            </tr>

            <tr>
              {!hideFilter && (
                <th>
                  <div className="exon-clear-filter action-buttons">
                    <a
                      className="link-filter"
                      title="Limpar filtros"
                      onClick={() => cleanFilter()}
                    >
                      <span className="fa-stack exon-clear-icon">
                        <i className="fa fa-filter fa-stack-1x bigger-100"></i>
                        <i className="fa-sm fa fa-times"></i>
                      </span>
                    </a>
                  </div>
                </th>
              )}
              {!hideFilter &&
                columns.map((column, i) => {
                  const placeholder = 'Procure por ' + column.label;
                  return column.hide ? (
                    <th hidden={column.hide} key={'header-filter-' + i}></th>
                  ) : (
                    <th key={'header-filter-' + i} hidden={hideFilter}>
                      {column.formatter == enums.formatters.boolean ? (
                        <GridTemplate.filterFormatters.boolean
                          column={column}
                          filterValueChange={filterValueChange}
                        />
                      ) : column.formatter == enums.formatters.noFilter ? (
                        <GridTemplate.filterFormatters.noFilter
                          column={column}
                          placeholder={placeholder}
                          filterValueChange={filterValueChange}
                        />
                      ) : column._filterItems ? (
                        <GridTemplate.filterFormatters.select
                          column={column}
                          placeholder={placeholder}
                          filterValueChange={filterValueChange}
                        />
                      ) : (
                        <GridTemplate.filterFormatters.default
                          key={`header-filter-field-${i}`}
                          column={column}
                          placeholder={placeholder}
                          filterValueChange={filterValueChange}
                        />
                      )}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {rowsFiltered.map((item, i) => {
              return (
                <tr key={item[identifierRefName]}>
                  {hideCheckboxes ? (
                    <td> </td>
                  ) : (
                    <td>
                      <label className="exon-check pos-rel">
                        <input
                          type="checkbox"
                          name="checkboxGrid"
                          checked={item.checked}
                          onChange={(ev) =>
                            checkboxChange(ev, item[identifierRefName])
                          }
                          value={item[identifierRefName]}
                          className="check ace"
                        />
                        <span className="lbl"></span>
                      </label>
                    </td>
                  )}
                  {columns.map((column, j) => {
                    if (column.hide) {
                      return <td hidden={column.hide} key={'row-' + j}></td>;
                    } else {
                      let cellData;
                      switch (column.formatter) {
                        case enums.formatters.boolean:
                          cellData = (
                            <GridTemplate.cellFormatters.boolean
                              column={column}
                              item={item}
                            />
                          );
                          break;
                        case enums.formatters.html:
                          cellData = (
                            <GridTemplate.cellFormatters.html
                              column={column}
                              item={item}
                            />
                          );
                          break;
                        case enums.formatters.list:
                          cellData = (
                            <GridTemplate.cellFormatters.list
                              column={column}
                              item={item}
                            />
                          );
                          break;
                        case enums.formatters.image:
                          cellData = (
                            <GridTemplate.cellFormatters.image
                              column={column}
                              item={item}
                              urlImage={urlImage}
                            />
                          );
                          break;
                        case enums.formatters.noFilter:
                          cellData = (
                            <GridTemplate.cellFormatters.noFilter
                              column={column}
                              item={item}
                            />
                          );
                          break;
                        default:
                          cellData = (
                            <GridTemplate.cellFormatters.default
                              column={column}
                              item={item}
                            />
                          );
                          break;
                      }

                      let rowDiff;
                      if (rowsDiff) {
                        rowDiff = rowsDiff.find(
                          (r) => r.identifier === item._identifier,
                        );
                      }

                      return (
                        <td
                          key={'row-' + j}
                          onClick={() => onRowClick(item[identifierRefName], j)}
                          className={
                            'exon-grid-row ' +
                            (rowDiff
                              ? rowDiff.diff[column.refName]
                                ? 'exon-history-row-highlighted'
                                : ''
                              : '')
                          }
                        >
                          {column.prefix ? column.prefix : ''} {cellData}{' '}
                          {column.sufix ? column.sufix : ''}{' '}
                          {column.tooltipRefName ? (
                            <span
                              className="fa fa-info-circle blue"
                              title={item.tooltipRefName}
                            ></span>
                          ) : (
                            ''
                          )}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  },

  filterFormatters: {
    boolean: ({ column, filterValueChange }) => (
      <select
        className="dm-grid-filter form-control"
        value={column.filterValue}
        onChange={(event) => filterValueChange(event, column)}
      >
        <option value="">Todos</option>
        <option value="1">Sim</option>
        <option value="0">Não</option>
      </select>
    ),

    select: ({ column, filterValueChange }) => {
      return (
        <select
          className="dm-grid-filter form-control"
          value={column.filterValue}
          onChange={(event) => filterValueChange(event, column)}
        >
          <option value="">Todos</option>
          {column._filterItems.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
      );
    },

    noFilter: ({ column, placeholder, filterValueChange }) => (
      <input
        className="dm-grid-filter form-control"
        value={column.filterValue}
        onChange={(event) => filterValueChange(event, column)}
        placeholder={placeholder}
        type={column.formatter == enums.formatters.noFilter ? 'hidden' : 'text'}
      />
    ),

    default: ({ column, placeholder, filterValueChange }) => (
      <input
        className="dm-grid-filter form-control"
        value={column.filterValue}
        onChange={(event) => filterValueChange(event, column)}
        placeholder={placeholder}
        type={column.formatter == enums.formatters.image ? 'hidden' : 'text'}
      />
    ),
  },
  cellFormatters: {
    boolean: ({ column, item }) => (
      <label
        className={
          'badge ' +
          (item[column.refName] == true ? 'badge-success' : 'badge-danger')
        }
      >
        {item[column.refName] == true ? 'Sim' : 'Não'}
      </label>
    ),
    html: ({ column, item }) => {
      const rawValue = item[column.refName];
      const cleanValue = new DOMParser().parseFromString(rawValue, 'text/html')
        .documentElement.textContent;
      return cleanValue;
    },
    list: ({ column, item }) => {
      let listIdentifierRefName = column.listIdentifierRefName;
      let listLabelRefName = column.listIdentifierRefName;

      if (!listIdentifierRefName) listIdentifierRefName = 'value';

      if (!listLabelRefName) listLabelRefName = 'label';

      const rawValue = item[column.refName];
      const listItem =
        (column.items &&
          column.items.find((i) => i[listIdentifierRefName] == rawValue)) ||
        {};

      return listItem[listLabelRefName] || rawValue;
    },
    image: ({ column, item, urlImage }) => {
      return (
        <img className="thumbnail-grid" src={urlImage + item[column.refName]} />
      );
    },
    noFilter: ({ column, item }) => {
      return item[column.refName];
    },
    default: ({ column, item }) => {
      return item[column.refName];
    },
  },
};
export default GridTemplate;
