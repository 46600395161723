'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import ButtonCollectionTemplate from './button-collection.template.js';

export default class ButtonCollectionComponent extends React.Component {
  externalOptions = {
    events: {
      onAdditioned: null,
      onDeleted: null,
      onSelected: null,
    },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      dataControlledByParent: props.value != undefined,

      items: [{ label: null }, { label: null }],

      labels: [null, null],
      indexSelected: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let indexSelected = state.dataControlledByParent
      ? props.value
      : state.indexSelected;
    return {
      labels: props.labels || state.labels,
      indexSelected: indexSelected,
    };
  }

  render() {
    return (
      <ButtonCollectionTemplate.main
        //items={this.externalOptions.items }
        items={this.state.items}
        labels={this.state.labels}
        textPrefix={this.props.defaultbuttontextprefix}
        indexSelected={this.state.indexSelected}
        onAddClicked={this.events.onAdditioned}
        onDeleteClicked={this.events.onDeleted}
        onButtonClicked={this.events.onSelected}
      />
    );
  }

  events = {
    onAdditioned: function () {
      let items = this.state.items;
      items.push({ label: null });

      this.setState({
        items: items,
        indexSelected: items.length - 1,
      });

      if (this.externalOptions.events.onAdditioned)
        this.externalOptions.events.onAdditioned();
    },
    onDeleted: function (index) {
      let items = this.state.items;
      items.splice(index, 1);

      let totalItems = items.length - 1;

      let selectedIndex = this.state.indexSelected;
      if (selectedIndex >= totalItems) selectedIndex = totalItems;

      this.setState({
        items: items,
        indexSelected: selectedIndex,
      });

      if (this.externalOptions.events.onDeleted)
        this.externalOptions.events.onDeleted(index);
    },
    onSelected: function (index) {
      this.setState({ indexSelected: index });

      if (this.externalOptions.events.onSelected)
        this.externalOptions.events.onSelected(index);
    },
  };

  setSelectedButtonLabel = function (label) {
    let items = this.state.items;
    items[this.state.indexSelected].label = label;
    this.setData(items);
  };

  setData = function (items) {
    this.setState({ items: items });
  };
}
