import { QueryClient } from '@tanstack/react-query';
import { createTRPCReact, httpLink } from '@trpc/react-query';
import type { AppRouter } from 'bff';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { HROS_ACCESS_TOKEN } from '@shared/constants/Storage.constants';
import { getAuthContextData } from '@api/connectOn/connectOn.api';

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();

export const trpcClient = trpc.createClient({
  links: [
    httpLink({
      url: `${process.env.BFF_URL}/trpc` || '',
      headers: () => {
        const flashOS = getFromLS(HROS_ACCESS_TOKEN);
        const accessToken = flashOS?.accessToken;
        const { selectedCompanyId } = getAuthContextData();

        return {
          Authorization: `Bearer ${accessToken}`,
          'company-id': selectedCompanyId,
        };
      },
    }),
  ],
  transformer: undefined,
});
