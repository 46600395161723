import BookingBox from '../../../booking-box/booking-box.component.js';
import Popup from '../../../../popup/popup.component.js';
import Carroussel from '../../../../carroussel/carroussel.component.js';
import BookingIntegrations from '../../../../integrations/booking-integrations.component.js';
import { enums } from '@legacy-utils/enums';
import { utils } from '@legacy-utils/utils';

var BookingTicketDetailsTemplate = {
  main: ({
    airTicket,
    allowedActions,
    showActions,
    confirmationProps,
    events,
    onShowEmailPopupClick,
    canEdit,
  }) => {
    try {
      let getFormattedDateObjFromDateObject =
        utils.formatters.date.getFormattedDateObjFromDateObject;
      let moneyFormatted = utils.formatters.money.getFormatted;
      let fare = airTicket.fare && airTicket.fare.travelerValue;
      let traveler = airTicket.traveler;
      let totalValue = airTicket.valueData
        ? airTicket.valueData.totalValue
        : null;
      let createdBy =
        airTicket.audit && airTicket.audit.createdBy
          ? airTicket.audit.createdBy.name
          : null;
      let payments = airTicket.payments;
      let paymentForms = airTicket.paymentForms;

      if (!airTicket.createdAt.fomattedData)
        formatter.formatAirTicketsData(airTicket);

      return (
        <div className="exon-booking-ticket-details">
          {confirmationProps.showCancelConfirmationPopup && (
            <BookingTicketDetailsTemplate.confirmationPopup.confirmCancelAirTicket
              airTicket={airTicket}
              events={events}
            />
          )}
          <div className="exon-booking-ticket-details-box">
            <div className="exon-booking-ticket-details-box-head">
              <div>
                {createdBy == 'SystemUser' && (
                  <span className="exon-icon exon-icon-lightning-filled_enabled-green exon-tooltip">
                    <span className="tooltip">Gerado automaticamente</span>
                  </span>
                )}
                {allowedActions.integrations.apps.isEnabled && (
                  <BookingIntegrations
                    serviceRequestId={airTicket.serviceRequestId}
                    serviceRequestItemId={airTicket.id}
                    allowedActions={allowedActions}
                    integrations={airTicket.integrations}
                    onDispatchClick={() =>
                      events.onSendAppsClicked(airTicket.id)
                    }
                  />
                )}
              </div>

              {showActions && (
                <div className="exon-booking-ticket-details-actions vcenter-item">
                  <span
                    className="exon-icon exon-icon-button exon-icon-envelope-filled_enabled"
                    onClick={() =>
                      onShowEmailPopupClick(
                        airTicket.id,
                        airTicket.workflow.status.id,
                        false,
                      )
                    }
                  ></span>
                  {canEdit && (
                    <span
                      className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
                      onClick={() =>
                        events.onOpenFormPopupClicked(enums.actionsForm.edit)
                      }
                    >
                      {' '}
                    </span>
                  )}
                  <span
                    type={'button'}
                    disabled={true}
                    className="exon-icon exon-icon-double-update-unfilled_enabled exon-row-aling-itens-center disabled"
                    title="Reemitir Bilhete"
                    onClick={events.onReplaceButtonClicked}
                  ></span>
                  {airTicket.workflow.status.id !=
                    enums.workflowStatus.canceled && (
                    <span
                      type={'button'}
                      className="exon-icon exon-icon-refuse-unfilled_enabled"
                      title="Cancelar Bilhete"
                      onClick={events.onCancelButtonClick}
                    ></span>
                  )}
                </div>
              )}
            </div>

            <BookingTicketDetailsTemplate.airTrips airTicket={airTicket} />

            <table className="exon-table-line">
              <thead>
                <tr>
                  <th>Operado por</th>
                  <th>N. do vôo</th>
                  <th>Assento</th>
                  <th>Classe</th>
                  <th>Cabine</th>
                  <th>Origem</th>
                  <th>Destino</th>
                  <th>Saída</th>
                  <th>Chegada</th>
                  <th>Escala</th>
                  <th>Status do vôo</th>
                </tr>
              </thead>
              <tbody>
                {airTicket.airTrips.map((airTrip, tripIndex) => {
                  return airTrip.flights.map((flight, flightIndex) => {
                    return (
                      <tr key={`${tripIndex}-${flightIndex}`}>
                        <td>
                          {flight.operatedBy.name +
                            ' - ' +
                            flight.operatedBy.code}
                        </td>
                        <td>{flight.flightNumber}</td>
                        <td>{flight.seat}</td>
                        <td>{flight.classOfService}</td>
                        <td>{enums.airClasses[flight.cabinType]}</td>
                        <td>{flight.departure.iata}</td>
                        <td>{flight.arrival.iata}</td>
                        <td>
                          {getFormattedDateObjFromDateObject(
                            flight.departureDate,
                          ).dateFormatted +
                            ' ' +
                            getFormattedDateObjFromDateObject(
                              flight.departureDate,
                            ).timeFormatted}
                        </td>
                        <td>
                          {getFormattedDateObjFromDateObject(flight.arrivalDate)
                            .dateFormatted +
                            ' ' +
                            getFormattedDateObjFromDateObject(
                              flight.arrivalDate,
                            ).timeFormatted}
                        </td>
                        <td>{flight.layover}</td>
                        <td>{flight.status}</td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
            <div className="exon-booking-ticket-details-fares">
              {fare && (
                <div className="exon-booking-ticket-details-box-fares">
                  {fare.exchangeRate && (
                    <div className="exon-ticket-details-box-fares">
                      <label>Valor Câmbio</label>
                      <p>
                        {fare.exchangeRate.from} 1.00
                        <span>
                          {fare.exchangeRate.to + ' ' + fare.exchangeRate.value}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="exon-ticket-details-box-fares">
                    <label>Tarifas</label>
                    <p>
                      {fare.originalFare.currencyCode +
                        ' ' +
                        moneyFormatted(
                          fare.originalFare.value,
                          fare.originalFare.currencyCode,
                        )}
                    </p>
                  </div>
                  <div className="exon-ticket-details-box-fares">
                    <label>Tarifa Equivalente</label>
                    <p>
                      {fare.fare.currencyCode +
                        ' ' +
                        moneyFormatted(fare.fare.value, fare.fare.currencyCode)}
                    </p>
                  </div>
                  <div className="exon-ticket-details-box-fares">
                    <label>Taxas</label>
                    <p>
                      {fare.taxes.currencyCode +
                        ' ' +
                        moneyFormatted(
                          fare.taxes.value,
                          fare.taxes.currencyCode,
                        )}
                    </p>
                  </div>
                  <div className="exon-ticket-details-box-fares">
                    <label>Taxa DU</label>
                    <p>
                      {fare.du.currencyCode +
                        ' ' +
                        moneyFormatted(fare.du.value, fare.du.currencyCode)}
                    </p>
                  </div>
                  {payments && payments.length > 0 && (
                    <div className="exon-ticket-details-box-payment">
                      <label>Pagamento</label>
                      <div className="exon-ticket-payments">
                        {payments.map((payment, index) => {
                          let paymentForm = payment.paymentData
                            ? payment.paymentData.paymentForm
                            : null;
                          if (!paymentForm) return;

                          let creditCard = paymentForm.creditCard;
                          let type = paymentForm.type;
                          return (
                            <div key={index}>
                              <p>
                                {creditCard
                                  ? `Cartão final: ${creditCard.number.substring(
                                      creditCard.number.length - 4,
                                    )} `
                                  : ''}
                              </p>
                              <p>
                                {creditCard
                                  ? `Código de autorização: ${
                                      payment.authorizationCode || ''
                                    }`
                                  : !isNaN(type)
                                  ? enums.paymentType[type]
                                  : ''}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="exon-booking-ticket-details-box">
            <div className="exon-booking-ticket-details-identifier">
              <label>Número do bilhete</label>
              <h6>{airTicket.number}</h6>
              <div className="exon-row-itens exon-row-aling-itens-center">
                <div>
                  <label>Emissão</label>
                  <p>
                    {airTicket.audit && airTicket.audit.createdAt
                      ? getFormattedDateObjFromDateObject(
                          airTicket.audit.createdAt,
                        ).dateFormatted
                      : ''}
                  </p>
                </div>
                <div>
                  <label>Localizador</label>
                  <p>
                    <strong>{airTicket.bookingLocator}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="exon-booking-ticket-details-traveler">
              <div>
                <label>Name</label>
                <p>
                  {traveler.honorificPrefix}
                  <span>{traveler.lastName},</span>
                  {traveler.firstName}
                </p>
              </div>
              <div>
                <label>Data de nascimento</label>
                <p>
                  {
                    getFormattedDateObjFromDateObject(traveler.birthDate)
                      .dateFormatted
                  }
                </p>
              </div>
            </div>
            <div className="exon-booking-ticket-details-fares">
              {totalValue && (
                <p>
                  {enums.currencySymbol[totalValue.currencyCode] +
                    ' ' +
                    moneyFormatted(totalValue.value)}
                </p>
              )}
            </div>
          </div>
        </div>
      );
    } catch (e) {
      console.log('Erro na renderização dos detalhes do bilhete', e);

      return <div className="exon-booking-ticket-details">Algo deu errado</div>;
    }
  },

  confirmationPopup: {
    confirmCancelAirTicket: ({ airTicket, events }) => {
      return (
        <Popup
          okButtonLabel={'Confirmar'}
          cancelButtonLabel={'Cancelar'}
          title={null}
          exonStyle={true}
          cancelButtonClick={events.confirmation.cancelAirTicketCancellation}
          okButtonClick={events.confirmation.confirmAirTicketCancellation}
        >
          <span className="exon-icon exon-icon-popup-info-unfilled_enabled"></span>
          <p>
            Deseja realmente cancelar o bilhete{' '}
            <strong>{airTicket.number}</strong> ?
          </p>
        </Popup>
      );
    },
  },

  airTrips: ({ airTicket }) => {
    if (airTicket.roundTrip) {
      let lastAirTrip = airTicket.airTrips[airTicket.airTrips.length - 1];
      if (lastAirTrip) {
        airTicket.arrival = lastAirTrip.departure;
        airTicket.endAt = lastAirTrip.departureDate;
      }
    }
    return airTicket.roundTrip ? (
      <BookingTicketDetailsTemplate.airTripInfo data={airTicket} />
    ) : (
      <div className="exon-box-carroussel">
        <Carroussel
          autoPlay={false}
          showDots={airTicket.airTrips.length > 1}
          items={airTicket.airTrips.map((airTrip, index) => {
            return (
              <BookingTicketDetailsTemplate.airTripInfo
                key={index}
                data={airTrip}
              />
            );
          })}
        />
      </div>
    );
  },
  airTripInfo: ({ data }) => {
    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;

    return (
      <div className="exon-data-trip">
        {data.airLine && (
          <span
            style={{ backgroundImage: `url(${data.airLine.imageUrl})` }}
            className="exon-logo-airline"
          >
            {!data.airLine.imageUrl
              ? data.airLine.name || data.airLine.code
              : ''}
          </span>
        )}
        <div className="exon-box-data-trip-items">
          <div className="exon-box-data-trip">
            <p>
              {
                getFormattedDateObjFromDateObject(
                  data.startAt || data.departureDate,
                ).dayOfWeek
              }
              ,
              <span>
                {
                  getFormattedDateObjFromDateObject(
                    data.startAt || data.departureDate,
                  ).dateShortFormatted
                }
              </span>
            </p>
            <div className="exon-airport-data">
              <span>{data.departure ? data.departure.iata : '--'}</span>
              <span>
                {
                  getFormattedDateObjFromDateObject(
                    data.startAt || data.departureDate,
                  ).timeFormatted
                }
              </span>
            </div>
            <p>{data.departure ? data.departure.name : '--'}</p>
          </div>
          <div className="air-trips-icon">
            <span
              className={
                'exon-icon-stops-card' + (data.stops == 0 ? ' hide' : '')
              }
            >
              <span className="exon-icon-stops-card-item"></span>
              <span className="exon-icon-stops-card-amount">{data.stops}</span>
            </span>
            <span
              className={
                (data.roundTrip
                  ? 'exon-icon exon-icon-round-trip-unfilled_enabled'
                  : 'exon-icon exon-icon-right-arrow-unfilled_enabled') +
                (data.stops !== 0 ? ' hide' : '')
              }
            ></span>
          </div>
          <div className="exon-box-data-trip">
            <p>
              {
                getFormattedDateObjFromDateObject(
                  data.endAt || data.arrivalDate,
                ).dayOfWeek
              }
              ,
              <span>
                {
                  getFormattedDateObjFromDateObject(
                    data.endAt || data.arrivalDate,
                  ).dateShortFormatted
                }
              </span>
            </p>
            <div className="exon-airport-data">
              <span>{data.arrival ? data.arrival.iata : '--'}</span>
              <span>
                {
                  getFormattedDateObjFromDateObject(
                    data.endAt || data.arrivalDate,
                  ).timeFormatted
                }
              </span>
            </div>
            <p>{data.arrival ? data.arrival.name : '--'}</p>
          </div>
        </div>
      </div>
    );
  },
};

//TODO: Remover e incluir a formatação dentro do template
var formatter = {
  formatAirTicketsData(airTicket) {
    const _this = formatter;

    airTicket.createdAt.fomattedData = _this.getGenerateObjDate(
      _this.getjsonDate(airTicket.createdAt),
    );
    airTicket.issuedAt.fomattedData = _this.getGenerateObjDate(
      _this.getjsonDate(airTicket.issuedAt),
    );

    airTicket.airTrips.forEach((trip) => {
      trip.departureDate.formattedData = _this.getGenerateObjDate(
        _this.getjsonDate(trip.departureDate),
      );
      trip.arrivalDate.formattedData = _this.getGenerateObjDate(
        _this.getjsonDate(trip.arrivalDate),
      );

      trip.flights.forEach((flight) => {
        flight.departureDate.formattedData = _this.getGenerateObjDate(
          _this.getjsonDate(flight.departureDate),
        );
        flight.arrivalDate.formattedData = _this.getGenerateObjDate(
          _this.getjsonDate(flight.arrivalDate),
        );
      });
    });

    let fare = airTicket.fare && airTicket.fare.travelerValue;
    let formatFare = (fareData) => {
      fareData
        ? (fareData.formattedData = {
            currencySymbol: _this.getCurrencySymbol(fareData.currencyCode),
            value: _this.generateValueFormatted(
              fareData.value,
              fareData.currencyCode,
              false,
            ),
          })
        : null;
    };

    if (fare) {
      if (fare.exchangeRate)
        fare.exchangeRate.date.formattedData = _this.getGenerateObjDate(
          _this.getjsonDate(fare.exchangeRate.date),
        );

      formatFare(fare.originalFare);
      formatFare(fare.fare);
      formatFare(fare.taxes);
      formatFare(fare.du);
      formatFare(fare.total);
    }

    airTicket.traveler.birthDate.formattedData = _this.getGenerateObjDate(
      _this.getjsonDate(airTicket.traveler.birthDate),
    );
  },

  getGenerateObjDate(dateItem) {
    if (
      dateItem != '--' ||
      dateItem != '-' ||
      dateItem != null ||
      dateItem != undefined
    ) {
      let date = new Date(dateItem);

      let objDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        dateFormatted: date.toLocaleDateString(),
        dayOfWeek: enums.daysOfWeek[date.getDay()],
        dateShortFormatted:
          date.getDate() + ' de ' + enums.months[date.getMonth() + 1],
        timeFormatted: date.toTimeString().substring(5, 0),
      };

      return objDate;
    } else {
      return '--';
    }
  },
  getjsonDate(objDate) {
    return new Date(
      objDate.year,
      objDate.month - 1,
      objDate.day,
      objDate.hour ? objDate.hour : 0,
      objDate.minute ? objDate.minute : 0,
      objDate.second ? objDate.second : 0,
    ).toJSON();
  },
  getCurrencySymbol(currencyCode) {
    return currencyCode == enums.currencyCode.real
      ? enums.currencySymbol.BRL
      : currencyCode == enums.currencyCode.dolar
      ? enums.currencySymbol.USD
      : enums.currencySymbol.EUR;
  },
  generateValueFormatted(value, nationality, round) {
    switch (nationality) {
      case enums.currencyCode.dolar:
        if (round)
          return new Intl.NumberFormat('en-IN')
            .format(Math.round(value))
            .toString();
        else
          return new Intl.NumberFormat('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
            .format(value)
            .toString();
        break;

      case enums.currencyCode.euro:
        if (round)
          return new Intl.NumberFormat('de-DE')
            .format(Math.round(value))
            .toString();
        else
          return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
            .format(value)
            .toString();
        break;

      default:
        if (round)
          return new Intl.NumberFormat('pt-BR')
            .format(Math.round(value))
            .toString();
        else
          return new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
            .format(value)
            .toString();
    }
  },
  generateDurationFormatted(timeStamp) {
    let hours = Math.floor(timeStamp / 60)
      .toString()
      .padStart(2, '0');
    let minutes = (timeStamp - hours * 60).toString().padStart(2, '0');

    if (hours >= 12) {
      if (hours == 12) {
        hours = hours;
        minutes = minutes;
      } else if (hours == 24) {
        minutes = '59';
      } else {
        minutes = minutes;
      }
    } else {
      hours = hours;
      minutes = minutes;
    }

    return hours + 'h ' + minutes + 'min';
  },
};

export default BookingTicketDetailsTemplate;
