import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import { callApi } from './connectOn.api';

import { search as mockedAirSearch } from './mocks/air.mocks';
import {
  details as mockedHotelDetails,
  search as mockedHotelSearch,
} from './mocks/hotel.mocks';
import { preBooking as mockedPreBooking } from './mocks/sale.mocks';

import { downloadFile } from '@api/api.utils';
import {
  TRAVEL_FLASH_OS_COMPANY_ID,
  TRAVEL_FLASH_OS_EMPLOYEE_ID,
  TRAVEL_USER_PROFILE_DATA,
} from '@shared/constants/Storage.constants';

//#region TRAVEL - AIR

export const findAirLocations = async (keywords: string) => {
  try {
    const { data } = await callApi({
      method: 'get',
      url: `travel/air/locations/${keywords}`,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const doAirSearch = async (request: any, contextId: string) => {
  if (request.mock)
    return new Promise((resolve) => resolve(mockedAirSearch.response));

  try {
    // const mockedRequest = request;
    const { data } = await callApi(
      {
        method: 'post',
        url: `travel/air/search`,
        data: request,
      },
      contextId,
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const issueAirTicket = async (request: any, contextId: string) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `/travel/air/ticket/issue`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const getAirlines = async () => {
  const { data } = await callApi({
    method: 'get',
    url: `travel/air/airlines`,
  });

  return data;
};

//#endregion

//#region TRAVEL - BOOKING

export const retrieveExternalBooking = async (request: any) => {
  const { source, locator, contextId } = request;
  const { data } = await callApi(
    {
      method: 'get',
      url: `/travel/booking/fetch/${source}/${locator}`,
      data: request,
    },
    contextId,
  );

  return data;
};

//#endregion

//#region TRAVEL - HOTEL

export const findHotelLocations = async (keywords: string) => {
  try {
    const { data } = await callApi({
      method: 'get',
      url: `travel/hotel/locations/${keywords}`,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const doHotelSeach = async (request: any) => {
  if (request.mock)
    return new Promise((resolve) => resolve(mockedHotelSearch.response));

  try {
    // const mockedRequest = mockedHotelSearch.request;
    const { data } = await callApi(
      {
        method: 'post',
        url: `travel/hotel/search`,
        data: request,
      },
      request.contextId,
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getHotelDetails = async (request: any) => {
  const { searchParameters, contextId, mock } = request;

  if (mock)
    return new Promise((resolve) => resolve(mockedHotelDetails.response));

  try {
    const { data } = await callApi(
      {
        method: 'post',
        url: `travel/hotel/details`,
        data: searchParameters,
      },
      contextId,
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getHotelRoomDetails = async (request: any) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `travel/hotel/room/details`,
      data: request,
    },
    request.contextId,
  );

  return data;
};

export const findHotelPointsOfInterest = async (keywords: string) => {
  const { data } = await callApi({
    method: 'get',
    url: `travel/hotel/pointofinterest/${keywords}`,
  });

  return data;
};

//#endregion

//#region TRAVEL - SALE

export const preBooking = async (request: any) => {
  if (request.mock)
    return new Promise((resolve) => resolve(mockedPreBooking.response));

  const { data } = await callApi(
    {
      method: 'post',
      url: `travel/sale/prebooking`,
      data: request,
    },
    request.contextId,
  );

  return data;
};

export const processBooking = async (request: any) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `travel/sale/processbooking`,
      data: request,
    },
    request.contextId,
  );

  return data;
};

//#endregion

//#region TRAVEL - SERVICE REQUEST

export const getServiceRequest = async (request: any) => {
  const { id, contextId } = request;
  const { data } = await callApi(
    {
      method: 'get',
      url: `/servicerequest/${id}`,
    },
    contextId,
  );

  return data;
};

export const fetchServiceRequest = async (request: any) => {
  const { id, contextId } = request;
  const { data } = await callApi(
    {
      method: 'get',
      url: `/servicerequest/${id}/fetch`,
    },
    contextId,
  );

  return data;
};

export const listServiceRequests = async (request: any) => {
  const { data } = await callApi({
    method: 'post',
    url: `/servicerequest/items`,
    data: request,
  });

  return data;
};

export const countServiceRequests = async (request: any) => {
  const { data } = await callApi({
    method: 'post',
    url: `/servicerequest/count`,
    data: request,
  });

  return data;
};

export const updateCostSplitServiceRequest = async (request: any) => {
  const { rootContextId, contextId } = request;
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/cost-split`,
      data: request,
    },
    (rootContextId || contextId) ?? null,
  );

  return data;
};

export const cancelServiceRequest = async (request: any) => {
  const { id, rootContextId, contextId } = request;
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/${id}/cancel`,
      data: request,
    },
    (rootContextId || contextId) ?? null,
  );

  return data;
};

export const submitServiceRequest = async (request: any) => {
  const { id, contextId } = request;
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/${id}/submit`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const saveServiceRequest = async (request: any) => {
  const { serviceRequest, contextId } = request;
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/save`,
      data: { serviceRequest },
    },
    contextId,
  );

  return data;
};

export const approvalServiceRequest = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/approval`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const getMonitoring = async (request: any) => {
  const { data } = await callApi({
    method: 'post',
    url: `/servicerequest/monitoring`,
    data: request,
  });

  return data;
};

export const sendServiceRequestNotification = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/notify`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const previewServiceRequestNotification = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/notify/preview`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const getServiceRequestHistory = async (request: any) => {
  const { serviceRequestId, page, pageSize, contextId } = request;
  const { data } = await callApi(
    {
      method: 'get',
      url: `/servicerequest/${serviceRequestId}/history?page=${page}&pageSize=${pageSize}`,
    },
    contextId,
  );

  return data;
};

//#endregion

//#region TRAVEL - SERVICE REQUEST ITEM

export const getServiceRequestItem = async (
  request: any,
  selectedContextId?: string,
) => {
  const { serviceRequestItemId, contextId } = request;
  const { data } = await callApi(
    {
      method: 'get',
      url: `/servicerequest/item/${serviceRequestItemId}`,
    },
    selectedContextId ?? contextId,
  );

  return data;
};

export const fetchServiceRequestItem = async (request: any) => {
  const { serviceRequestItemId, contextId } = request;
  const { data } = await callApi(
    {
      method: 'get',
      url: `/servicerequest/item/${serviceRequestItemId}/fetch`,
    },
    contextId,
  );

  return data;
};

export const approvalServiceRequestItem = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/item/approval`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const integrationAck = async (request: any, contextId: string) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/item/integration/ack`,
      data: request,
    },
    contextId,
  );
  return data;
};

export const cancelServiceRequestItem = async (request: any) => {
  const { productId, contextId } = request;
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/item/${productId}/cancel`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const saveServiceRequestItem = async (request: any) => {
  const { serviceRequestItem, contextId, policyViolationAlertsJustified } =
    request;
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/item/save`,
      data: { serviceRequestItem, policyViolationAlertsJustified },
    },
    contextId,
  );

  return data;
};

export const previewServiceRequestItemNotification = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/item/notify/preview`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const sendServiceRequestItemNotification = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/item/notify`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const sendToAppsIntegration = async (request: any) => {
  request.backOffice = 'Apps';
  const { data } = await callApi(
    {
      method: 'post',
      url: `/servicerequest/item/integration/dispatch`,
      data: request,
    },
    request.contextId,
  );

  return data;
};

//#endregion

//#region LICENCE

export const getLicenceFeaturesByContext = async (contextId: string) => {
  //TODO CAMILLA LOCAL STORAGE

  const { data } = await callApi(
    {
      method: 'get',
      url: `licence/companies/features`,
    },
    contextId,
  );

  return data;
};

//#endregion

//#region LOCATIONS

export const findLocations = async (request: any) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `locations/search`,
      data: request,
    },
    request.contextId,
  );

  return data;
};

export const getLocation = async (id: any) => {
  const { data } = await callApi({
    method: 'get',
    url: `locations/search/${id}`,
  });

  return data;
};

//#endregion

//#region PROFILE

export const getUserProfileData = async () => {
  const employeeId = getFromLS(TRAVEL_FLASH_OS_EMPLOYEE_ID);
  const selectedCompanyId = getFromLS(TRAVEL_FLASH_OS_COMPANY_ID);
  const travelUserProfileDataLSKey = `${TRAVEL_USER_PROFILE_DATA}-${employeeId}-${selectedCompanyId}`;

  let data = getFromLS(travelUserProfileDataLSKey);

  if (!data || !data.userProfile?.company?.travelModuleActive) {
    const response = await callApi({
      method: 'get',
      url: `profile/user`,
    });

    data = response.data;
    setInLS({
      key: travelUserProfileDataLSKey,
      value: data,
    });
  }

  return data;
};

export const getCompanySettings = async (contextId: string) => {
  const response = await callApi(
    {
      method: 'get',
      url: `profile/company/settings`,
    },
    contextId,
  );

  return response.data;
};

export const findTravelers = async (request: any) => {
  const { keywords, travelerType, contextId, loadUsers } = request;

  let queryStringParameters = `type=${travelerType}`;
  if (contextId) queryStringParameters += `&contextId=${contextId}`;

  if (loadUsers === false) queryStringParameters += `&loadUsers=${loadUsers}`;

  const { data } = await callApi(
    {
      method: 'get',
      url: `profile/travelers/find/${keywords.trim()}?${queryStringParameters}`,
      data: request,
    },
    request.contextId,
  );

  return data;
};

export const insertTraveler = async (request: any, contextId: string) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `profile/travelers`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const updateTraveler = async (request: any, contextId: string) => {
  const { data } = await callApi(
    {
      method: 'put',
      url: `profile/travelers`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const findCostCenter = async (keywords: string, contextId: string) => {
  let parameters = '';
  if (keywords && keywords.trim().length) parameters = `/${keywords}`;

  const { data } = await callApi(
    {
      method: 'get',
      url: `profile/costcenters/find${parameters}`,
    },
    contextId,
  );

  return data;
};

export const getTravelReasons = async (contextId: string) => {
  const { data } = await callApi(
    {
      method: 'get',
      url: `/profile/travel/reasons`,
    },
    contextId,
  );

  return data;
};

export const getCustomFields = async (contextId: string) => {
  const { data } = await callApi(
    {
      method: 'get',
      url: `profile/customfields`,
    },
    contextId,
  );

  return data;
};

export const getPaymentForms = async (request: any) => {
  const { products, supplier, costCenter, contextId, travelers, requester } =
    request;

  let params = `?product=${products}&supplier=${supplier}`;
  if (travelers && travelers.length > 0)
    params += travelers.map((_) => `&travelers=${_}`).join('');

  if (costCenter) params += `&costCenter=${costCenter}`;
  if (requester) params += `&requesterId=${requester}`;

  const { data } = await callApi(
    {
      method: 'get',
      url: `/profile/paymentForms${params}`,
    },
    contextId,
  );

  return data;
};

export const getPurchaseConfiguration = async (contextId: string) => {
  const { data } = await callApi(
    {
      method: 'get',
      url: `/profile/purchaseConfigurations`,
    },
    contextId,
  );

  return data;
};

export const getTravelJustifications = async (request: any) => {
  const { productType, contextId } = request;
  const { data } = await callApi(
    {
      method: 'get',
      url: `/profile/travel/justifications?productType=${productType}`,
    },
    contextId,
  );

  return data;
};

export const findRequesters = async (keywords: string, contextId: string) => {
  const { data } = await callApi(
    {
      method: 'get',
      url: `profile/requesters/find/${keywords.trim()}`,
    },
    contextId,
  );

  return data;
};

//#endregion

//#region TEMPLATE

export const previewTemplate = async (request: any, contextId: string) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `template/process`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const sendTemplate = async (request: any, contextId: string) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `template/notify`,
      data: request,
    },
    contextId,
  );

  return data;
};

//#endregion

//#region Reports
export const getServiceRequestReportData = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `reports/serviceRequest`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const exportServiceRequestReport = async (
  request: any,
  format: 'json' | 'xlsx',
  contextId: string,
) => {
  const response = await callApi(
    {
      method: 'post',
      url: `reports/serviceRequest/export/${format}`,
      data: request,
      axiosOptions: {
        responseType: 'blob',
      },
    },
    contextId,
  );

  downloadFile(
    response.data,
    `travel_requests_${new Date().toISOString()}.${format}`,
  );

  return response.data;
};

export const getBookingsReportData = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `reports/bookings`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const exportBookingsReport = async (
  request: any,
  format: 'json' | 'xlsx',
  contextId: string,
) => {
  const response = await callApi(
    {
      method: 'post',
      url: `reports/bookings/export/${format}`,
      data: request,
      axiosOptions: {
        responseType: 'blob',
      },
    },
    contextId,
  );

  downloadFile(
    response.data,
    `travel_bookings_${new Date().toISOString()}.${format}`,
  );

  return response.data;
};

export const getAirTicketsReportData = async (
  request: any,
  contextId: string,
) => {
  const { data } = await callApi(
    {
      method: 'post',
      url: `reports/air/tickets`,
      data: request,
    },
    contextId,
  );

  return data;
};

export const exportAirTicketsReport = async (
  request: any,
  format: 'json' | 'xlsx',
  contextId: string,
) => {
  const response = await callApi(
    {
      method: 'post',
      url: `reports/air/tickets/export/${format}`,
      data: request,
      axiosOptions: {
        responseType: 'blob',
      },
    },
    contextId,
  );

  downloadFile(
    response.data,
    `travel_bookings_${new Date().toISOString()}.${format}`,
  );

  return response.data;
};

//#endregion
