import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import { statusFlowResolver } from '../../utils/resolvers/statusFlowResolver.js';
import { enums } from '../../utils/enums.js';
import ShoppingCartTemplate from './shopping-cart.template.js';
import ErrorBoundary from '../common/error-boundary/error-boundary.component.js';
import useShoppingCartStore from '../../../store/ShoppingCart.store';

export default class ShoppingCartComponent extends React.Component {
  components = {
    lastGroup: null,
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    };

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.components.select, this);

    this.state = {
      displayMoveProducts: false,
      displayBudgetPreview: false,
      notifier: {
        data: {},
        show: false,
        fieldsData: {
          to: { email: '', name: '' },
          cc: { email: '', name: '' },
          cco: { email: '', name: '' },
        },
      },

      scrollToLastGroup: false,
      selectGroupsListValue: 0,
      groupIndexSelected: 0,
      warningSelectGroup: false,
      showMoveAllProducts: false,
      linkItemsFromGroups: false,
      enableProductCheckbox: false,
    };

    this.setCounterValues = this.setCounterValues.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const shoppingCart = props.shoppingCart ? props.shoppingCart : [];
    let shoppingCartAllProducts = {
      travels: [],
      hotels: [],
    };

    shoppingCart.forEach((group, groupIndex) => {
      group.products.travels.forEach((product, productIndex) => {
        product.groupIndex = groupIndex;
        product.productIndex = productIndex;
        shoppingCartAllProducts.travels.push(product);
      });

      group.products.hotels.forEach((product, productIndex) => {
        product.groupIndex = groupIndex;
        product.productIndex = productIndex;
        shoppingCartAllProducts.hotels.push(product);
      });
    });

    return {
      shoppingCart: shoppingCart,
      shoppingCartAllProducts: shoppingCartAllProducts,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shoppingCart !== this.props.shoppingCart) {
      this.setCounterValues();
    }
  }

  componentDidMount() {
    this.setCounterValues();
  }

  setCounterValues() {
    const shoppingCart = this.state.shoppingCart || [];
    let counterAir = 0;
    let counterHotel = 0;

    shoppingCart.forEach((group) => {
      counterAir += group.products.travels.length;
      counterHotel += group.products.hotels.length;
    });

    const { setCounterAir, setCounterHotel } = useShoppingCartStore.getState();
    setCounterAir(counterAir);
    setCounterHotel(counterHotel);
  }

  render() {
    return (
      <ErrorBoundary>
        <ShoppingCartTemplate.main
          shoppingCart={this.state.shoppingCart}
          groupIndexSelected={this.state.groupIndexSelected}
          selectGroupsListValue={this.state.selectGroupsListValue}
          warningSelectGroup={this.state.warningSelectGroup}
          showMoveAllProducts={this.state.showMoveAllProducts}
          shoppingCartAllProducts={this.state.shoppingCartAllProducts}
          linkItemsFromGroups={this.state.linkItemsFromGroups}
          enableProductCheckbox={this.state.enableProductCheckbox}
          displayBudgetPreview={this.state.displayBudgetPreview}
          budgetDataSource={this.props.budgetDataSource}
          budgetProductsGroupData={this.state.budgetProductsGroupData}
          displayMoveProducts={this.state.displayMoveProducts}
          components={this.components}
          selectProps={this.components.select.getProps(this.state.shoppingCart)}
          onHideShoppingCartClick={this.events.onHideShoppingCartClicked}
          onIncludeGroupClick={this.events.onIncludeGroupClicked}
          onIncludeProductClick={this.events.onIncludeProductClicked}
          onDeleteProductClick={this.events.onDeleteProductClicked}
          onDeleteGroupClick={this.events.onDeleteGroupClicked}
          onUpdateProductsClick={this.events.onUpdateProductsClicked}
          onSendBudgetClick={this.events.onSendBudgetClicked}
          onConfirmSendBudgetClick={this.events.onConfirmSendBudgetClicked}
          onCloseBudgetPreviewClick={this.events.onCloseBudgetPreviewClicked}
          onSelectedGroupClick={this.events.onSelectedGroupClicked}
          onSelectedProductClick={this.events.onSelectedProductClicked}
          onSelectedHotelProductClick={
            this.events.onSelectedHotelProductClicked
          }
          onMoveProductClick={this.events.onMoveProductClicked}
          onMoveAllProductsClick={this.events.onMoveAllProductsClicked}
          onSelectedMoveTripProductChange={
            this.events.onSelectedMoveTripProductChanged
          }
          onHideMoveProductsClick={this.events.onHideMoveProductsClicked}
          onRenameGroupChange={this.events.onRenameGroupChanged}
          onMoveProductConfimrClick={this.events.onMoveProductConfimrClicked}
          previewBudgetEvents={this.events.previewBudgetEvents}
          notifierProps={this.notifier.getProps()}
          notifierEvents={this.events.notifier}
        />
      </ErrorBoundary>
    );
  }

  events = {
    onHideShoppingCartClicked: () => {
      const { setDisplayShoppingCart } = useShoppingCartStore.getState();
      setDisplayShoppingCart(false);
    },

    onIncludeGroupClicked: () => {
      this.setState({ scrollToLastGroup: true });

      if (this.props.onIncludeGroupShoppingCartClick)
        this.props.onIncludeGroupShoppingCartClick();
    },

    onIncludeProductClicked: () => {
      if (this.props.onIncludeProductShoppingCartClick)
        this.props.onIncludeProductShoppingCartClick();
    },

    onDeleteProductClicked: (groupIndex, productIndex, productType) => {
      if (this.props.onDeleteProductShoppingCartClick) {
        this.props.onDeleteProductShoppingCartClick(
          groupIndex,
          productIndex,
          productType,
        );
      }
      let shoppingCart = [...this.props.shoppingCart];
      if (productType === 'air') {
        shoppingCart[groupIndex].products.travels.splice(productIndex, 1);
      } else if (productType === 'hotel') {
        shoppingCart[groupIndex].products.hotels.splice(productIndex, 1);
      }
      this.setState({ shoppingCart }, () => {
        this.setCounterValues();
      });
    },

    onDeleteGroupClicked: (groupIndex) => {
      if (this.props.onDeleteGroupShoppingCartClick)
        this.props.onDeleteGroupShoppingCartClick(groupIndex);
    },

    onUpdateProductsClicked: () => {
      if (this.props.onUpdateProductsShoppingCartClick)
        this.props.onUpdateProductsShoppingCartClick();
    },

    onSendBudgetClicked: (groupIndex) => {
      let groupData = this.state.shoppingCart[groupIndex];
      let notifier = this.state.notifier;
      notifier.data = groupData;
      notifier.show = true;

      this.setState({
        notifier,
      });

      if (this.props.onSendBudgetShoppingCartClick)
        this.props.onSendBudgetShoppingCartClick(groupIndex);
    },

    previewBudgetEvents: {
      onNameFieldChanged: (value) => {
        let budgetProductsGroupData = this.state.budgetProductsGroupData;
        budgetProductsGroupData.Addressee.Name = value;

        this.setState({ budgetProductsGroupData });
      },
      onToFieldChanged: (value) => {
        let budgetProductsGroupData = this.state.budgetProductsGroupData;
        budgetProductsGroupData.Addressee.To = value;

        this.setState({ budgetProductsGroupData });
      },
      onCcFieldChanged: (value) => {
        let budgetProductsGroupData = this.state.budgetProductsGroupData;
        budgetProductsGroupData.Addressee.Cc = value;

        this.setState({ budgetProductsGroupData });
      },
      onCcoFieldChanged: (value) => {
        let budgetProductsGroupData = this.state.budgetProductsGroupData;
        budgetProductsGroupData.Addressee.Cco = value;

        this.setState({ budgetProductsGroupData });
      },
      onSubjectFieldChanged: (value) => {
        let budgetProductsGroupData = this.state.budgetProductsGroupData;
        budgetProductsGroupData.Addressee.Subject = value;

        this.setState({ budgetProductsGroupData });
      },
      onMessageFieldChanged: (value) => {
        let budgetProductsGroupData = this.state.budgetProductsGroupData;
        budgetProductsGroupData.Addressee.Message = value;

        this.setState({ budgetProductsGroupData });
      },
      onConfirmSendBudgetClicked: () => {
        let budgetProductsGroupData = this.state.budgetProductsGroupData;
        budgetProductsGroupData.data = JSON.stringify(
          budgetProductsGroupData.data,
        );
        let dataSource = this.props.budgetDataSource;
        dataSource.send(budgetProductsGroupData);
        return;
      },
    },

    onCloseBudgetPreviewClicked: () => {
      this.setState({ displayBudgetPreview: false });
    },

    onSelectedGroupClicked: (groupIndex) => {
      if (this.props.onSelectedGroupShoppingCartClick)
        this.props.onSelectedGroupShoppingCartClick(groupIndex);
    },

    onSelectedProductClicked: (groupIndex, productIndex, productType) => {
      if (this.props.onSelectedProductShoppingCartClick)
        this.props.onSelectedProductShoppingCartClick(
          groupIndex,
          productIndex,
          productType,
        );
    },

    onMoveProductClicked: (groupIndex, productIndex, productType) => {
      this.setState({
        displayMoveProducts: true,
        groupIndexSelected: groupIndex,
        enableProductCheckbox: true,
      });

      if (this.props.onMoveProductShoppingCartClick)
        this.props.onMoveProductShoppingCartClick(
          groupIndex,
          productIndex,
          productType,
        );
    },

    onMoveAllProductsClicked: (groupIndex) => {
      let group = this.state.shoppingCart[groupIndex];
      let selectedGroup = this.getSelectListGroups(this.state.shoppingCart);

      this.setState({
        showMoveAllProducts: true,
        displayMoveProducts: true,
        selectGroupsListValue: selectedGroup.find((item) => {
          return item.label == group.groupName;
        }),
        enableProductCheckbox: true,
        linkItemsFromGroups: true,
      });
    },

    onSelectedMoveTripProductChanged: (
      ev,
      groupIndex,
      productIndex,
      productType,
    ) => {
      if (this.props.onSelectedMoveTripProductShoppingCartChange)
        this.props.onSelectedMoveTripProductShoppingCartChange(
          ev,
          groupIndex,
          productIndex,
          productType,
        );
    },

    onMoveProductConfimrClicked: () => {
      let selectGroup = this.state.selectGroupsListValue;
      if (!selectGroup || selectGroup.value == 0) {
        this.setState({ warningSelectGroup: true });
      } else {
        if (this.props.onMoveProductConfimrShoppingCartClick)
          this.props.onMoveProductConfimrShoppingCartClick(
            selectGroup.value - 1,
            this.state.groupIndexSelected,
          );

        this.events.onHideMoveProductsClicked();
      }
    },

    onHideMoveProductsClicked: () => {
      this.setState({
        displayMoveProducts: false,
        enableProductCheckbox: false,
        selectGroupsListValue: 0,
        warningSelectGroup: false,
        showMoveAllProducts: false,
        linkItemsFromGroups: false,
      });

      if (this.props.onHideMoveProductsShoppingCartClick)
        this.props.onHideMoveProductsShoppingCartClick();
    },

    onRenameGroupChanged: (ev, groupIndex) => {
      if (this.props.onRenameGroupShoppingCartChange)
        this.props.onRenameGroupShoppingCartChange(ev, groupIndex);
    },

    onConfirmClicked: () => {},

    notifier: {
      onHidePopupClicked: () => {
        this.setState({
          notifier: {
            ...this.state.notifier,
            data: {},
            fieldsData: {
              to: { email: '', name: '' },
              cc: { email: '', name: '' },
              cco: { email: '', name: '' },
            },
            show: false,
          },
        });
      },

      onConfirmNotifyServiceRequestClicked: () => {
        let objEmail = statusFlowResolver.getFormattedEmailObject(
          this.state.notifier.fieldsData,
        );
        let data = {
          ...objEmail,
          templateName: 'budget',
          templateData: JSON.stringify(this.state.notifier.data),
        };
        this.props.budgetDataSource.send(data);

        this.events.notifier.onHidePopupClicked();
      },

      onFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef] = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },

      onNameFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef].name = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },

      onEmailFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef].email = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },
    },
  };

  getSelectListGroups(shoppingCart, considerTravelers, considerGroup) {
    let groupIndexSelected = this.state.groupIndexSelected;
    let selectGroups = [];
    let travelers = shoppingCart[groupIndexSelected].travelers;

    shoppingCart.forEach((group, index) => {
      if (considerGroup && groupIndexSelected == index) return;

      if (!considerTravelers || !travelers || !group.travelers) {
        selectGroups.push({
          value: index + 1,
          label: group.groupName,
        });
        return;
      }

      if (
        group.travelers.adultCount == travelers.adultCount &&
        group.travelers.childCount == travelers.childCount
      ) {
        let info = ` - ${travelers.adultCount} adulto(s)${
          travelers.childCount ? `, ${travelers.childCount} criança(s)` : ''
        }`;
        selectGroups.push({
          value: index + 1,
          label: group.groupName + info,
        });
      }
    });

    if (selectGroups.length == 0) {
      selectGroups.push({
        value: 0,
        label: 'Nenhum grupo disponível',
      });
    }
    return selectGroups;
  }

  notifier = {
    getProps: () => {
      return {
        ...this.state.notifier,
        notificationType: enums.notificationType.email,
        dataSources: {
          previewTemplate: () => {
            if (this.props.budgetDataSource) {
              let data = { items: [this.state.notifier.data] };

              let loadOptions = {
                jsonData: JSON.stringify(data),
                templateName: 'budget',
              };

              let dsEvents = this.props.budgetDataSource.events || {};
              dsEvents.beforeLoad && dsEvents.beforeLoad(loadOptions);
              return new Promise((resolve, reject) => {
                this.props.budgetDataSource
                  .load(loadOptions)
                  .then((content) => {
                    dsEvents.afterLoad && dsEvents.afterLoad(content);
                    resolve(content);
                  })
                  .catch((result) => {
                    dsEvents.afterLoad && dsEvents.afterLoad(result);
                    reject(result);
                  });
              });
            }
          },
        },
      };
    },
  };

  components = {
    select: {
      getProps: function (shoppingCart) {
        let _this = this;

        return {
          data: this.state.selectGroupsListValue,
          placeholder: 'Selecione um grupo',
          options: {
            dataSource: {
              load: function () {
                return Promise.resolve(
                  _this.getSelectListGroups(shoppingCart, true, true),
                );
              },
            },
            events: {
              onSelected: function (selectedItem) {
                _this.setState({ selectGroupsListValue: selectedItem });
              },
            },
          },
        };
      },
    },
  };
}
