import React from 'react';

import Icon from '../icon/icon.component.js';
import Link from '../link/link.component.js';

const Template = {
  main: ({ stepsControl, children, events }) => {
    return (
      <div className="exon-wizard">
        {stepsControl.showHeader && (
          <div>
            <div className="exon-wizard_header-nav">
              <Template.steps.header
                stepsControl={stepsControl}
                children={children}
              />
            </div>
            <hr />
          </div>
        )}

        <Template.steps.mainContainer
          stepsControl={stepsControl}
          children={children}
        />

        {stepsControl.showNavigation && (
          <Template.steps.navigation props={stepsControl} events={events} />
        )}
      </div>
    );
  },

  steps: {
    header: ({ stepsControl, children }) => {
      return children.map((child, index) => {
        if (child.props.stepName)
          return (
            <div
              key={`step-${index + 1}`}
              className={`exon-wizard_header-nav-item ${
                index + 1 == stepsControl.currentStep
                  ? 'exon-wizard_header-nav-item-active'
                  : ''
              } ${
                index + 1 < stepsControl.currentStep
                  ? 'exon-wizard_header-nav-item-concluded'
                  : ''
              }`}
            >
              <span className="exon-wizard_header-nav-item-identifier">
                {index + 1 < stepsControl.currentStep && (
                  <Icon icon={'exon-icon-checked-filled_disabled'} />
                )}

                {!(index + 1 < stepsControl.currentStep) && index + 1}
              </span>
              <div className="exon-wizard_header-nav-item-name">
                {child.props.stepName}
              </div>
            </div>
          );
      });
    },

    mainContainer: ({ stepsControl, children }) => {
      let childrenToRender = children.slice(0, stepsControl.currentStep);
      return childrenToRender.map((child, index) => {
        return (
          <div
            key={`step-${index + 1}`}
            className="exon-wizard_step-container"
            hidden={index != stepsControl.currentStep - 1}
          >
            {React.cloneElement(child, { key: `wizard-step_${index}` })}
          </div>
        );
      });
    },
    navigation: ({ props, events }) => (
      <div className="exon-wizard_steps-nav">
        <div className="exon-step-nav_box-buttons">
          <button
            className="btn btn-sm btn-round form-cancel exon-step-nav_prev"
            onClick={events.onPrevClicked}
          >
            {props.prevButtonLabel}
          </button>
          <div className="exon-step-nav_next-butons">
            {props.allowSkipStep && (
              <Link
                className="exon-skip-step"
                onClick={events.onNextClicked}
                description={'Pular'}
              />
            )}

            <button
              className="btn btn-sm btn-round btn-primary form-ok exon-step-nav_next"
              disabled={props.disableNextButton}
              onClick={events.onNextClicked}
            >
              {props.nextButtonLabel}
            </button>
          </div>
        </div>
      </div>
    ),
  },
};

export default Template;
