'use strict';
import React from 'react';
import Template from './checkbox.template.js';
import { componentUtil } from '@legacy-utils/componentUtil';

export default class CheckboxComponent extends React.Component {
  externalOptions = {
    events: {
      onSelected: null,
      onDeleted: null,
    },
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {};
  }

  render() {
    return (
      <Template
        checked={this.props.checked ? this.props.checked : false}
        onChange={this.props.onChange ? this.props.onChange : null}
        label={this.props.label ? this.props.label : ''}
      />
    );
  }

  events = {};
}
