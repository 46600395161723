import React from 'react';
import MonitoringMatrixTemplate from './monitoring-matrix.template.js';
import { enums } from '@legacy-utils/enums';

export default class MonitoringMatrixComponent extends React.Component {
  constructor(props) {
    super(props);

    let doc = document.documentElement;
    doc.setAttribute('data-useragent', navigator.userAgent);

    let columns = [
      { label: 'Status', refName: 'name', identifier: true },
      { label: '', refName: '' },
      { label: '< -3', refName: enums.dateIntervals.lessThenThree },
      { label: '-3', refName: enums.dateIntervals.minusThree },
      { label: '-2', refName: enums.dateIntervals.minusTwo },
      { label: '-1', refName: enums.dateIntervals.minusOne },
      { label: '0', refName: enums.dateIntervals.zero },
      { label: '1', refName: enums.dateIntervals.one },
      { label: '2', refName: enums.dateIntervals.two },
      { label: '3', refName: enums.dateIntervals.three },
      { label: '> 3', refName: enums.dateIntervals.greaterThenThree },
      { label: '', refName: '' },
      { label: 'Total', refName: 'total' },
    ];
    let columnIdentifier = columns.find((column) => column.identifier);
    let rows = props.rows;

    this.state = {
      columns: columns,
      rows: rows,
      identifierRefName: columnIdentifier.refName,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let rows = props.rows;

    return {
      rows: rows,
    };
  }

  render() {
    return (
      <MonitoringMatrixTemplate.main
        viewBySelected={this.props.viewBySelected}
        columns={this.state.columns}
        rows={this.state.rows}
        identifierRefName={this.state.identifierRefName}
        onCellsClicked={this.events.onCellsClicked.bind(this)}
        updateAt={this.props.updateAt}
        updateClick={this.props.updateClick}
      />
    );
  }

  events = {
    onCellsClicked(status, interval) {
      if (interval != 'name' && interval != 'total') {
        let rows = this.state.rows;
        let findCelulle = rows.find((row) => row.name == status && row.cells);

        let identifier;
        findCelulle.cells.forEach((cell) => {
          if (cell.key.interval == interval) {
            identifier = cell._identifier;
          }
        });

        if (this.props.onIdentifierCellClick)
          this.props.onIdentifierCellClick(identifier);
      }
    },
  };
}
