let BookingBoxTemplate = ({ title, subtitle, children, className }) => {
  return (
    <div className={`exon-booking-box ${className}`}>
      <div className="exon-booking-box-header">
        <h5>{title}</h5>
        {subtitle &&
          (<h6>&nbsp;{`- ${subtitle}`}</h6>)
        }
      </div>
      <div className="exon-booking-box-body">{children}</div>
    </div>
  );
};

export default BookingBoxTemplate;
