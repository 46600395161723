import Popup from '../../../../popup/popup.component.js';

let Template = (props) => {
  return (
    <div className="exon-room-amenities">
      {props.amenities &&
        props.amenities
          .slice(0, 3)
          .map((amenity, index) => (
            <p key={`${index}-amenities`}>{amenity.name}</p>
          ))}

      {props.amenities && props.amenities.length > 3 && (
        <p className="exon-link">
          <span onClick={() => props.events.onClick()}>Ver mais</span>
        </p>
      )}

      {(!props.amenities || props.amenities.length == 0) && (
        <p>Não informado pelo hotel</p>
      )}

      {props.show && (
        <Popup
          exonStyle={true}
          title={props.title || 'Comodidades do quarto'}
          cancelButtonClick={() => props.events.onClick()}
        >
          {props.amenities &&
            props.amenities.map((amenity, index) => (
              <p key={`${index}-amenities-all`}>{amenity.name}</p>
            ))}
        </Popup>
      )}
    </div>
  );
};

export default Template;
