import { Modal } from '@flash-tecnologia/hros-web-ui-v2';
import { ModalRootProps } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Modal/Root';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { PolicyRegisterForm, PolicyRuleConfiguration } from '../../types';
import PolicyRuleField from '../PolicyRuleField';

export type ParameterFormValues =
  PolicyRegisterForm['rules'][number]['parameters'][number];

type Props = {
  rule: PolicyRuleConfiguration;
  defaultValues?: ParameterFormValues;
  onSave(data: ParameterFormValues): void;
} & Pick<ModalRootProps, 'onClose'>;

export function PolicyRuleParametersModal(props: Props) {
  const ruleSetupForm = useForm<ParameterFormValues>({
    defaultValues: props.defaultValues,
  });

  function onSubmit(data: ParameterFormValues) {
    return props.onSave(data);
  }

  return (
    <Modal.Root open {...props}>
      <>
        <Modal.Header
          title={props.rule.code}
          description={props.rule.description}
        />

        <Modal.Content>
          <Grid container spacing={2}>
            {props.rule.fields.map((field) => (
              <Grid item xs={12}>
                <Controller
                  control={ruleSetupForm.control}
                  name={field.key}
                  render={(controller) => (
                    <PolicyRuleField
                      settings={field}
                      onChange={controller.field.onChange}
                      value={controller.field.value}
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Modal.Content>

        <Modal.Footer onConfirm={ruleSetupForm.handleSubmit(onSubmit)} />
      </>
    </Modal.Root>
  );
}
