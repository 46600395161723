import { PaymentItem } from '@models/PaymentItem';
import { FlashCard } from './FlashCard';
import { EmployeeCorporateBalance } from './EmployeeCorporateBalance';
import { useMemo } from 'react';

type PaymentOptionCardProps = {
  item: PaymentItem;
  selectable?: boolean;
  className?: string;
  checked: boolean;
  onChange: () => void;
  showBalance?: boolean;
  price: number;
  isCurrentUserATravelerProfile?: boolean;
};

export function PaymentOptionCard({
  item,
  selectable = false,
  className = '',
  checked,
  onChange,
  showBalance = true,
  price,
  isCurrentUserATravelerProfile,
}: PaymentOptionCardProps) {
  const cardDisabled = useMemo(() => {
    return (item.balance <= 0 || item.balance < price) && selectable;
  }, [item, price, selectable]);
  return (
    <>
      {!!item.card?.cardId ? (
        <FlashCard
          paymentItem={item}
          selectable={selectable}
          className={className}
          checked={checked}
          onChange={onChange}
          showBalance={showBalance}
          disabled={cardDisabled}
        />
      ) : (
        <EmployeeCorporateBalance
          item={item}
          selectable={selectable}
          className={className}
          checked={checked}
          onChange={onChange}
          showBalance={showBalance}
          disabled={cardDisabled}
          isCurrentUserATravelerProfile={isCurrentUserATravelerProfile}
        />
      )}
    </>
  );
}
