'use strict';

import React from 'react';
import CarrousselTemplate from './carroussel.template.js';

export default class CarrousselComponent extends React.Component {
  componentRef = null;

  constructor(props) {
    super(props);
    let autoPlay =
      this.props.autoplay == undefined ? true : this.props.autoplay;
    let autoPlaySpeed =
      this.props.autoplayspeed == undefined ? 5000 : this.props.autoplayspeed;
    let fadeSpeed =
      this.props.fadespeed == undefined ? 1000 : this.props.fadespeed;
    let showDots =
      this.props.showDots == undefined ? true : this.props.showDots;
    let fade = this.props.fade == undefined ? true : this.props.fade;
    let arrows = this.props.arrows == undefined ? true : this.props.arrows;

    this.componentRef = React.createRef();

    this.state = {
      autoPlay: autoPlay,
      autoPlaySpeed: autoPlaySpeed,
      showDots: showDots,
      fadeSpeed: fadeSpeed,
      fade: fade,
      arrows: arrows,
    };
  }

  render() {
    return (
      <CarrousselTemplate.main
        items={this.props.items}
        componentRef={this.componentRef}
      />
    );
  }

  componentDidMount() {
    this.createSlick();
  }

  componentWillUnmount() {
    try {
      this.removeSlick();
    } catch (e) {
      console.log('componentWillUnmount', e);
    }
  }

  removeSlick() {
    // var compRef = $(this.componentRef.current);
    // if (compRef.hasClass("slick-initialized")) compRef.slick("unslick");
  }

  createSlick() {
    //   var compRef = $(this.componentRef.current);
    //   try {
    //     this.removeSlick();
    //   } catch (e) {
    //     console.log("criarSlick - removerSlick", e);
    //   }
    //   try {
    //     compRef.slick({
    //       dots: this.state.showDots,
    //       speed: this.state.fadeSpeed,
    //       autoplay: this.state.autoPlay,
    //       autoplaySpeed: this.state.autoPlaySpeed,
    //       fade: this.state.fade,
    //       arrows: this.state.arrows,
    //     });
    //   } catch (e) {
    //     console.log("criarSlick - slick", e);
    //   }
  }
}
