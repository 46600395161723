import TripManagementComponent from '../trip-management/trip-management.component.js';
import Popup from '../popup/popup.component.js';
import AirOption from '../products-travel/air-product/air-option/air-option.component.js';
import Select from '../select/select.component.js';
var AirSearchResultTemplate = {
  main: ({
    tripPartsSelected,
    tripType,
    separeResults,
    tripItems,
    fareIndexSelected,
    numberOfItems,
    apiReturn,
    orderList,
    showFaresIndexes,
    tariffedPriceFareResult,
    displayPopupAlert,
    popupAlert,
    displayLoaderTariffedPrice,
    tripPartsIndexSelected,
    onOpenFaresClicked,
    onSelectTripClicked,
    onSelectMultipleTripClick,
    onIncludeInCartClicked,
    onSelectFareClicked,
    onOrderClicked,
    onTripClicked,
    onClearTripClicked,
    onIncludeInCartTripsManagementClick,
    onTryAgainTariffedPriceMultipleTripClick,
    listOrderSelect,
  }) => {
    return (
      <div className="exon-air-search-result">
        {/*<div className={"exon-box-row" + (numberOfItems == 0 && apiReturn ? " hide" : "")}>*/}
        <div className="">
          <div className="exon-box">
            {(tripType != 0 && tripType != 1) ||
            (separeResults && tripType == 1) ? (
              <TripManagementComponent
                tripType={tripType}
                tripPartsIndexSelected={tripPartsIndexSelected}
                tripItems={tripItems}
                tariffedPriceFareResult={tariffedPriceFareResult}
                onTripClicked={onTripClicked}
                onClearTripClicked={onClearTripClicked}
                onSelectMultipleTripClick={onSelectMultipleTripClick}
                onIncludeInCartClick={onIncludeInCartTripsManagementClick}
                onTryAgainTariffedPriceMultipleTripClick={
                  onTryAgainTariffedPriceMultipleTripClick
                }
              />
            ) : (
              ''
            )}
          </div>

          {displayLoaderTariffedPrice && (
            <div className="exon-box-loading-tariffed-trips">
              <div className="exon-loading-tariffed-trips"></div>
              <div className="exon-description-loader-tariffed-trips">
                <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
                <p>
                  Por favor aguarde...
                  <br />
                  Estamos atualizando os valores!
                </p>
              </div>
            </div>
          )}

          <div
            className={
              'exon-box-order-policy exon-box-order-policy-row' +
              ((separeResults && tripType != 0) ||
              (tripType != 1 && !separeResults)
                ? ' exon-box-order-policy-multiple'
                : '')
            }
          >
            {/*<div className={"exon-box-order-policy" + (!separeResults && tripType == 0 || tripType == 1 && !separeResults ? " exon-box-order-policy-row" : "")}>*/}
            <Select
              data={listOrderSelect.data}
              placeholder={listOrderSelect.placeholder}
              externalOptions={listOrderSelect.options}
            />
            <div
              className={
                'exon-box exon-policy-caption exon-policy-caption-row' +
                ((separeResults && tripType != 0) ||
                (tripType != 1 && !separeResults)
                  ? ' exon-policy-caption-multiple'
                  : '')
              }
            >
            </div>
          </div>
        </div>
        <div className="box-description exon-box-description">
          <p className="descriptionm exon-description-item">
            <span className="highlight exon-description-item__highlight">
              {numberOfItems}
            </span>
            {numberOfItems != 1
              ? ' resultados retornados'
              : ' resultado retornardo'}
          </p>
        </div>
        <div
          className={'exon-no-data-box' + (numberOfItems == 0 ? '' : ' hide')}
        >
          <div>
            <span className="exon-icon exon-icon-noData-unfilled_disabled"></span>
            <p>Nenhum resultado encontrado!</p>
          </div>
        </div>

        {tripPartsSelected &&
          tripPartsSelected.flightOptions.map(
            (flightOption, flightOptionIndex) => {
              // listar opções de vôo
              let flightTripIndex = flightOptionIndex + 1;
              let indexSelected = showFaresIndexes
                ? showFaresIndexes.includes(flightOptionIndex)
                : [];
              let enableShoppingCart =
                (separeResults && tripType == 1) ||
                tripType == 2 ||
                tripType == 3
                  ? false
                  : true;
              let fareFlights = flightOption.fareOptions[fareIndexSelected];

              return (
                <div key={flightOptionIndex}>
                  {
                    <AirOption
                      flights={flightOption.trips.map((airTrip, index) => {
                        return {
                          flightPart:
                            (tripType == 0 && index == 0) || tripType == 1
                              ? 'Ida'
                              : tripType == 2
                              ? 'Opção ' + flightTripIndex
                              : 'Volta',
                          airLine: airTrip.airLine,
                          departureDate: airTrip.departure.date,
                          cabinType: airTrip.airClass.id,
                          flightNumber: airTrip.originalAirTrip.flights.map(
                            (flight) => flight.flightNumber,
                          ),
                          departure: {
                            iata: airTrip.departure.iata,
                            name: airTrip.departure.fullName,
                            hour: airTrip.departure.date.timeFormatted,
                            date: airTrip.departure.date.dateFormatted,
                          },
                          stops: airTrip.stops,
                          duration: airTrip.duration.durationFormatted,
                          arrival: {
                            iata: airTrip.arrival.iata,
                            name: airTrip.arrival.fullName,
                            hour: airTrip.arrival.date.timeFormatted,
                            date: airTrip.arrival.date.dateFormatted,
                          },
                          bagagge: {
                            baggage: flightOption.fareOptions[0].baggages,
                            descriptions:
                              flightOption.fareOptions[0].additionalInfo !==
                              undefined
                                ? flightOption.fareOptions[0].additionalInfo
                                    .baggage
                                : ['Sem informação'],
                          },
                          supplier: airTrip.originalAirTrip.supplier,
                          flights: airTrip.flights,
                        };
                      })}
                      fareData={{
                        agreementApplied: fareFlights.agreementApplied,
                        agreementCode: fareFlights.agreementCode,
                        fare: {
                          currencySymbol: fareFlights.price.currencySymbol,
                          value: fareFlights.price.fare.formatted,
                        },
                        taxes: `${fareFlights.price.currencySymbol} ${fareFlights.price.embarkTaxes.formatted}`,
                        service: `${fareFlights.price.currencySymbol} ${fareFlights.price.serviceTaxes.formatted}`,
                        total: `${fareFlights.price.currencySymbol} ${fareFlights.price.total.formatted}`,
                      }}
                      actions={
                        <div>
                          <button
                            className="exon-button-fees"
                            onClick={() =>
                              onOpenFaresClicked(flightOptionIndex)
                            }
                          >
                            Outras tarifas
                            <span
                              className={
                                'exon-icon-down-arrow' +
                                (indexSelected
                                  ? ' exon-icon-down-arrow__active'
                                  : '')
                              }
                            ></span>
                          </button>
                          <div className="exon-box-button-double exon-box-button-double-small">
                            <button
                              className={
                                'exon-button exon-button-secondary exon-button-secondary_enabled' +
                                (enableShoppingCart ? '' : ' hide')
                              }
                              onClick={() =>
                                onIncludeInCartClicked(
                                  flightOptionIndex,
                                  fareIndexSelected,
                                )
                              }
                            >
                              <span className="exon-icon exon-icon-marketing-unfilled_disabled"></span>
                            </button>
                            <button
                              className={
                                'exon-button exon-button-primary' +
                                (enableShoppingCart ? '' : ' exon-no-margin')
                              }
                              onClick={() =>
                                onSelectTripClicked(
                                  flightOptionIndex,
                                  fareIndexSelected,
                                  enableShoppingCart,
                                )
                              }
                            >
                              Selecionar
                            </button>
                          </div>
                        </div>
                      }
                    />
                  }

                  <AirSearchResultTemplate.fareList
                    flight={flightOption}
                    tripType={tripType}
                    separeResults={separeResults}
                    indexSelected={indexSelected}
                    indexFlight={flightOptionIndex}
                    onSelectFareClicked={onSelectFareClicked}
                    onIncludeInCartClicked={onIncludeInCartClicked}
                  />
                </div>
              );
            },
          )}

        {displayPopupAlert && (
          <AirSearchResultTemplate.popupAlert popupAlert={popupAlert} />
        )}
      </div>
    );
  },

  fareList: ({
    flight,
    tripType,
    separeResults,
    indexSelected,
    indexFlight,
    onSelectFareClicked,
    onIncludeInCartClicked,
  }) => {
    let enableShoppingCart =
      (separeResults && tripType == 1) || tripType == 2 || tripType == 3
        ? false
        : true;
    return (
      <div
        className={
          'exon-air-search-result__fares' +
          (!indexSelected ? '' : ' fares-open')
        }
      >
        {flight.fareOptions.map((fare, index) => {
          let fareName;
          if (fare.originalFare.fareInformations[0].mappedFareName)
            fareName =
              fare.originalFare.fareInformations[0].mappedFareName.split(' ');

          return (
            <div className="exon-air-search-result__fare-box" key={index}>
              {fare.additionalInfo !== undefined && fare.additionalInfo.baggage[0] !== ""? (
                <div className="exon-air-search-result__fare-box">
                  <div className="fare__box-title">
                    <span>{fareName[0]}</span>
                    <span className="exon-icon exon-icon-info-unfilled_disabled fare__box-title-tooltip exon-tooltip">
                      <div className="tooltip">
                        <b>{fare.originalFare.fareInformations[0].name}</b>
                      </div>
                    </span>
                    <h6>{fareName[1]}</h6>
                  </div>
                  <div className="fare__box-info fare__width">
                    <span
                      className={`exon-icon-baggages${fare.baggages}-filled_enabled`}
                    ></span>
                    <div className="fare__info-descriptions">
                      {fare.additionalInfo.baggage.map((description, index) => {
                        return <p key={index}>{description}</p>;
                      })}
                    </div>
                  </div>
                  <div className="fare__box-info fare__width">
                    <span className="exon-icon-seat-filled_enabled"></span>
                    <div className="fare__info-descriptions">
                      {fare.additionalInfo.seat.map((description, index) => {
                        return <p key={index}>{description}</p>;
                      })}
                    </div>
                  </div>
                  <div className="fare__box-info">
                    <span className="exon-icon-anticipation-filled_enabled"></span>
                    <div className="fare__info-descriptions fare__info-descriptions-box-cancellation">
                      <p>Alteração / Cancelamento</p>
                      <span className="exon-icon exon-icon-info-unfilled_disabled fare__box-title-tooltip exon-tooltip">
                        <div className="tooltip">
                          <ul>
                            {fare.additionalInfo.cancellation.map(
                              (description, index) => {
                                return (
                                  <li key={'cancellation-' + index}>
                                    <b>Alteração / Cancelamento:</b>{' '}
                                    {description}
                                  </li>
                                );
                              },
                            )}
                            {fare.additionalInfo.anticipation.map(
                              (description, index) => {
                                return (
                                  <li key={'anticipation-' + index}>
                                    <b>Antecipação de Vôo:</b> {description}
                                  </li>
                                );
                              },
                            )}
                          </ul>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="fare__box-info fare__margin-right">
                    <span className="exon-icon-refund-filled_enabled"></span>
                    <div className="fare__info-descriptions fare__info-descriptions-box-refund">
                      <p>Reembolso / No show</p>
                      <span className="exon-icon exon-icon-info-unfilled_disabled fare__box-title-tooltip exon-tooltip">
                        <div className="tooltip">
                          <ul>
                            {fare.additionalInfo.refund.map(
                              (description, index) => {
                                return (
                                  <li key={'refund-' + index}>
                                    <b>Reembolso:</b> {description}
                                  </li>
                                );
                              },
                            )}
                            {fare.additionalInfo.noShow.map(
                              (description, index) => {
                                return (
                                  <li key={'noShow-' + index}>
                                    <b>No show:</b> {description}
                                  </li>
                                );
                              },
                            )}
                          </ul>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="exon-air-search-result__fare-box">
                  <div className="exon-air-search-result-fare-name">
                    <span>Tarifa</span>
                    <br />
                    <span title="Clique aqui para conferir os beneficios desta tarifa">
                      <a
                        href={
                          'https://www.google.com/search?q=' +
                          `Tarifa ${
                            fare.originalFare.fareInformations[0].name +
                            ' ' +
                            fare.airLineName
                          }`
                        }
                        target="_blank"
                        rel="external"
                      >
                        {fare.originalFare.fareInformations[0].name}
                      </a>
                    </span>
                  </div>
                </div>
              )}

              <div className="fare__details">
                <div className="trip-policy-and-values__box-amount">
                  <div className="trip-policy-and-values__box-icons">
                    {/*<span className={"exon-icon" + (fare.inPolicy ? "-policy-ok" : "-policy-not")}></span>*/}
                    {/**/}
                    {fare.agreementApplied && (
                      <span
                        title={`${fare.agreementCode}`}
                        className={'exon-icon exon-icon-policy-wake-up'}
                      ></span>
                    )}
                  </div>
                  <div className="trip-policy-and-values__amount">
                    <span>
                      {fare.price.currencySymbol}
                      <span>{fare.price.fare.formatted}</span>
                    </span>
                  </div>
                </div>
                <div className="exon-box-button-double exon-box-button-double-small">
                  <button
                    className={
                      'exon-button exon-button-secondary' +
                      (enableShoppingCart ? '' : ' hide')
                    }
                    onClick={() => onIncludeInCartClicked(indexFlight, index)}
                  >
                    <span className="exon-icon exon-icon-marketing-unfilled_disabled "></span>
                  </button>
                  <button
                    className={
                      'exon-button exon-button-primary' +
                      (enableShoppingCart ? '' : ' exon-no-margin')
                    }
                    onClick={() =>
                      onSelectFareClicked(
                        indexFlight,
                        index,
                        !enableShoppingCart,
                      )
                    }
                  >
                    Selecionar
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  },

  popupAlert: ({ popupAlert }) => {
    return (
      <Popup
        okButtonLabel={popupAlert.okButtonLabel}
        cancelButtonLabel={null}
        title={popupAlert.title}
        cancelButtonClick={popupAlert.onClosePopupClick}
        exonStyle={true}
        okButtonClick={popupAlert.onOkPopupAlertClick}
      >
        <div className="exon-box-alert-popup">
          {popupAlert.messages.map((msg, index) => {
            return <p key={index}> {msg} </p>;
          })}
        </div>
      </Popup>
    );
  },
};

export default AirSearchResultTemplate;
