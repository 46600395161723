'use strict';

import React from 'react';
import TabPanelTemplate from './tab-panel.template.js';
import ErrorBoundary from '../common/error-boundary/error-boundary.component.js';

export default class TabPanelComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <TabPanelTemplate.main
          children={this.props.children}
          identifier={this.props.identifier}
          activeTabIdentifier={this.props.activeTabIdentifier}
          loading={this.props.loading}
          actions={this.props.actions ? this.props.actions : null}
          displayActionsTabPanel={
            this.props.displayActionsTabPanel
              ? this.props.displayActionsTabPanel
              : false
          }
          loaderType={this.props.loaderType ? this.props.loaderType : null}
        />
      </ErrorBoundary>
    );
  }
}
