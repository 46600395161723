let OptionsListTemplate = ({ options, onChange }) => {
  return (
    <div className="exon-options-list">
      {options &&
        options.map((option, index) => {
          return (
            <div className="exon-options-list__box-item" key={index}>
              <label
                className={
                  'exon-options-list__item' +
                  (option.disabled ? ' exon-options-list__item-disabled' : '')
                }
              >
                <input
                  type="checkbox"
                  value={option.value}
                  disabled={option.disabled}
                  checked={option.checked}
                  onChange={(event) => onChange(event, index)}
                />
                <label className="exon-label-checkbox">
                  <span></span>
                </label>
                {option.label}
              </label>
            </div>
          );
        })}
    </div>
  );
};

export default OptionsListTemplate;
