'use strict';

import React from 'react';
import Template from './hotel-rules-and-policies.template.js';

export default class HotelRulesAndPoliciesComponent extends React.Component {
  render() {
    return <Template {...this.props} />;
  }
}
