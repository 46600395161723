import styled from 'styled-components';
export const FlexRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & > div:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
`;
export const GuaranteeContainer = styled.div``;
