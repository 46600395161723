import React, { useState, useEffect } from 'react';
import * as SC from './styled';
import { useTracking } from '@shared/hooks/useTracking';
import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  TRACKING_DISCLAIMER_CLICK,
  TRACKING_DISCLAIMER_PAGE,
} from '@shared/constants/Tracking.constants';

export const infoNewFunctions = [
  {
    title: 'Integração com a plataforma NDC:',
    description:
      'Estamos atualizados com a nova modalidade de distribuição de conteúdos!',
    url: 'https://drive.google.com/file/d/1Zbg0tz3hqYIL6K5NzGMBVsO4CWdVunoj/view?usp=sharing',
  },
  {
    title: 'Aprovações via App da Flash oficial:',
    description:
      'Agora você já pode aprovar suas requisições de viagens no app da Flash!',
    url: 'https://drive.google.com/file/d/1Rsy6ENnLQe0jq5JFPag3HfQ9Vf-inazj/view?usp=sharing',
  },
  {
    title: 'Formulário de Veículo:',
    description: 'Agora você já pode pedir seu veículo pela plataforma.',
    url: 'https://drive.google.com/file/d/1RrwnfeVp0wMSTIkdT9KRcLBvKf8vDb5a/view?usp=sharing',
  },
  {
    title: 'Hotel Internacional:',
    description:
      'Planejando uma reunião corporativa no exterior? Garanta a melhor hospedagem, em qualquer destino do mundo e com o melhor custo benefício! Descubra como',
    url: 'https://drive.google.com/file/d/1RI-_eLB4IZllfrreiozSxm28nMP6uFED/view?usp=sharing',
  },
];

export default function NewFunctionsFlash() {
  const [currentInfoIndex, setCurrentInfoIndex] = useState(0);
  const { trackEvent, trackPage } = useTracking();
  useEffect(() => {
    trackPage({
      name: TRACKING_DISCLAIMER_PAGE,
    });
  }, [trackPage]);

  useEffect(() => {
    const paginaAtual = window.location.pathname;
    if (
      paginaAtual === '/travel/sale' ||
      paginaAtual === '/travel/list' ||
      paginaAtual === '/travel/approvals'
    ) {
      trackEvent({
        name: `Carregamento da Página: ${paginaAtual}`,
      });
    }
  }, [trackEvent]);

  const handleUrlClick = (url) => {
    trackEvent({
      name: TRACKING_DISCLAIMER_CLICK,
      params: { url: url },
    });
    window.open(url, '_blank');
  };

  useEffect(() => {
    const fetchIndex = async () => {
      const index = parseInt(localStorage.getItem('currentInfoIndex'), 10) || 0;
      setCurrentInfoIndex(index);
      const nextIndex = (index + 1) % infoNewFunctions.length;
      localStorage.setItem('currentInfoIndex', nextIndex.toString());
    };

    fetchIndex();
  }, []);

  return (
    <>
      <SC.NewFunctionsContanier>
        <SC.Icon>
          <ShapeIcon
            name={'IconSparkles'}
            size={45}
            color={'#fb2a81'}
            variant={'default'}
          />
        </SC.Icon>
        <div key={infoNewFunctions[currentInfoIndex].title}>
          <SC.TitleNewsFunctions>
            <Typography variant={'body3'} children={'Novidade:'} weight={500} />
          </SC.TitleNewsFunctions>
          <Typography variant={'body4'} weight={500}>
            <b>{infoNewFunctions[currentInfoIndex].title}</b>{' '}
            {infoNewFunctions[currentInfoIndex].description}{' '}
            <SC.Link
              rel="noopener noreferrer"
              onClick={() =>
                handleUrlClick(infoNewFunctions[currentInfoIndex].url)
              }
            >
              Clique aqui
            </SC.Link>{' '}
            para saber mais.
          </Typography>
        </div>
      </SC.NewFunctionsContanier>
    </>
  );
}
