import { trpc } from '@api/bff/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { GetPolicyByIdResponse } from 'bff/src/routers/policies';
import { useEffect } from 'react';
import { Resolver, useForm } from 'react-hook-form';

import { dispatchToast } from '@shared/toast';
import {
  PolicyAction,
  PolicyRuleCode,
  PolicyRuleStatus,
} from 'bff/src/routers/policies/enums';
import { usePolicyRules } from '../components/PolicyProperties/PolicyRules/hooks/usePolicyRules';
import { PolicyRegisterForm, PolicyRegisterFormStep } from '../types';
import { usePolicyFormValidation } from './usePolicyFormValidation';
import { useUploadFile } from './useUploadFile';

type Props = {
  step: PolicyRegisterFormStep;
  policy: GetPolicyByIdResponse;
  onSave: () => void;
};

export function usePolicyForm({ onSave, policy, step }: Props) {
  const validation = usePolicyFormValidation({ step });

  const rules = usePolicyRules();

  const defaultRules = rules.map((rule) => ({
    code: rule.code as PolicyRuleCode,
    status: PolicyRuleStatus.INACTIVE,
  }));

  const policyForm = useForm<PolicyRegisterForm>({
    defaultValues: {
      name: '',
      description: '',
      action: PolicyAction.BLOCK,
      rules: defaultRules,
      conditions: [{}],
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validation) as Resolver<PolicyRegisterForm>,
  });

  const savePolicy = trpc.savePolicy.useMutation();
  const uploadFile = useUploadFile();

  async function onSubmit(data: PolicyRegisterForm) {
    try {
      const result = await savePolicy.mutateAsync(data);

      if (result.presignedUrl) {
        await uploadFile.mutateAsync({
          file: data.document.file,
          url: result.presignedUrl,
        });
      }

      dispatchToast({
        type: 'success',
        content: `A política ${data.name} foi salva com sucesso.`,
      });

      return onSave?.();
    } catch (err) {
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro ao salvar a política. Tente novamente.',
      });
    }
  }

  useEffect(() => {
    if (!policy) return;

    return policyForm.reset({
      ...policy,
      rules: policy.rules.length > 0 ? policy.rules : defaultRules,
    });
  }, [policy]);

  return {
    policyForm,
    onSubmit,
    isSaving: policyForm.formState.isSubmitting || uploadFile.isLoading,
  };
}
