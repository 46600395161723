import Select from '../../select/select.component.js';
import RadioGroup from '../../radio-group/radio-group.component.js';
import Locations from '../../locations/locations.component.js';

var MonitoringFilterTemplate = {
  main: ({
    isAgency,

    viewByProps,
    typesProps,

    companyProps,
    companySelected,
    onClearSelectedCompanyClick,
    locationProps,
    isLocationEmpty,
    onClearSelectedLocationClick,

    onApplyClick,
  }) => {
    return (
      <div className="exon-monitoring-filter">
        <MonitoringFilterTemplate.filter.viewBy viewByProps={viewByProps} />

        <MonitoringFilterTemplate.filter.types typesProps={typesProps} />

        {isAgency && (
          <MonitoringFilterTemplate.filter.company
            companyProps={companyProps}
            companySelected={companySelected}
            onClearSelectedCompanyClick={onClearSelectedCompanyClick}
          />
        )}

        <MonitoringFilterTemplate.filter.location
          locationProps={locationProps}
          isLocationEmpty={isLocationEmpty}
          onClearSelectedLocationClick={onClearSelectedLocationClick}
        />

        <MonitoringFilterTemplate.filter.toApply onApplyClick={onApplyClick} />
      </div>
    );
  },

  filter: {
    viewBy: ({ viewByProps }) => (
      <div className="exon-monitoring-filter-viewBy exon-monitoring-filter-containter">
        <label>Visualizar por:</label>
        <div>
          <Select
            data={viewByProps.data}
            placeholder={'Selecione'}
            externalOptions={viewByProps.options}
          />
        </div>
      </div>
    ),
    types: ({ typesProps }) => (
      <div className="exon-monitoring-filter-types exon-monitoring-filter-containter">
        <label>Tipo</label>
        <div className="exon-monitoring-filter-types-radio">
          <RadioGroup
            name={'monitoringFilter'}
            item={typesProps.data}
            externalOptions={typesProps.options}
          />
        </div>
      </div>
    ),
    company: ({
      companyProps,
      companySelected,
      onClearSelectedCompanyClick,
    }) => (
      <div className="exon-monitoring-filter-company exon-monitoring-filter-containter">
        <label>Empresa / Filial</label>
        <div className="exon-monitoring-filter-company-select">
          <Select
            data={companyProps.data}
            placeholder={companyProps.placeholder}
            externalOptions={companyProps.options}
            showSearch={true}
          />
          {companySelected != 0 && (
            <span
              className="exon-monitoring-filter-clear"
              title="Remover empreesa selecionada"
              onClick={() => onClearSelectedCompanyClick()}
            >
              X
            </span>
          )}
        </div>
      </div>
    ),
    location: ({
      locationProps,
      isLocationEmpty,
      onClearSelectedLocationClick,
    }) => (
      <div className="exon-monitoring-filter-location exon-monitoring-filter-containter">
        <label>Localidade</label>
        <div className="exon-monitoring-filter-location-select">
          <Locations
            data={locationProps.data}
            placeholder={locationProps.placeholder}
            externalOptions={locationProps.options}
          />
          {!isLocationEmpty && (
            <span
              className="exon-monitoring-filter-clear"
              title="Remover localidade selecionada"
              onClick={() => onClearSelectedLocationClick()}
            >
              X
            </span>
          )}
        </div>
      </div>
    ),

    toApply: ({ onApplyClick }) => (
      <div className="exon-monitoring-filter-button">
        <button
          className="exon-monitoring-filter-button-toAplay"
          onClick={() => onApplyClick()}
        >
          Aplicar
        </button>
      </div>
    ),
  },
};

export default MonitoringFilterTemplate;
