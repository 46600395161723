//CSS no padrão BEM (Bloco Elemento Modificador)

let AutoCompleteTemplate = {
  //Template principal "pai" dos demais componentes
  main: ({
    placeholder,
    itemsElement,
    showItems,
    showLoader,
    showOverlay,
    inputText,
    validationResult,
    onChange,
    onCancelSelectionClick,
    onClick,
    onInputBlur,
    disabled,
  }) => (
    <div
      className={
        'exon-auto-complete' +
        (!validationResult.isValid && !disabled ? ' exon-alert-border' : '')
      }
    >
      <div
        className={
          'exon-overlay exon-outside-options-background-mask' +
          (!showOverlay ? 'hide' : '')
        }
        onClick={onCancelSelectionClick}
      ></div>

      <input
        className={
          'exon-textbox exon-field ' + (showOverlay ? 'exon-field-active' : '')
        }
        autoComplete="off"
        name="text"
        type="text"
        disabled={disabled}
        pattern="[a-z\s]+$"
        required="required"
        placeholder={placeholder}
        value={inputText}
        onChange={onChange}
        onClick={onClick}
        onBlur={onInputBlur}
      />
      <span className={'exon-icon-loader' + (showLoader ? '' : ' hide')}></span>
      {!validationResult.isValid && !disabled && (
        <span className={'exon-msg-alert-field'}>
          {validationResult.message}
        </span>
      )}
      {itemsElement}
    </div>
  ),

  //Template das opções a serem renderizadas a partir do resultado da busca
  items: ({ items, onClick, showOptions }) => (
    <ul className={'exon-select-itens list' + (showOptions ? '' : ' hide')}>
      {items.map(({ value, label }, index) => {
        let disabled = value == undefined;
        return (
          <li
            key={index}
            disabled={disabled}
            onClick={() => {
              onClick(value);
            }}
            value={value}
            className="exon-list-item"
          >
            {label}
          </li>
        );
      })}
    </ul>
  ),
};

export default AutoCompleteTemplate;
