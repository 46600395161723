import React from 'react';
import {
  Modal,
  Button,
  Loader,
  CircularProgress,
  ShapeIcon,
} from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styled';
import useModalStore from '../../../store/Modal.store';

const AlertModalCustom = () => {
  const {
    isOpen,
    closeModal,
    title,
    subTitle,
    content,
    primaryButtonLabel,
    primaryButtonAction,
    secondaryButtonLabel,
    secondaryButtonAction,
    isLoading,
    status,
    isAlert,
    isPrimaryButtonLoading,
    setPrimaryButtonLoading,
  } = useModalStore();

  const iconConfig = {
    isLoading: {
      component: CircularProgress,
      props: { variant: 'indeterminate', size: 48 },
    },
    success: {
      component: ShapeIcon,
      props: { name: 'IconCheck', variant: status, size: 64 },
    },
    error: {
      component: ShapeIcon,
      props: { name: 'IconX', variant: status, size: 64 },
    },
    alert: {
      component: ShapeIcon,
      props: { name: 'IconAlertTriangle', variant: 'info', size: 64 },
    },
    default: {
      component: ShapeIcon,
      props: { name: 'IconExclamationCircle', variant: status, size: 64 },
    },
  };

  const { component: IconComponent, props: iconProps } =
    iconConfig[isLoading ? 'isLoading' : status] || iconConfig.default;

  const isBothButtons = !!primaryButtonLabel && !!secondaryButtonLabel;

  const handlePrimaryButtonClick = async () => {
    if (primaryButtonAction) {
      setPrimaryButtonLoading(true);
      try {
        await primaryButtonAction();
      } finally {
        setPrimaryButtonLoading(false);
      }
    }
  };

  const renderContent = () => {
    if (React.isValidElement(content)) {
      return content;
    } else if (typeof content === 'string') {
      return <SC.Children>{content}</SC.Children>;
    }
    return null;
  };

  return (
    <Modal.Root open={isOpen} onClose={closeModal} size={'md'}>
      <Modal.Content>
        <SC.ModalContainer>
          {isLoading && <Loader size={'large'} variant={'secondary'} />}
          {!isLoading && (
            <div>
              {status && (
                <SC.IconCustomModal>
                  <IconComponent {...iconProps} />
                </SC.IconCustomModal>
              )}
              <SC.Title>{title}</SC.Title>
              {subTitle && <SC.SubTitle>{subTitle}</SC.SubTitle>}
              {renderContent()}
              <SC.ButtonContainer isBothButtons={isBothButtons}>
                {secondaryButtonLabel && (
                  <Button
                    variant="secondary"
                    onClick={secondaryButtonAction}
                    size={'large'}
                  >
                    {secondaryButtonLabel}
                  </Button>
                )}
                {primaryButtonLabel && (
                  <Button
                    variant={isAlert ? 'secondary' : 'primary'}
                    onClick={handlePrimaryButtonClick}
                    size={'large'}
                    loading={isPrimaryButtonLoading}
                  >
                    {primaryButtonLabel}
                  </Button>
                )}
              </SC.ButtonContainer>
            </div>
          )}
        </SC.ModalContainer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default AlertModalCustom;
