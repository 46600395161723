import Traveler from '../../../../traveler/traveler.component';
import { enums } from '@legacy-utils/enums.js';
import { AdditionalFieldRoom } from './additional-field-room';
export type CheckoutTravelerProps = {
  roomOptions?: any;
  hotelsOptions?: any;
  selectedRoom?: any;
  travelerNumber: any;
  confirmedTraveler: any;
  serviceRequestExisting?: any;
  travelerType: any;
  disabledFields: any;
  dataSources: any;
  onTravelerConfirm: any;
  onTravelerAutoCompleteInputChange: any;
  showValidationsFields: any;
  isImportExternal: any;
  travelerOnReservation: any;
  showValidationAdditionalFieldsTraveler: any;
  isInternational?: any;
  isRvOffline?: any;
  needCompleteAddress: any;
  isWoobaAndInternacional: any;
};

export const CheckoutTraveler = ({
  roomOptions,
  hotelsOptions,
  selectedRoom,
  travelerNumber,
  confirmedTraveler,
  serviceRequestExisting,
  travelerType,
  disabledFields,
  dataSources,
  onTravelerConfirm,
  onTravelerAutoCompleteInputChange,
  showValidationsFields,
  isImportExternal,
  travelerOnReservation,
  showValidationAdditionalFieldsTraveler,
  isInternational,
  isRvOffline,
  needCompleteAddress,
  isWoobaAndInternacional,
}: CheckoutTravelerProps) => {
  if (isImportExternal && travelerOnReservation) {
    var nameTravaler = travelerOnReservation.useSocialName
      ? travelerOnReservation.socialFirstName +
        ' ' +
        travelerOnReservation.socialMiddleName +
        ' ' +
        travelerOnReservation.socialLastName
      : travelerOnReservation.firstName +
        ' ' +
        travelerOnReservation.middleName +
        ' ' +
        travelerOnReservation.lastName;
  }

  return (
    <div className="exon-passenger-information__group">
      <div>
        {isImportExternal && travelerOnReservation && (
          <div className="exon-traveler">
            <div className="exon-box-fields-row">
              <div className="exon-box-field">
                <label className="exon-label-field">
                  Nome do Viajante na Reserva
                </label>
                <div className="exon-field">
                  <input
                    className="exon-box-company-input"
                    name="text"
                    type="text"
                    value={nameTravaler}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <Traveler
            isInternational={isInternational}
            isRvOffline={isRvOffline}
            dataSources={dataSources}
            traveler={confirmedTraveler}
            autoCompleteInputText={
              disabledFields && confirmedTraveler.labelToShow
                ? confirmedTraveler.labelToShow
                : undefined
            } // TODO: modificar para obter a partir do confirmedTraveler
            travelerNumber={travelerNumber}
            travelerType={travelerType}
            showValidationsFields={!disabledFields && showValidationsFields}
            disabled={disabledFields && confirmedTraveler}
            label={isImportExternal ? 'Passageiro na plataforma' : false}
            needCompleteAddress={needCompleteAddress}
            additionalFields={
              roomOptions &&
              roomOptions.options &&
              roomOptions.options.items &&
              roomOptions.options.items.length && (
                <AdditionalFieldRoom
                  roomOptions={roomOptions}
                  hotelsOptions={hotelsOptions}
                  travelerNumber={travelerNumber}
                  travelerType={travelerType}
                  validationFields={showValidationAdditionalFieldsTraveler}
                />
              )
            }
            onTravelerConfirm={(traveler) => {
              onTravelerConfirm(traveler, travelerNumber, roomOptions.data);
            }}
            onAutoCompleteInputChanged={(travelerId) =>
              onTravelerAutoCompleteInputChange(travelerId)
            }
            isWoobaAndInternacional={isWoobaAndInternacional}
          />
          <div
            className={
              'exon-check-label' +
              (travelerType == enums.travelerType.Adt ? '' : ' hide')
            }
          >
            <div className="exon-input-check">
              <label htmlFor="confirm-traveler">
                <input
                  type="checkbox"
                  disabled={disabledFields}
                  readOnly
                  checked={
                    (confirmedTraveler && confirmedTraveler.useSocialName) ||
                    false
                  }
                  id="confirm-traveler"
                />
                <label>
                  <span></span>
                </label>
              </label>
            </div>
            <label className="exon-label-field">
              Usar nome social na reserva
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
