import * as SCPage from '../../styled';
import * as SCFeature from './styled';
const SC = { ...SCPage, ...SCFeature };
interface HeroProps {
  img: string;
  greeting: string;
  title: string;
  subtitle: string;
  buttonText: string;
  loadingButton: boolean;
  onClickButton?: () => void;
}
export const Hero = ({
  img,
  greeting,
  title,
  subtitle,
  buttonText,
  loadingButton,
  onClickButton,
}: HeroProps) => {
  return (
    <SC.ContentContainer>
      <SC.Wrapper>
        <SC.LeftContainer>
          <SC.TextsContainer>
            <SC.GreetingsText variant="body3">{greeting}</SC.GreetingsText>
            <SC.Title variant="headline5">{title}</SC.Title>
            <SC.Subtitle variant="body3">{subtitle}</SC.Subtitle>
            <SC.ButtonContainer>
              <SC.StyledButton
                variant="primary"
                size="large"
                type={'button'}
                buttonType="primary"
                onClick={onClickButton}
                loading={loadingButton}
                disabled={loadingButton}
              >
                {buttonText}
              </SC.StyledButton>
            </SC.ButtonContainer>
          </SC.TextsContainer>
        </SC.LeftContainer>
        <SC.RightContainer imageUrl={img}></SC.RightContainer>
      </SC.Wrapper>
    </SC.ContentContainer>
  );
};
