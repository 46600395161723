'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import DateAndPeriodTemplate from './date-and-period.template.js';

export default class DateAndPeriodComponent extends React.Component {
  selected = null;

  externalOptions = {
    events: {
      onSelected: null,
    },
    clear: null,
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.subComponents.date, this);
    componentUtil.react.bindMethods(this.subComponents.period, this);

    this.state = {
      dataControlledByParent: props.data != undefined,

      data: props.data || {
        date: null,
        period: null,
      },
      startDate: new Date(),
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      data: state.dataControlledByParent ? props.data : state.data,
      startDate: props.startDate || state.startDate,
    };
  }

  render() {
    return (
      <DateAndPeriodTemplate.main
        date={this.state.data ? this.state.data.date : null}
        period={this.state.data ? this.state.data.period : null}
        startDate={this.state.startDate}
        placeholder={this.props.placeholder}
        labelDate={this.props.labeldate}
        labelPeriod={this.props.labelperiod}
        options={this.getOptions()}
      />
    );
  }

  getOptions = function () {
    return {
      date: this.subComponents.date.getOptions(),
      period: this.subComponents.period.getOptions(),
    };
  };

  subComponents = {
    date: {
      getOptions: function () {
        let _this = this;
        return {
          events: {
            onSelected: _this.events.onDateSelected,
          },
        };
      },
    },
    period: {
      getOptions: function () {
        let _this = this;
        return {
          events: {
            onSelected: _this.events.onPeriodSelected,
          },
        };
      },
    },
  };

  getData = function () {
    return this.state.data;
  };

  setData = function (data) {
    this.setState({
      data: data,
    });
  };

  clear = function () {
    this.setData({ date: null, period: null });
  };

  events = {
    onDateSelected: function (date) {
      this.events.onDateAndPeriodSelected(
        date,
        this.state.data ? this.state.data.period : null,
      );
    },
    onPeriodSelected: function (period) {
      this.events.onDateAndPeriodSelected(
        this.state.data ? this.state.data.date : null,
        period,
      );
    },
    onDateAndPeriodSelected: function (date, period) {
      let data = { date: date, period: period };
      this.setData(data);

      if (this.externalOptions.events.onSelected)
        this.externalOptions.events.onSelected(data);
    },
  };
}
