import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import {
  PillButton,
  tableControllers,
  Toggle,
  Checkbox,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ColumnDef } from '@tanstack/react-table';
import useModalStore from '../../../../store/Modal.store';
import { trpc } from '@api/bff/client';
import { gritter } from '@legacy-utils/utils';
import { ApprovalItem } from '../../types';

type ExtendedColumnDef<T> = ColumnDef<T> & {
  accessorKey?: keyof T;
  id: string;
};

export function useTableColumns({
  data,
  onPaginationChange,
  pagination,
  refetch,
}) {
  const { useSelectedRows, useTableColumns } = tableControllers;
  const openModal = useModalStore((state) => state.openModal);
  const closeModal = useModalStore((state) => state.closeModal);

  const deleteApprovalFlowMutation = trpc.deleteApprovalFlowById.useMutation();
  const toggleApprovalFlowStatusMutation =
    trpc.toggleApprovalFlowStatus.useMutation();

  const [selected] = useSelectedRows<ApprovalItem>(data.length);

  const handleEditFlow = useCallback((id: string) => {
    console.log(`Editar fluxo ${id}`);
  }, []);

  const handleDeleteFlow = useCallback(
    (id: string) => {
      openModal({
        title: 'Deseja realmente excluir?',
        subTitle:
          'Essa ação não poderá ser desfeita, deseja excluir o fluxo de aprovação?',
        status: 'error',
        primaryButtonLabel: 'Excluir',
        primaryButtonAction: async () => {
          try {
            await deleteApprovalFlowMutation.mutateAsync({ id });
            refetch();
            closeModal();
          } catch (error) {
            gritter.Error(`Erro ao excluir o fluxo de aprovação:`, error);
          }
        },
      });
    },
    [deleteApprovalFlowMutation, openModal],
  );

  const columns: ExtendedColumnDef<ApprovalItem>[] = [
    {
      id: 'selection',
      header: () => (
        <Checkbox checked={selected.allSelected} aria-label="Select all" />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={selected.selected.some(
            (selectedRow) => selectedRow.id === row.original.id,
          )}
          aria-label="Select row"
        />
      ),
    },
    {
      id: 'priority',
      header: 'Prioridade',
      accessorKey: 'priority',
    },
    {
      id: 'name',
      header: 'Nome do fluxo',
      accessorKey: 'name',
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row }) => (
        <Toggle
          checked={row.original.status === 'ACTIVE'}
          onChange={async () => {
            toggleApprovalFlowStatusMutation.mutateAsync({
              id: row.original.id,
            });
          }}
        />
      ),
    },
    {
      id: 'description',
      header: 'Descrição',
      accessorKey: 'description',
    },
    {
      id: 'actions',
      header: 'Ações',
      cell: ({ row }) => (
        <Box display="flex" gap={2}>
          <PillButton
            icon="IconEdit"
            size="small"
            type="secondary"
            variant="default"
            onClick={() => handleEditFlow(row.original.id)}
          />
          <PillButton
            icon="IconTrash"
            size="small"
            type="secondary"
            variant="default"
            onClick={() => handleDeleteFlow(row.original.id)}
          />
        </Box>
      ),
    },
  ];

  return useTableColumns({
    columns,
    data,
    onPaginationChange,
    pagination,
  });
}
