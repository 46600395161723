'use strict';

import React from 'react';
import Template from './air-booking.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { enums } from '@legacy-utils/enums';
import { statusFlowResolver } from '@legacy-utils/resolvers/statusFlowResolver.js';
import { componentUtil } from '@legacy-utils/componentUtil.js';

export default class AirBookingComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.airTickets, this);

    this.state = {
      displayLoader: true,
      analysisItemData: {
        show: false,
      },
      booking: {},
      airTicketIssueFlowData: {
        show: false,
        showIfHasTicketsToIssue: false,
        hasTicketsToIssue: false,
        ticketsToIssue: [],
        replacement: false,
        dataSources: {
          paymentForms: this.props.dataSources.paymentForms,
          airLines: this.props.dataSources.airLines,
          airTicket: this.props.dataSources.airTicket,
          locations: this.props.dataSources.locations,
        },
        costCenter: this.props.costCenter,
        requester: this.props.requester,
      },

      airTripReplacementData: {
        show: false,
        airTrips: [],
        dataSources: {
          airSearch: null,
          locations: null,
          airlines: null,
        },
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    let airTicketIssueFlowData = state.airTicketIssueFlowData;
    let booking = props.booking;

    if (!booking.loading) {
      booking.allowedActions = statusFlowResolver.getRecordAllowedActions(
        booking,
        props.requester,
        props.currentUserData.userId,
        props.currentUserData.travelProfile,
        props.currentUserData.company,
      );

      if (airTicketIssueFlowData.showIfHasTicketsToIssue) {
        let ticketsToIssue = AirBookingComponent.getTicketsToIssue(booking);
        let hasTicketsToIssue = ticketsToIssue.length > 0;

        airTicketIssueFlowData.booking = booking;
        airTicketIssueFlowData.hasTicketsToIssue = hasTicketsToIssue;
        airTicketIssueFlowData.ticketsToIssue = ticketsToIssue;
        airTicketIssueFlowData.show =
          state.airTicketIssueFlowData.showIfHasTicketsToIssue &&
          hasTicketsToIssue;
        airTicketIssueFlowData.showNoTicketToIssueMessage =
          state.airTicketIssueFlowData.showIfHasTicketsToIssue &&
          !hasTicketsToIssue;
        airTicketIssueFlowData.showIfHasTicketsToIssue = false;
      }
    }

    return {
      booking,
      airTicketIssueFlowData,
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          booking={this.state.booking}
          isOffline={this.props.isOffline}
          bookingEvents={this.events.booking}
          dataSources={this.props.dataSources}
          analysisItemData={this.state.analysisItemData}
          analysisItemEvents={this.events.analysisItem}
          airTicketIssueFlowData={this.state.airTicketIssueFlowData}
          airTicketIssueFlowEvents={this.events.airTicketIssueFlow}
          airTripReplacementData={this.state.airTripReplacementData}
          airTripReplacementEvents={this.events.airTripReplacement}
          editItemsEvents={this.events.editItens}
          travelersList={this.props.travelersList}
          onShowEmailPopupClick={this.props.onShowEmailPopupClick}
        />
      </ErrorBoundary>
    );
  }

  events = {
    analysisItem: {
      onShowAnalysisItemClicked: () => {
        this.props.events.onAnalysisItemClicked &&
          this.props.events.onAnalysisItemClicked(enums.products.type.air);

        this.setState({
          analysisItemData: { ...this.state.analysisItemData, show: true },
        });
      },
      onHideAnalysisItemClicked: () => {
        this.setState({
          analysisItemData: { ...this.state.analysisItemData, show: false },
        });
      },
    },

    booking: {
      onUpdateBookingClicked: () => {
        this.props.events.onUpdateAirBookingClicked &&
          this.props.events.onUpdateAirBookingClicked();
      },

      onSendAppsClicked: (bookingId) => {
        this.props.events.onSendAppsClicked &&
          this.props.events.onSendAppsClicked(bookingId);
      },
    },
    airTicketIssueFlow: {
      onOpenIssueTicketFlowButtonClicked: () => {
        this.setState({
          airTicketIssueFlowData: {
            ...this.state.airTicketIssueFlowData,
            showIfHasTicketsToIssue: true,
          },
        });

        this.props.events.onUpdateAirBookingClicked &&
          this.props.events.onUpdateAirBookingClicked();
      },
      onReplaceButtonClicked: (ticketNumber) => {
        this.setState({
          airTicketIssueFlowData: {
            ...this.state.airTicketIssueFlowData,
            ticketsToIssue: [
              this.state.booking.airBooking.airTickets.find(
                (t) => t.number == ticketNumber,
              ),
            ],
            replacement: true,
            show: true,
          },
        });
      },

      onCloseFlowClicked: () => {
        this.setState({
          airTicketIssueFlowData: {
            ...this.state.airTicketIssueFlowData,
            show: false,
          },
        });
      },
      onFinishFlowClick: () => {
        this.props.events.onUpdateAirBookingClicked &&
          this.props.events.onUpdateAirBookingClicked();

        this.setState({
          airTicketIssueFlowData: {
            ...this.state.airTicketIssueFlowData,
            show: false,
          },
        });
      },

      onCloseNoTicketToIssueMessage: () => {
        this.setState({
          airTicketIssueFlowData: {
            ...this.state.airTicketIssueFlowData,
            showNoTicketToIssueMessage: false,
          },
        });
      },
    },
    airTripReplacement: {
      onReplaceAirTripClicked: (airTripIndex) => {
        this.setState({
          airTripReplacementData: {
            ...this.state.airTripReplacementData,
            airTrips: [this.state.booking.airBooking.airTrip], //TODO: obter a partir do indice
            show: true,
          },
        });
      },

      onCloseFlowClicked: () => {
        this.setState({
          airTripReplacementData: {
            ...this.state.airTripReplacementData,
            show: false,
          },
        });
      },
      onFinishFlowClick: () => {
        this.setState({
          airTripReplacementData: {
            ...this.state.airTripReplacementData,
            show: false,
          },
        });
      },
    },

    airTicket: {
      onCancelButtonClicked: (airTicketId) => {
        const dataSource = this.props.dataSources.airTicket;
      },
    },

    editItens: {
      onEditAirSegmentsClicked: (data) => {
        if (this.props.rvOfflineEvents.onEditAirSegmentsClicked)
          this.props.rvOfflineEvents.onEditAirSegmentsClicked(
            data,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );
      },
      onEditAirTariffsAndFessClicked: (data) => {
        if (this.props.rvOfflineEvents.onEditAirTariffsAndFessClicked)
          this.props.rvOfflineEvents.onEditAirTariffsAndFessClicked(
            data,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );
      },
      onEditAirTicketClicked: (data) => {
        if (this.props.rvOfflineEvents.onEditAirTicketClicked)
          this.props.rvOfflineEvents.onEditAirTicketClicked(
            data,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );
      },
      onAddAirTicketClicked: (data) => {
        if (this.props.rvOfflineEvents.onAddAirTicketClicked)
          this.props.rvOfflineEvents.onAddAirTicketClicked(
            data,
            this.props.identifierActiveTab,
            this.props.identifierActiveBooking,
          );
      },
    },
  };

  static getTicketsToIssue(booking) {
    const tickets = [];

    if (booking.pendingTickets && booking.pendingTickets.length) {
      booking.pendingTickets.forEach((ticket, index) => {
        tickets.push({
          ...ticket,
          originalFromResponse: { ...ticket },

          airTrips: booking.airTrips,
        });
      });
    }

    return tickets;
  }

  airTripReplacement = {};
}
