import { usePermissionsFlags } from '@flash-hros/auth-helper';

export function useCheckFlagPermissions() {
  const permissions = usePermissionsFlags.getState();
  function checkFlag(flagName: string) {
    return permissions?.flags?.some(
      (_flag) => _flag.name === flagName && _flag.enabled,
    );
  }

  return checkFlag;
}
