import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs5};
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  margin: 0 auto;
`;
export const Items = styled.div``;
export const NavContent = styled.div``;

export const NavList = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs3};
  margin: 0 auto;
`;

export interface NavListItemProps {
  isActive: boolean;
}

export const NavListItem = styled.div<NavListItemProps>`
  cursor: pointer;
  border-radius: ${(props) => props.theme.borders.radius.circular};
  height: 8px;
  width: 8px;
  background-color: ${(props) =>
    props.isActive
      ? props.theme.colors.primary
      : props.theme.colors.secondary[95]};
`;

export const NavButton = styled.div`
  margin: auto;
`;
