'use strict';

import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import PaginationTemplate from './pagination.template.js';

export default class PaginationComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    const itemsPerPage = this.props.itemsPerPage ? this.props.itemsPerPage : 10;

    this.state = {
      itemsPerPage: itemsPerPage,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const itemsQuantity = props.itemsQuantity ? props.itemsQuantity : 0;
    const currentPage = props.currentPage ? props.currentPage : 1;
    const itemsPerPage = props.itemsPerPage ? props.itemsPerPage : 10;
    const numberPages =
      itemsQuantity > 0 ? Math.ceil(itemsQuantity / itemsPerPage) : 0;

    return {
      itemsQuantity: itemsQuantity,
      currentPage: currentPage,
      numberPages: numberPages,
    };
  }

  render() {
    let visibleOptionsPages = this.getVisibleOptionsPages(
      this.state.currentPage,
      this.state.numberPages,
    );

    return (
      <PaginationTemplate
        numberPages={this.state.numberPages}
        currentPage={this.state.currentPage}
        visibleOptionsPages={visibleOptionsPages}
        onPageClick={this.events.onPageClicked}
        onNextPagesClick={this.events.onNextPagesClicked}
        onPrevPagesClick={this.events.onPrevPagesClicked}
      />
    );
  }

  events = {
    onPageClicked: (currentPage) => {
      if (this.props.onPageClick) this.props.onPageClick(currentPage);
    },

    onNextPagesClicked: () => {
      let currentPage = this.state.currentPage;
      let numberPages = this.state.numberPages;

      if (currentPage < numberPages) {
        let currentPageUpDate = currentPage + 1;

        if (this.props.onPageClick) this.props.onPageClick(currentPageUpDate);
      }
    },

    onPrevPagesClicked: () => {
      let currentPage = this.state.currentPage;

      if (currentPage > 1) {
        let currentPageUpDate = currentPage - 1;

        if (this.props.onPageClick) this.props.onPageClick(currentPageUpDate);
      }
    },
  };

  getVisibleOptionsPages(currentPage, numberPages) {
    let optionsPage = [];

    if (currentPage == 1) {
      for (var i = 2; i <= 5 && i <= numberPages - 1; i++) {
        optionsPage.push(i);
      }

      return optionsPage;
    }

    if (currentPage > 1 && currentPage < 5) {
      let begin = 2;
      let finish = 5;

      for (var i = begin; i <= finish && i < numberPages; i++) {
        optionsPage.push(i);
      }

      return optionsPage;
    }

    if (currentPage >= 5 && currentPage + 2 < numberPages) {
      let begin = currentPage - 2;
      let finish = currentPage + 1;

      for (var i = begin; i <= finish; i++) {
        optionsPage.push(i);
      }

      return optionsPage;
    }

    if (currentPage >= 5 && currentPage >= numberPages - 3) {
      let begin = numberPages <= 5 ? numberPages - 3 : numberPages - 4;
      let finish = numberPages - 1;

      for (var i = begin; i <= finish; i++) {
        optionsPage.push(i);
      }

      return optionsPage;
    }
  }
}
