import React from 'react';
import * as SCPage from '../../styled';
import * as SCFeature from './styled';
const SC = { ...SCPage, ...SCFeature };
export interface BigNumberItem {
  number: string;
  text: React.ReactNode;
}
interface BigNumbersProps {
  items: BigNumberItem[];
  children: React.ReactNode;
  buttonText: string;
  loadingButton: boolean;
  onClickButton?: () => void;
}
export const BigNumbers = ({
  children,
  items,
  buttonText,
  loadingButton,
  onClickButton,
}: BigNumbersProps) => {
  return (
    <SC.NumbersSection>
      <SC.ContentContainer>
        <SC.Container>
          <SC.TitleContainer>
            <SC.Title>{children}</SC.Title>
          </SC.TitleContainer>
          <SC.NumberContainer>
            {items.map((item, index) => (
              <SC.BigNumberCard key={index}>
                <SC.BigNumber>{item.number}</SC.BigNumber>
                <SC.BigNumberText>{item.text}</SC.BigNumberText>
              </SC.BigNumberCard>
            ))}
          </SC.NumberContainer>
          <SC.ButtonContainer>
            <SC.StyledButton
              variant="primary"
              size="large"
              type={'button'}
              onClick={onClickButton}
              loading={loadingButton}
              disabled={loadingButton}
            >
              {buttonText}
            </SC.StyledButton>
          </SC.ButtonContainer>
        </SC.Container>
      </SC.ContentContainer>
    </SC.NumbersSection>
  );
};
