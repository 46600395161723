import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

type Props = {
  hasSelection?: boolean;
  hasChildren?: boolean;
};

export const Container = styled.div<Props>`
  padding: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xs};
  padding-left: ${({ theme, hasSelection }) =>
    hasSelection ? theme.spacings.xs : theme.spacings.xs};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Header = styled.div<Props>`
  display: grid;
  grid-row-gap: ${({ theme, hasSelection }) =>
    hasSelection ? '2px' : theme.spacings.xs5};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
`;

export const Caption = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const Body = styled.div<Props>`
  width: 100%;
  padding-left: ${({ theme, hasSelection }) =>
    hasSelection ? theme.spacings.l : 0};
  padding-top: ${({ theme }) => theme.spacings.xs};
`;

export const HeaderContent = styled.div<Props>`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Divider = styled.hr`
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  margin: ${({ theme }) => theme.spacings.xs} -24px;
  margin-bottom: 0;
`;
