import AirOption from '../../../../products-travel/air-product/air-option/air-option.component.js';
import Pagination from '../../../../pagination/pagination.componente.js';
import { utils } from '@legacy-utils/utils';

var Template = {
  main: ({
    airOptions,
    dataSearchParameters,
    dataNavigator,
    dataPagination,
  }) => {
    let selectedTrip = dataNavigator.selectedTrip;

    return (
      <div className="exon-search-results-options exon-air-search-results-options">
        <div className="exon-search-results-options-header">
          {airOptions.length > 1 && (
            <Template.navigatorMultipleTrips data={dataNavigator} />
          )}

          <div></div>
        </div>
        <div className="exon-air-search-results-options-body">
          <Template.airOptions
            data={{
              airOptions: airOptions[selectedTrip],
              airOptionsAmount: airOptions.length,
              selectedTrip: selectedTrip,
              isMultiple: dataNavigator.isMultiple,
              dataPagination: dataPagination,
            }}
          />
        </div>
        <div className="exon-air-search-results-options-footer">
          <Pagination
            itemsQuantity={airOptions[selectedTrip].items.length}
            itemsPerPage={dataPagination.itemsPerPage}
            onPageClick={dataPagination.onPageClick}
            currentPage={dataPagination.currentPage}
          />
        </div>
      </div>
    );
  },

  searchParameters: ({ data }) => {
    let searchData = data.searchData;
    return <div className="exon-air-search-results-options-parameters"></div>;
  },

  navigatorMultipleTrips: ({ data }) => {
    let buttons = [];
    let searchData = data.searchData.trips;

    for (let i = 1; i <= data.amountTrips; i++) {
      let tripIndex = i - 1;
      buttons.push(
        <button
          key={i}
          className={`exon-button exon-button-secondary ${
            data.selectedTrip == tripIndex
              ? 'exon-button-secondary_enabled'
              : ''
          }`}
          onClick={() => data.navigatorEvents.onTripClicked(tripIndex)}
        >
          {data.isMultiple
            ? 'Trecho ' + i + ':'
            : tripIndex == 0
            ? 'Ida:'
            : 'Volta:'}
          <br />
          <p>
            {searchData[tripIndex].iataOrigin}
            <span className="exon-icon exon-icon-down-unfilled_disabled"></span>
            {searchData[tripIndex].iataDestination}
          </p>
        </button>,
      );
    }

    return <div className="exon-air-search-results-options-nav">{buttons}</div>;
  },

  airOptions: ({ data }) => {
    let items = data.airOptions.items;
    let isMultiple = data.isMultiple;

    let getFormattedDateObjFromDateObject =
      utils.formatters.date.getFormattedDateObjFromDateObject;
    let getDurationHoursMinute = utils.formatters.time.durationHoursMinute;
    let getCurrencySymbol = utils.converters.money.getCurrencySymbol;
    let getFormattedRounded = utils.formatters.money.getFormattedRounded;

    let pagination = data.dataPagination;
    let begin =
      pagination.currentPage > 1
        ? pagination.itemsPerPage * (pagination.currentPage - 1)
        : 0;
    let end =
      pagination.currentPage > 1
        ? begin + pagination.itemsPerPage
        : pagination.itemsPerPage;

    return (
      <div className="exon-air-search-results-options-box">
        {items.slice(begin, end).map((item, index) => {
          let fareItem = item.fare.travellersFareValues[0];
          return (
            <AirOption
              key={index}
              flights={item.trips.map((trip, index) => {
                return {
                  flightPart: isMultiple
                    ? 'Opção'
                    : index == 0
                    ? 'Ida'
                    : 'Volta',
                  ciaCode: trip.issuerAirline.code,
                  departureDate: getFormattedDateObjFromDateObject(
                    trip.departureDate,
                  ),
                  cabinType: trip.firstFlightCabinType,
                  departure: {
                    iata: trip.departure.iata,
                    name: trip.departure.name,
                    hour: getFormattedDateObjFromDateObject(trip.departureDate)
                      .timeFormatted,
                  },
                  stops: trip.stops,
                  duration: getDurationHoursMinute(trip.duration),
                  arrival: {
                    iata: trip.arrival.iata,
                    name: trip.arrival.name,
                    hour: getFormattedDateObjFromDateObject(trip.arrivalDate)
                      .timeFormatted,
                  },
                  bagagge: {
                    baggage: trip.baggages,
                    descriptions: ['1 Bagagem até 32kg'],
                  },
                };
              })}
              fareData={{
                fare: {
                  currencySymbol: getCurrencySymbol(
                    fareItem.markupedFare.currencyCode,
                  ),
                  value: getFormattedRounded(
                    fareItem.markupedFare.value,
                    fareItem.markupedFare.currencyCode,
                  ),
                },
                taxes: `${getCurrencySymbol(
                  fareItem.taxes.currencyCode,
                )} ${getFormattedRounded(
                  fareItem.taxes.value,
                  fareItem.taxes.currencyCode,
                )}`,
                service: `${getCurrencySymbol(
                  fareItem.du.currencyCode,
                )} ${getFormattedRounded(
                  fareItem.du.value,
                  fareItem.du.currencyCode,
                )}`,
                total: `${getCurrencySymbol(
                  fareItem.du.currencyCode,
                )} ${getFormattedRounded(
                  fareItem.du.value +
                    fareItem.markupedFare.value +
                    fareItem.taxes.value,
                  fareItem.fare.currencyCode,
                )}`,
              }}
            />
          );
        })}
      </div>
    );
  },
};

export default Template;
