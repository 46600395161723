import TabPanel from '../tab-panel/tab-panel.component.js';
import Tab from '../tab/tab.component.js';
import TabsGroup from '../tabs-group/tabs-group.component.js';
import RequestStatusBar from '../travel-requests/request-status-bar/request-status-bar.component.js';
import TravelRequestDetails from '../travel-requests/travel-request-details/travel-request-details.component.js';
import TravelRequestNavigation from '../travel-requests/travel-request-navigation/travel-request-navigation.component.js';
import TravelRequestsNotifications from '../travel-requests/travel-requests-notifications/travel-requests-notifications.component.js';

import AirBooking from '../travel-requests/air-booking/air-booking.component.js';
import HotelBooking from '../travel-requests/hotel-booking/hotel-booking.component.js';
import VehicleBooking from './vehicle-booking/vehicle-booking.component.js';

import { ApprovalRequestedModal } from '../../../components/ApprovalRequestedModal';
import Form from '../common/form/form.component.js';
import Notifier from '../common/notifier/notifier.component.js';
import Field from '../field/field.component.js';
import Popup from '../popup/popup.component.js';
import Checkout from '../quotes/checkout/checkout.componente.js';
import Select from '../select/select.component.js';
import ActionsRequestHistory from '../travel-requests/actions-request-history/actions-request-history.component.js';
import Dashboard from '../travel-requests/dashboard/dashboard.component.js';
import RequestHistoryLogs from '../travel-requests/request-history-logs/request-history-logs.component.js';

import Monitoring from '../monitoring/monitoring-manager/monitoring-manager.component.js';

import { enums } from '@legacy-utils/enums';
import { useTravelItemStore } from '../../../store/RequestTravelItem.store';

var TravelRequestsTemplate = {
  main: ({
    requests,
    bookings,
    identifierActiveTab,
    identifierActiveBooking,
    notifierProps,
    historicData,
    requestHistoriesData,
    popupFeedbackData,
    popupDecisionData,
    mainLoader,
    userTravelProfile,
    isAgency,
    companies,
    popupRecuseJustificationData,
    descriptionJustification,
    showValidationsfieldsJustification,

    //RV Offline
    rvOfflineData,
    selectCompanies,
    selectAmountTravelersOptions,
    formCreateItem,
    rvOfflineEvents,
    travelersList,

    approvalMethods,
    currentUserData,
    airLines,

    //events
    tabEvents,
    requetsEvents,
    navigationEvents,
    notifierEvents,
    historicEvents,
    requestHistoriesEvents,
    isApproval,
    dataSources,
    bookingsEvents,
    decisionEvents,
    justificationEvents,
    monitoringDataSurces,
    isMonitoring,
  }) => {
    const currentRequest = requests[identifierActiveTab];

    return (
      <div className="exon-travel-requests">
        <TabsGroup>
          {Object.keys(requests).map((identifier, index) => {
            let label = isMonitoring ? 'Monitoramento' : 'Requisições';
            return (
              <Tab
                key={'tab' + identifier}
                tabIndex={identifier}
                identifier={identifier}
                label={
                  requests[identifier].identifier == 'request-list'
                    ? label
                    : requests[identifier].identifier
                }
                selectedTab={identifierActiveTab}
                showCloseButton={index == 0 ? false : true}
                loading={requests[identifier].loading}
                onTabClick={tabEvents.onSelectTabClicked}
                onDeleteTabClick={tabEvents.onDeleteTabClicked}
              />
            );
          })}
        </TabsGroup>
        <div className="exon-box-content-tab">
          {Object.keys(requests).map((identifier, index) => {
            return (
              <TabPanel
                key={'tabPanel-' + identifier}
                identifier={identifier}
                activeTabIdentifier={identifierActiveTab}
                loading={requests[identifier].loading}
              >
                {!requests[identifier].loading && index > 0 && (
                  <TravelRequestsTemplate.request
                    requests={requests}
                    currentUserData={currentUserData}
                    bookings={bookings}
                    identifier={identifier}
                    identifierActiveTab={identifierActiveTab}
                    identifierActiveBooking={identifierActiveBooking}
                    userTravelProfile={userTravelProfile}
                    formCreateItem={formCreateItem}
                    navigationEvents={navigationEvents}
                    requetsEvents={requetsEvents}
                    bookingsEvents={bookingsEvents}
                    decisionEvents={decisionEvents}
                    justificationEvents={justificationEvents}
                    rvOfflineEvents={rvOfflineEvents}
                    travelersList={travelersList}
                    dataSources={dataSources}
                  />
                )}
                {index == 0 && companies.length > 0 && !isMonitoring && (
                  <Dashboard
                    requests={requests}
                    externalOptions={requetsEvents}
                    isApproval={isApproval}
                    dataSources={dataSources}
                    userTravelProfile={userTravelProfile}
                    isAgency={isAgency}
                    companies={companies}
                    approvalMethods={approvalMethods}
                    airLines={airLines}
                    eventsRvOffline={rvOfflineData.events}
                    currentUserData={currentUserData}
                  />
                )}

                {index == 0 && companies.length > 0 && isMonitoring && (
                  <Monitoring
                    dataSources={monitoringDataSurces}
                    isAgency={isAgency}
                    requetsEvents={requetsEvents}
                  />
                )}
              </TabPanel>
            );
          })}
        </div>

        {currentRequest && currentRequest.allowedActions && (
          <div
            className={`exon-travel-requests-sr-box-actions ${
              currentRequest.identifier == 'request-list' ||
              currentRequest.loading
                ? 'hide'
                : 'nda'
            }`}
          >
            <div>
              {currentRequest.allowedActions.canAdd && (
                <button
                  className="btn btn-sm btn-round btn-dark"
                  onClick={decisionEvents.onAddProductClicked}
                >
                  <span className="exon-travel-request-pluss">+</span>
                  Adicionar
                </button>
              )}

              {currentRequest.allowedActions.canSendToAuthorization && (
                <button
                  className="btn btn-sm btn-round btn-success"
                  onClick={() =>
                    decisionEvents.onSendToAuthorizationClicked(
                      currentRequest.identifier,
                    )
                  }
                >
                  <i className="icon-ok ace-icon fa fa-check"></i>
                  Enviar para Autorização
                </button>
              )}

              {currentRequest.allowedActions.canCancel && (
                <button
                  className="btn btn-sm btn-round btn-default"
                  onClick={() =>
                    decisionEvents.onCancelRequestClicked(
                      currentRequest.identifier,
                    )
                  }
                >
                  Cancelar
                </button>
              )}

              {currentRequest.allowedActions.canAuthorize &&
                currentRequest.allowedActions.currentApprover && (
                  <button
                    className="btn btn-sm btn-round btn-success"
                    onClick={() =>
                      decisionEvents.onApproveRequestClicked(
                        currentRequest.identifier,
                      )
                    }
                  >
                    <i className="icon-ok ace-icon fa fa-check"></i>
                    Aprovar
                  </button>
                )}

              {currentRequest.allowedActions.canRefuse &&
                currentRequest.allowedActions.currentApprover && (
                  <button
                    className="btn btn-sm btn-round btn-default"
                    onClick={() =>
                      justificationEvents.onRefuseRequestClicked(
                        currentRequest.identifier,
                      )
                    }
                  >
                    <span className="exon-travel-request-pluss">X</span>
                    Reprovar
                  </button>
                )}
            </div>
            <div>
              <button
                className="btn btn-sm btn-round btn-success align-to-right"
                onClick={() =>
                  requetsEvents.onShowRequestHistoriesPopupClicked()
                }
              >
                <i className="icon-ok ace-icon fa fa-history"></i>
                Histórico
              </button>
            </div>
          </div>
        )}

        {notifierProps.show && (
          <div className="exon-box-notifier">
            <TravelRequestsTemplate.notifier
              notifierProps={notifierProps}
              notifierEvents={notifierEvents}
            />
          </div>
        )}

        {historicData.show && (
          <div className="exon-box-historic">
            <TravelRequestsTemplate.historic
              data={historicData}
              events={historicEvents}
            />
          </div>
        )}

        {requestHistoriesData.show && (
          <div className="exon-box-historic">
            <TravelRequestsTemplate.requestHistories
              dataSource={dataSources.serviceRequestHistory}
              events={requestHistoriesEvents}
              currentRequest={currentRequest}
            />
          </div>
        )}

        {popupFeedbackData.show && (
          <div className="exon-box-popup-feedback">
            <TravelRequestsTemplate.popup.feedback
              data={popupFeedbackData}
              events={popupFeedbackData.events}
            />
          </div>
        )}

        <div className="exon-box-popup-decision">
          <ApprovalRequestedModal
            isOpen={popupDecisionData.show}
            onApprovalClick={popupDecisionData.events.confirm}
            onCancelClick={popupDecisionData.events.cancel}
            onRepprovalClick={popupRecuseJustificationData.events.confirm}
            data={popupDecisionData.description}
            type="approve"
          />
        </div>

        {rvOfflineData.show && (
          <div className="exon-box-popup-rv-offline-initial">
            <TravelRequestsTemplate.popup.rvOffline
              data={rvOfflineData}
              selectCompanies={selectCompanies}
              selectAmountTravelersOptions={selectAmountTravelersOptions}
              dataSources={rvOfflineData.dataSources}
              events={rvOfflineData.events}
            />
          </div>
        )}

        {formCreateItem.show && (
          <div className="exon-box-popup-form exon-box-popup-form-rv">
            <TravelRequestsTemplate.popup.formCreateItem
              data={formCreateItem}
              events={formCreateItem.events}
            />
          </div>
        )}

        <div className="exon-box-popup-justification">
          <ApprovalRequestedModal
            isOpen={popupRecuseJustificationData.show}
            onApprovalClick={popupRecuseJustificationData.events.confirm}
            onCancelClick={popupRecuseJustificationData.events.cancel}
            data={popupRecuseJustificationData.description}
            type="refuse"
            descriptionJustification={descriptionJustification}
            setDescriptionJustification={(e) =>
              popupRecuseJustificationData.events.onChangeDescription(e)
            }
          />
        </div>

        {mainLoader && <TravelRequestsTemplate.mainLoader />}
      </div>
    );
  },

  request: ({
    requests,
    bookings,
    identifierActiveBooking,
    currentUserData,
    identifierActiveTab,
    identifier,
    userTravelProfile,
    navigationEvents,
    requetsEvents,
    bookingsEvents,
    decisionEvents,
    formCreateItem,
    rvOfflineEvents,
    travelersList,

    justificationEvents,
    dataSources,
  }) => {
    let request = requests[identifier];

    let requestNavigationItens = [
      {
        identifier: identifier,
        productType: null,
        label: request.identifier,
      },
    ];

    request.itemsSummary &&
      request.itemsSummary.map((booking) => {
        let productType = enums.productType.none;

        switch (booking.type) {
          case enums.products.namedType.airBooking:
            productType = enums.productType.air;
            break;
          case enums.products.namedType.hotelBooking:
            productType = enums.productType.hotel;
            break;
          case enums.products.namedType.vehicleBooking:
            productType = enums.productType.vehicle;
            break;
        }

        requestNavigationItens.push({
          id: booking.id,
          contextId: request.contextId,
          rootContextId: request.rootContextId,
          identifier: booking.identifier,
          productType: productType,
          label: booking.identifier,
        });
      });

    let booking = request.items[request.managerNavigation.identifier];
    const isModalOpen = useTravelItemStore((state) => state.isModalOpen);

    return (
      <div className="exon-travel-request-box">
        <div className="exon-row-itens">
          <RequestStatusBar
            statusInfos={{
              requestNumber: request.id,
              status: request.workflow.status,
              travelType: request.travelType,
              evaluation: request.workflow.inPolicy,
              dataOrigin: request.audit ? request.audit.dataOrigin : null,
            }}
          />
          <div className="exon-actions-travel-request exon-row-itens exon-row-justify-content-space-between exon-row-aling-itens-center exon-w-100">
            <div className="exon-row-itens ">
              <span
                className="exon-icon exon-icon-double-update-unfilled_enabled exon-row-aling-itens-center"
                onClick={requetsEvents.onUpdateDataClicked}
              ></span>
              {request.allowedActions.sendNotification && (
                <span
                  className="exon-icon exon-icon-envelope-filled_enabled"
                  onClick={() =>
                    requetsEvents.onShowEmailPopupClicked(
                      request.id,
                      request.workflow.status.id,
                      true,
                    )
                  }
                ></span>
              )}
            </div>
          </div>
        </div>
        {request.workflow.policyViolations &&
          request.workflow.policyViolations.length && (
            <TravelRequestsNotifications
              requestIdentifier={request.identifier}
              notifications={request.workflow.policyViolations}
            />
          )}
        <TravelRequestNavigation
          items={requestNavigationItens}
          onNavigationItemClick={navigationEvents.onNavigationItemClicked}
          isOffline={request.audit.dataOrigin == enums.requestDataOrigin.manual}
          identifierActiveItem={request.managerNavigation.itemActive}
          events={{
            onOpenSelectItemTypeClick:
              formCreateItem.events.onOpenFormPopupClicked,
          }}
        />

        {!request.managerNavigation.bookingType && (
          <TravelRequestDetails
            currentUserData={currentUserData}
            request={request}
            userTravelProfile={userTravelProfile}
            decisionEvents={decisionEvents}
            justificationEvents={justificationEvents}
            dataSources={dataSources}
            updateIsModalItem={isModalOpen}
            events={{
              onOpenSelectItemTypeClick:
                formCreateItem.events.onOpenFormPopupClicked,
              onCostSplitChanged: requetsEvents.onCostSplitChanged,
            }}
          />
        )}

        {request.managerNavigation.bookingType && booking && (
          <TravelRequestsTemplate.serviceRequestItem.container
            booking={booking}
            isOffline={
              request.audit.dataOrigin == enums.requestDataOrigin.manual
            }
            allowedActions={request.allowedActions}
            bookingType={request.managerNavigation.bookingType}
            dataSources={dataSources}
            bookingEvents={bookingsEvents}
            identifierActiveBooking={identifierActiveBooking}
            identifierActiveTab={identifierActiveTab}
            rvOfflineEvents={rvOfflineEvents}
            travelersList={travelersList}
            requester={request.requester}
            costCenter={request.costCenter}
            currentUserData={currentUserData}
            onShowEmailPopupClick={requetsEvents.onShowEmailPopupClicked}
          />
        )}
      </div>
    );
  },

  serviceRequestItem: {
    container: ({
      booking,
      requester,
      costCenter,
      currentUserData,
      allowedActions,
      bookingType,
      dataSources,
      bookingEvents,
      identifierActiveTab,
      identifierActiveBooking,
      rvOfflineEvents,
      isOffline,
      travelersList,
      onShowEmailPopupClick,
    }) => {
      let productTemplate;
      switch (bookingType) {
        case enums.productType.air:
          productTemplate = (
            <TravelRequestsTemplate.serviceRequestItem.airBooking
              booking={booking}
              isOffline={isOffline}
              allowedActions={allowedActions}
              requester={requester}
              costCenter={costCenter}
              currentUserData={currentUserData}
              dataSources={dataSources}
              events={bookingEvents}
              identifierActiveBooking={identifierActiveBooking}
              identifierActiveTab={identifierActiveTab}
              rvOfflineEvents={rvOfflineEvents}
              travelersList={travelersList}
              onShowEmailPopupClick={onShowEmailPopupClick}
            />
          );
          break;
        case enums.productType.hotel:
          productTemplate = (
            <TravelRequestsTemplate.serviceRequestItem.hotelBooking
              booking={booking}
              isOffline={isOffline}
              dataSources={dataSources}
              events={bookingEvents}
              identifierActiveBooking={identifierActiveBooking}
              identifierActiveTab={identifierActiveTab}
              rvOfflineEvents={rvOfflineEvents}
              travelersList={travelersList}
              onShowEmailPopupClick={onShowEmailPopupClick}
              currentUserData={currentUserData}
              requester={requester}
            />
          );
          break;
        case enums.productType.vehicle:
          productTemplate = (
            <TravelRequestsTemplate.serviceRequestItem.vehicleBooking
              booking={booking}
              isOffline={isOffline}
              dataSources={dataSources}
              events={bookingEvents}
              identifierActiveBooking={identifierActiveBooking}
              identifierActiveTab={identifierActiveTab}
              rvOfflineEvents={rvOfflineEvents}
              travelersList={travelersList}
              onShowEmailPopupClick={onShowEmailPopupClick}
              currentUserData={currentUserData}
              requester={requester}
            />
          );
          break;
      }

      if (booking && !booking.loading && !booking.id) {
        return (
          <div className="exon-box-error-item-SR">
            <span className="exon-icon exon-icon-popup-info-unfilled_enabled"></span>
            <p>Houve um erro ao coletar o item da requisição de serviço.</p>
          </div>
        );
      } else {
        //TODO: Passar loading para fora do componente do produto
        return productTemplate;
      }
    },

    airBooking: ({
      booking,
      requester,
      costCenter,
      currentUserData,
      allowedActions,
      dataSources,
      identifierActiveTab,
      identifierActiveBooking,
      travelersList,
      rvOfflineEvents,
      isOffline,
      events,
      onShowEmailPopupClick,
    }) => {
      return (
        <AirBooking
          booking={booking}
          requester={requester}
          costCenter={costCenter}
          currentUserData={currentUserData}
          isOffline={isOffline}
          allowedActions={allowedActions}
          dataSources={dataSources}
          events={events}
          identifierActiveBooking={identifierActiveBooking}
          identifierActiveTab={identifierActiveTab}
          rvOfflineEvents={rvOfflineEvents}
          travelersList={travelersList}
          onShowEmailPopupClick={onShowEmailPopupClick}
        />
      );
    },

    hotelBooking: ({
      booking,
      dataSources,
      identifierActiveTab,
      identifierActiveBooking,
      travelersList,
      rvOfflineEvents,
      isOffline,
      events,
      onShowEmailPopupClick,
      currentUserData,
      requester,
    }) => {
      return (
        <HotelBooking
          booking={booking}
          isOffline={isOffline}
          dataSources={dataSources}
          events={events}
          currentUserData={currentUserData}
          identifierActiveBooking={identifierActiveBooking}
          identifierActiveTab={identifierActiveTab}
          rvOfflineEvents={rvOfflineEvents}
          travelersList={travelersList}
          onShowEmailPopupClick={onShowEmailPopupClick}
          requester={requester}
        />
      );
    },

    vehicleBooking: ({
      booking,
      dataSources,
      identifierActiveTab,
      identifierActiveBooking,
      travelersList,
      rvOfflineEvents,
      isOffline,
      events,
      onShowEmailPopupClick,
      currentUserData,
      requester,
    }) => {
      return (
        <VehicleBooking
          booking={booking}
          isOffline={isOffline}
          dataSources={dataSources}
          events={events}
          currentUserData={currentUserData}
          identifierActiveBooking={identifierActiveBooking}
          identifierActiveTab={identifierActiveTab}
          rvOfflineEvents={rvOfflineEvents}
          travelersList={travelersList}
          onShowEmailPopupClick={onShowEmailPopupClick}
          requester={requester}
        />
      );
    },
  },

  notifier: ({ notifierProps, notifierEvents }) => {
    return (
      <Popup
        title={'Notificação da RV'}
        exonStyle={true}
        cancelButtonClick={notifierEvents.onHidePopupClicked}
        okButtonClick={
          notifierProps.isRv
            ? notifierEvents.onConfirmNotifyServiceRequestClicked
            : notifierEvents.onConfirmNotifyServiceRequestItemClicked
        }
        cancelButtonLabel="Cancelar"
        okButtonLabel="Enviar"
      >
        <Notifier
          notificationType={notifierProps.notificationType}
          dataSource={
            notifierProps.isRv
              ? notifierProps.dataSources.serviceRequest
              : notifierProps.dataSources.serviceRequestItem
          }
          fieldValues={notifierProps.fieldsData}
          events={notifierEvents}
        />
      </Popup>
    );
  },

  historic: ({ events }) => {
    let title = (
      <div className="exon-title-historic">
        <span className="exon-icon exon-icon-historic-unfilled_enabled"></span>
        <h5>Histórico de ações</h5>
      </div>
    );

    return (
      <Popup
        okButtonLabel={null}
        title={title}
        exonStyle={true}
        cancelButtonClick={events.onHidePopupClicked}
        okButtonClick={null}
      >
        <ActionsRequestHistory />
      </Popup>
    );
  },

  requestHistories: ({ dataSource, events, currentRequest }) => {
    let serviceRequestId = currentRequest.id;

    let title = (
      <div className="exon-title-historic">
        <h5>Histórico da RV {serviceRequestId}</h5>
      </div>
    );

    return (
      <Popup
        okButtonLabel={null}
        title={title}
        exonStyle={true}
        cancelButtonClick={events.onHidePopupClicked}
        okButtonClick={null}
      >
        <RequestHistoryLogs
          dataSource={dataSource}
          serviceRequestId={currentRequest.id}
          contextId={currentRequest.context.id}
        />
      </Popup>
    );
  },

  popup: {
    feedback: ({ data, events }) => {
      return (
        <Popup
          okButtonLabel={null}
          title={null}
          exonStyle={true}
          closeButtonClick={events.onClosePopupClicked}
          okButtonClick={null}
        >
          <span className="exon-icon exon-icon-popup-info-unfilled_enabled"></span>

          {data.descriptions.map((item, index) => {
            return (<div key={index}>
              <p>{item.message}</p>
              {
                !!item.detail &&
                <p>{item.detail}</p>
              }
              </div>);
          })}
        </Popup>
      );
    },

    decision: ({ data, events }) => {
      return (
        <Popup
          okButtonLabel={'Confirmar'}
          cancelButtonLabel={'Cancelar'}
          title={null}
          exonStyle={true}
          cancelButtonClick={events.cancel}
          okButtonClick={events.confirm}
        >
          <span className="exon-icon exon-icon-popup-info-unfilled_enabled"></span>
          <p>{data}</p>
        </Popup>
      );
    },

    justification: ({
      data,
      events,
      descriptionJustification,
      showValidationsfieldsJustification,
    }) => {
      return (
        <Popup
          okButtonLabel={'Confirmar'}
          okButtonClick={events.confirm}
          cancelButtonLabel={'Cancelar'}
          cancelButtonClick={events.cancel}
          title={null}
          exonStyle={true}
        >
          <span className="exon-icon exon-icon-popup-info-unfilled_enabled"></span>
          <p>{data}</p>

          <div
            className={
              'exon-box-popup-justification-container exon-field' +
              ((descriptionJustification == null ||
                descriptionJustification == '') &&
              showValidationsfieldsJustification
                ? ' exon-alert-border'
                : '')
            }
          >
            <label className="exon-label-field">
              {' '}
              Para seguir com essa reprovação, justifique:{' '}
            </label>

            <textarea
              className="exon-box-popup-justification-textarea"
              type="readonly"
              placeholder="Digite a justificativa da reprovação"
              onChange={(e) => events.onChangeDescription(e.target.value)}
            ></textarea>
            {(descriptionJustification == null ||
              descriptionJustification == '') &&
              showValidationsfieldsJustification && (
                <span className="exon-msg-alert-field">campo obrigatório</span>
              )}
          </div>
        </Popup>
      );
    },

    formCreateItem: ({ data, events }) => {
      return (
        <Popup
          title={'Adicionar Item'}
          okButtonLabel={'Criar'}
          cancelButtonLabel={'Cancelar'}
          exonStyle={true}
          cancelButtonClick={events.onCloseFormPopupClicked}
          okButtonClick={events.onConfirmPopupClicked}
        >
          <Form>
            {Object.keys(data.fields).map((refName, index) => {
              let field = data.fields[refName];
              let attrs = { ...field, forceValidation: data.forceValidation };
              return (
                <div
                  key={index}
                  className={`exon-box-form-field ${
                    field.additionalClass ? field.additionalClass : ''
                  }`}
                >
                  <Field {...attrs} />
                  {index == 0 && field.value && (
                    <div className="exon-label-divider">
                      <label>
                        <label>
                          Dados do item de{' '}
                          <strong> {field.value.label} </strong>
                        </label>
                      </label>
                      <hr />
                    </div>
                  )}
                </div>
              );
            })}
          </Form>
        </Popup>
      );
    },

    rvOffline: ({
      data,
      dataSources,
      selectCompanies,
      selectAmountTravelersOptions,
      events,
    }) => {
      return (
        <Popup
          title={'RV Offline'}
          exonStyle={true}
          cancelButtonClick={events.onCancelRvOfflineClicked}
        >
          <div className="exon-field exon-field-offline">
            <label className="exon-label-field">Requisição para cliente</label>
            <Select
              data={selectCompanies.data}
              externalOptions={selectCompanies.options}
              placeholder={selectCompanies.placeholder}
              required={true}
              showSearch={true}
            ></Select>
          </div>

          <div className="exon-box-fields-row exon-amount-travelers">
            <label className="exon-label-field">
              Quantos passageiros nessa requisição?
            </label>
            <div className="exon-field">
              <Select
                data={selectAmountTravelersOptions.data}
                placeholder={selectAmountTravelersOptions.placeholder}
                externalOptions={selectAmountTravelersOptions.options}
                required={true}
                forceValidation={false}
                disabled={data.agencyAndCompany.company ? false : true}
              />
            </div>
          </div>

          {data.agencyAndCompany.company && data.numberOfTravelers && <hr />}

          {data.agencyAndCompany.company && data.numberOfTravelers && (
            <Checkout
              dataSources={dataSources}
              data={{
                travelers: {
                  adults: data.numberOfTravelers,
                },
                events: {
                  onCancelRvOfflineClick: events.onCancelRvOfflineClicked,
                  onCreateRvOfflineClick: events.onCreateRvOfflineClicked,
                },
              }}
              userId={dataSources.userId}
              onCloseCheckoutClick={events.onCancelRvOffline}
              agencyAndCompany={data.agencyAndCompany}
              isRvOffline={true}
            />
          )}
        </Popup>
      );
    },
  },

  mainLoader: () => {
    return (
      <div>
        <div className="exon-main-loader">
          <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
          <p>
            Por favor aguarde!
            <br />
            Estamos processando a sua solicitação...
          </p>
        </div>
        <div className="modal-backdrop fade in modal-stack exon-adm-modal-backdrop"></div>
      </div>
    );
  },
};

export default TravelRequestsTemplate;
