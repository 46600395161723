'use strict';

import React from 'react';
import Template from './hotel-price-details.template.js';

export default class HotelPriceDetailsComponent extends React.Component {
  render() {
    return <Template {...this.props} />;
  }
}
