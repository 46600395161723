import { gritter } from '@legacy-utils/utils';

const NAO_AUTORIZADO =
  'Não autorizado, tente novamente em instante e caso o erro persista, contate o suporte.';
const TENTE_NOVAMENTE =
  'Tente novamente em instantes e caso o erro persista, contate o suporte.';
const ERRO_SERVIDOR =
  'Ocorreu um erro no servidor, tente novamente em instantes e caso o erro persista, contate o suporte.';
const ERRO_GENERICO =
  'Ocorreu um erro inesperado, tente novamente em instantes e caso o erro persista, contate o suporte.';

export const apiResponse = {
  http: {
    showErrorIfExists: function (result, message) {
      if (result?.status >= 400 && result?.status <= 499) {
        if (result.status === 401) gritter.Error(NAO_AUTORIZADO);
        else gritter.Warning(message || TENTE_NOVAMENTE);
        console.error('Error:', result);
      } else if (result?.status <= 599) {
        gritter.Error(message || ERRO_SERVIDOR);
        console.error('Error:', result);
      } else if (!result?.status && result?.message) {
        message = message ? `${message}, \n${ERRO_SERVIDOR}` : ERRO_SERVIDOR;
        gritter.Error(message);
        console.error('Error:', result);
      }
    },
  },

  successful: {
    showErrorIfExists: function (response, message) {
      if (response?.errors?.length) {
        gritter.ResponseError(response.errors);
        console.error('Response error:', response);
      }

      if (response?.messages?.length) {
        console.log('Response Messages:', response.messages);
        if (!response.successful) {
          gritter.ResponseError(response.messages);
        }
      }
    },
  },
};
