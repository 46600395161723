import PopupFilterComponent from '../popup-filters/popup-filters.component.js';
import OptionsListTemplate from '../options-list/options-list.component.js';
import RangeTimeComponent from '../range-time/range-time.component.js';
import { enums } from '@legacy-utils/enums';

let AirSearchResultsFilterTemplate = ({
  openFilter,
  identifier,
  identifierName,
  filterOptions,
  typeTrip,
  tripIndex,
  //Eventos do pop-up
  onOpenPopupButtonClick,
  onPopupCloseButtonClick,
  onPopupApplyButtonClick,

  //Evento para aplicar todos os filtros
  onApplyFiltersClick,
  onClearFiltersClick,

  //Eventos dos filtros
  onOptionsListBaggageChange,
  onOptionsListHourDepartureChange,
  onOptionsListHourArrivalChange,
  onOptionsListCiaChange,
  onOptionsListAirportDepartureChange,
  onOptionsListAirportArrivalChange,
  onOptionsListStopsChange,
  onOptionsListDurationChange,
}) => {
  return (
    <div className="exon-air-filter">
      <label className="exon-air-filter__title">
        Filtros
        <div>
          <span>{identifierName || ''}</span>
          <span>
            {typeTrip == 2
              ? ' | Trecho ' + (tripIndex + 1)
              : typeTrip == 0
              ? ' | Ida e volta'
              : typeTrip == 1
              ? ' | Ida'
              : ' | Volta'}
          </span>
        </div>
      </label>

      <div className="exon-air-filter-box-items">
        <div className="box-button-filter">
          <button
            className={
              'exon-button-icon' +
              (filterOptions.statusFilters.hour.pendantFilter
                ? ' exon-button-icon-pending'
                : filterOptions.statusFilters.hour.appliedFilter
                ? ' exon-button-icon-active'
                : '')
            }
            onClick={() => onOpenPopupButtonClick(identifier.hour)}
          >
            <span
              className={
                filterOptions.statusFilters.hour.appliedFilter ||
                filterOptions.statusFilters.hour.pendantFilter
                  ? 'icon exon-icon-clock-green'
                  : filterOptions.cia.length <= 1
                  ? 'icon exon-icon-cia-filter-disabled'
                  : 'icon exon-icon-clock'
              }
            ></span>
            Por Hora
          </button>

          <div
            className={
              'exon-air-filter__options-item' +
              (openFilter != identifier.hour ? ' hide' : '')
            }
          >
            <PopupFilterComponent
              title="Por Hora"
              identifier={identifier.hour}
              onPopupCloseButtonClick={onPopupCloseButtonClick}
              onPopupApplyButtonClick={onPopupApplyButtonClick}
            >
              <label className="title-option">Saída</label>
              <RangeTimeComponent
                min={filterOptions.hour.departure.limits.min}
                max={filterOptions.hour.departure.limits.max}
                initialValue={filterOptions.hour.departure.set.min}
                finalValue={filterOptions.hour.departure.set.max}
                step={5}
                onValuesChange={onOptionsListHourDepartureChange}
              />
              <label className="title-option">Chegada</label>
              <RangeTimeComponent
                min={filterOptions.hour.arrival.limits.min}
                max={filterOptions.hour.arrival.limits.max}
                initialValue={filterOptions.hour.arrival.set.min}
                finalValue={filterOptions.hour.arrival.set.max}
                step={5}
                onValuesChange={onOptionsListHourArrivalChange}
              />
            </PopupFilterComponent>
          </div>
        </div>
        <div className="box-button-filter">
          <button
            className={
              'exon-button-icon' +
              (filterOptions.statusFilters.cia.pendantFilter
                ? ' exon-button-icon-pending'
                : filterOptions.statusFilters.cia.appliedFilter &&
                  filterOptions.cia.some((i) => i.applied)
                ? ' exon-button-icon-active'
                : '')
            }
            disabled={filterOptions.cia.length <= 1}
            onClick={() => onOpenPopupButtonClick(identifier.cia)}
          >
            <span
              className={
                filterOptions.statusFilters.cia.appliedFilter ||
                filterOptions.statusFilters.cia.pendantFilter
                  ? 'icon exon-icon-cia-filter-green'
                  : filterOptions.cia.length <= 1
                  ? 'icon exon-icon-cia-filter-disabled'
                  : 'icon exon-icon-cia-filter'
              }
            ></span>
            Por companhia aérea
          </button>
          <div
            className={
              'exon-air-filter__options-item' +
              (openFilter != identifier.cia ? ' hide' : '')
            }
          >
            <PopupFilterComponent
              title="Companhias aéreas"
              identifier={identifier.cia}
              onPopupCloseButtonClick={onPopupCloseButtonClick}
              onPopupApplyButtonClick={onPopupApplyButtonClick}
            >
              <OptionsListTemplate
                options={filterOptions.cia}
                onItemChange={onOptionsListCiaChange}
              />
            </PopupFilterComponent>
          </div>
        </div>
        <div className="box-button-filter">
          <button
            className={
              'exon-button-icon' +
              (filterOptions.statusFilters.airportDeparture.pendantFilter
                ? ' exon-button-icon-pending'
                : filterOptions.statusFilters.airportDeparture.appliedFilter &&
                  filterOptions.airportDeparture.some((i) => i.applied)
                ? ' exon-button-icon-active'
                : '')
            }
            disabled={filterOptions.airportDeparture.length <= 1}
            onClick={() => onOpenPopupButtonClick(identifier.airportDeparture)}
          >
            <span
              className={
                filterOptions.statusFilters.airportDeparture.appliedFilter ||
                filterOptions.statusFilters.airportDeparture.pendantFilter
                  ? 'icon exon-icon-departures-filter-green'
                  : filterOptions.airportDeparture.length == 1
                  ? 'icon exon-icon-departures-filter-disabled'
                  : 'icon exon-icon-departures-filter'
              }
            ></span>
            Por aeroporto de partida
          </button>

          <div
            className={
              'exon-air-filter__options-item' +
              (openFilter != identifier.airportDeparture ? ' hide' : '')
            }
          >
            <PopupFilterComponent
              title="Aeroportos de partida"
              identifier={identifier.airportDeparture}
              onPopupCloseButtonClick={onPopupCloseButtonClick}
              onPopupApplyButtonClick={onPopupApplyButtonClick}
            >
              <OptionsListTemplate
                options={filterOptions.airportDeparture}
                onItemChange={onOptionsListAirportDepartureChange}
              />
            </PopupFilterComponent>
          </div>
        </div>
        <div className="box-button-filter">
          <button
            className={
              'exon-button-icon' +
              (filterOptions.statusFilters.stops.pendantFilter
                ? ' exon-button-icon-pending'
                : filterOptions.statusFilters.stops.appliedFilter &&
                  filterOptions.stops.some((i) => i.applied)
                ? ' exon-button-icon-active'
                : '')
            }
            disabled={filterOptions.stops.length <= 1}
            onClick={() => onOpenPopupButtonClick(identifier.stops)}
          >
            <span
              className={
                filterOptions.statusFilters.stops.appliedFilter ||
                filterOptions.statusFilters.stops.pendantFilter
                  ? 'icon exon-icon-stops-filter-green'
                  : filterOptions.stops.length <= 1
                  ? 'icon exon-icon-stops-filter-disabled'
                  : 'icon exon-icon-stops-filter'
              }
            ></span>
            Por quantidade de paradas
          </button>
          <div
            className={
              'exon-air-filter__options-item' +
              (openFilter != identifier.stops ? ' hide' : '')
            }
          >
            <PopupFilterComponent
              title="Paradas"
              identifier={identifier.stops}
              onPopupCloseButtonClick={onPopupCloseButtonClick}
              onPopupApplyButtonClick={onPopupApplyButtonClick}
            >
              <OptionsListTemplate
                options={filterOptions.stops}
                onItemChange={onOptionsListStopsChange}
              />
            </PopupFilterComponent>
          </div>
        </div>
        <div className="box-button-filter">
          <button
            className={
              'exon-button-icon' +
              (filterOptions.statusFilters.baggage.pendantFilter
                ? ' exon-button-icon-pending'
                : filterOptions.statusFilters.baggage.appliedFilter &&
                  filterOptions.baggage.some((i) => i.applied)
                ? ' exon-button-icon-active'
                : '')
            }
            disabled={filterOptions.baggage.length <= 1}
            onClick={() => onOpenPopupButtonClick(identifier.baggage)}
          >
            <span
              className={
                filterOptions.statusFilters.baggage.appliedFilter ||
                filterOptions.statusFilters.baggage.pendantFilter
                  ? 'icon exon-icon-baggage-filter-green'
                  : filterOptions.baggage.length <= 1
                  ? 'icon exon-icon-baggage-filter-disabled'
                  : 'icon exon-icon-baggage-filter'
              }
            ></span>
            Por bagagem
          </button>
          <div
            className={
              'exon-air-filter__options-item' +
              (openFilter != identifier.baggage ? ' hide' : '')
            }
          >
            <PopupFilterComponent
              title="Bagagens"
              identifier={identifier.baggage}
              onPopupCloseButtonClick={onPopupCloseButtonClick}
              onPopupApplyButtonClick={onPopupApplyButtonClick}
            >
              <OptionsListTemplate
                options={filterOptions.baggage}
                onItemChange={onOptionsListBaggageChange}
              />
            </PopupFilterComponent>
          </div>
        </div>
        <div className="box-button-filter">
          <button
            className={
              'exon-button-icon' +
              (filterOptions.statusFilters.airportArrival.pendantFilter
                ? ' exon-button-icon-pending'
                : filterOptions.statusFilters.airportArrival.appliedFilter &&
                  filterOptions.airportArrival.some((i) => i.applied)
                ? ' exon-button-icon-active'
                : '')
            }
            disabled={filterOptions.airportArrival.length <= 1}
            onClick={() => onOpenPopupButtonClick(identifier.airportArrival)}
          >
            <span
              className={
                filterOptions.statusFilters.airportArrival.appliedFilter ||
                filterOptions.statusFilters.airportArrival.pendantFilter
                  ? 'icon exon-icon-arrival-filter-green'
                  : filterOptions.airportArrival.length <= 1
                  ? 'icon exon-icon-arrival-filter-disabled'
                  : 'icon exon-icon-arrival-filter'
              }
            ></span>
            Por aeroporto de chegada
          </button>
          <div
            className={
              'exon-air-filter__options-item exon-air-filter-arrival-airports' +
              (openFilter != identifier.airportArrival ? ' hide' : '')
            }
          >
            <PopupFilterComponent
              title="Aeroportos de chegada"
              identifier={identifier.airportArrival}
              onPopupCloseButtonClick={onPopupCloseButtonClick}
              onPopupApplyButtonClick={onPopupApplyButtonClick}
            >
              <OptionsListTemplate
                options={filterOptions.airportArrival}
                onItemChange={onOptionsListAirportArrivalChange}
              />
            </PopupFilterComponent>
          </div>
        </div>
        <div className="box-button-filter">
          <button
            className={
              'exon-button-icon' +
              (filterOptions.statusFilters.duration.pendantFilter
                ? ' exon-button-icon-pending'
                : filterOptions.statusFilters.duration.appliedFilter
                ? ' exon-button-icon-active'
                : '')
            }
            onClick={() => onOpenPopupButtonClick(identifier.duration)}
            disabled={!filterOptions.durationGoing}
          >
            <span
              className={
                filterOptions.statusFilters.duration.appliedFilter ||
                filterOptions.statusFilters.duration.pendantFilter
                  ? 'icon exon-icon-duration-filter-green'
                  : filterOptions.airportArrival.length <= 1
                  ? 'icon exon-icon-arrival-filter-disabled'
                  : 'icon exon-icon-duration-filter'
              }
            ></span>
            Por duração de vôo
          </button>
          <div
            className={
              'exon-air-filter__options-item' +
              (openFilter != identifier.duration ? ' hide' : '')
            }
          >
            <PopupFilterComponent
              title="Duração"
              identifier={identifier.duration}
              onPopupCloseButtonClick={onPopupCloseButtonClick}
              onPopupApplyButtonClick={onPopupApplyButtonClick}
            >
              {filterOptions.durationReturn && (
                <label className="exon-label-field">Ida</label>
              )}

              {filterOptions.durationGoing && (
                <RangeTimeComponent
                  min={filterOptions.durationGoing.limits.min}
                  max={filterOptions.durationGoing.limits.max}
                  initialValue={filterOptions.durationGoing.set.min}
                  finalValue={filterOptions.durationGoing.set.max}
                  step={5}
                  onValuesChange={(period) =>
                    onOptionsListDurationChange(
                      period,
                      enums.tripPartTypes.going,
                    )
                  }
                />
              )}

              {filterOptions.durationReturn && (
                <div>
                  <label className="exon-label-field">Volta</label>
                  <RangeTimeComponent
                    min={filterOptions.durationReturn.limits.min}
                    max={filterOptions.durationReturn.limits.max}
                    initialValue={filterOptions.durationReturn.set.min}
                    finalValue={filterOptions.durationReturn.set.max}
                    step={5}
                    onValuesChange={(period) =>
                      onOptionsListDurationChange(
                        period,
                        enums.tripPartTypes.return,
                      )
                    }
                  />
                </div>
              )}
            </PopupFilterComponent>
          </div>
        </div>
        <div className="exon-air-filter-search">
          <div className="exon-box-button-double">
            <button
              className="exon-button exon-button-secondary"
              onClick={onClearFiltersClick}
            >
              <span className="exon-icon exon-icon-dump-unfilled_disabled"></span>
            </button>
            <button
              className="exon-button exon-button-primary"
              onClick={onApplyFiltersClick}
            >
              Aplicar
            </button>
          </div>
        </div>
      </div>

      {/*<button className="exon-button-icon" onClick={() => onOpenPopupButtonClick('policy')} >*/}
      {/*    <span className="icon exon-icon-policy-filter"></span>*/}
      {/*        Pelas políticas da empresa*/}
      {/*</button>*/}
    </div>
  );
};

export default AirSearchResultsFilterTemplate;
