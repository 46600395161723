'use strict';

import React from 'react';
import Template from './vehicle-product-summary.template.js';

export default class VehicleProductSummaryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRentalCompanyImage: true
    }
  }

  onRentalCompanyImageErrored = () => {
    this.setState({showRentalCompanyImage: false});
  }

  render() {
    const templateData = {
      ...this.props,
      ...this.state,
      onRentalCompanyImageErrored: this.onRentalCompanyImageErrored
    };

    return (
      <Template.main {...templateData} />
    );
  }
}
