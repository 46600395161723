import { config } from '@shared/config';
import {
  HROS_ACCESS_TOKEN,
  TRAVEL_FLASH_OS_COMPANY_ID,
  TRAVEL_FLASH_OS_EMPLOYEE_ID,
  USER_SYNC_ASSURED,
} from '@shared/constants/Storage.constants';
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import { HttpService } from '../http.service';
import { CreateLeadDto } from './DTOS/createLead.dto';

const baseUrl = config.expenseGatewayApi.url;

const getHeaders = (): { [key: string]: string } => {
  const authToken = getFromLS(HROS_ACCESS_TOKEN)?.accessToken;
  return { Authorization: `${authToken}` };
};

export const ensureEmployeeSync = async (): Promise<boolean> => {
  const employeeId = getFromLS(TRAVEL_FLASH_OS_EMPLOYEE_ID);
  const selectedCompanyId = getFromLS(TRAVEL_FLASH_OS_COMPANY_ID);
  const userSyncAssuredLSKey = `${USER_SYNC_ASSURED}-${employeeId}-${selectedCompanyId}`;

  let assured = getFromLS(userSyncAssuredLSKey) || null;
  if (assured === null) {
    assured = await HttpService.post<boolean>(
      `${baseUrl}/v1/employees/sync/ensure`,
      getHeaders(),
      {},
    );

    setInLS({
      key: userSyncAssuredLSKey,
      value: assured,
    });
  }

  return assured;
};

export const addLead = async (lead: CreateLeadDto): Promise<void> => {
  await HttpService.post(`${baseUrl}/v1/travel/leads`, getHeaders(), lead);
};

export const getApprovalAdvances = async (): Promise<any[]> => {
  try {
    return await HttpService.get<any[]>(
      `${baseUrl}/v1/approvals/advances`,
      getHeaders(),
    );
  } catch (error) {
    throw error;
  }
};
