'use strict';

/*TODO:
 * - Aplicar traduções
 * - Destino do ultimo trecho preenche a origem do novo trecho selecionado?
 * - Limpeza dos campos não está limpando o campo de data
 */
import React from 'react';
import { componentUtil } from '../../utils/componentUtil.js';
import { listItemsResolver } from '../../utils/resolvers/listItemresolver.js';
import { enums } from '../../utils/enums.js';
import AirSearchEngineTemplate from './air-search-engine.template.js';
import DateComponent from '../date/date.component.js';
import ErrorBoundary from '../common/error-boundary/error-boundary.component.js';
import { gritter } from '@legacy-utils/utils.js';

export default class AirSearchEngineComponent extends React.Component {
  externalOptions = {
    events: {
      onSearchClicked: null,
    },

    dataSources: {
      locations: null,
      airLines: null,
      airClasses: null,
    },

    airlines: null,
  };

  tripTypes = {
    roundTrip: { value: 1, label: 'Ida e Volta' },
    oneWay: { value: 2, label: 'Somente Ida' },
    multiTrips: { value: 3, label: 'Multi-trechos' },
  };

  constructor(props) {
    super(props);

    this.externalOptions = { ...props.externalOptions };

    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.tripsManager, this);
    componentUtil.react.bindMethods(this.airClassesDataSource, this);

    for (let component in this.components) {
      let componentFunctions = this.components[component];
      componentUtil.react.bindMethods(componentFunctions, this);
    }

    this.state = this.getFieldsInitialValue();
    this.state.selectedTripIndex = 0;
    this.state.tripType = this.tripTypes.roundTrip;
    this.state.multiTripsButtonLabels = [null, null];

    this.tripsManager.initialize();
  }

  addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getFieldsInitialValue = function () {
    let beginDate = new Date();

    if (
      window.location.href.includes('qa.expenseon') ||
      window.location.href.includes('localhost') ||
      window.location.href.includes('dev-expenseon') ||
      window.location.href.includes('staging-platform')
    )
      beginDate = this.addDays(new Date(), 60);

    return {
      origin: {},
      destination: {},
      goingStartDate: beginDate,
      returnStartDate: beginDate,
      goingDate: {
        date: null,
        period: null,
      },
      returnDate: {
        date: null,
        period: null,
      },
      airClass: {},
      airLines: [],
      passengers: { adults: 1 },
      onlyDirectFlights: false,
      onlyWithBaggageIncluded: false,
      separeResults: false,
    };
  };

  render() {
    return (
      <ErrorBoundary>
        <AirSearchEngineTemplate.main
          tripTypesProps={this.components.tripTypes.getProps()}
          multiTripsProps={this.components.multiTrip.getProps()}
          originProps={this.components.origin.getProps()}
          destinationProps={this.components.destination.getProps()}
          goingDateProps={this.components.goingDate.getProps()}
          returnDateProps={this.components.returnDate.getProps()}
          airClassesProps={this.components.airClasses.getProps()}
          airLinesProps={this.components.airLines.getProps()}
          passengersProps={this.components.passengers.getProps()}
          directFlightsProps={this.components.directFlights.getProps()}
          baggagesProps={this.components.baggagesProps.getProps()}
          separeResultsProps={this.components.separeResults.getProps()}
          onSearchClicked={this.events.onSearchClicked}
          onClearFieldsClicked={this.events.onClearFieldsClicked}
        />
      </ErrorBoundary>
    );
  }

  events = {
    onSearchClicked: function (ev) {
      let mockData = false;
      if (
        window.event.ctrlKey &&
        window.location.href.indexOf('localhost') != -1
      )
        mockData = true;

      let data = {};
      if (mockData) {
        data = this.getData(mockData);
        data.mock = mockData;
      } else {
        data = this.getData();
      }

      if (data.mock || this.validate(data)) {
        if (this.externalOptions.events.onSearchClicked)
          this.externalOptions.events.onSearchClicked(data);
      }
    },
    onClearFieldsClicked: function (ev) {
      this.tripsManager.clearSelected();

      this.setState(this.getFieldsInitialValue());
    },
  };

  validate = function (data) {
    let tripsResults = [];

    let trips =
      data.tripType.value == this.tripTypes.oneWay.value
        ? [data.trips[0]]
        : data.trips;

    for (let trip of trips) {
      if (
        !trip.origin ||
        !trip.origin.iata ||
        !trip.destination ||
        !trip.destination.iata ||
        !trip.goingDate ||
        !trip.goingDate.date
      ) {
        tripsResults.push(false);
        break;
      }

      tripsResults.push(true);
    }

    if (tripsResults.indexOf(false) == -1) {
      //trechos válidos
      if (!data.passengers && data.passengers.adults <= 0) {
        gritter.Error('A viagem deve possuir ao menos 1 adulto.');
        return false;
      }
    } else {
      for (let i = 0; i < tripsResults.length; i++) {
        const tripResultSuccess = tripsResults[i];
        if (!tripResultSuccess) {
          let tripLabelSufix = '';
          if (data.tripType.value == this.tripTypes.multiTrips.value) {
            tripLabelSufix =
              ' do trecho ' + (this.tripsManager.items[i].label || i + 1);
          }

          gritter.Error('Revise o preenchimento dos campos para realizar a busca' + tripLabelSufix);

          break;
        }
      }

      return false;
    }

    return true;
  };

  getData = function (mock) {
    return {
      tripType: this.state.tripType,
      trips: this.getTrips(mock),
      passengers: this.state.passengers,
      directFlights: this.state.onlyDirectFlights,
      baggageIncluded: this.state.onlyWithBaggageIncluded,
      separeResults: this.state.separeResults,
      sendPriceMatrix: false,
    };
  };

  getTrips = function (mock) {
    let trips = this.tripsManager.items.map((i) => i.memory);
    let parsedTrips = [];

    if (mock) {
      let mockedTripObj = this.getFinalTrip({
        origin: {
          id: 15125,
          type: 'airport',
          name: 'São Paulo (CGH) - Congonhas',
          iata: 'CGH',
        },
        destination: {
          id: 15125,
          type: 'airport',
          name: 'São Paulo (CGH) - Congonhas',
          iata: 'CGH',
        },
        goingDate: { date: { day: 29, month: 7, year: 2021 }, period: null },
      });
      return [mockedTripObj, mockedTripObj];
    }

    switch (this.state.tripType.value) {
      case this.tripTypes.oneWay.value:
        //going
        parsedTrips = [this.getFinalTrip(trips[0], false)];

        break;
      case this.tripTypes.roundTrip.value:
        //return
        parsedTrips = [
          this.getFinalTrip(trips[0], false),
          this.getFinalTrip(trips[0], true),
        ];

        break;
      case this.tripTypes.multiTrips.value:
        //multi
        for (let i = 0; i < trips.length; i++) {
          parsedTrips.push(this.getFinalTrip(trips[i], false));
        }

        break;
    }

    return parsedTrips;
  };

  getFinalTrip = function (trip, isReturnTrip, mock) {
    return {
      origin: isReturnTrip ? trip.destination : trip.origin,
      destination: isReturnTrip ? trip.origin : trip.destination,
      goingDate: isReturnTrip ? trip.returnDate : trip.goingDate,
      airClass: trip.airClass,
      airLines: trip.airLines,
    };
  };

  airClassesDataSource = {
    load: function (loadOptions) {
      return Promise.resolve(
        listItemsResolver.resolveMapEnum(enums.airClassesMap),
      );
    },
    events: {
      beforeLoad: function (loadOptions) {},
      afterLoad: function (items) {},
    },
  };

  components = {
    tripTypes: {
      getProps: function () {
        let _this = this;
        return {
          data: this.state.tripType,
          options: {
            items: [
              this.tripTypes.roundTrip,
              this.tripTypes.oneWay,
              this.tripTypes.multiTrips,
            ],
            events: {
              onChanged: function (item) {
                _this.setState({ tripType: item });

                if (item != _this.tripTypes.multiTrips)
                  _this.tripsManager.select(0);

                if (item.value != 1) _this.setState({ separeResults: false });
              },
            },
          },
        };
      },
    },
    multiTrip: {
      getProps: function () {
        let _this = this;

        return {
          name: 'tripTypes',
          defaultButtonTextPrefix: 'Trecho',
          labels: this.state.multiTripsButtonLabels,
          value: this.state.selectedTripIndex,
          options: {
            events: {
              onAdditioned: function () {
                _this.tripsManager.add();
              },
              onDeleted: function (index) {
                _this.tripsManager.remove(index);
              },
              onSelected: function (index) {
                _this.tripsManager.select(index);
              },
            },
          },
        };
      },
    },
    origin: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.origin,
          placeholder: 'Insira uma origem',
          options: {
            events: {
              onInputChanged: (inputText) => {
                let data = _this.state.origin || {};
                data.name = inputText;
                _this.setState({ origin: data });
              },
              onSelected: (data) => {
                let obj = { origin: data };
                _this.tripsManager.includeData(obj);
                _this.setState(obj);
              },
            },
            dataSource: _this.externalOptions.dataSources.locations,
          },
        };
      },
    },
    destination: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.destination,
          placeholder: 'Insira um destino',
          options: {
            events: {
              onInputChanged: (inputText) => {
                let data = _this.state.destination || {};
                data.name = inputText;
                _this.setState({ destination: data });
              },
              onSelected: (data) => {
                let obj = { destination: data };
                _this.tripsManager.includeData(obj);
                _this.setState(obj);
              },
            },
            dataSource: _this.externalOptions.dataSources.locations,
          },
        };
      },
    },
    goingDate: {
      getProps: function () {
        let _this = this;

        if (!_this.state.goingDate)
          _this.state.goingDate = { date: null, period: null };

        if (!_this.state.goingDate.date)
          _this.state.goingDate.date = _this.state.goingStartDate;

        return {
          data: _this.state.goingDate,
          startDate: _this.state.goingStartDate,
          placeholder: 'Selecione',
          labels: {
            date: 'Ida',
            period: 'Período',
          },
          options: {
            events: {
              onSelected: function (data) {
                let obj = { goingDate: data };

                if (data && data.date) {
                  obj.returnStartDate = DateComponent.convertDateObjectToDate(
                    data.date,
                  );
                } else {
                  obj.returnStartDate = new Date();
                }

                _this.tripsManager.includeData(obj);
                _this.setState(obj);
              },
            },
          },
        };
      },
    },
    returnDate: {
      getProps: function () {
        let _this = this;

        if (!_this.state.returnDate)
          _this.state.returnDate = { date: null, period: null };

        if (
          !_this.state.returnDate.date ||
          DateComponent.convertDateObjectToDate(_this.state.returnDate.date) <
            _this.state.returnStartDate
        )
          _this.state.returnDate.date = _this.state.returnStartDate;

        return {
          data: _this.state.returnDate,
          startDate: _this.state.returnStartDate,
          placeholder: 'Selecione',
          labels: {
            date: 'Volta',
            period: 'Período',
          },
          options: {
            events: {
              onSelected: function (data) {
                let obj = { returnDate: data };
                _this.tripsManager.includeData(obj);
                _this.setState(obj);
              },
            },
          },
        };
      },
    },
    airClasses: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.airClass,
          placeholder: 'Selecione',
          options: {
            events: {
              onSelected: function (selectedItem) {
                let obj = { airClass: selectedItem };
                _this.tripsManager.includeData(obj);
                _this.setState(obj);
              },
            },
            dataSource: _this.airClassesDataSource,
          },
        };
      },
    },
    airLines: {
      getProps: function () {
        let _this = this;

        return {
          data: _this.state.airLines,
          placeholder: 'Selecione',
          isMultiple: true,
          options: {
            events: {
              onSelected: function (selectedItems) {
                let obj = { airLines: selectedItems };
                _this.tripsManager.includeData(obj);
                _this.setState(obj);
              },
            },
            // items: _this.props.externalOptions.airlines.sort((a, b) => {
            //   if (a.label > b.label) {
            //     return 1;
            //   }
            //   if (a.label < b.label) {
            //     return -1;
            //   }
            //   return 0;
            // }),
            items: _this.props.externalOptions.airlines,
          },
        };
      },
    },
    passengers: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.passengers,
          labels: {
            passageiros: 'Passageiros',
            adultos: 'Adultos',
            adicionarCriancas: 'Adicionar crianças',
            criancas: 'Criancas',
            bebes: 'Bebês',
          },
          options: {
            events: {
              onSelected: function (data) {
                _this.setState({ passengers: data });
              },
            },
          },
        };
      },
    },
    directFlights: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.onlyDirectFlights,
          placeholder: 'Apenas voos diretos?',
          options: {
            events: {
              onChanged: (checked) => {
                _this.setState({ onlyDirectFlights: checked });
              },
            },
          },
        };
      },
    },
    baggagesProps: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.onlyWithBaggageIncluded,
          placeholder: 'Bagagem inclusa?',
          options: {
            events: {
              onChanged: (checked) => {
                _this.setState({ onlyWithBaggageIncluded: checked });
              },
            },
          },
        };
      },
    },
    separeResults: {
      getProps: function () {
        let _this = this;

        return {
          data: this.state.separeResults,
          placeholder: 'Selecionar ida e volta separados',
          options: {
            events: {
              onChanged: (checked) => {
                _this.setState({ separeResults: checked });
              },
            },
          },
        };
      },
    },
  };

  //Gerenciador de memória para os N trechos configurados no motor (ida = 1 trecho, ida e volta = 2 trechos e multi-trechos = N trechos)
  tripsManager = {
    selectedIndex: 0,
    items: [],

    emptyItem: {
      get: function () {
        return {
          label: null,
          memory: {},
        };
      },
    },

    initialize: function () {
      //Inicializa com 2 elementos vazios considerando que será carregado 2 trechos por padrão
      this.tripsManager.items = [
        this.tripsManager.emptyItem.get(),
        this.tripsManager.emptyItem.get(),
      ];
    },

    add: function () {
      this.tripsManager.items.push(this.tripsManager.emptyItem.get());
      this.tripsManager.select(this.tripsManager.items.length - 1);
    },
    remove: function (index) {
      this.tripsManager.items.splice(index, 1);

      let itensMaxIndex = this.tripsManager.items.length - 1;
      if (this.tripsManager.selectedIndex > itensMaxIndex) {
        this.tripsManager.select(itensMaxIndex);
      }
    },
    select: function (index) {
      this.tripsManager.selectedIndex = index;
      let previous = null;
      if (index > 0) {
        previous = this.tripsManager.items[index - 1].memory;
      }
      this.tripsManager.fillTripComponentsData(
        this.tripsManager.items[this.tripsManager.selectedIndex].memory,
        previous,
      );

      this.setState({ selectedTripIndex: index });
    },

    clearSelected: function () {
      this.tripsManager.items[this.tripsManager.selectedIndex] =
        this.tripsManager.emptyItem.get();
      this.setCurrentTripButtonLabel(null);
    },

    includeData: function (data) {
      let memoryItem = this.tripsManager.items[this.tripsManager.selectedIndex];

      memoryItem.memory = { ...memoryItem.memory, ...data };
      this.tripsManager.setCurrentItemLabel();

      this.setCurrentTripButtonLabel(memoryItem.label);
    },
    setCurrentItemLabel: function () {
      let memoryItem = this.tripsManager.items[this.tripsManager.selectedIndex];
      let memoryLabelParts = [];
      let memory = memoryItem.memory;

      if (memory) {
        if (memory.origin) memoryLabelParts.push(memory.origin.iata);

        if (memory.destination) memoryLabelParts.push(memory.destination.iata);
      }
      if (memoryLabelParts.length)
        memoryItem.label = memoryLabelParts.join(' > ');
      else memoryItem.label = null;
    },

    fillTripComponentsData: function (data, previous) {
      if (data) {
        this.setState({
          origin: data.origin
            ? data.origin
            : previous
            ? previous.destination
            : data.origin,
          destination: data.destination,
          goingDate: data.goingDate,
          returnDate: data.returnDate,
          airLines: data.airLines,
          airClass: data.airClass,
        });

        if (previous && !data.origin) {
          const obj = {
            origin: previous.destination,
            destination: data.destination,
            goingDate: data.goingDate,
            returnDate: data.returnDate,
            airLines: data.airLines,
            airClass: data.airClass,
          };
          this.tripsManager.includeData(obj);
        }
      }
    },
  };

  setCurrentTripButtonLabel = (label) => {
    let labels = this.state.multiTripsButtonLabels;
    labels[this.tripsManager.selectedIndex] = label;
    this.setState({ multiTripsButtonLabels: labels });
  };
}
