import { Button, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Cards, { Focused } from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import DisplayErrorFormValidation from '@components/RequestTravel/DisplayErrorFormValidation';

const creditCardSchema = Yup.object().shape({
  number: Yup.string()
    .required('Número do cartão é obrigatório')
    .matches(/^\d{16,19}$/, 'Número do cartão deve ter entre 16 e 19 dígitos')
    .transform((value) => value.replace(/\s+/g, '')),
  name: Yup.string().required('Nome no cartão é obrigatório'),
  expiry: Yup.string()
    .required('Validade é obrigatória')
    .matches(
      /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
      'Formato de validade deve ser MM/AA',
    ),
  cvc: Yup.string()
    .required('CVC é obrigatório')
    .matches(/^\d{3,4}$/, 'CVC deve ter 3 ou 4 dígitos'),
});

const expiryMask = {
  mask: 'MM/YY',
  definitions: {
    M: /[0-9]/,
    Y: /[0-9]/,
  },
  format: (str) => {
    const [month, year] = str.split('/');
    return [month.padStart(2, '0'), year.padStart(2, '0')].join('/');
  },
  parse: (str) => str.replace(/[^\d]/g, ''),
};

const numberCreditCardMask = {
  mask: '0000 0000 0000 0000 000',
  definitions: {
    '0': /[0-9]/,
  },
};

export function CreditCard({ onSaveCard }) {
  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      number: '',
      name: '',
      expiry: '',
      cvc: '',
    },
    resolver: yupResolver(creditCardSchema),
  });

  const [cardType, setCardType] = useState('');
  const [focused, setFocused] = useState<Focused | undefined>(undefined);

  const handleCallback = ({ issuer }) => {
    setCardType(issuer);
    const currentCVC = watch('cvc');
    if (
      (issuer === 'amex' || issuer === 'american-express') &&
      currentCVC.length > 4
    ) {
      setValue('cvc', currentCVC.substring(0, 4));
    } else if (
      issuer !== 'amex' &&
      issuer !== 'american-express' &&
      currentCVC.length > 3
    ) {
      setValue('cvc', currentCVC.substring(0, 3));
    }
  };

  const cvcMask = {
    mask:
      cardType === 'amex' || cardType === 'american-express' ? '0000' : '000',
    definitions: {
      '0': /[0-9]/,
    },
  };

  const onSubmit = (data) => {
    const updatedData = { ...data, cardType };
    console.log('onSubmit', updatedData);
    onSaveCard(updatedData);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}
          >
            <div style={{ width: '100%' }}>
              <Controller
                name="number"
                control={control}
                render={({ field }) => (
                  <TextField
                    style={{ width: '100%' }}
                    imaskProps={numberCreditCardMask}
                    {...field}
                    label="Número do Cartão"
                    onFocus={() => setFocused('number')}
                    onBlur={() => setFocused(undefined)}
                    helperText={
                      <DisplayErrorFormValidation
                        errors={errors}
                        fieldName="number"
                      />
                    }
                  />
                )}
              />
            </div>
            <div style={{ width: '100%' }}>
              <TextField
                style={{ width: '100%' }}
                {...register('name')}
                label="Nome no Cartão"
                onFocus={() => setFocused('name')}
                onBlur={() => setFocused(undefined)}
                helperText={
                  <DisplayErrorFormValidation
                    errors={errors}
                    fieldName="name"
                  />
                }
              />
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <div style={{ width: '50%' }}>
                <Controller
                  name="expiry"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      style={{ width: '100%' }}
                      imaskProps={expiryMask}
                      {...field}
                      label="Validade"
                      onFocus={() => setFocused('expiry')}
                      onBlur={() => setFocused(undefined)}
                      helperText={
                        <DisplayErrorFormValidation
                          errors={errors}
                          fieldName="expiry"
                        />
                      }
                    />
                  )}
                />
              </div>
              <div style={{ width: '50%' }}>
                <Controller
                  name="cvc"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      style={{ width: '100%' }}
                      imaskProps={cvcMask}
                      {...field}
                      label="CVC"
                      onFocus={() => setFocused('cvc')}
                      onBlur={() => setFocused(undefined)}
                      helperText={
                        <DisplayErrorFormValidation
                          errors={errors}
                          fieldName="cvc"
                        />
                      }
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
        <div>
          <Cards
            number={watch('number')}
            name={watch('name')}
            expiry={watch('expiry')}
            cvc={watch('cvc')}
            focused={focused}
            callback={handleCallback}
          />
        </div>
      </div>
      <div style={{ width: '100%', paddingTop: '15px' }}>
        <Button
          size={'large'}
          variant={'primary'}
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar Cartão
        </Button>
      </div>
    </div>
  );
}
