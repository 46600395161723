import Slide from './slide/slide.component.js';
import Icon from '../../common/icon/icon.component.js';

let Template = ({ props, args, events }) => {
  return (
    <div className="exon-slider">
      {props.images.map((img, index) => {
        return (
          <Slide key={index} image={img} active={index === args.activeSlide} />
        );
      })}

      <button
        className="exon-slider-button exon-slider-button-prev"
        onClick={() => events.onPrevSlideClick()}
      >
        <Icon icon={'exon-icon-left-unfilled_enabled'} />
      </button>
      <button
        className="exon-slider-button exon-slider-button-next"
        onClick={() => events.onNextSlideClick()}
      >
        <Icon icon={'exon-icon-right-unfilled_enabled'} />
      </button>
    </div>
  );
};

export default Template;
