import { SlideItem, Slider } from '../../components/slider';
import * as SC from '../../styled';
interface SolutionsProps {
  title: string;
  solutions: SlideItem[];
}
export const Solutions = ({ title, solutions }: SolutionsProps) => {
  return (
    <SC.ContentContainer>
      <Slider
        items={solutions}
        showDots={false}
        buttonsPosition="top"
        title={title}
      />
    </SC.ContentContainer>
  );
};
