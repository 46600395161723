'use strict';

import React, { useEffect } from 'react';
import TravelRequestsTemplate from './travel-requests.template.js';
import ErrorBoundary from '../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil.js';
import { enums, travelEnums } from '@legacy-utils/enums';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import { utils, gritter } from '@legacy-utils/utils';
import { apiResponse } from '@legacy-utils/apiResponse';
import { statusFlowResolver } from '@legacy-utils/resolvers/statusFlowResolver.js';
import { paymentResolver } from '@legacy-utils/resolvers/paymentResolver';
import { serviceRequestResolver } from '@legacy-utils/resolvers/serviceRequestResolver';
import { compressorResolver } from '@legacy-utils/resolvers/compressorResolver';
import { Loading } from '@components/Loading/Loading';
import { getVehicleBookingFullName } from '../../utils/resolvers/vehicleResolver.ts';

//#region HOC
import { useTravelItemStore } from '../../../store/RequestTravelItem.store';
import { useTravelStore } from '../../../store/RequestTravel.store';
export default function TravelRequestsComponent(props) {
  const [updateContextId] = useTravelStore((state) => [state.updateContextId]);

  const [updateID, setModalOpen] = useTravelItemStore((state) => [
    state.updateID,
    state.setModalOpen,
  ]);

  useEffect(() => {
    updateID(props.externalOptions.requestId);
    updateContextId(props.externalOptions.rootContextId);
  }, [props.externalOptions.requestId, props.externalOptions.rootContextId]);

  return (
    <_TravelRequestsComponent
      {...props}
      setModalItemOpen={setModalOpen}
      setSelectedRequest={(serviceRequestId, contextId) => {
        updateID(serviceRequestId);
        updateContextId(contextId);}} />
  );
}
//#endregion

class _TravelRequestsComponent extends React.Component {
  externalOptions = {
    events: {
      onSelected: null,
      onDeleted: null,
    },
    dataSources: {
      listTravelRequest: null,
      counterTravelRequest: null,
    },
    isApproval: false,
    isAgency: false,
    userTravelProfile: null,
    currentUserId: null,
    userData: null,
    requestId: null,
    rootContextId: null,
    modalItemOpen: null,
  };

  constructor(props) {
    super(props);

    this.externalOptions = {
      ...this.externalOptions,
      ...props.externalOptions,
    }; //$.extend(true, this.externalOptions, props.externalOptions);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);
    componentUtil.react.bindMethods(this.requestsManager, this);
    componentUtil.react.bindMethods(this.selects.selectCompanies, this);
    componentUtil.react.bindMethods(this.selects.selectTravelers, this);
    componentUtil.react.bindMethods(this.selects.selectItemsType, this);

    this.state = {
      loading: false,
      requests: {},
      identifierActiveTab: '',
      identifierActiveBooking: '',
      notifier: {
        show: false,
        fieldsData: {},
      },
      historicData: { show: false },
      requestHistoriesData: { show: false },
      popupFeedbackData: {
        show: false,
        events: this.events.feedbacks,
      },
      popupDecisionData: {
        show: false,
        description: null,
        events: {
          confirm: null,
          cancel: null,
        },
      },
      mainLoader: false,
      selectedProductId: null,
      userId: this.externalOptions.currentUserId,
      userTravelProfile: this.externalOptions.userTravelProfile,
      companies: [],
      companySelected: null,
      rvOfflineData: {
        show: false,
        events: this.events.rvOffline,
        numberOfTravelers: null,
        dataSources: {
          licenceFeatures: this.externalOptions.dataSources.licenceFeatures,
          nationality: this.externalOptions.dataSources.nationality,
          companySettings: this.externalOptions.dataSources.companySettings,
          justificationsList:
            this.externalOptions.dataSources.justificationsList, // TODO: Verificar a necessidade de enviar o contextId
          travelers: this.dataSources.rvOffline.travelers,
          costCenter: this.dataSources.rvOffline.costCenter,
          paymentForms: this.dataSources.rvOffline.paymentForms,
          customField: this.dataSources.rvOffline.customField,
          travelReason: this.dataSources.rvOffline.travelReason,
          requester: this.dataSources.rvOffline.requester,
          serviceRequest: this.externalOptions.dataSources.serviceRequest,
        },
        userId: this.externalOptions.currentUserId,
        agencyAndCompany: {
          agency: {
            isAgency: this.externalOptions.isAgency,
            travelProfile: this.externalOptions.userTravelProfile,
          },
          company: null,
        },
      },

      createItemsData: {
        show: false,
        events: this.events.rvOffline,
        itemTypeSelected: null,
        forceValidation: false,
      },

      formCreateItem: {
        show: false,
        events: this.events.form.formCreateItem.popup,
        fields: this.formManager.getFields(enums.actionsForm.createItem),
        forceValidation: false,
      },
      airLines: [],

      popupRecuseJustificationData: {
        show: false,

        events: {
          confirm: null,
          cancel: null,
        },
      },
      descriptionJustification: null,
      showValidationsfieldsJustification: false,
      isAgency: this.externalOptions.isAgency,
      travelersList: [],
      currentUserData: this.externalOptions.userData,
      mainTabIdentifier: null,
    };
  }

  componentDidMount() {
    this.configureMainTab();
    this.dataSources.airLines.load();
    this.dataSources.companies.load();

    if (this.externalOptions.requestId)
      this.dataSources.serviceRequest.callLoad({
        id: this.externalOptions.requestId,
        contextId: this.externalOptions.rootContextId,
      });
  }

  componentDidUpdate() {
    if (
      this.props.externalOptions.userData.company.contextId !=
      this.state.currentUserData.company.contextId
    ) {
      this.requestsManager.reset();
      this.setState({
        currentUserData: this.props.externalOptions.userData,
      });
    }
  }

  render() {
    let requests = this.state.requests;
    let identifierSRActive = this.state.identifierActiveTab;
    let bookings =
      requests &&
      requests[identifierSRActive] &&
      requests[identifierSRActive].items
        ? requests[identifierSRActive].items
        : {};

    return (
      <ErrorBoundary>
        {this.state.loading && <Loading />}
        <TravelRequestsTemplate.main
          requests={requests}
          bookings={bookings}
          identifierActiveTab={identifierSRActive}
          identifierActiveBooking={this.state.identifierActiveBooking}
          notifierProps={this.notifier.getProps()}
          historicData={this.state.historicData}
          requestHistoriesData={this.state.requestHistoriesData}
          popupFeedbackData={this.state.popupFeedbackData}
          popupDecisionData={this.state.popupDecisionData}
          mainLoader={this.state.mainLoader}
          userTravelProfile={this.state.userTravelProfile}
          isApproval={this.externalOptions.isApproval}
          isAgency={this.state.isAgency}
          companies={this.state.companies}
          currentUserData={{
            userId: this.props.externalOptions.currentUserId,
            travelProfile: this.props.externalOptions.userTravelProfile,
            company: this.props.externalOptions.userData.company,
          }}
          approvalMethods={{
            approveInBatch: this.requestsManager.approveInBatch,
            reproveInBatch: this.requestsManager.reproveInBatch,
          }}
          airLines={this.state.airLines}
          descriptionJustification={this.state.descriptionJustification}
          popupRecuseJustificationData={this.state.popupRecuseJustificationData}
          showValidationsfieldsJustification={
            this.state.showValidationsfieldsJustification
          }
          //RV Offline
          rvOfflineData={this.state.rvOfflineData}
          selectCompanies={this.selects.selectCompanies.getProps()}
          selectAmountTravelersOptions={this.selects.selectTravelers.getProps()}
          selectItemsTypeOptions={this.selects.selectItemsType.getProps()}
          createItemsData={this.state.createItemsData}
          travelersList={this.state.travelersList}
          formCreateItem={this.state.formCreateItem}
          //Events
          tabEvents={this.events.tabs}
          requetsEvents={this.events.requets}
          navigationEvents={this.events.navigationRequest}
          notifierEvents={this.events.notifier}
          historicEvents={this.events.historic}
          requestHistoriesEvents={this.events.requestHistories}
          bookingsEvents={this.events.bookings}
          decisionEvents={this.events.popup.decision}
          justificationEvents={this.events.popup.justification}
          dataSources={{
            ...this.externalOptions.dataSources,
            ...this.dataSources.overrides,
          }}
          rvOfflineEvents={this.events.rvOffline}
          monitoringDataSurces={this.externalOptions.dataSources.monitoring}
          isMonitoring={this.externalOptions.monitoring}
        />
      </ErrorBoundary>
    );
  }

  getSelectedItem() {
    let item;
    if (
      this.state.requests[this.state.identifierActiveTab].managerNavigation
        .itemActive
    )
      item = this.state.requests[
        this.state.identifierActiveTab
      ].itemsSummary.find(
        (item) => item.identifier == this.state.identifierActiveBooking,
      );

    return item;
  }

  events = {
    tabs: {
      onSelectTabClicked: (identifier) => {
        let itemActive =
          this.state.requests[identifier].managerNavigation.itemActive;
        this.setState({
          identifierActiveTab: identifier,
          identifierActiveBooking: itemActive ? itemActive : null,
        });
      },

      onDeleteTabClicked: (identifier) => {
        this.requestsManager.delete(identifier);
      },
    },

    onOpenRequestClicked: (requestNumber, rootContextId) => {
      let isAgency = this.state.isAgency;

      this.dataSources.serviceRequest.callLoad({
        id: requestNumber,
        contextId: isAgency && rootContextId ? rootContextId : null,
      });
    },

    rvOffline: {
      onOpenCreateRvOfflineClicked: () => {
        this.setState({
          rvOfflineData: {
            ...this.state.rvOfflineData,
            show: true,
          },
        });
      },

      onCancelRvOfflineClicked: () => {
        let rvOfflineData = this.state.rvOfflineData;
        rvOfflineData.agencyAndCompany.company = null;
        rvOfflineData.show = false;
        rvOfflineData.numberOfTravelers = null;

        this.setState({ rvOfflineData, companySelected: 0 });
      },

      onCreateRvOfflineClicked: (serviceRequest) => {
        this.dataSources.rvOffline.serviceRequest.add(serviceRequest);
        this.events.rvOffline.onCancelRvOfflineClicked();
      },

      onSelectedCompanyClicked: (companySelected) => {
        this.setState({ companySelected });
      },

      onOpenSelectItemTypeClicked: () => {
        this.setState({
          createItemsData: {
            ...this.state.createItemsData,
            show: true,
          },
        });
      },

      onCloseCreateItemsClicked: () => {
        this.setState({
          createItemsData: {
            ...this.state.createItemsData,
            show: false,
            itemTypeSelected: null,
          },
        });
      },

      onConfirmCreateItemClicked: () => {
        this.events.rvOffline.onCloseCreateItemsClicked();
      },

      onEditAirSegmentsClicked: (data, tabIdentifier, bookingIdentifier) => {
        let requests = this.state.requests;
        let item = requests[tabIdentifier].items[bookingIdentifier];
        item.airTrips = data;

        this.dataSources.serviceRequestItem.callEdit({
          serviceRequestItem: item,
          contextId: item.rootContextId,
          productType: enums.products.type.air,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },

      onEditAirTariffsAndFessClicked: (
        data,
        tabIdentifier,
        bookingIdentifier,
      ) => {
        let requests = this.state.requests;
        let item = requests[tabIdentifier].items[bookingIdentifier];
        item.fares = data;

        item.fares.forEach((fare) => {
          fare.travelerValue.travellersCount = item.travelers.length;
        });

        this.dataSources.serviceRequestItem.callEdit({
          serviceRequestItem: item,
          contextId: item.rootContextId,
          productType: enums.products.type.air,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },

      onAddAirTicketClicked: (data, tabIdentifier, bookingIdentifier) => {
        let requests = this.state.requests;
        let request = requests[tabIdentifier];
        let item = request.items[bookingIdentifier];

        data.serviceRequestId = request.id;
        data.traveler = request.travelers.find(
          (traveler) => traveler.id == data.traveler.value,
        );

        this.dataSources.serviceRequestItem.callAdd({
          sendData: data,
          rootContextId: request.rootContextId,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },

      onEditAirTicketClicked: (data, tabIdentifier, bookingIdentifier) => {
        let requests = this.state.requests;
        let request = requests[tabIdentifier];
        let item = request.items[bookingIdentifier];

        data.serviceRequestId = request.id;
        data.traveler = request.travelers.find(
          (traveler) => traveler.id == data.traveler.value,
        );

        this.dataSources.serviceRequestItem.callEdit({
          serviceRequestItem: data,
          rootContextId: request.rootContextId,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },

      onEditHotelSegmentsClicked: (data, tabIdentifier, bookingIdentifier) => {
        let requests = this.state.requests;
        let item = requests[tabIdentifier].items[bookingIdentifier];
        item.hotel = data.hotel;
        data.saleType ? (item.saleType = data.saleType) : null;
        data.bookingLocator
          ? (item.bookingLocator = data.bookingLocator)
          : null;

        this.dataSources.serviceRequestItem.callEdit({
          serviceRequestItem: item,
          contextId: item.rootContextId,
          productType: enums.products.type.hotel,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },

      onEditHotelTariffsAndFessClicked: (
        data,
        tabIdentifier,
        bookingIdentifier,
      ) => {
        let requests = this.state.requests;
        let item = requests[tabIdentifier].items[bookingIdentifier];
        item.hotel = data.hotel;

        this.dataSources.serviceRequestItem.callEdit({
          serviceRequestItem: item,
          contextId: data.contextId,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },

      onEditVehicleFaresAndFessClicked: (
        data,
        tabIdentifier,
        bookingIdentifier,
      ) => {
        let requests = this.state.requests;
        let item = requests[tabIdentifier].items[bookingIdentifier];
        item.vehicle = data.vehicle;

        this.dataSources.serviceRequestItem.callEdit({
          serviceRequestItem: item,
          contextId: data.contextId,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },

      onConfirmDataClicked: (data, tabIdentifier, bookingIdentifier) => {
        let requests = this.state.requests;
        let item = requests[tabIdentifier].items[bookingIdentifier];

        if (data.expiration) item.expiration = data.expiration;

        if (data.confirmedBy) item.confirmedBy = data.confirmedBy;

        this.dataSources.serviceRequestItem.callEdit({
          serviceRequestItem: item,
          contextId: item.rootContextId,
          productType: enums.products.type.air,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },

      onConfirmIssuanceClicked: (locator, tabIdentifier, bookingIdentifier) => {
        let requests = this.state.requests;
        let item = requests[tabIdentifier].items[bookingIdentifier];

        if (locator) {
          item.sourceBookingLocator = locator;
          item.bookingLocator = locator;
        }

        this.dataSources.serviceRequestItem.callEdit({
          serviceRequestItem: item,
          contextId: item.rootContextId,
          productType: enums.products.type.air,
          identifier: bookingIdentifier,
          identifierSR: tabIdentifier,
        });

        requests[tabIdentifier].items[bookingIdentifier].loading = true;

        this.setState({
          requests,
        });
      },
    },

    form: {
      formCreateItem: {
        popup: {
          onOpenFormPopupClicked: () => {
            this.setState({
              formCreateItem: {
                ...this.state.formCreateItem,
                show: true,
                forceValidation: false,
              },
            });
          },

          onCloseFormPopupClicked: () => {
            let formCreateItem = this.state.formCreateItem;

            this.formManager.clearFieldsFormCreateItem(formCreateItem);

            this.setState({
              formCreateItem: {
                ...formCreateItem,
                show: false,
                forceValidation: false,
              },
            });
          },

          onConfirmPopupClicked: () => {
            let formCreateItem = this.state.formCreateItem;
            let travelType = formCreateItem.fields.selectItemType.value;
            let identifierSR = this.state.identifierActiveTab;
            let currentSR = this.state.requests[identifierSR];
            let identifierBooking = this.generateIdentifier();

            if (this.formManager.fieldsValidateFormCreateItem()) {
              this.bookingManager.add({
                id: identifierBooking,
                identifier: identifierBooking,
                identifierSR: identifierSR,
                contextId: currentSR.contextId,
                bookingType: enums.products.type[travelType.value],
                rootContextId: currentSR.rootContextId,
                type:
                  travelType.value == enums.products.type.air
                    ? enums.products.namedType.airBooking
                    : enums.products.namedType.hotelBooking,
                bookingIndex: 0,
                sendData: this.prepareDataItemAddSend(
                  { ...formCreateItem.fields },
                  currentSR,
                ),
              });
              this.events.form.formCreateItem.popup.onCloseFormPopupClicked();
            }
          },
        },
        fields: {
          onLocationChanged: (refName, inputText) => {
            let formCreateItem = this.state.formCreateItem;
            formCreateItem.fields[refName].value.name = inputText;

            this.setState({ formCreateItem });
          },

          onChanged: (refName, inputText) => {
            let formCreateItem = this.state.formCreateItem;
            formCreateItem.fields[refName].value = inputText;

            this.setState({ formCreateItem });
          },
          onPaymentSelected: (refName, paymentForm) => {
            let formCreateItem = this.state.formCreateItem;
            formCreateItem.fields[refName].value = paymentForm;

            this.setState({ formCreateItem });
          },

          onSelected: (refName, selectedData) => {
            const vehicleValue = 3; // ou qualquer valor que representa "Veículo"

            if (selectedData.value === vehicleValue) {
              this.props.setModalItemOpen(true);
              return; // Impede a execução das demais ações
            }

            let formCreateItem = this.state.formCreateItem;

            if (refName == 'broker') {
              this.loadPaymentsForms(selectedData.value, true);
              formCreateItem.fields.hotelGuarante.disabled = false;
            }
            if (refName == 'system') {
              this.loadPaymentsForms(selectedData.value);
              formCreateItem.fields.payment.disabled = false;
            }

            if (refName == 'selectItemType') {
              this.formManager.clearFieldsFormCreateItem(formCreateItem);

              Object.keys(formCreateItem.fields).forEach((refName) => {
                let field = formCreateItem.fields[refName];

                if (field.productType)
                  field.productType == selectedData.value &&
                  refName != 'suplierName'
                    ? (field.visible = true)
                    : (field.visible = false);
                else field.visible = true;
              });
            }

            formCreateItem.fields[refName].value = selectedData;
            formCreateItem.forceValidation = false;

            let brokerField = formCreateItem.fields['broker'].value;

            if (brokerField && brokerField.value == 'hotelOffline')
              formCreateItem.fields.suplierName.visible = true;
            else formCreateItem.fields.suplierName.visible = false;

            this.setState({ formCreateItem });
          },
        },
      },
    },

    requets: {
      onOpenRequestClicked: (requestNumber, rootContextId) => {
        let isAgency = this.state.isAgency;

        this.dataSources.serviceRequest.callLoad({
          id: requestNumber,
          contextId: isAgency && rootContextId ? rootContextId : null,
        });
      },

      onAddProductClicked: () => {
        let requestId = this.state.requests[this.state.identifierActiveTab].id;

        let urlOrigin = window.location.origin;
        window.location.href =
          urlOrigin + `/travel/sale?requestId=${requestId}`;
      },

      onSendToAuthorizationClicked: () => {
        let request = this.state.requests[this.state.identifierActiveTab];

        this.events.popup.decision.onClosePopupClicked();

        this.dataSources.serviceRequest.callSendToAuthorization({
          requestId: request.id,
          contextId:
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : null,
          tabIdentifier: this.state.identifierActiveTab,
        });
      },

      onApproveRequestClicked: (useDefaultPayment) => {
        let request = this.state.requests[this.state.identifierActiveTab];

        return new Promise(async (resolve, reject) => {
          try {
            const response =
              await this.dataSources.approveRequest.callApproveRequest({
                requestId: request.id,
                contextId:
                  this.state.isAgency && request.rootContextId
                    ? request.rootContextId
                    : null,
                tabIdentifier: this.state.identifierActiveTab,
                useDefaultPayment,
              });
            resolve(response);
          } catch (error) {
            reject(error);
          }
        });
      },

      onApproveRequestItemClicked: () => {
        let item = this.getSelectedItem();

        this.events.popup.decision.onClosePopupClicked();

        this.dataSources.approveRequest.callApproveItemRequest({
          requestId: item.id,
          contextId:
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : null,
          tabIdentifier: this.state.identifierActiveTab,
        });
      },

      onCancelRequestClicked: () => {
        let requestId = this.state.requests[this.state.identifierActiveTab].id;
        this.events.popup.decision.onClosePopupClicked();

        this.dataSources.cancelRequest.callCancelRequest({
          requestId: requestId,
          tabIdentifier: this.state.identifierActiveTab,
        });
      },

      onCancelRequestItemClicked: () => {
        let item = this.getSelectedItem();
        this.events.popup.decision.onClosePopupClicked();
        if (item) {
          this.dataSources.cancelRequest.callCancelItemRequest({
            productId: item.id,
            tabIdentifier: this.state.identifierActiveTab,
          });
        } else {
          gritter.Error('Não foi possivel obter o item');
        }
      },

      onRefuseRequestClicked: () => {
        if (this.validateFieldsJustification()) {
          let request = this.state.requests[this.state.identifierActiveTab];

          this.events.popup.justification.onClosePopupClicked();

          this.dataSources.refuseRequest.callRefuseRequest({
            requestId: request.id,
            contextId:
              this.state.isAgency && request.rootContextId
                ? request.rootContextId
                : null,
            tabIdentifier: this.state.identifierActiveTab,
            justification: this.state.descriptionJustification,
          });

          this.setState({ showValidationsfieldsJustification: false });
        } else {
          this.setState({ showValidationsfieldsJustification: true });
        }
      },

      onRefuseProductClicked: () => {
        if (this.validateFieldsJustification()) {
          let request = this.getSelectedItem();

          this.events.popup.justification.onClosePopupClicked();

          this.dataSources.refuseProductRequest.callRefuseProductRequest({
            productId: request.id,
            contextId:
              this.state.isAgency && request.rootContextId
                ? request.rootContextId
                : null,
            tabIdentifier: this.state.identifierActiveTab,
            justification: this.state.descriptionJustification,
          });

          this.setState({ showValidationsfieldsJustification: false });
        } else {
          this.setState({ showValidationsfieldsJustification: true });
        }
      },

      onDeleteProductClicked: () => {
        this.dataSources.cancelBookingItem.callCancelProductRequest({
          tabIdentifier: this.state.identifierActiveTab,
          productId: this.state.selectedProductId,
        });

        this.events.popup.decision.onClosePopupClicked();
      },

      onUpdateDataClicked: () => {
        let identifier = this.state.identifierActiveTab;
        let request = this.state.requests[identifier];

        if (
          request.allowedActions.canFetch &&
          request.audit &&
          request.audit.dataOrigin != enums.requestDataOrigin.manual
        ) {
          this.dataSources.serviceRequest.callFetch({
            identifier,
            id: request.id,
            contextId:
              this.state.isAgency && request.rootContextId
                ? request.rootContextId
                : null,
          });
        } else {
          this.dataSources.serviceRequest.callUpdate({
            identifier,
            id: request.id,
            contextId:
              this.state.isAgency && request.rootContextId
                ? request.rootContextId
                : null,
          });
        }
      },

      onShowEmailPopupClicked: (id, statusId, isRv) => {
        this.setState({
          notifier: {
            ...this.state.notifier,
            show: true,
            id: id,
            fieldsData: statusFlowResolver.getEmailAdreesseeByStatus(statusId),
            isRv: isRv,
          },
        });
      },

      onShowHistoricPopupClicked: () => {
        this.setState({
          historicData: { ...this.state.historicData, show: true },
        });
      },

      onShowRequestHistoriesPopupClicked: () => {
        let identifier = this.state.identifierActiveTab;
        let request = this.state.requests[identifier];

        this.setState({
          requestHistoriesData: {
            ...this.state.requestHistoriesData,
            show: true,
            serviceResquestId: request.id,
          },
        });
      },

      onCostSplitChanged: (event) => {
        this.dataSources.serviceRequest.callLoad(event.request);
      },
    },

    navigationRequest: {
      onNavigationItemClicked: (item, index) => {
        this.bookingManager.open({
          bookingId: item.id,
          identifier: item.identifier,
          contextId: item.contextId,
          rootContextId: item.rootContextId,
          bookingType: item.productType,
          bookingIndex: index,
          itemActive: item.identifier,
        });
      },
    },

    notifier: {
      onHidePopupClicked: () => {
        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData: {},
            id: null,
            show: false,
          },
        });
      },

      onConfirmNotifyServiceRequestClicked: (event) => {
        let serviceRequest =
          this.state.requests[this.state.identifierActiveTab];
        let contextId =
          this.state.isAgency && serviceRequest.rootContextId
            ? serviceRequest.rootContextId
            : null;

        let objEmail = statusFlowResolver.getFormattedEmailObject(
          this.state.notifier.fieldsData,
        );
        objEmail.serviceRequestId = serviceRequest.id;
        this.dataSources.serviceRequest.notify(objEmail, contextId);

        this.events.notifier.onHidePopupClicked();
      },

      onConfirmNotifyServiceRequestItemClicked: (event) => {
        let notifier = this.state.notifier;
        let serviceRequest =
          this.state.requests[this.state.identifierActiveTab];

        let contextId =
          this.state.isAgency && serviceRequest.rootContextId
            ? serviceRequest.rootContextId
            : null;

        let objEmail = statusFlowResolver.getFormattedEmailObject(
          notifier.fieldsData,
        );
        objEmail.serviceRequestItemId = notifier.id;
        this.dataSources.serviceRequestItem.notifyItem(objEmail, contextId);

        this.events.notifier.onHidePopupClicked();
      },

      onFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef] = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },

      onNameFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef].name = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },

      onEmailFieldChanged: (fieldRef, value) => {
        let fieldsData = this.state.notifier.fieldsData;
        fieldsData[fieldRef].email = value;

        this.setState({
          notifier: {
            ...this.state.notifier,
            fieldsData,
          },
        });
      },
    },

    historic: {
      onHidePopupClicked: () => {
        this.setState({
          historicData: { ...this.state.historicData, show: false },
        });
      },
    },

    requestHistories: {
      onHidePopupClicked: () => {
        this.setState({
          requestHistoriesData: {
            ...this.state.requestHistoriesData,
            show: false,
          },
        });
      },
    },

    bookings: {
      onAnalysisItemClicked: (productType) => {
        let identifierItem = this.state.identifierActiveBooking;
        let requests = this.state.requests;
        let identifierRS = this.state.identifierActiveTab;

        if (
          !requests[identifierRS].items[identifierItem].analysisItem
            .searchResults
        )
          this.dataSources.itemsForAnalysis.callItemsForAnalysis({
            id: requests[identifierRS].items[identifierItem].id,
            identifierItem,
            identifierRS,
            productType: productType,
          });
      },

      onUpdateAirBookingClicked: () => {
        let request = this.state.requests[this.state.identifierActiveTab];
        this.bookingManager.fetch(request.managerNavigation);
      },

      onUpdateHotelBookingClicked: () => {
        let request = this.state.requests[this.state.identifierActiveTab];
        this.bookingManager.fetch(request.managerNavigation);
      },

      onRefreshBookingClicked: () => {
        let request = this.state.requests[this.state.identifierActiveTab];
        this.bookingManager.fetch(request.managerNavigation);
      },

      onSendAppsClicked: (id) => {
        let request = this.state.requests[this.state.identifierActiveTab];

        this.dataSources.serviceRequestItem.callSendToApps({
          requestItemId: id,
          requestId: request.id,
          contextId:
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : null,
          identifier: request.identifier,
          identifierSR: this.state.identifierActiveTab,
        });
      },
    },

    popup: {
      feedbacks: {
        onClosePopupClicked: () => {
          this.setState({
            popupFeedbackData: {
              show: false,
              description: null,
              events: null,
            },
          });
        },
      },

      decision: {
        onClosePopupClicked: () => {
          this.setState({
            popupDecisionData: {
              show: false,
              description: null,
              events: {
                confirm: null,
                cancel: null,
              },
            },
          });
        },

        onAddProductClicked: () => {
          let description =
            'Você será direcionado(a) para a página de cotações.';
          this.setState({
            popupDecisionData: {
              show: true,
              description: description,
              events: {
                confirm: this.events.requets.onAddProductClicked,
                cancel: this.events.popup.decision.onClosePopupClicked,
              },
            },
          });
        },

        onSendToAuthorizationClicked: (identifier) => {
          let description = `Confirma o envio da requisição: ${identifier} para AUTORIZAÇÃO ?`;

          this.setState({
            popupDecisionData: {
              show: true,
              description: description,
              events: {
                confirm: this.events.requets.onSendToAuthorizationClicked,
                cancel: this.events.popup.decision.onClosePopupClicked,
              },
            },
          });
        },

        onApproveRequestClicked: (identifier) => {
          let description = `Confirma a APROVAÇÃO da requisição: ${identifier}`;
          let confirmEvent = this.events.requets.onApproveRequestClicked;

          let managerNavigation =
            this.state.requests[this.state.identifierActiveTab]
              .managerNavigation;

          if (managerNavigation.bookingId && managerNavigation.bookingType) {
            description = `Confirma a APROVAÇÃO do item: ${this.state.identifierActiveBooking}`;
            confirmEvent = this.events.requets.onApproveRequestItemClicked;
          }

          this.setState({
            popupDecisionData: {
              show: true,
              description: description,
              events: {
                confirm: confirmEvent,
                cancel: this.events.popup.decision.onClosePopupClicked,
              },
            },
          });
        },

        onCancelRequestClicked: (identifier) => {
          let description = `Atenção: Essa ação cancela todas as RESERVAS existentes nessa Requisição de Viagens (${identifier}). Tem certeza que deseja continuar?`;
          let confirmEvent = this.events.requets.onCancelRequestClicked;

          let managerNavigation =
            this.state.requests[this.state.identifierActiveTab]
              .managerNavigation;

          if (managerNavigation.bookingId && managerNavigation.bookingType) {
            description = `Atenção: Essa ação cancela o item (${this.state.identifierActiveBooking}). Tem certeza que deseja continuar?`;
            confirmEvent = this.events.requets.onCancelRequestItemClicked;
          }

          this.setState({
            popupDecisionData: {
              show: true,
              description: description,
              events: {
                confirm: confirmEvent,
                cancel: this.events.popup.decision.onClosePopupClicked,
              },
            },
          });
        },

        onDeleteProductClicked: (selectedProductId) => {
          let request = this.state.requests[this.state.identifierActiveTab];
          let product = request.itemsSummary.find(
            (item) => item.id == selectedProductId,
          );
          let description = '';

          switch (product.type) {
            case enums.products.namedType.airBooking:
              if (product.airTrips) {
                let departureIata = product.airTrips[0].departure.iata;
                let arrivalIata = product.airTrips[0].arrival.iata;

                description = `Confirma o CANCELAMENTO da reserva: ${departureIata} > ${arrivalIata}?`;
              } else {
                description = 'Confirma o CANCELAMENTO da reserva?';
              }
              break;
            case enums.products.namedType.hotelBooking:
              let hotel = product.hotel.name;
              description = `Confirma o CANCELAMENTO da reserva: ${hotel}?`;
              break;
            case enums.products.namedType.vehicleBooking:
              const vehicleFullName = getVehicleBookingFullName(
                product.vehicle,
              );
              description = `Confirma o CANCELAMENTO da reserva: ${vehicleFullName}?`;
              break;
            default:
              description = `Confirma o CANCELAMENTO da reserva: ${product.bookingLocator}?`;
              break;
          }

          this.setState({
            selectedProductId,
            popupDecisionData: {
              show: true,
              description: description,
              events: {
                confirm: this.events.requets.onDeleteProductClicked,
                cancel: this.events.popup.decision.onClosePopupClicked,
              },
            },
          });
        },
      },

      justification: {
        onClosePopupClicked: () => {
          this.setState({
            popupRecuseJustificationData: {
              show: false,
              description: null,
              events: {
                confirm: null,
                cancel: null,
              },
            },
            showValidationsfieldsJustification: false,
          });
        },

        onRefuseRequestClicked: (identifier) => {
          let description = `Confirma a REPROVAÇÃO da requisição: ${identifier}`;
          let refuseEvent = this.events.requets.onRefuseRequestClicked;

          let managerNavigation =
            this.state.requests[this.state.identifierActiveTab]
              .managerNavigation;

          if (managerNavigation.bookingId && managerNavigation.bookingType) {
            description = `Confirma a REPROVAÇÃO do item: ${this.state.identifierActiveBooking}`;
            refuseEvent = this.events.requets.onRefuseProductClicked;
          }

          this.setState({
            popupRecuseJustificationData: {
              show: true,
              description: description,
              isRequest: true,
              events: {
                confirm: refuseEvent,
                cancel: this.events.popup.justification.onClosePopupClicked,
                onChangeDescription:
                  this.events.popup.justification.onChangeDescription,
              },
            },
            descriptionJustification: '',
          });
        },

        onRefuseProductClicked: (selectedProductId) => {
          let request = this.state.requests[this.state.identifierActiveTab];
          let product = request.itemsSummary.find(
            (item) => item.id == selectedProductId,
          );
          let description = '';

          if (product.type == enums.products.namedType.airBooking) {
            let departureIata = product.airTrips[0].departure.iata;
            let arrivalIata = product.airTrips[0].arrival.iata;
            description = [
              `Confirma a REPROVAÇÃO da reserva: ${departureIata} > ${arrivalIata}`,
            ];
          } else {
            let hotel = product.hotel.name;
            description = [`Confirma a REPROVAÇÃO da reserva: ${hotel}`];
          }

          this.setState({
            selectedProductId,
            popupRecuseJustificationData: {
              show: true,
              description: description,
              isRequest: false,
              events: {
                confirm: this.events.requets.onRefuseProductClicked,
                cancel: this.events.popup.justification.onClosePopupClicked,
                onChangeDescription:
                  this.events.popup.justification.onChangeDescription,
              },
            },
            descriptionJustification: '',
          });
        },

        onChangeDescription: (value) => {
          this.setState({ descriptionJustification: value });
        },
      },
    },
  };

  loadPaymentsForms(supplier, isGuarante) {
    const activeTab = this.state.identifierActiveTab;
    const rv = this.state.requests[activeTab];
    let userTravlers = rv.travelers.filter((t) => t.userId);

    let payload = {
      products: 1,
      supplier: supplier,
      costCenter: rv.costCenter.id,
      contextId: rv.contextId,
      requester: rv.requester.userId,
      travelers: userTravlers.map((t) => t.userId),
    };
    this.dataSources.overrides.paymentForms.events.beforeLoad();
    this.dataSources.overrides.paymentForms
      .load(payload)
      .then((paymentForms) => {
        let validPaymentForms = paymentForms.filter(
          (payment) =>
            payment.paymentForm.paymentType != enums.paymentType.b2bPay,
        );
        let formCreateItem = this.state.formCreateItem;

        if (isGuarante)
          formCreateItem.fields.hotelGuarante.paymentForms = validPaymentForms;
        else formCreateItem.fields.payment.paymentForms = validPaymentForms;

        this.setState({ formCreateItem });
        this.dataSources.overrides.paymentForms.events.afterLoad();
      });
  }

  prepareDataItemAddSend(fields, serviceRequest) {
    let booking = {};

    booking.bookingLocator = fields.locator.value;
    booking.bookingStatus = fields.bookingStatus
      ? fields.bookingStatus.value.value
      : null;
    booking.serviceRequestId = serviceRequest.id;

    booking.travelers = serviceRequest.travelers.filter((traveler) =>
      fields.bookingTravelers.value.some((x) => x.value == traveler.id),
    );

    booking.expiration = {
      expireAt: utils.date.strDateTimeToObjectDate(
        fields.expirationDate.value,
        '/',
      ),
    };

    if (fields.selectItemType.value.value == enums.products.type.air) {
      booking.type = enums.products.namedType.airBooking;
      booking.connectionSource = fields.system.value.value;
      booking.issuerAirline = {
        code: fields.airline.value.value,
        name: fields.airline.value.label,
      };
      booking.airTrips = [];
      booking.payments = this.resolvePayment(fields.payment.value.paymentForm);
    } else {
      booking.type = enums.products.namedType.hotelBooking;
      booking.connectionSource = fields.broker.value.value;
      fields.suplierName.value
        ? (booking.suplierName = fields.suplierName.value)
        : null;
      booking.payments = this.resolvePayment(
        fields.hotelGuarante.value.paymentForm,
        true,
      );
      booking.hotel = {
        rating: fields.rating.value.value,
      };
    }

    return booking;
  }

  resolvePayment(paymentForm, isGuarante) {
    let payment = {
      isGuarantee: isGuarante,
      paymentData: {
        type: 0,
        paymentForm:
          paymentResolver.convertPaymentFormComponentObjToApi(paymentForm),
      },
    };
    return [payment];
  }

  selects = {
    selectCompanies: {
      getProps: function () {
        let _this = this;
        let companies = this.state.companies;

        return {
          data: _this.state.companySelected,
          placeholder: 'Selecione o cliente',
          options: {
            items: companies,
            dataSource: {
              load: function () {
                return Promise.resolve(companies);
              },
            },

            events: {
              onSelected: function (companySelected) {
                let rvOfflineData = _this.state.rvOfflineData;
                rvOfflineData.agencyAndCompany.company = companySelected;
                rvOfflineData.numberOfTravelers = null;

                _this.setState({
                  companySelected,
                  rvOfflineData,
                });
              },
            },
          },
        };
      },
    },

    selectTravelers: {
      getProps: function () {
        let _this = this;
        return {
          data: _this.state.rvOfflineData.numberOfTravelers,
          options: {
            dataSource: {
              load: function () {
                return Promise.resolve(listItemsResolver.resolveRange(1, 7));
              },
            },
            events: {
              onSelected: (numberOfTravelers) => {
                _this.setState({
                  rvOfflineData: {
                    ...this.state.rvOfflineData,
                    numberOfTravelers: numberOfTravelers.value,
                  },
                });
              },
            },
          },
        };
      },
    },

    selectItemsType: {
      getProps: () => {
        let _this = this;
        let itemSelected = this.state.createItemsData.itemTypeSelected;
        return {
          data: itemSelected,
          options: {
            dataSource: {
              load: function () {
                return Promise.resolve(
                  listItemsResolver.resolveEnum(enums.products.offlineType),
                );
              },
            },
            events: {
              onSelected: (itemTypeSelected) => {
                _this.setState({
                  createItemsData: {
                    ...this.state.createItemsData,
                    itemTypeSelected,
                  },
                });
              },
            },
          },
        };
      },
    },
  };

  toggleModalLoading = (loading) => {
    this.setState({ loading });
  };

  dataSources = {
    serviceRequest: {
      callLoad: (data) => {
        let identifier = null;

        if (data.identifier) identifier = data.identifier;
        else identifier = this.generateIdentifier();

        this.requestsManager.include(identifier, data.id);

        let dataSource = this.externalOptions.dataSources.serviceRequest;
        dataSource.load(data).then((response) => {
          if (response.serviceRequest) {
            this.requestsManager.update(identifier, response);
          } else {
            this.requestsManager.delete(identifier);
          }
        });
      },

      callUpdate: (data) => {
        let requests = this.state.requests;
        requests[data.identifier].loading = true;
        this.setState({ requests });

        let dataSource = this.externalOptions.dataSources.serviceRequest;
        dataSource.update(data).then((response) => {
          if (response.errors && response.errors.length) {
            requests[data.identifier].loading = false;
            this.setState({ requests });
          } else {
            this.requestsManager.update(data.identifier, response);
          }
        });
      },

      callFetch: (data) => {
        let requests = this.state.requests;
        requests[data.identifier].loading = true;
        this.setState({ requests });

        let dataSource = this.externalOptions.dataSources.serviceRequest;
        dataSource.fetch(data).then((response) => {
          if (response.errors && response.errors.length) {
            requests[data.identifier].loading = false;
            this.setState({ requests });
          } else {
            this.requestsManager.update(data.identifier, response);
          }
        });
      },

      callSendToAuthorization: (data) => {
        let identifier = data.tabIdentifier;
        let requests = this.state.requests;
        requests[identifier].loading = true;

        let dataSource = this.externalOptions.dataSources.serviceRequest;
        dataSource
          .sendToAuthorization({
            id: data.requestId,
            contextId: data.contextId,
          })
          .then((response) => {
            this.requestsManager.update(identifier, response);
          });
      },

      notify: (loadOption, contextId) => {
        const _this = this;
        const dataSource = this.externalOptions.dataSources.serviceRequest;

        if (dataSource.events && dataSource.events.beforeSendNotify)
          dataSource.events.beforeSendNotify();

        dataSource
          .sendNotify(loadOption, contextId)
          .then((result) => {
            apiResponse.successful.showErrorIfExists(result);

            if (result.successful)
              gritter.Success('Seu e-mail será enviado em instantes.');

            if (dataSource.events && dataSource.events.afterSendNotify)
              dataSource.events.afterSendNotify();
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(
              error,
              'Ocorreu um erro ao Client/Servidor ao enviar e-mail',
            );

            if (dataSource.events && dataSource.events.afterSendNotify)
              dataSource.events.afterSendNotify();
          });
      },
    },

    serviceRequestItem: {
      callLoad: (data, productType) => {
        let dataSource = this.externalOptions.dataSources.serviceRequestItem;
        dataSource
          .load({
            serviceRequestItemId: data.id,
            contextId:
              this.state.isAgency && data.rootContextId
                ? data.rootContextId
                : null,
          })
          .then((response) => {
            this.bookingManager.update(
              data.identifier,
              data.identifierSR,
              response,
            );
          });
      },

      callFetch: (data) => {
        let dataSource = this.externalOptions.dataSources.serviceRequestItem;
        dataSource
          .fetch({
            serviceRequestItemId: data.id,
            contextId:
              this.state.isAgency && data.rootContextId
                ? data.rootContextId
                : null,
          })
          .then((response) => {
            this.bookingManager.update(
              data.identifier,
              data.identifierSR,
              response,
            );
          });
      },
      callAdd: (data) => {
        let dataSource = this.externalOptions.dataSources.serviceRequestItem;
        let requests = this.state.requests;
        let request = requests[data.identifierSR];

        let rootContextId =
          this.state.isAgency && request.rootContextId
            ? request.rootContextId
            : null;

        dataSource
          .add({
            serviceRequestItem: data.sendData,
            contextId: rootContextId,
            productType: data.productType,
            identifier: data.identifier,
          })
          .then((response) => {
            if (
              response.serviceRequest &&
              response.serviceRequestItem.type ==
                enums.products.namedType.airTicket
            ) {
              this.dataSources.serviceRequestItem.callLoad({
                id: request.items[data.identifier].id,
                rootContextId: rootContextId,
                identifier: data.identifier,
                identifierSR: data.identifierSR,
                productType: enums.products.type.air,
              });
            } else
              this.bookingManager.update(
                data.identifier,
                data.identifierSR,
                response,
              );
          });
      },
      callEdit: (data) => {
        let dataSource = this.externalOptions.dataSources.serviceRequestItem;
        let requests = this.state.requests;
        let request = requests[data.identifierSR];

        let rootContextId =
          this.state.isAgency && request.rootContextId
            ? request.rootContextId
            : null;

        dataSource
          .edit({
            serviceRequestItem: data.serviceRequestItem,
            contextId: rootContextId,
            productType: data.productType,
            identifier: data.identifier,
          })
          .then((response) => {
            if (
              response.serviceRequest &&
              response.serviceRequestItem.type ==
                enums.products.namedType.airTicket
            ) {
              this.dataSources.serviceRequestItem.callLoad({
                id: request.items[data.identifier].id,
                rootContextId: rootContextId,
                identifier: data.identifier,
                identifierSR: data.identifierSR,
                productType: enums.products.type.air,
              });
            } else
              this.bookingManager.update(
                data.identifier,
                data.identifierSR,
                response,
              );
          });
      },

      notifyItem: (loadOption, contextId) => {
        const _this = this;
        const dataSource = this.externalOptions.dataSources.serviceRequestItem;

        if (dataSource.events && dataSource.events.beforeSendNotifyItem)
          dataSource.events.beforeSendNotifyItem();

        dataSource
          .sendNotifyItem(loadOption, contextId)
          .then((result) => {
            apiResponse.successful.showErrorIfExists(result);

            if (result.successful)
              gritter.Success('Seu e-mail será enviado em instantes.');

            if (dataSource.events && dataSource.events.afterSendNotifyItem)
              dataSource.events.afterSendNotifyItem();
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(
              error,
              'Ocorreu um erro ao Client/Servidor ao enviar e-mail',
            );

            if (dataSource.events && dataSource.events.afterSendNotifyItem)
              dataSource.events.afterSendNotifyItem();
          });
      },

      callSendToApps: (data) => {
        let identifierSR = data.identifierSR;
        let identifier = data.identifier;

        let dataSource = this.externalOptions.dataSources.serviceRequestItem;

        dataSource.events.beforeSendToAppsIntegration &&
          dataSource.events.beforeSendToAppsIntegration();

        dataSource
          .sendToAppsIntegration(
            data.requestId,
            data.requestItemId,
            data.contextId,
          )
          .then((response) => {
            this.bookingManager.update(identifier, identifierSR, response);

            dataSource.events.afterSendToAppsIntegration &&
              dataSource.events.afterSendToAppsIntegration();
          });
      },
    },

    itemsForAnalysis: {
      callItemsForAnalysis: (data) => {
        let dataSource = this.externalOptions.dataSources.itemsForAnalysis;
        dataSource.load(data.id).then((response) => {
          if (data.productType == enums.products.type.air)
            response = response.air;
          else response = response.hotel;

          this.bookingManager.analysisItemOpenData(
            data.identifierItem,
            data.identifierRS,
            response,
          );
        });
      },
    },

    approveRequest: {
      callApproveRequest: async (data) => {
        let identifier = data.tabIdentifier;
        let requests = this.state.requests;
        requests[identifier].loading = true;
        let dataSource = this.externalOptions.dataSources.serviceRequest;

        let useDefaultPayment = data.useDefaultPayment;

        try {
          const response = await dataSource.approve(
            {
              serviceRequestId: data.requestId,
              action: 1,
              useDefaultPayment,
            },
            data.rootContextId,
          );

          if (response.successful) {
            this.setState({
              popupDecisionData: {
                show: true,
                events: {
                  confirm: this.events.popup.decision.onClosePopupClicked,
                },
              },
            });
            return response;
          } else {
            apiResponse.successful.showErrorIfExists(
              response,
              `Ocorreu um erro ao tentar aprovar a RV ${data.requestId}.`,
            );
            throw response;
          }
        } catch (error) {
          throw error;
        } finally {
          this.events.requets.onUpdateDataClicked();
        }
      },
      callApproveItemRequest: (data) => {
        let identifier = data.tabIdentifier;
        let requests = this.state.requests;
        requests[identifier].loading = true;

        let dataSource = this.externalOptions.dataSources.serviceRequestItem;
        dataSource
          .approve(
            {
              serviceRequestItemId: data.requestId,
              action: 1,
            },
            data.rootContextId,
          )
          .then((response) => {
            if (response.successful) {
              if (response.messages && response.messages.length > 0) {
                gritter.Info(
                  `Item ${data.requestId}: ` + response.messages[0].message,
                );
              } else {
                gritter.Success(`Item ${data.requestId} aprovado com sucesso.`);
              }
            } else {
              apiResponse.successful.showErrorIfExists(
                response,
                `Ocorreu um erro ao tentar aprovar o item ${data.requestId}.`,
              );
            }

            this.events.requets.onUpdateDataClicked();
          });
      },

      callApproveRequestInBatch: (serviceRequests) => {
        let approvalResponse = [];
        let dataSource = this.externalOptions.dataSources.serviceRequest;

        return new Promise((resolve, reject) => {
          for (let i = 0; i < serviceRequests.length; i++) {
            let serviceRequest = serviceRequests[i];
            dataSource
              .approve(
                {
                  serviceRequestId: serviceRequest.id,
                  action: 1,
                },
                serviceRequest.rootContextId,
              )
              .then((response) => {
                approvalResponse.push({ id: serviceRequest.id, response });

                if (serviceRequests.length == approvalResponse.length) {
                  resolve(approvalResponse);
                }
              });
          }
        });
      },
    },

    cancelRequest: {
      callCancelRequest: (data) => {
        let identifier = data.tabIdentifier;
        let requests = this.state.requests;
        let requestCurrent = requests[identifier];
        requests[identifier].loading = true;

        let dataSource = this.externalOptions.dataSources.serviceRequest;
        dataSource
          .cancel({
            id: requestCurrent.id,
            rootContextId:
              this.state.userTravelProfile == enums.userTravelProfile.consultant
                ? requestCurrent.rootContextId
                : null,
            contextId:
              this.state.userTravelProfile == enums.userTravelProfile.consultant
                ? requestCurrent.contextId
                : null,
          })
          .then((response) => {
            if (
              response.errors.length == 0 &&
              response.messages.length == 0 &&
              response.successful == true
            )
              this.requestsManager.cancel(identifier);
            else {
              let standardMessage = [
                {
                  message:
                    'Algo deu errado, tente novamente, caso persista entre em contato com o suporte.',
                },
              ];

              requests[identifier].loading = false;

              this.setState({
                requests,
                popupFeedbackData: {
                  show: true,
                  descriptions:
                    response.errors.length > 0
                      ? response.errors
                      : response.messages.length > 0
                      ? response.messages
                      : standardMessage,
                  events: this.events.popup.feedbacks,
                },
              });
            }
          });
      },
      callCancelItemRequest: (data) => {
        let identifier = data.tabIdentifier;
        let requests = this.state.requests;
        let requestCurrent = requests[identifier];
        requests[identifier].loading = true;

        let dataSource = this.externalOptions.dataSources.serviceRequestItem;
        dataSource
          .cancelBookingItem({
            productId: data.productId,
            rootContextId:
              this.state.userTravelProfile == enums.userTravelProfile.consultant
                ? requestCurrent.rootContextId
                : null,
            contextId:
              this.state.userTravelProfile == enums.userTravelProfile.consultant
                ? requestCurrent.contextId
                : null,
          })
          .then((response) => {
            if (
              response.errors.length == 0 &&
              response.messages.length == 0 &&
              response.successful == true
            )
              this.requestsManager.cancel(identifier);
            else {
              let standardMessage = [
                {
                  message:
                    'Algo deu errado, tente novamente, caso persista entre em contato com o suporte.',
                },
              ];

              requests[identifier].loading = false;

              this.setState({
                requests,
                popupFeedbackData: {
                  show: true,
                  descriptions:
                    response.errors.length > 0
                      ? response.errors
                      : response.messages.length > 0
                      ? response.messages
                      : standardMessage,
                  events: this.events.popup.feedbacks,
                },
              });
            }
          });
      },
    },

    refuseRequest: {
      callRefuseRequest: (data) => {
        let identifier = data.tabIdentifier;
        let requests = this.state.requests;
        requests[identifier].loading = true;

        let dataSource = this.externalOptions.dataSources.serviceRequest;
        dataSource
          .refuse(
            {
              serviceRequestId: data.requestId,
              justification: data.justification,
              action: 2,
            },
            data.contextId,
          )
          .then((response) => {
            if (response.successful) {
              if (response.messages && response.messages.length > 0) {
                gritter.Info(
                  `RV ${data.requestId}: ` + response.messages[0].message,
                );
              } else {
                gritter.Success(`RV ${data.requestId} recusado com sucesso.`);
              }
            } else {
              apiResponse.successful.showErrorIfExists(
                response,
                `Ocorreu um erro ao tentar recusada a RV ${data.requestId}.`,
              );
            }

            this.events.requets.onUpdateDataClicked();
          });
      },
      callRefuseRequestInBatch: (serviceRequests, justification) => {
        let refuseResponse = [];
        let dataSource = this.externalOptions.dataSources.serviceRequest;

        return new Promise((resolve, reject) => {
          for (let i = 0; i < serviceRequests.length; i++) {
            let serviceRequest = serviceRequests[i];
            dataSource
              .refuse(
                {
                  serviceRequestId: data.requestId,
                  justification: justification,
                  action: 2,
                },
                data.contextId,
              )
              .then((response) => {
                refuseResponse.push({ id: serviceRequest.id, response });

                if (serviceRequests.length == refuseResponse.length) {
                  resolve(refuseResponse);
                }
              });
          }
        });
      },
    },

    refuseProductRequest: {
      callRefuseProductRequest: (data) => {
        let identifier = data.tabIdentifier;
        let requests = this.state.requests;
        requests[identifier].loading = true;

        let dataSource = this.externalOptions.dataSources.serviceRequestItem;
        dataSource
          .refuse(
            {
              serviceRequestItemId: data.productId,
              action: 2,
              justification: data.justification,
            },
            data.contextId,
          )
          .then((response) => {
            if (response.successful) {
              if (response.messages && response.messages.length > 0) {
                gritter.Info(
                  `Item ${data.productId}: ` + response.messages[0].message,
                );
              } else {
                gritter.Success(`Item ${data.productId} recusado com sucesso.`);
              }
            } else {
              apiResponse.successful.showErrorIfExists(
                response,
                `Ocorreu um erro ao tentar recusar o item ${data.productId}.`,
              );

              if (response.messages && response.messages.length > 0)
                gritter.Error(
                  `RV ${data.requestId}: ` + response.messages[0].message,
                );
            }

            this.events.requets.onUpdateDataClicked();
          });
      },
    },

    cancelBookingItem: {
      callCancelProductRequest: (data) => {
        let identifier = data.tabIdentifier;
        let requests = this.state.requests;
        let dataSource = this.externalOptions.dataSources.serviceRequestItem;
        let requestCurrent = requests[identifier];

        requests[identifier].loading = true;
        this.setState({ requests });

        dataSource
          .cancelBookingItem({
            productId: data.productId,
            parentId: requestCurrent.id,
            contextId:
              this.state.isAgency && requestCurrent.rootContextId
                ? requestCurrent.rootContextId
                : null,
          })
          .then((response) => {
            if (
              response.errors.length == 0 &&
              response.messages.length == 0 &&
              response.successful == true
            )
              this.requestsManager.cancel(identifier);
            else {
              let standardMessage = [
                {
                  message:
                    'Algo deu errado, tente novamente, caso persista entre em contato com o suporte.',
                },
              ];

              requests[identifier].loading = false;

              this.setState({
                requests,
                popupFeedbackData: {
                  show: true,
                  descriptions:
                    response.errors.length > 0
                      ? response.errors
                      : response.messages.length > 0
                      ? response.messages
                      : standardMessage,
                  events: this.events.popup.feedbacks,
                },
              });
            }
          });
      },
    },

    companies: {
      load: () => {
        let _this = this;
        let dataSource = this.externalOptions.dataSources.companies;

        if (dataSource.events && dataSource.events.beforeLoad)
          dataSource.events.beforeLoad();

        dataSource
          .load()
          .then((companies) => {
            let companiesOptions = [];
            for (let i = 0; i < companies.length; i++) {
              let company = companies[i];

              if (!company.subsidiaryName) {
                let companyOption = listItemsResolver.resolveItem(
                  company,
                  'contextId',
                  'companyName',
                );
                companiesOptions.push(companyOption);
              }
            }

            companies.sort(function (a, b) {
              if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
              if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
              return 0;
            });

            _this.setState({
              companies,
              companiesOptions,
            });

            if (dataSource.events && dataSource.events.afterLoad)
              dataSource.events.afterLoad();
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(error);
          });
      },
    },

    airLines: {
      load: () => {
        let _this = this;
        let dataSource = this.externalOptions.dataSources.airLines;

        if (dataSource.events && dataSource.events.beforeLoad)
          dataSource.events.beforeLoad();

        dataSource
          .load()
          .then((result) => {
            _this.setState({ airLines: result });

            if (dataSource.events && dataSource.events.afterLoad)
              dataSource.events.afterLoad();
          })
          .catch((error) => {
            apiResponse.http.showErrorIfExists(error);
          });
      },
    },

    airSuppliers: {
      load: (loadOptions) => {
        if (!loadOptions) loadOptions = {};

        let company = this.state.companySelected;
        if (
          this.externalOptions.isAgency &&
          this.externalOptions.userTravelProfile === 4 &&
          company.value !== 0
        )
          loadOptions.contextId = company.value;

        return this.externalOptions.dataSources.airSuppliers.load(loadOptions);
      },
    },

    hotelSuppliers: {
      load: (loadOptions) => {
        if (!loadOptions) loadOptions = {};

        let company = this.state.companySelected;
        if (
          this.externalOptions.isAgency &&
          this.externalOptions.userTravelProfile === 4 &&
          company.value !== 0
        )
          loadOptions.contextId = company.value;

        return this.externalOptions.dataSources.hotelSuppliers.load(
          loadOptions,
        );
      },
    },

    rvOffline: {
      serviceRequest: {
        add: (loadOptions) => {
          let identifier = this.generateIdentifier();
          let company = this.state.companySelected;
          let contextId = null;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            contextId = company.value;

          this.requestsManager.include(identifier, loadOptions.id);

          let dataSource = this.externalOptions.dataSources.serviceRequest;
          dataSource
            .add({
              serviceRequest: loadOptions,
              contextId: contextId,
              identifier: identifier,
            })
            .then((response) => {
              this.requestsManager.update(identifier, response);
            });
        },
        edit: (loadOptions) => {},
      },
      serviceRequestItem: {
        add: (loadOptions) => {},
        edit: (loadOptions) => {},
      },
      justifications: {
        //TODO: Verificar necessidade de enviar contextId
      },
      travelers: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.travelers.load(loadOptions);
        },
        insert: (traveler) => {
          if (!traveler) traveler = {};

          let company = this.state.companySelected;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            traveler.contextId = company.value;

          return this.externalOptions.dataSources.travelers.insert(traveler);
        },
        update: (traveler) => {
          if (!traveler) traveler = {};

          let company = this.state.companySelected;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            traveler.contextId = company.value;

          return this.externalOptions.dataSources.travelers.update(traveler);
        },

        currentSRTravelers: () => {
          return new Promise((resolve) => {
            let identifierSR = this.state.identifierActiveTab;
            let currentSR = this.state.requests[identifierSR];

            let listItems = listItemsResolver.resolve(
              serviceRequestResolver.resolveTravelers(currentSR.travelers),
              'id',
              'name',
            );

            resolve(listItems);
          });
        },

        events: {
          beforeLoad: () => {},
          afterLoad: () => {},
          beforeInsert: () => {
            this.toggleModalLoading(true);
          },
          afterInsert: () => {
            this.toggleModalLoading(false);
          },
          beforeUpdate: () => {
            this.toggleModalLoading(true);
          },
          afterUpdate: () => {
            this.toggleModalLoading(false);
          },
        },
      },
      costCenter: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.costCenter.load(loadOptions);
        },
      },
      paymentForms: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.paymentForms.load(
            loadOptions,
          );
        },
        events: {
          beforeLoad: () => {
            this.externalOptions.dataSources.paymentForms.beforeLoad();
          },
          afterLoad: () => {
            this.externalOptions.dataSources.paymentForms.afterLoad();
          },
        },
      },
      customField: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.customField.load(loadOptions);
        },
        events: {
          beforeLoad: () => {
            this.externalOptions.dataSources.customField.beforeLoad();
          },
          afterLoad: () => {
            this.externalOptions.dataSources.customField.afterLoad();
          },
        },
      },
      travelReason: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.travelReason.load(
            loadOptions,
          );
        },
      },
      requester: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let company = this.state.companySelected;
          if (
            this.externalOptions.isAgency &&
            this.externalOptions.userTravelProfile === 4 &&
            company.value !== 0
          )
            loadOptions.contextId = company.value;

          return this.externalOptions.dataSources.requester.load(loadOptions);
        },
      },
    },

    overrides: {
      costCenter: {
        load: (loadOptions) => {
          if (!loadOptions) loadOptions = {};

          let request = this.state.requests[this.state.identifierActiveTab];
          loadOptions.contextId =
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : null;

          return this.externalOptions.dataSources.costCenter.load(loadOptions);
        },
      },
      airTicket: {
        issue: (data) => {
          let request = this.state.requests[this.state.identifierActiveTab];
          let contextId =
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : null;

          let dataSource = this.externalOptions.dataSources.airTicket;
          return dataSource.issue(data, contextId);
        },

        simulateReplacement: (ticketNumberToReplace, newTicketDetails) => {
          let request = this.state.requests[this.state.identifierActiveTab];
          let contextId =
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : null;

          let dataSource = this.externalOptions.dataSources.airTicket;
          return dataSource.simulateReplacement(
            ticketNumberToReplace,
            newTicketDetails,
            contextId,
          );
        },

        load: (loadOptions) => {
          let request = this.state.requests[this.state.identifierActiveTab];
          let identifierItem = this.state.identifierActiveBooking;

          let requestItem = request.items[identifierItem];

          let contextId =
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : null;

          let dataSource = this.externalOptions.dataSources.airTicket;

          //TODO GRAF 001: Remover requestItem quando resposta da API for devolvida
          return dataSource.load(loadOptions, contextId, requestItem);
        },

        cancel: (airTicketId) => {
          const _this = this;
          let request = this.state.requests[this.state.identifierActiveTab];

          let contextId =
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : null;
          let dataSource = this.externalOptions.dataSources.airTicket;

          return new Promise((resolve, reject) => {
            dataSource
              .cancel(airTicketId, contextId)
              .then((response) => {
                _this.events.bookings.onUpdateAirBookingClicked();
                resolve(response);
              })
              .catch((result) => {
                reject(result);
              });
          });
        },

        events: {
          beforeIssue: () => {
            this.externalOptions.dataSources.airTicket.events.beforeIssue();
          },
          afterIssue: () => {
            this.externalOptions.dataSources.airTicket.events.afterIssue();
          },

          beforeLoad: () => {
            this.externalOptions.dataSources.airTicket.events.beforeLoad &&
              this.externalOptions.dataSources.airTicket.events.beforeLoad();
          },
          afterLoad: () => {
            this.externalOptions.dataSources.airTicket.events.afterLoad &&
              this.externalOptions.dataSources.airTicket.events.afterLoad();
          },

          beforeCancel: () => {
            this.externalOptions.dataSources.airTicket.events.beforeCancel &&
              this.externalOptions.dataSources.airTicket.events.beforeCancel();
          },
          afterCancel: () => {
            this.externalOptions.dataSources.airTicket.events.afterCancel &&
              this.externalOptions.dataSources.airTicket.events.afterCancel();
          },
        },
      },
      paymentForms: {
        load: (loadOptions) => {
          let request = this.state.requests[this.state.identifierActiveTab];
          loadOptions.contextId =
            this.state.isAgency && request.rootContextId
              ? request.rootContextId
              : loadOptions.contextId
              ? loadOptions.contextId
              : null;
          let dataSource = this.externalOptions.dataSources.paymentForms;

          return new Promise((resolve, reject) => {
            dataSource
              .load(loadOptions)
              .then((paymentFormsList) => {
                let requestPaymentsList = [];
                if (request.paymentForms && request.paymentForms.length)
                  requestPaymentsList =
                    paymentResolver.getPaymentFormsListItems(
                      request.paymentForms,
                    );

                //Remover opções duplicadas
                if (paymentFormsList.some((payment) => payment.value === 0)) {
                  requestPaymentsList = requestPaymentsList.filter(
                    (reqPayment) => reqPayment.value !== 0,
                  );
                }

                let finalList = [...paymentFormsList, ...requestPaymentsList];
                resolve(finalList);
              })
              .catch((result) => {
                reject(result);
              });
          });
        },
        events: {
          beforeLoad: () => {
            this.externalOptions.dataSources.paymentForms.events.beforeLoad();
          },
          afterLoad: () => {
            this.externalOptions.dataSources.paymentForms.events.afterLoad();
          },
        },
      },
    },
  };

  bookingManager = {
    open: (data) => {
      this.bookingManager.load(data);
    },

    fetch: (data) => {
      this.bookingManager.load(data, true);
    },

    add: (data) => {
      let requests = this.state.requests;
      let identifierSR = this.state.identifierActiveTab;
      let currentSR = this.state.requests[identifierSR];

      !currentSR.itemsSummary ? (currentSR.itemsSummary = []) : null;

      currentSR.itemsSummary.push(data);
      currentSR.items[data.identifier] = {
        identifier: data.identifier,
        loading: true,
      };

      if (!data.rootContextId) data.rootContextId = currentSR.rootContextId;

      this.dataSources.serviceRequestItem.callAdd(data);

      requests[identifierSR] = currentSR;
      requests[identifierSR].managerNavigation = data;

      this.setState({
        requests,
        identifierActiveBooking: data.identifier,
      });
    },

    load: (data, fetch) => {
      let identifierSR = this.state.identifierActiveTab;
      let requests = this.state.requests;
      let currentSR = requests[identifierSR];
      let sri = currentSR.items ? currentSR.items[data.identifier] : null;
      if (!sri)
        sri = currentSR.itemsSummary
          ? currentSR.itemsSummary.find((i) => i.identifier == data.identifier)
          : null;

      if (currentSR.items && currentSR.items[data.identifier] && !fetch)
        currentSR.items[data.identifier].loading = false;
      else if (sri) {
        currentSR.items[data.identifier] = {
          identifier: data.identifier,
          loading: true,
        };

        let loadOptions = {
          id: data.bookingId,
          identifier: data.identifier,
          contextId: data.contextId,
          rootContextId: data.rootContextId,
          identifierSR,
          sendData: data.sendData,
        };

        if (
          fetch &&
          sri.allowedActions.canFetch &&
          currentSR.audit &&
          currentSR.audit.dataOrigin != enums.requestDataOrigin.manual &&
          sri.connectionSource != 'HotelOffline'
        )
          this.dataSources.serviceRequestItem.callFetch(loadOptions);
        else
          this.dataSources.serviceRequestItem.callLoad(loadOptions, sri.type);
      }

      requests[identifierSR] = currentSR;
      requests[identifierSR].managerNavigation = data;

      this.setState({
        requests,
        identifierActiveBooking: data.identifier,
      });
    },

    update: (identifierItem, identifierSR, response) => {
      let requests = this.state.requests;
      let currentSR = requests[identifierSR];

      if (response.serviceRequestItem) {
        identifierItem = response.serviceRequestItem.identifier;
        currentSR.items[identifierItem] = response.serviceRequestItem;
      }

      currentSR.items[identifierItem].loading = false;
      currentSR.items[identifierItem].analysisItem = {
        loading: true,
        searchResults: null,
      };

      if (
        response.serviceRequestItem &&
        response.serviceRequestItem.audit.dataOrigin ==
          enums.requestDataOrigin.manual
      ) {
        currentSR.managerNavigation.id = response.serviceRequestItem.id;
        currentSR.managerNavigation.identifier = identifierItem;
      }

      currentSR.managerNavigation.itemActive = identifierItem;
      requests[identifierSR] = currentSR;

      this.setState({
        requests,
        identifierActiveBooking: identifierItem,
      });
    },

    analysisItemOpenData: (identifierItem, identifierRS, data) => {
      let requests = this.state.requests;
      let searchResults = {};

      data.compressedSearchResults.forEach((item) => {
        searchResults[item.searchId] = {
          additionalProperties: item.additionalProperties
            ? JSON.parse(item.additionalProperties)
            : null,
          cheapestOption: item.cheapestOption
            ? compressorResolver.descompress(item.cheapestOption)
            : null,
          equivalentOption: '',
          searchData: item.searchData ? JSON.parse(item.searchData) : null,
          searchResults: item.searchResults
            ? compressorResolver.descompress(item.searchResults)
            : null,
          selectedOption: item.selectedOption
            ? compressorResolver.descompress(item.selectedOption)
            : null,
        };
      });

      requests[identifierRS].items[identifierItem].analysisItem = {
        loading: false,
        searchResults: searchResults, // data.searchResults
      };

      this.setState({ requests });
    },
    sendToAppsIntegration: (id, contextId) => {
      this.dataSources.serviceRequestItem.sendToAppsIntegration(id, contextId);
    },
  };

  requestsManager = {
    reset: () => {
      let requests = this.state.requests;

      const mainTabIdentifier = this.state.mainTabIdentifier;
      const otherTabIdentifiers = Object.keys(requests).filter(
        (_) => _ != mainTabIdentifier,
      );

      if (otherTabIdentifiers?.length) {
        otherTabIdentifiers.forEach((_) => {
          delete requests[_];
        });
      }

      this.setState({
        requests,
        identifierActiveTab: mainTabIdentifier,
      });
    },

    include: (identifier, id) => {
      let requests = this.state.requests;

      requests[identifier] = {
        id: id ? id : 'RV Offline',
        identifier: 'RV ...',
        loading: true,
        items: {},
      };

      this.setState({
        requests: requests,
        identifierActiveTab: identifier,
      });
    },

    update: (identifier, response) => {
      let tabData = response.serviceRequest
        ? response.serviceRequest
        : response;
      let travelersList = this.state.travelersList;

      tabData.loading = false;
      tabData.items = {};
      tabData.identifier = tabData.identifier
        ? tabData.identifier
        : `RV ${tabData.id}`;
      tabData.managerNavigation = {
        bookingId: tabData.id,
        bookingType: null,
        bookingIndex: null,
      };
      tabData.connectionType !== null &&
      tabData.connectionType !== undefined &&
      tabData.connectionType == enums.connectionType.offline
        ? (tabData.travelType = enums.travelType.offline)
        : null;

      //TODO: Retornar as ações permitidas a partir a obtenção da SR (a partir do fluxo de status)
      if (response.serviceRequest) {
        tabData.allowedActions = statusFlowResolver.getRecordAllowedActions(
          tabData,
          tabData.requester,
          this.externalOptions.currentUserId,
          this.externalOptions.userTravelProfile,
          this.externalOptions.userData.company,
        );

        if (
          tabData.allowedActions.canAuthorize ||
          tabData.allowedActions.canRefuse
        )
          tabData.allowedActions.currentApprover =
            this.currentApproverByApprovalFlow(tabData);

        this.fillItemsSummaryData(response.serviceRequest);
      }

      if (tabData.travelers) {
        travelersList = tabData.travelers.map((traveler) => {
          return {
            id: traveler.id,
            name: `${traveler.firstName} ${traveler.lastName}`,
          };
        });
      }

      let requests = this.state.requests;
      requests[identifier] = tabData;

      let popupFeedbackData = this.state.popupFeedbackData;
      if (response.messages && response.messages.length > 0) {
        (popupFeedbackData.show = true),
          (popupFeedbackData.descriptions = response.messages),
          (popupFeedbackData.events = this.events.popup.feedbacks);
      }

      if (response.serviceRequest)
        this.props.setSelectedRequest(response.serviceRequest.id, response.serviceRequest.rootContextId);

      this.setState({ requests, travelersList, popupFeedbackData });
    },

    delete: (identifier) => {
      let requests = this.state.requests;
      let currentIndex = Object.keys(requests).indexOf(identifier);

      delete requests[identifier];

      let identifierArray = Object.keys(requests);
      let identifierActiveTab =
        currentIndex >= identifierArray.length - 1
          ? identifierArray[identifierArray.length - 1]
          : identifierArray[currentIndex];
      let identifierActiveBooking =
        requests[identifierActiveTab].managerNavigation.itemActive;

      this.setState({
        requests,
        identifierActiveTab,
        identifierActiveBooking,
      });
    },

    cancel: (identifier) => {
      let requests = this.state.requests;

      this.setState({
        popupFeedbackData: {
          show: true,
          descriptions: [
            {
              message:
                'O cancelamento da requisição de serviços foi efetuado com sucesso!',
            },
          ],
          events: this.events.popup.feedbacks,
        },
      });

      this.dataSources.serviceRequest.callUpdate({
        identifier,
        id: requests[identifier].id,
        contextId:
          this.state.isAgency && requests[identifier].rootContextId
            ? requests[identifier].rootContextId
            : null,
      });
    },

    select: (identifier) => {
      this.setState({ identifierActiveTab: identifier });
    },

    approveInBatch: (serviceRequests) => {
      return this.dataSources.approveRequest.callApproveRequestInBatch(
        serviceRequests,
      );
    },
    reproveInBatch: (serviceRequests, justification) => {
      return this.dataSources.refuseRequest.callRefuseRequestInBatch(
        serviceRequests,
        justification,
      );
    },
  };

  fillItemsSummaryData(serviceRequest) {
    if (serviceRequest.itemsSummary && serviceRequest.itemsSummary.length) {
      serviceRequest.itemsSummary.forEach((itemSummary) => {
        if (itemSummary.type == enums.products.namedType.airBooking) {
          this.fillAirItemSummaryData(itemSummary);
        }
      });
    }
  }

  fillAirItemSummaryData(itemSummary) {
    if (itemSummary.airTrips && itemSummary.airTrips.length) {
      itemSummary.airTrips.forEach((airTrip) => {
        const airLine = this.state.airLines.find(
          (a) => a.code == airTrip.issuerAirline.code,
        );
        airTrip.issuerAirline.imageUrl = airLine ? airLine.imageUrl : '';
      });
    }
  }

  fillAirLinesDetails(airLines) {}

  formManager = {
    getFields: (form) => {
      const _this = this;
      if (form == enums.actionsForm.createItem)
        return {
          selectItemType: this.formManager.generateField.select({
            label: 'Tipo do item',
            refName: 'selectItemType',
            additionalClass: 'exon-select-item-type',
            dataSource: {
              load: function () {
                return new Promise((resolve) => {
                  let items = listItemsResolver.resolveEnum(
                    enums.products.offlineType,
                  );
                  // Filtra itens com base no valor de isAgency
                  if (!_this.state.isAgency) {
                    items = items.filter((item) => item.value === 3);
                  }
                  resolve(items);
                });
              },
            },
          }),
          locator: this.formManager.generateField.text({
            label: 'Localizador da reserva',
            refName: 'locator',
            visible: false,
          }),
          expirationDate: this.formManager.generateField.mask({
            label: 'Data de expiração',
            refName: 'expirationDate',
            mask: '99/99/9999 99:99',
            placeholder: '00/00/00 00:00',
            removeMask: false,
            visible: false,
          }),
          //system: this.formManager.generateField.text({
          //    label: "Sistema",
          //    refName: "system",
          //    productType: enums.products.type.air,
          //    visible: false
          //}),

          system: this.formManager.generateField.select({
            label: 'Sistema',
            refName: 'system',
            dataSource: this.externalOptions.dataSources.airSuppliers,
            productType: enums.products.type.air,
            visible: false,
          }),

          airline: this.formManager.generateField.select({
            label: 'Companhia Emissora',
            refName: 'airline',
            dataSource: this.externalOptions.dataSources.airLines,
            productType: enums.products.type.air,
            visible: false,
          }),
          broker: this.formManager.generateField.select({
            label: 'Fornecedor',
            refName: 'broker',
            dataSource: {
              load: function () {
                return new Promise((resolve) => {
                  let suppliersList = travelEnums.suppliers.hotel
                    .filter((supplier) => supplier.id != 'all')
                    .map((supplier) => {
                      return { id: supplier.id, name: supplier.text };
                    });
                  resolve(
                    listItemsResolver.resolve(suppliersList, 'id', 'name'),
                  );
                });
              },
            },
            productType: enums.products.type.hotel,
            visible: false,
          }),
          suplierName: this.formManager.generateField.text({
            label: 'Nome do fornecedor',
            refName: 'suplierName',
            productType: enums.products.type.hotel,
            visible: false,
          }),
          //meals: this.formManager.generateField.text({
          //    label: "Refeições inclusas",
          //    refName: "meals",
          //    productType: enums.products.type.hotel,
          //    visible: false
          //}),
          rating: this.formManager.generateField.select({
            label: 'Quantidade de estrelas',
            refName: 'rating',
            dataSource: {
              load: function () {
                return Promise.resolve(listItemsResolver.resolveRange(1, 5));
              },
            },
            productType: enums.products.type.hotel,
            visible: false,
          }),
          bookingTravelers: this.formManager.generateField.select({
            label: 'Viajantes da Reserva',
            refName: 'bookingTravelers',
            dataSource: {
              load: function () {
                return _this.dataSources.rvOffline.travelers.currentSRTravelers();
              },
            },
            isMultiple: true,
            productType: enums.products.type.none,
            visible: false,
          }),
          bookingStatus: this.formManager.generateField.select({
            label: 'Status do Fornecedor',
            refName: 'bookingStatus',
            dataSource: {
              load: function () {
                return new Promise((resolve) => {
                  let listItems = listItemsResolver.resolveEnum(
                    enums.bookingStatus,
                  );
                  resolve(listItems);
                });
              },
            },
            productType: enums.products.type.none,
            visible: false,
          }),
          hotelGuarante: {
            type: enums.fieldType.paymentSelector,
            label: 'Garantia do hotel',
            refName: 'hotelGuarante',
            productType: enums.products.type.hotel,
            visible: false,
            onPaymentSelect:
              this.events.form.formCreateItem.fields.onPaymentSelected,
            disabled: true,
          },
          payment: {
            type: enums.fieldType.paymentSelector,
            label: 'Pagamento',
            refName: 'payment',
            productType: enums.products.type.air,
            visible: false,
            onPaymentSelect:
              this.events.form.formCreateItem.fields.onPaymentSelected,
            disabled: true,
          },
        };
    },

    clearFieldsFormCreateItem: (form) => {
      Object.keys(form.fields).forEach((refName) => {
        form.fields[refName].value = null;

        if (refName != 'selectItemType') form.fields[refName].visible = false;
      });
    },

    generateField: {
      location: (data) => {
        let _this = this;
        return {
          type: enums.fieldType.locations,
          value: {},
          label: data.label,
          refName: data.refName,
          placeholder: `Digite ao menos 3 letras`,
          options: {
            events: {
              onInputChanged: (inputText) => {
                this.events.form.formCreateItem.fields.onLocationChanged(
                  data.refName,
                  inputText,
                );
              },
              onSelected: (selectedData) => {
                this.events.form.formCreateItem.fields.onSelected(
                  data.refName,
                  selectedData,
                );
              },
            },
            dataSource: _this.props.dataSources.locations,
          },
          locationType: enums.locationType.airport,
          disabled: false,
          required: data.required ? data.required : true,
          visible: data.visible,
          productType: data.productType,
        };
      },
      number: (data) => {
        let numberField = this.formManager.generateField.commomFields(data);
        numberField.type = enums.fieldType.number;

        return numberField;
      },
      text: (data) => {
        let textField = this.formManager.generateField.commomFields(data);
        textField.type = enums.fieldType.text;

        return textField;
      },
      select: (data) => {
        let selectField = this.formManager.generateField.commomFields(data);
        selectField.type = enums.fieldType.select;
        selectField.items = [];
        selectField.onFieldChange =
          this.events.form.formCreateItem.fields.onSelected;
        selectField.dataSource = data.dataSource;
        selectField.completeItem = true;
        selectField.isZeroValid = true;
        selectField.isMultiple = data.isMultiple;

        return selectField;
      },
      mask: (data) => {
        let fieldMask = this.formManager.generateField.commomFields(data);
        fieldMask.type = enums.fieldType.mask;
        fieldMask.mask = data.mask;
        data.removeMask !== undefined
          ? (fieldMask.removeMask = data.removeMask)
          : null;

        return fieldMask;
      },
      commomFields: (data) => {
        return {
          placeholder: data.placeholder ? data.placeholder : `Digite aqui`,
          additionalClass: data.additionalClass ? data.additionalClass : null,
          label: data.label,
          refName: data.refName,
          value: data.valeu ? data.value : null,
          required:
            data.required != null || data.required != undefined
              ? data.required
              : true,
          disabled:
            data.disabled != null || data.disabled != undefined
              ? data.disabled
              : false,
          onFieldChange: this.events.form.formCreateItem.fields.onChanged,
          visible: data.visible,
          productType: data.productType,
        };
      },
    },

    fieldsValidateFormCreateItem: () => {
      let isValid = true;
      let fields = this.state.formCreateItem.fields;

      Object.keys(fields).forEach((refName) => {
        if (fields[refName].visible !== false)
          isValid = isValid && fields[refName].value ? true : false;
      });

      if (!isValid)
        this.setState({
          formCreateItem: {
            ...this.state.formCreateItem,
            forceValidation: true,
          },
        });

      return isValid;
    },
  };

  generateIdentifier = function () {
    return 'rv-' + Math.floor(Date.now() * Math.random()).toString(36);
  };

  configureMainTab() {
    let tabData = {};
    let identifier = this.generateIdentifier();

    tabData = {
      id: 'request-list',
      identifier: 'request-list',
      loading: false,
    };

    this.setState({
      identifierActiveTab: identifier,
      mainTabIdentifier: identifier,
    });

    this.requestsManager.update(identifier, tabData);
  }

  currentApproverByApprovalFlow = function (serviceRequest) {
    let isCurrentApprover = false;
    let userId = this.state.userId;
    let itemsOfApprover = serviceRequest.itemsSummary.filter((summary) =>
      summary.workflow.approvers.some((a) => a.userId === userId),
    );

    const getPendingLevels = (approversByLevel) => {
      const levelsPending = [];

      Object.keys(approversByLevel).forEach((levelNumber) => {
        const itemsInLevel = approversByLevel[levelNumber];
        var hasPending =
          itemsInLevel.every(
            (_) =>
              _.status === enums.approvalStatus.pending &&
              !_.costCenterApprover,
          ) ||
          itemsInLevel.some(
            (_) =>
              _.status == enums.approvalStatus.pending && _.costCenterApprover,
          );

        if (hasPending) levelsPending.push(itemsInLevel);
      });

      return levelsPending;
    };

    itemsOfApprover.forEach((summary) => {
      //Agrupa os aprovadores por nivel em arrays diferentes
      const approversByLevel = utils.array.groupBy(
        summary.workflow.approvers,
        'level',
      );

      if (
        approversByLevel &&
        summary.workflow.approvalFlowStatus !== enums.approvalStatus.refused
      ) {
        const allPendingLevels = getPendingLevels(approversByLevel);
        if (!allPendingLevels?.length) return false;

        switch (summary.workflow.approvalFlowMethod) {
          case enums.approvalMode.sequential:
            let currentApprovers = allPendingLevels[0];
            isCurrentApprover = currentApprovers.some(
              (approver) =>
                approver.userId === userId &&
                approver.status === enums.approvalStatus.pending &&
                !approver.costCenterApprover,
            );

            break;
          case enums.approvalMode.parallel:
            isCurrentApprover = allPendingLevels.some((approversInLevel) =>
              approversInLevel.some(
                (approver) =>
                  approver.userId == userId &&
                  approver.status === enums.approvalStatus.pending &&
                  !approver.costCenterApprover,
              ),
            );
            break;
        }
      }
    });

    return isCurrentApprover;
  };

  validateFieldsJustification() {
    let isValid = true;

    isValid =
      isValid &&
      this.state.descriptionJustification != null &&
      this.state.descriptionJustification !== '';

    return isValid;
  }

  notifier = {
    getProps: () => {
      return {
        ...this.state.notifier,
        notificationType: enums.notificationType.email,
        dataSources: {
          serviceRequest: {
            previewTemplate: (args) => {
              if (!args) args = {};

              let serviceRequest =
                this.state.requests[this.state.identifierActiveTab];
              let contextId =
                this.state.isAgency && serviceRequest.rootContextId
                  ? serviceRequest.rootContextId
                  : null;

              args.serviceRequestId = serviceRequest.id;

              const dataSource =
                this.externalOptions.dataSources.serviceRequest;
              dataSource.events &&
                dataSource.events.beforePreviewNotification &&
                dataSource.events.beforePreviewNotification(args);
              return new Promise((resolve, reject) => {
                dataSource
                  .previewNotification(args, contextId)
                  .then((response) => {
                    dataSource.events &&
                      dataSource.events.afterPreviewNotification &&
                      dataSource.events.afterPreviewNotification(
                        args,
                        response,
                      );
                    resolve(response);
                  })
                  .catch((result) => {
                    dataSource.events &&
                      dataSource.events.afterPreviewNotification &&
                      dataSource.events.afterPreviewNotification(args, result);
                    reject(result);
                  });
              });
            },
          },

          serviceRequestItem: {
            previewTemplate: (args) => {
              if (!args) args = {};

              let serviceRequest =
                this.state.requests[this.state.identifierActiveTab];
              let contextId =
                this.state.isAgency && serviceRequest.rootContextId
                  ? serviceRequest.rootContextId
                  : null;

              args.serviceRequestItemId = this.state.notifier.id;

              const dataSource =
                this.externalOptions.dataSources.serviceRequestItem;
              dataSource.events &&
                dataSource.events.beforePreviewNotification &&
                dataSource.events.beforePreviewNotification(args);
              return new Promise((resolve, reject) => {
                dataSource
                  .previewNotification(args, contextId)
                  .then((response) => {
                    dataSource.events &&
                      dataSource.events.afterPreviewNotification &&
                      dataSource.events.afterPreviewNotification(
                        args,
                        response,
                      );
                    resolve(response);
                  })
                  .catch((result) => {
                    dataSource.events &&
                      dataSource.events.afterPreviewNotification &&
                      dataSource.events.afterPreviewNotification(args, result);
                    reject(result);
                  });
              });
            },
          },
        },
      };
    },
  };
}
