let Template = ({ onChange, label, checked }) => {
  return (
    <div className="exon-check-label">
      <div className="exon-input-check">
        <label onChange={(ev) => onChange(ev.target.checked)}>
          <input type="checkbox" readOnly checked={checked} />
          <label>
            <span></span>
          </label>
          <p className="exon-label-field">{label}</p>
        </label>
      </div>
    </div>
  );
};

export default Template;
