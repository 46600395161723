'use strict';

import React from 'react';
import Template from './traveler-information.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';
import { componentUtil } from '@legacy-utils/componentUtil.js';

export default class TravelerInformationsComponent extends React.Component {
  constructor(props) {
    super(props);

    componentUtil.react.bindMethods(this.events, this);

    const { travelInfo } = this.props.travelerInformations;

    this.state = {
      travelerInfos: this.props.travelerInformations,

      editDataPopup: {
        show: false,
        events: this.events.editDataPopup,
      },

      costCenterData: {
        inputText: (travelInfo.costCenter && travelInfo.costCenter.name) || '',
        placeholder: 'Insira o centro de custo da viagem',
        events: this.events.costCenter,
        selected: 0,
        dataSource: this.props.dataSources.costCenter,
      },

      requesterData: {
        inputText: travelInfo.requester.name,
        placeholder: 'Insira o solicitante da viagem',
        events: this.events.requester,
        selected: 0,
        dataSource: this.props.dataSources.travelers,
      },

      reasonTravelData: {
        selected: {
          value: travelInfo.travelReason ? travelInfo.travelReason.id : 0,
          label: travelInfo.travelReason ? travelInfo.travelReason.name : '',
        },
        placeholder: 'carregando motivos da viagem...',
        dataSource: this.props.dataSources.travelReason,
        events: this.events.reasonTravel,
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          travelerInfos={this.state.travelerInfos}
          canEdit={this.props.canEdit}
          costCenterData={this.state.costCenterData}
          requesterData={this.state.requesterData}
          reasonTravelData={this.state.reasonTravelData}
          editDataPopup={this.state.editDataPopup}
          goToSplit={this.events.goToSplit}
        />
      </ErrorBoundary>
    );
  }

  events = {
    goToSplit: () => {
      this.props.splitRef.current.scrollIntoView();
    },
    editDataPopup: {
      onOpenClicked: () => {
        let editDataPopup = this.state.editDataPopup;
        editDataPopup.show = true;

        this.setState({ editDataPopup });
      },

      onCloseClicked: () => {
        this.setState({
          requesterData: {
            ...this.state.requesterData,
            inputText: this.state.travelerInfos.travelInfo.requester.name,
            selected: 0,
          },
          costCenterData: {
            ...this.state.costCenterData,
            inputText: this.state.travelerInfos.travelInfo.costCenter.name,
            selected: 0,
          },
          reasonTravelData: {
            ...this.state.reasonTravelData,
            selected: this.state.travelerInfos.travelInfo.travelReason.id,
          },
          editDataPopup: {
            ...this.state.editDataPopup,
            show: false,
          },
        });
      },

      onSaveClicked: () => {
        this.events.editDataPopup.onCloseClicked();
        let travelerInfos = this.state.travelerInfos;

        travelerInfos.travelInfo = {
          ...this.state.travelerInfos,
          costCenter: {
            id: this.state.costCenterData.selected,
            name: this.state.costCenterData.inputText,
          },
          requester: {
            id: this.state.requesterData.selected,
            name: this.state.requesterData.inputText,
          },
          travelReason: {
            id: this.state.reasonTravelData.selected.value,
            name: this.state.reasonTravelData.selected.label,
          },
        };

        // enviar para travel-request
      },
    },

    costCenter: {
      onChanged: (selectedItem) => {
        this.setState({
          costCenterData: {
            ...this.state.costCenterData,
            inputText: selectedItem.label,
            selected: selectedItem.value,
          },
        });
      },

      onInputChanged: (value) => {
        this.setState({
          costCenterData: {
            ...this.state.costCenterData,
            inputText: value,
          },
        });
      },
    },

    requester: {
      onChanged: (selectedItem) => {
        this.setState({
          requesterData: {
            ...this.state.requesterData,
            inputText: selectedItem.label,
            selected: selectedItem.value,
          },
        });
      },

      onInputChanged: (value) => {
        this.setState({
          requesterData: {
            ...this.state.requesterData,
            inputText: value,
          },
        });
      },
    },

    reasonTravel: {
      onSelected: (selectedItem) => {
        this.setState({
          reasonTravelData: {
            ...this.state.reasonTravelData,
            selected: {
              value: selectedItem.value,
              label: selectedItem.label,
            },
          },
        });
      },
    },
  };
}
