import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { resolveCompanies } from '@legacy-dataSources/profile.dataSources';
import {
  dataSources as saleDataSources,
  setLoader,
} from '@legacy-dataSources/sale.dataSources';

import { ILoader } from '@shared/loader';
import { UserProfileData } from '@shared/types';
import { Loading } from '@components/Loading/Loading';

import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import Quotes from '@legacy-components/quotes/quotes.component';

import PageBase from '../PageBase';

const Page = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const loader: ILoader = {
    toggleLoading(isLoading) {
      setLoading(isLoading);
    },
  };
  setLoader(loader);

  const [userProfile, setUserProfile] = useState<UserProfileData>(null);
  const dataSources = {
    ...saleDataSources,
    companies: { load: async () => resolveCompanies(userProfile) },
  };

  return (
    <PageBase
      pageTitleKey="pages.sale.title"
      userProfileLoaded={(profileData) => setUserProfile(profileData)}
      skeleton={<Skeleton variant="rectangular" height="556px" width="371px" />}
      showAddTravelRequest={true}
    >
      {loading && <Loading />}
      {userProfile && (
        <Quotes
          externalOptions={{
            agency: {
              isAgency: userProfile.company.isAgency,
              travelProfile: userProfile.travelProfile,
            },
            user: userProfile,
            dataSources,
          }}
          navigate={navigate}
        />
      )}
    </PageBase>
  );
};

export default Page;
