import SelectComponent from '../select/select.component.js';

let PassengersTemplate = {
  main: ({
    adultProps,
    childrenProps,
    infantProps,
    labels,
    childrenVisibility,
    onOpenChildrenClicked,
  }) => (
    <div className="exon-passengers">
      <div className="exon-passengers__box-field">
        <label>
          {!childrenVisibility ? labels.passageiros : labels.adultos}
        </label>
        <SelectComponent
          data={adultProps.data}
          externalOptions={adultProps.options}
          placeholder="0"
        ></SelectComponent>
      </div>
      <div
        className={
          'exon-passengers__box-add ' + (childrenVisibility ? 'hide' : '')
        }
      >
        {childrenProps && (
          <a onClick={onOpenChildrenClicked}>{labels.adicionarCriancas}</a>
        )}
      </div>
      {childrenProps && (
        <div
          className={
            'exon-passengers__box-field ' + (!childrenVisibility ? 'hide' : '')
          }
        >
          <label>{labels.criancas}</label>
          <SelectComponent
            data={childrenProps.data}
            externalOptions={childrenProps.options}
            placeholder="0"
          ></SelectComponent>
        </div>
      )}
      {infantProps && (
        <div
          className={
            'exon-passengers__box-field ' + (!childrenVisibility ? 'hide' : '')
          }
        >
          <label>{labels.bebes}</label>
          <SelectComponent
            data={infantProps.data}
            externalOptions={infantProps.options}
            placeholder="0"
          ></SelectComponent>
        </div>
      )}
    </div>
  ),
};

export default PassengersTemplate;
