'use strict';

import React from 'react';
import Template from './booking-air-tickets.template.js';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component.js';
import { enums } from '@legacy-utils/enums';
import { listItemsResolver } from '@legacy-utils/resolvers/listItemresolver.js';
import { utils, gritter } from '@legacy-utils/utils';
import { componentUtil } from '@legacy-utils/componentUtil.js';

export default class BookingAirTicketsComponent extends React.Component {
  constructor(props) {
    super(props);

    //Necessário para que o "this" dentro da function seja o contexto do componente atual (caso contrário, this = undefined)
    componentUtil.react.bindMethods(this.events, this);

    this.state = {
      selectedTicketIndex: null,
      airTickets: this.props.airTickets,
      airTicketItem: null,

      form: {
        show: false,
        events: this.events.form,
        fields: this.formManager.getFields(),
        editIndex: null,
        forceValidation: false,
      },

      airTicketItemEdit: {
        type: enums.products.namedType.airTicket,
        airTrips: [],
        fare: {
          travelerValue: {},
        },
      },
    };
  }

  render() {
    return (
      <ErrorBoundary>
        <Template.main
          airTickets={this.state.airTickets}
          bookingLocator={this.props.bookingLocator}
          allowedActions={this.props.allowedActions}
          connectionSource={this.props.connectionSource}
          airTicketItem={this.state.airTicketItem}
          airTicketItemEdit={this.state.airTicketItemEdit}
          canEdit={this.props.canEdit}
          selectedTicketIndex={this.state.selectedTicketIndex}
          form={this.state.form}
          dataSources={this.props.dataSources}
          events={{
            ...this.events,
            onSendAppsClicked: this.props.onSendAppsClicked,
          }}
          onShowEmailPopupClick={this.props.onShowEmailPopupClick}
        />
      </ErrorBoundary>
    );
  }

  events = {
    form: {
      popup: {
        onOpenFormPopupClicked: (action) => {
          let form = this.state.form;
          let airTicketItemEdit = this.state.airTicketItemEdit;

          if (action == enums.actionsForm.edit) {
            airTicketItemEdit = JSON.parse(
              JSON.stringify(this.state.airTicketItem),
            );
            this.formManager.filledFields(form, airTicketItemEdit);
          } else {
            airTicketItemEdit = {
              type: 'airTicket',
              airTrips: [],
              fare: {
                travelerValue: {},
              },
            };
          }

          form.show = true;
          form.action = action;
          this.setState({ form, airTicketItemEdit });
        },

        onCloseFormPopupClicked: () => {
          let form = this.state.form;

          this.formManager.clearFields(form);

          this.setState({
            form: {
              ...this.state.form,
              show: false,
              forceValidation: false,
              editIndex: null,
            },
          });
        },

        onConfirmPopupClicked: () => {
          let airTicketItemEdit = this.state.airTicketItemEdit;
          let action = this.state.form.action;

          if (this.formManager.fieldsValidate()) {
            this.formManager.prepareForShipping(airTicketItemEdit);
            if (airTicketItemEdit.airTrips.length == 0) {
              gritter.Warning('Adicionar seguimento para proseguir');
              return;
            }

            if (action == enums.actionsForm.edit)
              this.props.onEditAirTicketClick &&
                this.props.onEditAirTicketClick(airTicketItemEdit);
            else
              this.props.onAddAirTicketClick &&
                this.props.onAddAirTicketClick(airTicketItemEdit);

            this.setState({
              form: {
                ...this.state.form,
                show: false,
                forceValidation: false,
                editIndex: null,
              },
            });
          }
        },
      },

      fields: {
        onLocationChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value.name = inputText;

          this.setState({ form });
        },

        onChanged: (refName, inputText) => {
          let form = this.state.form;
          form.fields[refName].value = inputText;

          this.setState({ form });
        },

        onSelected: (refName, selectedData) => {
          let form = this.state.form;
          form.fields[refName].value = selectedData;

          this.setState({ form });
        },
      },
    },

    onSelectedTicketClicked: (selectedIndex) => {
      let selectedTicketIndex = this.state.selectedTicketIndex;
      let index = selectedTicketIndex == selectedIndex ? null : selectedIndex;

      if (index != undefined) {
        let airTicket = this.props.airTickets[index];
        this.dataSources.callLoadAirTicketDetails(airTicket);
      }

      this.setState({
        selectedTicketIndex: index,
      });
    },

    onReplaceButtonClicked: (airTicket) => {
      this.props.onReplaceButtonClick &&
        this.props.onReplaceButtonClick(airTicket.number);
    },

    onCancelButtonClicked: (airTicket) => {
      this.dataSources.callCancelAirTicket(airTicket.id);
    },

    onEditSegmentsClicked: (airTrips) => {
      let airTicketItemEdit = this.state.airTicketItemEdit;
      airTicketItemEdit.airTrips = airTrips;

      this.setState({ airTicketItemEdit });
    },
  };

  dataSources = {
    callLoadAirTicketDetails: (airTicketSummary) => {
      const _this = this;
      const dataSource = this.props.dataSources.airTicket;

      dataSource.events.beforeLoad && dataSource.events.beforeLoad();

      dataSource
        .load({ serviceRequestItemId: airTicketSummary.id })
        .then((response) => {
          dataSource.events.afterLoad && dataSource.events.afterLoad();

          this.setState({
            airTicketItem: {
              ...airTicketSummary,
              ...response.serviceRequestItem,
            },

            airTicketItemEdit: {
              ...airTicketSummary,
              ...response.serviceRequestItem,
            },
          });
        });
    },

    callCancelAirTicket: (airTicketId) => {
      const dataSource = this.props.dataSources.airTicket;

      dataSource.events.beforeLoad && dataSource.events.beforeLoad();

      dataSource.cancel(airTicketId).then((response) => {
        dataSource.events.afterLoad && dataSource.events.afterLoad();
      });
    },
  };

  formManager = {
    getFields: () => {
      let fields = {};
      let _this = this;
      let labelCurrencyCode = 'Código da moeda';

      fields.traveler = this.formManager.generateField.select({
        label: 'Passageiro',
        refName: 'traveler',
        groupType: 'main',
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolve(
                _this.props.travelersList,
                'id',
                'name',
              ),
            );
          },
        },
      });
      fields.ticketNumber = this.formManager.generateField.text({
        label: 'Numero do bilhete',
        refName: 'ticketNumber',
        groupType: 'main',
      });
      fields.issuedAt = this.formManager.generateField.mask({
        label: 'Data da emissão (dd/mm/aaaa',
        refName: 'issuedAt',
        mask: '99/99/9999',
        placeholder: '00/00/00',
        removeMask: false,
        groupType: 'main',
      });
      fields.bookingLocator = this.formManager.generateField.text({
        label: 'Localizador',
        refName: 'bookingLocator',
        visible: false,
        groupType: 'main',
        value: this.props.bookingLocator,
      });
      //Tarifa Neto
      fields.originalFareCurrencyCode = this.formManager.generateField.select({
        label: `${labelCurrencyCode} (Tarifa Neto)`,
        refName: 'originalFareCurrencyCode',
        groupType: 'currencyCode',
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolveEnum(enums.currencyCode),
            );
          },
        },
      });
      fields.originalFare = this.formManager.generateField.number({
        label: 'Tarifa Neto',
        refName: 'originalFare',
        groupType: 'fare',
      });
      //Tarifa Equivalente
      fields.fareCurrencyCode = this.formManager.generateField.select({
        label: `${labelCurrencyCode} (Tarifa Equivalente)`,
        refName: 'fareCurrencyCode',
        groupType: 'currencyCode',
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolveEnum(enums.currencyCode),
            );
          },
        },
      });
      fields.fare = this.formManager.generateField.number({
        label: 'Tarifa Equivalente',
        refName: 'fare',
        groupType: 'fare',
      });

      //Tarifa DU
      fields.duCurrencyCode = this.formManager.generateField.select({
        label: `${labelCurrencyCode} (Tarifa DU)`,
        refName: 'duCurrencyCode',
        groupType: 'currencyCode',
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolveEnum(enums.currencyCode),
            );
          },
        },
      });
      fields.du = this.formManager.generateField.number({
        label: 'Tarifa DU',
        refName: 'du',
        groupType: 'fare',
      });
      //Taxas
      fields.taxesCurrencyCode = this.formManager.generateField.select({
        label: `${labelCurrencyCode} (Taxas)`,
        refName: 'taxesCurrencyCode',
        groupType: 'currencyCode',
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolveEnum(enums.currencyCode),
            );
          },
        },
      });
      fields.taxes = this.formManager.generateField.number({
        label: 'Taxas',
        refName: 'taxes',
        groupType: 'fare',
      });

      fields.exchangeRateCurrencyCode = this.formManager.generateField.select({
        label: `${labelCurrencyCode} (Cambio)`,
        refName: 'exchangeRateCurrencyCode',
        groupType: 'currencyCode',
        dataSource: {
          load: function () {
            return Promise.resolve(
              listItemsResolver.resolveEnum(enums.currencyCode),
            );
          },
        },
      });
      fields.exchangeRate = this.formManager.generateField.number({
        label: 'Cambio',
        refName: 'exchangeRate',
        groupType: 'exchange',
      });

      fields.fareBasis = this.formManager.generateField.text({
        label: 'Base Tarifária',
        refName: 'fareBasis',
        groupType: 'fare',
      });

      return fields;
    },

    filledFields: (form, airTicketItemEdit) => {
      let getFormattedDateObjFromDateObject =
        utils.formatters.date.getFormattedDateObjFromDateObject;

      Object.keys(form.fields).forEach((refName) => {
        let field = form.fields[refName];

        if (refName == 'traveler')
          field.value = {
            value: airTicketItemEdit[refName].id,
            label: airTicketItemEdit[refName].name
              ? airTicketItemEdit[refName].name
              : `${airTicketItemEdit[refName].firstName} ${airTicketItemEdit[refName].lastName}`,
          };

        if (field.groupType == 'fare') {
          if (refName == 'fareBasis')
            field.value = airTicketItemEdit.fare
              ? airTicketItemEdit.fare[refName]
              : null;
          else {
            field.value =
              airTicketItemEdit.fare &&
              airTicketItemEdit.fare.travelerValue &&
              airTicketItemEdit.fare.travelerValue[refName].value
                ? airTicketItemEdit.fare.travelerValue[refName].value
                : null;
            form.fields[`${refName}CurrencyCode`].value =
              airTicketItemEdit.fare &&
              airTicketItemEdit.fare.travelerValue &&
              airTicketItemEdit.fare.travelerValue[refName].currencyCode
                ? enums.currency[
                    airTicketItemEdit.fare.travelerValue[refName].currencyCode
                  ]
                : null;
          }
        }
        if (field.groupType == 'exchange') {
          field.value =
            airTicketItemEdit.fare && airTicketItemEdit.fare.exchangeRate
              ? airTicketItemEdit.fare.exchangeRate.value
              : null;
          form.fields[`${refName}CurrencyCode`].value =
            airTicketItemEdit.fare && airTicketItemEdit.fare.exchangeRate
              ? enums.currency[airTicketItemEdit.fare.exchangeRate.origin]
              : null;
        }

        if (refName == 'issuedAt')
          field.value = airTicketItemEdit[refName]
            ? getFormattedDateObjFromDateObject(airTicketItemEdit[refName])
                .dateFormatted
            : null;

        if (refName == 'ticketNumber' || refName == 'locator')
          field.value = airTicketItemEdit[refName]
            ? airTicketItemEdit[refName]
            : null;
      });
    },

    clearFields: (form) => {
      Object.keys(form.fields).forEach((refName) => {
        if (refName !== 'locator' && refName !== 'bookingLocator')
          form.fields[refName].value = null;
      });
    },

    generateField: {
      location: (data) => {
        let _this = this;
        return {
          type: enums.fieldType.locations,
          value: {},
          label: data.label,
          refName: data.refName,
          placeholder: `Digite ao menos 3 letras`,
          options: {
            events: {
              onInputChanged: (inputText) => {
                this.events.form.fields.onLocationChanged(
                  data.refName,
                  inputText,
                );
              },
              onSelected: (selectedData) => {
                this.events.form.fields.onSelected(data.refName, selectedData);
              },
            },
            dataSource: _this.props.dataSources.locations,
          },
          locationType: enums.locationType.airport,
          disabled: false,
          required: data.required ? data.required : true,
        };
      },
      number: (data) => {
        let numberField = this.formManager.generateField.commomFields(data);
        numberField.type = enums.fieldType.number;

        return numberField;
      },
      text: (data) => {
        let textField = this.formManager.generateField.commomFields(data);
        textField.type = enums.fieldType.text;

        return textField;
      },
      select: (data) => {
        let selectField = this.formManager.generateField.commomFields(data);
        selectField.type = enums.fieldType.select;
        selectField.items = [];
        selectField.onFieldChange = this.events.form.fields.onSelected;
        selectField.dataSource = data.dataSource;
        selectField.completeItem = true;
        selectField.isZeroValid = true;

        return selectField;
      },
      mask: (data) => {
        let fieldMask = this.formManager.generateField.commomFields(data);
        fieldMask.type = enums.fieldType.mask;
        fieldMask.mask = data.mask;
        data.removeMask !== undefined
          ? (fieldMask.removeMask = data.removeMask)
          : null;

        return fieldMask;
      },
      commomFields: (data) => {
        return {
          placeholder: data.placeholder ? data.placeholder : `Digite aqui`,
          label: data.label,
          refName: data.refName,
          value: data.value ? data.value : null,
          required:
            data.required != null || data.required != undefined
              ? data.required
              : true,
          disabled:
            data.disabled != null || data.disabled != undefined
              ? data.disabled
              : false,
          onFieldChange: this.events.form.fields.onChanged,
          groupType: data.groupType ? data.groupType : null,
          readOnly: data.readOnly,
        };
      },
    },

    fieldsValidate: () => {
      let isValid = true;
      let fields = this.state.form.fields;

      Object.keys(fields).forEach((refName) => {
        let fieldValue = fields[refName].value;
        isValid =
          isValid &&
          fieldValue !== null &&
          fieldValue !== undefined &&
          fieldValue !== '';
      });

      if (!isValid)
        this.setState({
          form: {
            ...this.state.form,
            forceValidation: true,
          },
        });

      return isValid;
    },

    prepareForShipping: (airTicketItemEdit) => {
      let fields = this.state.form.fields;

      Object.keys(fields).forEach((refName) => {
        let field = fields[refName];
        airTicketItemEdit.airBookingId = this.props.airBookingId;
        airTicketItemEdit.type = 'airTicket';

        switch (field.groupType) {
          case 'fare':
            if (refName != 'fareBasis') {
              airTicketItemEdit.fare.travelerValue[refName] = {
                currencyCode:
                  enums.currencyCode[
                    fields[`${refName}CurrencyCode`].value.value
                  ],
                value: parseFloat(fields[refName].value),
              };
            } else {
              airTicketItemEdit.fare[refName] = fields[refName].value;
            }
            break;
          case 'main':
            if (refName == 'issuedAt')
              airTicketItemEdit[refName] =
                utils.converters.date.stringToDateObject(field.value);
            else airTicketItemEdit[refName] = field.value;
            break;
          case 'exchange':
            airTicketItemEdit.fare.exchangeRate = {
              origin:
                enums.currencyCode[
                  fields[`${refName}CurrencyCode`].value.value
                ],
              value: parseFloat(fields[refName].value),
            };
        }
      });
    },
  };
}
