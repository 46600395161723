let Template = ({ statusInfos }) => {
  let lockedTooltip = null;
  let triggerTooltip = null;

  let locked = statusInfos.lock && statusInfos.lock.locked;
  let lockedBy = '';
  let lockTriggeredBy = '';

  if (locked) {
    lockedBy = statusInfos.lock.lockedBy
      ? ` por '${statusInfos.lock.lockedBy.name}'`
      : '';
    lockTriggeredBy = statusInfos.lock.lockedBy
      ? ` pelo processo '${statusInfos.lock.triggeredBy}'`
      : '';

    lockedTooltip = `Item bloqueado${lockedBy}${lockTriggeredBy}.`;
  }

  if (statusInfos.status && statusInfos.status.hasAutomaticAction)
    triggerTooltip = `Possui uma ação automática atribuída ao status`;

  return (
    <div className="exon-booking-status-bar">
      <p>
        {'Status:  ' +
          (statusInfos.status && statusInfos.status.name
            ? statusInfos.status.name
            : 'Status não informado')}
        {lockedTooltip && (
          <span className="exon-icon exon-icon-locked-filled_enabled exon-tooltip">
            <span className="tooltip">{lockedTooltip}</span>
          </span>
        )}
        {triggerTooltip && (
          <span className="exon-icon exon-icon-lightning-filled_enabled exon-tooltip">
            <span className="tooltip">{triggerTooltip}</span>
          </span>
        )}
      </p>
      {!statusInfos.ignoreExpiration && (
        <p>
          Data da expiração: {statusInfos.expirationDate}
          {statusInfos.canEdit &&
            (<span
              className="exon-icon exon-icon-button exon-icon-edit-unfilled_enabled"
              onClick={() => statusInfos.onEditClick && statusInfos.onEditClick()}>
            </span>)}
        </p>
      )}
      {statusInfos.issueDate && <p>Data de emissão: {statusInfos.issueDate}</p>}
    </div>
  );
};

export default Template;
