import Carrousel from '../../../carroussel/carroussel.component.js';

var Template = {
  main: ({ hotel, actions }) => {
    return (
      <div className="exon-hotel-option-details">
        <div className="exon-hotel-option-details-box">
          <div className="exon-row-itens">
            <Template.images data={hotel.images} />

            <div className="exon-hotel-option-details-box-info">
              <Template.details.hotelName data={hotel.name} />

              <Template.details.evaluation data={hotel.evaluation} />

              <Template.details.services data={hotel.services} />

              <Template.details.localization data={hotel.localization} />
            </div>
          </div>

          <div className="exon-hotel-option-details-box-info">
            <Template.details.description data={hotel.description} />

            {actions && <Template.details.actions data={actions} />}
          </div>
        </div>
      </div>
    );
  },

  images: ({ data }) => {
    return (
      <div className="exon-hotel-option-details-images">
        {data.length > 0 && (
          <Carrousel
            autoplay={data.length > 1}
            autoPlaySpeed={5000}
            showDots={data.length > 1}
            arrows={false}
            items={
              data &&
              data.map((url, indexImg) => {
                return (
                  <div key={indexImg} className="exon-carroussel-item">
                    <img className="exon-img-hotel" src={url} />
                  </div>
                );
              })
            }
          />
        )}

        {data.length == 0 && (
          <img
            className="exon-img-hotel"
            src={require('../../../../assets/images/thumb-hotel-placeholder.svg')}
          />
        )}
      </div>
    );
  },

  details: {
    hotelName: ({ data }) => {
      return (
        <div className="exon-hotel-option-details-hotel-name">
          <h6>{data}</h6>
        </div>
      );
    },

    evaluation: ({ data }) => {
      let stars = [];

      for (let i = 1; i <= 5; i++) {
        stars.push(
          <span
            className={`exon-icon ${
              data.rating >= i
                ? 'exon-icon-star-filled_enabled'
                : 'exon-icon-star-no-border-unfilled_disabled'
            }`}
            key={i}
          ></span>,
        );
      }

      return (
        <div className="exon-hotel-option-details-evaluation">
          <div className="exon-hotel-option-details-evaluation-stars">
            {stars}
          </div>
          <div className="exon-hotel-option-details-evaluation-source">
            <p>{data.source}</p>
          </div>
        </div>
      );
    },

    services: ({ data }) => {
      return (
        <div className="exon-hotel-option-details-services">
          <p>
            Incluso:
            <br />
            <span>{data}</span>
          </p>
        </div>
      );
    },

    localization: ({ data }) => {
      return (
        <div className="exon-hotel-option-details-localization">
          <span className="exon-icon exon-icon-city-unfilled_enabled"></span>
          <p>{data}</p>
        </div>
      );
    },

    description: ({ data }) => {
      return (
        <div className="exon-hotel-option-details-description">
          <p>{data}</p>
          {data && <span>ver mais</span>}
        </div>
      );
    },

    actions: ({ data }) => {
      return <div className="exon-hotel-option-details-actions">{data}</div>;
    },
  },
};

export default Template;
