import { Section } from '@components/Section';
import { WizardStep } from '@components/Wizard/components/WizardStep';
import PolicyActionField from './PolicyActionField';
import { PolicyConditions } from './PolicyConditions';
import PolicyRules from './PolicyRules';

export const PolicyProperties = () => {
  return (
    <WizardStep
      title="Propriedades da política"
      description="Selecione quando a política será aplicada, e quais regras determinarão a aplicação. Nenhuma regra é obrigatória, mas a política deve conter ao menos uma regra."
    >
      <Section
        title="Condições de aplicação da política"
        caption="Defina, através das condicionais selecionadas, para quais grupos de pessoas essa política será aplicada. Quanto mais específicas as condicionais, mais prioritária será a política."
      >
        <PolicyConditions />
      </Section>

      <Section
        title="Ação"
        caption="Defina qual ação deverá ocorrer em caso de violação da política"
        showDivider
      >
        <PolicyActionField />
      </Section>

      <Section
        title="Regras da Política"
        caption="Defina as regras para restringir as opções de viagens para esta política"
        showDivider
      >
        <PolicyRules />
      </Section>
    </WizardStep>
  );
};
