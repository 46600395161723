'use strict';

import React from 'react';
import Template from './hotel-address.template.js';

export default class HotelAddressComponent extends React.Component {
  render() {
    return <Template {...this.props} />;
  }
}
