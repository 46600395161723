let Template = (props) => {
  return (
    <a
      className={`exon-link ${props.className}`}
      onClick={(e) => {
        e.stopPropagation();
        return props.onClick ? props.onClick(e) : null;
      }}
      target={props.newTab ? '_blank' : ''}
      href={props.href}
    >
      {props.description}
    </a>
  );
};

export default Template;
