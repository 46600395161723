'use strict';

import React from 'react';
import Template from './wizard-step.template.js';

export default class WizardStepComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Template {...this.props} />;
  }
}
