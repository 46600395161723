import { ModalHeaderRoot } from './ModalHeaderRoot';
import { ModalHeaderSubtitle } from './ModalHeaderSubtitle';
import { ModalHeaderTitle } from './ModalHeaderTitle';
import { ModalHeaderUpperItem } from './ModalHeaderUpperItem';
import { ModalIconHeader } from './ModalIconHeader';

export const ModalHeader = {
  Root: ModalHeaderRoot,
  Subtitle: ModalHeaderSubtitle,
  Title: ModalHeaderTitle,
  Icon: ModalIconHeader,
  UpperItem: ModalHeaderUpperItem,
};
