import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StepTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const StepDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
