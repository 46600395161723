import { RoundedIcon } from '@components/RoundedIcon';
import { FlashLogo } from '@components/FlashLogo';
import { Modal } from '../../../../../components/Modal';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { enums } from '@legacy-utils/enums';
const { Root, Body } = Modal;
const { Root: Header, Title } = Modal.Header;
const { Root: Footer, ActionButton, DismissButton } = Modal.Footer;

const FlashCard = ({ paymentForms }) => {
  const getItemName = (productType) => {
    switch (productType) {
      case enums.purchaseConfiguration.product.air:
        return 'Aéreo';
      case enums.purchaseConfiguration.product.hotel:
        return 'Hotel';
      default:
        return 'Item';
    }
  };

  return paymentForms.map((payment, i) => {
    const itemName = getItemName(payment.productType);

    return (
      <div key={i} className="exon-model-process-booking-mold-card">
        {payment.productType != 0 && (
          <p>
            Para o item <b>{itemName}</b> foi gerado o seguinte cartão virtual
          </p>
        )}
        <div className="exon-model-process-booking-flash-card">
          <div className="exon-model-process-booking-flash-card-logo">
            <FlashLogo height="20" width="56"></FlashLogo>
          </div>
          <div className="exon-model-process-booking-flash-card-info">
            <p>
              {payment.creditCard.cardName} - Final {payment.creditCard.number}
            </p>
            <p>{payment.creditCard.printedName}</p>
          </div>

          {payment.flashPayment &&
            payment.flashPayment.balance &&
            payment.flashPayment.balance > 0 && (
              <div className="exon-model-process-booking-flash-card-value">
                <p className="exon-model-process-booking-flash-card-value-coin">
                  R$ {payment.flashPayment.balance}
                </p>
                <p className="exon-model-process-booking-flash-card-value-text">
                  Valor disponível
                </p>
              </div>
            )}
        </div>
      </div>
    );
  });
};

export const PopupProcessBooking = ({ data }) => {
  return (
    <Root
      size="md"
      open={data.show}
      className="exon-model-process-booking-root"
    >
      <Header
        className="exon-model-process-booking-header"
        hasDismissButton={true}
        onCloseClick={() => data.events.onCloseClick()}
      >
        <></>
      </Header>
      <Body className="exon-model-process-booking-body">
        <span className="exon-model-process-booking-body-icon">
          <RoundedIcon icon={data.icon} variant={data.variant} />
        </span>
        <Title textAlign={'center'}>{data.title}</Title>

        {data.description && (
          <p className="exon-model-process-booking-body-description">
            {data.description}
          </p>
        )}

        {data.paymentForms && data.paymentForms.length > 0 && (
          <div className="exon-model-process-booking-flash">
            <FlashCard paymentForms={data.paymentForms} />
          </div>
        )}

        {data.subDescription && (
          <p className="exon-model-process-booking-body-description">
            {data.subDescription}
          </p>
        )}
      </Body>

      <Footer center={true} className="exon-model-process-booking-footer">
        <DismissButton onCancelClick={() => data.events.onDismissButtonClick()}>
          {data.buttons.hasIconRefresh && (
            <Icons name="IconRefresh" fill="transparent" size={16} />
          )}{' '}
          {data.buttons.dismissButtonText}
        </DismissButton>

        <ActionButton
          onActionClick={() => data.events.onActionButtonClick()}
          actionDisabled={data.buttons.isActionButtonDisabled}
          tooltipButtonAction={
            data.buttons.tooltipActionDescription
              ? data.buttons.tooltipActionDescription
              : ''
          }
          showTooltipButtonAction={data.buttons.isActionButtonDisabled}
        >
          {data.buttons.actionButtonText}
        </ActionButton>
      </Footer>
    </Root>
  );
};
