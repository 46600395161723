'use strict';

import React from 'react';
import BookingItemAnalysisTemplate from './booking-item-analysis.template.js';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component.js';

export default class BookingItemAnalysisComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ErrorBoundary>
        <BookingItemAnalysisTemplate
          action={this.props.action ? this.props.action : null}
        />
      </ErrorBoundary>
    );
  }
}
