import styled from 'styled-components';

export const RequestContainer = styled.div`
  overflow: auto;
  max-height: 200px;
  padding-right: 20px;
  @media (min-height: 600px) {
    max-height: 300px;
  }
  @media (min-height: 900px) {
    max-height: 500px;
  }
`;
