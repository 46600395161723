import { enums } from '../../../utils/enums.js';
import { listItemsResolver } from '../../../utils/resolvers/listItemresolver.js';
import AutoComplete from '../../common/auto-complete/auto-complete.component.js';
import Locations from '../../locations/locations.component.js';
import Popup from '../../popup/popup.component.js';
import Select from '../../select/select.component.js';
import Textbox from '../../textbox/textbox.component.js';

var TravelerTemplate = {
  main: ({
    displayDetailsTravel,

    traveler,
    travelerType,
    confirmedTraveler,
    disabled,
    autoCompleteInputText,

    genderOptions,
    documentOptions,
    documentTypeOptions,
    documentTypeAdditionalOptions,
    honorificPrefixOptions,
    socialHonorificPrefixOptions,
    birthDateOptions,
    label,

    showDocumentType,
    showValidationsResults,
    showValidationsFieldsExternal,
    additionalField,
    travelerNumber,
    dataSource,

    eventsName,
    eventsBirthDate,
    eventsNationality,
    eventsPhone,
    eventsEmail,
    eventsDocument,
    eventsDocumentAdditional,

    nationalityDataSource,

    onAutoCompleteChange,
    onAutoCompleteInputChange,

    onCloseTravelerClick,
    onConfirmTravelerClick,

    needCompleteAddress,
    eventsAddress,
    isWoobaAndInternacional
  }) => {
    return (
      <div className="exon-traveler">
        <div className="exon-box-fields-row">
          <div className="exon-box-field">
            <label className="exon-label-field">
              {label ||
                (confirmedTraveler?.labelToShow
                  ? 'Viajante ' + travelerNumber
                  : 'Informar viajante ' + travelerNumber)}
            </label>
            <div className="exon-field">
              <AutoComplete
                selectedItem={
                  confirmedTraveler
                    ? listItemsResolver.resolveItem(
                        confirmedTraveler,
                        'reference',
                        'labelToShow',
                      )
                    : null
                }
                inputText={autoCompleteInputText}
                placeholder={'Digite no mínimo 3 letras.'}
                dataSource={dataSource}
                required={true}
                onChange={onAutoCompleteChange}
                onInputChange={onAutoCompleteInputChange}
                forceValidation={showValidationsFieldsExternal}
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        {displayDetailsTravel && (
          <Popup
            okButtonLabel={'Confirmar'}
            cancelButtonLabel={null}
            title={null}
            exonStyle={true}
            cancelButtonClick={onCloseTravelerClick}
            okButtonClick={onConfirmTravelerClick}
          >
            <TravelerTemplate.detaisTraveler
              traveler={traveler}
              travelerType={travelerType}
              genderOptions={genderOptions}
              documentOptions={documentOptions}
              documentTypeOptions={documentTypeOptions}
              honorificPrefixOptions={honorificPrefixOptions}
              socialHonorificPrefixOptions={socialHonorificPrefixOptions}
              birthDateOptions={birthDateOptions}
              showValidationsResults={showValidationsResults}
              additionalField={additionalField}
              nationalityDataSource={nationalityDataSource}
              showDocumentType={showDocumentType}
              eventsName={eventsName}
              eventsBirthDate={eventsBirthDate}
              eventsNationality={eventsNationality}
              eventsPhone={eventsPhone}
              eventsEmail={eventsEmail}
              eventsDocument={eventsDocument}
              needCompleteAddress={needCompleteAddress}
              eventsAddress={eventsAddress}
              isWoobaAndInternacional={isWoobaAndInternacional}
              eventsDocumentAdditional={eventsDocumentAdditional}
              documentTypeAdditionalOptions={documentTypeAdditionalOptions}
            />
          </Popup>
        )}
      </div>
    );
  },

  detaisTraveler: ({
    traveler,
    travelerType,

    genderOptions,
    documentOptions,
    documentTypeOptions,
    honorificPrefixOptions,
    socialHonorificPrefixOptions,
    birthDateOptions,

    showValidationsResults,
    additionalField,

    nationalityDataSource,
    showDocumentType,

    eventsName,
    eventsBirthDate,
    eventsNationality,
    eventsPhone,
    eventsEmail,
    eventsDocument,

    needCompleteAddress,
    eventsAddress,
    isWoobaAndInternacional,
    eventsDocumentAdditional,
    documentTypeAdditionalOptions
  }) => {
    let titleComponent = traveler ? 'Informar viajante' : 'Adicionar viajante';

    return (
      <div className="exon-traveler-details">
        <label className="exon-title-componente">{titleComponent}</label>

        <div className="exon-box-travelers">
          <TravelerTemplate.name
            travelerType={travelerType}
            useSocialName={traveler.useSocialName}
            name={traveler.name ? traveler.name : {}}
            socialName={traveler.socialName ? traveler.socialName : {}}
            alias={traveler.alias}
            showValidationsResults={showValidationsResults}
            honorificPrefixOptions={honorificPrefixOptions}
            socialHonorificPrefixOptions={socialHonorificPrefixOptions}
            events={eventsName}
          />

          <div className="exon-box-fields-row">
            <TravelerTemplate.birthDate
              label={'Data de Nascimento'}
              data={traveler.birthDate}
              customValidation={birthDateOptions.validateBirthDate}
              events={eventsBirthDate}
              showValidationsResults={showValidationsResults}
            />

            <TravelerTemplate.gender
              label={'Sexo conforme documento'}
              data={traveler.gender ? traveler.gender : traveler.genre}
              options={genderOptions}
              showValidationsResults={showValidationsResults}
            />

            <TravelerTemplate.nationality
              label={'Nacionalidade'}
              data={traveler.nationality}
              events={eventsNationality}
              nationalityDataSource={nationalityDataSource}
              showValidationsResults={showValidationsResults}
            />
          </div>
          <div className="exon-box-fields-row">
            <TravelerTemplate.phone
              label={'Telefone'}
              data={traveler.phone}
              events={eventsPhone}
              showValidationsResults={showValidationsResults}
            />

            <TravelerTemplate.email
              label={'Email'}
              data={traveler.email}
              events={eventsEmail}
              showValidationsResults={showValidationsResults}
            />
          </div>

          {!!needCompleteAddress && (
            <div className="exon-box-fields-row">
              <TravelerTemplate.address
                traveler={traveler}
                showValidationsResults={showValidationsResults}
                eventsAddress={eventsAddress}
              />
            </div>
          )}

          <hr />
          <TravelerTemplate.documentData
            traveler={traveler}
            documentOptions={documentOptions}
            documentTypeOptions={documentTypeOptions}
            events={eventsDocument}
            showValidationsResults={showValidationsResults}
            showDocumentType={showDocumentType}
          />

          {isWoobaAndInternacional && (
            <>
            <hr />
            <TravelerTemplate.additionalDocumentData
              traveler={traveler}
              documentOptions={documentOptions}
              documentTypeOptions={documentTypeAdditionalOptions}
              events={eventsDocumentAdditional}
              showValidationsResults={showValidationsResults}
              showDocumentType={showDocumentType}
              />
            </>
          )}

          <hr className={additionalField ? '' : ' hide'} />

          {additionalField && (
            <div className="exon-box-fields-row">{additionalField}</div>
          )}
        </div>
      </div>
    );
  },

  name: ({
    travelerType,
    useSocialName,
    name,
    socialName,
    alias,
    showValidationsResults,
    honorificPrefixOptions,
    socialHonorificPrefixOptions,
    events,
  }) => {
    return (
      <div className="exon-traveler-name">
        <div className="exon-form-itens">
          <div className="exon-box-field">
            <label className="exon-label-field">Nome</label>
            <div className="exon-box-fields-row">
              <div className="exon-field small">
                <Select
                  data={name.honorificPrefix}
                  placeholder={honorificPrefixOptions.placeholder}
                  externalOptions={honorificPrefixOptions.options}
                  value={name.firstName ? name.firstName : ''}
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
              <div className="exon-field">
                <Textbox
                  placeholder={'Primeiro Nome'}
                  onChange={events.onFirstNameChanged}
                  value={name.firstName ? name.firstName : ''}
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
              <div className="exon-field">
                <Textbox
                  placeholder={'Nome do meio'}
                  onChange={events.onMiddleNameChanged}
                  value={name.middleName ? name.middleName : ''}
                />
              </div>
              <div className="exon-field">
                <Textbox
                  placeholder={'Último nome'}
                  onChange={events.onLastNameChanged}
                  value={name.lastName ? name.lastName : ''}
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
            </div>
          </div>
          {travelerType == enums.travelerType.Adt && (
            <div className="exon-check-label">
              <div className="exon-input-check">
                <label>
                  <input
                    type="checkbox"
                    onChange={(ev) => events.onShowSocialNameChanged(ev)}
                    checked={useSocialName}
                  />
                  <label>
                    <span></span>
                  </label>
                </label>
              </div>
              <label className="exon-label-field">
                Usar nome social na reserva
              </label>
            </div>
          )}
        </div>
        {travelerType == enums.travelerType.Adt && (
          <div
            className={
              'exon-box-field exon-social-name' +
              (!useSocialName ? ' hide' : '')
            }
          >
            <label className="exon-label-field">Nome social</label>
            <div className="exon-box-fields-row">
              <div className="exon-field small">
                <Select
                  data={socialName.honorificPrefix}
                  placeholder={socialHonorificPrefixOptions.placeholder}
                  externalOptions={socialHonorificPrefixOptions.options}
                  value={name.firstName ? name.firstName : ''}
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
              <div className="exon-field">
                <Textbox
                  placeholder={'Primeiro Nome'}
                  onChange={events.onFirstSocialNameChanged}
                  value={socialName.firstName ? socialName.firstName : ''}
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
              <div className="exon-field">
                <Textbox
                  placeholder={'Nome do meio'}
                  onChange={events.onMiddleSocialNameChanged}
                  value={socialName.middleName ? socialName.middleName : ''}
                />
              </div>
              <div className="exon-field">
                <Textbox
                  placeholder={'Último nome'}
                  onChange={events.onLastSocialNameChanged}
                  value={socialName.lastName ? socialName.lastName : ''}
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
            </div>
          </div>
        )}

        <div className="exon-box-field">
          <label className="exon-label-field">Apelido</label>
          <div className="exon-field">
            <Textbox
              placeholder={'Apelido'}
              onChange={events.onAliasChanged}
              value={alias}
              required={false}
            />
          </div>
        </div>
      </div>
    );
  },

  birthDate: ({
    label,
    data,
    showValidationsResults,
    customValidation,
    events,
  }) => {
    return (
      <div className="exon-box-field">
        <label className="exon-label-field">{label}</label>
        <div className="exon-field">
          <Textbox
            type={enums.components.textBoxType.date}
            onChange={events.onBirthDateChanged}
            value={data ? data : ''}
            required={true}
            forceValidation={showValidationsResults}
            customValidation={customValidation}
          />
        </div>
      </div>
    );
  },

  gender: ({ label, showValidationsResults, data, options }) => {
    return (
      <div className="exon-box-field">
        <label className="exon-label-field">{label}</label>
        <div className="exon-field">
          <Select
            data={data}
            placeholder={options.placeholder}
            externalOptions={options.options}
            required={true}
            forceValidation={showValidationsResults}
          />
        </div>
      </div>
    );
  },

  nationality: ({
    label,
    data,
    showValidationsResults,
    nationalityDataSource,
    events,
  }) => {
    return (
      <div className="exon-box-field">
        <label className="exon-label-field">{label}</label>
        <div className="exon-field">
          <Locations
            placeholder={'Informe a nacionalidade'}
            data={data ? data : null}
            required={true}
            forceValidation={showValidationsResults}
            locationType={enums.locationType.country}
            externalOptions={{
              dataSource: nationalityDataSource,
              events: {
                onInputChanged: events.onNationalityLocationInputChanged,
                onSelected: events.onNationalityChanged,
              },
            }}
          />
        </div>
      </div>
    );
  },

  phone: ({ label, data, showValidationsResults, events }) => {
    return (
      <div className="exon-box-field">
        <label className="exon-label-field">{label}</label>
        <div className="exon-field">
          <Textbox
            type={enums.components.textBoxType.default}
            value={data ? data : ''}
            placeholder={'(XX) XXX-XXX-XXX'}
            required={true}
            forceValidation={showValidationsResults}
            onChange={events.onPhoneChanged}
          />
        </div>
      </div>
    );
  },

  email: ({ label, data, showValidationsResults, events }) => {
    return (
      <div className="exon-box-field">
        <label className="exon-label-field">{label}</label>
        <div className="exon-field">
          <Textbox
            type={enums.components.textBoxType.email}
            onChange={events.onEmailChanged}
            value={data ? data : ''}
            required={true}
            forceValidation={showValidationsResults}
          />
        </div>
      </div>
    );
  },

  documentData: ({
    traveler,
    documentTypeOptions,
    documentOptions,
    showValidationsResults,
    showDocumentType,
    events,
  }) => {
    const fieldType =
      traveler.selectedDocumentType == enums.travelerDocumentType.Cpf
        ? enums.components.textBoxType.cpf
        : enums.components.textBoxType.default;

    let readOnlyDoc = true;

    if(showDocumentType)
      readOnlyDoc = false;

    if(documentTypeOptions.options.isRvOffline != undefined && documentTypeOptions.options.isRvOffline)
      readOnlyDoc = false;

    return (
      <div className="exon-box-fields-row">
        <div className="exon-box-field">
          <label className="exon-label-field">{'Tipo do Documento'}</label>
          <div
            className={`exon-field exon-box-list-ontop ${
              readOnlyDoc ? 'readonly' : ''
            }`}
          >
            <Select
              data={traveler.selectedDocumentType}
              placeholder={documentTypeOptions.placeholder}
              externalOptions={documentTypeOptions.options}
              required={true}
              forceValidation={showValidationsResults}
              readOnly={readOnlyDoc}
            />
          </div>
        </div>

        <div className="exon-box-field">
          <label className="exon-label-field">{'Numero do documento'}</label>
          <div className="exon-field">
            <Textbox
              type={fieldType}
              value={
                traveler.selectedDocumentNumber
                  ? traveler.selectedDocumentNumber
                  : ''
              }
              required={true}
              forceValidation={showValidationsResults}
              customValidation={(value) =>
                documentOptions.validateDocumentByType(
                  traveler.selectedDocumentType,
                  value,
                )
              }
              onChange={events.onDocumentNumberChanged}
            />
          </div>
        </div>

        <div className="exon-box-field readonly">
          <label className="exon-label-field">
            {'Nome Impresso no documento'}
          </label>
          <div className="exon-field">
            <Textbox
              onChange={events.onDocumentNameChanged}
              value={traveler.selectedDocumentName || ''}
              forceValidation={showValidationsResults}
              readOnly={true}
            />
          </div>
        </div>

        {traveler.selectedDocumentType ==
          enums.travelerDocumentType.Passport && (
          <div className="exon-box-field">
            <label className="exon-label-field">
              {'Validade do documento'}
            </label>
            <div className="exon-field">
              <Textbox
                type={enums.components.textBoxType.date}
                onChange={events.onDocumentExpirationChanged}
                value={traveler.selectedDocumentExpiration || ''}
                required={true}
                forceValidation={showValidationsResults}
              />
            </div>
          </div>
        )}
      </div>
    );
  },

  address: ({ traveler, showValidationsResults, eventsAddress }) => {
    return (
      <div className="exon-traveler-name">
        <div className="exon-form-itens">
          <div className="exon-box-fields-row">
            <div className="exon-box-field">
              <label className="exon-label-field">Cep</label>
              <div className="exon-field">
                <Textbox
                  placeholder={'Digite o cep'}
                  onChange={eventsAddress.onAddressZipCodeChanged}
                  value={
                    traveler.address && traveler.address.zipCode
                      ? traveler.address.zipCode
                      : ''
                  }
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
            </div>

            <div className="exon-box-field">
              <label className="exon-label-field">Rua</label>
              <div className="exon-field">
                <Textbox
                  placeholder={'Digite a rua'}
                  onChange={eventsAddress.onAddressStreetChanged}
                  value={
                    traveler.address && traveler.address.street
                      ? traveler.address.street
                      : ''
                  }
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
            </div>

            <div className="exon-box-field">
              <label className="exon-label-field">Numero</label>
              <div className="exon-field">
                <Textbox
                  placeholder={'Digite o numero'}
                  onChange={eventsAddress.onAddressNumberChanged}
                  value={
                    traveler.address && traveler.address.number
                      ? traveler.address.number
                      : ''
                  }
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
            </div>

            <div className="exon-box-field">
              <label className="exon-label-field">Complemento</label>
              <div className="exon-field">
                <Textbox
                  placeholder={'Digite o complemento'}
                  onChange={eventsAddress.onAddressComplementChanged}
                  value={
                    traveler.address && traveler.address.complement
                      ? traveler.address.complement
                      : ''
                  }
                  required={false}
                  forceValidation={showValidationsResults}
                />
              </div>
            </div>

            <div className="exon-box-field">
              <label className="exon-label-field">Cidade</label>
              <div className="exon-field">
                <Textbox
                  placeholder={'Digite a cidade'}
                  onChange={eventsAddress.onAddressCityChanged}
                  value={
                    traveler.address && traveler.address.city
                      ? traveler.address.city
                      : ''
                  }
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
            </div>

            <div className="exon-box-field">
              <label className="exon-label-field">Estado</label>
              <div className="exon-field">
                <Textbox
                  placeholder={'Digite o estado'}
                  onChange={eventsAddress.onAddressStateChanged}
                  value={
                    traveler.address && traveler.address.state
                      ? traveler.address.state
                      : ''
                  }
                  required={true}
                  forceValidation={showValidationsResults}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },

  additionalDocumentData : ({
    traveler,
    documentTypeOptions,
    documentOptions,
    showValidationsResults,
    showDocumentType,
    events,
  }) => {
     const fieldType =
      traveler.selectedDocumentTypeAdditional == enums.travelerDocumentType.Cpf
        ? enums.components.textBoxType.cpf
        : enums.components.textBoxType.default;
    const readOnlyDoc =
      !showDocumentType || !documentTypeOptions.options.isRvOffline;

    return (
      <div className="exon-box-fields-row">
        <div className="exon-box-field">
          <label className="exon-label-field">{'Tipo do Documento'}</label>
          <div
            className={`exon-field exon-box-list-ontop ${
              readOnlyDoc ? 'readonly' : ''
            }`}
          >
            <Select
              data={traveler.selectedDocumentTypeAdditional}
              placeholder={documentTypeOptions.placeholder}
              externalOptions={documentTypeOptions.options}
              required={true}
              forceValidation={showValidationsResults}
              readOnly={readOnlyDoc}
            />
          </div>
        </div>

        <div className="exon-box-field">
          <label className="exon-label-field">{'Numero do documento'}</label>
          <div className="exon-field">
            <Textbox
              type={fieldType}
              value={
                traveler.selectedDocumentNumberAdditional
                  ? traveler.selectedDocumentNumberAdditional
                  : ''
              }
              required={true}
              forceValidation={showValidationsResults}
              customValidation={(value) =>
                documentOptions.validateDocumentByType(
                  traveler.selectedDocumentTypeAdditional,
                  value,
                )
              }
              onChange={events.onDocumentNumberChanged}
            />
          </div>
        </div>

        <div className="exon-box-field readonly">
          <label className="exon-label-field">
            {'Nome Impresso no documento'}
          </label>
          <div className="exon-field">
            <Textbox
              onChange={events.onDocumentNameChanged}
              value={traveler.selectedDocumentNameAdditional || ''}
              forceValidation={showValidationsResults}
              readOnly={true}
            />
          </div>
        </div>

        {traveler.selectedDocumentTypeAdditional ==
          enums.travelerDocumentType.Passport && (
          <div className="exon-box-field">
            <label className="exon-label-field">
              {'Validade do documento'}
            </label>
            <div className="exon-field">
              <Textbox
                type={enums.components.textBoxType.date}
                onChange={events.onDocumentExpirationChanged}
                value={traveler.selectedDocumentExpirationAdditional || ''}
                required={true}
                forceValidation={showValidationsResults}
              />
            </div>
          </div>
        )}
      </div>
    );
  },
};

export default TravelerTemplate;
